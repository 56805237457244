export function getUnitName(unit_name: string, business_type: string): string {

  let unitName = unit_name;
  if (unitName.indexOf("栋") < 0 && unitName.indexOf("号") < 0 && unitName.indexOf("区") < 0 && business_type !== "2") {
    unitName += "栋";
  }
  return unitName;
}

export function getFloor(floor: string, business_type: string): string {
  let unitName = floor;
  if (unitName.indexOf("栋") < 0 && unitName.indexOf("号") < 0 && business_type !== "2") {
    unitName += "层";
  }
  return unitName;
}
