import {Component, OnInit, ViewChild} from '@angular/core';
import {BsModalService, ModalDirective} from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {WorkModel} from "../../../model/common/common.model";
import {ComplaintsService} from '../../../service/complaints/complaints.service';
import {
  ComplaintsDealRequest,
  ComplaintsInfoDTO,
  ComplaintsListRequest,
  ComplaintsListResponse,
  ComplaintsLogInfoDTO,
  ComplaintsLogListResponse,
  ComplaintStatisticsRequest
} from '../../../model/complaints/complaints.model';
import {ActivatedRoute} from '@angular/router';
import {IMyDpOptions} from "../../third-party/mydatepicker/src/lib/interfaces";
import {WorkService} from "../../../service/common/work.service";
import {getTimeFromPlugin} from "../../../common/date.util";
import {ComplainModalComponent} from "../../shared/modal/complain-modal.component";
import {AuthService} from "../../../service/common/auth.service";
import { Chart } from 'angular-highcharts';
import {ImagePreviewModel} from "../../../model/image/image-file";

declare var baidutongji:any;

@Component({
  selector: 'app-complain',
  templateUrl: './complain.component.html',
  styleUrls: ['./complain.component.css']
})
export class ComplainComponent implements OnInit {
  btn_privileges: any = {
    'COMPLAINT_MANAGE': false,
    'COMPLAINT_DATA_STA': false,
    'COMPLAINT_VIEW_HANDLE': false,
    'VIEW_COMPLAINT': false,
    'HANDLE_COMPLAINT': false
  };

  @ViewChild("staticModal") dataStatisticsModal: ModalDirective;
  @ViewChild(ComplainModalComponent, { static: true }) complainModal: ComplainModalComponent;

  modalRef: BsModalRef;
  timer;
  auditRemark: string;
  beingResut: string = null;
  classifyType: string = null;

  start_time: any;
  end_time: any;

  showHr: boolean = false;

  complainReq: ComplaintsListRequest = {
    page_num: 1,
    page_size: 20,
    complaints_user_phone: '',
  };
  complaintsDetailRequest: ComplaintsDealRequest = {
    complaints_id: null,
    operate_type: null,
    operate_content: null
  };
  total: number;

  statisticsRequest: ComplaintStatisticsRequest;
  statistics_start_time: any;
  statistics_end_time: any;

  complain: ComplaintsListResponse;
  complaintsLogListResponse: ComplaintsLogListResponse;
  complaintsInfoDTO: ComplaintsInfoDTO;
  complaintsLogInfoDTO: ComplaintsLogInfoDTO;
  status = [
    {name: '未处理', selected: true, id: 'WAITING'},
    {name: '处理中', selected: true, id: 'PROCESSING'},
    {name: '处理完成', selected: true, id: 'FIXED'}
  ];
  classify_type_list = [
    {code: 'OUTDOOR_NOISE', desc: '室外噪音'},
    {code: 'NEIGHBOURS_NUISANCE', desc: '邻居扰民'},
    {code: 'INFRASTRUCTURE', desc: '基础设施'},
    {code: 'COMMUNITY_MATCHING', desc: '社区配套'},
    {code: 'INDOOR_ASSETS', desc: '室内资产'},
    {code: 'ROOM_POWER_SUPPLY', desc: '房间供电'},
    {code: 'ROOM_WATER_SUPPLY', desc: '房间供水'},
    {code: 'ROOM_HEATING', desc: '房间供暖'},
    {code: 'DOOR_LOCK_PASSWORD', desc: '门锁密码'},
    {code: 'HEATER', desc: '热水器'},
    {code: 'KITCHEN_AND_TOILET_FACILITIES', desc: '厨卫设施'},
    {code: 'BROADBAND_NETWORK', desc: '宽带网络'},
    {code: 'BILL_COST', desc: '账单费用'},
    {code: 'APP_USE', desc: 'app使用'},
    {code: 'COMMUNITY_SAFETY', desc: '社区安全'},
    {code: 'PERSONNEL_SERVICE', desc: '人员服务'},
    {code: 'GOODS_REPAIR', desc: '物品报修'},
    {code: 'OTHER_QUESTIONS', desc: '其他问题'},
  ];

  chart1;
  chart2;
  chart3;

  constructor(
    private modalService: BsModalService,
    private router: ActivatedRoute,
    private complaintsService: ComplaintsService,
    private workService: WorkService,
    private authService: AuthService,
  ) {
  }

  openModal(complaintsInfoDTO: ComplaintsInfoDTO) {
    baidutongji("投诉处理");
    this.complaintsInfoDTO = complaintsInfoDTO;
    this.getComplainLog(complaintsInfoDTO.complaints_id);
    this.complainModal.auditRemark="";
    // this.modalRef = this.modalService.show(template);
    this.complainModal.handleModal.show();
  }

  // 退出页面销毁
  OnDestroy() {
    clearInterval(this.timer);
  }

  // 初始化
  ngOnInit() {
    this.authService.getOperatePrivilege(this.btn_privileges);
    baidutongji("投诉管理");
    /*let a=this.status.map(sta=>sta.name);*/
    this.timer = setInterval(() => {
      this.refresh();
    }, 1000 * 60 * 2); //2分钟刷新一次列表

    this.router.queryParams.subscribe(param => {
      this.getComplainList();
    });
  }


  // 搜索
  search() {
    baidutongji("投拆搜索");
    this.complainReq.page_num = 1;
    this.complainReq.page_size = 20;
    this.total = 0;
    this.getComplainList();
  }

  // 清除筛选
  clear() {
    this.complainReq.complaints_user_phone = '';
    this.status.forEach(element => {
      element.selected = true;
    });
  }

  // 刷新
  refresh(): void {
    this.getComplainList();
  }

  // 去除搜索框空格
  trim(str: string): any {
    if (str && str.length > 0) {
      return str.trim();
    }
    return str;
  }

  // 获取列表
  getComplainList(): void {
    /*this.complainReq.complaints_status_list = [];
    this.status.forEach(item => {
      if (item.selected) {
        this.complainReq.complaints_status_list.push(item.id);
      }
    });*/
    if (this.start_time && !this.end_time) {
      this.workService.showErrorModal("结束提交时间缺失");
      return;
    }
    if (this.end_time && !this.start_time) {
      this.workService.showErrorModal("开始提交时间缺失");
      return;
    }
    if (this.end_time && this.start_time && this.start_time.epoc > this.end_time.epoc) {
      this.workService.showErrorModal("开始时间不能大于结束时间");
      return;
    }
    if (this.start_time) {
      this.complainReq.start_create_time = getTimeFromPlugin(this.start_time.formatted);
    }
    if (this.end_time) {
      this.complainReq.end_create_time = getTimeFromPlugin(this.end_time.formatted);
    }

    this.complainReq.complaints_user_phone = this.trim(
      this.complainReq.complaints_user_phone
    );

    // 移除空字符串
    if (!this.complainReq.complaints_type) {
      delete this.complainReq['complaints_type'];
    }
    if (!this.complainReq.complaints_status) {
      delete this.complainReq['complaints_status'];
    }
    if (!this.complainReq.classify_type) {
      delete this.complainReq['classify_type'];
    }
    if (!this.complainReq.complaints_user_phone) {
      delete this.complainReq['complaints_user_phone'];
    }

    this.complaintsService
      .listWithLoading(this.complainReq)
      .then(data => {
        if (data) {
          this.complain = data;
        } else {
          this.complain = {
            complaints_info_dtos: [],
            total: 0
          };
        }
      });
  }

  // 获取弹窗内容
  getComplainLog(id: any): void {
    this.complaintsDetailRequest.complaints_id = id;
    this.complaintsService
      .log(this.complaintsDetailRequest)
      .then(response => {
        this.auditRemark = '';
        if (response) {
          this.complaintsLogListResponse = response;
        } else {
          this.complaintsLogListResponse = {};
        }
      });
  }

  // 分页
  getComplainPage(currentPage: number) {
    this.complainReq.page_num = currentPage;
    this.getComplainList();
  }

  check(str: string) {
    if (str && str === 'being') {
      this.beingResut = 'PROCESSING';
    }
    if (str && str === 'succ') {
      this.beingResut = 'FIXED';
    }
  }

  myDatePickerOptions: IMyDpOptions = {
    dateFormat: "yyyy-mm-dd",
    editableDateField: false,
    disableDateRanges: [
      {
        begin: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate() + 1
        },
        end: {year: 2999, month: 1, day: 1}
      }
    ]
  };

  getClassifyTypeName(classify_type: string) {
    if (classify_type) {
      for (let item of this.classify_type_list) {
        if (item.code === classify_type) {
          return item.desc;
        }
      }
    }
    return "";
  }

  getDate(date: any) {
    return (date + "").replace("-", ".");
  }

  // 查询统计图表所需数据
  getStatisticsList() {
    let statisticsRequest = new ComplaintsListRequest();
    if(!this.statistics_start_time && !this.statistics_end_time){
      this.workService.showErrorModal("提交时间缺失");
      return;
    }
    if (this.statistics_start_time && !this.statistics_end_time) {
      this.workService.showErrorModal("结束提交时间缺失");
      return;
    }
    if (this.statistics_end_time && !this.statistics_start_time) {
      this.workService.showErrorModal("开始提交时间缺失");
      return;
    }
    if (this.statistics_start_time.epoc > this.statistics_end_time.epoc) {
      this.workService.showErrorModal("开始时间不能大于结束时间");
      return;
    }
    if (this.statistics_start_time) {
      statisticsRequest.start_create_time = getTimeFromPlugin(this.statistics_start_time.formatted);
    }
    if (this.statistics_end_time) {
      statisticsRequest.end_create_time = getTimeFromPlugin(this.statistics_end_time.formatted);
    }
    this.complaintsService.list(statisticsRequest).then(data => {
      if(data.complaints_info_dtos){
        // 显示图标
        $("#pieChart").css("display", 'block');
        $("#complaintChart").css("display", 'block');
        $("#proposalChart").css("display", 'block');
        this.showHr = true;
        let proposalList = data.complaints_info_dtos.filter(item => item.complaints_type === 'PROPOSAL');
        let complaintList = data.complaints_info_dtos.filter(item => item.complaints_type === 'COMPLAINT');
        let proposalCount = proposalList.length;
        let complaintCount = complaintList.length;
        // 保留两位小数
        let proposalRate = Math.round(proposalCount / (proposalCount + complaintCount) * 10000) / 100;
        let complaintRate = Math.round(complaintCount / (proposalCount + complaintCount) * 10000) / 100;
        // 饼状图
        this.chart1 = this.pieChart(proposalRate, complaintRate, proposalCount, complaintCount);
        // 条形图
        let classifyDescList = [];
        let comClassifyDataList = [];
        let proClassifyDataList = [];
        for(let item of this.classify_type_list){
          classifyDescList.push(item.desc);
          let comCount = complaintList.filter(com => com.classify_type === item.code).length;
          comClassifyDataList.push(comCount);
          let proCount = proposalList.filter(pro => pro.classify_type === item.code).length;
          proClassifyDataList.push(proCount);
        }
        this.chart2 = this.complaintChart(classifyDescList, comClassifyDataList);
        this.chart3 = this.proposalChart(classifyDescList, proClassifyDataList);
      }else{
        // 隐藏图表
        this.showHr = false;
        $("#pieChart").css("display", 'none');
        $("#complaintChart").css("display", 'none');
        $("#proposalChart").css("display", 'none');
        this.workService.showErrorModal("暂无数据");
        return;
      }
    });
  }


  // 饼状图
  pieChart(proposalRate: number, complaintRate: number, proposalCount: number, complaintCount: number) :any{
    // Build the chart
    return new Chart( {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: '投诉建议总量',
        style: {
          fontWeight: 'bold'
        }
      },
      tooltip: {
        pointFormat: '<b>{point.percentage:.2f}%</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        }
      },
      series: [{
        type:'pie',
        name: '总量',
        // colorByPoint: true,
        data: [
          {
            name: '投诉 '+complaintCount+'个',
            y: complaintRate,
            color: '#f28f43',
          },
          {
          name: '建议 '+proposalCount+'个',
          y: proposalRate,
          sliced: true,
          selected: true,
          color: '#2f7ed8',
        }],
      }],
    });
  }
  // 投诉归类条形图
  complaintChart(classifyDescList: any[], comClassifyDataList: any[]): any {
    return new Chart( {

      title: {
        text: '投诉归类明细',
        style: {
          fontWeight: 'bold'
        }
      },

      xAxis: {
        categories: classifyDescList,
        tickWidth: 0,
      },

      series: [{
        type: 'column',
        // colorByPoint: true,
        data: comClassifyDataList,
        // showInLegend: false
        color: '#f28f43',
        name: '数量',
      }],

      yAxis: {
        gridLineWidth: 0,
        tickWidth:0,
        labels: {
          enabled: false,
        },
        title: {
          text: ''
        }
      },

      legend: {
        enabled: false
      },

      chart: {
        inverted: true,
        polar: false,
        height: comClassifyDataList.length * 20 + 100
      }
    });
  }
  // 建议归类条形图
  proposalChart(classifyDescList: any[], proClassifyDataList: any[]){
    return new Chart( {

      title: {
        text: '建议归类明细',
        style: {
          fontWeight: 'bold'
        }
      },

      xAxis: {
        categories: classifyDescList,
        tickWidth: 0,
      },

      series: [{
        type: 'column',
        // colorByPoint: true,
        data: proClassifyDataList,
        // showInLegend: false
        color: '#2f7ed8',
        name: '数量',
      }],

      yAxis: {
        gridLineWidth: 0,
        tickWidth:0,
        labels: {
          enabled: false,
        },
        title: {
          text: ''
        }
      },

      legend: {
        enabled: false
      },

      chart: {
        inverted: true,
        polar: false,
        height: proClassifyDataList.length * 20 + 100
      },
    });
  }

  // 投诉模态框关闭后刷新列表
  refreshList(refresh: any) {
    if(refresh){
      this.getComplainList();
    }
  }
}
