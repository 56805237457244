<div bsModal #childModal="bs-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     style="z-index: 999999;background: rgba(0,0,0,0.4);">
  <div [class]="'modal-dialog ' + messageModalInfo?.modal_class" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title"><i class="fa fa-info-circle" aria-hidden="true"></i>{{messageModalInfo?.title}}
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="stayHere()">
            <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
          </button>
      </div>
      <div class="modal-body">
        <p class="text-center" *ngIf="!messageModalInfo||messageModalInfo?.type===1">
          {{messageModalInfo?.operationMessage}}</p>
        <div *ngIf="messageModalInfo?.type===2" [innerHTML]="messageModalInfo.html"></div>
      </div>

      <div *ngIf="messageModalInfo?.trace_id" class="w-100 text-sm text-center font-weight-light" style="margin-bottom:20px">
        行为ID：<span ngxCopyPaste #cp="copy" >{{messageModalInfo?.trace_id}}</span><button class="btn btn-link" (click)="cp.copy()">复制ID</button>
        <hr>
        <p class="tips-p">解决方法：</p>
        <p class="tips-p">1、复制<span class="text-danger">（请勿截图）</span>上述行为ID编码，联系并发送至技术部排查处理</p>
      </div>

      <div class="modal-footer">

        <button *ngIf="messageModalInfo?.cancel" type="button" class="btn btn-secondary" (click)="stayHere()">
          取消
        </button>
        <button *ngIf="messageModalInfo?.stayStatus" type="button" class="btn btn-success" (click)="stayHere()">继续
        </button>
        <button type="button" class="btn btn-primary" (click)="returnTo()">
          {{messageModalInfo?.okBtn?messageModalInfo.okBtn:'确认'}}
        </button>
      </div>
    </div>
  </div>
</div>
