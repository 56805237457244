import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'filtercate'
})
export class FilterCatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!args) {
      return value;
    } else {

      let newValue = value.filter(
        item => item.categroy_name.indexOf(args) > -1
      );
      // 没有展示全部
      return newValue.length === 0 ? value : newValue;
    }
  }
}
