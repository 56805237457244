import {EventEmitter, Injectable} from '@angular/core';
import {WorkModel} from '../../model/common/common.model';
import {MessageModalInfo} from '../../model/modal/message-model';
import {LogService} from "../log/log.service";

/**
 * @description: 组件间通信可用
 * @Author: kaungye
 * @Date: 2018/3/30
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-05-20 15:00:16
 */
@Injectable()
export class WorkService {

  public type = {
    // 页面滚动
    PANEL_SCROLL: 'PANEL_SCROLL',

    // 需要刷新页面
    PANEL_NEED_REFRESH: 'PANEL_NEED_REFRESH',

    // loading
    PANEL_LOADING_START: 'PANEL_LOADING_START',
    PANEL_LOADING_END: 'PANEL_LOADING_END',
    // 请求成功弹框
    DIALOG_SUCCESS: 'DIALOG_SUCCESS',
    // 请求错误弹框提示
    DIALOG_ERROR: 'DIALOG_ERROR',

    // 待办事项业务处理
    SCHEDULE_TASK: 'SCHEDULE_TASK',

    // PDF_VIEW
    PDF_VIEW: 'PDF_VIEW',
    // PDF_PRINT
    PDF_PRINT: 'PDF_PRINT',

    // 房态图关闭详情
    ROOM_PANEL_CLOSE: 'ROOM_PANEL_CLOSE',

    // 主动删除modal-open
    MODAL_CLASS_REMOVE: 'MODAL_CLASS_REMOVE',

    ROOM_STATUS_STOP: 'ROOM_STATUS_STOP',

    ROOM_STATUS_NORMAL: 'ROOM_STATUS_NORMAL:',

    ROOM_OPEN: 'ROOM_OPEN',

    ROOM_CLOSE: 'ROOM_CLOSE',

    DIALOG_CLOSE: 'DIALOG_CLOSE',

    COMPANY_SHOW: 'COMPANY_SHOW',
    COMPANY_HIDE: 'COMPANY_HIDE',

    NO_PROJECT: 'NO_PROJECT',
    NO_COMPANY: 'NO_COMPANY',


    NOT_ACTIVE: 'NOT_ACTIVE',

    RESIZE: 'RESIZE',

    // 关闭自身tab
    TAB_CLOSE_SELF: 'TAB_CLOSE_SELF',
    // 关闭所有tab
    TAB_CLOSE_ALL: 'TAB_CLOSE_ALL',


    // 刷新左侧边栏
    REFRESH_MENU: 'REFRESH_MENU',


    //办理入住 刷新房态
    ROOM_CHECK_IN: 'ROOM_CHECK_IN',
    //退宿 刷新房态
    ROOM_CHECK_OUT: 'ROOM_CHECK_OUT',

    NOW_CHECK_PROJECT: 'NOW_CHECK_PROJECT',

    //隐藏平台向导
    HIDE_GUIDE_MODAL: 'HIDE_GUIDE_MODAL',
    //show平台向导
    SHOW_GUIDE_MODAL: 'SHOW_GUIDE_MODAL',
    //允许跳转
    ROUTE_YES: 'ROUTE_YES',

    // 全局刷新
    RELOAD: 'RELOAD',
    //图片全屏预览
    IMGFULL_SCREEN_PREVEIEW:'IMGFULL_SCREEN_PREVEIEW',

    SHOW_LOADING: 'SHOW_LOADING',
    HIDE_LOADING: 'HIDE_LOADING',
  };

  // 通讯总线
  public event_bus: EventEmitter<WorkModel> = new EventEmitter<WorkModel>();

  show = true;

  constructor(
    private logService: LogService
  ) {

    $("body").on("hide_company", () => {
      this.event_bus.emit(new WorkModel(this.type.COMPANY_HIDE))
    })
  }

  /**
   * @Description: 显示错误弹窗
   * @Author: 陆炳彬
   * @Date: 2018/8/3 下午3:38
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/8/3 下午3:38
   */
  showErrorModal(errorMsg: string) {
    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.operationMessage = errorMsg;
    this.event_bus.emit(new WorkModel(this.type.DIALOG_ERROR, messageModalInfo));
    return;
  }

  /**
   * @Description: 显示正确弹窗
   * @Author: 陆炳彬
   * @Date: 2018/11/8 上午11:56
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/11/8 上午11:56
   */
  showSuccessModal(successMsg: string) {
    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.operationMessage = successMsg;
    this.event_bus.emit(new WorkModel(this.type.DIALOG_SUCCESS, messageModalInfo));
    return;
  }


}
