import {EventEmitter, Injectable} from '@angular/core';
import { ApplyQueryDesensitizationLogReq, WorkModel } from '../../model/common/common.model';
import {MessageModalInfo} from '../../model/modal/message-model';
import {LocalStorage} from "../../common/localstorage";
import {Constants} from "../../common/constants";
import {AuthService} from "./auth.service";
import {Router} from "@angular/router";
import {LogService} from "../log/log.service";
import {UserService} from "../user/user.service";
import {WorkService} from "./work.service";
import {ImageService} from "../image-upload/image.service";
import { RequestService } from "../request.service";
import { environment } from '../../../environments/environment';

/**
 * @description: 公共服务
 * @Author: kaungye
 * @Date: 2019年10月24日13:44:49
 */
@Injectable()
export class CommonService {
  private APPLY_QUERY_DESENSITIZATION_LOG = `${environment.apiHost}apply/query/desensitization/log`;

  constructor(
    private authService: AuthService,
    private router: Router,
    private logService: LogService,
    private userService: UserService,
    private workService: WorkService,
    private requestService: RequestService,
    private imageService: ImageService
  ) {
  }

  // 去除ng元素
  removeNgElement(nativeElement: HTMLElement) {
    let parentElement: HTMLElement = nativeElement.parentElement;
    // move all children out of the element
    while (nativeElement.firstChild) {
      parentElement.insertBefore(nativeElement.firstChild, nativeElement);
    }
    // remove the empty element(the host)
    parentElement.removeChild(nativeElement);
  }

  // 按钮操作权限获取
  getOperatePrivilege(priv) {
    let pri = LocalStorage.getItem(Constants.USER_OPERATE_PRIVILEGES + this.authService.getUserInfo().id);
    if (pri) {
      const privileges = JSON.parse(pri);
      if (privileges) {
        for (let p in priv) {
          priv[p] = privileges.find(e => e === p);
        }
      }
    }
  }


  /**
   * logout
   */
  logout(): void {

    // close all tab
    this.workService.event_bus.emit(new WorkModel(this.workService.type.TAB_CLOSE_ALL));

    this.userService.logout().then(value => {
      //清空所有缓存
      LocalStorage.customClear(this.authService.getUserInfo());
      sessionStorage.clear();
      this.router.navigate(['login']);
    }).catch(error => {
      this.logService.error('退出登录失败');
    });
  }


  quillCreated(quill: any, editor: any, id) {

    // 获取quill的工具栏对象
    const toolbar = quill.getModule('toolbar');
    // 给工具栏的image功能添加自定义函数，注意this指向问题
    toolbar.addHandler('image', this.imageHandler.bind(this, editor, id));
    // 保存quill对象
    editor[id] = quill;
  }

  imageHandler(editor, id) {

    this.workService.event_bus.emit(new WorkModel(this.workService.type.PANEL_LOADING_START));

    const Imageinput = document.createElement('input');
    Imageinput.setAttribute('type', 'file');
    Imageinput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp'); // 可改上传图片格式
    Imageinput.classList.add('ql-image');
    Imageinput.addEventListener('change', () => {
      const file = Imageinput.files[0];
      if (Imageinput.files != null && Imageinput.files[0] != null) {
        /* 自定义代码 */

        this.imageService.simplePostImage(file).then(imageUrl => {
          // 下面这句话必填，成功后执行 （imageUrl 为上传成功后的图片完整路径）
          editor[id].insertEmbed(editor[id].getSelection(true).index, 'image', imageUrl);

          $('#'+id+' .ql-editor').append('<p></p>');
          // $('#'+id+' .ql-editor').html($('#'+id+' .ql-editor').html());

          this.workService.event_bus.emit(new WorkModel(this.workService.type.PANEL_LOADING_END));
        });

      }
    });
    Imageinput.click();
  }

  recordingLog(params: ApplyQueryDesensitizationLogReq): Promise<any> {
    return this.requestService.authPost(this.APPLY_QUERY_DESENSITIZATION_LOG, params);
  }
}
