import { RequestService } from '../request.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class WaterElectricService {
  //水电费管理
  private WATER_ELECT_MANAGE = `${environment.apiHost}waterElectric/manage`;
  //水电价格列表
  private WATER_ELECT_FEE_LIST = `${environment.apiHost}waterElectric/list`;
  //水电价格修改记录列表
  private WATER_ELECT_FEE_LOG_LIST = `${environment.apiHost}waterElectric/log/list`;
  //水电历史读数记录列表
  private WATER_ELECT_AMOUNT_LIST = `${environment.apiHost}waterElectric/amount/list`;

  // 退房抄表
  private CHECKOUT_METER_READING = `${environment.apiHost}waterElectric/meter/reading`;

  constructor(private requestService: RequestService) {

  }

  /**
   * @Description:   水电费价格修改
   * @Author: zhoujiahao
   * @Date: 2018-05-07 15:09
   */
  public manage(requestData?: any): Promise<any> {
    return this.requestService.authPost(this.WATER_ELECT_MANAGE, requestData);
  }

  /**
   * @Description:   水电费价格列表查询
   * @Author: zhoujiahao
   * @Date: 2018-05-07 15:09
   */
  public baseFeeList(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.WATER_ELECT_FEE_LIST, requestData);
  }

  /**
   * @Description:   水电费价格修改记录
   * @Author: zhoujiahao
   * @Date: 2018-05-07 15:09
   */
  public logList(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.WATER_ELECT_FEE_LOG_LIST, requestData);
  }

  /**
   * @Description:   水电历史读数列表
   * @Author: zhoujiahao
   * @Date: 2018-05-07 15:09
   */
  public amountList(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.WATER_ELECT_AMOUNT_LIST, requestData);
  }

  public checkOutMeterReading(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.CHECKOUT_METER_READING, requestData);
  }
}
