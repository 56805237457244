import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {RequestService} from '../request.service';
import {EleMonthPriceRequest,EleMonthPriceResponse,EleMonthPriceRecRequest,EleMonthPriceRecResponse} from '../../model/elediff/elemonth.model';


@Injectable()
export class ElemonthService {

  //月度电费单价列表
  private GET_ELEMONTHPRICE_URL = `${environment.apiHost}ele/diff/monthPriceList`;
  //月度电费结算单价添加
  private ADD_ELEMONTHPRICE_URL = `${environment.apiHost}ele/diff/addMonthPrice`;

  constructor(private requestService: RequestService) {
  }
  /**
   * 月度电费单价列表
   * @param eleMonthPriceRequest
   */
  getEleMonthPrice(eleMonthPriceRequest?: EleMonthPriceRequest): Promise<EleMonthPriceResponse> {
    return this.requestService.authGet(this.GET_ELEMONTHPRICE_URL, eleMonthPriceRequest);
  }

  /**
   * 月度电费结算单价添加
   * @param eleMonthPriceRecRequest
   */
  addEleMonthPrice(eleMonthPriceRecRequest?: EleMonthPriceRecRequest): Promise<EleMonthPriceRecResponse> {
    return this.requestService.authPost(this.ADD_ELEMONTHPRICE_URL, eleMonthPriceRecRequest);
  }
}
