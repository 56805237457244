{{setBillDetailModal(staticModal15)}}
<div class="modal fade" bsModal #staticModal15="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>账单详情</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="staticModal15.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 col-md-6 mb10">
            <p class="w90px text-right">账单金额：</p>
            <p class="condivred">{{leaseRoomBillDetail?.bill_amount}}元</p>
          </div>
          <div class="col-12 col-md-6 mb10">
            <p class="w90px text-right">应付时间：</p>
            <p class="condiv">
              {{leaseRoomBillDetail?.plan_pay_time | date:'yyyy-MM-dd HH:mm:ss'}}
            </p>
          </div>
          <div class="col-12 col-md-6 mb10">
            <p class="w90px text-right">账单周期：</p>
            <p class="condiv">
              <ng-template [ngIf]="leaseRoomBillDetail?.bill_start_time && leaseRoomBillDetail?.bill_end_time">
                {{leaseRoomBillDetail.bill_start_time | date:'yyyy-MM-dd'}} - {{leaseRoomBillDetail.bill_end_time |
                date:'yyyy-MM-dd'}}
              </ng-template>
              <span class="text-orange" *ngIf="leaseRoomBillDetail?.is_free">（免租{{leaseRoomBillDetail?.free_day}}天）</span>
            </p>
          </div>
          <div class="col-12 col-md-6 mb10">
            <p class="w90px text-right">账单类型：</p>
            <p class="condiv">{{leaseRoomBillDetail?.bill_type_name}}</p>
          </div>
          <div class="col-12 col-md-6 mb10">
            <p class="w90px text-right">所属合同：</p>
            <p class="condiv">{{leaseRoomBillDetail?.contract_num}}</p>
          </div>
          <div class="col-12 col-md-6 mb10">
            <p class="w90px text-right">车库号：</p>
            <p class="condiv">{{leaseRoomBillDetail?.carport_number}}</p>
          </div>
          <div class="col-12 mb20">
            <p class="w90px text-right">备注：</p>
            <p class="condiv">{{leaseRoomBillDetail?.remark}}</p>
          </div>
        </div>
        <p class="text-orange text-center mb10 border-top padd15" *ngIf="!billPayLogList || billPayLogList.length===0">
          此账单暂无任何收退款交易明细</p>
        <ng-template [ngIf]="billPayLogList && billPayLogList.length>0">
          <p class="black mb10">此账单共有{{billPayLogList.length}}条 账务明细：</p>
          <div class="table-box">
            <table class="table table-bordered table-striped">
              <thead>
              <tr>
                <th>交易时间</th>
                <th>交易类型</th>
                <th>交易方式</th>
                <th>支付金额</th>
                <th>相关支付流水或凭证</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let billPayLog of billPayLogList">
                <td>{{billPayLog.pay_time | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                <td>{{billPayLog.bill_type}}</td>
                <td>{{billPayLog.pay_type}}</td>
                <td>{{billPayLog.pay_amount}}</td>
                <td>
                  {{billPayLog.source_type_name}}
                  <ng-template [ngIf]="billPayLog.source_type==='PAY_LOG' || billPayLog.source_type==='BOOKING'">
                    <button type="button" class="btn btn-outline-primary"
                            (click)="showDetail(billPayLog.source_id,billPayLog.source_type)">{{billPayLog.source_no}}
                    </button>
                  </ng-template>
                  <ng-template [ngIf]="billPayLog.source_type!=='PAY_LOG' && billPayLog.source_type!=='BOOKING'">
                    <span class="text-blue">{{billPayLog.source_no}}</span>
                  </ng-template>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </ng-template>


        <div class="text-center">
          <button type="button" class="btn btn-warning mr20" (click)="showDialog(leaseRoomBillDetail.carport_lease_id,leaseRoomBillDetail.bill_id)"
                  *ngIf="(leaseRoomBillDetail?.bill_status==='NO_PAYMENT' || leaseRoomBillDetail?.bill_status==='PARTIAL_PAYMENT')  && leaseRoomBillDetail?.bill_charge_off_status==='OUT' && LEASE_BILL">
            <i class="fa fa-cny"></i>收款
          </button>
          <button type="button" class="btn btn-info mr20"
                  (click)="printshow(leaseRoomBillDetail.carport_lease_id,leaseRoomBillDetail.bill_id)">
            <i class="fa fa-print"></i>打印
          </button>
          <button type="button" class="btn btn-secondary" (click)="staticModal15.hide()">关闭</button>
        </div>
      </div>

    </div>
  </div>

</div>

<app-bill-gathering></app-bill-gathering>
<!--<app-print-preview #printprew [src]="contractPath"></app-print-preview>-->


