import { RequestService } from "../request.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../common/auth.service";
import { environment } from '../../../environments/environment';
import {ModuleListResponse} from "../../model/module/module.model";

@Injectable()
export class ModuleService {

  private MODULE_QUERY = `${environment.apiHost}module/query`;

  constructor(private auth: AuthService, private requestService: RequestService, private http: HttpClient) {

  }

  queryModule(moduleId?: any): Promise<ModuleListResponse> {
    if (moduleId == null) {
      return this.requestService.authGet(this.MODULE_QUERY);
    } else {
      return this.requestService.authGet(this.MODULE_QUERY, { moduleId: moduleId });
    }

  }
}
