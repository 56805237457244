import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Constants } from "../../../common/constants";
import { LocalStorage } from "../../../common/localstorage";
import { AuthService } from "../../../service/common/auth.service";

@Component({
  selector: 'app-sign-change',
  templateUrl: './sign-change.component.html',
  styleUrls: ['./sign-change.component.css']
})
export class SignChangeComponent implements OnInit {
  //租约或者订单ID
  leasId: number;
  //租约状态
  leaseStatus: string;
  //租约详情
  LEASE_DETAIL;
  //租约增加
  LEASE_ADD;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private  authService: AuthService) {
    this.leasId = this.activatedRoute.params['value']['lease_id'];
    this.leaseStatus = this.activatedRoute.params['value']['lease_status'];
    this.getOperatePrivilege();

  }

  ngOnInit() {
  }

  //按钮操作权限
  getOperatePrivilege() {
    let pri = LocalStorage.getItem(Constants.USER_OPERATE_PRIVILEGES + this.authService.getUserInfo().id);
    if (pri) {
      const privileges = JSON.parse(pri);
      if (privileges) {
        this.LEASE_DETAIL = privileges.find(e => e === 'LEASE_DETAIL');
        this.LEASE_ADD = privileges.find(e => e === 'LEASE_ADD');
      }
    }
    if (this.leaseStatus === 'UNCOMMITTED' && this.LEASE_ADD) {
      this.router.navigate(['/system_manage/lease/tolease/tolease'], { queryParams: { 'lease_id': this.leasId } });
    } else {
      this.router.navigate(["/system_manage/signed/pact/", this.leasId]);
    }
  }
}
