import {ComplainComponent} from './complain/complain.component';
import {NgModule} from '@angular/core';

import {WorkspaceRoutingModule} from './workspace-routing.module';
import {WorkbenchComponent} from './workbench/workbench.component';
import {SharedRootModule} from "../shared/module/share-common.module";
import {RiskComponent} from './risk/risk.component';
import {RepairComponent} from './repair/repair/repair.component';
import {SeeHouseModule} from "../seehouse/seehouse.module";
import {NgCircleProgressModule} from 'ng-circle-progress';
import {SafetyComponent} from './safety/safety.component';
import {DataDeatilComponent} from './data-deatil/data-deatil.component';
import {SafetyDisposeListComponent} from "./safety-dispose-list/safety-dispose-list.component";
import {WorkbenchPreloadComponent} from './workbench-preload/workbench-preload.component';
import { WorkOrderComponent } from './work-order/work-order.component';
import { ApprovDoneListComponent } from './approv-done-list/approv-done-list.component';
import {CleanTaskListComponent} from "./cleantask-list/cleantask-list.component";
import { CleanTaskImportBatchComponent } from './clean-task-import-batch/clean-task-import-batch.component';
import {UMeditorModule} from "ngx-umeditor";
import {ApprovLockPasswordListComponent} from "./approv-lock-password-list/approv-lock-password-list.component";

@NgModule({
  imports: [
    SharedRootModule,
    WorkspaceRoutingModule,
    SeeHouseModule,
    NgCircleProgressModule.forRoot({
      "radius": 28,
      "space": -4,
      "outerStrokeGradient": true,
      "outerStrokeWidth": 4,
      "outerStrokeLinecap": "square",
      "innerStrokeWidth": 4,
      "innerStrokeColor": "#e7e8ea",
      "subtitleFontSize": "10",
      "animateTitle": false,
      "animationDuration": 300,
      "showUnits": false,
      "showBackground": false,
      "clockwise": true,
      "title": "0",
      "subtitle": "%",
      "titleFontSize": "14"
    }),
    UMeditorModule.forRoot(),
  ],
  exports: [
    SafetyComponent
  ],
  declarations: [
    WorkbenchComponent,
    RiskComponent,
    ComplainComponent,
    RepairComponent,
    SafetyComponent,
    DataDeatilComponent,
    SafetyDisposeListComponent,
    WorkbenchPreloadComponent,
    WorkOrderComponent,
    ApprovDoneListComponent,
    CleanTaskListComponent,
    CleanTaskImportBatchComponent,
    ApprovLockPasswordListComponent,
  ]
})
export class WorkspaceModule {
}
