<div class="setbox securityCall">
  <div><label>安全报警类型：</label></div>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-3 item mb10">
      <select class="w-100" [(ngModel)] ="safe_type">
        <option value="ALL">全部</option>
        <option value="UN_REGISTERED">未登记在住人员</option>
        <option value="UNKNOW_SUSPICIOUS">陌生可疑人员</option>
        <option value="THID_NOT_OUT">3日未出人员</option>
        <option value="THID_NOT_IN">3日未归人员</option>
      </select>
    </div>
    <div class="col-12 col-md-6 col-lg-3 item mb10">
      <label>&nbsp;</label>
      <button (click)="search(1)" class="btn btn-info" type="button">
        <i class="fa fa-search"></i>搜索
      </button>
      （仅显示最近6个月的消息）
    </div>

  </div>

  <div>
    <p *ngIf="loading">数据正在查询中......</p>
    <p *ngIf="!loading && !(personSafeAlarmListRes?.person_safe_alarm_dtos)">未查询到相关数据</p>
  </div>

  <div style="padding: 10px;" class="content row" *ngIf="!loading && personSafeAlarmListRes?.person_safe_alarm_dtos">
    <ng-container *ngFor="let info of personSafeAlarmListRes?.person_safe_alarm_dtos | paginate: {itemsPerPage: personSafeAlarmListReq.page_size,
          currentPage: personSafeAlarmListReq.page_num,totalItems: personSafeAlarmListRes?.total }" >
      <div *ngIf="info?.safe_type == 'UN_REGISTERED'" class="position-relative frame">
        <div class="hovershadow u12584_div row">
          <div style="width: 130px;height: 160px;" class="padd5"><img [src]="info?.head_image" ></div>
          <div class="position-relative infoLeft">
            <p class="text1">未登记在住人员</p>
            <p class="text1 mt10">{{info?.not_registered_chickin_dto?.total_num}}次进出{{info?.not_registered_chickin_dto?.unit_name}}</p>
            <p class="p-line-heigth">进出时段：</p>
            <p class="p-line-heigth" *ngIf="info?.not_registered_chickin_dto?.come_in_time">进：{{info?.not_registered_chickin_dto?.come_in_time}} {{info?.not_registered_chickin_dto?.come_in_num}}次</p>
            <p class="p-line-heigth" *ngIf="!info?.not_registered_chickin_dto?.come_in_time">进：未能获取</p>
            <p class="p-line-heigth" *ngIf="info?.not_registered_chickin_dto?.get_out_time">出：{{info?.not_registered_chickin_dto?.get_out_time}} {{info?.not_registered_chickin_dto?.get_out_num}}次</p>
            <p class="p-line-heigth" *ngIf="!info?.not_registered_chickin_dto?.get_out_time">出：未能获取</p>
            <p class="p-line-heigth" *ngIf="info?.not_registered_chickin_dto?.first_come_in_time">首次进入日期：{{info?.not_registered_chickin_dto?.first_come_in_time | date :"yyyy.MM.dd"}}</p>
            <p class="p-line-heigth" *ngIf="!info?.not_registered_chickin_dto?.first_come_in_time">首次进入日期：未能获取</p>
            <p class="p-line-heigth handleren">{{info?.operator_name}}于{{info?.operator_time | date:"MM月dd日"}}处理关闭</p>
          </div>
        </div>
        <span class="end">已关闭</span>
        <div class="groupbtn">
          <div class="col-12">
            <div class="row">
              <div class="col-6">
                <button class="btn btn-outline-primary text-center w-100" type="button" (click)="safeWarnDetail(info)">详 情</button>
              </div>
              <div class="col-6">
                <button class="btn btn-outline-primary text-center w-100" type="button" (click)="safeWarnHandle(info)">处理记录</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="info?.safe_type == 'UNKNOW_SUSPICIOUS'" class="position-relative frame">
        <div class="hovershadow u12584_div row">
          <div style="width: 130px;height: 160px;" class="padd5"><img [src]="info?.head_image" ></div>
          <div class="position-relative infoLeft">
            <p class="text1">陌生可疑人员</p>
            <p class="text1 mt10">频繁多次进出</p>
            <div class="list">
              <ng-container *ngFor="let std of info?.strange_suspect_dtos">
                <p class="text2" *ngFor="let ados of std?.access_dtos">{{ados?.access_time | date:"MM-dd HH:mm:ss"}}：
                  {{ados?.position_detail}}{{ados?.type}}</p>
              </ng-container>
            </div>
            <p class="text2 handleren">{{info?.operator_name}}于{{info?.operator_time | date:"MM月dd日"}}处理关闭</p>
          </div>
        </div>
        <span class="end">已关闭</span>
        <div class="groupbtn">
          <div class="col-12">
            <div class="row">
              <div class="col-6">
                <button class="btn btn-outline-primary text-center w-100" type="button" (click)="safeWarnDetail(info)">详 情</button>
              </div>
              <div class="col-6">
                <button class="btn btn-outline-primary text-center w-100" type="button" (click)="safeWarnHandle(info)">处理记录</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="info?.safe_type == 'THID_NOT_IN'" class="position-relative frame">
        <div class="hovershadow u12584_div row">
          <div style="width: 130px;height: 160px;" class="padd5"><img [src]="info?.head_image" ></div>
          <div class="position-relative infoLeft">
            <p class="text1">3日未归人员</p>
            <p class="text1 mt10">{{info?.room_name}}  {{info?.person_name}}</p>
            <p class="text2">{{info?.person_remark}}</p>
            <p class="text2">离宿时间：{{info?.get_out_time | date:"MM-dd HH:mm:ss"}}</p>
            <p class="text2 handleren">{{info?.operator_name}}于{{info?.operator_time | date:"MM月dd日"}}处理关闭</p>
          </div>
        </div>
        <span class="end">已关闭</span>
        <div class="groupbtn">
          <div class="col-12">
            <div class="row">
              <div class="col-6">
                <button class="btn btn-outline-primary text-center w-100" type="button" (click)="safeWarnDetail(info)">详 情</button>
              </div>
              <div class="col-6">
                <button class="btn btn-outline-primary text-center w-100" type="button" (click)="safeWarnHandle(info)">处理记录</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="info?.safe_type == 'THID_NOT_OUT'" class="position-relative frame">
        <div class="hovershadow u12584_div row">
          <div style="width: 130px;height: 160px;" class="padd5"><img [src]="info?.head_image" ></div>
          <div class="position-relative infoLeft">
            <p class="text1">3日未出人员</p>
            <p class="text1 mt10">{{info?.room_name}}  {{info?.person_name}}</p>
            <p class="text2">{{info?.person_remark}}</p>
            <p class="text2">进入时间：{{info?.come_in_time | date:"MM-dd HH:mm:ss"}}</p>
            <p class="text2 handleren">{{info?.operator_name}}于{{info?.operator_time | date:"MM月dd日"}}处理关闭</p>
          </div>
        </div>
        <span class="end">已关闭</span>
        <div class="groupbtn">
          <div class="col-12">
            <div class="row">
              <div class="col-6">
                <button class="btn btn-outline-primary text-center w-100" type="button" (click)="safeWarnDetail(info)">详 情</button>
              </div>
              <div class="col-6">
                <button class="btn btn-outline-primary text-center w-100" type="button" (click)="safeWarnHandle(info)">处理记录</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

  </div>

  <div *ngIf="personSafeAlarmListRes?.person_safe_alarm_dtos && personSafeAlarmListRes?.total > 0"
       class="text-right pages">
    <pagination-controls (pageChange)="search($event)" directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                         screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                         screenReaderCurrentLabel="You're on page"></pagination-controls>
    <div class="text-right">共有{{personSafeAlarmListRes?.total}}条信息</div>
  </div>

  <app-safe-warn-detail (handled)="safeListHandle()"></app-safe-warn-detail>
</div>
