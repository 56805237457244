import { Pipe, PipeTransform } from '@angular/core';

/**
 * @Description: 最大长度管道
 * @Author: 陆炳彬
 * @Date: 2018/6/6 下午3:24
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/6/6 下午3:24
 */
@Pipe({
  name: 'maxLengthPipe'
})
export class MaxLengthPipe implements PipeTransform {

  transform(text: string, maxLength: number): any {
    if (!text || text.length <= maxLength) {
      return text;
    }

    return text.substring(0, maxLength) + '...';
  }
}
