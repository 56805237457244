import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../service/common/auth.service";
import {WorkService} from "../../../service/common/work.service";
import {IdempotentService} from "../../../service/idempotentService/IdempotentService";
import {AppointService} from "../../../service/appoint/appoint.service";
import {AppointmentHouseApplyDetailResponse} from "../../../model/appoint/appoint.model";

@Component({
  selector: 'app-appointment-detail',
  templateUrl: './appointment-detail.component.html',
  styleUrls: ['./appointment-detail.component.css']
})
export class AppointmentDetailComponent implements OnInit {

  btn_privileges: any = {
    'APPOINT_ROOM_WAIT_VIEW': false,
    'WSEE_HOUSE_OPERATE': false,
  };

  id;
  detail: AppointmentHouseApplyDetailResponse;

  s1 = 0;
  s2 = 0;
  s3 = 0;
  s4 = 0;
  s5 = 0;
  st = 0;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private workService: WorkService,
    private idempotentService: IdempotentService,
    private appointService: AppointService,
  ) {

  }

  ngOnInit(): void {

    this.authService.getOperatePrivilege(this.btn_privileges);

    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      this.getDetail();
    });
  }


  getDetail() {
    this.appointService.appointmentDetail(this.id).then(data => {
      this.detail = data;

      let s1Obj = data.score_list.find(e => e.service_code === 'SER_1');
      this.s1 = s1Obj ? s1Obj.score : 0;
      let s2Obj = data.score_list.find(e => e.service_code === 'SER_2');
      this.s2 = s2Obj ? s2Obj.score : 0;
      let s3Obj = data.score_list.find(e => e.service_code === 'SER_3');
      this.s3 = s3Obj ? s3Obj.score : 0;
      let s4Obj = data.score_list.find(e => e.service_code === 'SER_4');
      this.s4 = s4Obj ? s4Obj.score : 0;
      let s5Obj = data.score_list.find(e => e.service_code === 'SER_5');
      this.s5 = s5Obj ? s5Obj.score : 0;
    });
  }
}
