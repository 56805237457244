<div class="">
  <div class="app flex-row align-items-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">404</h1>
            <h4 class="pt-3">页面不见了</h4>
            <p class="text-muted">您输入的地址有误。</p>
          </div>
          <div class="text-center">
            <button class="btn btn-secondary" type="button" [routerLink]="['/']">返回</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
