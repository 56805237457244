<div class="modal fade" bsModal #binddingModal1="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" (click)="cclean($event)"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header ">

        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i> pad设备连接</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="binddingModal1.hide();cleanTime();">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body text-center pt-5 pb-5" style="color: #000000;">
        <div>
          <img style="width: 80px;" src="../../../../assets/img/loading.gif" alt="">
        </div>
        <div class="mt-3">
          设备搜索中,请稍后...
        </div>
      </div>
      <div class="modal-footer" style="color:#ffffff;">
        <button class="btn btn-secondary" (click)="binddingModal1.hide();binddingModal2.show()">
          连接
        </button>&nbsp;
        <button class="btn btn-secondary"
                (click)="binddingModal1.hide();cleanTime();">
          取消
        </button>&nbsp;
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #binddingModal2="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" (click)="cclean($event)"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header ">

        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i> pad设备连接</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="binddingModal2.hide();cleanTime();">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body text-center pt-3" style="color: #000000;">
        <div class="text-left">
          请选择设备并连接:
        </div>
        <div class="mt-3" style="max-height: 200px;overflow-y: auto;">
          <table class="table table-bordered table-striped border_none">
            <tbody class="thead">
            <tr class="border_none" *ngFor="let pad of ipad_uuids;">
              <td class="border_none" style="width: 30%;"><input type="radio" name="deviceNo" [checked]="ipad_uuid === pad"
                         (click)="changeIpadId(pad)"></td>
              <td class="border_none text-left">{{pad}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer" style="color:#ffffff;">
        <button *ngIf="ipad_uuid" class="btn btn-primary" (click)="binddingModal2.hide();connectDaiog(2)">
          连接
        </button>&nbsp;
        <button *ngIf="!ipad_uuid" class="btn btn-primary">
          连接
        </button>&nbsp;
        <button class="btn btn-secondary"
                (click)="binddingModal2.hide();cleanTime();">
          取消
        </button>&nbsp;
      </div>
    </div>
  </div>
</div>


<div class="modal fade" bsModal #binddingModal3="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" (click)="cclean($event)"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header ">

        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i> pad设备连接</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="binddingModal3.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body text-center pt-5 pb-5" style="color: #000000;">
        <div>
          <img style="width: 80px;" src="../../../../assets/img/loading.gif" alt="">
        </div>
        <div class="mt-3">
          连接中,请稍后...
        </div>
      </div>
      <div class="modal-footer" style="color:#ffffff;">
        <button class="btn btn-secondary" (click)="binddingModal3.hide();binddingModal4.show()">
          连接
        </button>&nbsp;
        <button class="btn btn-secondary"
                (click)="binddingModal3.hide()">
          取消
        </button>&nbsp;
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #binddingModal4="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" (click)="cclean($event)"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header ">

        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i> pad设备连接</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="binddingModal4.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body text-center pt-5 pb-5" style="color: #000000;">
        <div>
          已连接设备：{{ipad_uuid}}
        </div>
      </div>
      <div class="modal-footer" style="color:#ffffff;">
        <button class="btn btn-primary" (click)="pushSignData()">
          确认并使用
        </button>&nbsp;
        <button class="btn btn-secondary"
                (click)="binddingModal4.hide();getRedisIpadPc()">
          切换设备
        </button>&nbsp;
      </div>
    </div>
  </div>
</div>


<div class="modal fade" bsModal #binddingModal5="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" (click)="cclean($event)"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header ">

        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i> 操作提示</h4>
        <!--<button type="button" class="close pull-right" aria-label="Close" (click)="binddingModal5.hide();cleanTime1();">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>-->
      </div>
      <div class="modal-body text-center pt-5 pb-5" style="color: #000000;">
        <div>
          请等待客户在pad设备上提交签字内容...
        </div>
        <div class="mt-2">
          如客户已提交成功，页面未跳转，请点击刷新按钮
        </div>
      </div>
      <div class="modal-footer" style="color:#ffffff;">
        <button class="btn btn-secondary" (click)="breakConnect();cleanTime1();">
          断开连接
        </button>&nbsp;
        <button class="btn btn-primary"
                (click)="querySignStatus()">
          刷新
        </button>&nbsp;
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #binddingModal6="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" (click)="cclean($event)"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header ">

        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i> 操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="binddingModal6.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body text-center pt-5 pb-5" style="color: #000000;">
        <div>
          <p *ngIf="this.signIpadResponse && this.signIpadResponse.sign_router_link_type !== 'CHECKOUT_LIST' && this.signIpadResponse.sign_router_link_type !== 'CHANGE_ROOM'">客户已签署！</p>
          <p *ngIf="this.signIpadResponse && this.signIpadResponse.sign_router_link_type === 'CONTRACT'">关闭弹窗后可打印电子合同，也可在已签署合同明细页面，点击“合同预览”再次打印。</p>
          <p *ngIf="this.signIpadResponse && this.signIpadResponse.sign_router_link_type === 'GOODS_LIST'">关闭弹窗后可打印交接单，也可在合同中“查看房间明细”中找到此单据再次打印</p>
          <p *ngIf="this.signIpadResponse && this.signIpadResponse.sign_router_link_type === 'INVENTORY_LIST'">物品赔损确认成功！您可返回租约页面继续操作退房，也可关闭弹窗查看并打印交接单！</p>
          <p *ngIf="this.signIpadResponse && this.signIpadResponse.sign_router_link_type === 'CHECKOUT_LIST'">退房成功！</p>
          <p *ngIf="this.signIpadResponse && this.signIpadResponse.sign_router_link_type === 'CHECKOUT_LIST'">您可在“已签署合同明细页面”-【退房记录】模块查看/打印退房单。</p>
          <p *ngIf="this.signIpadResponse && this.signIpadResponse.sign_router_link_type === 'CHANGE_ROOM'">换房成功！</p>
          <p *ngIf="this.signIpadResponse && this.signIpadResponse.sign_router_link_type === 'CHANGE_ROOM'">您可在“已签署合同明细页面”-【换房记录】模块查看/打印换房协议书。</p>
        </div>
      </div>
      <div class="modal-footer" style="color:#ffffff;">
        <button class="btn btn-primary"
                (click)="binddingModal6.hide();skip()">
         关闭
        </button>&nbsp;
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #binddingModal7="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" (click)="cclean($event)"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header ">

        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i> pad设备连接</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="binddingModal7.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body text-center pt-5 pb-5" style="color: #000000;">
        <div>
          设备：{{ipad_uuid}}连接已断开！
        </div>
      </div>
      <div class="modal-footer" style="color:#ffffff;">
        <button class="btn btn-primary"
                (click)="binddingModal7.hide()">
          关闭
        </button>&nbsp;
      </div>
    </div>
  </div>
</div>
