import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {RenterInfo, RenterInfoWithIndex} from '../../../model/lease/lease.model';
import {LeaseService} from '../../../service/lease/lease.service';
import {BaseInfoService} from '../../../service/common/base-info.service';
import {BaseEnum} from '../../../model/modal/base-model';
import {
  nameValidator,
  renterModalCretTypeValidator,
  renterModalCretValueValidator,
  renterModalPhoneValidator
} from '../../../core/validator/validator.sign';
import {IdReaderService} from '../../../service/renter/id_reader.service';
import {
  CreateLesseeParams,
  MatchLesseeParams,
  ReadIdCardInfo,
  RenterEditBaseInfo
} from '../../../model/renter/renter.model';
import {ClientuserService} from "../../../service/user/clientuser.service";
import {WorkService} from "../../../service/common/work.service";
import {ModalDirective} from "ngx-bootstrap/modal";
import {AuthService} from '../../../service/common/auth.service';

@Component({
  selector: 'app-renter-modal',
  templateUrl: './renter-modal.component.html',
  styleUrls: ['./renter-modal.component.css']
})
export class RenterModalComponent implements OnInit {

  btn_privileges: any = {
    'QUERY_USER_PRIVAYE': false,
  };
  @ViewChild('checkOutModal', { static: true }) checkOutModal: ModalDirective;
  @ViewChild('renter_modal') renter_modal: ModalDirective;
  @Input()
  index: number;

  // 最终提交的
  @Input()
  renter_list: RenterInfo[];

  @Input()
  check_sex: boolean;

  @Input()
  subject_type: string;

  @Output()
  out_renter_with_index: EventEmitter<RenterInfoWithIndex> = new EventEmitter<RenterInfoWithIndex>();

  //入住人表单
  renter_form_group: UntypedFormGroup = this.fb.group({
    renter_info_array: this.fb.array([])
  });

  forbidden:false;

  // 是否提交
  is_submit: boolean = false;

  // 灵活租金弹窗
  // renter_modal: any;

  // 证件类型
  cret_type_list: BaseEnum[];

  // 错误信息
  error_msg: string;

  remove_names: string[] = [];

  // 入住人可编辑列表
  renter_base_info_arr: RenterEditBaseInfo[] = [];

  // 读取身份证定时器
  private read_id_card_interval: any;

  //
  temp_renter_info: ReadIdCardInfo = new ReadIdCardInfo();

  // 退宿模态框需要的变量
  modal_index: number;
  modal_info_id: number;
  modal_name: string;
  modal_cret_num: string;


  //
  wentDutch = false;

  constructor(private fb: UntypedFormBuilder,
              private leaseService: LeaseService,
              private idReaderService: IdReaderService,
              private baseInfoService: BaseInfoService,
              private clientuserService: ClientuserService,
              private authService: AuthService,
              private workService: WorkService) {
  }

  ngOnInit() {
    this.authService.getOperatePrivilege(this.btn_privileges);
    this.baseInfoService.getCretTypeList().then(data => {
      this.cret_type_list = data.base_enum_dto_list;
    });
    this.temp_renter_info.sex = 'MALE';
    this.temp_renter_info.cret_type ='ID_CD';
  }

  // initSomething(modal: any) {
  //   this.renter_modal = modal;
  // }

  //租客弹窗显示
  resetRenterList(forbidden) {
    /*this.forbidden = forbidden;
    this.remove_names = [];
    this.error_msg = null;
    this.is_submit = false;
    this.clearRenterArray();

    this.renter_list.forEach(item => {
      let renter_item = this.createRenterInfo();
      renter_item.patchValue({
        info_id: item.info_id,
        name: item.name,
        phone: item.phone,
        sex: item.sex,
        register_vip: item.register_vip,
        cret_type: item.cret_type ? item.cret_type : '',
        cret_num: item.cret_num,
      });

      this.renter_base_info_arr.push({
        is_empty: !item.disable,
        disable: item.disable,
        status: item.renter_status,
        phone_disable: item.disable,
        is_remove: false,
        is_edit: !item.disable
      });
    });*/
  }

  //入住人弹窗确认
  confirmRenterInfo() {
    this.is_submit = true;
    // 将temp的list的数据同步到最终要提交的list
    /*if(this.renter_list && this.renter_list.length > 0){
      this.renter_list.forEach(item => {
        // 删除
        if(item.info_id && item.operate === 'DELETE'){
          this.renter_list.filter(rent => rent.info_id === item.info_id)[0].operate = 'DELETE'
        }
      });
    }*/
    // 参数校验
    if(this.renter_list){
      this.renter_list.forEach(item => {
        if(!item.name || item.name.length < 2 || item.name.length > 20){
          this.workService.showErrorModal('姓名不能为空, 且长度在2-20字符');
          return;
        }
        if(!item.sex){
          this.workService.showErrorModal("性别不能为空");
          return;
        }
        if(!item.cret_type || item.cret_type == ''){
          this.workService.showErrorModal('请选择证件类型');
          return;
        }
        if(!item.cret_num ||  item.cret_num.length < 6 || item.cret_num.length > 18){
          this.workService.showErrorModal('证件号不为空, 且长度在6-18字符');
          return;
        }
        if(!item.phone || item.phone.length < 8 || item.phone.length > 11){
          this.workService.showErrorModal('手机号不能为空, 且长度在8-11字符');
          return;
        }
      })
    }

    this.out_renter_with_index.emit(new RenterInfoWithIndex(this.index, this.renter_list));
    this.is_submit = false;
    this.hide();
    this.workService.showSuccessModal("入住人信息更新成功！");
  }


  show() {
    this.temp_renter_info = new ReadIdCardInfo();
    this.renter_modal.show();
  }

  hide() {
    this.error_msg = '';
    // this.renter_list = [];
    this.renter_modal.hide();
    clearInterval(this.read_id_card_interval);
  }

  // 身份证扫描
  scanIdCard() {
    let params: MatchLesseeParams = new MatchLesseeParams();

    this.idReaderService.readcardinfo(params);
    setTimeout(() => {
      if (params.cret_num) {
        this.temp_renter_info.cret_num = params.cret_num;
        this.temp_renter_info.sex = params.sex === '男' ? 'MALE' : 'FEMALE';
        this.temp_renter_info.name = params.name;
        this.temp_renter_info.cret_type = 'ID_CD';
      }
    }, 2000);
  }

  // 是否显示错误信息
  isError(is_submit: boolean, container: any, control_name: string, error_code: string): boolean {
    return this.baseInfoService.isError(is_submit, container, control_name, error_code);
  }

  // 添加住户
  addRenter() {
    // 查看空位
    let renterInfos = this.renter_list.filter(item => !item.phone);
    if(!renterInfos || renterInfos.length <= 0){
      this.workService.showErrorModal('入住人数已满');
      return;
    }
    // 校验数据
    if(!this.temp_renter_info.name || this.temp_renter_info.name.length < 2 || this.temp_renter_info.name.length > 20){
      this.workService.showErrorModal('姓名不能为空, 且长度在2-20字符');
      return;
    }
    if(!this.temp_renter_info.cret_type || this.temp_renter_info.cret_type == ''){
      this.workService.showErrorModal('请选择证件类型');
      return;
    }
    if(!this.temp_renter_info.cret_num ||  this.temp_renter_info.cret_num.length < 6 || this.temp_renter_info.cret_num.length > 18){
      this.workService.showErrorModal('证件号不为空, 且长度在6-18字符');
      return;
    }
    if(!this.temp_renter_info.phone || this.temp_renter_info.phone.length < 8 || this.temp_renter_info.phone.length > 11){
      this.workService.showErrorModal('手机号不能为空, 且长度在8-11字符');
      return;
    }
    if(this.renter_list && this.renter_list.length > 0){
      let cret_num_filter = this.renter_list.filter(item => item.cret_num === this.temp_renter_info.cret_num && item.operate != 'DELETE');
      let phone_filter = this.renter_list.filter(item => item.phone === this.temp_renter_info.phone  && item.operate != 'DELETE');
      if(cret_num_filter && cret_num_filter.length > 0){
        this.error_msg = '入住人证件号不能相同';
        return;
      }
      if(phone_filter && phone_filter.length > 0){
        this.error_msg = '入住人手机号码不能相同';
        return;
      }
    }
    if(this.renter_list && this.renter_list.length > 0){
      if(this.subject_type && this.subject_type === 'COMPANY'){
        let sex_filter = this.renter_list.filter(item => (!this.check_sex && item.sex && item.sex !== this.temp_renter_info.sex && item.cret_num && item.operate != 'DELETE'));
        if(sex_filter && sex_filter.length > 0){
          this.error_msg = '不允许不同性别入住';
          return;
        }
      }
    }

    renterInfos[0].cret_num = this.temp_renter_info.cret_num;
    renterInfos[0].cret_num_conceal = this.temp_renter_info.cret_num;
    renterInfos[0].phone = this.temp_renter_info.phone;
    renterInfos[0].tel_conceal = this.temp_renter_info.phone;
    renterInfos[0].name = this.temp_renter_info.name;
    renterInfos[0].cret_type = this.temp_renter_info.cret_type;
    renterInfos[0].sex = this.temp_renter_info.sex;
    renterInfos[0].renter_status = 'WAITING_CHECKIN';
    renterInfos[0].operate = 'ADD';
    renterInfos[0].register_vip = true;
    this.temp_renter_info = new ReadIdCardInfo();
    this.temp_renter_info.cret_type = '';
    this.temp_renter_info.sex = 'MALE';
    this.error_msg = '';
  }

  // 移除租户
  deleteRenter(index: number, infoId: number) {
    if(infoId){
      let renterInfo = this.renter_list.filter(item => item.info_id === infoId)[0];
      renterInfo.operate = 'DELETE';
      this.renter_list.push(renterInfo);
      // 置空原行
      this.checkOutModal.hide();
    }else {
      // this.renter_list.filter(item => {item.phone = this.temp_renter_info.phone})[0].operate = 'DELETE';
      /*let temp_index = this.renter_list.indexOf(renterInfo);
      this.renter_list.splice(temp_index, 1);*/
      this.error_msg = '';
    }
    // 删除等于更换成新对象
    let newRenter = new RenterInfo();
    this.renter_list[index] = newRenter;
  }

  // 退租弹出模态框
  checkOutRenter(index: number, infoId: number, name: string, cret_num: string) {

    // 分摊判断分摊不可以在此退宿 | 2022年5月6日13:46:32
    // if(this.wentDutch) {
    //   this.workService.showErrorModal('该合同有费用分摊，不可在此操作退宿！');
    //   return;
    // }

    this.modal_index = index;
    this.modal_info_id = infoId;
    this.modal_name = name;
    this.modal_cret_num = cret_num;
    this.checkOutModal.show();
  }

  // 根据手机号查找用户并回显
  getSearchClientUser(type: string) {
    if ((this.temp_renter_info.phone && this.temp_renter_info.phone.length >= 8) || (this.temp_renter_info.cret_num && this.temp_renter_info.cret_num.length>= 6)) {
      let request = type && type === 'PHONE' ? {user_phone: this.temp_renter_info.phone} : {cret_num: this.temp_renter_info.cret_num};
      this.clientuserService.pmsCilentDetail(request).then(data => {
        if(data){
          if(data.cret_num_str){
            this.temp_renter_info.cret_num = data.cret_num_str;
          }
          if(data.cret_type){
            this.temp_renter_info.cret_type = data.cret_type;
          }
          this.temp_renter_info.name = data.user_name;
          this.temp_renter_info.phone = data.user_phone;
          this.temp_renter_info.sex = data.user_sex;
        }
      });
    }
  }

  // 判断是否有值, 是否可以添加
  hasRenterData() {
    if(!this.renter_list){
      return false;
    }
    if(!this.temp_renter_info.name || !this.temp_renter_info.sex || !this.temp_renter_info.cret_type || !this.temp_renter_info.cret_num || !this.temp_renter_info.phone){
      return false;
    }
    return true;
  }

  // 判断是否能保存
  canSave() {
    if(this.renter_list){
      return this.renter_list.filter(item => item.phone).length <= 0;
    }
    return true;
  }
}
