<div class="setbox-top">
  <div class="bg-primary ">
    <div class="row">
      <div class="col-12 col-md-6 col-xl-7 hei32">
        <i class="fa fa-info-circle">合同号：{{contract_ext?.record_number}}</i>
      </div>
      <div class="col-12 col-md-6 col-xl-5">
        <p class="text-yellow1 text-right hei32">
          <span class="mr20">合同状态: {{contract_ext?.status | enumTransfer: status_list}}</span>
        </p>
      </div>
    </div>
  </div>
  <div class="setbox contract">
    <div class="frame-content">

      <app-pdf-view [src]="iframe"></app-pdf-view>

    </div>
  </div>
  <div class="contract-fixed">
    <button class="btn btn-info" (click)="printFrame()">打印</button>
    <button class="btn btn-info" (click)="toggleUrl()">{{is_bill ? '查看协议书' : '查看换房单'}}</button>
    <button class="btn btn-secondary" [routerLink]="['/system_manage/signed/pact/' + contract_ext?.lease_id]">返回</button>
  </div>
</div>
