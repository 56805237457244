<ngx-loading-bar [color]="'#383e4b'"></ngx-loading-bar>

<router-outlet></router-outlet>


<div class="loading" [@loading]="loadingTimer>0?'b':'a'">

  <div class="loading-frame">
    <div class="loading-content px-4 py-3">
      <i class="fa fa-spinner fa-pulse"></i> 加载中，请稍后...
    </div>
  </div>

</div>

<div class="full-loading-mask text-center" style="z-index: 1001;" *ngIf="fullLoadingMask">
  <i class="fa fa-spinner fa-pulse fa-3x text-white"></i>
</div>

<app-message-modal #messageModal></app-message-modal>


<app-print-preview></app-print-preview>
<!-- 图片查看 -->
<ngx-image-gallery #imageGallery (onError)="galleryError($event)" [images]="imageGallery.images" [conf]="galleryConf"></ngx-image-gallery>
