<div class="setbox-top">
  <div class="bg-primary ">
    <div class="row">
      <div class="col-12 col-md-6 col-xl-7 hei32">
        <i class="fa fa-info-circle">合同号：{{contract_preview_ext?.contract_num}}</i>
      </div>
      <div class="col-12 col-md-6 col-xl-5">
        <p class="text-yellow1 text-right hei32">
          <span class="mr20">合同状态: {{contract_preview_ext?.business_status | enumTransfer: lease_status_list}}</span>
          <span>审核状态：
              {{contract_preview_ext?.audit_status | enumTransfer: audit_status_list}}
            </span>
        </p>
      </div>
    </div>
  </div>
  <div class="setbox contract">


    <div class="frame-content">

      <app-pdf-view [src]="pdf_url" [temp]="false"></app-pdf-view>

    </div>
  </div>
  <div class="contract-fixed">
    <button class="btn btn-info" (click)="printFrame()">打印</button>
    <!--<button class="btn btn-blue">-->
    <!--<a (click)="downloadPDF()">下载PDF</a>-->
    <!--</button>-->
    <button class="btn btn-secondary" (click)="back()">返回</button>
    <button class="btn btn-secondary" (click)="delete()"
            *ngIf="contract_preview_ext && contract_preview_ext.business_status == 'EFFECTIVE'
            && contract_preview_ext.contract_effective_type == 'UPLOAD_CONTRACT'">删除</button>
  </div>
</div>
