import { Pipe, PipeTransform } from "@angular/core";

/**
 * @Description: 预定单/租约审核状态管道
 * @Author: 周嘉豪
 * @Date: 2018/3/29 上午10:26
 * @Last Modified by: 周嘉豪
 * @Last Modified time: 2018/3/29 上午10:26
 */
@Pipe({
  name: 'leaseAuditPipe'
})
export class LeaseAuditPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const status = {
      WAITING_AUDIT: '待审核',
      AUDIT_SUCCESS: '审核成功',
      AUDIT_FAIL: '审核失败',
      NO_AUDIT: '无需审核'
    };
    return status[value];
  }
}
