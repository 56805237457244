
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RequestService } from '../request.service';
import { AuditInfos } from '../../model/lease/audit.model';

@Injectable()
export class ImageHandleService {

  private LEASE_IMAGE_HANDLE = `${environment.apiHost}image/lease/handle`;

  constructor(private requestService: RequestService) {
  }

  /**
   * 租约图片处理
   * @param {number} leaseId
   * @returns {Promise<AuditInfos>}
   */
  public leaseImageHandle(data: any): Promise<any> {
    return this.requestService.authPost(this.LEASE_IMAGE_HANDLE, data);
  }

}
