import { Pipe, PipeTransform } from '@angular/core';

/**
 * @Description: 租约日志内容管道
 * @Author: 田广野
 * @Date: 2018/3/30 上午11:53
 * @Last Modified by: 田广野
 * @Last Modified time: 2018/3/30 上午11:53
 */
@Pipe({
  name: 'leaseLogContent'
})
export class LeaseLogContent implements PipeTransform {

  transform(value: any, args?: any): any {
    const status = {
      TENANT_SIGNING: '租客签约',
      TENANT_RESERVATION: '租客预定',
      LEASE_REVIEW: '租约审核',
      CONTINUING_REVIEW: '续租审核',
      CHECK_OUT_REVIEW: '退租审核',
    };
    return status[value];
  }
}
