<!-- login pms -->
<div class="login" *ngIf="platformCode !== 'LEQIDA'">

  <div class="wrapper wrapper-login wrapper-login-full p-0">
    <div
      class="login-aside w-50 d-flex flex-column align-items-center justify-content-center text-center bg-secondary-gradient">

      <img class="left-bg show2" src="../../../../assets/img/v3/shang_lxz.png">
      <img class="left-bg-bottom show2" src="../../../../assets/img/v3/xia_2020.png">
      <img class="left-bg show1" src="../../../../assets/img/v3/shang_lxz_phone.png">

      <!--<h1 class="title fw-bold text-white mb-3">欢迎使用OpenPMS</h1>-->
      <!--<p class="subtitle text-white op-7">公寓管理好帮手</p>-->

      <!--<span id="siteseal"></span>-->

    </div>

    <div class="login-aside d-flex align-items-center justify-content-center bg-white w-50">
      <div class="container container-login container-transparent">
        <div class="text-center"><img class="login-logo" src="../../../../assets/img/v3/login_lxz.png" alt=""></div>
        <!--<h2 class="text-center mt-2">云盾宿管</h2>-->
        <!--<div class="text-center">乐璟生活社区</div>-->
        <div class="login-form col-12 mt-5" *ngIf="!showCode&&!has_login">

          <div class="row">
            <div class="w-100">
              <div class="form-group">
                <label>用户名</label>
                <input class="form-control"
                       type="text"
                       [(ngModel)]="login.userAccount"
                       (input)="check(false)"
                       #account=ngModel
                       autofocus
                       maxlength="11"
                       tabindex="1"
                >
              </div>
            </div>

            <div class="col-12" *ngIf="codeShow">
              <div class="row">
                <div class="w-75">
                  <div class="form-group">
                    <label>验证码</label>
                    <input class="form-control"
                           #imgCode=ngModel
                           [(ngModel)]="login.imgCode"
                           (input)="check(false)"
                           placeholder="请输入图形验证码"
                           tabindex="2"
                    >
                  </div>
                </div>

                <div class="w-25 text-right">
                  <img class="text-center mess-img" (click)="imgCodeClick()" src="{{imgUrl}}">
                </div>
              </div>
            </div>

            <div class="w-100">
              <div class="form-group">
                <label>密码</label>
                <input class="form-control"
                       type="password"
                       [(ngModel)]="login.userPassword"
                       (input)="check(false);"
                       (keyup.enter)="userLogin()"
                       #password=ngModel
                       tabindex="3"
                >
                <button class="btn btn-link text-info" [routerLink]="['../reset_password']"
                        [queryParams]="{system_type: platformCode}">忘记密码？
                </button>
              </div>
            </div>

            <div class="w-100 text-center">
              <div *ngIf="errorContent != null && errorContent !== '' "
                   class="text-danger">
                {{errorContent}}
              </div>
            </div>
          </div>

          <div class="col-12 text-center mt-2" *ngIf="!wechatflag">
            <button class="btn btn-info w-75 rounded-pill login"
                    [ngStyle]="{'letter-spacing':loginLoading ? '0' : '1.5em', 'text-indent':loginLoading ? '0' : '1.5em'}"
                    (click)="userLogin()" tabindex="4">
              <img src="/assets/img/loading.gif" style="width: 1.5em" alt=""
                   *ngIf="loginLoading">{{loginLoading ? '登录中...' : '登录'}}
            </button>
          </div>
          <div class="col-12 text-center mt-2" *ngIf="wechatflag">
            <button class="btn btn-info w-75 rounded-pill login"
                    (click)="backLogin()" tabindex="4">登录
            </button>
          </div>

          <!--<div class="w-100 mt-5 text-center">-->
          <!--<a class="btn btn-link text-info" (click)="userLogin()">如发生错误，点此登入备用线路</a>-->
          <!--</div>-->

        </div>
        <div class="login-form col-12 mt-5 code" *ngIf="is_need_sms_check">
          <div>
            <h3 class="title">安全验证</h3>
            <div class="col-12">
              <div class="row">
                <div class="w-75">
                  <div class="form-group">
                    <label>&nbsp;</label>
                    <p style="text-align: left" class="text4">手机号</p>
                    <p style="text-align: left" class="text3">{{user_phone}}</p>
                  </div>
                </div>

                <div class="w-25 text-right">

                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="w-75">
                  <div class="form-group">
                    <label>&nbsp;</label>
                    <p style="text-align: left" class="text4">短信验证码</p>
                    <input class="form-control"
                           [(ngModel)]="loginSecondCheckRequest.sms_code"
                           tabindex="3" placeholder="短信验证码"
                    >
                  </div>
                </div>

                <div class="w-25 text-right">
                  <label class="w-100">&nbsp;</label>
                  <button style="margin-top: 1.8em" class="btn btn-info"
                          *ngIf="buttonShow"
                          type="button"
                          (click)="sendCodeRequest('loginCheckCode')"
                  >获取验证码
                  </button>
                  <button class="btn btn-info"
                          style="margin-top: 1.8em"
                          *ngIf="!buttonShow"
                          readonly
                          type="button"
                          [disabled]="!buttonShow"
                  >{{clock}}s后获取
                  </button>

                </div>
              </div>
            </div>
            <div>
              <input checked="" class="mr5" type="checkbox" [(ngModel)]="is_select_need_check">
              <label class="text5">30天</label><label>内该设备下该账号每天登陆不需要验证码</label>
            </div>
            <div class="w-100 text-center">
              <div *ngIf="errorCodeContent != null && errorCodeContent !== '' "
                   class="text-danger">
                {{errorCodeContent}}
              </div>
            </div>
            <div class="col-12 text-center mt-2">
              <button class="btn btn-info w-75 rounded-pill login"
                      (click)="checkSmsCode()"
                      tabindex="6"
              >确认
              </button>
            </div>
          </div>
        </div>
        <div class="login-form col-12 mt-5 code" *ngIf="showCode">
          <div>
            <p class="title">账户绑定</p>
            <div class="mt20">
              <img src="../../../../assets/img/u3530.jpg" alt="">
            </div>
            <p class="text1">打开微信扫一扫</p>
            <p class="text1">绑定乐享住服务号后即可享受消息推送服务哦</p>
          </div>
        </div>
        <div *ngIf="showCode&&!has_login" class="tis">
          <p class="text2">扫码关注微信服务号/已关注回复【绑定】→点击立即绑定→登录账户→绑定完成</p>
          <!-- <button class="btn btn-link text-info" (click)="showCode = false">返回登录</button> -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- login leqida -->
<div class="login lqd-login" *ngIf="platformCode === 'LEQIDA'">

  <div class="wrapper wrapper-login wrapper-login-full p-0 m-0">
    <div class="login-aside w-50 d-flex flex-column align-items-center justify-content-center text-center">

      <img class="left-bg-lqd show2" src="../../../../assets/img/v3/lqd/llgg.png">
      <div class="text-left text-black mt-2 desc show2">
        <p>即刻享用</p>
        <p>全国超过5万个床位供您挑选；</p>
        <p>住宿成本分析、员工住宿情况随时掌控；</p>
        <p>智能的住宿管理软件，提升自有宿舍满意度；</p>
        <p>专业的人力资源服务，快速满足您的各类招聘需求；</p>
        <p>专属定制的员工餐饮和班车服务。</p>
      </div>

      <div class="text-center mt-0 mt-sm-5 show1">
        <!--        <img class="login-logo" src="../../../../assets/img/v3/leqida_logo@2x.png" alt="">-->
      </div>


    </div>

    <div class="login-aside d-flex align-items-center justify-content-center w-50">
      <div class="overflow-hidden boxs1 rounded container container-login container-transparent bg-white">
        <div class="">
          <div class="position-relative lqd-reg">
            <div class="triangle"></div>
            <div class="text" (click)="goReg()">注册</div>
          </div>
        </div>

        <div class="text-center">

          <div class="text-center mt-5 show1 d-inline">
            <img class="login-logo pb-3" src="../../../../assets/img/v3/leqida_logo@2x.png" alt="">
          </div>

          <div class="">
            <tabset class="lqd-login-tab">
              <tab heading="短信登录" id="tab1" (selectTab)="onSelectTab('CHECK_CODE')">
                <div class="login-form col-12 mt-5" *ngIf="!showCode&&!has_login">

                  <div class="row">
                    <div class="w-100">
                      <div class="form-group">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="login.userAccount"
                               #account=ngModel
                               autofocus
                               maxlength="11"
                               tabindex="1"
                               placeholder="请输入账号"
                        >
                      </div>
                    </div>

                    <div class="w-100">
                      <div class="form-group">
                        <div class="input-group mb-3">
                          <input class="form-control"
                                 type="text"
                                 [(ngModel)]="login.loginCheckCode"
                                 (keyup.enter)="userLogin()"
                                 #password=ngModel
                                 tabindex="2"
                                 placeholder="请输入验证码"
                          >
                          <div class="input-group-prepend ml-2">
                            <button class="btn btn-info m-0 border-r4" type="button" *ngIf="buttonShow"
                                    (click)="sendCodeRequest('leQiDaLoginCode')">获取验证码
                            </button>
                            <button class="btn btn-info m-0 border-r4" type="button" *ngIf="!buttonShow"
                                    [disabled]="!buttonShow" readonly>{{clock}}s后获取
                            </button>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="w-100 text-center">
                      <div *ngIf="errorContent != null && errorContent !== '' "
                           class="text-danger">
                        {{errorContent}}
                      </div>
                    </div>
                  </div>

                  <!--<div class="col-12 text-center mt-2" *ngIf="platformCode !== 'LEQIDA' && !wechatflag">
                    <button class="btn btn-info w-75 rounded-pill login"
                            [ngStyle]="{'letter-spacing':loginLoading ? '0' : '1.5em', 'text-indent':loginLoading ? '0' : '1.5em'}"
                            (click)="userLogin()" tabindex="4">
                      <img src="/assets/img/loading.gif" style="width: 1.5em" alt=""
                           *ngIf="loginLoading">{{loginLoading ? '登录中...' : '登录'}}
                    </button>
                  </div>-->
                  <div class="col-12 text-center mt-2" *ngIf="wechatflag">
                    <button class="btn btn-info w-75 rounded-pill login"
                            (click)="backLogin()" tabindex="4">登录
                    </button>
                  </div>
                  <div class="row text-center mt-2" *ngIf="!wechatflag">
                    <button class="btn btn-info w-100 login_lqd ml-0"
                            (click)="userLogin()" tabindex="4">登录
                    </button>
                    <button class="btn btn-link text-green1 pl-0" [routerLink]="['../reset_password']"
                            [queryParams]="{system_type: platformCode}">忘记密码？
                    </button>
                  </div>

                  <!--<div class="w-100 mt-5 text-center">-->
                  <!--<a class="btn btn-link text-info" (click)="userLogin()">如发生错误，点此登入备用线路</a>-->
                  <!--</div>-->

                </div>
                <div class="login-form col-12 mt-5 code" *ngIf="is_need_sms_check">
                  <div>
                    <h3 class="title">安全验证</h3>
                    <div class="col-12">
                      <div class="row">
                        <div class="w-75">
                          <div class="form-group">
                            <label>&nbsp;</label>
                            <p style="text-align: left" class="text4">手机号</p>
                            <p style="text-align: left" class="text3">{{user_phone}}</p>
                          </div>
                        </div>

                        <div class="w-25 text-right">

                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="w-75">
                          <div class="form-group">
                            <label>&nbsp;</label>
                            <p style="text-align: left" class="text4">短信验证码</p>
                            <input class="form-control"
                                   [(ngModel)]="loginSecondCheckRequest.sms_code"
                                   tabindex="3" placeholder="短信验证码"
                            >
                          </div>
                        </div>

                        <div class="w-25 text-right">
                          <label class="w-100">&nbsp;</label>
                          <button style="margin-top: 1.8em" class="btn btn-info"
                                  *ngIf="buttonShow"
                                  type="button"
                                  (click)="sendCodeRequest('loginCheckCode')"
                          >获取验证码
                          </button>
                          <button class="btn btn-info"
                                  style="margin-top: 1.8em"
                                  *ngIf="!buttonShow"
                                  readonly
                                  type="button"
                                  [disabled]="!buttonShow"
                          >{{clock}}s后获取
                          </button>

                        </div>
                      </div>
                    </div>
                    <div>
                      <input checked="" class="mr5" type="checkbox" [(ngModel)]="is_select_need_check">
                      <label class="text5">30天</label><label>内该设备下该账号每天登陆不需要验证码</label>
                    </div>
                    <div class="w-100 text-center">
                      <div *ngIf="errorCodeContent != null && errorCodeContent !== '' "
                           class="text-danger">
                        {{errorCodeContent}}
                      </div>
                    </div>
                    <div class="col-12 text-center mt-2">
                      <button class="btn btn-info w-75 rounded-pill login"
                              (click)="checkSmsCode()"
                              tabindex="6"
                      >确认
                      </button>
                    </div>
                  </div>
                </div>
                <div class="login-form col-12 mt-5 code" *ngIf="showCode">
                  <div>
                    <p class="title">账户绑定</p>
                    <div class="mt20">
                      <img src="../../../../assets/img/leqidawechat.jpg" alt="">
                    </div>
                    <p class="text1">打开微信扫一扫</p>
                    <p class="text1">绑定乐企达服务号后即可享受消息推送服务哦</p>
                  </div>
                </div>
              </tab>
              <tab heading="密码登录" active="true" (selectTab)="onSelectTab('PASSWORD')">
                <div class="login-form col-12 mt-5" *ngIf="!showCode&&!has_login">

                  <div class="row">
                    <div class="w-100">
                      <div class="form-group">
                        <input class="form-control"
                               type="text"
                               [(ngModel)]="login.userAccount"
                               (input)="check(false)"
                               #account=ngModel
                               autofocus
                               maxlength="11"
                               tabindex="1"
                               placeholder="请输入账号"
                        >
                      </div>
                    </div>

                    <div class="col-12" *ngIf="codeShow">
                      <div class="row">
                        <div class="w-75">
                          <div class="form-group">
                            <label>&nbsp;</label>
                            <input class="form-control"
                                   #imgCode=ngModel
                                   [(ngModel)]="login.imgCode"
                                   (input)="check(false)"
                                   placeholder="请输入图形验证码"
                                   tabindex="2"
                            >
                          </div>
                        </div>

                        <div class="w-25 text-right">
                          <img class="text-center mess-img" (click)="imgCodeClick()" src="{{imgUrl}}">
                        </div>
                      </div>
                    </div>

                    <div class="w-100">
                      <div class="form-group">
                        <input class="form-control"
                               type="password"
                               [(ngModel)]="login.userPassword"
                               (input)="check(false);"
                               (keyup.enter)="userLogin()"
                               #password=ngModel
                               tabindex="3"
                               placeholder="请输入密码"
                        >

                      </div>
                    </div>

                    <div class="w-100 text-center">
                      <div *ngIf="errorContent != null && errorContent !== '' "
                           class="text-danger">
                        {{errorContent}}
                      </div>
                    </div>
                  </div>

                  <!--<div class="col-12 text-center mt-2" *ngIf="platformCode !== 'LEQIDA' && !wechatflag">
                    <button class="btn btn-info w-75 rounded-pill login"
                            [ngStyle]="{'letter-spacing':loginLoading ? '0' : '1.5em', 'text-indent':loginLoading ? '0' : '1.5em'}"
                            (click)="userLogin()" tabindex="4">
                      <img src="/assets/img/loading.gif" style="width: 1.5em" alt=""
                           *ngIf="loginLoading">{{loginLoading ? '登录中...' : '登录'}}
                    </button>
                  </div>-->
                  <div class="col-12 text-center mt-2" *ngIf="wechatflag">
                    <button class="btn btn-info w-75 rounded-pill login"
                            (click)="backLogin()" tabindex="4">登录
                    </button>
                  </div>
                  <div class="row text-center mt-2" *ngIf="!wechatflag">
                    <button class="btn btn-info w-100 login_lqd ml-0"
                            (click)="userLogin()" tabindex="4">登录
                    </button>
                    <button class="btn btn-link text-green1 pl-0" [routerLink]="['../reset_password']"
                            [queryParams]="{system_type: platformCode}">忘记密码？
                    </button>
                  </div>

                  <!--<div class="w-100 mt-5 text-center">-->
                  <!--<a class="btn btn-link text-info" (click)="userLogin()">如发生错误，点此登入备用线路</a>-->
                  <!--</div>-->

                </div>
                <div class="login-form col-12 mt-5 code" *ngIf="is_need_sms_check">
                  <div>
                    <h3 class="title">安全验证</h3>
                    <div class="col-12">
                      <div class="row">
                        <div class="w-75">
                          <div class="form-group">
                            <label>&nbsp;</label>
                            <p style="text-align: left" class="text4">手机号</p>
                            <p style="text-align: left" class="text3">{{user_phone}}</p>
                          </div>
                        </div>

                        <div class="w-25 text-right">

                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="w-75">
                          <div class="form-group">
                            <label>&nbsp;</label>
                            <p style="text-align: left" class="text4">短信验证码</p>
                            <input class="form-control"
                                   [(ngModel)]="loginSecondCheckRequest.sms_code"
                                   tabindex="3" placeholder="短信验证码"
                            >
                          </div>
                        </div>

                        <div class="w-25 text-right">
                          <label class="w-100">&nbsp;</label>
                          <button style="margin-top: 1.8em" class="btn btn-info"
                                  *ngIf="buttonShow"
                                  type="button"
                                  (click)="sendCodeRequest('loginCheckCode')"
                          >获取验证码
                          </button>
                          <button class="btn btn-info"
                                  style="margin-top: 1.8em"
                                  *ngIf="!buttonShow"
                                  readonly
                                  type="button"
                                  [disabled]="!buttonShow"
                          >{{clock}}s后获取
                          </button>

                        </div>
                      </div>
                    </div>
                    <div>
                      <input checked="" class="mr5" type="checkbox" [(ngModel)]="is_select_need_check">
                      <label class="text5">30天</label><label>内该设备下该账号每天登陆不需要验证码</label>
                    </div>
                    <div class="w-100 text-center">
                      <div *ngIf="errorCodeContent != null && errorCodeContent !== '' "
                           class="text-danger">
                        {{errorCodeContent}}
                      </div>
                    </div>
                    <div class="col-12 text-center mt-2">
                      <button class="btn btn-info w-75 rounded-pill login"
                              (click)="checkSmsCode()"
                              tabindex="6"
                      >确认
                      </button>
                    </div>
                  </div>
                </div>
                <div class="login-form col-12 mt-5 code" *ngIf="showCode">
                  <div>
                    <p class="title">账户绑定</p>
                    <div class="mt20">
                      <img src="../../../../assets/img/leqidawechat.jpg" alt="">
                    </div>
                    <p class="text1">打开微信扫一扫</p>
                    <p class="text1">绑定乐企达服务号后即可享受消息推送服务哦</p>
                  </div>
                </div>
              </tab>
            </tabset>
          </div>


          <div class="row bottom mt-3 justify-content-center">
            <span class="text-gray-300 font-s">登录即表示您同意</span>
            <a class="btn btn-link p-0 text-gray-300 font-s" href="https://m.leqida.com.cn/service-agreement.html"
               target="_blank">《乐企达服务协议》</a>
            <a class="btn btn-link p-0 text-gray-300 font-s" href="https://m.leqida.com.cn/privacy-policy.html"
               target="_blank">《隐私权政策》</a>
          </div>

          <div *ngIf="showCode&&!has_login" class="tis">
            <p class="text2">扫码关注微信服务号/已关注回复【绑定】→点击立即绑定→登录账户→绑定完成</p>
            <!-- <button class="btn btn-link text-info" (click)="showCode = false">返回登录</button> -->
          </div>

        </div>
      </div>
    </div>
  </div>

</div>


<app-safety #safetyComponent (loginOp)="_loginOp()"></app-safety>
