import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RequestService } from '../request.service';
import {CompanyPrepayRechargeRequest} from "../../model/prepay/company-prepay.model";

/**
 * @Description: 企业预付账户服务
 * @Author: 徐礼华
 * @Date: 2019年7月17日16:18:22
 * @Last Modified by: ky
 * @Last Modified time: 2019年7月17日16:18:18
 */
@Injectable()
export class CompanyPrepayService {

  //  预付账户详情
  private PREPAY_ACCOUNT_DETAIL = `${environment.apiHost}companyprepay/account/detail`;

  // 账户充值
  private PREPAY_ACCOUNT_RECHARGE = `${environment.apiHost}companyprepay/account/recharge`;

  //  账户提现
  private PREPAY_ACCOUNT_WITHDRAW = `${environment.apiHost}companyprepay/account/withdraw`;

  // 预付账户现金充值生成 code
  private ACCOUNT_RECHARGE_CODE = `${environment.apiHost}companyprepay/account/recharge/cash/code`;

  constructor(private requestService: RequestService) {
  }

  /**
   * 预付账户详情
   */
  public accountDetail(requestData: any): Promise<any> {
    return this.requestService.authGet(this.PREPAY_ACCOUNT_DETAIL, requestData);
  }

  /**
   *  账户充值
   */
  public accountRecharge(requestData: CompanyPrepayRechargeRequest): Promise<any> {
    return this.requestService.authPost(this.PREPAY_ACCOUNT_RECHARGE, requestData);
  }

  /**
   * 账户提现
   */
  public accountWithdraw(requestData: any): Promise<any> {
    return this.requestService.authPost(this.PREPAY_ACCOUNT_WITHDRAW, requestData);
  }

  /**
   * 预付账户现金充值生成CODE
   * @param requestData
   */
  public accountRechargeCode(requestData: CompanyPrepayRechargeRequest): Promise<any> {
    return this.requestService.authPost(this.ACCOUNT_RECHARGE_CODE, requestData);
  }



}
