<div class="modal fade" bsModal #room_change_confirm_modal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name" (click)="stopP($event)">
  <div class="modal-dialog modal-primary modal-lg" *ngIf="origin_lease_room_info">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>换房设置</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="!is_internal_lease">
          <div>
            原房间：
          </div>

          <div class="over10">
            <table class="table table-bordered table-striped">
              <thead>
              <tr>
                <th class="w-125">原房间号</th>
                <th class="w-25">月均租金</th>
                <th class="w-125">押金</th>
                <th class="w-25">入住人</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  {{origin_lease_room_info.room_num}}
                </td>
                <td>
                  {{origin_lease_room_info.month_rent}}
                </td>
                <td>
                  {{origin_lease_room_info.room_deposit}}
                </td>
                <td>
                  {{renter_name_list_str}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div>
            新房间：
          </div>

          <div class="over10">
            <table class="table table-bordered table-striped">
              <thead>
              <tr>
                <th class="w-125">新房间号</th>
                <th class="w-25">月均租金</th>
                <th class="w-125">押金</th>
                <th class="w-25">入住人</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  {{new_room_info.room_name}}
                </td>
                <td>
                  <div class="col-12">
                    <select class="dw100 mr10" [(ngModel)]="apply_req.new_room_info.rent_type">
                      <option value="NORMAL">每月固定</option>
                      <option value="MULTI">灵活租金</option>
                    </select>

                    <ng-container *ngIf="apply_req.new_room_info.rent_type === 'NORMAL'">
                      <input type="text" class="dw100"
                             [placeholder]="new_room_info.base_price!=null?(new_room_info.base_price+'元/月'):'输入固定租金'"
                             [(ngModel)]="apply_req.new_room_info.month_rent"
                             autocomplete="false">
                      <span class="text-danger w-100" *ngIf="month_rent_error === 'required'">
                          租金不能为空
                        </span>
                      <span class="text-danger w-100" *ngIf="month_rent_error === 'formatError'">
                          格式错误(0-200000的数字，支持小数点后两位)
                        </span>
                    </ng-container>

                    <ng-container *ngIf="apply_req.new_room_info.rent_type === 'MULTI'">
                        <span class="text-primary pointer" (click)="customRentModalShow(true)">
                          设置每月租金
                          <i class="fa fa-exclamation text-dark" style="cursor:help" *ngIf="custom_rent_list_filled"
                             title="已设置过每月租金">
                          </i>
                        </span>
                      <span class="text-danger w-100" *ngIf="month_rent_error === 'required'">
                          租金不能为空
                        </span>
                    </ng-container>
                  </div>
                </td>
                <td>
                  <input class="w-100" type="text" [(ngModel)]="apply_req.new_room_info.room_deposit" autocomplete="false">
                  <span class="text-danger w-100" *ngIf="room_deposit_error === 'required'">
                        不能为空
                  </span>
                  <span class="text-danger w-100" *ngIf="room_deposit_error === 'formatError'">
                        格式错误(0-200000之前的数字，且只支持小数点后两位)
                  </span>
                </td>
                <td>
                  {{renter_name_list_str}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          申请类型：
        </div>
        <div>
          <div class="form-check w140px">
            <input type="radio" class="form-check-input hei2532" id="exampleCheck1"
                   [(ngModel)]="apply_req.apply_type" value="RENTER_APPLY" [checked]="apply_req.apply_type=='RENTER_APPLY'">
            <label class="form-check-label hei32" for="exampleCheck1">房客申请</label>
          </div>
          <div class="form-check w140px">
            <input type="radio" class="form-check-input hei2532" id="exampleCheck2"
                   [(ngModel)]="apply_req.apply_type" value="HOTEL_APPLY"  [checked]="apply_req.apply_type=='HOTEL_APPLY'">
            <label class="form-check-label hei32" for="exampleCheck2">公寓申请</label>
          </div>
          <div class="w300px ml-1" *ngIf="apply_req.apply_type === 'HOTEL_APPLY'">
            <input class="w-100" type="text" placeholder="请输入公寓申请的换房原因，限50字" maxlength="50" [(ngModel)]="apply_req.reason">

            <div class="text-danger" *ngIf="apply_reason_error === 'required'">换房原因不能为空</div>
            <div class="text-danger" *ngIf="apply_reason_error === 'formatError'">换房原因不能超过50个字</div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <div class="text-center mb20">
          <button type="button" class="btn btn-primary mr20" (click)="doConfirm()" *ngIf="!is_internal_lease">确定
          </button>
          <button type="button" class="btn btn-primary mr20" (click)="directSubmit()" *ngIf="is_internal_lease">确定
          </button>
          <button type="button" (click)="goBack()" class="btn btn-secondary">返回</button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-custom-rent-modal #custom_rent_modal (out_custom_rent_with_index)="confirmCustomRent($event)"></app-custom-rent-modal>
