<!-- {{initSomething(renter_modal)}} -->

<!-- 设置入住人 -->
<div class="modal fade" bsModal #renter_modal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
       aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-primary modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>设置入住人
            <span class="text-danger" *ngIf="subject_type === 'COMPANY'">（此房间{{check_sex ? '允许' : '不允许'}}不同性别入住）</span>
          </h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
            <span><i class="icon icon-close" aria-hidden="true"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="con-body">
            <div class="mb-2">
              <button class="btn btn-primary" (click)="scanIdCard()">读取证件</button>
            </div>
            <div class="mb-2">
              <div class="d-inline-block mr-1">
                <input type="text" class="w-100" placeholder="姓名" [(ngModel)]="temp_renter_info.name">
              </div>
              <div class="d-inline-block mr-1">
                <select class="w-100" [(ngModel)]="temp_renter_info.sex">
                  <option value="MALE" >男</option>
                  <option value="FEMALE">女</option>
                </select>
              </div>
              <div class="d-inline-block mr-1">
                <select class="w-100" [(ngModel)]="temp_renter_info.cret_type">
                  <option value="">证件类型</option>
                  <option *ngFor="let cret_type of cret_type_list" [value]="cret_type.code">
                    {{cret_type.desc}}
                  </option>
                </select>
              </div>
              <div class="d-inline-block mr-1">
                <input type="text" class="w-100" placeholder="证件号码" [(ngModel)]="temp_renter_info.cret_num" (input)="getSearchClientUser('CRET_NUM')" [maxLength]="18">
              </div>
              <div class="d-inline-block mr-1">
                <input type="text" class="w-100" placeholder="手机号码" [(ngModel)]="temp_renter_info.phone" (input)="getSearchClientUser('PHONE')" [maxLength]="11">
              </div>
              <div class="d-inline-block ml-2">
                <button class="btn btn-primary" (click)="addRenter()" *ngIf="hasRenterData()">添加</button>
                <button class="btn btn-primary" *ngIf="!hasRenterData()" disabled>添加</button>
              </div>
            </div>
            <div class="mb-2">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th>序号</th>
                  <th>姓名</th>
                  <th>性别</th>
                  <th>手机号码</th>
                  <th>证件类型</th>
                  <th>证件号码</th>
                  <th>状态</th>
                  <th>操作</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let item of renter_list; let i = index;">
                  <tr *ngIf="item.operate !== 'DELETE'">
                      <td>{{i+1}}</td>
                      <td>{{item?.name}}</td>
                      <td>{{item?.sex ? (item.sex === 'MALE' ? '男' : '女') : ''}}</td>
                      <td>
                        <ng-container *ngIf="btn_privileges?.QUERY_USER_PRIVAYE">
                          {{item.phone}}
                        </ng-container>
                        <ng-container *ngIf="!btn_privileges?.QUERY_USER_PRIVAYE">
                          {{item.tel_conceal}}
                        </ng-container>
                      </td>
                      <td>{{item?.cret_type ? (item.cret_type === 'ID_CD' ? '身份证' : '护照') : ''}}</td>
                      <td>
                        <ng-container *ngIf="btn_privileges?.QUERY_USER_PRIVAYE">
                          {{item.cret_num}}
                        </ng-container>
                        <ng-container *ngIf="!btn_privileges?.QUERY_USER_PRIVAYE">
                          {{item.cret_num_conceal}}
                        </ng-container>
                      </td>
                      <td>{{item?.renter_status === 'CHECKIN' ? '已入住' : (item.renter_status === 'WAITING_CHECKIN' ? '待入住' : '')}}</td>
                      <td>
                        <button class="btn btn-default b-blue" *ngIf="item?.renter_status === 'WAITING_CHECKIN'" (click)="deleteRenter(i, item.info_id)">移除</button>
                        <button class="btn btn-default b-blue" *ngIf="item?.renter_status === 'CHECKIN'" (click)="checkOutRenter(i, item.info_id, item.name, item.cret_num)">退宿</button>
                        <button class="btn btn-default" *ngIf="!item?.phone" disabled>移除</button>
                      </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </div>
            <div class="mt-2" *ngIf="subject_type === 'PERSON'">
              <label>
                <input type="checkbox" checked> 为所有入住人开通乐享住APP账号并绑定合同
              </label>
            </div>
            <!--<ng-container *ngFor="let renter_info_group of renterInfoArray.controls; let h=index;" [formGroupName]="h">

              <div class="form-check trans mb10 hei32">
                <p class="hei32 d-inline mr-5">入住人{{h + 1}}：</p>

                <input class="form-check-input" type="checkbox" formControlName="register_vip"
                       [attr.disabled]="renter_base_info_arr[h].disable? true: null">
                <label class="form-check-label hei32">注册会员并绑定合同</label>

                <ng-container *ngIf="renter_base_info_arr[h].is_empty">
                  <button class="btn-link font-lg mr-3" (click)="scanIdCard(h)">
                    读取
                  </button>
                </ng-container>

                <ng-container *ngIf="!renter_base_info_arr[h].is_empty">
                  <button class="btn-link font-lg mr-3" (click)="editRenter(h, 'all')"
                          *ngIf="renter_base_info_arr[h].status === 'WAITING_CHECKIN'">
                    修改
                  </button>

                  <ng-container
                    *ngIf="renter_base_info_arr[h].status === 'CHECKIN' && !renter_base_info_arr[h].is_remove">
                    &lt;!&ndash;<button class="btn-link font-lg mr-3" (click)="editRenter(h, 'phone')">&ndash;&gt;
                    &lt;!&ndash;修改手机&ndash;&gt;
                    &lt;!&ndash;</button>&ndash;&gt;

                    <button class="btn-link font-lg mr-3" (click)="removeRenter(h)">
                      移除
                    </button>
                  </ng-container>
                </ng-container>
              </div>

              <div class="row justify-content-center">
                <div class="col-11 mb10 ">
                  <div class="list-1  mr-3">
                    <input type="text" class="w-100" [ngClass]="{'bg-gray':renter_base_info_arr[h].disable}"
                           [attr.disabled]="renter_base_info_arr[h].disable || (forbidden && renter_info_group.get('info_id')?.value)? true: null"
                           placeholder="姓名" formControlName="name">
                    <p class="text-danger" *ngIf="isError(is_submit, renter_info_group, 'name', 'formatError')">
                      长度请控制在2-20字符内
                    </p>
                  </div>

                  <div class="list-2 mr-3">
                    <select class="w-100" [ngClass]="{'bg-gray':renter_base_info_arr[h].disable}"
                            [attr.disabled]="renter_base_info_arr[h].disable || (forbidden && renter_info_group.get('info_id')?.value)? true: null"
                            formControlName="sex">
                      <option value="MALE">男</option>
                      <option value="FEMALE">女</option>
                    </select>
                  </div>

                  <div class="list-33 mr-3">
                    <input type="text" [ngClass]="{'bg-gray':renter_base_info_arr[h].phone_disable}"
                           [attr.disabled]="renter_base_info_arr[h].disable || (forbidden && renter_info_group.get('info_id')?.value)? true: null"
                           class="w-100" placeholder="手机" formControlName="phone"
                           (input)="getSearchClientUser($event,h,'PHONE',renter_base_info_arr[h].disable)">
                    <p class="text-danger" *ngIf="isError(is_submit, renter_info_group, 'phone', 'formatError')">
                      请输入合法手机号
                    </p>
                    <p class="text-danger"
                       *ngIf="isError(is_submit, renter_info_group, 'phone', 'registerVipPhoneRequired')">
                      注册会员必须填写手机号
                    </p>
                  </div>

                  <div class="list-33 mr-3">
                    <select class="w-100" [ngClass]="{'bg-gray':renter_base_info_arr[h].disable}"
                            [attr.disabled]="renter_base_info_arr[h].disable || (forbidden && renter_info_group.get('info_id')?.value)? true: null"
                            formControlName="cret_type" #renterCretType (change)="cretTypeChange(h)">
                      <option value="">证件类型</option>
                      <option *ngFor="let cret_type of cret_type_list" [value]="cret_type.code">
                        {{cret_type.desc}}
                      </option>
                    </select>
                    <p class="text-danger" *ngIf="isError(is_submit, renter_info_group, 'cret_type', 'required')">
                      请选择证件类型
                    </p>
                  </div>

                  <div class="list-44 mr-3">
                    <input type="text" class="w-100" [ngClass]="{'bg-gray':renter_base_info_arr[h].disable}"
                           [attr.disabled]="renter_base_info_arr[h].disable || (forbidden && renter_info_group.get('info_id')?.value)? true: null"
                           placeholder="证件号" formControlName="cret_num"
                           (input)="getSearchClientUser($event,h,'CRET_NUM',false)">
                    <p class="text-danger" *ngIf="isError(is_submit, renter_info_group, 'cret_num', 'required')">
                      请填写证件号码
                    </p>
                    <p class="text-danger" *ngIf="isError(is_submit, renter_info_group, 'cret_num', 'formatError')">
                      {{renterCretType.value === 'ID_CD' ? "证件号码格式不正确(身份证为18位数字或字母)" : "证件号码格式不正确(护照为6-20位数字或字母)"}}
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>-->
            <div class="row justify-content-center">
              <span class="text-danger">{{error_msg}}</span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary mr20" (click)="confirmRenterInfo()" [disabled]="canSave()">保存</button>
          <button type="button" class="btn btn-secondary" (click)="hide()">取消</button>
        </div>
      </div>
    </div>
  </div>

<div class="modal fade" bsModal #checkOutModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>退宿确认</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="checkOutModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="text-center mt25 mb-4">
          <div class="mt-2">须知：办理退宿，将删除为该入住人下发的门锁密码，解除其与该合同房间的绑定关系</div>
          <div class="mt-2 mb-2">确认为&nbsp;<i class="red">{{modal_name}}&nbsp;&nbsp;{{modal_cret_num}}</i>&nbsp;办理退宿吗？</div>
        </div>
      </div>
      <div class="modal-footer" >
        <button type="button" class="btn btn-primary mr-2" (click)="deleteRenter(modal_index, modal_info_id)">确定</button>
        <button type="button" class="btn btn-secondary" (click)="checkOutModal.hide()">取消</button>
      </div>
    </div>
  </div>
</div>
