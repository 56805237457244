/**
 * @description:当前路径是否可进入 逻辑判断
 *
 * @Author: yinqiang
 * @Date: 2018-03-23 16:04:04
 * @Last Modified by: yinqiang
 * @Last Modified time: 2018-03-23 19:20:38
 */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../../service/common/auth.service';
import { LogService } from '../../service/log/log.service';
import {Observable} from "rxjs";

@Injectable()
export class PathGuard implements CanActivate {

  constructor(public authService: AuthService, private logService: LogService, private router: Router) {
    this.logService.debug('PathGuard constructor');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    this.logService.info("path canActivate execute");
    //如果进入到登录路由，判断是否已经登录过 如果已经登录过直接跳转到system_manage 否则基线执行
    let currentPath: string = route.url.toString();
    if (currentPath != null && currentPath.endsWith("login") && !this.authService.isExpired()) {
      this.logService.info("path canActivate, login path to system_manage");
      this.router.navigate(['/']);
      return false;

    }
    return true;
  }

}
