import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {SignedService} from "../../../../service/signed/signed.service";
import {
  CheckoutFinanceRoomListViewResponse,
  CheckoutRoomDTO,
  CheckoutViewResponse, ObjectDTO,
  RenterBillDTO
} from "../../../../model/signed/checkout.model";
import Decimal from "decimal.js";

@Component({
  selector: 'app-checkout-record',
  templateUrl: './checkout-record.component.html',
  styleUrls: ['./checkout-record.component.css']
})
export class CheckoutRecordComponent implements OnInit {

  checkoutViewResponse: CheckoutFinanceRoomListViewResponse;

  constructor(private signedService: SignedService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
  ) {
  }

  checkoutRecordNumber: string;
  financeType: string;
  type: string;

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.checkoutRecordNumber = queryParams.id;

      this.signedService.checkoutRecold({checkout_record_number: this.checkoutRecordNumber}).then(data => {
        this.checkoutViewResponse = data;
        if(this.checkoutViewResponse.refund_info_dto){
          this.type = this.checkoutViewResponse.refund_info_dto.refund_type;
          this.financeType = this.checkoutViewResponse.refund_info_dto.refund_finance_type;
        }
      });
    });
  }

  goLease() {
    this.router.navigate(['../pact', this.checkoutViewResponse.lease_info.lease_id], {
      relativeTo: this.activatedRoute
    });
  }

  sumCouponTotal(list: Array<CheckoutRoomDTO>, type?) {
    let total = new Decimal("0");
    if (list != null && list.length > 0) {
      for (let info of list) {
        if (info.check_out_room_info_dto != null && info.check_out_room_info_dto.recycle_items != null
          && info.check_out_room_info_dto.recycle_items.length > 0) {
          for (let coupon of info.check_out_room_info_dto.recycle_items) {
            if (!type || (type && type === coupon.object_name)) {
              total = total.add(new Decimal(coupon.object_value+""));
            }
          }
        }
      }
    }
    return +(total.toString());
  }
}
