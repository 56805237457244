import {Constants} from './constants';
import {SysUser, TempStorageModel} from '../model/user/user.model';

export const LocalStorage = {

  getItem: (key: string): any => {
    if (localStorage) {
      let item = localStorage.getItem(key);
      if (item && item !== "undefined") {
        return item;
      }
    }
    return null;
  },

  setItem: (key: string, value: string) => {
    if (localStorage) {
      localStorage.setItem(key, value);
    }
  },

  removeItem: (key: string) => {
    if (localStorage) {
      localStorage.removeItem(key);
    }
  },

  clear() {
    let whiteListStorage: Array<TempStorageModel> = [];
    Constants.LOCAL_STORAGE_WHITE_LIST.forEach(item => {
      let tempStorageModel = new TempStorageModel();
      tempStorageModel.storage_name = item;
      tempStorageModel.storage_value = localStorage.getItem(item);
      if (tempStorageModel.storage_value) {
        whiteListStorage.push(tempStorageModel);
      }
    });
    if (localStorage) {
      localStorage.clear();
    }
    whiteListStorage.forEach(item => {
      if (item.storage_value) {
        localStorage.setItem(item.storage_name,item.storage_value);
      }
    });
  },

  customClear(sysUser: SysUser) {

    if (localStorage) {

      localStorage.removeItem(Constants.CURRENT_USER_SOURCE);
      // 带了USERID
      if (sysUser) {
        localStorage.removeItem(Constants.USER_OPERATE_PRIVILEGES + sysUser.id);
        localStorage.removeItem(Constants.PROJECT_ID + sysUser.id);
        localStorage.removeItem(Constants.CURRENT_COMPANY_ID + sysUser.id);
      }

      sessionStorage.removeItem(Constants.EXPIRE_TIME);
      sessionStorage.removeItem(Constants.TOKEN_KEY);
      sessionStorage.removeItem(Constants.USER_INFO);

      localStorage.setItem(Constants.EXPIRE_TIME, "");
      localStorage.setItem(Constants.TOKEN_KEY, "");
      localStorage.setItem(Constants.USER_INFO, "");

      localStorage.removeItem("uniqueTag");
      localStorage.removeItem(Constants.NON_PRESENTMENT + "SAFETY");

      localStorage.removeItem(Constants.PROJECT_ID);
      localStorage.removeItem(Constants.CURRENT_COMPANY_ID);

      // 清除向导相关的
      this.clearUserGuide();

    }
  },

  clearUserGuide() {
    localStorage.removeItem("guide_data");
    localStorage.removeItem("guide_status");
    localStorage.removeItem("guide_show");
    localStorage.removeItem("guide_detail");
    localStorage.removeItem("add_dept");
    localStorage.removeItem("add_project");
    localStorage.removeItem("add_user");
    localStorage.removeItem("add_room");
    localStorage.removeItem("addProject_modal");
    localStorage.removeItem("addDept_modal");
    localStorage.removeItem("addUser_modal");
    localStorage.removeItem("addRoom_modal");
    localStorage.removeItem("have_project");
  },

  clearProjectUserGuide() {
    localStorage.removeItem("guide_data");
    localStorage.removeItem("guide_status");
    // localStorage.removeItem("guide_show");
    localStorage.removeItem("guide_detail");
    // localStorage.removeItem("add_dept");
    // localStorage.removeItem("add_project");
    // localStorage.removeItem("add_user");
    // localStorage.removeItem("add_room");
    localStorage.removeItem("addProject_modal");
    localStorage.removeItem("addDept_modal");
    localStorage.removeItem("addUser_modal");
    localStorage.removeItem("addRoom_modal");
    localStorage.removeItem("have_project");
  }

};
