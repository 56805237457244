import { PageReq, PageResp } from "../page/page";
import { UserProjectDTO } from '../room/project.model';

/**
 * @description:房源列表查询
 * @Author: zhoujiahao
 * @Date: 2018/11/26
 */
export class RoomGroupListReqParam extends PageReq {
  //房型ID
  room_type_id?: any;
  //房源状态 AUDIT 审核中 EFFECT 有效 REMOVE 下架 CANCEL 取消 REJECT 拒绝
  room_group_status?: string;
}

export class RoomGroupAddRequest {
  // @Remark("月租价格")
  month_price?: number;
  // @Remark("房价增长方式")
  increase_type?: string;
  // @Remark("增长量")
  increase_amount?: number;
  // @Remark("管理费/物业费")
  manage_fee?: number;
  // @Remark("房型")
  room_type_id?: number;
  // @Remark("房型别名")
  room_type_alias?: string;
  // @Remark("床数")
  bed_num?: number;
  // @Remark("最多可住人数")
  livein_num?: number;
  // @Remark("方位")
  orientation?: string;
  // @Remark("装修类型ID")
  room_group_style_id?: number;
  // @Remark("面积")
  area?: string;
  // @Remark("简洁地址")
  simple_location?: string;
  // @Remark("交通信息描述")
  traffic_desc?: string;
  // @Remark("主图")
  banner_pic?: string;
  // @Remark("图片列表")
  pic_list?: Array<RoomGroupImageDTO>;
  // @Remark("设备列表")
  device_list?: Array<number>;

  key?: string;

  ar_url?: string;

  video_url?: string;
}

export class RoomGroupImageDTO {
  // @Remark("图片地址")
  url?: string;
  // @Remark("图片分类code")
  code?: string;
  // @Remark("图片分类描述")
  desc?: string;
  // @Remark("是否为封面")
  img_default?: boolean = false;
}

export class RoomGroupUpdateRequest extends RoomGroupAddRequest {
  // @Remark("ID")
  id?: number;
  location?: string;
}

export class RoomGroupFacListResponse {
  room_group_fac_dtolist?: Array<RoomGroupFacDTO>;
}

export class RoomGroupFacDTO {

  facility_id?: number;
  // 设施名
  facility_name?: string;
  // 设施编号
  facility_code?: string;
  // 设施图片
  facility_url?: string;

  checked?: boolean = false;
}

export class RoomGroupStyleAddRequest {
  name?: string;
}

export class RoomGroupStyleAddResponse {
  id?: number;
}

export class RoomGroupEditDetailRequest {
  id?: number;
}

export class RoomGroupEditDetailResponse extends RoomGroupUpdateRequest {
  project_dto?: UserProjectDTO;
}

/**
 * @description:房源列表响应
 * @Author: zhoujiahao
 * @Date: 2018/11/26
 */
export class RoomGroupListResponse extends PageResp {
  room_group_list_dtos?: RoomGroupListDTO[];
}

/**
 * @description:房源列表响应实体DTO
 * @Author: zhoujiahao
 * @Date: 2018/11/26
 */
export class RoomGroupListDTO {
  //@Remark("房源ID")
    id?:number;
  //@Remark("房源编号")
  room_group_no?:string;
  //@Remark("项目名称")
  project_name?:string;

  //@Remark("房型：一床房【白领单身公寓】")
  roon_type_name?:string;

  //@Remark("装修风格")
  style_name?:string;

  //@Remark("月租金")
  month_price?:string;

  //@Remark("递增金额")
  increase_amount?:string;

  //@Remark("物业费")
  manage_fee?:string;

  //@Remark("房源分租状态 AUDIT 审核中 EFFECT 有效 REMOVE 下架 CANCEL 取消 REJECT 拒绝")
   room_group_status?:string;

  //@Remark("递增类型 PER_YEAR 每年 PER_HALF_YEAR 每半年 PER_SEASON 每季度 PER_MONTH 每月")
  increase_type?:string;

  //@Remark("房间数量")
  room_num?:string;

  //@Remark("创建时间")
   create_time?:Date;
}

/**
* @Description:  房源详情相应实体
* @Author: zhoujiahao
* @Date: 2018-11-26  16:00
*/
export class RoomGroupDetailResponse {
  //@Remark("房源ID")
  id?:number;
  //@Remark("房源编号")
  room_group_no?:string;

  //@Remark("月租金")
  month_price?:string;

  //@Remark("递增类型 PER_YEAR 每年 PER_HALF_YEAR 每半年 PER_SEASON 每季度 PER_MONTH 每月")
  increase_type?:string;

  //@Remark("递增金额")
  increase_amount?:string;

  //床数量
  bed_num?:number;
  //最多可入住人数
  livein_num?:number;
  //朝向
  orientation?:string;
  //房源风格
  group_style_name?:string;
  //面积
  area?:string;
  //粗略地址
  simple_location?:string;
  //详细地址
  location?:string;
  //交通信息
  traffic_desc?:string;
  //经度
  longtitude?:string;
  //纬度
  latitude?:string;
  //状态
  status?:string;
  //房间数量
  room_num?:number;
  //主图
  banner_pic?:string;
  image_list?: RoomGroupImageDTO[];

  //@Remark("物业费")
  manage_fee?:string;

  //@Remark("项目名称")
  project_name?:string;
  //房型  一床房 / 高级大床房
  roon_type_name?:string;

  //设施
  facility_info_dtos?:FacilityInfoDTO[];

  last_modify_time?:Date;

  ar_url?: string;

  video_url?: string;
}

/**
 * @Description:  设施信息
 * @Author: zhoujiahao
 * @Date: 2018-11-26  16:00
 */
export class FacilityInfoDTO {
  facility_id?:number;
  facility_name?:string;
  facility_code?:string;
  facility_url?:string;
}

/**
 * @description:房源楼栋列表查询
 * @Author: zhoujiahao
 * @Date: 2018/11/26
 */
export class RoomGroupUnitReqParam extends PageReq {
  //房源ID
  id?: number;
}


/**
 * @Description:  楼栋信息
 * @Author: zhoujiahao
 * @Date: 2018-11-26  16:00
 */
export class RoomGroupUnitResponse extends PageResp{
  room_group_room_num_dtos?:RoomGroupRoomNumDTO[];
}
/**
 * @Description:  楼栋房间数量信息
 * @Author: zhoujiahao
 * @Date: 2018-11-26  16:00
 */
export class RoomGroupRoomNumDTO {
  unit_name?:string;
  num?:number;
  sign_num?:number;
}

/**
 * @Description:  房源操作日志列表
 * @Author: zhoujiahao
 * @Date: 2018-11-26  16:00
 */
export class RoomGroupLogListResponse extends PageResp{
  room_group_log_dtos?:RoomGroupLogDTO[];
}

/**
 * @Description:  操作日志详情
 * @Author: zhoujiahao
 * @Date: 2018-11-26  16:00
 */
export class RoomGroupLogDTO {
  //操作内容
  operation_content?:string;
  log_id?:number;
  //操作人
  operation_user?:string;
  //创建时间
  create_time?:Date;
}
/**
 * @Description:  房源下所有房间号信息
 * @Author: zhoujiahao
 * @Date: 2018-11-26  16:00
 */
export class RoomGroupRoomNumResponse{
  room_group_room_name_dtos?:RoomGroupRoomListDTO[];
}

/**
 * @Description:  房源下所有房间详情信息
 * @Author: zhoujiahao
 * @Date: 2018-11-26  16:00
 */
export class RoomGroupRoomListDTO{
  //楼栋号
  unit_name?:string;
  //房间号：通过 , 分割
  room_nums?:string;
}

export class RoomGroupRelationConfirmRequest {
  group_id?: number;
  confirm?: boolean;
}

export class RoomGroupRelationResponse {
  key?: string;

  unit_num?: number;

  room_num?: number;

  unit_list?: Array<RoomGroupRelatedUnitGroupDTO>;

  error_info?: string;
}

export class RoomGroupRelatedInfoDTO {
  unit_name?: string;

  room_num?: string;

  unit_id?: number;

  room_id?: number;
}

export class RoomGroupRelatedUnitGroupDTO {
  unit_name?: string;
  related_info_dtolist?: Array<RoomGroupRelatedInfoDTO>;
}

export class RoomGroupBaseDataResponse {
  image_classifys?: Array<RoomGroupImageClassifyDTO>;
}

export class RoomGroupImageClassifyDTO {
  code?: string;
  desc?: string;
}




