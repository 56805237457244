
<div class="setbox">
  <div class="row">
    <div class="col-12 col-sm-6 col-md-3 col-xl-2 col-2xl-2 mb10" >
      <div class="form-group d-inline-block w-100">
        <label>审批开始日期</label>
        <my-date-picker (inputFieldChanged)="endTimeChange()" placeholder="开始日期" [options]="myPastDatePickerOptions" [(ngModel)]="start_time"></my-date-picker>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-3 col-xl-2 col-2xl-2 mb10" >
      <div class="form-group d-inline-block w-100" >
        <label>审批结束日期</label>
        <my-date-picker placeholder="结束日期" [options]="endDatePickerOptions" [(ngModel)]="end_time"></my-date-picker>
      </div>
    </div>

    <div class="col-12 col-md-6 col-lg-2 item mb10">
      <label>提交人</label>
      <input [(ngModel)]="user_name"  class="form-control" placeholder="请输入提交人姓名">
    </div>
    <div class="col-12 col-sm-6 col-md-3 col-xl-2 col-2xl-2 mb10" >
      <div class="form-group">
        <label>类型</label>
        <select [(ngModel)]="approvTypeStr"  class="w-100" >
          <option  value="ALL">全部</option>
          <option *ngFor="let item of approvType" [value]="item.type">{{item.desc}}</option>
        </select>
      </div>
    </div>
    <div style="top: -5px;">
      <div class="form-group" style="max-width: 2400px">
        <label class="w-100 d-web">&nbsp;</label>
        <button (click)="goPage(1)" class="btn btn-info" type="button" >
          <i class="fa fa-search"></i> 搜索
        </button>
      </div>
    </div>
  </div>
  <!--  <div class="row">-->
  <!--    <div class="col-12 col-sm-6 col-md-6 col-xl-1 col-2xl-1">-->
  <!--      <div class="form-group">-->
  <!--        <label>类型</label>-->
  <!--        <select class="w-100" [(ngModel)]="complainReq.complaints_type">-->
  <!--          <option value>全部</option>-->
  <!--          <option value="COMPLAINT">投诉</option>-->
  <!--          <option value="PROPOSAL">建议</option>-->
  <!--        </select>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="col-12 col-sm-6 col-md-6 col-xl-1 col-2xl-1">-->
  <!--      <div class="form-group">-->
  <!--        <label>状态</label>-->
  <!--        <select class="w-100" [(ngModel)]="complainReq.complaints_status">-->
  <!--          <option value>全部</option>-->
  <!--          <option value="{{item.id}}" *ngFor="let item of status; let i=index">{{item.name}}</option>-->
  <!--        </select>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="col-12 col-sm-6 col-md-6 col-xl-1 col-2xl-1">-->
  <!--      <div class="form-group">-->
  <!--        <label>处理归类</label>-->
  <!--        <select class="w-100" [(ngModel)]="complainReq.classify_type">-->
  <!--          <option value>全部</option>-->
  <!--          <option value="{{item.code}}" *ngFor="let item of classify_type_list; let i=index">{{item.desc}}</option>-->
  <!--        </select>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="col-12 col-sm-6 col-md-2 col-xl-2">-->
  <!--      <div class="form-group">-->
  <!--        <label>手机号码</label>-->
  <!--        <input type="text" class="form-control" [(ngModel)]="complainReq.complaints_user_phone" class="w-90">-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="col-12 col-sm-6 col-md-4 col-xl-4">-->
  <!--      <div class="form-group">-->
  <!--        <label>提交日期</label>-->
  <!--        <div>-->
  <!--          <div class="d-inline-block w-45">-->
  <!--            <my-date-picker [options]="myDatePickerOptions" placeholder="年/月/日" [(ngModel)]="start_time">-->
  <!--            </my-date-picker>-->
  <!--          </div>-->
  <!--          <span class="ml-1 mr-1 valign-top">-</span>-->
  <!--          <div class="d-inline-block w-45">-->
  <!--            <my-date-picker [options]="myDatePickerOptions" placeholder="年/月/日" [(ngModel)]="end_time">-->
  <!--            </my-date-picker>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->

  <!--    <div class="col-12 col-sm-6 col-md-3 col-xl-3 mb10" style="margin-top: 30px">-->
  <!--      <div class="form-group">-->
  <!--        <button class="btn btn-info mr-3" type="button" (click)="search()">-->
  <!--          <i class="fa fa-search"></i>搜索-->
  <!--        </button>-->
  <!--        &lt;!&ndash;<button class="btn btn-secondary " type="button" (click)="clear()">-->
  <!--          <i class="fa fa-times"></i>清空条件-->
  <!--        </button>&ndash;&gt;-->
  <!--        <button class="btn btn-info" (click)="dataStatisticsModal.show()" *ngIf="btn_privileges.COMPLAINT_DATA_STA"><i class="fa fa-bar-chart"></i>数据统计</button>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <!-- table -->
  <table class="table table-bordered table-striped">
    <thead>
    <tr>
      <th class="show2">审批单号</th>
      <th>类型</th>
      <th>提交时间</th>
      <th>审批时间</th>
      <th class="show2">提交人</th>
      <th class="show2">审批意见</th>
      <th>操作</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="!resp?.approval_task_dtolist">
      <td class="show1" colspan="4" *ngIf="resp?.total == 0">本次未查询到相关数据</td>
      <td class="show2" colspan="9" *ngIf="resp?.total == 0">本次未查询到相关数据</td>
    </tr>
    <ng-container *ngIf="resp?.approval_task_dtolist && resp?.approval_task_dtolist.length > 0">
      <tr *ngFor="let item of resp?.approval_task_dtolist  | paginate: {itemsPerPage: req?.page_size,
        currentPage: req?.page_num, totalItems: resp?.total }">
        <td class="show2">{{item?.record_no}}</td>
        <td>
          <ng-container *ngIf="item?.type==='LEASE_CHANGE'">合同信息变更申请</ng-container>
          <ng-container *ngIf="item?.type==='LEASE_BILL_CHANGE'">费用调整申请</ng-container>
          <ng-container *ngIf="item?.type==='ASSET_TRANSFER'">资产转移审批</ng-container>
          <ng-container *ngIf="item?.type==='ROOM_AVAILABLE_TIME_CHANGE'">房间使用期限调整审批</ng-container>
          <ng-container *ngIf="item?.type==='FROZEN_PASSWORD'">冻结密码审批</ng-container>
          <ng-container *ngIf="item?.type==='LOCK_PASSWORD'">密码申请审批</ng-container>
          <ng-container *ngIf="item?.type==='RENTER_CHECKOUT_DEPOSIT'">住客退押审批</ng-container>
          <ng-container *ngIf="item?.type==='PROJECT_BASE_PRICE'">项目基础价格变更</ng-container>
          <ng-container *ngIf="item?.type==='LEASE_COMMIT'">合同审批</ng-container>
        </td>
        <td>{{item?.create_time|date:'yyyy-MM-dd HH:mm:ss'}}</td>
        <td>{{item?.approv_time|date:'yyyy-MM-dd HH:mm:ss'}}</td>
        <td class="show2">{{item?.create_user_name}}</td>
        <td class="show2">
          <ng-container *ngIf="item?.approv_status==='true'">
            <span class="badge badge-success badge-pill">同意</span>
          </ng-container>
          <ng-container *ngIf="item?.approv_status==='false'">
            <span class="badge badge-danger badge-pill">拒绝</span>
          </ng-container>
          <ng-container *ngIf="item?.approv_status==='cancel'">
            <span class="badge badge-warning badge-pill">取消</span>
          </ng-container>
        </td>
        <td>
          <button class="btn btn-info" type="button" (click)="showDetail(item)">
            <i class="fa fa-search"></i>查看
          </button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <div class="text-right pages" *ngIf="resp?.approval_task_dtolist">
    <app-page *ngIf="resp?.total !== 0" (pageChangeEvent)="goPage($event)"></app-page>
    <div class="text-right">共有{{resp.total}}条记录</div>
  </div>
</div>

<app-approv-record-detail-modal></app-approv-record-detail-modal>
