import { LogService } from "../../../service/log/log.service";
import {Component, EventEmitter, OnInit, Output, ViewChild} from "@angular/core";
import {
  LoginUnusualDTO,
  LoginUnusualInserRequest,
  LoginUnusualRequest,
  UserLoginOftenCityDTO,
  UserOfterCityInserRequest,
} from "../../../model/user/user.model";
import { UserService } from "../../../service/user/user.service";
import { AuthService } from "../../../service/common/auth.service";
import { Router } from "@angular/router";
import { BaseInfoService } from "../../../service/common/base-info.service";
import {CityDTO, LoginAreaResponse, ProvinceDTO} from "../../../model/modal/base-model";
import {WorkModel} from "../../../model/common/common.model";
import {WorkService} from "../../../service/common/work.service";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
  selector: "app-safety",
  templateUrl: "./safety.component.html",
  styleUrls: ["./safety.component.css"]
})
export class SafetyComponent implements OnInit {

  @Output() loginOp = new EventEmitter<any>();

  province_dtos: Array<ProvinceDTO>;
  city_dtos: Array<CityDTO>;
  login_unusual_dtos: Array<LoginUnusualDTO>;
  city_id: number;
  province_id: number;
  login_ip: string;
  login_total: number = 0;
  user_id: number;

  nowCityName: string;
  nowProvinceName: string;

  userOfterCityInserRequest: Array<UserLoginOftenCityDTO> = [];

  city_name: Array<LoginUnusualDTO> = [];

  is_frequently_city: boolean = false;

  // safetyTipsModal;
  // historyModal;
  // expectionModal;

  @ViewChild('oftenCityModal') oftenCityModal: ModalDirective;
  @ViewChild('safetyTipsModal') safetyTipsModal: ModalDirective;
  @ViewChild('expectionModal') expectionModal: ModalDirective;
  @ViewChild('historyModal') historyModal: ModalDirective;

  local_city_name;

  local_province_name;

  local_country;

  //异常列表
  login_unusual_dtos_exception: Array<LoginUnusualDTO> = [];

  constructor(private logService: LogService,
              private userService: UserService,
              private auth: AuthService,
              private router: Router,
              private baseInfoService: BaseInfoService,
              private workService: WorkService,
  ) {
  }

  // initDialog(safetyTipsModal?: any, historyModal?: any, expectionModal?: any,
  //            oftenCityModal?: any) {
  //   this.safetyTipsModal = safetyTipsModal;
  //   this.historyModal = historyModal;
  //   this.expectionModal = expectionModal;
  //   this.oftenCityModal = oftenCityModal;
  // }

  openDialog(platformCode: string){
    this.findCity(platformCode);
  }


  ngOnInit() {
  }

  findCity(platformCode: string){
    if (platformCode === 'LEQIDA') {
      this.loginOp.emit();
    } else {
      this.baseInfoService.findProvinceOrCitylist().then(data => {
        this.province_dtos = data.province_dtos;
        this.findBase();
      });
    }
  }
  findBase(){
    this.user_id = this.auth.getUserInfo().id;
    let loginUnusualRequest = new LoginUnusualRequest();
    loginUnusualRequest.user_id = this.user_id;
    loginUnusualRequest.page_num = 1;
    loginUnusualRequest.page_size = 30;
    //获取用户常用城市列表
    this.userService.getOftenCityList(loginUnusualRequest).then(data => {
      this.userOfterCityInserRequest = data.user_login_often_city_dtos;
      this.province_id = data.province_id;
      this.city_id = data.city_id;
      this.login_ip = data.login_ip;
      this.local_country = data.country;
      this.local_city_name = data.city_name;
      this.local_province_name = data.province_name;
      if(!this.province_id){
        this.province_id = this.province_dtos[0].id;
        this.city_dtos = this.province_dtos[0].city_dtos;
        this.city_id =  this.city_dtos[0].id;
      }else{
        let provinceDTO = this.province_dtos.filter(province => province.id === this.province_id).pop();
        this.nowProvinceName = provinceDTO.name;
        this.city_dtos = provinceDTO.city_dtos;
        let cityDTO = this.city_dtos.filter(city => city.id === this.city_id).pop();
        if(!cityDTO){
          this.city_id = this.city_dtos[0].id;
        }else{
          this.nowCityName = cityDTO.name;
        }
      }
      if(this.userOfterCityInserRequest && this.userOfterCityInserRequest.length > 0){
        this.userOfterCityInserRequest.forEach((onten, index) => {
          let provinceDTO = this.province_dtos.filter(province => province.id === +onten.province_id).pop();
          let cityDTO = provinceDTO.city_dtos.filter(city => city.id === +onten.city_id).pop();
          let loginUnusualDTO = new LoginUnusualDTO();
          loginUnusualDTO.city = cityDTO.name;
          loginUnusualDTO.id = onten.city_id;
          this.city_name.push(loginUnusualDTO);
        });
      }

      if (data.city_name === "未知") {
        let loginUnusualDTO = new LoginUnusualDTO();
        loginUnusualDTO.city = data.city_name;
        loginUnusualDTO.id = 0;
        this.city_name.push(loginUnusualDTO);
        this.nowCityName = data.city_name;
      }
      //获取用户登录记录
      this.userService.getUserLoginUnusualRecord(loginUnusualRequest).then(data1 => {
        this.login_unusual_dtos = data1.login_unusual_dtos;
        this.login_total = data1.total;
        //是否第一次登录,是则设置常用登录城市
        if(!this.city_name || this.city_name.length === 0){
          this.loginOp.emit();
        }else{
          let firstException = false;
          this.city_name.forEach((city, index) => {
            if(this.nowCityName === city.city){
              this.is_frequently_city = true;
            }
          });
          if(this.login_unusual_dtos && this.login_unusual_dtos.length > 0 &&this.login_unusual_dtos[0].ext_info === 'error'){
            firstException = true;
          }
          this.addLoginUnusualRecord(false);
          //是否是常用城市登录
          if(this.is_frequently_city && firstException){
            let number = 0;
            let keepGoing = true;
            this.login_unusual_dtos.forEach((city, index) => {
              if(keepGoing){
                let c = false;
                if(city.ext_info || city.ext_info === 'error'){
                  number += 1;
                }else{
                   c = true;
                }
                if(c){
                  number = index;
                  keepGoing = false;
                }
              }
            });
            this.login_unusual_dtos_exception = [];
            for(let i=0;i<number;i++){
              let loginUnusualDTO = this.login_unusual_dtos[i];
              this.login_unusual_dtos_exception.push(loginUnusualDTO);
            }
            this.expectionModal.show();
          } else {
            this.loginOp.emit();
          }
        }
      });
    });
  }

  submit(){
      if(!this.userOfterCityInserRequest || this.userOfterCityInserRequest.length === 0){
        this.workService.showErrorModal("请选择常用登录城市");
        return;
      }
      let request = new UserOfterCityInserRequest();
      request.user_login_often_city_dtolist = this.userOfterCityInserRequest;
      this.userService.settingOftenCity(request).then(data => {
          if(data && data.result === 'SUCCESS'){
            this.oftenCityModal.hide();
            this.safetyTipsModal.show();
            this.addLoginUnusualRecord(true);
          }else{
            this.workService.showErrorModal("常用登录城市设置失败！");
          }
      });
  }

  addLoginUnusualRecord(isFirst: boolean){
    let loginUnusualInserRequest = new LoginUnusualInserRequest();
    let loginUnusualDTO = new LoginUnusualDTO();
    loginUnusualDTO.country = this.local_country;
    loginUnusualDTO.user_id = this.user_id;
    loginUnusualDTO.source = this.isMobile() ? '移动端' : '网页';
    loginUnusualDTO.login_ip = this.login_ip;
    if(!this.nowCityName || !this.local_city_name){
    }else{
      loginUnusualDTO.city = this.nowCityName ? this.nowCityName : this.local_city_name;
      loginUnusualDTO.province = this.nowProvinceName ? this.nowProvinceName : this.local_province_name;
      if(!isFirst){
        if(!this.is_frequently_city || !this.nowCityName || this.nowCityName === null){
          loginUnusualDTO.ext_info = 'error';
        }
      }
      loginUnusualInserRequest.login_unusual_dto = loginUnusualDTO;
      loginUnusualInserRequest.page_size = 1;//无用参数但必传
      loginUnusualInserRequest.page_num = 1;//无用参数但必传
      this.userService.addUserLoginUnusualRecord(loginUnusualInserRequest).then(data => {
      });
    }
  }

  checkProvince(event: any){
    let provinceDTO = this.province_dtos.filter(province => province.id === +event.target.value).pop();
    this.city_dtos = provinceDTO.city_dtos;
    this.city_id =  this.city_dtos[0].id;
  }
  addCity(){
    if(!this.userOfterCityInserRequest){
      this.userOfterCityInserRequest = [];
    }
    let i = 0;
    this.userOfterCityInserRequest.forEach((city, index) => {
      if(city.city_id === this.city_id){
        i = 1;
      }
    });
    if(i === 0 && this.userOfterCityInserRequest.length < 5){
      let userLoginOftenCityDTO = new UserLoginOftenCityDTO();
      userLoginOftenCityDTO.city_id = this.city_id;
      userLoginOftenCityDTO.province_id = this.province_id;
      userLoginOftenCityDTO.user_id = this.user_id;
      let provinceDTO = this.province_dtos.filter(province => province.id === +userLoginOftenCityDTO.province_id).pop();
      let cityDTO = provinceDTO.city_dtos.filter(city => city.id === +userLoginOftenCityDTO.city_id).pop();
      let loginUnusualDTO = new LoginUnusualDTO();
      loginUnusualDTO.city = cityDTO.name;
      loginUnusualDTO.id = userLoginOftenCityDTO.city_id;
      this.city_name.push(loginUnusualDTO);
      this.userOfterCityInserRequest.push(userLoginOftenCityDTO);
    }
  }

  delCity(id: number){
    let city_index;
    let onten_index;
    this.city_name.forEach((city, index) => {
      if(id === +city.id){
        city_index = index;
      }
    });
    this.userOfterCityInserRequest.forEach((onten, index) => {
      if(id === +onten.city_id){
        onten_index = index;
      }
    });
    delete this.city_name[city_index];
    delete this.userOfterCityInserRequest[onten_index];
    let ontenCityName: Array<UserLoginOftenCityDTO> = [];
    let cityName: Array<LoginUnusualDTO> = [];
    this.city_name.forEach((city, index) => {
      if(city != null){
        cityName.push(city);
      }
    });
    this.userOfterCityInserRequest.forEach((onten, index) => {
      if(onten != null){
        ontenCityName.push(onten);
      }
    });
    this.city_name = cityName;
    this.userOfterCityInserRequest = ontenCityName;
  }

  isMobile(): boolean {
    let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return flag;
  }

  getLoginOp() {
    this.loginOp.emit();
  }
}
