import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { ResetPassword, SendCode } from "../../../model/user/user.model";
import { UserService } from "../../../service/user/user.service";
import { RegularExp } from "../../../common/util.regexp";
import { loadScript } from '../../../common/js-loader';
import {loginPassWordValidator} from "../../../core/validator/validator.common";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"]
})
export class ResetPasswordComponent implements OnInit {
  imgCodeUrl: string = environment.apiHost + "image/code/";
  imgUrl: string;
  errorContent: string;
  sendCode: SendCode;
  resetPassword: ResetPassword;
  buttonShow: boolean = true;
  clock: number = 60;

  success = false;
  system_type: string = 'CSDM';

  constructor(
    private router: Router,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    // loadScript(
    //   "https://seal.godaddy.com/getSeal?sealID=z5pqOmGebDjEWJm5KjpZT11XRAZ9by12DDoiftZaTeni2lI3Uhk3ahilA3HD"
    // );

    this.activatedRoute.queryParams.subscribe(queryParams => {
      if(queryParams.system_type){
        this.system_type = queryParams.system_type;
      }
    });
    this.sendCode = new SendCode();
    this.resetPassword = new ResetPassword();
    this.imgCodeClick();
  }

  imgCodeClick() {
    let uniqueTag = localStorage.getItem("uniqueTag");
    let date = new Date();
    if (uniqueTag == null) {
      uniqueTag = this.createCharacter();
      localStorage.setItem("uniqueTag", uniqueTag);
    }
    this.imgUrl = this.imgCodeUrl + uniqueTag + "?num=" + date.getTime();
  }

  check(bl?: boolean) {
    this.sendCode.mobile =
      Number((this.sendCode.mobile + "").trim()) === 0
        ? null
        : Number((this.sendCode.mobile + "").trim());

    this.sendCode.imgCode = this.sendCode.imgCode ? (this.sendCode.imgCode).trim() : null;

    if (!new RegExp(RegularExp.phone.mobile).test(this.sendCode.mobile + "")) {
      this.errorContent = "请输入合法手机号";
      return false;
    } else if (this.sendCode.imgCode == null || this.sendCode.imgCode === "") {
      this.errorContent = "图片验证码不能为空";
      return false;
    } else if (bl && !loginPassWordValidator(this.resetPassword.password)) {
      this.errorContent = "密码长度8-18位，须含数字、大写字母、小写字母、符号中的3种及以上";
      return false;
    } else if (bl && this.resetPassword.password !== this.resetPassword.newPassword) {
      this.errorContent = "两次输入密码必须一致";
      return false;
    } else {
      this.errorContent = "";
    }
    return true;
  }

  sendCodeRequest() {
    if (this.check(false)) {
      let uniqueTag = localStorage.getItem("uniqueTag");
      this.sendCode.uniqueTag = uniqueTag;
      this.sendCode.codeType = 1;
      this.userService.sendCodeRequest(this.sendCode).then(data => {
        this.clock = 60;
        // 发送成功之后进行倒记时
        this.buttonShow = false;
        setTimeout(() => this.timeOut(), 1000);
      }).catch(error => {
        this.clock = 60;
        this.buttonShow = true;
      });
    }
  }

  timeOut() {
    this.clock = this.clock - 1;
    if (this.clock === 0) {
      this.buttonShow = true;
      return true;
    } else {
      setTimeout(() => this.timeOut(), 1000);
    }
  }

  resetPasswordFunction() {
    if (this.check(true)) {
      // 提交重置密码
      this.resetPassword.mobile = this.sendCode.mobile;
      this.userService.resetPasswordRequest(this.resetPassword).then(data => {
        // 成功
       this.success = true;
      });
    }
  }

  createCharacter() {
    let date = new Date();
    let charcter: string =
      date.getTime() + "imgcodesms" + Math.random() * 100000000000;
    if (charcter.length !== 32) {
      return charcter.substring(0, 32);
    }
    return charcter;
  }

  goLogin() {
    this.router.navigate(['/login'], {queryParams: {system_type: 'LEQIDA'}});
  }
}
