import {CityDTO} from "../modal/base-model";
import {HotelProjectDTO} from "../project/project.model";
import {HotelProject, ProjectGroupDTO} from '../room/project.model';
import {PageReq, PageResp} from "../page/page";
import {DepartDTO} from "../department/department.model";
import {SystemConfigDTO} from "../company/company.model";
import {ClientInfoBriefModel} from "../clientinfo/client-info.model";

export class Login {
  userAccount?: string;
  userPassword?: string;
  userAccountType?: string;
  imgCode?: string;
  uniqueTag?: string;
  loginUniqueId?: string;
  code?: string;
  systemType?: string;
  smsCode?: string;
  loginCheckCode?: string;
  loginType?: string = 'PASSWORD';
}

/**
 * @description: 用户
 * @Author: lubingbin
 * @Date: 2018/3/6
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-09-24 15:45:27
 */
export class SysUser {
  id: number;
  user_id: number;
  user_name: string;
  user_sex: string;
  nick_name: string;
  user_icon: string;
  user_phone: string;
  department_id: number;
  position_id: number;
  user_status: string;
  current_login_fail_count: number;
  login_fail_count: number;
  mast_change_password: number;
  create_time: number;
  last_modify_time: number;
  ext_info: string;
  tel_conceal?: string;

  user_no: string;
  hire_date: Date;
  last_login_date: Date;
  login_times: number;
  company_name: string;
  position_name: string;
  department_name: string;
  company_id: number;

  //维修账号id
  sys_worker_id: number;

  rank_name;
  post_name;

  mange_room_count?: string;
  manage_client_count?: string;
}

/**
 * @Description: 工单人员信息
 * @Author: yangwen
 * @Date: 2019/6/13 21:12
 */
export class SysWorker {
  id: number;
  account: string;
  password: string;
  type: string;
  nick_name: string;
  create_time: Date;
}

/**
 * 销售
 */
export class Seller {
  id?: number;
  user_name?: string;
}

/**
 * @Description:   用户列表信息获取请求参数
 * @Author: zhoujiahao
 * @Date: 2018-03-21  16:45
 */
export class UserListRequestParam {
  user_search_type?: string;
  search_content?: string;
  department_id?: number;
  position_id?: number;
  page_count?: number;
  page_no?: number;
  param1?: string;
}

/**
 * @Description:   用户列表响应
 * @Author: zhoujiahao
 * @Date: 2018-03-21  16:48
 */
export class UserListResponse {
  user_info_dtos?: UserListDTO[];
  total?: number;
  page?: number;
}

/**
 * @Description:   用户列表返回DTO
 * @Author: zhoujiahao
 * @Date: 2018-03-21  16:53
 */
export class UserListDTO {
  user_id: number;
  user_name?: string;
  department_id: number;
  position_id: number;
  depart_name: string;
  position_name: string;
  lease_number?: number;
  user_phone: string;
  user_sex: string;
}

/**
 * @Description:   用户添加或者修改请求
 * @Author: zhoujiahao
 * @Date: 2018-03-23  16:45
 */
export class UserManageReqParam {
  user_id?: number;
  user_name?: string;
  department_id: number;
  position_id?: any;
  position_name?: string;
  user_account: string;
  user_sex: string;
  hotel_project_id: any;
  operation_type: string;
  user_password: string;
  department_name: string;

}

export class SendCode {
  uniqueTag?: string;
  mobile?: number;
  imgCode?: string;
  codeType?: number;
}

export class ResetPassword {
  mobile?: number;
  smsCode?: string;
  password?: string;
  newPassword?: string;
}

export class DeptUserListReqParam {
  company_id?: number; // 公司ID
  user_name: string;

  constructor() {
  }
}

export class DeptUserItem {
  id: number;
  text: string;
}

/**
 * @Description:   用户添加或者修改请求
 * @Author: yw
 */
export class SysUserManageReqParam {
  operation_type: string;
  user_id?: number;

  id?: number;
  user_name?: string;
  company_id?: number;
  company_name?: string;
  department_id: number;
  position_id?: number;
  position_name?: string;
  user_sex: string;
  department_name: string;
  birthday: Date;
  hire_date: Date;
  email: string;
  user_phone: string;
  user_status: string;
  user_no: string;
  qr_code?: string;

  login_times?: any;
  last_login_date?: any;
  last_login_ip?: any;
  project_ids?: number[];
  rank_id?: number;
  post_name?: string;
  role_id_list?: number[];

  user_company_dtos?: Array<SysUserCompanyDTO>;
  delete_ids?: number[];
  platformType?: any;
}

export class SysUserCompanyDTO {
  project_ids?: Array<Number>;
  rank_id?: number;
  post_name?: string;
  department_id?: number;
  company_id?: number;
  company_name?: string;
  department_name?: string;
  hire_date?: Date;
  status?: string;
  hdate?: any;
  deptInfos?: Array<DepartDTO>;
  project_dtolist: Array<HotelProject>;
  project_dtolist_wos: Array<HotelProject>;
  role_ids: Array<number>;
  company_role_dtos: Array<RoleDTO>;
  lead_comapny: boolean;
  id?: number;
  manage_project_type?: string;
  manage_project_type_wos?: string;
  project_ids_wos?: any;
  lead_comapny_disabled?: boolean;
}

export class RoleDTO {
  position_id?: number;
  position_name?: string;
  remark?: string;
  checked?: boolean;
}


export class UserIndexRequestParam {
  user_search_type?: string;
  search_content?: string;
  department_id?: number;
  position_id?: number;
  page_size?: number;
  page_num?: number;
  param1?: string;
  company_id?: number;
  plat_form_codes?: Array<any>;
  status?: string = 'EFFECTIVE';
}

export class ProjectListResponse {
  city_dtolist: Array<CityDTO>;
  project_dtolist: Array<HotelProject>;
  project_group_list: Array<ProjectGroupDTO>;

  company_has_project?: boolean;

  system_config_dto?: SystemConfigDTO;
}

export class ArrangeLeaseRequest {
  incumbent?: number;
  leaver?: number;
  company_id?: number;
}

//登录记录
export class LoginUnusualRequest extends PageReq {
  user_id?: number;
}

export class LoginUnusualResponse extends PageResp {
  login_unusual_dtos: Array<LoginUnusualDTO>;
}

export class LoginUnusualInserRequest extends PageReq {
  login_unusual_dto: LoginUnusualDTO;
}

export class CommonResponse {
  result?: string;
}

export class UserOfterCityInserRequest {
  user_login_often_city_dtolist: Array<UserLoginOftenCityDTO>;
}

export class UserOfterCityResponse {
  city_id?: number;

  province_id?: number;

  login_ip?: string;

  user_login_often_city_dtos: Array<UserLoginOftenCityDTO>;

  city_name;

  province_name;

  country;
}

export class UserLoginOftenCityDTO {
  user_id?: number;

  province_id?: number;

  city_id?: number;
}

export class LoginUnusualDTO {
  id?: number;

  user_id?: number;

  country?: string;

  province?: string;

  city?: string;

  login_ip?: string;

  source?: string;

  create_time?: Date;

  ext_info?: string;

}


export class UserDetailRequest {
  userId?: number;
  companyId?: number;
}

export class UserDetailResponse {
  user_id?: number;
  phone?: string;
  company_name?: string;
  company_id?: number;
  department_name?: string;
  post_name?: string;
  rank_name?: string;
  company_number?: number;
  bind_wechat?: string;

  upload_face?: any;
}

export class UserWechatRelateFlagRequest{
  temporary_code?: string;
  system_type?: string;
}

export class UserWechatRelateFlagResponse {
  flag?: string;
  redirect_url?: string;
}

export class SmsCodeCheckRequest {
  user_account?: string;
  sms_check_tag?: string;
}

export class LoginSecondCheckRequest {
  mobile?: string;
  sms_code?: string;
  img_code?: string;
  unique_id?: string;
  login_unique_id?: string;
  is_select_need_check?: string;
  user_id?: number;
}

export class LoginCheckCode {
  uniqueTag?: string;
  mobile?: string;
  codeType?: number;
}

export class SysUserDTO {
  //@Remark("用户ID")
  id?: number;
  //@Remark("用户名")
  user_name?: string;
  //@Remark("性别")
  user_sex?: string;
  //@Remark("昵称")
  nick_name?: string;
  //@Remark("用户图标")
  user_icon?: string;
  //@Remark("用户电话")
  user_phone?: string;
  //@Remark("用户电话(脱敏)")
  tel_conceal?: string;
  //@Remark("用户证件号(脱敏)")
  cret_num_conceal?: string;
  //@Remark("部门ID")
  department_id?: number;
  //@Remark("岗位ID")
  position_id?: number;
  //@Remark("用户状态EFFECTIVE：有效 INVALID：禁用")
  user_status?: string;
  //@Remark("当前登录错误次数")
  current_login_fail_count?: number;
  //@Remark("总登录错误次数")
  login_fail_count?: number;
  //@Remark("首次登录是否必须修改密码")
  mast_change_password?: number;
  //@Remark("worker的type")
  worker_type?: string;
  sys_worker_id?: number;
  company_id?: number;
  company_name?: string;
  department_name?: string;
  position_name?: string;
  user_no?: string;
  birthday: Date;
  hire_date: Date;
  email?: string;
  login_times?: number;
  last_login_date: Date;
  last_login_ip?: string;
  create_user_id?: number;
  update_user_id?: number;
  post_name?: string;
  rank_id?: number;
  rank_name?: string;
  //@Remark("是否为维修保洁人员")
  is_wos?: boolean;
  role_id_list?: number[];
  user_company_dtos?: Array<SysUserCompanyDTO>;
}

export class TempStorageModel {
  storage_name: string;
  storage_value: string;
}

export class UserProjectListRequest {
  // @Remark("userId为空时则取当前登录用户ID")
  user_id?: number;
  // @Remark("status为空时则查询当前用户能够管理的，为ALL时则查用户所有包括不能管理的")
  status?: string;
  // @Remark("公司id")
  company_id?: number;
  // @Remark("平台标识")
  platfrom_code?: string;

  business_type?: string;
}

export class ManageProjectListResponse {
  project_dtolist?: Array<HotelProjectDTO>;
  client_info_list?: Array<ClientInfoBriefModel>;
  select_all_client?: boolean;
}

export class ProjectRoomUserRelationDTO {
  project_id?: number;
  room_id?: number;
}

export class UserProjectRoomRelationRequest {
  sys_company_id?: number;
  user_id?: number;

  select_all?: boolean;
  business_type?: string;

  client_ids?: Array<number>;
  select_client_all?: boolean;

  project_room_user_relation_dtolist?: Array<ProjectRoomUserRelationDTO>;
}

export class LeQiDaUserRegisterRequest{
  user_name?: string;
  user_phone?: string;
  sms_code?: string;
  password?: string;
  second_password?: string;
}

export class LeQiDaUserRegisterResponse{
  user_id?: number;
}

export class LeQiDaLoginSmsCodeRequest{
  send_phone?: string;
  code_type?: number;
}
