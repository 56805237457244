import { RequestService } from '../request.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { ScheduleListResponse } from '../../model/schedule/schedule.model';

@Injectable()
export class ScheduleServer {
  private SCHEDULE_LIST = `${environment.apiHost}schedule/list`;

  constructor(private requestService: RequestService) {

  }

  /**
   * @Description:   获得待办事项列表
   * @Author: zhoujiahao
   * @Date: 2018-05-07 15:09
   */
  public queryScheduleLit(requestData?: any): Promise<ScheduleListResponse> {
    return this.requestService.authGet(this.SCHEDULE_LIST, requestData);
  }
}
