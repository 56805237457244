import {PayLogListDTO} from "../finance/bill.model";
import {PageReq, PageResp} from '../page/page';
import {ObjectDTO} from '../signed/checkout.model';

/**
 * @Description: 获取可用车位列表请求
 * @Author: 陆炳彬
 * @Date: 2018/12/6 下午2:18
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/12/6 下午2:18
 */
export class AvaliableCarportListReq {
  start_time: number;
  end_time: number;
  search_text: string;
  edit_lease_id: number;
  exclude_carport_info_id: number;
}

/**
 * @Description: 可用车位列表返回
 * @Author: 陆炳彬
 * @Date: 2018/12/6 下午2:20
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/12/6 下午2:20
 */
export class AvaliableCarportListResp {
  avaliable_carport_list: CarportInfo[];
}

/**
 * @Description: 车库信息
 * @Author: 陆炳彬
 * @Date: 2018/12/6 下午2:20
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/12/6 下午2:20
 */
export class CarportInfo {
  carport_info_id?: number;
  carport_number?: string;
  month_price?: number;
  create_time?: Date;
  carport_status?: string;
  carport_deposit?: number;
  month_price_str?: string;
}

// 租约账单/支付流水  查询参数
export class CarportLeaseBillListParam {
  carport_lease_id?: number;
  bill_no?: string;
  bill_status?: boolean;
  page_size?: number;
  page_num?: number;

  constructor(carport_lease_id: number, bill_no: string, bill_status: boolean, page_size: number, page_num: number) {
    this.carport_lease_id = carport_lease_id;
    this.bill_no = bill_no;
    this.bill_status = bill_status;
    this.page_size = page_size;
    this.page_num = page_num;
  }
}

// 租约账单对象
export class CarportLeaseBillDTO {
  bill_id?: number;
  bill_type_name?: string;
  carport_number?: string;
  bill_amount?: string;
  bill_start_time?: Date;
  bill_end_time?: Date;
  plan_pay_time?: Date;
  received?: string;
  waiting_pay_amount?: string;
  remark?: string;
  carport_lease_id?: number;
  bill_no?: string;
  // 账单支付状态
  bill_status?: string;
  // 账单支付状态明文
  bill_status_name?: string;
  contract_num?: string;
  // 账单状态 出账/待出
  bill_charge_off_status;
  bill_charge_off_status_name;
  // 是否免租优惠
  is_free?: boolean;
  free_day?: number;
}

// 租约账单返回对象
export class CarportLeaseBillListResponse {
  carport_lease_bill_dtolist?: CarportLeaseBillDTO[];
  pay_log_list?: PayLogListDTO[];
  arrearages?: string;
  total?: number;
}

// 账单财务明细对象
export class CarportBillPayLogDTO {
  // 支付时间
  pay_time?: Date;
  // 账单类型
  bill_type?: string;
  // 支付类型
  pay_type?: string;
  // 支付金额
  pay_amount?: string;
  // 支付来源类型  支付流水PAY_LOG/预定金抵扣BOOKING
  source_type?: string;
  // 支付来源类型  支付流水/预定金抵扣
  source_type_name?: string;
  // 支付来源类型 ID
  source_id?: number;
  // 流水号
  source_no?: string;
  // 操作人
  create_name?: string;
}

// 账单详情参数
export class CarportLeaseBillDetailParam {
  bill_id?: number;// 账单ID
  carport_lease_id?: number;// 合同ID
  constructor(bill_id: number, carport_lease_id: number) {
    this.bill_id = bill_id;
    this.carport_lease_id = carport_lease_id;
  }
}

// 账单财务明细 返回对象
export class CarportBillPayLogListResponse {
  bill_pay_log_list?: CarportBillPayLogDTO[];
  received?: string;
}

// 创建车库租约返回
export class CreateCarportResp {
  carport_lease_id: number;
}

// 租约详情请求
export class CarportLeaseDetailReq {
  carport_lease_id: number;
  is_edit: boolean;

  constructor(carport_lease_id: number, is_edit: boolean) {
    this.carport_lease_id = carport_lease_id;
    this.is_edit = is_edit;
  }
}

// 租约详情返回
export class CarportLeaseDetailResp {
  carport_lease_detail: CarportLeaseDetail;
}

// 租约详情
export class CarportLeaseDetail {
  // 租约id
  carport_lease_id: number;

  // 租约类型
  lease_type: string;

  // 租约状态
  lease_status: string;

  // 合同号
  contract_num: string;

  // 审核状态
  audit_status: string;

  // 签约联系人
  sign_contact_name: string;

  // 签约联系方式
  sign_contact_phone: string;

  // 联系人姓名
  contact_name: string;

  // 联系方式
  contact_phone: string;

  // 性别
  sex: string;

  // 证件类型
  cret_type: string;

  // 证件号
  cret_num: string;

  // 邮编
  zip: string;

  // 地址
  address: string;

  // 开始时间
  start_time: number;

  // 结束时间
  end_time: number;

  // 支付周期
  pay_cycle: number;

  // 联系人是否与签约人相同
  contact_same: boolean;

  // 租约来源
  source_id: number;

  // 销售
  adviser_id: number;

  // 备注
  remark: number;

  // 提前提醒
  remind_value: number;

  // 是否免租优惠
  is_free: boolean;

  // 免租优惠开始日期
  free_start_time: number;

  // 免租优惠结束日期
  free_end_time: number;

  // 车库信息
  carport_info: CarportRentInfo;

  // 上传图片地址
  image_url_list: string[];

  // 紧急联系人
  emergency_contacts: EmergencyContactInfo[];

  // 来源
  source_name: string;

  // 销售
  sellor: string;

  audit_position_name: string;

  business_status: string;
  business_status_desc: string;
  ext_attachment_num: number;
}

// 车库信息
export class CarportRentInfo {
  // 车位id
  carport_info_id: number;

  // 月租金
  month_price: number;

  // 车位押金
  carport_deposit: number;

  // 其他费用
  other_fee: number;

  // 车牌号
  license_number: string;

  // 车库编号
  carport_number: string;
}

// 紧急联系人
export class EmergencyContactInfo {
  contact_name: string;
  contact_phone: string;
}

// 通用租约请求(只包含carport_lease_id)
export class CarportLeaseCommonReq {
  carport_lease_id: number;

  constructor(carport_lease_id: number) {
    this.carport_lease_id = carport_lease_id;
  }
}

// 取消租约返回
export class CarportLeaseCommonResp {
  result: boolean;
}

// 获取车库租约日志列表请求
export class CarportLeaseLogListReq extends PageReq {
  carport_lease_id;
}

// 获取车库租约日志列表返回
export class CarportLeaseLogListResp extends PageResp {
  carport_lease_log_list: CarportLeaseLog[];
}

// 车库租约日志对象
export class CarportLeaseLog {
  create_time: number;
  carport_lease_id: number;
  log_id: number;
  operation_content: string;
  operation_id: number;
  operation_name: string;
  operation_type: string;
}

export class CarportLeaseBaseDTO {
  carport_lease_id?: number;
  lease_type?: string;
  lease_status?: string;
  contract_num?: string;
  audit_status?: string;
  sign_contact_name?: string;
  sign_contact_phone?: string;
  contact_name?: string;
  contact_phone?: string;
  start_time?: number;
  end_time?: number;
  carport_number?: string;
  create_time?: number;
  business_status ?: string;
  business_status_desc?: string;
}


/**
 * @Description:  收款详情请求参数
 * *  收款类型
 * * 1 otherExpense 其它费用
 *
 * 2 renterExpense   房费、押金、水电等
 *
 * 6   tips  提示文案
 * @Author: zhoujiahao
 * @Date: 2018-07-13  16:55
 */
export class CarportGatherAmountDetailRequestParam {
  carport_lease_id?: number;//租约ID
  carport_ids?: Array<number>;//车库ID
  gather_type?: string;//收款类型
  bill_type_ids?: Array<number>;
  refundable_bill?: string;
}

/**
 * @Description:  确认付款 参数
 * @Author: zhoujiahao
 * @Date: 2018-07-13  16:55
 */
export class CarportConfirmPaymentRequest {
  lease_id?: number;//租约ID
  carport_ids?: Array<number>;//车库ID
  payment_total_amount?: string;//应付总金额 （如果选择了预定金的话则应该减去预定金的钱）
  // deductible?: boolean;//预定金是否抵扣
  bill_ids?: Array<number>;//账单ID （未支付和部分支付）
  type_id?: number;//付款类型
  actual_payment_amount?: string;//实际支付金额
  attached_list?: Array<string>;//附件
  remark?: string;//备注： 其它收款时必输
  // book_amount?: string;//预定金金额
  gather_type?: string;//收款类型
  pay_log_no?: string;//支付流水号
  bill_type_code?: string;//当收款类型为 其它类型时此字段不能为空： 水费，电费，其它（备注不能为空）
  bussiness_type?: string;//退房收款时增加的参数：用户辨别当前收款时退房收款目前值为 CHECKOUT
  idempotent_key?:string;
}


/**
 * @Description: 获取合同url
 * @Author: 陆炳彬
 * @Date: 2018/12/10 下午1:50
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/12/10 下午1:50
 */
export class GetContractUrlResp {
  image_url: string;
}

export class CarportLeaseCheckoutListRequest {
  lease_id?: number;
}

export class CarportLeaseCheckoutListResponse {
  carport_lease_checkout_list_dtolist?: Array<CarportLeaseCheckoutListDTO>;
}

export class CarportLeaseCheckoutListDTO {
  // @Remark("车位ID")
  carport_id?: number;
  // @Remark("车位编号")
  carport_num?: string;
  // @Remark("车牌号")
  license_number?: string;
  // @Remark("租约结束时间")
  lease_end_time?: number;
  // @Remark("退租时间")
  checkout_time?: number;
  // @Remark("月租金")
  month_price?: string;
  // @Remark("押金")
  pledge_amount?: string;
  // @Remark("退租类型")
  checkout_type?: string;
}

export class CarportLeaseCheckoutViewRequest {
  // @Remark("租约ID")
  lease_id?: number;
  // @Remark("车位ID")
  carport_checkout_view_dtolist?: Array<CarportCheckoutViewDTO>;
}

export class CarportGeneratePdfRequest {
  lease_id: number;

}

export class CarportCheckoutViewDTO {
  // @Remark("车位ID")
  carport_id?: number;
  // @Remark("车位关系ID")
  carport_relation_id?: number;
  // @Remark("退房类型")
  checkout_type?: string;
}

export class CarportLeaseCheckoutViewResponse {
  // @Remark("租约信息")
  carport_lease_info_dto?: CarportLeaseDetail;
  // @Remark("合计")
  carport_checkout_info_dto?: CarportCheckoutInfoDTO;
  // @Remark("车位列表")
  carport_checkout_port_info_dtolist?: Array<CarportCheckoutPortInfoDTO>;
}

export class CarportCheckoutInfoDTO {

  carport_id?: number;
  // 房间号
  carport_num?: string;
  // 应收总计
  receivable_total?: string;
  // 应退总计
  retreated_total?: string;
  // 应收项
  receivable_items?: Array<ObjectDTO>;
  // 应退项
  retreated_items?: Array<ObjectDTO>;
  // 合计
  amount_total?: string;
  // 未完成收款账单ID集合
  bill_ids?: Array<number>;
}

export class CarportCheckoutPortInfoDTO {
  // @Remark("车位ID")
  carport_id?: number;
  // @Remark("车库租约ID")
  lease_id?: number;
  // @Remark("车库租约ID")
  lease_relation_id?: number;
  // @Remark("退租日期")
  end_time?: number;
  // @Remark("退租类型")
  checkout_type?: string;
  // @Remark("逾期天数")
  overdue_day?: string;
  // @Remark("账单详情")
  carport_checkout_info_dto?: CarportCheckoutInfoDTO;
}

export class CarportLeaseCheckoutConfirmRequest {
  // @Remark("租约ID")
  lease_id?: number;
  // @Remark("退款类型 现金/转账")
  checkout_bill_pay_type_enum?: string;

  refund_name?: string;

  open_bank?: string;

  bank_no?: string;

  remark?: string;
  // @Remark("房间ID")
  checkout_view_dtolist?: Array<CarportCheckoutViewDTO>;
  // @Remark("总金额")
  total?: string;

  // lbb 2019-01-21
  bill_type?: string;
}

export class CarportLeaseCheckoutConfirmResponse {
  carpot_num_list?: Array<string>;
}

// 车库是否在该时间段内被占用请求
export class IsCarportAvaliableReq {
  exclude_carport_lease_id: number;
  start_time: number;
  end_time: number;
  carport_info_id: number;
}

// 车库是否在该时间段内被占用请求
export class IsCarportAvaliableResp {
  is_avaliable: boolean;
}

export class CarportSearchDTO {
  carport_info_id?: number;
  carport_number?: string;
  month_price?: string;
  project_id?: number;
  project_name?: string;
  carport_status?: string;
  create_time?: number;
}

/**
 * @Description: 退房单号
 * @Author: 陆炳彬
 * @Date: 2019/1/22 下午1:46
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/22 下午1:46
 */
export class CarportCheckoutContractExtResp {
  record_num: string;
}
