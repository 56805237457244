import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LeaseService } from '../../../service/lease/lease.service';
import { ContractPreviewExt } from '../../../model/lease/lease.model';
import { BaseInfoService } from '../../../service/common/base-info.service';
import { BaseEnum } from '../../../model/modal/base-model';
import { AuthService } from '../../../service/common/auth.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { WorkModel } from "../../../model/common/common.model";
import { WorkService } from "../../../service/common/work.service";
import { PdfViewComponent } from '../../shared/pdf-view/pdf-view.component';
import {MessageModalInfo} from "../../../model/modal/message-model";

@Component({
  selector: 'app-contract-preview',
  templateUrl: './contract-preview.component.html',
  styleUrls: ['./contract-preview.component.css']
})
export class ContractPreviewComponent implements OnInit {

  // 租约id
  private lease_id: number;

  contract_preview_ext: ContractPreviewExt;

  // 租约状态列表
  lease_status_list: BaseEnum[];

  // 审核状态列表
  audit_status_list: BaseEnum[];

  pdf_url: string;

  load_error: boolean = false;

  iframe;
  @ViewChild(PdfViewComponent, { static: true }) pdfView: PdfViewComponent;


  constructor(private activatedRoute: ActivatedRoute,
              private baseInfoService: BaseInfoService,
              private authService: AuthService,
              private sanitizer: DomSanitizer,
              private router: Router,
              private http: HttpClient,
              private leaseService: LeaseService,
              private workService: WorkService) {
    this.activatedRoute.params.subscribe(params => {
      this.lease_id = params['lease_id'];
    });
  }

  ngOnInit() {

    this.leaseService.getContractPreviewExt(this.lease_id).then(data => {
      this.contract_preview_ext = data;
    });

    this.lease_status_list = this.baseInfoService.getLeaseStatusList();
    this.audit_status_list = this.baseInfoService.getAuditStatusList();

    // 获取合同pdf的URL
    this.leaseService.getContractUrl({ lease_id: this.lease_id }).then(data => {
      if (data && data.image_url) {
        this.pdf_url = data.image_url;
      } else {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "未获取到合同的PDF，请联系管理员处理。"));
      }
    });
  }


  printFrame() {
    this.pdfView.print();
  }

  back() {
    this.router.navigateByUrl('/system_manage/signed/pact/' + this.lease_id);
  }

  downloadPDF() {
    let download_window = window.open('', '_blank');
    this.leaseService.getContractUrl({ lease_id: this.lease_id }).then(data => {
      if (data && data.image_url) {
        download_window.location.href = data.image_url;
      }
    });
  }

  delete(){
    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.type = 2;
    messageModalInfo.title = "操作提示";
    messageModalInfo.cancel = true;
    messageModalInfo.html = `
          <div class="text-md-center">确认删除合同吗？</div>
          <div class="text-md-center">删除后需重新上传合同，未上传合同无法操作合同功能。</div>
        `;
    messageModalInfo.callback.subscribe(() => {
      this.leaseService.delContract({ lease_id: this.lease_id }).then(data => {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, '删除成功！'));
        this.workService.event_bus.emit(new WorkModel(this.workService.type.TAB_CLOSE_SELF));
      });
    });
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
  }
}
