import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {getUserAgent} from '../../../common/common';
import {ModalDirective} from "ngx-bootstrap/modal";
import {PdfViewComponent} from '../pdf-view/pdf-view.component';

@Component({
  selector: 'app-print-preview',
  templateUrl: './print-preview.component.html',
  styleUrls: ['./print-preview.component.css']
})
export class PrintPreviewComponent implements OnInit {

  @Input() src;
  @Input() temp = true;

  @ViewChild('staticModal', { static: true }) modal: ModalDirective;

  canShow;

  @ViewChild(PdfViewComponent, { static: true }) pdfView: PdfViewComponent;

  constructor() {
  }

  ngOnInit() {

    let agent = getUserAgent();
    this.canShow = agent.split("|")[1];
  }


  show() {
    this.modal.show();
  }

  printFrame() {
    this.pdfView.print();
  }

  cclean(event: any) {
    event.stopPropagation();//阻止向上冒泡
  }
}
