<div class="setbox">
  <div class="row">
    <div class="col-12 col-md-4 col-xl-3 item mb10">
      <p class="hei32">查询条件</p>
      <div class="condiv1">
        <select class="w-100" [(ngModel)]="alertType">
          <option value="">请选择报警类型</option>
          <option value="DUE" *ngIf="btn_privileges.ROOM_LEASE_BILL_ALARM && projectConfig?.need_lease_contract == 1">房间欠费</option>
          <option value="NO_CHECKOUT" *ngIf="btn_privileges.PAYBACK_UNDO_ALARM && projectConfig?.need_lease_contract == 1">应退未退</option>
          <option value="FAULT" *ngIf="btn_privileges.ROOM_DEVICE_ALARM && (projectConfig?.ele_type == 'SMART' || projectConfig?.cold_water_type == 'SMART' || projectConfig?.hot_water_type == 'SMART')">设备故障</option>
        </select>
      </div>
    </div>
    <div class="col-12 col-md-5 item mb10">
      <label class="hei32 mr-3">状态</label>
      <div class="form-check form-check-inline hei32 ">
        <label class="checkbox-inline hei32 ">
          <input class="mr5" type="checkbox" (click)="statusSet($event,'WAITING')" value="option1" [(ngModel)]="alertStatus1">
          <label class="form-check-label">未处理</label>
        </label>
      </div>
      <div class="form-check form-check-inline hei32 ">
        <label class="checkbox-inline hei32 ">
          <input class="mr5" type="checkbox" (click)="statusSet($event,'IN_HANDLE')" value="option2" [(ngModel)]="alertStatus2">
          <label class="form-check-label">处理中</label>
        </label>
      </div>
      <div class="form-check form-check-inline hei32 ">
        <label class="checkbox-inline hei32 ">
          <input class="mr5" type="checkbox" (click)="statusSet($event,'OFF')" value="option3" [(ngModel)]="alertStatus3">
          <label class="form-check-label">已关闭</label>
        </label>
      </div>

    </div>
    <div class="col-12 col-md-3 text-right mb10">
      <button class="btn btn-info mr-1" type="button" (click)="search()">
        <i class="fa fa-search"></i>搜索
      </button>
      <button class="btn btn-secondary" type="button" (click)="clear()">
        <i class="fa fa-times"></i>清空条件
      </button>
    </div>
  </div>
  <!-- table -->
  <table class="table table-bordered table-striped">
    <thead>
    <tr>
      <th>报警信息</th>
      <th class="show2">报警类型</th>
      <th class="show2">第一次报警时间</th>
      <th>状态</th>
      <th class="show2">操作</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="!btn_privileges.ALARM_QUERY || riskList?.risk_info_dtos == null || riskList?.risk_info_dtos.length == 0">
      <td class="show1" colspan="5">本次未查询到相关数据</td>
      <td class="show2" colspan="8">本次未查询到相关数据</td>
    </tr>
    <ng-container *ngIf="btn_privileges.ALARM_QUERY && riskList?.risk_info_dtos">
      <tr *ngFor="let item of riskList?.risk_info_dtos  | paginate: {itemsPerPage: riskReq.page_size,
          currentPage: riskReq.page_num, totalItems:riskList?.total, id :'lease' }; let i = index;">


        <td class="show2" *ngIf="item?.alert_type == 'DUE'">{{item?.contract_num}}合同房费将要到期</td>
        <td class="show2" *ngIf="item?.alert_type == 'NO_CHECKOUT'">{{item?.contract_num}}合同房间应该办理退房</td>
        <td class="show2" *ngIf="item?.alert_type != 'DUE' && item?.alert_type != 'NO_CHECKOUT'">{{item?.title}}</td>

        <td class="show2" *ngIf="item?.alert_type == 'DUE'">房间催租提醒</td>
        <td class="show2" *ngIf="item?.alert_type == 'NO_CHECKOUT'">应退未退</td>
        <td class="show2" *ngIf="item?.alert_type != 'DUE' && item?.alert_type != 'NO_CHECKOUT'">设备故障</td>

        <td class="show2">
          {{item?.first_alert_time | date:'yyyy-MM-dd HH:mm:ss'}}
        </td>

        <td>
          <span class="badge badge-pill badge-primary" *ngIf="item?.alert_status == 'WAITING'">未处理</span>
          <span class="badge badge-pill badge-secondary" *ngIf="item?.alert_status == 'OFF'">已关闭</span>
          <span class="badge badge-pill badge-success" *ngIf="item?.alert_status == 'IN_HANDLE'">处理中</span>
        </td>
        <td >
          <button class="btn btn-info" type="button" *ngIf="item?.alert_type == 'DUE' && !btn_privileges.ALARM_VIEW_HANDLE" (click)="showModal(template,item,item?.alert_type)">
            <i class="fa fa-eye"></i>查看
          </button>
          <button class="btn btn-info" type="button" *ngIf="item?.alert_type == 'DUE' && btn_privileges.ALARM_VIEW_HANDLE" (click)="showModal(template,item,item?.alert_type)">
            <i class="fa fa-cogs"></i>处理
          </button>
          <button class="btn btn-info" type="button" *ngIf="item?.alert_type == 'NO_CHECKOUT' && !btn_privileges.ALARM_VIEW_HANDLE" (click)="showModal(template,item,item?.alert_type)">
            <i class="fa fa-eye"></i>查看
          </button>
          <button class="btn btn-info" type="button" *ngIf="item?.alert_type == 'NO_CHECKOUT' && btn_privileges.ALARM_VIEW_HANDLE" (click)="showModal(template,item,item?.alert_type)">
            <i class="fa fa-cogs"></i>处理
          </button>
          <button class="btn btn-info" type="button" *ngIf="item?.alert_type != 'DUE' && item?.alert_type != 'NO_CHECKOUT' && !btn_privileges.ALARM_VIEW_HANDLE" (click)="showModal(template,item,'FAULT')">
            <i class="fa fa-eye" ></i>查看
          </button>
          <button class="btn btn-info" type="button" *ngIf="item?.alert_type != 'DUE' && item?.alert_type != 'NO_CHECKOUT' && btn_privileges.ALARM_VIEW_HANDLE" (click)="showModal(template,item,'FAULT')">
            <i class="fa fa-cogs" ></i>处理
          </button>
        </td>
      </tr>
    </ng-container>

    </tbody>
  </table>
  <div class="text-right pages" *ngIf="riskList && riskList.risk_info_dtos.length">
    <pagination-controls id="lease" (pageChange)="pageChange($event)" directionLinks="true" previousLabel="上一页"
                         nextLabel="下一页" screenReaderPaginationLabel="Pagination"
                         screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"></pagination-controls>
  </div>
</div>


<!-- 处理告警信息 -->
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>处理告警信息</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 col-lg-6 mb10">告警时间：{{riskInfo?.first_alert_time | date:'yyyy-MM-dd HH:mm:ss'}}</div>
      <div class="col-12 col-lg-6 mb10" *ngIf="riskInfo?.alert_type == 'DUE'">报警类型：房间欠费</div>
      <div class="col-12 col-lg-6 mb10" *ngIf="riskInfo?.alert_type == 'NO_CHECKOUT'">报警类型：应退未退</div>
      <div class="col-12 col-lg-6 mb10" *ngIf="riskInfo?.alert_type != 'DUE' && riskInfo?.alert_type != 'NO_CHECKOUT'">报警类型：设备故障</div>
      <div class="col-12 col-lg-6 mb10">最后处理时间：{{riskInfo?.last_handle_time | date:'yyyy-MM-dd HH:mm:ss'}}</div>
      <div class="col-12 col-lg-6 mb10">最后处理人：{{riskInfo?.last_handler_name}}</div>
    </div>
    <div class="con">
      <div class="row" *ngIf="riskInfo?.alert_type == 'DUE'">
        <div class="col-12 mb10">报警内容：</div>
        <div class="col-12 col-lg-6 mb10 pointer">合同号：
          <span class="text-primary pointer"  (click)="to_lease(riskInfo?.lease_id,template)">{{riskInfo?.contract_num}}</span></div>
        <div class="col-12 col-lg-6 mb10">签约人：{{riskInfo?.contact_name}}</div>
        <div class="col-12 col-lg-6 mb10">欠费类型：房费</div>
        <div class="col-12 col-lg-6 mb10">总金额：{{riskInfo?.bill_amount}}</div>
        <div class="col-12 col-lg-6 mb10">合同联系人：{{riskInfo?.contact_name}}</div>
        <div class="col-12 col-lg-6 mb10">联系电话：{{riskInfo?.contact_phone}}</div>
        <div class="col-12 col-lg-6 mb10">应付日期：{{riskInfo?.plan_time | date:'yyyy-MM-dd HH:mm:ss'}}</div>
      </div>
      <div class="row" *ngIf="riskInfo?.alert_type == 'NO_CHECKOUT'">
        <div class="col-12 mb10">报警内容：</div>
        <div class="col-12 col-lg-6 mb10 pointer">合同号：
        <span class="text-primary pointer"  (click)="to_lease(riskInfo?.lease_id,template)">{{riskInfo?.contract_num}}</span></div>
        <div class="col-12 col-lg-6 mb10">签约人：{{riskInfo?.contact_name}}</div>
        <div class="col-12 col-lg-6 mb10">合同联系人：{{riskInfo?.contact_name}}</div>
        <div class="col-12 col-lg-6 mb10">联系电话：{{riskInfo?.contact_phone}}</div>
        <div class="col-12 col-lg-6 mb10">应退日期：{{riskInfo?.plan_time | date:'yyyy-MM-dd HH:mm:ss'}}</div>
      </div>
      <div class="row" *ngIf="riskInfo?.alert_type != 'DUE' && riskInfo?.alert_type != 'NO_CHECKOUT'">
        <div class="col-12 mb10">报警内容：</div>
        <p class="col-12 new-lines mb10">{{riskInfo?.title}}</p>
      </div>
    </div>

    <div class="con">
      <div class="row">
        <div class="col-12 mb10">处理进展：</div>
        <div class="col-12 mb10">
          <div class="height260"  *ngIf="riskInfo?.evolve_dtos.length != 0 ">
            <div class="row">
              <div class="col-12 col-md-9 col-xl-7" *ngFor="let item of riskInfo?.evolve_dtos; let i = index; ">
                <div class="card car-bor">
                  <div class="card-header">
                    <span>{{item?.create_time | date: 'yyyy-MM-dd'}}</span>
                    <span>{{item?.create_time | date: 'HH:mm:ss'}}</span>
                    <span>{{item?.handler}}</span>
                    <span *ngIf="item?.status == 'IN_HANDLE'  && i === 0 ">改变状态为处理中</span>
                    <span *ngIf="item?.status == 'IN_HANDLE' && i !== 0">增加进展</span>
                    <span *ngIf="item?.status == 'OFF'">改变状态为关闭</span>
                  </div>
                  <div class="card-body">
                    <blockquote class="blockquote mb-0">
                      <p class="new-lines">{{item?.remark}}</p>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="con1 ">
      <div class="row mb10">
        <div class="col-12 mb10">增加进展：</div>
        <div class="col-12">
          <textarea class="w-100" rows="3" [(ngModel)]="evolve_remark"></textarea>
          <p class="text-danger">请填写进展内容，不少于3个字符</p>
        </div>
      </div>
    </div>
    <div class="row mb10">
      <div class="col-12">
        <div class="form-check form-check-inline">
          <input class="form-check-input" style="cursor: pointer;" type="radio" value="IN_HANDLE"
                 [(ngModel)]="evolve_status">
          <label class="form-check-label">处理中</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" style="cursor: pointer;" type="radio" value="OFF" [(ngModel)]="evolve_status">
          <label class="form-check-label">已关闭</label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" *ngIf="pri" class="btn btn-primary" (click)="ok_evolve(riskInfo)">保存</button>
    <button type="button" *ngIf="pri" class="btn btn-secondary" data-dismiss="modal" (click)="modalRef.hide()">取消
    </button>
    <button type="button" *ngIf="!pri" class="btn btn-secondary" data-dismiss="modal" (click)="modalRef.hide()">关闭
    </button>
  </div>
</ng-template>
