import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {RequestService} from '../request.service';
import {
  BaseEnum,
  ProvinceCityReqParam,
  LoginAreaResponse, ProvinceCityResponse
} from '../../model/modal/base-model';
import {FormControl, UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {AuthService} from "./auth.service";
import {UserService} from "../user/user.service";
import {LeaseTypeListResponse} from "../../model/booking/booking-list.model";
import {RenterBillTypeListResponse} from "../../model/finance/finance.model";

@Injectable()
export class BaseInfoService {

  private CRET_TYPE_LIST = `${environment.apiHost}common/cretType/list`;
  private PAY_CYCLE_LIST = `${environment.apiHost}common/payCycle/list`;
  private PROVINCE_CITY_LIST = `${environment.apiHost}common/provincecity/list`;
  private RENTER_STATUS_LIST = `${environment.apiHost}common/renter/status/list`;
  private LEASE_LOG_TYPE_LIST = `${environment.apiHost}common/lease/log/list`;
  private INVOICE_TYPE_LIST = `${environment.apiHost}common/invoicetype/list`;
  private BILL_TYPE_OTHER_LIST = `${environment.apiHost}common/bill/type/other/list`;
  // 获取快递公司列表
  private EXPRESS_LIST = `${environment.apiHost}common/express/list`;
  // 获取租约来源列表
  private LEASE_SOURCE_LIST = `${environment.apiHost}common/leaseSource/list`;
  // 获取报表账单列表
  private RPT_BILL_TYPE_LIST = `${environment.apiHost}common/rpt/bill/type/list`;

  private BILL_TYPE_LIST_L_URL = `${environment.apiHost}billtype/list/l`;

  private FIND_PROVINCE_OR_CITY_LIST = `${environment.apiHost}common/findProvinceOrCitylist`;

  // 获取行业类型列表
  private INDUSTRY_LIST = `${environment.apiHost}common/industryList`;

  //获取企业性质列表
  private COMPANY_PROPERTY_LIST = `${environment.apiHost}common/companyPropertyList`;

  private SHOP_TREE_LIST = `${environment.apiHost}common/shop/treeList`;
  private LEASE_TYPE_LIST = `${environment.apiHost}lease/booking/lease/type/list`;

  private RENTER_BILL_TYPE_LIST = `${environment.apiHost}billtype/renter/type/list`;
  private IHOME_LXZ_CONFIG = `${environment.apiHost}ihome/lxz/config`;

  constructor(private requestService: RequestService,
              private authService: AuthService,
              private userService: UserService
  ) {
  }

  /**
   * 获取租约来源列表
   */
  public getLeaseSourceList(): Promise<any> {
    return this.requestService.authGet(this.LEASE_SOURCE_LIST);
  }

  /**
   * @description: 获取证件类型
   * @Author: lubingbin
   * @Date: 2018/3/5
   * @Last Modified by: lubingbin
   * @Last Modified time: 2018/3/5
   */
  getCretTypeList(): Promise<any> {
    return this.requestService.authGet(this.CRET_TYPE_LIST);
  }

  /**
   * @description: 获取支付周期
   * @Author: lubingbin
   * @Date: 2018/3/5
   * @Last Modified by: lubingbin
   * @Last Modified time: 2018/3/5
   */
  getPayCycleList(): Promise<any> {
    return this.requestService.authGet(this.PAY_CYCLE_LIST);
  }

  /**
   * @description: 获取省市区列表
   * @Author: lubingbin
   * @Date: 2018/3/14
   * @Last Modified by: lubingbin
   * @Last Modified time: 2018/3/14
   */
  getProvinceCityList(params: ProvinceCityReqParam): Promise<ProvinceCityResponse> {
    return this.requestService.authGet(this.PROVINCE_CITY_LIST, params);
  }

  /**
   * @Description: 获取发票类型
   * @Author: 陆炳彬
   * @Date: 2018/7/8 下午1:01
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/7/8 下午1:01
   */
  getInvoiceTypeList(): Promise<any> {
    return this.requestService.authGet(this.INVOICE_TYPE_LIST);
  }

  /**
   * @description: 获取租客状态列表
   * @Author: lubingbin
   * @Date: 2018/3/18
   * @Last Modified by: lubingbin
   * @Last Modified time: 2018/3/18
   */
  getRenterStatusList(): Promise<any> {
    return this.requestService.authGet(this.RENTER_STATUS_LIST);
  }

  /**
   * @Description: 获取附加费用列表
   * @Author: 陆炳彬
   * @Date: 2018/7/17 下午2:09
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/7/17 下午2:09
   */
  getBillTypeOtherList(): Promise<any> {
    return this.requestService.authGet(this.BILL_TYPE_OTHER_LIST);
  }


  /**
   * 账单类型列表
   */
  getBillTypeListl(param?: any): Promise<any> {
    return this.requestService.authGet(this.BILL_TYPE_LIST_L_URL, param);
  }

  /**
   * @Description: 获取支票状态枚举列表
   * @Author: 陆炳彬
   * @Date: 2018/9/25 下午4:55
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/9/25 下午4:55
   */
  getCheckList(): BaseEnum[] {
    return [
      new BaseEnum('CHECKED', '已收到支票'),
      new BaseEnum('NO_CHECKED', '未收到支票')
    ];
  }

  /**
   * @Description: 获取租约类型列表
   * @Author: 陆炳彬
   * @Date: 2018/11/8 上午10:46
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/11/8 上午10:46
   */
  getLeaseTypeList(): BaseEnum[] {
    return [
      new BaseEnum('ORIGIN', '新合同'),
      new BaseEnum('RELET', '续约'),
      new BaseEnum('EXCHANGE', '换房'),
    ];
  }

  /**
   * @Description: 获取租约房间状态信息
   * @Author: 陆炳彬
   * @Date: 2018/11/8 下午5:09
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/11/8 下午5:09
   */
  getLeaseRoomStatusList(): BaseEnum[] {
    return [
      new BaseEnum('BOOKING', '预定'),
      new BaseEnum('TO_LEASE', '转租约'),
      new BaseEnum('UNCOMMITTED', '未提交'),
      new BaseEnum('COMMITTED', '已提交'),
      new BaseEnum('EFFECTIVE', '已生效'),
      new BaseEnum('CANCEL', '取消'),
      new BaseEnum('CHECKOUT', '退房'),
      new BaseEnum('DELETE', '删除')
    ];
  }

  /**
   * @Description: 获取租约状态信息
   * @Author: 陆炳彬
   * @Date:  下午5:10
   * @Last Modified by: 陆炳彬
   * @Last Modified time:  下午5:10
   */
  getLeaseStatusList(): BaseEnum[] {
    return [
      new BaseEnum('UNCOMMITTED', '未提交'),
      new BaseEnum('COMMITTED', '已提交'),
      new BaseEnum('EFFECTIVE', '生效中'),
      new BaseEnum('CANCEL', '取消'),
      new BaseEnum('CHECKOUT', '已退租'),
      new BaseEnum('BOOKING', '预定'),
      new BaseEnum('TO_LEASE', '转租约'),
      new BaseEnum('LOSE_EFFICACY', '已失效')
    ];
  }

  getExpenseTypeList(): BaseEnum[] {
    return [
      new BaseEnum('otherExpense', '其它费用'),
      new BaseEnum('renterExpense', '房费、押金、水电等'),
      new BaseEnum('firstRenterDepositExpense', '首期房费+押金'),
      new BaseEnum('firstRenterExpense', '首期房费'),
      new BaseEnum('depositExpense', '押金')
    ];
  }

  /**
   * @Description: 获取审核状态列表
   * @Author: 陆炳彬
   * @Date: 2018/11/8 下午5:12
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/11/8 下午5:12
   */
  getAuditStatusList(): BaseEnum[] {
    return [
      new BaseEnum('WAITING_AUDIT', '待审核'),
      new BaseEnum('AUDIT_SUCCESS', '审核成功'),
      new BaseEnum('AUDIT_FAIL', '审核失败'),
      new BaseEnum('NO_AUDIT', '无需审核'),
    ];
  }

  /**
   * 获取快递公司列表
   */
  getExpressList(): Promise<any> {
    return this.requestService.authGet(this.EXPRESS_LIST);
  }

  // 是否显示错误信息
  isError(is_submit: boolean, container: any, control_name: string, error_code: string): boolean {
    if (!container) {
      return true;
    }

    let form_group: UntypedFormGroup = container as UntypedFormGroup;
    return (is_submit || form_group.controls[control_name].dirty) && form_group.hasError(error_code, [control_name]);
  }

  isArrObjItemError(is_submit: boolean, container: any, i, obj: string, control_name: string, error_code: string): boolean {
    if (!container) {
      return true;
    }
    let form_group: UntypedFormGroup = container as UntypedFormGroup;
    let fa = form_group.controls[obj] as UntypedFormArray;
    let fg = fa.controls[i] as UntypedFormGroup;
    return (is_submit || fg.controls[control_name].dirty)
      && fg.hasError(error_code, [control_name]);
  }


  /**
   * @Description: 获取车库状态列表
   * @Author: 陆炳彬
   * @Date: 2018/12/8 下午2:03
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/12/8 下午2:03
   */
  getCarportLeaseStatusList(): BaseEnum[] {
    return [
      new BaseEnum('UNCOMMITTED', '未提交'),
      new BaseEnum('COMMITTED', '已提交'),
      new BaseEnum('EFFECTIVE', '已生效'),
      new BaseEnum('CANCEL', '取消'),
      new BaseEnum('CHECKOUT', '退房')
    ];
  }

  /**
   * @Description: 获取租约审核状态列表
   * @Author: 陆炳彬
   * @Date: 2018/12/8 下午2:06
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/12/8 下午2:06
   */
  getCarportAuditStatusList(): BaseEnum[] {
    return [
      new BaseEnum('WAITING_AUDIT', '待审核'),
      new BaseEnum('AUDIT_SUCCESS', '审核成功'),
      new BaseEnum('AUDIT_FAIL', '审核失败'),
      new BaseEnum('NO_AUDIT', '无需审核'),
    ];
  }

  /**
   * @Description: 获取报表账单类型列表
   * @Author: 陆炳彬
   * @Date: 2018/12/22 下午1:55
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/12/22 下午1:55
   */
  getRptBillTypeList(params: any) {
    return this.requestService.authGet(this.RPT_BILL_TYPE_LIST, params);
  }

  /**
   * @Description: 获取换房状态列表
   * @Author: 陆炳彬
   * @Date: 2019/1/4 下午2:22
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2019/1/4 下午2:22
   */
  getRoomChangeStatusList() {
    return [
      new BaseEnum('WAITING_AUDIT', '审核中'),
      new BaseEnum('AUDIT_FAIL', '审核拒绝'),
      new BaseEnum('UNCOMPLETED', '未完成'),
      new BaseEnum('CANCEL', '已取消'),
      new BaseEnum('COMPLETED', '已完成'),
      // new BaseEnum('EXPIRE','已过期'),
    ];
  }

  /**
   * @Description: 获取租约业务状态列表
   * @Author: 陆炳彬
   * @Date: 2019/1/22 下午2:59
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2019/1/22 下午2:59
   */
  getLeaseBusinessStatusList() {
    return [
      new BaseEnum('UNCOMMITTED', '未提交'),
      new BaseEnum('CANCEL', '已取消'),
      new BaseEnum('WAITING_AUDIT', '审核中'),
      new BaseEnum('AUDIT_FAIL', '审核失败'),
      new BaseEnum('WAIT_SIGN', '待签署'),
      new BaseEnum('EFFECTIVE', '生效中'),
      new BaseEnum('LOSE_EFFICACY', '已失效'),
      new BaseEnum('CHECKOUT', '已退租'),
    ];
  }

  getLeaseBusinessStatusListRoom(privilege: boolean) {
    if (privilege) {
      return [
        new BaseEnum('UNCOMMITTED', '未提交'),
        new BaseEnum('CANCEL', '已取消'),
        new BaseEnum('WAITING_AUDIT', '审核中'),
        new BaseEnum('AUDIT_FAIL', '审核失败'),
        new BaseEnum('WAIT_SIGN', '待签署'),
        new BaseEnum('EFFECTIVE', '生效中'),
        new BaseEnum('LOSE_EFFICACY', '已失效'),
        new BaseEnum('CHECKOUT', '已退租'),
      ];
    } else {
      return [
        new BaseEnum('WAITING_AUDIT', '审核中'),
        new BaseEnum('WAIT_SIGN', '待签署'),
        new BaseEnum('EFFECTIVE', '生效中'),
      ];
    }

  }

  getColorByBusinessStatus(business_status): string {
    switch (business_status) {
      case 'UNCOMMITTED':
        return '#ffbc00';
        break;
      case 'CANCEL':
        return '#c8ccd7';
        break;
      case 'WAITING_AUDIT':
        return '#3ac4f4';
        break;
      case 'AUDIT_FAIL':
        return '#ff404b';
        break;
      case 'WAIT_SIGN':
        return '#00abe7';
        break;
      case 'EFFECTIVE':
        return '#afef60';
        break;
      case 'LOSE_EFFICACY':
        return '#c8ccd7';
        break;
      case 'CHECKOUT':
        return '#c8ccd7';
        break;
    }
  }


  /**
   * 支付方式
   */
  getBillPayTypeList() {
    return [
      new BaseEnum("WEIXIN", "微信"),
      new BaseEnum("ALIPAY", "支付宝"),
      new BaseEnum("TRANSFER", "转账"),
      new BaseEnum("CASH", "现金"),
      new BaseEnum("POS", "POS"),
      new BaseEnum("CHECK", "支票"),
      /*new BaseEnum("CASH", "现金"),
      new BaseEnum("POS", "POS刷卡"),
      new BaseEnum("ALIPAY", "支付宝转账"),
      new BaseEnum("WEIXIN", "微信转账"),
      new BaseEnum("COMPANY", "企业账户"),
      new BaseEnum("REMITTANCE", "汇款"),
      new BaseEnum("TEMPORARY_AR", "临时AR"),
      new BaseEnum("ENTERPRISE_AR", "企业AR"),
      new BaseEnum("BANK_COMMISSION", "银行卡手续费"),
      new BaseEnum("PREPAY_BALANCE", "预付账户"),
      new BaseEnum("TRANSFER", "转账"),
      new BaseEnum("CHECK", "支票"),
      new BaseEnum("COUPON", "优惠券"),*/
    ];
  }

  findProvinceOrCitylist(): Promise<LoginAreaResponse> {
    return this.requestService.authGet(this.FIND_PROVINCE_OR_CITY_LIST, null);
  }

  /**
   * 获取行业类型列表
   */
  getIndustryInfoList(): Promise<any> {
    return this.requestService.authGet(this.INDUSTRY_LIST);
  }

  /**
   * 获取企业性质列表
   */
  getCompanyPropertyList(): Promise<any> {
    return this.requestService.authGet(this.COMPANY_PROPERTY_LIST);
  }

  /**
   * 商铺状态
   * @returns {[BaseEnum,BaseEnum]}
   */
  getShopStatusList() {
    return [
      new BaseEnum('EFFECTIVE', '使用中'),
      new BaseEnum('SOLDOUT', '已弃用')
    ];
  }

  getColorByShopStatus(shop_status): string {
    switch (shop_status) {
      case 'SOLDOUT':
        return '#c8ccd7';
        break;
      case 'EFFECTIVE':
        return '#afef60';
        break;
    }
  }

  getShopTreeAllList(): Promise<any> {
    return this.requestService.authGet(this.SHOP_TREE_LIST);
  }

  getLeaseTypeList2(): Promise<LeaseTypeListResponse> {
    return this.requestService.authGet(this.LEASE_TYPE_LIST);
  }

  getLeaseDateType() {
    return [
      new BaseEnum('LEASE_END_TIME', '合同到期时间'),
      new BaseEnum('LEASE_SIGN_TIME', '合同签约时间'),
      new BaseEnum('LEASE_START_TIME', '合同开始时间'),
      new BaseEnum('LEASE_CHECKOUT_TIME', '合同退租时间'),
    ];
  }

  getRenterBillTypeList(): Promise<RenterBillTypeListResponse> {
    return this.requestService.authGet(this.RENTER_BILL_TYPE_LIST);
  }
  getIhomeLXZConfig(): Promise<any> {
    return this.requestService.authGet(this.IHOME_LXZ_CONFIG);
  }

}
