import {Component, OnInit, ViewChild} from "@angular/core";
import {WorkbenchService} from '../../../service/workbench/workbench.service';
import {SysUserDTO} from "../../../model/CustomResponse";
import {LocalStorage} from '../../../common/localstorage';
import {Constants} from "../../../common/constants";
import {AuthService} from "../../../service/common/auth.service";
import {WorkService} from "../../../service/common/work.service";
import {ModalDirective} from "ngx-bootstrap/modal";

@Component({
  selector: "app-work-order",
  templateUrl: "./work-order.component.html",
  styleUrls: ["./work-order.component.css"]
})
export class WorkOrderComponent implements OnInit {

  @ViewChild('showMsgModal', {static: true}) showMsgModal: ModalDirective;
  @ViewChild('showConfirmModal', {static: true}) showConfirmModal: ModalDirective;
  @ViewChild('detalisModal', {static: true}) detalisModal: ModalDirective;
  @ViewChild('delModal', {static: true}) delModal: ModalDirective;

  type: string = 'list';
  msgtext: string = '';
  problemTypeList: any = [];
  problemTypeAllList: any = [];
  fileList: any = [];
  attachmentUrlList: any = [];
  attachmentUrlKeyList: any = [];
  detalisData: any = {};
  //项目名称
  projectName: string;
  projectId: any;
  sysUserDTO: SysUserDTO;
  levelName: any = {
    'VERYHOT': '非常紧急',
    'HOT': '紧急',
    'COMMON': '一般'
  };
  searchForm: any = {
    searchKey: '',
    problem_type_name: '',
    workOrderLevel: -1,
    workOrderStatus: -1,
    isSelf: false
  };

  submitForm: any = {
    problem_type_id: -1,
    work_order_level: -1,
    summary: '',
    description: '',
  };
  confirmForm: any = {
    text: '',
    status: ''
  };
  request: any = {
    page_num: 1,
    page_size: 20,
  };

  res: any = {
    order_page_dtolist: [],
    total: 0
  };
  deal_record_list: any = [];
  loading: boolean = false;
  editLoading: boolean = false;
  active_workOrderId: any = -1;
  activeStatus: any = -1;
  refuseText: any = '';
  active_id: any;

  btn_privileges: any = {
    'WORK_ORDER_PAGE_QUERY': false,
    'WORK_ORDER_INFO': false,
    'WORK_ORDER_CONFIRM': false,
    'WORK_ORDER_UPDATE': false,
    'WORK_ORDER_CANCEL': false,
    'WORK_ORDER_SUBMIT': false
  };

  constructor(
    private workbenchService: WorkbenchService,
    private authService: AuthService,
    private workService: WorkService
  ) {
  }

  ngOnInit(): void {// user
    this.authService.getOperatePrivilege(this.btn_privileges);
    this.projectName = this.authService.getProjectName();
    this.projectId = this.authService.getProjectId();
    let localitem = this.authService.getUserInfo();
    if (localitem) {
      this.sysUserDTO = localitem;
    }
    this.getProblemtypeList();
    this.search();
  }

  checkStatus(num) {
    this.refuseText = '';
    this.activeStatus = num;
  }

  getProblemtypeList() {
    this.workbenchService.workOrderProblemtypeAllList().then(res => {
      this.problemTypeAllList = res;
    });
    this.workbenchService.workOrderProblemtypeList().then(res => {
      this.problemTypeList = res;
    });
  }

  search() {
    if (this.loading) {
      return;
    }
    Object.assign(this.request, this.searchForm);
    if (!this.searchForm.problem_type_name) {
      delete this.request.problem_type_name;
    }
    if (this.searchForm.workOrderLevel < 0) {
      delete this.request.workOrderLevel;
    }
    if (this.searchForm.workOrderStatus < 0) {
      delete this.request.workOrderStatus;
    }
    this.request.isSelf = Number(this.request.isSelf);
    this.loading = true;
    this.workbenchService.workOrderList(this.request).then(res => {
      this.loading = false;
      this.res.order_page_dtolist = res.order_page_dtolist || [];
      this.res.total = res.total;
    }).catch((err) => {
      this.loading = false;
    });
  }

  openDetalis(id) {

    this.workbenchService.workOrderDetail({workOrderId: id}).then(res => {
      this.detalisModal.show();
      this.detalisData = res.work_order_details_dto;
      this.deal_record_list = res.deal_record_list || [];
      // if(this.deal_record_list){
      //   for(let items of this.deal_record_list) {

      //   }
      // }
    });
  }

  submitFormInit() {
    this.attachmentUrlList = [];
    this.attachmentUrlKeyList = [];
    if (this.active_workOrderId !== -1) {
      this.submitForm.summary = '';
      this.submitForm.description = '';
      this.submitForm.attachmentUrlList = [];
    } else {
      this.submitForm = {
        problem_type_id: -1,
        work_order_level: -1,
        summary: '',
        description: '',
        attachmentUrlList: []
      };
    }
  }

  submit() {
    if (this.editLoading) {
      return;
    }
    let data = Object.assign({}, this.submitForm);
    data.attachmentUrlList = [];
    if (this.submitForm.problem_type_id < 0) {
      this.workService.showErrorModal('请选择问题类型！');
      return;
    }
    if (this.submitForm.work_order_level < 0) {
      this.workService.showErrorModal('请选择紧急程度！');
      return;
    }
    if (!this.submitForm.summary) {
      this.workService.showErrorModal('请输入问题概要！');
      return;
    }

    data.attachmentUrlList = this.attachmentUrlKeyList.filter(item => item);
    this.editLoading = true;
    if (this.active_workOrderId !== -1) {
      this.workbenchService.workOrderUpdate(data).then(res => {
        this.msgtext = '工单修改成功！';
        this.editLoading = false;
        this.showMsgModal.show();
        this.active_workOrderId = -1;
        this.submitFormInit();
      }).catch(err => {
        this.editLoading = false;
      });
    } else {
      this.workbenchService.workOrderSubmit(data).then(res => {
        this.msgtext = '工单提交成功！';
        this.editLoading = false;
        this.showMsgModal.show();
        this.active_workOrderId = -1;
        this.submitFormInit();
      }).catch(err => {
        this.editLoading = false;
      });
    }
  }

  submitConfirm() {
    if (this.activeStatus === -1) {
      this.workService.showErrorModal('请选择是否解决了您的问题');
      return;
    }
    let data = {
      workOrderId: this.detalisData.work_order_id,
      is_resolve: this.activeStatus,
    };
    if (!this.activeStatus) {
      if (!this.refuseText) {
        this.workService.showErrorModal('请输入具体说明！');
        return;
      }
      data['desc'] = this.refuseText;
    }
    this.workbenchService.workOrderConfirm(data).then(res => {
      if (this.activeStatus) {
        this.msgtext = '确认成功，工单已关闭！';
      } else {
        this.msgtext = '确认成功，等待宿管继续处理！';
      }
      this.showConfirmModal.hide();
      this.detalisModal.hide();
      this.showMsgModal.show();
      this.search();
    });
  }

  workOrderEdit(id) {
    this.active_workOrderId = id;
    this.attachmentUrlList = [];
    this.attachmentUrlKeyList = [];
    this.workbenchService.workOrderInfo({workOrderId: id}).then(res => {
      this.type = 'submit';
      this.attachmentUrlList = res.img_url_list;
      this.attachmentUrlKeyList = res.attachment_info_list.map(item => item.attachment_url);
      Object.assign(this.submitForm, res);
    });
  }

  cancel() {
    // let messageModalInfo = new MessageModalInfo();
    // messageModalInfo.cancel=true;
    // messageModalInfo.operationMessage='确认取消本条工单吗？';
    // messageModalInfo.callback.subscribe(data=>{
    //   this.workbenchService.workOrderCancel({workOrderId: id}).then(res => {
    //     this.msgtext = '取消成功，工单已关闭！';
    //     this.showMsgModal.show();
    //     this.search();
    //   });
    // });
    // this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
    this.workbenchService.workOrderCancel({
      workOrderId: this.active_id
    }).then(res => {
      this.delModal.hide();
      this.msgtext = '取消成功，工单已关闭！';
      this.showMsgModal.show();
      this.search();
    });
  }

  pageChange(currentPage: number): void {
    this.request.page_num = currentPage;
    this.search();
  }

  // 上传图片变化
  fileChange($event, key, urlKey?) {
    if ($event.length === 0) {
      this[key] = [];
      this[urlKey] = [];
      return;
    }
    if ($event && $event.length > 0) {
      this[key] = [];
      let arr = this[urlKey].concat();
      this[urlKey] = [];
      $event.forEach(value => {
        this[key].push(value.url);
        if (value.key) {
          this[urlKey].push(value.key);
        } else {
          let str = value.url.split('?');
          let t = str[0].split('/');
          let imgstr = t[t.length - 1];
          let arrname = arr.filter((item) => {
            let _str = item.substr(-imgstr.length);
            return _str === imgstr;
          });
          this[urlKey].push(arrname[0]);
        }
      });
    }
  }

  openConfirmModal(id) {
    this.detalisData.work_order_id = id;
    this.activeStatus = -1;
    this.showConfirmModal.show();
  }
}
