import { TabletComponent } from '../tablet/tablet.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LeaseService } from "../../../service/lease/lease.service";
import { ElecContratInfo } from "../../../model/lease/lease.model";
import { ActivatedRoute, Router } from "@angular/router";
import { WorkService } from "../../../service/common/work.service";
import { WorkModel } from "../../../model/common/common.model";
import { BaseEnum } from "../../../model/modal/base-model";
import { BaseInfoService } from "../../../service/common/base-info.service";
import {SignDataRequest, SignOnIpadRequest} from "../../../model/signed/sign.model";
import {SignedService} from "../../../service/signed/signed.service";
import {SignDeviceBindingComponent} from "../../sign-device-binding/sign-device-binding.component";
import {ProjectService} from "../../../service/project/project.service";
import {ProjectTemplateRequest, ProjectTemplateResponse} from "../../../model/project/project.model";
import {ModalDirective} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css']
})
export class ContractComponent implements OnInit {

  @ViewChild(TabletComponent, { static: true }) tabletComponent: TabletComponent;
  @ViewChild('staticModal', { static: true }) staticModal: ModalDirective;
  @ViewChild(SignDeviceBindingComponent, { static: true }) signDeviceBindingComponent:SignDeviceBindingComponent;


  lease_id:number;
  title="请签约人在下方写字板内签字";

  imgStr:string;

  //电子合同信息
  electContratInfoResponse: ElecContratInfo;

  // 支付周期
  pay_cycle_list: BaseEnum[];

  // //租赁总月份
  // totalMonth: number;

  template_html = "读取合同模板中，请稍后...";

  constructor(private leaseService: LeaseService,
               private activatedRoute: ActivatedRoute,
               private workService: WorkService,
               private router: Router,
               private baseInfoService: BaseInfoService,
               private signedService: SignedService,
              private projectService: ProjectService) {
    if (this.activatedRoute.params['value']['lease_id']) {
      this.lease_id = this.activatedRoute.params['value']['lease_id'];
    }

  }

  ngOnInit() {
    //获取支付周期枚举类
    this.baseInfoService.getPayCycleList().then(data => {
      this.pay_cycle_list = data.base_enum_dto_list;
    });

    //获取电子合同信息
    this.leaseService.getELecContractInfo(this.lease_id).then(res => {
      this.electContratInfoResponse = res;

      if(this.electContratInfoResponse.lease_status === 'EFFECTIVE'){//租约已生效:则跳转至合同预览页
        this.router.navigateByUrl('/system_manage/signed/contract/preview/' + this.lease_id);
      }else{
        //获取待签字的合同内容
        const req  = new ProjectTemplateRequest();
        let leaseIdArray: Array<string> = [this.lease_id.toString()];
        req.file_type = 'CONTRACT';
        req.param_map = {"leaseId": leaseIdArray};
        this.projectService.getProjectTemplateConfigure(req).then(data => {
          if(data && data.template_content){
            this.template_html = data.template_content;
          }
        });
      }
    });

  }

  calMonths(startDate: Date, endDate: Date){
    let startMonth = startDate.getMonth();
    let endMonth = endDate.getMonth();
    let spaceMonth = ((endDate.getFullYear()*12+endMonth) - (startDate.getFullYear()*12+startMonth));
    //判断开始日期是不是月份第一天，结束日期是不是月份最后一天，是的话，spaceMonth+1
    let startYear = startDate.getFullYear();
    let startMonthFirstDay = new Date(startYear, startMonth,1);
    let endYear = endDate.getFullYear();
    let endMonthLastDay = new Date(endYear, endMonth+1, 0);
    if(startMonthFirstDay.getTime() === startDate.getTime() && endMonthLastDay.getTime() === endDate.getTime()){
      spaceMonth+=1;
    }
    return spaceMonth;
  }

  commitSign(imgStr:string){
    this.imgStr = imgStr;
    if(!this.imgStr){
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请先填写签名后再点击提交！'));
      return false;
    }
    //电子合同上传
    this.leaseService.contractConfrim({leaseId:this.lease_id,
                                        imgStr:this.imgStr}).then(data => {
      this.staticModal.show();
    });
  }


  // 在ipad上签名
  signOnIpad(){
    let request = new SignOnIpadRequest();
    request.sign_router_link_type = 'CONTRACT';
    request.router_link = this.router.url;
    request.response_data = this.electContratInfoResponse;
    let signDataRequest = new SignDataRequest();
    signDataRequest.lease_id = this.lease_id;
    request.sign_data_request = signDataRequest;
    request.computer_no = localStorage.getItem('PC_UUID');
    /*this.signedService.signOnIpad(request).then(data => {
      console.info(data);
    });*/
    // 打开连接弹窗
    this.signDeviceBindingComponent.openModel(request,1);
  }

  // 判断当前设备是否是ipad
  isPad(): boolean{
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone",
      "SymbianOS", "Windows Phone",
      "iPad", "iPod"];
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        return true;
      }
    }
    return false;
  }

}
