import {ApprovalTaskDTO} from "../approv/approv.model";
import {LeaseRoomBillListDTO} from "../finance/bill.model";
import {PageReq, PageResp} from '../page/page';
import {RoomInfoDTO} from "../room/room.model";
import {EmergencyContactInfoDTO, LeaseSubjectInfoDTO} from "../booking/booking-detail.model";
import {LeaseRenterInfoDTO} from "../renter/renter.model";
import {CheckoutRoomViewDTO} from "../signed/checkout.model";
import {SignatoryDTO} from "../workbench/workbench.model";

export class LeaseBaseInfoDTO {
  //@Remark("租约ID")
  lease_id?: number;
  lease_type?: string;
  // @Remark("PERSON 个人 COMPANY 企业")
  lease_subject_type?: string;
  //@Remark("合同编号")
  contract_num?: string;
  //@Remark("租客类型 SINGLE:个人  COMPANY:企业")
  renter_type?: string;
  //@Remark("租客姓名(如果是企业签约，这里是联系人姓名)")
  renter_name?: string;
  //@Remark("租客手机号")
  renter_phone?: string;
  //@Remark("企业id(仅当企业签约时，必填)")
  company_id?: number;
  //@Remark("提醒值")
  remind_value?: number;
  //@Remark("租约开始时间")
  start_time?: number;
  //@Remark("租约结束时间")
  end_time?: number;
  //@Remark("支付周期(月为单位 季度支付为4 年为12 )")
  pay_cycle?: number;
  //@Remark("收租提前天数")
  pay_before_day?: number;
  //@Remark("上级租约(续租使用)")
  parent_lease_id?: number;
  //@Remark("渠道来源ID")
  source_id?: number;
  //@Remark("租约状态(已生效 已取消 审核中 审核失败)")
  lease_status?: string;
  //@Remark("管家id")
  adviser_id?: number;
  //@Remark("创建人id")
  create_id?: number;
  //@Remark("备注")
  remark?: string;
  //@Remark("管家名")
  management_name?: string;
  //@Remark("销售员")
  sales_name?: string;
  //@Remark("是否为退房待审核")
  // check_out: boolean;

  down_payment?: LeaseDownPaymentDTO;

  // @Remark("合同联系人")
  contact_name?: string;
  // @Remark("合同联系电话")
  contact_phone?: string;
  // @Remark("紧急联系人")
  emergency_contact_name?: string;
  // @Remark("紧急联系电话")
  emergency_contact_phone?: string;
  // @Remark("租约联系人")
  lease_contact_name?: string;
  // @Remark("租约联系电话")
  lease_contact_phone?: string;
  lease_step: string;
  cret_type?:string;
  cret_num?:string;
  company_name?:string;

}

// 租约详情
export class LeaseInfoDetail extends LeaseBaseInfoDTO {
  // lease_id?: number;
  lease_type?: string;
  // contract_num?: string;
  // renter_type?: string;
  // renter_name?: string;
  remind_type?: string;
  // remind_value?: string;
  // renter_phone?: string;
  // company_id?: number;
  company_name?: string;
  // start_time?: number;
  // end_time?: number;
  // pay_cycle?: number;
  // source_id?: number;
  // lease_status?: string;
  // adviser_id?: number;
  // create_id?: number;
  // remark?: string;
  // sales_name?: string;
  // create_name?: string;
  lease_room_list?: LeaseRoomQueryDTO[];
  lease_audit_record_list?: LeaseAuditRecordDTO[];
  overdue?: boolean;
  renewal?: boolean;
  source_name?: string;
  audit?: boolean;
  credential_num?: string;
  lease_start?: boolean;
  for_renewal?: boolean;
  can_check_out?: boolean;
  // management_name?: string;
  need_down_payment?: boolean;
  // down_payment?: LeaseDownPaymentDTO;
}

export class LeaseRoomInfoDTO {
  lease_room_status?: string;
  room_id?: number;
  room_name?: string;
  lease_room_id: number;
  room_deposit?: string;
  rent_type?: string;
  month_rent?: string;
  room_type_name?: string;//床型
  room_num?: string;//房间号
  custom_rent_list: LeaseCustomRentDTO[];
  expense_list?: LeaseExpenseInfoDTO[];
  renter_list?: any[];
  bed_num?: number;
  asset_confirm?: string;
  check_sex: boolean;
  checkout_record_number: string;
  record_number_ext: boolean;
  is_change_room: boolean;
  room_change_record_id: string;
  parent_lease_room_id?: number;
  checkout_time?: number;
  need_room_goods_list?: number;

  ele_check_in_number?: string;
  //冷水表读数
  cold_check_in_number?: string;
  //热水表读数
  hot_check_in_number?: string;

  ele_checkout_number?: string;
  //冷水表读数
  cold_checkout_number?: string;
  //热水表读数
  hot_checkout_number?: string;
  // 住客租金
  renter_deposit?: string;
}

export class LeaseExpenseInfoDTO {
  id?: number;
  lease_id?: number;
  lease_room_id?: number;
  gather_type?: string;
  bill_type_id?: number;
  expense_value?: string;
  expense_name?: string;
}

//审核信息
export class LeaseAuditRecordDTO {
  auditRecordId?: number;
  lease_id?: number;
  record_type?: string;
  lease_room_ids?: number[];
  submit_user_id?: number;
  record_user_id?: number;
  record_status?: string;
  remark?: string;
  submit_user_name?: string;
  record_user_name?: string;
  create_time?: Date;
  last_modify_time?: Date;
  status?: string;
}

export class LeaseRoomBaseInfoDTO {
  lease_room_id?: number;
  lease_id?: number;
  room_id?: number;
  month_rent?: number;
  rent_type?: string;
  room_deposit?: string;
  lease_room_status?: string;
  payment_amount?: string;
}

export class LeaseRoomQueryDTO extends LeaseRoomBaseInfoDTO {
  room_info_query_dto?: RoomInfoQueryDTO;
  discount?: string;
  custom_rent_list?: LeaseCustomRentDTO[];
  lease_expense?: LeaseExpenseInfoDTO[];
  change_rooms?: boolean;
  service_status?: string;
}

export class RoomInfoQueryDTO {
  project_id?: number;
  unit_id?: number;
  room_id?: number;
  room_name?: string;
  room_num?: number;
  bedroom_num?: number;
  hall_num?: number;
  kitchen_num?: number;
  base_price?: string;
  room_area?: string;
  floor?: number;
  room_status?: string;
  rent_type?: string;
  project_unit_dto?: ProjectUnitDTO;
  hotel_project_base_dto?: HotelProjectBaseDTO;
  room_type_base_dto?: RoomTypeBaseDTO;
}

// 灵活租金信息
export class LeaseCustomRentDTO {
  start_time?: number;
  end_time?: number;
  custom_rent?: string;
  custom_id?: number;
  lease_room_id?: number;
  month_interval?: number;
}

// 单元
export class ProjectUnitDTO {
  id?: number;
  project_id?: number;
  project_name?: string;
  unit_name?: string;
  total_floor?: string;
  unit_status?: string;
  project_unit_floor_dtolist?: Array<ProjectUnitFloorDTO>;
}

export class ProjectUnitFloorDTO {

  floor?: string;
  logic_floor?: number;

  room_info_dtolist?: Array<RoomInfoDTO> ;
}

// 项目
export class HotelProjectBaseDTO {
  id?: number;
  project_type?: string;
  project_name?: string;
  project_full_name?: string;
  project_address?: string;
  latitude?: number;
  longitude?: number;
  city_id?: number;
  region_id: number;
  region_name?: string;
  province_id?: number;
  project_status?: string;

  city_name?: string;
  province_name?: string;
}

// 房源类型
export class RoomTypeBaseDTO {
  id?: number;
  project_id?: number;
  room_type_name?: string;
  base_price?: string;
  room_type_status?: string;
  bed_num?: number;
}

// 装修风格类型
export class RoomGroupStyleDTO {
  id?: number;
  room_group_style_name?: string;
}


// 房型+可用房间数
export class RoomTypeWithAvaliableNum {
  room_type?: RoomTypeBaseDTO;
  room_info_list?: RoomInfoDTO[];
  avaliable_num?: number;
}

// 租约列表请求信息
export class LeaseListReqParam {
  roomName?: string; //房源名称
  roomNumber?: number; //房间号
  contact?: string; // 承租人
  phone?: string; // 承租人电话
  status?: string; //租约状态
  leaseType?: string; //租约类型
  pageNum?: number;
  pageSize?: number;
}

export class LeaseCustomRentBaseInfoDTO {
//@Remark("开始时间")
  start_time: string;
//@Remark("结束时间")
  end_time: string;
//@Remark("租金")
  custom_rent: string;
//@Remark("灵活租约ID")
  custom_id: number;
//@Remark("租约房间ID")
  lease_room_id: number;
}

//日志内容
export class LeaseLog {
  create_time: number;
  lease_id: number;
  log_id: number;
  operation_content: string;
  operation_id: number;
  operation_peoson: string;
  operation_type: string;
}

//租约预定金
export class LeaseDownPaymentDTO {
  //预定金金额
  payment_amount: number;
  //支付类型
  pay_type_id: number;
  //预定金凭证图片列表
  image_url_list: Array<string>;
}

export class LeaseListResponse extends PageResp {
  // @Remark("租约集合")
  lease_list: Array<LeaseInfoDetail>;
  // @Remark("是否是预定单页面")
  is_booking: boolean;
}

//租约来源
export class LeaseSource {
  //来源id
  source_id: number;
  //来源名
  source_name: string;
  //来源类型
  source_type: string;
  //类型名称
  type_name: string;
}

export class LeaseSourceGroup {
  //来源类别
  source_type: string;
  //类别名称
  type_name: string;
  //来源列表
  lease_source_list: LeaseSource[];
}

//-------------new lbb
//房间缓存
export class RoomListCache {
  room_id: number;
  room_name: string;
  room_deposit: number;
  room_type_id: number;
  room_type_name: string;
  room_area: string;
  bed_num: number;
  rent_type: string;
  base_price: number;
  month_rent: number;
  custom_rent_list: CustomRentInfo[];
  custom_rent_list_filled: boolean = false;
  expense_list: ExpenseInfo[]; // 额外费用列表
  renter_list: RenterInfo[];
  check_sex: boolean;
  renter_deposit;
  need_renter_deposit: boolean;
  avaliable_checkin_num: number;
}

// 灵活租金
export class CustomRentInfo {
  month?: number;
  custom_rent?: number;

  constructor(month: number, custom_rent?: number) {
    this.month = month;
    this.custom_rent = custom_rent;
  }
}

export class CustomRentInfoWithIndex {
  index: number;
  custom_rent_list: CustomRentInfo[];

  constructor(index: number, custom_rent_list: CustomRentInfo[]) {
    this.index = index;
    this.custom_rent_list = custom_rent_list;
  }
}

// 额外费用
export class ExpenseInfo {
  gather_type: string;
  bill_type_id: number;
  expense_name: string;
  expense_value: number;

  constructor(gather_type: string, expense_name?: string, expense_value?: number) {
    this.gather_type = gather_type;
    this.expense_name = expense_name;
    this.expense_value = expense_value;
  }
}

export class ExpenseInfoWithIndex {
  index: number;
  expense_list: ExpenseInfo[];

  constructor(index: number, expense_list: ExpenseInfo[]) {
    this.index = index;
    this.expense_list = expense_list;
  }
}

// 入住人信息
export class RenterInfo {
  info_id: number;
  renter_status: string;
  name: string;
  sex: string;
  phone: string;
  tel_conceal?: string;
  register_vip: boolean;
  cret_type: string;
  cret_num: string;
  cret_num_conceal?: string;
  disable: boolean = false;
  operate: string;
}

export class RenterInfoWithIndex {
  index: number;
  renter_list: RenterInfo[];

  constructor(index: number, renter_list: RenterInfo[]) {
    this.index = index;
    this.renter_list = renter_list;
  }
}

/**
 * @Description: 查询房间是否可用请求
 * @Author: 陆炳彬
 * @Date: 2018/9/13 下午4:24
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/9/13 下午4:24
 */
export class IsRoomAvaliableReq {
  start_time: number;
  end_time: number;
  room_ids: number[];
  exclude_lease_id: number;
}

/**
 * @Description: 日志列表请求
 * @Author: 陆炳彬
 * @Date: 2018/9/25 下午10:54
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/9/25 下午10:54
 */
export class LogListReq extends PageReq {
  lease_type?: string;
  lease_id?: number;
  relet_id?: number;
}

/**
 * @Description: 批量更改价格
 * @Author: 陆炳彬
 * @Date: 2018/10/30 下午1:46
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/10/30 下午1:46
 */
export class BatchPriceSetResult {
  deposit: number;
  renter_deposit:number;
  month_rent: number;
  rent_type: string;
  custom_rent_list: CustomRentInfo[];
}

/**
 * 电子合同信息
 */
export class ElecContratInfo {
  project_name: string;
  contract_num: string;
  audit_status: string;
  lease_status: string;
  room_name: string;
  start_time: Date;
  end_time: Date;
  month_rent: number;
  deposit: number;
  cold_water_fee: number;
  hot_water_fee: number;
  elec_fee: number;
  pay_cycle: number;
  outstanding_amount_percent: number;
  lease_subject_info_dto: LeaseSubjectInfoDTO;
  emergency_contact_info_dtos: EmergencyContactInfoDTO[];
  lease_renter_dtos: LeaseRenterInfoDTO[];
}

/**
 * @Description: 合同预览额外信息
 * @Author: 陆炳彬
 * @Date: 2018/11/8 下午5:03
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/11/8 下午5:03
 */
export class ContractPreviewExt {
  contract_num: string;
  lease_status: Date;
  audit_status: Date;
  business_status: string;
  contract_effective_type: string;
}

export class LeaseWithinTenDaysResponse extends PageResp {
  within_ten_days_list: LeaseWithinTenDaysDTO[];
  signatory_dtolist?: Array<SignatoryDTO>;
}

export class LeaseWithinTenDaysRequest extends PageReq {
  alert_type?: string;
  room_name: string;
  contract_num?: string;
}

export class LeaseWithinTenDaysDTO {
  // @Remark("租约ID")
  lease_id: number;
  // @Remark("合同号")
  contract_num: string;
  // @Remark("到期天数")
  within_days: number;
  // 签约人名称
  sign_name: string;
  lease_overdue: boolean;
  frozen_pwd_completed_lease: boolean;
}

export class PrepayArrearageResponse extends PageResp {
  prepay_arrearage_list?: PrepayArrearageDTO[];
}

export class PrepayArrearageDTO {
  room_name?: string;
  arrearage_amount?: string;
}


export class SubleaseSelectResponse {
  sublease_select_dtolist?: Array<SubleaseSelectDTO>;
}

export class SubleaseSelectDTO {
  checkout_time?: number;
  checkout_room_view_dtolist?: Array<CheckoutRoomViewDTO>;
  roomCheck?: boolean = false;
}

export class CompanyPrepayInfoReq {
  lease_id: number;
}

export class CompanyPrepayCreateReq {
  lease_id: number;
  limit: number;
  arrears_percent?: number;
}

export class CompanyPrepayInfoRes {
  // @Remark("充值总额")
  total?: string;
  // @Remark("已使用金额")
  used?: string;
  // @Remark("余额")
  left?: string;
  // @Remark("阈值")
  limit?: string;
  // @Remark("租约类型")
  lease_type: string;
  // 欠费额度
  arrears_percent: number;
}

export class CompanyPrepayDetailRequest extends PageReq {
  lease_id?: number;
  start_time?: number;
  end_time?: number;
}


export class CompanyPrepayDetailDTO{
  create_time: Date;
  service_type: string;
  total: string;
  room_name: string;
}

export class CompanyPrepayDetailResponse extends PageResp {
  company_prepay_detail_dtos?: CompanyPrepayDetailDTO[];
}
export class AuditPersonResponse{
  audit_person_dtos?: Array<AuditPersonDTO>;
}
export class AuditPersonDTO{
  id: number;
  user_name: string;
  user_phone: string;
}

export class LeaseContactRequest{
  lease_id: number;
  contact_name: string;
  contact_phone: string;
  audit_person_id: number;
  old_contact_name: string;
  old_contact_phone: string;
  audit_person_phone: string;
  model_code: string;
}

export class LeaseContactResponse{
  result: string;
}
export class ModelCodeRequest{
  user_id: number;
  mobile: string;
  code_type: number;
}

export class CompanyPrepayRechargeRecordRequest {
  lease_id: number;
}

export class CompanyPrepayRechargeRecordResponse {
  company_prepay_record_dtos : Array<CompanyPrepayRecordDTO>;
}

export class CompanyPrepayRecordDTO{
  operator: string;

  amount: string;

  pay_time: Date;

  pay_type: string;
}

export class RoomListBuffer {
  room_id: number;
  room_name: string;
  room_deposit: number;
  room_type_id: number;
  room_type_name: string;
  room_area: string;
  public_area:string;
  covered_area:string;
  bed_num: number;
  rent_type: string;
  base_price: number;
  month_rent: number;
  custom_rent_list: CustomRentInfo[];
  expense_list: ExpenseInfo[];
  renter_list: RenterInfo[];
  check_sex: boolean;
  bottom_price:number;
  data_type:string;

  // 记录房间原始数据用于比较
  old_room_type_id: number;
  old_base_price: number;
  old_bottom_price: number;
  old_room_area: string;
  old_room_type_name: string;
  old_public_area:string;
  old_covered_area:string;

  // 可更改字段校验
  check_base_price: boolean = true;
  check_bottom_price:boolean = true;
  check_room_area: boolean = true;
  check_public_area: boolean = true;
  check_covered_area: boolean = true;

  // css
  base_price_css: string;
  bottom_price_css: string;
  room_area_css: string;
  public_area_css:string;
  covered_area_css:string;

}

export class WaitSignContractDTO {
  // @Remark("租约ID")
  lease_id?: number;
  // @Remark("租约号")
  contract_no?: string;
  // @Remark("开始时间")
  start_time?: number;
  // @Remark("结束时间")
  end_time?: number;

  // @Remark("签约人")
  sign_name?: string;

  // @Remark("联系人")
  contract_name?: string;
  // @Remark("联系电话")
  contract_tel?: string;

  // @Remark("距离签约日期天数差")
  count_down_day?: number;
}

export class WaitSignContractListResponse extends PageResp {
  wait_sign_contract_dtolist?: Array<WaitSignContractDTO> ;
}



export class LeaseSubjectResponse {

  // @Remark("签约主体类型")
  subject_type?: string;
  // @Remark("公司名称")
  company_name?: string;
  // @Remark("签约人姓名")
  name?: string;
  // @Remark("联系方式")
  tel?: string;
  cret_type?: string;
  // @Remark("证件号")
  cret_num?: string;
  // @Remark("上传图片地址")
  image_url_list?: Array<string>;
  // @Remark("紧急联系人")
  emergency_contacts?: Array<EmergencyContactInfoDTO> ;
  // @Remark("变更原因")
  change_reason?: string;
}

export class LeaseSubjectRequest {
  lease_id?: number;
  // @Remark("签约主体类型")
  subject_type?: string;
  // @Remark("公司名称")
  company_name?: string;
  // @Remark("签约人姓名")
  name?: string;
  // @Remark("联系方式")
  tel?: string;

  cret_type?: string;
  // @Remark("证件号")
  cret_num?: string;
  // @Remark("上传图片地址")
  image_url_list?: Array<string>;
  // @Remark("紧急联系人")
  emergency_contacts?: Array<EmergencyContactInfoDTO> ;
  // @Remark("变更原因")
  change_reason?: string;

  imgs?: Array<string>;
}

export class ApprovalResponse {
  approv_num?: string;
}


 // audit

export class LeaseSubjectChangeDTO {
  // @Remark("签约主体类型")
  subject_type?: string ;
  // @Remark("租约ID")
  lease_id?: number;
  // @Remark("签约人姓名/联系人")
  name?: string;
  // @Remark("公司名称")
  company_name?: string;
  // @Remark("联系方式")
  tel?: string;
  // @Remark("证件号")
  cret_num?: string;
  // @Remark("证件类型")
  cret_type?: string;
  // @Remark("上传图片地址")
  image_url_list?: Array<string>;
  // @Remark("紧急联系人")
  emergency_contacts?: Array<EmergencyContactInfoDTO> ;
  // @Remark("变更原因")
  change_reason?: string;
  // @Remark("创建时间")
  created_time?: number;
  // @Remark("创建人id")
  create_id?: number;
  // @Remark("最后修改时间")
  last_modify_time?: number;
  // @Remark("修改人id")
  last_modify_id?: number;
}

export class LeaseAuditFlowBaseResponse {
  appro_record_info_id?: number;
  process_instance_id?: string;
  lease_id?: number;
  // @Remark("合同号")
  contract_num?: string;
  // @Remark("签约人/公司")
  name?: string;
  // @Remark("合同开始时间")
  start_time?: number;
  // @Remark("合同结束时间")
  end_time?: number;
  // @Remark("变更内容")
  change_type?: string;
  // @Remark("提交人")
  submit_name?: string;
  // @Remark("提交时间")
  submit_time?: number;

  // @Remark("审批单号")
  approv_num?: string;
  // @Remark("变更原因")
  change_reason?: string;

  attachment_list?: Array<string>;
}

export class LeaseSubjectFlowAuditResponse extends LeaseAuditFlowBaseResponse {
  // @Remark("变更前信息")
  change_before_dto?: LeaseSubjectChangeDTO ;
  // @Remark("变更后信息")
  change_after_dto?: LeaseSubjectChangeDTO ;

  // @Remark("上传图片地址")
  image_url_list?: Array<string> ;

  project_name?: string;
}

export class ApprovEditRequest {
  // @Remark("审批表主键id")
  // @NotNull(message = "主键ID不能为空")
  appro_record_info_id?: number;

  // @Remark("流程任务ID")
  task_id?: number;

  // @Remark("流程意见-（同意/拒绝）")
  agree?: boolean;
  comment?: string;
}

export class LeaseBillFlowAuditDTO extends LeaseRoomBillListDTO {
  after_amount?: string;
  percent?: string;
}

export class LeaseBillFlowAuditResponse extends LeaseAuditFlowBaseResponse {
  lease_bill_flow_audit_dtolist?: Array<LeaseBillFlowAuditDTO>;

  balance?: string;

  balance_percent?: string;

  project_name?: string;
}

export class LeaseLogRequest extends PageReq {
  lease_id?: number;
}

export class FlowLeaseLogDTO extends ApprovalTaskDTO {
  runtime_position?: string;

  approv_status_name?: string;
}

export class FlowLeaseLogResponse extends PageResp {
  flow_lease_log_list?: Array<FlowLeaseLogDTO> ;
}

export class FirstRentBillPayResponse {
  is_first_rent_bill_pay?: boolean;
  remaining_days?: number;
  end_date?: Date;
}

export class RoomAvailableTimeResponse {
  has_room_available_time?: boolean;
  white_list_days?: number;
  pay_end_date?: Date;
  change_reason?: String;
}

export class ChangeRoomAvailableDaysRequest {
  lease_id?: number;
  room_available_days?: number;
  change_reason?: String;
  pay_end_date?: Date;

  imgs?: Array<string>;
}

export class ChangeRoomAvailableDaysFlowAuditResponse extends LeaseAuditFlowBaseResponse {
  // @Remark("变更前信息")
  change_before_dto?: RoomAvailableDaysChangeDTO ;
  // @Remark("变更后信息")
  change_after_dto?: RoomAvailableDaysChangeDTO ;

  project_name?: string;
}

export class RoomAvailableDaysChangeDTO{
  lease_id?: number;
  room_available_days?: number;
  pay_end_date?: Date;
}

export class CheckInLimitResponse extends PageResp{
  checkin_limit_lease_dtolist?: Array<CheckInLimitLeaseDTO>;
}

export class CheckInLimitDetailResponse {
  checkin_limit_lease_dtolist?: Array<CheckInLimitLeaseDTO>;
}

export class CheckInLimitLeaseDTO{
  // @Remark("合同号")
  contract_num?: string;
  // @Remark("签约人/公司")
  name?: string;
  // 欠费方
  owe_name?: string;
  // 账单类型
  bill_type?: string;
  // 应付时间
  plan_pay_time?: Date;
  // 宽限天数
  room_available_days?: number;
  // 限制内容
  limit_content?: string;
  // 状态
  limit_status?: string;
  // 距离执行天数
  limit_days?: number;
  lease_id?: number;
  bill_start_time?: Date;
  bill_end_time?: Date;
}

export class CheckoutNotFinishDTO {
  // @Remark("租约ID")
  lease_id?: number;
  // @Remark("合同号")
  contract_num?: string;
  // @Remark("签约主体")
  subject_name?: string;
  // @Remark("退房时间")
  checkout_time?: number;
  // @Remark("未结算房间数")
  room_num?: number;
  // @Remark("未结算房间")
  room_name?: string;
  // @Remark("退房时间")
  checkout_between_day?: number;
  // @Remark("状态（未处理 WAITING 处理中 PROCESSING）")
  status?: string;
}

export class CheckoutFinanceUncheckListResponse extends PageResp {
  checkout_not_finish_dtolist?: Array<CheckoutNotFinishDTO>;
}

export class CheckoutFinanceUncheckListRequest extends PageReq {
  room_name?: string;
  contract_num?: string;
}

export class CheckoutFinanceNotFinishDTO {
  // @Remark("租约ID")
  lease_id?: number;
  // @Remark("合同号")
  contract_num?: string;
  // @Remark("签约主体")
  subject_name?: string;
  // @Remark("未结算房间（数）")
  room_name?: string;
  // @Remark("未结算房间数")
  room_num?: number;
  // @Remark("退房单ID")
  checkout_record_id?: number;
  // @Remark("退房单号")
  checkout_record_num?: string;
  // @Remark("退房单金额")
  checkout_record_total?: string;
  // @Remark("退房单最晚确认时间时间差")
  checkout_confirm_end_time?: number;
  // @Remark("状态（未确认 1 未操作 2）")
  status?: string;
  bill_ids?: Array<number>;
  pay_total?: string;
  checkout_confirm_end_time_new?:string;
  room_ids?: Array<number>;
  lease_room_ids?: Array<number>;

}

export class CheckoutFinanceUnconfirmListResponse extends PageResp{
  checkout_finance_not_finish_dtos?: Array<CheckoutFinanceNotFinishDTO>;
}

export class ClientNameIsExistResponse {
  exist?:boolean;
  client_info_list?:Array<ClientInfoModel>;
}
export class ClientInfoModel{
  clien_id?:number;
  client_name?:string;
  certificate_type_id?:string;
  cert_code?:string;
  client_type_id?:number;
  recommend?:string;
  address?:string;
  status?:string;
  company_codes?:string;
}

export class LogListResponse extends PageResp{
  lease_log_dtolists?: Array<LeaseLog>;
}

export class FrozenPasswordFlowAuditResponse extends LeaseAuditFlowBaseResponse {
  contract_num?: string;
  sign_name?: string;
  frozen_type?: string;
  room_name?: string;
}



export class RenterCheckoutNotFinishRequest extends PageReq {

}
export class RenterCheckoutNotFinishModel {
  record_id?: number;
  // @Remark("合同号")
  contract_no?: string;
  // @Remark("房间号")
  room_name?: string;
  // @Remark("住客姓名")
  name?: string;
  // @Remark("退宿日期")
  checkout_time?: number;
  // @Remark("挂账时长（天）")
  time?: number;
}
export class RenterCheckoutNotFinishResponse extends PageResp {
  renter_checkout_wait_finance_confirm_dtos?: Array<RenterCheckoutNotFinishModel>;
}

export class RenterCheckoutFinanceNotFinishRequest extends PageReq {

}
export class RenterCheckoutFinanceNotFinishModel {
  record_id?: number;
  // @Remark("退宿单号")
  record_no?: string;
  // @Remark("合同号")
  contract_no?: string;
  // @Remark("房间号")
  room_name?: string;
  // @Remark("住客姓名")
  name?: string;
  // @Remark("退宿日期")
  checkout_time?: number;
  // @Remark("剩余天数")
  time?: number;
  // @Remark("应收/应退总计")
  total?: string;
  // @Remark("状态")
  status?: string;
}
export class RenterCheckoutFinanceNotFinishResponse extends PageResp {
  list?: Array<RenterCheckoutFinanceNotFinishModel>;
}


export class RenterCheckoutRemindListRequest extends PageReq {

}
export class RenterCheckoutRemindModel {
  record_id?: number;
  // @Remark("房间号")
  room_name?: string;
  // @Remark("住客姓名")
  name?: string;
  // @Remark("退宿日期")
  checkout_time?: number;
  // @Remark("申请日期")
  create_time?: number;
  // @Remark("剩余天数")
  time?: number;
}
export class RenterCheckoutRemindListResponse extends PageResp {
  list?: Array<RenterCheckoutRemindModel>;
  total_amount?: string;
}


export class RenterCheckoutTransferErrorRemindListRequest extends PageReq {

}
export class RenterCheckoutTransferErrorRemindModel {
  record_id?: number;
  // 合同号
  contact_num?: string;
  // @Remark("房间号")
  room_name?: string;
  // @Remark("住客姓名")
  name?: string;
  // 退宿单号
  checkout_num?: string;
  // 退押金额（元）
  amount?: string;
  // 退款方式
  transfer_type?: string;
  // @Remark("应退日期")
  transfer_time?: number;
  // @Remark("逾期天数")
  time?: number;
  // 失败原因
  msg?: string;

  renter_checkout_id?: number;
}
export class RenterCheckoutTransferErrorRemindListResponse extends PageResp {
  list?: Array<RenterCheckoutTransferErrorRemindModel>;
}

export class RenterCheckoutTransferErrorDealRequest {
  // @Remark("退款申请ID")
  record_id?: number;
  // @Remark("退款财务类型 退款/延迟/转收入")
  checkout_finance_type_enum?: string;
  // @Remark("收款人姓名")
  refund_name?: string;
  // @Remark("开户行")
  open_bank?: string;
  // @Remark("银行账号")
  bank_no?: string;

}


export class PrepayBillCollectionDTO {
  start_time: number;
  end_time: number;

  cold_water_range: string;
  cold_water_fee: string;

  hot_water_range: string;
  hot_water_fee: string;

  ele_range: string;
  ele_fee: string;
}

export class LeaseSimpleDetailResponse {
  // @Remark("预定单/合同ID")
  lease_id: number;
  // @Remark("合同编号")
  contract_num: string;
  // @Remark("房间总数")
  room_num: number;

  // @Remark("待入住数量")
  waiting_check_num: number;
  // @Remark("已入住数量")
  check_in_num: number;
  // @Remark("退房数量")
  refund_num: number;

  // @Remark("租约房间信息")
  lease_room_info_dtos: Array<LeaseRoomInfoDTO> ;


  // @Remark("租约开始时间")
  start_time: number;
  // @Remark("租约结束时间")
  end_time: number;

  prepay_total: number;

  lease_room_id: number;
  prepay_bill_collection_model_list: Array<PrepayBillCollectionDTO> ;
}
