import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";
import {
  PersonSafeAlarmDTO,
  PersonSafeAlarmLogDTO,
  PersonSafeAlarmLogRequest
} from "../../../model/workbench/workbench.model";
import {WorkbenchService} from "../../../service/workbench/workbench.service";
import {is_inApp_Android, is_inApp_IOS} from "../../../common/common";

declare var html2canvas;

@Component({
  selector: 'app-safe-warn-detail',
  templateUrl: './safe-warn-detail.component.html',
  styleUrls: ['./safe-warn-detail.component.css']
})
export class SafeWarnDetailComponent implements OnInit {

  @Output()
  handled: EventEmitter<any> = new EventEmitter<any>();


  @ViewChild("templateDetail", { static: true }) templateDetail: ModalDirective;
  @ViewChild("templateHandle", { static: true }) templateHandle: ModalDirective;
  @ViewChild("templateImg", { static: true }) templateImg: ModalDirective;

  hideContent = false;

  detail: PersonSafeAlarmDTO;
  logs?: Array<PersonSafeAlarmLogDTO>;

  evolve_remark: string;
  evolve_status: string = 'PROCESSING';

  img?: string;
  imgData;
  showHandle;

  constructor(
    private workbenchService: WorkbenchService
  ) {
  }

  ngOnInit() {
  }


  detailShow(item: PersonSafeAlarmDTO) {

    this.detail = item;

    this.templateDetail.show();
  }

  handleShow(item: PersonSafeAlarmDTO, flag?: boolean) {

    this.detail = item;

    this.evolve_remark = null;
    this.evolve_status = 'PROCESSING';

    this.getLogs();

    this.templateHandle.show();

    if (flag) {
      this.showHandle = flag
    } else {
      this.showHandle = false
    }
  }

  getLogs() {
    this.workbenchService.getWorkbenchWarningLogs(this.detail.alarm_id).then(data => {
      if (data.person_safe_alarm_log_dtos) {
        this.logs = data.person_safe_alarm_log_dtos;
      } else {
        this.logs = [];
      }
    });
  }


  ok_evolve(info: PersonSafeAlarmDTO): void {

    let req = new PersonSafeAlarmLogRequest();
    req.alarm_id = info.alarm_id;
    req.content = this.evolve_remark;
    req.type = this.evolve_status;
    if (this.evolve_remark.length >= 3) {
      this.workbenchService.handleWorkbenchWarning(req)
        .then(data => {
          if (req.type == 'FIXED') {
            this.handled.emit();
          }
          this.templateHandle.hide();
        });
    }
  }

  seeImg(detail: PersonSafeAlarmDTO, image: string) {

    this.hideContent = false;

    window['swd-img-data'] = null;
    window['swd-img'] = null;

    this.img = image;

    $(".imgSave").show();
    $(".share-pic").html('');

    window['swd-img'] = image;

    this.templateImg.show();
  }

  savePic() {

    this.hideContent = true;

    if (!window['swd-img-data']) {

      html2canvas($(".imgSave")[0], {
        allowTaint: true,
        imageTimeout: 0,
        logging: false,
        removeContainer: true,
        useCORS: true
      }).then(function (canvas) {
        let image = new Image();
        let data = canvas.toDataURL("image/png");
        image.src = data;
        $(".share-pic").html(image);
        window['swd-img-data'] = data;

        if (is_inApp_IOS()) {
          $(".share-pic").append("<div class='text-center'>IOS长按图片进行下载</div>")
        } else if (is_inApp_Android()) {
          window['androidAPP'].savePic(data, '');
        } else {
          let a = document.createElement('a')
          // 创建一个单击事件
          let event = new MouseEvent('click')
          // 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
          a.download = '预警图片';
          // 将生成的URL设置为a.href属性
          a.href = data
          // 触发a的单击事件
          a.dispatchEvent(event)
        }

        $(".imgSave").hide();
      });
    } else {

      let data = window['swd-img-data'];

      if (is_inApp_IOS()) {
      } else if (is_inApp_Android()) {
        window['androidAPP'].savePic(data, '');
      } else {
        let a = document.createElement('a')
        // 创建一个单击事件
        let event = new MouseEvent('click')
        // 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
        a.download = '预警图片';
        // 将生成的URL设置为a.href属性
        a.href = data
        // 触发a的单击事件
        a.dispatchEvent(event)
      }
    }
  }
}
