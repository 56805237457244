import { Pipe, PipeTransform } from "@angular/core";

/**
 * @Description: 证件类型
 * @Author: 周嘉豪
 * @Date: 2018/3/29 上午10:26
 * @Last Modified by: 周嘉豪
 * @Last Modified time: 2018/3/29 上午10:26
 */
@Pipe({
  name: 'cretTypePipe'
})
export class CretTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const status = {
      ID_CD: '居民身份证',
      RESIDENCE_PERMIT: '居住证',
      OFFICAL_CF: '军官证',
      POLICE_OFFICAL_CF: '武警军官证',
      RETIREMENT_CF: '文职干部证',
      SOLDIER_CF: '士兵证',
      STUDENT_ID_CD: '学生证',
      HKMO_PASS_CF: '港澳居民往来内地通行证',
      TW_PASS_CF: '台湾居民往来大陆通行证',
      CHINA_GUIDE_CD: '中华人民共和国旅行证',
      PASSPORT: '护照',
      '': '其他',
    };
    return status[value];
  }
}
