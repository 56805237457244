import { RequestService } from "../request.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../common/auth.service";
import { environment } from '../../../environments/environment';

@Injectable()
export class DiscountService {
  //获得优惠类型
  private DICCOUNT_TYPE = `${environment.apiHost}discount/list`;

  constructor(private auth: AuthService, private requestService: RequestService, private http: HttpClient) {

  }

  /**
   * @Description:  获得折扣类型列表
   * @Author: zhoujiahao
   * @Date: 2018-03-30  14:44
   */
  queryDiscountTypeList(): Promise<any> {
    return this.requestService.authGet(this.DICCOUNT_TYPE, null);
  }

}
