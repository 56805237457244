<div class="modal fade" bsModal #detailModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-static-name" class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>预约处理</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModel()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body" style="margin-left: 20px;margin-right: 20px;">
        <table class="table table-bordered" style="margin-bottom:0px">
          <thead>
          <tr>
            <th style="width:40%;background-color: #f2f2f2;">预约人姓名</th>
            <th style="width:30%;background-color: #f2f2f2;">手机号码</th>
            <th style="width:30%;background-color: #f2f2f2;">微信号</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{appointDetailResponse?.check_in_name}}</td>
            <td>{{appointDetailResponse?.check_in_phone}}</td>
            <td>{{appointDetailResponse?.check_in_weixin}}</td>
          </tr>
          </tbody>
        </table>
        <table class="table table-bordered">
          <thead>
          <tr>
            <th style="width:40%;background-color: #f2f2f2;">预约社区</th>
            <th style="width:30%;background-color: #f2f2f2;">预约房型</th>
            <th style="width:30%;background-color: #f2f2f2;">预计入住日期</th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{appointDetailResponse?.project_name}}</td>
              <td>{{appointDetailResponse?.room_type_name}}</td>
              <td>{{appointDetailResponse?.check_in_time | date:'yyyy-MM-dd'}}</td>
            </tr>
          </tbody>
        </table>

        <ng-container *ngIf="btn_privileges.WSEE_HOUSE_OPERATE && appointDetailResponse?.status == 'UNHANDLE'">
          <div class="row col-12">
            <div class="col-md-6 form-check-inline">
              <div class="col-md-6">
                <input type="radio" name="radio1" value="WAITSEEHOUSE" [(ngModel)]="status" style="margin-top: 5px;">预约看房时间：
              </div>
              <div class="col-md-6" style=" margin-left: -30px;padding-left: 0px;">
                <my-date-picker placeholder="年/月/日" [options]="myDatePickerOptions" [(ngModel)]="day"></my-date-picker>
              </div>
            </div>
            <div class="col-md-4" style="margin-left: -60px;padding-left: 0px;">
              <timepicker [(ngModel)]="time" [showMeridian]="isMeridian"></timepicker>
            </div>
            <div class="form-check-inline">
              <p class="text-danger" *ngIf="errorMsg">{{errorMsg}}</p>
            </div>
          </div>
          <div class="row col-12">
            <div class="col-md-6 form-check-inline">
              <div class="col-md-5">
                <input type="radio" name="radio1" value="CANCLE" [(ngModel)]="status" style="margin-top: 5px;">取消预约
              </div>
            </div>
          </div>

          <div class="text-center mb20 mt25">
            <button type="button" class="btn btn-primary mr20 " (click)="doSubmit()" *ngIf="btn_privileges.WSEE_HOUSE_OPERATE">
              确定
            </button>
            <button type="button" class="btn btn-secondary" (click)="closeModel()">
              取消
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="btn_privileges.WSEE_HOUSE_OPERATE && appointDetailResponse?.status == 'WAITSEEHOUSE' || appointDetailResponse?.status == 'UNSIGN'
          || appointDetailResponse?.status == 'UNSEEHOUSE'">
          <div class="row col-12">
            <label class="col-12 col-md-3 form-check-inline">预约看房时间：</label>
            <label class="col-12 col-md-3" style="margin-left: -55px;padding-left: 0px;">{{appointDetailResponse?.see_house_time | date:'yyyy-MM-dd HH:mm'}}</label>
          </div>
          <div class="row col-12">
            <label class="col-12 col-md-3 form-check-inline">客户意向：</label>
            <div class="col-12 col-md-3 border-0"  style="margin-left: -55px;padding-left: 0px;">
              <select (click)="statusSet($event)" class="w-100">
                <option value="">全部</option>
                <option value="UNSIGN">未签约</option>
                <option value="SIGNED">已签约</option>
                <option value="UNSEEHOUSE">未看房</option>
              </select>
            </div>
          </div>
          <div class="text-center mb20 mt25">
            <button type="button" class="btn btn-primary mr20 " (click)="doSubmitAgain()">
              确定
            </button>
            <button type="button" class="btn btn-secondary" (click)="closeModel()">
              取消
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="btn_privileges.APPOINT_ROOM_WAIT_VIEW && appointDetailResponse?.status == 'CANCLE'">
          <div class="row col-12">
            <label style="font-size:16px;">客户取消预约</label>
          </div>
          <div class="text-center mb20 mt25">
            <button type="button" class="btn btn-primary mr20" (click)="closeModel()">关闭</button>
          </div>
        </ng-container>


      </div>
    </div>
  </div>
</div>

