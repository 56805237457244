import {RoomLeaseDetailComponent} from "../room-configuration/room-lease-detail/room-lease-detail.component";
import {ApprovDoneListComponent} from "./approv-done-list/approv-done-list.component";
import {CleanTaskImportBatchComponent} from "./clean-task-import-batch/clean-task-import-batch.component";
import {RiskComponent} from './risk/risk.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WorkbenchComponent} from './workbench/workbench.component';
import {PrivilegeResolve} from "../../core/resolve/privilege.resolve";
import {ComplainComponent} from './complain/complain.component';
import {RepairComponent} from "./repair/repair/repair.component";
import {TabTitleResolve} from "../../core/resolve/tab-title.resolve";
import {DataDeatilComponent} from './data-deatil/data-deatil.component';
import {SafetyDisposeListComponent} from "./safety-dispose-list/safety-dispose-list.component";
import {WorkbenchPreloadComponent} from './workbench-preload/workbench-preload.component';
import { WorkOrderComponent } from './work-order/work-order.component';
import {CleanTaskListComponent} from "./cleantask-list/cleantask-list.component";
import {ApprovLockPasswordListComponent} from "./approv-lock-password-list/approv-lock-password-list.component";

const routes: Routes = [
  {
    path: 'workbench',
    component: WorkbenchComponent,
    data: {
      title: "工作台",
      OPERATION_PRIVILEGES: [
        'WORKBENCH_BOOK_BTN',
        'WORKBENCH_SIGN_BTN',
        'WORKBENCH_NO_LEASE_BTN',
        'WORKBENCH_PREPAY_BTN',
        'WORKBENCH_MONITOR_BTN',
        'WORKBENCH_ADD_ROOM_BTN',
        'WORKBENCH_EXPORT_ROOM_BTN',
        'WORKBENCH_MONEY_REPORT_BTN',
        'WORKBENCH_ACCOUNT_REPORT_BTN',
        'WORKBENCH_BUSINESS_REPORT_BTN',
        'WORKBENCH_PURCHASE_LEASE_BTN',
        'WORKBENCH_GATHERING_BTN',
        'WORKBENCH_REFUND_BTN',
        'WORKBENCH_COUPON_BTN',
        'CLEAN_WORK_STATISTICS',
        'SECURITY_WORK_STATISTICS',
        'ENGERING_WORK_STATISTICS',
        'DEVICE_WORK_STATISTICS',
        'PAD_SIGN',
        'ROOM_RENT_STATISTICS',
        'RENTER_STATISTICS',
        'LEASE_GATHERING_RENT',
        'LEASE_OVERDUE_CHECKOUT',
        'COMPLIANT_STATISTICS',
        'DEV_ERROR_STATISTICS',
        'CLEAN_STATISTICS',
        'REPAIR_STATISTICS',
        'ROOM_INSPECTION_STATISTICS',
        'BUSINESS_BASIS_DATA',
        'PREPAY_CONSUME_DATA',
        'OVERDUE_GATHERING_DATA',
        'PREPAY_RECHARGE_STATISTICS',
        'REFUND_STATISTICS',
        'INVOICE_STATISTICS',
        'BUSINESS_GATHERING_DATA',
        'WORK_SCHEDULE_STATISTICS',
        'PLAN_MATTER_STATISTICS',
        /*'CLEAN_WORK_STATISTICS',
        'SECURITY_WORK_STATISTICS',
        'ENGERING_WORK_STATISTICS',
        'DEVICE_WORK_STATISTICS',*/
        'PATROL_RECORD_UPLOAD_REMINDER',
        'COMPLAINT_DEAL',
        'CLEAN_REPAIR',
        'SCAN_PAYMENT',
        'PAYBACK_UNDO_ALARM',
        'PREPAY_ARREARAGE_ALARM',
        'ROOM_LEASE_BILL_ALARM',
        'ROOM_DEVICE_ALARM',
        'LEASE_WITHIN_TEN_DAYS_A',
        'AUDIT_LIST',
        'RESERVE_ROOM',
        'DOOR_CHECK',
        'LEASE_AUDIT_AGAIN',
        'SUBLEASE_CHANGE_REMIND',
        'ASSET_CHANGE_REMIND',
        'COMPLAINT_DEAL_VIEW',
        'COMPLAINT_DEAL_HANDLE',
        'CLEAN_REPAIR_VIEW',
        'CLEAN_REPAIR_HANDLE',
        'PAYBACK_UNDO_ALARM_VIEW',
        'PAYBACK_UNDO_ALARM_HANDLE',
        'ROOM_LEASE_BILL_ALARM_VIEW',
        'ROOM_LEASE_BILL_ALARM_HANDLE',
        'ROOM_DEVICE_ALARM_VIEW',
        'ROOM_DEVICE_ALARM_HANDLE',
        'AUDIT_LIST_HANDLE',
        'RESERVE_ROOM_VIEW',
        'RESERVE_ROOM_HANDLE',
        'DOOR_CHECK_HANDLE',
        'LEASE_AUDIT_AGAIN_HANDLE',
        'SUBLEASE_CHANGE_REMIND_HANDLE',
        'ASSET_CHANGE_REMIND_HANDLE',
        'LEASE_DISCOUNT_HANDLE',
        'CARPORT_LEASE_DISCOUNT_AUDIT',
        'SHOP_LEASE_DISCOUNT_HANDLE',
        'PROJECT_CONFIG_AUDIT',
        'REPORT_DAILY',
        'REPORT_INCOME_REFUND',
        'REPORT_INCOME_REFUND_DETAIL',
        'REPORT_FEE',
        'REPORT_INVOICE',
        'REPORT_WATER_ELECTRIC',
        'REPORT_COUPON',
        'REPORT_ACCOUNT',
        'SWEEP_CODE_PAY_HANDLE',
        'VIEW_COMPLAINT',
        'HANDLE_COMPLAINT',
        'WSEE_HOUSE_OPERATE',
        'SEE_HOUSE_OPERATE',
        'REFUND_STATISTICS_DETAIL',
        'LEASE_END',
        'SECURITY_ALARM',
        'VIEW_SECURITY_ALARM',
        'PROCESS_SECURITY_ALARM',
        'SECURITY_WARN_PANEL',
        'DELAY_REFUND',
        'DELAY_REFUND_HANDLE',
        'ROOM_MANAGE_LIST_BUTTON',
        'ROOM_EDIT_AUDIT',
        'ASSET_TRANS_AUDIT',
        'ASSET_CHECK_REMIND',
        'ASSET_CHECK_VIEW',
        'MONTH_PRICE_AVG_DATA',
        'WORK_ORDER_CENTER',
        'WAIT_SIGN_LEASE',
        'CHECK_IN_LIMIT',
        'CHECKOUT_NOT_FINISH',
        'CHECKOUT_FINANCE_NOT_FINISH',
        'OPERATING_THE_BRAIN',
        'ROOM_DIAGRAM',
        'WORKSPACE_CLEANTASK_REMIND',
        'WORKSPACE_CLEANTASK_LIST_VIEW',
        'GATHER_MONEY_INPUT',
        'RENTER_CHECKOUT_NOT_FINISH',
        'RENTER_CHECKOUT_FINANCE_NOT_FINISH',
        'RENTER_CHECKOUT_REMIND',
        'RENTER_CHECKOUT_REMIND_CANCEL_BTN',
        'RENTER_CHECKOUT_REMIND_AGREE_BTN',

        'RENTER_CHECKOUT_TRANSFER_ERROR_REMIND',
        'RENTER_CHECKOUT_TRANSFER_ERROR_REMIND_DEAL_BTN',

        'FIRE_FIGHTING_CS',
        'FIRE_FIGHTING_CS_HANDLE',
      ],
      reuse: false
    },
    resolve: {
      operation_priviliges: PrivilegeResolve,
      title: TabTitleResolve
    }
  },
  {
    path: 'workbench/preload',
    component: WorkbenchPreloadComponent,
    data: {
      title: "工作台",
      reuse: false
    },
    resolve: {
      title: TabTitleResolve
    }
  },
  {
    path: 'risk',
    component: RiskComponent,
    data: {
      title: "告警列表",
      OPERATION_PRIVILEGES: [
        "ALARM_QUERY",
        "ALARM_VIEW_HANDLE",
      ],
      reuse: true
    },
    resolve: {
      operation_priviliges: PrivilegeResolve,
      title: TabTitleResolve
    }
  },
  {
    path: 'complain',
    component: ComplainComponent,
    data: {
      title: "投诉管理",
      OPERATION_PRIVILEGES:[
        'COMPLAINT_MANAGE',
        'COMPLAINT_DATA_STA',
        'COMPLAINT_VIEW_HANDLE',
        'VIEW_COMPLAINT',
        'HANDLE_COMPLAINT',
      ]
    },
    resolve: {
      operation_priviliges: PrivilegeResolve
      ,
      title: TabTitleResolve
    }
  },
  {
    path: 'repair',
    component: RepairComponent,
    data: {
      title: "保洁维修",
      OPERATION_PRIVILEGES: [
        "REPAIR_QUERY",
        "REPAIR_DETAIL",
        "CLEAN_DETAIL",
        'REPAIR_UPDATE',
        'CLEAN_UPDATE'
      ],
    },
    resolve: {
      operation_priviliges: PrivilegeResolve,
      title: TabTitleResolve
    }
  },
  {
    path: 'cleantask',
    component: CleanTaskListComponent,
    data: {
      title: "保洁任务",
      OPERATION_PRIVILEGES: [
        "WORKSPACE_CLEANTASK_LIST_VIEW"
      ],
    },
    resolve: {
      operation_priviliges: PrivilegeResolve,
      title: TabTitleResolve
    }
  },
  {
    path: 'cleantask/import',
    component: CleanTaskImportBatchComponent,
    data: {
      title: "保洁任务导入",
      OPERATION_PRIVILEGES: [
        "WORKSPACE_CLEANTASK_LIST_VIEW"
      ],
    },
    resolve: {
      operation_priviliges: PrivilegeResolve,
      title: TabTitleResolve
    }
  },
  {
    path: 'data/detail/:type/:gatheringType',
    component: DataDeatilComponent,
    data: {
      title: "数据明细",
      OPERATION_PRIVILEGES: [
      ],
    },
    resolve: {
      operation_priviliges: PrivilegeResolve,
      title: TabTitleResolve
    }
  },
  {
    path: 'safety/dispose/list',
    component: SafetyDisposeListComponent,
    data: {
      title: "安全报警处理记录",
      OPERATION_PRIVILEGES: [
      ],
    },
    resolve: {
      operation_priviliges: PrivilegeResolve,
      title: TabTitleResolve
    }
  },
  {
    path: 'work-order/list',
    component: WorkOrderComponent,
    data: {
      title: "工单中心",
      OPERATION_PRIVILEGES: [
        'WORK_ORDER_PAGE_QUERY',
        'WORK_ORDER_INFO',
        'WORK_ORDER_CONFIRM',
        'WORK_ORDER_UPDATE',
        'WORK_ORDER_CANCEL',
        'WORK_ORDER_SUBMIT'
      ],
    },
    resolve: {
      operation_priviliges: PrivilegeResolve,
      title: TabTitleResolve
    }
  },


  {
    path: 'approv/done',
    component: ApprovDoneListComponent,
    data: {
      title: "审批记录列表",
      OPERATION_PRIVILEGES: [
      ],
    },
    resolve: {
      operation_priviliges: PrivilegeResolve,
      title: TabTitleResolve
    }
  },
  {
    path: 'approv/lock/password/list',
    component: ApprovLockPasswordListComponent,
    data: {
      title: "密码申请审批列表",
      OPERATION_PRIVILEGES: [
      ],
    },
    resolve: {
      operation_priviliges: PrivilegeResolve,
      title: TabTitleResolve
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkspaceRoutingModule {
}
