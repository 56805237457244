import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";
import { LeaseRoomInfoDTO } from '../../../../model/lease/lease.model';

@Component({
  selector: 'app-cr-room-choose',
  templateUrl: './cr-room-choose.component.html',
  styleUrls: ['./cr-room-choose.component.css']
})
export class CrRoomChooseComponent implements OnInit {

  @ViewChild('modal', { static: true }) modal: ModalDirective;

  @Input()
  lease_id: number;

  change_lease_room_id: number;

  avaliable_lease_room_list: LeaseRoomInfoDTO[] = [];

  @Output()
  room_choose_emitter: EventEmitter<number> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  show(avaliable_lease_room_list: LeaseRoomInfoDTO[]) {
    this.change_lease_room_id = null;
    this.avaliable_lease_room_list = avaliable_lease_room_list;
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }

  next() {
    this.hide();
    this.room_choose_emitter.emit(this.change_lease_room_id);
  }

  canNext(): boolean {
    return !this.change_lease_room_id && this.avaliable_lease_room_list.length > 0;
  }
}
