<div class="setbox-top">
  <div class="setbox">
    <div class="content">
      <div class="row">
        <div class="col-12 col-lg-12">
          <div class="part1">
            <h5 class="bg-gray ">基本信息</h5>
            <div class="padd15">
              <div class="row">
                <div class="col-12 col-md-3 mb10">
                  <p class="font-weight-bold">预约号</p>
                  <div>{{ detail.id }}</div>
                </div>
                <div class="col-12 col-md-3 mb10">
                  <p class="font-weight-bold">手机号</p>
                  <div>{{ detail.contacts_phone }}</div>
                </div>
                <div class="col-12 col-md-3 mb10">
                  <p class="font-weight-bold">预约看房时间</p>
                  <div>{{ detail.appointment_time }} {{ detail.start_time }}</div>
                </div>
                <div class="col-12 col-md-3 mb10">
                  <p class="font-weight-bold">带看管家</p>
                  <div>{{ detail.sys_user_name ? detail.sys_user_name : '未分配' }}</div>
                </div>
                <div class="col-12 col-md-3 mb10">
                  <p class="font-weight-bold">状态</p>
                  <div *ngIf="detail.service_status=='ALLOCATION'">待分配</div>
                  <div *ngIf="detail.service_status=='WAITING'">待处理</div>
                  <div *ngIf="detail.service_status=='WAIT_COMMENT'">待评价</div>
                  <div *ngIf="detail.service_status=='COMMENT'">已评价</div>
                </div>
                <div class="col-12 col-md-3 mb10">
                  <p class="font-weight-bold">看房结果</p>
                  <div>{{ detail.user_quality_text }}</div>
                </div>
                <div class="col-12 col-md-3 mb10">
                  <p class="font-weight-bold">意向价格</p>
                  <div>{{ detail.quality_room_price }}</div>
                </div>
                <div class="col-12 col-md-3 mb10">
                  <p class="font-weight-bold">意向房号</p>
                  <div>{{ detail.quality_room }}</div>
                </div>
              </div>
            </div>

            <ng-container *ngIf="detail.score_list && detail.score_list.length>0">
              <h5 class="bg-gray ">客户评价（总分{{ detail.score }}）</h5>
              <div class="padd15">
                <div class="row">
                  <div class="col-12 mb10">
                    管家衣着是否整洁，接待是否热情（{{ s1 }}分）
                  </div>
                  <div class="col-12 mb10">
                    管家介绍是否详细，是否主动（{{ s2 }}分）
                  </div>
                  <div class="col-12 mb10">
                    房间布局和设施是否满意（{{ s3 }}分）
                  </div>
                  <div class="col-12 mb10">
                    社区环境和卫生是否满意（{{ s4 }}分）
                  </div>
                  <div class="col-12 mb10">
                    是否愿意推荐其他朋友来居住（{{ s5 }}分）
                  </div>
                  <div class="col-12 mb10">
                    其他建议：{{ detail.user_comment ? detail.user_comment : '无' }}
                  </div>
                </div>
              </div>
            </ng-container>

            <h5 class="bg-gray ">日志</h5>
            <div class="">
              <div class="col-12 mb10" *ngFor="let log of detail.log_list;let i=index">
                <hr *ngIf="i!=0" class="divider m-0 w-100"/>
                <p
                  class="font-weight-bold">{{ log.operation_name }} {{ log.operation_time|date:'yyyy-MM-dd HH:mm:ss' }}</p>
                <div>{{ log.content }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
