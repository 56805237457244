import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {
  BillPayLogDTO,
  LeaseRoomBillDetailParam,
  LeaseRoomBillListDTO, RenterBillDetailListRequest,
  RenterBillDetailRequest, RenterBillDetailResponse
} from "../../../model/finance/bill.model";
import {DialogComponent} from "../../shared/dialog/dialog.component";
import {BillService} from "../../../service/finance/bill.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../service/common/auth.service";
import {WorkService} from "../../../service/common/work.service";
import {environment} from "../../../../environments/environment";
import {WorkModel} from "../../../model/common/common.model";
import {LocalStorage} from "../../../common/localstorage";
import {Constants} from "../../../common/constants";
import {LeaseRoomBillDetailComponent} from "../lease-room-bill/lease-room-bill-detail.component";
import {ModalDirective} from "ngx-bootstrap/modal";
import {DepositReceiptComponent} from "./deposit-receipt.component";
import {PayLogDetailComponent} from "../pay-log/pay-log-detail.component";

@Component({
  selector: 'app-renter-went-dutch-bill-detail',
  templateUrl: './renter-went-dutch-bill-detail.component.html',
  styleUrls: ['./renter-went-dutch-bill-detail.component.css']
})
export class RenterWentDutchBillDetailComponent implements OnInit {

  //账单ID
  billId: number;
  renterWentDutchBillId: number;
  //账单对象
  renterBillDetail: RenterBillDetailResponse;
  //账单财务明细
  billPayLogList: Array<BillPayLogDTO>;
  @Output() billDetailChange = new EventEmitter();
  @Output() couponDetailChange = new EventEmitter();
  //打印相关
  //isPhone: boolean = true;
  @ViewChild(DialogComponent, { static: true }) dialogComponent: DialogComponent;
  @ViewChild('billDetail', {static: true}) billDetail: LeaseRoomBillDetailComponent;
  @ViewChild('renterBillDetailModal', {static: true}) renterBillDetailModal: ModalDirective;
  @ViewChild('depositReceipt', {static: true}) depositReceipt: DepositReceiptComponent;
  @ViewChild('payLogDetail', {static: true}) payLogDetail: PayLogDetailComponent;
  contractPath: string;
  //收款权限
  LEASE_BILL: boolean;
  isNoContract: boolean = false;
  chamberlain: string = '';
  contractPayTime: any;

  //v1.2.7账单财务明细
  billPayLogLists: Array<BillPayLogDTO>;
  received: string;
  constructor(
    private billService: BillService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private workService: WorkService
  ) {
  }

  ngOnInit() {
    this.getOperatePrivilege();
  }

  showBillDetail(id: number) {
    this.billDetail.showModel(id);
  }

  showModel(billId?: number, renterWentDutchBillId? : number) {
    this.billId = billId;
    this.renterWentDutchBillId = renterWentDutchBillId;
    this.getRenterBillDetail();
    this.getBillPayLogLists();
  }

  closeModel() {
    this.renterBillDetailModal.hide();
  }

  //账单详情
  getRenterBillDetail(): void {
    let param = new RenterBillDetailRequest();
    param.bill_id = this.billId;
    param.renter_went_dutch_bill_id = this.renterWentDutchBillId;
    this.billService.getRenterBillDetail(param)
      .then(data => {
        if (data) {
          this.renterBillDetail = data;
        } else {
          this.renterBillDetail = null;
        }
        this.renterBillDetailModal.show();
      });
  }

  showDetail(id: number, type: string) {
    if (type === 'PAY_LOG') {
      this.closeModel();
      this.payLogDetail.showModel(id);
    } else if (type === 'BOOKING') {
      this.closeModel();
      this.router.navigate(['/system_manage/booking-management/detail/' + id])
    } else if(type === 'COUPON'){
      this.couponDetailChange.emit(id);
    }
  }

  showDialog(leaseId?: any, billId?: any) {
    this.dialogComponent.openGatherDialog(leaseId, billId);
  }

  printshow(leaseId: number, billId: number) {
    this.contractPath = `${environment.apiHost}` + 'file/preview?bizcode=USERBILL&leaseId='
      + leaseId + '&billId=' + billId + '&' + Math.ceil(Math.random() * 100);
    this.workService.event_bus.emit(new WorkModel(this.workService.type.PDF_PRINT, this.contractPath));
  }

  getOperatePrivilege() {
    let data = LocalStorage.getItem(Constants.USER_OPERATE_PRIVILEGES + this.authService.getUserInfo().id);
    if (data) {
      let privileges = JSON.parse(data);
      this.LEASE_BILL = privileges.find(value => value === 'LEASE_BILL');
    }
  }

  getBillPayLogLists(): void {
    let param = new RenterBillDetailListRequest();
    param.bill_id = this.billId;
    param.renter_went_dutch_bill_id = this.renterWentDutchBillId;
    this.billService.getRenterBillDetailList(param)
      .then(data => {
        if (data && data.bill_pay_log_list) {
          this.billPayLogLists = data.bill_pay_log_list;
          this.received = data.received;
        } else {
          this.billPayLogLists = null;
          this.received = '0';
        }
      });
  }

  printRenterDepositShow(bill_id: number) {
    this.closeModel();
    this.depositReceipt.showModal(bill_id);
  }

}
