import { AbstractControl } from '@angular/forms';
/**
 * @Description: 将控件重置
 * @Author: 陆炳彬
 * @Date: 2018/8/3 上午11:03
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/8/3 上午11:03
 */
export function setPure(...objs: AbstractControl[]) {
  if (objs && objs.length > 0) {
    objs.forEach(obj => {
      obj.setValue('');
      obj.setErrors(null);
      obj.markAsPristine();
    });
  }
}

export function setPass(...objs: AbstractControl[]) {
  if (objs && objs.length > 0) {
    objs.forEach(obj => {
      obj.setErrors(null);
      obj.markAsPristine();
    });
  }
}


/**
 * @Description: 深度拷贝
 * @Author: 陆炳彬
 * @Date: 2018/7/13 下午6:09
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/13 下午6:09
 */
export function deepCopy(obj): any {
  let objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        //判断ojb子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === "object" && !Array.isArray(obj[key])) {
          objClone[key] = deepCopy(obj[key]);
        } else {
          //如果不是，简单复制
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
}
