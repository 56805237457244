/**
 * @Description: 日期工具
 * @Author: 陆炳彬
 * @Date: 2018/3/29 上午10:24
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/3/29 上午10:24
 */

/**
 * 获取下N个月(减一天)
 * @param date
 * @returns {string}
 */
export function getNextNMonth(dt: Date, m: number, needPrev: boolean): Date {
  let d = formatYYYYmmdd(dt);
  let ds: string[] = d.split('-');
  let _d = parseInt(ds[2], 0) - 0;
  let nextM = new Date(parseInt(ds[0], 0), parseInt(ds[1], 0) + m, 0);
  let max = nextM.getDate();
  let date = new Date(parseInt(ds[0], 0), parseInt(ds[1], 0) - 1 + m, _d > max ? max : _d);
  let dd = formatYYYYmmdd(nextM);
  let dds: string[] = dd.split('-');
  if (dds[1] === '02') {
    let year = +dds[0];
    if (year % 4 == 0 && year % 100 != 0 || year % 400 == 0) {
      if (+ds[2] >= 30) {
        //var monthEndDate = new Date(nowYear, nowMonth+1, 0);
        date = new Date(parseInt(ds[0], 0), parseInt(ds[1], 0) + m, 0 + 1);
      }
    } else {
      if (+ds[2] >= 29) {
        date = new Date(parseInt(ds[0], 0), parseInt(ds[1], 0) + m, 0 + 1);
      }
    }
  } else {
    if (+ds[2] === 31 && +dds[2] === 30) {
      return needPrev ? addDate(date, -0) : date;
    }
  }
  return needPrev ? addDate(date, -1) : date;
}

/**
 * 添加日期
 * @param {Date} d
 * @returns {Date}
 */
export function addDate(d: Date, num: number) {
  return new Date(d.getTime() + num * 24 * 60 * 60 * 1000);
}

/**
 * 两个日期是否在一个月内(减一天)
 * @param {string} d1
 * @param {string} d2
 * @returns {boolean}
 */
export function isInOneMonth(start: Date, end: Date): boolean {
  let oneMonthAfter = getNextNMonth(start, 1, true);
  return end <= oneMonthAfter;
}

/**
 * 将日期格式化为YYYY-mm-dd格式
 * @param {Date} d
 * @returns {string}
 */
export function formatYYYYmmdd(d: Date) {
  if (d) {
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let date = d.getDate();
    let monthStr: string = (month < 10 ? '0' : '') + month;
    let dateStr: string = (date < 10 ? '0' : '') + date;
    return [year, monthStr, dateStr].join('-');
  } else {
    return "";
  }
}

export function formatYYYYmmdd2(d: Date) {
  if (d) {
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let date = d.getDate();
    let monthStr: string = (month < 10 ? '0' : '') + month;
    let dateStr: string = (date < 10 ? '0' : '') + date;
    return [year, monthStr, dateStr].join('.');
  } else {
    return "";
  }
}

export function formatHHmmss(d: Date) {
  if (d) {
    let hour = d.getHours();
    let min = d.getMinutes();
    let second = d.getSeconds();
    let minStr: string = (min < 10 ? '0' : '') + min;
    let secondStr: string = (second < 10 ? '0' : '') + second;
    return [hour, minStr, secondStr].join(':');
  } else {
    return "";
  }
}

export function formatHHmm(d: Date) {
  if (d) {
    let hour = d.getHours();
    let min = d.getMinutes();
    let minStr: string = (min < 10 ? '0' : '') + min;
    return [hour, minStr].join(':');
  } else {
    return "";
  }
}

/**
 * 获取两个日期间的月份数
 * @param {Date} startTime
 * @param {Date} endTime
 */
export function getIntervalMonth(startTime: Date, endTime: Date): number {
  /*if (startTime > endTime) {
    return 0;
  }

  let i = 0;
  while (true) {
    debugger;
    let afterOneMonth = getNextNMonth(startTime, 1, true);
    if (endTime > afterOneMonth) {
      i = i + 1;
      startTime = addDate(afterOneMonth, 1);
      continue;
    } else if (endTime.getTime() === afterOneMonth.getTime()) {
      i = i + 1;
      break;
    } else {
      i += 0.5;
      break;
    }
  }
  return i;*/
  if (!startTime || !endTime) {
    return 0;
  }
  let start = startTime.getFullYear() * 12 + startTime.getMonth();
  let end = endTime.getFullYear() * 12 + endTime.getMonth();
  let result = end - start;
  if (endTime.getDate() >= startTime.getDate()) {
    result += 1;
  }
  return result;
}

/**
 * 按照自然月份拆分账单获取两个月间隔
 * @param startTime
 * @param endTime
 */
export function getIntervalMonthByNaturalMonth(startTime: Date, endTime: Date): number {
  if (!startTime || !endTime) {
    return 0;
  }
  let start = startTime.getFullYear() * 12 + startTime.getMonth();
  let end = endTime.getFullYear() * 12 + endTime.getMonth();
  let result = end - start + 1;
  return result;
}

/**
 * @Description: 将插件返回对象formatted转为number
 * @Author: 陆炳彬
 * @Date: 2018/4/8 上午10:37
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/4/8 上午10:37
 */
export function getTimeFromPlugin(str: string): number {
  return new Date(str.replace(/-/g, '/')).getTime();
}

/**
 * @Description: 将插件返回对象formatted转为date
 * @Author: 陆炳彬
 * @Date: 2018/4/10 下午2:01
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/4/10 下午2:01
 */
export function getDateFromPlugin(str: string): Date {
  return new Date(str.replace(/-/g, '/'));
}

export const DateFormat = function (date: Date, format: string) {
  let o = {
    "M+": date.getMonth() + 1, //month
    "d+": date.getDate(),    //day
    "h+": date.getHours(),   //hour
    "m+": date.getMinutes(), //minute
    "s+": date.getSeconds(), //second
    "q+": Math.floor((date.getMonth() + 3) / 3),  //quarter
    "S": date.getMilliseconds() //millisecond
  };
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (let k in o) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
    }
  }
  return format;
};

/**
 * @Description: 生成日期插件所需的数据
 * @Author: 陆炳彬
 * @Date: 2018/9/12 上午10:33
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/9/12 上午10:33
 */
export function generatePluginDate(date: Date): any {
  return {
    date: {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    },
    formatted: formatYYYYmmdd(date)
  };
}

/**
 * @Description: 获取日期(只包含年月日)
 * @Author: 陆炳彬
 * @Date: 2019/1/21 上午10:31
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/21 上午10:31
 */
export function getDateExcludeHHMMSS(date: Date): Date {
  return new Date(formatYYYYmmdd(date));
}

/**
 * 将日期格式化为YYYY-mm-dd格式
 * @param {Date} d
 * @returns {string}
 */
export function YYYYmmdd(d: Date) {
  let year = d.getFullYear();
  let month = d.getMonth() + 1;
  let date = d.getDate();
  let monthStr: string = (month < 10 ? '0' : '') + month;
  let dateStr: string = (date < 10 ? '0' : '') + date;
  return year + monthStr + dateStr;
}

export function YYYYmmdd2(d: Date) {
  let year = d.getFullYear();
  let month = d.getMonth() + 1;
  let date = d.getDate();
  let monthStr: string = (month < 10 ? '0' : '') + month;
  let dateStr: string = (date < 10 ? '0' : '') + date;
  return year + "年" + monthStr + "月" + dateStr + "日";
}

export function dateTimeCountDown(d: number) {

  let mi = d / 1000;
  let day = Math.trunc(mi / (24 * 3600));
  let h = Math.trunc(mi % (24 * 3600) / 3600);
  let m = Math.trunc(mi % (24 * 3600) % (3600) / 60) ;

  return day ? day + '天' + h + '时' + m + '分' : h ? h + '时' + m + '分' : m + '分';
}
