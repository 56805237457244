import {Component, EventEmitter, Input, OnInit, Optional, Output, SkipSelf, ViewChild} from '@angular/core';
import {WorkModel} from "../../../model/common/common.model";
import {removeFromArray} from "../../../common/array.util";
import {MessageModalInfo} from "../../../model/modal/message-model";
import {BaseEnum} from "../../../model/modal/base-model";
import {OrderService} from "../../../service/order/order.service";
import {FinanceService} from "../../../service/finance/finance.service";
import {BillService} from "../../../service/finance/bill.service";
import {WorkService} from "../../../service/common/work.service";
import {BookingService} from "../../../service/booking/booking.service";
import {LogService} from "../../../service/log/log.service";
import {LeaseService} from "../../../service/lease/lease.service";
import {Decimal} from "decimal.js";
import {
  CarportConfirmPaymentRequest,
  CarportGatherAmountDetailRequestParam,
  CarportInfo,
  CarportLeaseBillDTO
} from "../../../model/carport/carport.modal";
import {PaymentInfoDTO, RefundApplyRequest} from "../../../model/finance/bill.model";
import {CarportService} from "../../../service/carport/carport.service";
import {OrderInfoDTO} from "../../../model/order/order.model";
import {CarportPayLogDetailComponent} from "../pay-log-detail/pay-log-detail.component";
import {RegularExp} from "../../../common/util.regexp";
import {BillTypeInfo} from "../../../model/finance/finance.model";
import {BaseInfoService} from "../../../service/common/base-info.service";
import {commonGroupBy} from "../../../common/common";
import {RefundListService} from "../../../service/refund/refund.service";
import {SignedService} from "../../../service/signed/signed.service";
import {SysUser} from "../../../model/user/user.model";
import {IdempotentService} from "../../../service/idempotentService/IdempotentService";

@Component({
  selector: 'app-bill-gathering',
  templateUrl: './bill-gathering.component.html',
  styleUrls: ['./bill-gathering.component.css']
})
export class BillGatheringComponent implements OnInit {
  @ViewChild(CarportPayLogDetailComponent, { static: true }) payLogDetailComponent: CarportPayLogDetailComponent;
  //租约ID
  @Input() leaseId: number;
  //房间ID
  @Input() roomId: number;
  @Output() callback: EventEmitter<any> = new EventEmitter<any>();
  //付款成功页面
  gatherSuccessDialog: any;
  /*  付款 参数定义 开始 */

  //收款页面租约ID
  paymentleaseId: any;
  //合同号
  contactNum: any;
  //错误提示
  errorContent: string;
  // 房源下拉列表
  carportInfoList: Array<CarportInfo>;
  //收款确认请求参数
  confirmPaymentRequest = new CarportConfirmPaymentRequest();
  //点击收款时 下面文案更改
  /*
   * 1 otherExpense 其它费用
   *
   * 2 renterExpense   房费、押金、水电等
   *
   * 3 NOLEASE 无合同收款
   */
  //是否展示 提示文案
  showTips = true;
  expenseType: string;
  //存放所有账单ID
  allBillIds = [];
  //存放选中的账单Id
  checkedBillIds = [];
  //被选中的账单数量
  ckeckedNum = 0;
  //收款数据
  paymentInfoDTO = new PaymentInfoDTO();
  //应付总金额
  paymengTotalAmount = 0;
  //付款详情请求参数
  gatherAmountDetailRequestParam = new CarportGatherAmountDetailRequestParam();
  //收款页面房间ID
  carportIds: Array<number> = [];
  //账单列表
  billList: Array<CarportLeaseBillDTO> = [];
  //费用类型枚举
  expenseTypeEnum: BaseEnum[];
  //付款类型
  paymentType: string;
  //付款类型名称
  paymentTypeName: string;
  //倒计时
  clock: number;
  //展示蓝字提示
  showContent: boolean;
  //关闭成功页面
  close: number;
  gatherBillDialog: any;
  orderNum = 0;
  orderDtos: Array<OrderInfoDTO>;
  aliOrWeixinDialog: any;
  //账单和订单关联数据
  bindOrderBillDialog: any;
  //商户订单号
  orderId: string;
  //订单查询不到错误提示
  orderRemind: string;
  //预定金收款
  gatherBookingAmount;
  // 退款收取弹框
  checkOutModel;
  string;
  //支付成功后额账单流水ID
  payId: number;
  @Output() paymentCallback = new EventEmitter();
  //通过账单id收款
  gatherByBillId: any;
  actualAmountValidErrorContent: string;
  /*  付款 参数定义 结束 */

  /*  1.0.5 版本更改 开始 */
  //是否有合同收款
  hasContact = true;

  //是否展示确认收款按钮
  showConfirmBtn = true;

  /*  1.0.5 版本更改 结束 */

  //无合同收款是否禁用
  noContract = false;
  billTypeList: Array<BillTypeInfo> = [];
  auditList: Array<SysUser> = [];
  time = 60;
  codeBtnStr = '点击获取';
  inter;
  refundApplyRequest = new RefundApplyRequest();
  modeOfPayment: string = '';
  canRefundNextError: boolean = true;
  refundSelectValue: number = 0;
  resultRefundNo: string = '';
  auditCodeError: boolean = false;
  refundBillDialog: any;
  refundBillConfirmDialog: any;
  refundBillConfirmSuccessDialog: any;
  // 已付总金额
  paymentedTotalAmount = 0;
  refundBillTypeList: Array<BillTypeInfo> = [];
  refundAuditUserList: Array<SysUser> = [];
  idempotent_key:string;

  constructor(private leaseService: LeaseService, private  logService: LogService, private bookingService: BookingService,
              private  workService: WorkService, private  billService: BillService, private  financeService: FinanceService,
              private carportService: CarportService, private baseInfoService: BaseInfoService,
              private refundListService: RefundListService, private signedService: SignedService,
              private idempotentService:IdempotentService,
              private orderService: OrderService, @Optional() @SkipSelf() billGatheringComponent: BillGatheringComponent) {
    this.getBillTypeList();
    this.getAuditList();
    this.getRefundAuditUserList();

  }

  ngOnInit() {
    this.expenseTypeEnum = [
      new BaseEnum('otherExpense', '其它费用'),
      new BaseEnum('renterExpense', '租金、押金等'),
    ];
  }

  getAuditList() {
    this.signedService.leaseAuditCheckoutList().then(data => {
      this.auditList = data.audit_user;
    });
  }

  getRefundAuditUserList() {
    this.signedService.billRefundAuditUserList().then(data => {
      this.refundAuditUserList = data.audit_user;
    });
  }


  //contractNum 合同号 打开收款页面
  openGatherDialog(leaseId?: any, billId?: any) {
    this.changeValueToNull();
    this.gatherAmountDetailRequestParam.refundable_bill = '';
    this.billList = [];
    //默认展示文案
    this.showTips = true;
    if (billId) {
      //默认选中所选的账单和房间 如果账单不为空，则默认选择付款类型：renterExpense 并且选中账单ID，房间ID
      //选中
      this.paymentleaseId = leaseId;
      this.gatherByBillId = billId;
      //不展示提示文案
      this.showTips = false;
    }
    if (leaseId) {
      this.hasContact = true;
      this.expenseType = 'renterExpense';
      this.noContract = true;
      this.enterLeaseId('LEASE_ID', leaseId);
    }
    //无合同收款
    if (!leaseId) {
      //不展示提示文案
      this.showTips = false;
      this.expenseType = 'NOLEASE';
      this.hasContact = false;
      this.gatherBillDialog.show();
    }
  }

  /**
   * @Description:  输入合同号或者点击收款获取租约ID
   * leaseType  LEASE_ID（车库租约ID）或者 CONTACT_NUM（合同号）
   * @Author: zhoujiahao
   * @Date: 2018-07-13  10:40
   */
  enterLeaseId(leaseType?: any, leaseValue?: any) {
    this.actualAmountValidErrorContent = null;
    // //add by yangwen增加“全部”选项,默认选中“全部”
    this.carportInfoList = [];
    const reqDate = {lease_room_query_type: leaseType, lease_value: leaseValue, gather_type: '1RENT'};
    //获取租约下的车库列表
    this.carportService.getLeaseCarports(reqDate).then(res => {
      if (!res || !res.lease_id) {
        this.errorContent = "无效的合同号，请重新输入";
        this.carportInfoList = [];
        this.showTips = true;
        return;
      }
      this.carportIds = [];
      this.paymentleaseId = res.lease_id;
      this.contactNum = res.contract_num;
      this.confirmPaymentRequest.lease_id = this.paymentleaseId;
      this.errorContent = null;
      res.carport_info_dtolist.forEach(e => {
        this.carportInfoList.push(e);
      });
      this.carportIds = this.carportInfoList.map(r => r.carport_info_id);
      //  this.roomSelect();
      if (this.expenseType) {
        //输入合同的同时也刷新下面的数据
        this.paymentDetail(this.expenseType);
      } else {
        this.gatherBillDialog.show();
      }
    }).catch(e => {
      this.errorContent = "无效的合同号，请重新输入";
      this.logService.error('PactComponent:获取租约下的车库失败' + JSON.stringify(e));
      this.showTips = true;
      return;
    });
  }

  /**
   * @Description:  切换收款类型时将参数置为空
   * @Author: zhoujiahao
   * @Date: 2018-07-14  21:20
   */
  changeValueToNull() {
    this.checkedBillIds = [];
    this.allBillIds = [];
    this.ckeckedNum = 0;
    this.confirmPaymentRequest.payment_total_amount = null;
    this.confirmPaymentRequest.remark = null;
    this.confirmPaymentRequest.bill_ids = null;
    this.confirmPaymentRequest.actual_payment_amount = null;
    this.confirmPaymentRequest.attached_list = null;
    this.confirmPaymentRequest.lease_id = null;
    this.confirmPaymentRequest.carport_ids = null;
    this.confirmPaymentRequest.type_id = null;
    this.paymentInfoDTO.payment_total_amount = null;
    this.paymentInfoDTO.paymented_amount = null;
    this.confirmPaymentRequest.bill_type_code = '';
    this.paymentInfoDTO.deposit = null;
    this.paymentInfoDTO.bill_ids = null;
    this.paymengTotalAmount = null;
    this.gatherBookingAmount = null;
    this.actualAmountValidErrorContent = null;
    this.showConfirmBtn = true;
  }

  initDialog(gatherBill?: any, bindOrderBill?: any, gatherSuccess?: any, checkOutModel?: any, refundBillModel?: any,
             refundBillConfirmModel?: any, refundBillConfirmSuccessModel?: any) {
    this.gatherBillDialog = gatherBill;
    this.bindOrderBillDialog = bindOrderBill;
    this.gatherSuccessDialog = gatherSuccess;
    this.checkOutModel = checkOutModel;
    this.refundBillDialog = refundBillModel;
    this.refundBillConfirmDialog = refundBillConfirmModel;
    this.refundBillConfirmSuccessDialog = refundBillConfirmSuccessModel;
  }

  /**
   * @Description:  关闭收款页面： 需要将所有参数清空
   * @Author: zhoujiahao
   * @Date: 2018-07-28  14:54
   */
  closeGatherDialog(gatherBill?: any) {
    this.changeValueToNull();
    this.paymentleaseId = null;
    this.carportInfoList = [];
    this.contactNum = null;
    this.billList = [];
    gatherBill.hide();
  }

  /**
   * @Description:  有合同和无合同收款切换
   * changeType  true表示有合同收款，false表示无合同收款
   * @Author: zhoujiahao
   * @Date: 2018-09-17  15:59
   */
  changeContactGather(changeType?: boolean) {
    this.showTips = false;
    this.actualAmountValidErrorContent = null;
    this.confirmPaymentRequest.actual_payment_amount = null;
    if (changeType) {
      //如果是有合同收款 非其它费用
      this.expenseType = 'renterExpense';
      this.paymentDetail(this.expenseType);
    } else {
      this.expenseType = 'NOLEASE';
    }
    this.hasContact = changeType;

  }

  /*
   * 有合同，其它费用收款或者有账单收款
   */
  changeGatherType(expenseType?: string) {
    this.idempotentService.getIdempotentKey().then(data =>{
      this.idempotent_key = data.idempotent_key;
    })
    this.actualAmountValidErrorContent = null;
    this.confirmPaymentRequest.actual_payment_amount = null;
    this.expenseType = expenseType;
    this.paymentDetail(this.expenseType);
  }

  /**
   * @Description:   重新选择房间后出发查询付款详情
   * @Author: zhoujiahao
   * @Date: 2018-07-30  14:28
   */
  roomSelect(type?:string) {
    this.actualAmountValidErrorContent = null;
    //如果合同号正确才会去调付款详情
    if (!this.errorContent) {
      if (type && type === 'REFUND') {
        this.canRefundBillDetail(this.expenseType);
      } else {
        this.paymentDetail(this.expenseType);
      }
    }

  }

  //获取付款信息
  paymentDetail(expenseType?: string, billTypeId?: number): string {
    //将参数置空
    this.changeValueToNull();
    if (!this.paymentleaseId && this.expenseType !== 'otherExpense') {
      //合同ID为空
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '合同号不能为空'));
      return;
    }
    this.confirmPaymentRequest.lease_id = this.paymentleaseId;
    this.confirmPaymentRequest.payment_total_amount = '0';
    this.expenseType = expenseType;
    this.gatherAmountDetailRequestParam.gather_type = this.expenseType;
    this.gatherAmountDetailRequestParam.carport_lease_id = this.paymentleaseId;
    this.gatherAmountDetailRequestParam.carport_ids = this.carportIds;
    if (billTypeId) {
      this.gatherAmountDetailRequestParam.bill_type_ids = [billTypeId];
    } else {
      this.gatherAmountDetailRequestParam.bill_type_ids = [];
    }
    if (expenseType === 'otherExpense' && (!this.carportIds || this.carportIds.length > 1) && this.paymentleaseId) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '选择其他费用收款时，只能选单个车库，且必须选择一个车库'));
      return;
    }
    this.confirmPaymentRequest.carport_ids = this.carportIds;
    if (expenseType === 'renterExpense') {
      //月租金、押金
      this.carportService.gatheringBillDetail(this.gatherAmountDetailRequestParam).then(res => {
        this.allBillIds = [];
        if (res) {
          this.allBillIds = res.carport_lease_bill_list.map(r => r.bill_id);
          this.billList = res.carport_lease_bill_list;
          this.confirmPaymentRequest.gather_type = this.expenseType;
          this.paymengTotalAmount = res.payment_total_amount;
          if (!this.gatherByBillId) {
            this.checkedBillIds = [];
          } else {
            if (this.allBillIds.lastIndexOf(this.gatherByBillId) < 0) {
              //如果当前账单不含有用户勾选的账单id
              this.checkedBillIds = [];
            } else {
              this.billList.forEach(e => {
                if (e.bill_id === this.gatherByBillId) {
                  this.confirmPaymentRequest.payment_total_amount = e.waiting_pay_amount;
                  this.checkedBillIds.push(this.gatherByBillId);
                  this.confirmPaymentRequest.bill_ids = this.checkedBillIds;
                  this.confirmPaymentRequest.actual_payment_amount = e.waiting_pay_amount;
                  this.ckeckedNum += 1;
                }
              });
            }
            this.gatherByBillId = null;
          }
          this.gatherBillDialog.show();
        }
      }).catch(error => {
        this.logService.error('PactComponent:获取账单列表信息失败');
        return;
      });

    } else {
      if (this.paymentleaseId) {
        this.carportService.gatheringBillDetail(this.gatherAmountDetailRequestParam).then(res => {
          if (res) {
            this.paymentInfoDTO = res;
            //如果费用类型是 其他类型
            if (this.expenseType === 'otherExpense') {
              this.confirmPaymentRequest.payment_total_amount = '0';
            } else {
              this.confirmPaymentRequest.payment_total_amount = this.paymentInfoDTO.payment_total_amount;
            }
            this.confirmPaymentRequest.gather_type = this.expenseType;
          } else {
            this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '无待收款账单'));
            return;
          }
        }).catch(error => {
          this.logService.error('PactComponent:获取收款详情失败');
          return;
        });
      }
    }
    this.showTips = false;
  }

  /**
   * @Description:  用户输入金额判断
   * @Param:  renterExpense,
   * @return:
   * @Author: zhoujiahao
   * @Date: 2018-07-13  17:21
   */
  renterExpense() {
    let messageModalInfo = new MessageModalInfo();
    if (!this.validTwoPoint(this.confirmPaymentRequest.actual_payment_amount)) {
      this.actualAmountValidErrorContent = '请输入一个正确的金额';
      return;
    }
    this.actualAmountValidErrorContent = null;
    if (this.expenseType === 'otherExpense') {
      this.confirmPaymentRequest.payment_total_amount = this.confirmPaymentRequest.actual_payment_amount;
    } else if (this.expenseType === 'renterExpense') {
      if (!this.confirmPaymentRequest.payment_total_amount) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "应收金额不能为空"));
        return;
      }
      if (this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount)
        .greaterThan(this.changToDecimal(this.confirmPaymentRequest.payment_total_amount))) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "收取费用不能超过待收费用"));
        return;
      }
    }
  }

  /**
   * @Description:   传进一个字符串金额：转换成两个小数点的Decimal类型
   * @Author: zhoujiahao
   * @Date: 2018-07-30  16:20
   */
  changToDecimal(amount?: string): Decimal {
    return new Decimal(this.getTwoPoint(new Decimal(amount)));
  }

  /**
   * @Description:  保留两位小数点
   * @Author: zhoujiahao
   * @Date: 2018-07-30  16:31
   */
  getTwoPoint(data?: Decimal): string {
    return (Math.ceil(new Decimal(data).mul("100").toNumber()) / 100).toString();
  }

  clearRemark() {
    if (this.confirmPaymentRequest.bill_type_code !== 'OTHER') {
      this.confirmPaymentRequest.remark = null;
    }
  }

  /**
   * @Description:
   * @Param:
   * @return:
   * @Author: zhoujiahao
   * @Date: 2018-07-14  19:01
   */
  checkAll(event?: any) {
    if (event.target.checked) {
      this.checkedBillIds = this.allBillIds;
      this.ckeckedNum = this.allBillIds.length;
      if (this.paymengTotalAmount) {
        this.confirmPaymentRequest.payment_total_amount = this.paymengTotalAmount.toString();
        this.confirmPaymentRequest.actual_payment_amount = this.confirmPaymentRequest.payment_total_amount;
      }
    } else {
      //实际支付金额减去总金额<0
      if (this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount)
        .lessThan(this.changToDecimal(this.confirmPaymentRequest.payment_total_amount))) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "收取费用不能超过待收费用"));
        event.target.checked = true;
        return;
      }
      this.checkedBillIds = [];
      this.ckeckedNum = 0;
      this.confirmPaymentRequest.payment_total_amount = null;
      this.confirmPaymentRequest.actual_payment_amount = '0';
    }
    this.confirmPaymentRequest.bill_ids = this.checkedBillIds;
  }

  /**
   * @Description: 选中当前账单
   * @Author: zhoujiahao
   * @Date: 2018-07-14  19:02
   */
  checkCurrentBill(event?: any, billId?: any, waitingAmount?: any) {
    //用户输入金额
    let currentActualAmount = this.changToDecimal("0");
    if (this.confirmPaymentRequest.actual_payment_amount) {
      currentActualAmount = this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount);
    }
    let waitingAmountCheck = this.changToDecimal(waitingAmount);
    if (event.target.checked) {
      if (this.confirmPaymentRequest.payment_total_amount) {
        this.confirmPaymentRequest.payment_total_amount =
          this.getTwoPoint(this.changToDecimal(this.confirmPaymentRequest.payment_total_amount).add(waitingAmountCheck));
      } else {
        this.confirmPaymentRequest.payment_total_amount = this.getTwoPoint(waitingAmountCheck);
      }
      this.confirmPaymentRequest.actual_payment_amount =
        this.getTwoPoint(currentActualAmount.add(waitingAmountCheck));
      this.ckeckedNum += 1;
      this.checkedBillIds.push(billId);
    } else {
      //取消选中
      //用户输入了金额
      if (this.confirmPaymentRequest.actual_payment_amount
        && this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount).greaterThan("0")) {
        //取消后的账单金额>实际支付金额 此处的实际支付金额是用户付的
        if (waitingAmountCheck.greaterThan(currentActualAmount)) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "收款金额不能大于应付金额"));
          event.target.checked = true;
          return;
        }
      } else {
        if (currentActualAmount.lessThan(waitingAmount)) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "应付金额不能小于0"));
          event.target.checked = true;
          return;
        }
      }
      this.confirmPaymentRequest.payment_total_amount =
        this.getTwoPoint(this.changToDecimal(this.confirmPaymentRequest.payment_total_amount).sub(waitingAmountCheck));
      this.confirmPaymentRequest.actual_payment_amount =
        this.getTwoPoint(currentActualAmount.sub(waitingAmountCheck));
      this.ckeckedNum -= 1;
      this.checkedBillIds = removeFromArray(this.checkedBillIds, billId);
    }
    //如果为选中账单
    if (this.checkedBillIds.length <= 0) {
      this.confirmPaymentRequest.actual_payment_amount = '0';
    }
    this.confirmPaymentRequest.bill_ids = this.checkedBillIds;
  }

  /**
   * @Description:   判断金额是否大于0
   * @Author: zhoujiahao
   * @Date: 2018-07-22  14:19
   */
  vaildAmount(amount?: any): boolean {
    if (!amount || parseFloat(amount) <= 0) {
      return false;
    }
    return true;
  }

  /**
   * @Description:  收款  aliOrWeixin 不为空表示是微信或者支付宝支付
   * @Author: zhoujiahao
   * @Date: 2018-07-14  23:59
   */
  payment(paymentType?: any, dialog?: any, aliOrWeixin?: any, nowType?: string) {
    this.idempotentService.getIdempotentKey().then(data =>{
      this.idempotent_key = data.idempotent_key;
    });
    // modify by xulihua pms1.18 添加 同账单类型，不支持跨周期支付需求  start
    if (!nowType || "LEAVE" !== nowType) {
      // 当前选中的账单
      let nowCheckedBillIds = this.confirmPaymentRequest.bill_ids;
      let currentBillList = new Array();
      let groupBillList = new Array();
      if (nowCheckedBillIds !== null){
        currentBillList = this.billList.filter(bill => bill.bill_id === nowCheckedBillIds.find(e => e === bill.bill_id));
        groupBillList = commonGroupBy(currentBillList, item => item.bill_type_name);
      }
      let actualBillList = commonGroupBy(this.billList, item => item.bill_type_name);
      for (let i = 0; i < groupBillList.length; i++) {
        let handlerBillList = [];
        handlerBillList = groupBillList[i];
        let totalNum = handlerBillList.length;
        // 被选中的账期中，值最大的账单
        let maxHandlerBill = handlerBillList[totalNum - 1];
        let billTypeName = handlerBillList[0].bill_type_name;
        let actualResultList = [];
        actualResultList = actualBillList.find(billList => billList[0].bill_type_name === billTypeName);
        // 取出实际展示的 同类型账单中 此 index下的bill的账期时间
        let actualResult = actualResultList[totalNum - 1];
        if (actualResult.bill_end_time === maxHandlerBill.bill_end_time) {
          continue;
        } else {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '同类型账单需按支付周期顺序支付，不支持跨周期支付！'));
          return;
        }
      }
    }
    // modify by xulihua pms1.18 添加 同账单类型，不支持跨周期支付需求  end
    const req = {bill_type: 'SINGLE'};
    //实际付款金额
    if (!this.validTwoPoint(this.confirmPaymentRequest.actual_payment_amount)) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请输入正确的金额'));
      return;
    }
    this.confirmPaymentRequest.gather_type = this.expenseType;
    this.confirmPaymentRequest.carport_ids = this.carportIds;
    //将商户流水号置空
    this.confirmPaymentRequest.pay_log_no = null;
    let messageModalInfo = new MessageModalInfo();
    //处理其他费用 的收费类型

    if (this.expenseType !== 'NOLEASE' && (!this.confirmPaymentRequest.payment_total_amount
      || parseFloat(this.confirmPaymentRequest.payment_total_amount) < 0)) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '应付金额不能为空'));
      this.logService.error('payment方法：选中付款方式为' + this.expenseType + " 应付总金额confirmPaymentRequest.payment_total_amount="
        + this.confirmPaymentRequest.payment_total_amount);
      return;
    }
    //预定单 页面收取定金
    if (this.expenseType === 'NOLEASE') {
      if (!this.gatherOtherExpwnseChekck(true)) {
        return;
      }
      this.confirmPaymentRequest.carport_ids = null;
    } else {
      //如果应付金额为空
      if (!this.confirmPaymentRequest.actual_payment_amount || parseFloat(this.confirmPaymentRequest.actual_payment_amount) <= 0) {
        //如果付费类型为 其它
        if (this.expenseType === 'otherExpense') {
          if (!this.gatherOtherExpwnseChekck(true)) {
            return;
          }
        }
      } else {
        //如果用户输入了金额
        //如果付费类型为 其它
        if (this.expenseType === 'otherExpense') {
          if (!this.gatherOtherExpwnseChekck(true)) {
            return;
          }
        } else {
          //用户输入的金额不为空
          if (parseFloat(this.confirmPaymentRequest.actual_payment_amount) > parseFloat(this.confirmPaymentRequest.payment_total_amount)) {
            //收取金额不能大于总金额
            messageModalInfo.operationMessage = "本次收取金额不能大于应付总金额";
            this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
            return;
          }
        }
      }
    }
    //获得付款类型以及 打开支付确认页面
    this.paymentType = paymentType;
    this.financeService.getPaymentType(req).then(res => {
      res.pay_types.forEach(data => {
        if (data.pay_type_code === paymentType) {
          this.confirmPaymentRequest.type_id = data.pay_type_id;
          this.paymentTypeName = data.pay_type_name;
        }
      });
    }).then(res => {
      this.aliOrWeixinDialog = dialog;
      if (aliOrWeixin) {
        this.showContent = false;
        this.confirmPaymentRequest.attached_list = [];
        this.clock = 120;
        this.timeOut();
      }
      this.gatherBillDialog.hide();
      this.checkOutModel.hide();
      dialog.show();
    });
  }

  /**
   * @Description:  倒计时120秒
   * @Author: zhoujiahao
   * @Date: 2018-07-15  0:32
   */
  timeOut() {
    this.clock = this.clock - 1;
    if (this.clock === 0 || this.clock === -1) {
      this.clock = 0;
      this.showContent = true;
    } else if (this.clock > 0) {
      if (new RegExp(RegularExp.signlessInteger).test((this.clock / 5).toString())) {
        //五秒中查询一次账单对应的订单信息
        this.getBillOrderList();
      }
      setTimeout(() => this.timeOut(), 1000);
    }
  }

  setCloseTime(successDialog?: any) {
    this.close = this.close - 1;
    if (this.close === 0 || this.close === -1) {
      this.close = 0;
      successDialog.hide();
    } else if (this.close > 0) {
      setTimeout(() => this.setCloseTime(successDialog), 1000);
    }
  }

  /**
   * @Description: 根据支付订单金额获取订单信息
   * @Author: zhoujiahao
   * @Date: 2018-07-22  16:46
   */
  getBillOrderList() {
    const reqParam = {
      pay_channel: this.paymentType,
      payment_amount: this.confirmPaymentRequest.actual_payment_amount
    };
    this.orderService.getBillOrderList(reqParam).then(res => {
      if (res) {
        this.orderDtos = res.order_info_dtos;
        this.orderNum = this.orderDtos.length;
        if (this.orderNum > 0) {
          this.clock = 0;
          this.bindOrderBillDialog.show();
          this.aliOrWeixinDialog.hide();
        }
      }
    }).catch(error => {
      this.logService.error('PactComponent:根据付款金额获取订单信息失败' + JSON.stringify(error));
    });
  }

  /**
   * @Description:   校验只能含有两个小数点
   * @Author: zhoujiahao
   * @Date: 2018-07-26  15:33
   */
  validTwoPoint(amount?: any): boolean {
    if (amount) {
      if (/^\d+(\.\d{0,2})?$/.test(amount)) {
        let s = parseFloat(amount);
        if (s < 1 || s > 10000000) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * @Description:   收费类型为其它： 校验车库ID不能为空并且只能收取一个车库的费用
   * remind 是否提示用户
   * @Author: zhoujiahao
   * @Date: 2018-07-28  14:36
   */
  gatherOtherExpwnseChekck(remind: boolean): boolean {
    let remindMessage;
    if (!this.confirmPaymentRequest.bill_type_code) {
      remindMessage = '款项类型不能为空';
    }
    if (!this.confirmPaymentRequest.remark && this.confirmPaymentRequest.bill_type_code === 'OTHER') {
      remindMessage = '请输入备注信息';
    }
    //备注长度不能长于200
    if (this.confirmPaymentRequest.bill_type_code === 'OTHER'
      && this.confirmPaymentRequest.remark && this.confirmPaymentRequest.remark.length > 200) {
      remindMessage = '备注信息不能超过200字';
    }
    if (remindMessage && this.paymentleaseId) {
      if (remind) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, remindMessage));
      }
      return false;
    }
    if (this.expenseType !== 'NOLEASE') {
      //其它类型只能展示收取一个房间的费用
      if (!this.confirmPaymentRequest.carport_ids) {
        remindMessage = '选择其他费用收款时，只能选单个车库，且必须选择一个车库';
      }
    }
    if (remindMessage) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, remindMessage));
      return false;
    }
    return true;
  }

  /**
   * @Description:  支付宝、微信支付倒计时页面关闭
   * @Author: zhoujiahao
   * @Date: 2018-07-26  15:14
   */
  closeAliOrWei(closeAliOrWei?: any) {
    this.clock = 0;
    closeAliOrWei.hide();
  }

  /**
   * @Description:  显示通过商户订单号查询订单信息dialog
   * @Author: zhoujiahao
   * @Date: 2018-07-23  10:35
   */
  showQueryByOrderId(queryDialog?: any, needCloseDialog?: any) {
    this.orderId = null;
    this.orderRemind = null;
    if (needCloseDialog) {
      needCloseDialog.hide();
    }
    queryDialog.show();
  }

  /**
   * @Description:  确认收款
   * paymentType：支付类型
   * confirmDialog：支付确认弹框
   * paymentType：gatherSuccess 支付成功弹框
   * paymentType：预定金金额完全抵扣应付金额： 付款成功之后直接弹出收款成功页面      confirmDialog 此参数为空
   * @Author: zhoujiahao
   * @Date: 2018-07-15  1:06
   */
  confirm(paymentType?: any, confirmDialog?: any, gatherSuccess?: any, bookAmountPayment?: any): Promise<boolean> {
    this.confirmPaymentRequest.idempotent_key = this.idempotent_key;
    this.showConfirmBtn = false;
    //1 otherExpense 其它费用  不需要账单ID    2 renterExpense   房费、押金、水电等  账单ID在勾选账单ID是已经选好
    if (this.confirmPaymentRequest.gather_type !== 'otherExpense' && this.confirmPaymentRequest.gather_type !== 'renterExpense') {
      this.confirmPaymentRequest.bill_ids = this.paymentInfoDTO.bill_ids;
    }
    if (paymentType === 'POS' || paymentType === 'TRANSFER' || paymentType === 'CHECK') {
      if (!this.confirmPaymentRequest.attached_list || this.confirmPaymentRequest.attached_list.length <= 0) {
        this.showConfirmBtn = true;
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请上传付款凭证'));
        return;
      }
    }
    //实际付款金额
    if (!this.validTwoPoint(this.confirmPaymentRequest.actual_payment_amount)) {
      this.showConfirmBtn = true;
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请输入正确的金额'));
      return;
    }
    //账单收款类型只有两种
    //账单收款其它费用传这个/其它费用
    // OTHEREXPENSE("otherExpense", "其它费用-无账单收款"),
    //账单收款确认时传这个
    // NORMAL("NORMAL", "正常收款"),;
    //NOLEASE("NOLEASE", "无合同收款"),
    if (this.confirmPaymentRequest.gather_type === 'renterExpense' && this.paymentleaseId) {
      if (parseFloat(this.confirmPaymentRequest.payment_total_amount) < 0) {
        this.showConfirmBtn = true;
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '应收总金额不能小于0'));
        return;
      }
      this.confirmPaymentRequest.gather_type = 'NORMAL';
    } else if (this.confirmPaymentRequest.gather_type === 'NOLEASE') {
      this.confirmPaymentRequest.lease_id = null;
      if (!this.gatherOtherExpwnseChekck(true)) {
        this.showConfirmBtn = true;
        return;
      }
    }
    //调用收款接口
    this.carportService.gatheringBillConfirm(this.confirmPaymentRequest).then(res => {
      if (res.bill_id) {
        this.payId = res.bill_id;
      }
      this.close = 5;
      confirmDialog.hide();
      gatherSuccess.show();
      this.setCloseTime(gatherSuccess);
      if (this.gatherBookingAmount === 'checkOut') {
        //退房结账
        this.callback.emit();
      }
      //支付成功后刷新 租约详情页面
      // this.paymentCallback.emit();
      this.workService.event_bus.emit(new WorkModel(this.workService.type.SCHEDULE_TASK, this.confirmPaymentRequest.lease_id));
    }).catch(error => {
      this.showConfirmBtn = true;
      this.logService.error('CarportPactComponent:确认付款' + JSON.stringify(error));
    });
  }


  /**
   * @Description:  附件
   * @Author: zhoujiahao
   * @Date: 2018-07-15  1:11
   */
  paymnetFill(imageList?: any) {
    this.confirmPaymentRequest.attached_list = imageList;
  }

  //打印支付凭证
  printPaymentCret() {
    this.payLogDetailComponent.printShow(this.confirmPaymentRequest.lease_id, this.payId);
  }

  /**
   * @Description:  关闭通过商户订单号查询订单的页面
   * @Author: zhoujiahao
   * @Date: 2018-07-26  18:05
   */
  closeOrderQueryDialog(orderQueryDialog?: any) {
    this.orderId = null;
    this.orderRemind = null;
    orderQueryDialog.hide();
  }

  /**
   * @Description:  通过订单号查询订单是否存在
   * @Author: zhoujiahao
   * @Date: 2018-07-23  10:39
   */
  queryByOrderId(queryShowDialog?: any, gatherSuccessDialog?: any) {
    this.orderRemind = null;
    if (!this.orderId) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请输入商户订单号'));
      return;
    }
    const reqParam = {
      pay_channel: this.paymentType,
      payment_amount: this.confirmPaymentRequest.actual_payment_amount,
      order_id: this.orderId
    };
    this.orderService.getBillOrderList(reqParam).then(res => {
      if (res) {
        this.orderDtos = res.order_info_dtos;
        if (res.order_info_dtos.length === 1) {
          this.confirmPaymentRequest.pay_log_no = this.orderDtos[0].order_no;
          this.confirm(this.paymentType, queryShowDialog, gatherSuccessDialog);
        }
      } else {
        this.orderRemind = '查不到相应的支付记录 ，请确认订单号是否正确';
      }
    }).catch(error => {
      this.logService.error('PactComponent:根据订单和金额查询订单时失败' + JSON.stringify(error));
    });
  }

  /**
   * @Description:   确认订单和账单信息关联
   * @Author: zhoujiahao
   * @Date: 2018-07-22  17:06
   */
  reletedBillAndOrder(orderNo?: any, bindOrderBillDialog?: any, gatherSuccess?: any) {

    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.operationMessage = "请确认此款项正确，确认后不能更改且立即入账";
    messageModalInfo.cancel = true;
    messageModalInfo.callback.subscribe(() => {
      this.confirmPaymentRequest.pay_log_no = orderNo;
      //付款类型为空
      this.confirm(this.paymentType, bindOrderBillDialog, gatherSuccess);
    });
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
  }

  /**
   * @Description:  退款结账收款
   * @Author: zhoujiahao
   * @Date: 2018-07-23  11:37
   */
  gatherCheckOutAmount(leaseId: any, billIds?: any, amount?: any) {
    if (!amount || this.changToDecimal(amount).lessThan(new Decimal('0'))) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '收款金额不能为0'));
      return;
    }
    if (!billIds || billIds.length <= 0) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '账单不能为空'));
      return;
    }
    if (!leaseId) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '租约ID不能为空'));
      return;
    }
    this.changeValueToNull();
    this.gatherBookingAmount = 'checkOut';
    this.confirmPaymentRequest.lease_id = leaseId;
    this.confirmPaymentRequest.payment_total_amount = amount;
    this.confirmPaymentRequest.actual_payment_amount = amount;
    this.expenseType = 'NORMAL';
    this.confirmPaymentRequest.gather_type = this.expenseType;
    this.confirmPaymentRequest.bussiness_type = 'CHECKOUT';
    this.paymentInfoDTO.bill_ids = billIds;
    this.confirmPaymentRequest.bill_ids = billIds;
    this.checkOutModel.show();
  }

  getBillTypeList() {
    this.baseInfoService.getBillTypeListl({type:'CARPORT_LEASE'}).then(res => {
      this.billTypeList = res.bill_types;
      if (this.billTypeList) {
        this.billTypeList = this.billTypeList.filter(billType => billType.bill_type_name !== '水电费');
      }
      if(this.billTypeList && this.billTypeList.length > 0){
        this.refundBillTypeList =
          this.billTypeList.filter(billType => (billType.bill_type_name === '房费'
            || billType.bill_type_name === '押金' || billType.bill_type_name === '预定金'));
      }

    });
  }

  /**
   * 选择账单类型
   */
  selectBillingType(event: any, type?: any) {
    let value = event.target.value;
    if (value && value > 0) {
      this.expenseType = 'renterExpense';
      if (type && type === 'REFUND') {
        this.canRefundBillDetail(this.expenseType, value);
      } else {
        this.paymentDetail(this.expenseType, value);
      }
    } else {
      this.expenseType = 'renterExpense';
      if (type && type === 'REFUND') {
        this.canRefundBillDetail(this.expenseType);
      } else {
        this.paymentDetail(this.expenseType);
      }
    }
  }

  //contractNum 合同号 打开退款页面
  openRefundDialog(leaseId: any) {
    this.changeValueToNull();
    this.showTips = true;
    if (leaseId) {
      this.hasContact = true;
      this.expenseType = 'renterExpense';
      this.noContract = true;
      this.enterRefundLeaseId('LEASE_ID', leaseId);
    }
  }

  enterRefundLeaseId(leaseType?: any, leaseValue?: any) {
    this.actualAmountValidErrorContent = null;
    // //add by yangwen增加“全部”选项,默认选中“全部”
    this.carportInfoList = [];
    const reqDate = {lease_room_query_type: leaseType, lease_value: leaseValue, gather_type: '1RENT'};
    //获取租约下的车库列表
    this.carportService.getLeaseCarports(reqDate).then(res => {
      if (!res || !res.lease_id) {
        this.errorContent = "无效的合同号，请重新输入";
        this.carportInfoList = [];
        this.showTips = true;
        return;
      }
      this.carportIds = [];
      this.paymentleaseId = res.lease_id;
      this.contactNum = res.contract_num;
      this.confirmPaymentRequest.lease_id = this.paymentleaseId;
      this.errorContent = null;
      res.carport_info_dtolist.forEach(e => {
        this.carportInfoList.push(e);
      });
      this.carportIds = this.carportInfoList.map(r => r.carport_info_id);
      if (this.expenseType) {
        //输入合同的同时也刷新下面的数据
        this.canRefundBillDetail(this.expenseType);

      } else {
        this.refundApplyRequest = new RefundApplyRequest();
        this.refundApplyRequest.audit_phone = 'ALL';
        this.refundApplyRequest.apply_type = 'CARPORT_LEASE';
        this.refundBillDialog.show();
      }
    }).catch(e => {
      this.errorContent = "无效的合同号，请重新输入";
      this.logService.error('PactComponent:获取租约下的车库失败' + JSON.stringify(e));
      this.showTips = true;
      return;
    });
  }

  //获取付款信息
  canRefundBillDetail(expenseType?: string, billTypeId?: number) {
    //将参数置空
    this.changeValueToNull();
    this.refundApplyRequest.lease_id = this.paymentleaseId;
    this.refundApplyRequest.payment_total_amount = '0';
    this.expenseType = expenseType;
    this.gatherAmountDetailRequestParam.refundable_bill = "REFUND_BILL";
    this.gatherAmountDetailRequestParam.gather_type = this.expenseType;
    this.gatherAmountDetailRequestParam.carport_lease_id = this.paymentleaseId;
    this.gatherAmountDetailRequestParam.carport_ids = this.carportIds;
    if (billTypeId) {
      this.gatherAmountDetailRequestParam.bill_type_ids = [billTypeId];
    } else {

      if (this.billTypeList) {
        this.gatherAmountDetailRequestParam.bill_type_ids = this.billTypeList
          .filter(billlType => (billlType.bill_type_name === '房费'
            || billlType.bill_type_name === '押金' || billlType.bill_type_name === '预定金')).map(billType => billType.bill_type_id);
      }
    }
    this.refundApplyRequest.carport_ids = this.carportIds;
    if (expenseType === 'renterExpense') {
      //月租金、押金
      this.carportService.gatheringBillDetail(this.gatherAmountDetailRequestParam).then(res => {
        this.allBillIds = [];
        if (res) {
          this.refundApplyRequest = new RefundApplyRequest();
          this.refundApplyRequest.audit_phone = 'ALL';
          this.refundApplyRequest.apply_type = 'CARPORT_LEASE';
          this.allBillIds = res.carport_lease_bill_list.map(r => r.bill_id);
          this.billList = res.carport_lease_bill_list;
          this.refundApplyRequest.gather_type = this.expenseType;
          this.paymentedTotalAmount = res.paymented_amount;
          if (!this.gatherByBillId) {
            this.checkedBillIds = [];
          } else {
            if (this.allBillIds.lastIndexOf(this.gatherByBillId) < 0) {
              //如果当前账单不含有用户勾选的账单id
              this.checkedBillIds = [];
            } else {
              this.billList.forEach(e => {
                if (e.bill_id === this.gatherByBillId) {
                  this.refundApplyRequest.payment_total_amount = e.waiting_pay_amount;
                  this.checkedBillIds.push(this.gatherByBillId);
                  this.refundApplyRequest.bill_ids = this.checkedBillIds;
                  this.refundApplyRequest.actual_payment_amount = e.waiting_pay_amount;
                  this.ckeckedNum += 1;
                }
              });
            }
            this.gatherByBillId = null;
          }
          this.refundBillDialog.show();
        }
      }).catch(error => {
        this.logService.error('PactComponent:获取账单列表信息失败');
        return;
      });
    } else {
      if (this.paymentleaseId) {
        this.carportService.gatheringBillDetail(this.gatherAmountDetailRequestParam).then(res => {
          if (res) {
            this.paymentInfoDTO = res;
            //如果费用类型是 其他类型
            if (this.expenseType === 'otherExpense') {
              this.confirmPaymentRequest.payment_total_amount = '0';
            } else {
              this.confirmPaymentRequest.payment_total_amount = this.paymentInfoDTO.payment_total_amount;
            }
            this.confirmPaymentRequest.gather_type = this.expenseType;
          } else {
            this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '无待收款账单'));
            return;
          }
        }).catch(error => {
          this.logService.error('PactComponent:获取收款详情失败');
          return;
        });
      }
    }
    this.showTips = false;
  }

  /**
   * 关闭退款申请页面。
   * @param refundBillModel
   */
  closeRefundDialog(refundBillModel?: any) {
    this.changeValueToNull();
    this.contactNum = null;
    this.billList = [];
    this.refundApplyRequest = new RefundApplyRequest();
    this.refundApplyRequest.audit_phone = 'ALL';
    this.refundApplyRequest.apply_type = 'CARPORT_LEASE';
    refundBillModel.hide();
  }

  /**
   * 选择转账类型
   */
  modeOfPaymentSet(evetn: any, type: string) {
    if (evetn.target.checked) {
      this.modeOfPayment = type;
      if (type && type === 'CASE') {
        this.refundApplyRequest.gather_value = null;
        this.refundApplyRequest.bank_name = null;
        this.refundApplyRequest.card_number = null;
      }
    } else {
      this.modeOfPayment = '';
    }
  }

  /**
   * 选中转账收款时，输入收款账户相关信息
   * @param type
   * @param inputValue
   */
  entryTransferInfo(type: any, inputValue: any) {
    if (type && type === 'GATHER_VALUE') {
      // 收款人姓名
      if (inputValue && this.refundApplyRequest.bank_name && this.refundApplyRequest.card_number) {
        this.canRefundNextError = false;
      } else {
        this.canRefundNextError = true;
      }
    } else if (type && type === 'BANK_NAME') {
      // 开户行
      if (inputValue && this.refundApplyRequest.gather_value && this.refundApplyRequest.card_number) {
        this.canRefundNextError = false;
      } else {
        this.canRefundNextError = true;
      }
    } else if (type && type === 'CARD_NUMBER') {
      // 银行账号
      if (inputValue && this.refundApplyRequest.gather_value && this.refundApplyRequest.bank_name) {
        this.canRefundNextError = false;
      } else {
        this.canRefundNextError = true;
      }
    } else {
      return;
    }
  }

  /**
   * 获取审核短信验证码
   * @param modal
   */
  getAuditCodeWithUser(modal?: any) {
    if (this.refundApplyRequest.audit_phone !== 'ALL') {
      let requestRoomIds = [];
      if (this.carportIds.length === 1 && this.carportIds[0] === 0) {
        requestRoomIds = this.carportInfoList.map(roomInfo => roomInfo.carport_info_id);
      } else {
        requestRoomIds = this.carportIds;
      }
      let request = {
        lease_id: this.leaseId,
        room_ids: requestRoomIds,
        bill_ids: this.checkedBillIds,
        phone: this.refundApplyRequest.audit_phone,
        code: '0000',
        lease_type: 'CARPORT_LEASE'
      };
      this.refundListService.refundBillAuditSmsSend(request).then(response => {
        this.time = 60;
        this.inter = setInterval(() => {
          if (--this.time > 0) {
            this.codeBtnStr = this.time + 's后重新获取';
          } else {
            this.time = 60;
            this.codeBtnStr = '点击获取';
            clearInterval(this.inter);
          }
        }, 1000);
      }).catch(error => {

      });
    }
  }

  /**
   * 验证码输入
   * @param codeValue
   */
  auditCodeInput(codeValue: string) {
    this.auditCodeError = false;
  }

  /**
   * 确认请求申请退款，弹窗确认验证码
   */
  confirmRefundRequest(model: any) {
    if (this.canRefundNextError && (this.modeOfPayment && this.modeOfPayment === 'TRANSFER')) {
      return;
    } else {
      this.refundApplyRequest.audit_phone = 'ALL';
      this.refundApplyRequest.apply_type = 'CARPORT_LEASE';
      model.show();
    }
  }

  /**
   * 提交 退款申请
   */
  confirmRefundSubmit() {
    if (this.refundApplyRequest.audit_phone !== 'ALL ' && this.refundApplyRequest.audit_code) {
      this.refundApplyRequest.mode_of_payment = this.modeOfPayment;
      this.refundApplyRequest.lease_id = this.leaseId;
      this.refundListService.renfundGatherHandler(this.refundApplyRequest).then(response => {
        if (response && response.result === '短信验证码验证错误') {
          this.auditCodeError = true;
        } else if (response && response.result === '执行成功') {
          this.resultRefundNo = response.refund_no;
          this.refundBillConfirmDialog.hide();
          this.refundBillConfirmSuccessDialog.show();
          this.paymentCallback.emit();
        }
      }).catch(error => {

      });
    }
  }

  closeRefundConfirmDialog(refundBillModel?: any) {
    this.refundApplyRequest.audit_phone = "ALL";
    this.refundApplyRequest.audit_code = null;
    refundBillModel.hide();
  }

  /**
   * 账单申请退款，选中/取消选中处理
   * @param event
   * @param billId
   * @param receiveAmount
   * @param billTypeName
   */
  checkRefundCurrentBill(event?: any, billId?: any, receiveAmount?: any, billTypeName?: string) {
    //用户输入金额
    let currentActualAmount = this.changToDecimal("0");
    if (this.refundApplyRequest.actual_payment_amount) {
      currentActualAmount = this.changToDecimal(this.refundApplyRequest.actual_payment_amount);
    }
    // 待退款金额
    let waitingAmountCheck = this.changToDecimal(receiveAmount);
    // 如果是勾选了账单
    if (event.target.checked) {
      if (this.refundApplyRequest.payment_total_amount) {
        this.refundApplyRequest.payment_total_amount =
          //保留两位小数点
          this.getTwoPoint(this.changToDecimal(this.refundApplyRequest.payment_total_amount).add(waitingAmountCheck));
      } else {
        this.refundApplyRequest.payment_total_amount = this.getTwoPoint(waitingAmountCheck);
      }
      this.refundApplyRequest.actual_payment_amount =
        this.getTwoPoint(currentActualAmount.add(waitingAmountCheck));
      this.ckeckedNum += 1;
      this.checkedBillIds.push(billId);
    } else {
      //取消选中
      if (this.refundApplyRequest.actual_payment_amount
        && this.changToDecimal(this.refundApplyRequest.actual_payment_amount).greaterThan("0")) {
        //取消后的账单金额>实际支付金额 此处的实际支付金额是用户付的
        if (waitingAmountCheck.greaterThan(currentActualAmount)) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "收款金额不能大于应付金额"));
          event.target.checked = true;
          return;
        }
      } else {
        if (currentActualAmount.lessThan(receiveAmount)) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "应付金额不能小于0"));
          event.target.checked = true;
          return;
        }
      }
      this.refundApplyRequest.payment_total_amount =
        this.getTwoPoint(this.changToDecimal(this.refundApplyRequest.payment_total_amount).sub(waitingAmountCheck));
      this.refundApplyRequest.actual_payment_amount =
        this.getTwoPoint(currentActualAmount.sub(waitingAmountCheck));
      this.ckeckedNum -= 1;
      this.checkedBillIds = removeFromArray(this.checkedBillIds, billId);
    }
    //如果为选中账单
    if (this.checkedBillIds.length <= 0) {
      this.refundApplyRequest.actual_payment_amount = '0';
    }
    this.refundApplyRequest.bill_ids = this.checkedBillIds;
  }

  /**
   * 申请退款账单全选功能
   * @param event
   */
  checkRefundBillAll(event?: any) {
    if (event.target.checked) {
      // 全选
      this.checkedBillIds = this.allBillIds;
      this.ckeckedNum = this.allBillIds.length;
      if (this.paymentedTotalAmount) {
        this.refundApplyRequest.payment_total_amount = this.paymentedTotalAmount.toString();
        this.refundApplyRequest.actual_payment_amount = this.refundApplyRequest.payment_total_amount;
      }
    } else {
      this.checkedBillIds = [];
      this.ckeckedNum = 0;
      this.refundApplyRequest.payment_total_amount = null;
      this.refundApplyRequest.actual_payment_amount = '0';
    }
    this.refundApplyRequest.bill_ids = this.checkedBillIds;
  }


}
