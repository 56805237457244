import { Injectable } from '@angular/core';

import { RequestService } from '../request.service';
import { environment } from '../../../environments/environment';

/**
 * @Description:  用户权限码业务类
 * @Author: zhoujiahao
 * @Date: 2018-03-16  17:40
 */
@Injectable()
export class PrivilegeService {

  // 查询用户权限码
  private PRIVILEGE_QUERY = `${environment.apiHost}privilege/query`;
  //获得所有模块的权限
  private PRIVILEGE_LIST = `${environment.apiHost}privilege/list`;

  //获得用户所有权限码ID
  private USER_PRIVILEGES = `${environment.apiHost}privilege/user/list`;

  //根据操作类型查询是否有操作权限
  private USER_OPERATE_PRIVILEGES = `${environment.apiHost}privilege/operate/list`;

  //根据操作类型查询是否有操作权限
  private GET_ADMINS = `${environment.apiHost}privilege/code/admins`;

  //系统平台查询pai
  private GET_SYS_PLAT = `${environment.apiHost}common/getSysPlatforms`;


  constructor(private requestService: RequestService) {
  }

  /**
   * @Description:   获取用户权限码
   * @Author: zhoujiahao
   * @Date: 2018-03-16  17:45
   */
  public getPrivilegeQuery(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.PRIVILEGE_QUERY, requestData);
  }

  public getAllModelPrivilege(req): Promise<any> {
    return this.requestService.authGet(this.PRIVILEGE_LIST, req);
  }

  public getUserPrivilegeids(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.USER_PRIVILEGES, requestData);
  }

  /**
   * @Description:   根据用户操作权限查询是否有权限（返回含有的权限）
   * @Author: zhoujiahao
   * @Date: 2018-04-03  15:53
   */
  public getUserOperatePrivilege(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.USER_OPERATE_PRIVILEGES, requestData);
  }

  public getAdminsByPrivilegeCode(requestData: any) : Promise<any> {
    return this.requestService.authGet(this.GET_ADMINS, requestData);
  }

  public getSysPlatForms() : Promise<any> {
    return this.requestService.authGet(this.GET_SYS_PLAT);
  }
}
