import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appNavDropdown]'
})
export class NavDropdownDirective {

  constructor(private el: ElementRef) {
  }

  toggle() {
    this.el.nativeElement.classList.toggle('open');
  }
}

/**
 * Allows the dropdown to be toggled via click.
 */
@Directive({
  selector: '[appNavDropdownToggle]'
})
export class NavDropdownToggleDirective {


  constructor(private dropdown: NavDropdownDirective) {
  }

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    this.dropdown.toggle();
  }
}


@Directive({
  selector: '[appToggleClazz]'
})
export class ToggleClazzDirective {

  @Input('appToggleClazz')
  clazz: string;

  constructor(private el: ElementRef) {
  }

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();

    if (this.clazz) {
      this.el.nativeElement.classList.toggle(this.clazz);
    }
  }
}

@Directive({
  selector: '[appToggleChildClazz]'
})
export class ToggleChildClazzDirective {

  @Input('appToggleChildClazz')
  child: { child: string, childClazz: string };

  constructor(private el: ElementRef) {
  }

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();

    if (this.child) {
      let child = this.el.nativeElement.querySelector(this.child.child);
      child.classList.toggle(this.child.childClazz);
    }
  }
}

export const NAV_DROPDOWN_DIRECTIVES = [
  NavDropdownDirective,
  NavDropdownToggleDirective,
  ToggleClazzDirective,
  ToggleChildClazzDirective
];
