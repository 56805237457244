<div class='item' (click)="select($event)">
  <div class="item" [class.active]="item.selected && !needCheckBox">
    <a href="javascript:void(0)" (click)="toggleOpen($event)">
            <span>
                    <i class="fa"
                    [class.fa-plus-square-o]="haveChildren && !isOpen"
                    [class.fa-minus-square-o]="haveChildren && isOpen"></i>
                </span>
            <i class="fa" *ngIf="needCheckBox" [class.fa-check-square-o]="item.checked === true" [class.fa-square-o]="item.checked === false"
                [class.fa-square]="item.checked === null" (click)="select($event)"></i>
            <span *ngIf="allowParentSelection" (click)="select($event)"> {{item.text}}</span>
            <span *ngIf="!allowParentSelection"> {{item.text}}</span>
        </a>
  </div>
  <ul *ngIf="haveChildren && isOpen" class="ui-select-choices" role="menu">
    <li *ngFor="let o of item.children | itemPipe:filter" role="menuitem">
      <tree-select-item [item]="o" [onTouchedCallBack]="onTouchedCallBack"></tree-select-item>
    </li>
  </ul>
</div>
