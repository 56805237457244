import { PageReq, PageResp } from '../page/page';
import { AnnexDTO } from '../common/common.model';

export class ComplaintsListRequest extends PageReq {
  // @Remark("投诉处理状态")
  complaints_status_list?: Array<string>;
  // 状态
  complaints_status?: string;

  // 类型
  complaints_type?: string;

  // 分类
  classify_type?: string;

  // @Remark("投诉用户手机号")
  complaints_user_phone?: string;

  // 开始提交时间
  start_create_time?: number;

  // 结束提交时间
  end_create_time?: number;
}

export class ComplaintsListResponse extends PageResp {
// @Remark("投诉集合信息")
  complaints_info_dtos?: Array<ComplaintsInfoDTO>;
}

export class ComplaintsInfoDTO {
  // @Remark("投诉ID")
  complaints_id?: string;
  // @Remark("投诉时间")
  create_time?: number;
  // @Remark("投诉人名称")
  complaints_name?: string;
  // @Remark("投诉人手机号")
  complaints_user_phone?: string;
  // @Remark("投诉状状态")
  complaints_status?: string;
  // @Remark("最后处理时间")
  solve_time?: number;
  // @Remark("最后处理人")
  solve_user_name?: string;
  // @Remark("投诉内容")
  content?: string;
  // @Remark("投诉内容")
  complaints_num?: string;
  // @Remark("附件")
  annex_dtos?: Array<AnnexDTO>;
  room_name:string;
  contract_num:string;
  complaints_type:string;
  complaints_type_name:string;
  classify_type: string;
  //态度分数
  score_attitude?:number;
  //质量分数
  score_quality?:number;
  //评价内容
  comment_content?:string;
}

export class ComplaintsDetailRequest {
// @Remark("投诉ID")
  complaints_id?: string;
}

export class ComplaintsDealRequest {
// @Remark("投诉ID")
  complaints_id?: string;
// @Remark("投诉处理操作")
  operate_type?: string;
// @Remark("处理结果")
  operate_content?: string;
  // 处理分类
  classify_type?: string;
}

export class ComplaintsLogListResponse {
  // @Remark("日志列表")
  complaints_log_info_dtos?: Array<ComplaintsLogInfoDTO>;
}

export class ComplaintsLogInfoDTO {
  // @Remark("日志ID")
  log_Id?: number;
  // @Remark("投诉ID")
  complaints_Id?: number;
  // @Remark("日志操作类型:WAITING:未处理,PROCESSING:处理中,FIXED:处理完成,COMPLAINTS:继续投诉")
  log_type?: string;
  // @Remark("操作人类型:RENTER:租客,SYSTEM:工作人员")
  operate_user_type?: string;
  // @Remark("操作人名称:操作人类型是租客则 名字为 '用户'")
  operate_user_name?: string;
  operate_log_content?: string;
  // @Remark("操作时间")
  create_time?: number;
  // @Remark("操作内容")
  log_content?: string;
  // @Remark("附件")
  annex_dtos?: Array<AnnexDTO>;
}

export class ComplaintStatisticsRequest{
  start_time?: number;
  end_time?: number;
}
