import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {LogService} from '../../service/log/log.service';
import {Constants} from '../../common/constants';
import {Injectable} from '@angular/core';
import {PrivilegeService} from "../../service/privilege/privilege.service";
import {AuthService} from "../../service/common/auth.service";
import {LocalStorage} from '../../common/localstorage';

/**
 * @description:操作类权限预加载
 *
 * @Author: yinqiang
 * @Date: 2018-03-28 14:16:13
 * @Last Modified by: yinqiang
 * @Last Modified time: 2018-03-28 15:29:56
 */
@Injectable()
export class PrivilegeResolve implements Resolve<string[]> {

  operatePrivilegesReqdata = {
    operation_priviliges: [],
    company_id: null
  };
  isShow: boolean;

  constructor(
    private logService: LogService,
    private privilegeService: PrivilegeService,
    private authService: AuthService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<string[]> {
    let user = this.authService.getUserInfo();
    return new Promise<string[]>((resolve, reject) => {

      this.logService.debug("PrivilegeResolve execute");

      //获取权限码 Constants.OPERATION_PRIVILEGES是route配置中data中key为OPERATION_PRIVILEGES的值
      let priviliges: string[] = route.data[Constants.OPERATION_PRIVILEGES];

      this.logService.debug(" 新增的权限码 " + JSON.stringify(priviliges));

      this.operatePrivilegesReqdata.operation_priviliges = [];

      //这里请求数据库 获取权限 将 priviliges作为参数传给api api的返回可以转换为set类型 在com中判断有没有 有就认为有权限 无则无权限
      if (priviliges && priviliges.length > 0) {
        //获得之前缓存过得操作权限
        let localItem = LocalStorage.getItem(Constants.USER_OPERATE_PRIVILEGES + user.id);
        if (!localItem) {
          localItem = "{}";
        }
        let allOperatePrivileges = JSON.parse(localItem);
        if (allOperatePrivileges && allOperatePrivileges.length > 0) {
          priviliges.forEach(value => {
            if (!allOperatePrivileges.find(all => all === value)) {
              this.operatePrivilegesReqdata.operation_priviliges.unshift(value);
            }
          });
        } else {
          this.operatePrivilegesReqdata.operation_priviliges = priviliges;
        }


        if (this.operatePrivilegesReqdata.operation_priviliges.length > 0) {
          const reqData = {};
          for (let item in this.operatePrivilegesReqdata) {
            if (item) {
              reqData[item] = this.operatePrivilegesReqdata[item];
            }
          }
          // 如果选择了公司, 传递公司参数
          let companyId = LocalStorage.getItem(Constants.CURRENT_COMPANY_ID + user.id);
          if (companyId) {
            this.operatePrivilegesReqdata.company_id = companyId;
          }

          let result = new Set<string>();
          this.privilegeService.getUserOperatePrivilege(this.operatePrivilegesReqdata).then(res => {
            let resopone = [];
            if (res.operation_priviliges) {
              resopone = res.operation_priviliges;
            }
            if (resopone && allOperatePrivileges && allOperatePrivileges.length > 0) {
              allOperatePrivileges.forEach(value => {
                resopone.unshift(value);
              });
            }
            localStorage.setItem(Constants.USER_OPERATE_PRIVILEGES + user.id, JSON.stringify(resopone));

            resolve(resopone);
          });

        } else {
          resolve([]);
        }
      } else {
        resolve([]);
      }
    });
  }

  /**
   * @Description:   通过传入的按钮操作判断当前永不是否有展示按钮的权限
   * @Param:
   * @return:
   * @Author: zhoujiahao
   * @Date: 2018-04-24  19:26
   */
  hasPrivilege(operateType?: any): boolean {
    let data = LocalStorage.getItem(Constants.USER_OPERATE_PRIVILEGES + this.authService.getUserInfo().id);
    if (data) {
      let privileges = JSON.parse(data);
      this.isShow = privileges.find(value => value === operateType);
    }
    return this.isShow;
  }
}
