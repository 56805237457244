<div class="m-3">
  <button class="btn btn-warning" (click)="tabletComponent.tabletShow()" *ngIf="isPad()">线上签字</button>
  <button class="btn btn-warning" (click)="signOnIpad()" *ngIf="!isPad()">同步pad签字</button>
  <button class="btn btn-secondary ml-2" [routerLink]="['/system_manage/signed/pact', lease_id]">返回</button>
</div>
<div class="setbox-top">
    <div class="bg-primary ">
      <div class="row">
        <div class="col-12 col-md-6 col-xl-7 hei32">
          <i class="fa fa-info-circle">合同号：{{electContratInfoResponse?.contract_num}}</i>
          <!--<i class="fa fa-info-circle">租约编号</i>-->
        </div>
        <div class="col-12 col-md-6 col-xl-5">
          <p class="text-yellow1 text-right hei32">
            <span class="mr20">合同状态:待生效</span>
            <span>审核状态：
              <ng-container *ngIf="electContratInfoResponse?.audit_status == 'NO_AUDIT'">
                无需审核
              </ng-container>
              <ng-container *ngIf="electContratInfoResponse?.audit_status == 'AUDIT_SUCCESS'">
                已审核
              </ng-container>
            </span>
          </p>
        </div>
      </div>
    </div>
    <div class="setbox contract">
      <div class="col-12" [innerHTML]="template_html | html"></div>
    </div>
</div>

<!-- 写字板 -->
<app-tablet [title]="title" (commitSign)="commitSign($event)"></app-tablet>

<!-- 操作提示 -->
<div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static', keyboard: false}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-static-name" class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" [routerLink]="['/system_manage/signed/pact', lease_id]">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <p class="text-dark mt-3 mb-3 font16">提交成功！</p>
        <p class="text-dark mt-3 mb-3 font16">关闭弹窗后可打印电子合同，也可在已签署合同明细页面，点击“合同预览”再次打印。</p>
        <button class="btn btn-info" [routerLink]="['/system_manage/signed/contract/preview', lease_id]">关闭</button>
      </div>
    </div>
  </div>
</div>

<app-sign-device-binding #signDeviceBindingComponent></app-sign-device-binding>
