import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {AuthService} from "../../common/auth.service";
import {RequestService} from "../../request.service";
import {
  OrderDetailResponse, WithdrawManagerModel,
  WithdrawManagerRequest,
  WithdrawManagerRespone
} from "../../../model/ebusiness/ebusiness.model";

@Injectable()
export class EWithdrawService {

  // 提现记录列表
  private GET_WITHDRAW_LIST = `${environment.apiHost}eb/withdrawRecord/list`;

  // 提现记录详情
  private GET_WITHDRAW_DETAIL = `${environment.apiHost}eb/withdrawRecord/withdrawDetail`;

  // 提现审核处理
  private HANDLE_WITHDRAW_AUDIT = `${environment.apiHost}eb/withdrawRecord/audit/handle`;

  constructor(
    private auth: AuthService,
    private requestService: RequestService
  ) {

  }

  /**
   * 提现记录列表
   */
  getWithdrawList(req?: WithdrawManagerRequest): Promise<WithdrawManagerRespone> {
    return this.requestService.authGet(this.GET_WITHDRAW_LIST, req);
  }

  /**
   * 提现记录详情
   */
  getWithdrawDetail(withdrawRecordId?: number): Promise<WithdrawManagerModel> {
    return this.requestService.authGet(this.GET_WITHDRAW_DETAIL, {withdrawRecordId: withdrawRecordId});
  }

  /**
   * 提现审核处理
   */
  handleWithdrawAudit(params: any): Promise<any> {
    return this.requestService.authPost(this.HANDLE_WITHDRAW_AUDIT, params);
  }
}
