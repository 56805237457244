import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SignedService } from '../../../service/signed/signed.service';
import { CheckoutRoomInventoryRequest, CheckoutRoomInventoryResponse } from '../../../model/signed/inventory.model';

@Component({
  selector: 'app-checkoutinventory',
  templateUrl: './checkoutInventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class CheckoutInventoryComponent implements OnInit {
  @Input()
  lease_id: number;
  @Input()
  room_id: number;
  checkoutRoomInventoryRequest: CheckoutRoomInventoryRequest = new CheckoutRoomInventoryRequest();
  checkoutRoomInventoryResponse: CheckoutRoomInventoryResponse = new CheckoutRoomInventoryResponse();
  errorText: string;
  is_save: boolean = false;
  callback: any;
  @ViewChild('checkoutInventoryModal', { static: true }) checkoutInventory: any;
  radioText: string;

  constructor(private signdeService: SignedService) {
  }

  ngOnInit() {
    this.checkoutRoomInventoryRequest.lease_id = this.lease_id;
    this.checkoutRoomInventoryRequest.room_id = this.room_id;
  }

  hideModal(modal: any) {
    modal.hide();
  }

  openModal() {
    this.errorText = null;
    this.loadingData(this.checkoutInventory);
  }

  loadingData(modal: any) {
    if (!this.is_save) {
      this.signdeService.inventoryCheckout(this.checkoutRoomInventoryRequest).then(data => {
        this.checkoutRoomInventoryResponse = data;
        this.checkoutRoomInventoryResponse.compensate = true;
        this.radioText = "yes";
        modal.show();
      });
    } else {
      if (this.checkoutRoomInventoryResponse.compensate) {
        this.radioText = "yes";
      } else {
        this.radioText = "no";
      }
      modal.show();
    }
  }

  compensateChange(bl: boolean) {
    this.checkoutRoomInventoryResponse.compensate = bl;
    this.radioText = bl ? 'yes' : 'no';
  }

  isNotBlank(text: any) {
    if (text != null && text !== "") {
      return true;
    } else {
      return false;
    }
  }

  save(modal: any) {
    let water_reading: number = this.isNotBlank(this.checkoutRoomInventoryResponse.water_reading)
      ? Number.parseFloat(this.checkoutRoomInventoryResponse.water_reading) : 0;
    let electricity_reading: number = this.isNotBlank(this.checkoutRoomInventoryResponse.electricity_reading)
      ? Number.parseFloat(this.checkoutRoomInventoryResponse.electricity_reading) : 0;
    let top_electricity_reading: number = this.isNotBlank(this.checkoutRoomInventoryResponse.top_electricity_reading)
      ? Number.parseFloat(this.checkoutRoomInventoryResponse.top_electricity_reading) : 1;
    let top_water_reading: number = this.isNotBlank(this.checkoutRoomInventoryResponse.top_water_reading)
      ? Number.parseFloat(this.checkoutRoomInventoryResponse.top_water_reading) : 1;
    if (this.checkoutRoomInventoryResponse.water_dev || this.checkoutRoomInventoryResponse.need_water === "YES") {
      if (water_reading < top_water_reading || water_reading > 99999999) {
        this.errorText = "水表读数必须大于等于" + top_water_reading + ",并且小于等于99999999";
        return false;
      }
    }
    if (this.checkoutRoomInventoryResponse.water_dev || this.checkoutRoomInventoryResponse.need_electricity === "YES") {
      if (electricity_reading < top_electricity_reading || electricity_reading > 99999999) {
        this.errorText = "电表读数必须大于等于" + top_electricity_reading + ",并且小于等于99999999";
        return false;
      }
    }
    if (this.checkoutRoomInventoryResponse.compensate && this.checkoutRoomInventoryResponse.inventory_list != null
      && this.checkoutRoomInventoryResponse.inventory_list.length > 0) {
      for (let i = 0; i < this.checkoutRoomInventoryResponse.inventory_list.length; i++) {
        if (this.checkoutRoomInventoryResponse.inventory_list[i].status === 'NO_CHECK') {
          this.errorText = '房间尚有物品状态未确认，请确认后再保存';
          return false;
        }
      }
    }
    this.errorText = null;
    this.hideModal(modal);
    this.is_save = true;
    this.callback();
  }

  valueSet(event: any) {
    let text = event.target.value + '';
    if (Number.parseFloat(text) + "" === "NaN") {
      event.target.value = text.substring(0, text.length - 1);
      return false;
    } else if (Number.parseFloat(text) + "" !== text) {
      if (text.substring(text.length - 1, text.length) === '.') {
        text = text.substring(0, text.length - 1);
      } else if (text.substring(text.length - 1, text.length) !== '0') {
        event.target.value = Number.parseFloat(text);
      }
    }
    if (text.split('.').length > 1) {
      if (text.split('.')[1].length > 2) {
        event.target.value = text.split('.')[0] + '.' + text.split('.')[1].substring(0, 2);
      }
    }
  }

}
