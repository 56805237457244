<div class="setbox goodslist">
  <div class="goodslist-header">
    <div class="btn-list">

      <ng-container *ngIf="type === 'sign' && printDiv">
        <button printSectionId="printDiv" ngxPrint class="btn btn-primary mr-3"  >打印交接单</button>
      </ng-container>

      <button class="btn btn-info" *ngIf="type == 'sign'" (click)="alertTips()">线下确认</button>
      <button class="btn btn-warning ml-5" *ngIf="type == 'sign' && !isPad()" (click)="signOnIpad()">同步pad签字</button>
      <button class="btn btn-warning ml-5" *ngIf="type == 'sign' && isPad()" (click)="tabletComponent.tabletShow()">线上签字</button>
      <button class="btn btn-info" *ngIf="type == 'view'" (click)="printFrame()">打印交接单</button>
      <button class="btn btn-info" *ngIf="type == 'view'" (click)="remarksModal.show()">添加备注</button>
    </div>
    <div class="test-describe" *ngIf="type == 'sign'">
      操作提示 : 点击<span class="yes">【是】</span>/<span class="no">【否】</span>可切换物品状态
      <div class="text-red text-center"
           *ngIf="checkinRoomInventoryResponse && checkinRoomInventoryResponse.need_refresh_reading">
        最新水表读数获取中，30s后自动刷新。1分钟后请手动刷新页面。如10分钟后仍未获取请手动抄表
      </div>
    </div>
  </div>
  <div class="goodslist-body" id="printDiv" #printDiv>
    <table *ngIf="type === 'sign'" border="1px soild #f2f2f2" width="100%">
      <tbody>
      <tr>
        <td colspan="10">
          <div class="table-title">
            <h3 style="text-align:center;line-height: 40px">公寓物品交接清单<span>【入住】</span></h3>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="2">公寓门店</td>
        <td colspan="3">{{checkinRoomInventoryResponse?.project_name}}</td>
        <td colspan="2">房号</td>
        <td colspan="3">{{checkinRoomInventoryResponse?.room_info?.room_name}}</td>
      </tr>
      <tr>
        <td [attr.colspan]="isNeedFilter ? 1:2">水表读数</td>

        <td [attr.colspan]="isNeedFilter ? 2:3">
          <ng-container *ngIf="checkinRoomInventoryResponse.water_dev && checkinRoomInventoryResponse.water_reading">
            <input readonly="readonly" [(ngModel)]="checkinRoomInventoryResponse.water_reading" type="text">
            <span class="gray">（{{checkinRoomInventoryResponse.water_reading_time | date:'yyyy.MM.dd HH:mm'}}）</span>
          </ng-container>
          <ng-container *ngIf="checkinRoomInventoryResponse.water_dev && !checkinRoomInventoryResponse.water_reading">
            <input type="text" [(ngModel)]="roomGoodsTempletRequest.water_reading">
          </ng-container>
          <ng-container
            *ngIf="!checkinRoomInventoryResponse.water_dev && checkinRoomInventoryResponse?.need_water == 'YES'">
            <input type="text" [(ngModel)]="roomGoodsTempletRequest.water_reading">
          </ng-container>
          <span class="org"
                *ngIf="!checkinRoomInventoryResponse.water_dev && checkinRoomInventoryResponse?.need_water == 'NO'">
                  此房型设置了不需要抄表收水费
                </span>
        </td>

        <td [attr.colspan]="isNeedFilter ? 1:2" *ngIf="isNeedFilter">热水表读数</td>
        <td [attr.colspan]="isNeedFilter ? 2:3" *ngIf="isNeedFilter">
          <ng-container
            *ngIf="checkinRoomInventoryResponse.hot_water_dev && checkinRoomInventoryResponse.hot_water_reading">
            <input readonly="readonly" [(ngModel)]="checkinRoomInventoryResponse.hot_water_reading" type="text">
            <span class="gray">（{{checkinRoomInventoryResponse.hot_water_reading_time | date:'yyyy.MM.dd HH:mm'}}
              ）</span>
          </ng-container>
          <ng-container
            *ngIf="checkinRoomInventoryResponse.hot_water_dev && !checkinRoomInventoryResponse.hot_water_reading">
            <input type="text" [(ngModel)]="roomGoodsTempletRequest.hot_water_reading">
          </ng-container>
          <ng-container
            *ngIf="!checkinRoomInventoryResponse.hot_water_dev && checkinRoomInventoryResponse?.need_hot_water == 'YES'">
            <input type="text" [(ngModel)]="roomGoodsTempletRequest.hot_water_reading">
          </ng-container>
          <span class="org"
                *ngIf="!checkinRoomInventoryResponse.hot_water_dev && checkinRoomInventoryResponse?.need_hot_water == 'NO'">
                  此房型设置了不需要抄表收水费
                </span>
        </td>

        <td [attr.colspan]="isNeedFilter ? 1:2">电表读数</td>
        <td [attr.colspan]="isNeedFilter ? 3:3">
          <ng-container
            *ngIf="checkinRoomInventoryResponse.electricity_dev && checkinRoomInventoryResponse.electricity_reading">
            <input readonly="readonly" type="text" [(ngModel)]="checkinRoomInventoryResponse.electricity_reading">
            <span class="gray">（{{checkinRoomInventoryResponse.electricity_reading_time | date:'yyyy.MM.dd HH:mm'}}
              ）</span>
          </ng-container>
          <ng-container
            *ngIf="checkinRoomInventoryResponse.electricity_dev && !checkinRoomInventoryResponse.electricity_reading">
            <input type="text" [(ngModel)]="roomGoodsTempletRequest.electricity_reading">
          </ng-container>
          <ng-container
            *ngIf="!checkinRoomInventoryResponse.electricity_dev && checkinRoomInventoryResponse.need_electricity == 'YES'">
            <input type="text" [(ngModel)]="roomGoodsTempletRequest.electricity_reading">
          </ng-container>
          <span class="org"
                *ngIf="!checkinRoomInventoryResponse.electricity_dev && checkinRoomInventoryResponse?.need_electricity != 'YES'">
                  此房型设置了不需要抄表收电费
                </span>
        </td>
      </tr>
      <tr>
        <th colspan="10">
          <div class="table-title-second">
            房屋标准配置清单及价格
          </div>
        </th>
      </tr>
      <tr>
        <td colspan="2">部位</td>
        <td colspan="1">物品名称</td>
        <td colspan="1">单价</td>
        <!--<td colspan="1">是否有</td>-->
        <td colspan="1">数量</td>
        <td colspan="1">是否损坏</td>
        <td colspan="1">物品名称</td>
        <td colspan="1">单价</td>
        <!--<td colspan="1">是否有</td>-->
        <td colspan="1">数量</td>
        <td colspan="1">是否损坏</td>
      </tr>
      <!--物品-->
      <ng-container *ngFor="let key of goodsMapKey;let i = index">
        <tr *ngFor="let item of goodsMap.get(key);let j = index">
          <ng-container *ngIf="j*2<=goodsMap.get(key).length-1">
            <td *ngIf="j==0" colspan="2"
                [attr.rowspan]="goodsMap.get(key).length % 2 == 0 ? goodsMap.get(key).length / 2 : (goodsMap.get(key).length / 2)+1">
              <span class="black">{{key}}</span></td>
            <td>{{goodsMap.get(key)[j * 2].good_name}}</td>
            <td><p style="text-align: right">{{goodsMap.get(key)[j * 2].price | number}}{{goodsMap.get(key)[j * 2].unit}}</p>
            </td>
            <td>
              <ng-container *ngIf="goodsMap.get(key)[j * 2].focus">
                <input class="input-1" type="number" [(ngModel)]="goodsMap.get(key)[j * 2].quantity" min="0" max="1000" (mouseout)="changeFocus(goodsMap.get(key)[j * 2])"/>
              </ng-container>
              <ng-container *ngIf="!goodsMap.get(key)[j * 2].focus">
                <span class="span-1" (click)="changeFocus(goodsMap.get(key)[j * 2])">{{goodsMap.get(key)[j * 2].quantity}}</span>
              </ng-container>
            </td>
            <!--<td>-->
              <!--<a-->
                <!--[ngClass]="{'yes':goodsMap.get(key)[j*2].isHave==true,'no':goodsMap.get(key)[j*2].isHave==false,'change':goodsMap.get(key)[j*2].initHave!=goodsMap.get(key)[j*2].isHave}"-->
                <!--(click)="checkGoods(key, j*2, 'have',$event)">-->
                <!--{{goodsMap.get(key)[j * 2].isHave == true ? '【是】' : '【否】'}}-->
              <!--</a>-->
            <!--</td>-->
            <td>
              <a
                [ngClass]="{'yes':goodsMap.get(key)[j*2].isBad==true,'no':goodsMap.get(key)[j*2].isBad==false,'change':goodsMap.get(key)[j*2].initBad!=goodsMap.get(key)[j*2].isBad}"
                (click)="checkGoods(key, j*2, 'bad',$event)">
                {{goodsMap.get(key)[j * 2].isBad == 1 ? '【是】' : '【否】'}}
              </a>
            </td>
          </ng-container>
          <ng-container *ngIf="j*2+1<=goodsMap.get(key).length-1">
            <td>{{goodsMap.get(key)[j * 2 + 1].good_name}}</td>
            <td><p
              style="text-align: right">{{goodsMap.get(key)[j * 2 + 1].price | number}}{{goodsMap.get(key)[j * 2 + 1].unit}}</p>
            </td>
            <td>
              <ng-container *ngIf="goodsMap.get(key)[j * 2 + 1].focus">
                <input class="input-1" type="number" [(ngModel)]="goodsMap.get(key)[j * 2 + 1].quantity" min="0" max="1000" (mouseout)="changeFocus(goodsMap.get(key)[j * 2 + 1])"/>
              </ng-container>
              <ng-container *ngIf="!goodsMap.get(key)[j * 2 + 1].focus">
                <span class="span-1" (click)="changeFocus(goodsMap.get(key)[j * 2 + 1])">{{goodsMap.get(key)[j * 2 + 1].quantity}}</span>
              </ng-container>
            </td>
            <!--<td>-->
              <!--<a-->
                <!--[ngClass]="{'yes':goodsMap.get(key)[j*2+1].isHave==true,'no':goodsMap.get(key)[j*2+1].isHave==false,'change':goodsMap.get(key)[j*2+1].initHave!=goodsMap.get(key)[j*2+1].isHave}"-->
                <!--(click)="checkGoods(key, j*2+1, 'have',$event)">-->
                <!--{{goodsMap.get(key)[j * 2 + 1].isHave == true ? '【是】' : '【否】'}}-->
              <!--</a>-->
            <!--</td>-->
            <td>
              <a
                [ngClass]="{'yes':goodsMap.get(key)[j*2+1].isBad==1,'no':goodsMap.get(key)[j*2+1].isBad==0,'change':goodsMap.get(key)[j*2+1].initBad!=goodsMap.get(key)[j*2+1].isBad}"
                (click)="checkGoods(key, j*2+1, 'bad',$event)">
                {{goodsMap.get(key)[j * 2 + 1].isBad == 1 ? '【是】' : '【否】'}}
              </a>
            </td>
          </ng-container>
          <ng-container *ngIf="j*2<=goodsMap.get(key).length-1 && j*2+1>goodsMap.get(key).length-1">
            <td colspan="4"></td>
          </ng-container>
        </tr>
      </ng-container>
      <tr>
        <td colspan="1"><p style="height: 50px;line-height: 50px;">房客签字</p></td>
        <td colspan="3"><p style="height: 50px;line-height: 50px;"></p></td>
        <td colspan="1"><p style="height: 50px;line-height: 50px;">接待人</p></td>
        <td colspan="3"><p style="height: 50px;line-height: 50px;"></p></td>
        <td colspan="1"><p style="height: 50px;line-height: 50px;">确认日期</p></td>
        <td colspan="1"><p style="height: 50px;line-height: 50px;"></p></td>
      </tr>
      </tbody>
    </table>
    <div class="tips" [ngStyle]="{'left':offset_x+'px','top':offset_y+'px','display':show}">
      状态已切换
      <div class="sang">

      </div>
    </div>
  </div>
  <div *ngIf="type == 'view'" class="setbox">
    <div class="frame-content">

      <app-pdf-view [src]="iframe" [temp]="false"></app-pdf-view>

      <div *ngIf="!roomTypeGoodsFlag" class="text-center">该房型没有配置物品清单，请先配置物品清单后预览</div>
    </div>
  </div>
  <div *ngIf="type == 'view'" class="goods-remarks">
    <div class="remarks-header">
      备注信息:
    </div>
    <div class="remarks-body">
      <ng-container *ngFor="let item of remarkList">
        <p style="font-size: 14px;">
          {{item.create_time | date:'yyyy.MM.dd'}}
          &nbsp;
          {{item.create_user_name}}
          &nbsp;
          :
          &nbsp;
          {{item.remark}}
        </p>
      </ng-container>
    </div>
  </div>
</div>

<!--添加备注-->
<div class="modal fade" bsModal #remarksModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>添加备注</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="remarksModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <textarea class="remarks-text" [(ngModel)]="remark" placeholder="请输入备注内容，限60字。"></textarea>
      </div>
      <div class="modal-footer">
        <button class="btn btn-info" (click)="commitRemark()">确定</button>
      </div>
    </div>
  </div>
</div>

<!-- 写字板 -->
<app-tablet [title]="title" (commitSign)="commitSign($event)"></app-tablet>
<!-- 打印-->
<!--<app-print-preview #contract_preview [src]="path"></app-print-preview>-->

<!--物品损坏确认-->
<div class="modal fade" bsModal #damageModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>赔损确认</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="damageModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h5>以下物品破损需赔偿：</h5>
        <table class="table table-bordered" style="margin-bottom: 20px;">
          <thead>
          <tr>
            <th>物品名称</th>
            <th>单价</th>
            <th>是否损坏</th>
            <th>损坏状况</th>
            <th>赔偿金额</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>洗衣机</td>
            <td>10元/台</td>
            <td>
                <span class="change">
                  【是】
                </span>
            </td>
            <td>
              <select>
                <option>部分损坏</option>
                <option>全部损坏</option>
                <option>丢失</option>
              </select>
            </td>
            <td>
              <input type="text"/>
            </td>
          </tr>
          </tbody>
        </table>
        <h5>赔偿金额总计（元）:<span class="change" style="font-size: 20px;">0.00</span></h5>
      </div>
      <div class="modal-footer">
        <button class="btn btn-info">下一步</button>
      </div>
    </div>
  </div>
</div>

<app-sign-device-binding #signDeviceBindingComponent  (signGoodsChange)="ngOnInit()"></app-sign-device-binding>
