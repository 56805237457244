import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../../service/common/auth.service";
import { CarportInfo } from "../../../model/carport/carport.modal";
import { CarportService } from "../../../service/carport/carport.service";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { WorkModel } from "../../../model/common/common.model";
import { WorkService } from "../../../service/common/work.service";
import {MessageModalInfo} from "../../../model/modal/message-model";

@Component({
  selector: 'app-garage-add',
  templateUrl: './garage-add.component.html',
  styleUrls: ['./garage-add.component.css']
})
export class GarageAddComponent implements OnInit {

  // 新增个数
  newNumber: number = 0;
  // 车库信息列表
  carportInfoList: Array<CarportInfo> = [];

  carportInfoForm: UntypedFormGroup;
  isSubmitConfirm: boolean = false;

  constructor(
    private authService: AuthService,
    private carportService: CarportService,
    private formBuilder: UntypedFormBuilder,
    private workService:WorkService) {

  }

  ngOnInit() {
    this.carportInfoForm = this.formBuilder.group({
      carportFormArrayList: this.formBuilder.array([])
    });
  }

  newNumberInput(value:any){
    this.newNumber = value;
  }

  get carportFormArrayList(): UntypedFormArray {
    return this.carportInfoForm.get('carportFormArrayList') as UntypedFormArray;
  }

  addNewRow() {
    if (this.newNumber && this.newNumber <= 20) {
      for (let i = 0; i < this.newNumber; i++) {
        if(this.carportInfoList.length <= 20){
          this.carportInfoList.push({
            // carport_number:'',
            // month_price_str:''
          });
        }
      }
    } else {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请输入添加的个数且大于0小于20！"));
    }
  }

  releaseNowRow() {
    this.carportInfoList = [];
  }

  submitCarportAdd() {
    let flag = true;
    for (let i = 0; i < this.carportInfoList.length; i++) {
      if (!this.carportInfoList[i].carport_number) {
        flag = false;
        break;
      }
      if (!this.carportInfoList[i].month_price) {
        flag = false;
        break;
      }
    }
    let isDuplicate = this.containsDuplicate(this.carportInfoList);
    if (isDuplicate) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "表格内车库/位号【" + isDuplicate + "】重复，请修改数据！"));
      return;
    }
    if (flag) {
      this.isSubmitConfirm = true;
      this.carportService.addCarportManageIncreased({carportIncreasedList: this.carportInfoList}).then(response => {
        if (response.is_success && response.is_success === 'defeated') {
          this.isSubmitConfirm = false;
          let dupCarportNumber = "";
          if (response.carport_numbers) {
            for (let i = 0; i < response.carport_numbers.length; i++) {
              if (i === 0) {
                dupCarportNumber += response.carport_numbers[i];
              } else {
                dupCarportNumber += ("，" + response.carport_numbers[i]);
              }
            }
          }
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "车库/位号【" + dupCarportNumber + "】系统已存在，请修改数据！"));
        } else if (response.is_success && response.is_success === 'success') {
          let messageModalInfo = new MessageModalInfo();
          messageModalInfo.operationMessage = '添加车库/位成功！!';
          messageModalInfo.returnUrl = '/system_manage/signed/carport/list';
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
        }
      }).catch(error => {
        this.isSubmitConfirm = false;
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请填写完表格中的内容！"));
      });
    } else {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请填写完表格中的内容！"));
    }
  }

  containsDuplicate(carportInfos: Array<CarportInfo>): string {
    // 设置flag
    // 容错判断
    if (carportInfos.length <= 1) {
      return;
    }
    // 通过最简单直白的去重的思想去处理
    let current: string[] = [];
    for (let i = 0; i < carportInfos.length; i++) {
      let result = current.indexOf(carportInfos[i].carport_number) === -1;
      if (result) {
        current.push(carportInfos[i].carport_number);
      } else {
        return carportInfos[i].carport_number;
      }
    }
    return;
  }

  inputCarportNumber(event: any, index: number) {
    this.carportInfoList[index].carport_number = event.target.value;
  }

  inputMonthPriceStr(event: any, index: number) {
    this.carportInfoList[index].month_price_str = event.target.value;
    this.carportInfoList[index].month_price = event.target.value;
  }


}
