import {Component, Input, OnInit} from '@angular/core';
import {LeaseBaseInfoDTO} from "../../../../model/lease/lease.model";
import {CheckoutRoomViewRequest} from '../../../../model/signed/checkout.model';
import {SignedService} from '../../../../service/signed/signed.service';

@Component({
  selector: 'app-check-out-confirm',
  templateUrl: './check-out-confirm.component.html',
  styleUrls: ['./check-out-confirm.component.css']
})
export class CheckOutConfirmComponent implements OnInit {

  @Input() paymentleaseId: number;
  @Input() roomIdList: Array<any> = [];
  @Input() isLease_room_id: Array<any> = [];
  // @Output() isCheckBack: EventEmitter<any> = new EventEmitter<any>();
  checkoutRoomViewRequest: CheckoutRoomViewRequest = new CheckoutRoomViewRequest();
  isCheckout: boolean = false;
  isShow: boolean = false;
  isRemark: boolean = false;//备注
  isRadio: boolean = false;//单选框提示语
  listData: any = [];
  //退房原因
  checkoutReson: string;
  checkoutResonExt: string;
  setRadio: string = "";//单选
  outRoomId: Array<any> = [];//仅存储转租退房方式
  str: number = 0;

  @Input()
  leaseDetailResponse: LeaseBaseInfoDTO;

  constructor(
    private signedService: SignedService,
  ) {
  }

  ngOnInit() {
    this.isRoom();
  }

  isRoom() {
    let checkoutRoomViewRequest = {};
    if (this.roomIdList.length > 0) {
      checkoutRoomViewRequest = {
        lease_id: this.paymentleaseId,
        lease_room_ids: this.roomIdList
      };
    } else {
      checkoutRoomViewRequest = {
        lease_id: this.paymentleaseId,
        lease_room_ids: this.isLease_room_id
      };
    }

    this.signedService.checkoutRoomChooseConfirmNew(checkoutRoomViewRequest).then(data => {
      data.checkout_room_list.forEach(item => {
        item['end_time'] = data.lease_info.end_time;
      });
      this.listData = data.checkout_room_list;

    });

  }

  canNoMoreFeeAudit(e, id) {
    if (this.listData) {
      this.listData.forEach(el => {
        if (el.lease_room_id == id) {
          el['checkoutRoomType'] = e.target.value;
        }

      });
    }
    if (e.target.value == 'SUBLEASE') {
      this.isShow = true;
      this.str++;
      this.outRoomId.push(id);

    } else {

      let index = this.outRoomId.indexOf(id);
      this.outRoomId.splice(index, 1);

      this.str--;
      if (this.str == 0) {
        this.isShow = false;
      }
    }
  }

  radioChage(e) {
    this.setRadio = e;
    if (this.setRadio != '') {
      this.isRadio = false;
    }
  }

  checkOutRoom() {

    if (this.checkoutReson) {
      this.isCheckout = false;
    }
    if (this.checkoutReson == '其他') {
      this.isRemark = true;
    }
    // this.setData.roomType=this.checkoutReson
  }

  checkOutRoomInput() {
    if (this.checkoutResonExt) {
      this.isRemark = false;
    } else {
      this.isRemark = true;
    }
  }
}
