import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {
  InventorViewRequest,
  InventorViewResponse,
  RoomInventoryAffirmRequest
} from '../../../model/signed/inventory.model';
import {SignedService} from '../../../service/signed/signed.service';
import {MessageModalInfo} from '../../../model/modal/message-model';
import {WorkService} from '../../../service/common/work.service';
import {WorkModel} from '../../../model/common/common.model';
import {CheckinInventoryComponent} from "./CheckinInventory.component";
import {environment} from '../../../../environments/environment';
import { ModalDirective } from "ngx-bootstrap/modal";
@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit, OnChanges {

  //打印
  // @ViewChild(PrintPreviewComponent) printprew: PrintPreviewComponent;
  @ViewChild(CheckinInventoryComponent) checkinInventoryComponent: CheckinInventoryComponent;
  @ViewChild('inventoryModal') inventoryModal: ModalDirective;
  @Output()
  lessee: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  lease_id: number;
  @Input()
  room_id: number;
  inventorViewRequest: InventorViewRequest = new InventorViewRequest();
  inventorViewResponse: InventorViewResponse = new InventorViewResponse();
  errorText: string;
  idList: number[] = [];
  roomInventoryAffirmRequest: RoomInventoryAffirmRequest = new RoomInventoryAffirmRequest();
  callback: any;
  // inventoryModal: any;
  pintUrl: string;
  //打印相关
  isPhone: boolean = true;

  constructor(private signdeService: SignedService,
              private workService: WorkService) {

  }

  ngOnInit() {
    this.inventorViewRequest.lease_id = this.lease_id;
    this.inventorViewRequest.room_id = this.room_id;
    let result = localStorage.getItem('CURRENT_USER_SOURCE');
    if (!result) {
      this.isPhone = false;
    }
  }

  ngOnChanges() {
    this.inventorViewRequest.room_id = this.room_id;
  }

  loadingData(modal: any) {
    this.idList = [];
    this.inventorViewRequest.lease_id = this.lease_id;
    this.inventorViewRequest.room_id = this.room_id;
    this.signdeService.inventoryView(this.inventorViewRequest).then(data => {
      this.inventorViewResponse = data;
      modal.show();
    });
  }

  hideModal() {
    this.inventoryModal.hide();
  }

  openModal() {
    this.loadingData(this.inventoryModal);
  }

  isNotBlank(text: any) {
    if (text != null && text !== "") {
      return true;
    } else {
      return false;
    }
  }

  inventorAffirm(success: any) {
    if (this.inventorViewResponse.water_dev || this.inventorViewResponse.need_water === "YES") {
      let water_reading: number = this.isNotBlank(this.inventorViewResponse.water_reading)
        ? Number.parseFloat(this.inventorViewResponse.water_reading) : 0;
      if (water_reading <= 0 || water_reading > 99999999) {
        this.errorText = '请输入正确的水表读数';
        return false;
      }
    }
    if (this.inventorViewResponse.electricity_dev || this.inventorViewResponse.need_electricity === "YES") {
      let electricity_reading: number = this.isNotBlank(this.inventorViewResponse.electricity_reading)
        ? Number.parseFloat(this.inventorViewResponse.electricity_reading) : 0;
      if (electricity_reading <= 0 || electricity_reading > 99999999) {
        this.errorText = '请输入正确的电表读数';
        return false;
      }
    }
    this.errorText = null;
    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.operationMessage = '确认后将不能再更改，确认么？';
    messageModalInfo.cancel = true;
    this.roomInventoryAffirmRequest.water_reading = this.inventorViewResponse.water_reading;
    this.roomInventoryAffirmRequest.electricity_reading = this.inventorViewResponse.electricity_reading;
    this.roomInventoryAffirmRequest.inventory_id_list = this.idList;
    this.roomInventoryAffirmRequest.lease_id = this.lease_id;
    this.roomInventoryAffirmRequest.room_id = this.room_id;
    let pdata = this.roomInventoryAffirmRequest;
    let service = this.signdeService;
    let t = this;
    messageModalInfo.callback.subscribe(() => {
      service.inventoryAffirm(pdata).then(data => {
        success.show();
        this.inventoryModal.hide();
        if (t.callback != null) {
          t.callback();
        }
      });
    });
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
    return true;
  }

  checkboxIn(id: number, event: any) {
    if (event.target.checked) {
      this.idList.push(id);
    } else {
      for (let i = 0; i < this.idList.length; i++) {
        if (this.idList[i] === id) {
          this.idList.splice(i, 1);
          return;
        }
      }
    }
  }

  // setInventoryModal(modal: any) {
  //   this.inventoryModal = modal;
  // }

  printPage() {
    let url = "&leaseId=" + this.lease_id + "&roomId=" + this.room_id;
    this.pintUrl = `${environment.apiHost}file/preview?bizcode=CHECKININVENTORY` + url;

    this.workService.event_bus.emit(new WorkModel(this.workService.type.PDF_PRINT, this.pintUrl));
    // this.printprew.src = this.pintUrl;
    // this.printprew.show();
  }

  printComplete() {
  }

  checkin() {
    this.lessee.emit(this.lease_id);
  }
}
