import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LocalStorage} from "../../../common/localstorage";
import {AvaliableRoomInfo, AvaliableRoomListReq, AvaliableUnit, TimeWithChoosedRoomList} from '../../../model/lease/room_choose.model';
import {AuthService} from '../../../service/common/auth.service';
import {RoomService} from '../../../service/room/room.service';
import {IMyDpOptions} from "../../third-party/mydatepicker/src/lib/interfaces";
import {generateArr} from '../../../common/array.util';
import {generatePluginDate, getDateFromPlugin, getNextNMonth, getTimeFromPlugin} from '../../../common/date.util';
import {WorkService} from '../../../service/common/work.service';
import {LeaseService} from '../../../service/lease/lease.service';
import {ProjectUnitDTO} from '../../lock/smart/smart.component';
import {deepCopy} from '../../../common/form.util';
import {RoomTypeBaseDTO} from "../../../model/lease/lease.model";
import {ModalDirective} from "ngx-bootstrap/modal";
import {RenterService} from "../../../service/renter/renter.service";
import {LeaseRenterInfoDTO} from "../../../model/renter/renter.model";
import {RoomInfoDTO} from "../../../model/room/room.model";
import {RoomChangeService} from "../../../service/lease/roomchange.service";
import {RenterChangeConfirmReq} from "../../../model/lease/roomchange.model";
import {WorkModel} from "../../../model/common/common.model";
import {getFloor, getUnitName} from '../../../common/room.util';

@Component({
  selector: 'app-choose-room-modal',
  templateUrl: './choose-room-modal.component.html',
  styleUrls: ['./choose-room-modal.component.css']
})
export class ChooseRoomModalComponent implements OnInit {

  @ViewChild('modal', {static: true}) modal: ModalDirective;
  @ViewChild('staticModal', {static: true}) staticModal: ModalDirective;
  @ViewChild('roomChooseModal') choose_room_modal: ModalDirective;
  end_time: Date;

  @Input()
  is_sign: boolean = true;

  @Input()
  lease_step: string = 'NORMAL';

  //是否是换房流程的房态图
  is_changeRoom: boolean = false;
  //是否是无合同换房、有合同的合同内换房 =》这两种情况都是人员换离
  is_changRenter: boolean = false;
  //选择的要换离的人员ID
  renterIds: Array<number>;
  //老的租约房间ID
  change_lease_room_id: number;
  //选择的新房间
  selectChangeRoom: AvaliableRoomInfo;
  //换房确认信息
  renterList: Array<LeaseRenterInfoDTO>;
  oldRoomInfo: RoomInfoDTO;
  //换房确认请求参数
  renterChangeConfirmReq: RenterChangeConfirmReq = new RenterChangeConfirmReq();
  //新房间是否是一房两锁
  doubleDoorLock = false;
  is_company: boolean = false;

  @Output()
  time_with_choosed_room_list: EventEmitter<TimeWithChoosedRoomList> = new EventEmitter<TimeWithChoosedRoomList>();
  @Output()
  refresh: EventEmitter<any> = new EventEmitter<any>();

  myDatePickerOptions: IMyDpOptions;

  // 选择房间弹窗
  // choose_room_modal: any;

  // 楼栋列表
  unit_list: ProjectUnitDTO[] = [];
  // 房型列表
  roomType: Array<RoomTypeBaseDTO>;

  // 选择房间请求体
  choose_room_req: AvaliableRoomListReq = new AvaliableRoomListReq();

  // 楼栋结构列表
  unit_with_room: AvaliableUnit[] = [];

  // 所有可用房间
  room_list: AvaliableRoomInfo[] = [];

  // 楼栋、楼层选中
  unit_floor_checked: any = new Object();

  // 选中的房间id列表
  choosed_room_id_list: number[] = [];

  room_ids: Array<string>;

  canChange = true;

  constructor(private authService: AuthService,
              private roomService: RoomService,
              private workService: WorkService,
              private leaseService: LeaseService,
              private renterService: RenterService,
              private roomChangeService: RoomChangeService) {
  }

  ngOnInit() {
    // 获取楼栋列表
    this.roomService.getProjectUnits({project_id: this.authService.getProjectId()}).then(data => {
      this.unit_list = data.project_units;
      this.roomType = data.room_type_base_dtos;
    });
  }

  // 初始化弹窗
  // initSomething(modal: any) {
  //   this.choose_room_modal = modal;
  // }

  // 更新时间
  resetModal(start_time: Date, end_time: Date, choosed_room_id_list: number[], exclude_lease_id: number, exclude_lease_romm_id: number,
             data_type?: string, canChange = true
  ) {

    this.canChange = canChange;

    if (start_time) {
      this.choose_room_req.start_time_obj = generatePluginDate(start_time);
    }
    if (end_time) {
      this.choose_room_req.end_time_obj = generatePluginDate(end_time);
      this.end_time = end_time;
    }

    this.myDatePickerOptions = this.is_sign ? {
      dateFormat: "yyyy-mm-dd",
      editableDateField: false
    } : {
      dateFormat: "yyyy-mm-dd",
      editableDateField: false,
      disableDateRanges: [
        {
          begin: {year: 1978, month: 1, day: 1},
          end: {
            year: new Date(new Date().getTime() - 60000 * 60 * 24).getFullYear(),
            month: new Date(new Date().getTime() - 60000 * 60 * 24).getMonth() + 1,
            day: new Date(new Date().getTime() - 60000 * 60 * 24).getDate()
          }
        },
        {
          begin: {
            year: this.end_time.getFullYear(),
            month: this.end_time.getMonth() + 1,
            day: this.end_time.getDate()
          },
          end: {year: 2999, month: 1, day: 1}
        }
      ]
    };

    this.choosed_room_id_list = choosed_room_id_list;
    this.choose_room_req.unit_id = -1;
    this.choose_room_req.room_type_id = -1;
    this.choose_room_req.exclude_lease_id = exclude_lease_id;

    if (!this.is_sign) {
      this.choose_room_req.check_room_num = 1;
    }
    if (exclude_lease_romm_id) {
      this.choose_room_req.exclude_lease_room_id = exclude_lease_romm_id;
      this.is_changeRoom = true;
    }

    if (data_type) {
      this.choose_room_req.data_type = data_type;
    }

    this.is_changRenter = false;
    this.searchRoom();
  }

  /**
   * @Description: 无合同换房、合同内换房 走这个方法设置查询参数(除此之外的房态图弹框都走上面那个方法设置查询参数)
   * @Author: yangwen
   * @Date: 2020/3/3 14:53
   */
  resetParam(is_changRenter: boolean, change_lease_room_id: number, include_lease_id: number, renterIds: Array<number>, data_type: string = 'ROOM') {
    if (include_lease_id) {
      this.leaseService.getLeaseInfoDetail({lease_id: include_lease_id}).then(data => {
        this.is_company = data.lease_subject_info_dto.subject_type === "COMPANY";
      });
    }
    this.is_changRenter = is_changRenter;
    this.choose_room_req.unit_id = -1;
    this.choose_room_req.room_type_id = -1;
    this.choose_room_req.include_lease_id = include_lease_id;
    this.choose_room_req.exclude_lease_room_id = change_lease_room_id;
    this.choose_room_req.renter_num = renterIds.length;
    this.renterIds = renterIds;
    this.change_lease_room_id = change_lease_room_id;

    this.renterChangeConfirmReq.lease_id = include_lease_id;
    this.renterChangeConfirmReq.origin_lease_room_id = change_lease_room_id;
    this.renterChangeConfirmReq.renter_ids = renterIds;

    if (!this.is_sign) {
      this.choose_room_req.check_room_num = 1;
    }

    if (data_type) {
      this.choose_room_req.data_type = data_type;
    }
    this.canChange = false;

    this.is_changeRoom = true;

    this.searchAvaliableRooms();
  }

  searchAvaliableRooms() {
    //查询可用房间
    let req = deepCopy(this.choose_room_req);
    if (+req.unit_id === -1) {
      delete req.unit_id;
    }
    if (+req.room_type_id === -1) {
      delete req.room_type_id;
    }
    // 调用获取可用房间列表接口
    this.leaseService.getCrAvaliableRoomList(req).then(data => {
      // 清空楼层、楼栋、可用房间列表
      this.room_list = [];
      this.unit_with_room = [];
      this.unit_floor_checked = new Object();

      // 填充可用房间列表
      this.unit_with_room = data.unit_list;

      if (this.unit_with_room) {
        this.unit_with_room.forEach(unit => {
          this.unit_floor_checked['unit_' + unit.unit_id] = false;

          if (unit.floor_list && unit.floor_list.length > 0) {
            unit.floor_list.forEach(floor => {
              this.unit_floor_checked['unit_' + unit.unit_id + '_floor_' + floor.floor] = false;

              if (floor.room_info_list && floor.room_info_list.length > 0) {
                floor.room_info_list.forEach(room => {
                  if (this.choosed_room_id_list.indexOf(room.room_id) > -1) {
                    room.checked = true;
                  }

                  room.base_price = parseFloat(room.base_price + '');
                  this.room_list.push(room);
                });
              }
            });
          }
        });

        if (this.choosed_room_id_list.length > 0) {
          this.unit_with_room.forEach(unit => {
            if (unit.floor_list && unit.floor_list.length > 0) {
              unit.floor_list.forEach(floor => {
                if (floor.room_info_list && floor.room_info_list.length > 0) {
                  this.refreshFloorChecked(floor.room_info_list[0], true);
                }
              });
            }
          });
        }
      }
    });
  }

  // 快速设置结束时间
  quickSetEndTime(month_str: string) {
    let month = parseInt(month_str, 0);
    let start_time_obj = this.choose_room_req.start_time_obj;
    if (start_time_obj) {
      let new_end_time: Date = getNextNMonth(new Date(start_time_obj.formatted), month, true);
      this.choose_room_req.end_time_obj = generatePluginDate(new_end_time);
    } else {
      this.workService.showErrorModal('请选择入住时间');
    }
  }

  // 查询可用 房间列表
  searchRoom() {
    if (!this.choose_room_req.start_time_obj) {
      this.workService.showErrorModal('请填写入住时间');
      return;
    }
    if (!this.choose_room_req.end_time_obj) {
      this.workService.showErrorModal('请填写结束时间');
      return;
    }

    let req = deepCopy(this.choose_room_req);
    req.start_time = getTimeFromPlugin(req.start_time_obj.formatted);
    req.end_time = getTimeFromPlugin(req.end_time_obj.formatted);
    if (+req.unit_id === -1) {
      delete req.unit_id;
    }
    if (+req.room_type_id === -1) {
      delete req.room_type_id;
    }
    // 调用获取可用房间列表接口
    this.leaseService.getAvaliableRoomList(req).then(data => {
      // 清空楼层、楼栋、可用房间列表
      this.room_list = [];
      this.unit_with_room = [];
      this.unit_floor_checked = new Object();

      // 填充可用房间列表
      this.unit_with_room = data.unit_list;

      if (this.unit_with_room) {
        this.unit_with_room.forEach(unit => {
          this.unit_floor_checked['unit_' + unit.unit_id] = false;

          if (unit.floor_list && unit.floor_list.length > 0) {
            unit.floor_list.forEach(floor => {
              this.unit_floor_checked['unit_' + unit.unit_id + '_floor_' + floor.floor] = false;

              if (floor.room_info_list && floor.room_info_list.length > 0) {
                floor.room_info_list.forEach(room => {
                  if (this.choosed_room_id_list.indexOf(room.room_id) > -1) {
                    room.checked = true;
                  }

                  room.base_price = parseFloat(room.base_price + '');
                  this.room_list.push(room);
                });
              }
            });
          }
        });

        if (this.choosed_room_id_list.length > 0) {
          this.unit_with_room.forEach(unit => {
            if (unit.floor_list && unit.floor_list.length > 0) {
              unit.floor_list.forEach(floor => {
                if (floor.room_info_list && floor.room_info_list.length > 0) {
                  this.refreshFloorChecked(floor.room_info_list[0], true);
                }
              });
            }
          });
        }
      }
    });
  }

  // 选中、取消选中
  checkAll(type: string, is_checked: boolean, unit_id: number, floor_id?: string) {
    if (type === 'unit') {
      let floor_id_set: string[] = [];

      // 房间选中
      this.room_list.filter(room => room.unit_id === unit_id)
        .forEach(room => {
          room.checked = is_checked;
          floor_id_set.push(room.floor);
        });

      // 楼层选中
      floor_id_set.forEach(floor => {
        this.unit_floor_checked['unit_' + unit_id + '_floor_' + floor] = is_checked;
      });
    } else {
      // 房间选中
      this.room_list
        .filter(room => room.unit_id === unit_id && room.floor === floor_id)
        .forEach(room => room.checked = is_checked);

      // 楼层选中
      this.unit_floor_checked['unit_' + unit_id + '_floor_' + floor_id] = is_checked;

      // 校验单元是否需要选中
      this.refreshUnitChecked(unit_id, is_checked);
    }
  }

  // 取消选中
  cancelChoose() {
    this.room_list.forEach(room => {
      room.checked = false;
    });

    for (const key of Object.keys(this.unit_floor_checked)) {
      this.unit_floor_checked[key] = false;
    }
  }

  // 选中房间
  chooseRoom(room: AvaliableRoomInfo) {
    room.checked = !room.checked;
    if (this.is_sign) {
      this.refreshFloorChecked(room, room.checked);
    } else {
      if (room.checked) {
        this.selectChangeRoom = room;
        // 去除其他被选中
        this.room_list.forEach(rm => {
          if (room.room_id !== rm.room_id) {
            rm.checked = false;
          }
        });
      }
    }
  }

  // 重新计算楼层checkbox是否选中
  refreshFloorChecked(room, is_checked) {
    if (!is_checked) {
      this.unit_floor_checked['unit_' + room.unit_id] = false;
      this.unit_floor_checked['unit_' + room.unit_id + '_floor_' + room.floor] = false;
      return;
    }

    let floor_exist_not_checked = false;
    this.room_list.forEach(function (item) {
      if (item.unit_id === room.unit_id && item.floor === room.floor) {
        if (!floor_exist_not_checked && !item.checked) {
          floor_exist_not_checked = true;
        }
      }
    });

    this.unit_floor_checked['unit_' + room.unit_id + '_floor_' + room.floor] = !floor_exist_not_checked;

    this.refreshUnitChecked(room.unit_id, is_checked);
  }

  // 重新计算楼栋checkbox是否选中
  refreshUnitChecked(unit_id: number, is_checked: boolean) {
    if (!is_checked) {
      this.unit_floor_checked['unit_' + unit_id] = false;
      return;
    }

    let unit_exist_not_checked = false;
    for (const key of Object.keys(this.unit_floor_checked)) {
      if (key.indexOf('unit_' + unit_id + '_floor_') > -1) {
        if (!unit_exist_not_checked && !this.unit_floor_checked[key]) {
          unit_exist_not_checked = true;
        }
      }
    }
    this.unit_floor_checked['unit_' + unit_id] = !unit_exist_not_checked;
  }

  // 获取选中的房间列表
  getCheckedRoom(): AvaliableRoomInfo[] {
    return this.room_list.filter(function (room) {
      return room.checked;
    });
  }

  // 确认选中房间
  doConfirm() {
    this.time_with_choosed_room_list.emit(
      new TimeWithChoosedRoomList(
        getDateFromPlugin(this.choose_room_req.start_time_obj.formatted),
        getDateFromPlugin(this.choose_room_req.end_time_obj.formatted),
        this.getCheckedRoom(),
        null
      )
    );

    this.hide();
  }

  // 显示
  show() {
    setTimeout(() => {
      this.choose_room_modal.show();
    }, 300);
  }

  // 隐藏
  hide() {
    this.choose_room_modal.hide();
  }

  // 获取数字数组
  getArr(start_num: number, end_num: number): number[] {
    return generateArr(start_num, end_num);
  }

  //智能选房
  intelligenceCheckRoom() {
    if (!this.choose_room_req.unit_id || +this.choose_room_req.unit_id === -1) {
      this.workService.showErrorModal('请选择楼栋');
      return;
    }
    if (!this.choose_room_req.start_time_obj) {
      this.workService.showErrorModal('请填写入住时间');
      return;
    }
    if (!this.choose_room_req.end_time_obj) {
      this.workService.showErrorModal('请填写结束时间');
      return;
    }
    let reg = /^[1-9]\d*$/;
    if (!this.choose_room_req.check_room_num) {
      this.workService.showErrorModal('请输入房间数');
      return;
    }
    if (!reg.test(this.choose_room_req.check_room_num + "")) {
      this.workService.showErrorModal('房间数格式错误');
      return;
    }


    let req = deepCopy(this.choose_room_req);
    req.start_time = getTimeFromPlugin(req.start_time_obj.formatted);
    req.end_time = getTimeFromPlugin(req.end_time_obj.formatted);
    if (!req.check_room_num) {
      req.check_room_num = 0;
    }
    // 调用获取可用房间列表接口
    this.leaseService.getAvaliableRoomList(req).then(data => {
      // 清空楼层、楼栋、可用房间列表
      this.room_list = [];
      this.unit_with_room = [];
      this.unit_floor_checked = new Object();
      this.choosed_room_id_list = [];

      // 填充可用房间列表
      let sum = +req.check_room_num;
      this.unit_with_room = data.unit_list;
      this.room_ids = data.room_ids;
      let min = 10000;
      let label = null;
      let avaliableUnit = new AvaliableUnit();
      if (this.unit_with_room) {
        let a = 0;
        this.unit_with_room.forEach(unit => {
          this.unit_floor_checked['unit_' + unit.unit_id] = false;

          if (unit.floor_list && unit.floor_list.length > 0) {
            let b = 0;
            unit.floor_list.forEach(floor => {
              this.unit_floor_checked['unit_' + unit.unit_id + '_floor_' + floor.floor] = false;

              if (floor.room_info_list && floor.room_info_list.length > 0) {
                let c = 0;
                floor.room_info_list.forEach(room => {
                  if (+req.check_room_num === 1) {
                    if (room.date_diff !== 0 && min > room.date_diff) {
                      min = room.date_diff;
                      label = a + ";" + b + ";" + c;
                    }
                  } else {
                    if (sum > 0) {
                      if (this.room_ids && this.room_ids.length > 0) {
                        for (let i = 0; i < this.room_ids.length; i++) {
                          if (room.room_num === this.room_ids[i]) {
                            room.checked = true;
                            sum = sum - 1;
                          }
                        }
                      } else {
                        room.checked = true;
                        sum = sum - 1;
                      }
                    }
                  }
                  room.base_price = parseFloat(room.base_price + '');
                  this.room_list.push(room);
                  c++;
                });
              }
              b++;
            });
          }
          a++;
          avaliableUnit = unit;
        });
        if (sum === 1) {
          if (+min !== 10000 && label) {
            let lab = label.split(";");
            this.unit_with_room[lab[0]].floor_list[lab[1]].room_info_list[lab[2]].checked = true;
          } else {
            this.unit_with_room[0].floor_list[0].room_info_list[0].checked = true;
          }
        }
        this.isAllcheck(avaliableUnit);
      }
    });
  }


  isAllcheck(unit: AvaliableUnit) {
    let unitNum = 0;
    unit.floor_list.forEach(floor => {
      let flootNum = 0;
      if (floor.room_info_list && floor.room_info_list.length > 0) {
        floor.room_info_list.forEach(room => {
          if (room.checked) {
            flootNum = flootNum + 1;
          }
          if (flootNum === floor.room_info_list.length) {
            this.unit_floor_checked['unit_' + unit.unit_id + '_floor_' + floor.floor] = true;
            unitNum = unitNum + 1;
          }
        });
      }
      if (unitNum === unit.floor_list.length) {
        this.unit_floor_checked['unit_' + unit.unit_id] = true;
      }

    });
  }

  /**
   * @Description: 打开确认换房弹框
   * @Author: yangwen
   * @Date: 2020/3/3 16:50
   */
  openConfirmRenterInfo() {
    //查询换房确认信息
    this.renterService.queryCrConfirmInfo(
      {
        renter_ids: this.renterIds,
        old_lease_room_id: this.change_lease_room_id,
        new_lease_room_id: this.selectChangeRoom.lease_room_id,
      }
    ).then(data => {
      if (data && data.lease_renter_dtolist) {
        this.renterList = data.lease_renter_dtolist;
        this.oldRoomInfo = data.room_info_dto;
        this.hide();
        this.modal.show();
      } else {
        this.hide();
        //不能进行换房操作
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, "新房间未签署入住物品清单，请先签署入住物品清单后操作换房！"));
      }
    });
  }

  /**
   * @Description: 换房处理
   * @Author: yangwen
   * @Date: 2020/3/3 16:50
   */
  confirmChange() {
    this.modal.hide();
    if (this.selectChangeRoom.lease_id) {
      this.renterChangeConfirmReq.lease_id = this.selectChangeRoom.lease_id;
    }
    this.renterChangeConfirmReq.new_room_id = this.selectChangeRoom.room_id;
    this.roomChangeService.renterChangeConfirm(this.renterChangeConfirmReq).then(data => {
      if (data && data.double_door_lock) {
        this.doubleDoorLock = true;
      }
      this.staticModal.show();
    });
  }

  /**
   * @Description:   阻止向上冒泡
   * @Author: zhoujiahao
   * @Date: 2018-11-02  9:55
   */
  stopP($event) {
    $event.stopPropagation();
  }

  hideSuccess() {
    this.staticModal.hide();
    this.refresh.emit();
  }

  getUnitName(unit_name: string): string {
    return getUnitName(unit_name, LocalStorage.getItem(this.authService.getUserInfo().id + "_room_type"));
  }

  getFloor(floor: string): string {
    return getFloor(floor, LocalStorage.getItem(this.authService.getUserInfo().id + "_room_type"));
  }

  chooseRoomBed(dataType: string) {
    this.choose_room_req.data_type = dataType;
    this.searchRoom();
  }
}
