import { UntypedFormControl } from "@angular/forms";

/**
 * @Description: 通用验证器
 * @Author: 陆炳彬
 * @Date: 2018/4/4 上午10:46
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/4/4 上午10:46
 */

/**
 * 手机号、固话校验（6-11位以内纯数字）
 * @param {FormControl} control
 * @returns {any}
 */
export function phoneOrTelValidator(control: UntypedFormControl): any {
  let value = control.value;
  if (value) {
    if (!/^\d{6,11}$/.test(control.value)) {
      return { formatError: true };
    }
  }
  return null;
}

/**
 * 金额校验，小数点后一位的正数
 * @param {FormControl} control
 * @returns {any}
 */
export function priceValidator(control: UntypedFormControl): any {
  let value = control.value;
  if (value) {
    if (!/^\d+(\.\d{0,1})?$/.test(value)) {
      return { formatError: true };
    }
  }
  return null;
}

/**
 * 几万的验证，验证到分
 * @param {FormControl} control
 * @returns {any}
 */
export function priceMyriadValidator(control: UntypedFormControl): any {
  let value = control.value;
  if (value) {
    if (!/^\d+(\.\d{1,6})?$/.test(value)) {
      return { formatError: true };
    }
  }
  return null;
}

export function priceDoubleValidator(control: UntypedFormControl): any {
  let value = control.value;
  if (value) {
    if (!/^\d+(\.\d{1,2})?$/.test(value)) {
      return Promise.all([{ formatError: true }]);
    }
  }
  return Promise.resolve();
}
export function priceDoubleValidator2(control: UntypedFormControl): any {
  let value = control.value;
  if (value || value != null) {
    if (!/^\d+(\.\d{1,2})?$/.test(value)) {
      return {formatError: true,required:false};
    }else{
      return null;
    }
  }
  return {required: true};
}



/**
 * 邮箱验证
 * @param {FormControl} control
 * @returns {any}
 */
export function emailValidator(control: UntypedFormControl): any {
  let value = control.value;
  if (value) {
    if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)) {
      return { formatError: true };
    }
  }
  return null;
}


export function timeMinValidator(control: UntypedFormControl): any {
  let value = control.value;
  if (value) {
    let min = new Date(value).getMinutes();
    if (min!==0&&min!==30) {
      return { formatError: true };
    }
  }
  return null;
}

/**
 * 登录密码验证，规则：密码长度8-18位，须含数字、大写字母、小写字母、符号中的3种及以上
 * @param {String} passWord
 * @returns boolean
 */
export function loginPassWordValidator(passWord: string): boolean {
  // tslint:disable-next-line:max-line-length
  if (!new RegExp(/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,18}$/).test(passWord)) {
      return false;
  }
  return true;
}
