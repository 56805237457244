import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
  CheckoutNoOtherFeeRequest,
  CheckoutRoomViewDTO,
  CheckoutRoomViewRequest,
  CheckoutRoomViewResponse,
  SMSAuditCodeRequest
} from '../../../../model/signed/checkout.model';
import {SignedService} from '../../../../service/signed/signed.service';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkModel} from '../../../../model/common/common.model';
import {WorkService} from '../../../../service/common/work.service';
import {SysUser} from '../../../../model/user/user.model';
import {AuthService} from "../../../../service/common/auth.service";
import {
  RoomEquipmentIndicationDTO,
  RoomEquipmentIndicationRequest,
  RoomEquipmentOrIndicationRequest
} from "../../../../model/room/room.model";
import {RoomService} from "../../../../service/room/room.service";
import {UserService} from "../../../../service/user/user.service";
import { MessageModalInfo } from '../../../../model/modal/message-model';
import {LeaseExpenseInfoDTO} from "../../../../model/lease/lease.model";

@Component({
  selector: 'app-cheout-affirm',
  templateUrl: './cheout-affirm.component.html',
  styleUrls: ['./cheout-affirm.component.css']
})
export class CheoutAffirmComponent implements OnInit {

  checkoutRoomViewRequest: CheckoutRoomViewRequest = new CheckoutRoomViewRequest();
  checkoutRoomViewResponse: CheckoutRoomViewResponse;

  @Output() nextConfirm = new EventEmitter<CheckoutRoomViewResponse>();

  needAudit: boolean = false;
  noMoreFee: boolean;
  auditList: Array<SysUser>;
  inter;
  time = 60;
  codeBtnStr = '点击获取';
  gray_btn: boolean = false;


  SMSAuditCodeRequest = new SMSAuditCodeRequest();
  cnof: CheckoutNoOtherFeeRequest = new CheckoutNoOtherFeeRequest();

  overdueType: string = "";
  overdueContent: string;

  unconfirmed_count: number = 0;
  isSign: boolean = false;
  confirm: boolean = false;
  need_room_goods_list: number;
  rei_dto: RoomEquipmentIndicationDTO = new RoomEquipmentIndicationDTO();
  rei_room_name: string;
  rei_room_id: number;
  //水电读数
  cold_number: string;
  hot_number: string;
  ele_number: string;

  //退房原因
  checkoutReson:string;
  checkoutResonExt:string;
  type:string = 'NORMAL';//INTERNAL_LEASE

  constructor(
    private signedService: SignedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private workService: WorkService,
    private authService: AuthService,
    private roomService: RoomService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    //this.need_room_goods_list = this.authService.getProjectInfo().need_room_goods_list;
    this.userService.userProjectInfo(this.authService.getProjectId()).then(x => {
      this.need_room_goods_list = x.project.need_room_goods_list;
      if(this.need_room_goods_list === 1){
        this.isSign = true;
      }else{
        this.isSign = false;
      }
      this.activatedRoute.queryParams.subscribe(param => {
        this.checkoutRoomViewRequest.lease_id = param['lease_id'];
        this.checkoutRoomViewRequest.room_ids = param['ids'];
        this.signedService.checkoutRoomChooseConfirm(this.checkoutRoomViewRequest).then(data => {
          this.checkoutRoomViewResponse = data;
          this.type = this.checkoutRoomViewResponse.lease_info.lease_step;
          if(this.checkoutRoomViewResponse.checkout_room_list && this.need_room_goods_list === 0){
            this.checkoutRoomViewResponse.checkout_room_list.forEach(e =>{
              if(e.need_room_goods_list === 1){
                this.isSign = true;
              }
            });
          }
          //房间是否含有预付费项
          if(!this.isSign){
            let req = new RoomEquipmentIndicationRequest();
            req.room_ids = this.checkoutRoomViewRequest.room_ids;
            req.type = "checkout";
            this.roomService.getRoomEquipmentOrIndication(req).then(res => {

              if(res && res.rooms){
                res.rooms.forEach(r =>{
                  if(r.confirm){
                    this.confirm = true;
                  }
                  this.checkoutRoomViewResponse.checkout_room_list.forEach(e =>{
                    if(e.room_id === r.room_id){
                      e.rei_dto = r;
                    }
                  });
                });
              }
            });
          }
          this.canNoMoreFeeAudit();
        });
      });
    });
  }

  // 获取其他费用详情
  getCheckoutExpenseInfo(expense_info_list: LeaseExpenseInfoDTO[]): string {
    let result = '';

    if (!expense_info_list || expense_info_list.length === 0) {
      result = '无';
    } else {
      expense_info_list.forEach((expense_info, index) => {
        result += (expense_info.expense_name ? expense_info.expense_name : '') + ' ' + expense_info.expense_value + '元';
        if (expense_info.gather_type === 'EVERY_YEAR_CHARGE') {
          result += '/年';
        } else if (expense_info.gather_type === 'EVERY_MONTH_CHARGE') {
          result += '/月';
        }
        if (index !== expense_info_list.length - 1) {
          result += ', ';
        }
      });
    }
    return result;
  }

  changeDs(room_id: number,room_name: string,checkInEqumentModal: any){
    this.checkoutRoomViewResponse.checkout_room_list.forEach(e =>{
      if(e.room_id ===room_id){
        this.rei_dto = e.rei_dto;
      }
    });
    if(this.rei_dto.elemeter_info_dto){
      this.ele_number = this.rei_dto.elemeter_info_dto.indication === -100 ? null :  this.rei_dto.elemeter_info_dto.indication+'';
    }
    if(this.rei_dto.cold_watermeter_info_dto){
      this.cold_number = this.rei_dto.cold_watermeter_info_dto.indication === -100 ? null :  this.rei_dto.cold_watermeter_info_dto.indication+''
    }
    if(this.rei_dto.hot_watermeter_info_dto){
      this.hot_number =  this.rei_dto.hot_watermeter_info_dto.indication === -100 ? null :  this.rei_dto.hot_watermeter_info_dto.indication+''
    }
    this.rei_room_name = room_name;
    this.rei_room_id = room_id;
    checkInEqumentModal.show();
  }

  checkRoomIndication(checkInEqumentModal:any){
    var reg=/^\d+(\.\d{0,2})?$/;
    if(this.rei_dto.elemeter_info_dto){
      if(!reg.test(this.ele_number)){
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "电表读数格式有误"));
        return;
      }
      if(this.checkNumber(this.ele_number)){
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "电表读数格式有误"));
        return;
      }
    }
    if(this.rei_dto.cold_watermeter_info_dto){
      if(!reg.test(this.cold_number)){
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "水表读数格式有误"));
        return;
      }
      if(this.checkNumber(this.cold_number)){
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "水表读数格式有误"));
        return;
      }
    }
    if(this.rei_dto.hot_watermeter_info_dto){
      if(!reg.test(this.hot_number)){
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "热水表读数格式有误"));
        return;
      }
      if(this.checkNumber(this.hot_number)){
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "热水表读数格式有误"));
        return;
      }
    }

    this.checkoutRoomViewResponse.checkout_room_list.forEach(e =>{
      if(e.room_id === this.rei_room_id){
        if(this.rei_dto.elemeter_info_dto){
          e.rei_dto.elemeter_info_dto.indication = +this.ele_number;
        }
        if(this.rei_dto.cold_watermeter_info_dto){
          e.rei_dto.cold_watermeter_info_dto.indication = +this.cold_number;
        }
        if(this.rei_dto.hot_watermeter_info_dto){
          e.rei_dto.hot_watermeter_info_dto.indication = +this.hot_number;
        }
      }
    });

    checkInEqumentModal.hide();
  }

  checkNumber(str: string){
    let result = (str).indexOf(".");
    let rst = false;
    if(result != -1) {
      let s = str.toString().split('.');
      if(!s[1]){
        rst = true;
      }
    }
    return rst;
  }

  canNoMoreFeeAudit() {
    // 需要在全部退房单签署之后 且 非正常退房时才显示
    if (this.checkoutRoomViewResponse && this.checkoutRoomViewResponse.checkout_room_list
      && this.checkoutRoomViewResponse.checkout_room_list
        .filter(e => e.no_more_fee_audit && (e.violate === 'OVERDUE' || e.violate === 'BREAK')).length > 0
    ) {
      this.needAudit = true;
    } else {
      this.needAudit = false;
      this.noMoreFee = false;
    }
  }

  checkoutDs(){
    let rooms = [];
    this.checkoutRoomViewResponse.checkout_room_list.forEach(e =>{
      if(e.asset_confirm !== "CONFIRMEDOUT"){
        let dto = new RoomEquipmentIndicationDTO();
        dto.elemeter_info_dto = e.rei_dto && e.rei_dto.elemeter_info_dto ? e.rei_dto.elemeter_info_dto: null;
        dto.cold_watermeter_info_dto = e.rei_dto && e.rei_dto.cold_watermeter_info_dto ? e.rei_dto.cold_watermeter_info_dto: null;
        dto.hot_watermeter_info_dto = e.rei_dto && e.rei_dto.hot_watermeter_info_dto ? e.rei_dto.hot_watermeter_info_dto: null;
        dto.room_id = e.room_id;
        rooms.push(dto);
      }
    })
    if(rooms.length > 0){
      let req = new RoomEquipmentOrIndicationRequest();
      req.rooms = rooms;
      req.type = "checkout";
      this.roomService.setRoomEquipmentOrIndication(req).then(res => {
        if(res && res.result === 'SUCCESS'){
          this.goNext()
        }else{
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "房间设备读数确认失败！"));
        }
      });
    }else{
      this.goNext();
    }
  }

  tgoNext(){
    if (this.checkoutRoomViewResponse && this.checkoutRoomViewResponse.checkout_room_list
      && this.checkoutRoomViewResponse.checkout_room_list.length > 0) {
      return (!this.noMoreFee || this.noMoreFee && this.cnof.phone && this.cnof.code && this.overdueType && this.overdueContent)
        ;
    } else {
      return false;
    }
  }

  goNext() {
    this.signedService.checkoutRoomChooseConfirm(this.checkoutRoomViewRequest).then(data => {
      if (data && data.checkout_room_list
        && data.checkout_room_list.length > 0 && (data.checkout_room_list.filter(c => c.asset_confirm === 'CONFIRMEDOUT').length
        === data.checkout_room_list.length)) {

        //退房原因
        this.checkoutRoomViewResponse.checkoutReason = this.checkoutReson;
        if(this.checkoutResonExt){
          this.checkoutRoomViewResponse.checkoutReason = this.checkoutReson + "," + this.checkoutResonExt;
        }

        this.nextConfirm.emit(this.checkoutRoomViewResponse);
      }
    });
  }

  next() {
    this.router
      .navigate(["../checkoutlist"],
        {
          queryParams: {
            lease_id: this.checkoutRoomViewRequest.lease_id,
            ids: this.checkoutRoomViewRequest.room_ids,
            checkoutReson:this.checkoutReson
          },
          relativeTo: this.activatedRoute
        });

  }

  goSettingAsset(info: CheckoutRoomViewDTO) {
    this.router
      .navigate(["../inventorylist"],
        {
          queryParams: {
            lease_id: this.checkoutRoomViewRequest.lease_id,
            room_id: info.room_id,
            ids: this.checkoutRoomViewRequest.room_ids

          },
          relativeTo: this.activatedRoute
        });
  }

  checkout() {
    if(this.type !== 'INTERNAL_LEASE'){
      if((this.checkoutReson == null || this.checkoutReson.length == 0) && (this.checkoutResonExt == null || this.checkoutResonExt.length == 0)){
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请填写退房原因"));
        return;
      }
      this.confirmCheckout();
    } else {
      let messageModalInfo = new MessageModalInfo();
      messageModalInfo.cancel=true;
      messageModalInfo.operationMessage='确认后将停止房间计费并生成全部费用账单进行结算！是否继续？';
      messageModalInfo.callback.subscribe(data=>{
        this.confirmCheckout();
      })
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
    }
  }

  confirmCheckout() {
    let next = true;
    let next_1 = true;
    if(!this.isSign){
      this.checkoutRoomViewResponse.checkout_room_list.forEach(e =>{
        if(e.asset_confirm !== "CONFIRMEDOUT"){
          if(e.rei_dto && e.rei_dto.elemeter_info_dto){
            if(!e.rei_dto.elemeter_info_dto.indication || e.rei_dto.elemeter_info_dto.indication === -100){
              //this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "电表读数格式有误"));
              next = false;
            }
          }
          if(e.rei_dto && e.rei_dto.cold_watermeter_info_dto){
            if(!e.rei_dto.cold_watermeter_info_dto.indication || e.rei_dto.cold_watermeter_info_dto.indication === -100){
              //this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "水表读数格式有误"));
              next = false;
            }
          }
          if(e.rei_dto && e.rei_dto.hot_watermeter_info_dto){
            if(!e.rei_dto.hot_watermeter_info_dto.indication || e.rei_dto.hot_watermeter_info_dto.indication === -100){
              //this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "热水表读数格式有误"));
              next = false;
            }
          }
        }
      });
      if(next){
        if (this.noMoreFee) {
          this.cnof.lease_id = this.checkoutRoomViewRequest.lease_id;
          this.cnof.room_ids = this.checkoutRoomViewRequest.room_ids;
          this.cnof.overdueType = this.overdueType;
          this.cnof.overdueContent = this.overdueContent;
          this.signedService.leaseAuditCheckoutAuditSMSConfirm(this.cnof).then(data => {
            this.checkoutDs();
          });
        } else {
          this.checkoutDs();
        }
      }else{
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "房间设备读数异常,请修改读数！"));
      }
    }else{
      this.checkoutRoomViewResponse.checkout_room_list.forEach(e =>{
        if(e.asset_confirm === "UNCONFIRMED"){
          next_1 = false;

        }
        if(e.asset_confirm === "CONFIRMED"){
          next_1 = false;
        }
      });
      if(next_1){
        if (this.noMoreFee) {
          this.cnof.lease_id = this.checkoutRoomViewRequest.lease_id;
          this.cnof.room_ids = this.checkoutRoomViewRequest.room_ids;
          this.cnof.overdueType = this.overdueType;
          this.cnof.overdueContent = this.overdueContent;
          this.signedService.leaseAuditCheckoutAuditSMSConfirm(this.cnof).then(data => {
            this.checkoutDs();
          });
        } else {
          this.checkoutDs();
        }
      }else{
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请确认入住或退房物品交接单"));
      }
    }
  }
  backLease() {
    this.router.navigate(["../pact/", this.checkoutRoomViewRequest.lease_id], {
      relativeTo: this.activatedRoute
    });
  }

  noConfirm() {
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请设置入住物品交接单'));
  }

  getCode() {
    if (this.cnof.phone) {
      if (this.time === 60) {
        this.time = 0;
        this.SMSAuditCodeRequest.code_type = 17;
        this.SMSAuditCodeRequest.lease_id = this.checkoutRoomViewRequest.lease_id;
        this.SMSAuditCodeRequest.mobile = this.cnof.phone;

        this.signedService.leaseAuditCheckoutAuditSMS(this.SMSAuditCodeRequest).then(data => {

          this.time = 60;
          this.gray_btn = true;
          this.inter = setInterval(() => {

            if (--this.time > 0) {
              this.codeBtnStr = this.time + 's后重新获取';
            } else {
              this.gray_btn = false;
              this.time = 60;
              this.codeBtnStr = '点击获取';
              clearInterval(this.inter);
            }

          }, 1000);
        }).catch(() => {
          this.time = 60;
        });
      }
    }
  }

  getAuditList() {
    if (this.noMoreFee) {
      let type = 'OVERDUE';//逾期
      this.checkoutRoomViewResponse.checkout_room_list.forEach(e => {
        if (e.violate === 'BREAK') {//违约
          type = 'BREAK';
        }
      });
      if (type === 'OVERDUE') {
        this.signedService.leaseAuditCheckoutList().then(data => {
          this.auditList = data.audit_user;
        });
      } else if (type === 'BREAK') {
        this.signedService.leaseAuditBreakCheckoutList().then(data => {
          this.auditList = data.audit_user;
        });
      }
    }
  }
}
