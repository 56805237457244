<div class="setbox-top">
  <div class="bg-primary">退房确认</div>
  <div class="setbox">
    <p class="text-orange">请核实每个房间的退房物品清单及退房状态，确认无误后，继续操作退房</p>
    <div class="over15">
      <!-- tbody超出15个tr就加  overflow-y:auto; -->
      <ng-container *ngIf="isSign">
      <table class="table table-bordered">
        <thead class="thead-light">
        <tr>
          <th>房间号</th>
          <th class="show2">房型</th>
          <th>合同结束日期</th>
          <th>退房日期</th>
          <th class="show2">本期账单月租金</th>
          <th class="show2">其他费用</th>
          <th class="show2">押金</th>
          <th class="show2">入住人</th>
          <th>退房物品交接单</th>
          <th *ngIf="type !== 'INTERNAL_LEASE'">退房状态</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let info of checkoutRoomViewResponse?.checkout_room_list">
            <td>{{info?.room_name}}</td>
            <td class="show2">{{info?.room_type_name}}</td>
            <td>{{checkoutRoomViewResponse?.lease_info?.end_time | date:'yyyy-MM-dd'}}</td>
            <td><span
              [ngClass]="{'text-danger':info.violate!='NORMAL'}">{{info?.checkout_time | date:'yyyy-MM-dd'}}</span></td>
            <td class="show2">{{info?.base_price}}</td>
            <td class="show2 new-lines">
              {{getCheckoutExpenseInfo(info.lease_expense_info_dtolist)}}
            </td>
            <td class="show2">{{info.cash_pledge}}</td>
            <td class="show2 new-lines">
              <ng-container *ngFor="let renter of info?.renter_list">{{renter.name}}({{renter.sex=='MALE'?'男':'女'}}),
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="info.asset_confirm=='CONFIRMEDOUT'">
                <span class="text-secondary">已设置</span>
              </ng-container>
              <ng-container *ngIf="info.asset_confirm=='UNCONFIRMED' && need_room_goods_list === 1">
                <!-- 设置入住物品交接单 -->
                <button class="btn btn-link px-0"
                        (click)="noConfirm()">设置</button>
              </ng-container>

              <ng-container *ngIf="info.asset_confirm=='CONFIRMED' && need_room_goods_list === 1">
                <!-- 设置退房物品交接单 -->
                  <button class="btn btn-link px-0"
                          (click)="goSettingAsset(info)">设置
                  </button>
              </ng-container>
              <ng-container *ngIf="info.asset_confirm=='CONFIRMED' && need_room_goods_list === 0">
                <!-- 设置退房物品交接单 -->
                <button *ngIf="info.need_room_goods_list === 1" class="btn btn-link px-0"
                        (click)="goSettingAsset(info)">设置
                </button>
                <ng-container *ngIf="info.need_room_goods_list === 0">
                   无
                </ng-container>
              </ng-container>
              <ng-container *ngIf="info.asset_confirm=='UNCONFIRMED' && need_room_goods_list === 0">
                无
              </ng-container>
            </td>
            <td *ngIf="type !== 'INTERNAL_LEASE'">
              <span *ngIf="info?.violate=='NORMAL'">正常</span>
              <span *ngIf="info?.violate=='OVERDUE'" class="text-orange">逾期</span>

              <select *ngIf="info?.violate=='BREAK'||info?.violate=='BEFORE'||info?.violate=='SUBLEASE'"
                      class="text-orange w-100"
                      [(ngModel)]="info.violate" (change)="canNoMoreFeeAudit()">
                <option value="BREAK" >违约</option>
                <option value="BEFORE">提前</option>
                <option value="SUBLEASE">转租</option>
              </select>
            </td>
          </tr>
        <tr *ngIf="!checkoutRoomViewResponse?.checkout_room_list">
          <td colspan="10" class="show2" *ngIf="type !== 'INTERNAL_LEASE'">暂无数据</td>
          <td colspan="9" class="show2" *ngIf="type === 'INTERNAL_LEASE'">暂无数据</td>
          <td colspan="5" class="show1">暂无数据</td>
        </tr>
        </tbody>
      </table>
      </ng-container>

      <ng-container *ngIf="!isSign">
        <ng-container *ngIf="!confirm">
          <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
              <th>房间号</th>
              <th class="show2">房型</th>
              <th>合同结束日期</th>
              <th>退房日期</th>
              <th class="show2">本期账单月租金</th>
              <th class="show2">其他费用</th>
              <th class="show2">押金</th>
              <th class="show2">入住人</th>
              <th *ngIf="type !== 'INTERNAL_LEASE'">退房状态</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let info of checkoutRoomViewResponse?.checkout_room_list">
              <td>{{info?.room_name}}</td>
              <td class="show2">{{info?.room_type_name}}</td>
              <td>{{checkoutRoomViewResponse?.lease_info?.end_time | date:'yyyy-MM-dd'}}</td>
              <td><span
                [ngClass]="{'text-danger':info.violate!='NORMAL'}">{{info?.checkout_time | date:'yyyy-MM-dd'}}</span></td>
              <td class="show2">{{info?.base_price}}</td>
              <td class="show2 new-lines">
                <ng-container *ngIf="info?.lease_expense_info_dtolist">
                  <ng-container *ngFor="let expense of info?.lease_expense_info_dtolist">
                    {{expense.expense_name}}{{expense.expense_value}};
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!info?.lease_expense_info_dtolist">
                  无
                </ng-container>
              </td>
              <td class="show2">{{info.cash_pledge}}</td>
              <td class="show2 new-lines">
                <ng-container *ngFor="let renter of info?.renter_list">{{renter.name}}({{renter.sex=='MALE'?'男':'女'}}),
                </ng-container>
              </td>
              <td *ngIf="type !== 'INTERNAL_LEASE'">
                <span *ngIf="info?.violate=='NORMAL'">正常</span>
                <span *ngIf="info?.violate=='OVERDUE'" class="text-orange">逾期</span>

                <select *ngIf="info?.violate=='BREAK'||info?.violate=='BEFORE'||info?.violate=='SUBLEASE'"
                        class="text-orange w-100"
                        [(ngModel)]="info.violate" (change)="canNoMoreFeeAudit()">
                  <option value="BREAK">违约</option>
                  <option value="BEFORE">提前</option>
                  <option value="SUBLEASE">转租</option>
                </select>
              </td>
            </tr>
            <tr *ngIf="!checkoutRoomViewResponse?.checkout_room_list">
              <td colspan="10" class="show2" *ngIf="type !== 'INTERNAL_LEASE'">暂无数据</td>
              <td colspan="9" class="show2" *ngIf="type === 'INTERNAL_LEASE'">暂无数据</td>
              <td colspan="5" class="show1">暂无数据</td>
            </tr>
            </tbody>
          </table>
        </ng-container>
        <ng-container *ngIf="confirm">
          <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
              <th>房间号</th>
              <th class="show2">房型</th>
              <th>合同结束日期</th>
              <th>退房日期</th>
              <th class="show2">本期账单月租金</th>
              <th class="show2">其他费用</th>
              <th class="show2">押金</th>
              <th class="show2">入住人</th>
              <th>设备读数</th>
              <th *ngIf="type !== 'INTERNAL_LEASE'">退房状态</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let info of checkoutRoomViewResponse?.checkout_room_list">
              <td>{{info?.room_name}}</td>
              <td class="show2">{{info?.room_type_name}}</td>
              <td>{{checkoutRoomViewResponse?.lease_info?.end_time | date:'yyyy-MM-dd'}}</td>
              <td><span
                [ngClass]="{'text-danger':info.violate!='NORMAL'}">{{info?.checkout_time | date:'yyyy-MM-dd'}}</span></td>
              <td class="show2">{{info?.base_price}}</td>
              <td class="show2 new-lines">
                <ng-container *ngIf="info?.lease_expense_info_dtolist">
                  <ng-container *ngFor="let expense of info?.lease_expense_info_dtolist">
                    {{expense.expense_name}}{{expense.expense_value}};
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!info?.lease_expense_info_dtolist">
                  无
                </ng-container>
              </td>
              <td class="show2">{{info.cash_pledge}}</td>
              <td class="show2 new-lines">
                <ng-container *ngFor="let renter of info?.renter_list;let i=index">
                  {{renter.name}}({{renter.sex=='MALE'?'男':'女'}})
                  <ng-container *ngIf="i !== info?.renter_list.length-1">
                    ,
                  </ng-container>
                </ng-container>
              </td>
              <td class="text-left" style="position: relative;">
                <ng-container *ngIf="info.asset_confirm === 'UNCONFIRMED'">
                  未入住，无需确认
                </ng-container>
                <ng-container *ngIf="info.asset_confirm === 'CONFIRMED' && info.rei_dto">
                  <span class="info cursor" (click)="changeDs(info.room_id,info.room_name,checkInEqumentModal)"
                        style="position: absolute;right: 10px;color: #169BD5;" *ngIf="info.rei_dto.elemeter_info_dto
                        || info.rei_dto.cold_watermeter_info_dto || info.rei_dto.hot_watermeter_info_dto">修改</span>
                  <p *ngIf="info.rei_dto.elemeter_info_dto" [ngClass]="{'w-75': info.rei_dto.elemeter_info_dto}">电表：
                    <span *ngIf="info.rei_dto.elemeter_info_dto.indication !== -100">{{info.rei_dto.elemeter_info_dto.indication}}</span>
                    <span class="text-danger" *ngIf="info.rei_dto.elemeter_info_dto.indication === -100">异常</span>
                  </p>
                  <p *ngIf="info.rei_dto.cold_watermeter_info_dto" [ngClass]="{'w-75': !info.rei_dto.elemeter_info_dto}">水表：
                    <span *ngIf="info.rei_dto.cold_watermeter_info_dto.indication !== -100">{{info.rei_dto.cold_watermeter_info_dto.indication}}</span>
                    <span class="text-danger" *ngIf="info.rei_dto.cold_watermeter_info_dto.indication === -100">异常</span>
                  </p>
                  <p *ngIf="info.rei_dto.hot_watermeter_info_dto" [ngClass]="{'w-75': (!info.rei_dto.elemeter_info_dto && !info.rei_dto.cold_watermeter_info_dto)}">热水表：
                    <span *ngIf="info.rei_dto.hot_watermeter_info_dto.indication !== 100">{{info.rei_dto.hot_watermeter_info_dto.indication}}</span>
                    <span class="text-danger" *ngIf="info.rei_dto.hot_watermeter_info_dto.indication === -100">异常</span>
                  </p>
                </ng-container>
                <ng-container *ngIf="info.asset_confirm === 'CONFIRMEDOUT'">
                  已设置
                </ng-container>
              </td>
              <td *ngIf="type !== 'INTERNAL_LEASE'">
                <span *ngIf="info?.violate=='NORMAL'">正常</span>
                <span *ngIf="info?.violate=='OVERDUE'" class="text-orange">逾期</span>

                <select *ngIf="info?.violate=='BREAK'||info?.violate=='BEFORE'||info?.violate=='SUBLEASE'"
                        class="text-orange w-100"
                        [(ngModel)]="info.violate" (change)="canNoMoreFeeAudit()">
                  <option value="BREAK" >违约</option>
                  <option value="BEFORE">提前</option>
                  <option value="SUBLEASE">转租</option>
                </select>
              </td>
            </tr>
            <tr *ngIf="!checkoutRoomViewResponse?.checkout_room_list">
              <td colspan="10" class="show2" *ngIf="type !== 'INTERNAL_LEASE'">暂无数据</td>
              <td colspan="9" class="show2" *ngIf="type === 'INTERNAL_LEASE'">暂无数据</td>
              <td colspan="5" class="show1">暂无数据</td>
            </tr>
            </tbody>
          </table>
        </ng-container>
      </ng-container>

      <div class="col-12" *ngIf="needAudit && type !== 'INTERNAL_LEASE'">
        <div class="form-check w140px">
          <input type="checkbox" class="form-check-input hei2532" id="exampleCheck1" [(ngModel)]="noMoreFee"
                 (change)="getAuditList()">
          <label class="form-check-label hei32" for="exampleCheck1">不收滞纳金/违约金</label>
        </div>
        <div class="w200px ml-1" *ngIf="noMoreFee">
          <select class="w-100" [(ngModel)]="cnof.phone">
            <option value="">请选择</option>
            <option *ngFor="let man of auditList" [value]="man.user_phone">{{man.user_name}}({{man.user_phone}})
            </option>
          </select>
          <div *ngIf="noMoreFee&&!cnof.phone" class="text-danger">审核用户不能为空</div>
        </div>
        <div class="w200px ml-1" *ngIf="noMoreFee">
          <input class="w-100" type="text" placeholder="请输入短信验证码" maxlength="4" [(ngModel)]="cnof.code">
          <div *ngIf="noMoreFee&&!cnof.code" class="text-danger">验证码不能为空</div>
        </div>
        <ng-container *ngIf="noMoreFee">
          <button class="btn btn-primary ml-1" [disabled]="gray_btn" (click)="getCode()">{{codeBtnStr}}</button>
        </ng-container>

      </div>

      <div style="clear:both;margin-top: 10px;" class="col-12" *ngIf="needAudit && type !== 'INTERNAL_LEASE'">
        <div class="form-check w140px">
          <label class="form-check-label hei32" for="exampleCheck1"></label>
        </div>
        <div class="w200px ml-1" *ngIf="noMoreFee">
          <select class="w-100" [(ngModel)]="overdueType">
            <option value="" disabled>请选择</option>
            <option value="客户协商">客户协商</option>
            <option value="无法收取">无法收取</option>
            <option value="公寓原因">公寓原因</option>
          </select>
          <div *ngIf="!overdueType" class="text-danger">请选择原因</div>
        </div>
        <div class="w300px ml-1" *ngIf="noMoreFee">
          <input class="w-100" [(ngModel)]="overdueContent" type="text" placeholder="请描述具体原因，限30字" maxlength="30" >
          <div *ngIf="!overdueContent" class="text-danger">具体原因不能为空</div>
        </div>
      </div>

      <div style="clear:both;margin-top: 10px;" class="col-12" *ngIf="type !== 'INTERNAL_LEASE'">
          <div class="w200px ml-1">
            <span>退房原因:</span>
            <select class="w-100" [(ngModel)]="checkoutReson">
              <option value="合同到期正常退房">合同到期正常退房</option>
              <option value="企业用工减少退房">企业用工减少退房</option>
              <option value="空房闲置退房">空房闲置退房</option>
              <option value="企业迁移退房">企业迁移退房</option>
              <option value="其他">其他</option>
            </select>
            <div *ngIf="!checkoutReson" class="text-danger">请选择原因</div>
          </div>
          <div class="w300px ml-1">
              <span>&nbsp;</span>
            <input class="w-100" [(ngModel)]="checkoutResonExt" type="text" placeholder="其他备注信息" maxlength="100" >
          </div>
        </div>

    </div>
    <hr>
    <div class="text-center">
      <button *ngIf="tgoNext()" type="button" class="btn btn-primary mr-3" (click)="checkout()">下一步</button>
      <button *ngIf="!tgoNext()" type="button" class="btn btn-secondary mr-3" disabled>下一步</button>
      <button type="button" class="btn btn-secondary" (click)="backLease()">返回</button>
    </div>
  </div>
</div>

<div style="z-index: 999999;background: rgba(0,0,0,0.4);" class="modal fade" bsModal #checkInEqumentModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>确认房间设备读数</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="checkInEqumentModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="part1">
          <div class="row mlmr">
            <div class="col-12 col-md-12 bg-green mb-4" style="line-height: 65px;margin-top: -30px;">
<!--             设备所属房间号：<span class="roomname">{{rei_room_name}}</span>-->
              <div class="pt-2" style="line-height: normal">设备所属房间号：</div>
              <div class="roomname text-center">{{rei_room_name}}</div>
            </div>
            <p class="mt10 ml-4">房间首次入住,请确认该房间收费项目的设备最后度数：</p>
            <div class="col-12 col-md-12 mt10 pt10" style="padding-left: 20%">
              <ng-container *ngIf="rei_dto.elemeter_info_dto">
                <p *ngIf="rei_dto.elemeter_info_dto.indication !== -100" class="mb10">电&nbsp;&nbsp;&nbsp;&nbsp;表：
                  <input class="w-50" [(ngModel)]="ele_number" type="text" value="{{ele_number}}"></p>
                <p *ngIf="rei_dto.elemeter_info_dto.indication === -100" class="mb10">电&nbsp;&nbsp;&nbsp;&nbsp;表：
                  <input class="w-50" [(ngModel)]="ele_number" type="text" value="" placeholder="设备异常,未获取到读数"></p>
              </ng-container>

              <ng-container *ngIf="rei_dto.cold_watermeter_info_dto">
                <p *ngIf="rei_dto.cold_watermeter_info_dto.indication !== -100" class="mb10">水&nbsp;&nbsp;&nbsp;&nbsp;表：<input class="w-50" [(ngModel)]="cold_number" type="text" value="{{cold_number}}"></p>
                <p *ngIf="rei_dto.cold_watermeter_info_dto.indication === -100" class="mb10">水&nbsp;&nbsp;&nbsp;&nbsp;表：
                  <input class="w-50" [(ngModel)]="cold_number" type="text" value="" placeholder="设备异常,未获取到读数"></p>
               </ng-container>

              <ng-container *ngIf="rei_dto.hot_watermeter_info_dto">
                <p *ngIf="rei_dto.hot_watermeter_info_dto.indication !== -100" class="mb10">热水表：<input class="w-50" [(ngModel)]="hot_number" type="text" value="{{hot_number}}"></p>
                <p *ngIf="rei_dto.hot_watermeter_info_dto.indication === -100" class="mb10">热水表：
                  <input class="w-50" [(ngModel)]="hot_number" type="text" value="" placeholder="设备异常,未获取到读数"></p>
              </ng-container>

            </div>
            <!--<p class="mt10 ml-4" style="font-size: 12px;color: #FF6600;">提醒：确认完成后，客户可在APP端房间信息内查看设备初始度数，管家可在“签约管理-合同详情-房间模块”查看</p>-->
          </div>
        </div>
      </div>
      <div class="modal-footer">

       <button
                type="button" class="btn btn-info" (click)="checkRoomIndication(checkInEqumentModal)">确&nbsp;&nbsp;定</button>&nbsp;&nbsp;
        <button type="button" class="btn btn-secondary" (click)="checkInEqumentModal.hide()">取&nbsp;&nbsp;消</button>
      </div>
    </div>
  </div>
</div>




