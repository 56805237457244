import {
  BuildFloorModel,
  BuildingFloorMap,
  BuildingMap,
  BuildModel,
  BuildProject,
  BuildRoomModel
} from "../fire-building-index/building-data";

// 引入three.js
import * as THREE from 'three';
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry';
// bsp
import ThreeBSPModule from '../../../common/threeBSP';

const ThreeBSP = ThreeBSPModule(THREE);

export const wall = 10;
export const doorX = 50;
export const doorY = 120;
export const doorZ = wall;

export function animate() {

  let animateList = window['THREE_FIRE']['animate'] || [];


  let warningColorR = 245 / 255;
  let warningColorG = 16 / 255;
  let warningColorB = 16 / 255;

  let degree = 0.05;

  for (let a of animateList) {
    for (let mesh of a.meshs) {
      mesh.dto = mesh.dto || {};
      mesh.dto.warning = true;

      let originColor = mesh.dto.originColor || mesh.material.color;
      mesh.dto.originColor = originColor;

      if (Math.abs(mesh.material.color.r - warningColorR) < degree) {
        if (mesh.material.color.r < originColor.r) {
          mesh.material.color.r += degree;
        } else {
          mesh.material.color.r -= degree;
        }
      } else {
        if (Math.abs(mesh.material.color.r - originColor.r) < degree) {
          if (originColor.r < warningColorR) {
            mesh.material.color.r += degree;
          } else {
            mesh.material.color.r -= degree;
          }
        }
      }

      if (Math.abs(mesh.material.color.g - warningColorG) < degree) {
        if (mesh.material.color.g < originColor.g) {
          mesh.material.color.g += degree;
        } else {
          mesh.material.color.g -= degree;
        }
      } else {
        if (Math.abs(mesh.material.color.g - originColor.g) < degree) {
          if (originColor.g < warningColorG) {
            mesh.material.color.g += degree;
          } else {
            mesh.material.color.g -= degree;
          }
        }
      }

      if (Math.abs(mesh.material.color.b - warningColorB) < degree) {
        if (mesh.material.color.b < originColor.b) {
          mesh.material.color.b += degree;
        } else {
          mesh.material.color.b -= degree;
        }
      } else {
        if (Math.abs(mesh.material.color.b - originColor.b) < degree) {
          if (originColor.b < warningColorB) {
            mesh.material.color.b += degree;
          } else {
            mesh.material.color.b -= degree;
          }
        }
      }
    }
  }

  if (window['THREE_FIRES']) {
    for (let index in window['THREE_FIRES']) {
      if (window['THREE_FIRES'][index]['controls']) {
        window['THREE_FIRES'][index]['controls'].update();
      }
      window['THREE_FIRES'][index]['renderer'].render(window['THREE_FIRES'][index]['scene'], window['THREE_FIRES'][index]['camera']); //渲染界面
    }
  }

  requestAnimationFrame(animate);

}


export function animateToWarn() {

  if (window['THREE_FIRE']) {
    let animateList = window['THREE_FIRE']['animate'] || [];


    let warningColorR = 245 / 255;
    let warningColorG = 16 / 255;
    let warningColorB = 16 / 255;

    let degree = 0.05;

    for (let a of animateList) {
      for (let mesh of a.meshs) {
        mesh.dto = mesh.dto || {};
        mesh.dto.warning = true;

        let originColor = mesh.dto.originColor || mesh.material.color;
        mesh.dto.originColor = Object.assign({}, originColor);

        if (Math.abs(mesh.material.color.r - warningColorR) > degree) {
          if (mesh.material.color.r < warningColorR) {
            mesh.material.color.r += degree;
          } else {
            mesh.material.color.r -= degree;
          }
        } else if (Math.abs(mesh.material.color.g - warningColorG) > degree) {
          if (mesh.material.g < warningColorG) {
            mesh.material.color.g += degree;
          } else {
            mesh.material.color.g -= degree;
          }
        } else if (Math.abs(mesh.material.color.b - warningColorB) > degree) {
          if (mesh.material.b < warningColorB) {
            mesh.material.color.b += degree;
          } else {
            mesh.material.color.b -= degree;
          }
        } else {
          requestAnimationFrame(animateToOrigin);
          return;
        }
      }
    }

    if (window['THREE_FIRES']) {
      for (let index in window['THREE_FIRES']) {
        if (window['THREE_FIRES'][index]['controls']) {
          window['THREE_FIRES'][index]['controls'].update();
        }
        window['THREE_FIRES'][index]['renderer'].render(window['THREE_FIRES'][index]['scene'], window['THREE_FIRES'][index]['camera']); //渲染界面
      }
    }

  }

  requestAnimationFrame(animateToWarn);
}

export function animateToOrigin() {

  if (window['THREE_FIRE']) {
    let animateList = window['THREE_FIRE']['animate'] || [];


    let warningColorR = 245 / 255;
    let warningColorG = 16 / 255;
    let warningColorB = 16 / 255;

    let degree = 0.05;

    for (let a of animateList) {
      for (let mesh of a.meshs) {
        mesh.dto = mesh.dto || {};
        mesh.dto.warning = true;

        let originColor = mesh.dto.originColor || mesh.material.color;
        mesh.dto.originColor = Object.assign({}, originColor);

        if (Math.abs(mesh.material.color.r - originColor.r) > degree) {
          if (mesh.material.color.r < originColor.r) {
            mesh.material.color.r += degree;
          } else {
            mesh.material.color.r -= degree;
          }
        } else if (Math.abs(mesh.material.color.g - originColor.g) > degree) {
          if (mesh.material.color.g < originColor.g) {
            mesh.material.color.g += degree;
          } else {
            mesh.material.color.g -= degree;
          }
        } else if (Math.abs(mesh.material.color.b - originColor.b) > degree) {
          if (mesh.material.color.b < originColor.b) {
            mesh.material.color.b += degree;
          } else {
            mesh.material.color.b -= degree;
          }
        } else {
          requestAnimationFrame(animateToWarn);
          return;
        }
      }
    }

    if (window['THREE_FIRES']) {
      for (let index in window['THREE_FIRES']) {
        if (window['THREE_FIRES'][index]['controls']) {
          window['THREE_FIRES'][index]['controls'].update();
        }
        window['THREE_FIRES'][index]['renderer'].render(window['THREE_FIRES'][index]['scene'], window['THREE_FIRES'][index]['camera']); //渲染界面
      }
    }

  }

  requestAnimationFrame(animateToOrigin);
}

export function transferObj(data) {
  let project = new BuildProject();
  project.id = data.id;

  if (data.unit_list) {
    let buildings: Array<BuildModel> = [];
    data.unit_list.forEach(item => {

      let build = new BuildModel();
      build.id = item.id;
      build.name = item.unit_name;

      let buildingMap = BuildingMap[build.id];
      if (buildingMap) {

        build.x = buildingMap.x;
        build.y = buildingMap.y;
        build.z = buildingMap.z;

        build.buildingX = buildingMap.buildingX;
        build.buildingY = buildingMap.buildingY;
        build.buildingZ = buildingMap.buildingZ;

        build.buildingStairX = buildingMap.buildingStairX;
        build.buildingSpaceX = buildingMap.buildingSpaceX;

        if (item.project_unit_floor_dtolist) {
          let floors: Array<BuildFloorModel> = [];
          item.project_unit_floor_dtolist.forEach(itemFloor => {
            let f = new BuildFloorModel();
            f.floor = itemFloor.floor;

            if (BuildingFloorMap[build.id]) {
              let roomMatrix = BuildingFloorMap[build.id][itemFloor.floor];
              if (roomMatrix) {
                f.roomMatrix = roomMatrix;
                if (itemFloor.room_info_dtolist) {
                  f.room = [];
                  itemFloor.room_info_dtolist.forEach((itemRoom, itemRoomIndex) => {
                    let br = new BuildRoomModel(itemRoom.room_id, itemRoom.room_name);
                    f.room.push(br);
                  });
                }
                floors.push(f);
              }
            }
          });
          build.floor = floors;
        }

        buildings.push(build);
      }
    });
    project.buildings = buildings;
  }

  return project;
}


export function createFloorV2(index, projectId, buildModel: BuildModel, floorModel: BuildFloorModel) {

  let unitId = buildModel.id, floor = floorModel.floor;

  let x = buildModel.buildingX, y = buildModel.buildingY, z = buildModel.buildingZ,
    offset = 100,
    stairX = buildModel.buildingStairX,
    spaceX = buildModel.buildingSpaceX
  ;

  let rooms = floorModel.room;

  let buildingFloorMap = floorModel.roomMatrix;

  let floorZ = buildingFloorMap.floorWidthZ || z;

  const group = new THREE.Group();

  if (buildingFloorMap && buildingFloorMap.matrix) {
    let zIndex = 0;
    // 每一行 为 一组
    for (let i = 0; i < buildingFloorMap.matrix.length; i++) {

      const group1 = new THREE.Group();

      let roomMatrix: Array<number> = buildingFloorMap.matrix[i];
      let publicArea = buildingFloorMap.publicArea;

      // 1212121间隔旋转180度
      let needRotate = roomMatrix.includes(5) || roomMatrix.includes(6) ? 0 : (i % 2 === 0 ? 0 : 2);

      let xIndex = 0;

      let zSplit = true;

      zIndex = needRotate ? x : z;

      // 排列房间
      // 1 房间 2 楼梯 3 公区 0 空白
      // 4 不旋转房间
      // 5 旋转房间（90deg） 6 旋转楼梯（90deg）
      for (let j = 0; j < roomMatrix.length; j++) {
        if (roomMatrix[j] === 1) {
          let r = rooms.shift();
          // 房间
          let roomN = createRoomV2(index, projectId, unitId, floor, r.roomId, r.roomName, x, y, z, needRotate);
          roomN.position.set(offset + xIndex, (floor - 1) * y, 0);
          group1.add(roomN);
          xIndex += x;
        } else if (roomMatrix[j] === 2) {
          // 楼梯
          let roomN = createStair(stairX, y, z, needRotate);
          xIndex += (needRotate ? (stairX - x) : 0);
          roomN.position.set(offset + xIndex, (floor - 1) * y, 0);
          group1.add(roomN);
          xIndex += (needRotate ? x : stairX);
        } else if (roomMatrix[j] === 7) {
          // 7 旋转楼梯
          let roomN = createStair(stairX, y, z, 1);
          roomN.position.set(xIndex + x / 2, (floor - 1) * y, 0);
          group1.add(roomN);
          xIndex += z;
        } else if (roomMatrix[j] === 3) {
          // 3 公区
          // 获取公区长度
          let publicAreaX = publicArea.shift();
          let roomN = createSRoom(publicAreaX, y, z, needRotate);
          xIndex += (needRotate ? (publicAreaX - x) : 0);
          roomN.position.set(offset + xIndex, (floor - 1) * y, 0);
          group1.add(roomN);
          xIndex += (needRotate ? x : publicAreaX);
        } else if (roomMatrix[j] === 0) {
          // 0 空白
          // let roomN = this.createSpace(spaceX, y, z, needRotate);
          // roomN.position.set(offset + xIndex, (floor - 1) * y, 0);
          xIndex += (needRotate ? (spaceX + x) : spaceX);
        } else if (roomMatrix[j] === 4 || roomMatrix[j] === 5) {
          let r = rooms.shift();
          // 房间
          let roomN = createRoomV2(index, projectId, unitId, floor, r.roomId, r.roomName, x, y, z, 1);
          roomN.position.set(offset + xIndex, (floor - 1) * y, 0);
          group1.add(roomN);
          xIndex += z;
        } else if (roomMatrix[j] === 6) {
          let r = rooms.shift();
          // 房间
          let roomN = createRoomV2(index, projectId, unitId, floor, r.roomId, r.roomName, x, y, z, 1);
          roomN.position.set(xIndex + x / 2, (floor - 1) * y, x - Math.abs(x - z));
          group1.add(roomN);
          xIndex += z;
          zSplit = false;
        } else if (roomMatrix[j] === 8) {
          // 获取公区长度
          let publicAreaX = publicArea.shift();
          let roomN = createSRoom(publicAreaX, y, z, needRotate);
          xIndex += (needRotate ? (publicAreaX - x) : 0);
          roomN.position.set(offset + xIndex, (floor - 1) * y, 0);
          group1.add(roomN);
          xIndex += (needRotate ? x : publicAreaX);
          zSplit = false;
        }
      }

      if (i) {
        // 第二层开始 要往下平移
        let matrixFront: Array<number> = buildingFloorMap.matrix[i - 1];
        let matrix: Array<number> = buildingFloorMap.matrix[i];

        if (zSplit) {
          // 加多宽？ z
          if ((matrixFront.includes(6) || matrixFront.includes(7) || matrixFront.includes(-1)) && !matrix.includes(6)) {
            group1.position.set(0, 0, z * i + 200);
          } else {
            // group1.position.set(
            //   needRotate ? x : 0,
            //   0,
            //   needRotate ? (x > z ? (x * 2 * i + 200) : (z * 2 * i + 200)) : (x > z ? (x * 2 * i) : (z * 2 * i)));

            if (matrixFront.includes(5) || matrixFront.includes(6)) {
              group1.position.set(needRotate ? x / 2 : 0, 0, needRotate ? x * (i + 1) + 200 : x * i);
            } else {
              group1.position.set(needRotate ? z / 2 : 0, 0, needRotate ? z * (i + 1) + 200 : z * i);
            }
          }
        } else {
          // 加多宽？ x
          if ((matrixFront.includes(6) || matrixFront.includes(7)) && !matrix.includes(6) || matrixFront.includes(-1)) {
            group1.position.set(0, 0, x * i);
          } else {
            group1.position.set(0, 0, x * i + z);
          }
        }
      }

      group.add(group1);
    }
  }

  group.floor = floor;

  let floorMesh = createFloorMesh(floor, x, y, floorZ, buildingFloorMap.floorWidthNum, buildingFloorMap.matrix.length);
  group.add(floorMesh);

  if (index === 0) {
    let txtMesh = createFloorTxt(floor + '楼');
    txtMesh.position.set(-1000, (floor - 1) * y, 500);
    group.add(txtMesh);
  }

  // only one floor
  // if (index === 0) {
  //   group.position.set(0, -(floor - 1) * y, 0);
  // }

  group.objType = 'FLOOR';
  group.objId = floor;

  if (index != null) {
    window[window['WINDOW_KEY'] + ''][index].objf = window[window['WINDOW_KEY'] + ''][index].objf || {};
    window[window['WINDOW_KEY'] + ''][index].objf[projectId] = window[window['WINDOW_KEY'] + ''][index].objf[projectId] || {};
    window[window['WINDOW_KEY'] + ''][index].objf[projectId][unitId] = window[window['WINDOW_KEY'] + ''][index].objf[projectId][unitId] || {};
    window[window['WINDOW_KEY'] + ''][index].objf[projectId][unitId][floor] = group;
  } else {
    window[window['WINDOW_KEY'] + ''].objf = window[window['WINDOW_KEY'] + ''].objf || {};
    window[window['WINDOW_KEY'] + ''].objf[projectId] = window[window['WINDOW_KEY'] + ''].objf[projectId] || {};
    window[window['WINDOW_KEY'] + ''].objf[projectId][unitId] = window[window['WINDOW_KEY'] + ''].objf[projectId][unitId] || {};
    window[window['WINDOW_KEY'] + ''].objf[projectId][unitId][floor] = group;
  }


  return group;
}


export function createRoomV2(index, projectId, unitId, floor, roomId, name: string, x, y, z, rotate = 0): THREE.Group {
  const group = new THREE.Group();

  //创建一个长方体几何对象Geometry
  // RGB XYZ
  // 默认高 200
  // 默认墙体 10
  const geometryOut = new THREE.BoxGeometry(x, y, z);
  const geometryIn = new THREE.BoxGeometry(x - wall * 2, y - wall, z - wall * 2);

  //创建一个材质对象Material
  const materialWall = new THREE.MeshBasicMaterial({
    color: 0x0190ff,//设置材质颜色为淡蓝色
    transparent: true,//开启透明
    opacity: 0.5,//设置透明度
  });


  // 两个参数分别为几何体geometry、材质material
  const meshOut = new THREE.Mesh(geometryOut, materialWall); //网格模型对象Mesh
  const meshIn = new THREE.Mesh(geometryIn, materialWall); //网格模型对象Mesh


  const geometryDoor = new THREE.BoxGeometry(doorX, doorY, doorZ);
  const meshDoor = new THREE.Mesh(geometryDoor, materialWall); //网格模型对象Mesh


  //设置网格模型在三维空间中的位置坐标，默认是坐标原点
  meshOut.position.set(x / 2, y / 2, z / 2);
  meshIn.position.set(x / 2, y / 2 + wall, z / 2);

  meshDoor.position.set(doorX / 2 + 100, doorY / 2, z);


  const meshBackBSP = new ThreeBSP(meshOut);
  const meshLeftBSP = new ThreeBSP(meshIn);
  let unionBSP = meshBackBSP.subtract(meshLeftBSP);


  const doorBSP = new ThreeBSP(meshDoor);
  const frontExceptDoorGeometry = unionBSP.subtract(doorBSP).toGeometry();

  const mesh = new THREE.Mesh(frontExceptDoorGeometry, materialWall); //网格模型对象Mesh
  mesh.position.set(x / 2, y / 2, z / 2);
  group.add(mesh);


  // let txtMesh = createTxt(name);
  // txtMesh.position.set(0, 30, wall + 2);
  // meshFront.add(txtMesh);
  //
  // let txtMesh2 = createTxt(name);
  // txtMesh2.position.set(-70, 30, wall + 2);
  // meshBack.add(txtMesh2);
  //
  // group.add(meshFront);
  // group.add(meshBack);
  // group.add(meshLeft);
  // group.add(meshRight);
  // group.add(meshBottom);

  // group.add(meshDoor);

  // let box = this.createFireBox();
  // box.position.set(0, 0.8 * y, z / 2);
  // group.add(box);


  if (rotate > 0) {
    group.rotateY(Math.PI / 2 * rotate);
  }

  group.name = name;

  group.objType = 'ROOM';
  group.objId = roomId;

  if (index != null) {
    window[window['WINDOW_KEY'] + ''][index].obj = window[window['WINDOW_KEY'] + ''][index].obj || {};
    window[window['WINDOW_KEY'] + ''][index].obj[projectId] = window[window['WINDOW_KEY'] + ''][index].obj[projectId] || {};
    window[window['WINDOW_KEY'] + ''][index].obj[projectId][unitId] = window[window['WINDOW_KEY'] + ''][index].obj[projectId][unitId] || {};
    window[window['WINDOW_KEY'] + ''][index].obj[projectId][unitId][floor]
      = window[window['WINDOW_KEY'] + ''][index].obj[projectId][unitId][floor] || {};
    window[window['WINDOW_KEY'] + ''][index].obj[projectId][unitId][floor][roomId] = group;
  } else {
    window[window['WINDOW_KEY'] + ''].obj = window[window['WINDOW_KEY'] + ''].obj || {};
    window[window['WINDOW_KEY'] + ''].obj[projectId] = window[window['WINDOW_KEY'] + ''].obj[projectId] || {};
    window[window['WINDOW_KEY'] + ''].obj[projectId][unitId] = window[window['WINDOW_KEY'] + ''].obj[projectId][unitId] || {};
    window[window['WINDOW_KEY'] + ''].obj[projectId][unitId][floor] = window[window['WINDOW_KEY'] + ''].obj[projectId][unitId][floor] || {};
    window[window['WINDOW_KEY'] + ''].obj[projectId][unitId][floor][roomId] = group;
  }


  return group;
}


export function createSpace(x, y, z, rotate = 0): THREE.Group {
  const group = new THREE.Group();

  //创建一个长方体几何对象Geometry
  // RGB XYZ
  // 默认高 200
  // 默认墙体 10
  const geometryBox = new THREE.BoxGeometry(x, y, z);

  //创建一个材质对象Material
  const materialWall = new THREE.MeshBasicMaterial({
    color: 0xffffff,//设置材质颜色为淡蓝色
    transparent: true,//开启透明
    opacity: 0,//设置透明度
  });


  // 两个参数分别为几何体geometry、材质material
  const meshFront = new THREE.Mesh(geometryBox, materialWall); //网格模型对象Mesh


  //设置网格模型在三维空间中的位置坐标，默认是坐标原点
  meshFront.position.set(x / 2, y / 2, z / 2);

  // group.add(meshFront);
  group.add(meshFront);

  if (rotate > 0) {
    group.rotateY(Math.PI / 2 * rotate);
  }

  return group;
}

export function createStair(x, y, z, rotate = 0): THREE.Group {
  const group = new THREE.Group();

  //创建一个长方体几何对象Geometry
  // RGB XYZ
  // 默认高 200
  // 默认墙体 10
  const geometryOut = new THREE.BoxGeometry(x, y, z);
  const geometryIn = new THREE.BoxGeometry(x - wall * 2, y - wall, z - wall);

  //创建一个材质对象Material
  const materialWall = new THREE.MeshBasicMaterial({
    color: 0xf28e16,//设置材质颜色为淡蓝色
    transparent: true,//开启透明
    opacity: 0.2,//设置透明度
  });

  // 两个参数分别为几何体geometry、材质material
  const meshOut = new THREE.Mesh(geometryOut, materialWall); //网格模型对象Mesh

  //设置网格模型在三维空间中的位置坐标，默认是坐标原点
  meshOut.position.set(x / 2, y / 2, z / 2);

  group.add(meshOut);

  if (rotate > 0) {
    group.rotateY(Math.PI / 2 * rotate);
  }

  return group;
}

export function createSRoom(x, y, z, rotate = 0): THREE.Group {
  const group = new THREE.Group();

  //创建一个长方体几何对象Geometry
  // RGB XYZ
  // 默认高 200
  // 默认墙体 10
  const geometryBack = new THREE.BoxGeometry(x, y, z);

  //创建一个材质对象Material
  const materialWall = new THREE.MeshBasicMaterial({
    color: 0x5ACC73,//设置材质颜色为淡蓝色
    transparent: true,//开启透明
    opacity: 0.4,//设置透明度
  });


  // 两个参数分别为几何体geometry、材质material
  // const meshFront = new THREE.Mesh(geometryFront, materialWall); //网格模型对象Mesh
  const meshBack = new THREE.Mesh(geometryBack, materialWall); //网格模型对象Mesh

  //设置网格模型在三维空间中的位置坐标，默认是坐标原点
  meshBack.position.set(x / 2, y / 2, z / 2);

  // group.add(meshFront);
  group.add(meshBack);

  if (rotate > 0) {
    group.rotateY(Math.PI / 2 * rotate);
  }

  return group;
}

export function createFloorMesh(floor, x, y, z, num, row): THREE.Group {
  const group = new THREE.Group();

  //创建一个长方体几何对象Geometry
  // RGB XYZ
  // 默认高 200 -5
  // 默认墙体 10
  const geometryBottom = new THREE.BoxGeometry(x * num, -5, (z + 250) * row);

  //创建一个材质对象Material
  const materialWall = new THREE.MeshBasicMaterial({
    color: 0xffffff,//设置材质颜色为淡蓝色
    transparent: true,//开启透明
    opacity: 0.1,//设置透明度
  });


  // 两个参数分别为几何体geometry、材质material
  const meshBottom = new THREE.Mesh(geometryBottom, materialWall); //网格模型对象Mesh


  //设置网格模型在三维空间中的位置坐标，默认是坐标原点
  meshBottom.position.set(x * num / 2 - 10, (floor - 1) * y, ((z + 250) * row) / 2);

  group.add(meshBottom);

  return group;
}


export function createFireBox(type: number = 0): THREE.Group {
  const group = new THREE.Group();

  //创建一个长方体几何对象Geometry
  // RGB XYZ
  // 默认高 200
  // 默认墙体 10
  let boxSize = 50;
  const geometryBox = new THREE.BoxGeometry(boxSize, boxSize, boxSize);

  //创建一个材质对象Material
  const materialBox = new THREE.MeshBasicMaterial({
    color: 0xDBAF6B,//设置材质颜色为黄色
    transparent: true,//开启透明
    opacity: 0.7,//设置透明度
  });

  // 两个参数分别为几何体geometry、材质material
  const meshBox = new THREE.Mesh(geometryBox, materialBox); //网格模型对象Mesh
  meshBox.position.set(boxSize / 2, boxSize / 2, boxSize / 2);

  group.add(meshBox);

  return group;
}


export function createTxt(name): THREE.Mesh {
  let txtGeo = new TextGeometry(name, {
    font: window['THREE']['font'],
    size: 28,
    height: 0.1,
    curveSegments: 12,
    bevelEnabled: true,
    bevelThickness: 0.1,
    bevelSize: 0.05,
    bevelSegments: 3
  });
  let txtMater = new THREE.MeshBasicMaterial({
    color: 0xfbda41,
    transparent: true,
    opacity: 0.9
  });
  let txtMesh = new THREE.Mesh(txtGeo, txtMater);
  return txtMesh;
}


export function createFloorTxt(name): THREE.Mesh {
  let txtGeo = new TextGeometry(name, {
    font: window['THREE']['font'],
    size: 168,
    height: 0.1,
    curveSegments: 12,
    bevelEnabled: true,
    bevelThickness: 0.1,
    bevelSize: 0.05,
    bevelSegments: 3
  });
  let txtMater = new THREE.MeshBasicMaterial({
    color: 0xfbda41,
    transparent: true,
    opacity: 0.9
  });
  let txtMesh = new THREE.Mesh(txtGeo, txtMater);
  return txtMesh;
}


export function clearGroup(group) {
  // 释放 几何体 和 材质
  const clearCache = (item) => {
    item.geometry.dispose();
    item.material.dispose();
  };

  // 递归释放物体下的 几何体 和 材质
  const removeObj = (obj) => {
    let arr = obj.children.filter((x) => x);
    arr.forEach((item) => {
      if (item.children.length) {
        removeObj(item);
      } else {
        clearCache(item);
        item.clear();
      }
    });
    obj.clear();
    arr = null;
  };

  // 移除 group
  removeObj(group);
}
