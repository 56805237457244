<div class="setbox">
  <div class="row">
    <div class="col-12 col-sm-6 col-md-6 col-xl-1 col-2xl-1">
      <div class="form-group">
        <label>类型</label>
        <select class="w-100" [(ngModel)]="complainReq.complaints_type">
          <option value>全部</option>
          <option value="COMPLAINT">投诉</option>
          <option value="PROPOSAL">建议</option>
        </select>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-6 col-xl-1 col-2xl-1">
      <div class="form-group">
        <label>状态</label>
        <select class="w-100" [(ngModel)]="complainReq.complaints_status">
          <option value>全部</option>
          <option value="{{item.id}}" *ngFor="let item of status; let i=index">{{item.name}}</option>
        </select>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-6 col-xl-1 col-2xl-1">
      <div class="form-group">
        <label>处理归类</label>
        <select class="w-100" [(ngModel)]="complainReq.classify_type">
          <option value>全部</option>
          <option value="{{item.code}}" *ngFor="let item of classify_type_list; let i=index">{{item.desc}}</option>
        </select>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-2 col-xl-2">
      <div class="form-group">
        <label>手机号码</label>
        <input type="text" class="form-control" [(ngModel)]="complainReq.complaints_user_phone" class="w-90">
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-xl-4">
      <div class="form-group">
        <label>提交日期</label>
        <div>
          <div class="d-inline-block w-45">
            <my-date-picker [options]="myDatePickerOptions" placeholder="年/月/日" [(ngModel)]="start_time">
            </my-date-picker>
          </div>
          <span class="ml-1 mr-1 valign-top">-</span>
          <div class="d-inline-block w-45">
            <my-date-picker [options]="myDatePickerOptions" placeholder="年/月/日" [(ngModel)]="end_time">
            </my-date-picker>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-3 col-xl-3 mb10" style="margin-top: 30px">
      <div class="form-group">
        <button class="btn btn-info mr-3" type="button" (click)="search()">
          <i class="fa fa-search"></i>搜索
        </button>
        <!--<button class="btn btn-secondary " type="button" (click)="clear()">
          <i class="fa fa-times"></i>清空条件
        </button>-->
        <button class="btn btn-info" (click)="dataStatisticsModal.show()" *ngIf="btn_privileges.COMPLAINT_DATA_STA"><i class="fa fa-bar-chart"></i>数据统计</button>
      </div>
    </div>
  </div>
  <!-- table -->
  <table class="table table-bordered table-striped">
    <thead>
    <tr>
      <th class="show2">编号</th>
      <th>类型</th>
      <th>处理归类</th>
      <th>投诉人/建议人</th>
      <th class="show2">投诉时间</th>
      <th class="show2">最后处理时间</th>
      <th class="show2">继续反馈次数</th>
      <th>用户评价</th>
      <th>状态</th>
      <th>操作</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="!btn_privileges.COMPLAINT_MANAGE || !complain?.complaints_info_dtos">
      <td class="show1" colspan="4" *ngIf="complain?.total == 0">本次未查询到相关数据</td>
      <td class="show2" colspan="9" *ngIf="complain?.total == 0">本次未查询到相关数据</td>
    </tr>
    <ng-container *ngIf="btn_privileges.COMPLAINT_MANAGE && complain?.complaints_info_dtos && complain?.total > 0">
      <tr *ngFor="let item of complain?.complaints_info_dtos  | paginate: {itemsPerPage: complainReq?.page_size,
        currentPage: complainReq?.page_num, totalItems:complain?.total, id : 'complaints' }">
        <td class="show2">{{item?.complaints_id}}</td>
        <td>{{item?.complaints_type_name}}</td>
        <td>{{getClassifyTypeName(item?.classify_type)}}</td>
        <td>{{item?.complaints_user_phone}}</td>
        <td class="show2">{{item?.create_time | date:'yyyy-MM-dd HH:mm'}}</td>
        <td class="show2">{{item?.solve_time | date:'yyyy-MM-dd HH:mm'}}</td>
        <td class="show2">{{item?.complaints_num}}</td>
        <td>
          <span *ngIf="item?.score_attitude != null && item?.score_quality!= null">
            态度{{item?.score_attitude}}星;质量{{item?.score_quality}}星
          </span>
          <span *ngIf="item?.score_attitude == null && item?.score_quality== null">--</span>
        </td>
        <td>
          <span class="badge badge-primary badge-pill" *ngIf="item?.complaints_status == 'WAITING'">未处理</span>
          <span class="badge badge-success badge-pill" *ngIf="item?.complaints_status == 'PROCESSING'">处理中</span>
          <span class="badge badge-secondary badge-pill" *ngIf="item?.complaints_status == 'FIXED'">处理完成</span>
        </td>
        <td>
          <button class="btn btn-info" type="button" (click)="openModal(item)" *ngIf="btn_privileges.COMPLAINT_VIEW_HANDLE">
            <ng-container *ngIf="item?.complaints_status == 'FIXED'"><i class="fa fa-search"></i>查看</ng-container>
            <ng-container *ngIf="item?.complaints_status == 'WAITING' || item?.complaints_status == 'PROCESSING'"><i class="fa fa-edit"></i>处理</ng-container>
          </button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <div class="text-right pages" *ngIf="btn_privileges.COMPLAINT_MANAGE && complain?.complaints_info_dtos">
    <pagination-controls id="complaints" *ngIf="complain?.total !== 0" (pageChange)="getComplainPage($event)"
                         directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                         screenReaderPaginationLabel="Pagination"
                         screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"></pagination-controls>
  </div>
</div>

<!-- 数据统计 -->
<div class="modal fade" bsModal #dataStatisticsModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header ">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle"></i>投诉/建议数据统计</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="dataStatisticsModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body table-overflow" style="min-height: 300px; max-height: 600px">
        <div class="ml-3 mt-2">
          <p class="d-inline-block mr-2 valign-top">提交日期:</p>
          <div class="d-inline-block mr-2">
            <div class="d-inline-block width140">
              <my-date-picker [options]="myDatePickerOptions" placeholder="年/月/日" [(ngModel)]="statistics_start_time">
              </my-date-picker>
            </div>
            <span class="ml-1 mr-1 valign-top">-</span>
            <div class="d-inline-block width140">
              <my-date-picker [options]="myDatePickerOptions" placeholder="年/月/日" [(ngModel)]="statistics_end_time">
              </my-date-picker>
            </div>
          </div>
          <button class="btn btn-primary d-inline-block valign-top" (click)="getStatisticsList()"><i class="fa fa-search"></i>查看</button>
        </div>
        <div class="ml-3 mt-2">
          <span *ngIf="statistics_start_time && statistics_end_time">数据周期: {{getDate(statistics_start_time.formatted)}}-{{getDate(statistics_end_time.formatted)}}</span>
        </div>
        <!-- highChart使用的div容器只能写id, 不要写样式-->
        <div class="text-center mt-3"><div id="pieChart" [chart]="chart1"></div></div>
        <hr class="w-100" *ngIf="showHr">
        <div class="ml-3 mt-3"><div id="complaintChart" [chart]="chart2"></div></div>
        <hr class="w-100" *ngIf="showHr">
        <div class="ml-3 mt-3"><div id="proposalChart" [chart]="chart3"></div></div>


      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="dataStatisticsModal.hide()">
          关闭</button>
      </div>
    </div>
  </div>
</div>

<app-complain-modal #complainModal [complaintsInfoDTO]="complaintsInfoDTO" [complaintsLogListResponse]="complaintsLogListResponse" (refresh_list)="refreshList($event)"></app-complain-modal>


