import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from '../../service/common/auth.service';
import {LogService} from '../../service/log/log.service';
import {Observable} from "rxjs";

/**
 * @description:权限验证guard
 *
 * @Author: yinqiang
 * @Date: 2018-02-13 17:35:41
 * @Last Modified by: yinqiang
 * @Last Modified time: 2018-04-12 20:35:15
 */
@Injectable()
export class PathChildGuard implements CanActivateChild {

  constructor(public authService: AuthService, private logService: LogService, private router: Router) {
    this.logService.debug('PathChildGuard constructor');
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const authToken: String = this.authService.getAuthToken();
    // this.logService.debug('PathChildGuard执行，获取token=' + authToken);
    //如果当前没有token 则不允许进入当前路径 直接跳转到login页
    return this.authService.autoLoginWechatCode()
      .then(() => {
        return true;
      })
      .catch(() => {

        if (authToken == null || authToken.length <= 0) {

          this.router.navigate(['/login']);

          return false;
        } else {
          return true;
        }
      });
  }
}
