
<div class="modal fade" bsModal #templateDetail="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md" *ngIf="detail">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>详细信息</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="templateDetail.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 mb10 text-center">
            <img class="figure-img img-fluid " style="width: 6em;height: 8em"
                 src="{{detail.head_image}}" alt="">
          </div>

          <!--未登记在住人员-->
          <ng-container *ngIf="detail.safe_type=='UN_REGISTERED'">

            <div class="col-12 font-weight-bold">
              此人7天内被抓拍到多次进出社区，可能为<span class="text-danger">未登记在住人员</span>，通过系统分析推测，此人可能住在<span class="text-danger"><ng-container *ngIf="detail?.not_registered_chickin_dto?.unit_room_name">{{detail?.not_registered_chickin_dto?.unit_room_name}}</ng-container><ng-container *ngIf="!detail?.not_registered_chickin_dto?.unit_room_name">&nbsp;&nbsp;</ng-container></span>房间
            </div>
            <div class="col-12">
              <span class="font-weight-bold">以下为被抓拍的进出信息：</span>
              <table class="table border-striped table-fix-scroll v2 " >
                <thead>
                <tr>
                  <th>位置</th>
                  <th>进出</th>
                  <th>时间</th>
                  <th>操作</th>
                </tr>
                </thead>
                <tbody *ngIf="detail.not_registered_chickin_dto&&detail.not_registered_chickin_dto.strange_suspect_dtolist">
                <ng-container *ngFor="let item1 of detail.not_registered_chickin_dto.strange_suspect_dtolist" >
                  <ng-container *ngFor="let item2 of item1.access_dtos" >
                    <tr >
                      <td>{{item2.position_detail}}</td>
                      <td>{{item2.type}}</td>
                      <td>{{item2.access_time|date:'yyyy.MM.dd HH:mm:ss'}}</td>
                      <td><button class="btn btn-link" (click)="seeImg(detail, item2.image)">查看图片</button></td>
                    </tr>
                  </ng-container>
                </ng-container>
                </tbody>
              </table>
            </div>

          </ng-container>
          <!--陌生可疑-->
          <ng-container *ngIf="detail.safe_type=='UNKNOW_SUSPICIOUS'">

            <div class="col-12 font-weight-bold">
              此陌生人被抓拍到在社区内频繁进出，可能为<span class="text-danger">陌生可疑人员</span>，以下为被抓拍的进出路径信息：
            </div>
            <div class="col-12 scroll v2" *ngIf="detail.strange_suspect_dtos" >

              <ng-container *ngFor="let item1 of detail.strange_suspect_dtos;let i=index">
                <div class="bg-gray">
                  <span class="font-weight-bold">{{item1.unit_detail}}</span>

                  <table class="table ">
                    <tbody>
                    <tr *ngFor="let item2 of item1.access_dtos">
                      <td>{{item2.type}}</td>
                      <td>{{item2.access_time|date:'yyyy.MM.dd HH:mm:ss'}}</td>
                      <td><button class="btn btn-link" (click)="seeImg(detail, item2.image)">查看图片</button></td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <div class="text-center" *ngIf="i!=detail.strange_suspect_dtos.length-1">↓</div>
              </ng-container>

            </div>

          </ng-container>
          <!--3日未归-->
          <ng-container *ngIf="detail.safe_type=='THID_NOT_IN'">

            <div class="col-12 text-center font-weight-bold">
              {{detail.room_name}} {{detail.person_name}}
            </div>
            <div class="col-12 text-center font-weight-bold">
              {{detail.person_remark}}
            </div>
            <div class="col-12 font-weight-bold">
              此在住人员离开已超过72小时未进入，可能为<span class="text-danger">3日未归人员</span>，以下为最后一次被抓拍的信息：
            </div>
            <div class="col-12 " >
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th>最后一次离宿时间</th>
                      <th>操作</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>{{detail.get_out_time|date:'yyyy.MM.dd HH:mm:ss'}}</td>
                      <td><button class="btn btn-link" (click)="seeImg(detail, detail.last_image)">查看图片</button></td>
                    </tr>
                    </tbody>
                  </table>
            </div>

          </ng-container>
          <!--三日未出-->
          <ng-container *ngIf="detail.safe_type=='THID_NOT_OUT'">

            <div class="col-12 text-center font-weight-bold">
              {{detail.room_name}} {{detail.person_name}}
            </div>
            <div class="col-12 text-center font-weight-bold">
              {{detail.person_remark}}
            </div>
            <div class="col-12 font-weight-bold">
              此在住人员离开已进入72小时未离开，可能为<span class="text-danger">3日未出人员</span>，以下为最后一次被抓拍的信息：
            </div>
            <div class="col-12 " >
              <table class="table table-striped">
                <thead>
                <tr>
                  <th>最后一次进入时间</th>
                  <th>操作</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{detail.come_in_time|date:'yyyy.MM.dd HH:mm:ss'}}</td>
                  <td><button class="btn btn-link" (click)="seeImg(detail, detail.last_image)">查看图片</button></td>
                </tr>
                </tbody>
              </table>
            </div>

          </ng-container>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal"
                (click)="templateDetail.hide()">
          关闭
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #templateHandle="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md" *ngIf="detail">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>安全报警处理</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="templateHandle.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 col-lg-6 mb10">报警时间：{{detail?.alarm_time | date:'yyyy-MM-dd HH:mm:ss'}}</div>
          <div class="col-12 col-lg-6 mb10" >报警类型：
            <ng-container *ngIf="detail.safe_type=='THID_NOT_IN'">3日未归人员</ng-container>
            <ng-container *ngIf="detail.safe_type=='THID_NOT_OUT'">3日未出人员</ng-container>
            <ng-container *ngIf="detail.safe_type=='UN_REGISTERED'">未登记在住人员</ng-container>
            <ng-container *ngIf="detail.safe_type=='UNKNOW_SUSPICIOUS'">陌生可疑人员</ng-container>
          </div>
          <div class="col-12 mb10">人员图像：
            <img class="figure-img img-fluid " style="width: 6em;height: 8em"
                 src="{{detail.head_image}}" alt="">
          </div>
        </div>
        <div class="con">
          <div class="row">
            <div class="col-12 mb10">处理进展：</div>
            <div class="col-12 mb10">
              <div class="height260" *ngIf="logs">
                <div class="row">
                  <div class="col-12 col-md-9 col-xl-7"
                       *ngFor="let item of logs; let i = index;">
                    <div class="card car-bor">
                      <div class="card-header">
                        <span>{{item?.create_time | date: 'yyyy-MM-dd'}}</span>
                        <span>{{item?.create_time | date: 'HH:mm:ss'}}</span>
                        <span> {{item?.operate_name}}</span>
                        <span *ngIf="item?.type == 'PROCESSING'  && i === 0 ">改变状态为处理中</span>
                        <span *ngIf="item?.type == 'PROCESSING' && i !== 0">增加进展</span>
                        <span *ngIf="item?.type == 'FIXED' && i !== 0">改变状态为关闭</span>

                      </div>
                      <div class="card-body">
                        <blockquote class="blockquote mb-0">
                          <p class="new-lines">{{item?.content}}</p>
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="con1 " *ngIf="!showHandle">
          <div class="row mb10">
            <div class="col-12 mb10">增加进展：</div>
            <div class="col-12">
              <textarea class="w-100" rows="3" [(ngModel)]="evolve_remark"></textarea>
              <p class="text-danger">请填写进展内容,不少于3个字符</p>
            </div>
          </div>
        </div>
        <div class="row mb10" *ngIf="!showHandle">
          <div class="col-12">
            <div class="form-check form-check-inline">
              <input class="form-check-input" style="cursor: pointer;" type="radio" value="PROCESSING"
                     [(ngModel)]="evolve_status">
              <label class="form-check-label">处理中</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" style="cursor: pointer;" type="radio" value="FIXED"
                     [(ngModel)]="evolve_status">
              <label class="form-check-label">已关闭</label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" *ngIf="!showHandle">
        <button  type="button" class="btn btn-primary" (click)="ok_evolve(detail)">保存
        </button>
        <button  type="button" class="btn btn-secondary" data-dismiss="modal"
                (click)="templateHandle.hide()">
          取消
        </button>
      </div>
      <div class="modal-footer" *ngIf="showHandle">
        <button  type="button" class="btn btn-primary" (click)="templateHandle.hide()">关闭
        </button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" bsModal #templateImg="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md" >
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>查看照片</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="templateImg.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body mh-24">
        <div class="row imgSave py-2" *ngIf="!hideContent && detail">
          <div class="col-12 mb10 text-center">
            <div class="mx-5">
              <img class="figure-img img-fluid w-100 "
                   [src]="img" alt="">
            </div>
          </div>
          <div class="col-12 ">
            <div class="bg-gray mx-5">
              <ng-container *ngIf="detail.safe_type=='THID_NOT_IN'">
                <div class="col-12 font-weight-bold">
                  3日未归人员
                </div>
                <div class="col-12">
                  {{detail.room_name}} {{detail.person_name}} {{detail.person_remark}}
                </div>
              </ng-container>
              <ng-container *ngIf="detail.safe_type=='THID_NOT_OUT'">
                <div class="col-12 font-weight-bold">
                  3日未出人员
                </div>
                <div class="col-12">
                  {{detail.room_name}} {{detail.person_name}} {{detail.person_remark}}
                </div>
              </ng-container>
              <ng-container *ngIf="detail.safe_type=='UN_REGISTERED'">
                <div class="col-12 font-weight-bold">
                  未登记在住人员
                </div>
                <div class="col-12">
                  {{detail.not_registered_chickin_dto.total_num}}次进出{{detail.not_registered_chickin_dto.unit_name}}
                </div>
                <div class="col-12">进出时段：</div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-6" *ngIf="detail.not_registered_chickin_dto.come_in_time">
                      进：{{detail.not_registered_chickin_dto.come_in_time}}</div>
                    <div class="col-6" *ngIf="!detail.not_registered_chickin_dto.come_in_time">
                      进：未能获取</div>
                    <div class="col-6" *ngIf="detail.not_registered_chickin_dto.get_out_time">
                      出：{{detail.not_registered_chickin_dto.get_out_time}}</div>
                    <div class="col-6" *ngIf="!detail.not_registered_chickin_dto.get_out_time">
                      出：未能获取</div>
                  </div>
                </div>
                <div class="col-12">
                  可能住在<ng-container *ngIf="detail?.not_registered_chickin_dto?.unit_room_name">{{detail?.not_registered_chickin_dto?.unit_room_name}}</ng-container><ng-container *ngIf="!detail?.not_registered_chickin_dto?.unit_room_name">&nbsp;&nbsp;</ng-container>房间
                </div>
              </ng-container>
              <ng-container *ngIf="detail.safe_type=='UNKNOW_SUSPICIOUS'">
                <div class="col-12 font-weight-bold">
                  陌生可疑人员
                </div>
                <div class="col-12">活动路径：</div>
                <div class="col-12">
                  <ng-container *ngFor="let item1 of detail.strange_suspect_dtos;let i=index">
                    {{item1.unit_detail}}<ng-container *ngIf="detail.strange_suspect_dtos.length-1!=i">→</ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="share-pic w-100">
        </div>
      </div>
      <div class="modal-footer">
        <button  type="button" class="btn btn-primary" (click)="savePic()">保存图片
        </button>
        <button  type="button" class="btn btn-secondary" data-dismiss="modal"
                 (click)="templateImg.hide()">
          关闭
        </button>
      </div>
    </div>
  </div>
</div>
