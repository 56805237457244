import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {RequestService} from '../request.service';
import {BannerDetailRequest} from '../../model/banner/banner.model';
import {
  AllocationUsersResponse,
  AppointDetailRequest,
  AppointListRequest,
  AppointListResponse,
  AppointmentHouseApplyDetailResponse, AppointmentHouseApplyListRequest, AppointmentHouseApplyListResponse,
  AppointmentHouseApplySubmitRequest,
  AppointmentHouseApplyUpdateTimeRequest, AppointmentProjectGroupListResponse,
  AppointmentProjectTimesResponse,
  WorkRemindAppointmentAllocationRequest,
  WorkRemindDisposeAppointmentRequest,
  WorkRemindDisposeAppointmentResponse
} from "../../model/appoint/appoint.model";

@Injectable()
export class AppointService {

  private APPOINT_LIST = `${environment.apiHost}appoint/list`;
  private APPOINT_OPERATE = `${environment.apiHost}appoint/operate`;
  private APPOINT_DETAIL = `${environment.apiHost}appoint/detail`;

  // 2024年8月22日10:20:34 new
  private GET_APPOINT_ALLOCATION_USER_LIST = `${environment.apiHost}appointment/allocation/users`;
  private POST_APPOINT_ALLOCATION = `${environment.apiHost}work/remind/appointment/allocation`;
  private GET_APPOINT_DETAIL = `${environment.apiHost}appointment/detail`;
  private GET_APPOINT_TIMES = `${environment.apiHost}appointment/project/times`;
  private POST_APPOINT_TIME = `${environment.apiHost}appointment/update/time`;
  private POST_APPOINT_DISPOSE = `${environment.apiHost}work/remind/appointment/dispose`;
  private GET_APPOINT_GROUP_LIST = `${environment.apiHost}appointment/group/list`;
  private GET_APPOINT_SUBMIT = `${environment.apiHost}appointment/submit`;
  private GET_APPOINT_LIST = `${environment.apiHost}appointment/search/list`;

  constructor(private requestService: RequestService) {
  }

  /**
   * @Description: 预约看房列表
   * @Author: yangwen
   * @Date: 2019/2/26 19:09
   */
  list(req: AppointListRequest): Promise<AppointListResponse> {
    return this.requestService.authGet(this.APPOINT_LIST, req);
  }

  listWithLoading(req: AppointListRequest): Promise<AppointListResponse> {
    return this.requestService.authGet(this.APPOINT_LIST, req, true);
  }

  /**
   * @Description: 预约看房处理
   * @Author: yangwen
   * @Date: 2019/2/26 19:11
   */
  operate(req: BannerDetailRequest): Promise<any> {
    return this.requestService.authPost(this.APPOINT_OPERATE, req);
  }

  /**
   * @Description: 看房详细信息
   * @Author: yangwen
   * @Date: 2019/2/26 19:12
   */
  detail(req: AppointDetailRequest): Promise<any> {
    return this.requestService.authGet(this.APPOINT_DETAIL, req);
  }


  allocationUserList(): Promise<AllocationUsersResponse> {
    return this.requestService.authGet(this.GET_APPOINT_ALLOCATION_USER_LIST);
  }

  allocationUser(req: WorkRemindAppointmentAllocationRequest): Promise<any> {
    return this.requestService.authPost(this.POST_APPOINT_ALLOCATION, req);
  }

  appointmentDetail(id: number): Promise<AppointmentHouseApplyDetailResponse> {
    return this.requestService.authGet(this.GET_APPOINT_DETAIL, {id: id});
  }

  appointmentTimes(time: string): Promise<AppointmentProjectTimesResponse> {
    return this.requestService.authGet(this.GET_APPOINT_TIMES, {appointment_time: time});
  }

  updateAppointmentTime(req: AppointmentHouseApplyUpdateTimeRequest): Promise<any> {
    return this.requestService.authPost(this.POST_APPOINT_TIME, req);
  }

  appointmentDispose(req: WorkRemindDisposeAppointmentRequest): Promise<WorkRemindDisposeAppointmentResponse> {
    return this.requestService.authPost(this.POST_APPOINT_DISPOSE, req);
  }

  appointmentGroupList(): Promise<AppointmentProjectGroupListResponse> {
    return this.requestService.authGet(this.GET_APPOINT_GROUP_LIST);
  }

  appointmentSubmit(req: AppointmentHouseApplySubmitRequest): Promise<any> {
    return this.requestService.authPost(this.GET_APPOINT_SUBMIT, req);
  }

  appointmentList(req: AppointmentHouseApplyListRequest): Promise<AppointmentHouseApplyListResponse> {
    return this.requestService.authGet(this.GET_APPOINT_LIST, req);
  }
}
