import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {
  CheckoutRefundDTO,
  CheckoutRenterConfirmRequest,
  CheckoutRenterListViewResponse,
  RenterCheckoutRecordDetailRequest, RenterCheckoutRecordDetailResponse, RenterCheckoutRefundDTO
} from "../../../../model/signed/checkout.model";
import {LeaseRenterInfoDTO} from "../../../../model/renter/renter.model";
import {SignedService} from "../../../../service/signed/signed.service";
import {WorkService} from "../../../../service/common/work.service";
import {WorkModel} from "../../../../model/common/common.model";

@Component({
  selector: 'app-renter-checkout-detail',
  templateUrl: './renter-checkout-detail.component.html',
  styleUrls: ['./renter-checkout-detail.component.css']
})
export class RenterCheckoutDetailComponent implements OnInit {

  recordId?: number;

  resp: RenterCheckoutRecordDetailResponse;
  renter: LeaseRenterInfoDTO;

  checkoutRefundDTO: RenterCheckoutRefundDTO;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private signedService: SignedService,
    private workService: WorkService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(queryParams => {
      this.recordId = queryParams['recordId'];
      this.queryDetail();
    });
  }

  queryDetail(){
    let req = new RenterCheckoutRecordDetailRequest();
    req.record_id = this.recordId;
    this.signedService.renterCheckoutRecordDetail(req).then(data =>{
      if (data) {
        this.resp = data;
        this.renter = data.lease_renter_dto;
        this.checkoutRefundDTO = data.renter_checkout_refund_dto;
      }
    })
  }

  closeTab() {
    this.workService.event_bus.emit(new WorkModel(this.workService.type.TAB_CLOSE_SELF));
  }

  transferInTotalAbs(total: string): number{
    return Math.abs(+total);
  }
}
