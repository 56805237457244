<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-title font-s">
        {{'系统操作日志'}}
      </div>
      <table class="table table-striped">
        <thead>
        <tr>
          <th>
            操作人
          </th>
          <th>
            操作类型
          </th>
          <th>
            操作内容
          </th>
          <th>
            操作时间
          </th>
        </tr>
        </thead>
        <tbody *ngIf="!systemLogListResponse?.system_log_dtos">
        <tr>
          <td colspan="4" class="text-center">
            暂无数据
          </td>
        </tr>
        </tbody>
        <tbody>
        <tr *ngFor="let item of systemLogListResponse?.system_log_dtos | paginate: {itemsPerPage: systemLogReqParam.page_count,
                        currentPage: systemLogReqParam.page_no,totalItems: systemLogListResponse?.total }">
          <td>{{item?.operate_name}}</td>

          <td>
            <span *ngIf="item?.operate_type=='DEPARTMENT_ADD'">添加部门</span>
            <span *ngIf="item?.operate_type=='DEPARTMENT_UPDATE'">修改部门</span>
            <span *ngIf="item?.operate_type=='USER_ADD'">添加用户</span>
            <span *ngIf="item?.operate_type=='USER_UPDATE'">修改用户</span>
            <span *ngIf="item?.operate_type=='POSITION_UPDATE'">增加岗位</span>
            <span *ngIf="item?.operate_type=='POSITION_ADD'">增加岗位</span>
          </td>
          <td>{{item?.operate_conent}}</td>
          <td>{{item?.last_modify_time}}</td>
        </tr>
        </tbody>
        <tfoot *ngIf="systemLogListResponse?.system_log_dtos">
        <tr>
          <td colspan="9" class="text-right pages">
            <pagination-controls (pageChange)="pageChange($event)"
                                 directionLinks="true"
                                 previousLabel="上一页"
                                 nextLabel="下一页"
                                 screenReaderPaginationLabel="Pagination"
                                 screenReaderPageLabel="page"
                                 screenReaderCurrentLabel="You're on page"
            ></pagination-controls>
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
