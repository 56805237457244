import {PageReq, PageResp} from '../page/page';
import {ProjectAppointmentTimeDTO} from "../project/project.model";

export class AppointListRequest extends PageReq {
  status?: string;
  search_content?: string;

  // constructor(){
  //
  // }
}

export class AppointDetailRequest {
  id?: number;
  status?: string;
  see_house_time?: Date;
}

export class AppointListResponse extends PageResp {
  appoint_info_dtos?: Array<AppointInfoDTO>;
}

export class AppointInfoDTO {
  id?: number;

  check_in_time?: Date;

  check_in_name?: string;

  check_in_phone?: string;

  check_in_weixin?: string;

  project_id?: number;

  project_name?: string;

  room_type_id?: number;

  room_type_name?: string;

  see_house_time?: Date;

  status?: string;

  create_time?: Date;

}

export class AllocationUserDTO {
  user_id?: number;
  user_name?: string;
}

export class AllocationUsersResponse {
  users?: Array<AllocationUserDTO>;
}

export class WorkRemindAppointmentAllocationRequest {
  // @Remark("待办ID")
  work_remind_id?: number;
  // @Remark("预约申请ID")
  apply_id?: number;
  // @Remark("分配用户ID")
  user_id?: number;
}

export class AppointmentHouseApplyLogDTO {
  // @Remark("操作人")
  operation_name?: string;
  // @Remark("操作时间")
  operation_time?: number;
  // @Remark("操作内容")
  content?: string;
}

export class AppointmentHouseApplyScoreDTO {
  // @Remark("服务编码")
  service_code?: string;
  // @Remark("服务名称文本")
  service_text?: string;
  // @Remark("评分")
  score?: number;
}

export class AppointmentHouseApplyDetailResponse {
  // @Remark("预约ID")
  id?: number;
  // @Remark("联系人")
  contacts_name?: string;
  // @Remark("电话")
  contacts_phone?: string;
  // @Remark("房源")
  room_group_name?: string;
  // @Remark("城市")
  city_name?: string;
  // @Remark("项目")
  project_name?: string;
  // @Remark("yyyy-MM-dd")
  appointment_time?: string;
  // @Remark("HH:mm:ss")
  start_time?: string;
  // @Remark("HH:mm:ss")
  end_time?: string;
  // @Remark("提交时间戳")
  create_time?: string;
  // @Remark("状态")
  service_status?: string;
  // @Remark("状态描述")
  status_text?: string;
  // @Remark("评价建议")
  user_comment?: string;
  // @Remark("带看管家")
  sys_user_name?: string;
  // @Remark("评分")
  score?: number;
  // @Remark("意向结果")
  user_quality_text?: string;
  // @Remark("意向价格")
  quality_room_price?: string;
  // @Remark("意向房间")
  quality_room?: string;

  log_list?: Array<AppointmentHouseApplyLogDTO>;

  score_list?: Array<AppointmentHouseApplyScoreDTO>;
}

export class AppointmentProjectTimesDTO extends ProjectAppointmentTimeDTO {
  residue_num?: number;

  selected = false;
}

export class AppointmentProjectTimesResponse {
  times?: Array<AppointmentProjectTimesDTO>;
}

export class AppointmentHouseApplyUpdateTimeRequest {
  // @Remark("看房预约ID")
  id?: number;
  // @Remark("yyyy-MM-dd")
  // @Pattern(regexp = RE.YYYY_MM_DD, message = "appointmentTime格式应为yyyy-MM-dd")
  appointment_time?: string;
  // @NotNull(message = "startTime不能为空")
  start_time?: string;
  // @NotNull(message = "endTime不能为空")
  end_time?: string;
  // @Remark("备注")
  remark?: string;
}

export class WorkRemindDisposeAppointmentRequest {
  // @NotNull(message = "workRemindId不能为空")
  work_remind_id?: number;
  // @NotNull(message = "applyId不能为空")
  apply_id?: number;
  // @NotNull(message = "userQuality不能为空")
  // @Remark("PURPOSE:有意向，NO_PURPOSE:无意向")
  user_quality?: string;
  // @Remark("意向价格（元）")
  quality_room_price?: string;
  // @Remark("意向房间")
  quality_room?: string;
  // @Remark("用户级别A,B,C")
  // @NotNull(message = "userLevel不能为空")
  user_level?: string;
  // @Remark("备注")
  remark?: string;
}

export class WorkRemindDisposeAppointmentResponse {
  comment_url?: string;
}

export class AppointmentProjectGroupDTO {
  group_id?: number;
  group_name?: string;
}

export class AppointmentProjectGroupListResponse {
  group_list?: Array<AppointmentProjectGroupDTO>;
}

export class AppointmentHouseApplySubmitRequest {
  // @Remark("房源ID")
  // @NotNull(message = "房源ID不能为空")
  room_group_id?: any = '';
  // @Remark("yyyy-MM-dd")
  // @Pattern(regexp = RE.YYYY_MM_DD, message = "appointmentTime格式应为yyyy-MM-dd")
  appointment_time?: string;
  // @NotNull(message = "开始时间不能为空")
  // @Remark("HH:mm:ss")
  start_time?: string;
  // @NotNull(message = "结束时间不能为空")
  // @Remark("HH:mm:ss")
  end_time?: string;
  // @Remark("联系人")
  // @NotNull(message = "联系人不能为空")
  contacts_name?: string;
  // @NotNull(message = "联系电话不能为空")
  // @Remark("联系电话")
  contacts_phone?: string;
  quality_room?: string;
  quality_room_price?: string;
  t?: string = '';
}

export class AppointmentHouseApplyListRequest extends PageReq {
  // @Remark("预约id")
  id?: number;
  // @Remark("预约手机号")
  contacts_phone?: string;
  // @Remark("是否已分配，true已分配，false未分配为空则为全部")
  allocation?: any;
}

export class AppointmentHouseApplyListDTO {

  id?: number;
  contacts_name?: string;
  // @Remark("yyyy-MM-dd")
  appointment_time?: string;
  // @Remark("HH:mm:ss")
  start_time?: string;
  // @Remark("HH:mm:ss")
  end_time?: string;
  city_name?: string;
  project_name?: string;
  group_name?: string;
  // @Remark("带看管家")
  sys_user_name?: string;
  create_time?: number;
  // @Remark("true:处理，false:查看")
  dispose?: boolean;
  // @Remark("dispose为true时返回")
  work_remind_id?: number;
}

export class AppointmentHouseApplyListResponse extends PageResp {
  list?: Array<AppointmentHouseApplyListDTO>;
}
