import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ModalDirective} from "ngx-bootstrap/modal";
import {LeaseService} from "../../../../service/lease/lease.service";
import {SubleaseSelectDTO} from "../../../../model/lease/lease.model";
import {CheckoutRoomViewDTO} from "../../../../model/signed/checkout.model";

declare var baidutongji: any;

@Component({
  selector: 'app-sublease-room-select',
  templateUrl: './sublease-room-select.component.html',
  styleUrls: ['./sublease-room-select.component.css']
})
export class SubleaseRoomSelectComponent implements OnInit {

  @Input() lease_id: number;

  ids: Array<CheckoutRoomViewDTO> = [];

  @ViewChild("checkoutRoomView", { static: true }) modal: ModalDirective;

  subleaseList?: Array<SubleaseSelectDTO>;

  constructor(private leaseService: LeaseService,
              private router: Router,
              private activatedRoute: ActivatedRoute,) {
  }

  ngOnInit() {
  }

  showModal() {

    this.leaseService.subleaseRoomList(this.lease_id).then(data => {

      if (data.sublease_select_dtolist && data.sublease_select_dtolist.length === 1) {
        this.ids = data.sublease_select_dtolist[0].checkout_room_view_dtolist;
        this.goSublease();
      } else {
        this.subleaseList = data.sublease_select_dtolist;
        this.modal.show();
      }
    });
  }

  removeOther() {
    this.subleaseList.forEach(dto => {
      dto.roomCheck = false;
    });
  }

  goSublease() {
    baidutongji("合同转租");
    this.router.navigate(['/system_manage/lease/sign/sublease'],
      {
        queryParams: {
          'lease_id': this.lease_id,
          subroom: this.ids.map(e => e.lease_room_id)
        }
      });
  }

}
