import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {BaseEnum} from '../../../model/modal/base-model';
import {
  AuditPersonDTO, ChangeRoomAvailableDaysRequest, CheckoutFinanceNotFinishDTO,
  LeaseContactRequest,
  LeaseExpenseInfoDTO,
  LeaseRoomInfoDTO, LeaseSubjectRequest, LeaseSubjectResponse,
  ModelCodeRequest,
  RenterInfo,
  RenterInfoWithIndex
} from '../../../model/lease/lease.model';
import {BatchRechargeRoomDTO, LeaseDetailResponse} from '../../../model/booking/booking-detail.model';
import {LogService} from '../../../service/log/log.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {BookingService} from '../../../service/booking/booking.service';
import {ConfirmPaymentRequest} from '../../../model/finance/bill.model';
import {MessageModalInfo} from '../../../model/modal/message-model';
import {ApplyQueryDesensitizationLogReq, WorkModel} from '../../../model/common/common.model';
import {OperationType} from '../../../common/constants';
import {WorkService} from '../../../service/common/work.service';
import {BillService} from '../../../service/finance/bill.service';
import {LeaseService} from '../../../service/lease/lease.service';
import {AuthService} from '../../../service/common/auth.service';
import {LesseeComponent} from '../../lease/lessee/lessee.component';
import {DialogComponent} from '../../shared/dialog/dialog.component';
import {CheckoutInventoryComponent} from '../../shared/inventory/checkoutInventory.component';
import {InventoryComponent} from '../../shared/inventory/inventory.component';
import {LeaseRoomBillComponent} from '../../finance/lease-room-bill/lease-room-bill.component';
import {HandleLogComponent} from '../handlelog/handlelog.component';
import {RenterService} from '../../../service/renter/renter.service';
import {CheckinInventoryComponent} from '../../shared/inventory/CheckinInventory.component';
import {RenterModalComponent} from '../../lease/renter-modal/renter-modal.component';
import {BaseInfoService} from '../../../service/common/base-info.service';
import {environment} from '../../../../environments/environment';
import {NewFileUploadComponent} from '../../shared/new-file-upload/new-file-upload.component';
import {CheckoutRoomSelectComponent} from '../checkout/checkout-room-select/checkout-room-select.component';
import {DomSanitizer} from '@angular/platform-browser';
import {deepCopy} from '../../../common/form.util';
import {CrRoomChooseComponent} from '../change-room/cr-room-choose/cr-room-choose.component';
import {CrRoomChooseSetComponent} from '../change-room/cr-room-choose-set/cr-room-choose-set.component';
import {ROOM_CHANGE_STEP, RoomChangeAvaliableReq, RoomChangeGotoStepReq} from '../../../model/lease/roomchange.model';
import {ChooseRoomModalComponent} from '../../lease/choose-room-modal/choose-room-modal.component';
import {AvaliableRoomInfo, TimeWithChoosedRoomList} from '../../../model/lease/room_choose.model';
import {CrChangeRoomRecordComponent} from '../change-room/cr-change-room-record/cr-change-room-record.component';
import {RoomChangeService} from '../../../service/lease/roomchange.service';
import {SubleaseRoomSelectComponent} from "./sublease-room-select/sublease-room-select.component";
import {CompanyPrepayComponent} from "../company-prepay/company-prepay.component";
import {SendCode} from "../../../model/user/user.model";
import {RoomGroupService} from '../../../service/roomgroup/roomgroup.service';
import {CrRenterChooseComponent} from "../change-room/cr-renter-choose/cr-renter-choose.component";
import {ModalDirective} from "ngx-bootstrap/modal";
import {HotelProject} from "../../../model/room/project.model";
import {removeFromArray} from "../../../common/array.util";
import {UserService} from "../../../service/user/user.service";
import {Decimal} from 'decimal.js';
import {RefundListService} from '../../../service/refund/refund.service';
import {SignedService} from "../../../service/signed/signed.service";
import {CheckoutConfirmRequest, CheckoutRoomViewRequest} from "../../../model/signed/checkout.model";
import {CommonService} from '../../../../app/service/common/common.service';
import {changeReasonValidator, gracePeriodDaysValidator, roomAvailableDaysValidator} from "../../../core/validator/validator.sign";
import {addDate} from "../../../common/date.util";
import {countDownTime} from "../../../common/common";
declare var baidutongji: any;

@Component({
  selector: 'app-pact',
  templateUrl: './pact.component.html',
  styleUrls: ['./pact.component.css']
})
export class PactComponent implements OnInit {

  //按钮权限对象
  btn_privileges: any = {
    'CANCLE_LEASE_BTN': false,
    'LEASE_CONTRACT_SIGN': false,
    'LEASE_CONTRACT_PRINT': false,
    'LEASE_GATHERING_BTN': false,
    'LEASE_ROOM_CHECKIN': false,
    'LEASE_RELET_BTN': false,
    'LEASE_CHANGE_BTN': false,
    'LEASE_ROOM_CHECKOUT': false,
    'LEASE_CONTRACT_VIEW': false,
    'LEASE_CONTRACT_UPLOAD': false,
    'LEASE_REFUND_BTN': false,
    'SUBLEASE_SIGN_BTN': false,
    'LEASE_FILE_DELETE': false,
    'LEASE_FILE_VIEW': false,
    'BATCH_IMPORT_RENTER': false,
    'CHECKIN_GOODS_SIGN': false,
    'CHECKOUT_GOODS_SIGN': false,
    'RENTER_SETTING_BTN': false,
    'LEASE_BILL_SETTING': false,
    'BILL_RECONCILIATION': false,
    'BILL_ADVANCE': false,
    'CLIENT_COMPANY_USER_CHANGE': false,
    'OPEN_COMPANY_PREPAY_ACCOUNT': false,
    'COMPANY_PREPAY_FIVE': false,
    'COMPANY_PREPAY_ARREARS': false,
    'COMPANY_PREPAY_SETTING': false,
    'COMPANY_PREPAY_RECHARGE': false,
    'COMPANY_PREPAY_RECORD': false,
    'BATCH_RECHARGE_PREPAY': false,
    'QUERY_USER_PRIVAYE': false,
    'APPLY_QUERY_USER_PRIVAYE': false,
    'ELE_DIFF_BTN': false
  };

  @ViewChild('renter_modal', {static: true}) renterModal: RenterModalComponent;
  // @ViewChild('contract_print') contractPrintComponent: PrintPreviewComponent;
  @ViewChild('contract_file_upload') contractFileUploadComponent: NewFileUploadComponent;
  @ViewChild('changeTypeModel', {static: true}) changeTypeModel: ModalDirective;
  @ViewChild(LesseeComponent) lesseeComponent: LesseeComponent;
  @ViewChild(DialogComponent, {static: true}) dialogComponent: DialogComponent;
  @ViewChild(InventoryComponent, {static: true}) meterInventory: InventoryComponent;
  @ViewChild(CheckoutRoomSelectComponent, {static: true}) checkoutRoomSelectComponent: CheckoutRoomSelectComponent;
  @ViewChild(HandleLogComponent, {static: true}) handleLogComponent: HandleLogComponent;
  @ViewChild(LeaseRoomBillComponent) leaseRoomBillComponent: LeaseRoomBillComponent;
  @ViewChild(CheckinInventoryComponent, {static: true}) meterCheckinInventory: CheckinInventoryComponent;
  @ViewChild(CheckoutInventoryComponent) meterCheckoutInventory: CheckoutInventoryComponent;
  @ViewChild(CrRoomChooseComponent, {static: true}) crRoomChooseComponent: CrRoomChooseComponent;
  @ViewChild(CrRoomChooseSetComponent, {static: true}) crRoomChooseSetComponent: CrRoomChooseSetComponent;
  @ViewChild(ChooseRoomModalComponent, {static: true}) chooseRoomModalComponent: ChooseRoomModalComponent;
  @ViewChild(CrChangeRoomRecordComponent, {static: true}) crChangeRoomRecordComponent: CrChangeRoomRecordComponent;
  @ViewChild(SubleaseRoomSelectComponent, {static: true}) subleaseRoomSelectComponent: SubleaseRoomSelectComponent;
  @ViewChild(CompanyPrepayComponent) companyPrepayComponent: CompanyPrepayComponent;
  @ViewChild(CrRenterChooseComponent, {static: true}) chooseRenterModalComponent: CrRenterChooseComponent;
  @ViewChild('rechargeRoomsModel', {static: true}) rechargeRoomsModel: ModalDirective;
  @ViewChild('showRefundModal', {static: true}) showRefundModal: ModalDirective;
  @ViewChild('showMsgModal', {static: true}) showMsgModal: ModalDirective;

  @ViewChild('modalChange', {static: true}) modalChange: ModalDirective;
  @ViewChild('modalChangeLeaseSubject', {static: true}) modalChangeLeaseSubject: ModalDirective;
  @ViewChild('modalChangeRoomAvailableTime', {static: true}) modalChangeRoomAvailableTime: ModalDirective;
  @ViewChild('approvModal', {static: true}) approvModal: ModalDirective;
  @ViewChild('confirm_lease_modal') confirm_lease_modal: ModalDirective;
  @ViewChild('contractConfirm', {static: true}) contractConfirm: ModalDirective;

  @ViewChild('cancelPopup', {static: true}) cancelPopup: ModalDirective;
  //收款页面租约ID
  @Input()
  paymentleaseId: number;
  extAttachmentSize: number = 0;
  leaseDetailResponse: LeaseDetailResponse;
  //租约房间信息
  leaseRoomInfoDTO: LeaseRoomInfoDTO;

  attachedList: string[];

  addAttachedList: string[] = [];
  uploadName: string = '添加附件';
  //错误提示
  errorContent: string;

  //关闭成功页面
  close: number;

  //是否换房中
  changeing: Boolean;
  //收款确认请求参数
  confirmPaymentRequest = new ConfirmPaymentRequest();

  detail: boolean;

  edit_lease_room_id: number;

  // 支付周期
  pay_cycle_list: BaseEnum[];
  // 租约类型
  lease_type_list: BaseEnum[];
  // 收费类型
  gather_type_list: BaseEnum[];
  // 费用类型枚举
  expense_type_list: BaseEnum[];
  // 租客状态
  renter_status_list: BaseEnum[];
  // 房间租约状态
  lease_room_status_list: BaseEnum[];

  // 合同上传url
  contract_upload_url: string;

  pdf_url: string;

  // 换房相关 2019-01-04 lbb
  // 房间租约id
  change_lease_room_id: number;
  change_room_id: number;
  // 换房日期
  change_time: Date;
  // 换取房间信息
  new_room_info: AvaliableRoomInfo;
  // private confirm_lease_modal: any;
  timer_second: number;
  //换房类型
  changeRoomType: string;
  start_time: Date;
  choosed_room_id_list: number[];

  confirm_type: string;

  //已签署合同企业联系人变更
  contact_phone: string;
  contact_name: string;
  contact_old_phone: string;
  ccontact_old_name: string;
  contact_check_code: string;
  contact_lease_id: number;
  contact_audit_person_id: any;
  audit_person_list: Array<AuditPersonDTO>;
  checkcod_timer_second: number;
  sendCode: SendCode;
  code_formatError;
  name_formatError;
  phone_formatError;
  importRoomResidentsUrl = `${environment.apiHost}manage/lease/importRoomResidents`;
  importRoomResidentsHeader = {
    Authorization: this.authService.getAuthToken()
  };
  residentsFileList;
  importRoomResidentsData = {
    leaseId: 1
  };

  //
  tabIndex = 1;
  room_status = '全部状态';
  roomStatus = [
    {id: '全部状态', value: '全部状态'},
    {id: '未入住', value: '未入住'},
    {id: '已入住', value: '已入住'},
    {id: '已退房', value: '已退房'},
    {id: '换房', value: '换房'}
  ];
  changeLogsNum = 0;

  remove_image: string[] = [];
  add_images: string[] = [];
  add_image: string[] = [];

  //项目性质
  need_room_goods_list: number;
  //项目配置
  projectConfig: HotelProject;

  reUploadFlag = false;
  applyQueryed: boolean = false;
  //存放可批量充值的房间列表信息
  rechargeRooms: Array<BatchRechargeRoomDTO> = [];
  //选择的租约房间Ids
  checkedLeaseRoomIds = [];
  //批量充值的金额
  batchRechargeAmount: number;
  //充值总金额
  batchRechargeTotal = 0;
  // 电费补差退款-类型
  refundType: string = 'TRANSFER';
  refundInfoDetalis: any = {
    ele_refund_dtos: [],
    period_list: [],
  };
  refundNoInfo: any = {
    refund_name: '',
    open_bank: '',
    bank_no: ''
  };
  saveLoading: boolean = false;
  isRefundDiffShowBtn: any = false;
  management_fee_gather_desc: string;
  signatureList: Array<CheckoutFinanceNotFinishDTO> = [];//签字提示

  // lease change
  changeType;
  approvNum;

  leaseSubjectResponse: LeaseSubjectResponse;
  fgLeaseSubjectChange: UntypedFormGroup;
  changeLeaseSubjectReq: LeaseSubjectRequest = new LeaseSubjectRequest();
  changeRoomAvailableRequest: ChangeRoomAvailableDaysRequest = new ChangeRoomAvailableDaysRequest();
  lscImgRequired = false;
  lscErr = false;
  lscSubmit = false;
  deposit_need_pay_show;
  isFirstRentBillPay: boolean;
  remainingDays: number;
  // 房间首期房费截止时间
  payEndDate: Date;
  roomAvailableChangeGroup: UntypedFormGroup;
  tempPayEndDate: Date;
  showPayEndDate: Date;
  hasRoomAvailableTime: boolean;

  initImg = [];

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private logService: LogService,
    private workService: WorkService,
    private authService: AuthService,
    private billService: BillService,
    private leaseService: LeaseService,
    private roomChangeService: RoomChangeService,
    private renterService: RenterService,
    private bookingService: BookingService,
    private baseInfoService: BaseInfoService,
    private roomGroupService: RoomGroupService,
    private refundListService: RefundListService,
    private userService: UserService,
    private signedService: SignedService,
    private commonService: CommonService,
  ) {
  }

  ngOnInit() {
    this.authService.getOperatePrivilege(this.btn_privileges);

    // 获取项目配置参数
    this.projectConfig = this.authService.getProjectInfo();

    baidutongji("租约详情");

    this.baseInfoService.getPayCycleList().then(data => {
      this.pay_cycle_list = data.base_enum_dto_list;
    });
    this.leaseService.getBillTypeWithGather().then(data => {
      this.gather_type_list = data.base_enum_dto_list;
    });
    this.baseInfoService.getRenterStatusList().then(data => {
      this.renter_status_list = data.base_enum_dto_list;
    });
    this.lease_type_list = this.baseInfoService.getLeaseTypeList();
    this.expense_type_list = this.baseInfoService.getExpenseTypeList();
    this.lease_room_status_list = this.baseInfoService.getLeaseRoomStatusList();
    this.userService.userProjectInfo(this.authService.getProjectId()).then(x => {
      this.need_room_goods_list = x.project.need_room_goods_list;
    });

    this.activatedRoute.params.subscribe((params: Params) => {

      this.paymentleaseId = params['leaseId'];
      // 合同上传url
      this.contract_upload_url = `${environment.apiHost}image/uploadContract?leaseId=` + this.paymentleaseId;

      // 获取租约详情
      this.getLeaseDetail();

      // 获取合同pdf
      this.leaseService.getContractUrl({lease_id: this.paymentleaseId}).then(data => {
        if (data && data.image_url) {
          this.pdf_url = data.image_url;
        }
      });

      this.workService.event_bus.subscribe((data: WorkModel) => {
        if (data && data.type === this.workService.type.SCHEDULE_TASK) {
          //刷新账单，和操作日志
          this.refushBill();
        }
      });
      this.getRefundDiffShowBtn();
    });


    this.fgLeaseSubjectChange = this.fb.group({
      change_reason: []
    });
    this.roomAvailableChangeGroup = this.fb.group({
      room_available_days:[0],
      change_reason: []
    });
  }

  getRefundDiffShowBtn() {
    this.refundListService.refundDiffShowBtn({lease_id: this.paymentleaseId}).then(res => {
      this.isRefundDiffShowBtn = res.show_btn;
    });
  }

  /**
   * @Description:获取租约详情
   * @Param:
   * @return:
   * @Author: zhoujiahao
   * @Date: 2018-07-11  16:18
   */
  getLeaseDetail() {
    this.bookingService.leaseDetail({lease_id: this.paymentleaseId}).then(res => {
      if(res.checkout_finance_not_finish_dtos){
        res.checkout_finance_not_finish_dtos.forEach(item => {
          if (item.checkout_confirm_end_time) {
            let isTime = countDownTime(item.checkout_confirm_end_time);
            item.checkout_confirm_end_time_new = isTime;
          }
        });
      }

      this.signatureList = res.checkout_finance_not_finish_dtos;
      this.leaseDetailResponse = res;
      this.extAttachmentSize = res.ext_attachment_num;
      this.handleLogComponent.getHandleLog(this.leaseDetailResponse.lease_id);
      //如果租约为已取消则不可以操作附件
      if (this.leaseDetailResponse.lease_status === 'CANCEL') {
        this.btn_privileges.LEASE_CONTRACT_UPLOAD = false;
      }

      //如果审核状态为待审核 则不能 上传，
      if (this.leaseDetailResponse.audit_status === 'WAITING_AUDIT') {
        this.btn_privileges.LEASE_CONTRACT_UPLOAD = false;
      }

      if (this.leaseDetailResponse.attached_list) {
        this.attachedList = this.leaseDetailResponse.attached_list.map(r => r.image_url);
      }

      this.leaseRoomInfoDTO = this.leaseDetailResponse.lease_room_info_dtos[0];
      this.deposit_need_pay_show = this.leaseDetailResponse.deposit_need_pay_show;

      //转换房间状态
      for (let i = 0; i < this.leaseDetailResponse.lease_room_info_dtos.length; i++) {

      }
      // 查询首期房费应付信息
      if (this.leaseDetailResponse.lease_step !== 'INTERNAL_LEASE' &&
        (this.leaseDetailResponse.lease_status === 'EFFECTIVE' || this.leaseDetailResponse.lease_status === 'EXPIRED')) {
        this.leaseService.checkFirstRentBillPay(this.paymentleaseId).then(data => {
          this.isFirstRentBillPay = data.is_first_rent_bill_pay;
          this.remainingDays = data.remaining_days;
          this.payEndDate = data.end_date;
        });
      }
    }).catch(() => {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.TAB_CLOSE_SELF));
    });
  }

  changeStatus(item: any) {
    //换房
    if (item.is_change_room) {
      //有效
      if (item.lease_room_status === 'EFFECTIVE') {
        return '换房';
      } else if (item.lease_room_status === 'CHECKOUT') {
        //退房
        return '换房';
      } else {
        return '--';
      }
    } else {
      //非换房
      //有效
      if (item.lease_room_status === 'EFFECTIVE') {
        //判断入住人是否存在
        if (item.renter_list) {
          return '已入住';
        } else {
          return '未入住';
        }
      } else if (item.lease_room_status === 'CHECKOUT') {
        //退房
        return '已退房';
      } else {
        return '--';
      }
    }
  }

  refush(leaseId?: number) {
    this.paymentleaseId = leaseId;
    this.ngOnInit();
  }

  /**
   * @Description:   签约主体：企业，个人
   * @Author: zhoujiahao
   * @Date: 2018-07-11  10:04
   */
  leaseSubjectInfo(person?: any, company?: any) {
    if (this.leaseDetailResponse && this.leaseDetailResponse.renter_type === 'PERSON') {
      person.show();
    } else {
      company.show();
    }
  }

  fileChange(imageList?: any) {
    //附件原数量
    let originAttachedNum = this.attachedList.length;
    let url;
    let operateType = OperationType.ADD;
    if (originAttachedNum > imageList.length) {
      //图片删除
      this.attachedList.forEach(e => {
        if (!imageList.includes(e)) {
          url = e;
        }
      });
      operateType = OperationType.DELETE;
    } else if (originAttachedNum < imageList.length) {
      //图片增加
      imageList.forEach(e => {
        if (!this.attachedList.includes(e)) {
          url = e;
        }
      });
    }

    if (originAttachedNum !== imageList.length) {
      this.attachedList = imageList;
      const reqDate = {operation_type: operateType, url: url, lease_id: this.leaseDetailResponse.lease_id};
      this.bookingService.attachedManage(reqDate).then(res => {
        //刷新操作日志
        this.handleLogComponent.getHandleLog(this.leaseDetailResponse.lease_id);
      }).catch(e => {
        this.logService.error('PactComponent:附件操作失败');
      });
    }

  }

  /**
   * @Description:  弹窗弹出  并且初始化所有弹框
   * @Param: leaseId 租约ID
   * @Param: contract_num 合同编号
   * @Author: zhoujiahao
   * @Date: 2018-07-13  16:39
   */
  showDialog() {
    this.dialogComponent.openGatherDialog(this.leaseDetailResponse.lease_id);
  }

  /**
   * 弹窗弹出“账单退款弹窗”  并且初始化所有弹框
   */
  showRefundDialog() {
    baidutongji("退款");
    this.dialogComponent.openRefundDialog(this.leaseDetailResponse.lease_id);
  }

  /**
   * @Description:  打开房间租金信息
   * @Author: zhoujiahao
   * @Date: 2018-07-16  19:40
   */
  openRoomDetail(leaseRoomId?: any) {
    this.dialogComponent.leaseRoomId = leaseRoomId;
    this.dialogComponent.openRoomDetetail();
  }

  roomDetailShow(modal: any) {
    const reqDate = {lease_id: this.paymentleaseId};
    this.bookingService.leaseDetail(reqDate).then(res => {
      this.leaseDetailResponse.lease_room_info_dtos = res.lease_room_info_dtos;
      modal.show();
    });
  }

  /**
   * @Description:  取消合同
   * @Author: zhoujiahao
   * @Date: 2018-07-19  10:38
   */
  cancleLease(leaseId?: any) {
    baidutongji("取消合同");
    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.type = 2;
    // messageModalInfo.operationMessage = '取消的合同不能再恢复，且会释放房态，是否继续';
    messageModalInfo.html = `
    <h4 class="text-center">取消的合同不能再恢复，不能再申请退款，且会释放房态，是否继续？</h4>
    <p class="text-center">提示：预定金、房费、押金如需退款务必在取消合同前申请！</p>
    `;
    messageModalInfo.cancel = true;
    messageModalInfo.callback.subscribe(() => {
      const req = {lease_id: leaseId};
      this.bookingService.bookingCancle(req).then(res => {
        this.ngOnInit();
      }).catch(error => {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, JSON.stringify(error)));
        this.logService.error('PactComponent:合同单取消失败');
      });
    });
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
  }

  //增加入住人
  openRenters() {
    this.lesseeComponent.emergencys = this.leaseDetailResponse.reserve_contact_info_dto.emergency_contacts;
    this.lesseeComponent.subjectType = this.leaseDetailResponse.lease_subject_info_dto.subject_type;
    this.lesseeComponent.openRenters();
  }

  //退房
  checkOut() {
    let checkoutRoomViewRequest = new CheckoutRoomViewRequest();

    if (this.changeing) {
      checkoutRoomViewRequest.lease_id = this.leaseDetailResponse?.lease_id;
      this.signedService.checkoutRoomChooseView(checkoutRoomViewRequest).then(data => {

        if (!data.checkout_room_list) {
          this.workService.showErrorModal('全部房间换房进行中或换房审核中,无法进行退房');
          return;
        } else {
          this.checkoutRoomSelectComponent.showModal();
        }

      });
    } else {
      this.checkoutRoomSelectComponent.showModal();
    }
  }

  checkOutRoom() {

    let checkoutRoomViewRequest = new CheckoutRoomViewRequest();
    const leaseId = this.leaseDetailResponse?.lease_id;
    checkoutRoomViewRequest.lease_id = this.leaseDetailResponse?.lease_id;
    if (this.changeing) {//是否换房

      this.signedService.checkoutRoomChooseViewNew(checkoutRoomViewRequest).then(data => {

        if (!data.checkout_room_list || data.checkout_room_list.length === 0) {
          this.workService.showErrorModal('全部房间换房进行中或换房审核中,无法进行退房');
          return;
        } else {
          this.router.navigate(['/system_manage/signed/checkoutroomcontract'], {queryParams: {'leaseId': leaseId}});
        }
      });
    } else {
      this.router.navigate(['/system_manage/signed/checkoutroomcontract'], {queryParams: {'leaseId': leaseId}});
      // this.workService.showErrorModal('全部房间换房进行中或换房审核中,无法进行退房');
    }


  }

  /**
   * @Description:   支付成功之后刷新账单，刷新 刷新操作日志,刷新详情
   * @Author: zhoujiahao
   * @Date: 2018-07-24  21:38
   */
  refushBill() {
    //this.leaseRoomBillComponent.serach_bill();
    //this.handleLogComponent.getHandleLog(this.leaseDetailResponse.lease_id);
    this.getLeaseDetail();
  }

  // 入住人弹窗显示
  renterModalShow(h: number) {
    let lease_room_info = this.leaseDetailResponse.lease_room_info_dtos[h];
    this.edit_lease_room_id = lease_room_info.lease_room_id;

    let to_modal_renter_list: any[] = lease_room_info.renter_list && lease_room_info.renter_list.length > 0 ?
      deepCopy(lease_room_info.renter_list) : [];

    // 去除入住人中非待入住及入住的人
    if (to_modal_renter_list.length > 0) {
      to_modal_renter_list = to_modal_renter_list.filter(renter_info =>
        renter_info.renter_status === 'WAITING_CHECKIN' || renter_info.renter_status === 'CHECKIN'
      );
    }

    // 床位数
    let bed_num = lease_room_info.bed_num;

    this.renterModal.index = h;
    this.renterModal.subject_type = this.leaseDetailResponse.lease_subject_info_dto.subject_type;
    if (to_modal_renter_list.length === 0) {
      this.renterModal.renter_list = [];
      for (let i = 0; i < bed_num; i++) {
        let renter_info = new RenterInfo();
        // renter_info.sex = 'MALE';
        renter_info.register_vip = false;
        this.renterModal.renter_list.push(renter_info);
      }
    } else {
      to_modal_renter_list.forEach(renter_info => {
        if (renter_info.name) {
          renter_info.disable = true;
        }
      });

      // 填充入住人
      this.renterModal.renter_list = to_modal_renter_list;

      let renter_num = to_modal_renter_list.length;
      let avaliable_bed_num = bed_num - renter_num;
      if (avaliable_bed_num > 0) {
        for (let i = 0; i < avaliable_bed_num; i++) {
          let renter_info = new RenterInfo();
          // renter_info.sex = 'MALE';
          renter_info.register_vip = false;
          this.renterModal.renter_list.push(renter_info);
        }
      }
    }
    this.renterModal.check_sex = lease_room_info.check_sex;
    this.renterModal.resetRenterList(false);

    this.renterModal.wentDutch = this.leaseDetailResponse.went_dutch;
    this.renterModal.show();
  }

  // 入住人确认
  confirmRenter(renter_with_index: RenterInfoWithIndex) {
    /*let renter_list = renter_with_index.renter_list.filter(function (renter) {
      return !renter.disable;
    });*/

    this.renterService.batchOperate({
      lease_room_id: this.edit_lease_room_id,
      renter_list: renter_with_index.renter_list
    }).then(() => {
      this.bookingService.leaseDetail({lease_id: this.paymentleaseId}).then(res => {
        this.leaseDetailResponse.lease_room_info_dtos = res.lease_room_info_dtos;
      });
    });
  }

  // 合同上传成功
  contractUpload() {
    this.contractConfirm.show();

    // 判断是否是重新上传
    if (this.reUploadFlag) {
      // 获取租约详情
      this.getLeaseDetail();
    } else {
      // 合同生效
      this.leaseService.leaseContractConfirm(this.paymentleaseId).then(() => {
        this.getLeaseDetail();
        //刷新账单
        this.refushBill();
      });
    }
  }

  // 合同预览
  contractPreview() {
    this.router.navigateByUrl('/system_manage/signed/contract/preview/' + this.paymentleaseId);
  }

  // 合同打印
  contractPrint() {
    baidutongji("打印合同");
    // this.contractPrintComponent.src = `${environment.apiHost}file/preview?bizcode=CONTRACT&leaseId=` + this.paymentleaseId;
    // this.contractPrintComponent.show();

    let url = `${environment.apiHost}file/preview?bizcode=CONTRACT&leaseId=` + this.paymentleaseId;
    this.workService.event_bus.emit(new WorkModel(this.workService.type.PDF_PRINT, url));
  }

  // 换房选择
  changeRoom() {
    baidutongji("换房");
    if (this.leaseDetailResponse.children_lease_id) {
      this.bookingService.leaseDetail({lease_id: this.leaseDetailResponse.children_lease_id}).then(res => {
        if (res.lease_type === 'RELET') {
          this.workService.showErrorModal('该合同有关联续租合同无法进行换房操作！');
          return;
        }
        this.roomChangeService.getAvaliableRoomChangeLeaseRoomList(new RoomChangeAvaliableReq(this.paymentleaseId)).then(data => {
          if (data.lease_room_info_list.length === 0) {
            this.workService.showErrorModal('无可换房房间(所有房间都已经退房/签署退房单/正处于换房操作中)');
          } else if (data.lease_room_info_list.length === 1) {
            this.gotoChooseChangeType(data.lease_room_info_list[0].lease_room_id, new Date(), []);
          } else {
            this.crRoomChooseComponent.show(data.lease_room_info_list);
          }
        });
      }).catch(() => {
        this.roomChangeService.getAvaliableRoomChangeLeaseRoomList(new RoomChangeAvaliableReq(this.paymentleaseId)).then(data => {
          if (data.lease_room_info_list.length === 0) {
            this.workService.showErrorModal('无可换房房间(所有房间都已经退房/签署退房单/正处于换房操作中)');
          } else if (data.lease_room_info_list.length === 1) {
            this.gotoChooseChangeType(data.lease_room_info_list[0].lease_room_id, new Date(), []);
          } else {
            this.crRoomChooseComponent.show(data.lease_room_info_list);
          }
        });
      });
    } else {
      this.roomChangeService.getAvaliableRoomChangeLeaseRoomList(new RoomChangeAvaliableReq(this.paymentleaseId)).then(data => {
        if (data.lease_room_info_list.length === 0) {
          this.workService.showErrorModal('无可换房房间(所有房间都已经退房/签署退房单/正处于换房操作中)');
        } else if (data.lease_room_info_list.length === 1) {
          this.gotoChooseChangeType(data.lease_room_info_list[0].lease_room_id, new Date(), []);
        } else {
          this.crRoomChooseComponent.show(data.lease_room_info_list);
        }
      });
    }
  }

  // 跳转到选择换房类型弹窗
  gotoChooseChangeType(lease_room_id: number, start_time?: Date, choosed_room_id_list?: number[]) {
    if (!start_time) {
      start_time = new Date();
    }
    this.start_time = start_time;
    if (!choosed_room_id_list) {
      choosed_room_id_list = [];
    }
    this.choosed_room_id_list = choosed_room_id_list;
    this.change_lease_room_id = lease_room_id;

    this.change_room_id = this.leaseDetailResponse.lease_room_info_dtos.filter(e => e.lease_room_id === lease_room_id)[0].room_id;

    this.changeTypeModel.show();

    // this.chooseRoomModalComponent.resetModal(start_time,
    //   new Date(this.leaseDetailResponse.reserve_contact_info_dto.end_time), choosed_room_id_list, null);
    // this.chooseRoomModalComponent.show();
  }

  // 跳转到换房设置
  gotoRoomChangeSet(time_with_choosed_room_list: TimeWithChoosedRoomList) {
    this.change_time = time_with_choosed_room_list.start_time;
    this.new_room_info = time_with_choosed_room_list.choosed_room_list[0];

    this.crRoomChooseSetComponent.resetModal(this.paymentleaseId, this.change_lease_room_id, this.new_room_info, this.change_time,
      new Date(this.leaseDetailResponse.reserve_contact_info_dto.end_time),this.leaseDetailResponse.generate_bill_type);
    // this.crRoomChooseSetComponent.show();
  }

  // 返回或跳转到换房确认
  gotoBackOrConfirm(back_or_confirm: any) {
    if (back_or_confirm.type === 'back') {
      this.gotoChooseChangeType(this.change_lease_room_id, back_or_confirm.start_time, back_or_confirm.new_room_id);
    } else if (back_or_confirm.type === 'next') {
      let needGoodList = this.authService.getProjectInfo().need_room_goods_list;
      //如果项目需要签署物品交接单
      if (needGoodList === 1) {
        this.router.navigateByUrl('/system_manage/signed/change/room/confirm/' + back_or_confirm.record_id);
      } else {
        //如果项目不需要签署物品交接单，直接模拟换房确认的下一步动作，跳过这一步
        this.roomChangeService.gotoStep(new RoomChangeGotoStepReq(back_or_confirm.record_id, ROOM_CHANGE_STEP.RECORD)).then(data => {
          this.router.navigateByUrl('/system_manage/signed/change/room/bill/details/' + back_or_confirm.record_id);
        });
      }
    } else if (back_or_confirm.type === 'refresh') {
      this.roomChangeRecordAndLogReload();
    }
  }

  // 刷新换房记录、日志记录
  roomChangeRecordAndLogReload() {
    this.crChangeRoomRecordComponent.getRoomChangeList(0);
    this.handleLogComponent.getHandleLog(this.paymentleaseId);
    this.leaseDetailResponse.lease_logs_num++;
  }

  // 获取其他费用详情
  getExpenseInfo(expense_info_list: LeaseExpenseInfoDTO[]): string {
    let result = '';

    if (!expense_info_list || expense_info_list.length === 0) {
      result = '无';
    } else {
      if (this.leaseDetailResponse.lease_property_dto?.type === 'WUYE_PAY') {
        expense_info_list.forEach((expense_info, index) => {
          if (expense_info.expense_name === '物业费' && !this.management_fee_gather_desc) {
            switch (expense_info.gather_type) {
              case "EVERY_YEAR_CHARGE":
                this.management_fee_gather_desc = "每年一次";
                break;
              case "EVERY_MONTH_CHARGE":
                this.management_fee_gather_desc = "每月一次";
                break;
              case "ONE_TIME_CHARGE":
                this.management_fee_gather_desc = "一次性";
                break;
            }
          }
          if (expense_info.expense_name !== '物业费') {
            result += (expense_info.expense_name ? expense_info.expense_name : '') + ' ' + expense_info.expense_value + '元';
            if (expense_info.gather_type === 'EVERY_YEAR_CHARGE') {
              result += '/年';
            } else if (expense_info.gather_type === 'EVERY_MONTH_CHARGE') {
              result += '/月';
            }
            if (index !== expense_info_list.length - 1 && expense_info_list[index + 1].expense_name !== '物业费') {
              result += ', ';
            }
          }
        });
      } else {
        expense_info_list.forEach((expense_info, index) => {
          result += (expense_info.expense_name ? expense_info.expense_name : '') + ' ' + expense_info.expense_value + '元';
          if (expense_info.gather_type === 'EVERY_YEAR_CHARGE') {
            result += '/年';
          } else if (expense_info.gather_type === 'EVERY_MONTH_CHARGE') {
            result += '/月';
          }
          if (index !== expense_info_list.length - 1) {
            result += ', ';
          }
        });
      }
    }
    return result;
  }

  goRelet() {
    baidutongji("合同续约");
    // 是否可以续租
    this.billService.leaseIsRenewal({
      lease_id: this.paymentleaseId,
    }).then(data => {
      if (data && data.renewal) {
        this.router.navigate(['/system_manage/lease/relet/relet'], {queryParams: {'lease_id': this.paymentleaseId}});
      } else {
        this.workService.showErrorModal(data.message);
      }
    });
  }

  subleaseModal() {
    this.subleaseRoomSelectComponent.showModal();
  }

  // goSublease() {
  //   baidutongji("合同转租");
  //   let can_relet = this.leaseDetailResponse.lease_room_info_dtos
  //       .filter(lease_room_info => lease_room_info.lease_room_status === 'EFFECTIVE' && lease_room_info.asset_confirm !== 'CONFIRMEDOUT')
  //       .length !== 0;
  //   if (!can_relet) {
  //     this.workService.showErrorModal('所有房间都已经退房/签署退房单, 无法转租');
  //     return;
  //   } else {
  //     this.router.navigate(['/system_manage/lease/sign/sublease'], {queryParams: {'lease_id': this.paymentleaseId}});
  //   }
  // }

  addFileChange(img: string[], type: number) {

    if (!this.attachedList) {
      this.attachedList = [];
    }

    if (img.length === 0) {
      let list = this.attachedList;
      this.attachedList = img;
      if (list.length > 0) {
        this.leaseService.delLeaseAttachment({
          attachment_list: list,
          lease_type: 'LEASE',
          lease_id: this.paymentleaseId
        });
      }
    } else {

      this.add_image = img.filter(v => !this.attachedList.includes(v));

      this.remove_image = this.attachedList.filter(v => !img.includes(v));

      this.attachedList = img;

      if (this.remove_image.length > 0) {
        this.leaseService.delLeaseAttachment({
          attachment_list: this.remove_image,
          lease_type: 'LEASE',
          lease_id: this.paymentleaseId
        });
      }
      if (this.add_image.length > 0) {
        this.leaseService.leaseAttachment({
          attachment_list: this.add_image,
          lease_type: 'LEASE',
          lease_id: this.paymentleaseId
        });
      }
    }

  }

  // initSomething(confirm_lease_modal: any) {
  //   this.confirm_lease_modal = confirm_lease_modal;
  // }

  closeModal() {
    this.confirm_lease_modal.hide();
  }

  // 在签字和上传合同之前确认弹窗
  confirmRemind(confirm_type: string) {
    this.confirm_type = confirm_type;
    $("#ensure_btn").removeClass("btn-primary").addClass("btn-secondary").attr("disabled", "disabled");
    this.confirm_lease_modal.show();
    this.timer_second = 10;
    let th = this;
    let handler = setInterval(function () {
      th.timer_second--;
      if (th.timer_second <= 0) {
        $("#ensure_btn").removeAttr("disabled").removeClass("btn-secondary").addClass("btn-primary");
        clearInterval(handler);
      }
    }, 1000);
  }

  // 点击确定按钮后弹框
  ensureAfter() {
    if (this.confirm_type === "SIGN") {
      this.confirm_lease_modal.hide();
      $("#sign_lease_btn").click();
    } else if (this.confirm_type === "UPLOAD") {
      this.confirm_lease_modal.hide();
      $("app-new-file-upload .ant-upload button").click();
    }
  }

  // 触发重新上传合同按钮点击事件
  clickReUploadBtn() {
    this.reUploadFlag = true;
    this.contract_upload_url = `${environment.apiHost}image/uploadContract?leaseId=` + this.paymentleaseId + `&reUpload=true`;
    $("app-new-file-upload .ant-upload button").click();
  }

  /**
   * 已签署合同变更企业联系人信息
   * @param leaseId
   * @param cname
   * @param cphone
   * @param changeContactsModel
   */
  changeContactsModelShow(leaseId: number, cname: string, cphone: string, changeContactsModel: any) {
    this.name_formatError = true;
    this.phone_formatError = true;
    this.contact_old_phone = cphone;
    this.ccontact_old_name = cname;
    this.contact_lease_id = leaseId;
    changeContactsModel.show();
  }

  changeContactsHide(changeContactsModel: any) {
    changeContactsModel.hide();
    this.contact_name = "";
    this.contact_phone = "";
  }

  auditPersonHide(auditPersonModel: any) {
    auditPersonModel.hide();
    this.contact_name = "";
    this.contact_phone = "";
    this.contact_check_code = "";
  }

  contactOperationalTipsHide(contactOperationalTipsModel: any) {
    contactOperationalTipsModel.hide();
    this.contact_name = "";
    this.contact_phone = "";
    this.contact_check_code = "";
    //刷新租约详情
    this.getLeaseDetail();
  }

  auditPersonShow(auditPersonModel: any, changeContactsModel: any) {
    this.code_formatError = true;
    if (!!this.contact_name && !!this.contact_phone && this.name_formatError && this.phone_formatError) {
      //获取审核人列表
      this.leaseService.findAuditPersons(1).then(data => {
        this.contact_audit_person_id = data.audit_person_dtos[0].id + ","
          + data.audit_person_dtos[0].user_name + "," + data.audit_person_dtos[0].user_phone;
        this.audit_person_list = data.audit_person_dtos;
      });
      changeContactsModel.hide();
      auditPersonModel.show();
    }
  }

  //验证码发送
  getCheckCode() {
    const result = this.contact_audit_person_id.split(',');
    const req = new ModelCodeRequest();
    req.user_id = result[0];
    req.mobile = result[2];
    req.code_type = 24;
    this.leaseService.getMobileCode(req).then(data => {
      $("#check_code_btn").removeClass("btn-primary").addClass("btn-secondary").attr("disabled", "disabled");
      $("#check_code_vlaue").text("重新获取");
      this.checkcod_timer_second = 60;
      let th = this;
      let handler = setInterval(function () {
        th.checkcod_timer_second--;
        if (th.checkcod_timer_second <= 0) {
          $("#check_code_vlaue").text("点击获取");
          $("#check_code_btn").removeAttr("disabled").removeClass("btn-secondary").addClass("btn-primary");
          clearInterval(handler);
        }
      }, 1000);
    });
  }

  changeLeaseContacts(auditPersonModel: any, contactOperationalTips: any) {
    if (!!this.contact_check_code && !!this.code_formatError) {
      const result = this.contact_audit_person_id.split(',');
      const req = new LeaseContactRequest();
      req.lease_id = this.contact_lease_id;
      req.contact_name = this.contact_name;
      req.contact_phone = this.contact_phone;
      req.audit_person_id = result[0];
      req.old_contact_name = this.ccontact_old_name;
      req.old_contact_phone = this.contact_old_phone;
      req.audit_person_phone = result[2];
      req.model_code = this.contact_check_code;
      this.leaseService.changeContacts(req).then(data => {
        if (data.result === 'SUCCESS') {
          auditPersonModel.hide();
          contactOperationalTips.show();
        } else {
          this.workService.showErrorModal(data.result);
        }
      });
    }

  }

  name_format() {
    if (this.contact_name.length < 2 || this.contact_name.length > 20) {
      this.name_formatError = false;
    } else {
      this.name_formatError = true;
    }
  }

  phone_format() {
    let TEL_REG = /^[-|0-9|\s|*]{8,20}$/;
    if (!TEL_REG.test(this.contact_phone)) {
      this.phone_formatError = false;
    } else {
      this.phone_formatError = true;
    }
  }

  code_format() {
    if (!this.contact_check_code || this.contact_check_code.length === 0) {
      return;
    }
    if (this.contact_check_code.length === 6 && (/^\d{6}$/.test(this.contact_check_code))) {
      this.code_formatError = true;
    } else {
      this.code_formatError = false;
    }
  }


  residentTemplateShow(leaseId: number, residentTemplateModel: any) {
    this.importRoomResidentsData.leaseId = leaseId;
    residentTemplateModel.show();
  }

  /**
   * @Description:  下载入住人导入模板ecxel
   * @Author: zandezhang
   * @Date: 2019-8-21  20:24
   */
  downResidentTemplate() {
    window.open("/assets/file/房间入住人模板.xlsx");
    /*let downloadUrl = this.roomGroupService.downResidentTemplate() +
      '?Authorization=' + this.authService.getAuthToken()+'&id='+this.roomGroupId;*/
    /*let downloadUrl = this.roomGroupService.downResidentTemplate() +
      '?Authorization=' + this.authService.getAuthToken();
    window.open(downloadUrl, '_self');*/
  }

  importRoomResidents($event, residentTemplateModel: any) {
    if ($event.type === "success") {
      let response = $event.file.response;
      if (response.status_code === "SUCCESS") {
        if (response.biz_response.result === "SUCCESS") {
          this.getLeaseDetail();
          residentTemplateModel.hide();
        } else {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, response.biz_response.result));
        }
        this.residentsFileList = [];
      } else {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, response.message));
      }

    }

  }

  chooseTab(index: number) {
    if (index === 1) {
      baidutongji("租约详情");
    } else if (index === 2) {
      baidutongji("账单及流水");
    } else if (index === 3) {
      baidutongji("企业预付费账户");
    } else if (index === 4) {
      baidutongji("换房记录");
    } else if (index === 5) {
      baidutongji("操作日志");
    }
    this.tabIndex = index;
  }

  setChangLogsNum(num: number) {
    this.changeLogsNum = num;
  }

  setIsChangeing(change: Boolean) {
    this.changeing = change;
  }

  turnToViewCheckoutPdf(lease_id: any, room_id: any, lease_room_id: any) {
    this.router
      .navigate(["/system_manage/signed/inventoryview"],
        {
          queryParams: {
            lease_id: lease_id,
            room_id: room_id,
            lease_room_id: lease_room_id
          }
        });
  }

  turnToCheckoutPdf(lease_id: any, room_id: any, lease_room_id: any) {
    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.type = 2;
    messageModalInfo.title = "操作提示";
    messageModalInfo.cancel = true;
    messageModalInfo.html = `
       <div class="text-center">确认签署<span class="font16 text-red">【退房】</span>物品交接单？</div>
      `;
    messageModalInfo.callback.subscribe(data => {
      this.router
        .navigate(["/system_manage/signed/inventorylist"],
          {
            queryParams: {
              lease_id: lease_id,
              room_id: room_id,
              lease_room_id: lease_room_id
            }
          });
    });
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));

  }

  //改变预付费-文案
  prepayChange() {
    this.leaseDetailResponse.open_company_prepay_account = true;
  }

  canNext2(): boolean {
    return !this.changeRoomType;
  }

  next2() {
    this.changeTypeModel.hide();
    //合同外换房，则不用选择人员，直接跳房间选择
    if (this.changeRoomType === "OUT") {
      this.chooseRoomModalComponent.resetModal(this.start_time,
        new Date(this.leaseDetailResponse.reserve_contact_info_dto.end_time), this.choosed_room_id_list, null,
        this.change_lease_room_id,
        this.leaseDetailResponse.data_type,
        false
        );
      this.chooseRoomModalComponent.show();
    } else {
      //合同内换房/无合同换房，先选择人员，再跳房间选择
      //弹出选择人员弹窗
      this.chooseRenterModalComponent.chooseRenters();
    }
  }

  gotoChooseNewRoom(renterIdArray: Array<number>) {
    let projectNature = this.authService.getProjectInfo().need_lease_contract;
    //合同内换房
    if (projectNature === 1) {
      this.chooseRoomModalComponent.resetParam(true, this.change_lease_room_id, this.leaseDetailResponse.lease_id, renterIdArray, this.leaseDetailResponse.data_type);
    } else {
      //无合同换房
      this.chooseRoomModalComponent.resetParam(true, this.change_lease_room_id, null, renterIdArray, this.leaseDetailResponse.data_type);
    }
    this.chooseRoomModalComponent.show();
  }

  checkReletTime() {
    let now = new Date();
    if (now > this.leaseDetailResponse.reserve_contact_info_dto.start_time) {
      return true;
    } else {
      return false;
    }
  }

  //打开批量充值水电的弹窗
  openRechargeModel() {
    this.leaseService.getBatchRechargeRooms({lease_id: this.leaseDetailResponse.lease_id}).then(data => {
      if (data && data.room_list) {
        this.rechargeRooms = data.room_list;
        // 全选
        this.checkedLeaseRoomIds = this.rechargeRooms.map(e => e.lease_room_id);
        this.rechargeRoomsModel.show();
      } else {
        this.workService.showErrorModal('没有可批量充值的房间');
      }
    });
  }

  checkAll(event?: any) {
    if (event.target.checked) {
      // 全选
      this.checkedLeaseRoomIds = this.rechargeRooms.map(e => e.lease_room_id);
    } else {
      //取消全选
      this.checkedLeaseRoomIds = [];
    }
    this.calTotalAmount();
  }

  checkCurrentRoom(event?: any, leaseRoomId?: any) {
    // 勾选
    if (event.target.checked) {
      this.checkedLeaseRoomIds.push(leaseRoomId);
    } else {
      // 取消勾选
      this.checkedLeaseRoomIds = removeFromArray(this.checkedLeaseRoomIds, leaseRoomId);
    }
    this.calTotalAmount();
  }

  //批量修改金额
  resetAmount() {
    if (!this.checkedLeaseRoomIds || this.checkedLeaseRoomIds.length === 0) {
      this.workService.showErrorModal('请先勾选需要充值的房间');
      return;
    }
    this.checkedLeaseRoomIds.forEach(e => $("#" + e).val(this.batchRechargeAmount));
    this.calTotalAmount();
  }

  calTotalAmount() {
    this.batchRechargeTotal = 0;
    if (this.checkedLeaseRoomIds && this.checkedLeaseRoomIds.length > 0) {
      this.checkedLeaseRoomIds.forEach(e => {
        let val = $("#" + e).val();
        if (val) {
          this.batchRechargeTotal = +new Decimal(this.batchRechargeTotal).add(new Decimal((val.toString()))).toString();
        }
      });
    }
  }

  changAmount() {
    this.calTotalAmount();
  }

  cancelRecharge() {
    this.checkedLeaseRoomIds = [];
    //批量充值的金额
    this.batchRechargeAmount = null;
    //充值总金额
    this.batchRechargeTotal = 0;
    this.rechargeRoomsModel.hide();
  }

  format_input_num(event) {
    let obj = event.currentTarget;
    // 清除"数字"和"."以外的字符
    obj.value = obj.value.replace(/[^\d.]/g, "");
    // 验证第一个字符是数字
    obj.value = obj.value.replace(/^\./g, "");
    // 只保留第一个, 清除多余的
    obj.value = obj.value.replace(/\.{2,}/g, ".");
    obj.value = obj.value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    // 只能输入两个小数
    obj.value = obj.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');

    this.calTotalAmount();
  }

  turnToRechargeModle() {
    //组装数据
    let checkRechargeRooms: Array<BatchRechargeRoomDTO> = [];
    if (this.checkedLeaseRoomIds && this.checkedLeaseRoomIds.length > 0) {
      this.checkedLeaseRoomIds.forEach(e => {
        let val = $("#" + e).val();
        if (val && new Decimal((val.toString())).greaterThan(0)) {
          let item = this.rechargeRooms.filter(i => i.lease_room_id === e)[0];
          item.recharge_total = val.toString();
          checkRechargeRooms.push(item);
        }
      });
    }
    //打开收银台充值弹窗
    this.dialogComponent.openRechargePrepayModel(this.leaseDetailResponse, checkRechargeRooms, this.batchRechargeTotal);
  }

  showElectricFeeRefund() {
    this.refundListService.getDiffRefundInfo({lease_id: this.paymentleaseId,}).then(res => {
      Object.assign(this.refundInfoDetalis, res);
      this.showRefundModal.show();
    });
    this.refundNoInfo = {
      open_bank: '',
      refund_name: '',
      bank_no: ''
    };
  }

  submitRefund() {
    if (this.saveLoading) {
      return;
    }
    let req = {
      lease_id: this.paymentleaseId,
      checkout_bill_pay_type_enum: this.refundType
    };
    if (this.refundType === 'TRANSFER') {
      if (!this.refundNoInfo.refund_name) {
        this.workService.showErrorModal('请填写收款人姓名');
        return;
      }
      if (!this.refundNoInfo.open_bank) {
        this.workService.showErrorModal('请填写开户行');
        return;
      }
      if (!this.refundNoInfo.bank_no) {
        this.workService.showErrorModal('请填写银行账号');
        return;
      }
      req['refund_name'] = this.refundNoInfo.refund_name;
      req['open_bank'] = this.refundNoInfo.open_bank;
      req['bank_no'] = this.refundNoInfo.bank_no;
    }
    this.saveLoading = true;
    this.refundListService.confirmDiffRefundInfo(req).then(res => {
      setTimeout(() => {
        this.saveLoading = false;
      }, 500);
      this.getRefundDiffShowBtn();
      this.showRefundModal.hide();
      this.showMsgModal.show();
    }).catch(err => {
      this.saveLoading = false;
    });
  }

  refundDownload() {
    let baseurl = `${environment.apiHost}ele/diff/refundInfo/download?lease_id=${this.paymentleaseId}`;
    window.open(baseurl + '&Authorization=' + this.authService.getAuthToken(), "_blank");
  }

  refushLogs() {
    this.handleLogComponent.getHandleLog(this.leaseDetailResponse.lease_id);
  }



  clickHasShowConceal(index: string) {
    let hasShowConceal = $("#show-html-" + index).attr('data-hasShowConceal');
    if (hasShowConceal === 'false') {
      let req = new ApplyQueryDesensitizationLogReq();
      if (index === '1' || index === '4') {
        req = new ApplyQueryDesensitizationLogReq('CONTRACT_DETAILS', this.paymentleaseId + "", 'CONTACT_INFORMATION');
      }
      if (index === '2' || index === '5') {
        req = new ApplyQueryDesensitizationLogReq('CONTRACT_DETAILS', this.paymentleaseId + "", 'EMERGENCY_CONTACT');
      }
      if (index === '3') {
        req = new ApplyQueryDesensitizationLogReq('CONTRACT_DETAILS', this.paymentleaseId + "", 'IDENTIFICATION_NUMBER');
      }
      this.commonService.recordingLog(req);
      if (index === '2' || index === '5') {
        this.applyQueryed = true;
      } else {
        let showHtml = $("#hide-html-" + index).attr('value');
        $("#show-html-" + index).html(showHtml);
      }
      $("#show-html-" + index).attr('data-hasShowConceal', 'true');
    }
  }

  showLeaseChangeModal() {
    this.changeType = null;
    this.modalChange.show();
  }

  showLeaseChangeModalAndHide() {
    this.contractConfirm.hide();
    this.changeType = 'roomAvailableTime';
    this.modalChange.show();
  }

  leaseChange() {

    if (this.changeType) {

      switch (this.changeType) {
        case 'leaseSubject':
          this.changeSubject();
          break;
        case 'roomAvailableTime':
          this.changeRoomAvailableTime();
          break;
      }

      this.modalChange.hide();
    }
  }

  changeSubject() {

    // clearReq
    this.changeLeaseSubjectReq = new LeaseSubjectRequest();

    this.leaseService.getLeaseSubjectDetail(this.leaseDetailResponse.lease_id).then(data => {
      this.leaseSubjectResponse = data;


      if (this.leaseDetailResponse.lease_step === 'INTERNAL_LEASE') {

        if (this.leaseSubjectResponse.subject_type === 'PERSON') {

          this.fgLeaseSubjectChange = this.fb.group({
            name: [, [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
            tel: [, [Validators.required, Validators.pattern(/^[-|0-9|\s|*]{8,20}$/)]],
            cret_type: ['ID_CD'],
            cret_num: [, [Validators.required, Validators.pattern(/^[A-Za-z0-9]{6,20}$/)]],
            change_reason: [, [Validators.required]]
          });

          this.fgLeaseSubjectChange.patchValue({
            name: this.leaseSubjectResponse.name,
            tel: this.leaseSubjectResponse.tel,
            cret_type: this.leaseSubjectResponse.cret_type,
            cret_num: this.leaseSubjectResponse.cret_num,
            change_reason: this.leaseSubjectResponse.change_reason
          });


          if (this.leaseSubjectResponse.emergency_contacts) {
            this.leaseSubjectResponse.emergency_contacts.forEach(item => {
              (this.fgLeaseSubjectChange.get("emergency_contacts") as UntypedFormArray).push(this.fb.group({
                contact_name: [item.contact_name, [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
                contact_phone: [item.contact_phone, [Validators.required, Validators.pattern(/^[-|0-9|\s|*]{8,20}$/)]]
              }));
            });
          }

        } else {

          this.fgLeaseSubjectChange = this.fb.group({
            company_name: [, [Validators.required, Validators.maxLength(50)]],
            name: [, [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
            tel: [, [Validators.required, Validators.pattern(/^[-|0-9|\s|*]{8,20}$/)]],
            change_reason: [, [Validators.required]]
          });

          this.fgLeaseSubjectChange.patchValue({
            company_name: this.leaseSubjectResponse.company_name,
            name: this.leaseSubjectResponse.name,
            tel: this.leaseSubjectResponse.tel,
            cret_num: this.leaseSubjectResponse.cret_num,
            change_reason: this.leaseSubjectResponse.change_reason
          });

        }
      } else {
        if (this.leaseSubjectResponse.subject_type === 'PERSON') {

          this.fgLeaseSubjectChange = this.fb.group({
            name: [, [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
            tel: [, [Validators.required, Validators.pattern(/^[-|0-9|\s|*]{8,20}$/)]],
            cret_type: ['ID_CD'],
            cret_num: [, [Validators.required, Validators.pattern(/^[A-Za-z0-9]{6,20}$/)]],
            emergency_contacts: this.fb.array([]),
            change_reason: [, [Validators.required]]
          });

          this.fgLeaseSubjectChange.patchValue({
            name: this.leaseSubjectResponse.name,
            tel: this.leaseSubjectResponse.tel,
            cret_type: this.leaseSubjectResponse.cret_type,
            cret_num: this.leaseSubjectResponse.cret_num,
            change_reason: this.leaseSubjectResponse.change_reason
          });

          this.changeLSCCheck();

          if (this.leaseSubjectResponse.emergency_contacts) {
            this.leaseSubjectResponse.emergency_contacts.forEach(item => {
              (this.fgLeaseSubjectChange.get("emergency_contacts") as UntypedFormArray).push(this.fb.group({
                contact_name: [item.contact_name, [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
                contact_phone: [item.contact_phone, [Validators.required, Validators.pattern(/^[-|0-9|\s|*]{8,20}$/)]]
              }));
            });
          }

        } else {

          this.fgLeaseSubjectChange = this.fb.group({
            company_name: [, [Validators.required, Validators.maxLength(50)]],
            name: [, [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
            tel: [, [Validators.required, Validators.pattern(/^[-|0-9|\s|*]{8,20}$/)]],
            emergency_contacts: this.fb.array([]),
            change_reason: [, [Validators.required]]
          });

          this.fgLeaseSubjectChange.patchValue({
            company_name: this.leaseSubjectResponse.company_name,
            name: this.leaseSubjectResponse.name,
            tel: this.leaseSubjectResponse.tel,
            cret_num: this.leaseSubjectResponse.cret_num,
            change_reason: this.leaseSubjectResponse.change_reason
          });

          if (this.leaseSubjectResponse.emergency_contacts) {
            this.leaseSubjectResponse.emergency_contacts.forEach(item => {
              this.emergency_contacts.push(this.fb.group({
                contact_name: [item.contact_name, [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
                contact_phone: [item.contact_phone, [Validators.required, Validators.pattern(/^[-|0-9|\s|*]{8,20}$/)]]
              }));
            });
          }
        }
      }


    });

    this.initImg = [];
    this.modalChangeLeaseSubject.show();
  }

  changeRoomAvailableTime() {
    this.leaseService.queryRoomAvailableTime(this.leaseDetailResponse.lease_id).then(data => {
      this.roomAvailableChangeGroup = this.fb.group({
        room_available_days: [0, [Validators.required, roomAvailableDaysValidator]],
        change_reason: [, [Validators.required, changeReasonValidator]]
      });
      this.showPayEndDate = new Date(data.pay_end_date);
      this.tempPayEndDate = addDate(new Date(data.pay_end_date),data.white_list_days);
      this.roomAvailableChangeGroup.patchValue({
        room_available_days: data.white_list_days
      });
    });
    this.initImg = [];
    this.modalChangeRoomAvailableTime.show();
  }

  changeRoomAvailableTimeSubmit() {
    if (this.roomAvailableChangeGroup.valid) {
      let form = this.roomAvailableChangeGroup.value;
      Object.assign(this.changeRoomAvailableRequest, form);
      this.changeRoomAvailableRequest.lease_id = this.leaseDetailResponse.lease_id;
      if (this.changeRoomAvailableRequest.room_available_days == null) {
        this.changeRoomAvailableRequest.room_available_days = 0;
      }
      this.changeRoomAvailableRequest.pay_end_date = this.showPayEndDate;
      this.leaseService.changeRoomAvailableTimeSubmit(this.changeRoomAvailableRequest).then(data => {
        this.modalChangeRoomAvailableTime.hide();
        this.approvNum = data.approv_num;
        this.approvModal.show();
      });
    }
  }

  changeTempPayEndDate() {
    this.tempPayEndDate = addDate(this.showPayEndDate,this.roomAvailableChangeGroup.get('room_available_days').value);
  }

  get emergency_contacts(): UntypedFormArray {
    return this.fgLeaseSubjectChange.get("emergency_contacts") as UntypedFormArray;
  }

  leaseSubjectFileChange($event: Array<string>) {
    this.changeLeaseSubjectReq.image_url_list = $event;
  }

  addLSEmergency() {
    if (this.emergency_contacts.controls.length < 3) {
      this.emergency_contacts.push(this.fb.group({
        contact_name: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
        contact_phone: [null, [Validators.required, Validators.pattern(/^[-|0-9|\s|*]{8,20}$/)]]
      }));
    }
  }

  delLSEmergency(i: number) {
    this.emergency_contacts.removeAt(i);
  }

  leaseChangeSubmit() {

    this.lscSubmit = true;

    if (this.fgLeaseSubjectChange.valid && !this.lscErr) {
      let form = this.fgLeaseSubjectChange.value;

      Object.assign(this.changeLeaseSubjectReq, form);
      this.changeLeaseSubjectReq.lease_id = this.leaseDetailResponse.lease_id;
      this.changeLeaseSubjectReq.subject_type = this.leaseSubjectResponse.subject_type;

      // this.lscImgRequired = false;
      // if (this.changeLeaseSubjectReq.name !== this.leaseSubjectResponse.name
      //   || this.changeLeaseSubjectReq.cret_num !== this.leaseSubjectResponse.cret_num
      // ) {
      //   if (!this.changeLeaseSubjectReq.image_url_list || this.changeLeaseSubjectReq.image_url_list.length === 0) {
      //     this.lscImgRequired = true;
      //     return;
      //   }
      // }

      this.leaseService.changeLeaseSubjectDetail(this.changeLeaseSubjectReq).then(data => {
        this.lscSubmit = false;
        this.modalChangeLeaseSubject.hide();

        this.approvNum = data.approv_num;
        this.approvModal.show();
      });

    }
  }

  changeLSCCheck() {
    let ID_CARD_REG = /^[A-Za-z0-9]{18}$/;
    let OTHER_REG = /^[A-Za-z0-9]{6,20}$/;

    if (this.leaseSubjectResponse.subject_type === 'PERSON') {
      let fcCretNum = this.fgLeaseSubjectChange.get('cret_num');
      let fcCretType = this.fgLeaseSubjectChange.get('cret_type');
      let value = fcCretType.value;
      if (value === 'ID_CD') {
        fcCretNum.clearValidators();
        fcCretNum.setValidators([Validators.required, Validators.pattern(ID_CARD_REG)]);
      } else {
        fcCretNum.clearValidators();
        fcCretNum.setValidators([Validators.required, Validators.pattern(OTHER_REG)]);
      }
    }
  }

  changeEmergency() {
    this.lscErr = false;

    let name = this.fgLeaseSubjectChange.get('name').value;
    let tel = this.fgLeaseSubjectChange.get('tel').value;

    if (name || tel) {
      this.emergency_contacts.controls.forEach(control => {
        let contact_name = control.get('contact_name').value;
        let contact_phone = control.get('contact_phone').value;
        if (
          (contact_name && contact_name === name)
          || (contact_phone && contact_phone === tel)
        ) {
          this.lscErr = true;
        }
      });
    }
  }

  signatureCancel(id: number) {

    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.type = 2;
    messageModalInfo.html = `
        <div class="mt-2 text-center">取消后客户将无法在APP端操作签字确认</div>
        <div class="mt-2 text-center">是否确认取消？</div>
    `;
    messageModalInfo.cancel = true;
    messageModalInfo.callback.subscribe(() => {
      let req = new CheckoutConfirmRequest();
      req.checkout_id = id;
      this.signedService.checkoutFinanceRoomCancel(req).then(() => {
        this.getLeaseDetail();
      });
    });
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
  }

  checkoutFinanceRoomList() {
    if (this.leaseDetailResponse.check_out_finance) {
      this.router.navigate(['./../../checkout/finance/roomselect', this.leaseDetailResponse.lease_id], {
        relativeTo: this.activatedRoute
      });
    } else {
      this.router.navigate(['/system_manage/checkoutrenter/checkout/view'], {
        queryParams: {lease_id:this.leaseDetailResponse.lease_id}
      });
    }
  }

  getCheckoutBill(item: CheckoutFinanceNotFinishDTO) {
    this.dialogComponent.gatherCheckOutAmountWithPrepay(
      item.lease_id,
      item.room_ids,
      item.lease_room_ids,
      item.bill_ids,
      item.pay_total,
      'CHECKOUT',
      item.contract_num,
      item.checkout_record_id
    );
  }

  checkoutRecordPreview(checkout_record_id: number) {
    this.router.navigate(['../../checkoutrecord/preview', checkout_record_id],{relativeTo:this.activatedRoute});
  }

  setFiles1($event: Array<string>) {
    this.changeLeaseSubjectReq.imgs = $event;
  }

  setFiles2($event: Array<string>) {
    this.changeRoomAvailableRequest.imgs = $event;
  }


  showRenterCheckoutRecord() {
    this.router.navigate(['../../renter/checkout/record/list', this.paymentleaseId],{relativeTo:this.activatedRoute});
  }
}
