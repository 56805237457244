import { Pipe, PipeTransform } from '@angular/core';

/**
 * @Description: 有效无效管道
 * @Author: 陆炳彬
 * @Date: 2018/3/29 上午10:25
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/3/29 上午10:25
 */
@Pipe({
  name: 'commonPipe'
})
export class CommonPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const status = {
        EFFECTIVE: '有效',
        INVALID: '无效',
      }
    ;
    return status[value];
  }
}
