import { RequestService } from "../request.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../common/auth.service";
import { environment } from '../../../environments/environment';

@Injectable()
export class DepartmentService {
  //获取所有部门树
  private DEPART_TREE = `${environment.apiHost}depart/departs`;
  //部门列表查询
  private DEPART_LIST = `${environment.apiHost}depart/list`;

  private DEPART_MANAGE = `${environment.apiHost}depart/manage`;
  //通过部门名称获取部门列表信息
  private DEPART_QUERY_BY_NAME = `${environment.apiHost}depart/query/list`;

  /***************UPDATE**************************/
    //部门设置首页
  private DEPT_INDEX = `${environment.apiHost}depart/queryDepartsByCoId`;
  //获取公司列表
  private COMPANY_LIST = `${environment.apiHost}sysCompany/getCompanyList`;
  //部门管理：新增、修改、删除
  private DEPT_MANAGE = `${environment.apiHost}depart/departManage`;
  //根据部门ID查找上级部门信息
  private PARENT_DEPT = `${environment.apiHost}depart/queryParentDept`;
  //判断是否能删除
  private CAN_DELETE = `${environment.apiHost}depart/canDelete`;
  //获取该公司下所有部门列表
  private ALL_DEPT = `${environment.apiHost}depart/queryAllDepartsByCoId`;

  //获取该公司下所有部门列表
  private RANK_LIST = `${environment.apiHost}depart/rank/list`;

  //公司管理
  private COM_MANAGE = `${environment.apiHost}sysCompany/manage`;
  //判断公司是否能删除
  private COM_CAN_DELETE = `${environment.apiHost}sysCompany/can/delete`;

  constructor(private auth: AuthService, private requestService: RequestService, private http: HttpClient) {

  }

  /**
   *员工设置 通过公司ID获取所有部门信息
   */
  queryAllDepartsByCoId(reqData?: any): Promise<any> {
    return this.requestService.authGet(this.ALL_DEPT, reqData);
  }

  /**
   *部门设置 判断是否能删除
   */
  canDelete(reqData?: any): Promise<any> {
    return this.requestService.authGet(this.CAN_DELETE, reqData);
  }

  /**
   *部门设置 通过部门ID获取部门信息
   */
  queryParentDept(reqData?: any): Promise<any> {
    return this.requestService.authGet(this.PARENT_DEPT, reqData);
  }

  /**
   * 部门管理：新增、修改、删除
   */
  deptManage(reqData?: any): Promise<any> {
    return this.requestService.authPost(this.DEPT_MANAGE, reqData);
  }

  /**
   *部门设置 首页：通过公司ID获取一级部门列表和登录用户的公司信息
   */
  queryDepartsByCoId(reqData?: any): Promise<any> {
    return this.requestService.authGet(this.DEPT_INDEX, reqData);
  }

  /**
   *  获得部门树
   */
  queryDeparts(): Promise<any> {
    return this.requestService.authGet(this.DEPART_TREE, null);
  }

  getCompanyList(): Promise<any> {
    return this.requestService.authGet(this.COMPANY_LIST, null);
  }

  /**
   *  根据上级部门ID获取子部门ID
   */
  queryDepartList(departListReqData?: any): Promise<any> {
    return this.requestService.authGet(this.DEPART_LIST, departListReqData);
  }

  /**
   * @Description:   增加或者修改部门
   * @Author: zhoujiahao
   * @Date: 2018-03-22  16:54
   */
  departManage(reqData?: any): Promise<any> {
    return this.requestService.authPost(this.DEPART_MANAGE, reqData);
  }

  queryDeprtListByName(reqData?: any): Promise<any> {
    return this.requestService.authGet(this.DEPART_QUERY_BY_NAME, reqData);
  }

  getRankList(reqData?: any) {
    return this.requestService.authGet(this.RANK_LIST, reqData);
  }

  /**
   * 公司管理：新增、修改、删除
   */
  companyManage(reqData?: any): Promise<any> {
    return this.requestService.authPost(this.COM_MANAGE, reqData);
  }

  /**
   * 判断公司是否能删除
   */
  canDeleteCompany(reqData?: any): Promise<any> {
    return this.requestService.authPost(this.COM_CAN_DELETE, reqData);
  }

}
