/**
 * 公司(列表)
 */
import { PageReq } from '../page/page';

export class CompanyInfo {
  company_id: number;
  company_name: string;
  simply_name: string;
  legal_person: string;
  code: string;
  province_id: number;
  city_id: number;
  address: string;
  tel: string;
  email: string;
  fax: string;
  contacts: number;
  contacts_tel: string;
  contacts_phone: string;
  contacts_email: string;
  contacts_fax: string;
  contacts_address: string;
  company_status: string;
  zip: string;
  recharge: string;
  consume: string;
  balance: string;
  city_name: string;
  image_url_list: string[];
}

/**
 * 公司详细
 */
export class CompanyDetail {
  company_id: number;
  company_name: string;
  simply_name: string;
  legal_person: string;
  code: string;
  province_id: number;
  city_id: number;
  address: string;
  tel: string;
  email: string;
  fax: string;
  contacts: string;
  contacts_tel: string;
  contacts_phone: string;
  contacts_email: string;
  contacts_address: string;
  contacts_fax: string;
  bank_name: string;
  invoice_title: string;
  invoice_type: string;
  taxpayer_num: string;
  new_taxpayer_num: string;
  company_address: string;
  card_number: string;
  zip: string;
  image_url_list: string[];
}

export class CompanyListReqParam extends PageReq {
  //企业名称
  company_name?: string;
  //是否需要账户余额
  need_balance?: boolean;
  //是否需要删除的企业
  need_delete?: boolean;
}

export class SimpleCompanyInfo {
  company_id: number;
  company_name: string;
}

export class SystemConfigDTO {
  sys_company_id?: number;
  add_dept?: boolean;
  add_project?: boolean;
  add_user?: boolean;
  add_room?: boolean;
}
