<div class="login" *ngIf="system_type !== 'LEQIDA'">

  <div class="wrapper wrapper-login wrapper-login-full p-0">
    <div
      class="login-aside w-50 d-flex flex-column align-items-center justify-content-center text-center bg-secondary-gradient">

      <img class="left-bg show2" src="../../../../assets/img/v3/shang_lxz.png" >
      <img class="left-bg-bottom show2" src="../../../../assets/img/v3/xia_2020.png" >
      <img class="left-bg show1" src="../../../../assets/img/v3/shang_lxz_phone.png" >

      <!--<h1 class="title fw-bold text-white mb-3">欢迎使用OpenPMS</h1>-->
      <!--<p class="subtitle text-white op-7">公寓管理好帮手</p>-->

      <!--<span id="siteseal"></span>-->

    </div>
    <div class="login-aside w-50 d-flex align-items-center justify-content-center bg-white">
      <div class="container container-login container-transparent">
        <h3 class="text-center text-black-50">重置密码</h3>
        <div class="login-form col-12" *ngIf="!success">

          <div class="row">
            <div class="w-100">
              <div class="form-group">
                <label>用户名</label>
                  <input class="form-control"
                         type="number"
                         [(ngModel)]="sendCode.mobile"
                         (input)="check()"
                         maxlength="11" tabindex="1"
                         placeholder="用户名"
                  >
              </div>
            </div>

            <div class="col-12">
              <div class="row">
                <div class="w-75">
                  <div class="form-group">
                    <label>图形验证码</label>
                    <input class="form-control"
                           [(ngModel)]="sendCode.imgCode"
                           (input)="check(false)"
                           placeholder="请输入图形验证码" tabindex="2"
                    >
                  </div>
                </div>

                <div class="w-25 text-right">
                  <img class="text-center mess-img" (click)="imgCodeClick()" src="{{imgUrl}}">
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="row">
                <div class="w-75">
                  <div class="form-group">
                    <label>短信验证码</label>
                    <input class="form-control"
                           [(ngModel)]="resetPassword.smsCode"
                           tabindex="3" placeholder="短信验证码"
                    >
                  </div>
                </div>

                <div class="w-25 text-right">
                  <label class="w-100">&nbsp;</label>

                  <button class="btn btn-info"
                          *ngIf="buttonShow"
                          type="button"
                          (click)="sendCodeRequest()"
                  >获取验证码
                  </button>
                  <button class="btn btn-info"
                          *ngIf="!buttonShow"
                          readonly
                          type="button"
                          [disabled]="!buttonShow"
                  >{{clock}}s后获取
                  </button>

                </div>
              </div>
            </div>

            <div class="w-100">
              <div class="form-group">
                <label>新密码</label>
                <input class="form-control"
                       type="password"
                       [(ngModel)]="resetPassword.password"
                       tabindex="4"
                       placeholder="8-18位，含数字、大写字母、小写字母、符号中的3种及以上"
                >
              </div>
            </div>

            <div class="w-100">
                <div class="form-group">
                  <label>确认密码</label>
                  <input class="form-control"
                         type="password"
                         [(ngModel)]="resetPassword.newPassword"
                         tabindex="5"
                         placeholder="确认密码"
                  >
                </div>
            </div>

            <div class="w-100">
              <div class="text-danger">
                {{errorContent}}
              </div>
            </div>

            <div class="">
              <button class="btn btn-link text-info" [routerLink]="['../login']" [queryParams]="{system_type: system_type}" >返回登录</button>
            </div>
          </div>

          <div class="col-12 text-center mt-2">
                <button class="btn btn-info w-75 rounded-pill login"
                        (click)="resetPasswordFunction()"
                        tabindex="6"
                >确认重置密码
                </button>
          </div>

        </div>
        <div class="col-12" *ngIf="success">

          <div class="col-12 my-5 text-center">
            密码已重置，请重新登录！
          </div>

          <div class="col-12 text-center mt-2">
            <button class="btn btn-info w-75 rounded-pill login"
                    [routerLink]="['../login']" [queryParams]="{system_type: system_type}"
            >去登录
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- leqida  -->
<div class="login lqd-login" *ngIf="system_type === 'LEQIDA'">

  <div class="wrapper wrapper-login wrapper-login-full p-0 m-0">
    <div class="login-aside w-50 d-flex flex-column align-items-center justify-content-center text-center">

      <img class="left-bg-lqd show2" src="../../../../assets/img/v3/lqd/llgg.png">
      <div class="text-left text-black mt-2 desc show2">
        <p>即刻享用</p>
        <p>全国超过5万个床位供您挑选；</p>
        <p>住宿成本分析、员工住宿情况随时掌控；</p>
        <p>智能的住宿管理软件，提升自有宿舍满意度；</p>
        <p>专业的人力资源服务，快速满足您的各类招聘需求；</p>
        <p>专属定制的员工餐饮和班车服务。</p>
      </div>

      <div class="text-center mt-0 mt-sm-5 show1">
        <!--        <img class="login-logo" src="../../../../assets/img/v3/leqida_logo@2x.png" alt="">-->
      </div>


    </div>

    <div class="login-aside d-flex align-items-center justify-content-center w-50">
      <div class="overflow-hidden boxs1 rounded container container-login container-transparent bg-white">

        <div class="text-center" *ngIf="!success">

          <div class="text-center mt-5 show1 d-inline">
            <img class="login-logo pb-3" src="../../../../assets/img/v3/leqida_logo@2x.png" alt="">
          </div>

          <div class="">
            <div class="login-form col-12" >
              <div class="row">
                <div class="w-100">
                  <div class="form-group">
                    <input class="form-control"
                           type="text"
                           (input)="check(false)"
                           autofocus
                           maxlength="11"
                           tabindex="1"
                           placeholder="请输入账号"
                           [(ngModel)]="sendCode.mobile"
                    >
                  </div>
                </div>

                <div class="w-100">
                  <div class="form-group">
                    <div class="input-group mb-3">
                      <input class="form-control mt-imgCode"
                             [(ngModel)]="sendCode.imgCode"
                             (input)="check(false)"
                             placeholder="请输入图形验证码" tabindex="2"
                      >
                      <div class="input-group-prepend ml-2">
                        <img class="text-center mess-img m-0" (click)="imgCodeClick()" src="{{imgUrl}}">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="w-100">
                  <div class="form-group">
                    <div class="input-group mb-3">
                      <input class="form-control"
                             type="text"
                             tabindex="3"
                             placeholder="短信验证码"
                             [(ngModel)]="resetPassword.smsCode"
                      >
                      <div class="input-group-prepend ml-2">
                        <button class="btn btn-info m-0 border-r4" type="button" *ngIf="buttonShow" (click)="sendCodeRequest()">获取验证码</button>
                        <button class="btn btn-info m-0 border-r4" type="button" *ngIf="!buttonShow" [disabled]="!buttonShow" readonly>
                          {{clock}}s后获取</button>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="w-100">
                  <div class="form-group">
                    <input class="form-control"
                           type="password"
                           [(ngModel)]="resetPassword.password"
                           tabindex="4"
                           placeholder="8-18位，含数字、大写字母、小写字母、符号中的3种及以上"
                    >
                  </div>
                </div>

                <div class="w-100">
                  <div class="form-group">
                    <input class="form-control"
                           type="password"
                           [(ngModel)]="resetPassword.newPassword"
                           tabindex="5"
                           placeholder="确认密码"
                    >
                  </div>
                </div>

                <div class="w-100 text-center">
                  <div *ngIf="errorContent != null && errorContent !== '' "
                       class="text-danger">
                    {{errorContent}}
                  </div>
                </div>
              </div>


              <div class="row text-center mt-2">
                <button class="btn btn-info w-100 login_lqd m-0" tabindex="4" (click)="resetPasswordFunction()">重置密码
                </button>
                <button class="btn btn-link text-green1 mt-2 pl-0" [routerLink]="['../login']"
                        [queryParams]="{system_type: system_type}">返回登录
                </button>
              </div>

            </div>
          </div>


        </div>

        <div class="text-center" *ngIf="success">
          <div class="text-center mb-5">
            <img class="w-25" src="../../../../assets/img/v3/lqd/ggg.png" alt="">
            <div class="font-weight-bold mt-3 font-lg ">重置密码成功！</div>

            <div class="text-center mt-3" >
              <button class="btn btn-info px-3 m-0 w-100"
                      (click)="goLogin()" tabindex="4">返回登录
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</div>
