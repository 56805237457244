import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {RequestService} from '../request.service';
import {
  BatchPwdRequest,
  BatchPwdResponse,
  DevAcceptanceRequest,
  DeviceManageBrandNameListRequest,
  DeviceManageBrandNameListResponse,
  DeviceManageDelRequest,
  DeviceManageGatewayListRequest,
  DeviceManageGatewayListResponse,
  DeviceManageGatewayRoomListRequest,
  DeviceManageGatewayRoomListResponse,
  DeviceManageGatewaySaveRequest,
  DeviceManageListRequest,
  DeviceManageListResponse,
  DeviceManageSaveRequest,
  DevInstallListRequest,
  DevInstallListResponse,
  RoomElemeterEnableRequest,
  SMSBatchDoorPwdAuditCodeRequest,
  DevTemporaryConsumeRequest,
  DevTemporaryConsumeResponse,
  PredictConsumeFeeRequest,
  PredictConsumeFeeResponse, LockCheckPollRequest, LockCheckPollResponse, LockCheckConfirmRequest,

} from '../../model/device/device.model';
import {CheckoutAuditListResponse} from "../../model/signed/checkout.model";

@Injectable()
export class DeviceService {

  // 房源创建api
  private DEV_BRAND_LIST_URL = `${environment.apiHost}device/brand/list`;
  // 工单添加api
  private DEV_TICKET_ADD_URL = `${environment.apiHost}device/ticket/add`;
  private DEV_ROOM_LIST_URL = `${environment.apiHost}device/room/device/list`;

  private DEVICE_LIST_URL = `${environment.apiHost}device/install/list`;
  private DEVICE_LOCK_ACCEPT_URL = `${environment.apiHost}device/lock/acceptance`;
  private DEVICE_LOCK_RESETPWD_URL = `${environment.apiHost}device/lock/pwd/reset`;

  private DEVICE_ROOM_DEVICE_ELEMETER_ENABLE = `${environment.apiHost}device/room/device/elemeter/enable`;

  //无智能设备最后读数
  private TEMPORARY_CONSUME_DETAIL_URL = `${environment.apiHost}device/temporary/consume/detail`;
  // 无智能设备 读数录入
  private TEMPORARY_CONSUME_ADD_URL = `${environment.apiHost}device/temporary/consume/add`;
  // 手动抄表费用预估
  private PREDICT_CONSUME_FEE = `${environment.apiHost}device/predict/consume/fee`;
  // 公区设备楼栋列表
  private AREA_DEVICE_LIST_URL = `${environment.apiHost}public/area/device/list`;
  // 楼栋公区设备读数添加
  private AREA_DEVICE_CONSUME_ADD_URL = `${environment.apiHost}public/area/device/consume/add`;

  private LOCK_PWD_TEMPORARY_SETTING_URL = `${environment.apiHost}device/lock/temporary/setting`;
  private LOCK_PWD_TEMPORARY_DETAIL_URL = `${environment.apiHost}device/lock/temporary/detail`;
  //给入住人发送门锁密码
  private SEND_LOCK_PWD_MSG = `${environment.apiHost}device/lock/msg`;

  // 批量设置临时密码
  private POST_LOCK_PWD_TEMPORARY_BATCH_URL = `${environment.apiHost}device/lock/temporary/batch`;
  // 批量设置临时密码审核
  private POST_SEND_LOCK_PWD_MSG_URL = `${environment.apiHost}sms/manage/audit/code/batchDoorDevice`;
  // 批量设置临时密码审核人列表
  private GET_SEND_LOCK_PWD_MSG_AUDIT_LIST_URL = `${environment.apiHost}device/lock/temporary/batch/audit/person`;
  // 获取供应商列表
  private GET_BRAND_NAME_LIST = `${environment.apiHost}device/manage/brand/name/list`;
  // 设备管理列表
  private DEVICE_MANAGE_LIST = `${environment.apiHost}device/manage/list`;
  // 网关设备列表
  private DEVICE_MANAGE_GATEWAY_LIST = `${environment.apiHost}device/manage/gateway/list`;
  // 网关关联房间列表
  private DEVICE_MANAGE_GATEWAY_ROOM_LIST = `${environment.apiHost}device/manage/gateway/room/list`;
  // 新增/编辑设备
  private DEVICE_MANAGE_SAVE = `${environment.apiHost}device/manage/save`;
  // 新增/编辑网关
  private DEVICE_MANAGE_GATEWAY_SAVE = `${environment.apiHost}device/manage/gateway/save`;
  // 删除设备
  private DEVICE_MANAGE_DEL = `${environment.apiHost}device/manage/del`;

  private DEVICE_LOCK_TEMPORARY_COUNT = `${environment.apiHost}device/lock/temporary/count`;

  private DEVICE_LOCK_TEMPORARY_APPROVE = `${environment.apiHost}device/lock/temporary/approve`;

  private DEVICE_LOCK_TEMPORARY_APPROVE_BATCH = `${environment.apiHost}device/lock/temporary/approve/batch`;

  private DEVICE_LOCK_TEST_START = `${environment.apiHost}lock/check/start`;

  private DEVICE_LOCK_TEST_STATUS = `${environment.apiHost}lock/check/poll`;

  private DEVICE_LOCK_TEST_FINISH = `${environment.apiHost}lock/check/confirm`;

  constructor(private requestService: RequestService) {
  }

  /**
   * @Description:  获得设备品牌列表信息
   * @Author: xulihua
   * @Last Modified by: xulihua
   * @Last Modified time: 2018-05-14  11:59
   */
  getDevBrandList(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.DEV_BRAND_LIST_URL, requestData)
      .then(data => {
        return data.dev_brands;
      });
  }

  /**
   * @Description:  工单添加api
   * @Author: xulihua
   * @Last Modified by: xulihua
   * @Last Modified time: 2018-05-14  11:59
   */
  addRoomDevTicket(requestData?: any): Promise<any> {
    return this.requestService.authPost(this.DEV_TICKET_ADD_URL, requestData)
      .then(data => {
        return data;
      });
  }

  /**
   * @Description:  拉取房源已安装设备
   * @Author: xulihua
   * @Last Modified by: xulihua
   * @Last Modified time: 2018-05-14  11:59
   */
  pullRoomDeviceList(requestData?: any): Promise<any> {
    return this.requestService.authPost(this.DEV_ROOM_LIST_URL, requestData)
      .then(data => {
        return data;
      });
  }

  getListByPage(devInstallListRequest: DevInstallListRequest): Promise<DevInstallListResponse> {
    return this.requestService.authGet(this.DEVICE_LIST_URL, devInstallListRequest);
  }

  lockAcceptance(devAcceptanceRequest: DevAcceptanceRequest): Promise<any> {
    return this.requestService.authPost(this.DEVICE_LOCK_ACCEPT_URL, devAcceptanceRequest);
  }

  resetPwd(uuid): Promise<boolean> {
    return this.requestService.authPost(this.DEVICE_LOCK_RESETPWD_URL, {uuid: uuid});
  }

  getBrandNameList(deviceManageBrandNameListRequest: DeviceManageBrandNameListRequest): Promise<DeviceManageBrandNameListResponse> {
    return this.requestService.authGet(this.GET_BRAND_NAME_LIST, deviceManageBrandNameListRequest);
  }

  getDeviceManageList(deviceManageListRequest: DeviceManageListRequest): Promise<DeviceManageListResponse> {
    return this.requestService.authPost(this.DEVICE_MANAGE_LIST, deviceManageListRequest);
  }

  getDeviceManageGatewayList(deviceManageGatewayListRequest: DeviceManageGatewayListRequest): Promise<DeviceManageGatewayListResponse> {
    return this.requestService.authPost(this.DEVICE_MANAGE_GATEWAY_LIST, deviceManageGatewayListRequest);
  }

  getDeviceManageGatewayRoomList(deviceManageGatewayRoomListRequest: DeviceManageGatewayRoomListRequest): Promise<DeviceManageGatewayRoomListResponse> {
    return this.requestService.authPost(this.DEVICE_MANAGE_GATEWAY_ROOM_LIST, deviceManageGatewayRoomListRequest);
  }

  deviceManageSave(deviceManageSaveRequest: DeviceManageSaveRequest): Promise<any> {
    return this.requestService.authPost(this.DEVICE_MANAGE_SAVE, deviceManageSaveRequest);
  }

  deviceManageGatewaySave(deviceManageGatewaySaveRequest: DeviceManageGatewaySaveRequest): Promise<any> {
    return this.requestService.authPost(this.DEVICE_MANAGE_GATEWAY_SAVE, deviceManageGatewaySaveRequest);
  }

  deviceManageDel(deviceManageDelRequest: DeviceManageDelRequest): Promise<any> {
    return this.requestService.authPost(this.DEVICE_MANAGE_DEL, deviceManageDelRequest);
  }

  roomDeviceOnOff(roomElemeterEnableRequest: RoomElemeterEnableRequest): Promise<any> {
    return this.requestService.authPost(this.DEVICE_ROOM_DEVICE_ELEMETER_ENABLE, roomElemeterEnableRequest);
  }

  temporaryConsumeDetail(requestParam: any): Promise<DevTemporaryConsumeResponse> {
    return this.requestService.authGet(this.TEMPORARY_CONSUME_DETAIL_URL, requestParam);
  }

  temporaryConsumeAdd(requestParam: DevTemporaryConsumeRequest): Promise<any> {
    return this.requestService.authPost(this.TEMPORARY_CONSUME_ADD_URL, requestParam);
  }

  predictConsumeFee(request: PredictConsumeFeeRequest): Promise<PredictConsumeFeeResponse> {
    return this.requestService.authGet(this.PREDICT_CONSUME_FEE, request);
  }

  areaDeviceList(requestParam: any): Promise<any> {
    return this.requestService.authGet(this.AREA_DEVICE_LIST_URL, requestParam);
  }

  /**
   * 楼栋公区设备读数添加
   * @param requestParam
   */
  areaDeviceConsumeAdd(requestParam: any): Promise<any> {
    return this.requestService.authPost(this.AREA_DEVICE_CONSUME_ADD_URL, requestParam);
  }

  /**
   * 智能业务：门锁临时密码（30分钟有效期）
   * @param requestParam
   */
  lockPwdTemporarySetting(requestParam: any): Promise<any> {
    return this.requestService.authPost(this.LOCK_PWD_TEMPORARY_SETTING_URL, requestParam);
  }

  /**
   * 批量设置门锁密码
   * @param requestParam
   */
  lockPwdTemporaryBatch(requestParam: BatchPwdRequest): Promise<BatchPwdResponse> {
    return this.requestService.authPost(this.POST_LOCK_PWD_TEMPORARY_BATCH_URL, requestParam);
  }

  /**
   * 门锁临时密码
   * @param requestParam
   */
  lockPwdTemporaryDetail(requestParam: any): Promise<any> {
    return this.requestService.authGet(this.LOCK_PWD_TEMPORARY_DETAIL_URL, requestParam);
  }

  /**
   * 给入住人发送门锁密码
   * @param requestParam
   * @returns {Promise<any>}
   */
  sendDoorPwdMsg(requestParam: any): Promise<any> {
    return this.requestService.authGet(this.SEND_LOCK_PWD_MSG, requestParam);
  }


  batchSendDoorPwdAuditSMS(requestParam: SMSBatchDoorPwdAuditCodeRequest): Promise<any> {
    return this.requestService.authPost(this.POST_SEND_LOCK_PWD_MSG_URL, requestParam);
  }

  batchSendDoorPwdAuditSMSAuditList(): Promise<CheckoutAuditListResponse> {
    return this.requestService.authGet(this.GET_SEND_LOCK_PWD_MSG_AUDIT_LIST_URL);
  }

  getEventCount(): Promise<any> {
    return this.requestService.authGet(this.DEVICE_LOCK_TEMPORARY_COUNT);
  }

  lockPwdTemporaryApprove(requestParam: any): Promise<any> {
    return this.requestService.authPost(this.DEVICE_LOCK_TEMPORARY_APPROVE, requestParam);
  }

  lockPwdTemporaryApproveBatch(requestParam: any): Promise<any> {
    return this.requestService.authPost(this.DEVICE_LOCK_TEMPORARY_APPROVE_BATCH, requestParam);
  }

  lockTest(requestParam: any): Promise<any> {
    return this.requestService.commonHttp2(this.DEVICE_LOCK_TEST_START, 'POST', requestParam, true,120 * 1000, false);
  }

  lockTestStatus(requestParam: LockCheckPollRequest): Promise<LockCheckPollResponse> {
    return this.requestService.authGet(this.DEVICE_LOCK_TEST_STATUS, requestParam);
  }

  lockTestFinish(requestParam: LockCheckConfirmRequest): Promise<any> {
    return this.requestService.authPost(this.DEVICE_LOCK_TEST_FINISH, requestParam);
  }
}
