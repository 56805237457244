import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {PageReq} from "../../model/page/page";
import {
  WorkRemindDetailResponse,
  WorkRemindDeviceDetailResponse,
  WorkRemindDisposeRequest,
  WorkRemindFireDetailResponse,
  WorkRemindFireWarnListResponse,
  WorkRemindLeaseExpireDetailResponse,
  WorkRemindLeaseExpireEmailResponse,
  WorkRemindListReqsonse,
  WorkRemindListRequest,
  WorkRemindLogPageRequest,
  WorkRemindLogPageResponse,
  WorkRemindOrderInfoResponse, WorkRemindPrivateRuleDetailResponse, WorkRemindPrivateRuleEditRequest,
  WorkRemindPrivateRuleListResponse,
  WorkRemindRuleTypeResponse
} from "../../model/workremind/workremind.model";
import {AuthService} from "../common/auth.service";
import {RequestService} from "../request.service";


@Injectable()
export class WorkRemindService {


  private GET_REMIND_FIRE_LIST = `${environment.apiHost}fire/fireWarnList/all`;

  private GET_REMIND_TYPE_LIST = `${environment.apiHost}work/remind/get/type`;

  private GET_REMIND_LIST = `${environment.apiHost}work/remind/list`;

  private GET_REMIND_DETAIL = `${environment.apiHost}work/remind/detail`;

  private GET_REMIND_FIRE_DETAIL = `${environment.apiHost}work/remind/fire/detail`;

  // 设备告警详情
  private GET_REMIND_DEVICE_DETAIL = `${environment.apiHost}work/remind/device/detail`;

  // 合同到期提醒待办详情
  private LEASE_EXPIRE_DETAIL = `${environment.apiHost}work/remind/lease/expire/detail`;

  private LOG_PAGE = `${environment.apiHost}work/remind/log/page`;

  private WORK_REMIND_LEASE_EXPIRE_EMAIL = `${environment.apiHost}work/remind/lease/expire/email`;

  private WORK_REMIND_ORDER_INFO = `${environment.apiHost}work/remind/order/info`;

  private WORK_REMIND_DISPOSE = `${environment.apiHost}work/remind/dispose`;

  // 规则列表
  private WORK_REMIND_CUSTOM_RULE_LIST = `${environment.apiHost}work/remind/rule/list`;
  // 规则详情
  private WORK_REMIND_CUSTOM_RULE_DETAIL = `${environment.apiHost}work/remind/rule/detail`;
  // 规则编辑
  private WORK_REMIND_CUSTOM_RULE_EDIT = `${environment.apiHost}work/remind/rule/edit`;
  // 规则操作
  private WORK_REMIND_CUSTOM_RULE_OPERATE = `${environment.apiHost}work/remind/rule/operation`;

  // 扫码操作
  private WORK_REMIND_CUSTOM_RULE_QRCODE = `${environment.apiHost}work/remind/qr/dispose`;

  private WORK_REMIND_QUERY_IHOME_APPROVE = `${environment.apiHost}work/remind/query/ihome/approve`;

  private WORK_REMIND_IHOME_DISPOSE = `${environment.apiHost}work/remind/ihome/dispose`;


  constructor(
    private requestService: RequestService,
    private authService: AuthService
  ) {
  }


  public getRemindFireList(requestData?: any): Promise<WorkRemindFireWarnListResponse> {
    return this.requestService.authGet(this.GET_REMIND_FIRE_LIST, requestData);
  }

  public getRemindTypeList(): Promise<WorkRemindRuleTypeResponse> {
    return this.requestService.authGet(this.GET_REMIND_TYPE_LIST);
  }

  public getRemindList(req?: WorkRemindListRequest): Promise<WorkRemindListReqsonse> {
    return this.requestService.authGet(this.GET_REMIND_LIST, req);
  }

  public getRemindDetail(workRemindId: number): Promise<WorkRemindDetailResponse> {
    return this.requestService.authGet(this.GET_REMIND_DETAIL, {work_remind_id: workRemindId});
  }

  public getRemindFireDetail(workRemindId: number): Promise<WorkRemindFireDetailResponse> {
    return this.requestService.authGet(this.GET_REMIND_FIRE_DETAIL, {work_remind_id: workRemindId});
  }

  public getDeviceDetail(workRemindId: number): Promise<WorkRemindDeviceDetailResponse> {
    return this.requestService.authGet(this.GET_REMIND_DEVICE_DETAIL, {work_remind_id: workRemindId});
  }

  public getLeaseExpireDetail(workRemindId: number): Promise<WorkRemindLeaseExpireDetailResponse> {
    return this.requestService.authGet(this.LEASE_EXPIRE_DETAIL, {work_remind_id: workRemindId});
  }

  public getLogPage(req: WorkRemindLogPageRequest): Promise<WorkRemindLogPageResponse> {
    return this.requestService.authGet(this.LOG_PAGE, req);
  }

  public workRemindLeaseExpireEmail(leaseId: number): Promise<WorkRemindLeaseExpireEmailResponse> {
    return this.requestService.authGet(this.WORK_REMIND_LEASE_EXPIRE_EMAIL, {lease_id: leaseId});
  }

  public getWorkRemindOrderInfo(orderId: string): Promise<WorkRemindOrderInfoResponse> {
    return this.requestService.authGet(this.WORK_REMIND_ORDER_INFO, {order_id: orderId});
  }

  public workRemindDispose(req: WorkRemindDisposeRequest): Promise<any> {
    return this.requestService.authPost(this.WORK_REMIND_DISPOSE, req);
  }

  public workRemindCustomRuleList(req: PageReq): Promise<WorkRemindPrivateRuleListResponse> {
    return this.requestService.authGet(this.WORK_REMIND_CUSTOM_RULE_LIST, req);
  }

  public workRemindCustomRuleDetail(id: number): Promise<WorkRemindPrivateRuleDetailResponse> {
    return this.requestService.authGet(this.WORK_REMIND_CUSTOM_RULE_DETAIL, {private_rule_id: id});
  }

  public workRemindCustomRuleEdit(req: WorkRemindPrivateRuleEditRequest): Promise<any> {
    return this.requestService.authPost(this.WORK_REMIND_CUSTOM_RULE_EDIT, req);
  }

  public workRemindCustomRuleOperation(id, operation): Promise<any> {
    return this.requestService.authPost(this.WORK_REMIND_CUSTOM_RULE_OPERATE, {privateRuleId: id, operation: operation});
  }

  public workRemindCustomRuleQrcode(id, qrcode): Promise<any> {
    return this.requestService.authPost(this.WORK_REMIND_CUSTOM_RULE_QRCODE, {work_remind_id: id, qr_code: qrcode});
  }
  public workRemindQueryIhomeApprove(req): Promise<any> {
    return this.requestService.authGet(this.WORK_REMIND_QUERY_IHOME_APPROVE, req);
  }

  public workRemindIhomeDispose(req) : Promise<any> {
    return this.requestService.authPost(this.WORK_REMIND_IHOME_DISPOSE, req);
  }

}
