import { RequestService } from "../request.service";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { EvolveReqParam, RiskInfoList, RiskReqParam } from "../../model/workbench/risk.model";

/**
 * Created by Administrator on 2018/7/11.
 */

@Injectable()
export class RiskService {
  private RISK_LIST = `${environment.apiHost}alert/list/risk`;

  private EVOLVE_HANDLE = `${environment.apiHost}alert/handle`;

  constructor(private requestService: RequestService) {

  }

  getRiskList(riskReq: RiskReqParam): Promise<RiskInfoList> {
    return this.requestService.authGet(this.RISK_LIST, riskReq);
  }
  getRiskListWithLoading(riskReq: RiskReqParam): Promise<RiskInfoList> {
    return this.requestService.authGet(this.RISK_LIST, riskReq, true);
  }

  handleEvolve(evolveReq: EvolveReqParam): Promise<any> {
    return this.requestService.authPost(this.EVOLVE_HANDLE, evolveReq);
  }
}
