import {Component, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {IMyDpOptions} from "../../third-party/mydatepicker/src/lib/interfaces";
import {ModalDirective} from "ngx-bootstrap/modal";
import {environment} from "../../../../environments/environment";
import {filterFormData} from "../../../common/common";
import {generatePluginDate, getTimeFromPlugin} from "../../../common/date.util";
import {GroupModel} from "../../../model/common/common.model";
import {ProjectUnitModel} from "../../../model/room/project.model";
import {CleanTaskDTO, CleanTaskEditRequest, CleanTaskListRequest, CleanTaskListResponse} from "../../../model/room/repair.model";
import {ProjectListUnitRequest} from "../../../model/room/room.model";
import {AssetService} from "../../../service/asset/asset.service";
import {AuthService} from "../../../service/common/auth.service";
import {WorkService} from "../../../service/common/work.service";
import {RepairService} from "../../../service/room/repair.service";
import {RoomService} from "../../../service/room/room.service";
import {UserService} from "../../../service/user/user.service";

@Component({
  selector: 'app-cleantask',
  templateUrl: './cleantask-list.component.html',
  styleUrls: ['./cleantask-list.component.css']
})
export class CleanTaskListComponent implements OnInit {

  btn_privileges: any = {
    'WORKSPACE_CLEANTASK_LIST_VIEW': false,
  };

  req: CleanTaskListRequest = {
    page_num: 1,
    page_size: 20,
    project_id_list: [],
    room_name: "",
    clean_status: "WAITING",
    unit_id: 0,
  };
  response?: CleanTaskListResponse;
  projectUnitReq: ProjectListUnitRequest = {
    project_id_list: [],
  };
  selectGroupList: Array<GroupModel>;
  platformCode: string;
  projectId: number;

  // 楼栋号（单元）
  projectUnits: Array<ProjectUnitModel> = [];
  filterProjectUnits: Array<ProjectUnitModel> = [];

  @ViewChild("modal1") modal1: ModalDirective;
  updateModel: CleanTaskDTO;
  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private assetService: AssetService,
    private workService: WorkService,
    private authService: AuthService,
    private userService: UserService,
    private roomService: RoomService,
    private repairService: RepairService
  ) {
  }

  ngOnInit() {
    this.platformCode = localStorage.getItem('platformCode');
    this.projectId = this.authService.getProjectId();
    this.authService.getOperatePrivilege(this.btn_privileges);
    this.searchProject();
  }

  searchProject() {
    let companyId = this.authService.getCompanyId();
    this.userService.userProjectList({
      company_id: companyId,
      user_id: this.authService.getUserInfo().id,
      platfromCode: this.platformCode
    }).then(data => {
      this.selectGroupList = [];
      if (data.project_group_list) {
        data.project_group_list.forEach(group => {
          let arr = [];
          group.project_dtolist.forEach(project => {
            arr.push(new GroupModel(project.id, project.project_name));
          });
          this.selectGroupList.push(new GroupModel(group.group_id, group.project_group_name, arr));
        });
      }
      if (data.project_dtolist) {
        this.projectUnitReq.project_id_list = data.project_dtolist.map(e => e.id);
      }
      this.getUnitList();
      this.list();
    });
  }

  /**
   * 获取项目下单元列表
   */
  getUnitList(): void {
    this.roomService.getProjectListUnits(this.projectUnitReq)
      .then(response => {
          this.projectUnits = response.project_units;

          this.filterUnit();
        }
      );
  }

  pageChange($event: any) {
    this.req.page_num = $event;
    this.list();
  }

  list() {

    filterFormData(this.req);
    this.repairService.getCleanTaskList(this.req).then(data => {
      if (data.clean_task_list) {
        this.response = data;
      } else {
        this.response = {};
        this.response.clean_task_list = [];
      }
    });
  }

  search() {
    this.req.page_num = 1;
    this.list();
  }

  download() {

    let param = "";
    for (let key in this.req) {
      if (this.req[key] && this.req.hasOwnProperty(key)) {

        if (this.req[key] instanceof Array) {
          param += (key + '=' + this.req[key].join(",") + '&');
        } else {
          param += (key + '=' + this.req[key] + '&');
        }
      }
    }

    let downloadUrl = `${environment.apiHost}clean/task/downloadList`;
    if (param) {
      downloadUrl += "?" + param + 'Authorization=' + this.authService.getAuthToken();
    } else {
      downloadUrl += '?Authorization=' + this.authService.getAuthToken();
    }
    window.open(downloadUrl, '_blank');
  }

  batchUpdate() {
    this.router.navigate(['./import'], {
      relativeTo: this.activatedRoute
    });
  }

  update(info: any) {
    this.updateModel = Object.assign({}, info);
    if (this.updateModel.late_clean_date) {
      this.updateModel.late_clean_date = generatePluginDate(new Date(this.updateModel.late_clean_date));
    }
    if (this.updateModel.finish_clean_date) {
      this.updateModel.finish_clean_date = generatePluginDate(new Date(this.updateModel.finish_clean_date));
    }
    this.modal1.show();
  }

  updateDo() {

    if (this.validUpdate()) {

      let req = new CleanTaskEditRequest();
      req.status = this.updateModel.clean_status;
      req.remark = this.updateModel.remark;
      if (this.updateModel.late_clean_date) {
        req.delay_date = getTimeFromPlugin(this.updateModel.late_clean_date['formatted'] + " 00:00:00");
      }
      if (this.updateModel.finish_clean_date) {
        req.finish_date = getTimeFromPlugin(this.updateModel.finish_clean_date['formatted'] + " 00:00:00");
      }
      req.task_id = this.updateModel.clean_id;
      this.repairService.updateCleanTask(req).then(() => {
        this.list();
        this.modal1.hide();
      });
    }
  }

  validUpdate(): boolean {
    let tag = true;
    if (this.updateModel.clean_status === 'FINISH' && !this.updateModel.finish_clean_date) {
      tag = false;
    }
    if (this.updateModel.clean_status === 'DELAY' && !this.updateModel.late_clean_date) {
      tag = false;
    }

    return tag;
  }

  filterUnit() {
    let id = this.authService.getProjectInfo().id;
    if (this.req.project_id_list && this.req.project_id_list.length > 0) {
      this.filterProjectUnits = this.projectUnits.filter(e => this.req.project_id_list.includes(e.project_id));
    } else {
      this.filterProjectUnits = this.projectUnits.filter(e => e.project_id === +id);
    }
  }

  changeProject($event: any) {
    this.filterUnit();
    this.req.unit_id = 0;
  }
}
