import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RequestService } from '../request.service';
import { TradingSceneRequest, TradingSceneResponse } from '../../model/finance/finance.model';

/**
 * @description: 支付场景
 * @Author: kaungye
 * @Date: 2018-06-07
 * @Last Modified by: kuangye
 * @Last Modified time: 2018-06-07
 */
@Injectable()
export class TradingSceneService {

  private TRADING_SCENE_LIST_URL = `${environment.apiHost}tradingscene/list`;

  constructor(private requestService: RequestService) {
  }

  // 支付类型/支付项 列表
  getSceneList(tradingSceneRequest: TradingSceneRequest): Promise<TradingSceneResponse> {
    return this.requestService.authGet(this.TRADING_SCENE_LIST_URL, tradingSceneRequest);
  }

}
