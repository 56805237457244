import { Component, OnInit } from '@angular/core';
import { SystemLogListResponse, SystemLogReqParam } from "../../../model/systemLog/systemLog.model";
import { ActivatedRoute } from "@angular/router";
import { SystemLogService } from "../../../service/systemLog/systemLog.service";
import { LogService } from "../../../service/log/log.service";

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css']
})
export class LogComponent implements OnInit {
  systemLogReqParam: SystemLogReqParam = {
    business_id: null,
    system_log_type: null,
    page_no: 1,
    page_count: 10,
  };
  systemLogListResponse: SystemLogListResponse;

  constructor(private activatedRoute: ActivatedRoute, private systemLogService: SystemLogService, private  logService: LogService) {
    this.systemLogReqParam.business_id = this.activatedRoute.params['value']['businessId'];
    this.systemLogReqParam.system_log_type = this.activatedRoute.params['value']['systemLogType'];
  }

  ngOnInit() {
    this.getSystemLogList();
  }

  getSystemLogList() {
    const reqParam = {};
    for (let item in this.systemLogReqParam) {
      if (item) {
        reqParam[item] = this.systemLogReqParam[item];
      }
    }
    this.systemLogService.getSystemLogList(reqParam).then(value => {
      this.systemLogListResponse = value;
    });
  }

  pageChange(currentPage: number): void {
    this.systemLogReqParam.page_no = currentPage;
    this.getSystemLogList();
  }
}
