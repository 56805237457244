import {RoomBedDTO, RoomInfoDTO} from "../room/room.model";

export const BillTypeModel = {
  BOOKING: '预定金',
  RENT: '房费',
  DEPOSIT: '押金',
  WATERANDELECTRICITY: '水电费',
  WATER: '水费',
  ELECTRICITY: '电费',
  PENALTY: '违约金',
  CLEAN: '保洁费',
  REPAIR: '维修费',
  OVERDUE: '滞纳金',
  DAMAGE: '赔偿金',
  SERVICE: '服务费',
  HEATING: '暖气费',
  PROPERTYMANAGEMENTFEE: '物业费',
  ROOMCHANGE: '换房费',
  PUBLICELECTRICITY: '公共电费',
  OTHER: '其他费用'
};

export class FinanceListDTO {
  bill_id?: number;
  bill_status?: string;
  bill_status_name?: string;
  room_name?: string;
  renter_name?: string;
  bill_type_name?: string;
  bill_start_time?: number;
  bill_end_time?: number;
  bill_amount?: string;
  plan_pay_time?: number;
  remark?: string;
  bill_tag?: string;
  pay_info_list?: FinanceListPayInfoDTO[];
  waiting_pay_amount?: string;//待支付金额
}

export class FinanceListPayInfoDTO {
  //对应收款日
  pay_time?: number;
  //支付方式
  pay_type_name?: string;
  //收款金额
  pay_amount?: string;
}

export class BillListResponse {
  finance_lists ?: FinanceListDTO[];
  total_bill_amount?: string;
  exchange_support?: string;
}

//房源下拉框
export class RoomBoxReqParam {
  lease_id?: number;
  is_necessary?: string;
  room_status?: string;
}

export class RoomAndBedDTO extends RoomInfoDTO {
  room_beds?: RoomBedDTO[];
}

export class RoomBoxDTO {
  room_and_bed_infos?: RoomAndBedDTO[];
}

//账单项目
export class BillTypeReqParam {
  bill_type_status?: string;
}

export class BillTypeInfo {
  bill_type_id?: number;
  bill_type_name?: string;
  bill_type_code?: string;
  bill_grop_name?: string;
  bill_type_pay_way?: string;
}

export class BillTypeDTO {
  group_name?: string;
  bill_type_code?: string;
  bill_types?: BillTypeInfo[];
}

export class BillTypeGrouPDTO {
  bill_type_groups: BillTypeDTO[];
}

//租客账单 查询参数
export class FinanceListReqParam {
  bill_status?: string;
  plan_pay_time_start?: any;
  plan_pay_time_end?: any;
  room_id?: number;
  room_num?: string;
  bill_type_id?: number;
  lease_id?: number;
  bill_id?: number;
  bed_id?: number;
  page_size?: number;
  page_num?: number;
}

//收款记录
export class PayLogDTO {
  pay_time?: Date;
  pay_type_name?: string;
  pay_amount?: string;
}

//账单
export class LeaseRoomBillDTO {
  bill_id?: number;
  bill_status?: string;
  bill_status_name?: string;
  room_name?: string;
  renter_name?: string;
  bill_type_name?: string;
  bill_start_time?: Date;
  bill_end_time?: Date;
  bill_amount?: string;
  plan_pay_time?: Date;
  remark?: string;
  bill_tag?: string;
  pay_infolist?: PayLogDTO[];
  gathering?: boolean;
}

//租约账单
export class RenterLeaseRoomBillDTO {
  lease_id?: number;
  bill_id?: number;
  bill_no?: string;
  account_period?: number;
  bill_start_time?: Date;
  bill_end_time?: Date;
  room_name?: string;
  bill_type_name?: string;
  bill_amount_str?: string;
  plan_pay_time?: Date;
  pay_amount?: string;
  pay_time?: Date;
  bill_status?: string;
  bill_status_name?: string;
  remark?: string;
  operation_status?: string;
  create_type?: string;
  gathering_show?: boolean;
  split_show?: boolean;
  room_id?: number;
  bill_type_id?: number;
}

export class CheckOutBillListDTO {
  room_name?: string;
  check_out_time?: Date;
  pay_type_name?: string;
  operation_name?: string;
  check_out_total?: string;
  remark?: string;
  check_out_bill_list?: RenterLeaseRoomBillDTO[];
}

//账单返回
export class FinanaceListDTO {
  finance_lists?: LeaseRoomBillDTO[];
  lease_finance_list?: RenterLeaseRoomBillDTO[];
  check_out_bill_list?: CheckOutBillListDTO[];
  total?: number;
  renter_name?: string;
  print_date?: Date;
  print_user?: string;
  consume_print?: string;
  payment_print?: string;
  add_show?: boolean;
  extra_refund_show?: boolean;
  lease_type?: string;
}

//收款方式 参数
export class PayTypeReqParam {
  //company 企业  person 个人
  bill_type?: string;

  constructor(bill_type: string) {
    this.bill_type = bill_type;
  }
}

export class TradingSceneRequest {
  //COMPANY 企业  SINGLE 个人
  bill_type?: string = 'SINGLE';
  //场景
  trading_scene_code?: string;

  constructor(trading_scene_code: string, bill_type?: string) {
    this.trading_scene_code = trading_scene_code;
    this.bill_type = bill_type;
  }
}

//收款方式 INFO
export class PayTypeDTO {
  pay_type_id?: number;
  pay_type_name?: string;
  pay_type_code?: string;
  pay_type_status?: string;
}

//收款方式 List
export class PayTypeListDTO {
  pay_types?: PayTypeDTO[];

  constructor() {
  }
}

export class ThrowLeaseParam {
  bill_pays_requests: Array<BillPaysRequest>;
  lease_id: number;
  pay_time: number;
  reason: string;
  pay_type_id: number;
  credential_num: string;
  remark: string;
  is_pass: string;
  lease_room_ids: Array<number>;
  room_ids: Array<number>;
  bed_id: number;
  cret_images?: Array<string>;
  lease_room_id: number;
}

//收款账务
export class BillPaysRequest {
  leaseId: number;
  leaseRoomId: number;
  roomId: number;
  billTypeId: number;
  payAmount: string;
  accountType: number;
  billId: number;
}

export enum TradingSceneEnum {
  //场景类型
  PAYTYPE = 'PAYTYPE',
  BILLTYPE = 'BILLTYPE',

  //场景CODE
  PAY_REFUND = 'PAY_REFUND',
  PAY_CHECKOUT_REFUND = 'PAY_CHECKOUT_REFUND',
  PAY_GATHERING = 'PAY_GATHERING',
  PAY_CHECKOUT_GATHERING = 'PAY_CHECKOUT_GATHERING',
  PAY_EXTRA_REFUND = 'PAY_EXTRA_REFUND',

  SYSTEM_PAY_ACCOUNTING_ADD = 'SYSTEM_PAY_ACCOUNTING_ADD',
  PAY_ACCOUNTING_ADD = 'PAY_ACCOUNTING_ADD',

  PAY_CHECKOUT = 'PAY_CHECKOUT',

  BILL_ADD = 'BILL_ADD',
  BILL_CHECKOUT = 'BILL_CHECKOUT',

  LEASE_ADD = 'LEASE_ADD',
  COMPANY_BALANCE_RECHARGE = 'COMPANY_BALANCE_RECHARGE',
  COMPANY_BALANCE_WITHDRAW = 'COMPANY_BALANCE_WITHDRAW',
  RPT_BILL_TYPE_LIST = 'RPT_BILL_TYPE_LIST'
}

export class TradingSceneResponse {
  pay_types: Array<PayTypeDTO>;
  bill_type_groups: Array<BillTypeGroupDTO>;
}

export class BillTypeGroupDTO {
  group_name: string;
  bill_type_code: string;
  bill_types: Array<BillTypeDTO>;
}

export class BillReconciliationRequest {
  bill_no?: string;
  bill_id?: number;
  bill_start_time?: any;
  bill_end_time?: any;
  plan_pay_time?: any;
  bill_amount?: string;
  confirm_password?: string;
}

export class RenterBillTypeListResponse{
  bill_type_list?: Array<BillTypeInfo>;
}

export class RenterCreateBillDTO{
  room_id?: number;
  /**
   * 账单起始时间
   */
  //@NotNull(message = "账单起始时间不能为空")
  start_time?: number;
  /**
   * 账单结束时间
   */
  //@NotNull(message = "账单结束时间不能为空")
  end_time?: number;
  /**
   * 实际支付金额
   */
  bill_amount?: string;
  /**
   * 备注： 其它收款时必输
   */
  remark?: string;
  //@Remark("住客id")
  renter_id?: number;
  /**
   * 账单类型
   */
  //@NotBlank(message = "账单类型不能为空")
  bill_type_code?: string;
}
