import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {AuthService} from "../common/auth.service";
import {Observable} from "rxjs";

@Injectable()
export class ImageService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
  }

  public postImage(url: string, image: File,
                   headers?: { [name: string]: string | string[] },
                   partName: string = 'image',
                   customFormData?: { [name: string]: any },
                   withCredentials?: boolean): Observable<any> {
    if (!url || url === '') {
      throw new Error('Url is not set! Please set it before doing queries');
    }

    let options: {
      headers?: { [name: string]: string | string[] };
      params?: HttpParams;
      withCredentials?: boolean;
    } = {};

    if (withCredentials) {
      options.withCredentials = withCredentials;
    }

    if (headers) {
      options.headers = headers;
    }

    // add custom form data
    let formData = new FormData();
    for (let key in customFormData) {
      if (customFormData[key]) {
        formData.append(key, customFormData[key]);
      }
    }
    formData.append(partName, image);

    // post
    return this.http.post(url, formData, options);
  }

  public simplePostImage(image: File): Promise<any> {

    let url = environment.apiHost + `image/upload/file`;

    let options: {
      headers?: { [name: string]: string | string[] };
      params?: HttpParams;
      withCredentials?: boolean;
    } = {};

    let fd = {};
    fd = {isNeedWaterMark: 'NOT_NEED_WATER_MARK'};
    fd['logoNeedWaterMark'] = 'NOT_NEED_LEJING_LOGO';
    fd['requestId'] = new Date().getTime() + '_' + Math.random();

    let headers = {Authorization: 'MyToken'};
    headers.Authorization = this.authService.getAuthToken();

    if (headers) {
      options.headers = headers;
    }

    // add custom form data
    let formData = new FormData();
    for (let key in fd) {
      if (fd[key]) {
        formData.append(key, fd[key]);
      }
    }
    formData.append('image', image);

    // post
    return this.http.post(url, formData, options).toPromise()
      .then(response=>{
        if (response['status_code'] === 'SUCCESS') {
          return response['biz_response'].image_url;
        } else {
          return null;
        }
      });
  }

}
