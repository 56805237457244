import { RequestService } from "../request.service";
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import {
  CompanyPrepayCreateReq,
  CompanyPrepayRechargeRecordRequest,
  CompanyPrepayRechargeRecordResponse
} from "../../model/lease/lease.model";
import {RoomSmartRechargeRequest} from "../../model/room/room.model";
import {AuthService} from "../common/auth.service";

/**
 * @Description: 企业服务
 * @Author: 陆炳彬
 * @Date: 2018/4/3 上午11:21
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/4/3 上午11:21
 */
@Injectable()
export class CompanyService {

  //获取企业分页列表
  private COMPANY_LIST = `${environment.apiHost}manage/company/company/list`;

  //获取企业信息
  private GET_COMPANY_INFO = `${environment.apiHost}manage/company/info`;

  //获取企业预付账户信息
  private GET_COMPANY_PREPAY_INFO = `${environment.apiHost}companyprepay/info`;

  //企业预付账户开通
  private CREATE_COMPANY_PREPAY = `${environment.apiHost}companyprepay/create`;

  //查询企业预付账户消费明细
  private COMPANY_PREPAY_DETAIL = `${environment.apiHost}companyprepay/detail`;

  //企业预付账户消费明细下载
  private DOWNLOAD_CONSUME_DETAIL = `${environment.apiHost}companyprepay/detail/download`;

  //企业预付账户参数修改
  private CHANGE_COMPANY_PREPAY = `${environment.apiHost}companyprepay/change`;

  private USER_CAOMPAY_LIST = `${environment.apiHost}manage/company/user/company/list`

  //公司项目ID
  private CAOMPAY_PROJECT_IDS_LIST = `${environment.apiHost}manage/company/projectIdsList`

  //获取企业预付账户充值记录
  private GET_COMPANY_PREPAY_RECHARG_RECORD_LIST = `${environment.apiHost}companyprepay/rechargeRecordList`;

  //企业预付账户充值记录下载
  private DOWN_COMPANY_PREPAY_RECHARG_RECORD = `${environment.apiHost}companyprepay/downPrepayRechargeRecords`;


  constructor(private requestService: RequestService,private authService: AuthService) {
  }

  /**项目ID不能为空
   * @Description: 获取企业分页列表
   * @Author: 陆炳彬
   * @Date:  下午3:32
   * @Last Modified by: 陆炳彬
   * @Last Modified time:  下午3:32
   */
  queryCompanyList(companyReqParam: any): Promise<any> {
    return this.requestService.authGet(this.COMPANY_LIST, companyReqParam);
  }

  /**
   * @Description: 获取企业信息
   * @Author: 陆炳彬
   * @Date: 2018/4/4 下午3:46
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/4/4 下午3:46
   */
  getCompanyInfo(companyId: any): Promise<any> {
    return this.requestService.authGet(this.GET_COMPANY_INFO, { company_id: companyId });
  }

  /**
  * @Description:  获取企业预付账户信息
  * @Author: yangwen
  * @Date: 2019/7/16 19:21
  */
  getCompanyPrepayInfo(params: any): Promise<any> {
    return this.requestService.authGet(this.GET_COMPANY_PREPAY_INFO, params);
  }

  /**
   * @Description:  企业预付账户开通
   * @Author: yangwen
   * @Date: 2019/7/16 19:21
   */
  createCompanyPrepay(params: any): Promise<any> {
    return this.requestService.authPost(this.CREATE_COMPANY_PREPAY, params);
  }

  /**
  * @Description: 查询消费明细
  * @Author: yangwen
  * @Date: 2019/7/17 14:19
  */
  getCompanyPrepayDetail(params: any): Promise<any> {
    return this.requestService.authGet(this.COMPANY_PREPAY_DETAIL, params);
  }

  /**
  * @Description: 消费明细下载
  * @Author: yangwen
  * @Date: 2019/7/17 16:25
  */
  downloadConsumeDetail(lease_id: number): string {
    let url = this.DOWNLOAD_CONSUME_DETAIL + '?lease_id=' + lease_id;
    return url;
  }

  changeCompanyPrepay(params: CompanyPrepayCreateReq) {
    return this.requestService.authPost(this.CHANGE_COMPANY_PREPAY, params);
  }

  // 查询用户的可管理公司
  getUserCompanyList(platformCode): Promise<any> {
    return this.requestService.authGet(this.USER_CAOMPAY_LIST, {platformCode: platformCode});
  }

  //获取公司项目ids
  getCompanyProjectIds(companyId: any): Promise<any> {
    return this.requestService.authGet(this.CAOMPAY_PROJECT_IDS_LIST, { company_id: companyId });
  }

  //企业租约预付账户充值记录
  getCompanyPrepayRchargRecordList(req: CompanyPrepayRechargeRecordRequest): Promise<CompanyPrepayRechargeRecordResponse> {
    return this.requestService.authGet(this.GET_COMPANY_PREPAY_RECHARG_RECORD_LIST, req);
  }

  //企业租约预付账户充值下载
  downloadCompanyPrepayRchargRecord(request: CompanyPrepayRechargeRecordRequest) {

    let param = "";
    for (let key in request) {
      if (request[key] && request.hasOwnProperty(key)) {
        param += (key + '=' + request[key] + '&');
      }
    }
    if (param) {
      window.open(this.DOWN_COMPANY_PREPAY_RECHARG_RECORD + "?" + param + 'Authorization=' + this.authService.getAuthToken(), "_blank");
    } else {
      window.open(this.DOWN_COMPANY_PREPAY_RECHARG_RECORD + '?Authorization=' + this.authService.getAuthToken(), "_blank");
    }
  }

}
