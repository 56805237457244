<div class="modal fade" bsModal #checkoutRoomView="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>请选择要转租签约的房间</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="checkoutRoomView.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="over10">
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th>
              </th>
              <th>退房时间</th>
              <th>房间号</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let info of subleaseList">
              <td>
                <input [(ngModel)]="ids" [value]="info.checkout_room_view_dtolist" type="radio" name="a">
              </td>
              <td>
                {{info?.checkout_time | date:'yyyy-MM-dd'}}
              </td>
              <td>
                <div *ngFor="let room of info?.checkout_room_view_dtolist">
                  {{room.room_name}}
                </div>
              </td>
            </tr>
            <tr>
              <td
                *ngIf="subleaseList == null || subleaseList.length == 0 "
                colspan="3">此合同下无可转租房间
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="text-center mb20">

          <button type="button" *ngIf="ids.length>0" (click)="goSublease()" class="btn btn-primary mr20">下一步
          </button>
          <button type="button"
                  *ngIf="ids.length==0"
                  disabled="disabled" class="btn btn-secondary mr20">下一步
          </button>
          <button type="button" (click)="modal.hide()" class="btn btn-secondary">取消</button>
        </div>
      </div>
    </div>
  </div>
</div>
