import { Component, OnInit } from '@angular/core';
import {
  RenterCheckoutRecordListRequest,
  RenterCheckoutRecordListResponse
} from "../../../../model/signed/checkout.model";
import {SignedService} from "../../../../service/signed/signed.service";
import {ActivatedRoute, Router} from "@angular/router";
import {RoomInfoDTO} from "../../../../model/room/room.model";
import {BookingService} from "../../../../service/booking/booking.service";

@Component({
  selector: 'app-renter-checkout-list',
  templateUrl: './renter-checkout-list.component.html',
  styleUrls: ['./renter-checkout-list.component.css']
})
export class RenterCheckoutListComponent implements OnInit {

  renterCheckoutRecordListResponse?: RenterCheckoutRecordListResponse
  renterCheckoutRecordListRequest?: RenterCheckoutRecordListRequest = new RenterCheckoutRecordListRequest();
  leaseId?: number;
  roomId?: number = 0;
  searchContext?: string;
  renterRoomInfos: Array<RoomInfoDTO> = [];
  constructor(
    private signedService: SignedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private bookingService: BookingService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(queryParams => {
      this.leaseId = queryParams['leaseId'];
      this.searchData(1);
      this.queryRoomInfo();
    });
  }

  searchData(pageNum?: number){
    this.renterCheckoutRecordListRequest.page_num = pageNum;
    this.renterCheckoutRecordListRequest.page_size = 10;
    this.renterCheckoutRecordListRequest.lease_id =this.leaseId;
    if (this.roomId != 0) {
      this.renterCheckoutRecordListRequest.room_id = this.roomId;
    } else {
      this.renterCheckoutRecordListRequest.room_id = null;
    }
    this.renterCheckoutRecordListRequest.search_context = this.searchContext;
    this.signedService.renterCheckoutRecordList(this.renterCheckoutRecordListRequest).then(data => {
      this.renterCheckoutRecordListResponse = data;
    });
  }

  queryRoomInfo(){
    let reqDate = {lease_room_query_type: 'LEASE_ID', lease_value: this.leaseId};
    this.bookingService.getLeaseRoomList(reqDate).then(data => {
      if (data) {
        this.renterRoomInfos = data.room_info_list;
      }
    });
  }

}
