<div class="setbox padd15 list">
  <div class="row">
    <div class="col-12 mb10">
      <button [routerLink]="['/system_manage/carport/sign/add']" class="btn btn-info" type="button" *ngIf="btn_privileges.CARPORT_LEASE_ADD">
        <i class="fa fa-plus" aria-hidden="true"></i>新签车库/位
      </button>
      <button (click)="gatherMoney()" class="btn btn-warning" style="margin-left: 10px" type="button" *ngIf="btn_privileges.CARPORT_LEASE_BILL">
        <i class="fa fa-rmb" aria-hidden="true"></i>收款
      </button>
      <!--<button [routerLink]="['/system_manage/signed/list']" class="btn btn-warning" style="margin-left: 10px" type="button">
        <i class="fa fa-refresh" aria-hidden="true"></i>切换至房源
      </button>-->
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-3 mb10">
      <p class="hei32 w90px">查询条件：</p>
      <div class="condiv">
        <input type="text" class="form-control" placeholder="合同号、联系人姓名、手机号或车库/位号" [(ngModel)]="searchContent">
      </div>
    </div>
    <div class="col-12 col-md-2 mb10">
      <p class="hei32 w60px">状态：</p>
      <div class="condiv">
        <select [(ngModel)]="searchStatus">
          <option value="">全部</option>
          <option *ngFor="let item of lease_business_status_list" [value]="item.code" >{{item.desc}}</option>
        </select>
        <!--<ng-container *ngFor="let item of lease_business_status_list">
          <div class="form-check form-check-inline hei32 mr20">
            <input (click)="statusSet($event, item.code)" class="form-check-input" type="checkbox"
                   [(ngModel)]="status_checked_list[item.code].checked">
            <label class="form-check-label">{{item.desc}}</label>
          </div>
        </ng-container>-->
      </div>
    </div>
    <div class="col-12 col-md-1 mb10">
      <button class="btn btn-info" type="button" (click)="searchList()">
        <i class="fa fa-search">搜索</i>
      </button>
    </div>
    <!--<div class="col-6 col-md-1">-->
      <!--<button class="btn btn-secondary" type="button" (click)="clearSearch()">-->
        <!--<i class="fa fa-times">清空条件</i>-->
      <!--</button>-->
    <!--</div>-->
  </div>
  <!-- table -->
  <table class="table table-bordered table-striped">
    <thead>
    <tr>
      <th class="show2">合同号</th>
      <th class="show2">签约类型</th>
      <th>车库/位</th>
      <th class="show2">租期起止</th>
      <th class="show2">签约人</th>
      <th>联系人</th>
      <th>联系电话</th>
      <th>状态</th>
      <th>操作</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="carportInfoList && carportInfoList.length == 0">
      <td colspan="5" class="show1">本次未查询到相关数据</td>
      <td colspan="9" class="show2">本次未查询到相关数据</td>
    </tr>
    <tr *ngFor="let itemCarport of carportInfoList| paginate: {itemsPerPage: 20, currentPage: page_num,totalItems: totalNum }">
      <td class="show2">{{itemCarport.contract_num}}</td>
      <td class="show2">车库/位</td>
      <td>{{itemCarport.carport_number}}</td>
      <td class="show2">{{itemCarport.start_time | date:'yyyy.MM.dd' }} - {{itemCarport.end_time | date:'yyyy.MM.dd' }}</td>
      <td class="show2">{{itemCarport.sign_contact_name}}</td>
      <td>{{itemCarport.contact_name}}</td>
      <td>{{itemCarport.contact_phone}}</td>
      <td>
          <span class="badge badge-pill" [style.background-color]="getColorByBusinessStatus(itemCarport.business_status)">
            {{itemCarport.business_status_desc}}
          </span>
      </td>
      <td>
        <button type="button" *ngIf="btn_privileges.CARPORT_LEASE_QUERY &&  'UNCOMMITTED' == itemCarport?.lease_status"
                [routerLink]="['/system_manage/carport/sign/edit']"
                [queryParams]="{'carport_lease_id':itemCarport.carport_lease_id}" class="btn btn-info">
          <i class="fa fa-cogs"></i>查看/操作
        </button>
        <button type="button" *ngIf="btn_privileges.CARPORT_LEASE_QUERY && 'UNCOMMITTED' !== itemCarport?.lease_status"
                [routerLink]="['/system_manage/carport/details/',itemCarport?.carport_lease_id]" class="btn btn-info">
          <i class="fa fa-eye"></i>查看
        </button>
      </td>
    </tr>
    </tbody>
  </table>
  <div class="text-right mt-3 pages" *ngIf="carportInfoList && carportInfoList.length > 0">
    <pagination-controls (pageChange)="pageChange($event)" directionLinks="true" previousLabel="上一页" nextLabel="下一页">
    </pagination-controls>
    <div class="text-right">共有{{totalNum}}条租约信息</div>
  </div>
</div>

<app-bill-gathering></app-bill-gathering>
