import {Injectable} from "@angular/core";
import {RequestService} from "../../request.service";
import {AuthService} from "../../common/auth.service";
import {environment} from "../../../../environments/environment";
import {
  AddSiteSessionRequest,
  DictListResponse,
  EbSiteReserveDescResponse,
  EbSiteReserveListRequest,
  EbSiteReserveListResponse,
  EbSiteReserveSaveRequest,
  EbSiteReserveShelfRequest,
  EbSiteReserveUpdateRequest, SiteInvalidDateListResponse,
  SiteOrderManagerListRequest,
  SiteOrderPageResponse,
  SiteSessionResponse
} from "../../../model/ebusiness/ebusiness.model";

@Injectable()
export class EVenueService {

  // 获得场地列表
  private GET_VENUE_LIST = `${environment.apiHost}eb/site/siteReserve/selectPage`;
  // 场地添加
  private POST_VENUE_ADD = `${environment.apiHost}eb/site/siteReserve/addSiteDetail`;
  // 场地添加
  private POST_VENUE_EDIT = `${environment.apiHost}eb/site/siteReserve/modifySiteDetail`;
  // 场地详情
  private GET_VENUE_DETAIL = `${environment.apiHost}eb/site/siteReserve/siteDetail`;

  // 场地上下架
  private POST_VENUE_ONOFF = `${environment.apiHost}eb/site/siteReserve/upAndDownTheShelf`;


  // 场地注意事项标题列表
  private GET_VENUE_EXT_LIST = `${environment.apiHost}eb/site/siteReserve/getTitleList`;
  // 场地注意事项标题添加
  private POST_VENUE_EXT_ADD = `${environment.apiHost}eb/site/siteReserve/addTiltle`;


  // 获得场地排期
  private GET_VENUE_SESSION_DETAIL = `${environment.apiHost}eb/site/reserveSession/sessionDetail`;
  // 添加场地排期
  private POST_VENUE_SESSION_ADD = `${environment.apiHost}eb/site/reserveSession/addSession`;
  // 编辑场地排期
  private POST_VENUE_SESSION_UPDATE = `${environment.apiHost}eb/site/reserveSession/modifySession`;
  // 是否存在临时排期
  private GET_VENUE_HAS_VENUE = `${environment.apiHost}eb/site/invalidSession/getInvalidSessionList`;


  // 获得场地订单列表
  private GET_VENUE_ORDER_LIST = `${environment.apiHost}eb/site/orderManager/selectPage`;
  // 场地订单详情接口
  private GET_VENUE_ORDER_DETAIL = `${environment.apiHost}eb/site/orderManager/detail`;

  // 获取临时排期日历
  private GET_SITE_MONTH = `${environment.apiHost}eb/site/invalidSession/getByMonth`;
  // 根据日期查询临时排期列表
  private GET_SITE_SCHEDULE_LIST  = `${environment.apiHost}eb/site/invalidSession/getByDate`;
  // 临时排期查询上架中的场地列表
  private GET_SITE_LIST  = `${environment.apiHost}eb/site/siteReserve/getOnSiteList`;
  // 根据日期和场地查询临时排期详情
  private GET_SITE_SCHEDULE_DETAIL  = `${environment.apiHost}eb/site/invalidSession/getByDateAndSiteId`;
  // 新增临时排期
  private SITE_SCHEDULE_ADD  = `${environment.apiHost}eb/site/invalidSession/addInvalids`;
  // 临时排期判断是否影响未使用订单
  private HAS_ORDERBY_DATE  = `${environment.apiHost}eb/site/invalidSession/checkInvalidSession`;
  // 删除临时排期
  private SITE_SCHEDULE_DELETE  = `${environment.apiHost}eb/site/invalidSession/deleteInvalids`;
  // 编辑临时排期
  private SITE_SCHEDULE_MODIFY  = `${environment.apiHost}eb/site/invalidSession/modifyInvalids`;
  // 查询场地预约详情
  private GET_SITE_STATUS_LIST  = `${environment.apiHost}eb/site/reserveStatus/list`;
  // 场地是否已设置过排期
  private GET_HAS_SITE_SESSION  = `${environment.apiHost}eb/site/reserveSession/hasSiteSession`;
  // 设置订单规则
  private SITE_ORDER_RULE_ADD  = `${environment.apiHost}eb/site/orderRule/addOrderRule`;
  // 编辑订单规则
  private SITE_ORDER_RULE_MODIFY  = `${environment.apiHost}eb/site/orderRule/modifyOrderRule`;
  // 查询订单规则
  private SITE_ORDER_RULE_QUERY  = `${environment.apiHost}eb/site/orderRule/orderRuleDetail`;
  // 券码校验接口
  private SITE_VERIFCATION_CHECK  = `${environment.apiHost}eb/site/couponCode/verificationCheck`;
  // 场地券码核销
  private SITE_VERIFCATION  = `${environment.apiHost}eb/site/couponCode/verification`;
  // 场地排序列表接口
  private GET_SORT_LIST  = `${environment.apiHost}eb/site/siteReserve/getSortList`;
  // 场地排序接口
  private SITE_SORT  = `${environment.apiHost}eb/site/siteReserve/sort`;

  constructor(
    private auth: AuthService,
    private requestService: RequestService
  ) {

  }

  /**
   * 场地列表
   */
  getVenueList(req?: EbSiteReserveListRequest): Promise<EbSiteReserveListResponse> {
    return this.requestService.authGet(this.GET_VENUE_LIST, req);
  }


  changeOnOff(req: EbSiteReserveShelfRequest) {
    return this.requestService.authPost(this.POST_VENUE_ONOFF, req);
  }


  /**
   * 添加场地
   */
  addVenue(req?: EbSiteReserveSaveRequest): Promise<any> {
    return this.requestService.authPost(this.POST_VENUE_ADD, req);
  }

  /**
   * 编辑场地
   */
  editVenue(req?: EbSiteReserveUpdateRequest): Promise<any> {
    return this.requestService.authPost(this.POST_VENUE_EDIT, req);
  }

  getVenueDetail(id?: number): Promise<EbSiteReserveDescResponse> {
    return this.requestService.authGet(this.GET_VENUE_DETAIL, {site_id: id});
  }


  getVenueExtList(): Promise<DictListResponse> {
    return this.requestService.authGet(this.GET_VENUE_EXT_LIST);
  }

  addVenueExt(req: string): Promise<any> {
    return this.requestService.authPost(this.POST_VENUE_EXT_ADD, {dict_value: req});
  }


  /**
   * 场地订单列表
   */
  getVenueOrderList(req?: SiteOrderManagerListRequest): Promise<SiteOrderPageResponse> {
    return this.requestService.authGet(this.GET_VENUE_ORDER_LIST, req);
  }
  /**
   * 场地订单详情
   */
   getVenueOrderDetail(req?: any): Promise<any> {
    return this.requestService.authGet(this.GET_VENUE_ORDER_DETAIL, req);
  }
  /**
   * 获取临时排期日历
   */
  getSiteMonth(req:any): Promise<any> {
    return this.requestService.authGet(this.GET_SITE_MONTH, req);
  }
  /**
   * 根据日期查询临时排期列表
   */
  getSiteScheduleList(req:any): Promise<any> {
    return this.requestService.authGet(this.GET_SITE_SCHEDULE_LIST, req);
  }
  /**
   * 临时排期查询上架中的场地列表
   */
  getSiteList(req:any): Promise<any> {
    return this.requestService.authGet(this.GET_SITE_LIST, req);
  }
  /**
   * 根据日期和场地查询临时排期详情
   */
   getSiteScheduleDetail(req:any): Promise<any> {
    return this.requestService.authGet(this.GET_SITE_SCHEDULE_DETAIL, req);
  }
  /**
   * 新增设置排期
   */
   siteScheduleAdd(req:any): Promise<any> {
    return this.requestService.authPost(this.SITE_SCHEDULE_ADD, req);
  }
  /**
   * 临时排期判断是否影响未使用订单
   */
   hasOrderByDate(req:any): Promise<any> {
    return this.requestService.authPost(this.HAS_ORDERBY_DATE, req);
  }
  /**
   * 删除临时排期
   */
   siteScheduleDel(req:any): Promise<any> {
    return this.requestService.authPost(this.SITE_SCHEDULE_DELETE, req);
  }
  /**
   * 编辑临时排期
   */
   siteScheduleModify(req:any): Promise<any> {
    return this.requestService.authPost(this.SITE_SCHEDULE_MODIFY, req);
  }
  /**
   * 查询场地预约详情
   */
   getSiteStatusList(req:any): Promise<any> {
    return this.requestService.authGet(this.GET_SITE_STATUS_LIST, req);
  }
  /**
   * 场地是否已设置过排期
   */
   getHasSiteSession(req:any): Promise<any> {
    return this.requestService.authGet(this.GET_HAS_SITE_SESSION, req);
  }
  siteOrderRuleAdd(req: any): Promise<any> {
    return this.requestService.authPost(this.SITE_ORDER_RULE_ADD, req);
  }
  siteOrderRuleModify(req: any): Promise<any> {
    return this.requestService.authPost(this.SITE_ORDER_RULE_MODIFY, req);
  }
  siteOrderRuleQuery(req: any): Promise<any> {
    return this.requestService.authGet(this.SITE_ORDER_RULE_QUERY, req);
  }


  getVenueSession(id: number): Promise<SiteSessionResponse> {
    return this.requestService.authGet(this.GET_VENUE_SESSION_DETAIL, {site_id: id});
  }

  addVenueSession(req: AddSiteSessionRequest): Promise<any> {
    return this.requestService.authPost(this.POST_VENUE_SESSION_ADD, req);
  }

  updateVenueSession(req: AddSiteSessionRequest): Promise<any> {
    return this.requestService.authPost(this.POST_VENUE_SESSION_UPDATE, req);
  }

  getInvalidSession(siteId: number) : Promise<SiteInvalidDateListResponse> {
    return this.requestService.authGet(this.GET_VENUE_HAS_VENUE, {site_id: siteId});
  }

  siteVerifcationCheck(req: any) : Promise<any> {
    return this.requestService.authGet(this.SITE_VERIFCATION_CHECK, req);
  }

  siteVerifcation(req: any) : Promise<any> {
    return this.requestService.authPost(this.SITE_VERIFCATION, req);
  }
  getSortList() : Promise<any> {
    return this.requestService.authGet(this.GET_SORT_LIST);
  }
  siteSort(req: any) : Promise<any> {
    return this.requestService.authPost(this.SITE_SORT, req);
  }
}
