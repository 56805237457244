import {Injectable} from '@angular/core';
import {RequestService} from '../request.service';
import {environment} from '../../../environments/environment';
import {InvoiceDetailRequest, InvoiceDetailResponse, InvoiceListRequest, InvoiceListResponse} from "../../model/invoice/invoice.model";

/**
 * @description:开票相关服务
 *
 * @Author: xulihua
 * @Date: 2018-02-27 15:31:31
 * @Last Modified by: xulihua
 * @Last Modified time: 2018-02-27 15:31:31
 */
@Injectable()
export class InvoiceService {

  // 可开票账单列表
  private invoiceBillListUrl = `${environment.apiHost}invoice/bill/list`;
  // 开票新增/编辑
  private invoiceSaveUrl = `${environment.apiHost}invoice/save`;
  //开票运营列表
  private INVOICE_LIST_URL = `${environment.apiHost}invoice/list`;
  //开票寄送
  private invoiceSendUrl = `${environment.apiHost}invoice/send`;
  //企业银行信息
  private companyDetailUrl = `${environment.apiHost}invoice/company/detail`;
  //开票日志列表
  private invoiceLogListUrl = `${environment.apiHost}invoice/log/list`;

  public billTypeListUrl = `${environment.apiHost}invoice/billType/list`;

  //财务处理列表
  private INVOICE_DISPOSE_LIST_URL = `${environment.apiHost}invoice/dispose/list`;

  //发票详情
  private INVOICE_DETAIL_URL = `${environment.apiHost}invoice/detail`;

  //处理确认  审核通过/拒绝
  private INVOICE_DISPOSE_CONFIRM_URL = `${environment.apiHost}invoice/dispose/confirm`;

  //签收
  private INVOICE_SIGN_URL = `${environment.apiHost}invoice/sign`;

  //签收
  private INVOICE_SIGN_LIST_URL = `${environment.apiHost}invoice/sign/list`;

  //签收检查
  private INVOICE_SIGN_CHECK_URL = `${environment.apiHost}invoice/sign/check`;

  //跳转签收
  private INVOICE_TOSIGN_URL = `${environment.apiHost}invoice/tosign`;

  //取消签收
  private INVOICE_CANCEL_SIGN_URL = `${environment.apiHost}invoice/cancelsign`;

  constructor(private requestService: RequestService) {
  }

  /**
   *  可开票账单列表
   */
  public invoiceBillList(requestData: any): Promise<any> {
    return this.requestService.authGet(this.invoiceBillListUrl, requestData);
  }

  /**
   *  开票新增/编辑
   */
  public invoiceSave(requestData: any): Promise<any> {
    return this.requestService.authPost(this.invoiceSaveUrl, requestData);
  }

  /**
   *  开票运营列表
   */
  public invoiceList(requestData: any): Promise<any> {
    return this.requestService.authGet(this.INVOICE_LIST_URL, requestData);
  }

  public invoiceListWithLoading(requestData: any): Promise<any> {
    return this.requestService.authGet(this.INVOICE_LIST_URL, requestData, true);
  }

  /**
   * 查询开票类型
   */
  public queryAllInvoiceType(): Promise<any> {
    return this.requestService.authGet(this.billTypeListUrl);
  }

  /**
   *  开票寄送
   */
  public invoiceSend(requestData: any): Promise<any> {
    return this.requestService.authPost(this.invoiceSendUrl, requestData);
  }

  /**
   *  企业银行信息
   */
  public companyDetail(requestData: any): Promise<any> {
    return this.requestService.authGet(this.companyDetailUrl, requestData);
  }

  /**
   * 操作日志列表
   */
  public invoiceLogList(requestData: any): Promise<any> {
    return this.requestService.authGet(this.invoiceLogListUrl, requestData);
  }


  /**
   * 财务发票列表
   */
  public invoiceDisposeList(requestData: InvoiceListRequest): Promise<InvoiceListResponse> {
    return this.requestService.authGet(this.INVOICE_DISPOSE_LIST_URL, requestData);
  }

  public invoiceDisposeListWithLoading(requestData: InvoiceListRequest): Promise<InvoiceListResponse> {
    return this.requestService.authGet(this.INVOICE_DISPOSE_LIST_URL, requestData, true);
  }

  /**
   * 发票详情
   */
  public invoiceDetail(requestData: InvoiceDetailRequest): Promise<InvoiceDetailResponse> {
    return this.requestService.authGet(this.INVOICE_DETAIL_URL, requestData);
  }

  /**
   * 处理确认  审核通过/拒绝
   */
  public invoiceDispose(requestData: InvoiceDetailRequest): Promise<any> {
    return this.requestService.authPost(this.INVOICE_DISPOSE_CONFIRM_URL, requestData);
  }

  /**
   * 签收
   */
  public invoiceSign(requestData: InvoiceDetailRequest): Promise<any> {
    return this.requestService.authPost(this.INVOICE_SIGN_URL, requestData);
  }

  /**
   * 签收列表
   */
  public invoiceSignList(requestData: InvoiceDetailRequest): Promise<InvoiceListResponse> {
    return this.requestService.authGet(this.INVOICE_SIGN_LIST_URL, requestData);
  }

  /**
   * 签收检查
   */
  public invoiceSignCheck(requestData: InvoiceDetailRequest): Promise<any> {
    return this.requestService.authGet(this.INVOICE_SIGN_CHECK_URL, requestData);
  }

  /**
   * 跳转签收
   */
  public invoiceToSign(requestData: InvoiceDetailRequest): Promise<any> {
    return this.requestService.authPost(this.INVOICE_TOSIGN_URL, requestData);
  }

  /**
   * 取消签收
   */
  public invoiceCancelSign(requestData: InvoiceDetailRequest): Promise<any> {
    return this.requestService.authPost(this.INVOICE_CANCEL_SIGN_URL, requestData);
  }
}
