<div class="setbox padd15 list">
  <div class="row">
    <div class="col-12 mb10" *ngIf="btn_privileges.ADD_CARPORT">
      <button [routerLink]="['/system_manage/signed/garageadd']" class="btn btn-primary" type="button" *ngIf="btn_privileges.ADD_CARPORT">
        <i class="fa fa-plus" aria-hidden="true"></i>新增车库/位
      </button>
      <button [routerLink]="['/system_manage/roomgroup/list']" class="btn btn-warning" style="margin-left: 10px"
              type="button">
        <i class="fa fa-refresh" aria-hidden="true"></i>切换至房源
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-2 mb10">
      <p>社区名称</p>
      <select class="w-100">
        <option value="{{hotelProject?.id}}">{{hotelProject?.project_name}}</option>
      </select>
    </div>
    <div class="col-12 col-md-2 mb10">
      <p>状态</p>
      <select class="w-100" [(ngModel)]="requestList.carport_status">
        <option value="ALL">全部</option>
        <option value="PUTAWAY">生效中</option>
        <option value="SOLD_OUT">已下架</option>
      </select>
    </div>
    <div class="col-6 col-md-1 mb10">
      <button class="btn btn-primary mg21" type="button" (click)="getList()">
        <i class="fa fa-search"></i>搜索
      </button>
    </div>
    <div class="col-6 col-md-1 mb10">
      <button class="btn btn-secondary mg21" type="button" (click)="clear()">
        <i class="fa fa-search"></i>清空条件
      </button>
    </div>
  </div>
  <!-- table -->
  <table class="table table-bordered table-striped">
    <thead>
    <tr>
      <th>车库/位编号</th>
      <th class="show2">所在社区</th>
      <th>租金</th>
      <th class="show2">创建时间</th>
      <th>状态</th>
      <th>操作</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="carportSearchList == null || carportSearchList.length == 0">
      <td class="show1" colspan="4">本次未查询到相关数据</td>
      <td class="show2" colspan="6">本次未查询到相关数据</td>
    </tr>
    <ng-container *ngFor="let item of carportSearchList | paginate: {itemsPerPage: requestList.page_size,
          currentPage: requestList.page_num,totalItems: total }">
      <tr>
        <td>{{item?.carport_number}}</td>
        <td class="show2">{{item?.project_name}}</td>
        <td>{{item?.month_price}}元/月</td>
        <td class="show2">{{item?.create_time | date:'yyyy-MM-dd HH:mm:ss'}}</td>
        <td>
          <span *ngIf="'PUTAWAY' == item?.carport_status" style="color: #00B8D4;">生效中</span>
          <span *ngIf="'SOLD_OUT' == item?.carport_status" style="color: #A1A1A1;">已下架</span>
        </td>
        <td>
          <div class="show1">
            <button type="button" class="btn" (click)="slodOutCarport(staticModal,item.carport_info_id)"
                    [disabled]="'PUTAWAY' !== item?.carport_status" [ngClass]="{'btn-info': 'PUTAWAY' == item?.carport_status}" *ngIf="btn_privileges.TAKE_OFF_CARPORT">
              <i class="anticon anticon-upload ng-star-inserted i-rotate"></i>
            </button>
          </div>
          <div class="show2">
            <button type="button" class="btn" (click)="slodOutCarport(staticModal,item.carport_info_id)"
                    [disabled]="'PUTAWAY' !== item?.carport_status" [ngClass]="{'btn-info': 'PUTAWAY' == item?.carport_status}" *ngIf="btn_privileges.TAKE_OFF_CARPORT">
              <i class="anticon anticon-upload ng-star-inserted i-rotate"></i>下架
            </button>
          </div>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <div *ngIf=" carportSearchList != null && carportSearchList?.length > 0"
       class="text-right pages">
    <pagination-controls (pageChange)="pageChange($event)" directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                         screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                         screenReaderCurrentLabel="You're on page"></pagination-controls>
    <div class="text-right">共有{{total}}条记录</div>
  </div>
</div>



<div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="staticModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h3 class="text-center model-text">该车库/位下架后，将不可用于新合同的出租及旧合同的续租。确认下架吗？</h3>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn" (click)="configSubmit(staticModal)">是</button>
        <button type="button" class="btn btn-primary" (click)="cancelSubmit(staticModal)">否</button>
      </div>
    </div>
  </div>
</div>




