import {Pipe, PipeTransform} from "@angular/core";

/**
 * @Description: 房源状态管道
 * @Author: 周嘉豪
 * @Date: 2018/05/07 上午10:26
 * @Last Modified by: 周嘉豪
 * @Last Modified time: 2018/3/29 上午10:26
 */
@Pipe({
  name: 'roomGroupStatusPipe'
})
export class RoomGroupStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const status = {
      AUDIT: '审核中',
      EFFECTIVE: '生效中',
      REMOVE: '已下架',
      CANCEL: '已取消',
      REJECT: '已拒绝',
    };
    return status[value];
  }
}
