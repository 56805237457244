import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PathChildGuard} from './core/guard/path.child.guard';
import {PrivilegeResolve} from './core/resolve/privilege.resolve';
import {LoginComponent} from './view/user/login/login.component';
import {RegComponent} from "./view/user/reg/reg.component";
import {ResetPasswordComponent} from './view/user/reset-password/reset-password.component';
import {MobileSafeWarnComponent} from "./view/user/mobile-safe-warn/mobile-safe-warn.component";
import {Page404Component} from "./view/page404/page404.component";

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'alarm',
    component: MobileSafeWarnComponent
  },
  {
    path: 'reset_password',
    component: ResetPasswordComponent
  },
  {
    path: 'reg',
    component: RegComponent
  },
  {
    path: '',
    redirectTo: 'system_manage',
    pathMatch: 'full'
  },
  {
    path: 'system_manage',
    data: {
      preload: true,
      OPERATION_PRIVILEGES: [
        'HOTEL_ADD',
        'USER_RENTER_ADD',
        'COMPANY_RENTER_ADD',
        'SCHEDULE_LIST'
      ]
    },
    canActivateChild: [PathChildGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./view/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { preload: true }
      }
    ],
    resolve: {
      operation_priviliges: PrivilegeResolve
    }
  },
  // 运营大脑 单独框架头
  {
    path: "ob",
    loadChildren: () => import('./view/operating-brain/operating-brain.module').then(m => m.OperatingBrainModule),
    canActivateChild: [PathChildGuard],
    resolve: {
      operation_priviliges: PrivilegeResolve
    }
  },
  // pad签字版 单独框架
  {
    path: "padsign",
    loadChildren: () => import('./view/padsign/padsign.module').then(m => m.PadsignModule),
    canActivateChild: [PathChildGuard],
    resolve: {
      operation_priviliges: PrivilegeResolve
    }
  },
  {
    path: "**",
    component: Page404Component
  }
];

@NgModule({
  // 注意：forRoot 代表当作根路由
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
exports: [RouterModule]
})
export class AppRoutingModule {
}
