import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RoomChangeService } from '../../../../service/lease/roomchange.service';
import { RoomChangeBillsReq, RoomChangeCommonReq, RoomChangeRecordConfirmReq } from '../../../../model/lease/roomchange.model';
import { CheckoutRoomAffirmRequest } from '../../../../model/signed/checkout.model';
import { DialogComponent } from '../../../shared/dialog/dialog.component';
import {AuthService} from "../../../../service/common/auth.service";
import {
  QueryAccountLeaseRequest,
  QueryAccountLeaseResponse,
  UploadPaymentCertificateResponse
} from "../../../../model/clientinfo/client-info.model";
import {QueryAccountNameResponse} from "../../../../model/workbench/workbench.model";
import {ClientInfoService} from "../../../../service/clientinfo/ClientInfoService";

@Component({
  selector: 'app-cr-change-room-confirm',
  templateUrl: './cr-change-room-bill-detail.component.html',
  styleUrls: ['./cr-change-room-bill-detail.component.css']
})
export class CrChangeRoomBillDetailComponent implements OnInit {

  record_id: number;

  lease_id: number;
  finance_details: any = {
    origin_room_bills: {
      receivable_total: '',
      amount_total: '',
      receivable_items: [],
      retreated_items: []
    },
    prepay_account: {},
    new_room_bills: {},
    room_change_info: {
      record_number: ''
    }
  };

  confirm_req: CheckoutRoomAffirmRequest = new CheckoutRoomAffirmRequest();

  no_bill: boolean = false;

  next_disable: boolean = false;

  clientUserNameMoneyId: number = 0;
  queryAccountNameRsp: QueryAccountNameResponse = new QueryAccountNameResponse();
  queryAccountLeaseReq: QueryAccountLeaseRequest = new QueryAccountLeaseRequest();
  queryAccountLeaseResponse: QueryAccountLeaseResponse = new QueryAccountLeaseResponse();

  @ViewChild(DialogComponent, { static: true }) dialogComponent: DialogComponent;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private roomChangeService: RoomChangeService,
              private authService: AuthService,
              private clientInfoService: ClientInfoService,) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.record_id = params['record_id'];
      this.getRoomChangeFinanceDetails();
    });
  }

  getRoomChangeFinanceDetails() {
    this.roomChangeService.getFinanceDetails(new RoomChangeBillsReq(this.record_id)).then(data => {
      // this.finance_details = data;
      Object.assign(this.finance_details, data);

      this.lease_id = data.lease_id;
      // 如果是退款，默认是现金
      if (this.finance_details.operate_flag === 'REFUNDOUT') {
        this.confirm_req.checkout_bill_pay_type_enum = 'ACCOUNT_MONEY';
      }

      this.finance_details.origin_room_bills.receivable_total = Number(this.finance_details.origin_room_bills.receivable_total);
      this.finance_details.new_room_bills.receivable_total = Number(this.finance_details.new_room_bills.receivable_total);
      this.finance_details.origin_room_bills.retreated_total = Number(this.finance_details.origin_room_bills.retreated_total);
      this.finance_details.new_room_bills.retreated_total = Number(this.finance_details.new_room_bills.retreated_total);
      this.queryAccountLeaseReq.lease_id = this.lease_id;
      this.clientInfoService.queryAccountLease(this.queryAccountLeaseReq).then(rep => {
        this.queryAccountLeaseResponse = rep;
      });
    });
  }

  getAmount() {
    let bill_id_list: number[] = [];
    if (this.finance_details.origin_room_bills.bill_ids && this.finance_details.origin_room_bills.bill_ids.length > 0) {
      this.finance_details.origin_room_bills.bill_ids.forEach(item => {
        bill_id_list.push(item);
      });
    }
    if (this.finance_details.new_room_bills.bill_ids && this.finance_details.new_room_bills.bill_ids.length > 0) {
      this.finance_details.new_room_bills.bill_ids.forEach(item => {
        bill_id_list.push(item);
      });
    }
    this.dialogComponent.gatherCheckOutAmount(this.finance_details.room_change_info.lease_id,
      bill_id_list,
      this.finance_details.total_amount,
      'CHANGEROOM',
      this.finance_details.contract_num,
      this.finance_details.coupon_recycle_amount
      );
  }

  refresh() {
    this.roomChangeService.storeHistory(new RoomChangeCommonReq(this.record_id)).then(data => {
      this.finance_details.gathered = true;
    });
  }

  goNext() {
    if (this.canGoNext()) {
      this.next_disable = true;

      let req = new RoomChangeRecordConfirmReq();
      req.record_id = this.record_id;

      if (this.finance_details.operate_flag === 'GATHERING') {
        req.no_bill = this.no_bill;
        if (req.no_bill) {
          req.remark = this.confirm_req.remark;
        }
      } else if (this.finance_details.operate_flag === 'REFUNDOUT') {
        req.checkout_bill_pay_type_enum = this.confirm_req.checkout_bill_pay_type_enum;

        if (req.checkout_bill_pay_type_enum === 'TRANSFER') {
          req.bank_no = this.confirm_req.bank_no;
          req.open_bank = this.confirm_req.open_bank;
          req.refund_name = this.confirm_req.refund_name;
        }
        if (req.checkout_bill_pay_type_enum === 'ACCOUNT_MONEY') {
          req.account_money_info_id = this.clientUserNameMoneyId;
        }
      }

      this.roomChangeService.roomChangeConfirm(req).then(data => {
        // if(this.finance_details.lease_step === 'INTERNAL_LEASE'){
        //   this.router.navigateByUrl('/system_manage/signed/pact/' + this.finance_details.room_change_info.lease_id);
        // } else {
          // this.router.navigateByUrl('/system_manage/signed/change/room/contract/' + this.record_id);
          let needGoodList = this.authService.getProjectInfo().need_room_goods_list;
          //如果项目需要签署物品交接单
          if (needGoodList === 1) {
            // 跳到入住交接单
            this.router.navigateByUrl('/system_manage/signed/change/room/confirm/' + this.record_id);
          } else {
            //如果项目不需要签署物品交接单，直接跳到上传换房合同
            this.router.navigateByUrl('/system_manage/signed/change/room/contract/' + this.record_id);
          }
        // }
      }).catch(e => {
        this.next_disable = false;
      });
    }
  }

  canGoNext(): boolean {
    /*if (this.finance_details.operate_flag === 'GATHERING') {
      return this.finance_details.gathered || this.finance_details.total_amount === 0
        || (this.no_bill && this.confirm_req.remark && this.confirm_req.remark.length > 0);
    } else*/ if (this.finance_details.operate_flag === 'REFUNDOUT') {
      return this.confirm_req.checkout_bill_pay_type_enum === 'ACCOUNT_MONEY' && this.clientUserNameMoneyId !== 0 ||
        this.confirm_req.checkout_bill_pay_type_enum === 'TRANSFER'
        && this.confirm_req.refund_name && this.confirm_req.refund_name.length > 0
        && this.confirm_req.open_bank && this.confirm_req.open_bank.length > 0
        && this.confirm_req.bank_no && this.confirm_req.bank_no.length > 0;
    } else {
      return true;
    }
  }
  clientShiftFocus(e) {
    this.checkRequiredClick();
  }
  checkRequiredClick(){
    if(this.clientUserNameMoneyId){
      //this.isBtn = true;
    }else{
      //this.isBtn = false;
    }
  }
}
