import {List} from "underscore";
import {LeaseRoomBillListDTO} from "../finance/bill.model";
import {LeaseRenterInfoDTO} from '../renter/renter.model';
import {LeaseBaseInfoDTO, LeaseExpenseInfoDTO} from '../lease/lease.model';
import {SysUser} from '../user/user.model';
import {RoomEquipmentIndicationDTO, RoomInfoDTO} from "../room/room.model";
import {RoomGoodsInfoDTO} from './inventory.model';
import {PageReq, PageResp} from "../page/page";

export class CheckoutRoomViewRequest {
  lease_id?: number;
  room_ids?: Array<number>;
}

export class CheckoutRoomViewResponse {
  checkout_room_list?: Array<CheckoutRoomViewDTO>;
  lease_info: LeaseBaseInfoDTO;
  //退房原因
  checkoutReason: string;
}

export class CheckoutRoomViewNewResponse {
  // @Remark("房间交接单列表")
  checkout_inventory_list_dtos: Array<CheckoutRoomViewNewDTO>;
// @Remark("处理人")
  manage_man: string;
  // @Remark("项目名")
  project_name: string;

  // 是否需要刷新等待读数
  need_refresh_reading?: boolean;
}

export class CheckoutRoomViewNewDTO {
  // @Remark("房间信息")
  room_info_dto: RoomInventoryDTO;
  // @Remark("租约房间信息")
  lease_room_info_dto: LeaseRoomInventoryDTO;
  // @Remark("是否有冷水表")
  water_dev: boolean;
  // @Remark("是否有热水表")
  hot_water_dev: boolean;
  // @Remark("是否有电表")
  electricity_dev: boolean;
  // @Remark("需要电表 YES NO")
  need_electricity: string;
  // @Remark("需要冷水表 YES NO")
  need_water: string;
  // @Remark("需要热水表 YES NO")
  need_hot_water: string;
  // @Remark("物品交接单信息")
  room_good_relation_dto: RoomGoodDTO;
  // @Remark("房间信息")
  water_reading?;
  hot_water_reading?;
  electricity_reading?;

  water_reading_temp?;
  hot_water_reading_temp?;
  electricity_reading_temp?;

  // @Remark("水表数读取时间")
  water_reading_time?: number;
  // @Remark("热水表数读取时间")
  hot_water_reading_time?: number;
  // @Remark("电表数读取时间")
  electricity_reading_time?: number;

  details?: Array<any>;
  out_room_details_type?: boolean;
}

export class RoomInventoryDTO {
  // @Remark("房间号")
  room_name: string;
}

export class LeaseRoomInventoryDTO {
  // @Remark("房间id")
  lease_room_id: number;

}

export class RoomGoodDTO {
  // @Remark("房间id")
  lease_room_id: number;
  // @Remark("分类列表")
  room_goods_info_category_dtolist: Array<GoodRoomCategoryDTO>;
  // @Remark("交接单物品")
  room_goods_info_dtolist: Array<GoodRoomCategoryDTO>;
}

export class GoodRoomCategoryDTO {
  category_name: string;
  room_goods_info_dtolist: Array<RoomGoodDtolistInfoDTO>;
}

export class RoomGoodDtolistInfoDTO {
  id?: number;
  project_id?: number;
  lease_id?: number;
  room_id?: number;
  lease_room_id?: number;
  good_name?: number;
  position?: number;
  sort?: number;
  price?: string;
  unit?: string;
  quantity?: number;
  is_bad?: string;
  init_bad?: string;
  bad_degree?: string = '1';
  compensate_price?: number;
  create_user_id?: number;
  affirm_user_id?: number;
  sys_company_id?: number;

  // custom


  partical_damage?: number;
  full_damage?: number;
  lose?: number;
}


export class CheckoutRoomViewDTO {
  // @Remark("房间ID")
  room_id?: number;
  // @Remark("租约房间ID")
  lease_room_id?: number;
  // @Remark("房型名")
  room_type_name?: string;
  // @Remark("房号")
  room_number?: string;
  // @Remark("房间名")
  room_name?: string;
  // @Remark("房间价格")
  base_price: string;
  // @Remark("房间押金总和计")
  cash_pledge?: string;
  // @Remark("入住人列表")
  renter_list?: Array<LeaseRenterInfoDTO>;
  // @Remark("入住时间")
  checkin_time?: Date;
  // @Remark("退房时间也叫账务终止日")
  checkout_time?: Date;
  // @Remark("预付账户余额")
  prepay_total?: string;
  // @Remark("是否欠款-true:欠款")
  arrearage?: boolean;
  checkoutInventory: boolean = false;
  roomCheck: boolean = false;
  // @Remark("NORMAL:正常按月 MULTI:灵活租金")
  rent_type: string;
  // @Remark("是否存在付加费用")
  expense: boolean;
  // @Remark("欠款账单条数")
  arrearage_count: number;
  // @Remark("是否违约")
  violate: string;
  // 附加费用列表
  lease_expense_info_dtolist?: Array<LeaseExpenseInfoDTO>;
  // @Remark("资产清单是否确认 UNCOINFIRMED-未确认， CONFIRMED-确认'")
  asset_confirm?: string;

  no_more_fee?: boolean;

  no_more_fee_audit?: boolean;

  need_room_goods_list: number;

  rei_dto: RoomEquipmentIndicationDTO;

  check?: boolean;
}

export class CheckoutRoomAffirmRequest {
  lease_id?: number;
  checkout_view_dtolist?: Array<CheckoutViewDTO>;
  refund_total: number;
  refund_name: string;
  open_bank: string;
  bank_no: string;
  remark: string;
  total?: string;
  checkout_bill_pay_type_enum?: string;
  checkout_finance_type_enum?: string;

  sign_img?: string;

  refund_id?: number;

  //退房原因
  checkoutReason: string;

  // @Remark("不收逾期费")
  no_overdue?: boolean;
  // @Remark("不收违约金")
  no_breaking?: boolean;
  // @Remark("损坏的物品列表")
  damage_goods_list?: Array<RoomGoodsInfoDTO>;

  account_money_info_id;
}

export class CheckoutViewRequest {
  lease_id?: number;
  checkout_view_dtolist?: Array<CheckoutViewDTO>;
}

export class CheckoutViewDTO {
  room_id?: number;
  checkout_type?: string;

  // @Remark("租约房间ID")
  lease_room_id?: number;
}

export class CheckoutViewResponse {
  lease_info?: LeaseBaseInfoDTO;
  check_out_room_info_dto?: CheckOutRoomInfoDTO;
  checkout_room_dtolist?: Array<CheckoutRoomDTO>;

  refund_transfer?: CheckOutRoomInfoDTO;

  refund_info_dto: RefundInfo;
  not_receivable_remark: string;

  sign_img?: string;
}

export class RefundInfo {
  // @Remark("退款ID")
  refund_id: number;

  // @Remark("退款金额")
  refund_amount: number;

  // @Remark("退款方式")
  refund_type_id: number;

  // @Remark("退款状态")
  refund_status: string;

  // @Remark("退款时间")
  refund_time: Date;

  // @Remark("退款操作人")
  refund_user_id: number;

  // @Remark("合同ID")
  lease_id: number;

  // @Remark("收款人")
  payee: string;

  // @Remark("开户银行")
  open_bank: string;

  // @Remark("银行卡号")
  bank_no: string;

  // @Remark("审核人")
  audit_user_id: number;

  // @Remark("审核时间")
  audit_time: Date;

  // @Remark("审核状态")
  audit_status: string;

  // @Remark("审核描述")
  audit_describe: string;

  // @Remark("备注")
  remark: string;

  // @Remark("创建提交人")
  create_user_id: number;

  // @Remark("项目id")
  project_id: number;

  // @Remark("退款类型名称")
  pay_type_name: string;

  refund_type?: string;

  refund_finance_type?: string;
}

export class CheckOutRoomInfoDTO {
  room_id?: number;
  // 房间号
  room_name?: string;
  // 应收总计
  receivable_total?: string;
  // 应退总计
  retreated_total?: string;
  // 应收项
  receivable_items?: Array<ObjectDTO>;
  // 应退项
  retreated_items?: Array<ObjectDTO>;
  // 回收项
  recycle_items?: Array<ObjectDTO>;
  // 合计
  amount_total?: string;
  // 未完成收款账单ID集合
  bill_ids?: Array<number>;

  // 自动抵充后的数据集合
  st_after_list?:Array<ObjectDTO>;

  // 应收住客分摊费用
  renter_bill_list:Array<RenterBillDTO>;

  //是否收支抵扣
  deduction?: boolean;
}

export class RenterBillDTO{
  renter_name:string;
  renter_bill_items:Array<ObjectDTO>;
}

export class ObjectDTO {
  object_id?: any;
  object_code?: any;
  object_name?: any;
  object_value?: any;
}

export class CheckoutRoomDTO {
  room_id?: number;
  lease_room_id?: number;
  end_time?: number;
  checkout_type?: string;
  // @Remark("逾期天数")
  overdue_day?: number;
  check_out_room_info_dto?: CheckOutRoomInfoDTO;
}

export class CheckoutAuditListResponse {
  audit_user?: Array<SysUser>;
}

export class CheckoutNoOtherFeeRequest {
  // @Remark("租约ID")
  lease_id?: number;
  // @Remark("房间IDs")
  room_ids?: Array<number>;
  // @Remark("手机号")
  phone?: string;
  // @Remark("验证码")
  code?: string;
  overdueType: string;
  overdueContent: string;

  no_overdue;
  no_breaking;
}

export class SMSAuditCodeRequest {

  mobile?: string;
// @Remark("验证码类型：17 租约审核")
  code_type?: number;
  // @Remark("租约ID")
  lease_id?: number;

  no_overdue;
  no_breaking;
}


export class CheckoutFinanceRoomListViewDTO {
  // @Remark("合同房间ID")
  lease_room_id?: number;
  // @Remark("房间ID")
  room_id?: number;
  // @Remark("房间名")
  room_name?: string;
  // @Remark("退房时间")
  checkout_time?: string;
  // @Remark("合同房间ID")
  status?: string;
  // @Remark("结算单号")
  checkout_id?: number;
  // @Remark("结算单号")
  checkout_num?: string;

  checked: boolean = false;

  renter_view_list:RenterViewList[];
}

export class RenterViewList{
  checkout_time:Date;
  renter_checkout_id:number;
  renter_info_id:number;
  renter_name:string;
  show_text:string;
  renter_checkout_number:string;
}

export class CheckoutFinanceRoomListResponse {
  checkout_finance_room_list_view_dtolist?: Array<CheckoutFinanceRoomListViewDTO>;
}

export class CheckoutViewNewDTO extends CheckoutViewDTO {
}

export class CheckoutRefundDTO {
  // @Remark("退款财务类型 退款/延迟/转收入")
  checkout_finance_type_enum?: string;
  // @Remark("退款类型 现金/银行卡/支付宝")
  checkout_bill_pay_type_enum?: string;
  // @Remark("收款人姓名")
  refund_name?: string;
  // @Remark("开户行")
  open_bank?: string;
  // @Remark("银行账号")
  bank_no?: string;
  // 客商账户id
  account_money_info_id?: number;
}

export class CheckoutFinanceRoomListViewRequest {
  lease_id?: number;
  // @Remark("房间退房类型")
  checkout_view_dtolist?: Array<CheckoutViewNewDTO>;
  // @Remark("不收逾期费")
  no_overdue?: boolean;
  // @Remark("不收违约金")
  no_breaking?: boolean;

  // @Remark("退房原因")
  checkout_reason?: string;
  // @Remark("退房信息")
  checkout_refund_dto?: CheckoutRefundDTO;

  damage_goods_list?: Array<RoomGoodsInfoDTO>;

  total;

  // @Remark("手机号")
  phone?: string;
  // @Remark("验证码")
  code?: string;
  overdue_type?: string;
  overdue_content?: string;
}

export class CheckoutDateRoomDTO {
  // @Remark("退房时间")
  checkout_time?: number;
  // @Remark("房间列表")
  room_names?: Array<string>;

  // @Remark("违约金总计")
  breaking_total?: string;
  // @Remark("剩余房费总计")
  not_payback_rent_total?: string;
  // @Remark("逾期金总计")
  overdue_total?: string;
  // @Remark("转租费总计")
  sublease_total?: string;

  checkout_type?: string;

  lease_room_ids?: Array<number>;

  show_before?: boolean;
}

export class LeaseRoomBillOverdueDTO extends LeaseRoomBillListDTO {
  overdue_amount?: string;

  overdue_day?: string;

  unreceived?: string;
}

export class LeaseRoomBillOverdueRoomGroupDTO {

}

export class CheckoutFinanceRoomListViewResponse extends CheckoutViewResponse {
  // @Remark("时间维度退房信息展示")
  checkout_date_room_dtos?: Array<CheckoutDateRoomDTO>;


  // @Remark("违约金总计")
  breaking_total?: string;
  // @Remark("逾期金总计")
  overdue_total?: string;
  // @Remark("转租费总计")
  sublease_total?: string;

  company_prepay_total?: string;

  total?: string;

  // @Remark("赔偿金总计")
  goods_breaking_total?: string;

  // @Remark("损坏的物品列表")
  damage_goods_list?: Array<RoomGoodsInfoDTO>;

  // @Remark("逾期金账单详情列表")
  overdue_lease_room_bill_list?: Array<LeaseRoomBillOverdueDTO>;

  overdue_room_group_dtos?: Array<LeaseRoomBillOverdueRoomGroupDTO>;

  //
  // // @Remark("租约信息")
  // lease_info?: LeaseBaseInfoDTO;
  // // @Remark("表头信息")
  // check_out_room_info_dto?: CheckOutRoomInfoDTO;
  // // @Remark("房间列表")
  // checkout_room_dtolist?: Array<CheckoutRoomDTO>;
  //
  // // @Remark("退款转收入列表")
  // refund_transfer?: CheckOutRoomInfoDTO;
  //
  // // @Remark("结算企业预付账户")
  // checkout_company?: boolean;

  // @Remark("签约主体")
  subject_name?: string;
  // @Remark("退房房间数")
  room_num?: number;
  // @Remark("退房单号")
  checkout_record_no?: string;
  // @Remark("退款信息")
  refund_dto?: CheckoutRefundDTO;

  // @Remark("是否展示退款")
  show_refund?: boolean;
  manage_name?: string;

  confirm_str?: string;


  has_overdue_fee?: boolean;

  //是否退款至客商账户
  is_refund_account_money?: boolean;
}


export class CheckoutConfirmRequest {
  checkout_id?: number;
}


export class CheckoutRenterListViewRequest {
  info_ids?: Array<number>;
  renter_id?: number;
  checkout_date?: number;
  lease_id?:number;
}

export class CheckoutRenterListViewResponse {

  lease_renter_dtos?: Array<LeaseRenterInfoDTO> ;

  room_info_dto?: RoomInfoDTO;

  check_out_room_info_dto?: CheckOutRoomInfoDTO ;

  renter_id?:number;
}

export class CheckoutRenterConfirmRequest {
  renter_id?: number;
  checkout_date?: number;
  checkout_refund_dto?: CheckoutRefundDTO;
  deduction?:boolean;
}

export class RenterCheckoutRecordListRequest extends PageReq{
  lease_id?: number;
  room_id?: number;
  search_context?: string;
}

export class RenterCheckoutRecordListResponse extends PageResp {
  renter_checkout_record_list?: Array<RenterCheckoutRecordListDTO>;
}

export class RenterCheckoutRecordListDTO{
  renter_id?: number;

  room_name?: string;

  renter_name?: string;

  check_in_time?: number;

  checkout_time?: number;

  renter_checkout_id?: number;

  renter_checkout_number?: string;

  in_arrears?: boolean;
}

export class RenterCheckoutRecordDetailRequest{
  record_id?: number;
}

export class RenterCheckoutRecordDetailResponse{
  lease_renter_dto?: LeaseRenterInfoDTO ;

  check_out_room_info_dto?: CheckOutRoomInfoDTO ;

  renter_checkout_number?: string;

  renter_checkout_refund_dto?: RenterCheckoutRefundDTO;

  operate_name?: string;

  affirm_time?: number;
}

export class RenterCheckoutRefundDTO extends CheckoutRefundDTO{
  account_money_info_name?: string;
}
