import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'invoiceStatusPipe'
})
export class InvoiceStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const status = {
      REJECTED: '已拒绝',
      INVOICING: '开票中',
      INVOICED: '已开票',
      WAIT_SENT: '待寄送',
      WAIT_SIGN: '待签收',
    };
    return status[value];
  }
}
