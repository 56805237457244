<div class="setbox-top flex" *ngIf="show">
  <div class="flex-content">
    <div class="bg-primary">退房确认</div>
    <div class="setbox text-dark">
      <h2 class="text-center">退房单</h2>
      <hr>
      <div class="row">
        <!--<div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">退房单号：xxxxxx</div>-->
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">合同号：{{checkoutViewResponse?.lease_info.contract_num}}</div>
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">合同周期:
          {{checkoutViewResponse?.lease_info.start_time|date:'yyyy.MM.dd'}}-{{checkoutViewResponse?.lease_info.end_time|date:'yyyy.MM.dd'}}
        </div>
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">退房日期: {{now|date:'yyyy.MM.dd'}}</div>
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">
          退房间数：{{checkoutViewResponse?.checkout_room_dtolist?.length}}
        </div>
      </div>


      <ng-container *ngIf="checkoutViewResponse?.check_out_room_info_dto?.receivable_total">
        <div class="box-table">
          <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
              <th class="w-25" scope="col">应收费用</th>
              <th class="w-25" scope="col">应收金额（元）</th>
              <th class="w-25" scope="col">应收费用</th>
              <th class="w-25" scope="col">应收金额（元）</th>
            </tr>
            </thead>
            <tbody *ngFor="let item of checkoutViewResponse?.check_out_room_info_dto?.receivable_items;let j = index">
            <tr *ngIf="j%2==0">
              <td *ngIf="j%2==0">{{item.object_code}}</td>
              <td *ngIf="j%2==0">{{item.object_value}}</td>
              <td
                *ngIf="j+1<checkoutViewResponse?.check_out_room_info_dto?.receivable_items.length && (j+1)%2==1">{{checkoutViewResponse?.check_out_room_info_dto?.receivable_items[j + 1].object_code}}</td>
              <td
                *ngIf="j+1<checkoutViewResponse?.check_out_room_info_dto?.receivable_items.length && (j+1)%2==1">{{checkoutViewResponse?.check_out_room_info_dto?.receivable_items[j + 1].object_value}}</td>
              <td *ngIf="j+1>=checkoutViewResponse?.check_out_room_info_dto?.receivable_items.length"></td>
              <td *ngIf="j+1>=checkoutViewResponse?.check_out_room_info_dto?.receivable_items.length"></td>
            </tr>
            </tbody>
            <tbody>
            <td class="bg-gray-200 font-weight-bold">应收总计（元）</td>
            <td class="text-right" colspan="3">{{checkoutViewResponse?.check_out_room_info_dto?.receivable_total}}</td>
            </tbody>
          </table>
        </div>
      </ng-container>

      <ng-container *ngIf="checkoutViewResponse?.check_out_room_info_dto?.retreated_total">
        <div class="box-table">
          <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
              <th class="w-25" scope="col">应退费用</th>
              <th class="w-25" scope="col">应退金额（元）</th>
              <th class="w-25" scope="col">应退费用</th>
              <th class="w-25" scope="col">应退金额（元）</th>
            </tr>
            </thead>
            <tbody *ngFor="let item of checkoutViewResponse?.check_out_room_info_dto?.retreated_items;let j = index">
            <tr *ngIf="j%2==0">
              <td *ngIf="j%2==0">{{item.object_code}}</td>
              <td *ngIf="j%2==0">{{item.object_value}}</td>
              <td
                *ngIf="j+1<checkoutViewResponse?.check_out_room_info_dto?.retreated_items.length && (j+1)%2==1">{{checkoutViewResponse?.check_out_room_info_dto?.retreated_items[j + 1].object_code}}</td>
              <td
                *ngIf="j+1<checkoutViewResponse?.check_out_room_info_dto?.retreated_items.length && (j+1)%2==1">{{checkoutViewResponse?.check_out_room_info_dto?.retreated_items[j + 1].object_value}}</td>
              <td *ngIf="j+1>=checkoutViewResponse?.check_out_room_info_dto?.retreated_items.length"></td>
              <td *ngIf="j+1>=checkoutViewResponse?.check_out_room_info_dto?.retreated_items.length"></td>
            </tr>
            </tbody>
            <tbody>
            <td class="bg-gray-200 font-weight-bold">应退总计（元）</td>
            <td class="text-right" colspan="3">{{checkoutViewResponse?.check_out_room_info_dto?.retreated_total}}</td>
            </tbody>
          </table>
        </div>
      </ng-container>
      <ng-container *ngIf="sumCouponTotal(checkoutViewResponse?.checkout_room_dtolist) > 0 ">
        说明：
        有{{checkoutViewResponse?.checkout_room_dtolist?.length}}间房回收未被使用预付费-代金券
        {{sumCouponTotal(checkoutViewResponse?.checkout_room_dtolist,"预付费")}}元；
        有{{checkoutViewResponse?.checkout_room_dtolist?.length}}间房回收未被使用房费-代金券
        {{sumCouponTotal(checkoutViewResponse?.checkout_room_dtolist,"房费")}}元；
      </ng-container>
      <ng-container *ngIf="!checkoutViewResponse?.check_out_room_info_dto?.retreated_total">
        <div class="row">
          <div class="col-12 mb-2"><b class="text-orange">应退总计：0.00元</b></div>
        </div>
      </ng-container>

      <hr>
      <div class="mb-3">
        <span class="text-orange mr-4">合计：<b class="font18">{{checkoutViewResponse?.check_out_room_info_dto?.amount_total}}</b>元</span>
      </div>


      <div class="card card-block mb-2" *ngIf="totalTag < 0">
        <h3><b class="font16">您可以操作</b></h3>
        <div class="mb-2 text-dark">
          <div class="row mb-3">
            <div class="col-12">退款方式：
              <div class="form-check form-check-inline">
                <input class="form-check-input cursor" type="radio" value="REFUND" name="refund_type"
                       [(ngModel)]="confirmReq.checkout_finance_type_enum">
                <label class="form-check-label">正常退款</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input cursor" type="radio" value="DELAY" name="refund_type"
                       [(ngModel)]="confirmReq.checkout_finance_type_enum">
                <label class="form-check-label">延期退款<span class="text-orange">（需要先退房，暂时不处理退款）</span></label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input cursor" type="radio" value="TRANSFER_IN" name="refund_type"
                       [(ngModel)]="confirmReq.checkout_finance_type_enum">
                <label class="form-check-label">退款转收入<span class="text-orange">（客户放弃退款）</span></label>
              </div>
            </div>
          </div>
        </div>

        <div class="" *ngIf="confirmReq.checkout_finance_type_enum==='REFUND'">
          <h3><b class="font16">退款账户</b></h3>
          <div class="mb-2 bg-gray text-dark">
            <div class="row mb-3">
              <div class="col-12">退款方式：
                <div class="form-check form-check-inline">
                  <input class="form-check-input cursor" type="radio" value="TRANSFER" name="refund_mode"
                         [(ngModel)]="confirmReq.checkout_bill_pay_type_enum">
                  <label class="form-check-label">转账</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input cursor" type="radio" value="CASH" name="refund_mode"
                         [(ngModel)]="confirmReq.checkout_bill_pay_type_enum">
                  <label class="form-check-label">现金</label>
                </div>
                <div class="text-danger" *ngIf="!confirmReq.checkout_bill_pay_type_enum">请选择退款方式</div>
              </div>
            </div>
            <div class="row" *ngIf="confirmReq.checkout_bill_pay_type_enum==='TRANSFER'">
              <div class="col-12 col-md-3 col-lg-2">
                <div class="form-group">
                  <label>收款人姓名：</label>
                  <input class="form-control" [(ngModel)]="confirmReq.refund_name" maxlength="20" required>
                  <div *ngIf="!confirmReq.refund_name" class="text-danger">收款姓名不能为空</div>
                </div>
              </div>
              <div class="col-12 col-md-3 col-lg-2">
                <div class="form-group">
                  <label>开户行：</label>
                  <input class="form-control" [(ngModel)]="confirmReq.open_bank" maxlength="50" required>
                  <div *ngIf="!confirmReq.open_bank" class="text-danger">开户行不能为空</div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label>银行账号：</label>
                  <input class="form-control" [(ngModel)]="confirmReq.bank_no" maxlength="50" required>
                  <div *ngIf="!confirmReq.bank_no" class="text-danger">银行账号不能为空</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="" *ngIf="confirmReq.checkout_finance_type_enum==='DELAY'">
          <h3><b class="font16">延期退款</b></h3>
          <div class="mb-2 bg-gray text-dark">
            <div class="row my-3 px-3">
              已选择延期退款，可直接提交退房，如需继续退款可从【工作台】-【我的待办】-【延期退款】模块继续操作退款。
            </div>
          </div>
        </div>

        <div class="" *ngIf="confirmReq.checkout_finance_type_enum==='TRANSFER_IN'">
          <h3><b class="font16">退款转收入明细</b></h3>
          <div class="mb-2 ">
            <div class="row my-3">
              <table class="table table-bordered">
                <thead class="thead-light">
                <tr>
                  <th>收支抵扣后剩余转收入费用</th>
                  <th>收支抵扣后剩余可转收入金额（元）</th>
                </tr>
                </thead>
                <tbody>

                <ng-container *ngIf="checkoutViewResponse.refund_transfer && checkoutViewResponse.refund_transfer.retreated_items">
                  <tr *ngFor="let item of checkoutViewResponse.refund_transfer.retreated_items">
                    <td>{{item.object_code}}</td>
                    <td>{{item.object_value}}</td>
                  </tr>
                </ng-container>

                <tr>
                  <td class="font-weight-bold">转收入总金额（元）</td>
                  <td class="text-right">{{checkoutViewResponse?.refund_transfer?.retreated_total}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="text-orange font-weight-bold">
            转收入后合计：{{checkoutViewResponse?.refund_transfer?.retreated_total}} 元
          </div>

          <div class="my-2 font-weight-bold">
            本人同意放弃剩余【{{checkoutViewResponse?.refund_transfer?.retreated_total}}元】退款
          </div>

        </div>

      </div>


      <div class="card card-block mb-2" *ngIf="totalTag > 0">
        <h3><b class="font16">您可以操作</b></h3>
        <div class="mb-2 bg-gray text-dark">
          <div class="row mb-3">
            <div class="col-12 mb-4"><a class="text-primary" style="text-decoration: underline"
                                        (click)="getAmount()"><b>点击收款</b></a></div>
            <div class="col-12 mb-3">
              <div class="form-check w140px">
                <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="noBill">
                <label class="form-check-label" for="exampleCheck1">不收款直接退房</label>
              </div>
              <div class="condiv3">
                <textarea class="w-100" rows="1" placeholder="请输入原因，限50字（必填）" maxlength="50"
                          [(ngModel)]="confirmReq.remark" [disabled]="!noBill"></textarea>

                <div *ngIf="noBill&&!confirmReq.remark" class="text-danger">原因不能为空</div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <h3><b class="font16">房间明细</b></h3>
      <hr>

      <div class="box-table" *ngFor="let item of checkoutViewResponse?.checkout_room_dtolist">
        <table class="table table-bordered">
          <thead class="thead-light">
          <tr>
            <th class="text-left" colspan="3">房间号：{{item?.check_out_room_info_dto?.room_name}}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="padd15" colspan="3">
              <div class="row">
                <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">
                  合同结束日期：{{checkoutViewResponse?.lease_info.end_time|date:'yyyy-MM-dd'}}
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">退房日期：{{now|date:'yyyy-MM-dd'}}</div>
                <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">退房状态：
                  <ng-container *ngIf="item.checkout_type==='NORMAL'">
                    正常
                  </ng-container>
                  <ng-container *ngIf="item.checkout_type==='OVERDUE'">
                    逾期<span class="text-orange">（{{item.overdue_day}}天）</span>
                  </ng-container>
                  <ng-container *ngIf="item.checkout_type==='BEFORE'">
                    提前
                  </ng-container>
                  <ng-container *ngIf="item.checkout_type==='BREAK'">
                    违约
                  </ng-container>
                  <ng-container *ngIf="item.checkout_type==='SUBLEASE'">
                    转租
                  </ng-container>
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">
                  应收总计：{{item.check_out_room_info_dto.receivable_total}}
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">
                  应退总计：-{{item.check_out_room_info_dto.retreated_total}}
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2"><b class="text-oran">合计：{{item.check_out_room_info_dto.amount_total}}</b>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
          <thead class="thead-light">
          <tr>
            <th scope="col">应收费用</th>
            <th scope="col">费用周期</th>
            <th scope="col">应收金额（元）</th>
          </tr>
          </thead>
          <tbody *ngFor="let inFee of item.check_out_room_info_dto.receivable_items">
          <tr>
            <td>{{inFee.object_code}}</td>
            <td>{{inFee.object_name}}</td>
            <td>{{inFee.object_value}}</td>
          </tr>
          </tbody>
          <thead class="thead-light">
          <tr>
            <th scope="col">应退费用</th>
            <th scope="col">费用周期</th>
            <th scope="col">应退金额（元）</th>
          </tr>
          </thead>
          <tbody *ngFor="let inFee of item.check_out_room_info_dto.retreated_items">
          <tr>
            <td>{{inFee.object_code}}</td>
            <td>{{inFee.object_name}}</td>
            <td>{{inFee.object_value}}</td>
          </tr>
          </tbody>
          <thead *ngIf="item?.check_out_room_info_dto?.recycle_items?.length > 0" class="thead-light">
          <tr>
            <th>回收优惠券</th>
            <th>回收来源</th>
            <th>回收金额（元）</th>
          </tr>
          </thead>
          <tbody *ngFor="let recycle of item.check_out_room_info_dto.recycle_items"  align="center">
          <tr >
            <td>{{recycle?.object_code}}</td>
            <td>{{recycle?.object_name}}</td>
            <td>{{recycle?.object_value}}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="box-table" *ngIf="signImg">
        <table class="table table-bordered">
          <tbody>
          <tr>
            <th class="padd15">
              用户签字
            </th>
          </tr>
          <tr>
            <td class="padd15" colspan="2">
              <img [src]="signImg" width="200px"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
  <div class="absolute-foot text-center">

<!--    <e-ngx-print-->
<!--      [btnText]="'打印退房汇总单'"-->
<!--      [btnClass]="{'btn btn-primary mr-3': true}"-->
<!--      [printHTML]="print_div_main"-->
<!--    >-->
<!--    </e-ngx-print>-->
    <button printSectionId="print_div_main" ngxPrint class="btn btn-primary mr-3"  >打印退房汇总单</button>

<!--    <e-ngx-print-->
<!--      [btnText]="'打印退房明细单'"-->
<!--      [btnClass]="{'btn btn-primary mr-3': true}"-->
<!--      [printHTML]="print_div"-->
<!--    >-->
<!--    </e-ngx-print>-->
    <button printSectionId="print_div" ngxPrint class="btn btn-primary mr-3"  >打印退房明细单</button>

    <button type="button" class="btn btn-warning ml-5" (click)="sign()" *ngIf="isPad()">线上签字</button>
    <button type="button" class="btn btn-warning ml-5" (click)="signOnIpad()" *ngIf="!isPad()">同步pad签字</button>

    <button type="button" class="btn btn-info ml-5" (click)="confirmOut()">提交并退房</button>
    <button type="button" class="btn btn-secondary" (click)="goLease()">返回</button>
  </div>
</div>

<!-- 写字板 -->
<app-tablet [title]="'请入住房客在下方写字板内签署姓名'" (commitSign)="commitSign($event)"></app-tablet>

<!-- 退房结果 -->
<div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-static-name" class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>退房</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="staticModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center text-dark">
        <p class="mb-4 mt-3">
          <ng-container *ngIf="checkoutViewResponse?.checkout_room_dtolist.length>5">
            {{checkoutViewResponse?.checkout_room_dtolist[0].check_out_room_info_dto.room_name}},{{checkoutViewResponse?.checkout_room_dtolist[1].check_out_room_info_dto.room_name}},...,{{checkoutViewResponse?.checkout_room_dtolist[checkoutViewResponse?.checkout_room_dtolist.length-2].check_out_room_info_dto.room_name}},{{checkoutViewResponse?.checkout_room_dtolist[checkoutViewResponse?.checkout_room_dtolist.length-1].check_out_room_info_dto.room_name}},
          </ng-container>
          <ng-container *ngIf="checkoutViewResponse?.checkout_room_dtolist.length<5">
            <ng-container *ngFor="let item of checkoutViewResponse?.checkout_room_dtolist;let i=index">
              {{item.check_out_room_info_dto.room_name}},
            </ng-container>
          </ng-container>
          ，共{{checkoutViewResponse?.checkout_room_dtolist.length}}间房<br>已成功办理退房！
        </p>
        <p class="text-orange font16 mb-3" *ngIf="billOwn()>0">尚有{{billOwn()}}笔账单未收款直接退房，如需收款，可从合同详情页面点击收款进行补收款。</p>
      </div>
      <div class="modal-footer text-center">
        <button type="button" class="btn btn-primary" (click)="goLease()">确认</button>
      </div>
    </div>
  </div>
</div>


<div style="display: none">
  <div #print_div_main id="print_div_main">
    <table width="100%" border="1" cellpadding="0" cellspacing="0">
      <thead bgcolor="#e4e4e4">
      <tr>
        <th colspan="4" align="center"><b>退房单</b></th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>退房单号：</td>
        <td>合同号：{{checkoutViewResponse?.lease_info.contract_num}}</td>
        <td>合同周期:
          {{checkoutViewResponse?.lease_info.start_time|date:'yyyy.MM.dd'}}
          -{{checkoutViewResponse?.lease_info.end_time|date:'yyyy.MM.dd'}}
        </td>
        <td>退房日期: {{now|date:'yyyy.MM.dd'}}</td>
      </tr>


      <tr>
        <th>应收费用</th>
        <th>应收金额（元）</th>
        <th>应收费用</th>
        <th>应收金额（元）</th>
      </tr>
      <ng-container *ngFor="let item of checkoutViewResponse?.check_out_room_info_dto?.receivable_items;let i=index">
        <tr *ngIf="i%2==0">
          <td *ngIf="i<checkoutViewResponse?.check_out_room_info_dto?.receivable_items.length">
            {{checkoutViewResponse?.check_out_room_info_dto?.receivable_items[i].object_code}}
          </td>
          <td *ngIf="i<checkoutViewResponse?.check_out_room_info_dto?.receivable_items.length">
            {{checkoutViewResponse?.check_out_room_info_dto?.receivable_items[i].object_value}}
          </td>
          <td *ngIf="i+1<checkoutViewResponse?.check_out_room_info_dto?.receivable_items.length">
            {{checkoutViewResponse?.check_out_room_info_dto?.receivable_items[i + 1].object_code}}
          </td>
          <td *ngIf="i+1<checkoutViewResponse?.check_out_room_info_dto?.receivable_items.length">
            {{checkoutViewResponse?.check_out_room_info_dto?.receivable_items[i + 1].object_value}}
          </td>
        </tr>
      </ng-container>
      <tr>
        <td colspan="4"><b>应收总计：{{checkoutViewResponse?.check_out_room_info_dto?.receivable_total}}元</b></td>
      </tr>

      <tr>
        <th>应退费用</th>
        <th>应退金额（元）</th>
        <th>应退费用</th>
        <th>应退金额（元）</th>
      </tr>
      <ng-container *ngFor="let item of checkoutViewResponse?.check_out_room_info_dto?.retreated_items;let i=index">
        <tr *ngIf="i%2==0">
          <td *ngIf="i<checkoutViewResponse?.check_out_room_info_dto?.retreated_items.length">
            {{checkoutViewResponse?.check_out_room_info_dto?.retreated_items[i].object_code}}
          </td>
          <td *ngIf="i<checkoutViewResponse?.check_out_room_info_dto?.retreated_items.length">
            {{checkoutViewResponse?.check_out_room_info_dto?.retreated_items[i].object_value}}
          </td>
          <td *ngIf="i+1<checkoutViewResponse?.check_out_room_info_dto?.retreated_items.length">
            {{checkoutViewResponse?.check_out_room_info_dto?.retreated_items[i + 1].object_code}}
          </td>
          <td *ngIf="i+1<checkoutViewResponse?.check_out_room_info_dto?.retreated_items.length">
            {{checkoutViewResponse?.check_out_room_info_dto?.retreated_items[i + 1].object_value}}
          </td>
        </tr>
      </ng-container>
      <tr>
        <td colspan="4"><b>应退总计：{{checkoutViewResponse?.check_out_room_info_dto?.retreated_total}}元</b></td>
      </tr>


      </tbody>
      <tfoot>
      <tr>
        <td colspan="4">合计：<b> {{checkoutViewResponse?.check_out_room_info_dto?.amount_total}}</b>元</td>
      </tr>
      </tfoot>
    </table>
    <br>
    <table width="100%" border="1" cellpadding="0" cellspacing="0" *ngIf="totalTag < 0">
      <thead bgcolor="#e4e4e4">
      <tr >
        <th colspan="3" align="left" *ngIf="confirmReq.checkout_finance_type_enum =='REFUND'">退款账户</th>
        <th colspan="3" align="left" *ngIf="confirmReq.checkout_finance_type_enum =='DELAY'">延期退款</th>
        <th colspan="3" align="center" *ngIf="confirmReq.checkout_finance_type_enum =='TRANSFER_IN'">退款转收入</th>
      </tr>
      </thead>
      <tbody *ngIf="confirmReq.checkout_finance_type_enum =='REFUND'">
      <tr>
        <td colspan="3" >退款方式：
          <ng-container *ngIf="confirmReq.checkout_bill_pay_type_enum==='TRANSFER'">转账</ng-container>
          <ng-container *ngIf="confirmReq.checkout_bill_pay_type_enum==='CASH'">现金</ng-container>
        </td>
      </tr>
      <tr *ngIf="confirmReq.checkout_bill_pay_type_enum==='TRANSFER'">
        <td>收款人姓名：{{confirmReq.refund_name}}</td>
        <td>开户行：{{confirmReq.open_bank}}</td>
        <td>银行账号：{{confirmReq.bank_no}}</td>
      </tr>
      </tbody>
      <tbody *ngIf="confirmReq.checkout_finance_type_enum =='DELAY'">
        <tr>
          <td colspan="3">退款方式：
            <ng-container>已选择延期退款，可直接提交退房，如需继续退款可从【工作台】-【我的待办】-【延期退款】模块继续操作退款。</ng-container>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="confirmReq.checkout_finance_type_enum =='TRANSFER_IN'">
        <tr align="center" style="font-weight: 600;">
          <td >收支抵扣后剩余转收入费用</td>
          <td colspan="2">收支抵扣后剩余可转收入金额（元）</td>
        </tr>
        <ng-container *ngIf="checkoutViewResponse.refund_transfer && checkoutViewResponse.refund_transfer.retreated_items">
          <tr *ngFor="let item of checkoutViewResponse.refund_transfer.retreated_items">
            <td>{{item.object_code}}</td>
            <td colspan="2">{{item.object_value}}</td>
          </tr>
        </ng-container>

        <tr>
          <td class="font-weight-bold">转收入总金额（元）</td>
          <td colspan="2" class="text-right">{{checkoutViewResponse?.refund_transfer?.retreated_total}}</td>
        </tr>
      </tbody>
    </table>
    <table width="100%" border="1" cellpadding="0" cellspacing="0" *ngIf="signImg">
      <tbody>
      <tr>
        <td>用户签字：
        </td>
        <td colspan="2">
          <img [src]="signImg" width="200px"/>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<div style="display: none">
  <div #print_div id="print_div">
    <table width="100%" border="1" cellpadding="0" cellspacing="0">
      <thead bgcolor="#e4e4e4">
      <tr>
        <th colspan="4" align="center"><b>退房单</b></th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>退房单号：</td>
        <td>合同号：{{checkoutViewResponse?.lease_info.contract_num}}</td>
        <td>合同周期:
          {{checkoutViewResponse?.lease_info.start_time|date:'yyyy.MM.dd'}}-{{checkoutViewResponse?.lease_info.end_time|date:'yyyy.MM.dd'}}
        </td>
        <td>退房日期: {{now|date:'yyyy.MM.dd'}}</td>
      </tr>

      <tr>
        <th>应收费用</th>
        <th>应收金额（元）</th>
        <th>应收费用</th>
        <th>应收金额（元）</th>
      </tr>
      <ng-container *ngFor="let item of checkoutViewResponse?.check_out_room_info_dto?.receivable_items;let i=index">
        <tr *ngIf="i%2==0">
          <td *ngIf="i<checkoutViewResponse?.check_out_room_info_dto?.receivable_items.length">
            {{checkoutViewResponse?.check_out_room_info_dto?.receivable_items[i].object_code}}
          </td>
          <td *ngIf="i<checkoutViewResponse?.check_out_room_info_dto?.receivable_items.length">
            {{checkoutViewResponse?.check_out_room_info_dto?.receivable_items[i].object_value}}
          </td>
          <td *ngIf="i+1<checkoutViewResponse?.check_out_room_info_dto?.receivable_items.length">
            {{checkoutViewResponse?.check_out_room_info_dto?.receivable_items[i + 1].object_code}}
          </td>
          <td *ngIf="i+1<checkoutViewResponse?.check_out_room_info_dto?.receivable_items.length">
            {{checkoutViewResponse?.check_out_room_info_dto?.receivable_items[i + 1].object_value}}
          </td>
        </tr>
      </ng-container>
      <tr>
        <td colspan="4"><b>应收总计：{{checkoutViewResponse?.check_out_room_info_dto?.receivable_total}}元</b></td>
      </tr>

      <tr>
        <th>应退费用</th>
        <th>应退金额（元）</th>
        <th>应退费用</th>
        <th>应退金额（元）</th>
      </tr>
      <ng-container *ngFor="let item of checkoutViewResponse?.check_out_room_info_dto?.retreated_items;let i=index">
        <tr *ngIf="i%2==0">
          <td *ngIf="i<checkoutViewResponse?.check_out_room_info_dto?.retreated_items.length">
            {{checkoutViewResponse?.check_out_room_info_dto?.retreated_items[i].object_code}}
          </td>
          <td *ngIf="i<checkoutViewResponse?.check_out_room_info_dto?.retreated_items.length">
            {{checkoutViewResponse?.check_out_room_info_dto?.retreated_items[i].object_value}}
          </td>
          <td *ngIf="i+1<checkoutViewResponse?.check_out_room_info_dto?.retreated_items.length">
            {{checkoutViewResponse?.check_out_room_info_dto?.retreated_items[i + 1].object_code}}
          </td>
          <td *ngIf="i+1<checkoutViewResponse?.check_out_room_info_dto?.retreated_items.length">
            {{checkoutViewResponse?.check_out_room_info_dto?.retreated_items[i + 1].object_value}}
          </td>
        </tr>
      </ng-container>
      <tr>
        <td colspan="4"><b>应退总计：{{checkoutViewResponse?.check_out_room_info_dto?.retreated_total}}元</b></td>
      </tr>


      </tbody>
      <tfoot>
      <tr>
        <td colspan="4">合计：<b> {{checkoutViewResponse?.check_out_room_info_dto?.amount_total}}</b>元</td>
      </tr>
      </tfoot>
    </table>
    <br>
    <table width="100%" border="1" cellpadding="0" cellspacing="0" *ngIf="totalTag < 0">
      <thead bgcolor="#e4e4e4">
      <tr>
        <th colspan="3" align="left" *ngIf="confirmReq.checkout_finance_type_enum =='REFUND'">退款账户</th>
        <th colspan="3" align="left" *ngIf="confirmReq.checkout_finance_type_enum =='DELAY'">延期退款</th>
        <th colspan="3" align="center" *ngIf="confirmReq.checkout_finance_type_enum =='TRANSFER_IN'">退款转收入</th>
      </tr>
      </thead>
      <tbody *ngIf="confirmReq.checkout_finance_type_enum =='REFUND'">
      <tr>
        <td colspan="3">退款方式：
          <ng-container *ngIf="confirmReq.checkout_bill_pay_type_enum==='TRANSFER'">转账</ng-container>
          <ng-container *ngIf="confirmReq.checkout_bill_pay_type_enum==='CASH'">现金</ng-container>
        </td>
      </tr>
      <tr *ngIf="confirmReq.checkout_bill_pay_type_enum==='TRANSFER'">
        <td>收款人姓名：{{confirmReq.refund_name}}</td>
        <td>开户行：{{confirmReq.open_bank}}</td>
        <td>银行账号：{{confirmReq.bank_no}}</td>
      </tr>
      </tbody>
      <tbody *ngIf="confirmReq.checkout_finance_type_enum =='DELAY'">
        <tr>
          <td colspan="3">退款方式：
            <ng-container>已选择延期退款，可直接提交退房，如需继续退款可从【工作台】-【我的待办】-【延期退款】模块继续操作退款。</ng-container>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="confirmReq.checkout_finance_type_enum =='TRANSFER_IN'">
        <tr align="center" style="font-weight: 600;">
          <td >收支抵扣后剩余转收入费用</td>
          <td colspan="2">收支抵扣后剩余可转收入金额（元）</td>
        </tr>
        <ng-container *ngIf="checkoutViewResponse.refund_transfer && checkoutViewResponse.refund_transfer.retreated_items">
          <tr *ngFor="let item of checkoutViewResponse.refund_transfer.retreated_items">
            <td>{{item.object_code}}</td>
            <td colspan="2">{{item.object_value}}</td>
          </tr>
        </ng-container>

        <tr>
          <td class="font-weight-bold">转收入总金额（元）</td>
          <td colspan="2" class="text-right">{{checkoutViewResponse?.refund_transfer?.retreated_total}}</td>
        </tr>
      </tbody>
    </table>
    <!-- <table width="100%" border="1" cellpadding="0" cellspacing="0" *ngIf="totalTag > 0">
      <thead bgcolor="#e4e4e4">
      <tr>
        <th colspan="3" align="left">退款账户</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td colspan="3">退款方式：
          <ng-container *ngIf="confirmReq.checkout_bill_pay_type_enum==='TRANSFER'">转账</ng-container>
          <ng-container *ngIf="confirmReq.checkout_bill_pay_type_enum==='CASH'">现金</ng-container>
        </td>
      </tr>
      <tr *ngIf="confirmReq.checkout_bill_pay_type_enum==='TRANSFER'">
        <td>收款人姓名：{{confirmReq.refund_name}}</td>
        <td>开户行：{{confirmReq.open_bank}}</td>
        <td>银行账号：{{confirmReq.bank_no}}</td>
      </tr>
      </tbody>
    </table> -->
    <br>
    <table width="100%" border="1" cellpadding="0" cellspacing="0"
           *ngFor="let item of checkoutViewResponse?.checkout_room_dtolist">
      <thead bgcolor="#e4e4e4" align="left">
      <tr>
        <th colspan="3">房间明细</th>
      </tr>
      <tr>
        <th colspan="3">房间号：{{item?.check_out_room_info_dto.room_name}}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>合同结束日期：{{checkoutViewResponse?.lease_info.end_time|date:'yyyy.MM.dd'}}</td>
        <td>退房日期：{{now|date:'yyyy.MM.dd'}}</td>
        <td>退房状态：
          <ng-container *ngIf="item.checkout_type==='NORMAL'">
            正常
          </ng-container>
          <ng-container *ngIf="item.checkout_type==='OVERDUE'">
            逾期<span class="text-orange">（{{item.overdue_day}}天）</span>
          </ng-container>
          <ng-container *ngIf="item.checkout_type==='BEFORE'">
            提前
          </ng-container>
          <ng-container *ngIf="item.checkout_type==='BREAK'">
            违约
          </ng-container>
          <ng-container *ngIf="item.checkout_type==='SUBLEASE'">
            转租
          </ng-container>
        </td>
      </tr>
      <tr>
        <td>应收总计：{{item.check_out_room_info_dto.receivable_total}}</td>
        <td>应退总计：-{{item.check_out_room_info_dto.retreated_total}}</td>
        <td><b>合计：{{item.check_out_room_info_dto.amount_total}}</b></td>
      </tr>
      </tbody>
      <thead bgcolor="#e4e4e4" align="center">
      <tr>
        <th>应收费用</th>
        <th>费用周期</th>
        <th>应收金额（元）</th>
      </tr>
      </thead>
      <tbody align="center">
      <tr *ngFor="let inFee of item.check_out_room_info_dto.receivable_items">
        <td>{{inFee.object_code}}</td>
        <td>{{inFee.object_name}}</td>
        <td>{{inFee.object_value}}</td>
      </tr>
      </tbody>
      <thead bgcolor="#e4e4e4" align="center">
      <tr>
        <th>应退费用</th>
        <th>费用周期</th>
        <th>应退金额（元）</th>
      </tr>
      </thead>
      <tbody align="center" border-color="#ccc">
      <tr *ngFor="let inFee of item.check_out_room_info_dto.retreated_items">
        <td>{{inFee.object_code}}</td>
        <td>{{inFee.object_name}}</td>
        <td>{{inFee.object_value}}</td>
      </tr>
      </tbody>

      <thead *ngIf="item?.check_out_room_info_dto?.recycle_items?.length > 0">
      <tr>
        <th>回收优惠券</th>
        <th>回收来源</th>
        <th>回收金额（元）</th>
      </tr>
      </thead>
      <tbody *ngFor="let recycle of item.check_out_room_info_dto.recycle_items">
      <tr>
        <td>{{recycle?.object_code}}</td>
        <td>{{recycle?.object_name}}</td>
        <td>{{recycle?.object_value}}</td>
      </tr>
      </tbody>

    </table>
    <table width="100%" border="1" cellpadding="0" cellspacing="0" *ngIf="signImg">
      <tbody>
      <tr>
        <td>用户签字：
        </td>
        <td colspan="2">
          <img [src]="signImg" width="200px"/>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<app-dialog (callback)="refresh()"></app-dialog>
<app-sign-device-binding #signDeviceBindingComponent></app-sign-device-binding>
