import {Component, OnInit, ViewChild} from '@angular/core';
import {CarportService} from "../../../service/carport/carport.service";
import {CarportLeaseBaseDTO} from "../../../model/carport/carport.modal";
import {BillGatheringComponent} from "../../carport/bill-gathering/bill-gathering.component";
import {BaseEnum} from '../../../model/modal/base-model';
import {BaseInfoService} from '../../../service/common/base-info.service';
import {AuthService} from "../../../service/common/auth.service";

declare var baidutongji:any;
@Component({
  selector: 'app-garage-list',
  templateUrl: './garage-list.component.html',
  styleUrls: ['./garage-list.component.css']
})
export class GarageListComponent implements OnInit {
  btn_privileges: any = {
    'CARPORT_LEASE_ADD': false,
    'CARPORT_LEASE_BILL': false,
    'CARPORT_LEASE_QUERY': false,
  };

  @ViewChild(BillGatheringComponent, { static: true }) billGatheringComponent: BillGatheringComponent;

  carportInfoList: Array<CarportLeaseBaseDTO> = [];

  totalNum: number = 0;
  page_num: number = 1;

  searchContent: string = '';
  searchStatus: string = '';

  status_checked_list: any = {
    UNCOMMITTED: false,
    CANCEL: false,
    WAITING_AUDIT: false,
    AUDIT_FAIL: false,
    WAIT_SIGN: false,
    EFFECTIVE: false,
    LOSE_EFFICACY: false,
    CHECKOUT: false,
  };

  lease_business_status_list: BaseEnum[] = [];

  constructor(private carportService: CarportService,
              private baseInfoService: BaseInfoService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.authService.getOperatePrivilege(this.btn_privileges);
    this.lease_business_status_list = this.baseInfoService.getLeaseBusinessStatusList();
    this.findCarportSearch();
    baidutongji("车库管理");
  }

  findCarportSearch() {
    let status_list = [];
    /*for (const key of Object.keys(this.status_checked_list)) {
      if (this.status_checked_list[key]) {
        status_list.push(key);
      }
    }*/
    if(this.searchStatus && this.searchStatus.length > 0){
      status_list.push(this.searchStatus);
    }

    this.carportService.findCarportLeaseListWithLoading({
      search_content: this.searchContent,
      status: status_list,
      page_num: this.page_num,
      page_size: 20,
      searchType: "LEASE",
    }).then(response => {
      this.carportInfoList = response.carport_lease_infos;
      this.totalNum = response.total;
    });
  }

  statusSet(event, code) {
    this.page_num = 1;
    this.status_checked_list[code] = event.target.checked;
    this.findCarportSearch();
  }

  searchList() {
    this.page_num = 1;
    this.findCarportSearch();
  }

  clearSearch() {
    for (const key of Object.keys(this.status_checked_list)) {
      this.status_checked_list[key] = false;
    }
    this.page_num = 1;
    this.searchContent = '';
    this.findCarportSearch();
  }

  /**
   * 开始分页
   * @param page
   */
  pageChange(currentPage: number): void {
    this.page_num = currentPage;
    this.findCarportSearch();
  }

  //收款
  gatherMoney() {
    this.billGatheringComponent.openGatherDialog(null);
  }

  getColorByBusinessStatus(business_status): string {
    return this.baseInfoService.getColorByBusinessStatus(business_status);
  }
}
