import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {RequestService} from '../request.service';
import {
  BaseEquipmentAddRequest,
  BaseEquipmentDeleteRequest,
  BaseEquipmentUpdateResponse,
  BaseEquipmentPlanRecoreRequest,
  BaseEquipmentPlanRecoreResponse,
  BaseEquipmentPlanRequest,
  BaseEquipmentPlanResponse,
  BaseEquipmentPlanCheckListResponse,
  BaseEquipmentPlanCheckRequest,
  BaseEquipmentListResponse,
  FormulateBaseEquipmentPlanRequest, EquipmentListRequest
} from '../../model/equipment/equipment.model';

@Injectable()
export class EquipmentService {

  //添加设备
  private ADD_EQUIPMENT_URL = `${environment.apiHost}base/equipment/add`;
  //删除设备
  private DELETE_EQUIPMENT_URL = `${environment.apiHost}base/equipment/delete`;

  //设备维保记录
  private EQUIPMENT_PLAN_RECORE_LIST = `${environment.apiHost}equipmentPlan/recore/list`;

  //设备维保计划
  private EQUIPMENT_PLAN_LIST = `${environment.apiHost}equipmentPlan/list`;

  //设备维保计划-检查列表
  private EQUIPMENT_PLAN_CHECK_LIST = `${environment.apiHost}equipmentPlan/checklist`;

  //设备维保计划-开始检查
  private EQUIPMENT_PLAN_CHECK = `${environment.apiHost}equipmentPlan/checkBaseEquipmentPlan`;

  //制定维保计划-设备列表查询
  private SELECT_BASE_EQUIPMENT_LIST = `${environment.apiHost}equipmentPlan/selectBaseEquipmentList`;

  //制定维保计划
  private FORMULATE_BASE_EQUIPMENT_PLAN = `${environment.apiHost}equipmentPlan/formulateBaseEquipmentPlan`;

  //修改计划
  private MODIFY_BASE_EQUIPMENT_PLAN = `${environment.apiHost}equipmentPlan/modifyBaseEquipmentPlan`;

  //删除计划
  private DELETE_BASE_EQUIPMENT_PLAN = `${environment.apiHost}equipmentPlan/deleteBaseEquipmentPlan`;

  // 基础设备列表
  private BASE_EQUIPMENT_LIST = `${environment.apiHost}base/equipment/list`;

  constructor(private requestService: RequestService) {
  }

  /**
   * 添加设备
   * @param baseEquipmentAddRequest
   */
  addEquipment(baseEquipmentAddRequest?: BaseEquipmentAddRequest): Promise<BaseEquipmentUpdateResponse> {
    return this.requestService.authPost(this.ADD_EQUIPMENT_URL, baseEquipmentAddRequest);
  }
  /**
   * 删除设备
   * @param baseEquipmentAddRequest
   */
  deleteEquipment(baseEquipmentDeleteRequest?: BaseEquipmentDeleteRequest): Promise<BaseEquipmentUpdateResponse> {
    return this.requestService.authPost(this.DELETE_EQUIPMENT_URL, baseEquipmentDeleteRequest);
  }

  /**
   * 设备维保记录
   * @param baseEquipmentPlanRecoreRequest
   */
  findEquipmentPlanRecoreList(baseEquipmentPlanRecoreRequest?: BaseEquipmentPlanRecoreRequest): Promise<BaseEquipmentPlanRecoreResponse> {
    return this.requestService.authGet(this.EQUIPMENT_PLAN_RECORE_LIST, baseEquipmentPlanRecoreRequest);
  }


  /**
   * 设备维保计划
   * @param baseEquipmentPlanRequest
   */
  findEquipmentPlanList(baseEquipmentPlanRequest?: BaseEquipmentPlanRequest): Promise<BaseEquipmentPlanResponse> {
    return this.requestService.authGet(this.EQUIPMENT_PLAN_LIST, baseEquipmentPlanRequest);
  }
  findEquipmentPlanListWithLoading(baseEquipmentPlanRequest?: BaseEquipmentPlanRequest): Promise<BaseEquipmentPlanResponse> {
    return this.requestService.authGet(this.EQUIPMENT_PLAN_LIST, baseEquipmentPlanRequest, true);
  }

  /**
   * 设备维保计划-检查列表
   * @param baseEquipmentPlanRequest
   */
  findEquipmentPlanCheckList(baseEquipmentPlanRequest?: BaseEquipmentPlanRequest): Promise<BaseEquipmentPlanCheckListResponse> {
    return this.requestService.authGet(this.EQUIPMENT_PLAN_CHECK_LIST, baseEquipmentPlanRequest);
  }

  /**
   * 设备维保计划-开始检查
   * @param baseEquipmentPlanCheckRequest
   */
  checkBaseEquipmentPlan(baseEquipmentPlanCheckRequest?: BaseEquipmentPlanCheckRequest): Promise<BaseEquipmentUpdateResponse> {
    return this.requestService.authPost(this.EQUIPMENT_PLAN_CHECK, baseEquipmentPlanCheckRequest);
  }

  /**
   * 制定维保计划-设备列表查询
   */
  findFormulateBaseEquipmentList(): Promise<BaseEquipmentListResponse> {
    return this.requestService.authGet(this.SELECT_BASE_EQUIPMENT_LIST,null);
  }

  /**
   * 制定维保计划
   * @param formulateBaseEquipmentRequest
   */
  formulateBaseEquipmentPlan(formulateBaseEquipmentPlanRequest?: FormulateBaseEquipmentPlanRequest): Promise<BaseEquipmentUpdateResponse> {
    return this.requestService.authPost(this.FORMULATE_BASE_EQUIPMENT_PLAN, formulateBaseEquipmentPlanRequest);
  }

  /**
   * 修改设备维保计划
   * @param formulateBaseEquipmentRequest
   */
  modifyBaseEquipmentPlan(formulateBaseEquipmentPlanRequest?: FormulateBaseEquipmentPlanRequest): Promise<BaseEquipmentUpdateResponse> {
    return this.requestService.authPost(this.MODIFY_BASE_EQUIPMENT_PLAN, formulateBaseEquipmentPlanRequest);
  }

  /**
   * 删除设备年度计划
   * @param formulateBaseEquipmentRequest
   */
  deleteBaseEquipmentPlan(baseEquipmentPlanRequest?: BaseEquipmentPlanRequest): Promise<BaseEquipmentUpdateResponse> {
    return this.requestService.authPost(this.DELETE_BASE_EQUIPMENT_PLAN, baseEquipmentPlanRequest);
  }

  /**
   * 获取列表数据
   * @param listRequest
   */
  equipmentList(request: EquipmentListRequest): Promise<any> {
    return this.requestService.authGet(this.BASE_EQUIPMENT_LIST, request);
  }
  equipmentListWithLoading(request: EquipmentListRequest): Promise<any> {
    return this.requestService.authGet(this.BASE_EQUIPMENT_LIST, request, true);
  }
}
