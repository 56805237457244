import {Injectable, Injector} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Constants} from '../../common/constants';
import {SysUser, TempStorageModel} from '../../model/user/user.model';
import {LogService} from "../log/log.service";
import {HotelProject} from "../../model/room/project.model";
import {LocalStorage} from '../../common/localstorage';
import {PrivilegeService} from "../privilege/privilege.service";
import {is_inApp_Android, is_inApp_IOS} from "../../common/common";
import {UserService} from "../user/user.service";
import {WorkService} from "./work.service";

/**
 * @description:身份验证服务
 *
 * @Author: yinqiang
 * @Date: 2018-02-13 17:40:31
 * @Last Modified by: yinqiang
 * @Last Modified time: 2018-03-07 21:16:10
 */
@Injectable()
export class AuthService {

  loginId: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private injector: Injector,
    private logService: LogService,
    private userService: UserService,
    private workService: WorkService
  ) {
  }

  getAuthToken(): string {
    //判断是否过期如果过期则移除token信息
    if (this.isExpired()) {
      this.tokenRemove();
      let sessionStorageList: Array<TempStorageModel> = [];
      Constants.SESSION_STORAGE_WHITE_LIST.forEach(item => {
        let tempStorageModel = new TempStorageModel();
        tempStorageModel.storage_name = item;
        tempStorageModel.storage_value = sessionStorage.getItem(item);
        if (tempStorageModel.storage_value) {
          sessionStorageList.push(tempStorageModel);
        }
      });
      sessionStorage.clear();
      sessionStorageList.forEach(item => {
        if (item.storage_value) {
          sessionStorage.setItem(item.storage_name, item.storage_value);
        }
      });
      LocalStorage.clear();
    } else {
      // token是否有效取决于服务端，这里如果检查token还未过期则刷新过期时间
      this.setExpiredTime();
    }
    return this.getToken();
  }

  /**
   * @Description:   设置token过期时间 30分钟(无操作过期)
   * @Author: zhoujiahao
   * @Date: 2018-03-15  16:30
   */
  setExpiredTime() {
    //设置过期时间
    // PMS app 不判断无操作
    const expiresAt = JSON.stringify((30 * 60 * 1000) + Date.now());
    sessionStorage.setItem(Constants.EXPIRE_TIME, expiresAt);
  }

  /**
   * @Description: 设置token以及过期时间
   * @Author: zhoujiahao
   * @Date: 2018-03-15  16:33
   */
  setToken(token: any) {
    sessionStorage.setItem(Constants.TOKEN_KEY, token);
    if (is_inApp_IOS() || is_inApp_Android()) {
      localStorage.setItem(Constants.TOKEN_KEY, token);
    }
    this.setExpiredTime();
  }

  /**
   * @Description:  判断当前token是否过期  返回true是表示token过期
   * @Param:
   * @return:  boolean
   * @Author: zhoujiahao
   * @Date: 2018-03-15  16:34
   */
  isExpired(): boolean {

    let token = this.getToken();
    if (!token) {
      return true;
    }

    // PMS app 不判断无操作
    if (is_inApp_IOS() || is_inApp_Android()) {
      return false;
    } else {
      //10 表示十进制
      const isExpired = parseInt(sessionStorage.getItem(Constants.EXPIRE_TIME), 10) - Date.now();
      return isExpired <= 0;
    }
  }

  getToken(): string {
    let token = sessionStorage.getItem(Constants.TOKEN_KEY);
    if (!token && (is_inApp_Android() || is_inApp_IOS())) {
      token = localStorage.getItem(Constants.TOKEN_KEY);
    }
    return token;
  }

  /**
   * @Description:   移除缓存中的token信息
   * @Param:
   * @return:
   * @Author: zhoujiahao
   * @Date: 2018-03-15  16:41
   */
  tokenRemove() {
    // this.logService.error('token过期');
    //移除token
    sessionStorage.removeItem(Constants.TOKEN_KEY);
    LocalStorage.removeItem(Constants.TOKEN_KEY);
    //移除当前token过期时间
    sessionStorage.removeItem(Constants.EXPIRE_TIME);
    LocalStorage.removeItem(Constants.EXPIRE_TIME);
    // 移除project_id
    LocalStorage.removeItem(Constants.PROJECT_ID);
    //默认值为false，设为true路由跳转时浏览器中的url是跳转前的路径，但是传入的参数依然有效

    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.wechatflag) {
        this.router.navigate(['/login'], {
          queryParams: {
            wechatflag: '1',
            projectId: queryParams.projectId,
            companyId: queryParams.companyId,
            id: queryParams.id
          }
        });
        return;
      }
    });
    this.router.navigate(['login'], {skipLocationChange: false});
  }

  /**
   * @description: 存入用户信息
   * @Author: mazy
   * @Date: 2018/3/8 21:06
   * @Last Modified by: mazy
   * @Last Modified time: 2018/3/8 21:06
   */
  public setUserInfo(user: SysUser) {
    sessionStorage.setItem(Constants.USER_INFO, JSON.stringify(user));
    if (is_inApp_Android() || is_inApp_IOS()) {
      localStorage.setItem(Constants.USER_INFO, JSON.stringify(user));
    }
  }

  /**
   * @description: 获取用户信息
   * @Author: mazy
   * @Date: 2018/3/8 21:12
   * @Last Modified by: mazy
   * @Last Modified time: 2018/3/8 21:12
   */
  public getUserInfo(): SysUser {
    let user = null;
    let userInfo = sessionStorage.getItem(Constants.USER_INFO);
    let userInfoLocal = localStorage.getItem(Constants.USER_INFO);
    if (userInfo) {
      user = JSON.parse(userInfo);
    } else if (userInfoLocal) {
      user = JSON.parse(userInfoLocal);
    }
    return user;
  }

  /**
   * 存入项目信息 对象
   * @param HotelProject
   */
  public setProjectInfo(projectDto: HotelProject) {
    LocalStorage.setItem(Constants.PROJECT_ID + this.getUserInfo().id, JSON.stringify(projectDto));
  }

  /**
   * 获取ProjectId
   * @param projectId
   */
  public getProjectId(): number {
    let a = this.getUserInfo();
    let projectId = LocalStorage.getItem(Constants.PROJECT_ID + a.id);
    if (projectId) {
      let hotelProjectInfo = JSON.parse(projectId);
      if (hotelProjectInfo) {
        return parseInt(hotelProjectInfo.id);
      }
      return null;
    } else {
      return null;
    }
  }

  /**
   * 获取项目名称
   * @param projectId
   */
  public getProjectName(): string {
    let k = LocalStorage.getItem(Constants.PROJECT_ID + this.getUserInfo().id);
    if (k) {
      let hotelProjectInfo = JSON.parse(k);
      return hotelProjectInfo.project_name;
    } else {
      return "";
    }
  }

  /**
   * 获取项目性质
   */
  public getProjectNature(): string {
    let k = LocalStorage.getItem(Constants.PROJECT_ID + this.getUserInfo().id);
    if (k) {
      let hotelProjectInfo = JSON.parse(k);
      return hotelProjectInfo.need_lease_contract;
    } else {
      return "";
    }
  }

  /**
   * 获取项目信息
   */
  public getProjectInfo(): HotelProject {
    let k = LocalStorage.getItem(Constants.PROJECT_ID + this.getUserInfo().id);
    if (k) {
      let hotelProjectInfo = JSON.parse(k);
      return hotelProjectInfo;
    } else {
      return null;
    }
  }

  /**
   * 设置 当前用户 所属公司
   * @param {number} companyId
   */
  public setCompanyId(companyId: number) {
    LocalStorage.setItem(Constants.CURRENT_COMPANY_ID + this.getUserInfo().id, companyId.toString());
  }

  /**
   * 获取 当前用户 所属公司
   * @returns {any}
   */
  public getCompanyId(): any {
    let companyId = LocalStorage.getItem(Constants.CURRENT_COMPANY_ID + this.getUserInfo().id);
    if (companyId) {
      return companyId;
    } else {
      return;
    }
  }

  public hasChooseProject(): any {
    let a = this.getUserInfo();
    let projectId = LocalStorage.getItem(Constants.HAS_CHOOSE_PROJECT_ID + a.id);
    if (projectId) {
      return projectId;
    } else {
      return;
    }
  }

  public setProject(projectId): any {
    LocalStorage.setItem(Constants.HAS_CHOOSE_PROJECT_ID + this.getUserInfo().id, projectId);
  }

  //按钮操作权限
  getOperatePrivilege(btn_privileges: any) {
    let localitem = LocalStorage.getItem(Constants.USER_OPERATE_PRIVILEGES + this.getUserInfo().id);
    if (localitem) {
      let privileges = JSON.parse(localitem);
      if (privileges) {
        for (let key of Object.keys(btn_privileges)) {
          for (let i = 0; i < privileges.length; i++) {
            let privilege = privileges[i];
            if (key === privilege) {
              btn_privileges[key] = true;
            }
          }
        }
      }
    }
  }

  //按钮操作权限
  initOperatePrivilege(btn_privileges: any) {
    for (let key of Object.keys(btn_privileges)) {
      btn_privileges[key] = false;
    }
  }

  removeProject() {
    LocalStorage.removeItem(Constants.PROJECT_ID + this.getUserInfo().id);
  }


  reloadPrivilege(priviliges: string[]): Promise<any> {

    return new Promise<any>((resolve, reject) => {

      this.logService.debug(" refresh 新增的权限码 " + JSON.stringify(priviliges));

      let operatePrivilegesReqdata = {
        operation_priviliges: null,
        company_id: null
      };

      operatePrivilegesReqdata.operation_priviliges = [];

      //这里请求数据库 获取权限 将 priviliges作为参数传给api api的返回可以转换为set类型 在com中判断有没有 有就认为有权限 无则无权限
      if (priviliges && priviliges.length > 0) {
        operatePrivilegesReqdata.operation_priviliges = priviliges;
        if (operatePrivilegesReqdata.operation_priviliges.length > 0) {
          const reqData = {};
          for (let item in operatePrivilegesReqdata) {
            if (item) {
              reqData[item] = operatePrivilegesReqdata[item];
            }
          }
          // 如果选择了公司, 传递公司参数
          let companyId = LocalStorage.getItem(Constants.CURRENT_COMPANY_ID + this.getUserInfo().id);
          if (companyId) {
            operatePrivilegesReqdata.company_id = companyId;
          }

          let result = new Set<string>();

          let privilegeService = this.injector.get(PrivilegeService);
          return privilegeService.getUserOperatePrivilege(operatePrivilegesReqdata).then(res => {
            let resopone = [];
            if (res.operation_priviliges) {
              resopone = res.operation_priviliges;
            }
            localStorage.setItem(Constants.USER_OPERATE_PRIVILEGES + this.getUserInfo().id, JSON.stringify(resopone));

            resolve(resopone);
          });
        } else {
          resolve([]);
        }
      } else {
        resolve([]);
      }

    });
  }

  // 获取二次登录验证的设备唯一id
  createLoginUniqueCode(isAndroid: boolean, isIos: boolean) {
    let character: string;
    if (isAndroid || isIos) {
      if (isAndroid) {
        character = window['androidAPP'].getLocalStorage(Constants.LOGIN_UNIQUE_TAG);
      }
      if (isIos) {
        /*window.document.addEventListener('apptransfer', (event)=>{
          let loginUniqueTag = event['detail'].param;
          window.document.removeEventListener('apptransfer',() =>{});
          return loginUniqueTag === '' || loginUniqueTag == null;
        });
        window['webkit'].messageHandlers.getLocalStorage.postMessage(null);*/
        character = localStorage.getItem(Constants.LOGIN_UNIQUE_TAG);
      }
    } else {
      character = localStorage.getItem(Constants.LOGIN_UNIQUE_TAG);
    }
    if (character == null || character === "" || character.length !== 32) {
      let date = new Date();
      character = date.getTime() + Constants.LOGIN_UNIQUE_TAG + Math.random() * 100000000000;
      if (character.length !== 32) {
        character = character.substring(0, 32);
      }
      this.setLoginUniqueTagExpiredTime(isAndroid, isIos);
    }
    // 针对优化前登录验证设备唯一id被清除的情形
    if (localStorage.getItem(Constants.LOGIN_UNIQUE_TAG_EXPIRE_TIME) === null) {
      this.setLoginUniqueTagExpiredTime(isAndroid, isIos);
    }
    if (isAndroid || isIos) {
      if (isAndroid) {
        window['androidAPP'].setLocalStorage(Constants.LOGIN_UNIQUE_TAG, character);
      }
      if (isIos) {
        window['webkit'].messageHandlers.setLocalStorage.postMessage({
          localStorage: Constants.LOGIN_UNIQUE_TAG,
          localStorageValue: character
        });
      }
    } else {
      localStorage.setItem(Constants.LOGIN_UNIQUE_TAG, character);
    }
    return character;
  }

  /**
   * 判断登录设备唯一id是否过期
   */
  isLoginUniqueTagExpired(isAndroid: boolean, isIos: boolean): boolean {
    if (isAndroid || isIos) {
      if (isAndroid) {
        if (window['androidAPP'].getLocalStorage(Constants.LOGIN_UNIQUE_TAG) == null ||
          window['androidAPP'].getLocalStorage(Constants.LOGIN_UNIQUE_TAG) === '') {
          return true;
        }
        const isExpired = parseInt(window['androidAPP'].getLocalStorage(Constants.LOGIN_UNIQUE_TAG_EXPIRE_TIME), 10) - Date.now();
        return isExpired <= 0;
      }
      if (isIos) {
        let iosLoginId = localStorage.getItem(Constants.LOGIN_UNIQUE_TAG);
        return iosLoginId == null || iosLoginId === '';
      }
    } else {
      if (localStorage.getItem(Constants.LOGIN_UNIQUE_TAG) == null) {
        return true;
      }
      //10 表示十进制
      const isExpired = parseInt(localStorage.getItem(Constants.LOGIN_UNIQUE_TAG_EXPIRE_TIME), 10) - Date.now();
      return isExpired <= 0;
    }
  }

  /**
   * 设置过期时间为30天
   */
  setLoginUniqueTagExpiredTime(isAndroid: boolean, isIos: boolean) {
    const expiresAt = JSON.stringify((60 * 60 * 24 * 30 * 1000) + Date.now());
    if (isAndroid) {
      window['androidAPP'].setLocalStorage(Constants.LOGIN_UNIQUE_TAG_EXPIRE_TIME, expiresAt);
    } else if (isIos) {

    } else {
      localStorage.setItem(Constants.LOGIN_UNIQUE_TAG_EXPIRE_TIME, expiresAt);
    }
  }

  /**
   * 删除登录设备唯一id
   */
  loginUniqueTagRemove(isAndroid: boolean, isIos: boolean) {
    if (isAndroid || isIos) {
      if (isAndroid) {
        window['androidAPP'].setLocalStorage(Constants.LOGIN_UNIQUE_TAG, '');
        window['androidAPP'].setLocalStorage(Constants.LOGIN_UNIQUE_TAG_EXPIRE_TIME, '');
      }
      if (isIos) {
        window['webkit'].messageHandlers.setLocalStorage.postMessage({
          localStorage: Constants.LOGIN_UNIQUE_TAG,
          localStorageValue: ''
        });
        window['webkit'].messageHandlers.setLocalStorage.postMessage({
          localStorage: Constants.LOGIN_UNIQUE_TAG_EXPIRE_TIME,
          localStorageValue: ''
        });
      }
    } else {
      localStorage.removeItem(Constants.LOGIN_UNIQUE_TAG);
      localStorage.removeItem(Constants.LOGIN_UNIQUE_TAG_EXPIRE_TIME);
    }
  }

  /**
   * android和pc可以直接调用本方法；ios需要监听事件回调
   */
  getLoginUniqueTag() {
    let loginUniqueTag;
    let u = navigator.userAgent;
    let isAndroid = is_inApp_Android(); //安卓端
    let isIos = is_inApp_IOS(); //IOS端
    if (this.isLoginUniqueTagExpired(isAndroid, isIos)) {
      this.loginUniqueTagRemove(isAndroid, isIos);
      loginUniqueTag = this.createLoginUniqueCode(isAndroid, isIos);
    } else {
      loginUniqueTag = this.createLoginUniqueCode(isAndroid, isIos);
    }

    return loginUniqueTag;
  }

  iosEventListener(): Promise<any> {
    let loginUniqueTag = '';
    window.document.addEventListener('apptransfer', (event) => {
      loginUniqueTag = event['detail'].param;
      window.document.removeEventListener('apptransfer', () => {
      });
    });
    window['webkit'].messageHandlers.getLocalStorage.postMessage(null);
    return Promise.resolve(loginUniqueTag);
  }

  autoLoginWechatCode(): Promise<any> {
    let login_wechat_code = sessionStorage.getItem(Constants.WECHAT_CODE);
    let login_backurl = sessionStorage.getItem(Constants.LOGIN_BACKURL);
    let login_companyid = sessionStorage.getItem(Constants.LOGIN_COMPANYID);
    let login_projectid = sessionStorage.getItem(Constants.LOGIN_PROJECTID);
    let login_systemtype = sessionStorage.getItem(Constants.LOGIN_SYSTEMTYPE);
    if (login_wechat_code && login_backurl && login_companyid && login_projectid) {
      login_systemtype = login_systemtype || 'CSDM';
      // auto redirect login with code
      return this.loginWithWechatCode(
        login_systemtype,
        login_wechat_code,
        login_backurl,
        login_companyid,
        login_projectid
      );
    } else {
      return Promise.reject();
    }
  }

  loginWithWechatCode(login_systemtype, login_wechat_code,
                      login_backurl, login_companyid, login_projectid): Promise<any> {
    // auto redirect login with code

    return this.userService.userLogin({
      systemType: login_systemtype,
      loginType: 'WECHAT_CODE',
      code: login_wechat_code
    }).then(data => {

      this.afterFinishLogin(data);

      sessionStorage.removeItem('code');
      localStorage.setItem('platformCode', login_systemtype);

      this.setLoginCompanyId(+login_companyid)
        .then(() => {
          this.setLoginProject(+login_projectid)
            .then(() => {

              this.removeWechatLoginSession();

              this.router.navigateByUrl(decodeURIComponent(login_backurl));
            });
        });
    }).catch(() => {
      this.removeWechatLoginSession();
    });
  }

  removeWechatLoginSession() {
    if (sessionStorage) {
      sessionStorage.removeItem(Constants.WECHAT_CODE);
      sessionStorage.removeItem(Constants.LOGIN_BACKURL);
      sessionStorage.removeItem(Constants.LOGIN_COMPANYID);
      sessionStorage.removeItem(Constants.LOGIN_PROJECTID);
      sessionStorage.removeItem(Constants.LOGIN_SYSTEMTYPE);
    }
  }

  afterFinishLogin(biz_response) {
    //登录成功 之后清空之前用户的信息
    LocalStorage.customClear(this.getUserInfo());
    sessionStorage.clear();
    // 存放用户信息
    this.setUserInfo(biz_response.sys_user_dto);
    this.removeProject();
    // 存放token
    this.setToken(biz_response.token);
  }

  public setLoginCompanyId(companyId: number) {

    this.setCompanyId(Number(companyId));
    // 后台存入
    return this.userService.saveCurrentCompany({companyId: companyId}).then(data => {
      // 向导相关
      if (data.add_dept) {
        localStorage.setItem("add_dept", data.add_dept);
      }
      if (data.add_project) {
        localStorage.setItem("add_project", data.add_project);
      }
      if (data.add_user) {
        localStorage.setItem("add_user", data.add_user);
      }
      if (data.add_room) {
        localStorage.setItem("add_room", data.add_room);
      }
    });
  }

  public setLoginProject(projectId) {

    // 房态图类型
    LocalStorage.removeItem(this.getUserInfo().id + "_room_type");
    return this.userService.userProjectChange(projectId).then(x => {
      // 存放项目信息
      this.setProjectInfo(x.project);

      // 房态图类型
      if (x.project.room_type !== 3) {
        LocalStorage.setItem(this.getUserInfo().id + "_room_type", x.project.room_type + "");
      }
    });
  }
}
