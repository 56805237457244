
<!-- login leqida -->
<div class="login lqd-login" *ngIf="platformCode === 'LEQIDA'">

  <div class="wrapper wrapper-login wrapper-login-full p-0 m-0">
    <div class="login-aside w-50 d-flex flex-column align-items-center justify-content-center text-center">

      <img class="left-bg-lqd show2" src="../../../../assets/img/v3/lqd/llgg.png">
      <div class="text-left text-black mt-2 desc show2">
        <p>即刻享用</p>
        <p>全国超过5万个床位供您挑选；</p>
        <p>住宿成本分析、员工住宿情况随时掌控；</p>
        <p>智能的住宿管理软件，提升自有宿舍满意度；</p>
        <p>专业的人力资源服务，快速满足您的各类招聘需求；</p>
        <p>专属定制的员工餐饮和班车服务。</p>
      </div>

      <div class="text-center mt-0 mt-sm-5 show1 ">
<!--        <img class="login-logo" src="../../../../assets/img/v3/leqida_logo@2x.png" alt="">-->
      </div>

    </div>

    <div class="login-aside d-flex align-items-center justify-content-center w-50">
      <div class="overflow-hidden boxs1 container container-signup container-transparent bg-white rounded ">
       <div class="">
         <div class="position-relative lqd-reg">
           <div class="triangle"></div>
           <div class="text" (click)="goLogin()">登录</div>
         </div>
       </div>
        <div class="text-center" *ngIf="!is_complete_register">

          <div class="text-center mt-5 show1 d-inline">
            <img class="login-logo pb-3" src="../../../../assets/img/v3/leqida_logo@2x.png" alt="">
          </div>

            <div class="">

                  <div class="login-form col-12 mt-5" *ngIf="!has_login">

                    <div class="row">
                      <div class="w-100">
                        <div class="form-group">
                          <input class="form-control"
                                 type="text"
                                 [(ngModel)]="registerRequest.user_name"
                                 (input)="check(false)"
                                 #account=ngModel
                                 autofocus
                                 maxlength="64"
                                 tabindex="1"
                                 placeholder="请输入您的姓名"
                          >
                        </div>
                      </div>
                      <div class="w-100">
                        <div class="form-group">
                          <input class="form-control"
                                 type="text"
                                 [(ngModel)]="registerRequest.user_phone"
                                 (input)="check(false)"
                                 #account=ngModel
                                 autofocus
                                 maxlength="11"
                                 tabindex="2"
                                 placeholder="请输入账号"
                          >
                        </div>
                      </div>

                      <div class="w-100">
                        <div class="form-group">
                          <div class="input-group mb-3">
                            <input class="form-control"
                                   [(ngModel)]="registerRequest.sms_code"
                                   tabindex="3"
                                   placeholder="请输入验证码"
                            >
                            <div class="input-group-prepend ml-2">
                              <button *ngIf="buttonShow" class="btn btn-info m-0 border-r4" type="button" (click)="sendCodeRequest()">获取验证码</button>
                              <button *ngIf="!buttonShow" class="btn btn-info m-0 border-r4" type="button" readonly [disabled]="!buttonShow">{{clock}}s后获取</button>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div class="w-100">
                        <div class="form-group">
                          <input class="form-control"
                                 type="password"
                                 [(ngModel)]="registerRequest.password"
                                 (input)="check(false)"
                                 tabindex="4"
                                 placeholder="请输入密码"
                          >
                        </div>
                      </div>

                      <div class="w-100">
                        <div class="form-group">
                          <input class="form-control"
                                 type="password"
                                 [(ngModel)]="registerRequest.second_password"
                                 tabindex="5"
                                 placeholder="请再次输入密码"
                          >
                        </div>
                      </div>

                      <div class="w-100 text-center">
                        <div *ngIf="errorContent != null && errorContent !== '' "
                             class="text-danger">
                          {{errorContent}}
                        </div>
                      </div>
                    </div>

                    <div class="row text-center mt-2" *ngIf="platformCode === 'LEQIDA'">
                      <button class="btn btn-info w-100 login_lqd ml-0"
                              (click)="userRegister()" tabindex="6">注册
                      </button>
                    </div>

                  </div>

            </div>

          </div>

        <div class="text-center" *ngIf="is_complete_register">
          <div class="text-center mb-5">
            <img class="w-25" src="../../../../assets/img/v3/lqd/ggg.png" alt="">
            <div class="font-weight-bold mt-3 font-lg ">注册成功</div>

            <div class="text-center mt-3" >
              <button class="btn btn-info px-3 m-0 w-100"
                      (click)="goLogin()" tabindex="4">返回登录
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</div>
