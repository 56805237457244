import { RoomInfo, RoomInfoDTO, RoomTypeGoodsDTO } from '../room/room.model';
import { LeaseBaseInfoDTO, LeaseRoomInfoDTO } from '../lease/lease.model';

export class InventorViewRequest {
  lease_id?: number;
  room_id?: number;
}

export class InventorViewResponse {
  // @Remark("水表读数")
  water_reading?: string;
  // @Remark("水表数读取时间")
  water_reading_time?: Date;
  // @Remark("电表读数")
  electricity_reading?: string;
  // @Remark("电表数读取时间")
  electricity_reading_time?: Date;
  // @Remark("房间信息")
  room_info?: RoomInfoDTO;
  // @Remark("资产集合")
  asset_list?: AssetBaseInfoDTO[];
  // @Remark("是否安装水表")
  water_dev?: boolean;
  // @Remark("是否安装电表")
  electricity_dev?: boolean;
  // @Remark("是否需要电：YES：是，NO：否")
  need_electricity?: string;
  // @Remark("是否需要水：YES：是，NO：否")
  need_water?: string;
}

export class AssetBaseInfoDTO {
  // 资产Id
  id?: number;

  // 资产状态
  asset_status: string;

  // 批次ID
  batch_id?: number;

  // 资产编号
  asset_serial?: string;

  // 报废时间
  scrap_time?: Date;

  // 物资价格
  asset_price: string;

  // 资产类别(型号)
  batch_category?: string;

  // 资产名称
  batch_model?: string;

  // 资产类型
  batch_type?: string;

  // 资产品牌
  batch_brand: string;

  // 批次价格
  batch_price?: string;

  room_id?: number;
}

export class RoomInventoryAffirmRequest {
  // @Remark("租约ID")
  lease_id: number;
  // @Remark("房间ID")
  room_id: number;
  // @Remark("资产ID集合")
  inventory_id_list: number[];
  // @Remark("手动修改水表抄表读数后的传值，不修改不用传")
  water_reading: string;
  // @Remark("手动修改电表抄表读数后的传值，不修改不用传")
  electricity_reading: string;
}

export class CheckoutRoomInventoryRequest {
  // @Remark("租约ID")
  lease_id?: number;
  // @Remark("房间ID")
  room_id?: number;
}

export class CheckoutRoomInventoryResponse {
  room_id?: number;
  // @Remark("水表读数")
  water_reading?: string;
  // @Remark("水表数读取时间")
  water_reading_time?: Date;
  // @Remark("电表读数")
  electricity_reading?: string;
  // @Remark("电表数读取时间")
  electricity_reading_time?: Date;
  // @Remark("房间信息")
  room_info?: RoomInfoDTO;
  // @Remark("是否安装水表")
  water_dev?: boolean;
  // @Remark("是否安装电表")
  electricity_dev?: boolean;
  // @Remark("房间物品清单集合")
  inventory_list?: LeaseRoomInventoryDTO[];
  // @Remark("上次水表读数")
  top_water_reading?: string;
  // @Remark
  top_electricity_reading?: string;
  // @Remark("水单价")
  water_price?: string;
  // @Remark("电单价")
  electricity_price?: string;
  compensate: boolean = true;
  // @Remark("是否需要电：YES：是，NO：否")
  need_electricity?: string;
  // @Remark("是否需要水：YES：是，NO：否")
  need_water?: string;
}

export class LeaseRoomInventoryDTO {
  // @Remark("租约房间物品交接清单ID")
  lease_room_inventory_id?: number;
  // @Remark("物品ID")
  inventory_id?: number;
  // @Remark("租约ID")
  lease_id?: number;
  // @Remark("租约房间ID")
  lease_room_id?: number;
  // @Remark("房间ID")
  room_id?: number;
  // @Remark("物品单价")
  price?: number;
  // @Remark("赔偿金额")
  compensate_price?: number;
  // @Remark("创建清单用户ID")
  create_user_id?: number;
  // @Remark("项目ID")
  project_id?: number;
  // @Remark("物品名称")
  inventory_name?: string;
  // @Remark("物品品牌")
  inventory_brand?: string;
  // @Remark("物品类别(如家具类)")
  inventory_category?: string;
  // @Remark("型号（类型）")
  inventory_type?: string;
  // @Remark("NO_CHECK：未检查，DONE_CHECK：已检查，PART_DAMAGE:部分损坏，DAMAGE：全部损坏")
  status?: string;
  // @Remark("确认退房单时的用户ID")
  affirm_user_id?: number;
}

export class CheckinRoomInventoryRequest {
  // @Remark("租约ID")
  lease_id?: number;
  // @Remark("房间ID")
  room_id?: number;
}

export class CheckinRoomInventoryResponse {
  project_name?: string;
  // @Remark("水表读数")
  water_reading?: string;
  // @Remark("水表数读取时间")
  water_reading_time?: Date;
  // @Remark("热水表读数")
  hot_water_reading?:string;
  // @Remark("热水表数读取时间")
  hot_water_reading_time?:Date;
  // @Remark("电表读数")
  electricity_reading?: string;
  // @Remark("电表数读取时间")
  electricity_reading_time?: Date;
  // @Remark("房间信息")
  room_info?: RoomInfoDTO;
  // @Remark("是否安装水表")
  water_dev?: boolean;
  // @Remark("是否安装热水表")
  hot_water_dev?:boolean;
  // @Remark("是否安装电表")
  electricity_dev?: boolean;
  // @Remark("房间物品清单集合")
  inventory_list?: LeaseRoomInventoryDTO[];
  // @Remark("上次水表读数")
  top_water_reading?: string;
  // @Remark("上次热水表读数")
  top_hot_water_reading?: string;
  // @Remark("上次电表读数")
  top_electricity_reading?: string;
  // @Remark("水单价")
  water_price?: string;
  // @Remark("热水单价")
  hot_water_price?: string;
  // @Remark("电单价")
  electricity_price?: string;
  // @Remark("是否需要电：YES：是，NO：否")
  need_electricity?: string;
  // @Remark("是否需要水：YES：是，NO：否")
  need_water?: string;
  // @Remark("是否需要热水：YES：是，NO：否")
  need_hot_water?:string;


  // 是否需要刷新页面获取读数
  need_refresh_reading?: boolean;
}

export class RoomGoodsTempletRequest {
  // @Remark("租约ID")
  lease_id?: number;
  // @Remark("房间ID")
  room_id?: number;
  room_goods_info_dto_list?: RoomTypeGoodsDTO[];
  img_str?: string;
  // @Remark("手动修改水表抄表读数后的传值，不修改不用传")
  water_reading: string;
  // @Remark("手动修改热水表抄表读数后的传值，不修改不用传")
  hot_water_reading:string;
  // @Remark("手动修改电表抄表读数后的传值，不修改不用传")
  electricity_reading: string;
}

export class CheckoutInventoryViewResponse {
  // @Remark("租约信息")
  lease_info_dto?: LeaseBaseInfoDTO;
  // @Remark("租约信息")
  room_info_dto?: RoomInfo;
  lease_room_info_dto?: LeaseRoomInfoDTO;
  // @Remark("水表读数")
  water_reading?: string;
  // @Remark("水表数读取时间")
  water_reading_time?: number;
  // @Remark("热水表读数")
  hot_water_reading?: string;
  // @Remark("热水表数读取时间")
  hot_water_reading_time?: number;
  // @Remark("电表读数")
  electricity_reading?: string;
  // @Remark("电表数读取时间")
  electricity_reading_time?: number;
  // @Remark("上次水表读数")
  top_water_reading?: string;
  // @Remark("上次热水表读数")
  top_hot_water_reading?: string;
  // @Remark("上次电表读数")
  top_electricity_reading?: string;
  // @Remark("水单价")
  water_price?: string;
  // @Remark("热水单价")
  hot_water_price?: string;
  // @Remark("电单价")
  electricity_price?: string;
  // @Remark("是否安装水表")
  water_dev?: boolean;
  // @Remark("是否安装热水表")
  hot_water_dev?: boolean;
  // @Remark("是否安装电表")
  electricity_dev?: boolean;
  // @Remark("是否需要电：YES：是，NO：否")
  need_electricity?: string;
  // @Remark("是否需要水：YES：是，NO：否")
  need_water?: string;
  // @Remark("是否需要热水：YES：是，NO：否")
  need_hot_water?: string;
  // @Remark("物品交接单列表")
  room_goods_info_category_dtolist?: Array<RoomGoodsInfoCategoryDTO>;
  //当前用户名
  manage_man?: string;
  //项目名
  project_name?: string;

  // 是否需要刷新等待读数
  need_refresh_reading?: boolean;
}

export class RoomGoodsInfoCategoryDTO {
  category_name?: string;
  room_goods_info_dtolist?: Array<RoomGoodsInfoDTO>;
}

export class RoomGoodsInfoDTO {
  id?: number;
  project_id?: number;
  lease_id?: number;
  room_id?: number;
  lease_room_id?: number;
  good_name?: number;
  position?: number;
  sort?: number;
  price?: string;
  unit?: string;
  quantity?: number;
  is_bad?: string;
  init_bad?: string;
  bad_degree?: string = '1';
  compensate_price?: string;
  create_user_id?: number;
  affirm_user_id?: number;
  sys_company_id?: number;

  // custom

  room_name?: string;

  partical_damage?: number;
  full_damage?: number;
  lose?: number;
  isNumber:boolean=false;
  writeLose:boolean=false;
  moneyNumber:boolean=false;
}

export class CheckoutInventoryConfirmRequest {
  // @Remark("租约ID")
  lease_id?: number;
  // @Remark("房间ID - 水电读数")
  checkout_room_inventorydto?: CheckoutRoomInventoryDTO;
  // @Remark("物品交接单列表")
  damage_goods_list?: Array<RoomGoodsInfoDTO>;
  // @Remark("签名")
  sign?: string;

  offline?: boolean;
}

export class CheckoutRoomInventoryDTO {
  room_id?: number;
  water_reading?: string;
  hot_water_reading?: string;
  electricity_reading?: string;
}

export class CheckoutInventoryConfirmRequestDTO {
  lease_id?: number;
  sign?:string;
  checkout_reason?:string;
  offline?:boolean;
  checkout_room_inventory_dtos?:Array<CheckoutRoomInvenDTO>;
}
export class CheckoutRoomInvenDTO{
  room_id?: number;
  water_reading?: string;
  hot_water_reading?: string;
  electricity_reading?: string;
  lease_room_id?:number;
  checkout_room_type?: string;
  damage_goods_list?:Array<DamageGoodsLilsDTO>;
}
export class DamageGoodsLilsDTO{
  good_name?:string;
  id?:number;
  is_bad?:string;
  partical_damage?:number;
  full_damage?:string;
  lose?:string;
  quantity?:number;
}
