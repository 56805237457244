<div class="modal fade" bsModal #billDetailModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>账单详情</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="billDetailModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="!leaseRoomBillDetail?.went_dutch_bill">
          <div class="col-12 col-md-6 mb10">
            <p class="w90px text-right">账单金额：</p>
            <p class="condivred">{{leaseRoomBillDetail?.bill_amount}}元</p>
          </div>
          <div class="col-12 col-md-6 mb10" *ngIf="!isNoContract">
            <p class="w90px text-right">应付时间：</p>
            <p class="condiv">
              {{leaseRoomBillDetail?.plan_pay_time | date:'yyyy-MM-dd HH:mm:ss'}}
            </p>
          </div>
          <div class="col-12 col-md-6 mb10" *ngIf="isNoContract">
            <p class="w90px text-right">收款时间：</p>
            <p class="condiv">
              {{contractPayTime | date:'yyyy-MM-dd HH:mm:ss'}}
            </p>
          </div>

          <div class="col-12 col-md-6 mb10" *ngIf="!isNoContract">
            <p class="w90px text-right">账单周期：</p>
            <p class="condiv">
              <ng-template [ngIf]="leaseRoomBillDetail?.bill_start_time && leaseRoomBillDetail?.bill_end_time">
                {{leaseRoomBillDetail.bill_start_time | date:'yyyy.MM.dd'}} - {{leaseRoomBillDetail.bill_end_time |
                date:'yyyy.MM.dd'}}
              </ng-template>
              <span class="text-orange"
                    *ngIf="leaseRoomBillDetail?.is_free">（免租{{leaseRoomBillDetail?.free_day}}天）</span>
            </p>
          </div>
          <div class="col-12 col-md-6 mb10">
            <p class="w90px text-right">账单类型：</p>
            <p class="condiv">{{leaseRoomBillDetail?.bill_type_name}}</p>
          </div>
          <div class="col-12 col-md-6 mb10" *ngIf="!isNoContract">
            <p class="w90px text-right">所属合同：</p>
            <p class="condiv">{{leaseRoomBillDetail?.contract_num}}</p>
          </div>
          <div class="col-12 col-md-6 mb10" *ngIf="!isNoContract">
            <p class="w90px text-right">所属房间：</p>
            <p class="condiv">{{leaseRoomBillDetail?.room_name}}</p>
          </div>
          <div class="col-12 col-md-6 mb10" *ngIf="isNoContract">
            <p class="w90px text-right">收款人：</p>
            <p class="condiv">{{chamberlain}}</p>
          </div>
          <div class="col-12 mb20">
            <p class="w90px text-right">备注：</p>
            <p class="condiv">{{leaseRoomBillDetail?.remark}}</p>
          </div>
        </div>
        <div class="row" *ngIf="leaseRoomBillDetail?.went_dutch_bill">
          <div class="col-12 col-md-6 mb10">
            <p class="w140px text-left">应收金额：</p>
            <p class="condivred">{{leaseRoomBillDetail?.bill_amount}}元</p>
          </div>
          <div class="col-12 col-md-6 mb10" *ngIf="!isNoContract">
            <p class="w140px text-left">应收/付时间：</p>
            <p class="condivred">
              {{leaseRoomBillDetail?.plan_pay_time | date:'yyyy-MM-dd HH:mm:ss'}}
            </p>
          </div>
          <div class="col-12 col-md-6 mb10" *ngIf="isNoContract">
            <p class="w140px text-left">收款时间：</p>
            <p class="condivred">
              {{contractPayTime | date:'yyyy-MM-dd HH:mm:ss'}}
            </p>
          </div>

          <div class="col-12 col-md-6 mb10" *ngIf="!isNoContract">
            <p class="w140px text-left">账单周期：</p>
            <p class="condivred">
              <ng-template [ngIf]="leaseRoomBillDetail?.bill_start_time && leaseRoomBillDetail?.bill_end_time">
                {{leaseRoomBillDetail.bill_start_time | date:'yyyy.MM.dd'}} - {{leaseRoomBillDetail.bill_end_time |
                date:'yyyy.MM.dd'}}
              </ng-template>
              <span class="text-orange"
                    *ngIf="leaseRoomBillDetail?.is_free">（免租{{leaseRoomBillDetail?.free_day}}天）</span>
            </p>
          </div>
          <div class="col-12 col-md-6 mb10">
            <p class="w140px text-left">账单类型：</p>
            <p class="condivred">{{leaseRoomBillDetail?.bill_type_name}}</p>
          </div>
          <div class="col-12 col-md-6 mb10" *ngIf="!isNoContract">
            <p class="w140px text-left">所属合同：</p>
            <p class="condivred">{{leaseRoomBillDetail?.contract_num}}</p>
          </div>
          <div class="col-12 col-md-6 mb10" *ngIf="!isNoContract">
            <p class="w140px text-left">所属房间：</p>
            <p class="condivred">{{leaseRoomBillDetail?.room_name}}</p>
          </div>
          <div class="col-12 col-md-6 mb10">
            <p class="w140px text-left">费用分摊总额：</p>
            <p class="condivred">{{leaseRoomBillDetail?.share_amount}}元</p>
          </div>
          <div class="col-12 col-md-6 mb10">
            <p class="w140px text-left">签约人应收/付金额：</p>
            <p class="condivred">{{leaseRoomBillDetail?.subject_pay_amount}}元</p>
          </div>
          <div class="col-12 col-md-6 mb10" *ngIf="isNoContract">
            <p class="w140px text-left">收款人：</p>
            <p class="condivred">{{chamberlain}}</p>
          </div>
          <div class="col-12 col-md-6 mb10">
            <p class="w140px text-left">账单金额：</p>
            <p class="condivred">{{leaseRoomBillDetail?.bill_amount}}元</p>
          </div>
          <div class="col-12 mb20">
            <p class="w140px text-left">备注：</p>
            <p class="condiv">{{leaseRoomBillDetail?.remark}}</p>
          </div>
        </div>

        <!-- 如果是退款单，需要显示退款金额的来源账单-->
        <ng-container  *ngIf="leaseRoomBillDetail?.bill_biz_type == 'REFUND'">
          <br/>
          <div class="black mb10">
            <ng-container *ngIf="leaseRoomBillDetail?.parent_bill_id">
              此退款【{{leaseRoomBillDetail?.bill_type_name.substring(2)}}】
              关联
              【{{leaseRoomBillDetail?.parent_bill_type_name}}】
              收款账单号：
              <button class="btn btn-outline-primary" (click)="showModel(leaseRoomBillDetail?.parent_bill_id)">{{leaseRoomBillDetail?.parent_bill_no}}</button>
            </ng-container>
          </div>
          <br/>
        </ng-container>

        <!--psmV1.2.7交易明细-->
        <div class="row">
          <p class="black mb10 col-6" *ngIf="!billPayLogLists || billPayLogLists.length===0">此账单共有0条 交易明细：</p>
          <p class="black mb10 col-6" *ngIf="billPayLogLists && billPayLogLists.length>0">此账单共有{{billPayLogLists.length}}条 交易明细：</p>
          <p class="black mb10 text-right col-6">实收金额：{{received}}元</p>
        </div>
        <div class="table-box">
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th>交易时间</th>
              <th>交易类型</th>
              <th>交易方式</th>
              <th>付款方</th>
              <th>交易金额</th>
              <th>相关支付流水或凭证</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let billPayLogs of billPayLogLists">
              <td>{{billPayLogs.pay_time | date:'yyyy-MM-dd HH:mm:ss'}}</td>
              <td>{{billPayLogs.bill_type}}</td>
              <td>{{billPayLogs.pay_type}}</td>
              <td>{{billPayLogs.pay_user}}</td>
              <td>{{billPayLogs.pay_amount}}</td>
              <td>
                【{{billPayLogs.source_type_name}}】
                <ng-template [ngIf]="billPayLogs.source_type==='PAY_LOG' || billPayLogs.source_type==='BOOKING' || billPayLogs.source_type==='COUPON'">
                  <button type="button" class="btn btn-outline-primary"
                          (click)="showDetail(billPayLogs.source_id,billPayLogs.source_type)">{{billPayLogs.source_no}}
                  </button>
                </ng-template>
                <ng-template [ngIf]="billPayLogs.source_type!=='PAY_LOG' && billPayLogs.source_type!=='BOOKING' && billPayLogs.source_type!=='COUPON'">
                  <ng-container *ngIf="billPayLogs.relate_bill">
                    <button class="btn btn-outline-primary" (click)="showModel(billPayLogs.source_bill_id)">{{billPayLogs.source_no}}</button>
                  </ng-container>
                  <ng-container *ngIf="!billPayLogs.relate_bill">
                    <span class="text-blue">{{billPayLogs.source_no}}</span>
                  </ng-container>
                </ng-template>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="text-center" style="margin-top:20px;">
          <button type="button" class="btn btn-warning mr20"
                  (click)="showDialog(leaseRoomBillDetail.lease_id,leaseRoomBillDetail.bill_id)"
                  *ngIf="(leaseRoomBillDetail?.bill_status==='NO_PAYMENT' || leaseRoomBillDetail?.bill_status==='PARTIAL_PAYMENT')  && leaseRoomBillDetail?.bill_charge_off_status==='OUT' && LEASE_BILL">
            <i class="fa fa-cny"></i>收款
          </button>
          <button type="button" class="btn btn-primary mr20"
                  (click)="printshow(leaseRoomBillDetail.lease_id,leaseRoomBillDetail.bill_id)">
            <i class="fa fa-print"></i>打印
          </button>
          <button type="button" class="btn btn-secondary" (click)="billDetailModal.hide()">关闭</button>
        </div>
      </div>

    </div>
  </div>

</div>

<app-dialog></app-dialog>
<!--<app-print-preview #printprew [src]="contractPath"></app-print-preview>-->


