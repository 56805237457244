import { PageReq, PageResp } from '../page/page';
import { LeaseRoomInfoDTO } from './lease.model';
import { CheckOutRoomInfoDTO, ObjectDTO } from '../signed/checkout.model';

/**
 * @Description: 获取换房记录列表请求
 * @Author: 陆炳彬
 * @Date: 2019/1/4 下午2:02
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/4 下午2:02
 */
export class RoomChangeListReq extends PageReq {
  // 租约id
  lease_id: number;
}

/**
 * @Description: 获取换房记录列表返回
 * @Author: 陆炳彬
 * @Date: 2019/1/4 下午2:02
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/4 下午2:02
 */
export class RoomChangeListResp extends PageResp {
  // 列表
  room_change_record_list: RoomChangeRecordListItem[];
}

/**
 * @Description: 换房记录列表对象
 * @Author: 陆炳彬
 * @Date: 2019/1/4 下午2:06
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/4 下午2:06
 */
export class RoomChangeRecordListItem {
  // 记录id
  record_id: number;
  // 原房间名
  origin_room_name: string;
  // 新房间名
  new_room_name: string;
  // 原租金
  origin_avg_month_rent: number;
  // 新租金
  new_avg_month_rent: number;
  // 状态
  status: string;
  // 步骤
  operate_step: string;
  // 审核失败原因
  audit_fail_reason: string;
  origin_rent_type: string;
  new_rent_type: string;
  origin_lease_room_id: number;
  new_lease_room_id: number;
}

/**
 * @Description: 选择房间
 * @Author: 陆炳彬
 * @Date: 2019/1/4 下午4:09
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/4 下午4:09
 */
export class RoomChooseInfo {
  // 房间租约id
  lease_room_id: number;
  // 房间id
  room_id: number;
  // 房间号
  room_name: string;

  constructor(lease_room_id: number, room_id: number, room_name: string) {
    this.lease_room_id = lease_room_id;
    this.room_id = room_id;
    this.room_name = room_name;
  }
}

/**
 * @Description: 获取老房间租约信息请求
 * @Author: 陆炳彬
 * @Date: 2019/1/7 上午10:01
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/7 上午10:01
 */
export class RoomChangeSettingReq {
  // 租约id
  lease_id: number;
  // 原房间id
  origin_lease_room_id: number;
  // 新房间id
  new_lease_room_id: number;
}

/**
 * @Description: 获取新房间租约、老房间租约信息返回
 * @Author: 陆炳彬
 * @Date: 2019/1/7 上午10:02
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/7 上午10:02
 */
export class RoomChangeSettingResp {
  // 老房间合同信息
  origin_lease_room_info: LeaseRoomInfoDTO;
  // 新房间合同信息
  new_lease_room_info: LeaseRoomInfoDTO;
  lease_step?: string;
}

/**
 * @Description: 换房申请请求
 * @Author: 陆炳彬
 * @Date: 2019/1/7 上午10:41
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/7 上午10:41
 */
export class RoomChangeApplyReq {
  // 租约id
  lease_id: number;
  // 原始房间租约id
  origin_lease_room_id: number;
  // 新房间租约信息
  new_room_info: LeaseRoomInfoDTO;
  // 申请类型
  apply_type: string;
  // 申请原因
  reason: string;
  // 换房时间
  change_time: number;
}

/**
 * @Description: 换房申请返回
 * @Author: 陆炳彬
 * @Date: 2019/1/7 下午7:46
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/7 下午7:46
 */
export class RoomChangeApplyResp {
  record_id: number;
}

/**
 * @Description: 换房审核返回
 * @Author: 陆炳彬
 * @Date: 2019/1/7 下午3:59
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/7 下午3:59
 */
export class RoomChangeAuditDetailResponse {
  room_change_info: RoomChangeInfo;
  new_room_base_price: number;
  charge_service_fee: number;
  contract_num: string;
  start_time: Date;
  end_time: Date;
}

export class RoomEditAuditDetailResponse {
  room_edit_record_id: number;
  project_name: string;
  project_address: string;
  create_time: Date;
  snapshot_info: Array<AuditSnapshotInfo>;
}

export class AuditSnapshotInfo {
  room_name: string;
  old_room_type_name: string;
  new_room_type_name: string;
  old_base_price: number;
  new_base_price: number;
  old_room_area: string;
  new_room_area: string;
  old_bottom_price:string;
  bottom_price:string;
}

/**
 * @Description: 换房详情
 * @Author: 陆炳彬
 * @Date: 2019/1/7 下午4:05
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/7 下午4:05
 */
export class RoomChangeInfo {
  record_id: number;
  record_number: string;
  lease_id: number;
  origin_room_id: number;
  origin_room_num: string;
  origin_room_avg_price: number;
  new_room_id: number;
  new_room_num: string;
  new_room_avg_price: number;
  origin_lease_room_id: number;
  new_lease_room_id: number;
  change_time: Date;
  status: string;
  apply_type: string;
  apply_reason: string;
  operate_step: string;
  refund_id: number;
  create_user_id: number;
  create_user_name: string;
  create_time: Date;
}

/**
 * @Description: 换房审核请求
 * @Author: 陆炳彬
 * @Date: 2019/1/7 下午4:28
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/7 下午4:28
 */
export class AuditRoomChangeReq {
  record_id: number;
  status: string;
  remark: string;
}

/**
 * @Description: 获取换房记录新老房间租约请求
 * @Author: 陆炳彬
 * @Date: 2019/1/8 上午10:26
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/8 上午10:26
 */
export class RoomChangeCommonReq {
  // 换房记录id
  record_id: number;

  constructor(record_id: number) {
    this.record_id = record_id;
  }
}

export class RoomChangeContractExtReq {
  record_id: number;
  is_detail: boolean;

  constructor(record_id: number, is_detail: boolean) {
    this.record_id = record_id;
    this.is_detail = is_detail;
  }
}

/**
 * @Description: 获取换房记录新老房间租约返回
 * @Author: 陆炳彬
 * @Date: 2019/1/8 上午10:28
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/8 上午10:28
 */
export class RoomChangeConfirmResp {
  // 老房间合同信息
  origin_lease_room_info: LeaseRoomInfoDTO;
  // 新房间合同信息
  new_lease_room_info: LeaseRoomInfoDTO;
  // 换房记录
  room_change_record: RoomChangeInfo;
  lease_step?: string;
}

/**
 * @Description: 换房操作请求
 * @Author: 陆炳彬
 * @Date: 2019/1/8 下午5:15
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/8 下午5:15
 */
export class RoomChangeCancelReq {
  // 操作类型
  operate_type: string;
  // 换房记录id
  record_id: number;

  constructor(operate_type: string, record_id: number) {
    this.operate_type = operate_type;
    this.record_id = record_id;
  }
}

/**
 * @Description: 获取可换房的房间列表
 * @Author: 陆炳彬
 * @Date: 2019/1/8 下午7:43
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/8 下午7:43
 */
export class RoomChangeAvaliableReq {
  lease_id: number;

  constructor(lease_id: number) {
    this.lease_id = lease_id;
  }
}

/**
 * @Description: 换房可用房间租约列表返回
 * @Author: 陆炳彬
 * @Date: 2019/1/8 下午8:03
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/8 下午8:03
 */
export class RoomChangeAvaliableResp {
  lease_room_info_list: LeaseRoomInfoDTO[];
}

/**
 * @Description: 切换步骤
 * @Author: 陆炳彬
 * @Date: 2019/1/9 上午10:42
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/9 上午10:42
 */
export class RoomChangeGotoStepReq {
  record_id: number;
  step: string;

  constructor(record_id: number, step: string) {
    this.record_id = record_id;
    this.step = step;
  }
}

export enum ROOM_CHANGE_STEP {
  // 换房确认
  CONFIRM = 'CONFIRM',
  // 换房单
  RECORD = 'RECORD',
  // 换房协议
  CONTRACT = 'CONTRACT'
}

/**
 * @Description: 获取换房单详情请求
 * @Author: 陆炳彬
 * @Date: 2019/1/9 上午11:30
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/9 上午11:30
 */
export class RoomChangeBillsReq {
  record_id: number;

  constructor(record_id: number) {
    this.record_id = record_id;
  }
}

/**
 * @Description: 获取换房单详情返回
 * @Author: 陆炳彬
 * @Date: 2019/1/9 上午11:31
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/9 上午11:31
 */
export class RoomChangeBillsResp {
  // 原房间账单信息
  origin_room_bills: CheckOutRoomInfoDTO;
  // 新房间账单信息
  new_room_bills: CheckOutRoomInfoDTO;
  // 预付账户转移
  prepay_account: ObjectDTO;
  // 换房记录
  room_change_info: RoomChangeInfo;
  contract_num: string;
  lease_start_time: Date;
  lease_end_time: Date;
  total_amount: number;
  operate_flag: string;
  gathered: boolean;
  lease_id: number;
}

/**
 * @Description: 换房确认请求
 * @Author: 陆炳彬
 * @Date: 2019/1/9 下午5:52
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/9 下午5:52
 */
export class RoomChangeRecordConfirmReq {
  record_id: number;

  checkout_bill_pay_type_enum: string;

  refund_name: string;

  open_bank: string;

  bank_no: string;

  total: number;

  no_bill: boolean;

  remark: string;

  account_money_info_id: number;
}

/**
 * @Description: 换房协议额外信息返回
 * @Author: 陆炳彬
 * @Date: 2019/1/9 下午5:52
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/9 下午5:52
 */
export class RoomChangeContractExtResp {
  // 换房单号
  record_number: string;
  // 换房单状态
  status: string;
  // 合同详情
  contract_details_map: any;

  lease_id: number;
}

/**
 * @Description: 合同上传签名
 * @Author: 陆炳彬
 * @Date: 2019/1/10 上午10:14
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/10 上午10:14
 */
export class RoomChangeContractSignUploadReq {
  record_id: number;
  img_str: string;

  constructor(record_id: number, img_str: string) {
    this.record_id = record_id;
    this.img_str = img_str;
  }
}

/**
 * 换房确认提交（无合同换房、有合同合同内换房）
 */
export class RenterChangeConfirmReq {
  lease_id: number;

  origin_lease_room_id: number;

  new_room_id: number;

  renter_ids: Array<number>;
}

export class ChangeRoomNowTermRentBillResp{
  bill_start_time: Date;
 }

export class ChangeRoomNowTermRentBillReq{
  lease_id: number;

  origin_lease_room_id: number;

  origin_room_id: number;

  change_time: number;
}
