import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {ModalDirective} from "ngx-bootstrap/modal";
import {WorkModel} from "../../model/common/common.model";
import {BindingIpadPcRequest, LeaseCheckoutRequest, SignIpadRequest,} from "../../model/devicebinding/devicebinding.model";
import {SignOnIpadRequest} from "../../model/signed/sign.model";
import {AuthService} from "../../service/common/auth.service";
import {WorkService} from "../../service/common/work.service";
import {DevicebindingService} from "../../service/devicebinding/devicebinding.service";
import {SignedService} from "../../service/signed/signed.service";

import { v4 as uuidv4 } from 'uuid';


@Component({
  selector: 'app-sign-device-binding',
  templateUrl: './sign-device-binding.component.html',
  styleUrls: ['./sign-device-binding.component.css']
})
export class SignDeviceBindingComponent implements OnInit, OnDestroy {

  constructor(
    private authService: AuthService,
    private workService: WorkService,
    private devicebindingService: DevicebindingService,
    private signedService: SignedService,
    private router: Router,
  ) {
  }

  @ViewChild('binddingModal1', {static: true}) binddingModal1: ModalDirective;
  @ViewChild('binddingModal2', {static: true}) binddingModal2: ModalDirective;
  @ViewChild('binddingModal3', {static: true}) binddingModal3: ModalDirective;
  @ViewChild('binddingModal4', {static: true}) binddingModal4: ModalDirective;
  @ViewChild('binddingModal5', {static: true}) binddingModal5: ModalDirective;
  @ViewChild('binddingModal6', {static: true}) binddingModal6: ModalDirective;
  @ViewChild('binddingModal7', {static: true}) binddingModal7: ModalDirective;
  @Output() signChange = new EventEmitter();
  @Output() signGoodsChange = new EventEmitter();
  @Output() signInventoryChange = new EventEmitter();

  pc_uuid: string;

  ipad_uuid: string;

  ipad_uuids: Array<string>;

  //定时器
  timer;
  timer1;

  signIpadResponse: SignOnIpadRequest;

  sign_request: SignOnIpadRequest;

  req_lease_id;
  req_room_id;
  req_lease_room_id;
  req_record_id;
  link_type;
  business_type;

  checkout_room_id: number;

  ngOnDestroy() {
    this.cleanTime();
    this.cleanTime1();
  }

  ngOnInit() {
    this.ipad_uuid = null;
    this.ipad_uuids = null;
  }

  getUuuId() {
    this.pc_uuid = localStorage.getItem("PC_UUID");
    if (!this.pc_uuid) {
      //设置uuid;
      localStorage.setItem("PC_UUID", uuidv4());
      this.pc_uuid = localStorage.getItem("PC_UUID");
    }
  }

  openModel(request: SignOnIpadRequest, type: number) {
    this.link_type = type;
    this.sign_request = request;
    this.req_lease_id = this.sign_request.sign_data_request.lease_id;
    this.req_room_id = this.sign_request.sign_data_request.room_id;
    this.req_lease_room_id = this.sign_request.sign_data_request.lease_room_id;
    this.req_record_id = this.sign_request.sign_data_request.record_id;
    this.business_type = this.sign_request.sign_router_link_type;
    if (this.business_type && (this.business_type === 'CHECKOUT_LIST' || this.business_type === 'DELAY_CHECKOUT_LIST')) {
      this.checkout_room_id = this.sign_request.sign_data_request.checkout_view_dtolist[0].room_id;
    }
    //获取设备唯一id；
    this.getUuuId();
    //判断该设备是否第一次连接
    this.sign_request.computer_no = this.pc_uuid;
    let bindingIpadPcRequest = new BindingIpadPcRequest();
    bindingIpadPcRequest.pc_uuid = this.pc_uuid;
    this.devicebindingService.queryIpadPc(bindingIpadPcRequest).then(data => {
      if (data && data.ipad_uuid && data.pc_uuid) {
        this.ipad_uuid = data.ipad_uuid;
        this.binddingModal3.show();
        this.connectDaiog(1);
      } else {
        this.binddingModal1.show();
        this.searchDaiog();
      }
    });
  }

  searchDaiog() {
    const that = this;
    setTimeout(function () {
      //定时，没2秒拉取一次待连接设备列表
      that.timer = setInterval(() => {
        that.getRedisIpadPc();
      }, 1000 * 2);
    }, 2000);
  }

  connectDaiog(type: number) {
    if (type === 1) {
      const that = this;
      setTimeout(function () {
        that.binddingModal3.hide();
        that.binddingModal4.show();
      }, 2000);
    } else if (type === 2) {
      this.binddingModal3.show();
      let bindingIpadPcRequest1 = new BindingIpadPcRequest();
      bindingIpadPcRequest1.pc_uuid = this.pc_uuid;
      bindingIpadPcRequest1.ipad_uuid = this.ipad_uuid;
      bindingIpadPcRequest1.type = 'INSERT';
      this.devicebindingService.bindingIpadPc(bindingIpadPcRequest1).then(data => {
        if (data && data.result === 'SUCCESS') {
          const that = this;
          setTimeout(function () {
            that.binddingModal3.hide();
            that.binddingModal4.show();
          }, 2000);
        } else {
          this.binddingModal3.hide();
          this.workService.showErrorModal('建立连接失败：' + data.result);
        }
      }).catch(err => {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, err.message));
      });
    }
  }

  breakConnect() {
    let bindingIpadPcRequest1 = new BindingIpadPcRequest();
    bindingIpadPcRequest1.pc_uuid = this.pc_uuid;
    bindingIpadPcRequest1.ipad_uuid = this.ipad_uuid;
    bindingIpadPcRequest1.type = 'DELETE';
    this.devicebindingService.bindingIpadPc(bindingIpadPcRequest1).then(data => {
      if (data && data.result === 'SUCCESS') {
        this.binddingModal5.hide();
        this.binddingModal7.show();
      } else {
        this.workService.showErrorModal('断开连接失败！');
      }
    }).catch(err => {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, err.message));
    });
  }

  getRedisIpadPc() {
    this.devicebindingService.getRedisIpadPc().then(data => {
      if (data && data.ipad_uuids && data.ipad_uuids.length > 0) {
        this.cleanTime();
        this.ipad_uuids = data.ipad_uuids;
        this.binddingModal1.hide();
        this.binddingModal2.show();
      }
    }).catch(err => {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, err.message));
    });
  }

  querySignStatus() {
    let signIpadRequest = new SignIpadRequest();
    signIpadRequest.pad_uuid = this.ipad_uuid;
    this.devicebindingService.querySignStatus(signIpadRequest).then(data => {
      this.signIpadResponse = data;
      if (this.signIpadResponse && "FINISHED" === this.signIpadResponse.status) {
        this.cleanTime1();
        this.binddingModal5.hide();
        if (this.signIpadResponse.sign_router_link_type === 'CONVENTION') {
          this.signChange.emit(this.signIpadResponse.sign_data_request.lease_id);
        } else {
          this.binddingModal6.show();
        }
      } else {
        this.workService.showErrorModal('请等待客户在pad设备上提交签字内容！');
      }
    }).catch(err => {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, err.message));
    });
  }

  pushSignData() {
    this.signedService.signOnIpad(this.sign_request).then(data => {
      this.binddingModal4.hide();
      this.binddingModal5.show();
      //定时拉取ipad签名状态
      this.timer1 = setInterval(() => {
        this.querySignStatusTimer();
      }, 1000 * 5);
    }).catch(err => {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, err.message));
    });
  }

  querySignStatusTimer() {
    let signIpadRequest = new SignIpadRequest();
    signIpadRequest.pad_uuid = this.ipad_uuid;
    this.devicebindingService.querySignStatus(signIpadRequest).then(data => {
      this.signIpadResponse = data;
      if (this.signIpadResponse && 'FINISHED' === this.signIpadResponse.status) {
        this.cleanTime1();
        this.binddingModal5.hide();
        if (this.signIpadResponse.sign_router_link_type === 'CONVENTION') {
          this.signChange.emit(this.signIpadResponse.sign_data_request.lease_id);
        } else {
          this.binddingModal6.show();
        }
      }
    });
  }

  //跳转页面
  skip() {
    //if(this.signIpadResponse){
    if (this.business_type === 'CONTRACT') {//合同签字

      this.workService.event_bus.emit(new WorkModel(this.workService.type.TAB_CLOSE_SELF));

      this.router.navigate(["/system_manage/signed/contract/preview", this.req_lease_id]);
    } else if (this.business_type === 'GOODS_LIST') {//入住物品交接单
      if (this.link_type === 2) {
        this.router.navigateByUrl(
          "/system_manage/signed/goods/" + this.req_lease_id + "/" + this.req_room_id + "/" + this.req_lease_room_id);
      } else {
        this.signGoodsChange.emit();
      }
    } else if (this.business_type === 'REDIS_GOODS_LIST') {//换房入住物品交接单
      this.signGoodsChange.emit();
    } else if (this.business_type === 'INVENTORY_LIST') {//退房物品交接单
      this.signInventoryChange.emit();
    } else if (this.business_type === 'REDIS_INVENTORY_LIST') {//退房物品交接单
      this.router.navigateByUrl("/system_manage/signed/inventoryview?lease_id=" + this.req_lease_id + "&room_id=" + this.req_room_id + "&lease_room_id=" + this.req_lease_room_id);
    } else if (this.business_type === 'CHECKOUT_LIST') {//退房签字
      this.queryCheckoutNumber(this.req_lease_id);
    } else if (this.business_type === 'CHANGE_ROOM') {//换房签字

      this.router.navigateByUrl("system_manage/signed/change/room/details/" + this.req_record_id);
    } else if (this.business_type === 'DELAY_CHECKOUT_LIST') {//延期退房签字
      this.queryCheckoutNumber(this.req_lease_id);
    }
    //}
  }

  queryCheckoutNumber(leaseId: number) {
    let leaseCheckoutRequest = new LeaseCheckoutRequest();
    leaseCheckoutRequest.lease_id = leaseId;
    this.devicebindingService.queryCheckoutNumber(leaseCheckoutRequest).then(data => {
      if (data && data.lease_checkout_dtos) {
        let recordNo = data.lease_checkout_dtos.filter(list => list.room_id === this.checkout_room_id).pop().record_no;
        this.router.navigateByUrl("/system_manage/signed/checkoutrecord?id=" + recordNo);
      }
    });
  }


  changeIpadId(ipadUuid: string) {
    this.ipad_uuid = ipadUuid;
  }

  cleanTime() {
    clearInterval(this.timer);
  }

  cleanTime1() {
    clearInterval(this.timer1);
  }

  cclean(event: any) {
    event.stopPropagation();//阻止向上冒泡
  }

}
