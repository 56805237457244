import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RequestService } from '../request.service';

/**
 * @Description: 预付账户服务
 * @Author: 徐礼华
 * @Date: 2018/3/29 上午10:23
 * @Last Modified by: 徐礼华
 * @Last Modified time: 2018/3/29 上午10:23
 */
@Injectable()
export class PrepayService {

  //  预付账户详情
  private PREPAY_ACCOUNT_DETAIL = `${environment.apiHost}prepay/account/detail`;

  // 租约下有效的房间预付账户 列表
  private PREPAY_ACCOUNT_LIST = `${environment.apiHost}prepay/account/list`;

  // 单个房间预付账户充值
  private PREPAY_ACCOUNT_RECHARGE = `${environment.apiHost}prepay/account/recharge`;

  //  账户提现
  private PREPAY_ACCOUNT_WITHDRAW = `${environment.apiHost}prepay/account/withdraw`;

  // 预付账户现金充值生成 code
  private ACCOUNT_RECHARGE_CODE = `${environment.apiHost}prepay/account/recharge/cash/code`;

  // 企业合同房间预付账户批量充值
  private PREPAY_BATCH_RECHARGE = `${environment.apiHost}prepay/batch/recharge/room/prepay`;

  constructor(private requestService: RequestService) {
  }

  /**
   * 预付账户详情
   */
  public accountDetail(requestData: any): Promise<any> {
    return this.requestService.authGet(this.PREPAY_ACCOUNT_DETAIL, requestData);
  }

  /**
   * 租约下有效的房间预付账户 列表
   */
  public accountList(requestData: any): Promise<any> {
    return this.requestService.authGet(this.PREPAY_ACCOUNT_LIST, requestData);
  }

  /**
   *  账户充值
   */
  public accountRecharge(requestData: any): Promise<any> {
    return this.requestService.authPost(this.PREPAY_ACCOUNT_RECHARGE, requestData);
  }

  /**
   * 账户提现
   */
  public accountWithdraw(requestData: any): Promise<any> {
    return this.requestService.authPost(this.PREPAY_ACCOUNT_WITHDRAW, requestData);
  }

  /**
   * 预付账户现金充值生成CODE
   * @param requestData
   */
  public accountRechargeCode(requestData: any): Promise<any> {
    return this.requestService.authPost(this.ACCOUNT_RECHARGE_CODE, requestData);
  }

  /**
   * 企业合同房间预付账户批量充值
   * @param requestData
   * @returns {Promise<any>}
   */
  public accountBatchRecharge(requestData: any): Promise<any> {
    return this.requestService.authPost(this.PREPAY_BATCH_RECHARGE, requestData);
  }

}
