<!-- {{initSomething(expense_modal)}} -->

<!-- 设置附加费用 -->
<form [formGroup]="expense_form_group">
  <div class="modal fade" bsModal #expense_modal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
       aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-primary modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>设置附加费用</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
            <span><i class="icon icon-close" aria-hidden="true"></i></span>
          </button>
        </div>
        <div class="modal-body" formArrayName="expense_array">
          <button type="button" class="btn btn-info mb10" (click)="addNewExpense()">
            <i class="fa fa-plus"></i>增加一行
          </button>
          <div class="input-box">
            <ul *ngFor="let expense of expenseArray.controls; let h=index;" [formGroupName]="h" >
              <li class="mb10 ">
                <select class="w-100" formControlName="gather_type">
                  <option *ngFor="let gather_type of gather_type_list" [value]="gather_type.code">{{gather_type.desc}}
                  </option>
                </select>
                <p class="text-danger" *ngIf="isError(is_submit, expense, 'gather_type', 'required')">
                  不能为空
                </p>
              </li>

              <li class="mb10 ">
                <input type="text" class="w-100" placeholder="请输入费用" formControlName="expense_value" autocomplete="false">
                <p class="text-danger" *ngIf="isError(is_submit, expense, 'expense_value', 'required')">
                  不能为空
                </p>
                <p class="text-danger" *ngIf="isError(is_submit, expense, 'expense_value', 'formatError')">
                  格式错误(1-10000范围内,小数点后至多两位的数字)
                </p>
              </li>

              <li class="mb10 ">
                <ng-select [items]="bill_type_other_list" [addTag]="addItem" addTagText="添加费用类型" placeholder="费用类型"
                           formControlName="expense_name">
                </ng-select>
                <p class="text-danger" *ngIf="isError(is_submit, expense, 'expense_name', 'required')">
                  不能为空
                </p>
              </li>

              <li class="mb10">
                <i class="fa fa-times fa-size" (click)="removeExpense(h)" style="cursor: pointer;"></i>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-primary" (click)="confirmExpenseModal()">确定</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hide()">取消
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
