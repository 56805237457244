import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {BillService} from "../../../service/finance/bill.service";
import {
  AuditPayLogRequest,
  LeaseRoomBillDetailParam,
  PayBillDetailDTO,
  PayLogDTO
} from "../../../model/finance/bill.model";
import {WorkService} from "../../../service/common/work.service";
import {AuthService} from "../../../service/common/auth.service";
// import {GALLERY_CONF, GALLERY_IMAGE, NgxImageGalleryComponent} from "ngx-image-gallery";
import {environment} from "../../../../environments/environment";
import {BaseInfoService} from '../../../service/common/base-info.service';
import {BaseEnum} from '../../../model/modal/base-model';
import {MessageModalInfo} from '../../../model/modal/message-model';
import {WorkModel} from '../../../model/common/common.model';
import {LeaseLogComponent} from '../../shared/log/lease-log.component';
import {Router} from "@angular/router";
import {ModalDirective} from "ngx-bootstrap/modal";
import {IMyDpOptions} from "../../third-party/mydatepicker/src/lib/interfaces";
import {getTimeFromPlugin} from "../../../common/date.util";
import {ImagePreviewModel} from "../../../model/image/image-file";

@Component({
  selector: 'app-pay-log-detail',
  templateUrl: './pay-log-detail.component.html',
  styleUrls: ['./pay-log-detail.component.css']
})
export class PayLogDetailComponent implements OnInit {

  // 回调事件
  @Output() payLogDetailChange = new EventEmitter();

  @Output() auditingChange = new EventEmitter();

  // @ViewChild('imageGallery', { static: true }) imageGallery: NgxImageGalleryComponent;

  // @ViewChild('printPreview') printPreview: PrintPreviewComponent;

  @ViewChild('logModal', { static: true }) logModal: LeaseLogComponent;

  // galleryConf: GALLERY_CONF = {
  //   imageOffset: '0px',
  //   showDeleteControl: false,
  //   showImageTitle: false,
  // };

  // images: GALLERY_IMAGE[] = [];

  contractPath: string;

  // 按钮权限对象
  btn_privileges: any = {
    'ACCOUNTTING_VIEW': false,
    'ACCOUNTTING_CHECK': false,
  };

  // 支付流水ID
  payLogId: number;

  // 支付流水详细
  payLogDetail: PayLogDTO;

  // 支付账单明细 列表
  payBillDetailList: Array<PayBillDetailDTO>;

  // 收款详细弹窗
  @ViewChild('payLogDetailModal') payLogDetailModal: ModalDirective;


  // 支票支付状态列表
  checkList: BaseEnum[];

  // 核销请求
  auditPayLogReq: AuditPayLogRequest;
  passwordError: boolean = false;

  createNumValue: string;
  currentPassword: string = '';
  // 手动填写到账时间
  manual_account_time: any;
  now: Date = new Date();
  accountTimeOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    showClearDateBtn: true,
    disableDateRanges: [{
      begin: {year: this.now.getFullYear(), month: (this.now.getMonth() + 1), day: this.now.getDate() + 1},
      end: {year: 2999, month: 1, day: 1}
    }]
  };

  constructor(private billService: BillService,
              private workService: WorkService,
              private baseService: BaseInfoService,
              private authService: AuthService,
              private router: Router
  ) {
  }

  ngOnInit() {
    // 获取权限
    this.authService.getOperatePrivilege(this.btn_privileges);

    // 获取支票支付状态列表
    this.checkList = this.baseService.getCheckList();

  }


  // // 初始化弹窗
  // setPayLogDetailModal(modal: any) {
  //   this.payLogDetailModal = modal;
  // }

  // 支付流水详细
  getPaylogDetail(): void {
    let param = new LeaseRoomBillDetailParam(this.payLogId, null);
    this.billService.getPaylogDetailt(param).then(data => {
      this.auditPayLogReq = new AuditPayLogRequest();
      if (data) {
        this.payLogDetail = data;
        this.auditPayLogReq.is_checked = data.check === 'CHECKED';
        this.auditPayLogReq.is_pay = data.audit_status === 'PAY';
        this.auditPayLogReq.pay_id = this.payLogId;
      } else {
        this.payLogDetail = null;
      }
    });
  }

  // 支付账单明细 列表
  getPaylogDetailList(): void {
    let param = new LeaseRoomBillDetailParam(this.payLogId, null);
    this.billService.getPaylogDetailtList(param).then(data => {
      if (data && data.pay_bill_list) {
        this.payBillDetailList = data.pay_bill_list;
      } else {
        this.payBillDetailList = null;
      }
    });
  }

  // 提交
  doSubmit(): void {
    this.auditPayLogReq.is_not_posted = false;
    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.cancel = true;

    if (this.auditPayLogReq.is_pay) {
      messageModalInfo.operationMessage = '确认将此支付流水变成已核销吗？变更后不能再更改';
    } else {
      if (this.payLogDetail.is_check_pay && this.auditPayLogReq.is_checked) {
        messageModalInfo.operationMessage = '确认将此支付流水变成支票已收吗？变更后不能再更改';
      }
    }

    messageModalInfo.callback.subscribe(() => {
      this.doAudit();
    });
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
  }

  // 保存
  doAudit(model?: any) {
    this.auditPayLogReq.pay_log_no = this.createNumValue;
    if (this.auditPayLogReq.is_pay === true && this.manual_account_time == null) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请选择到账日期'));
      return;
    }
    if (this.manual_account_time) {
      this.auditPayLogReq.manual_account_time = getTimeFromPlugin(this.manual_account_time.formatted);
    }
    this.billService.auditing(this.auditPayLogReq).then(data => {
      if (data.information && data.information === '密码错误') {
        this.passwordError = true;
      } else {

        if (model) {
          model.hide();
        }
        this.payLogDetailModal.hide();
        this.auditingChange.emit();
        this.auditPayLogReq.password = '';
        this.currentPassword = '';
        this.auditPayLogReq.is_not_posted = false;
      }
    });
  }

  // 支票收到改变(勾选了已支付，必须支票已收)
  checkChange(isCheck: boolean) {
    if (!isCheck && this.auditPayLogReq.is_pay) {
      this.auditPayLogReq.is_pay = false;
    }
  }

  // 支付状态变化(勾选了已支付，必须支票已收)
  payChange(isPay: boolean) {
    if (isPay) {
      this.auditPayLogReq.is_checked = true;
      this.auditPayLogReq.is_not_posted = false;
    } else {
      this.auditPayLogReq.is_checked = false;
      this.auditPayLogReq.is_not_posted = false;
    }
  }

  // 款未到账
  thePaymentIsNotPosted(isPay: boolean, modal?: any) {
    if (isPay) {
      this.auditPayLogReq.is_pay = false;
      this.auditPayLogReq.is_checked = false;
      this.auditPayLogReq.is_not_posted = true;
      if (modal) {
        modal.show();
      }
    } else {
      this.auditPayLogReq.is_not_posted = false;
      this.auditPayLogReq.is_checked = false;
      if (modal) {
        modal.hide();
      }
    }
    this.auditPayLogReq.password = '';
    this.currentPassword = '';
    this.passwordError = false;
  }

  cancelAuditingReject(confirmModal: any) {
    this.auditPayLogReq.is_not_posted = false;
    this.auditPayLogReq.password = '';
    this.currentPassword = '';
    this.passwordError = false;
    confirmModal.hide();
  }

  confirmRefuse(model: any) {
    if (!this.auditPayLogReq.password) {
      this.passwordError = true;
    }
    if (this.auditPayLogReq && this.auditPayLogReq.is_not_posted && !this.auditPayLogReq.is_pay) {
      this.passwordError = false;
      this.auditPayLogReq.is_pay = false;
      this.doAudit(model);
    }
  }

  passwordChange(event: any) {
    if (this.currentPassword) {
      this.auditPayLogReq.password = this.currentPassword;
      this.passwordError = false;
    }
  }


  // 跳转租约详情
  toLease(id: number, type: string) {

    this.payLogDetailModal.hide();

    if (type === 'BOOKING') {
      this.router.navigate(['/system_manage/booking-management/detail/', id]);
    }
    if (type === 'LEASE') {
      this.router.navigate(['/system_manage/signed/pact/', id]);
    }

  }

  // 预览图片
  openGallerys(imgArray: Array<string>, index: number) {
    this.workService.event_bus.emit(new WorkModel(this.workService.type.IMGFULL_SCREEN_PREVEIEW, new ImagePreviewModel(imgArray, index)));
  }

  // 显示相关联的收款账单详情
  showRelatedPayLogDetail(id: number) {
    this.closeModel();
    this.payLogDetailChange.emit(id);
  }

  // 显示打印预览弹窗
  printShow(leaseId: number, payLogId: number) {
    if(this.payLogDetail.audit_status === 'NO_PAY'){
      this.workService.showErrorModal("尚未核销不支持打印");
      return ;
    }
    this.contractPath = `${environment.apiHost}`
      + 'file/preview?bizcode=USERPAYBILL&leaseId=' + leaseId + '&payLogId='
      + payLogId + '&' + Math.ceil(Math.random() * 100);

    this.workService.event_bus.emit(new WorkModel(this.workService.type.PDF_PRINT, this.contractPath));
    // this.printPreview.show();
  }

  // 显示当前弹窗
  showModel(payId: number) {
    this.manual_account_time = null;
    this.createNumValue = '';
    this.payLogId = payId;
    this.getPaylogDetail();
    this.getPaylogDetailList();
    this.payLogDetailModal.show();
  }

  // 关闭当前弹窗
  closeModel() {
    this.createNumValue = '';
    this.payLogDetailModal.hide();
  }

  // 日志弹窗显示
  logModalShow() {
    this.logModal.lease_type = 'ROOM_LEASE';
    this.logModal.lease_id = this.payLogDetail.lease_id;
    this.logModal.relet_id = this.payLogDetail.pay_id;
    this.logModal.show();
  }
  cancel(confirmModal: any){
    confirmModal.hide();
    this.auditPayLogReq.is_not_posted= false;
  }
}
