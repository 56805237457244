import {RequestService} from "../request.service";
import {AuditReqParam, RefundDetailReqParam, RefundInfoDTO, RefundListDTO, RefundListReqParam} from "../../model/refund/refund.model";
import {environment} from "../../../environments/environment";
import {Injectable} from "@angular/core";

/**
 * Created by cyl on 2018/6/12.
 */

@Injectable()
export class RefundListService {
  private REFUNDLIST_URL = `${environment.apiHost}refund/list`;

  private REFUNDINFO_URL = `${environment.apiHost}refund/detail`;

  private REFUND_AUDIT_URL = `${environment.apiHost}refund/edit`;
  //账单退款审核短信发送
  private REFUND_BILL_AUDIT_SMSSEND_URL = `${environment.apiHost}audit/refund/code/sms`;
  //预定金退款审核短信发送
  private REFUND_BOOKING_AUDIT_SMSSEND_URL = `${environment.apiHost}audit/refund/booking/code/sms`;
  // 账单退款处理
  private RENFUND_GATHER_HANDLER_URL = `${environment.apiHost}audit/refund/gather/handler`;
  // 预定金退款处理
  private RENFUND_BOOKING_HANDLER_URL = `${environment.apiHost}audit/refund/booking/refund/handler`;
  // 电费补差退款--详情弹窗
  private GET_DIFF_REFUNDINFO = `${environment.apiHost}ele/diff/refundInfo`;
  // 电费补差退款-明细下载
  private DOWNLOAD_DIFF_REFUNDINFO = `${environment.apiHost}ele/diff/refundInfo/download`;
  // 电费补差退款-提交
  private CONFIRM_DIFF_REFUNDINFO = `${environment.apiHost}ele/diff/refund/confirm`;
  // 工业用电-是否展示补差退款按钮
  private REFUND_DIFF_SHOWBTN = `${environment.apiHost}ele/diff/showBtn`;

  constructor(private requestService: RequestService) {
  }

  getRefundList(refundListRequest: RefundListReqParam): Promise<RefundListDTO> {
    return this.requestService.authGet(this.REFUNDLIST_URL, refundListRequest);
  }

  getRefundInfo(refundInfoRequest: RefundDetailReqParam): Promise<RefundInfoDTO> {
    return this.requestService.authGet(this.REFUNDINFO_URL, refundInfoRequest);
  }

  auditHanlde(auditReqParam: AuditReqParam): Promise<boolean> {
    return this.requestService.authPost(this.REFUND_AUDIT_URL, auditReqParam);
  }

  refundBillAuditSmsSend(auditReqParam: any): any {
    return this.requestService.authPost(this.REFUND_BILL_AUDIT_SMSSEND_URL, auditReqParam);
  }

  refundBookingAuditSmsSend(auditReqParam: any): any {
    return this.requestService.authPost(this.REFUND_BOOKING_AUDIT_SMSSEND_URL, auditReqParam);
  }

  renfundGatherHandler(auditReqParam: any): any {
    return this.requestService.authPost(this.RENFUND_GATHER_HANDLER_URL, auditReqParam);
  }

  refundBoookingHandler(auditReqParam: any): any {
    return this.requestService.authPost(this.RENFUND_BOOKING_HANDLER_URL, auditReqParam);
  }

  getDiffRefundInfo(lease_id: any) {
    return this.requestService.authGet(this.GET_DIFF_REFUNDINFO, lease_id);
  }

  downloadDiffRefundInfo(lease_id: any) {
    return this.requestService.authGet(this.DOWNLOAD_DIFF_REFUNDINFO, lease_id);
  }

  confirmDiffRefundInfo(req: any) {
    return this.requestService.authPost(this.CONFIRM_DIFF_REFUNDINFO, req);
  }

  refundDiffShowBtn(req: any) {
    return this.requestService.authGet(this.REFUND_DIFF_SHOWBTN, req);
  }
}
