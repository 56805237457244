import {PageReq, PageResp} from "../page/page";

export class ClientInfoListRequest extends PageReq {
  search_content?: string;
  certificate_type?: string;
  healthy_st_time?: number;
  healthy_ed_time?: number;
}

export class DeclarationHealthyListRequest extends PageReq {
  client_id?: number;
}

export class ClientInfoDetailRequest {
  client_id: string;
}

export class AccountInfoIdRequest {
  client_id: number;
}

export class ClientInfoEditPhoneRequest extends ClientInfoDetailRequest {
  targetPhone: string;
}

export class AccountInfoDetailResponse {
  default_account?: DefaultAccountDTO;
  special_account?: Array<SpecialAccountDTO>;
}

export class QueryRelativeLeaseResponse {
  relative_lease_list?: Array<RelativeLeaseListDTO>;

}

export class AccountCostTypeResponse {
  cost_type_detail_list?: Array<CostTypeDetailListDTO>;
}

export class CostTypeDetailListDTO {
  name: string;
  value: string;
}

export class QueryRelativeRequest {
  client_id: number;
  account_money_info_id: number;
  lease_ids?: Array<number>;

}

export class LeaseIdsDTO {
  lease_id?: number;
}

export class QueryRelativeRoomsResponse {
  room_type_list?: Array<RoomTypeListDTO>;
  relative_lease_list?: Array<RelativeLeaseDTO>;
}

export class RoomTypeListDTO {
  room_type_name?: string;
  room_type_id?: number;
}

export class RelativeLeaseDTO {
  contract_num?: string;
  lease_id?: number;
  start_time?: Date;
  end_time?: Date;
  unit_floor_list?: Array<UnitFloorListDTO>;
  exist_relatived?: boolean = false;
}

export class UnitFloorListDTO {
  unit_id?: number;
  unit_name?: string;
  logic_floor?: number;
  floor?: string;
  room_info_list?: Array<RoomInfoListDTO>;
  exist_relatived?: boolean = false;

}

export class RoomInfoListDTO {
  room_name?: string;
  room_id?: number;
  lease_room_id?: number;
  exist_relatived?: boolean = false;
}

export class SpecialAccountRequest {
  edit: boolean;
  account_money_name?: string;
  client_id?: number;
  account_money_info_id?: number;
  lease_id_list: Array<number>;
  lease_room_id_list: Array<number>;
  bill_type?: string;
  account_status?: string;
  idempotent_key?: string;
}

export class ContractNumListDTO {
  contract_num: number;
}

export class RoomIdListDTO {
  room_id?: string;
}

export class DefaultAccountDTO {
  account_money_info_id?: number;
  account_money_name?: string;
  balance?: string;
  transfer_available_balance?: string;
}

export class SpecialAccountDTO {
  account_money_info_id?: number;
  account_money_name?: string;
  balance?: string;
  relative_room_name?: string;
  prepay_account: boolean;
  all_room_relative: boolean;
  transfer_available_balance?: string;
  relative_lease_list?: Array<RelativeLeaseListDTO>;
  relative_room_list?: Array<RelativeRoomListDTO>;
}

export class RelativeLeaseListDTO {
  contract_num?: string;
  lease_id?: number;
}

export class RelativeRoomListDTO {
  room_name?: string;
  room_id?: number;
  lease_room_id?: number;
}

export class ClientInfoListDTO {
  //客户id
  client_id?: number;

  //客户姓名
  client_name?: string;

  //证件类型
  certificate_type?: string;

  //证件号码
  cert_code?: string;

  //脱敏证件号码
  cret_num_conceal?: string;

  //客户类型
  client_type?: string;

  // 健康申报次数
  healthy_count?: number;

  // @Remark("绑定账号数量")
  account_count?: number;
  // @Remark("合同总数")
  lease_count?: number;
  // @Remark("未到期合同数量")
  not_yet_due_lease_count?: number;
  // @Remark("生效中的房间数")
  effective_room_count?: number;
}

export class ClientInfoListResponse extends PageResp {
  client_info_list_dtos?: Array<ClientInfoListDTO>;
}

export class DeclarationHealthyListResponse extends PageResp {

  client_name?: string;

  declaration_healthy_list?: Array<PromoteDeclarationHealthyDTO>;

}

export class PromoteDeclarationHealthyDTO {

  address?: string;

  room_num?: string;

  enterprise_name?: string;

  inspection?: boolean;

  vaccination?: number;

  route_place?: string;

  route_place_trans?: string;

  create_time?: Date;

}

export class ClientInfoDetailResponse extends PageResp {
  client_info_detail_dto?: ClientInfoDetailDTO;
}


export class ClientInfoDetailDTO {
  client_id?: number;

  client_user_id: number;

  client_name?: string;

  sex?: string;

  // @Remark("证件类型 ID_CD_DIC 居民身份证 PASSPORT_DIC 护照")
  certificate_type?: string;

  // @Remark("证件号码")
  cert_code?: string;

  // @Remark("证件号码脱敏")
  cret_num_conceal?: string;

  // @Remark("手机号")
  phone?: string;

  // @Remark("手机号码脱敏")
  tel_conceal?: string;

  // @Remark("入住状态")
  renter_status?: string;

  // @Remark("企业用户签约状态")
  subject_status?: string;

  // @Remark("最近签约时间")
  last_sign_time?: Date;

  // @Remark("身份证地址")
  cert_address?: string;

  // @Remark("客户性质")
  client_property?: string;

  // @Remark("客户类型")
  client_type?: string;

  // @Remark("行业类别")
  industry_type?: string;

  // @Remark("企业性质")
  company_property_type?: string;

  // @Remark("签约人信息")
  subject_info_list?: Array<SubjectInfoListDTO>;

  // @Remark("表头")
  renter_table_header?: Array<string>;

  // @Remark("入住人信息")
  renter_info_list?: Array<RenterInfoListDTO>;

  // @Remark("项目是否有入住公约")
  has_house_hold_convention?: boolean;

  // @Remark("是否签署入住公约")
  sign_house_hold_convention?: boolean;

  // @Remark("入住公约表 snapshoot_info 的 snapshoot_key")
  house_hold_convention_url?: string;

  // @Remark("图片地址")
  image_url?: string;

  // @Remark("图片请求ID")
  request_id: string;

  renter_id: string;

  lease_id: string;

  // @Remark("唯一签名")
  sign: string;

  // @Remark("附件信息")
  cret_image_list?: Array<string>;

  // @Remark("住客人脸信息")
  user_face_dtolist?: Array<UserFaceDTO>;

  face_imgs: Array<string> = new Array<string>();
  //
  // @Remark("巡房整改记录")
  room_inspect_record_list?: Array<RoomInspectRecordDTO>;

  // @Remark("绑定的企业账号")
  company_account_list?: Array<CompanyAccountDTO>;
}

export class CompanyAccountDTO {
  // @Remark("账号：手机号")
  account?: string;
  // @Remark("绑定时间")
  create_time?: number;
  // @Remark("最后一次登录时间")
  last_login_time?: number;
}

export class SubjectInfoListDTO {
  // @Remark("合同号")
  contract_num?: string;

  // @Remark("租约开始时间")
  start_time?: Date;

  // @Remark("租约结束时间")
  end_time?: Date;

  // @Remark("签约房间数")
  sign_room_num?: number;

  // @Remark("已退房间数")
  check_out_room_num?: number;

  // @Remark("预定或签约时间")
  create_time?: Date;

  // @Remark("联系人")
  contact?: string;

  contact_phone_conceal: string;

  // @Remark("联系人电话")
  contact_phone?: string;

  // @Remark("合同状态")
  contract_status?: string;

  effective_room_count?: number;
  avg_price?: string;
}

export class RenterInfoListDTO {

  lease_id?: number;
  // @Remark("入住人id")
  renter_id?: number;

  // @Remark("社区名称")
  project_name?: string;

  // @Remark("房间名称")
  room_name?: string;

  // @Remark("签约主体")
  subject_name?: string;

  // @Remark("入住时间")
  checkin_time?: Date;

  // @Remark("退租时间")
  checkout_time?: Date;

  // @Remark("人员属性")
  person_type?: string;

  subsidiary?: string;

  // @Remark("工号")
  emp_no?: string;

  extValueList: Array<string> = new Array<string>();

  // @Remark("入住人扩展信息")
  renter_ext_info?: Map<string, string>;

  free_start_time?;
  free_end_time?;
}

export class UserFaceDTO {
  // @Remark("图片")
  img?: string;
  // @Remark("位置")
  mark?: string;
}

export class RoomInspectRecordDTO {
  project_name?: string;

  check_start_time?: Date;

  check_end_time?: Date;

  sign_time?: Date;

  danger_num?: number;
}

export class RenterExtInfoValueDTO {

  ext_name?: string;

  ext_value?: string;

  ext_type?: string;

  required?: boolean;

  renter_value?: string = "";

  select_value: Array<string>;

}

export class ClientInfoRenterExtInfoDTO {

  renter_info_id: number;

  person_type: string;

  emp_no: string;

  subsidiary: string;

  renter_ext_info_values: Array<RenterExtInfoValueDTO>;

}

export class ClientInfoRenterExtRequest {
  renter_info_id: number;
}

export class ClientInfoFaceListResponse {
  // @Remark("住客人脸信息")
  user_face_dtolist?: Array<UserFaceDTO>;
}

export class ClientInfoFaceRequest {
  user_id: number;
}

export class QueryContractRoomTypeRequest {
  contract_num?: string;
  room_type_id?: number;
  lease_ids?: Array<number>;
}

export class QueryContractRoomTypeResponse {
  relative_lease_dto?: Array<RelativeLeaseDTO>;
}

export class AccountRelativeRoomRequest {
  client_id?: number;
  account_money_info_id?: number;
}

export class AccountRelativeRoomResponse {
  relative_room_total?: string;
  relative_lease_list?: Array<RelativeLeaseDTO>;

}

export class UploadPaymentCertificateRequest {
  file_url?: string;
  request_id?: string;
}

export class UploadPaymentCertificateResponse {
  payment_name?: string;
  payment_amount?: string;
  payment_account?: string;
  receipt_num?: string;

}

export class MoneyPaymentConfirmRequest {
  account_money_info_id?: number;
  payment_amount?: string;
  idempotent_key?: string;
  receipt_num?: string;
  payment_name?: string;
  img_url?: string;
}

export class QueryAvailableBalanceRequest {
  account_money_info_id?: number;

}

export class QueryAvailableBalanceResponse {
  available_balance?: number;

}

export class MoneyRefundConfirmRequest {
  account_money_info_id?: number;
  refund_amount?: number;
  refund_name?: string;
  open_bank?: string;
  bank_no?: string;
  idempotent_key?: string;
}

export class ChangeLogListRequest extends PageReq {
  account_money_info_id?: number;
  flow_type?: string;
  start_time?: number;
  end_time?: number;
  search_content?: string;
}

export class ChangeLogListResponse {
  prepay_account?: boolean;
  change_log_list?: Array<ChangeLogListDTO>;
  total?: number;

}

export class ChangeLogListDTO {
  flow_type?: string;
  income_time?: Date;
  contract_num?: string;
  room_name?: string;
  bill_type?: string;
  bill_start_time?: Date;
  bill_end_time?: Date;
  balance?: string;
  pay_type?: string;
}

export class TransferAccountBalanceResponse {
  available_transfer_amount?: number;
  account_money_info_list?: Array<AccountMoneyInfoListDTO>;

}

export class AccountMoneyInfoListDTO {
  account_money_name?: string;
  account_money_info_id?: number;
}

export class TransferSmsCodeRequest {
  origin_account_money_info_id?: number;
  target_account_money_info_id?: number;
  client_id?: number;
  transfer_amount?: number;
  phone?: string;

}

export class MoneyTransferConfirmRequest {
  origin_account_money_info_id?: number;
  target_account_money_info_id?: number;
  client_id?: number;
  transfer_amount?: number;
  audit_phone?: string;
  audit_code?: string;
  idempotent_key: string;
}

export class QueryAccountLeaseRequest {
  lease_id?: number;
  renter_id?: number;
}

export class QueryAccountLeaseResponse {
  account_money_list?: Array<AccountMoneyListDTO>;
}

export class AccountMoneyListDTO {
  account_money_info_id?: number;
  account_money_name?: string;
  balance?: string;
  special_type?: string;
  relative_lease_ids?: Array<RelativeLeaseIdsDTO>;
  relative_lease_room_ids?: Array<RelativeLeaseRoomIdsDTO>;
}

export class RelativeLeaseIdsDTO {
  lease_id?: number;
}

export class RelativeLeaseRoomIdsDTO {
  lease_room_id?: number;
}

export class ClientInfoBriefModel {
  client_id?: number;
  client_name?: string;
}

export class QueryClientInfoListReq {
  client_name?: string;
}

export class QueryClientInfoListResp {
  exist?: boolean;
  client_info_list?: Array<ClientInfoBriefModel>;
}

export class ClientBaseDataDTO {
  code?: string;
  desc?: string;
}

export class ClientUserBaseDataResponse {
  levels?: Array<ClientBaseDataDTO>;

  qualitys?: Array<ClientBaseDataDTO>;

  renter_status?: Array<ClientBaseDataDTO>;
}

export class ClientUserFollowUpSubmitRequest {
  // @NotNull(message = "clientUserId不能为空")
  client_user_id?: number;
  // @Remark("跟进时间时间戳,不传为系统默认时间")
  follow_up_time?: number;

  // @NotNull(message = "userQuality不能为空")
  // @Remark("PURPOSE:有意向，NO_PURPOSE:无意向,CHECKIN:已入住")
  user_quality?: string;
  // @Remark("意向价格")
  quality_room_price?: string;
  // @Remark("意向房间")
  quality_room?: string;
  // @Remark("用户级别A,B,C")
  // @NotNull(message = "userLevel不能为空")
  user_level?: string;
  // @Remark("备注")
  remark?: string;
}

export class WorkRemindDisposeUserFollowUpRequest {
  // @NotNull(message = "workRemindId不能为空")
  work_remind_id?: number;
  // @NotNull(message = "clientUserId不能为空")
  client_user_id?: number;
  // @Remark("跟进时间时间戳,不传为系统默认时间")
  follow_up_time?: number;

  // @NotNull(message = "userQuality不能为空")
  // @Remark("PURPOSE:有意向，NO_PURPOSE:无意向,CHECKIN:已入住")
  user_quality?: string;
  // @Remark("意向价格（元）")
  quality_room_price?: string;
  // @Remark("意向房间")
  quality_room?: string;
  // @Remark("用户级别A,B,C")
  // @NotNull(message = "userLevel不能为空")
  user_level?: string;
  // @Remark("备注")
  remark?: string;
}
