import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BusinessError } from '../model/error/business.error';
import { LogService } from '../service/log/log.service';

/**
 * @description:全局异常处理
 *
 * @Author: yinqiang
 * @Date: 2018-02-24 17:56:06
 * @Last Modified by: yinqiang
 * @Last Modified time: 2018-02-24 18:44:35
 */
@Injectable()
export class CustomErrorHandler implements ErrorHandler {

  production = `${environment.production}`;

  constructor(private logService: LogService) {
  }

  handleError(error: any): void {

    if (error instanceof Error) {

      if (error instanceof BusinessError) {
        this.logService.error('get business error;message:' + error.message + '; name:' + error.name + ';errorCode:' + error.errorCode);
      } else {
        // error.rejection.errorCode 获取错误码
        this.logService.error('get Error:' + error.message);
      }

      if (!Boolean(this.production)) {
        this.logService.error(error);
      }
    } else {
      this.logService.error(error);
    }

    this.logService.error(error);
  }
}
