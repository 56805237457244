import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {RoomChangeService} from '../../../../service/lease/roomchange.service';
import {
  ROOM_CHANGE_STEP, RoomChangeCancelReq, RoomChangeGotoStepReq, RoomChangeListReq, RoomChangeRecordListItem
}
  from '../../../../model/lease/roomchange.model';
import {BaseInfoService} from '../../../../service/common/base-info.service';
import {BaseEnum} from '../../../../model/modal/base-model';
import {WorkService} from '../../../../service/common/work.service';
import {MessageModalInfo} from '../../../../model/modal/message-model';
import {WorkModel} from '../../../../model/common/common.model';
import {Router} from '@angular/router';
import {DialogComponent} from "../../../shared/dialog/dialog.component";
import {AuthService} from "../../../../service/common/auth.service";

@Component({
  selector: 'app-cr-change-room-record',
  templateUrl: './cr-change-room-record.component.html',
  styleUrls: ['./cr-change-room-record.component.css']
})
export class CrChangeRoomRecordComponent implements OnInit, OnChanges {

  @Input()
  lease_id: number = 0;

  @Output()
  logsNum: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  isChangeing: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Output()
  reload_record: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(DialogComponent, { static: true }) dialogComponent: DialogComponent;

  @Input()
  lease_step: any = 'normal';
  // 获取换房记录列表请求
  room_change_list_req: RoomChangeListReq = {
    lease_id: this.lease_id,
    page_num: 1,
    page_size: 30
  };

  // 记录总数
  total: number;

  // 换房记录列表
  room_change_record_list: Array<RoomChangeRecordListItem> = new Array<RoomChangeRecordListItem>();

  // 换房记录状态列表
  room_change_status_list: BaseEnum[] = [];

  index = 0;

  constructor(private roomChangeService: RoomChangeService,
              private router: Router,
              private baseInfoService: BaseInfoService,
              private workService: WorkService,
              private authService: AuthService,) {
  }

  ngOnInit() {
    this.room_change_status_list = this.baseInfoService.getRoomChangeStatusList();
    this.index++;
    this.getRoomChangeList(this.index);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.index++;
    this.getRoomChangeList(this.index);
  }

  // 获取换房列表
  getRoomChangeList(index: number) {
    if (!this.lease_id) {
      this.room_change_record_list = [];
      this.total = 0;
      return;
    }

    this.room_change_list_req.lease_id = this.lease_id;
    this.roomChangeService.getRoomChangeList(this.room_change_list_req).then(data => {
      if (data.room_change_record_list && data.room_change_record_list.length > 0) {
        //判断是否是正在换房
        let changeing = false;
        for (let i = 0; i < data.room_change_record_list.length; i++) {
          if (data.room_change_record_list[i].status === 'UNCOMPLETED' || data.room_change_record_list[i].status === 'WAITING_AUDIT') {
            changeing = true;
            break;
          }
        }
        if (this.index === index) {
          if (this.room_change_record_list && this.room_change_record_list.length > 0) {
            this.room_change_record_list = this.room_change_record_list.concat(data.room_change_record_list);
          } else {
            this.room_change_record_list = data.room_change_record_list;
          }
          // 判断是否全部拉完
          if (this.room_change_record_list && data.total > this.room_change_record_list.length) {
            this.room_change_list_req.page_num++;
            this.getRoomChangeList(index);
          }
        }else {
          this.room_change_record_list = data.room_change_record_list;
        }
        this.total = data.total;
        this.logsNum.emit(this.total);
        this.isChangeing.emit(changeing);
      } else {
        this.room_change_record_list = [];
        this.total = 0;
        this.logsNum.emit(this.total);
        this.isChangeing.emit(false);
      }
    });
  }

  // 取消换房
  cancelRoomChange(record_id: number) {
    let msg_modal = new MessageModalInfo();
    msg_modal.operationMessage = '确认取消该换房申请吗?';
    msg_modal.cancel = true;
    msg_modal.callback.subscribe(() => {
      this.roomChangeService.roomChangeCancel(new RoomChangeCancelReq('CANCEL', record_id)).then(data => {
        this.workService.showSuccessModal('换房取消成功');
        this.reload_record.emit('reload');
      });
    });
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, msg_modal));
  }

  // 继续
  goOn(record: RoomChangeRecordListItem) {
    switch (record.operate_step) {
      case ROOM_CHANGE_STEP.CONFIRM:
        let needGoodList = this.authService.getProjectInfo().need_room_goods_list;
        //如果项目需要签署物品交接单
        if (needGoodList === 1) {
          this.router.navigateByUrl('/system_manage/signed/change/room/confirm/' + record.record_id);
        } else {
          //如果项目不需要签署物品交接单，直接模拟换房确认的下一步动作，跳过这一步
          this.router.navigateByUrl('/system_manage/signed/change/room/bill/details/' + record.record_id);
        }
        break;
      case ROOM_CHANGE_STEP.RECORD:
        this.router.navigateByUrl('/system_manage/signed/change/room/bill/details/' + record.record_id);
        break;
      case ROOM_CHANGE_STEP.CONTRACT:
        this.router.navigateByUrl('/system_manage/signed/change/room/bill/details/' + record.record_id);
        //this.router.navigateByUrl('/system_manage/signed/change/room/contract/' + record.record_id);
        break;
    }
  }

  // 显示错误原因
  showFailReason(audit_fail_reason: string) {
    this.workService.showErrorModal('审核失败原因:' + audit_fail_reason + '。 请重新进行换房操作！');
  }

  /**
   * @Description:  打开房间租金信息
   * @Author: zhoujiahao
   * @Date: 2018-07-16  19:40
   */
  openRoomDetail(leaseRoomId?: any,recordId ?: any) {
    this.dialogComponent.leaseRoomId = leaseRoomId;
    this.dialogComponent.recordId = recordId;
    this.dialogComponent.openRoomDetetail();
  }
}
