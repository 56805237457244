import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {RequestService} from "../request.service";
import {AuditRefundDetailReqParam, AuditRefundListReqParam, AuditRefundPayMoneyReqParam} from "../../model/audit/refund.model";

/**
 * @Description: 待退款/已退款服务
 * @Author: 陆炳彬
 * @Date: 2018/6/5 下午5:23
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/6/5 下午5:23
 */
@Injectable()
export class RefundService {

  //待退款/已退款列表
  private REFUND_LIST = `${environment.apiHost}audit/refund/pay/list`;
  //待退款/已退款详情
  private REFUND_DETAIL = `${environment.apiHost}audit/refund/detail`;
  //支付未支付退款
  private REFUND_PAY_MONEY = `${environment.apiHost}audit/refund/pay/money`;
  // 列表
  private REFUND_SEARCH_LIST = `${environment.apiHost}audit/refund/search/list`;
  // 详情
  private REFUND_SEARCH_DETAIL = `${environment.apiHost}audit/refund/search/detail`;
  // 审批
  private REFUND_SEARCH_EDIT = `${environment.apiHost}audit/refund/search/edit`;
  private REFUND_SEARCH_EDIT_BATCH = `${environment.apiHost}audit/refund/search/edit/batch`;



  constructor(private requestService: RequestService) {
  }

  /**
   * @Description: 获取待退款/已退款列表
   * @Author: 陆炳彬
   * @Date: 2018/6/5 下午5:25
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/6/5 下午5:25
   */
  getRefundList(params: AuditRefundListReqParam): Promise<any> {
    return this.requestService.authGet(this.REFUND_LIST, params);
  }

  /**
   * @Description: 获取待退款/已退款详情
   * @Author: 陆炳彬
   * @Date: 2018/6/5 下午5:57
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/6/5 下午5:57
   */
  getRefundDetail(params: AuditRefundDetailReqParam): Promise<any> {
    return this.requestService.authGet(this.REFUND_DETAIL, params);
  }

  /**
   * @Description: 支付未支付退款
   * @Author: 陆炳彬
   * @Date: 2018/6/5 下午6:00
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/6/5 下午6:00
   */
  payMoney(params: AuditRefundPayMoneyReqParam): Promise<any> {
    return this.requestService.authGet(this.REFUND_PAY_MONEY, params);
  }

  /**
   * 列表
   * @param params
   */
  getRefundHandleList(params: any): Promise<any> {
    return this.requestService.authGet(this.REFUND_SEARCH_LIST, params);
  }
  getRefundHandleListWithLoading(params: any): Promise<any> {
    return this.requestService.authGet(this.REFUND_SEARCH_LIST, params, true);
  }

  /**
   * 详情
   * @param params
   */
  getRefundHandleDetail(params: any): Promise<any> {
    return this.requestService.authGet(this.REFUND_SEARCH_DETAIL, params);
  }

  /**
   * 退款审批
   * @param params
   */
  refundHandleEdit(params: any): Promise<any> {
    return this.requestService.authPost(this.REFUND_SEARCH_EDIT, params);
  }

  refundHandleEditBatch(params: any): Promise<any> {
    return this.requestService.authPost(this.REFUND_SEARCH_EDIT_BATCH, params);
  }
}
