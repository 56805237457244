import {animate, keyframes, state, style, transition, trigger} from "@angular/animations";


export const loadingAnimate = trigger('loading', [

  // 定义一个状态a
  state('a', style({top: '-5em'})),
  // 定义另外一个状态b
  state('b', style({top: '1px'})),


  // 定义运动过程(从a到b状态)
  transition('a => b', animate(50)),
  // 定义运动过程(从b到a)
  transition('b => a', animate(200)),
]);


export const openCloseAnimate = trigger('openClose', [
  state('open', style({
    display: 'none'
  })),
  state('closed', style({
    display: 'block'
  })),
  transition('open => closed', [
    animate('0s')
  ]),
  transition('closed => open', [
    animate('0s')
  ]),
])
