import {Injectable} from '@angular/core';
import {MatchLesseeParams} from "../../model/renter/renter.model";
import {HttpClient} from "@angular/common/http";

/**
 * @Description: 身份证读取服务
 * @Author: 陆炳彬
 * @Date: 2018/3/29 上午10:23
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/3/29 上午10:23
 */
@Injectable()
export class IdReaderService {


  // get
  URL_V1 = 'http://127.0.0.1:24010/ZKIDROnline/ScanReadIdCardInfo?OP-DEV=1&CMD-URL=4';

  // post
  URL_V2 = 'http://127.0.0.1:18889/api/readCard';


  constructor(
    private http: HttpClient
  ) {
  }

  //{
  //     "ret":0,
  //     "Certificate":
  //     {
  //         "Name": "", //姓名
  //         "Sex": "",  //性别
  //         "Nation": "",   //民族
  //         "Birthday": "", //出生日期
  //         "Address": "",  //常住地址
  //         "IDNumber": "", //身份证号
  //         "IDIssued": "", //签发机关
  //         "IssuedData": "",   //签发日期
  //         "ValidDate": "",    //有效期截止日期
  //         "Other": "(null)",        //
  //         "CardNumber": "(null)",   //
  //         "PhotoName": "(null)",
  //         "Base64Photo": "base64照片",  //Base64照片
  //         "ImageName": "(null)",
  //         "Base64Image": "(null)",
  //         "fp_feature1":"Base64 String", //第一个登记手指指纹特征
  //         "fp_feature2":"Base64 String"  //第二个登记手指指纹特征
  // }
  readcardinfo(matchLesseeReqParam: MatchLesseeParams) {

    this.http.post(this.URL_V2, null)
      .toPromise()
      .then((data: any) => {

        if (data && data.resultContent) {
          matchLesseeReqParam.cret_num = data.resultContent.certNumber;
          matchLesseeReqParam.name = data.resultContent.partyName;
          matchLesseeReqParam.sex = data.resultContent.gender;
        } else {
          this.readIDV1(matchLesseeReqParam)
        }

      }).catch(() => {

      this.readIDV1(matchLesseeReqParam)
    });

  }

  readIDV1(matchLesseeReqParam: MatchLesseeParams) {

    this.http.get(this.URL_V1, {
      responseType: 'text'
    })
      .toPromise()
      .then((data: any) => {

        let result: any = null;
        data = data.replace(/\\/g, "/");
        try {
          result = JSON.parse(data);
        } catch (e) {
          result = JSON.parse("(" + data + ")");
        }

        if (result && result.Certificate) {
          matchLesseeReqParam.cret_num = result.Certificate.IDNumber;
          matchLesseeReqParam.name = result.Certificate.Name;
          matchLesseeReqParam.sex = result.Certificate.Sex;
        }

      }).catch((data) => {
    });


  }

  readIDV2(matchLesseeReqParam: MatchLesseeParams) {

    let result = null;

    $.post(this.URL_V2, data => {
      if (result.resultContent) {
        matchLesseeReqParam.cret_num = result.resultContent.certNumber;
        matchLesseeReqParam.name = result.resultContent.partyName;
        matchLesseeReqParam.sex = result.resultContent.gender;
      }
    })

  }
}
