// 正则表达式
export const RegularExp = {
  phone: {
    fixedLine: '^\\d{3}-\\d{8}|\\d{4}-\\d{7,8}$',
    mobile: '^((\\+86)|(86))?[1][3456789][0-9]{9}$',
    all: '^(((\\+86)|(86))?[1][3456789][0-9]{9})$|^(\\d{3}-\\d{8}|\\d{4}-\\d{7,8})$'
  },
  email: '^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-]+)+$',
  bankAccount: '^\\d{9,}$',
  bankBlankAccount: '([\\d]{4})([\\d]{4})([\\d]{4})([\\d]{4})([\\d]{0,})?',
  floorInTotal: '^\\d{1,}\/\\d{1,}$',
  validNumber: '^[+]?(\\d|[1-9]\\d+)(\\.\\d+)?$',
  // 一位小数
  oneBitsDecimal: '^(([1-9][0-9]*)|(([0]\\.\\d{1,1}|[1-9][0-9]*\\.\\d{1,1})))$',
  description: '[\\S\\s]+',
  positiveInt: '^[1-9]\\d*$',
  charactersOfZH: '[\u4e00-\u9fa5]',
  // 正整数
  signlessInteger: '^[+]{0,1}(\\d+)$',
  //在只能含有数字,中文，字母 任意几种
  zhNumberEng: '^[a-zA-Z0-9\u4e00-\u9fa5]+$',
  //只能为数字，并且第一个不能是0
  number: '[1-9]{1}[0-9]{0,}$',
  //校验是否是一个正确的金额： 只能包含两位小数点，03.33 不符合要求，整数前面不能含有0
  validAmount: '([1-9]\\d*|0)(\\.\\d{1,2})?',
  numberPointTwo: /^\d+(\.\d{0,2})?$/,
  validAmount2 : /^([1-9]\d*|0)(\.\d{1,2})?$/,
};
