import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {RequestService} from '../request.service';
import {
  CheckinRoomInventoryRequest,
  CheckoutInventoryConfirmRequest,
  CheckoutInventoryConfirmRequestDTO,
  CheckoutInventoryViewResponse,
  CheckoutRoomInventoryRequest
} from "../../model/signed/inventory.model";
import {
  CheckoutAuditListResponse,
  CheckoutNoOtherFeeRequest,
  CheckoutRoomAffirmRequest,
  CheckoutRoomViewRequest,
  CheckoutRoomViewNewResponse,
  CheckoutRoomViewResponse,
  CheckoutViewRequest,
  CheckoutViewResponse,
  SMSAuditCodeRequest,
  CheckoutFinanceRoomListResponse,
  CheckoutFinanceRoomListViewRequest,
  CheckoutFinanceRoomListViewResponse,
  CheckoutConfirmRequest,
  CheckoutRenterListViewRequest,
  CheckoutRenterListViewResponse,
  CheckoutRenterConfirmRequest,
  RenterCheckoutRecordListRequest,
  RenterCheckoutRecordListResponse, RenterCheckoutRecordDetailRequest, RenterCheckoutRecordDetailResponse
} from '../../model/signed/checkout.model';
import {SignOnIpadRequest} from "../../model/signed/sign.model";

@Injectable()
export class SignedService {
  private LEASE_BOOKING_INVENTORY_VIEW = `${environment.apiHost}lease/booking/inventory/view`;
  private LEASE_BOOKING_INVENTORY_AFFIRM = `${environment.apiHost}lease/booking/inventory/affirm`;
  private LEASE_BOOKING_INVENTORY_CHECKOUT = `${environment.apiHost}lease/booking/inventory/checkout`;
  private LEASE_BOOKING_CHECKOUT_ROOM_CHOOSE_VIEW = `${environment.apiHost}lease/booking/checkout/roomchoose/view`;

  private LEASE_BOOKING_CHECKOUT_ROOM_CHOOSE_CONFIRM = `${environment.apiHost}lease/booking/checkout/roomchoose/confirm`;

  private LEASE_BOOKING_CHECKOUT_ROOM_CHOOSE_CONFIRM_NEW = `${environment.apiHost}lease/booking/checkout/roomchoose/confirm/new`;

  private LEASE_BOOKING_INVENTORY_CHECKOUT_VIEW_NEW = `${environment.apiHost}lease/booking/inventory/checkout/view/new`;

  private LEASE_BOOKING_CHECKOUT_ROOM_CHOOSE_VIEW_NEW = `${environment.apiHost}lease/booking/checkout/roomchoose/view/new`;

  private LEASE_BOOKING_INVENTORY_CHECKIN = `${environment.apiHost}lease/booking/inventory/checkin`;
  private LEASE_BOOKING_CHECKOUT_LEASE_ROOM = `${environment.apiHost}lease/booking/checkout/lease/room`;

  private LEASE_BOOKING_CHECKOUT_ROOM_VIEW = `${environment.apiHost}lease/booking/checkout/lease/view`;
  private LEASE_BOOKING_CHECKOUT_ROOM_CONFIRM = `${environment.apiHost}lease/booking/checkout/lease/confirm`;

  // 获取房间退房物品交接单
  private LEASE_BOOKING_CHECKOUT_INVENTORY_VIEW = `${environment.apiHost}lease/booking/inventory/checkout/view`;
  // 提交房间退房物品交接单
  private LEASE_BOOKING_CHECKOUT_INVENTORY_CONFIRM = `${environment.apiHost}lease/booking/inventory/checkout/confirm`;
  // 提交房间退房物品交接单
  private LEASE_BOOKING_CHECKOUT_INVENTORY_CONFIRM_NEW = `${environment.apiHost}lease/booking/inventory/checkout/confirm/new`;
  // 提交房间退房物品交接单(走缓存)
  private LEASE_BOOKING_CHECKOUT_INVENTORY_REDIS_CONFIRM = `${environment.apiHost}lease/booking/redis/inventory/checkout/confirm`;

  // 退房单查看
  private LEASE_BOOKING_CHECKOUT_RECORD = `${environment.apiHost}lease/booking/checkout/record`;
  // 退房单查看
  private LEASE_BOOKING_CHECKOUT_RECORD_PREVIEW = `${environment.apiHost}lease/booking/checkout/record/preview`;
  // 免逾期金审核人列表
  private LEASE_CHECKOUT_AUDIT_LIST = `${environment.apiHost}lease/booking/checkout/lease/audit/list`;
  // 免违约金审核人列表
  private LEASE_CHECKOUT_BREAK_AUDIT_LIST = `${environment.apiHost}lease/booking/checkout/lease/break/audit/list`;

  // 免逾期金审核发送短信
  // 各种审核短信 | 2020年1月6日11:06:03
  private LEASE_CHECKOUT_AUDIT_SMS = `${environment.apiHost}sms/manage/audit/code`;

  // 免逾期金审核短信校验
  private LEASE_CHECKOUT_AUDIT_SMS_CONFIRM = `${environment.apiHost}lease/booking/checkout/lease/nomorefee/confirm`;

  // 账单退款申请-审批人列表
  private BILL_REFUND_AUDIT_USER_LIST_URL = `${environment.apiHost}audit/refund/bill/audit/user/list`;

  // 在ipad-上签名
  private LEASE_SIGN_ON_IPAD = `${environment.apiHost}lease/booking/sign/on/ipad`;


  // 延期退房-视图
  private REFUND_DELAY_VIEW = `${environment.apiHost}refund/delay/view`;

  // 延期退房-确认
  private REFUND_DELAY_CONFIRM = `${environment.apiHost}refund/delay/confirm`;

  // 查看提前出账账单
  private GET_ADVANCE_BILL = `${environment.apiHost}finance/lease/advanceBillList`;
  // 选择账单提前出账
  private CONFIRM_ADVANCE_BILL = `${environment.apiHost}finance/lease/chargeOutBills`;

  // 退房财务未结算房间列表
  private GET_LEASE_CHECKOUT_FINANCE_ROOM_LIST = `${environment.apiHost}lease/booking/checkout/finance/roomchoose/list`;
  // 退房财务未结算房间结算视图
  private POST_LEASE_CHECKOUT_FINANCE_ROOM_VIEW = `${environment.apiHost}lease/booking/checkout/finance/room/view`;
  // 退房财务未结算不收违约逾期金
  private GET_LEASE_CHECKOUT_FINANCE_NOMOREFEE_AUDIT_LIST = `${environment.apiHost}lease/booking/checkout/nofee/audit/list`;
  // 退房财务未结算不收违约逾期金校验
  private POST_LEASE_CHECKOUT_FINANCE_NOMOREFEE_AUDIT_CHECK = `${environment.apiHost}lease/booking/checkout/nofee/confirm`;
  // 退房财务未结算房间结算确认
  private POST_LEASE_CHECKOUT_FINANCE_ROOM_CONFIRM = `${environment.apiHost}lease/booking/checkout/finance/room/confirm`;
  // 退房财务未结算房间结算取消
  private POST_LEASE_CHECKOUT_FINANCE_CANCEL = `${environment.apiHost}lease/booking/checkout/record/cancel`;


  // 住客退宿结算视图
  private POST_RENTER_CHECKOUT_VIEW = `${environment.apiHost}lease/booking/renter/checkout/view`;
  // 住客退宿结算确认
  private POST_RENTER_CHECKOUT_CONFIRM = `${environment.apiHost}lease/booking/renter/checkout/confirm`;

  // 住客退宿
  private RENTER_CHECKOUT_RECORD_LIST = `${environment.apiHost}manage/renter/checkout/record/list`;
  // 住客退宿详情
  private RENTER_CHECKOUT_RECORD_DETAIL = `${environment.apiHost}manage/renter/checkout/record/detail`;

  constructor(private requestService: RequestService) {
  }

  public checkoutRecold(requestData: any): Promise<any> {
    return this.requestService.authGet(this.LEASE_BOOKING_CHECKOUT_RECORD, requestData);
  }

  public checkoutRecordPreview(checkoutId: any): Promise<any> {
    return this.requestService.authGet(this.LEASE_BOOKING_CHECKOUT_RECORD_PREVIEW, {checkout_id: checkoutId});
  }

  public inventoryView(requestData: any): Promise<any> {
    return this.requestService.authPost(this.LEASE_BOOKING_INVENTORY_VIEW, requestData);
  }

  public inventoryAffirm(requestData: any): Promise<any> {
    return this.requestService.authPost(this.LEASE_BOOKING_INVENTORY_AFFIRM, requestData);
  }

  public inventoryCheckout(requestData: any): Promise<any> {
    return this.requestService.authPost(this.LEASE_BOOKING_INVENTORY_CHECKOUT, requestData);
  }

  public checkoutRoomChooseView(requestData: CheckoutRoomViewRequest): Promise<CheckoutRoomViewResponse> {
    return this.requestService.authGet(this.LEASE_BOOKING_CHECKOUT_ROOM_CHOOSE_VIEW, requestData);
  }

  public checkoutRoomChooseConfirm(requestData: CheckoutRoomViewRequest): Promise<CheckoutRoomViewResponse> {
    return this.requestService.authPost(this.LEASE_BOOKING_CHECKOUT_ROOM_CHOOSE_CONFIRM, requestData);
  }

  public checkoutRoomChooseConfirmNew(requestData: CheckoutRoomViewRequest): Promise<CheckoutRoomViewResponse> {
    return this.requestService.authPost(this.LEASE_BOOKING_CHECKOUT_ROOM_CHOOSE_CONFIRM_NEW, requestData);
  }

  public inventoryCheckoutViewNew(requestData: CheckoutRoomViewRequest): Promise<CheckoutRoomViewNewResponse> {
    return this.requestService.authGet(this.LEASE_BOOKING_INVENTORY_CHECKOUT_VIEW_NEW, requestData);
  }

  public checkoutRoomChooseViewNew(requestData: CheckoutRoomViewRequest): Promise<CheckoutRoomViewResponse> {
    return this.requestService.authGet(this.LEASE_BOOKING_CHECKOUT_ROOM_CHOOSE_VIEW_NEW, requestData);
  }

  public inventoryCheckin(req: CheckinRoomInventoryRequest): Promise<any> {
    return this.requestService.authPost(this.LEASE_BOOKING_INVENTORY_CHECKIN, req);
  }

  public checkoutLeaseRoom(req: CheckinRoomInventoryRequest): Promise<any> {
    return this.requestService.authPost(this.LEASE_BOOKING_CHECKOUT_LEASE_ROOM, req);
  }

  public checkoutInventoryListView(req: CheckoutRoomInventoryRequest): Promise<CheckoutInventoryViewResponse> {
    return this.requestService.authPost(this.LEASE_BOOKING_CHECKOUT_INVENTORY_VIEW, req);
  }

  public checkoutInventoryListConfirm(req: CheckoutInventoryConfirmRequest): Promise<any> {
    return this.requestService.authPost(this.LEASE_BOOKING_CHECKOUT_INVENTORY_CONFIRM, req);
  }

  public checkoutInventoryListConfirmNew(req: CheckoutInventoryConfirmRequestDTO): Promise<any> {
    return this.requestService.authPost(this.LEASE_BOOKING_CHECKOUT_INVENTORY_CONFIRM_NEW, req);
  }

  public checkoutInventoryListRedisConfirm(req: CheckoutInventoryConfirmRequest): Promise<any> {
    return this.requestService.authPost(this.LEASE_BOOKING_CHECKOUT_INVENTORY_REDIS_CONFIRM, req);
  }

  public checkoutLeaseView(req: CheckoutViewRequest): Promise<CheckoutViewResponse> {
    return this.requestService.authPost(this.LEASE_BOOKING_CHECKOUT_ROOM_VIEW, req);
  }

  public checkoutLeaseConfirm(req: CheckoutRoomAffirmRequest): Promise<any> {
    return this.requestService.authPost(this.LEASE_BOOKING_CHECKOUT_ROOM_CONFIRM, req);
  }

  public leaseAuditCheckoutList(): Promise<CheckoutAuditListResponse> {
    return this.requestService.authPost(this.LEASE_CHECKOUT_AUDIT_LIST, null);
  }

  public leaseAuditBreakCheckoutList(): Promise<CheckoutAuditListResponse> {
    return this.requestService.authPost(this.LEASE_CHECKOUT_BREAK_AUDIT_LIST, null);
  }

  public billRefundAuditUserList(): any {
    return this.requestService.authGet(this.BILL_REFUND_AUDIT_USER_LIST_URL, null);
  }

  public leaseAuditCheckoutAuditSMS(req: SMSAuditCodeRequest): Promise<any> {
    return this.requestService.authPost(this.LEASE_CHECKOUT_AUDIT_SMS, req);
  }

  public leaseAuditCheckoutAuditSMSConfirm(req: CheckoutNoOtherFeeRequest): Promise<any> {
    return this.requestService.authPost(this.LEASE_CHECKOUT_AUDIT_SMS_CONFIRM, req);
  }

  public signOnIpad(request: SignOnIpadRequest): Promise<any> {
    return this.requestService.authPost(this.LEASE_SIGN_ON_IPAD, request);
  }

  public getRefundDelayView(request: any): Promise<any> {
    return this.requestService.authGet(this.REFUND_DELAY_VIEW, request);
  }

  public refundDelayConfirm(request: CheckoutRoomAffirmRequest): Promise<any> {
    return this.requestService.authPost(this.REFUND_DELAY_CONFIRM, request);
  }

  public getAdvanceBill(request: any): Promise<any> {
    return this.requestService.authGet(this.GET_ADVANCE_BILL, request);
  }

  public confirmAdvanceBill(request: any): Promise<any> {
    return this.requestService.authPost(this.CONFIRM_ADVANCE_BILL, request);
  }

  public checkoutFinanceRoomList(leaseId: any, time?: number): Promise<CheckoutFinanceRoomListResponse> {
    return this.requestService.authGet(this.GET_LEASE_CHECKOUT_FINANCE_ROOM_LIST, {lease_id: leaseId, checkout_time: time});
  }

  public checkoutFinanceRoomView(req: CheckoutFinanceRoomListViewRequest): Promise<CheckoutFinanceRoomListViewResponse> {
    return this.requestService.authPost(this.POST_LEASE_CHECKOUT_FINANCE_ROOM_VIEW, req);
  }

  public checkoutFinanceNomorefeeAuditList(noOverdue, noBreaking): Promise<CheckoutAuditListResponse> {
    return this.requestService.authGet(this.GET_LEASE_CHECKOUT_FINANCE_NOMOREFEE_AUDIT_LIST, {
      no_breaking_fee: noBreaking,
      no_overdue_fee: noOverdue
    });
  }


  public checkoutFinanceNomorefeeAuditCheck(req: CheckoutNoOtherFeeRequest): Promise<CheckoutAuditListResponse> {
    return this.requestService.authPost(this.POST_LEASE_CHECKOUT_FINANCE_NOMOREFEE_AUDIT_CHECK, req);
  }

  public checkoutFinanceRoomConfirm(req: CheckoutFinanceRoomListViewRequest): Promise<any> {
    return this.requestService.authPost(this.POST_LEASE_CHECKOUT_FINANCE_ROOM_CONFIRM, req);
  }

  public checkoutFinanceRoomCancel(req: CheckoutConfirmRequest): Promise<any> {
    return this.requestService.authPost(this.POST_LEASE_CHECKOUT_FINANCE_CANCEL, req);
  }


  public renterCheckoutView(req: CheckoutRenterListViewRequest): Promise<CheckoutRenterListViewResponse> {
    return this.requestService.authPost(this.POST_RENTER_CHECKOUT_VIEW, req);
  }
  public renterCheckoutConfirm(req: CheckoutRenterConfirmRequest): Promise<CheckoutRenterListViewResponse> {
    return this.requestService.authPost(this.POST_RENTER_CHECKOUT_CONFIRM, req);
  }

  public renterCheckoutRecordList(req: RenterCheckoutRecordListRequest): Promise<RenterCheckoutRecordListResponse> {
    return this.requestService.authGet(this.RENTER_CHECKOUT_RECORD_LIST, req);
  }

  public renterCheckoutRecordDetail(req: RenterCheckoutRecordDetailRequest): Promise<RenterCheckoutRecordDetailResponse> {
    return this.requestService.authGet(this.RENTER_CHECKOUT_RECORD_DETAIL, req);
  }
}
