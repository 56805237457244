export class ImageFile {

  url: string;

  fileName: string;

  constructor(url: string, fileName) {
    this.url = url;
    this.fileName = fileName;
  }
}

export class ImagePreviewModel {
  imgArray: Array<string>;
  index: number;

  constructor(imgArray: Array<string>, index: number) {
    this.imgArray = imgArray;
    this.index = index;
  }
}
