<div bsModal #handleModal="bs-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>处理投诉信息</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="handleModal.hide()">
          <span aria-hidden="true"></span>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body scroll-body" >
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 form-group ">
            <label>投诉时间：</label>
            <p class="form-control border-0">{{complaintsInfoDTO?.create_time | date:'yyyy-MM-dd HH:mm:ss'}}</p>
          </div>
          <div class="col-12 col-sm-6 col-md-4 form-group">
            <label>投诉人姓名：</label>
            <p class="form-control border-0">{{complaintsInfoDTO?.complaints_name ?
              complaintsInfoDTO?.complaints_name : '---'}}</p>
          </div>
          <div class="col-12 col-sm-6 col-md-4 form-group">
            <label>投诉人电话：</label>
            <p class="form-control border-0">{{complaintsInfoDTO?.complaints_user_phone}}</p>
          </div>
          <div class="col-12 col-sm-6 col-md-4 form-group">
            <label>最后处理时间：</label>
            <p class="form-control border-0">{{complaintsInfoDTO?.solve_time ?
              (complaintsInfoDTO?.solve_time | date:'yyyy-MM-dd HH:mm:ss'):'---'}}</p>
          </div>
          <div class="col-12 col-sm-6 col-md-4 form-group">
            <label>最后处理人：</label>
            <p class="form-control border-0">{{complaintsInfoDTO?.solve_user_name ?
              complaintsInfoDTO?.solve_user_name : '---'}}</p>
          </div>
          <div class="col-12 col-sm-6 col-md-4 form-group">
            <label>投诉人入住房间：</label>
            <p class="form-control border-0">{{complaintsInfoDTO?.room_name ?
              complaintsInfoDTO?.room_name : '---'}}</p>
          </div>
          <div class="col-12 col-sm-6 col-md-4 form-group">
            <label>投诉人签约合同：</label>
            <p class="form-control border-0">{{complaintsInfoDTO?.contract_num ?
              complaintsInfoDTO?.contract_num : '---'}}</p>
          </div>
          <div class="col-12 col-sm-6 col-md-4 form-group">
            <label>类型：</label>
            <p class="form-control border-0">{{complaintsInfoDTO?.complaints_type_name}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 form-group">
            <label>反馈内容：</label>
            <p class="form-control border-0">{{complaintsInfoDTO?.content}}</p>
          </div>
          <div class="col-12" *ngIf="complaintsInfoDTO?.annex_dtos && complaintsInfoDTO?.annex_dtos?.length>0">
            <hr>
            <p class="mb10 bold">相关附件：</p>
            <div class="col-8">
              <ul class="img-box mb10" *ngIf="complaintsInfoDTO?.annex_dtos && complaintsInfoDTO?.annex_dtos.length > 0">
                <app-new-file-upload [type]="2" [accept]="'FILE'"
                                     [imgList]="imageHandler(complaintsInfoDTO?.annex_dtos)"
                                     [showUploadBtn]="false" [showDelBtn]="false"></app-new-file-upload>
              </ul>
            </div>
            <hr>
          </div>
        </div>
        <div class="row" *ngIf="complaintsLogListResponse?.complaints_log_info_dtos?.length > 0">
          <div class="col-12 mb-2 bold">日志：<span class="float-right" *ngIf="complaintsInfoDTO?.classify_type">处理归类: {{getClassifyTypeName(complaintsInfoDTO?.classify_type)}}</span></div>
          <div class="col-12 mb-2">
            <div style="max-height: 200px;overflow-y: auto;overflow-x: hidden;">
              <div class="card" *ngFor="let item of complaintsLogListResponse?.complaints_log_info_dtos let i = index">
                <div class="card-header">
                  <div class="row">
                    <div class="col-12 col-md-8">
                      <span class="mr10">{{item?.create_time|date:'yyyy-MM-dd HH:mm:ss'}}</span>
                      <span class="mr10">{{item?.operate_user_name}}</span>
                      <span class="mr10">{{item?.operate_log_content}}</span>
                    </div>
                    <div class="col-12 col-md-4 text-right">
                      <span *ngIf="item?.log_type === 'WAITING' ">状态：未处理</span>
                      <span *ngIf="item?.log_type === 'PROCESSING'">状态：处理中</span>
                      <span *ngIf="item?.log_type === 'FIXED'">状态：处理完成</span>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  {{item?.log_content}}
                  <ul class="img-box-small img-box mb10" *ngIf="item?.annex_dtos && item?.annex_dtos.length > 0">
                    <app-new-file-upload [type]="2" [accept]="'FILE'"
                                         [imgList]="imageHandler(item?.annex_dtos)"
                                         [showUploadBtn]="false" [showDelBtn]="false"></app-new-file-upload>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="con">
          <div class="row">
            <ng-container *ngIf="complaintsInfoDTO?.complaints_status == 'FIXED'">
              <div  class="col-12 col-md-12 col-lg-12 mb10 is">用户评价</div>
              <div  class="col-12 col-md-6 col-lg-6 mb10" *ngIf="complaintsInfoDTO?.score_attitude != null">
                态度：{{complaintsInfoDTO?.score_attitude}}星
              </div>
              <div  class="col-12 col-md-6 col-lg-6 mb10" *ngIf="complaintsInfoDTO?.score_attitude == null">
                态度：---
              </div>
              <div  class="col-12 col-md-6 col-lg-6 mb10" *ngIf="complaintsInfoDTO?.score_attitude != null">
                质量：{{complaintsInfoDTO?.score_quality}}星
              </div>
              <div  class="col-12 col-md-6 col-lg-6 mb10" *ngIf="complaintsInfoDTO?.score_attitude == null">
                质量：---
              </div>
              <div  class="col-12 col-md-6 col-lg-6 mb10">
                内容：{{complaintsInfoDTO?.comment_content ? complaintsInfoDTO?.comment_content : '---'}}
              </div>
            </ng-container>
          </div>
        </div>

        <div class="row" *ngIf="btn_privileges.HANDLE_COMPLAINT">
          <ng-container *ngIf="complaintsInfoDTO?.complaints_status !== 'FIXED'">
            <div class="col-12 mb10">
              <p class="mb10 bold">专员处理内容</p>
              <textarea class="w-100" rows="3" minlength="3" [(ngModel)]="auditRemark"></textarea>
              <p class="text-danger" *ngIf="!auditRemark || auditRemark.length < 3">请至少录入3个汉字描述</p>
            </div>
            <div class="col-12 mb10 col-md-6">
              <p class="mb10 bold">处理结果</p>
              <select class="width210" [(ngModel)]="beingResut">
                <option value="">请选择结果</option>
                <option value="PROCESSING">处理中</option>
                <option value="FIXED">处理完成</option>
              </select>
              <!--<div class="form-check form-check-inline mr20">
                <input class="form-check-input" type="radio" name="exampleRadios" id="radio1" checked value="being" (click)="check('being')">
                <label class="form-check-label" for="radio1">处理中</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="exampleRadios" id="radio2" value="succ" (click)="check('succ')">
                <label class="form-check-label" for="radio2">处理完成</label>
              </div>-->
            </div>
          </ng-container>
          <div class="col-12 mb10 col-md-6">
            <p class="mb10 bold">处理归类</p>
            <ng-container *ngIf="!complaintsInfoDTO?.classify_type">
              <select class="width210" [(ngModel)]="classifyType">
                <option value="">请选择归类</option>
                <option value="{{item.code}}" *ngFor="let item of classify_type_list; let i=index">{{item.desc}}</option>
              </select>
            </ng-container>
            <ng-container *ngIf="complaintsInfoDTO?.classify_type">
              {{getClassifyTypeName(complaintsInfoDTO?.classify_type)}}
            </ng-container>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary mr-2 mt-2" (click)="ok_evolve()"
                *ngIf="btn_privileges.HANDLE_COMPLAINT && complaintsInfoDTO?.complaints_status !== 'FIXED' || !complaintsInfoDTO?.classify_type">保存
        </button>
        <button type="button" class="btn btn-secondary mr-2 mt-2" data-dismiss="modal" (click)="handleModal.hide()"
                *ngIf="complaintsInfoDTO?.complaints_status !== 'FIXED'">取消
        </button>
        <button type="button" class="btn btn-secondary mr-2 mt-2" data-dismiss="modal" (click)="handleModal.hide()"
                *ngIf="complaintsInfoDTO?.complaints_status === 'FIXED'">返回
        </button>
      </div>
    </div>
  </div>
</div>

