<div class="modal fade" bsModal #checkoutInventoryModal="bs-modal" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>退房物品清单</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal(checkoutInventoryModal)">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-left mb10">房间号：{{checkoutRoomInventoryResponse?.room_info?.room_name}}</p>
        <div class="row">
          <div class="col-12 col-md-6 mb10">
            <p class="w100px hei32">水表最后读数：</p>
            <ng-container
              *ngIf="checkoutRoomInventoryResponse?.water_dev || checkoutRoomInventoryResponse?.need_water == 'YES'">
              <input (input)="valueSet($event)" [(ngModel)]="checkoutRoomInventoryResponse.water_reading" type="text"
                     class="w100px mr10">
            </ng-container>
            <span
              *ngIf="checkoutRoomInventoryResponse?.water_dev && checkoutRoomInventoryResponse?.water_reading_time != null"
              class="font12 hei32 text-muted d-inline-block">{{checkoutRoomInventoryResponse?.water_reading_time | date:'yyyy-MM-dd HH:mm:ss'}} 自动读取</span>
            <span
              *ngIf="checkoutRoomInventoryResponse?.water_dev && checkoutRoomInventoryResponse?.water_reading_time == null"
              class="font12 hei32 text-muted d-inline-block">未抄表</span>
            <span style="color: #e63e00 !important;"
                  *ngIf="!checkoutRoomInventoryResponse?.water_dev && checkoutRoomInventoryResponse?.need_water != 'YES'"
                  class="font12 hei32 text-muted d-inline-block">此房型设置了不需要抄表收水费</span>
          </div>
          <div class="col-12 col-md-6 mb10">
            <p class="w100px hei32">电表最后读数：</p>
            <ng-container
              *ngIf="checkoutRoomInventoryResponse.electricity_dev || checkoutRoomInventoryResponse?.need_electricity == 'YES'">
              <input (input)="valueSet($event)" type="text"
                     [(ngModel)]="checkoutRoomInventoryResponse.electricity_reading" class="w100px mr10">
            </ng-container>
            <span
              *ngIf="checkoutRoomInventoryResponse.electricity_dev && checkoutRoomInventoryResponse.electricity_reading_time != null"
              class="font12 hei32 text-muted d-inline-block">{{checkoutRoomInventoryResponse.electricity_reading_time | date:'yyyy-MM-dd HH:mm:ss'}} 自动读取</span>
            <span
              *ngIf="checkoutRoomInventoryResponse.electricity_dev && checkoutRoomInventoryResponse.electricity_reading_time == null"
              class="font12 hei32 text-muted d-inline-block">未抄表</span>
            <span style="color: #e63e00 !important;"
                  *ngIf="!checkoutRoomInventoryResponse.electricity_dev && checkoutRoomInventoryResponse?.need_electricity != 'YES'"
                  class="font12 hei32 text-muted d-inline-block">此房型设置了不需要抄表收电费</span>
          </div>
        </div>
        <div>
          <div class="form-check form-check-inline mr20 mb10">
            <input (click)="compensateChange(true)" [checked]="radioText == 'yes'" class="form-check-input"
                   name="compensateName" type="radio" value="yes">
            <label class="form-check-label">需要赔偿</label>
          </div>
          <div class="form-check form-check-inline">
            <input (click)="compensateChange(false)" [checked]="radioText == 'no'" class="form-check-input"
                   name="compensateName" type="radio" value="no">
            <label class="form-check-label">不需要赔偿</label>
          </div>
        </div>
        <div class="form-check form-check-inline">
          <p style="color: #ff3b3b">{{errorText}}</p>
        </div>
        <ng-container *ngIf="checkoutRoomInventoryResponse.compensate">
          <p class="mb10 text-center black">
            <b>赔偿清单</b>
          </p>
          <div class="table-box-lg mb40">
            <table class="table table-bordered table-striped">
              <thead>
              <tr>
                <th>房间号</th>
                <th>物品名称</th>
                <th>品牌</th>
                <th>型号</th>
                <th>价格</th>
                <th>状况</th>
                <th>赔偿金额</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let info of checkoutRoomInventoryResponse?.inventory_list">
                <td>{{checkoutRoomInventoryResponse?.room_info?.room_num}}</td>
                <td>{{info?.inventory_name}}</td>
                <td>{{info?.inventory_brand}}</td>
                <td>{{info?.inventory_type}}</td>
                <td>￥{{info?.price}}</td>
                <td>
                  <select [(ngModel)]="info.status">
                    <option value="NO_CHECK">未检查</option>
                    <option value="DONE_CHECK">完好</option>
                    <option value="PART_DAMAGE">部分损坏</option>
                    <option value="DAMAGE">全部损坏</option>
                  </select>
                </td>
                <td>
                  <input (input)="valueSet($event)" *ngIf="'NO_CHECK' !=info.status && 'DONE_CHECK'!=info.status"
                         [(ngModel)]="info.compensate_price" type="text" class="w80px">
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
        <p *ngIf="!checkoutRoomInventoryResponse.water_dev || !checkoutRoomInventoryResponse.electricity_dev">
          此房间未安装智能{{!checkoutRoomInventoryResponse.water_dev?"水表":null}}{{!checkoutRoomInventoryResponse.electricity_dev?"电表":null}}</p>
        <div class="text-center mb20">
          <button type="button" (click)="save(checkoutInventoryModal)" class="btn btn-primary mr20">保存</button>
          <button type="button" (click)="hideModal(checkoutInventoryModal)" class="btn btn-secondary">取消</button>
        </div>
      </div>
    </div>
  </div>
</div>
