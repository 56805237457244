import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../service/common/auth.service";
import {HotelProject} from "../../../model/room/project.model";
import {LogService} from "../../../service/log/log.service";
import {CarportService} from "../../../service/carport/carport.service";
import {CarportSearchDTO} from "../../../model/carport/carport.modal";
import {WorkModel} from "../../../model/common/common.model";
import {WorkService} from "../../../service/common/work.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-carport-search-list',
  templateUrl: './carport-search-list.component.html',
  styleUrls: ['./carport-search-list.component.css']
})
export class CarportSearchListComponent implements OnInit {
  btn_privileges: any = {
    'ADD_CARPORT': false,
    'TAKE_OFF_CARPORT': false,
  };


  modalRef: BsModalRef;
  operateCarportInfoId: number = 0;

  hotelProject = new HotelProject();

  carportSearchList: Array<CarportSearchDTO> = [];

  requestList = {
    carport_status: 'ALL',
    page_num: 1,
    page_size: 20
  };

  total: number = 0;

  constructor(
    private authService: AuthService,
    private modalService: BsModalService,
    private workService: WorkService,
    private carportService: CarportService,
    private logService: LogService) {
  }

  ngOnInit() {
    this.authService.getOperatePrivilege(this.btn_privileges);
    //获得项目信息
    this.getProjectInfo();
    //获得列表信息
    this.getList();
  }

  //获取列表信息
  getList() {
    this.carportService.findCarportManageListWithLoading(this.requestList).then(response => {
      if (response) {
        this.carportSearchList = response.carport_search_list;
        this.total = response.total;
      }
    }).catch(erroe => {
      this.logService.error('CarportSearchListComponent:车库列表获取失败');
    });
  }

  /**
   * @Description:  获得项目信息
   * @Author: zhoujiahao
   * @Date: 2018-11-26  11:36
   */
  getProjectInfo() {
    this.hotelProject.project_name = this.authService.getProjectName();
    this.hotelProject.id = this.authService.getProjectId();
  }

  /**
   * @Description:  清空搜索条件
   * @Author: zhoujiahao
   * @Date: 2018-11-26  14:35
   */
  clear() {
    this.requestList.carport_status = 'ALL';
    this.requestList.page_num = 1;
    this.requestList.page_size = 20;
    this.getList();
  }

  slodOutCarport(template: any, carportInfoId: number) {
      template.show();
      this.operateCarportInfoId = carportInfoId;
  }

  configSubmit(template:any) {
    // this.modalRef.hide();
    template.hide();
    this.carportService.updateCarportPutawaySoldout({
      carport_info_id: this.operateCarportInfoId,
      carport_status: "SOLD_OUT"
    }).then(response => {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, "下架成功！"));
      this.getList();
    }).catch(error => {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, JSON.stringify(error)));
    });
  }

  cancelSubmit(template:any) {
    this.operateCarportInfoId = 0;
    // this.modalRef.hide();
    template.hide();

  }

  pageChange(currentPage: number): void {
    this.requestList.page_num = currentPage;
    this.getList();
  }
}
