<!--新增房间-->
<div class="modal fade" bsModal #addhouseModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>关联房间</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="addhouseModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body" *ngIf="!success">
        <div class="row">
          <div class="col-12 mb20">
            新房间：
          </div>
          <div class="col-12 mb20">
            <span>请按EXCEL模板格式填写数据后上传</span>
            <button class="btn p-0" style="color: #36a9e1; background: #fff; border: 1px solid #36a9e1; margin-left: 10px;" (click)="downloadDemo()">下载模板</button>
          </div>
          <div class="col-12" *ngIf="!success">

            <ng-container #uploadComponent>
              <nz-upload [nzAction]="url" [nzHeaders]="header"
                         (nzChange)="uploadChange($event)"
                         [nzFileList]="fileList">
                <button nz-button>
                  <i class="anticon anticon-upload"></i><span>上传附件</span>
                </button>
              </nz-upload>
            </ng-container>
          </div>
        </div>
      </div>


      <div class="modal-body over10" *ngIf="success">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>楼栋</th>
            <th>房间号</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let unit of res.unit_list">
            <td>{{unit.unit_name}}</td>
            <td>
              <ng-container *ngFor="let r of unit.related_info_dtolist">{{r.unit_name}}>{{r.room_num}},</ng-container>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer" *ngIf="success">
        <button class="btn btn-primary" (click)="confirmDo()">下一步</button>
        <button class="btn btn-secondary" (click)="returnUpload()">返回重传</button>
      </div>
    </div>
  </div>
</div>

<!--操作提示-->
<div class="modal fade" bsModal #infoModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="infoModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body text-center">
        <p class="mb20">
          <span class="org">{{errorInfo}}</span>
          房间已存在房源信息，请确认是否替换以上房间的房源信息?
        </p>
        <p class="mb20">
          替换后的费用信息将应用于新合同中，旧合同不影响。
        </p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" (click)="reConfirmDo()">是</button>
        <button class="btn btn-primary" (click)="infoModal.hide()">否</button>
      </div>
    </div>
  </div>
</div>
