import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApprovService} from "../../../service/approv/approv.service";

@Component({
  selector: 'app-approv-record-img',
  templateUrl: './approv-record-img.component.html',
  styleUrls: ['./approv-record-img.component.css']
})
export class ApprovRecordImgComponent implements OnInit {

  // @Input()
  instanceId;

  @ViewChild("divElement") divElement: ElementRef;
  approvImg;

  constructor(
    private approvService: ApprovService
  ) {
  }

  ngOnInit(): void {
  }

  show(instanceId): void {


    this.instanceId = instanceId;
    if (this.instanceId) {
      // this.approvService.approvDetailImg(this.instanceId).then(data1 => {
      //   this.approvImg = 'data:image/jpg;base64,' + data1.base64img;
      // });

      this.approvService.approvDetailImgJS(this.instanceId).then(data1 => {
        let code = "";
        if (data1) {
          if (data1.user_task_flow_chart_dto.nodes) {
            data1.user_task_flow_chart_dto.nodes.forEach(node => {
              let userStr = "";
              let users = node.users;
              if (users) {
                userStr = users.join(",");
              }
              let groups = node.groups;
              if (groups) {
                userStr = groups.join(",");
              }
              code += (node.node + ("@@" + userStr) + " \n ");
            });
          }
          code += (" \n ");
          if (data1.user_task_flow_chart_dto.links) {
            data1.user_task_flow_chart_dto.links.forEach(link => {
              code += (link + " \n ");
            });
          }
        }


        setTimeout(() => {
          let diagram = window['flowchart'].parse(code);
          // diagram.drawSVG('diagram');

          $('.diagram').html("");
          // you can also try to pass options:
          diagram.drawSVG(this.divElement.nativeElement, {
            'x': 0,
            'y': 0,
            'line-width': 3,
            'line-length': 50,
            'text-margin': 10,
            'font-size': 14,
            'font-color': 'black',
            'line-color': 'black',
            'element-color': 'black',
            'fill': '#000',
            'yes-text': '通过',
            'no-text': '拒绝',
            'arrow-end': 'block',
            'scale': 1,
            // style symbol types
            'symbols': {
              'start': {
                'font-color': 'red',
                'element-color': 'green',
                'fill': 'yellow'
              },
              'end': {
                'class': 'end-element'
              },
              'operation': {
                'element-color': 'white',
              },
              'parallel': {
                'font-color': '#000',
                'fill': '#000',
              }
            },
            // even flowstate support ;-)
            'flowstate': {
              'past': {'fill': '#21c485', 'font-color': '#fff', 'font-size': 12},
              'current': {'fill': '#ffad46', 'font-color': '#fff', 'font-weight': 'bold'},
              'future': {'fill': '#cdcdcd', 'font-color': '#fff'},
              'request': {'fill': 'blue'},
              'invalid': {'fill': '#444444', 'font-color': '#fff'},
              'approved': {'fill': '#58C4A3', 'font-color': '#fff', 'font-size': 12, 'yes-text': '同意', 'no-text': 'n/a'},
              'rejected': {'fill': '#E53935', 'font-color': '#fff', 'font-size': 12, 'yes-text': 'n/a', 'no-text': '拒绝'}
            }
          });
        });
      });
    }

  }

}
