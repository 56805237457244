import {LeaseAuditFlowBaseResponse} from "../lease/lease.model";
import {PageReq, PageResp} from "../page/page";
import {WelfareManagerModel} from "../ebusiness/ebusiness.model";

export class AssetDetailResponse {
  // 资产项目关系id
  asset_contract_project_relation_id?: number;
  //入库单序列号
  godown_serial?: string;
  // 入库时间
  godown_time?: number;
  // 分配时间
  allot_time?: number;
  unit_id?: number;
  room_id?: number;
  // 房间名称
  room_name?: string;
  // 资产
  asset?: AssetDTO;
  // 批次
  asset_batch?: AssetBatchDTO;
  // 日志列表
  asset_logs?: AssetLogDTO[];
  asset_allot?: AssetAllotDTO;
  asset_contract_num?: string;
  operator_name?: string;
}

export class AssetAllotDTO {
  asset_serial: string;
  room_id: number;
  unit_id: number;
  allot_time: number;
  room_name: string;
}

/**
 * 入库单
 */
export class AssetGodownDTO {
  // 入库时间
  godown_time?: number;
  // 入库单序列号
  godown_serial?: string;
  // 备注
  godown_remark?: string;
  // 入库人
  aodown_creator?: string;
  // 入库单状态
  godown_status?: string;
  // 审核人
  auditor?: string;
  // 审核时间
  audit_time?: number;
  // 审核备注
  audit_remark?: string;
  //  填写时间
  create_time?: number;
}

/**
 * 入库单列表
 */
export class GodownListResponse {
  asset_godowns?: AssetGodownDTO[];
}

export class GodownDetailResponse {
  asset_godown?: AssetGodownDTO;
  asset_batchs?: AssetBatchDTO[];
  attachments?: string[];
}

export class GodownAuditRequest {
  godown_serial?: string;
  audit_type?: string;
  audit_remark?: string;
}

/**
 * 资产
 */
export class AssetDTO {
  // 资产ID
  id?: number;
  // 资产状态
  asset_status?: string;
  // 入库单编号
  godown_serial?: string;
  // 批次编号
  batch_serial?: string;
  // 资产编号
  asset_serial?: string;
  // 批次价格
  batch_price?: string;
  // 报废时间
  scrap_time?: number;
  // 分配时间
  allot_time?: number;
  room_name?: string;
  // 批次ID
  batch_id?: number;
}

/**
 * 批次
 */
export class AssetBatchDTO {
  id?: number;
  // 所属部门
  department_id?: number;
  department_name?: string;
  // 入库单序列号
  godown_serial?: string;
  // 入库批次号
  batch_serial?: string;
  // 当前批次状态
  batch_status?: string;
  // 资产类别
  batch_category?: string;
  // 资产名称
  batch_model?: string;
  // 资产类型
  batch_type?: string;
  // 资产品牌
  batch_brand?: string;
  // 批次数量
  batch_num?: number;
  // 批次时间
  batch_time?: number;
  // 批次价格
  batch_price?: string;
  // 总金额（元）
  batch_total_price?: string;
  // 供应商
  supplier?: string;
  // 制造商
  manufacturer?: string;
  // 生产地
  product_addr?: string;
  // 操作人
  operator_name?: string;
  economic_use?: string;
  create_time?: number;
  batch_group_id?: number;
  asset_serials?: string[];
  attachment_list?: string[];
}

/**
 * 资产日志列表
 */
export class AssetLogDTO {
  biz_id?: number;
  log_type?: string;
  operator_id?: number;
  operator_name?: string;
  operate_content?: string;
  operate_time?: number;
}

/**
 * 具体资产列表
 */
export class AssetListDTO {
  id?: number;
  //入库单编号
  godown_serial?: string;
  //批次ID
  batch_id?: number;
  //批次编号
  batch_serial?: string;
  //资产编号
  asset_serial?: string;
  //房源名称
  room_name?: string;
  //资产状态
  asset_status?: string;
  asset_model_name?: string;
  asset_type_name?: string;
  economic_use?: string;
  asset_department?: string;
  asset_contract_num?:string;
  batch_price?:string;
}

/**
 * 房间查询
 */
export class RoomQueryDTO {
  room_id?: number;
  room_name?: string;
}

/**
 * 类型
 */
export class AssetClassDTO {
  id?: number;
  class_type?: string;
  class_name?: string;
  parent_id?: number;
}

/**
 * 资产名称
 */
export class AssetClassModelDTO extends AssetClassDTO {
  //资产类型
  asset_class_types?: AssetClassDTO[];
  //资产品牌
  asset_class_brands?: AssetClassDTO[];
}

/**
 * 资产类别
 */
export class AssetClassGategoryDTO extends AssetClassDTO {
  // 资产名称
  asset_class_models?: AssetClassModelDTO[];
}

export class GodownEditRequest {
  godown_serial?: string;
  godown_edit_type?: string;
  asset_batchs?: AssetBatchDTO[];
  godown_remark?: string;
  attachments?: string[];
}

export class AssetListRequest {
  //入库单编码
  godown_serial?: string;
  //批次编码
  batch_serial?: string;
  // 资产编码
  asset_serial?: string;
  //房间
  room_id?: number;
  // 资产类别
  batch_category?: string;
  // 资产类型
  batch_type?: string;
  page_size: number;
  page_num: number;
  search_content?:string;
  economic_use?:string;
  department_id?:number;
  unit_id?:number;
  asset_status?:string;
  // 项目名称
  search_project_id?:number;
}

export class GodownListRequest {
  creator?: string;
  start_time?: any;
  end_time?: any;
  godown_status?: string;
  page_size: number;
  page_num: number;
}

export class AssetEditRequest {
  approval_number?: string;
  asset_serial?: string;
  asset_status?: string;
  scrap_time?: any;
  unit_id?: number;
  room_id?: number;
  asset_contract_project_relation_id?: number;
}

export class RoomVagueDTO {
  room_id: number;
  room_num: string;
  room_name: string;
}

export class AssetContractListDTO {
  id?: number;
  //资产合同号
  asset_contract_num?: string;
  //资产名称
  asset_contract_name?: string;
  //资产类型
  asset_contract_type?: string;
  asset_contract_price?: number;
  asset_contract_sign_time?: number;
  asset_contract_status?: string;
  creator_id?: number;
  creator_name?: string;
  create_time?: number;
}

export class AssetBatchGroupDTO {
  id?: number;
  purchase_id?: number;
  godown_id?: number;
  storage_num?: number;

  // 已入库数量
  stored_in_num?: number;

  // 未入库数量
  stored_not_in_num?: number;

  // 单价（元）
  batch_group_price?: string;

  // 采购总金额（元）
  batch_group_total_price?: string;

  // 出库数量
  stored_out_num?:number;

  // 出库资产总金额（元）
  batch_group_out_price?:string;

  // 剩余资产总金额（元）
  left_group_price?:string;

  operator_id?: number;
  operator_name?: string;
  storage_status?: string;
  batch_category_id?: number;
  batch_category?: string;
  batch_model_id?: number;
  batch_model?: string;
  batch_type_id?: number;
  batch_type?: string;
  batch_brand_id?: number;
  batch_brand?: string;
  supplier?: string;
  manufacturer?: string;
  product_addr?: string;
}

export class AssetContractDTO {
  id?:number;
  contract_edit_type?: string;
  //资产合同号
  asset_contract_num?: string;
  // 状态
  asset_contract_status?: string;
  //资产名称
  asset_contract_name?: string;
  //资产合同总金额
  asset_contract_price?: string;
  //资产合同签署日期
  asset_contract_sign_time?: number;
  // 合同性质
  asset_contract_type?: string;
  // 操作日志数量
  action_log_num?: number;
  //资产批次
  asset_batch_group_list?: Array<AssetBatchGroupDTO> = [];
  // 附件合同
  attachments?: Array<string> = [];
}

export class AssetBatchGroupRequest {
  page_size?: number;
  page_num?: number;
  search_content?: string;
  storage_status?: string;
  department_id?: number;
  economic_use?: string;
}

export class AssetBatchMergeGroupDTO {
  batch_serial?: string;
  batch_num?: number;
  storage_time?: number;
  attachment_list?: Array<string> = [];
}

export class AssetAuditDTO {
  auditor?: string;
  audit_time?: number;
  audit_remark?: string;
}

export class AssetPurchaseDTO {
  id?: number;
  asset_contract_num?: string;
  asset_contract_name?: string;
  asset_contract_price?: string;
  asset_contract_sign_time?: number;
  asset_contract_status?: string;
  asset_purchase_num?: number;
  asset_purchase_storage_in_num?: number;
  asset_purchase_storage_out_num?: number;
  creator_id?: number;
  creator_name?: string;
}

export class AssetBatchDetailResponse {
  asset_audit?: AssetAuditDTO;
  asset_batch?: AssetBatchDTO;
  asset_purchase?: AssetPurchaseDTO;
  asset_batch_group?:AssetBatchGroupDTO;
  department_name?: string;
  asset_contract_num?: string;
}

export class AssetChangeRemindListResponse extends PageResp{
  asset_change_remind_list:AssetChangeRemindDTO[];
}

export class AssetChangeRemindDTO{
  asset_change_remind_id:number;
  asset_number:string;
  asset_change_commit_user_id:number;
  asset_id:number;
  status:string;
  project_id:number;
  // @Remark("变更前的状态desc非code")
  before_status:string;
  // @Remark("变更后的状态desc非code")
  later_status:string;
  // @Remark("变更时间")
  change_time:Date;
  // @Remark("变更前房间名")
  before_room_name:string;
  // @Remark("变更后房间名")
  later_room_name:string;
  // @Remark("OA审批单号")
  approval_number:string;
  // @Remark("资产分配时间")
  allocate_time:Date;
  // @Remark("保管部门")
  preserver:string;
  // @Remark("提交人名称")
  commit_user_name:string;
  // @Remark("资产型号")
  batch_category:string;
  // @Remark("资产名称")
  batch_model:string;
  // @Remark("资产类型")
  batch_type:string;
  // @Remark("资产品牌")
  batch_brand:string;
  // @Remark("批次价格")
  batch_price:number;
  // @Remark("入库时间")
  asset_create_time:Date;
}

export class AssetTransferProjectRequest {

  selectAssIdList: Array<number>;

  transProjectId: number;

  imgs?: Array<string>;
}

export class AssetBatchTransferRequest extends PageReq {
  // @Remark("资产类别ID")
  assetClassIdList?: Array<number>;

  // @Remark("资产编号")
  assetBatchSerial?: string;

  // @Remark("资产名称")
  assetName?: string;

  // @Remark("所属批次")
  batchInfoTxt?: string;

  // @Remark("所属合同")
  contractTxt?: string;

  // @Remark("是否仅显示可转移的资产")
  isShowCanTrans?: boolean;

  // 保管部门
  departmentId?: number;

  // 产品型号
  assetType?: string;

  // 存放地点
  roomName?: string;

  // 品牌
  batchBrand?: string;

  // 资产批次分组入库ID数组
  batchGroupIdList?: Array<number>;

  checkId?: number;
}

export class AssetCheckListRequest extends PageReq {
  schemeName: string;

  checkPrincipal: string;

  checkStatus: string;

  startTime?: number;

  endTime?: number;
}

export class AssetCheckListRespone extends PageResp {

  asset_check_list?: Array<AssetCheckListModel>;

}

export class AssetCheckListModel {

  // 序号
  index?: number;

  // 盘点ID
  id?: number;

  // 盘点编号
  check_number?: string;

  // 方案名称
  scheme_name?: string;

  // 盘点负责人
  check_principal?: string;

  // 盘点资产项数
  asset_num?: number;

  // 已盘项数
  finish_asset_num?: number;

  // 任务开始时间
  start_time?: Date;

  // 任务结束时间
  end_time?: Date;

  // 盘点状态
  check_status?: string;

  // 距离任务到期天数
  effect_days?: number;

  // 操作人ID
  operator_id?: number;
}



export class AssetBatchTransferResponse extends PageResp {
  asset_batch_transfer_list?: Array<AssetBatchTransferDTO>;

  return_num?: number;
}

export class AssetTransferOpResponse {
  message_txt?: string;

  message_detail_array?: Array<string>;
}

export class AssetBatchTransferDTO {
  // @Remark("资产ID")
  asset_id: number;

  // @Remark("资产转移项目合同关系ID")
  asset_contract_project_relation_id: number;

  //@Remark("资产类别名称")
  asset_batch_class_name: string;

  //@Remark("资产编号")
  asset_batch_serial: string;

  //@Remark("资产名称")
  asset_name: string;

  //@Remark("资产型号")
  asset_batch_type_name: string;

  // 品牌
  batch_brand: string;

  // 保管部门
  asset_department: string;

  // 存放地点
  room_name: string;

  //@Remark("资产状态名称")
  asset_status_name: string;

  // 资产盘点状态
  asset_check_status: string;

  // 资产转移状态
  asset_transfer_status: string;

  //@Remark("资产状态")
  asset_status: string;

  //@Remark("所属批次")
  batch_info_txt: string;

  //@Remark("所属合同")
  contract_txt: string;

  // 是否选中，true-选中，false-未选中
  is_check: boolean = true;
}


export class AssTransAuditDetailResponse extends LeaseAuditFlowBaseResponse {

  /** 资产转移审批记录id */
  asset_edit_record_id: number = 0;

  /** 原所属项目 */
  old_project_name: string;

  /** 新所属项目 */
  new_project_name: string;

  /** 审批单号 */
  approv_num: string = "";

  /** 审批表主键id */
  appro_record_info_id: number;

  /** 转移资产数 */
  asset_edit_num: string;

  /** 转移资产总价值 */
  asset_edit_total: string;

  /** 审批状态 */
  record_status: string;

  /** 资产转移审批记录资产列表 */
  asset_list?: Array<AssetEditRecordDetailAssetListModel>;

  total?: number;
}

export class AssetEditRecordDetailAssetListModel{
  /** 资产类别 */
  batch_category: string;

  /** 资产编号 */
  asset_serial: string;

  /** 资产名称 */
  batch_model: string;

  /** 资产型号 */
  batch_type: string;

  /** 单价 */
  asset_price: string;

  // 序列
  asset_index: number;
}

export class AssTransAuditDetailRequest extends PageReq {
  /** 资产转移审批记录id */
  assetEditRecordId?: number;
  // 审批单id
  approRecordInfoId?: number;
}

// 资产盘点保存
export class AssetCheckSaveRequest {
  /** 盘点id */
  checkId?: number;

  /** 方案名称 */
  schemeName: string;

  /** 盘点负责人 */
  checkPrincipal: number;

  /** 任务开始日期 */
  startTime: number;

  /** 任务结束日期 */
  endTime: number;

  /** 资产项目关系id集合 */
  assetContractProjectRelationIds: Array<number>;

  batchGroupIdList: Array<number>;
}

export class AuditAssetEditReq {
  asset_edit_record_id: number;
  record_status: string;
  remark: string;
}
