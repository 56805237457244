<div class="modal fade" bsModal #modal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name" (click)="stopP($event)">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>选择人员</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-2">请选择需要换离的人员：</p>
        <div class="over10">
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th>
                <input type="checkbox" [checked]="checkedInfoIds.length >0 && checkedInfoIds.length == renterList.length" (click)="checkAll($event)">
              </th>
              <th>全选</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of renterList">
              <td>
                <input type="checkbox" [checked]="(checkedInfoIds.indexOf(item.info_id)>=0)"
                       (click)="checkCurrentRenter($event,item.info_id)">
              </td>
              <td>{{item.name}} | {{item.sex=='MALE'?'男':'女'}} | {{item.cret_num}} | {{item.phone}}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="text-center mb20">
          <button type="button" class="btn btn-primary mr20" [disabled]="canNext()" (click)="next()">下一步</button>
          <button type="button" (click)="hide()" class="btn btn-secondary">取消</button>
        </div>
      </div>
    </div>
  </div>
</div>
