import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';


// 引入three.js
import * as THREE from 'three';
// 引入扩展库OrbitControls.js
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
// 引入扩展库GLTFLoader.js
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {CSS2DRenderer, CSS2DObject} from 'three/examples/jsm/renderers/CSS2DRenderer';
import {FontLoader} from 'three/examples/jsm/loaders/FontLoader';
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry';

import {animate, animateToWarn, createFloorV2, transferObj} from "./../ThreeUtil";


import ThreeBSPModule from '../../../../common/threeBSP';
import {ActivatedRoute, Router} from "@angular/router";
import {RoomService} from "../../../../service/room/room.service";
import {WorkRemindService} from "../../../../service/workremind/workremind.service";
import * as $ from "jquery";
import {RoomInfoDTO} from "../../../../model/room/room.model";
import {FireSystemComponentWarnBizDTO} from "../../../../model/workremind/workremind.model";
import {
  BuildFloorModel,
  BuildingFloorMap,
  BuildingMap,
  BuildModel,
  BuildProject,
  BuildRoomModel
} from "../../fire-building-index/building-data";


const ThreeBSP = ThreeBSPModule(THREE);

@Component({
  selector: 'app-fire-v-floor',
  templateUrl: './fire-v-floor.component.html',
  styleUrls: ['./fire-v-floor.component.css']
})
export class FireVFloorComponent implements OnInit, OnDestroy {


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private roomService: RoomService,
  ) {
  }

  @Input()
  fire: FireSystemComponentWarnBizDTO;


  @ViewChild("frameDiv") frameDiv: ElementRef;

  x = 200;
  y = 200;
  z = 300;


  str;

  projectId = 0;
  unitId = 0;
  floor = 0;
  room = '';

  building;


  noticeUpInter;
  warnQueryInter;


  hideLoading = false;


  index = 999;

  ngOnInit(): void {

    window['WINDOW_KEY'] = 'THREE_FIRES';

    window['THREE'] = window['THREE'] || {};
    window['THREE_FIRE'] = window['THREE_FIRE'] || {};
    window['THREE_FIRES'] = window['THREE_FIRES'] || [];

    let p: Promise<any> = new Promise((resolve, reject) => {
      new FontLoader().load('assets/fonts/DengXian_Regular.json', function (font) {
        window['THREE']['font'] = font;
        resolve(font);
      });
    }).then(() => {
      return this.roomService.getRoomBuildingIndex();
    });


    Promise.all([p])
      .then(arr => {
        let data = arr[0];
        try {
          this.building = data;
          this.initThree(data);
        } catch (e) {
          console.log(e);
        }
      }).then(() => {
      this.initFireWarn(this.fire);
      this.hideLoading = true;
    });
  }

  ngOnDestroy(): void {
    window['THREE_FIRE']['animate'] = [];

    if (this.noticeUpInter) {
      clearInterval(this.noticeUpInter);
    }
    if (this.warnQueryInter) {
      clearInterval(this.warnQueryInter);
    }
  }


  initFireWarn(room: FireSystemComponentWarnBizDTO) {
    window['THREE_FIRE']['animate'] = [];
    if (room) {
      let str = "R_" + room.project_id + "_" + room.unit_id + "_" + room.floor + "_" + room.room_id;
      this.warningAnimate(
        str,
        [
          window['THREE_FIRES'][this.index].obj[room.project_id][room.unit_id][room.floor][room.room_id].children[0]
        ]
      );
    }
  }

  warningAnimate(id, meshs: Array<THREE.Mesh>) {
    let list = window['THREE_FIRE']['animate'] || [];
    list.push({
      id: id,
      meshs: meshs
    });
    window['THREE_FIRE']['animate'] = list;
  }


  initThree(data) {

    window['THREE_FIRES'] = window['THREE_FIRES'] || [];
    window['THREE_FIRES'][this.index] = {};

    // 定义threejs输出画布的尺寸(单位:像素px)
    const width = 500; //宽度
    const height = 300; //高度

    window['THREE_FIRES'][this.index].width = width;
    window['THREE_FIRES'][this.index].height = height;

    // 创建3D场景对象Scene
    const scene = new THREE.Scene();

    const lod = new THREE.LOD();
    let group = this.initBuilding(data);
    lod.addLevel(group, 0);

    scene.add(lod);

    // AxesHelper：辅助观察的坐标系
    // const axesHelper = new THREE.AxesHelper(5000);
    // scene.add(axesHelper);

    window['THREE_FIRES'][this.index]['scene'] = scene;


    // 实例化一个透视投影相机对象 竖直方向视野角度 水平方向和竖直方向长度比
    // const camera = new THREE.PerspectiveCamera(120, 1, 200, 20000);

    const k = width / height; //canvas画布宽高比
    const s = 3000;//控制left, right, top, bottom范围大小
    const camera = new THREE.OrthographicCamera(-s * k, s * k, s, -s, 1, 10000);

    //相机在Three.js三维坐标系中的位置
    // 根据需要设置相机位置具体值
    // 10楼 10间房中间
    camera.position.set(0, 2000, 4000);

    //相机观察目标指向Threejs 3D空间中某个位置
    camera.lookAt(0, -200, 4000);

    window['THREE_FIRES'][this.index]['camera'] = camera;

    // 创建渲染器对象
    const renderer = new THREE.WebGLRenderer({
      antialias: true, //开启锯齿
      alpha: true//透明度，这个属性至关重要
    });

    renderer.setSize(width, height); //设置three.js渲染区域的尺寸(像素px)

    renderer.render(scene, camera); //执行渲染操作

    window['THREE_FIRES'][this.index]['renderer'] = renderer;

    // 设置相机控件轨道控制器OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);

    // 如果OrbitControls改变了相机参数，重新调用渲染器渲染三维场景
    controls.addEventListener('change', function () {
      renderer.render(scene, camera); //执行渲染操作
      // labelRenderer.render(scene, camera); //执行渲染操作
    });//监听鼠标、键盘事件

    window['THREE_FIRES'][this.index]['controls'] = controls;

    document.getElementById('webglf').innerHTML = '';
    document.getElementById('webglf').appendChild(renderer.domElement);


    // animate
    animateToWarn();

    // 鼠标选中
    let mouse = new THREE.Vector2();
    document.addEventListener('mousemove', onDocumnetMouseMove, false);

    function onDocumnetMouseMove(event) {
      mouse.x = (event.clientX / window['THREE_FIRES'][this.index].width) * 2 - 1;
      mouse.y = -(event.clientY / window['THREE_FIRES'][this.index].height) * 2 + 1;
    }

  }


  initBuilding(data): THREE.Group {

    let projectId = data.id;
    this.projectId = data.id;

    const group = new THREE.Group();

    let buildProject = transferObj(data);

    if (buildProject.buildings) {
      buildProject.buildings.forEach(unit => {
        if (this.fire.unit_id === unit.id) {
          let build = this.createBuildingV2(buildProject.id, unit);
          group.add(build);
        }
      });
    }

    group.objType = 'PROJECT';
    group.objId = projectId;

    window['THREE_FIRES'][this.index].objp = window['THREE_FIRES'][this.index].objp || {};
    window['THREE_FIRES'][this.index].objp[projectId] = group;

    return group;
  }


  createBuildingV2(projectId, buildModel: BuildModel) {

    const group = new THREE.Group();

    buildModel.floor.forEach(floor => {
      if (this.fire.floor === floor.floor) {
        let group1 = createFloorV2(this.index, projectId, buildModel, floor);
        group.add(group1);
      }
    });

    group.position.set(-4700, 0, 0);

    group.objType = 'UNIT';
    group.objId = buildModel.id;

    group.dto = {};
    group.dto.p = {};
    group.dto.p.x = group.position.x;
    group.dto.p.y = group.position.y;
    group.dto.p.z = group.position.z;

    window['THREE_FIRES'][this.index].obju = window['THREE_FIRES'][this.index].obju || {};
    window['THREE_FIRES'][this.index].obju[projectId] = window['THREE_FIRES'][this.index].obju[projectId] || {};
    window['THREE_FIRES'][this.index].obju[projectId][buildModel.id] = group;

    return group;
  }


}
