<div class="modal fade" bsModal #checkoutRoomView="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>请选择要退的房间</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="checkoutRoomView.hide();checkAll=false;ids=[]">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="over10">
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th>
                <input (change)="roomCheckAll()" [(ngModel)]="checkAll" [checked]="checkAll" type="checkbox">
              </th>
              <th>房间号</th>
              <th>房型</th>
              <th>合同结束日期</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let info of checkoutRoomViewResponse?.checkout_room_list">
              <td>
                <input [checked]="info.roomCheck" (change)="selectOne(info)" [(ngModel)]="info.roomCheck"
                       type="checkbox">
              </td>
              <td>
                {{info?.room_name}}
              </td>
              <td>{{info?.room_type_name}}</td>
              <td>
                {{checkoutRoomViewResponse?.lease_info?.end_time | date:'yyyy-MM-dd'}}
              </td>
            </tr>
            <tr>
              <td
                *ngIf="checkoutRoomViewResponse?.checkout_room_list == null || checkoutRoomViewResponse?.checkout_room_list.length == 0 "
                colspan="4">此合同下房间已退
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="text-center mb20">

          <button type="button" *ngIf="ids.length>0" (click)="openCheckoutConfirm()" class="btn btn-primary mr20">下一步
          </button>
          <button type="button"
                  *ngIf="checkoutRoomViewResponse?.checkout_room_list && checkoutRoomViewResponse?.checkout_room_list.length>0 && ids.length==0"
                  disabled="disabled" class="btn btn-secondary mr20">下一步
          </button>
          <button type="button" (click)="checkoutRoomView.hide();checkAll=false;ids=[]" class="btn btn-secondary">取消</button>
        </div>
      </div>
    </div>
  </div>
</div>
