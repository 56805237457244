<div class="setbox-top flex">
  <div class="flex-content">
    <div class="bg-primary bg-green">退房快照</div>
    <div class="setbox text-dark">
      <h2 class="text-center">退房费用结算单</h2>
      <hr>
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">退房单号：{{checkoutViewResponse.checkout_record_no!}}</div>
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">合同号：{{checkoutViewResponse?.lease_info.contract_num}}</div>
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">合同周期:
          {{checkoutViewResponse?.lease_info.start_time|date:'yyyy.MM.dd'}}
          -{{checkoutViewResponse?.lease_info.end_time|date:'yyyy.MM.dd'}}
        </div>
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">
          退房日期: {{checkoutViewResponse?.checkout_room_dtolist[0].end_time |date:'yyyy.MM.dd'}}</div>
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">
          退房间数：{{checkoutViewResponse?.checkout_room_dtolist?.length}}
        </div>
      </div>


      <ng-container *ngIf="checkoutViewResponse?.check_out_room_info_dto?.receivable_total">
        <div class="box-table">
          <h5 class="mt-1 font-weight-bold">费用结算汇总：</h5>
          <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
              <th class="w-25" scope="col">应收费用</th>
              <th class="w-25" scope="col">应收金额（元）</th>
              <th class="w-25" scope="col">应收费用</th>
              <th class="w-25" scope="col">应收金额（元）</th>
            </tr>
            </thead>
            <tbody *ngFor="let item of checkoutViewResponse?.check_out_room_info_dto?.receivable_items;let j = index">
            <tr *ngIf="j%2==0">
              <td *ngIf="j%2==0">{{item.object_code}}</td>
              <td *ngIf="j%2==0">{{item.object_value}}</td>
              <td
                *ngIf="j+1<checkoutViewResponse?.check_out_room_info_dto?.receivable_items.length && (j+1)%2==1">{{checkoutViewResponse?.check_out_room_info_dto?.receivable_items[j + 1].object_code}}</td>
              <td
                *ngIf="j+1<checkoutViewResponse?.check_out_room_info_dto?.receivable_items.length && (j+1)%2==1">{{checkoutViewResponse?.check_out_room_info_dto?.receivable_items[j + 1].object_value}}</td>
              <td *ngIf="j+1>=checkoutViewResponse?.check_out_room_info_dto?.receivable_items.length"></td>
              <td *ngIf="j+1>=checkoutViewResponse?.check_out_room_info_dto?.receivable_items.length"></td>
            </tr>
            </tbody>
            <tbody>
            <td class="bg-gray-200 font-weight-bold">应收总计（元）</td>
            <td class="text-right" colspan="3">{{checkoutViewResponse?.check_out_room_info_dto?.receivable_total}}</td>
            </tbody>
          </table>
        </div>
      </ng-container>

      <ng-container *ngIf="checkoutViewResponse?.check_out_room_info_dto?.retreated_total">
        <div class="box-table">
          <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
              <th class="w-25" scope="col">应退费用</th>
              <th class="w-25" scope="col">应退金额（元）</th>
              <th class="w-25" scope="col">应退费用</th>
              <th class="w-25" scope="col">应退金额（元）</th>
            </tr>
            </thead>
            <tbody *ngFor="let item of checkoutViewResponse?.check_out_room_info_dto?.retreated_items;let j = index">
            <tr *ngIf="j%2==0">
              <td *ngIf="j%2==0">{{item.object_code}}</td>
              <td *ngIf="j%2==0">{{item.object_value}}</td>
              <td
                *ngIf="j+1<checkoutViewResponse?.check_out_room_info_dto?.retreated_items.length && (j+1)%2==1">{{checkoutViewResponse?.check_out_room_info_dto?.retreated_items[j + 1].object_code}}</td>
              <td
                *ngIf="j+1<checkoutViewResponse?.check_out_room_info_dto?.retreated_items.length && (j+1)%2==1">{{checkoutViewResponse?.check_out_room_info_dto?.retreated_items[j + 1].object_value}}</td>
              <td *ngIf="j+1>=checkoutViewResponse?.check_out_room_info_dto?.retreated_items.length"></td>
              <td *ngIf="j+1>=checkoutViewResponse?.check_out_room_info_dto?.retreated_items.length"></td>
            </tr>
            </tbody>
            <tbody>
            <td class="bg-gray-200 font-weight-bold">应退总计（元）</td>
            <td class="text-right" colspan="3">{{checkoutViewResponse?.check_out_room_info_dto?.retreated_total}}</td>
            </tbody>
          </table>
        </div>
      </ng-container>

      <div class="mt-2 mt-2 text-right font-lg font-weight-bold">
        合计：<span class="text-danger font-lg">{{checkoutViewResponse?.check_out_room_info_dto?.amount_total}}</span>元
      </div>

      <ng-container *ngIf="checkoutViewResponse?.overdue_lease_room_bill_list&&checkoutViewResponse?.overdue_lease_room_bill_list.length>0">
        <div class="mt-2 font-lg font-weight-bold">
          滞纳金信息：该次退房存在滞纳金，滞纳金金额以客户实际支付账单时间进行计算（滞纳金=逾期未付的房费账单金额 * 滞纳金比例 * 逾期天数）
        </div>
        <div class="box-table mt-2">
          <h5 class="mt-1 font-weight-bold">滞纳金说明：</h5>
          <div class="mt-1">以下房间房费账单已逾期，根据合同约定预估产生的滞纳金明细如下</div>
          <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
              <th class="w-15" scope="col">房间号</th>
              <th class="w-15" scope="col">账单周期</th>
              <th class="w-15" scope="col">账单金额</th>
              <th class="w-10" scope="col">应付时间</th>
              <th class="w-10" scope="col">已付金额</th>
              <th class="w-10" scope="col">剩余金额</th>
              <th class="w-10" scope="col">逾期天数</th>
              <th class="w-15" scope="col">滞纳金</th>
            </tr>
            </thead>
            <tbody *ngFor="let item of checkoutViewResponse?.overdue_lease_room_bill_list;let j = index">
            <tr>
              <td>{{item.room_name}}</td>
              <td>{{item.bill_start_time|date:'yyyy.MM.dd'}}-{{item.bill_end_time|date:'yyyy.MM.dd'}}</td>
              <td>{{item.bill_amount}}</td>
              <td>{{item.plan_pay_time|date:'yyyy.MM.dd'}}</td>
              <td>{{item.received}}</td>
              <td>{{item.unreceived}}</td>
              <td>{{item.overdue_day}}</td>
              <td>{{item.overdue_amount}}</td>
            </tr>
            </tbody>
          </table>
          <div class="mt-2 text-right font-lg font-weight-bold ">
            合计：<span class="text-danger font-lg">{{checkoutViewResponse?.overdue_total}}元</span>
          </div>
        </div>
      </ng-container>

      <div class="mt-2 text-right font-xl font-weight-bold ">
        费用结算合计<span class="text-gray1 font-s">(含预估滞纳金)</span>：<span
        class="text-danger font-xl">{{checkoutViewResponse?.total}}元</span>
      </div>

      <div class="my-5 col-12">
        <div class="row">
          <div class="col-6 px-0">
            接待管家：<span class="d-inline-block border-bottom">{{checkoutViewResponse?.manage_name}}</span>
          </div>
          <div class="col-6 px-0">
            客户确认：
            <span class=" d-inline-block border-bottom">
             <img *ngIf="checkoutViewResponse?.sign_img" [src]="checkoutViewResponse?.sign_img" width="200px"/>
              <ng-container
                *ngIf="checkoutViewResponse?.confirm_str">{{checkoutViewResponse?.confirm_str}}</ng-container>
            </span>
          </div>
        </div>
      </div>

      <!--      <div class="mb-3">-->
      <!--        <span class="text-orange mr-4">合计：<b-->
      <!--          class="font18">{{checkoutViewResponse?.check_out_room_info_dto?.amount_total}}</b>元</span>-->

      <!--      </div>-->
      <div class="mt-2">
        <div *ngIf="checkoutViewResponse?.refund_info_dto != null && financeType==='REFUND'"
             class="card card-block mb-2">
          <h3><b class="font16">退款账户</b></h3>
          <div class="mb-2 bg-gray text-dark">
            <div class="row mb-3">
              <div class="col-12">退款方式：
                <div *ngIf="checkoutViewResponse?.refund_info_dto?.pay_type_name !== '现金'"
                     class="form-check form-check-inline">
                  <label class="form-check-label">转账</label>
                </div>
                <div *ngIf="checkoutViewResponse?.refund_info_dto?.pay_type_name === '现金'"
                     class="form-check form-check-inline">
                  <label class="form-check-label">现金</label>
                </div>
              </div>
            </div>
            <div *ngIf="checkoutViewResponse?.refund_info_dto?.pay_type_name !== '现金'" class="row">
              <div class="col-12 col-md-3 col-lg-2">
                <div class="form-group">
                  <label>收款人姓名：</label>
                  <input readonly="readonly" [value]="checkoutViewResponse?.refund_info_dto?.payee"
                         class="form-control">
                </div>
              </div>
              <div class="col-12 col-md-3 col-lg-2">
                <div class="form-group">
                  <label>开户行：</label>
                  <input readonly="readonly" [value]="checkoutViewResponse?.refund_info_dto?.open_bank"
                         class="form-control" maxlength="50">
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label>银行账号：</label>
                  <input readonly="readonly" [value]="checkoutViewResponse?.refund_info_dto?.bank_no"
                         class="form-control"
                         maxlength="50">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card card-block mb-2" *ngIf="financeType==='DELAY'">
          <h3><b class="font16">延期退款</b></h3>
          <div class="mb-2 bg-gray text-dark">
            <div class="row my-3 px-3">
              已选择延期退款，可直接提交退房，如需继续退款可从【工作台】-【我的待办】-【延期退款】模块继续操作退款。
            </div>
          </div>
        </div>

        <div class="card card-block mb-2" *ngIf="financeType==='TRANSFER_IN'">
          <h3><b class="font16">退款转收入明细</b></h3>
          <div class="mb-2 ">
            <div class="my-3">
              <table class="table table-bordered">
                <thead class="thead-light">
                <tr>
                  <th>收支抵扣后剩余转收入费用</th>
                  <th>收支抵扣后剩余可转收入金额（元）</th>
                </tr>
                </thead>
                <tbody>
                <ng-container
                  *ngIf="checkoutViewResponse.refund_transfer && checkoutViewResponse.refund_transfer.retreated_items">
                  <tr *ngFor="let item of checkoutViewResponse.refund_transfer.retreated_items">
                    <td>{{item.object_code}}</td>
                    <td>{{item.object_value}}</td>
                  </tr>
                </ng-container>
                <tr>
                  <td class="font-weight-bold">转收入总金额（元）</td>
                  <td class="text-right">{{checkoutViewResponse?.refund_transfer?.retreated_total}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="checkoutViewResponse?.not_receivable_remark != null" class="card card-block mb-2">
          <div class="mb-2 bg-gray text-dark">
            <div class="row mb-3">
              <div class="col-12 mb-4"></div>
              <div class="col-12 mb-3">
                <div class="form-check w140px">
                  <label class="form-check-label">不收款直接退房</label>
                </div>
                <div class="condiv3">
                <textarea class="w-100" rows="1" readonly="readonly" maxlength="50"
                >{{checkoutViewResponse?.not_receivable_remark}}</textarea>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box-table mt-2" *ngIf="sumCouponTotal(checkoutViewResponse?.checkout_room_dtolist) > 0 ">
        <h5 class="mt-1 font-weight-bold">其他信息：</h5>
        <div class="">
          说明：
          有{{checkoutViewResponse?.checkout_room_dtolist?.length}}间房回收未被使用预付费-代金券
          {{sumCouponTotal(checkoutViewResponse?.checkout_room_dtolist, "预付费")}}元；
          有{{checkoutViewResponse?.checkout_room_dtolist?.length}}间房回收未被使用房费-代金券
          {{sumCouponTotal(checkoutViewResponse?.checkout_room_dtolist, "房费")}}元；
        </div>
      </div>


      <h3><b class="font16">房间明细</b></h3>
      <hr>

      <div class="box-table" *ngFor="let item of checkoutViewResponse?.checkout_room_dtolist">
        <table class="table table-bordered">
          <thead class="thead-light">
          <tr>
            <th class="text-left" colspan="3">房间号：{{item?.check_out_room_info_dto?.room_name}}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="padd15" colspan="3">
              <div class="row">
                <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">
                  合同结束日期：{{checkoutViewResponse?.lease_info.end_time|date:'yyyy-MM-dd'}}
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">
                  退房日期：{{checkoutViewResponse?.checkout_room_dtolist[0].end_time |date:'yyyy.MM.dd'}}</div>
                <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">退房状态：
                  <block class="text-danger">
                    <ng-container *ngIf="item.checkout_type==='NORMAL'">
                      正常
                    </ng-container>
                    <ng-container *ngIf="item.checkout_type==='OVERDUE'">
                      逾期<span class="text-orange">（{{item.overdue_day}}天）</span>
                    </ng-container>
                    <ng-container *ngIf="item.checkout_type==='BEFORE'">
                      提前
                    </ng-container>
                    <ng-container *ngIf="item.checkout_type==='BREAK'">
                      违约
                    </ng-container>
                    <ng-container *ngIf="item.checkout_type==='SUBLEASE'">
                      转租
                    </ng-container>
                  </block>
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">
                  应收总计：{{item.check_out_room_info_dto.receivable_total}}
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">
                  应退总计：-{{item.check_out_room_info_dto.retreated_total}}
                </div>
                <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2"><b
                  class="text-danger">合计：{{item.check_out_room_info_dto.amount_total}}</b>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
          <thead class="thead-light">
          <tr>
            <th scope="col">应收费用</th>
            <th scope="col">费用周期</th>
            <th scope="col">应收金额（元）</th>
          </tr>
          </thead>
          <tbody *ngFor="let inFee of item.check_out_room_info_dto.receivable_items">
          <tr>
            <td>{{inFee.object_code}}</td>
            <td>{{inFee.object_name}}</td>
            <td>{{inFee.object_value}}</td>
          </tr>
          </tbody>
          <thead class="thead-light">
          <tr>
            <th scope="col">应退费用</th>
            <th scope="col">费用周期</th>
            <th scope="col">应退金额（元）</th>
          </tr>
          </thead>
          <tbody *ngFor="let inFee of item.check_out_room_info_dto.retreated_items">
          <tr>
            <td>{{inFee.object_code}}</td>
            <td>{{inFee.object_name}}</td>
            <td>{{inFee.object_value}}</td>
          </tr>
          </tbody>

          <thead class="thead-light">
          <tr>
            <th scope="col">回收优惠券</th>
            <th scope="col">回收来源</th>
            <th scope="col">回收金额（元）</th>
          </tr>
          </thead>
          <tbody *ngFor="let inFee of item.check_out_room_info_dto.recycle_items">
          <tr>
            <td>{{inFee.object_code}}</td>
            <td>{{inFee.object_name}}</td>
            <td>{{inFee.object_value}}</td>
          </tr>
          </tbody>
        </table>
        <ng-template [ngIf]="item.check_out_room_info_dto.renter_bill_list">
          <table *ngFor="let rn of item.check_out_room_info_dto.renter_bill_list" class="table table-bordered" style="margin-bottom: 0px">
            <thead class="thead-light">
            <tr>
              <th colSpan="3" style="text-align: left" >住客：{{rn.renter_name}}</th>
            </tr>
            <tr>
              <th scope="col" style="width: 24%">应收费用</th>
              <th scope="col">费用周期</th>
              <th scope="col">应收金额（元）</th>
            </tr>
            </thead>
            <tbody align="center">
            <tr *ngFor="let bill of rn.renter_bill_items">
              <td>{{bill.object_code}}</td>
              <td>{{bill.object_name}}</td>
              <td>{{bill.object_value}}</td>
            </tr>
            </tbody>
          </table>
        </ng-template>
      </div>


    </div>
  </div>
  <div class="absolute-foot text-center">

    <button type="button" (click)="goLease();" class="btn btn-secondary">返回</button>
  </div>
</div>





