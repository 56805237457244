import { Component, OnInit, ViewChild } from '@angular/core';
import { WorkModel } from '../../../../model/common/common.model';
import { RoomService } from '../../../../service/room/room.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkService } from '../../../../service/common/work.service';
import { LeaseService } from '../../../../service/lease/lease.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../../service/common/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { getUserAgent } from '../../../../common/common';
import { PdfViewComponent } from '../../../shared/pdf-view/pdf-view.component';

@Component({
  selector: 'app-checkout-inventory-view',
  templateUrl: './checkout-inventory-view.component.html',
  styleUrls: ['./checkout-inventory-view.component.css']
})
export class CheckoutInventoryViewComponent implements OnInit {

  iframe;
  @ViewChild(PdfViewComponent, { static: true }) pdfView: PdfViewComponent;

  path;

  lease_id;
  room_id;
  leaseroomid;
  ids;

  can_show: string;
  load_error: boolean = false;
  type: string;

  constructor(private roomService: RoomService,
              private workService: WorkService,
              private leaseService: LeaseService,
              private http: HttpClient,
              private authService: AuthService,
              private sanitizer: DomSanitizer,
              private router: Router,
              private activatedRoute: ActivatedRoute,) {
  }

  ngOnInit() {

    let agent = getUserAgent();
    this.can_show = agent.split("|")[1];

    this.activatedRoute.queryParams.subscribe(param => {
      this.lease_id = param['lease_id'];
      this.room_id = param['room_id'];
      this.leaseroomid = param['lease_room_id'];
      this.ids = param['ids'];

      this.leaseService.getContractUrl({
        lease_id: this.lease_id,
        service_type: 'CHECKOUT_CONNECT',
        object_id: this.leaseroomid
      })
        .then(data => {
          if (data.image_url) {
            this.path = data.image_url;
            this.showView();
          } else {
            this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '获取pdf异常'));
          }
        });
    });

  }

  /**
   * 展示pdf
   */
  showView() {
    this.iframe = this.path;
  }

  /**
   * 打印
   */
  printFrame() {
    this.pdfView.print();
  }

  goBack() {
    this.router
      .navigate(["../checkout"],
        {
          queryParams: {
            lease_id: this.lease_id,
            ids: this.ids
          },
          relativeTo: this.activatedRoute
        });
  }
}
