<div class="setbox-top">
  <div class="bg-primary">
    <div class="row">
      <div class="col-12 col-md-6 col-xl-7 hei32">
        <i class="fa fa-info-circle">
          换房确认
        </i>
      </div>
    </div>
  </div>

  <div class="setbox" *ngIf="room_change_confirm_resp">
    <p class="text-orange">请核实每个房间的退房物品清单及退房状态，确认无误后，继续操作换房</p>
    <div class="mt-2 mb-5">
      <div *ngIf="isCheckOut">
        原房间：
      </div>

      <div class="over10 mt-1" *ngIf="isCheckOut">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th class="w-125">原房间号</th>
            <th class="w-125" *ngIf="type !== 'INTERNAL_LEASE'" >月均租金</th>
            <th class="w-125" *ngIf="type !== 'INTERNAL_LEASE'">押金</th>
            <th class="w-25">入住人</th>
            <th class="w-125">退房物品交接单</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              {{room_change_confirm_resp.origin_lease_room_info.room_num}}
            </td>
            <td *ngIf="type !== 'INTERNAL_LEASE'">
              {{room_change_confirm_resp.origin_lease_room_info.month_rent}}
            </td>
            <td *ngIf="type !== 'INTERNAL_LEASE'">
              {{room_change_confirm_resp.origin_lease_room_info.room_deposit}}
            </td>
            <td>
              {{renter_name_list.join(", ")}}
            </td>
            <td>
              <ng-container [ngSwitch]="room_change_confirm_resp.origin_lease_room_info.asset_confirm">
                <span *ngSwitchCase="'CONFIRMEDOUT'" class="text-secondary">
                  已设置
                </span>
                <a *ngSwitchCase="'CONFIRMED'" class="text-primary" (click)="goCheckoutInventory()">
                  设置
                </a>
                <a *ngSwitchCase="'UNCONFIRMED'" class="text-primary" (click)="noConfirm()">
                  设置
                </a>
              </ng-container>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div *ngIf="!isCheckOut">
        新房间：
      </div>

      <div class="over10 mt-1" *ngIf="!isCheckOut">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th class="w-125">新房间号</th>
            <th class="w-125" *ngIf="type !== 'INTERNAL_LEASE'">月均租金</th>
            <th class="w-125" *ngIf="type !== 'INTERNAL_LEASE'">押金</th>
            <th class="w-25">入住人</th>
            <th class="w-125">入住物品交接单</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              {{room_change_confirm_resp.new_lease_room_info.room_num}}
            </td>
            <td *ngIf="type !== 'INTERNAL_LEASE'">
              {{room_change_confirm_resp.new_lease_room_info.month_rent}}
            </td>
            <td *ngIf="type !== 'INTERNAL_LEASE'">
              {{room_change_confirm_resp.new_lease_room_info.room_deposit}}
            </td>
            <td>
              {{renter_name_list.join(", ")}}
            </td>
            <td>
              <ng-container [ngSwitch]="room_change_confirm_resp.new_lease_room_info.asset_confirm">
                <a *ngSwitchCase="'UNCONFIRMED'" class="text-primary" (click)="goCheckinInventory()">
                  设置
                </a>
                <span *ngSwitchDefault class="text-secondary">
                  已设置
                </span>
              </ng-container>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="h100" >
        申请类型：
        <ng-container *ngIf="room_change_confirm_resp.room_change_record.apply_type === 'RENTER_APPLY'">
          房客申请
        </ng-container>
        <ng-container *ngIf="room_change_confirm_resp.room_change_record.apply_type !== 'RENTER_APPLY'">
          公寓申请
          <ng-container *ngIf="room_change_confirm_resp.room_change_record.apply_reason" >
            【{{room_change_confirm_resp.room_change_record.apply_reason}}】
          </ng-container>
        </ng-container>
      </div>
      <hr class="divider m-0 w-100">
    </div>

    <div class="mt-5">
      <div class="text-center mb20">
        <button type="button" class="btn btn-primary mr20" (click)="goNext()"
                [disabled]="!(
                (room_change_confirm_resp.origin_lease_room_info.asset_confirm === 'CONFIRMEDOUT' && isCheckOut)
        || (room_change_confirm_resp.new_lease_room_info.asset_confirm !== 'UNCONFIRMED' && !isCheckOut))">下一步
        </button>
        <button type="button" (click)="back()" class="btn btn-secondary">返回</button>
      </div>
    </div>
  </div>
</div>
