import { Component, OnInit, ViewChild } from '@angular/core';
import { SignedService } from '../../../../service/signed/signed.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckoutInventoryConfirmRequest, CheckoutInventoryViewResponse, CheckoutRoomInventoryDTO, CheckoutRoomInventoryRequest, RoomGoodsInfoDTO } from '../../../../model/signed/inventory.model';
import {ModalDirective} from "ngx-bootstrap/modal";
import { MessageModalInfo } from '../../../../model/modal/message-model';
import { WorkModel } from '../../../../model/common/common.model';
import { WorkService } from '../../../../service/common/work.service';

import { Decimal } from "decimal.js";
import { TabletComponent } from '../../tablet/tablet.component';
import { RegularExp } from "../../../../common/util.regexp";
import {BaseInfoService} from "../../../../service/common/base-info.service";
import {UserService} from "../../../../service/user/user.service";
import {AuthService} from "../../../../service/common/auth.service";
import {SignDataRequest, SignOnIpadRequest} from "../../../../model/signed/sign.model";
import {SignDeviceBindingComponent} from "../../../sign-device-binding/sign-device-binding.component";

@Component({
  selector: 'app-checkout-inventory',
  templateUrl: './checkout-inventory.component.html',
  styleUrls: ['./checkout-inventory.component.css']
})
export class CheckoutInventoryComponent implements OnInit {

  @ViewChild('damageModal', { static: true }) damageModal: ModalDirective;
  @ViewChild(TabletComponent, { static: true }) tabletComponent: TabletComponent;
  @ViewChild(SignDeviceBindingComponent, { static: true }) signDeviceBindingComponent:SignDeviceBindingComponent;

  leaseId: number;
  roomId: number;
  ids: any;

  btnDisable = false;

  response: CheckoutInventoryViewResponse;
  today = new Date();

  badGoods: Array<RoomGoodsInfoDTO>;

  totalDamageFee: string = "0.00";

  req: CheckoutInventoryConfirmRequest = new CheckoutInventoryConfirmRequest();

  online = true;
  offset_x: Number = 0;
  offset_y: Number = 0;
  show: string = 'none';
  timer: any;
  isNeedFilter: boolean = false;
  constructor(
    private signedService: SignedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private workService: WorkService,
    private baseInfoService:BaseInfoService,
    private userService:UserService,private authService:AuthService,
  ) {
    this.userService.isHotWater({project_id:this.authService.getProjectId()}).then(data =>{
      this.isNeedFilter = data.hot_water;
    });
  }

  ngOnInit() {
    this.req.checkout_room_inventorydto = new CheckoutRoomInventoryDTO();

    this.activatedRoute.queryParams.subscribe(param => {
      this.leaseId = param["lease_id"];
      this.roomId = param["room_id"];
      this.ids = param["ids"];

      let req = new CheckoutRoomInventoryRequest();
      req.lease_id = this.leaseId;
      req.room_id = this.roomId;
      this.signedService.checkoutInventoryListView(req).then(data => {
        let dataJson = data;

        if (data && data.water_dev && data.water_reading) {
          this.req.checkout_room_inventorydto.water_reading = data.water_reading;
        }
        if (data && data.hot_water_dev && data.hot_water_reading) {
          this.req.checkout_room_inventorydto.hot_water_reading = data.hot_water_reading;
        }
        if (data && data.electricity_dev && data.electricity_reading) {
          this.req.checkout_room_inventorydto.electricity_reading = data.electricity_reading;
        }

        if (dataJson.room_goods_info_category_dtolist) {
          dataJson.room_goods_info_category_dtolist.map((item, index) => {
            item.room_goods_info_dtolist.map((item2, index2) => {
              item2.init_bad = item2.is_bad;
            });
          });
        }

        this.response = dataJson;
      });
    });
  }

  goBack() {
    this.router
      .navigate(["../checkout"],
        {
          queryParams: {
            lease_id: this.leaseId,
            ids: this.ids
          },
          relativeTo: this.activatedRoute
        });
  }

  changeGoodsStatus(roomGoodsInfoDTO: RoomGoodsInfoDTO, $event) {
    roomGoodsInfoDTO.is_bad = roomGoodsInfoDTO.is_bad === "1" ? "0" : "1";
    clearTimeout(this.timer);
    this.show = 'block';
    this.offset_y = $($event.target).parent().position().top + $($event.target).parent().height() + 10;
    this.offset_x = $($event.target).parent().position().left + (($($event.target).parent().width() - 100) / 2);
    this.timer = setTimeout(() => {
      this.show = 'none';
    }, 1000);
  }

  outlineCheck() {

    if (this.btnDisable) {
      return;
    } else {

      // 直接确认
      let messageModalInfo = new MessageModalInfo();
      messageModalInfo.type = 2;
      messageModalInfo.title = "交接确认";
      messageModalInfo.operationMessage = '房间信息编辑成功!';
      messageModalInfo.html = `
      <div>确认打印并线下已签署纸质物品交接单？</div>
    `;
      messageModalInfo.callback.subscribe(() => {
        this.req.offline = true;
        this.req.sign = "";
        this.req.checkout_room_inventorydto.room_id = this.roomId;
        this.req.lease_id = this.leaseId;
        this.req.damage_goods_list = this.badGoods;

        this.btnDisable = true;

        this.signedService.checkoutInventoryListConfirm(this.req).then(data => {

          this.btnDisable = false;

          this.workService.event_bus.emit(new WorkModel(this.workService.type.TAB_CLOSE_SELF));

          this.router
            .navigate(["../inventoryview"],
              {
                queryParams: {
                  lease_id: this.leaseId,
                  room_id: this.roomId,
                  lease_room_id: this.response.lease_room_info_dto.lease_room_id,
                  ids: this.ids
                },
                relativeTo: this.activatedRoute
              });
        }).catch(() => {
          this.btnDisable = false;
        });
      });
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
    }

  }

  // 保存一个退房清单 - 线下确认
  saveOneOutLine() {
    this.online = false;
    this.lossConfirm();
  }

  // 保存一个退房清单 - 线上确认
  saveOneInLine() {
    this.online = true;
    this.lossConfirm();
  }

  lossConfirm() {
    let arr: Array<RoomGoodsInfoDTO> = [];
    if (this.response.room_goods_info_category_dtolist) {
      this.response.room_goods_info_category_dtolist.forEach(cate => {
        arr = arr.concat(cate.room_goods_info_dtolist);
      });
      this.badGoods = arr.filter(goods => goods.is_bad === "1");
    }

    // 如果有损坏
    if (this.badGoods && this.badGoods.length > 0) {
      this.damageModal.show();
    } else {
      // 确认没有物品赔损
      let messageModalInfo = new MessageModalInfo();
      messageModalInfo.title = "赔损确认";
      messageModalInfo.cancel = true;
      messageModalInfo.operationMessage = '确认没有物品赔损？';
      messageModalInfo.callback.subscribe(() => {
        if (this.online) {
          //  签名
          if(this.isPad()){
            this.tabletComponent.tabletShow();
          }else{
            this.signOnIpad();
          }
        } else {
          this.outlineCheck();
        }
      });
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
    }

  }

  confirmDamage() {

    if (this.badGoods) {
      let error = false;
      this.badGoods.forEach(good => {
        if (good.compensate_price) {
          error = this.hasError(good.compensate_price);
        }
      });
      if (error) {
        return;
      }
    }

    if (this.online) {
      //  签名
      if(this.isPad()){
        this.tabletComponent.tabletShow();
      }else{
        this.signOnIpad();
      }
    } else {
      this.outlineCheck();
    }

  }

  calc() {
    if (this.badGoods) {
      let total = new Decimal(0);
      this.badGoods.forEach(goods => {
        if (goods.compensate_price) {
          total = total.add(new Decimal(goods.compensate_price));
        }
      });
      this.totalDamageFee = total.toString();
    }
  }

  commitSign(imgStr: string) {

    if (this.btnDisable) {
      return;
    } else {

      //发送签名字符串，上传
      this.req.checkout_room_inventorydto.room_id = this.roomId;
      this.req.lease_id = this.leaseId;
      this.req.sign = imgStr;
      this.req.damage_goods_list = this.badGoods;

      this.btnDisable = true;

      this.signedService.checkoutInventoryListConfirm(this.req).then(name => {

        this.btnDisable = false;

        let next = null;
        if (typeof this.ids !== 'string') {
          let idlist = this.ids;
          if(idlist) {
            for (let i = 0; i < idlist.length; i++) {
              if (idlist[i] == (this.roomId + "")) {
                if (i + 1 < idlist.length) {
                  next = idlist[i + 1];
                }
              }
            }
          }
        }

        // 提交成功
        let messageModalInfo = new MessageModalInfo();
        messageModalInfo.type = 2;
        messageModalInfo.title = "赔损确认";
        if (next) {
          messageModalInfo.okBtn = "确认下一间";
          messageModalInfo.html = `
      <h2 class="text-warning text-center">${name}</h2>
       <div class="text-center">物品赔损确认成功！</div>
       <div class="text-center">您可返回租约页面继续操作退房，也可关闭弹窗查看并打印交接单！</div>
      `;
        } else {
          messageModalInfo.okBtn = "返回退房";
          messageModalInfo.html = `
      <h2 class="text-warning text-center">${name}</h2>
       <div class="text-center">物品赔损确认成功！</div>
       <div class="text-center">您可返回租约页面继续操作退房，也可关闭弹窗查看并打印交接单！</div>
      `;
        }
        messageModalInfo.callback.subscribe(() => {
          if (next) {
            this.damageModal.hide();
            // 下一间房 清空数据
            this.router
              .navigate(["../inventorylist"],
                {
                  queryParams: {
                    lease_id: this.leaseId,
                    room_id: next,
                    ids: this.ids
                  },
                  relativeTo: this.activatedRoute
                });
          } else {
            this.router
              .navigate(["../checkout"],
                {
                  queryParams: {
                    lease_id: this.leaseId,
                    ids: this.ids
                  },
                  relativeTo: this.activatedRoute
                });
          }
        });
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
      }).catch((e) => {
        this.btnDisable = false;
      });
    }

  }

  hasError(str) {
    if (str) {
      return !RegularExp.numberPointTwo.test(str);
    } else {
      return false;
    }
  }

  // 在ipad上签名
  signOnIpad(){
    // 页面操作数据
    let request_data = Object.assign({}, this.req);
    request_data.checkout_room_inventorydto.room_id = this.roomId;
    request_data.lease_id = this.leaseId;
    request_data.sign = "";
    request_data.damage_goods_list = this.badGoods;

    let request = new SignOnIpadRequest();
    request.sign_router_link_type = 'INVENTORY_LIST';
    request.router_link = this.router.url;
    request.response_data = this.response;
    request.operate_data = request_data;
    request.computer_no = localStorage.getItem('PC_UUID');
    let signDataRequest = new SignDataRequest();
    signDataRequest.lease_id = this.leaseId;
    signDataRequest.room_id = this.roomId;
    signDataRequest.lease_room_id = this.response.lease_room_info_dto.lease_room_id;
    request.sign_data_request = signDataRequest;
    /*this.signedService.signOnIpad(request).then(data => {
      console.info(data);
    });*/
    // 打开连接弹窗
   
    
    this.signDeviceBindingComponent.openModel(request,1);
  }

  // 判断当前设备是否是ipad
  isPad(): boolean{
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone",
      "SymbianOS", "Windows Phone",
      "iPad", "iPod"];
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        return true;
      }
    }
    return false;
  }
}
