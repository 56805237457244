<div class="setbox-top m-3">
  <div class="bg-primary bg-green ">
    <div class="row">
      <div class="col-12 col-md-6 col-xl-7">
        <i class="fa fa-info-circle"></i>合同房间退房
      </div>
    </div>
  </div>
  <div class="row ">
    <div class="col-12 col-lg-12">
      <div class="yellow p-3">
        <h5 class="font-weight-bold">合同签约主体</h5>
        <div class="row padd-l-15">
          <div class="col-12 col-md-3 mb10" *ngIf="leaseDetailResponse.lease_subject_type =='PERSON'">
            签约人：{{leaseDetailResponse.contact_name}}
          </div>
          <div class="col-12 col-md-3 mb10" *ngIf="leaseDetailResponse.lease_subject_type == 'PERSON'">
            证件类型：{{leaseDetailResponse.cret_type == 'PASSPORT' ? '护照' : '身份证'}}
          </div>
          <div class="col-12 col-md-3 mb10" *ngIf="leaseDetailResponse.lease_subject_type == 'PERSON'">
            证件号码：{{leaseDetailResponse.cret_num}}
          </div>
          <div class="col-12 col-md-3 mb10" *ngIf="leaseDetailResponse.lease_subject_type == 'COMPANY'">
            公司名称：{{leaseDetailResponse.company_name}}
          </div>
          <div class="col-12 col-md-3 mb10" *ngIf="leaseDetailResponse.lease_subject_type == 'COMPANY'">
            联系人：{{leaseDetailResponse.contact_name}}
          </div>
          <div class="col-12 col-md-3 mb10" *ngIf="leaseDetailResponse.lease_subject_type == 'COMPANY'">
            联系电话：{{leaseDetailResponse.contact_phone}}
          </div>
          <div class="col-12 col-md-3 mb10">
            合同租期：{{leaseDetailResponse.start_time| date:'yyyy.MM.dd'}}
            -
            {{leaseDetailResponse.end_time| date:'yyyy.MM.dd'}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-check-out-steps [stepsData]="stepsData" [active]='active'></app-check-out-steps>
  <hr>

  <div *ngIf="active==2">
    <div class="row">
      <div class="col-6">请点击需要退的房间： <span class=""><input type="checkbox" [(ngModel)]="selectAll" (change)="selectAllChange()"> 全选</span></div>
      <div class="col-6 isRight">
        <span class="greenBox"></span>
        <span class="spanPeople">无入住人</span>
        <span class=" greenBox blueBox"></span>
        <span class="spanPeople">有入住人</span>
      </div>
    </div>
    <div *ngFor="let item of roomList;let j = index">
      <div class="soRoomBox " [ngClass]="{'bgColor': item.renter_num>0}" (click)="onSave(item)">
        <div class="mb-3 text-truncate" [title]="item.room_name">{{item.room_name}}</div>
        <div>
          <img src="../../../../../assets/img/u8431.png" alt="">
          <span>{{item.renter_num}}</span>
        </div>
        <div *ngIf="item.check">
          <div class="mask">
            <img src="../../../../../assets/img/u3877.png" alt="" class="toImg">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isModel">没有房间可退！</div>
  <div *ngIf="active==3">
    <app-check-out-confirm [roomIdList]="roomIdList" [paymentleaseId]="paymentleaseId"
                           [leaseDetailResponse]="leaseDetailResponse"
                           [isLease_room_id]="isLease_room_id" #checkOutConfirm></app-check-out-confirm>
  </div>
  <div *ngIf="active==4">
    <app-check-out-information [roomIdList]="roomIdList" [paymentleaseId]="paymentleaseId"
                               [isLease_room_id]="isLease_room_id" (checkedBack)="checkedBack($event)"
                               (checkBack)="checkBack($event)"></app-check-out-information>
  </div>
  <div *ngIf="active==5">
    <div class="accomplish">
      <div class="mt-2 font-weight-bold">【{{roomNumber}}】退房成功</div>
      <div class="mt-2 mb-2 font-weight-bold">退房日期：{{newDate|date:'yyyy-MM-dd'}}</div>
<!--      <button class="btn btnColor " (click)="printCheckoutInvetory()">打印退房物品交接单</button>-->

    </div>

  </div>
  <div class="clear"></div>
  <hr class="mt-5">

  <div class="text-center" *ngIf="active==2">
    <button class="btn" (click)="btnNext()" *ngIf="!isBtn">确认并继续</button>
    <button class="btn colorBad" disabled *ngIf="isBtn">确认并继续</button>
  </div>

  <div class="text-center" *ngIf="active==3">
    <button class="btn" (click)="btnNext()">下一步</button>
  </div>
  <div *ngIf="active==4" class="text-center">
    <button class="btn btnColor" (click)="goSettingAsset()" printSectionId="printDiv" ngxPrint>打印交接单</button>
    <button class="btn" (click)="alertTips()">线下确认</button>
    <button class="btn sizeColor" *ngIf="isPad()" (click)="saveOneInLine()">线上签字</button>
    <button class="btn sizeColor" *ngIf="!isPad()" (click)="saveOneInLine()">同步pad签字</button>
  </div>
  <div *ngIf="active==5" class="text-center">
    <button class="btn bgblue" *ngIf="!isLease_room_id" (click)="continueRoom(1)">继续退下一间</button><!---->
    <button class="btn" (click)="continueRoom(2)">结束退房</button>
    <button class="btn sizeColor" (click)="goFinanceCheckout()">立即结算费用</button>

  </div>
  <div class="goodslist-body isDisplay" id="printDiv" #printDiv *ngIf="checkOutInformationNewdata">
    <div *ngFor="let response of checkOutInformationNewdata.checkout_inventory_list_dtos; let it=index">
      <table border="1px soild #f2f2f2" width="100%"  style=" border-collapse: collapse;text-align: center;">
        <tbody>
        <tr>
          <td colspan="10">
            <div class="table-title">

              公寓物品交接清单<span>【退房】</span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">公寓门店</td>
          <td colspan="3">{{roomName}}</td>
          <td colspan="2">房号</td>
          <td colspan="3">{{response.room_info_dto.room_name}}</td>
        </tr>
        <tr>
          <td [attr.colspan]="isNeedFilter ? 1:2">水表读数</td>
          <td [attr.colspan]="isNeedFilter ? 2:3">
            <ng-container *ngIf="response.water_reading">
              {{response?.water_reading}}
              <span class="gray" *ngIf="response?.water_reading_time">（{{response.water_reading_time|date:'yyyy-MM-dd HH:mm'}}）</span>
            </ng-container>
            <!-- <ng-container *ngIf="response.water_dev&&!response.water_reading">
              <input type="text" [(ngModel)]="req.checkout_room_inventorydto.water_reading">
            </ng-container>
            <ng-container *ngIf="!response.water_dev&&response.need_water=='YES'">
              <input type="text" [(ngModel)]="req.checkout_room_inventorydto.water_reading">
            </ng-container> -->
            <ng-container *ngIf="!response.water_dev&&response.need_water=='NO'">
              <span class="org">此房型设置了不需要抄表收费</span>
            </ng-container>
          </td>

          <td [attr.colspan]="isNeedFilter ? 1:2" >热水表读数</td>
          <td [attr.colspan]="isNeedFilter ? 2:3" >
            <ng-container *ngIf="response?.hot_water_reading">
              {{response?.hot_water_reading}}
              <span class="gray" *ngIf="response?.hot_water_reading_time">（{{response?.hot_water_reading_time|date:'yyyy-MM-dd HH:mm'}}）</span>
            </ng-container>
            <!-- <ng-container *ngIf="response?.hot_water_dev&&!response?.hot_water_reading">
              <input type="text" [(ngModel)]="req.checkout_room_inventorydto.hot_water_reading">
            </ng-container>
            <ng-container *ngIf="!response?.hot_water_dev&&response?.need_hot_water=='YES'">
              <input type="text" [(ngModel)]="req.checkout_room_inventorydto.hot_water_reading">
            </ng-container> -->
            <ng-container *ngIf="!response?.hot_water_dev&&response?.need_hot_water=='NO'">
              <span class="org">此房型设置了不需要抄表收费</span>
            </ng-container>
          </td>

          <td [attr.colspan]="isNeedFilter ? 1:2">电表读数</td>
          <td [attr.colspan]="isNeedFilter ? 3:3">
            <ng-container *ngIf="response?.electricity_reading">
              {{response?.electricity_reading}}
              <span class="gray" *ngIf="response?.electricity_reading_time">（{{response?.electricity_reading_time|date:'yyyy-MM-dd HH:mm'}}）</span>
            </ng-container>
            <!-- <ng-container *ngIf="response?.electricity_dev&&!response?.electricity_reading">
              <input type="text" [(ngModel)]="req.checkout_room_inventorydto.electricity_reading">
            </ng-container>
            <ng-container *ngIf="!response?.electricity_dev&&response?.need_electricity=='YES'">
              <input type="text" [(ngModel)]="req.checkout_room_inventorydto.electricity_reading">
            </ng-container> -->
            <ng-container *ngIf="!response?.electricity_dev&&response?.need_electricity=='NO'">
              <span class="org">此房型设置了不需要抄表收费</span>
            </ng-container>
          </td>
        </tr>
        <tr>
          <th colspan="10">
            <div class="table-title-second">
              房屋标准配置清单及价格
            </div>
          </th>
        </tr>
        <tr>
          <td colspan="2">部位</td>
          <td colspan="1">物品名称</td>
          <td colspan="1">单价</td>

          <td colspan="1">数量</td>
          <td colspan="1">是否损坏</td>
          <td colspan="1">物品名称</td>
          <td colspan="1">单价</td>

          <td colspan="1">数量</td>
          <td colspan="1">是否损坏</td>
        </tr>


        <ng-container *ngIf="response && response?.room_good_relation_dto">
          <ng-container *ngFor="let category of response.room_good_relation_dto.room_goods_info_category_dtolist">
            <ng-container *ngFor="let item of category.room_goods_info_dtolist;let i=index">
              <tr *ngIf="i < category.room_goods_info_dtolist.length/2">
                <ng-container *ngIf="i==0">
                  <td colspan="2"
                      [attr.rowspan]="category.room_goods_info_dtolist.length%2==0?category.room_goods_info_dtolist.length/2:(category.room_goods_info_dtolist.length-1)/2+1">
                    <span class="black">{{category.category_name}}</span>
                  </td>
                </ng-container>
                <td>{{category.room_goods_info_dtolist[2 * i].good_name}}</td>
                <td>
                  <p style="text-align: right">
                    {{category.room_goods_info_dtolist[2 * i].price | number}}
                    {{category.room_goods_info_dtolist[2 * i].unit}}
                  </p>
                </td>
                <td>{{category.room_goods_info_dtolist[2 * i].quantity}}</td>
                <td *ngIf="category.room_goods_info_dtolist[2*i].is_bad=='1'; else youLose">
                  <div>部分损坏{{category.room_goods_info_dtolist[2 * i].partical_damage || 0}}件;</div>
                  <div>全部损坏{{category.room_goods_info_dtolist[2 * i].full_damage || 0}}件;</div>
                  <div>丢失{{category.room_goods_info_dtolist[2 * i].lose || 0}}件</div>
                </td>
                <ng-template #youLose>
                  <td>【否】</td>
                </ng-template>
                <ng-container *ngIf="2*i+1 < category.room_goods_info_dtolist.length">
                  <td>{{category.room_goods_info_dtolist[2 * i + 1].good_name}}</td>
                  <td>
                    <p style="text-align: right">
                      {{category.room_goods_info_dtolist[2 * i + 1].price | number}}
                      {{category.room_goods_info_dtolist[2 * i + 1].unit}}
                    </p>
                  </td>
                  <td>{{category.room_goods_info_dtolist[2 * i + 1].quantity}}</td>
                  <td *ngIf="category.room_goods_info_dtolist[2*i+1].is_bad=='1'; else isLose">
                    <div>部分损坏{{category.room_goods_info_dtolist[2 * i + 1].partical_damage || 0}}件;</div>
                    <div>全部损坏{{category.room_goods_info_dtolist[2 * i + 1].full_damage || 0}}件;</div>
                    <div>丢失{{category.room_goods_info_dtolist[2 * i + 1].lose || 0}}件</div>
                  </td>
                  <ng-template #isLose>
                    <td>【否】</td>
                  </ng-template>
                </ng-container>
                <ng-container *ngIf="2*i+1 == category.room_goods_info_dtolist.length">
                  <td colspan="4"></td>
                </ng-container>
              </tr>

            </ng-container>
          </ng-container>

        </ng-container>

        <ng-container
          *ngIf="!response || !response?.room_good_relation_dto || response?.room_good_relation_dto.room_goods_info_category_dtolist.length==0">
          <tr>
            <td colspan="10">无</td>
          </tr>
        </ng-container>
        <tr>
          <td colspan="2"><p style="height: 50px;line-height: 50px;">房客签字</p></td>
          <td colspan="1" *ngIf="signName" style="text-align: center;">
            <p style="height: 50px;line-height: 50px;width:100px;text-align: center;">
              <img [src]="signName" alt="" style="width: 100px;height: 50px;">
            </p>
          </td>
          <td colspan="2" *ngIf="!signName">
            <p style="height: 50px;line-height: 50px;width:100px;"></p>
          </td>
          <td colspan="1"><p style="height: 50px;line-height: 50px;">接待人</p></td>
          <td colspan="2"><p style="height: 50px;line-height: 50px;">{{manageName}}</p></td>
          <td colspan="1"><p style="height: 50px;line-height: 50px;">确认日期</p></td>
          <td colspan="2"><p style="height: 50px;line-height: 50px;">{{newDate|date:'yyyy-MM-dd'}}</p></td>
        </tr>
        </tbody>
      </table>
      <div style="page-break-after:always;"
           *ngIf="it != checkOutInformationNewdata.checkout_inventory_list_dtos.length - 1 ">
      </div>
    </div>

  </div>
  <!-- 写字板 -->
  <app-tablet [title]="'请入住房客在下方写字板内签署姓名'" (commitSign)="commitSign($event)"></app-tablet>
  <app-sign-device-binding #signDeviceBindingComponent (signInventoryChange)="goStep5()"></app-sign-device-binding>
</div>
