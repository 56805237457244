import { ProjectUnitDTO } from '../lease/lease.model';
import {HotelProjectDTO} from "../project/project.model";
import { RoomInfoDTO } from './room.model';

/**
 * @description: 项目公寓
 * @Author: lubingbin
 * @Date: 2018/3/5
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-08-17 16:30:17
 */
export class HotelProject extends HotelProjectDTO {
  create_time?: number;
  // project_status: string;
  company_id?: number;
  //@Remark("是否需要签署租赁合同(1: 需要, 0: 不需要)")
  // need_lease_contract?: number;
  //@Remark("是否需要上传入住人员证件照片(1: 需要, 0: 不需要)")
  // need_renter_pic?: number;
  //@Remark("是否需要入住人员在线签署入住公约(1: 需要, 0: 不需要)")
  // need_online_sign_convention?: number;
  //@Remark("是否需要启用电子合同(1: 需要, 0: 不需要)")
  // need_use_electronic_contract?: number;
  //@Remark("是否需要签署并确认房间内的物品清单")
  // need_room_goods_list?: number;

  // //智能电表 NONE 无需抄表 SMART 智能电表 MANUAL 手动抄表
  // ele_type: string;
  // //付费方式 PREPAY 预付费 BILL 后付费
  // ele_fee_type: string;
  // //冷水表 NONE 无需抄表 SMART 智能电表 MANUAL 手动抄表
  // cold_water_type: string;
  // //付费方式 PREPAY 预付费 BILL 后付费
  // cold_water_fee_type: string;
  // //热水表 NONE 无需抄表 SMART 智能电表 MANUAL 手动抄表
  // hot_water_type: string;
  // //付费方式 PREPAY 预付费 BILL 后付费
  // hot_water_fee_type: string;
  // // 智能门锁: 支持智能门锁 SMART; 不支持: NONE
  // door_lock_type: string;

  //管理公司名称
  cname:string;
  ischeck:boolean;
}

// 项目楼栋（单元）
export class ProjectUnitModel {
  id?: number;
  project_id?: number;
  unit_name?: string;
  project_name?: string;
  total_floor?: string;
}

// 单元数组
export class ProjectUnits {
  project_units: Array<ProjectUnitModel>;
}

export class UserProjectDTO {
  id?: number;
  project_type?: string;
  project_name?: string;
  project_address?: string;
  latitude?: number;
  longitude?: number;
  city_id?: number;
  create_time?: number;
  last_modify_time?: number;
  project_status?: string;
}

export class RoomDetailResponse {
  project_unit?: ProjectUnitDTO;
  hotel_project?: UserProjectDTO;
  room_info ?: RoomInfoDTO;
}

export class ProjectGroupDTO {
  group_id?: number;
  parent_group_id?: number;
  project_group_name?: string;
  city_id?: number;

  project_dtolist?: Array<HotelProject> ;
  child_group_list?: Array<ProjectGroupDTO>;
}
