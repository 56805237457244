<div class="setbox-top" *ngIf="step===1">
  <div class="bg-primary bg-green"><i class="fa fa-info-circle"></i> 已退房间费用结算</div>
  <div class="setbox">
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-md-6 col-xl-4">
          <div class="col-12">
            <div class="row">
              <label class="col-4 btn-label">退房日期</label>
              <div class="col-4 p-0">
                <my-date-picker placeholder="年-月-日" [options]="myDatePickerOptions"
                                [(ngModel)]="endTime">
                </my-date-picker>
              </div>
              <div class="col-4">
                <button class="btn btn-info" (click)="search(true)"><i class="fa fa-search"></i>搜索</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-2">
          <div class="">
            <label class="btn-label">请选择需要结算费用的房间：</label>
          </div>
          <div class="maxh-20 mt-2 col-12">
            <div *ngIf="resp">

              <div class="col-12 border py-1 minh-4"
                   *ngFor="let item of resp.checkout_finance_room_list_view_dtolist">
                <div class="row">
                  <div style="width: 300px;padding-left: 10px;">
                    <div *ngIf="item.renter_view_list && item.checkout_time"><sapn style="color: red">退宿确认后方可操作退房结算</sapn></div>
                    <input type="checkbox" [value]="item.lease_room_id" [disabled]="item.checkout_id || item.renter_view_list"
                           [(ngModel)]="item.checked" (change)="itemClick(item)">
                    {{item.room_name}}<ng-template [ngIf]="item.checkout_time">【退房日期：<span
                    class="text-danger font-xl">{{item.checkout_time|date:'yyyy.MM.dd'}}</span>】</ng-template>
                  </div>
                  <div style="width: 500px" *ngIf="item.checkout_id">
                    <div class="">退房单客户签字确认中</div>
                    <div class="">费用结算单号 <span class="text-blue">{{item.checkout_num}}</span></div>
                  </div>
                  <div style="padding-left: 40px;" *ngIf="item.renter_view_list">
                    <div *ngFor="let rc of item.renter_view_list" class="">
                      <div class="row">
                        <div style="width: 100px;margin-top: 5px;" ><input [disabled]="rc.renter_checkout_number" [checked]="renterIds.indexOf(rc.renter_info_id) >= 0" style="margin-right: 5px;" type="checkbox" [value]="rc.renter_info_id"
                                                                            (change)="rcClick(rc,$event,item.renter_view_list)">{{rc.renter_name}}</div>
                        <div style="width: 200px">
                          【退宿日期：<span
                          class="text-danger font-xl">{{rc.checkout_time|date:'yyyy.MM.dd'}}</span>】
                        </div>

                        <div *ngIf="rc.renter_checkout_number">
                          <div>{{rc.show_text}}</div>
                          <div style="cursor:pointer" (click)="goRenterCheckout(rc.renter_checkout_id)">退宿单号<span style="color: #00aced">{{rc.renter_checkout_number}}</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="col-12 mt-2">
            <label *ngIf="roomCheckout"><input type="checkbox" [(ngModel)]="selectAll" (change)="selectAllClick()">全选</label>
            <label *ngIf="renterCheckout"><input type="checkbox" [(ngModel)]="rcSelectAll" (change)="selectRenterAllClick($event)">全选</label>
            <label *ngIf="(renterIds.length == 0 || roomCheckout) && !renterCheckout" class="mx-2">已选：<span class="text-danger">{{selectCount}}</span>间房</label>
            <label *ngIf="(renterIds.length > 0 || renterCheckout) && !roomCheckout " class="mx-2">已选：<span class="text-danger">{{renterIds.length}}</span>个住客</label>
          </div>

        </div>
      </div>


    </div>
    <hr>
    <div class="text-center">
      <button *ngIf="selectCount>0 || renterIds.length > 0" type="button" class="btn btn-primary" (click)="goStep2()">确认选择</button>
      <button *ngIf="selectCount===0 && renterIds.length === 0 " type="button" class="btn btn-secondary " disabled>确认选择</button>
    </div>
  </div>
</div>


<div class="setbox-top" *ngIf="step===2">
  <div class="bg-primary bg-green"><i class="fa fa-info-circle"></i> 已退房间费用结算</div>
  <div class="setbox">
    <div class="col-12">
      <table class="table table-bordered">
        <thead>
        <tr class="bg-gray1">
          <th colspan="4" class="text-left">一、退房状态确认：</th>
        </tr>
        <tr class="bg-gray1">
          <th class="w-15">退房日期</th>
          <th class="w-15">房间号</th>
          <th class="w-35">退房状态
            <span class="text-success text-underline pointer"
                  [popover]="popTemplate" container="body" placement="top"
            ><i class="fa fa-question-circle"></i>状态说明</span>
            <ng-template #popTemplate>
              <p>正常退房：已支付金额的押金全额退还；</p>
              <p>违约退房：如有已支付的剩余房费可正常退还，但会产生违约金，违约金等于押金账单金额；</p>
              <p>提前退房：已支付金额的押金全额退还，但已支付房费如有剩余将不予退还；</p>
              <p>转租退房：正常结算应退应收，但需额外收取转租费，完成转租退房后可至合同详情页头部按钮区域操作【转租签约】。</p>
            </ng-template>
          </th>
          <th class="w-35">违约金/预估滞纳金/转租费</th>
        </tr>
        </thead>
        <tbody *ngIf="resp2">
        <tr *ngFor="let item of resp2.checkout_date_room_dtos;let index=index">
          <td [ngClass]="{'text-danger':item.checkout_type!=='NORMAL'}">{{item.checkout_time|date:'yyyy.MM.dd'}}</td>
          <td>
            <ng-container *ngFor="let name of item.room_names;let i=index">
              {{name}}
              <ng-container *ngIf="i!=item.room_names.length-1">；</ng-container>
            </ng-container>
          </td>
          <td>
            <ng-container *ngIf="item.checkout_type==='NORMAL'">
              <input type="radio" readonly checked>正常
            </ng-container>
            <ng-container *ngIf="item.checkout_type==='BREAK'||item.checkout_type==='BEFORE'">
              <div class="col-12">
                <div class="row">
                  <div class="col-6" *ngIf="respNoRefresh.lease_info.lease_step !== 'INTERNAL_LEASE'" >
                    <label class="pointer">
                      <input type="radio" [name]="'checkout_type'+index" [(ngModel)]="item.checkout_type" (change)="refreshFee()" value="BREAK">违约
                    </label>
                  </div>
                  <div class="col-6" *ngIf="item.show_before">
                    <label class="pointer">
                      <input type="radio" [name]="'checkout_type'+index" [(ngModel)]="item.checkout_type" (change)="refreshFee()" value="BEFORE">提前
                    </label>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="item.checkout_type==='OVERDUE'">
              <input type="radio" readonly checked>逾期
            </ng-container>
            <ng-container *ngIf="item.checkout_type==='SUBLEASE'">
              <input type="radio" readonly checked>转租
            </ng-container>
          </td>
          <td>
            <ng-container *ngIf="calculTotal(item)===0">
              无
            </ng-container>
            <ng-container *ngIf="item.checkout_type==='BEFORE'">
              违约金：{{item.breaking_total}}元（剩余房费{{item.not_payback_rent_total}}元不退）
            </ng-container>
            <ng-container *ngIf="item.checkout_type==='BREAK'">
              违约金：{{item.breaking_total}}元
            </ng-container>
            <p *ngIf="item.checkout_type==='SUBLEASE'">
              转租费：{{item.sublease_total}}元
            </p>
            <p *ngIf="+item.overdue_total>0">
              预估滞纳金：{{item.overdue_total}}元
            </p>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="mt-2">
        <div class="row">
          <div class="col-4 font-weight-bold" *ngIf=" +(resp2.breaking_total) !== 0">违约金总计（元）：<span class="text-danger">{{resp2.breaking_total}}</span></div>
          <div class="col-4 font-weight-bold" *ngIf="+(resp2.overdue_total) !== 0">预估滞纳金总计（元）：<span class="text-danger">{{resp2.overdue_total}}</span></div>
          <div class="col-4 font-weight-bold" *ngIf="+(resp2.sublease_total) !== 0">转租费总计（元）：<span class="text-danger">{{resp2.sublease_total}}</span></div>
        </div>
      </div>
      <div class="mt-2" *ngIf="respNoRefresh.lease_info.lease_step !== 'INTERNAL_LEASE'">
        <div class="">
          <span *ngIf="showNoOverdue" ><input type="checkbox" [(ngModel)]="noOverdue" (change)="getAuditList()">不收滞纳金</span>
          <span class="ml-2" *ngIf="showNoBreaking" ><input type="checkbox" [(ngModel)]="noBreaking" (change)="getAuditList()">不收违约金</span>
        </div>
        <div class="mt-2">
          <div class="col-12" *ngIf="(noOverdue||noBreaking)">
            <div class="row">
              <div class="w120 d-inline-block">
                短信审核:
              </div>

              <div class="" *ngIf="!validCode">
                <div class="w200 d-inline-block ml-1 valign-top">
                  <select class="w-100" [(ngModel)]="cnof.phone">
                    <option value="">请选择</option>
                    <option *ngFor="let man of auditList" [value]="man.user_phone">{{man.user_name}}({{man.user_phone}})
                    </option>
                  </select>
                  <div *ngIf="(noOverdue||noBreaking)&&!cnof.phone" class="text-danger">审核用户不能为空</div>
                </div>
                <div class="w200 d-inline-block ml-1 valign-top">
                  <button class="btn btn-primary ml-1" [disabled]="gray_btn" (click)="getCode()">{{codeBtnStr}}</button>
                </div>
                <div class="w200 d-inline-block ml-1 valign-top">
                  <input class="w-100" type="text" placeholder="请输入短信验证码" maxlength="4" [(ngModel)]="cnof.code">
                  <div *ngIf="!cnof.code" class="text-danger">验证码不能为空</div>
                </div>
                <div class="w200 d-inline-block ml-1 valign-top">
                  <button class="btn btn-info" (click)="checkCode()">验证</button>
                  <div *ngIf="!validCode" class="text-danger">请验证</div>
                </div>
              </div>
              <div class="" *ngIf="validCode">
                <div class="text-green btn-label"><i class="fa fa-check-circle-o"></i> 验证通过</div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="w120 d-inline-block valign-top">
                备注原因:
              </div>
              <div class="w200 d-inline-block ml-1 valign-top">
                <select class="w-100" [(ngModel)]="overdueType">
                  <option value="" disabled>请选择</option>
                  <option value="客户协商">客户协商</option>
                  <option value="无法收取">无法收取</option>
                  <option value="公寓原因">公寓原因</option>
                </select>
                <div *ngIf="!overdueType" class="text-danger">请选择原因</div>
              </div>
              <div class="w300 d-inline-block ml-1 valign-top">
                <input class="w-100" [(ngModel)]="overdueContent" type="text" placeholder="请描述具体原因，限30字" maxlength="30">
                <div *ngIf="!overdueContent" class="text-danger">具体原因不能为空</div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <hr>
      <!--      2 -->
      <div class="mt-2">
        <table class="table table-bordered">
          <thead>
          <tr class="bg-gray1">
            <th colspan="5" class="text-left">二、退房物品破损确认赔偿金额：</th>
          </tr>
          <tr class="bg-gray1">
            <th>房间号</th>
            <th>物品名称</th>
            <th>单价</th>
            <th>损坏情况</th>
            <th>赔偿金额（元）</th>
          </tr>
          </thead>
          <tbody *ngIf="respNoRefresh.damage_goods_list">
          <tr *ngFor="let good of respNoRefresh.damage_goods_list">
            <td>{{good.room_name}}</td>
            <td>{{good.good_name}}</td>
            <td>{{good.price}}{{good.unit}}</td>
            <td>
              <p class="text-danger" *ngIf="good.partical_damage">【部分损坏】{{good.partical_damage}}件</p>
              <p class="text-danger" *ngIf="good.full_damage">【全部损坏】{{good.full_damage}}件</p>
              <p class="text-danger" *ngIf="good.lose">【丢失】{{good.lose}}件</p>
            </td>
            <td>
              <input type="text" [(ngModel)]="good.compensate_price" (change)="calculDamage()">
              <p class="text-danger" *ngIf="!validPrice(good.compensate_price) && isShowNumber">*请输入至多两位小数</p>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="mt-2">
          <div class="row">
            <div class="col-12 font-weight-bold">赔偿金额总计（元）：<span class="text-danger">{{damageTotal}}</span></div>
          </div>
        </div>
      </div>
      <!--     3 -->
      <hr>
      <div class="mt-2">
        <div class="">
          <button class="btn btn-primary" (click)="refreshFee()"><i class="fa fa-refresh"></i>刷新费用</button>
          <button class="btn btn-outline-primary mx-2" (click)="modal1.show()">查看房间费用明细</button>
        </div>
        <div class="mt-2">
          <table class="table table-bordered">
            <thead>
            <tr class="bg-gray1">
              <th colspan="4" class="text-left">三、费用结算预览（汇总）：</th>
            </tr>
            <tr class="bg-gray1">
              <th>房间号</th>
              <th>应收金额（元）</th>
              <th>应退合计（元）</th>
              <th>合计（元）</th>
            </tr>
            </thead>
            <tbody *ngIf="resp2">
            <tr *ngFor="let item of resp2.checkout_room_dtolist;let index=index">
              <td>{{item.check_out_room_info_dto.room_name}}</td>
              <td>{{item.check_out_room_info_dto.receivable_total}}</td>
              <td>{{item.check_out_room_info_dto.retreated_total}}</td>
              <td>{{item.check_out_room_info_dto.amount_total}}</td>
            </tr>
            </tbody>
          </table>
          <div class="mt-2">
            <div class="row">
              <div class="col-12 font-weight-bold mb-4" *ngIf="resp2?.company_prepay_total">企业预付费余额合计：<span class="text-danger">{{resp2?.company_prepay_total}}</span></div>
              <div class="col-12 font-weight-bold font-xl">费用结算合计：<span class="text-danger font-xl">{{resp2.total}}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="text-center">
      <button type="button" class="btn btn-primary mr-3" (click)="goStep3()">确认费用</button>
    </div>
  </div>
</div>


<div class="setbox-top" *ngIf="step===3">
  <div class="bg-primary bg-green"><i class="fa fa-info-circle"></i> 已退房间费用结算</div>
  <div class="setbox">
    <div class="col-12">
      <h2 class="text-center">退房费用结算单</h2>
    </div>
    <hr>

    <div class="col-12">
      <div class="row">
        <!--        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">退房单号：{{resp2?.checkout_record_no}}</div>-->
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">签约人：{{resp2?.subject_name}}</div>
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">合同号：{{resp2?.lease_info.contract_num}}</div>
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">合同周期:
          {{resp2?.lease_info.start_time|date:'yyyy.MM.dd'}}-{{resp2?.lease_info.end_time|date:'yyyy.MM.dd'}}
        </div>
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">
          退房间数：{{resp2?.checkout_room_dtolist?.length}}
        </div>
      </div>
    </div>


    <div class="box-table">
      <h5 class="mt-1 font-weight-bold">费用结算汇总：</h5>
      <table class="table table-bordered">

        <ng-container *ngIf="resp2?.check_out_room_info_dto?.receivable_total">
          <thead class="thead-light">
          <tr>
            <th class="w-25" scope="col">应收费用</th>
            <th class="w-25" scope="col">应收金额（元）</th>
            <th class="w-25" scope="col">应收费用</th>
            <th class="w-25" scope="col">应收金额（元）</th>
          </tr>
          </thead>
          <tbody *ngFor="let item of resp2?.check_out_room_info_dto?.receivable_items;let j = index">
          <tr *ngIf="j%2==0">
            <td *ngIf="j%2==0">{{item.object_code}}</td>
            <td *ngIf="j%2==0">{{item.object_value}}</td>
            <td
              *ngIf="j+1<resp2?.check_out_room_info_dto?.receivable_items.length && (j+1)%2==1">{{resp2?.check_out_room_info_dto?.receivable_items[j + 1].object_code}}</td>
            <td
              *ngIf="j+1<resp2?.check_out_room_info_dto?.receivable_items.length && (j+1)%2==1">{{resp2?.check_out_room_info_dto?.receivable_items[j + 1].object_value}}</td>
            <td *ngIf="j+1>=resp2?.check_out_room_info_dto?.receivable_items.length"></td>
            <td *ngIf="j+1>=resp2?.check_out_room_info_dto?.receivable_items.length"></td>
          </tr>
          </tbody>
          <tbody>
          <td class="bg-gray-200 font-weight-bold">应收总计（元）</td>
          <td class="text-right" colspan="3">{{resp2?.check_out_room_info_dto?.receivable_total}}</td>
          </tbody>
        </ng-container>

        <ng-container *ngIf="resp2?.check_out_room_info_dto?.retreated_total">
          <thead class="thead-light">
          <tr>
            <th class="w-25" scope="col">应退费用</th>
            <th class="w-25" scope="col">应退金额（元）</th>
            <th class="w-25" scope="col">应退费用</th>
            <th class="w-25" scope="col">应退金额（元）</th>
          </tr>
          </thead>
          <tbody *ngFor="let item of resp2?.check_out_room_info_dto?.retreated_items;let j = index">
          <tr *ngIf="j%2==0">
            <td *ngIf="j%2==0">{{item.object_code}}</td>
            <td *ngIf="j%2==0">{{item.object_value}}</td>
            <td
              *ngIf="j+1<resp2?.check_out_room_info_dto?.retreated_items.length && (j+1)%2==1">{{resp2?.check_out_room_info_dto?.retreated_items[j + 1].object_code}}</td>
            <td
              *ngIf="j+1<resp2?.check_out_room_info_dto?.retreated_items.length && (j+1)%2==1">{{resp2?.check_out_room_info_dto?.retreated_items[j + 1].object_value}}</td>
            <td *ngIf="j+1>=resp2?.check_out_room_info_dto?.retreated_items.length"></td>
            <td *ngIf="j+1>=resp2?.check_out_room_info_dto?.retreated_items.length"></td>
          </tr>
          </tbody>
          <tbody>
          <td class="bg-gray-200 font-weight-bold">应退总计（元）</td>
          <td class="text-right" colspan="3">{{resp2?.check_out_room_info_dto?.retreated_total}}</td>
          </tbody>
        </ng-container>
      </table>
      <div class="mt-2 mt-2 text-right font-lg font-weight-bold">
        合计：<span class="text-danger font-lg">{{resp2?.check_out_room_info_dto?.amount_total}}</span>元
      </div>
    </div>

    <ng-container *ngIf="resp2.overdue_lease_room_bill_list">
      <div class="box-table mt-2">
        <h5 class="mt-1 font-weight-bold">滞纳金说明：</h5>
        <div class="mt-1">以下房间房费账单已逾期，根据合同约定预估产生的滞纳金明细如下</div>
        <table class="table table-bordered">
          <thead class="thead-light">
          <tr>
            <th class="w-15" scope="col">房间号</th>
            <th class="w-15" scope="col">账单周期</th>
            <th class="w-15" scope="col">账单金额</th>
            <th class="w-10" scope="col">应付时间</th>
            <th class="w-10" scope="col">已付金额</th>
            <th class="w-10" scope="col">剩余金额</th>
            <th class="w-10" scope="col">逾期天数</th>
            <th class="w-15" scope="col">滞纳金</th>
          </tr>
          </thead>
          <tbody *ngFor="let item of resp2.overdue_lease_room_bill_list;let j = index">
          <tr>
            <td>{{item.room_name}}</td>
            <td>{{item.bill_start_time|date:'yyyy.MM.dd'}}-{{item.bill_end_time|date:'yyyy.MM.dd'}}</td>
            <td>{{item.bill_amount}}</td>
            <td>{{item.plan_pay_time|date:'yyyy.MM.dd'}}</td>
            <td>{{item.received}}</td>
            <td>{{item.unreceived}}</td>
            <td>{{item.overdue_day}}</td>
            <td>{{item.overdue_amount}}</td>
          </tr>
          </tbody>
        </table>
        <div class="mt-2 text-right font-lg font-weight-bold ">
          合计：<span class="text-danger font-lg">{{resp2.overdue_total}}元</span>
        </div>
      </div>
    </ng-container>

    <div class="mt-2 text-right font-xl font-weight-bold ">
      费用结算合计<span class="text-gray1 font-s">(含预估滞纳金)</span>：<span
      class="text-danger font-xl">{{ resp2.total }}元</span>
    </div>

    <div class="mb-2" *ngIf="resp2.show_refund">
      <h5 class="mt-1 font-weight-bold">退款信息：</h5>
     <div class="border ">
       <div class="card card-block">
         <div class="mb-2 text-dark">
           <div class="row mb-3">
             <div class="col-12">退款方式：
               <div class="form-check form-check-inline">
                 <input class="form-check-input cursor" type="radio" value="REFUND" name="refund_type"
                        [(ngModel)]="confirmReq.checkout_refund_dto.checkout_finance_type_enum">
                 <label class="form-check-label">转账</label>
               </div>
               <div class="form-check form-check-inline">
                 <input class="form-check-input cursor" type="radio" value="ACCOUNT_MONEY" name="refund_type"
                        [(ngModel)]="confirmReq.checkout_refund_dto.checkout_finance_type_enum">
                 <label class="form-check-label">客商账户</label>
               </div>
               <div class="form-check form-check-inline">
                 <input class="form-check-input cursor" type="radio" value="TRANSFER_IN" name="refund_type"
                        [(ngModel)]="confirmReq.checkout_refund_dto.checkout_finance_type_enum">
                 <label class="form-check-label">退款转收入<span class="text-orange">（客户放弃退款）</span></label>
               </div>
             </div>
           </div>
         </div>

         <div class="" *ngIf="confirmReq.checkout_refund_dto.checkout_finance_type_enum==='REFUND'">
           <h3><b class="font16">退款账户</b></h3>
           <div class="mb-2 bg-gray text-dark">
<!--             <div class="row mb-3">-->
<!--               <div class="col-12">退款方式：-->
<!--                 <div class="form-check form-check-inline">-->
<!--                   <input class="form-check-input cursor" type="radio" value="TRANSFER" name="refund_mode"-->
<!--                          [(ngModel)]="confirmReq.checkout_refund_dto.checkout_bill_pay_type_enum">-->
<!--                   <label class="form-check-label">转账</label>-->
<!--                 </div>-->
<!--               </div>-->
<!--             </div>-->
             <div class="row" *ngIf="confirmReq.checkout_refund_dto.checkout_bill_pay_type_enum==='TRANSFER'">
               <div class="col-12 col-md-3 col-lg-2">
                 <div class="form-group">
                   <label>收款人姓名：</label>
                   <input class="form-control" [(ngModel)]="confirmReq.checkout_refund_dto.refund_name" maxlength="20" required>
                   <div *ngIf="!confirmReq.checkout_refund_dto.refund_name" class="text-danger">收款姓名不能为空</div>
                 </div>
               </div>
               <div class="col-12 col-md-3 col-lg-2">
                 <div class="form-group">
                   <label>开户行：</label>
                   <input class="form-control" [(ngModel)]="confirmReq.checkout_refund_dto.open_bank" maxlength="50" required>
                   <div *ngIf="!confirmReq.checkout_refund_dto.open_bank" class="text-danger">开户行不能为空</div>
                 </div>
               </div>
               <div class="col-12 col-md-6 col-lg-4">
                 <div class="form-group">
                   <label>银行账号：</label>
                   <input class="form-control" [(ngModel)]="confirmReq.checkout_refund_dto.bank_no" maxlength="50" required>
                   <div *ngIf="!confirmReq.checkout_refund_dto.bank_no" class="text-danger">银行账号不能为空</div>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div class="" *ngIf="confirmReq.checkout_refund_dto.checkout_finance_type_enum==='ACCOUNT_MONEY'">
           <div class="mb-2">
             <span class="mr-2">客户账户</span>
             <select class="paymentStyle ml-3" [(ngModel)]="clientUserNameMoneyId"
                     (ngModelChange)="clientShiftFocus($event)">
               <option *ngFor="let item of queryAccountLeaseResponse?.account_money_list; let i=first " [ngValue]="item.account_money_info_id">
                 {{item.account_money_name}}
               </option>
             </select>
           </div>
         </div>

         <div class="" *ngIf="confirmReq.checkout_refund_dto.checkout_finance_type_enum==='TRANSFER_IN'">
           <h5 class="mt-1 font-weight-bold">退款转收入明细</h5>
           <div class="mb-2 ">
             <div class="my-3">
               <table class="table table-bordered">
                 <thead class="thead-light">
                 <tr>
                   <th>收支抵扣后剩余转收入费用</th>
                   <th>收支抵扣后剩余可转收入金额（元）</th>
                 </tr>
                 </thead>
                 <tbody>
                 <ng-container *ngIf="resp2.refund_transfer && resp2.refund_transfer.retreated_items">
                   <tr *ngFor="let item of resp2.refund_transfer.retreated_items">
                     <td>{{item.object_code}}</td>
                     <td>{{item.object_value}}</td>
                   </tr>
                 </ng-container>
                 <tr>
                   <td class="font-weight-bold">转收入总金额（元）</td>
                   <td class="text-right">{{resp2?.refund_transfer?.retreated_total}}</td>
                 </tr>
                 </tbody>
               </table>
             </div>
           </div>
           <div class="col-12 my-2">
             <div class="row">
               <div class="font-weight-bold col-6 px-0">
                 本人同意放弃剩余【{{resp2?.refund_transfer?.retreated_total}}元】退款
               </div>
               <div class="text-danger text-right font-weight-bold col-6 px-0">
                 转收入后合计：{{resp2?.refund_transfer?.retreated_total}} 元
               </div>
             </div>
           </div>
         </div>
       </div>

     </div>
    </div>

    <div class="col-12 my-5">
      <div class="row">
        <div class="col-6 px-0">
          接待管家：<span class="w120 d-inline-block border-bottom">{{resp2?.manage_name}}</span>
        </div>
        <div class="col-6 px-0">
          客户确认：<span class="w120 d-inline-block border-bottom"></span>
        </div>
      </div>
    </div>


    <div class="box-table mt-2" *ngIf="sumCouponTotal(resp2?.checkout_room_dtolist) > 0"  >
      <h5 class="mt-1 font-weight-bold">其他信息：</h5>
      <div class="">
          说明：
          有{{resp2?.checkout_room_dtolist?.length}}间房回收未被使用预付费-代金券
          {{sumCouponTotal(resp2?.checkout_room_dtolist, "预付费")}}元；
          有{{resp2?.checkout_room_dtolist?.length}}间房回收未被使用房费-代金券
          {{sumCouponTotal(resp2?.checkout_room_dtolist, "房费")}}元；
        <ng-container *ngIf="!resp2?.check_out_room_info_dto?.retreated_total">
          <div class="row">
            <div class="col-12 mb-2"><b class="text-orange">应退总计：0.00元</b></div>
          </div>
        </ng-container>
      </div>
    </div>

    <hr>

    <h5 class="mt-2 font-weight-bold" >房间明细</h5>
    <div class="box-table" *ngFor="let item of resp2?.checkout_room_dtolist">
      <table class="table table-bordered" style="margin-bottom: 0px;">
        <thead class="thead-light">
        <tr>
          <th class="text-left" colspan="3">房间号：{{item?.check_out_room_info_dto?.room_name}}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="padd15" colspan="3">
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">
                合同结束日期：{{resp2?.lease_info.end_time|date:'yyyy-MM-dd'}}
              </div>
              <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">退房日期：{{item.end_time|date:'yyyy-MM-dd'}}</div>
              <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">退房状态：
                <block class="text-danger">
                <ng-container *ngIf="item.checkout_type==='NORMAL'">
                  正常
                </ng-container>
                <ng-container *ngIf="item.checkout_type==='OVERDUE'">
                  逾期<span>（{{item.overdue_day}}天）</span>
                </ng-container>
                <ng-container *ngIf="item.checkout_type==='BEFORE'">
                  提前
                </ng-container>
                <ng-container *ngIf="item.checkout_type==='BREAK'">
                  违约
                </ng-container>
                <ng-container *ngIf="item.checkout_type==='SUBLEASE'">
                  转租
                </ng-container>
                </block>
              </div>
              <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">
                应收总计：{{item.check_out_room_info_dto.receivable_total}}
              </div>
              <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">
                应退总计：-{{item.check_out_room_info_dto.retreated_total}}
              </div>
              <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2"><b
                class="text-danger">合计：{{item.check_out_room_info_dto.amount_total}}</b>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
        <thead class="thead-light">
        <tr>
          <th scope="col">应收费用</th>
          <th scope="col">费用周期</th>
          <th scope="col">应收金额（元）</th>
        </tr>
        </thead>
        <tbody *ngFor="let inFee of item.check_out_room_info_dto.receivable_items">
        <tr>
          <td>{{inFee.object_code}}</td>
          <td>{{inFee.object_name}}</td>
          <td>{{inFee.object_value}}</td>
        </tr>
        </tbody>
        <thead class="thead-light">
        <tr>
          <th scope="col">应退费用</th>
          <th scope="col">费用周期</th>
          <th scope="col">应退金额（元）</th>
        </tr>
        </thead>
        <tbody *ngFor="let inFee of item.check_out_room_info_dto.retreated_items">
        <tr>
          <td>{{inFee.object_code}}</td>
          <td>{{inFee.object_name}}</td>
          <td>{{inFee.object_value}}</td>
        </tr>
        </tbody>
        <thead *ngIf="item?.check_out_room_info_dto?.recycle_items?.length > 0" class="thead-light">
        <tr>
          <th>回收优惠券</th>
          <th>回收来源</th>
          <th>回收金额（元）</th>
        </tr>
        </thead>
        <tbody *ngFor="let recycle of item.check_out_room_info_dto.recycle_items" align="center">
        <tr>
          <td>{{recycle?.object_code}}</td>
          <td>{{recycle?.object_name}}</td>
          <td>{{recycle?.object_value}}</td>
        </tr>
        </tbody>
      </table>

      <ng-template [ngIf]="item.check_out_room_info_dto.renter_bill_list">
        <table *ngFor="let rn of item.check_out_room_info_dto.renter_bill_list" class="table table-bordered" style="margin-bottom: 0px">
          <thead class="thead-light">
          <tr>
            <th colSpan="3" style="text-align: left" >住客：{{rn.renter_name}}</th>
          </tr>
          <tr>
            <th scope="col" style="width: 24%">应收费用</th>
            <th scope="col">费用周期</th>
            <th scope="col">应收金额（元）</th>
          </tr>
          </thead>
          <tbody align="center">
          <tr *ngFor="let bill of rn.renter_bill_items">
            <td>{{bill.object_code}}</td>
            <td>{{bill.object_name}}</td>
            <td>{{bill.object_value}}</td>
          </tr>
          </tbody>
        </table>
      </ng-template>

    </div>

    <div class="text-center">
      <button type="button" class="btn btn-blue mr-3" (click)="goConfirm()">发送至客户签字确认</button>
    </div>
  </div>
</div>


<div class="modal fade" bsModal #modal1="bs-modal" [config]="{backdrop: 'static',keyboard: false}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>查看房间费用明细</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modal1.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">

        <h3 class="text-center">退房费用结算明细</h3>
        <hr>

        <div class="box-table" *ngFor="let item of resp2?.checkout_room_dtolist">
          <table class="table table-bordered" style="margin-bottom: 0px">
            <thead class="thead-light">
            <tr>
              <th class="text-left" colspan="3">房间号：{{item?.check_out_room_info_dto?.room_name}}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="padd15" colspan="3">
                <div class="row">
                  <div class="col-12 col-md-4 col-lg-3 col-2xl-3 mb-2 text-left">
                    合同结束日期：{{resp2?.lease_info.end_time|date:'yyyy-MM-dd'}}
                  </div>
                  <div class="col-12 col-md-4 col-lg-3 col-2xl-3 mb-2 text-left">退房日期：{{item.end_time|date:'yyyy-MM-dd'}}</div>
                  <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2 text-left">退房状态：
                    <ng-container *ngIf="item.checkout_type==='NORMAL'">
                      正常
                    </ng-container>
                    <ng-container *ngIf="item.checkout_type==='OVERDUE'">
                      逾期<span class="text-orange">（{{item.overdue_day}}天）</span>
                    </ng-container>
                    <ng-container *ngIf="item.checkout_type==='BEFORE'">
                      提前
                    </ng-container>
                    <ng-container *ngIf="item.checkout_type==='BREAK'">
                      违约
                    </ng-container>
                    <ng-container *ngIf="item.checkout_type==='SUBLEASE'">
                      转租
                    </ng-container>
                  </div>
                  <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2 text-left">
                    应收总计：{{item.check_out_room_info_dto.receivable_total}}
                  </div>
                  <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2 text-left">
                    应退总计：-{{item.check_out_room_info_dto.retreated_total}}
                  </div>
                  <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2 text-left"><b
                    class="text-danger">合计：{{item.check_out_room_info_dto.amount_total}}</b>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
            <thead class="thead-light">
            <tr>
              <th scope="col">应收费用</th>
              <th scope="col">费用周期</th>
              <th scope="col">应收金额（元）</th>
            </tr>
            </thead>
            <tbody *ngFor="let inFee of item.check_out_room_info_dto.receivable_items">
            <tr>
              <td>{{inFee.object_code}}</td>
              <td>{{inFee.object_name}}</td>
              <td>{{inFee.object_value}}</td>
            </tr>
            </tbody>
            <thead class="thead-light">
            <tr>
              <th scope="col">应退费用</th>
              <th scope="col">费用周期</th>
              <th scope="col">应退金额（元）</th>
            </tr>
            </thead>
            <tbody *ngFor="let inFee of item.check_out_room_info_dto.retreated_items">
            <tr>
              <td>{{inFee.object_code}}</td>
              <td>{{inFee.object_name}}</td>
              <td>{{inFee.object_value}}</td>
            </tr>
            </tbody>
            <thead *ngIf="item?.check_out_room_info_dto?.recycle_items?.length > 0" class="thead-light">
            <tr>
              <th>回收优惠券</th>
              <th>回收来源</th>
              <th>回收金额（元）</th>
            </tr>
            </thead>
            <tbody *ngFor="let recycle of item.check_out_room_info_dto.recycle_items" align="center">
            <tr>
              <td>{{recycle?.object_code}}</td>
              <td>{{recycle?.object_name}}</td>
              <td>{{recycle?.object_value}}</td>
            </tr>
            </tbody>
          </table>
          <ng-template [ngIf]="item.check_out_room_info_dto.renter_bill_list">
            <table *ngFor="let rn of item.check_out_room_info_dto.renter_bill_list" class="table table-bordered" style="margin-bottom: 0px">
              <thead class="thead-light">
              <tr>
                <th colSpan="3" style="text-align: left" >住客：{{rn.renter_name}}</th>
              </tr>
              <tr>
                <th scope="col" style="width: 24%">应收费用</th>
                <th scope="col">费用周期</th>
                <th scope="col">应收金额（元）</th>
              </tr>
              </thead>
              <tbody align="center">
              <tr *ngFor="let bill of rn.renter_bill_items">
                <td>{{bill.object_code}}</td>
                <td>{{bill.object_name}}</td>
                <td>{{bill.object_value}}</td>
              </tr>
              </tbody>
            </table>
          </ng-template>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)=" modal1.hide()">关闭</button>
      </div>
    </div>
  </div>
</div>




