import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";

/**
 * @Description: 转换html管道
 * @Author: 陆炳彬
 * @Date: 2018/3/29 上午10:26
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/3/29 上午10:26
 */
@Pipe({
  name: "html"
})

export class HtmlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {

  }

  transform(style) {
    if (style) {
      return this.sanitizer.bypassSecurityTrustHtml(style);
    } else {
      return '';
    }
  }
}
