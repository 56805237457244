<ng-container *ngIf="finance_details">
  <div class="setbox-top flex">
    <div class="flex-content">
      <div class="bg-primary"><i class="fa fa-info-circle"></i>申请单号：{{finance_details.room_change_info.record_number}}</div>
      <div class="setbox text-dark">
        <h2 class="text-center">换房单</h2>
        <hr>
        <div class="row">
          <div class="col-12 col-md-4 col-lg-3 col-2xl-3 mb-2">换房单号：{{finance_details.room_change_info.record_number}}</div>
          <div class="col-12 col-md-4 col-lg-3 col-2xl-3 mb-2">合同号：{{finance_details.contract_num}}</div>
          <div class="col-12 col-md-4 col-lg-3 col-2xl-3 mb-2">合同周期:
            {{finance_details.lease_start_time | date: 'yyyy.MM.dd'}}-{{finance_details.lease_end_time | date: 'yyyy.MM.dd'}}
          </div>
          <div class="col-12 col-md-4 col-lg-3 col-2xl-3 mb-2">换房日期: {{finance_details.room_change_info.change_time | date: 'yyyy.MM.dd'}}</div>
        </div>

        <div class="row">
          <div class="col-12 col-md-4 col-lg-3 col-2xl-3 mb-2">
            <b class="text-orange">应收总计：{{finance_details.origin_room_bills?.receivable_total && (finance_details.origin_room_bills.receivable_total + finance_details.new_room_bills.receivable_total).toFixed(2)}}元</b>
          </div>
          <div class="col-12 col-md-4 col-lg-3 col-2xl-3 mb-2">
            <b class="text-orange">应退总计：{{finance_details.origin_room_bills?.retreated_total && finance_details.origin_room_bills.retreated_total * (-1)}}元</b>
          </div>
          <div class="col-12 col-md-4 col-lg-3 col-2xl-3 mb-2">
            <b class="text-orange">费用转移金额：{{finance_details.prepay_account.object_value}}元 (全额转入新房间，不退款)</b>
          </div>
        </div>

        <hr>
        <div class="mb-3">
          <span class="text-orange mr-4">合计：<b class="font18">{{finance_details.total_amount}}</b>元</span>
          <ng-container *ngIf="finance_details.operate_flag === 'REFUNDIN'">（换房成功后，退款金额直接用于抵扣下期应付房费账单）</ng-container>
        </div>

        <div class="card card-block mb-2" *ngIf="finance_details.operate_flag === 'REFUNDOUT' && finance_details.total_amount !== 0">
          <h3><b class="font16">退款账户</b></h3>
          <div class="mb-2 bg-gray text-dark">
            <div class="row mb-3">
              <div class="col-12">退款方式：
                <div class="form-check form-check-inline">
                  <input class="form-check-input" style="cursor: pointer;" type="radio" value="TRANSFER"
                         [(ngModel)]="confirm_req.checkout_bill_pay_type_enum">
                  <label class="form-check-label">转账</label>
                </div>
                <!--<div class="form-check form-check-inline">
                  <input class="form-check-input" style="cursor: pointer;" type="radio" value="CASH"
                         [(ngModel)]="confirm_req.checkout_bill_pay_type_enum">
                  <label class="form-check-label">现金</label>
                </div>-->
                <div class="form-check form-check-inline">
                  <input class="form-check-input" style="cursor: pointer;" type="radio" value="ACCOUNT_MONEY"
                         [(ngModel)]="confirm_req.checkout_bill_pay_type_enum">
                  <label class="form-check-label">客商账户</label>
                </div>
                <div class="text-danger" *ngIf="!confirm_req.checkout_bill_pay_type_enum">请选择退款方式</div>
              </div>
            </div>
            <div class="row" *ngIf="confirm_req.checkout_bill_pay_type_enum==='TRANSFER'">
              <div class="col-12 col-md-3 col-lg-2">
                <div class="form-group">
                  <label>收款人姓名：</label>
                  <input class="form-control" [(ngModel)]="confirm_req.refund_name" maxlength="20" required>
                  <div *ngIf="!confirm_req.refund_name" class="text-danger">收款姓名不能为空</div>
                </div>
              </div>
              <div class="col-12 col-md-3 col-lg-2">
                <div class="form-group">
                  <label>开户行：</label>
                  <input class="form-control" [(ngModel)]="confirm_req.open_bank" maxlength="50" required>
                  <div *ngIf="!confirm_req.open_bank" class="text-danger">开户行不能为空</div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label>银行账号：</label>
                  <input class="form-control" [(ngModel)]="confirm_req.bank_no" maxlength="50" required>
                  <div *ngIf="!confirm_req.bank_no" class="text-danger">银行账号不能为空</div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="confirm_req.checkout_bill_pay_type_enum==='ACCOUNT_MONEY'">
              <div class="col-12 col-md-3">
                <span class="mr-2">客户账户</span>
                <select class="paymentStyle ml-3" [(ngModel)]="clientUserNameMoneyId"
                        (ngModelChange)="clientShiftFocus($event)">
                  <option *ngFor="let item of queryAccountLeaseResponse?.account_money_list; let i=first " [ngValue]="item.account_money_info_id">
                    {{item.account_money_name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

<!--        <div class="card card-block mb-2"-->
<!--             *ngIf="finance_details.operate_flag === 'GATHERING' && finance_details.total_amount !== 0 && !finance_details.gathered">-->
<!--          <h3><b class="font16">您可以操作</b></h3>-->
<!--          <div class="mb-2 bg-gray text-dark">-->
<!--            <div class="row mb-3">-->
<!--              <div class="col-12 mb-4">-->
<!--                <a class="text-primary" style="text-decoration: underline" (click)="getAmount()"><b>点击收款</b></a>-->
<!--              </div>-->
<!--              <div class="col-12 mb-3">-->
<!--                <div class="form-check w140px">-->
<!--                  <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="no_bill">-->
<!--                  <label class="form-check-label" for="exampleCheck1">不收款直接换房</label>-->
<!--                </div>-->
<!--                <div class="condiv3">-->
<!--                <textarea class="w-100" rows="1" placeholder="请输入原因，限50字（必填）" maxlength="50"-->
<!--                          [(ngModel)]="confirm_req.remark" *ngIf="no_bill"></textarea>-->
<!--                  <div *ngIf="no_bill && !confirm_req.remark" class="text-danger">原因不能为空</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <h3><b class="font16">房间明细</b></h3>
        <hr>

        <div class="box-table">
          <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
              <th class="text-left" colspan="3">原房间号：{{finance_details.origin_room_bills.room_name}}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="padd15" colspan="3">
                <div class="row">

                  <div class="col-12 col-md-4 col-lg-3 mb-2">
                    应收总计：{{finance_details.origin_room_bills.receivable_total}}
                  </div>
                  <div class="col-12 col-md-4 col-lg-3 mb-2">
                    应退总计：{{finance_details.origin_room_bills.retreated_total * (-1)}}
                  </div>
                  <div class="col-12 col-md-8 col-lg-3 mb-2"><b class="text-orange">合计：{{finance_details.origin_room_bills.amount_total}}</b>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
            <thead class="thead-light">
            <tr>
              <th scope="col">应收费用</th>
              <th scope="col">费用周期</th>
              <th scope="col">应收金额（元）</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of finance_details?.origin_room_bills?.receivable_items">
              <td>{{item.object_code}}</td>
              <td>{{item.object_name}}</td>
              <td>{{item.object_value}}</td>
            </tr>
            </tbody>
            <thead class="thead-light">
            <tr>
              <th scope="col">应退费用</th>
              <th scope="col">费用周期</th>
              <th scope="col">应退金额（元）</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of finance_details?.origin_room_bills?.retreated_items">
              <td>{{item.object_code}}</td>
              <td>{{item.object_name}}</td>
              <td>{{item.object_value}}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="box-table">
          <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
              <th class="text-left" colspan="3">新房间号：{{finance_details.new_room_bills.room_name}}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="padd15" colspan="3">
                <div class="row">

                  <div class="col-12 col-md-4 col-lg-3 mb-2">
                    应收总计：{{finance_details.new_room_bills.receivable_total}}
                  </div>
                  <div class="col-12 col-md-8 col-lg-3 mb-2"><b class="text-orange">合计：{{finance_details.new_room_bills.amount_total}}</b>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
            <thead class="thead-light">
            <tr>
              <th scope="col">应收费用</th>
              <th scope="col">费用周期</th>
              <th scope="col">应收金额（元）</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of finance_details?.new_room_bills?.receivable_items">
              <td>{{item.object_code}}</td>
              <td>{{item.object_name}}</td>
              <td>{{item.object_value}}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="box-table">
          <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
              <th scope="col">转移费用</th>
              <th scope="col">转移金额（元）</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{finance_details.prepay_account.object_code}}</td>
              <td>{{finance_details.prepay_account.object_value}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="absolute-foot text-center">
<!--      <e-ngx-print [btnText]="'打印换房单'" [btnClass]="{'btn btn-primary mr-3': true}" [printHTML]="print_div"></e-ngx-print>-->
      <button printSectionId="print_div" ngxPrint class="btn btn-primary mr-3" >打印换房单</button>

      <button type="button" class="btn btn-secondary mr-3" [disabled]="!canGoNext() || next_disable" (click)="goNext()">下一步</button>
    </div>
  </div>


  <div style="display: none">
    <div #print_div id="print_div">
      <table width="100%" border="1" cellpadding="0" cellspacing="0">
        <thead bgcolor="#e4e4e4">
        <tr>
          <th colspan="4" align="center"><b>换房单</b></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>换房单号：{{finance_details.room_change_info.record_number}}</td>
          <td>合同号：{{finance_details.contract_num}}</td>
          <td>合同周期:
            {{finance_details.lease_start_time | date: 'yyyy.MM.dd'}}-{{finance_details.lease_end_time | date: 'yyyy.MM.dd'}}
          </td>
          <td>换房日期: {{finance_details.room_change_info.change_time | date: 'yyyy.MM.dd'}}</td>
        </tr>



        <tr>
          <td>应收总计：{{finance_details.origin_room_bills?.receivable_total && (finance_details.origin_room_bills.receivable_total + finance_details.new_room_bills.receivable_total).toFixed(2)}}元</td>
          <td>应退总计：{{finance_details.origin_room_bills?.retreated_total && finance_details.origin_room_bills.retreated_total * (-1)}}元</td>
          <td colspan="2"><b>费用转移金额：{{finance_details.prepay_account.object_value}}元</b></td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colspan="4"><b>合计： {{finance_details.total_amount}}</b>元</td>
        </tr>
        </tfoot>
      </table>
      <br>
      <ng-container *ngIf="finance_details.operate_flag === 'REFUNDOUT' && finance_details.total_amount !== 0">
        <table width="100%" border="1" cellpadding="0" cellspacing="0">
          <thead bgcolor="#e4e4e4">
          <tr>
            <th colspan="3" align="left">退款账户</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td colspan="3">退款方式：
              <ng-container *ngIf="confirm_req.checkout_bill_pay_type_enum === 'TRANSFER'">转账</ng-container>
              <ng-container *ngIf="confirm_req.checkout_bill_pay_type_enum === 'CASH'">现金</ng-container>
            </td>
          </tr>
          <tr *ngIf="confirm_req.checkout_bill_pay_type_enum === 'TRANSFER'">
            <td>收款人姓名：{{confirm_req.refund_name}}</td>
            <td>开户行：{{confirm_req.open_bank}}</td>
            <td>银行账号：{{confirm_req.bank_no}}</td>
          </tr>
          </tbody>
        </table>
        <br>
      </ng-container>

      <table width="100%" border="1" cellpadding="0" cellspacing="0">
        <thead bgcolor="#e4e4e4" align="left">
        <tr>
          <th colspan="3">房间明细</th>
        </tr>
        <tr>
          <th colspan="3">原房间号：{{finance_details.origin_room_bills.room_name}}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>应收总计：{{finance_details.origin_room_bills.receivable_total}}</td>
          <td>应退总计：{{finance_details.origin_room_bills.retreated_total * (-1)}}</td>
          <td><b>合计：{{finance_details.origin_room_bills.amount_total}}</b></td>
        </tr>
        </tbody>
        <thead bgcolor="#e4e4e4" align="center">
        <tr>
          <th>应收费用</th>
          <th>费用周期</th>
          <th>应收金额（元）</th>
        </tr>
        </thead>
        <tbody align="center">
        <tr *ngFor="let item of finance_details?.origin_room_bills?.receivable_items">
          <td>{{item.object_code}}</td>
          <td>{{item.object_name}}</td>
          <td>{{item.object_value}}</td>
        </tr>
        </tbody>
        <thead bgcolor="#e4e4e4" align="center">
        <tr>
          <th>应退费用</th>
          <th>费用周期</th>
          <th>应退金额（元）</th>
        </tr>
        </thead>
        <tbody align="center" border-color="#ccc">
        <tr *ngFor="let item of finance_details.origin_room_bills.retreated_items">
          <td>{{item.object_code}}</td>
          <td>{{item.object_name}}</td>
          <td>{{item.object_value}}</td>
        </tr>
        </tbody>

        <thead bgcolor="#e4e4e4" align="left">
        <tr>
          <th colspan="3">新房间号：{{finance_details.new_room_bills.room_name}}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>应收总计：{{finance_details.new_room_bills.receivable_total}}</td>
          <td colspan="2"><b>合计：{{finance_details.new_room_bills.amount_total}}</b></td>
        </tr>
        </tbody>
        <thead bgcolor="#e4e4e4" align="center">
        <tr>
          <th>应收费用</th>
          <th>费用周期</th>
          <th>应收金额（元）</th>
        </tr>
        </thead>
        <tbody align="center">
        <tr *ngFor="let item of finance_details?.new_room_bills?.receivable_items">
          <td>{{item.object_code}}</td>
          <td>{{item.object_name}}</td>
          <td>{{item.object_value}}</td>
        </tr>
        </tbody>

        <thead bgcolor="#e4e4e4" align="left">
        <tr>
          <th colspan="3">费用转移明细</th>
        </tr>
        </thead>
        <thead bgcolor="#e4e4e4" align="center">
        <tr>
          <th>转移费用</th>
          <th colspan="2">转移金额（元）</th>
        </tr>
        </thead>
        <tbody align="center">
        <tr>
          <td>{{finance_details.prepay_account.object_code}}</td>
          <td colspan="2">{{finance_details.prepay_account.object_value}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>

<app-dialog (callback)="refresh()"></app-dialog>
