<!-- 写字板 -->
<div bsModal #modal_n1="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true" (click)="cclean($event)"
>
  <div class="modal-dialog modal-danger modal-xl" role="document">
    <div class="modal-content tablet-content">
      <div class="modal-header border-bottom-0 tablet-title">
          <h4 class="modal-title"><i class="fa fa-info-circle" aria-hidden="true"></i>{{title}}</h4>
      </div>
      <div class="modal-body mx-2 font-lg">
        <div id="canvas_div" style="padding:0px;width:100%;margin:0 auto" class="tablet-body">
          <canvas id="canvas" style="margin-left: -10px;">
            您的浏览器不支持canvas技术,请升级浏览器!
          </canvas>
        </div>
      </div>
      <div class="modal-footer mx-2 mb-2 border-top-0 tablet-footer">
        <button type="button" id="clear" (click)="clearClick()" class="btn btn-info" >清除</button>
        <button type="button" id="save" (click)="saveClick()" class="btn btn-info" >提交</button>
        <!--<p>网络连接失败... /  服务器未响应...</p>-->
      </div>
    </div>
  </div>
</div>

