import {Component, OnInit, ViewChild} from '@angular/core';
import {SignedService} from '../../../../service/signed/signed.service';
import {ActivatedRoute, Router} from '@angular/router';
import {
  CheckoutRoomAffirmRequest,
  CheckoutRoomDTO,
  CheckoutRoomViewResponse,
  CheckoutViewDTO,
  CheckoutViewRequest,
  CheckoutViewResponse
} from '../../../../model/signed/checkout.model';

import {WorkModel} from '../../../../model/common/common.model';
import {DialogComponent} from '../../../shared/dialog/dialog.component';
import {WorkService} from '../../../../service/common/work.service';
import {ModalDirective} from "ngx-bootstrap/modal";
import {TabletComponent} from "../../tablet/tablet.component";
import {CheckOutListOperate, SignDataRequest, SignOnIpadRequest} from "../../../../model/signed/sign.model";
import {SignDeviceBindingComponent} from "../../../sign-device-binding/sign-device-binding.component";
import Decimal from "decimal.js";

@Component({
  selector: 'app-cheout-list',
  templateUrl: './cheout-list.component.html',
  styleUrls: ['./cheout-list.component.css']
})
export class CheoutListComponent implements OnInit {

  now: Date = new Date();

  show = false;

  req = new CheckoutViewRequest();
  checkoutViewResponse: CheckoutViewResponse;

  noBill = false;

  // 收款
  @ViewChild(DialogComponent, { static: true }) dialogComponent: DialogComponent;

  confirmReq: CheckoutRoomAffirmRequest = new CheckoutRoomAffirmRequest();

  @ViewChild("staticModal", { static: true }) staticModal: ModalDirective;
  @ViewChild(SignDeviceBindingComponent, { static: true }) signDeviceBindingComponent: SignDeviceBindingComponent;

  total;

  totalTag = 0;

  // 签字版
  @ViewChild(TabletComponent, { static: true }) tabletComponent: TabletComponent;

  signImg;

  //退房原因
  checkoutReason:string;

  constructor(private signedService: SignedService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private workService: WorkService) {
  }

  ngOnInit() {
  }

  getAmount() {
    this.dialogComponent.gatherCheckOutAmountWithPrepay(
      this.confirmReq.lease_id,
      this.checkoutViewResponse.checkout_room_dtolist.map(e => e.room_id),
      null,
      this.checkoutViewResponse.check_out_room_info_dto.bill_ids,
      this.checkoutViewResponse.check_out_room_info_dto.amount_total,
      'CHECKOUT',
      this.checkoutViewResponse.lease_info.contract_num
    );

  }

  refresh() {
    this.signedService.checkoutLeaseView(this.req).then(data => {
      this.checkoutViewResponse = data;

      this.total = new Decimal(this.checkoutViewResponse.check_out_room_info_dto.amount_total);
      this.totalTag = this.total.comparedTo(0);

    });
  }

  showDiv(crvr: CheckoutRoomViewResponse) {

    this.show = true;

    let cvdl = [];
    crvr.checkout_room_list.forEach(crl => {
      let cvd = new CheckoutViewDTO();
      cvd.room_id = crl.room_id;
      cvd.checkout_type = crl.violate;
      cvdl.push(cvd);
    });

    this.confirmReq.checkoutReason = crvr.checkoutReason;

    this.confirmReq.lease_id = crvr.lease_info.lease_id;
    this.confirmReq.checkout_view_dtolist = cvdl;

    this.req.lease_id = crvr.lease_info.lease_id;
    this.req.checkout_view_dtolist = cvdl;

    this.refresh();
  }

  signCheck(): boolean {

    if (this.totalTag < 0) {
      if (this.confirmReq.checkout_finance_type_enum) {
        if (this.confirmReq.checkout_finance_type_enum == 'REFUND') {
          if (this.confirmReq.checkout_bill_pay_type_enum) {
            if (this.confirmReq.checkout_bill_pay_type_enum === 'TRANSFER') {
              if (!this.confirmReq.refund_name) {
                return false;
              }
              if (!this.confirmReq.bank_no) {
                return false;
              }
              if (!this.confirmReq.open_bank) {
                return false;
              }
            }
          } else {
            return false;
          }
        }
      } else {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请选择退款方式'));
        return false;
      }

    } else if (this.totalTag > 0) {
      if (!this.noBill) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '还有账单未收款'));
        return false;
      }
      if (!this.confirmReq.remark) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '不收款原因未填写'));
        return false;
      }
    }

    return true;
  }

  confirmOut() {

    if (!this.signCheck()) {
      return;
    }

    this.confirmReq.total = this.checkoutViewResponse.check_out_room_info_dto.amount_total;

    this.confirmReq.sign_img = this.signImg;

    this.signedService.checkoutLeaseConfirm(this.confirmReq).then(data => {
      this.staticModal.show();
    });
  }

  billOwn(): number {
    if (this.totalTag > 0 && this.checkoutViewResponse && this.checkoutViewResponse.check_out_room_info_dto && this.checkoutViewResponse.check_out_room_info_dto.bill_ids) {
      return this.checkoutViewResponse.check_out_room_info_dto.bill_ids.length;
    } else {
      return 0;
    }
  }

  goLease() {

    this.workService.event_bus.emit(new WorkModel(this.workService.type.TAB_CLOSE_SELF));

    this.router.navigate(['../pact', this.confirmReq.lease_id], {
      relativeTo: this.activatedRoute
    });
  }

  sumCouponTotal(list: Array<CheckoutRoomDTO>, type?) {
    let total = new Decimal("0");
    if (list != null && list.length > 0) {
      for (let info of list) {
        if (info.check_out_room_info_dto != null && info.check_out_room_info_dto.recycle_items != null
          && info.check_out_room_info_dto.recycle_items.length > 0) {
          for (let coupon of info.check_out_room_info_dto.recycle_items) {
            if (!type || (type && type == coupon.object_name)) {
              total = total.add(new Decimal(coupon.object_value + ""));
            }
          }
        }
      }
    }
    return +(total.toString());
  }


  sign() {
    //  签名
    if (this.isPad()) {
      this.tabletComponent.tabletShow();
    }
  }

  commitSign(imgStr: string) {
    this.signImg = imgStr;
  }

  // 在ipad上签名
  signOnIpad() {

    if (!this.signCheck()) {
      return;
    }

    let request = new SignOnIpadRequest();
    request.sign_router_link_type = 'CHECKOUT_LIST';
    request.router_link = this.router.url;
    request.response_data = this.checkoutViewResponse;
    request.computer_no = localStorage.getItem('PC_UUID');
    let signDataRequest = new SignDataRequest();
    signDataRequest.lease_id = this.req.lease_id;
    signDataRequest.checkout_view_dtolist = this.req.checkout_view_dtolist;
    request.sign_data_request = signDataRequest;
    let operate = new CheckOutListOperate();
    operate.noBill = this.noBill;
    operate.not_receivable_remark = this.confirmReq.remark;
    operate.checkout_bill_pay_type_enum = this.confirmReq.checkout_bill_pay_type_enum;
    operate.refund_name = this.confirmReq.refund_name;
    operate.open_bank = this.confirmReq.open_bank;
    operate.bank_no = this.confirmReq.bank_no;
    // 添加延期退房
    operate.checkout_finance_type_enum = this.confirmReq.checkout_finance_type_enum;
    request.operate_data = operate;
    // 打开连接弹窗
    this.signDeviceBindingComponent.openModel(request, 1);
  }

  // 判断当前设备是否是ipad
  isPad(): boolean {
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone",
      "SymbianOS", "Windows Phone",
      "iPad", "iPod"];
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        return true;
      }
    }
    return false;
  }

}
