import { Injectable, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";
import { RequestService } from "../request.service";
import {
  CouponBatchInfoReq, CouponBatchInfoResp, CouponBatchLogListReq,
  CouponBatchLogListResp, CouponInfoBizRelationListReq, CouponInfoBizRelationListResp,
  CouponInfoDetailByCouponNumberReq, CouponInfoDetailByCouponNumberResp,
  CouponInfoRechargeResp, CouponRoomByBatchRequest, CouponShareInfoResp,
  CouponInfoListReq, CouponInfoListResp, CouponInfoRechargeReq, QueryGetCouponUserInfoReq, QueryGetCouponUserInfoResp,
} from '../../model/coupon/coupon.model';
import { promise } from "selenium-webdriver";
import { CouponInfoDetailComponent } from "../../view/coupon/detail/coupon-info-detail.component";

@Injectable()
export class CouponService implements OnInit {
  private COUPON_MANAGE_LIST = `${environment.apiHost}coupon/manage/list`;
  private COUPON_MANAGE_DELETE = `${environment.apiHost}coupon/manage/delete`;
  private GET_COUPON_BATCH_INFO = `${environment.apiHost}coupon/manage/coupon/batch/detail`;
  private GET_COUPON_INFO_PAGE = `${environment.apiHost}coupon/manage/info/list`;
  private GET_COUPON_BATCH_LOG_LIST = `${environment.apiHost}coupon/manage/batch/log/list`;
  private DOWNLOAD_COUPON_INFO_LIST = `${environment.apiHost}coupon/manage/info/list/download`;
  private GET_COUPON_INFO_BIZ_RELATION_LIST = `${environment.apiHost}coupon/manage/biz/relation/list`;
  private GET_COUPON_INFO_DETAIL_BY_COUPON_NUMBER = `${environment.apiHost}coupon/manage/info/detail/bynum`;
  private RECHARGE_COUPON_INFO = `${environment.apiHost}coupon/manage/info/recharge`;
  private COUPON_ADD = `${environment.apiHost}coupon/manage/insert`;
  private COUPON_SHARE = `${environment.apiHost}coupon/manage/couponShare`;
  private QUERY_GET_COUPON_USER_INFO = `${environment.apiHost}coupon/manage/queryGetCouponUserInfo`;
  private SEND_COUPON = `${environment.apiHost}coupon/manage/sendCoupon`;



  constructor(private requestService: RequestService) {
  }

  ngOnInit(): void {

  }

  insertCoupon(data: CouponRoomByBatchRequest): Promise<any> {
    return this.requestService.authPost(this.COUPON_ADD, data);
  }

  shareCoupon(data: any): Promise<CouponShareInfoResp> {
    return this.requestService.authGet(this.COUPON_SHARE, data);
  }

  public couponManageList(data: any): Promise<any> {
    return this.requestService.authGet(this.COUPON_MANAGE_LIST, data);
  }

  public couponManageListWithLoading(data: any): Promise<any> {
    return this.requestService.authGet(this.COUPON_MANAGE_LIST, data, true);
  }

  public couponManageDelete(data: any): Promise<any> {
    return this.requestService.authGet(this.COUPON_MANAGE_DELETE, data);
  }

  getCouponBatchInfo(data: CouponBatchInfoReq): Promise<CouponBatchInfoResp> {
    return this.requestService.authGet(this.GET_COUPON_BATCH_INFO, data);
  }

  getCouponInfoList(data: CouponInfoListReq): Promise<CouponInfoListResp> {
    return this.requestService.authGet(this.GET_COUPON_INFO_PAGE, data);
  }

  getCouponBatchLogList(data: CouponBatchLogListReq): Promise<CouponBatchLogListResp> {
    return this.requestService.authGet(this.GET_COUPON_BATCH_LOG_LIST, data);
  }

  downloadCouponInfoList(coupon_batch_id: number, coupon_number: string): string {
    let url = this.DOWNLOAD_COUPON_INFO_LIST + '?coupon_batch_id=' + coupon_batch_id;
    if (coupon_number != null && coupon_number.length !== 0) {
      url += '&coupon_number=' + coupon_number;
    }
    return url;
  }

  getCouponInfoBizRelationList(data: CouponInfoBizRelationListReq): Promise<CouponInfoBizRelationListResp> {
    return this.requestService.authGet(this.GET_COUPON_INFO_BIZ_RELATION_LIST, data);
  }

  getCouponInfoDetailByCouponNumber(data: CouponInfoDetailByCouponNumberReq): Promise<CouponInfoDetailByCouponNumberResp> {
    return this.requestService.authGet(this.GET_COUPON_INFO_DETAIL_BY_COUPON_NUMBER, data);
  }

  rechargeCouponInfo(data: CouponInfoRechargeReq): Promise<CouponInfoRechargeResp> {
    return this.requestService.authGet(this.RECHARGE_COUPON_INFO, data);
  }

  queryGetCouponUserInfo(data: QueryGetCouponUserInfoReq): Promise<QueryGetCouponUserInfoResp> {
    return this.requestService.authGet(this.QUERY_GET_COUPON_USER_INFO, data);
  }

  sendCoupon(data: QueryGetCouponUserInfoReq): Promise<any> {
    return this.requestService.authPost(this.SEND_COUPON, data);
  }
}
