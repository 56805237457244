import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CheckoutFinanceRoomSelectComponent} from "./checkout/checkout-finance-room-select/checkout-finance-room-select.component";
import {CheckoutRecordPreviewComponent} from "./checkout/checkout-record-preview/checkout-record-preview.component";
import {PactComponent} from './pact/pact.component';
import {ListComponent} from './list/list.component';
import {GoodsListComponent} from './goods-list/goods-list.component';
import {ContractComponent} from './contract/contract.component';
import {PrivilegeResolve} from '../../core/resolve/privilege.resolve';
import {SignChangeComponent} from './sign-change/sign-change.component';
import {CheoutListComponent} from './checkout/cheout-list/cheout-list.component';
import {CheckoutInventoryComponent} from './checkout/checkout-inventory/checkout-inventory.component';
import {ContractPreviewComponent} from './contract-preview/contract-preview.component';
import {CheckoutInventoryViewComponent} from './checkout/checkout-inventory-view/checkout-inventory-view.component';
import {CheckoutComponent} from './checkout/checkout/checkout.component';
import {GarageListComponent} from "./garage-list/garage-list.component";
import {GarageAddComponent} from "./garage-add/garage-add.component";
import {CheckoutRecordComponent} from "./checkout/checkout-record/checkout-record.component";
import {CarportSearchListComponent} from "./carport-list/carport-search-list.component";
import {CrChangeRoomCheckinInventoryComponent} from './change-room/cr-change-room-checkin-inventory/cr-change-room-checkin-inventory.component';
import {CrChangeRoomCheckoutInventoryViewComponent} from './change-room/cr-change-room-checkout-inventory-view/cr-change-room-checkout-inventory-view.component';
import {CrChangeRoomCheckoutInventoryComponent} from './change-room/cr-change-room-checkout-inventory/cr-change-room-checkout-inventory.component';
import {CrChangeRoomConfirmComponent} from './change-room/cr-change-room-confirm/cr-change-room-confirm.component';
import {CrChangeRoomContractComponent} from './change-room/cr-change-room-contract/cr-change-room-contract.component';
import {CrChangeRoomDetailsComponent} from './change-room/cr-change-room-details/cr-change-room-details.component';
import {CrChangeRoomBillDetailComponent} from './change-room/cr-change-room-bill-detail/cr-change-room-bill-detail.component';
import {TabTitleResolve} from "../../core/resolve/tab-title.resolve";
import {DelayRefundView} from './checkout/delay-refund-view/delay-refund-view.component';
import {CheckoutRoomContractComponent} from './checkout/checkout-room-contract/checkout-room-contract.component';
import {RenterCheckoutListComponent} from "./renter-checkout/renter-checkout-list/renter-checkout-list.component";
import {RenterCheckoutDetailComponent} from "./renter-checkout/renter-checkout-detail/renter-checkout-detail.component";

const routes: Routes = [
  {
    path: '',
    data: {
      title: '签约管理',
    },
    children: [
      // 签约
      {
        path: 'list',
        component: ListComponent,
        data: {
          title: '签约列表',
          OPERATION_PRIVILEGES: [
            'LEASE_QUERY',
            'LEASE_ADD',
            'LEASE_BILL',
            'TURN_TO_CARPORT_ROOM',
            'RE_MARK_WATER',
            'LEASE_DETAIL',
            'QUERY_USER_PRIVAYE',
            'LEASE_DOWNLOAD',
            'LEASE_LIST_FILTER',
          ],
          reuse: true
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      {
        path: 'garagelist',
        component: GarageListComponent,
        data: {
          title: '车库签约列表',
          OPERATION_PRIVILEGES: [
            'CARPORT_LEASE_ADD',
            'CARPORT_LEASE_BILL',
            'CARPORT_LEASE_QUERY'
          ],
          reuse: true
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      {
        path: 'garageadd',
        component: GarageAddComponent,
        data: {
          title: '新增车库/位',
          reuse: true
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },

      // 租约详情-合同签约
      {
        path: 'contract/:lease_id',
        component: ContractComponent,
        data: {
          title: '合同签约'
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },

      {
        path: 'pact/:leaseId',
        component: PactComponent,
        data: {
          title: '租约详情',
          OPERATION_PRIVILEGES: [
            'CANCLE_LEASE_BTN',
            'LEASE_CONTRACT_SIGN',
            'LEASE_CONTRACT_PRINT',
            'LEASE_GATHERING_BTN',
            'LEASE_ROOM_CHECKIN',
            'LEASE_RELET_BTN',
            'LEASE_CHANGE_BTN',
            'LEASE_ROOM_CHECKOUT',
            'LEASE_CONTRACT_VIEW',
            'LEASE_CONTRACT_UPLOAD',
            'LEASE_REFUND_BTN',
            'SUBLEASE_SIGN_BTN',
            'LEASE_FILE_DELETE',
            'LEASE_FILE_VIEW',
            'BATCH_IMPORT_RENTER',
            'CHECKIN_GOODS_SIGN',
            'CHECKOUT_GOODS_SIGN',
            'RENTER_SETTING_BTN',
            'LEASE_BILL_SETTING',
            'BILL_RECONCILIATION',
            'BILL_ADVANCE',
            'CLIENT_COMPANY_USER_CHANGE',
            'OPEN_COMPANY_PREPAY_ACCOUNT',
            'COMPANY_PREPAY_FIVE',
            'COMPANY_PREPAY_ARREARS',
            'COMPANY_PREPAY_SETTING',
            'COMPANY_PREPAY_RECHARGE',
            'COMPANY_PREPAY_RECORD',
            'BATCH_RECHARGE_PREPAY',
            'COMPANY_PREPAY_RECHARGE_RECORD',
            'APPLY_QUERY_USER_PRIVAYE',
            'ELE_DIFF_BTN'
            ]
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      {
        path: 'inventorylist',
        component: CheckoutInventoryComponent,
        data: {
          title: '物品交接单',
          OPERATION_PRIVILEGES: []
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      {
        path: 'inventoryview',
        component: CheckoutInventoryViewComponent,
        data: {
          title: "物品交接单",
          OPERATION_PRIVILEGES: []
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      {
        path: 'sign-change/:lease_id/:lease_status',
        component: SignChangeComponent,
        data: {
          OPERATION_PRIVILEGES: [
            'LEASE_ADD',
            'LEASE_DETAIL',
          ]
        },
        resolve: {
          operation_priviliges: PrivilegeResolve
        }
      },
      {
        path: 'goods/:lease_id/:room_id/:lease_room_id',
        component: GoodsListComponent,
        data: {
          title: '物品交接单'
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      {
        path: 'contract/preview/:lease_id',
        component: ContractPreviewComponent,
        data: {
          title: '合同预览'
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },

      // 退房
      {
        path: 'checkout',
        component: CheckoutComponent,
        data: {
          title: '退房',
          OPERATION_PRIVILEGES: []
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      // 延迟退款操作-退房退款
      {
        path: 'checkoutRefund',
        component: DelayRefundView,
        data: {
          title: '退房退款',
          OPERATION_PRIVILEGES: []
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      // 延迟退款操作-退房退款转收入
      {
        path: 'checkoutRefundIncome',
        component: DelayRefundView,
        data: {
          title: '退房退款转收入',
          OPERATION_PRIVILEGES: []
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      {
        path: 'checkoutlist',
        component: CheoutListComponent,
        data: {
          title: '退房',
          OPERATION_PRIVILEGES: []
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      {
        path: 'checkoutrecord',
        component: CheckoutRecordComponent,
        data: {
          title: '退房单',
          OPERATION_PRIVILEGES: []
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      {
        path: 'checkoutroom',
        component: CheoutListComponent,
        data: {
          title: "运营 / 签约管理 / 退房",
          OPERATION_PRIVILEGES: []
        },
        resolve: {
          operation_priviliges: PrivilegeResolve
        }
      },

      // ------------- 以下为换房相关--------------
      {
        path: 'change/room/confirm/:record_id',
        component: CrChangeRoomConfirmComponent,
        data: {
          title: '换房确认',
          OPERATION_PRIVILEGES: []
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      {
        path: 'change/room/checkout/inventory',
        component: CrChangeRoomCheckoutInventoryComponent,
        data: {
          title: '退房/物品交接单确认',
          OPERATION_PRIVILEGES: []
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      {
        path: 'change/room/checkout/inventory/view',
        component: CrChangeRoomCheckoutInventoryViewComponent,
        data: {
          title: '退房/物品交接单',
          OPERATION_PRIVILEGES: []
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      {
        path: 'change/room/checkin/inventory',
        component: CrChangeRoomCheckinInventoryComponent,
        data: {
          title: '入住/物品交接单确认',
          OPERATION_PRIVILEGES: []
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      {
        path: 'change/room/bill/details/:record_id',
        component: CrChangeRoomBillDetailComponent,
        data: {
          title: '换房单',
          OPERATION_PRIVILEGES: []
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      {
        path: 'change/room/contract/:record_id',
        component: CrChangeRoomContractComponent,
        data: {
          title: '换房单签署',
          OPERATION_PRIVILEGES: []
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      {
        path: 'change/room/details/:record_id',
        component: CrChangeRoomDetailsComponent,
        data: {
          title: '换房单/协议',
          OPERATION_PRIVILEGES: []
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },

      // 车库
      {
        path: 'carport/list',
        component: CarportSearchListComponent,
        data: {
          title: '车库房源列表',
          OPERATION_PRIVILEGES: ['ADD_CARPORT','TAKE_OFF_CARPORT'],
          reuse: true
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      // 测试用的
      {
        path: 'checkoutroomcontract',
        component: CheckoutRoomContractComponent,
        data: {
          title: '合同房间退房',
          OPERATION_PRIVILEGES: [],

        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },

      // 退房费用结算
      {
        path: 'checkout/finance/roomselect/:leaseId',
        component: CheckoutFinanceRoomSelectComponent,
        data: {
          title: '退房费用结算',
          OPERATION_PRIVILEGES: [],

        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      {
        path: 'checkoutrecord/preview/:record',
        component: CheckoutRecordPreviewComponent,
        data: {
          title: '退房单',
          OPERATION_PRIVILEGES: []
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      {
        path: 'renter/checkout/record/list/:leaseId',
        component: RenterCheckoutListComponent,
        data: {
          title: '退宿记录',
          OPERATION_PRIVILEGES: []
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      {
        path: 'renter/checkout/record/detail/:recordId',
        component: RenterCheckoutDetailComponent,
        data: {
          title: '退宿费用结算',
          OPERATION_PRIVILEGES: []
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SignedRoutingModule {
}
