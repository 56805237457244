import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {IMyDpOptions} from "../../third-party/mydatepicker/src/lib/interfaces";
import {ModalDirective} from "ngx-bootstrap/modal";
import {SignDataRequest, SignOnIpadRequest} from '../../../../app/model/signed/sign.model';
import "../../../../assets/js/views/axCam_Ocx.js";
import "../../../../assets/js/views/axCam_Ocx2.js";
import "../../../../assets/js/views/OcxUtil.js";
import "../../../../assets/js/views/WsUtil.js";
import {environment} from "../../../../environments/environment";
import {formatYYYYmmdd, getTimeFromPlugin} from "../../../common/date.util";
import {RegularExp} from "../../../common/util.regexp";
import {EmergencyContactInfoDTO} from "../../../model/booking/booking-detail.model";
import {ProjectConfigParamValueDTO, WorkModel} from "../../../model/common/common.model";
import {DevElemeterInfoDTO, DevWatermeterInfoDTO} from "../../../model/device/device.model";
import {ConfirmPaymentRequest} from "../../../model/finance/bill.model";
import {BaseEnum} from "../../../model/modal/base-model";
import {MessageModalInfo} from "../../../model/modal/message-model";
import {OrderInfoDTO} from "../../../model/order/order.model";
import {PrepayRechargeWithdrawReqeust} from "../../../model/prepay/preapy.model";
import {ProjectTemplateRequest} from "../../../model/project/project.model";

import {
  CalFreeTimeReq,
  CalFreeTimeResp,
  CheckInParams,
  CreateLesseeParams,
  LeaseRoomRentRequestParam,
  Lessee,
  MatchLesseeParams,
  ProjectExtInfoValue,
  RenterAddExtInfo,
  RenterRepeatRequest,
  RoomListParams
} from "../../../model/renter/renter.model";
import {
  RoomEquipmentIndicationDTO,
  RoomEquipmentIndicationRequest,
  RoomEquipmentOrIndicationRequest,
  RoomInfo
} from "../../../model/room/room.model";
import {AuthService} from "../../../service/common/auth.service";
import {BaseInfoService} from "../../../service/common/base-info.service";
import {WorkService} from "../../../service/common/work.service";
import {DeviceService} from "../../../service/device/device.service";
import {BillService} from "../../../service/finance/bill.service";
import {FinanceService} from "../../../service/finance/finance.service";
import {IdempotentService} from "../../../service/idempotentService/IdempotentService";
import {ImageQrcodeService} from "../../../service/image/image_qrcode.service";
import {LogService} from "../../../service/log/log.service";
import {OrderService} from "../../../service/order/order.service";
import {PrepayService} from "../../../service/prepay/prepay.service";
import {ProjectService} from "../../../service/project/project.service";
import {IdReaderService} from "../../../service/renter/id_reader.service";
import {RenterService} from "../../../service/renter/renter.service";
import {RoomService} from "../../../service/room/room.service";
import {RoominspentService} from "../../../service/roominspent/roominspent.service";
import {SignedService} from "../../../service/signed/signed.service";
import {ClientuserService} from "../../../service/user/clientuser.service";
import {UserService} from "../../../service/user/user.service";
import {InventoryComponent} from "../../shared/inventory/inventory.component";
import {NewFileUploadComponent} from "../../shared/new-file-upload/new-file-upload.component";
import {SignDeviceBindingComponent} from "../../sign-device-binding/sign-device-binding.component";
import {TabletComponent} from "../../signed/tablet/tablet.component";


// declare var OcxInit: any;
declare var WsInit,getImageStr,captureImagePz,stop : any;
// declare var CAM_Open:any;

@Component({
  selector: 'app-lessee',
  templateUrl: './lessee.component.html',
  styleUrls: ['./lessee.component.css']
})
export class LesseeComponent implements OnInit, OnDestroy {
  @ViewChild('meterInventory', { static: true }) meterInventory: InventoryComponent;
  @ViewChild("appFileUpload") appFileUpload: NewFileUploadComponent;
  @ViewChild(TabletComponent, { static: true }) tabletComponent: TabletComponent;
  // @ViewChild('print') print: PrintPreviewComponent;
  @ViewChild(SignDeviceBindingComponent, { static: true }) signDeviceBindingComponent:SignDeviceBindingComponent;
  @ViewChild('renterModal') renterModal: ModalDirective;
  @ViewChild('handInputModal') handInputModal: ModalDirective;
  @ViewChild('confirmModal') confirmModal: ModalDirective;
  @ViewChild('createRenterModal') createRenterModal: ModalDirective;
  @ViewChild('conventionModal') conventionModal: ModalDirective;
  @ViewChild('successModal') successModal: ModalDirective;
  @ViewChild('successModal2') successModal2: ModalDirective;
  @ViewChild('cameraModal') cameraModal: ModalDirective;
  @ViewChild('prepaygatherBill') prepay_gather_bill: ModalDirective;
  @ViewChild('prepaygatherSuccess') prepaygatherSuccess: ModalDirective;
  @Output() nextCheckIn = new EventEmitter<number>();

  @Input()
  subjectType:string;
  @Input()
  lease_id: number;
  @Input()
  new_room_id: number;
  @Input()
  new_room_name: string;
  @Input()
  went_dutch: boolean;

  // renterModal: any;
  // handInputModal: any;
  // confirmModal: any;
  // createRenterModal: any;
  // conventionModal: any;
  // successModal: any;
  // successModal2: any;
  // cameraModal:any;

  title: string = '请住户在下方写字板内签署姓名';
  imgStr: string;
  //证件类型
  cretTypeList: BaseEnum[];

  renterAddExt:Array<RenterAddExtInfo> = new Array<RenterAddExtInfo>();
  //选择的证件类型
  selectCretType: any = 'ID_CD';
  selectCretDesc: string = '身份证';
  //上传的图片列表
  imgList = [];

  // 账单关联操作
  orderRelevance = false;

  //匹配入住人参数
  matchLesseeReqParam: MatchLesseeParams = {
    name: '',
    cret_num: '',
    lease_id: null,
    sex: ''
  };
  //办理入住参数
  checkInReqparam: CheckInParams = {
    info_id: null,
    lease_id: null,
    room_id: null,
    img_list: null
  };
  //创建入住人参数
  createRenterReqParam: CreateLesseeParams = {
    room_id: null,
    lease_id: null,
    name: '',
    sex: 'MALE',
    phone: '',
    cret_type: '',
    cret_num: '',
    register_vip: true,
    contact_name1: '',
    contact_phone1: '',
    contact_name2: '',
    contact_phone2: '',
    img_url_list: [],
    subsidiary: '',
    bedding: null,
    key_num: null,
    project_ext_info_values:new Array<ProjectExtInfoValue>(),
    free_start_time: null,
    free_end_time: null,
    is_add: 0,
  };
  //合同紧急联系人数组
  emergencys: EmergencyContactInfoDTO[];

  //查询可办理入住房间的参数
  roomListReqParam: RoomListParams = {
    lease_id: null
  };
  //房间列表
  roomList: Array<RoomInfo>;
  //匹配结果
  lesseeList: Array<Lessee>;
  lessee = new Lessee();
  //选择的房间ID+资产状态
  selectRoomInfo: string = '';
  //选择的房间资产状态
  selectRoomAssetConfirm: string;
  selectRoomId: number;
  selectLeaseRoomId: number;

  display: boolean = false;

  setTimeoutName: any;

  leaseTel: string = '';

  check_sex: boolean;
  //是否线上签字
  online_sign: boolean = false;
  @Output() renterChange = new EventEmitter();

  renterName: string;
  roomName: string;
  renterDeposit: number = 0;
  // 加密密文用于h5上传证件
  identificationKey: string;
  // 身份证正面
  idCardFront: string;
  // 身份证反面
  idCardReverse: string;
  // 护照
  passport: string;

  qrCodePath: string;

  qrCodeIsShow: boolean = true;

  timer;

  is_read_card: boolean = false;

  template_content: string;

  roomDoorLockList: any[];

  roomDoorLockId: number;

  //是否待入住人扫码
  is_scan: boolean = false;

  // 免租期设置
  rentFree: boolean = false;
  free_time_disabled: boolean = true;
  free_start_time: any;
  free_end_time: any;
  lease_end_time: any;
  select_free: string = "0";
  select_free_disabled: boolean = true;

  calFreeTimeReq: CalFreeTimeReq = {
    select_free: 0,
    free_start_time: null,
    lease_id: null
  };
  calFreeTimeResp?: CalFreeTimeResp;

  // 入住押金支付
  paymentType: string;
  paymentTypeName: string;
  showContent: boolean;
  close: number;
  clock: number;
  orderNum = 0;
  hasChosenPayType: string;
  aliOrWeixinDialog: any;
  @ViewChild("bindOrderBill")bindOrderBillDialog: ModalDirective;
  check_order_no: string;
  prepaycashPaymentDialog: any;
  prepaygatherBillDialog: any;
  prepayposPaymnetDialog: any;
  prepaybindOrderBillDialog: any;
  // prepayorderQueryDialog: any;
  prepayRechargeWithdrawReq: PrepayRechargeWithdrawReqeust = {};
  hasChoseLeaseId: number = 0;
  hasChoseRoomId: number = 0;
  orderDtos: Array<OrderInfoDTO> = [];
  orderId: string;
  orderRemind: string;
  //收款确认请求参数
  confirmPaymentRequest = new ConfirmPaymentRequest();
  idempotent_key: string;

  // 匹配入住人的手机
  matchRenterPhone: string;
  // 已经发送过密码
  hasSent: boolean;

  //yundun 3.0
  //是否需要上传入住人员证件照片(1: 需要, 0: 不需要)
  need_renter_pic?: number;
  //是否需要入住人员在线签署入住公约(1: 需要, 0: 不需要)
  need_online_sign_convention: number;
  //是否需要启用电子合同(1: 需要, 0: 不需要)
  need_use_electronic_contract: number;
  //是否需要签署租赁合同(1: 需要, 0: 不需要)
  need_lease_contract?: number;

  //是否需要签署并确认房间内的物品清单
  need_room_goods_list: number;

  //智能电表 NONE 无需抄表 SMART 智能电表 MANUAL 手动抄表
  ele_type: string;
  cold_water_type: string;
  hot_water_type: string;


  info_id: number;
  getProjectNature: any;

  // @ViewChild('printPreview') printPreview: PrintPreviewComponent;

  @ViewChild('checkInEqumentModal', { static: true }) checkInEqumentModal: ModalDirective;

  contractPath: string;

  error_cret_num: string = 'SUCCESS';

  elemeter_info_dto: DevElemeterInfoDTO;

  cold_watermeter_info_dto: DevWatermeterInfoDTO;

  hot_watermeter_info_dto: DevWatermeterInfoDTO;

  is_sumbit: boolean = false;
  //水电读数
  cold_number: string;
  hot_number: string;
  ele_number: string;


  renter_person_type_list: Array<ProjectConfigParamValueDTO> = [];

  is_loading = false;
  is_init = false;
  identifiy_flag = false;
  needBedding = false;
  needKeyNum = false;

  // pzText = "拍照读取身份证信息";

  isInit = true;
  now: Date = new Date();
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    disableDateRanges: [{
      begin: {year: 1999, month: 1, day: 1},
      end: {year: this.now.getFullYear(),  month: (this.now.getMonth() + 1), day: this.now.getDate() - 1},
    }]
  };

  constructor(private baseInfoService: BaseInfoService,
              private renterService: RenterService,
              private idReaderService: IdReaderService,
              private workService: WorkService,
              private router: Router,
              private imageQrcodeService: ImageQrcodeService,
              private clientuserService: ClientuserService,
              private signedService: SignedService,
              private projectService: ProjectService,
              private roominspentService: RoominspentService,
              private idempotentService: IdempotentService,
              private deviceService: DeviceService,
              private authService: AuthService,
              private roomService: RoomService,
              private financeService: FinanceService,
              private prepayService: PrepayService,
              private billService: BillService,
              private orderService: OrderService,
              private logService: LogService,
              private userService: UserService) {
  }

  ngOnDestroy() {
    this.clearTimer();
    stop();
  }

  clearTimer() {
    clearInterval(this.timer);
  }

  ngOnInit() {
    /*this.matchLesseeReqParam.lease_id = this.lease_id;
    this.meterInventory.lessee.subscribe((id) => {
      this.lease_id = id;
      // this.openRenters();
      if (this.matchLesseeReqParam != null && this.matchLesseeReqParam.cret_num != null) {
        this.selectRoomAssetConfirm = "CONFIRMED";
        this.openModalStaticModal19(false);
      } else {
        this.openRenters();
      }
    });*/
    // setTimeout(()=>{
    //   this.conventionModal.show();
    // },1000)

    //this.getHouseholdConvention();
    this.selectCretType = 'ID_CD';
    this.selectCretDesc = '身份证';
    this.userService.userProjectInfo(this.authService.getProjectId()).then(x => {
      this.need_room_goods_list = x.project.need_room_goods_list;
      this.need_lease_contract = x.project.need_lease_contract;
      this.need_renter_pic = x.project.need_renter_pic;
      this.need_online_sign_convention = x.project.need_online_sign_convention;
      this.need_use_electronic_contract = x.project.need_use_electronic_contract;
      this.ele_type = x.project.ele_type;
      this.cold_water_type = x.project.cold_water_type;
      this.hot_water_type = x.project.hot_water_type;
      this.getProjectNature = this.authService.getProjectNature();
    });


  }

  // 确认入住人
  sendPhone: string;

  getHouseholdConvention(){
    // 获取公约
    const req  = new ProjectTemplateRequest();
    const param_map = new Map();
    req.file_type = 'CHECKINCONVENTION';
    req.param_map = param_map;
    this.projectService.getProjectTemplateConfigure(req).then(data => {
      this.template_content = data.template_content;
      // todo me
     /* if(!this.hasSent){
        this.roomDoorLockId = Number($("input[name='ensure_choosed_lock_radio']:checked").val());
      }
      if(!this.hasSent && this.roomDoorLockList.length == 2 && this.roomDoorLockId == null){
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请选择门锁"));
        return;
      }*/
    });
  }

  scanIdCardInfo() {
    this.is_scan = true;
    this.idReaderService.readcardinfo(this.matchLesseeReqParam);
    setTimeout(()=>{
      this.selectCretType = 'ID_CD';
      this.selectCretDesc = '身份证';
      this.createRenterReqParam.sex = this.matchLesseeReqParam.sex === '男' ? 'MALE' : 'FEMALE';
      this.error_cret_num = 'SUCCESS';
    },1000);
  }

  getCardInfoByCamera(){
    // this.hideRenterModal();
    // this.cameraModal.show();
    if (!this.is_init) {
      this.loadActiveX();
      this.is_init = true;
    }
    if (!this.is_loading){
      this.is_loading = true;
      this.identifiy_flag = true;
      setTimeout(()=>{
        this.captureImage("front",10);
      },500);
    }
  }


  loadActiveX() {
      //其他浏览器加载控件
      document.getElementById("ActiveXDivOne").innerHTML = " <canvas id=\"cameraCanvas\" width=\"500px\" height=\"400px\" style=\"border:1px solid #d3d3d3;\">";
      WsInit(500, 400, 500, 400, true);
  }

  captureImage(imgType:string,num){
    num--;
    if(num > 0){
      // 拍照
      captureImagePz();
      // 获取图片
      this.getImageStrBase64((str)=>{
        let s = str.substr(str.length - 2,2);
        str = s !== "=="?str.substr(0,str.length-1)+"=":str;
        if(s !== "\/\/"){
          this.captureIdentifiyInfo(str,imgType);
        }else{
          this.captureImage(imgType,num);
        }
      },15);
    }
  }

  captureIdentifiyInfo(basecode :string,imgType:string){
    this.roominspentService.captureIdentifiyInfo({base_code:basecode,identifiy_flag:this.identifiy_flag}).then(data =>{
      if (data){
        this.selectCretType = 'ID_CD';
        this.selectCretDesc = '身份证';
        if (imgType === "front"){
          this.matchLesseeReqParam.name = data.name;
          this.matchLesseeReqParam.sex = data.sex;
          this.matchLesseeReqParam.cret_num = data.cret_num;
          this.createRenterReqParam.sex = this.matchLesseeReqParam.sex === '男' ? 'MALE' : 'FEMALE';
          this.idCardFront =  data.pic_url;
        }else if(imgType === "back"){
          this.idCardReverse = data.pic_url;
        }
        let list = [];
        if (this.idCardFront) {
          list.push(this.idCardFront);
        }
        if (this.idCardReverse) {
          list.push(this.idCardReverse);
        }
        if (this.passport) {
          list.push(this.passport);
        }
        this.imgList = list;
        this.error_cret_num = 'SUCCESS';
        this.is_loading = false;
      }
    }).catch(e=>{
      this.is_loading = false;
    });
  }

  getImageStrBase64(callback,num:number){
    num--;
    let ts = this;
    setTimeout(()=>{
      let str = getImageStr();
      if(str && typeof(str) === "string"){
        callback(str);
      }else{
        if(num >= -15){
          if(num === 0){
            // 重新初始化后在尝试一次
            this.loadActiveX();
          }
          ts.getImageStrBase64(callback,num);
        }else {
          this.is_loading = false;
          this.workService.showErrorModal("设备初始化失败，请检查设备连接后刷新页面重试！");
        }
      }
    },500);
  }


  captureBackID(){
    if (!this.is_loading){
      this.is_loading = true;
      this.identifiy_flag = false;
      this.captureImage("back",10);
    }
  }


  openRenters() {

    //获取证件类型
    this.getCretType();

    this.getPersonTye();

    this.getRenterExtInfo();

    this.clearParam();
    this.roomListReqParam.lease_id = this.lease_id;
    this.selectRoomId = this.new_room_id;
    //是否需要确认房间设备读数
    if(this.need_room_goods_list === 1) {
      let req = new RoomEquipmentIndicationRequest();
      let ids = [];
      ids.push(this.selectRoomId);
      req.room_ids = ids;
      req.type = "checkin";
      this.roomService.getRoomEquipmentOrIndication(req).then(res => {
        if(res && res.rooms){
          let room = res.rooms[0];
          if(room.confirm){
            this.elemeter_info_dto = room.elemeter_info_dto;
            if(this.elemeter_info_dto){
              this.ele_number = this.elemeter_info_dto.indication === -100 ? null :  this.elemeter_info_dto.indication+'';
            }
            this.cold_watermeter_info_dto = room.cold_watermeter_info_dto;
            if(this.cold_watermeter_info_dto){
              this.cold_number = this.cold_watermeter_info_dto.indication === -100 ? null :  this.cold_watermeter_info_dto.indication+''
            }
            this.hot_watermeter_info_dto = room.hot_watermeter_info_dto;
            if(this.hot_watermeter_info_dto){
              this.hot_number =  this.hot_watermeter_info_dto.indication === -100 ? null :  this.hot_watermeter_info_dto.indication+''
            }
            if((this.elemeter_info_dto && this.elemeter_info_dto.indication)
              || (this.cold_watermeter_info_dto && this.cold_watermeter_info_dto.indication)
              || (this.hot_watermeter_info_dto && this.hot_watermeter_info_dto.indication) ){
              this.is_sumbit = true;
            }
            this.checkInEqumentModal.show();
          }else{
            this.openCheckIn();
          }
        }
      });
    }else{
      this.openCheckIn();
    }

  }
  openCheckIn(){
    this.getRoomDoorLockList();
    this.renterModal.show();
  }

  checkRoomIndication(checkInEqumentModal:any,successCheckinModal: any){
    var reg=/^\d+(\.\d{0,2})?$/;
    if(this.elemeter_info_dto){
      if(!reg.test(this.ele_number)){
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "电表读数格式有误"));
        return;
      }
      if(this.checkNumber(this.ele_number)){
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "电表读数格式有误"));
        return;
      }
      this.elemeter_info_dto.indication = +this.ele_number;
    }
    if(this.cold_watermeter_info_dto){
      if(!reg.test(this.cold_number)){
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "水表读数格式有误"));
        return;
      }
      if(this.checkNumber(this.cold_number)){
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "水表读数格式有误"));
        return;
      }
      this.cold_watermeter_info_dto.indication = +this.cold_number;
    }
    if(this.hot_watermeter_info_dto){
      if(!reg.test(this.hot_number)){
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "热水表读数格式有误"));
        return;
      }
      if(this.checkNumber(this.hot_number)){
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "热水表读数格式有误"));
        return;
      }
      this.hot_watermeter_info_dto.indication = +this.hot_number;
    }
    checkInEqumentModal.hide();
    successCheckinModal.show();
  }

  confimDs(){
    let req = new RoomEquipmentOrIndicationRequest();
    let dto = new RoomEquipmentIndicationDTO();
    dto.elemeter_info_dto = this.elemeter_info_dto;
    dto.cold_watermeter_info_dto = this.cold_watermeter_info_dto;
    dto.hot_watermeter_info_dto = this.hot_watermeter_info_dto;
    dto.room_id = this.selectRoomId;
    let rooms = [];
    rooms.push(dto);
    req.rooms = rooms;
    req.type = "checkin";
    this.roomService.setRoomEquipmentOrIndication(req).then(res => {
      if(res && res.result === "SUCCESS"){
        this.openCheckIn();
      }else{
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "房间设备读数确认失败！"));
      }
    });
  }


  checkNumber(str: string){
    let result = (str).indexOf(".");
    let rst = false;
    if(result != -1) {
      let s = str.toString().split('.');
      if(!s[1]){
        rst = true;
      }
    }
    return rst;
  }

  hideRenterModal() {
    this.renterModal.hide();
    clearInterval(this.setTimeoutName);
    this.clearParam();
    // this.cameraModal.hide();
    // this.pzText = "拍照读取身份证信息";
  }

  openInventory(modal: any) {
    // this.clearParam();
    modal.hide();

    this.meterInventory.openModal();
  }

  // setLesseeModal(modal1: any, modal2: any, modal3: any, modal4: any, modal5: any, modal6: any, modal7: any, modal8 :any) {
  //   this.renterModal = modal1;
  //   this.handInputModal = modal2;
  //   this.confirmModal = modal3;
  //   this.createRenterModal = modal4;
  //   this.conventionModal = modal5;
  //   this.successModal = modal6;
  //   this.successModal2 = modal7;
  //   this.cameraModal = modal8;
  // }

  openModalStaticModal18(): void {
    //this.hideRenterModal();
    //加载证件类型
    /*this.baseInfoService.getCretTypeList().then(data => {
      if (data && data.base_enum_dto_list) {
        this.cretTypeList = data.base_enum_dto_list;
        this.renterModal.hide();
        this.handInputModal.show();
        // 加载二维码
        this.imageQrcodeService.qrcodePath({"busy_type": "PERSON"}).then(data2 => {
          this.qrCodePath = data2.qr_code_path;
          this.identificationKey = data2.identification_key;
          this.qrCodeIsShow = true;
          // 触发每2秒查询一次证件
          this.getImgUrl(this.identificationKey);
        });
      }
    });*/

    //yundun 3.0
    this.renterModal.hide();
    this.handInputModal.show();
    // 加载二维码
    this.imageQrcodeService.qrcodePath({"busy_type": "PERSON"}).then(data2 => {
      this.qrCodePath = data2.qr_code_path;
      this.identificationKey = data2.identification_key;
      this.qrCodeIsShow = true;
      // 触发每2秒查询一次证件
      this.getImgUrl(this.identificationKey);
    });

  }

  qrCode() {
    this.imageQrcodeService.qrcodePath({"busy_type": "PERSON"}).then(data2 => {
      this.qrCodePath = data2.qr_code_path;
      this.identificationKey = data2.identification_key;
      this.qrCodeIsShow = true;
      // 触发每2秒查询一次证件
      this.getImgUrl(this.identificationKey);
    });
  }

  getImgUrl(key: string) {
    let th = this;
    if (th && th.identificationKey) {
      this.timer = setTimeout(function () {
        th.imageQrcodeService.leaseIdentification({"identification_key": key}).then(data => {
          if (key === th.identificationKey) {
            if (data != null) {
              if (data.id_card_front != null) {
                th.idCardFront = data.id_card_front;
              }
              if (data.id_card_reverse != null) {
                th.idCardReverse = data.id_card_reverse;
              }
              if (data.passprot != null) {
                th.passport = data.passprot;
              }
              let list = [];
              if (th.idCardFront) {
                list.push(th.idCardFront);
              }
              if (th.idCardReverse) {
                list.push(th.idCardReverse);
              }
              if (th.passport) {
                list.push(th.passport);
              }
              th.imgList = list;
            }
            th.getImgUrl(th.identificationKey);
          }
        });
      }, 3000);
    }
  }

  changeCretType(): void {
    if (this.selectCretType === 'ID_CD') {
      this.selectCretDesc = '身份证';
    } else if (this.selectCretType === 'PASSPORT') {
      this.selectCretDesc = '护照';
    }
    this.changeCretnum();
    this.imgList = [];
    delete this.idCardFront;
    delete this.idCardReverse;
    delete this.passport;
    this.appFileUpload.removeAll();
  }

  fileChange($event: any, type: string) {
    if ("idCardFront" === type) {
      this.idCardFront = $event && $event.length > 0 ? $event[0] : null;
    } else if ("idCardReverse" === type) {
      this.idCardReverse = $event && $event.length > 0 ? $event[0] : null;
    } else if ("passport" === type) {
      this.passport = $event && $event.length > 0 ? $event[0] : null;
    }
    let list = [];
    if (this.idCardFront != null) {
      list.push(this.idCardFront);
    }
    if (this.idCardReverse != null) {
      list.push(this.idCardReverse);
    }
    if (this.passport != null) {
      list.push(this.passport);
    }
    this.imgList = list;
  }

  openModalStaticModal19(flag: boolean): void {
    this.renterModal.hide();
    this.clearTimer();
    if (flag) {
      if (this.selectCretType === 'ID_CD') {
        if (this.imgList.length < 2) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "必须上传2张照片"));
          return;
        }
      } else if (this.selectCretType === 'PASSPORT') {
        if (this.imgList.length < 1) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "至少上传1张照片"));
          return;
        }
      }
      if (!this.matchLesseeReqParam.name) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请填写姓名"));
        return;
      }
      if (!this.matchLesseeReqParam.cret_num) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请填写证件号"));
        return;
      }
      let ID_CARD_REG = /^[A-Za-z0-9]{18}$/;
      let OTHER_REG = /^[A-Za-z0-9]{6,20}$/;
      if (this.selectCretType === 'ID_CD') {
        if (!ID_CARD_REG.test(this.matchLesseeReqParam.cret_num)) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请填写正确的身份证号（身份证为18位，护照为6-20字符以内）"));
          return;
        }
      } else {
        if (!OTHER_REG.test(this.matchLesseeReqParam.cret_num)) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请填写正确的证件号（身份证为18位，护照为6-20字符以内）"));
          return;
        }
      }
    }else{
      this.is_read_card = true;
      // 加载二维码
      this.imageQrcodeService.qrcodePath({"busy_type": "PERSON"}).then(data2 => {
        this.qrCodePath = data2.qr_code_path;
        this.identificationKey = data2.identification_key;
        this.qrCodeIsShow = true;
        // 触发每2秒查询一次证件
        this.getImgUrl(this.identificationKey);
      });
    }
    this.handInputModal.hide();
    //根据姓名和证件号匹配入住人
    this.renterService.matchLesseeList(this.matchLesseeReqParam).then(data => {
      if (data && data.lease_renter_dtolist) {
        this.lesseeList = data.lease_renter_dtolist;
        if (this.lesseeList.length === 0) {
          //弹出新增入住人窗口
          this.roomListReqParam.lease_id = this.lease_id;
          this.renterService.queryRoomList(this.roomListReqParam).then(data2 => {
            if (data2 && data2.room_info_list) {
              this.roomList = data2.room_info_list;
              this.leaseTel = data2.lease_tel;
              this.selectRoomInfo = this.roomList[0].room_id + "," + this.roomList[0].asset_confirm + "," + this.roomList[0].lease_room_id;
              this.createRenterModal.show();
              this.selectRoom();
            }
          });
        } else if (this.lesseeList.length === 1) {

          //弹出确认入住人窗口
          this.lessee = this.lesseeList[0];
          this.selectRoomId = this.lessee.room_id;
          this.selectLeaseRoomId = this.lessee.lease_room_id;
          if(flag){
            this.is_scan = false;
          }else{
            this.is_scan = true;
          }
          // 记录匹配到的入住人的手机号
          this.getRoomDoorLockList().then(data3 => {
            this.confirmModal.show();
          });
          this.matchRenterPhone = this.lesseeList[0].phone;
        } else {
          //弹出出错提示
          let roomNum = '';
          this.lesseeList.forEach(e => roomNum += (e.room_num + ","));
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR,
            "用此证件找到多个入住人，房间号为" + roomNum + "请更正入住人信息后再办理入住"));
          this.clearParam();
        }
      }
    });
  }

  checkIn() {
    if (this.lessee) {
      let leaseId = this.lessee.lease_id;
      this.renterName = this.lessee.name;
      this.roomName = this.lessee.room_name;
      this.info_id = this.lessee.info_id;
      this.sendPhone = this.lessee.phone;
      this.hasChoseLeaseId = this.lessee.lease_id;
      this.hasChoseRoomId = this.lessee.room_id;
    }

    if (this.renterDeposit > 0) {
      // 支付押金流程
      this.prepay_gather_bill.show();
    } else {
      this.createRenterReqParam.is_add = 1;
      this.renterService.createRenter(this.createRenterReqParam).then(res => {
        if (res && res.lease_renter_dtolist) {
          this.lessee = (res.lease_renter_dtolist)[0];
          this.confineCheckIn(this.successModal2);
        }
      });
    }
  }

  confineCheckIn(gatherSuccessModal?: any) {
    if (this.lessee) {
      this.checkInReqparam.info_id = this.lessee.info_id;
      this.checkInReqparam.lease_id = this.lessee.lease_id;
      this.checkInReqparam.room_id = this.lessee.room_id;
      this.checkInReqparam.img_list = this.imgList;
      this.renterService.checkIn(this.checkInReqparam).then(res => {
        if (res.result) {
          let messageModalInfo = new MessageModalInfo();
          messageModalInfo.operationMessage = res.result;
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
        } else {
          gatherSuccessModal.show();
          // TODO 发送门锁密码给用户
          if(this.roomDoorLockList && this.roomDoorLockList.length > 0 && this.roomDoorLockList.length === 2){
            this.deviceService.sendDoorPwdMsg({
              roomId: this.checkInReqparam.room_id,
              phoneNumber: this.sendPhone,
              roomDoorLockId: this.roomDoorLockId
            }).then(data => {

            });
          }
          this.clearParam();
        }
      });
    }
  }

  chooseConfirm(modal: any) {
    modal.show();
  }

  selectRoom() {
    if (this.selectRoomInfo) {
      let array = this.selectRoomInfo.split(",");
      this.selectRoomAssetConfirm = array[1];
      this.selectRoomId = parseInt(array[0], 0);
      this.selectLeaseRoomId = parseInt(array[2], 0);
      let param = new LeaseRoomRentRequestParam(this.lease_id, this.selectRoomId);
      this.renterService.queryLeaseRoomRenterList(param).then(res1 => {
        if (res1) {
          this.check_sex = res1.check_sex;
        }
        // 查询房间门锁
        this.getRoomDoorLockList();
      });
    }
  }

  createLeaseRenter() {
    this.is_scan = false;
    if (this.selectRoomId) {
      this.createRenterReqParam.room_id = this.selectRoomId;
    } else {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请选择房间"));
      return;
    }
    if (!this.createRenterReqParam.sex) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请选择性别"));
      return;
    }
    if (!this.createRenterReqParam.phone) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请填写手机号"));
      return;
    }
    if (!new RegExp(RegularExp.phone.mobile).test(this.createRenterReqParam.phone)) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请填写正确的手机号"));
      return;
    }

    if (this.renterAddExt.length > 0) {
      let error_name = null;
      let error_type = null;
      this.renterAddExt.forEach(ext =>{
        if(ext.is_required == 1 &&  (!ext.user_input_value || ext.user_input_value == "")){
          error_name = ext.name;
          error_type = ext.type == 'INPUT' ? '填写':'选择';
          return;
        }
      });
      if(error_name){
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请" + error_type + error_name));
        return;
      }
    }

    let tempLock = $("input[name='choosed_lock_radio']:checked").val();
    if(this.roomDoorLockList.length === 2 && !tempLock){
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请选择门锁"));
      return;
    }



    this.roomDoorLockId = Number($("input[name='choosed_lock_radio']:checked").val());
    this.hasSent = true;

    // 复制入住人phone
    this.matchRenterPhone = this.createRenterReqParam.phone;
    let param = new LeaseRoomRentRequestParam(this.lease_id, this.createRenterReqParam.room_id);
    this.renterService.queryLeaseRoomRenterList(param).then(res1 => {
      if (this.subjectType && this.subjectType === 'COMPANY' && res1 && !res1.check_sex && res1.lease_renter_dtolist) {
        let sex = res1.lease_renter_dtolist[0].sex;
        if (sex !== this.createRenterReqParam.sex) {
          let messageModalInfo = new MessageModalInfo();
          messageModalInfo.operationMessage = '不允许不同性别入住';
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
          return;
        }
      }
      //证件照
      if(!this.imgList || this.imgList.length === 0){
        let msg = new MessageModalInfo();
        msg.operationMessage = '请上传证件照片';
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, msg));
        return;
      }
      this.renterService.checkRoomOut(param).then(res2 => {
        if (res2 && res2.result_status) {
          let messageModalInfo = new MessageModalInfo();
          messageModalInfo.operationMessage = '该房间上一份合同到期尚未办理退房，请先为该房间办理退房？';
          messageModalInfo.callback.subscribe(data => {
            this.createRenterModal.hide();
            this.router.navigateByUrl('/system_manage/signed/pact/' + res2.result_id);
          });
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
          return;
        } else {
          this.createRenterReqParam.name = this.matchLesseeReqParam.name;
          this.createRenterReqParam.cret_type = this.selectCretType;
          this.createRenterReqParam.cret_num = this.matchLesseeReqParam.cret_num;
          this.createRenterReqParam.lease_id = this.lease_id;
          this.createRenterReqParam.img_url_list = this.imgList;

          this.fillParam();
          this.createRenterReqParam.is_add = 1;
          this.renterService.createRenter(this.createRenterReqParam).then(res => {
            if (res && res.lease_renter_dtolist) {
              this.lessee = (res.lease_renter_dtolist)[0];
              this.confirmModal.show();
              this.createRenterModal.hide();
            }
          });
        }
      });
    });
  }

  fillParam(){
    if(this.renterAddExt.length > 0){
      this.renterAddExt.forEach(ext => {
        let value = new ProjectExtInfoValue();
        value.name = ext.name;
        value.project_config_param_id = ext.id;
        value.value = ext.user_input_value;
        if(!this.createRenterReqParam.project_ext_info_values){
          this.createRenterReqParam.project_ext_info_values = new Array<ProjectExtInfoValue>();
        }
        this.createRenterReqParam.project_ext_info_values.push(value);
      });
    }
  }

  fillLeaseTel() {
    this.createRenterReqParam.phone = this.leaseTel;
  }

  closeModal(modal: any) {
    this.clearParam();
    modal.hide();
  }

  clearParam() {
    this.matchLesseeReqParam = new MatchLesseeParams();
    if (this.lease_id) {
      this.matchLesseeReqParam.lease_id = this.lease_id;
      this.matchLesseeReqParam.cret_num = '';
      this.matchLesseeReqParam.name = '';
      this.matchLesseeReqParam.sex = '';
    }
    this.createRenterReqParam = new CreateLesseeParams();
    this.createRenterReqParam.sex = 'MALE';
    this.lessee = new Lessee();
    this.imgList = [];

    this.idCardFront = null;
    this.idCardReverse = null;
    this.passport = null;

    this.roomList = new Array<RoomInfo>();
    this.selectRoomInfo = '';
    this.display = false;
    this.selectRoomAssetConfirm = '';
    this.appFileUpload.removeAll();
    this.selectCretType = 'ID_CD';
    this.selectCretDesc = '身份证';
    this.rentFree = false;
    this.leaseTel = '';
    this.free_time_disabled = true;
    this.select_free_disabled = true;
    this.free_start_time = null;
    this.free_end_time = null;
    this.select_free = "0";
  }

  //跳转物品交接单页面
  linkGoodsList(param1: number, param2: number, param3: number) {
    this.router.navigateByUrl('/system_manage/signed/goods/' + param1 + '/' + param2 + '/' + param3);
  }

  commitSign(imgStr: string) {
    this.imgStr = imgStr;
    this.renterService.uploadConvention({
      leaseId: this.lease_id,
      infoId: this.lessee.info_id,
      imgStr: this.imgStr
    }).then(data => {
      this.conventionModal.hide();
      this.checkIn();
    });

  }

  // 在ipad上签名
  signOnIpad(){

    let request = new SignOnIpadRequest();
    request.sign_router_link_type = 'CONVENTION';
    request.router_link = this.router.url;
    request.computer_no = localStorage.getItem('PC_UUID');
    let signDataRequest = new SignDataRequest();
    signDataRequest.lease_id = this.lessee.lease_id;
    signDataRequest.info_id = this.lessee.info_id;
    signDataRequest.room_id = this.lessee.room_id;
    signDataRequest.img_list = this.imgList;
    request.sign_data_request = signDataRequest;
    /*this.signedService.signOnIpad(request).then(data => {
      console.info(data);
    });*/

    // 打开连接弹窗
    this.signDeviceBindingComponent.openModel(request,1);
  }

  // 判断当前设备是否是ipad
  isPad(): boolean{
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone",
      "SymbianOS", "Windows Phone",
      "iPad", "iPod"];
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        return true;
      }
    }
    return false;
  }

  singChane(lease_id: any){
    this.conventionModal.hide();
    this.renterName = this.lessee.name;
    this.roomName = this.lessee.room_name;
    this.successModal2.show();
    this.renterChange.emit(lease_id);
    this.clearParam();
  }

  // 获取门锁
  getRoomDoorLockList(): Promise<any> {
    return  this.roominspentService.getRoomDoorLockList({"room_id":this.selectRoomId}).then(data => {
      this.roomDoorLockList = data.lock_list;
    });
  }

  getCretType(){
    this.baseInfoService.getCretTypeList().then(data => {
      if (data && data.base_enum_dto_list) {
        this.cretTypeList = data.base_enum_dto_list;
      }
    });
  }

  cclean(event: any){
    event.stopPropagation();//阻止向上冒泡
  }

  getLeaseReten(){
    this.renterService.matchLesseeList(this.matchLesseeReqParam).then(data => {
      if (data && data.lease_renter_dtolist) {
        //当前房间待入住人
        this.lesseeList = data.lease_renter_dtolist;
        if (this.lesseeList) {
          this.lesseeList.forEach(r =>{
            if(this.selectRoomId === r.room_id){
              this.lessee = this.lesseeList[0];
              this.selectRoomId = this.lessee.room_id;
              this.selectLeaseRoomId = this.lessee.lease_room_id;

              this.matchRenterPhone = this.lesseeList[0].phone;

              this.createRenterReqParam.phone = this.lesseeList[0].phone;

              this.matchLesseeReqParam.name = this.lesseeList[0].name;
              this.matchLesseeReqParam.sex = this.lesseeList[0].sex == 'FEMALE' ? '女' : '男';
              this.createRenterReqParam.subsidiary = this.lesseeList[0].subsidiary;

              if (this.lesseeList[0].cret_type === 'ID_CD') {
                this.selectCretDesc = '身份证';
              } else if (this.lesseeList[0].cret_type === 'PASSPORT') {
                this.selectCretDesc = '护照';
              }
            }
          });
        }
        /*if (this.lesseeList && this.lesseeList.length > 1) {
          //弹出出错提示
          let roomNum = '';
          this.lesseeList.forEach(e => roomNum += (e.room_num + ","));
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR,
            "用此证件找到多个入住人，房间号为" + roomNum + "请更正入住人信息后再办理入住"));
          this.clearParam();
        }*/
      }
    });
  }

  changeCretnum(){
    let ID_CARD_REG = /^[A-Za-z0-9]{18}$/;
    let OTHER_REG = /^[A-Za-z0-9]{6,20}$/;
    if (this.selectCretType === 'ID_CD') {
      if (!ID_CARD_REG.test(this.matchLesseeReqParam.cret_num)) {
        this.error_cret_num = '请输入正确格式的身份证号码';
        return;
      }else{
        this.error_cret_num = 'SUCCESS';
      }
    } else {
      if (!OTHER_REG.test(this.matchLesseeReqParam.cret_num)) {
        this.error_cret_num = '请输入正确格式的证件号';
        return;
      }else{
        this.error_cret_num = 'SUCCESS';
      }
    }
    this.getLeaseReten();
  }

  //是否需要上传证件照片
  isIdentificationPhoto(){
    if (this.selectRoomId) {
      this.createRenterReqParam.room_id = this.selectRoomId;
    } else {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "房间不存在"));
      return;
    }

    if (this.rentFree && this.free_start_time == null) {
      this.workService.showErrorModal("免租期：开始时间不可为空");
      return;
    }

    if (this.free_start_time != null && this.free_end_time != null) {
      let _start_time = getTimeFromPlugin(this.free_start_time.formatted);
      let _end_time = getTimeFromPlugin(this.free_end_time.formatted);
      if (_end_time < _start_time) {
        this.workService.showErrorModal("免租期：结束时间应大于开始时间");
        return;
      }

      // if (_end_time > this.lease_end_time) {
      //   this.workService.showErrorModal("免租期：结束日期不可大于租约结束日期");
      //   return;
      // }
      this.createRenterReqParam.free_start_time = _start_time;
      this.createRenterReqParam.free_end_time = _end_time;
    } else if (this.free_start_time != null && this.free_end_time == null) {
      this.createRenterReqParam.free_start_time = getTimeFromPlugin(this.free_start_time.formatted);
    }

    //一房两锁 选择门锁
    if(this.roomDoorLockList.length === 2 && !this.roomDoorLockId){
      //this.roomDoorLockId = Number($("input[name='ensure_choosed_lock_radio']:checked").val());
      //if(!this.roomDoorLockId){
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请选择门锁"));
        return;
      //}
    }
    if (!this.matchLesseeReqParam.name) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请填写姓名"));
      return;
    }

    if (!this.matchLesseeReqParam.sex) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请选择性别"));
      return;
    }

    if (!this.matchLesseeReqParam.cret_num) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请填写证件号"));
      return;
    }

    if (this.renterAddExt.length > 0) {
      let error_name = null;
      let error_type = null;
      this.renterAddExt.forEach(ext =>{
        if(ext.is_required == 1 &&  (!ext.user_input_value || ext.user_input_value == "")){
          error_name = ext.name;
          error_type = ext.type == 'INPUT' ? '填写':'选择';
          return;
        }
      });
      if(error_name){
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请" + error_type + error_name));
        return;
      }
    }

    if(this.error_cret_num !== 'SUCCESS'){
      return;
    }
    /*let ID_CARD_REG = /^[A-Za-z0-9]{18}$/;
    let OTHER_REG = /^[A-Za-z0-9]{6,20}$/;
    if (this.selectCretType === 'ID_CD') {
      if (!ID_CARD_REG.test(this.matchLesseeReqParam.cret_num)) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请填写正确的身份证号（身份证为18位，护照为6-20字符以内）"));
        return;
      }
    } else {
      if (!OTHER_REG.test(this.matchLesseeReqParam.cret_num)) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请填写正确的证件号（身份证为18位，护照为6-20字符以内）"));
        return;
      }
    }*/

    if (!this.createRenterReqParam.phone) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请填写手机号"));
      return;
    }
    if (!new RegExp(RegularExp.phone.mobile).test(this.createRenterReqParam.phone)) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请填写正确的手机号"));
      return;
    }
    this.createRenterReqParam.sex = this.matchLesseeReqParam.sex === '男' ? 'MALE' : 'FEMALE';


    let request = new RenterRepeatRequest();
    request.lease_id = this.lease_id;
    request.cret_num = this.matchLesseeReqParam.cret_num;
    request.phone = this.createRenterReqParam.phone;
    request.name = this.matchLesseeReqParam.name;

    this.renterService.checkInRepeat(request).then(data => {
      if (!data.check_cert_num) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "入住人【"+this.matchLesseeReqParam.name+""+this.matchLesseeReqParam.cret_num+"】已入住【"+data.room_name+"】房间，不能重复入住"));
        return;
      }
      if(!data.check_phone){
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "手机号码【"+this.createRenterReqParam.phone+"】已被他人使用请修改手机号码"));

        return;
      }

      // if(!data.match_phone_cert_num){
      //   this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "证件号【"+this.createRenterReqParam.cret_num+"】与之前入住证件不一致"));
      //   return;
      // }

      //是否无合同
      if(this.need_lease_contract === 1){
        let param = new LeaseRoomRentRequestParam(this.lease_id, this.createRenterReqParam.room_id);
        this.renterService.queryLeaseRoomRenterList(param).then(res1 => {
          if (this.subjectType && this.subjectType === 'COMPANY' && res1 && !res1.check_sex && res1.lease_renter_dtolist) {
            let sex = res1.lease_renter_dtolist[0].sex;
            if (sex !== this.createRenterReqParam.sex) {
              let messageModalInfo = new MessageModalInfo();
              messageModalInfo.operationMessage = '不允许不同性别入住';
              this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
              return;
            }
          }
          this.renterService.checkRoomOut(param).then(res2 => {
            if (res2 && res2.result_status) {
              let messageModalInfo = new MessageModalInfo();
              messageModalInfo.operationMessage = '该房间上一份合同到期尚未办理退房，请先为该房间办理退房？';
              messageModalInfo.callback.subscribe(data => {
                this.handInputModal.hide();
                this.router.navigateByUrl('/system_manage/signed/pact/' + res2.result_id);
              });
              this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
              return;
            } else {
              this.createRenterReqParam.name = this.matchLesseeReqParam.name;
              this.createRenterReqParam.cret_type = this.selectCretType;
              this.createRenterReqParam.cret_num = this.matchLesseeReqParam.cret_num;
              this.createRenterReqParam.lease_id = this.lease_id;
              this.createRenterReqParam.img_url_list = this.imgList;
              //是否需要上传证件照
              if(this.need_renter_pic === 1){
                this.openModalStaticModal18();
              }else{
                this.renterModal.hide();
                this.isSigningOfConvention();
              }
            }
          });
        });
      }else{
        this.createRenterReqParam.name = this.matchLesseeReqParam.name;
        this.createRenterReqParam.cret_type = this.selectCretType;
        this.createRenterReqParam.cret_num = this.matchLesseeReqParam.cret_num;
        this.createRenterReqParam.lease_id = this.lease_id;
        this.createRenterReqParam.img_url_list = this.imgList;
        //是否需要上传证件照
        if(this.need_renter_pic === 1){
          this.openModalStaticModal18();
        }else{
          this.renterModal.hide();
          this.isSigningOfConvention();
        }
      }
    });
  }

  submitIdentificationPhoto(): void {

    this.clearTimer();

    if (this.selectCretType === 'ID_CD') {
      if (this.imgList.length < 2) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "必须上传2张照片"));
        return;
      }
    } else if (this.selectCretType === 'PASSPORT') {
      if (this.imgList.length < 1) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "至少上传1张照片"));
        return;
      }
    }
    this.handInputModal.hide();
    this.isSigningOfConvention();
  }

  //是否需要签署入住公约
  isSigningOfConvention (){
    this.fillParam();
    this.createRenterReqParam.is_add = 0;
    this.renterService.createRenter(this.createRenterReqParam).then(res => {
      if (res && res.lease_renter_dtolist) {
        this.lessee = (res.lease_renter_dtolist)[0];
        this.renterDeposit = Number.parseFloat(res.renter_deposit);
        if(this.need_online_sign_convention){
          this.createRenterReqParam.is_add = 1;
          this.renterService.createRenter(this.createRenterReqParam).then(res2 => {
            if (res2 && res2.lease_renter_dtolist) {
              this.lessee = (res2.lease_renter_dtolist)[0];
            }
          });
          this.conventionModal.show();
          this.getHouseholdConvention();
        }else{
          this.checkIn();
        }
      }
    });
  }

  dealWith(type: number){
    this.nextCheckIn.emit(type);
  }

  // 显示打印预览弹窗
  printShow() {
    this.contractPath = `${environment.apiHost}`
      + 'file/preview?bizcode=LEASERENTER&infoIds=' + this.info_id + "&roomName="+this.roomName;

    this.workService.event_bus.emit(new WorkModel(this.workService.type.PDF_PRINT, this.contractPath));
    // this.printPreview.show();
  }

  checkLock($event: any){
    if($event.target.checked){
      this.roomDoorLockId = $event.target.value;
    }
  }

  getPersonTye() {
    this.renterService.getPersonType().then(data => {
      if (data.renter_person_type_list) {
        this.renter_person_type_list = data.renter_person_type_list;
        this.createRenterReqParam.person_type = data.renter_person_type_list[0].item_code;
      }
    });
  }

  getRenterExtInfo(){
    this.renterService.getRenterExtInfo().then(data => {
      if(data.project_ext_info_list && data.project_ext_info_list.length > 0){
        this.renterAddExt = [];
        data.project_ext_info_list.forEach(info =>{
          let ext = new RenterAddExtInfo();
          ext.name = info.name;
          ext.code = info.code;
          ext.id = info.id;
          ext.is_required = info.is_required;
          ext.project_id = info.project_id;
          ext.type = info.type;
          ext.value = info.value;
          if(info.type == 'SELECT'){
            ext.select_value = info.value.split("；").map(el => el.trim()).filter(item => item.trim() != '');
          }
          this.renterAddExt.push(ext);
        });
      }

    });
  }

  setFreeTime($event: any) {
    if ($event.target.checked) {
      this.free_time_disabled = false;
      this.select_free_disabled = null;

      this.calFreeTimeReq.lease_id = this.lease_id;
      this.calFreeTimeReq.select_free = -1;
      this.renterService.calFreeTime(this.calFreeTimeReq).then(data => {
        this.calFreeTimeResp = data;
        this.lease_end_time = this.calFreeTimeResp.end_time;
      });
    } else {
      this.free_time_disabled = true;
      this.select_free_disabled = true;
      this.free_start_time = null;
      this.free_end_time = null;
      this.select_free = "0";
    }
  }

  selectFreeSet() {
    if (this.select_free === "0") {

    } else {
      // 通过后端计算
      if (this.free_start_time != null) {
        this.calFreeTimeReq.free_start_time = getTimeFromPlugin(this.free_start_time.formatted);
      }

      if (this.select_free === "5") {
        this.calFreeTimeReq.lease_id = this.lease_id;
      }
      this.calFreeTimeReq.select_free = Number.parseInt(this.select_free);
      this.renterService.calFreeTime(this.calFreeTimeReq).then(data => {
        this.calFreeTimeResp = data;
        this.free_start_time = this.generateDatePluginData(new Date(this.calFreeTimeResp.free_start_time));
        this.free_end_time = this.generateDatePluginData(new Date(this.calFreeTimeResp.free_end_time));
      });
    }
  }

  generateDatePluginData(time: Date): any {
    let value = {
      date: {
        year: time.getFullYear(),
        month: time.getMonth() + 1,
        day: time.getDate()
      },
      formatted: formatYYYYmmdd(time),
      epoc: time.getTime() / 1000
    };
    return value;
  }

  prepayPayment(paymentType?: any, dialog?: any, aliOrWeixin?: any) {
    this.idempotentService.getIdempotentKey().then(data => {
      this.idempotent_key = data.idempotent_key;
    });
    const req = {bill_type: 'SINGLE'};
    this.hasChosenPayType = "HAS_CHOSEN";
    if (!this.validTwoPoint(this.renterDeposit)) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请输入正确的金额'));
      return;
    }
    //将商户流水号置空
    this.confirmPaymentRequest.pay_log_no = null;
    this.paymentType = paymentType;
    this.financeService.getPaymentType(req).then(res => {
      res.pay_types.forEach(data => {
        if (data.pay_type_code === paymentType) {
          this.confirmPaymentRequest.type_id = data.pay_type_id;
          this.paymentTypeName = data.pay_type_name;
        }
      });
    }).then(response => {
      this.aliOrWeixinDialog = dialog;
      if (aliOrWeixin) {
        this.showContent = false;
        this.prepayRechargeWithdrawReq.cret_images = [];
        this.clock = 120;
        this.timeOut();
      } else {
        this.prepayRechargeWithdrawReq = {};
      }

      this.prepayposPaymnetDialog.hide();
      this.prepaycashPaymentDialog.hide();
      this.prepaygatherBillDialog.hide();
      this.prepayRechargeWithdrawReq.cret_images = [];
      if (paymentType === 'CASH' || paymentType === 'TRANSFER') {
        let nowTime = new Date().getTime();
        this.prepayRechargeWithdrawReq.amount = this.renterDeposit.toString();
        this.prepayRechargeWithdrawReq.lease_id = this.hasChoseLeaseId;
        this.prepayRechargeWithdrawReq.room_id = this.hasChoseRoomId;
        this.prepayRechargeWithdrawReq.pay_type_code = 'CASH';
        this.prepayRechargeWithdrawReq.random_time = nowTime;
        this.prepayService.accountRechargeCode(this.prepayRechargeWithdrawReq).then(res => {
          this.prepayRechargeWithdrawReq.random_token = res.random_token;
          dialog.show();
        });
      } else {
        this.prepayRechargeWithdrawReq.pay_type_code = paymentType;
        this.prepayRechargeWithdrawReq.random_token = '';
        dialog.show();
      }
    });
  }

  validTwoPoint(amount?: any): boolean {
    if (amount) {
      if (/^\d+(\.\d{0,2})?$/.test(amount)) {
        let s = parseFloat(amount);
        if (s <= 0 || s > 10000000) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  timeOut() {
    this.clock = this.clock - 1;
    if (this.clock === 0 || this.clock === -1) {
      this.clock = 0;
      this.showContent = true;

    } else if (this.clock > 0) {
      if (new RegExp(RegularExp.signlessInteger).test((this.clock / 5).toString())) {
        //五秒中查询一次账单对应的订单信息
        this.getBillOrderList();
      }
      setTimeout(() => this.timeOut(), 1000);
    }
  }

  getBillOrderList() {
    const reqParam = {pay_channel: this.paymentType, payment_amount: this.renterDeposit};
    this.orderService.getBillOrderList(reqParam).then(response => {
      if (response) {
        this.orderDtos = response.order_info_dtos;
        this.orderNum = this.orderDtos.length;
        if (this.orderNum > 0) {
          this.clock = 0;
          this.bindOrderBillDialog.show();
          this.aliOrWeixinDialog.hide();
        }
      }
    }).catch(error => {
      this.logService.error('根据付款金额获取订单信息失败' + JSON.stringify(error));
    });
  }

  checkOrderNo(order_no?: any) {
    this.check_order_no = order_no;
  }

  stopP($event) {
    $event.stopPropagation();
  }

  closeGatherDialog(prepaygatherBill?: any) {
    prepaygatherBill.hide();
  }

  closeAliOrWei(closeAliOrWei?: any) {
    this.clock = 0;
    closeAliOrWei.hide();
  }

  prepayshowQueryByOrderId(queryDialog?: any, needCloseDialog?: any) {
    this.orderId = null;
    this.orderRemind = null;
    if (needCloseDialog) {
      needCloseDialog.hide();
    }
    queryDialog.show();
  }

  confirm(paymentType?: any, confirmDialog?: any, gatherSuccessModal?: any): Promise<boolean> {
    this._confirmGather(paymentType, confirmDialog, gatherSuccessModal);
    return;
  }

  paymnetFill(imageList?: any) {
    this.prepayRechargeWithdrawReq.cret_images = imageList;
  }

  closeOrderQueryDialog(orderQueryDialog?: any) {
    this.orderId = null;
    this.orderRemind = null;
    orderQueryDialog.hide();
  }





  /**
   * @Description:  显示通过商户订单号查询订单信息dialog
   * @Author: zhoujiahao
   * @Date: 2018-07-23  10:35
   */
  showQueryByOrderId(queryDialog?: any, needCloseDialog?: any) {
    this.orderId = null;
    this.orderRemind = null;
    if (needCloseDialog) {
      needCloseDialog.hide();
    }
    queryDialog.show();
  }

  /**
   * @Description:   确认订单和账单信息关联
   * @Author: zhoujiahao
   * @Date: 2018-07-22  17:06
   */
  reletedBillAndOrder(orderNo?: any, bindOrderBillDialog?: any, gatherSuccess?: any) {
    this.orderRelevance = true;
    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.operationMessage = "请确认此款项正确，确认后不能更改且立即入账";
    messageModalInfo.cancel = true;
    messageModalInfo.callback.subscribe(() => {
      this.confirmPaymentRequest.pay_log_no = orderNo;
      //付款类型为空
      this.confirm(this.paymentType, bindOrderBillDialog, gatherSuccess);
      this.orderRelevance = false;
    });
    messageModalInfo.cancelCallback.subscribe(() => {
      this.orderRelevance = true;
    });
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
  }

  /**
   * @Description:  通过订单号查询订单是否存在
   * @Author: zhoujiahao
   * @Date: 2018-07-23  10:39
   */
  queryByOrderId(queryShowDialog?: any, gatherSuccessDialog?: any) {
    this.orderRemind = null;
    if (!this.orderId) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请输入商户订单号'));
      return;
    }
    const reqParam = {
      pay_channel: this.paymentType,
      payment_amount: this.confirmPaymentRequest.actual_payment_amount,
      order_id: this.orderId
    };
    this.orderService.getBillOrderList(reqParam).then(res => {
      if (res) {
        this.orderDtos = res.order_info_dtos;
        if (res.order_info_dtos.length === 1) {
          this.confirmPaymentRequest.pay_log_no = this.orderDtos[0].order_no;
          this.confirm(this.paymentType, queryShowDialog, gatherSuccessDialog);
        }
      } else {
        this.orderRemind = '未查到该订单号，请确认订单号是否正确';
      }
    }).catch(error => {
      this.logService.error('PactComponent:根据订单和金额查询订单时失败' + JSON.stringify(error));
    });
  }


  initDialog(
    prepaycashPayment?: any,
    prepaygatherBill?: any,
    prepayposPaymnet?: any,
  ) {
    this.prepaycashPaymentDialog = prepaycashPayment;
    this.prepaygatherBillDialog = prepaygatherBill;
    this.prepayposPaymnetDialog = prepayposPaymnet;
  }

  _confirmGather(paymentType?: any, confirmDialog?: any, gatherSuccessModal?: any) {
    this.confirmPaymentRequest.idempotent_key = this.idempotent_key;
    this.confirmPaymentRequest.account_money_info_id = null;
    this.confirmPaymentRequest.actual_payment_amount = this.renterDeposit.toString();
    this.confirmPaymentRequest.lease_id = this.lessee.lease_id;
    this.confirmPaymentRequest.gather_type = 'otherExpense';
    this.confirmPaymentRequest.bill_type_code = 'RENTER_DEPOSIT';
    this.confirmPaymentRequest.room_ids = [];
    this.confirmPaymentRequest.room_ids.push(this.lessee.room_id);

    //实际付款金额
    if (!this.validTwoPoint(this.confirmPaymentRequest.actual_payment_amount)) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请输入正确的金额'));
      return;
    }

    if (parseFloat(this.confirmPaymentRequest.payment_total_amount) < 0) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '应收总金额不能小于0'));
      return;
    }

    //调用创建入住人信息接口
    this.createRenterReqParam.is_add = 1;
    this.renterService.createRenter(this.createRenterReqParam).then(res => {
      if (res && res.lease_renter_dtolist) {
        this.lessee = (res.lease_renter_dtolist)[0];
        this.confirmPaymentRequest.renter_info_id = this.lessee.info_id;

        if (this.lessee) {
          this.checkInReqparam.info_id = this.lessee.info_id;
          this.checkInReqparam.lease_id = this.lessee.lease_id;
          this.checkInReqparam.room_id = this.lessee.room_id;
          this.checkInReqparam.img_list = this.imgList;
          this.renterService.checkIn(this.checkInReqparam).then(res2 => {
            if (res2.result) {
              let messageModalInfo = new MessageModalInfo();
              messageModalInfo.operationMessage = res2.result;
              this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
            } else {
              // 调用收款接口
              this.billService.confirmGather(this.confirmPaymentRequest).then(response => {
                this.prepaycashPaymentDialog.hide();
                this.orderId = null;
                confirmDialog.hide();
                gatherSuccessModal.show();
              }).catch(error => {
                this.logService.error('LesseeComponent:确认付款' + JSON.stringify(error));
              });

              // TODO 发送门锁密码给用户
              if (this.roomDoorLockList && this.roomDoorLockList.length > 0 && this.roomDoorLockList.length === 2) {
                this.deviceService.sendDoorPwdMsg({
                  roomId: this.checkInReqparam.room_id,
                  phoneNumber: this.sendPhone,
                  roomDoorLockId: this.roomDoorLockId
                }).then(data => {
                });
              }
              this.clearParam();
            }
          });
        }
      }
    });
  }
}
