import {Component, OnInit, TemplateRef} from '@angular/core';
import {EvolveReqParam, RiskInfo, RiskInfoList, RiskReqParam} from "../../../model/workbench/risk.model";

import {RiskService} from "../../../service/workbench/risk.service";
import {AuthService} from "../../../service/common/auth.service";
import {Router} from "@angular/router";
import {HotelProject} from "../../../model/room/project.model";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

declare var baidutongji: any;

@Component({
  selector: 'app-risk',
  templateUrl: './risk.component.html',
  styleUrls: ['./risk.component.css']
})
export class RiskComponent implements OnInit {
  btn_privileges: any = {
    'ALARM_QUERY': false,
    'ALARM_VIEW_HANDLE': false,
    'ROOM_LEASE_BILL_ALARM': false,
    'PAYBACK_UNDO_ALARM': false,
    'ROOM_DEVICE_ALARM': false,
    'ROOM_LEASE_BILL_ALARM_HANDLE': false,
    'PAYBACK_UNDO_ALARM_HANDLE': false,
    'ROOM_DEVICE_ALARM_HANDLE': false,
  };

  modalRef: BsModalRef;
  alertType: string;
  alertStatus1: string;
  alertStatus2: string;
  alertStatus3: string;
  evolveReq: EvolveReqParam = new EvolveReqParam();
  riskList: RiskInfoList;
  riskInfo: RiskInfo;
  evolve_remark: string;
  evolve_status: string = 'IN_HANDLE';
  //权限
  pri: string;
  type = [];
  riskReq: RiskReqParam = new RiskReqParam();

  projectConfig: HotelProject;

  constructor(private modalService: BsModalService,
              private riskService: RiskService,
              private authService: AuthService,
              private router1: Router) {
  }

  ngOnInit() {
    this.projectConfig = this.authService.getProjectInfo();

    this.authService.getOperatePrivilege(this.btn_privileges);
    this.alertType = 'ALL';
    this.alertStatus1 = 'WAITING';
    this.riskReq.page_num = 1;
    this.riskReq.page_size = 20;
    if (this.alertStatus1 === 'WAITING') {
      this.riskReq.alert_status = ['WAITING'];
    }
    this.getRiskList();
    baidutongji("运营告警");
  }

  to_lease(lease_id: number) {
    this.modalRef.hide();
    this.router1.navigate(["/system_manage/signed/pact/", lease_id]);
  }

  showModal(template: TemplateRef<any>, riskInfo: RiskInfo, pri: string): void {
    baidutongji("运营告警处理");
    this.evolve_remark = null;
    this.evolve_status = 'IN_HANDLE';
    if (pri === 'DUE') {
      if (this.btn_privileges.ROOM_LEASE_BILL_ALARM_HANDLE) {
        this.pri = 'DUE';
      }
    }
    if (pri === 'NO_CHECKOUT') {
      if (this.btn_privileges.PAYBACK_UNDO_ALARM_HANDLE) {
        this.pri = 'NO_CHECKOUT';
      }
    }
    if (pri === 'FAULT') {
      if (this.btn_privileges.ROOM_DEVICE_ALARM_HANDLE) {
        this.pri = 'FAULT';
      }
    }
    this.riskInfo = riskInfo;
    this.modalRef = this.modalService.show(template);
  }

  getRiskList(): void {
    this.type = [];
    if (!this.btn_privileges.ROOM_DEVICE_ALARM) {
      this.type.push('FAULT');
      this.type.push('ELE');
      this.type.push('WAT');
      this.type.push('DOOR');

    }
    if (!this.btn_privileges.PAYBACK_UNDO_ALARM) {
      this.type.push('NO_CHECKOUT');
    }
    if (!this.btn_privileges.ROOM_LEASE_BILL_ALARM) {
      this.type.push('DUE');
    }
    this.riskReq.pri_type = this.type;
    this.riskReq.alert_type = this.alertType;
    this.riskService.getRiskListWithLoading(this.riskReq)
      .then(data => {
        if (data) {
          this.riskList = data;
        } else {
          this.riskList = null;
        }
      });

  }

  search(): void {
    baidutongji("运营告警搜索");
    this.riskReq.alert_type = this.alertType;
    this.getRiskList();
  }

  clear(): void {
    this.alertType = 'ALL';
    this.alertStatus1 = '';
    this.alertStatus2 = '';
    this.alertStatus3 = '';
    this.getRiskList();
  }

  pageChange(currentPage: number): void {
    this.riskReq.page_num = currentPage;
    this.getRiskList();
  }

  ok_evolve(info: RiskInfo): void {
    this.evolveReq.alert_id = info.alert_id;
    this.evolveReq.evolve_status = this.evolve_status;
    this.evolveReq.evolve_remark = this.evolve_remark;
    this.evolveReq.alert_type = info.alert_type;
    if (this.evolve_remark.length >= 3) {
      this.riskService.handleEvolve(this.evolveReq)
        .then(data => {
          if (data) {
            this.getRiskList();
            this.modalRef.hide();
          }
        });
    }
  }

  statusSet(event, code) {
    if (this.riskReq.alert_status == null) {
      this.riskReq.alert_status = [];
    }
    if (event.target.checked) {
      // 添加状态
      this.riskReq.alert_status.push(code);
      this.riskReq.page_num = 1;
    } else {
      // 删除状态
      for (let i = 0; i < this.riskReq.alert_status.length; i++) {
        if (this.riskReq.alert_status[i] === code) {
          this.riskReq.alert_status.splice(i, 1);
          this.riskReq.page_num = 1;
        }
      }
    }
    this.getRiskList();
  }

}
