
import { Component, Input, OnInit, Output, ViewChild,EventEmitter } from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";


@Component({
  selector: 'app-check-out-popup',
  templateUrl: './check-out-popup.component.html',
  styleUrls: ['./check-out-popup.component.css']
})
export class CheckOutPopupComponent implements OnInit {



  // 退房房间选择model
  @ViewChild('checkoutRoomView', { static: true }) checkoutRoomViewModal: ModalDirective;
  // checkoutReson:string;
  @Output() callback: EventEmitter<any> = new EventEmitter<any>();
  checkAll = false;
  listGoods: Array<any> = [];
  listGoodsNew: Array<any> = [];
  isGood:any;
  price:number;//单价
  unit:string;//单位
  amount:number;//数量
  fewDamage:number=0;//部分损坏
  allDamage:number=0;//全部损毁
  loseDamage:number=0;//丢失
  goodName:string;//名字
  isNumber=false;//是否大于
  arrData:Array<any> = [];
  // newType:boolean=true;
  popupId:number;
  popupIDArray:Array<number> = [];
  rowId:number;
  selectName:boolean=false;//下拉框判断
  writeLose:boolean=false;//丢失情况
  fewDamageNumber:boolean=false;
  allDamageNumber:boolean=false;
  loseDamageNumber:boolean=false;
  constructor(
  ) {
  }

  ngOnInit() {


  }

  showModal(data,details) {
    this.popupId=data.lease_room_info_dto.lease_room_id;
    this.price=0;
    this.unit='';
    this.amount=0;
    this.isGood=undefined;
    this.fewDamage=0;//部分损坏
    this.allDamage=0;//全部损毁
    this.loseDamage=0;//丢失
    this.checkoutRoomViewModal.show();
    this.arrData=details;
    this.listGoodsNew= data.room_good_relation_dto.room_goods_info_dtolist;
    this.arrData.forEach(item => {
      this.listGoodsNew = this.listGoodsNew.filter((ele)=>ele.id!==item.rowId );
    });
    this.selectName=true;
  }

  openCheckoutConfirm() {
      let str= (+this.loseDamage)+ (+this.allDamage)  + (+this.fewDamage);
      if((+this.amount) < str){
        this.isNumber=true;
      }else{
          if(this.loseDamage||this.allDamage||this.fewDamage){
            if(!this.fewDamageNumber && !this.allDamageNumber && !this.loseDamageNumber){
              this.isNumber=false;
            this.checkoutRoomViewModal.hide();
            const obj={
              goodName:this.goodName,
              fewDamage:this.fewDamage,
              allDamage:this.allDamage,
              loseDamage:this.loseDamage,
              id:this.popupId,
              rowId:this.rowId
            };
            this.arrData.push(obj);
            this.callback.emit(this.arrData);
            }
          }else{
            this.writeLose=true;
          }
      }
    // }


  }
  ischange(e){
    this.price=e.price;
    this.unit=e.unit;
    this.amount=e.quantity;
    this.goodName=e.good_name;
    this.rowId=e.id;
    this.selectName=false;

  }
  changeInput(){
    if( this.loseDamage  ||  this.allDamage || this.fewDamage ){
      this.writeLose=false;
    }
    if((+this.amount) < (+this.loseDamage)+ (+this.allDamage)  + (+this.fewDamage)){
      this.isNumber=true;
    }else{
      this.isNumber=false;
    }
  }
  fewDamageInput(e){
    let res=/^[0-9]+.?[0-9]*$/;
    if(!res.test(e.target.value)){
      this.fewDamageNumber=true;
    }else{
      this.fewDamageNumber=false;
    }
  }
  allDamageInput(e){
    let res=/^[0-9]+.?[0-9]*$/;
    if(!res.test(e.target.value)){
      this.allDamageNumber=true;
    }else{
      this.allDamageNumber=false;
    }

  }
  loseDamageInput(e){
    let res=/^[0-9]+.?[0-9]*$/;
    if(!res.test(e.target.value)){
      this.loseDamageNumber=true;
    }else{
      this.loseDamageNumber=false;
    }

  }
}
