<ng-container *ngIf="type===1">

  <app-nz-upload
    [nzAction]="url"
    [nzAccept]="acceptType"
    [nzBeforeUpload]="beforeUpload"
    [nzHeaders]="headers"
    [nzSize]="maxSize"
    [nzCustomRequest]="custom"
    [nzRemove]="remove"
    [nzShowUploadList]="showUploadList"
  >
    <button nz-button>
      <i class="anticon anticon-upload"></i><span>{{name}}</span>
    </button>
  </app-nz-upload>


</ng-container>

<ng-container *ngIf="type===2">

  <app-nz-upload
    [nzAction]="url"
    [nzAccept]="acceptType"
    [nzBeforeUpload]="beforeUpload"
    [nzHeaders]="headers"
    [nzSize]="maxSize"
    [nzCustomRequest]="custom"
    [nzRemove]="remove"

    nzListType="picture-card"
    [(nzFileList)]="fileList"
    [nzShowButton]="showUploadBtn && fileList.length < maxLength"
    [nzPreview]="handlePreview"
    [nzShowUploadList]="{showRemoveIcon: showDelBtn, showPreviewIcon: showViewBtn}"
  >
    <i class="anticon anticon-plus"></i>
    <div class="ant-upload-text">{{name}}</div>
  </app-nz-upload>

  <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
            (nzOnCancel)="previewVisible=false">
    <ng-template #modalContent>
      <img [src]="previewImage" [ngStyle]="{ 'width': '100%' }"/>
    </ng-template>
  </nz-modal>


</ng-container>

<ng-container *ngIf="type===3">

  <app-nz-upload
    [nzAction]="url"
    nzListType="picture-card"
    [(nzFileList)]="fileList"
    [nzShowButton]="fileList.length < maxLength"
    [nzPreview]="handlePreview">
    <i class="anticon anticon-plus"></i>
    <div class="ant-upload-text">{{name}}</div>
  </app-nz-upload>
  <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
            (nzOnCancel)="previewVisible=false">
    <ng-template #modalContent>
      <img [src]="previewImage" [ngStyle]="{ 'width': '100%' }"/>
    </ng-template>
  </nz-modal>
</ng-container>
