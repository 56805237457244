import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {is_inApp_IOS, trim} from '../../../common/common';
import {LocalStorage} from '../../../common/localstorage';
import {RegularExp} from "../../../common/util.regexp";
import {loginPassWordValidator} from "../../../core/validator/validator.common";
import {WorkModel} from "../../../model/common/common.model";
import {MessageModalInfo} from "../../../model/modal/message-model";
import {
  LeQiDaLoginSmsCodeRequest,
  LeQiDaUserRegisterRequest,
  Login,
  LoginCheckCode,
  LoginSecondCheckRequest,
  SendCode,
  SmsCodeCheckRequest,
  SysUser,
  UserWechatRelateFlagRequest
} from "../../../model/user/user.model";
import {AuthService} from "../../../service/common/auth.service";
import {WorkService} from "../../../service/common/work.service";
import {LogService} from "../../../service/log/log.service";
import {UserService} from "../../../service/user/user.service";
import {SafetyComponent} from "../../workspace/safety/safety.component";


@Component({
  selector: 'app-reg',
  templateUrl: './reg.component.html',
  styleUrls: ['./reg.component.css']
})
export class RegComponent implements OnInit, OnDestroy {

  clearTime: any;
  imgCodeUrl: string = environment.apiHost + "image/code/";
  imgUrl: string;
  errorContent: string;
  login: Login = new Login();
  registerRequest: LeQiDaUserRegisterRequest = new LeQiDaUserRegisterRequest();
  smsCodeCheckRequest: SmsCodeCheckRequest = new SmsCodeCheckRequest();
  buttonShow: boolean = true;
  clock: number = 60;
  charcter: string;
  codeShow = false;
  is_login: boolean;
  showCode: boolean = false;
  loginCode: string;
  wechatflag: string;
  projectId: string;
  companyId: number;
  id: string;
  loginLoading: boolean = false;
  platformCode: string = 'CSDM';
  redirect_url: any;
  temporary_sign: any;
  back_url: string;
  is_need_sms_check: boolean = false;
  sendCode: SendCode = new SendCode();
  loginSmsCodeRequest: LeQiDaLoginSmsCodeRequest = new LeQiDaLoginSmsCodeRequest();
  has_login: boolean = false;
  loginSecondCheckRequest: LoginSecondCheckRequest = new LoginSecondCheckRequest();
  user_phone: string;
  is_select_need_check: string;
  errorCodeContent: string;
  sysUserDTO: SysUser = new SysUser();
  temp_token: string;
  temp_redirect_url: any;
  temp_temporary_sign: any;
  loginId: string;
  callback: any;
  is_complete_register: boolean = false;

  @ViewChild(SafetyComponent, {static: true}) safetyComponent: SafetyComponent;

  constructor(private logService: LogService,
              private userService: UserService,
              private auth: AuthService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private workService: WorkService
  ) {
  }

  imgCodeClick() {
    let uniqueTag = this.createCharacter();
    let date = new Date();
    this.imgUrl = this.imgCodeUrl + uniqueTag + "?num=" + date.getTime();
    this.charcter = uniqueTag;
  }

  loginCodeShow() {
    this.userService.userCodeShow().then(data => {
      if (data.code_show === "SHOW") {
        this.imgCodeClick();
        this.codeShow = true;
      }
    });
  }

  check(bl: boolean) {
    this.registerRequest.user_phone = trim(this.registerRequest.user_phone);
    if (this.registerRequest.password === "") {
      this.registerRequest.password = null;
    }
    if (this.registerRequest.user_name == null || this.registerRequest.user_name === "") {
      this.errorContent = "姓名不能为空";
      return false;
    } else if (!new RegExp(RegularExp.phone.mobile).test(this.registerRequest.user_phone + "")) {
      this.errorContent = "请输入合法手机号";
      return false;
    } else if (this.registerRequest.sms_code == null || this.registerRequest.sms_code === "") {
      this.errorContent = "验证码不能为空";
      return false;
    } else if (!loginPassWordValidator(this.registerRequest.password)) {
      this.errorContent = "密码长度8-18位，须含数字、大写字母、小写字母、符号中的3种及以上";
      return false;
    } else if (bl && this.registerRequest.password !== this.registerRequest.second_password) {
      this.errorContent = "两次输入密码必须一致";
      return false;
    } else {
      this.errorContent = "";
    }
    return true;
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.system_type) {
        this.platformCode = queryParams.system_type;
        this.back_url = queryParams.backurl;
      }
      if (queryParams.wechatflag) {
        this.wechatflag = queryParams.wechatflag;
        this.companyId = queryParams.companyId;
        this.projectId = queryParams.projectId;
        this.id = queryParams.id;
      }

      if (!queryParams.system_type) {
        let item = sessionStorage.getItem("state");
        if (item) {
          this.platformCode = item;
        }
      }

    });
    this.is_login = false;

    this.loginCodeShow();
  }

  timeOut() {
    this.clock = this.clock - 1;
    if (this.clock === 0) {
      this.buttonShow = true;
      return true;
    } else {
      setTimeout(() => this.timeOut(), 1000);
    }
  }

  inspectWechat() {
    if (this.clearTime) {
      window.clearInterval(this.clearTime);
    }
    sessionStorage.removeItem('code');
    localStorage.setItem('platformCode', this.platformCode);

    if (this.platformCode === 'WOS' || this.platformCode === 'LEQIDA') {
      if (this.back_url) {
        location.href = `${this.back_url}?temporarySign=${this.temporary_sign}`;
      } else {
        location.href = `${this.redirect_url}?temporarySign=${this.temporary_sign}`;
      }
    } else {
      this.router.navigateByUrl("/system_manage");
    }
  }

  inspectWechatFlag() {
    let request = new UserWechatRelateFlagRequest();
    request.system_type = this.platformCode;
    request.temporary_code = trim(this.temporary_sign);
    this.userService.inspectWechatFlag(request).then(data => {
      this.loginLoading = false;
      if (data.flag === 'false') {
        if (data.redirect_url) {

          let messageModalInfo = new MessageModalInfo();
          messageModalInfo.operationMessage = "未绑定微信,待页面刷新后重新登录绑定。";
          messageModalInfo.callback.subscribe(() => {
            location.href = data.redirect_url;
          });
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));

        } else {
          this.showCode = true;
          this.is_need_sms_check = false;
          this.clearTime = setTimeout(() => {
            this.inspectWechatFlag();
          }, 3000);
        }
      } else {

        this.showSatetyDialog();
      }
    });
  }

  passWordEdit() {
    // 密码不合规，弹框提示
    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.operationMessage = '您的账号密码存在安全风险，请前往修改密码！!';
    messageModalInfo.returnUrl = "/reset_password?system_type=" + this.platformCode;
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
  }

  userLogin() {
    if (this.loginLoading) {
      return;
    }
    this.loginCode = sessionStorage.getItem("code") || '';
    if (is_inApp_IOS()) {
      window.document.addEventListener('apptransfer', this.callback);
      window['webkit'].messageHandlers.getLocalStorage.postMessage(null);
    } else {
      let uniqueTag = this.createCharacter();
      let loginUniqueTag = this.auth.getLoginUniqueTag();
      if (this.check(true)) {
        // 登录
        if (this.is_login) {
          return;
        }
        this.userSystemLogin(uniqueTag, loginUniqueTag);
      }
    }


  }

  userSystemLogin(uniqueTag: string, loginUniqueTag) {
    if (this.loginCode) {
      this.login.code = this.loginCode;
    }
    this.loginLoading = true;
    this.login.uniqueTag = uniqueTag;
    this.login.loginUniqueId = loginUniqueTag;
    this.login.systemType = this.platformCode;
    this.userService
      .userLogin(this.login)
      .then(biz_response => {
        this.is_login = false;
        let need_sms_check = biz_response.is_need_login_code_check;
        if (need_sms_check) {
          delete this.is_select_need_check;
          delete this.loginSecondCheckRequest.sms_code;
          delete this.loginSecondCheckRequest.mobile;
          this.user_phone = this.login.userAccount;
          this.user_phone = this.user_phone.replace(/(\d{3})\d*(\d{4})/, '$1****$2');
          this.has_login = true;
          this.is_need_sms_check = true;
          this.sysUserDTO = biz_response.sys_user_dto;
          this.temp_token = biz_response.token;
          this.temp_redirect_url = biz_response.redirect_url;
          this.temp_temporary_sign = biz_response.temporary_sign;
        } else {
          if (!loginPassWordValidator(this.login.userPassword)) {
            this.loginLoading = false;
            this.passWordEdit();
          } else {
            //登录成功 之后清空之前用户的信息
            LocalStorage.customClear(this.auth.getUserInfo());
            sessionStorage.clear();
            // if (data.status_code === "SUCCESS") {
            // 存放用户信息
            this.auth.setUserInfo(biz_response.sys_user_dto);
            this.auth.removeProject();
            // // 可能没有项目
            // if(biz_response.hotel_project_dto) {
            //   // 存放项目信息
            //   this.auth.setProjectInfo(biz_response.hotel_project_dto);
            // }
            // 存放token
            this.auth.setToken(biz_response.token);
            // this.auth.setCompanyId(biz_response.sys_user_dto.company_id);
            // 跳转
            // this.router.navigateByUrl("/system_manage");

            if (this.platformCode === 'WOS' || this.platformCode === 'LEQIDA') {
              this.redirect_url = biz_response.redirect_url;
              this.temporary_sign = biz_response.temporary_sign;
            }
            this.inspectWechatFlag();
          }
        }

      })
      .catch(e => {
        this.loginLoading = false;
        this.errorContent = e.message;
        // 查询是否需要展示图片验证码
        this.loginCodeShow();
        // 登录失败后刷新图片验证码
        this.imgCodeClick();
        this.is_login = false;
      });
  }

  backLogin() {

    this.loginCode = sessionStorage.getItem("code") || '';

    let uniqueTag = this.createCharacter();
    let loginUniqueTag = this.auth.getLoginUniqueTag();
    // 检查登录参数
    if (this.check(true)) {
      // 登录
      if (this.is_login) {
        return;
      }
      this.login.uniqueTag = uniqueTag;
      this.login.loginUniqueId = loginUniqueTag;
      this.userService
        .userLogin(this.login)
        .then(biz_response => {
          let need_sms_check = biz_response.is_need_login_code_check;
          if (need_sms_check) {
            delete this.is_select_need_check;
            delete this.loginSecondCheckRequest.sms_code;
            delete this.loginSecondCheckRequest.mobile;
            this.user_phone = this.login.userAccount;
            this.user_phone = this.user_phone.replace(/(\d{3})\d*(\d{4})/, '$1****$2');
            this.has_login = true;
            this.is_need_sms_check = true;
            this.sysUserDTO = biz_response.sys_user_dto;
            this.temp_token = biz_response.token;
            this.temp_redirect_url = biz_response.redirect_url;
            this.temp_temporary_sign = biz_response.temporary_sign;
          } else {
            if (!loginPassWordValidator(this.login.userPassword)) {
              this.loginLoading = false;
              this.passWordEdit();
            } else {
              this.is_login = false;
              //登录成功 之后清空之前用户的信息
              LocalStorage.customClear(this.auth.getUserInfo());
              sessionStorage.clear();
              this.auth.setUserInfo(biz_response.sys_user_dto);

              // 存放token
              this.auth.setToken(biz_response.token);

              this.setWechatConfig();

              this.showSatetyDialog();
              // 跳转
              // this.router.navigateByUrl("/system_manage/workspace/alarm/2898");
            }
          }
        })
        .catch(e => {

          this.errorContent = e.message;
          // 查询是否需要展示图片验证码
          this.loginCodeShow();
          // 登录失败后刷新图片验证码
          this.imgCodeClick();
          this.is_login = false;
        });
    }
  }

  sendCodeRequest() {
    if (this.checkMobile()) {
      this.loginSmsCodeRequest.code_type = 48;
      this.loginSmsCodeRequest.send_phone = this.registerRequest.user_phone;
      this.userService.leQiDaLoginSmsCode(this.loginSmsCodeRequest).then(data => {
        this.clock = 60;
        // 发送成功之后进行倒记时
        this.buttonShow = false;
        setTimeout(() => this.timeOut(), 1000);
      }).catch(error => {
        this.clock = 60;
        this.buttonShow = true;
      });
    }
  }

  checkOther(bl?: boolean) {
    this.sendCode.mobile =
      Number((this.login.userAccount + "").trim()) === 0
        ? null
        : Number((this.login.userAccount + "").trim());
    this.sendCode.imgCode = this.loginSecondCheckRequest.img_code;
    this.sendCode.imgCode = this.sendCode.imgCode ? (this.sendCode.imgCode).trim() : null;

    if (!new RegExp(RegularExp.phone.mobile).test(this.sendCode.mobile + "")) {
      this.errorContent = "请输入合法手机号";
      return false;
    } else if (this.sendCode.imgCode == null || this.sendCode.imgCode === "") {
      this.errorContent = "图片验证码不能为空";
      return false;
    } else {
      this.errorContent = "";
    }
    return true;
  }

  checkSmsCode() {
    this.loginSecondCheckRequest.unique_id = this.temp_token;
    this.loginSecondCheckRequest.mobile = this.login.userAccount;
    this.loginSecondCheckRequest.user_id = this.sysUserDTO.id;
    let is_select_need_check_flag;
    if (this.is_select_need_check) {
      is_select_need_check_flag = "NO_NEED";
      let loginUniqueTag = this.auth.getLoginUniqueTag();
      this.loginSecondCheckRequest.login_unique_id = loginUniqueTag;
    } else {
      is_select_need_check_flag = "NEED";
    }
    this.loginSecondCheckRequest.is_select_need_check = is_select_need_check_flag;
    this.userService.loginSecondCheck(this.loginSecondCheckRequest).then(data => {
      if (data.sms_code_check) {
        this.afterSecondCheckLogin();
      }
    }).catch(e => {
      this.errorCodeContent = e.message;
    });
  }

  setWechatConfig() {
    this.auth.setCompanyId(this.companyId);
    this.userService.saveCurrentCompany({companyId: this.companyId}).then(data => {
      if (data.add_dept) {
        localStorage.setItem("add_dept", data.add_dept);
      }
      if (data.add_project) {
        localStorage.setItem("add_project", data.add_project);
      }
      if (data.add_user) {
        localStorage.setItem("add_user", data.add_user);
      }
      if (data.add_room) {
        localStorage.setItem("add_room", data.add_room);
      }
      // this.userService.userProjectChange(this.projectId).then(x => {
      //   //获得项目名称
      //   // this.projectName = this.authService.getProjectName();
      //   this.router.navigateByUrl(`/alarm?id=${this.id}`);
      // });
      this.userService.userProjectChange(this.projectId).then(data2 => {
        // 存放项目信息
        this.auth.setProjectInfo(data2.project);
      });
    });
  }

  createCharacter() {
    let charcter: string = localStorage.getItem("uniqueTag");
    charcter = (charcter == null || charcter === "") && this.charcter != null ? this.charcter : charcter;
    if (charcter == null || charcter === "" || charcter.length !== 32) {
      let date = new Date();
      charcter = date.getTime() + "imgcodesms" + Math.random() * 100000000000;
      if (charcter.length !== 32) {
        charcter = charcter.substring(0, 32);
      }
    }
    localStorage.setItem("uniqueTag", charcter);
    return charcter;
  }

  ngOnDestroy() {
    if (this.clearTime) {
      window.clearInterval(this.clearTime);
    }
    window.document.removeEventListener('apptransfer', this.callback);
  }

  // 安全预警
  showSatetyDialog() {
    this.safetyComponent.openDialog(this.platformCode);
  }

  _loginOp() {
    if (!this.wechatflag) {
      this.inspectWechat();
    } else {
      this.router.navigateByUrl(`/alarm?id=${this.id}&wechatflag=2&projectId=${this.projectId}&companyId=${this.companyId}`);
    }
  }

  checkMobile() {
    if (!new RegExp(RegularExp.phone.mobile).test(this.registerRequest.user_phone + "")) {
      this.errorCodeContent = "请输入合法手机号";
      return false;
    } else {
      return true;
    }
  }

  afterSecondCheckLogin() {
    if (!loginPassWordValidator(this.login.userPassword)) {
      this.loginLoading = false;
      this.passWordEdit();
    } else {
      LocalStorage.customClear(this.auth.getUserInfo());
      sessionStorage.clear();
      this.auth.setUserInfo(this.sysUserDTO);
      this.auth.setToken(this.temp_token);
      if (this.platformCode === 'WOS' || this.platformCode === 'LEQIDA') {
        this.redirect_url = this.temp_redirect_url;
        this.temporary_sign = this.temp_temporary_sign;
      }
      this.inspectWechatFlag();
    }
  }

  goLogin() {
    this.router.navigate(['/login'], {queryParams: {system_type: 'LEQIDA'}});
  }

  userRegister() {
    if (this.check(true)) {
      // 提交注册请求
      this.userService.leQiDaUserRegister(this.registerRequest).then(data => {
        // 成功
        this.is_complete_register = true;
      });
    }
  }
}
