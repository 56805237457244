import {Injectable} from "@angular/core";
import {RequestService} from "../request.service";
import {environment} from "../../../environments/environment";
import {
  ClientuserListRequest,
  SearchCompanyUserListReq,
  CompanySubjectInfoRequest,
  ClientCompanyUpdateRequest,
  ClientUserSearchListRequest,
  ClientUserSearchListResponse,
  ClientUserDetailResponse,
  ClientCompanyUserListResponse
} from "../../model/user/clientuser.model";
import {AuthService} from "../common/auth.service";
import {
  ClientUserBaseDataResponse,
  ClientUserFollowUpSubmitRequest, WorkRemindDisposeUserFollowUpRequest
} from "../../model/clientinfo/client-info.model";

@Injectable()
export class ClientuserService {
  private PMS_CLIENT_LIST = `${environment.apiHost}pms/client/list`;
  private PMS_CLIENT_DETAIL = `${environment.apiHost}pms/client/detail`;
  private SMS_USER_MOBILE_CODE = `${environment.apiHost}sms/user/mobile/code`;
  private PMS_CLIENT_VALID_SMSCODE = `${environment.apiHost}pms/client/valid/smsCode`;
  private PMS_CLIENT_BINDACCOUNT = `${environment.apiHost}pms/client/bindAccount`;
  private PMS_CLIENT_COMPANY_USER_LIST = `${environment.apiHost}pms/client/company/list`;
  private PMS_CLIENT_COMPANY_USER_INFO = `${environment.apiHost}pms/client/company/details`;

  private PMS_CONFIG_MODULE = `${environment.apiHost}config/module`;

  private PMS_CLIENT_LIST_DOWN = `${environment.apiHost}pms/client/downUserList`;
  private PMS_CLIENT_COMPANY_USER_LIST_DOWN = `${environment.apiHost}pms/client/company/downUserList`;


  private COMPANY_UPDATE = `${environment.apiHost}pms/client/company/updateSubject`;

  private COMPANY_SUBJECT_INFO = `${environment.apiHost}pms/client/company/getSubjectInfo`;

  private GET_PMS_CLIENT_USER_LIST = `${environment.apiHost}pms/client/search/user/list`;
  private GET_PMS_CLIENT_BASE_DATA = `${environment.apiHost}pms/client/base/data`;
  private POST_PMS_CLIENT_ADD_FOLLOWUP = `${environment.apiHost}pms/client/follow/up/submit`;
  private POST_WORK_REMIND_PMS_CLIENT_ADD_FOLLOWUP = `${environment.apiHost}work/remind/user/follow/up/dispose`;





  constructor(private requestService: RequestService,
              private authService: AuthService) {

  }

  public pmsClientList(requestData?: any): Promise<any> {
    return this.requestService.authPost(this.PMS_CLIENT_LIST, requestData);
  }

  public pmsClientListWithLoading(requestData?: any): Promise<any> {
    return this.requestService.authPost(this.PMS_CLIENT_LIST, requestData, true);
  }

  public pmsCilentDetail(requestData?: any): Promise<ClientUserDetailResponse> {
    return this.requestService.authGet(this.PMS_CLIENT_DETAIL, requestData);
  }

  public userMobileCode(requestData?: any): Promise<any> {
    return this.requestService.commonHttp2(this.SMS_USER_MOBILE_CODE, 'post', requestData, true, null, false);
  }

  public pmsClientValidSmscode(requestData?: any): Promise<any> {
    return this.requestService.commonHttp2(this.PMS_CLIENT_VALID_SMSCODE, 'post', requestData, true, null, false);
  }

  public pmsClientBindaccount(requestData?: any): Promise<any> {
    return this.requestService.commonHttp2(this.PMS_CLIENT_BINDACCOUNT, 'post', requestData, true, null, false);
  }

  public pmsClientCompanyUserList(requestData?: SearchCompanyUserListReq): Promise<ClientCompanyUserListResponse> {
    return this.requestService.authGet(this.PMS_CLIENT_COMPANY_USER_LIST, requestData);
  }

  public pmsClientCompanyUserListWithLoading(requestData?: SearchCompanyUserListReq): Promise<ClientCompanyUserListResponse> {
    return this.requestService.authGet(this.PMS_CLIENT_COMPANY_USER_LIST, requestData, true);
  }

  public pmsClientCompanyUserInfo(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.PMS_CLIENT_COMPANY_USER_INFO, requestData);
  }

  downloadUserList(request: ClientuserListRequest) {

    let param = "";
    for (let key in request) {
      if (request[key] && request.hasOwnProperty(key)) {
        param += (key + '=' + request[key] + '&');
      }
    }

    if (param) {
      window.open(this.PMS_CLIENT_LIST_DOWN + "?" + param + 'Authorization=' + this.authService.getAuthToken(), "_blank");
    } else {
      window.open(this.PMS_CLIENT_LIST_DOWN + '?Authorization=' + this.authService.getAuthToken(), "_blank");
    }
  }

  downloadCompanyUserList(request: SearchCompanyUserListReq) {

    let param = "";
    for (let key in request) {
      if (request[key] && request.hasOwnProperty(key)) {
        param += (key + '=' + request[key] + '&');
      }
    }

    if (param) {
      window.open(this.PMS_CLIENT_COMPANY_USER_LIST_DOWN + "?" + param + 'Authorization=' + this.authService.getAuthToken(), "_blank");
    } else {
      window.open(this.PMS_CLIENT_COMPANY_USER_LIST_DOWN + '?Authorization=' + this.authService.getAuthToken(), "_blank");
    }
  }

  updateCompany(req: ClientCompanyUpdateRequest): Promise<any> {
    return this.requestService.authPost(this.COMPANY_UPDATE, req);
  }

  getSubjectInfo(req: CompanySubjectInfoRequest): Promise<any> {
    return this.requestService.authGet(this.COMPANY_SUBJECT_INFO, req);
  }

  public pmsConfigModule(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.PMS_CONFIG_MODULE, requestData);
  }

  public clientUserList(req: ClientUserSearchListRequest): Promise<ClientUserSearchListResponse> {
    return this.requestService.authGet(this.GET_PMS_CLIENT_USER_LIST, req);
  }

  public clientInfoBaseData(): Promise<ClientUserBaseDataResponse> {
    return this.requestService.authGet(this.GET_PMS_CLIENT_BASE_DATA);
  }

  public addFollowUp(req: ClientUserFollowUpSubmitRequest): Promise<any> {
    return this.requestService.authPost(this.POST_PMS_CLIENT_ADD_FOLLOWUP, req);
  }

  public addWorkRemindFollowUp(req: WorkRemindDisposeUserFollowUpRequest): Promise<any> {
    return this.requestService.authPost(this.POST_WORK_REMIND_PMS_CLIENT_ADD_FOLLOWUP, req);
  }
}
