/**
 * @Description:   将当前数据放入数组中如果存在则将新值存入数组，反之则存入，   最后返回最新数组
 * @Param:
 * @return:  Array
 * @Author: zhoujiahao
 * @Date: 2018-03-29  11:03
 */
export function joinToArray(array?: Array<number>, element?: any): Array<any> {
  //判断当前模块是否存在心新加入的元素
  let temp = [];
  if (!array) {
    temp.unshift(element);
    return temp;
  }
  if (!(array.indexOf(element) >= 0)) {
    array.unshift(element);
  }
  return array;
}

/**
 * @Description:  移除数组中的元素
 * @Param:
 * @return:  Array
 * @Author: zhoujiahao
 * @Date: 2018-03-29  11:03
 */
export function removeFromArray(array?: Array<number>, element?: any): Array<any> {
  let newArray = [];
  array.forEach(value => {
    if (value !== element) {
      newArray.unshift(value);
    }
  });
  array = newArray;
  return newArray;
}

/**
 * @Description:   去除两个数组重复的数据后合并
 * @Param:
 * @return:
 * @Author: zhoujiahao
 * @Date: 2018-03-29  14:45
 */
export function distinctArray(array1?: Array<number>, array2?: Array<number>): Array<any> {
  let newArray = array1;
  array2.forEach(value => {
    newArray = joinToArray(newArray, value);
  });
  return newArray;
}

/**
 * @Description:   数组相减
 * @Param:    arry1-arry2
 * @return:  Array
 * @Author: zhoujiahao
 * @Date: 2018-03-29  14:45
 */
export function divedArray(arry1?: Array<number>, array2?: Array<number>): Array<any> {
  let newArray = arry1;
  if (newArray.length <= 0) {
    return newArray;
  }
  array2.forEach(value => {
    newArray = removeFromArray(newArray, value);
  });
  return newArray;
}

/**
 * @Description:   取交集
 * @Author: zhoujiahao
 * @Date: 2018-03-29  14:45
 */
export function differArray(arry1?: Array<number>, array2?: Array<number>): Array<any> {
  let newArray = [];
  arry1.forEach(value => {
    array2.forEach(value2 => {
      if (value2 === value) {
        newArray.unshift(value);
      }

    });
  });
  return newArray;
}

/**
 * @Description: 获取[star...end]的数组
 * @Author: 陆炳彬
 * @Date: 2018/8/2 下午5:38
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/8/2 下午5:38
 */
export function generateArr(start: number, end: number): number[] {
  let arr: number[] = [];
  for (let i = start; i <= end; i++) {
    arr.push(i);
  }
  return arr;
}


/**
 * @Description: 数组中是否包含
 * @Author: 陆炳彬
 * @Date: 2018/7/18 下午3:35
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/18 下午3:35
 */
export function arrContains(arr: any[], str: any): boolean {
  if (arr && arr.length > 0) {
    let iscontain = false;
    arr.forEach(item => {
      if (!iscontain && item === str) {
        iscontain = true;
      }
    });
    return iscontain;
  }
  return false;
}

export function removeFromStrArray(array?: Array<string>, element?: any): Array<any> {
  let newArray = [];
  array.forEach(value => {
    if (value !== element) {
      newArray.unshift(value);
    }
  });
  array = newArray;
  return newArray;
}
