import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RequestService } from '../request.service';
import { BillTypeGrouPDTO, BillTypeReqParam, FinanaceListDTO, FinanceListReqParam, PayTypeListDTO, PayTypeReqParam, RoomBoxDTO, RoomBoxReqParam } from '../../model/finance/finance.model';

import { FinanceDetailDTO, FinanceDetailForm, FinanceDetailReqParam, ReverseRequest } from "../../model/finance/bill-detail.model";

@Injectable()
export class FinanceService {

  private ROOMBOX_LIST_URL = `${environment.apiHost}manage/room/box/list`;
  private BILLTYPEBOX_LIST_URL = `${environment.apiHost}billtype/list`;
  private LEASEROOMBILL_LIST_URL = `${environment.apiHost}finance/list`;
  private RENTER_LEASEROOMBILL_LIST_URL = `${environment.apiHost}finance/leaselist`;
  private LEASEROOMBILL_DETAIL_URL = `${environment.apiHost}finance/detailfinance`;
  private LEASEROOMBILL_DETAIL_EDIT_URL = `${environment.apiHost}finance/detailedit`;
  private LEASEROOMBILL_ADD_URL = `${environment.apiHost}finance/createfinance`;
  private PAYTYPEBOX_LIST_URL = `${environment.apiHost}paytype/list`;
  private LEASEROOMBILL_GATHERING_URL = `${environment.apiHost}finance/gatheringfinance`;
  private LEASEROOMBILL_SPLIT_URL = `${environment.apiHost}finance/splitfinance`;
  private TRADING_LIST_URL = `${environment.apiHost}tradingscene/list`;
  private LEASEROOMBILL_BILL_REVERSE_URL = `${environment.apiHost}finance/reversebill`;
  private LEASEROOMBILL_PAY_REVERSE_URL = `${environment.apiHost}finance/reversepaylog`;
  private LEASEROOMBILL_EXTRAREFUND_URL = `${environment.apiHost}finance/extrarefund`;
  //付款类型
  private PAY_TYPE_URL = `${environment.apiHost}paytype/list`;

  constructor(
    //引用服务
    private requestService: RequestService
  ) {
  }

  //房源下拉列表
  getRoomBoxList(roomBoxReqParam: RoomBoxReqParam): Promise<RoomBoxDTO> {
    return this.requestService.authGet(this.ROOMBOX_LIST_URL, roomBoxReqParam);
  }

  //账单项 下拉列表
  getBillTypeBoxList(billTypeReqParam: BillTypeReqParam): Promise<BillTypeGrouPDTO> {
    return this.requestService.authGet(this.BILLTYPEBOX_LIST_URL, billTypeReqParam);
  }

  //账单列表
  getListByPage(financeListReqParam: FinanceListReqParam): Promise<FinanaceListDTO> {
    return this.requestService.authGet(this.LEASEROOMBILL_LIST_URL, financeListReqParam);
  }

  //租约账单列表
  getRenterListByPage(financeListReqParam: FinanceListReqParam): Promise<FinanaceListDTO> {
    return this.requestService.authGet(this.RENTER_LEASEROOMBILL_LIST_URL, financeListReqParam);
  }

  //账单详情
  getBillRoomBilldDetail(detailReqParam: FinanceDetailReqParam): Promise<FinanceDetailDTO> {
    return this.requestService.authGet(this.LEASEROOMBILL_DETAIL_URL, detailReqParam);
  }

  //账单详情修改/作废
  detailEdit(financeDetailForm: FinanceDetailForm): Promise<boolean> {
    return this.requestService.authPost(this.LEASEROOMBILL_DETAIL_EDIT_URL, financeDetailForm);
  }

  //添加账单
  createBill(origin: any): Promise<boolean> {
    return this.requestService.authPost(this.LEASEROOMBILL_ADD_URL, origin);
  }

  //收款方式 列表
  getPayTypeBoxList(payTypeReqParam: PayTypeReqParam): Promise<PayTypeListDTO> {
    return this.requestService.authGet(this.PAYTYPEBOX_LIST_URL, payTypeReqParam);
  }

  //收款
  gatheringBill(origin: any): Promise<any> {
    return this.requestService.authPost(this.LEASEROOMBILL_GATHERING_URL, origin);
  }

  //拆分
  splitBill(origin: any): Promise<boolean> {
    return this.requestService.authPost(this.LEASEROOMBILL_SPLIT_URL, origin);
  }

  //冲账单
  reverseBill(reverseRequest: ReverseRequest): Promise<boolean> {
    return this.requestService.authPost(this.LEASEROOMBILL_BILL_REVERSE_URL, reverseRequest);
  }

  //冲流水
  reversePay(reverseRequest: ReverseRequest): Promise<boolean> {
    return this.requestService.authPost(this.LEASEROOMBILL_PAY_REVERSE_URL, reverseRequest);
  }

  extraRefund(origin: any): Promise<boolean> {
    return this.requestService.authPost(this.LEASEROOMBILL_EXTRAREFUND_URL, origin);
  }

  // getOne(repairInfoReqParam: RepairInfoReqParam): Promise<RepairInfo> {
  //
  //   let repairInfo: RepairInfo = new RepairInfo();
  //
  //   repairInfo.repair_id = repairInfoReqParam.repair_id;
  //   repairInfo.room_num = '40' + repairInfoReqParam.repair_id;
  //   repairInfo.create_time = new Date().getTime();
  //
  //   return Promise.resolve(repairInfo);
  // }

  getPaymentType(origin: any): Promise<any> {
    return this.requestService.authPost(this.PAY_TYPE_URL, origin);
  }

}
