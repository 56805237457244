
<div class="setbox">
  <div class="row">
    <div class="col-12 col-sm-6 col-md-3 col-xl-2 col-2xl-2 mb10" >
      <div class="form-group d-inline-block w-100">
        <label>提交开始日期</label>
        <my-date-picker (inputFieldChanged)="endTimeChange()" placeholder="开始日期" [options]="myPastDatePickerOptions" [(ngModel)]="start_time"></my-date-picker>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-3 col-xl-2 col-2xl-2 mb10" >
      <div class="form-group d-inline-block w-100" >
        <label>提交结束日期</label>
        <my-date-picker placeholder="结束日期" [options]="endDatePickerOptions" [(ngModel)]="end_time"></my-date-picker>
      </div>
    </div>

    <div style="top: -5px;">
      <div class="form-group" style="max-width: 2400px">
        <label class="w-100 d-web">&nbsp;</label>
        <button (click)="goPage(1)" class="btn btn-info" type="button" >
          <i class="fa fa-search"></i> 搜索
        </button>
      </div>
    </div>
  </div>

  <table class="table table-bordered table-striped">
    <thead>
    <tr>
      <th class="show2">审批单号</th>
      <th>申请内容</th>
      <th>提交时间</th>
      <th>审批结果</th>
      <th class="show2">密码是否已获取</th>
      <th>操作</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="!resp?.lock_password_list">
      <td class="show1" colspan="4" *ngIf="resp?.total == 0">本次未查询到相关数据</td>
      <td class="show2" colspan="9" *ngIf="resp?.total == 0">本次未查询到相关数据</td>
    </tr>
    <ng-container *ngIf="resp?.lock_password_list && resp?.lock_password_list.length > 0">
      <tr *ngFor="let item of resp?.lock_password_list  | paginate: {itemsPerPage: req?.page_size,
        currentPage: req?.page_num, totalItems: resp?.total }">
        <td class="show2">{{item?.approv_num}}</td>
        <td>
          {{item?.password_type}}
        </td>
        <td>{{item?.submit_time|date:'yyyy-MM-dd HH:mm:ss'}}</td>
        <td class="show2">
          <ng-container *ngIf="item?.status==='AUDIT_SUCCESS'">
            <span class="badge badge-success badge-pill">同意</span>
          </ng-container>
          <ng-container *ngIf="item?.status==='AUDIT_FAIL'">
            <span class="badge badge-danger badge-pill">拒绝</span>
          </ng-container>
          <ng-container *ngIf="item?.status==='CANCEL'">
            <span class="badge badge-warning badge-pill">取消</span>
          </ng-container>
          <ng-container *ngIf="item?.status==='WAITING_AUDIT'">
            <span class="badge badge-warning badge-pill">审核中</span>
          </ng-container>
        </td>
        <td>{{item.send === true?"已获取":"未获取"}}</td>
        <td>
          <button class="btn btn-info" type="button" (click)="showDetail(item)">
            <i class="fa fa-search"></i>查看
          </button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <div class="text-right pages" *ngIf="resp?.lock_password_list">
    <app-page *ngIf="resp?.total !== 0" (pageChangeEvent)="goPage($event)"></app-page>
    <div class="text-right">共有{{resp.total}}条记录</div>
  </div>
</div>

<app-approv-record-detail-modal></app-approv-record-detail-modal>
