import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { RequestService } from "../request.service";

@Injectable()
export class AssetService {

  // 资产入库
  private ASSET_GODOWN_EIDT = `${environment.apiHost}asset/godown/edit`;
  // 入库单审核
  private ASSET_GODOWN_ADUIT = `${environment.apiHost}asset/godown/aduit`;
  // 入库单列表
  private ASSET_GODOWN_LIST = `${environment.apiHost}asset/godown/list`;
  // 入库单详情（某个入库单的批次列表）
  private ASSET_GODOWN_DETAIL = `${environment.apiHost}asset/godown/detail`;
  // 具体资产详情
  private ASSET_INFO_DETAIL = `${environment.apiHost}asset/info/detail`;
  // 具体资产修改
  private ASSET_INFO_EDIT = `${environment.apiHost}asset/info/edit`;
  // 具体资产搜索列表
  private ASSET_INFO_LIST = `${environment.apiHost}asset/info/list`;
  // 资产类别、名称、型号、品牌列表
  private ASSET_CLASS_LIST = `${environment.apiHost}asset/class/list`;
  // 资产分配 接口（用于手机端 扫码关联
  private ASSET_INFO_ALLOT = `${environment.apiHost}asset/info/allot`;
  // 资产房间列表
  private ASSET_ROOM_LIST = `${environment.apiHost}asset/room/allot`;
  //批次下载
  private ASSET_DOWNLOAD = `${environment.apiHost}asset/group/export`;
  //资产下载
  private ASSET_GROUP_DOWNLOAD = `${environment.apiHost}asset/export/excel`;
  // 资产转移记录
  private ASSET_TRANSFER_LOG = `${environment.apiHost}asset/transfer/log`;
  // 搜索房间列表
  private ROOM_SEARCH_VAGUE = `${environment.apiHost}manage/room/search/vague`;

  // -------  2019-01-08 v1.12 ---------
  // 采购合同管理列表
  private ASSET_CONTRACT_LIST = `${environment.apiHost}asset/contract/list`;
  //采购合同基本信息
  private ASSET_CONTRACT_DETAIL = `${environment.apiHost}asset/contract/detail`;
  //新增资产采购合同
  private ASSET_CONTRACT_ADD = `${environment.apiHost}asset/contract/add`;
  //批量添加文件解析
  private ASSET_IMPORT_ANALYZE = `${environment.apiHost}asset/import/analyze`;
  //资产日志
  private ASSET_LOG_LIST = `${environment.apiHost}asset/log/list`;
  //采购资产批次详情
  private ASSET_BATCH_DETAIL = `${environment.apiHost}asset/batch/detail`;
  // 采购资产入库
  private ASSET_BATCH_GROUP_IN = `${environment.apiHost}asset/batch/group/in`;
  // 资产 / 资产管理
  private ASSET_BATCH_GROUP_LIST = `${environment.apiHost}asset/batch/group/list`;
  // 资产 / 资产转移列表
  private ASSET_BATCH_TRANS_LIST = `${environment.apiHost}asset/batch/transfer/list`;
  // 资产盘点批次分组列表
  private ASSET_CHECK_BATCH_GROUP_LIST = `${environment.apiHost}asset/check/batchGroupList`;
  // 资产盘点详情批次分组列表
  private ASSET_CHECK_DETAIL_ASSET_GROP_LIST = `${environment.apiHost}asset/check/detailAssetGropList`;
  // 盘点管理列表
  private ASSET_CHECK_LIST = `${environment.apiHost}asset/check/list`;
  // 资产盘点操作
  private ASSET_CHECK_ACTION = `${environment.apiHost}asset/check/action`;
  // 盘点方案保存
  private ASSET_CHECK_SAVE = `${environment.apiHost}asset/check/save`;

  private ASSET_CHECK_REMOVE_LIST = `${environment.apiHost}asset/check/removeAssetList`;
  // 资产盘点负责人
  private ASSET_CHECK_PRINCIPAL = `${environment.apiHost}asset/check/checkPrincipal`;
  // 资产盘点详情
  private ASSET_CHECK_DETAIL = `${environment.apiHost}asset/check/detail`;
  // 资产盘点详情资产列表
  private ASSET_CHECK_DETAIL_ASSET_LIST = `${environment.apiHost}asset/check/detailAssetList`;
  // 资产盘点详情资产列表下载
  private ASSET_CHECK_DETAIL_DOWN_ASSET_LIST = `${environment.apiHost}asset/check/downDetailAssetList`;
  // 资产转移审批记录详情
  private ASSET_EDIT_RECORD_DETAIL = `${environment.apiHost}asset/editRecordDetail`;
  // 资产转移操作
  private ASSET_TRANS_OP = `${environment.apiHost}asset/transfer/op`;
  // 获取资产转移审批记录列表
  private ASSET_EDIT_RECORD_LIST = `${environment.apiHost}asset/editRecordList`;
  // 资产转移审核
  private ASSET_EDIT_AUDIT = `${environment.apiHost}asset/edit/audit`;
  // 资产出库记录
  private ASSET_OUT_STOCK_RECORD = `${environment.apiHost}asset/outStockRecord`;
  // 该批次资产列表
  private ASSET_BATCH_GROUP_ASSET_LIST = `${environment.apiHost}asset/batch/group/asset/list`;
  // 入库资产批次审核
  private ASSET_BATCH_GROUP_AUDIT = `${environment.apiHost}asset/batch/group/audit`;
  // 待入库批次列表
  private ASSET_BATCH_GROUP_STORAGE_LIST = `${environment.apiHost}asset/batch/group/storage/list`;
  // Batch_Group 及 已 入库批次列表
  private ASSET_BATCH_GROUP_STORAGE_BATCH_LIST = `${environment.apiHost}asset/batch/group/storage/batch/list`;
  // 资产批次取消
  private ASSET_BATCH_CANCEL = `${environment.apiHost}asset/batch/cancel`;
  // 删除资产合同
  private ASSET_CONTRACT_DELETE = `${environment.apiHost}asset/contract/delete`;
  // 条形码打印
  private ASSET_BARCODE_PRINT = `${environment.apiHost}asset/barcode/print`;

  private ASSET_CHANGE_REMIND_LIST = `${environment.apiHost}asset/change/remind/list`;

  private ASSET_CHANGE_REMIND_READ = `${environment.apiHost}asset/change/remind/read`;
  private ASSET_OUT_PROJECT_LIST = `${environment.apiHost}asset/out/project/list`;
  private ASSET_UPDATE_CONTRACT_PDF = `${environment.apiHost}asset/update/contract/pdf`;

  constructor(private requestService: RequestService) {
  }

  /**
   * 资产入库
   * @param params
   * @returns {Promise<any>}
   */
  assetGodownEdit(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_GODOWN_EIDT, params);
  }

  /**
   * 入库单审核
   * @param params
   * @returns {Promise<any>}
   */
  assetGodownAudit(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_GODOWN_ADUIT, params);
  }

  /**
   * 入库单列表
   * @param params
   * @returns {Promise<any>}
   */
  assetGodownList(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_GODOWN_LIST, params);
  }

  /**
   * 入库单详情（某个入库单的批次列表）
   * @param params
   * @returns {Promise<any>}
   */
  assetGodownDetail(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_GODOWN_DETAIL, params);
  }

  /**
   * 具体资产详情
   * @param params
   * @returns {Promise<any>}
   */
  assetInfoDetail(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_INFO_DETAIL, params);
  }

  assetExportDownload(): String {
    return this.ASSET_DOWNLOAD;
  }

  assetGroupDownload(): String {
    return this.ASSET_GROUP_DOWNLOAD;
  }

  /**
   * 资产转移记录
   * @param params
   * @returns
   */
  assetTransferLog(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_TRANSFER_LOG, params);
  }

  /**
   * 具体资产修改
   * @param params
   * @returns {Promise<any>}
   */
  assetInfoEdit(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_INFO_EDIT, params);
  }

  /**
   * 具体资产搜索列表
   * @param params
   * @returns {Promise<any>}
   */
  assetInfoList(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_INFO_LIST, params);
  }

  assetInfoListWithLoading(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_INFO_LIST, params, true);
  }

  /**
   * 资产类别、名称、型号、品牌列表
   * @param params
   * @returns {Promise<any>}
   */
  assetClassList(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_CLASS_LIST, params);
  }

  /**
   * 资产分配 接口（用于手机端 扫码关联）
   * @param params
   * @returns {Promise<any>}
   */
  assetInfoAllot(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_INFO_ALLOT, params);
  }

  /**
   * 资产房间列表
   * @param params
   * @returns {Promise<any>}
   */
  assetRoomList(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_ROOM_LIST, params);
  }

  /**
   * 搜索房间列表
   * @param params
   */
  roomSearchVague(params: any): Promise<any> {
    return this.requestService.authGet(this.ROOM_SEARCH_VAGUE, params);
  }

  /**
   * 采购合同管理列表
   * @param params
   */
  assetContractList(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_CONTRACT_LIST, params);
  }

  assetContractListWithLoading(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_CONTRACT_LIST, params, true);
  }

  /**
   * 采购合同基本信息
   * @param params
   */
  assetContractDetail(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_CONTRACT_DETAIL, params);
  }

  /**
   * 资产日志
   * @param params
   */
  assetLogList(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_LOG_LIST, params);
  }

  /**
   * 采购资产详情
   * @param params
   */
  assetBatchDetail(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_BATCH_DETAIL, params);
  }

  /**
   * 采购资产入库
   * @param params
   */
  assetBatchGroupIn(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_BATCH_GROUP_IN, params);
  }

  /**
   * 资产 / 资产转移列表
   * @param params
   */
  assetBatchTransList(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_BATCH_TRANS_LIST, params);
  }

  assetCheckBatchGroupList(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_CHECK_BATCH_GROUP_LIST, params);
  }

  assetCheckDetailAssetGropList(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_CHECK_DETAIL_ASSET_GROP_LIST, params);
  }

  /**
   * 盘点管理列表
   * @param params
   */
  getAssetCheckList(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_CHECK_LIST, params);
  }

  /**
   * 资产盘点操作
   * @param params
   */
  assetCheckAction(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_CHECK_ACTION, params);
  }

  /**
   * 盘点方案保存
   * @param params
   */
  assetCheckSave(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_CHECK_SAVE, params);
  }

  assetCheckRemoveList(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_CHECK_REMOVE_LIST, params);
  }

  /**
   * 资产盘点负责人
   * @returns
   */
  assetCheckPrincipal(): Promise<any> {
    return this.requestService.authGet(this.ASSET_CHECK_PRINCIPAL, {});
  }

  /**
   * 资产盘点详情
   * @returns
   */
  assetCheckDetail(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_CHECK_DETAIL, params);
  }

  /**
   * 资产盘点详情资产列表
   * @returns
   */
  assetCheckDetailAssetList(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_CHECK_DETAIL_ASSET_LIST, params);
  }

  /**
   * 资产盘点详情资产列表下载
   * @returns
   */
  assetCheckDownload(): String {
    return this.ASSET_CHECK_DETAIL_DOWN_ASSET_LIST;
  }

  /**
   * 资产转移审批记录详情
   * @param params
   */
  assetEditRecordDetail(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_EDIT_RECORD_DETAIL, params);
  }

  /**
   * 资产转移操作
   * @param params
   */
  assetTransOp(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_TRANS_OP, params);
  }

  /**
   * 获取资产转移审批记录列表
   * @param params
   */
  getEditRecordList(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_EDIT_RECORD_LIST, params);
  }

  /**
   * 资产转移审批
   * @param params
   */
  assetEditAudit(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_EDIT_AUDIT, params);
  }

  /**
   * 资产出库记录
   * @param params
   */
  assetOutStockRecord(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_OUT_STOCK_RECORD, params);
  }

  /**
   * 资产 / 资产管理
   * @param params
   */
  assetBatchGroupList(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_BATCH_GROUP_LIST, params);
  }

  assetBatchGroupListWithLoading(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_BATCH_GROUP_LIST, params, true);
  }

  /**
   * 该批次资产列表
   * @param params
   */
  assetBatchGroupAssetList(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_BATCH_GROUP_ASSET_LIST, params);
  }

  /**
   * 入库资产批次审核
   * @param params
   */
  assetBatchGroupAudit(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_BATCH_GROUP_AUDIT, params);
  }

  /**
   * 待入库批次列表
   * @param params
   */
  assetBatchGroupAssetStorageList(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_BATCH_GROUP_STORAGE_LIST, params);
  }

  /**
   * 新增资产采购合同
   * @param params
   */
  assetContractAdd(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_CONTRACT_ADD, params);
  }

  /**
   *批量添加文件解析
   * @param params
   */
  importAssetAnalyze(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_IMPORT_ANALYZE, params);
  }

  /**
   * Batch_Group 及 已 入库批次列表
   * @param params
   */
  assetBatchGroupStorageBatchList(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_BATCH_GROUP_STORAGE_BATCH_LIST, params);
  }

  assetChangeRemindList(params: any): Promise<any> {
    return this.requestService.authGet(this.ASSET_CHANGE_REMIND_LIST, params);
  }

  assetChangeRemindRead(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_CHANGE_REMIND_READ, params);
  }

  /**
   *资产批次取消
   * @param params
   */
  assetBatchCancel(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_BATCH_CANCEL, params);
  }

  /**
   * 删除资产合同
   * @param params
   */
  assetContractDelete(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_CONTRACT_DELETE, params);
  }

  /**
   * 条形码打印
   * @param params
   */
  assetBarcodePrint(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_BARCODE_PRINT, params);
  }

  assetOutProjectList(): Promise<any> {
    return this.requestService.authGet(this.ASSET_OUT_PROJECT_LIST);
  }

  assetUpdateContractPdf(params: any): Promise<any> {
    return this.requestService.authPost(this.ASSET_UPDATE_CONTRACT_PDF, params);
  }
}
