import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {getTimeFromPlugin} from '../../common/date.util';
import {ThrowLeaseParam} from '../../model/finance/finance.model';
import {
  ApprovalResponse,
  ApprovEditRequest,
  AuditPersonResponse,
  ChangeRoomAvailableDaysFlowAuditResponse,
  ChangeRoomAvailableDaysRequest, CheckInLimitDetailResponse,
  CheckInLimitResponse,
  FirstRentBillPayResponse,
  FlowLeaseLogResponse, FrozenPasswordFlowAuditResponse,
  IsRoomAvaliableReq,
  LeaseBillFlowAuditResponse,
  LeaseContactRequest,
  LeaseContactResponse,
  LeaseListReqParam,
  LeaseListResponse,
  LeaseLogRequest, LeaseSimpleDetailResponse,
  LeaseSubjectFlowAuditResponse,
  LeaseSubjectRequest,
  LeaseSubjectResponse, LeaseWithinTenDaysRequest,
  LogListReq, LogListResponse,
  ModelCodeRequest,
  RoomAvailableTimeResponse,
  SubleaseSelectResponse,
  WaitSignContractListResponse
} from '../../model/lease/lease.model';
import {AvaliableRoomListReq, AvaliableRoomListResp} from '../../model/lease/room_choose.model';
import {PageReq} from "../../model/page/page";
import {RequestService} from '../request.service';
import {
  AlertListReqParam,
  FrozenDoorPasswordAuditRequest,
  FrozenDoorPasswordSmsCodeRequest,
  UnFrozenDoorPasswordRequest
} from "../../model/workbench/workbench.model";
import {AuthService} from "../common/auth.service";

/**
 * @Description: 租约服务
 * @Author: 陆炳彬
 * @Date: 2018/3/29 上午10:23
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/3/29 上午10:23
 */
@Injectable()
export class LeaseService {

  // 获取房型及可用房间数
  private AVALIABLE_ROOM_LIST = `${environment.apiHost}manage/lease/avaliable/room/list`;
  // 获取房型及可用房间数（仅用于无合同换房、有合同的合同内换房）
  private CR_AVALIABLE_ROOM_LIST = `${environment.apiHost}manage/lease/cr/avaliable/room/list`;
  // 获取收取类型列表
  private GATHER_TYPE_LIST = `${environment.apiHost}common/gatherType/list`;
  // 创建租约
  private CREATE_LEASE = `${environment.apiHost}manage/lease/create`;
  // 租约详情
  private LEASE_INFO_DETAIL_URL = `${environment.apiHost}manage/lease/detail`;
  // 租约列表
  private LEASE_LIST_URL = `${environment.apiHost}manage/lease/list`;
  // 租约数据统计
  private GET_LEASE_STATUS_COUNT = `${environment.apiHost}manage/lease/data/count`;
  // 获取租约日志
  private GET_LEASE_LOG = `${environment.apiHost}manage/log/list`;
  // 获取租约核销日志
  private GET_LEASE_ACCOUNT_LOG = `${environment.apiHost}manage/log/list/account`;
  // 获取退房审核数据
  private GET_LEASE_REVIEW_INFO = `${environment.apiHost}manage/lease/throwlease/getLeaseReviewInfo`;
  // 退房审核确认
  private CONFIRM_LEASE_REVIEW_INDO = `${environment.apiHost}manage/lease/throwlease/leaseReviewConfirm`;
  // 获取查看合同地址
  private FILE_GET_CONTRACT_LIST = `${environment.apiHost}image/getContractUrl`;
  // 合同生效
  private LEASE_CONTRACT_CONFIRM = `${environment.apiHost}manage/lease/contract/confirm`;
  // 房间租金明细
  private ROOM_RENT_LIST = `${environment.apiHost}manage/lease/rent/list`;
  // 房间其它费用列表
  private ROOM_EXPENSE_LIST = `${environment.apiHost}manage/lease/expense/list`;
  // 根据预定id获取租约id
  private GET_LEASE_ID_BY_BOOKING_ID = `${environment.apiHost}manage/lease/get_lease_id`;
  // 查询房间是否可用
  private IS_ROOM_AVALIABLE = `${environment.apiHost}manage/lease/is/room/avaliable`;
  // 查询电子合同信息
  private ELEC_CONTRACT_INFO = `${environment.apiHost}manage/lease/contractInfo`;
  // 合同额外信息
  private LEASE_PREVIEW_EXT = `${environment.apiHost}manage/lease/contract/preview/ext`;
  // 合同签字
  private ELEC_CONTRACT_CONFIRM = `${environment.apiHost}manage/lease/contract/sign`;
  // 10天内即将到期合同
  private LEASE_BOOKING_WITHIN_TEN_DAYS = `${environment.apiHost}lease/booking/within/ten/days`;
  // 房间预付费账户欠费提醒
  private PREPAY_ARREARAGE_URL = `${environment.apiHost}prepay/room/arrearage/list`;
  // 上传租约附件
  private LEASE_ATTACHMENT_URL = `${environment.apiHost}manage/lease/attachment/add`;
  // 换房转租提醒列表
  private LEASE_BOOKING_REMIND_SUBLEASE_CHANGE_LIST = `${environment.apiHost}lease/booking/remind/sublease/change/list`;
  // 换房转租已读
  private LEASE_BOOKING_REMIND_SUBLEASE_CHANGE_READ = `${environment.apiHost}lease/booking/remind/sublease/change/read`;

  // 转租房间列表
  private LEASE_SUBLEASE_ROOM_LIST = `${environment.apiHost}manage/lease/sublease/room`;

  //已签署合同联系人变更获取审核人列表
  private LEASE_CONTACTS_AUDITPERSON_LIST = `${environment.apiHost}manage/lease/contacts/findAuditPersons`;
  //已签署合同联系人
  private LEASE_CHANGE_CONTACTS = `${environment.apiHost}manage/lease/contacts/changeContacts`;
  private LEASE_CHANGE_CONTACTS_GETTELCODE = `${environment.apiHost}manage/lease/contacts/getMobileCode`;

  // 删除租约附件
  private DEL_LEASE_ATTACHMENT_URL = `${environment.apiHost}manage/lease/attachment/del`;

  // 删除合同文件
  private DELETE_CONTRACT = `${environment.apiHost}manage/lease/del/contract`;

  // 获取可批量充值的房间列表
  private GET_BATCH_RECHARGE_ROOM = `${environment.apiHost}manage/lease/recharge/room`;

  // 查询销售的合同列表
  private GET_LEASE_LIST_BY_ADVISER = `${environment.apiHost}manage/lease/list/byAdviser`;

  // 查询待签署的合同列表
  private GET_WAIT_SIGN_LIST = `${environment.apiHost}manage/lease/waitsign/list`;



  // 合同主体信息查询
  private GET_LEASE_SUBJECT_DETAIL = `${environment.apiHost}subject/lease/leaseSubjectDetail`;
  // 合同主体信息查询
  private POST_LEASE_SUBJECT_DETAIL = `${environment.apiHost}subject/lease/changeSubject`;
  // 合同主体信息审批详情
  private GET_LEASE_SUBJECT_AUDIT_DETAIL = `${environment.apiHost}subject/lease/approvDetailInfo`;
  // 合同主体信息审批详情
  private GET_LEASE_BILL_AUDIT_DETAIL = `${environment.apiHost}subject/lease/leaseBillChangeApprovDetailInfo`;
  // 合同主体信息审批 操作
  private POST_APPROV_AUDIT = `${environment.apiHost}flow/approvalOperation`;

  // 合同流程提交记录
  private GET_LEASE_FLOW_SUBMIT_LOG = `${environment.apiHost}flow/lease/submitLog`;
  // 首期房费是否支付
  private FIRST_RENT_BILL_PAY = `${environment.apiHost}manage/lease/first/rent/bill/pay`;
  // 查询租约白名单期限
  private QUERY_ROOM_AVAILABLE_TIME = `${environment.apiHost}manage/lease/query/room/available/time`;
  // 提交房间使用期限审核
  private CHANGE_ROOM_AVAILABLE_TIME= `${environment.apiHost}manage/lease/change/room/available/days`;
  // 提交房间使用期限审核
  private ROOM_AVAILABLE_TIME_CHANGE_AUDIT_DETAIL= `${environment.apiHost}manage/lease/room/available/days/change/audit/detail`;
  // 入住限制截止3天清单
  private CHECKIN_LIMIT_LEASE_LIST = `${environment.apiHost}manage/lease/checkin/limit/lease/list`;
  private CHECKIN_LIMIT_LEASE_DETAIL = `${environment.apiHost}manage/lease/checkin/limit/lease/list/detail`;


  private AUDIT_USER_LIST = `${environment.apiHost}manage/lease/frozen/password/audit/user/list`;
  private FROZEN_AUDIT_SEND_SMS_CODE = `${environment.apiHost}manage/lease/frozen/password/sms/code`;
  private FREEZE_PASSWORD_CONFIRM = `${environment.apiHost}manage/lease/frozen/password/confirm`;
  private UNFREEZE_PASSWORD_CONFIRM = `${environment.apiHost}manage/lease/unfrozen/password/confirm`;
  private FROZEN_PASSWORD_OPERATION_LOG = `${environment.apiHost}manage/log/frozen/password/operation/log`;
  private FROZEN_PASSWORD_AUDIT_DETAIL = `${environment.apiHost}manage/lease/frozen/password/audit/detail`;
  private WITHIN_TEN_DAYS_LIST_DOWNLOAD = `${environment.apiHost}lease/booking/within/ten/days/download`;

  private LEASE_SIMPLE_DETAIL = `${environment.apiHost}manage/lease/simple/detail`;

  constructor(private requestService: RequestService,
              private authService: AuthService) {
  }

  /**
   * 获取额外费用列表
   */
  public getBillTypeWithGather(): Promise<any> {
    return this.requestService.authGet(this.GATHER_TYPE_LIST);
  }

  /**
   * 创建租约
   */
  public createLease(result: any, type?: string): Promise<any> {
    result.lease_info.start_time = getTimeFromPlugin(result.lease_info.start_time_obj.formatted);
    result.lease_info.end_time = getTimeFromPlugin(result.lease_info.end_time_obj.formatted);
    if (result.lease_info.is_free) {
      result.lease_info.free_start_time = getTimeFromPlugin(result.lease_info.free_start_time_obj.formatted);
      result.lease_info.free_end_time = getTimeFromPlugin(result.lease_info.free_end_time_obj.formatted);
    }

    if (result.lease_info.lease_step === 'BOOKING') {
      result.lease_info.to_lease_date = getTimeFromPlugin(result.lease_info.to_lease_date_obj.formatted);
    } else {
      // 处理入住人手机号为空字符串的问题
      result.lease_info.room_info_list.forEach(room_info => {
        if (room_info.renter_list && room_info.renter_list.length > 0) {
          room_info.renter_list.forEach(renter => {
            if (!renter.phone || renter.phone.length === 0) {
              delete renter.phone;
            }
          });
        }
      });
    }
    result.operate_type = type.toUpperCase();

    if (result.lease_subject_info.subject_type === 'PERSON') {
      result.lease_subject_info.name = result.lease_subject_info.person_info.name;
      result.lease_subject_info.tel = result.lease_subject_info.person_info.tel;
      result.lease_subject_info.cret_type = result.lease_subject_info.person_info.cret_type;
      result.lease_subject_info.cret_num = result.lease_subject_info.person_info.cret_num;
      result.lease_subject_info.zip = result.lease_subject_info.person_info.zip;
      result.lease_subject_info.email = result.lease_subject_info.person_info.email;
      result.lease_subject_info.address = result.lease_subject_info.person_info.address;
    } else {
      result.lease_subject_info.company_id = result.lease_subject_info.company_info.company_id;
      result.lease_subject_info.company_name = result.lease_subject_info.company_info.company_name;
      result.lease_subject_info.contacts = result.lease_subject_info.company_info.contacts;
      result.lease_subject_info.tel = result.lease_subject_info.company_info.tel;
      result.lease_subject_info.zip = result.lease_subject_info.company_info.zip;
      result.lease_subject_info.email = result.lease_subject_info.company_info.email;
      result.lease_subject_info.address = result.lease_subject_info.company_info.address;
      result.lease_subject_info.taxpayer_num = result.lease_subject_info.company_info.taxpayer_num;
      if(result.lease_subject_info.invoice_info){
        result.lease_subject_info.invoice_type = result.lease_subject_info.invoice_info.invoice_type;
        result.lease_subject_info.invoice_title = result.lease_subject_info.invoice_info.invoice_title;
        if (result.lease_subject_info.invoice_info.taxpayer_num) {
          result.lease_subject_info.taxpayer_num = result.lease_subject_info.invoice_info.taxpayer_num;
        }
        result.lease_subject_info.bank_name = result.lease_subject_info.invoice_info.bank_name;
        result.lease_subject_info.card_number = result.lease_subject_info.invoice_info.card_number;
        result.lease_subject_info.company_address = result.lease_subject_info.invoice_info.company_address;
      }
    }
    return this.requestService.authPost(this.CREATE_LEASE, result);
  }

  /**
   * 查询租约详情
   */
  public getLeaseInfoDetail(requestData: any): Promise<any> {
    return this.requestService.authGet(this.LEASE_INFO_DETAIL_URL, requestData);
  }

  /**
   * 获取租约列表
   */
  public getLeaseList(leaseListReqParam: LeaseListReqParam): Promise<LeaseListResponse> {
    return this.requestService.authPost(this.LEASE_LIST_URL, leaseListReqParam);
  }
  public getLeaseListWithLoading(leaseListReqParam: LeaseListReqParam): Promise<LeaseListResponse> {
    return this.requestService.authPost(this.LEASE_LIST_URL, leaseListReqParam, true);
  }

  /**
   * 租约数据统计
   */
  public getLeaseStatusCount(): Promise<any> {
    return this.requestService.authGet(this.GET_LEASE_STATUS_COUNT);
  }

  /**
   * 获取租约操作日志
   */
  public getLeaseLogList(params: LogListReq): Promise<any> {
    return this.requestService.authPost(this.GET_LEASE_LOG, params);
  }
  public getLeaseLogListWithLoading(params: LogListReq): Promise<any> {
    return this.requestService.authPost(this.GET_LEASE_LOG, params);
  }

  /**
   * 获取租约操作日志
   */
  public getLeaseAccountLogList(params: LogListReq): Promise<any> {
    return this.requestService.authPost(this.GET_LEASE_ACCOUNT_LOG, params);
  }

  /**
   * 获取退租审核信息
   */
  public getLeaseReviewInfo(leaseId: number): Promise<any> {
    return this.requestService.authPost(this.GET_LEASE_REVIEW_INFO, {lease_id: leaseId});
  }

  public confirmLeaseReview(throwLeaseParam: ThrowLeaseParam): Promise<any> {
    return this.requestService.authPost(this.CONFIRM_LEASE_REVIEW_INDO, throwLeaseParam);
  }

  /**
   * 获取房型及可用房间数
   * @param requestData
   * @returns {Promise<any>}
   */
  public getAvaliableRoomList(requestData: AvaliableRoomListReq): Promise<AvaliableRoomListResp> {
    return this.requestService.authPost(this.AVALIABLE_ROOM_LIST, requestData);
  }

  /**
   * 获取房型及可用房间数
   * @param requestData
   * @returns {Promise<any>}
   */
  public getCrAvaliableRoomList(requestData: AvaliableRoomListReq): Promise<AvaliableRoomListResp> {
    return this.requestService.authPost(this.CR_AVALIABLE_ROOM_LIST, requestData);
  }

  /**
   * 获取合同地址
   */
  public getContractUrl(requestData: any): Promise<any> {
    return this.requestService.authGet(this.FILE_GET_CONTRACT_LIST, requestData);
  }

  /**
   * 合同生效
   */
  public leaseContractConfirm(leaseId: number): Promise<any> {
    return this.requestService.authPost(this.LEASE_CONTRACT_CONFIRM, {lease_id: leaseId});
  }

  /**
   * 获得房间租金列表
   */
  public getRentList(requestData: any): Promise<any> {
    return this.requestService.authGet(this.ROOM_RENT_LIST, requestData);
  }

  /**
   * 获得房间其它费用
   */
  public getExpenseList(requestData: any): Promise<any> {
    return this.requestService.authGet(this.ROOM_EXPENSE_LIST, requestData);
  }

  /**
   * 根据预定id获取租约id
   * @param requestData
   * @returns {Promise<any>}
   */
  public getLeaseIdByBookingId(requestData: any): Promise<any> {
    return this.requestService.authGet(this.GET_LEASE_ID_BY_BOOKING_ID, requestData);
  }

  /**
   * @Description: 查询房间是否可用
   * @Author: 陆炳彬
   * @Date: 2018/9/13 下午4:23
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/9/13 下午4:23
   */
  public isRoomAvaliable(params: IsRoomAvaliableReq): Promise<any> {
    return this.requestService.authGet(this.IS_ROOM_AVALIABLE, params);
  }

  /**
   * 获取电子合同信息
   * @param leaseId
   * @returns {Promise<any>}
   */
  public getELecContractInfo(leaseId: number): Promise<any> {
    return this.requestService.authGet(this.ELEC_CONTRACT_INFO, {lease_id: leaseId});
  }

  /**
   * @Description: 获取合同预览额外信息
   * @Author: 陆炳彬
   * @Date: 2018/11/8 下午4:58
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/11/8 下午4:58
   */
  public getContractPreviewExt(leaseId: number): Promise<any> {
    return this.requestService.authGet(this.LEASE_PREVIEW_EXT, {lease_id: leaseId});
  }

  /**
   * 电子合同签字确认
   */
  public contractConfrim(req: any): Promise<any> {
    return this.requestService.authPost(this.ELEC_CONTRACT_CONFIRM, req);
  }

  /**
   * 10天内即将到期合同
   * @param req
   */
  public withinTenDays(req: any): Promise<any> {
    return this.requestService.authGet(this.LEASE_BOOKING_WITHIN_TEN_DAYS, req);
  }

  /**
   * 房间预付费账户欠费提醒
   * @param req
   */
  public roomArrearageList(req: any): Promise<any> {
    return this.requestService.authGet(this.PREPAY_ARREARAGE_URL, req);
  }

  /**
   * 上传租约附件
   * @param req
   */
  public leaseAttachment(req: any): Promise<any> {
    return this.requestService.authPost(this.LEASE_ATTACHMENT_URL, req);
  }

  /**
   * @description: 获取换房转租列表
   * @Author: mazy
   * @Date: 2019/6/17 11:51
   * @Last Modified by: mazy
   * @Last Modified time: 2019/6/17 11:51
   */
  public subleaseChangeRemindList(req: any): Promise<any> {
    return this.requestService.authGet(this.LEASE_BOOKING_REMIND_SUBLEASE_CHANGE_LIST, req);
  }

  public subleaseChangeRemindRead(req: any) : Promise<any> {
    return this.requestService.authPost(this.LEASE_BOOKING_REMIND_SUBLEASE_CHANGE_READ,req);
  }

  public subleaseRoomList(leaseId: number): Promise<SubleaseSelectResponse> {
    return this.requestService.authGet(this.LEASE_SUBLEASE_ROOM_LIST, {lease_id: leaseId});
  }

  public findAuditPersons(req: any): Promise<AuditPersonResponse> {
    return this.requestService.authGet(this.LEASE_CONTACTS_AUDITPERSON_LIST, req);
  }
  public changeContacts(req: LeaseContactRequest): Promise<LeaseContactResponse> {
    return this.requestService.authGet(this.LEASE_CHANGE_CONTACTS, req);
  }
  public getMobileCode(req: ModelCodeRequest): Promise<any> {
    return this.requestService.authGet(this.LEASE_CHANGE_CONTACTS_GETTELCODE, req);
  }

  /**
   * 删除租约附件
   * @param req
   */
  public delLeaseAttachment(req: any): Promise<any> {
    return this.requestService.authPost(this.DEL_LEASE_ATTACHMENT_URL, req);
  }

  /**
   * @Description: 删除合同文件
   * @Author: yangwen
   * @Date: 2020/4/1 17:58
   */
  public delContract(req: any): Promise<any> {
    return this.requestService.authPost(this.DELETE_CONTRACT, req);
  }

  /**
   * 获取可批量充值预付费的房间
   * @param req
   * @returns {Promise<any>}
   */
  public getBatchRechargeRooms(req: any): Promise<any> {
    return this.requestService.authGet(this.GET_BATCH_RECHARGE_ROOM, req);
  }

  /**
   * 根据销售获取合同
   * @param req
   */
  public getLeaseListByAdviser(req: any): Promise<any> {
    return this.requestService.authGet(this.GET_LEASE_LIST_BY_ADVISER, req);
  }

  waitSignList(req: PageReq): Promise<WaitSignContractListResponse> {
    return this.requestService.authGet(this.GET_WAIT_SIGN_LIST, req);
  }

  getLeaseSubjectDetail(leaseId: number): Promise<LeaseSubjectResponse> {
    return this.requestService.authGet(this.GET_LEASE_SUBJECT_DETAIL, {lease_id: leaseId});
  }

  changeLeaseSubjectDetail(req: LeaseSubjectRequest): Promise<ApprovalResponse> {
    return this.requestService.authPost(this.POST_LEASE_SUBJECT_DETAIL, req);
  }

  getLeaseSubjectAuditDetail(id: number): Promise<LeaseSubjectFlowAuditResponse> {
    return this.requestService.authGet(this.GET_LEASE_SUBJECT_AUDIT_DETAIL, {appro_record_info_id: id});
  }

  postApprovAudit(req: ApprovEditRequest): Promise<any> {
    return this.requestService.authPost(this.POST_APPROV_AUDIT, req);
  }


  getLeaseBillChangeDetail(id: number): Promise<LeaseBillFlowAuditResponse> {
    return this.requestService.authGet(this.GET_LEASE_BILL_AUDIT_DETAIL, {appro_record_info_id: id});
  }

  getLeaseFlowSubmitLog(req: LeaseLogRequest): Promise<FlowLeaseLogResponse> {
    return this.requestService.authGet(this.GET_LEASE_FLOW_SUBMIT_LOG, req);
  }


  checkFirstRentBillPay(leaseId: number): Promise<FirstRentBillPayResponse> {
    return this.requestService.authGet(this.FIRST_RENT_BILL_PAY,{lease_id: leaseId});
  }

  queryRoomAvailableTime(leaseId: number): Promise<RoomAvailableTimeResponse> {
    return this.requestService.authGet(this.QUERY_ROOM_AVAILABLE_TIME,{lease_id: leaseId});
  }

  changeRoomAvailableTimeSubmit(req: ChangeRoomAvailableDaysRequest): Promise<ApprovalResponse> {
    return this.requestService.authPost(this.CHANGE_ROOM_AVAILABLE_TIME,req);
  }

  getRoomAvailableTimeChangeAuditDetail(id: number): Promise<ChangeRoomAvailableDaysFlowAuditResponse> {
    return this.requestService.authGet(this.ROOM_AVAILABLE_TIME_CHANGE_AUDIT_DETAIL, {appro_record_info_id: id});
  }

  getCheckInLimitLeaseList(req: PageReq): Promise<CheckInLimitResponse> {
    return this.requestService.authGet(this.CHECKIN_LIMIT_LEASE_LIST,req);
  }
  getCheckInLimitLeaseDetail(leaseRoomId: number): Promise<CheckInLimitDetailResponse> {
    return this.requestService.authGet(this.CHECKIN_LIMIT_LEASE_DETAIL, {lease_room_id: leaseRoomId});
  }

  frozenAuditUserList(): any{
    return this.requestService.authGet(this.AUDIT_USER_LIST,null);
  }
  frozenAuditSendSmsCode(req: FrozenDoorPasswordSmsCodeRequest): any{
    return this.requestService.authGet(this.FROZEN_AUDIT_SEND_SMS_CODE,req);
  }

  freezePasswordConfirm(req: FrozenDoorPasswordAuditRequest): Promise<ApprovalResponse>{
    return this.requestService.authPost(this.FREEZE_PASSWORD_CONFIRM,req);
  }

  unFreezePasswordConfirm(req: UnFrozenDoorPasswordRequest): Promise<any>{
    return this.requestService.authPost(this.UNFREEZE_PASSWORD_CONFIRM,req);
  }

  public getFrozenPwdOperationLogList(params: LogListReq): Promise<LogListResponse> {
    return this.requestService.authGet(this.FROZEN_PASSWORD_OPERATION_LOG, params);
  }

  getFrozenPasswordAuditDetail(id: number): Promise<FrozenPasswordFlowAuditResponse> {
    return this.requestService.authGet(this.FROZEN_PASSWORD_AUDIT_DETAIL, {appro_record_info_id: id});
  }

  withinTenDaysDownload(request: AlertListReqParam) {
    let param = "";
    for (let key in request) {
      if (request[key] && request.hasOwnProperty(key)) {
        param += (key + '=' + request[key] + '&');
      }
    }

    if (param) {
      window.open(this.WITHIN_TEN_DAYS_LIST_DOWNLOAD + "?" + param + 'Authorization=' + this.authService.getAuthToken(), "_blank");
    } else {
      window.open(this.WITHIN_TEN_DAYS_LIST_DOWNLOAD + '?Authorization=' + this.authService.getAuthToken(), "_blank");
    }
  }

  getLeaseSimpleDetail(leaseId: number, roomId: number): Promise<LeaseSimpleDetailResponse> {
    return this.requestService.authGet(this.LEASE_SIMPLE_DETAIL, {lease_id: leaseId, room_id: roomId});
  }
}
