import { Component, OnInit,Input } from '@angular/core';


@Component({
  selector: 'app-check-out-steps',
  templateUrl: './check-out-steps.component.html',
  styleUrls: ['./check-out-steps.component.css']
})
export class CheckOutStepsComponent implements OnInit {

  constructor() { }
  @Input() stepsData:any;
  @Input() active:string;
  ngOnInit() {
    if(!this.active){
      this.active="1";
    }
  }

}
