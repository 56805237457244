import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { WorkModel } from "../../../model/common/common.model";
import { RegularExp } from "../../../common/util.regexp";
import { RoomService } from "../../../service/room/room.service";
import { ActivatedRoute, Router } from "@angular/router";
import { WorkService } from "../../../service/common/work.service";
import { AuthService } from "../../../service/common/auth.service";
import { DeviceService } from "../../../service/device/device.service";
import { WorkbenchService } from "../../../service/workbench/workbench.service";
import { FinanceService } from "../../../service/finance/finance.service";
import { LogService } from "../../../service/log/log.service";
import { CouponService } from "../../../service/coupon/coupon.service";
import { OrderService } from "../../../service/order/order.service";
import { BaseInfoService } from "../../../service/common/base-info.service";
import { UserService } from "../../../service/user/user.service";
import { CompanyPrepayRechargeRequest } from "../../../model/prepay/company-prepay.model";
import { OrderInfoDTO } from "../../../model/order/order.model";
import { CompanyPrepayService } from "../../../service/prepay/company-prepay.service";
import { CouponInfoDetailByCouponNumberReq, CouponInfoDetailDTO } from "../../../model/coupon/coupon.model";
import { Decimal } from "decimal.js";
import { MessageModalInfo } from "../../../model/modal/message-model";
import {ModalDirective} from "ngx-bootstrap/modal";
import {
  AccountMoneyListDTO,
  QueryAccountLeaseRequest,
  QueryAccountLeaseResponse
} from "../../../model/clientinfo/client-info.model";
import {ClientInfoService} from "../../../service/clientinfo/ClientInfoService";

@Component({
  selector: 'app-company-prepay-recharge',
  templateUrl: './company-prepay-recharge.component.html',
  styleUrls: ['./company-prepay-recharge.component.css']
})
export class CompanyPrepayRechargeComponent implements OnInit {

  @Input()
  companyId;
  @Input()
  leaseId;
  @Input()
  contactNum;
  @Input()
  contactName;


  @ViewChild('prepaygatherBill', { static: true }) prepaygatherBillDialog: ModalDirective;
  @ViewChild('prepaycashPayment', { static: true }) prepaycashPaymentDialog: ModalDirective;
  @ViewChild('prepayposPaymnet', { static: true }) prepayposPaymnetDialog: ModalDirective;
  @ViewChild('prepaybindOrderBill', { static: true }) prepaybindOrderBillDialog: ModalDirective;
  @ViewChild('prepayorderQuery', { static: true }) prepayorderQueryDialog: ModalDirective;
  @ViewChild('prepayaliOrWeixin', { static: true }) prepayaliOrWeixinDialog: ModalDirective;
  @ViewChild('accountPayment', { static: true }) prepayAccountPaymentDialog: ModalDirective;
  aliOrWeixinDialog;

  //付款类型
  paymentType: string;
  //付款类型名称
  paymentTypeName: string;
  // 付款金额
  paymentAmount: number = 0;
  //倒计时
  clock: number;
  //展示蓝字提示
  showContent: boolean;

  hasChosenPayType: string;

  //商户订单号
  orderId: string;
  orderNum = 0;
  orderDtos: Array<OrderInfoDTO> = [];
  //订单查询不到错误提示
  orderRemind: string;

  close: number;

  prepayRechargeReq: CompanyPrepayRechargeRequest = {};

  // 优惠券
  selectCouponInput = false;
  // 代金券号
  coupon_number: string;
  coupon_info: CouponInfoDetailDTO;
  coupon_msg: string;
  coupon_flag = false;
  coupon_amount = 0;

  inputAmount;

  queryAccountLeaseReq: QueryAccountLeaseRequest = {};
  queryAccountLeaseResponse: QueryAccountLeaseResponse;
  account_money_info_id: number = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private workService: WorkService,
    private authService: AuthService,
    private roomService: RoomService,
    private deviceService: DeviceService,
    private workbenchService: WorkbenchService,
    private financeService: FinanceService,
    private logService: LogService,
    private couponService: CouponService,
    private orderService: OrderService,
    private companyPrepayService: CompanyPrepayService,
    private baseInfoService: BaseInfoService,
    private userService: UserService,
    private clientInfoService: ClientInfoService,
  ) {
  }

  ngOnInit() {
  }


  rechargeShow() {
    this.showPrepayGatherBill();
    this.prepaygatherBillDialog.show();
    this.paymentAmount = 0;
    this.inputAmount = null;
    this.selectCouponInput = false;
    this.coupon_number = null;
    this.coupon_msg = null;
  }

  closeGatherDialog(prepaygatherBill?: any) {
    this.paymentAmount = 0;
    this.prepaygatherBillDialog.hide();
  }


  prepayPayment(paymentType?: any, dialog?: any, aliOrWeixin?: any, accountMoneyInfoId?: number) {
    const req = { bill_type: 'SINGLE' };
    this.hasChosenPayType = "HAS_CHOSEN";
    if (!this.validTwoPoint(this.paymentAmount) && !this.coupon_flag) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请输入正确的金额'));
      return;
    }

    if (paymentType) {
      this.paymentType = paymentType;
      if (paymentType === "ACCOUNT_MONEY") {
        this.account_money_info_id = accountMoneyInfoId;
      }
      this.financeService.getPaymentType(req).then(res => {
        res.pay_types.forEach(data => {
          if (data.pay_type_code === paymentType) {
            this.paymentTypeName = data.pay_type_name;
          }
        });
      }).then(response => {
        this.aliOrWeixinDialog = dialog;
        if (aliOrWeixin) {
          this.showContent = false;
          this.prepayRechargeReq.cret_images = [];
          this.clock = 120;
          this.timeOut();
        }
        this.prepayposPaymnetDialog.hide();
        this.prepaycashPaymentDialog.hide();
        this.prepaygatherBillDialog.hide();
        this.prepayAccountPaymentDialog.hide();
        this.prepayRechargeReq.cret_images = [];
        if (paymentType === 'CASH' || paymentType === 'TRANSFER') {
          let nowTime = new Date().getTime();
          this.prepayRechargeReq.amount = this.paymentAmount.toString();
          this.prepayRechargeReq.company_id = this.companyId;
          this.prepayRechargeReq.lease_id = this.leaseId;
          this.prepayRechargeReq.pay_type_code = 'CASH';
          this.prepayRechargeReq.random_time = nowTime;
          this.companyPrepayService.accountRechargeCode(this.prepayRechargeReq).then(res => {
            this.prepayRechargeReq.random_token = res.random_token;
            dialog.show();
          });
        } else {
          this.prepayRechargeReq.pay_type_code = paymentType;
          this.prepayRechargeReq.random_token = '';
          dialog.show();
        }
      });
    } else {
      this.confirm(null);
    }

  }

  timeOut() {
    this.clock = this.clock - 1;
    if (this.clock === 0 || this.clock === -1) {
      this.clock = 0;
      this.showContent = true;

    } else if (this.clock > 0) {
      if (new RegExp(RegularExp.signlessInteger).test((this.clock / 5).toString())) {
        //五秒中查询一次账单对应的订单信息
        this.getBillOrderList();
      }
      setTimeout(() => this.timeOut(), 1000);
    }
  }

  getBillOrderList() {
    const reqParam = { pay_channel: this.paymentType, payment_amount: this.paymentAmount };
    this.orderService.getBillOrderList(reqParam).then(response => {
      if (response) {
        this.orderDtos = response.order_info_dtos;
        this.orderNum = this.orderDtos.length;
        if (this.orderNum > 0) {
          this.clock = 0;
          this.prepaybindOrderBillDialog.show();
          this.aliOrWeixinDialog.hide();
        }
      }
    }).catch(error => {
      this.logService.error('PactComponent:根据付款金额获取订单信息失败' + JSON.stringify(error));
    });
  }

  prepayshowQueryByOrderId(queryDialog?: any, needCloseDialog?: any) {
    this.orderId = null;
    this.orderRemind = null;
    if (needCloseDialog) {
      needCloseDialog.hide();
    }
    queryDialog.show();
  }


  closeOrderQueryDialog() {
    this.orderId = null;
    this.orderRemind = null;
    this.prepayorderQueryDialog.hide();
  }


  validTwoPoint(amount?: any): boolean {
    if (amount) {
      if (/^\d+(\.\d{0,2})?$/.test(amount)) {
        let s = parseFloat(amount);
        if (s <= 0 || s > 10000000) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  confirm(paymentType?: any) {
    this.rechargePrepayAccount(paymentType);
  }

  rechargePrepayAccount(payTypeCode: string) {
    this.prepayRechargeReq.amount = this.paymentAmount.toString();
    this.prepayRechargeReq.lease_id = this.leaseId;
    this.prepayRechargeReq.company_id = this.companyId;
    this.prepayRechargeReq.pay_time = new Date().getTime();
    this.prepayRechargeReq.pay_type_code = payTypeCode;
    if (payTypeCode === 'ACCOUNT_MONEY') {
      this.prepayRechargeReq.account_money_info_id = this.account_money_info_id;
    } else {
      this.prepayRechargeReq.account_money_info_id = null;
    }

    this.prepayRechargeReq.coupon = this.coupon_number;

    // 如果表单验证通过
    this.companyPrepayService.accountRecharge(this.prepayRechargeReq).then(response => {
      this.prepaygatherBillDialog.hide();
      this.prepayposPaymnetDialog.hide();
      this.prepaycashPaymentDialog.hide();
      this.prepaybindOrderBillDialog.hide();
      this.prepayorderQueryDialog.hide();
      this.prepayAccountPaymentDialog.hide();
      this.paymentAmount = 0;
      this.orderId = null;
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, "企业预付账户充值成功"));
      // 刷新页面控件
      this.workService.event_bus.emit(new WorkModel(this.workService.type.PANEL_NEED_REFRESH))
    }).catch(error => {
      this.paymentAmount = 0;
      this.orderId = null;
    });
  }

  paymnetFill(imageList?: any) {
    this.prepayRechargeReq.cret_images = imageList;
  }


  closeAliOrWei() {
    this.clock = 0;
    this.prepayaliOrWeixinDialog.hide();
  }

  showQueryByOrderId(queryDialog?: any, needCloseDialog?: any) {
    queryDialog.show();
  }

  printPaymentCret() {
  }


  // 输入商户订单号：查询订单
  prepayQueryByOrderId(queryShowDialog?: any, gatherSuccessDialog?: any) {
    this.orderRemind = null;
    if (!this.orderId) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请输入商户订单号'));
      return;
    }
    const reqParam = {
      pay_channel: this.paymentType,
      payment_amount: this.paymentAmount,
      order_id: this.orderId
    };
    this.orderService.getBillOrderList(reqParam).then(res => {
      if (res) {
        this.orderDtos = res.order_info_dtos;
        if (res.order_info_dtos.length === 1) {
          this.prepayRechargeReq.pay_log_no = this.orderDtos[0].order_no;
          this.confirm(this.paymentType);
        }
      } else {
        this.orderRemind = '查不到相应的支付记录 ，请确认订单号是否正确';
      }
    }).catch(error => {
      this.logService.error('PactComponent:根据订单和金额查询订单时失败' + JSON.stringify(error));
    });
  }


  /**
   * 确认订单和账单信息关联
   */
  reletedBillAndOrder(orderNo?: any, bindOrderBillDialog?: any, gatherSuccess?: any) {
    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.operationMessage = "请确认此款项正确，确认后不能更改且立即入账";
    messageModalInfo.cancel = true;
    messageModalInfo.callback.subscribe(() => {
      this.prepayRechargeReq.pay_log_no = orderNo;
      //付款类型为空
      this.confirm(this.paymentType);
    });
    messageModalInfo.cancelCallback.subscribe(() => {
    });
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
  }

  // 是否使用优惠券
  changeSelectCouponInput(event: any) {
    this.selectCouponInput = event.currentTarget.checked;
    if (!this.selectCouponInput) {
      this.coupon_number = null;
      this.coupon_flag = false;
      this.coupon_amount = 0;
      this.coupon_msg = null;
      this.cal();
    } else {
      this.confirmCouponNumber(this.inputAmount);
    }
  }

  confirmCouponNumberEvent(event) {
    let obj = event.currentTarget.value;

    this.confirmCouponNumber(obj);
  }

  confirmCouponNumber(value) {

    let val = value;
    // 清除"数字"和"."以外的字符
    val = val.replace(/[^\d.]/g, "");
    // 验证第一个字符是数字
    val = val.replace(/^\./g, "");
    // 只保留第一个, 清除多余的
    val = val.replace(/\.{2,}/g, ".");
    val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    // 只能输入两个小数
    val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');

    this.inputAmount = val;

    if (this.coupon_number == null || this.coupon_number.length === 0) {
      this.coupon_msg = '请输入优惠券号';
      this.coupon_amount = 0;
      this.cal();
      return;
    }

    this.couponService.getCouponInfoDetailByCouponNumber(
      new CouponInfoDetailByCouponNumberReq(this.coupon_number.trim(), this.inputAmount, "COMPANY_PREPAY")
    ).then(data => {
      if (data.error_msg) {
        this.coupon_msg = data.error_msg;
        this.coupon_flag = false;
        this.coupon_amount = 0;
      } else {
        this.coupon_amount = 0;
        //判断房费账单的剩余应收总金额是否大于代金券使用门槛
        let flag = this.checkCanUseCoupon(data.least_use_total);
        if (flag === 1) {
          this.coupon_info = data.coupon_info;
          if (data.coupon_info.coupon_batch_type === 'DISCOUNT') {
            this.coupon_msg = '企业预付费-代金券：' + data.coupon_info.discount_ratio + '折';
          } else {
            this.coupon_msg = '企业预付费-代金券：' + data.coupon_info.total_value + '元';
          }
          this.coupon_amount = data.coupon_info.total_value;
          this.coupon_flag = true;
        } else if (flag === 0) {
          this.coupon_flag = false;
          this.coupon_msg = '未达到该企业预付费-代金券使用门槛：' + data.least_use_total + '元，不能使用该优惠券';
        } else {
          this.coupon_flag = false;
          this.coupon_msg = '未填写金额，无法使用优惠券';
        }
      }
      this.cal();
    });

  }

  checkCanUseCoupon(leastAmount) {

    let totalLeftAmount = "0";
    let flag = -1;

    if (this.inputAmount) {
      totalLeftAmount = new Decimal(this.inputAmount).toString();
      flag = 0;
      //和优惠券门槛作比较，大于门槛才可以使用
      if (this.changToDecimal(totalLeftAmount).greaterThanOrEqualTo(this.changToDecimal(leastAmount))) {
        flag = 1;
        return flag;
      }
    }

    return flag;
  }

  /**
   * @Description:   传进一个字符串金额：转换成两个小数点的Decimal类型
   * @Author: zhoujiahao
   * @Date: 2018-07-30  16:20
   */
  changToDecimal(amount?: string): Decimal {
    return new Decimal(this.getTwoPoint(new Decimal(amount)));
  }

  /**
   * @Description:  保留两位小数点
   * @Author: zhoujiahao
   * @Date: 2018-07-30  16:31
   */
  getTwoPoint(data?: Decimal): string {
    return (Math.ceil(new Decimal(data).mul("100").toNumber()) / 100).toString();
  }

  // 计算金额
  cal() {
    let amount = this.inputAmount;
    //
    if (this.selectCouponInput && amount) {
      amount = new Decimal(amount).sub(new Decimal(this.coupon_amount)).toNumber();
    }

    if (!amount) {
      this.paymentAmount = 0;
    } else {
      this.paymentAmount = amount;
    }
  }

  showPrepayGatherBill() {
    if (this.leaseId) {
      this.getAccountLease(this.leaseId);
    }
  }

  getAccountLease(leaseId?: number) {
    if (leaseId) {
      this.queryAccountLeaseReq.lease_id = leaseId;
      this.clientInfoService.queryAccountLease(this.queryAccountLeaseReq).then(data => {
        this.queryAccountLeaseResponse = data;
      });
    }
  }

  compareLeaseAccount(num1: any, accountDto: AccountMoneyListDTO): boolean {
    let leaseTotal = Number.parseFloat(num1);
    let account = Number.parseFloat(accountDto.balance);
    let _return = true;

    if (account < leaseTotal) {
      _return = false;
    }
    if (accountDto.special_type === "SPECIAL_ACCOUNT_RENT_DIC") {
      _return = false;
    }
    return _return;
  }
}
