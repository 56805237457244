import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RequestService } from '../request.service';
import {AppActivityListRequest, AppActivityListResponse} from "../../model/appactivity/activity.model";

@Injectable()
export class ActivityService {

  //活动列表
  private ACTIVITY_LIST = `${environment.apiHost}app/activity/list`;
  //活动详情
  private ACTIVITY_DETAIL = `${environment.apiHost}app/activity/detail`;
  //活动的报名信息
  private ACTIVITY_ENROLL_INFO = `${environment.apiHost}app/activity/enroll/info`;
  //关闭活动
  private ACTIVITY_CLOSE = `${environment.apiHost}app/activity/close`;
  //下载活动报名明细
  private ACTIVITY_DOWNLOAD_ENROLL_INFO = `${environment.apiHost}app/activity/enroll/download`;
  // 添加活动信息
  private APP_ACTIVITY_ADD_EDIT =  `${environment.apiHost}app/activity/add/edit`;


  constructor(private requestService: RequestService) {
  }

  /**
  * @Description: 活动列表
  * @Author: yangwen
  * @Date: 2019/10/11 20:00
  */
  list(req: AppActivityListRequest): Promise<AppActivityListResponse> {
    return this.requestService.authGet(this.ACTIVITY_LIST, req);
  }
  listWithLoading(req: AppActivityListRequest): Promise<AppActivityListResponse> {
    return this.requestService.authGet(this.ACTIVITY_LIST, req, true);
  }

  /**
   * @Description: 活动详情
   * @Author: yangwen
   * @Date: 2019/10/11 20:00
   */
  detail(req: any): Promise<any> {
    return this.requestService.authGet(this.ACTIVITY_DETAIL, req);
  }

  /**
   * @Description: 活动的报名信息
   * @Author: yangwen
   * @Date: 2019/10/11 20:00
   */
  getEnrollInfo(req: any): Promise<any> {
    return this.requestService.authGet(this.ACTIVITY_ENROLL_INFO, req);
  }

  /**
   * @Description: 关闭活动
   * @Author: yangwen
   * @Date: 2019/10/11 20:00
   */
  close(req: any): Promise<any> {
    return this.requestService.authPost(this.ACTIVITY_CLOSE, req);
  }

  download(req: any) {
    let url = this.ACTIVITY_DOWNLOAD_ENROLL_INFO + '?activity_id=' + req;
    return url;
  }

  saveActivity(req: any): Promise<any> {
    return this.requestService.authPost(this.APP_ACTIVITY_ADD_EDIT, req);
  }
}
