import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

/**
 * 日志服务
 * @Author: yinqiang
 * @Date: 2018-02-13 16:35:09
 * @Last Modified by: yinqiang
 * @Last Modified time: 2018-02-24 18:03:07
 */
@Injectable()
export class LogService {

  constructor() {

  }

  public debug(message: any): void {
    if (environment.logLevel != null && environment.logLevel < 2) {
      this.writeLog(message);
    }
  }

  public info(message: any): void {
    if (environment.logLevel != null && environment.logLevel < 3) {
      this.writeLog(message);
    }
  }

  public warn(message: any): void {
    if (environment.logLevel != null && environment.logLevel < 4) {
      this.writeLog(message);
    }

  }

  public error(message: any): void {
    if (environment.logLevel != null && environment.logLevel < 11) {
      this.writeLog(message);
    }
  }

  private writeLog(message: any) {
    if (message) {
      // console.log(message);
    }
  }
}
