import {NgModule} from '@angular/core';

import {SharedRootModule} from '../shared/module/share-common.module';
import {SeeHouseRoutingModule} from "./seehouse-routing.module";
import {SeehouseDetailComponent} from "./seehouse-detail/seehouse-detail.component";
import {SeehouseListComponent} from "./seehouse-list/seehouse-list.component";
import {AppointmentListComponent} from './appointment-list/appointment-list.component';
import {AppointmentDetailComponent} from './appointment-detail/appointment-detail.component';
import {SignedModule} from "../signed/signed.module";

@NgModule({
  imports: [
    SharedRootModule,
    SeeHouseRoutingModule,
    SignedModule
  ],
  declarations: [
    SeehouseListComponent,
    SeehouseDetailComponent,
    AppointmentListComponent,
    AppointmentDetailComponent
  ],
  exports: [
    SeehouseDetailComponent,
  ]
})
export class SeeHouseModule {
}
