import {Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {LeaseService} from "../../../service/lease/lease.service";
import {LeaseLog, LogListReq} from "../../../model/lease/lease.model";
import {WorkService} from "../../../service/common/work.service";
import {WorkModel} from "../../../model/common/common.model";

/**
 * Created by cyl on 2018/7/23.
 */

@Component({
  selector: 'app-handlelog',
  templateUrl: './handlelog.component.html'
})
export class HandleLogComponent implements OnInit {

  leaseLogParam: LogListReq = {
    lease_id: null,
    page_num: 1,
    page_size: 100
  };
  index = 0;
  leaseLogs: Array<LeaseLog>;

  constructor(
    private leaseService: LeaseService,
    private workService: WorkService,
  ) {
  }

  ngOnInit() {

    this.workService.event_bus.subscribe((data: WorkModel) => {
      if (data && data.type === this.workService.type.PANEL_NEED_REFRESH) {
        this.getHandleLog(this.leaseLogParam.lease_id);
      }
    });

  }

  getHandleLog(leaseId: number) {
    this.leaseLogs = null;
    this.leaseLogParam.lease_id = leaseId;
    this.leaseLogParam.page_num = 1;
    this.index++;
    this.dataLoading(this.index);
  }

  dataLoading(index: number) {
    this.leaseService.getLeaseLogListWithLoading(this.leaseLogParam)
      .then(data => {
        if (data && this.index === index) {
          if (this.index === index) {
            if (this.leaseLogs != null) {
              this.leaseLogs = this.leaseLogs.concat(data.lease_log_dtolists);
            } else {
              this.leaseLogs = data.lease_log_dtolists;
            }
            // 判断是否全部拉完
            if (this.leaseLogs && data.total > this.leaseLogs.length) {
              this.leaseLogParam.page_num++;
              this.dataLoading(index);
            }
          }
        } else {
          this.leaseLogs = [];
        }
      });
  }


}
