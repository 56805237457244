import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {
  ComplaintsDealRequest,
  ComplaintsInfoDTO,
  ComplaintsLogListResponse
} from "../../../model/complaints/complaints.model";
import {ComplaintsService} from "../../../service/complaints/complaints.service";
import {WorkService} from "../../../service/common/work.service";
import {AuthService} from "../../../service/common/auth.service";
import {ModalDirective} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-complain-modal',
  templateUrl: './complain-modal.component.html',
})
export class ComplainModalComponent implements OnInit {
  @ViewChild('handleModal', { static: true }) handleModal: ModalDirective;
  @Input() complaintsInfoDTO: ComplaintsInfoDTO;
  @Input() complaintsLogListResponse: ComplaintsLogListResponse;
  @Output() refresh_list: EventEmitter<any> = new EventEmitter<any>();

  btn_privileges: any = {
    'COMPLAINT_MANAGE': false,
    'COMPLAINT_DATA_STA': false,
    'COMPLAINT_VIEW_HANDLE': false,
    'VIEW_COMPLAINT': false,
    'HANDLE_COMPLAINT': false
  };

  constructor(
    private complaintsService: ComplaintsService,
    private workService: WorkService,
    private authService: AuthService,
  ) {
  }

  complaintsDetailRequest: ComplaintsDealRequest = {
    complaints_id: null,
    operate_type: null,
    operate_content: null
  };
  auditRemark: string;
  beingResut: string = null;
  classifyType: string = null;
  classify_type_list = [
    {code: 'OUTDOOR_NOISE', desc: '室外噪音'},
    {code: 'NEIGHBOURS_NUISANCE', desc: '邻居扰民'},
    {code: 'INFRASTRUCTURE', desc: '基础设施'},
    {code: 'COMMUNITY_MATCHING', desc: '社区配套'},
    {code: 'INDOOR_ASSETS', desc: '室内资产'},
    {code: 'ROOM_POWER_SUPPLY', desc: '房间供电'},
    {code: 'ROOM_WATER_SUPPLY', desc: '房间供水'},
    {code: 'ROOM_HEATING', desc: '房间供暖'},
    {code: 'DOOR_LOCK_PASSWORD', desc: '门锁密码'},
    {code: 'HEATER', desc: '热水器'},
    {code: 'KITCHEN_AND_TOILET_FACILITIES', desc: '厨卫设施'},
    {code: 'BROADBAND_NETWORK', desc: '宽带网络'},
    {code: 'BILL_COST', desc: '账单费用'},
    {code: 'APP_USE', desc: 'app使用'},
    {code: 'COMMUNITY_SAFETY', desc: '社区安全'},
    {code: 'PERSONNEL_SERVICE', desc: '人员服务'},
    {code: 'GOODS_REPAIR', desc: '物品报修'},
    {code: 'OTHER_QUESTIONS', desc: '其他问题'},
  ];

  ngOnInit() {
    this.authService.getOperatePrivilege(this.btn_privileges);
    // this.getComplainLog(this.complaintsInfoDTO.complaints_id);;
  }

  imageHandler(annex_dtos: any) {
    let annex_urls = annex_dtos.map(annex => annex.annex_url);
    return annex_urls;
  }


  getClassifyTypeName(classify_type: string) {
    if (classify_type) {
      for (let item of this.classify_type_list) {
        if (item.code === classify_type) {
          return item.desc;
        }
      }
    }
    return "";
  }

  // 保存
  ok_evolve(): void {
    let cdr = new ComplaintsDealRequest();
    cdr.complaints_id = this.complaintsInfoDTO.complaints_id;
    cdr.operate_type = this.beingResut;
    cdr.operate_content = this.auditRemark;
    cdr.classify_type = this.classifyType;
    if(this.complaintsInfoDTO.complaints_status === 'WAITING' || this.complaintsInfoDTO.complaints_status === 'PROCESSING' ){
      if(!cdr.operate_type){
        this.workService.showErrorModal("处理结果不为空");
        return;
      }
      if(!cdr.operate_content){
        this.workService.showErrorModal("处理内容不为空");
        return;
      }
      if(cdr.operate_content.length < 3){
        this.workService.showErrorModal("处理内容至少3个字符");
        return;
      }
    }
    if(!this.complaintsInfoDTO.classify_type){
      if(!cdr.classify_type){
        this.workService.showErrorModal("处理归类不为空");
        return;
      }
    }
    this.complaintsService.handle(cdr).then(data => {
      this.handleModal.hide();
      // this.getComplainList();
      // 刷新列表
      this.refresh_list.emit(true);
    });
    // 清空下拉框
    this.classifyType = null;
  }

}
