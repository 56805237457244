import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {WorkModel} from "../../../../model/common/common.model";
import {FlowLeaseLogDTO, FlowLeaseLogResponse, LeaseLogRequest} from "../../../../model/lease/lease.model";
import {MessageModalInfo} from "../../../../model/modal/message-model";
import {ApprovService} from "../../../../service/approv/approv.service";
import {WorkService} from "../../../../service/common/work.service";
import {LeaseService} from "../../../../service/lease/lease.service";
import {ApprovRecordDetailModalComponent} from "../../../workspace/approv-record-detail-modal/approv-record-detail-modal.component";
import {BookingService} from "../../../../service/booking/booking.service";
import {Router} from "@angular/router";
import {ScheduleOperateModel} from "../../../../model/schedule/schedule.model";
import {OperationType} from "../../../../common/constants";

@Component({
  selector: 'app-lease-flow-submit-log',
  templateUrl: './lease-flow-submit-log.component.html',
  styleUrls: ['./lease-flow-submit-log.component.css']
})
export class LeaseFlowSubmitLogComponent implements OnInit, OnChanges {


  @Input()
  lease_id: number = 0;

  @ViewChild(ApprovRecordDetailModalComponent) modal: ApprovRecordDetailModalComponent;

  pageNo = 0;
  list: Array<FlowLeaseLogDTO> = [];

  constructor(
    private workService: WorkService,
    private leaseService: LeaseService,
    private approvService: ApprovService,
    private bookingService: BookingService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

    this.list = [];
    this.getList(1);
  }

  getList(pageNum: number) {
    if (this.lease_id) {
      let req = new LeaseLogRequest();
      req.lease_id = this.lease_id;
      req.page_size = 20;
      req.page_num = pageNum;
      this.pageNo = pageNum;
      this.leaseService.getLeaseFlowSubmitLog(req).then(data => {

        this.list = this.list.concat(data.flow_lease_log_list);

        if (data.total !== this.list.length) {
          this.getList(++this.pageNo);
        }
      });
    }
  }

  showDetail(dto: FlowLeaseLogDTO) {
    this.modal.showDetail(dto);
  }

  flowCancel(item: FlowLeaseLogDTO) {

    let messageModalInfo = new MessageModalInfo();

    let tag = '';
    if (item.type === 'LEASE_CHANGE') {
      tag = '合同信息变更审批';
    } else if (item.type === 'LEASE_BILL_CHANGE') {
      tag = '合同费用变更审批';
    } else if (item.type === 'ROOM_AVAILABLE_TIME_CHANGE') {
      tag = '房间使用期限调整审批';
    } else if (item.type === 'FROZEN_PASSWORD') {
      tag = '冻结门锁密码审批';
    }

    messageModalInfo.operationMessage = '确定取消【' + tag + '' + item.record_no + '】的申请吗？';
    messageModalInfo.cancel = true;
    messageModalInfo.callback.subscribe(() => {

      if (item.type === 'LEASE_COMMIT'){
        this.bookingService.bookingCancle({"lease_id":this.lease_id}).then(res => {
          this.list = [];
          this.getList(1);
          let scheduleOperateModel: ScheduleOperateModel = new ScheduleOperateModel();
          scheduleOperateModel.operateType = OperationType.QUERY;
          scheduleOperateModel.scheduleType = 'AUDIT';
          this.workService.event_bus.emit(
            new WorkModel(
              this.workService.type.SCHEDULE_TASK,
              scheduleOperateModel
            )
          );
        }).catch(error => {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, JSON.stringify(error)));
        });
      }else{
        this.approvService.cancelFlow(item.instance_id).then(data => {
          this.list = [];
          this.getList(1);
        });
      }
    });
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
  }
}
