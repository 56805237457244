import {
  WalletRechargeListRequest,
  WalletRechargeListResponse,
  WalletConsumeListRequest,
  WalletConsumeListResponse,
  WalletSettings,
  WalletSettingsResponse,
  WaterDispenserResponse
} from '../../model/wallet/wallet.model';
import { RequestService } from '../request.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class WalletService {
  //钱包充提列表
  private WALLET_RECHARGE_LIST = `${environment.apiHost}drink/wallet/queryRechargeList`;
  //钱包消费列表
  private WALLET_CONSUME_LIST = `${environment.apiHost}drink/wallet/queryConsumeList`;

  //获取钱包设置
  private QUERY_WALLET_SETTINGS = `${environment.apiHost}drink/wallet/queryWalletSettings`;

  //设置饮水钱包
  private WALLET_SETTINGS = `${environment.apiHost}drink/wallet/walletSettings`;
  //饮水设备列表
  private WATER_DISPENSER_LIST = `${environment.apiHost}drink/wallet/water/dispenserList`;
  //饮水充值提现统计
  private RECHARGE_STATISTICS = `${environment.apiHost}drink/wallet/queryRechargeStatistic`;
  //饮水消费统计
  private CONSUME_STATISTICS = `${environment.apiHost}drink/wallet/queryConsumeStatistic`;

  constructor(private requestService: RequestService) {

  }

  public getWalletRechargeList(walletRechargeListRequest?: WalletRechargeListRequest): Promise<WalletRechargeListResponse> {
    return this.requestService.authPost(this.WALLET_RECHARGE_LIST, walletRechargeListRequest);
  }
  public getWalletConsumeList(walletConsumeListRequest?: WalletConsumeListRequest): Promise<WalletConsumeListResponse> {
    return this.requestService.authPost(this.WALLET_CONSUME_LIST, walletConsumeListRequest);
  }

  public getWalletSettings(): Promise<WalletSettings> {
    return this.requestService.authGet(this.QUERY_WALLET_SETTINGS, null);
  }

  public walletSettings(walletSettings?: WalletSettings): Promise<WalletSettingsResponse> {
    return this.requestService.authPost(this.WALLET_SETTINGS, walletSettings);
  }

  public getWalletDispenserList(): Promise<WaterDispenserResponse> {
    return this.requestService.authGet(this.WATER_DISPENSER_LIST, null);
  }

  public queryRechargeStatistics(walletRechargeListRequest?: WalletRechargeListRequest): Promise<WalletRechargeListResponse> {
    return this.requestService.authPost(this.RECHARGE_STATISTICS, walletRechargeListRequest);
  }

  public queryConsumeStatistics(walletConsumeListRequest ?: WalletConsumeListRequest): Promise<WalletConsumeListResponse> {
    return this.requestService.authPost(this.CONSUME_STATISTICS ,walletConsumeListRequest)
  }
}
