import {RequestService} from '../request.service';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {
  WarningConfigDetailResponse,
  WarningManageRequest,
  WarningUserListResponse
} from "../../model/warning/warning.model";

@Injectable()
export class WarningService {

  // 获取预警配置
  private WARNING_CONFIG = `${environment.apiHost}warning/detail`;
  // 获取预警通知人
  private WARNING_NOTIFIER = `${environment.apiHost}user/warning/employees`;
  // 保存预警配置
  private WARNING_CONFIG_SAVE = `${environment.apiHost}warning/manage`;
  // 项目_报警配置通知人员列表查询people
  private GET_SECURITY_PEOPLE = `${environment.apiHost}user/project/companyUsers`;
  // 项目_安全报警配置查询
  private GET_SECURITY_CONFIG = `${environment.apiHost}project/manage/projectSecurityAlarm`;
  // 项目—安全报警配置保存
  private SAVE_SECURITY_PEOPLE = `${environment.apiHost}project/manage/save/projectSecurityAlarm`;

  constructor(private requestService: RequestService) {

  }


  public getWarningConfig(id:number): Promise<WarningConfigDetailResponse> {
    return this.requestService.authGet(this.WARNING_CONFIG, {project_id:id});
  }


  public getWarningNotifier(id:number): Promise<WarningUserListResponse> {
    return this.requestService.authGet(this.WARNING_NOTIFIER, {project_id:id});
  }

  public saveWarningConfig(req: WarningManageRequest): Promise<any>{
    return this.requestService.authPost(this.WARNING_CONFIG_SAVE, req);
  }

  public getSecurityPeople(req): Promise<any>{
    return this.requestService.authGet(this.GET_SECURITY_PEOPLE, req);
  }

  public getSecurityConfig(req): Promise<any>{
    return this.requestService.authGet(this.GET_SECURITY_CONFIG, req);
  }

  public saveSecurityPeople(req): Promise<any>{
    return this.requestService.authPost(this.SAVE_SECURITY_PEOPLE, req);
  }
}
