import {Injectable} from '@angular/core';
import {RoomService} from './room.service';
import {environment} from '../../../environments/environment';
import {RequestService} from '../request.service';
import {
  REPAIR_TYPE,
  RepairAddReqParam,
  RepairHandleReqParam,
  RepairInfoList,
  RepairListReqParam,
  RepairListRequest,
  RepairRemarkReqParam,
  RepairOverHoursDTOList,
  RepairReturnRequest,
  CleanerListRequest,
  CleanServiceTypeListResponse,
  CleanTaskListRequest,
  CleanTaskListResponse, CleanTaskEditRequest, RoomBatchImportPreviewRequest
} from '../../model/room/repair.model';
import {AuthService} from '../common/auth.service';

@Injectable()
export class RepairService {

  private REPAIR_LIST_URL = `${environment.apiHost}manage/repair/list`;
  private REPAIR_CREATE_URL = `${environment.apiHost}manage/repair/create`;
  private REPAIR_HANDLE_URL = `${environment.apiHost}manage/repair/handle`;
  private REPAIR_REMARK_URL = `${environment.apiHost}manage/repair/remark/edit`;
  //add by yangwen pms1.12 新增保洁维修页面
  private REPAIR_LIST_TYPE_URL = `${environment.apiHost}manage/repair/list/type`;
  private DOWN_LOAD_URL = `${environment.apiHost}manage/repair/downloadRepairOrCleanInfo`;


  private REPAIR_RETURN_REMARK = `${environment.apiHost}manage/repair/add/return`;

  // private CLEANER_LIST = `${environment.apiHost}manage/repair/cleaner/list`;

  private CLEANER_LIST = `${environment.apiHost}user/sysWorker/list`;

  private CLEAN_SERVICE_TYPE_LIST = `${environment.apiHost}manage/repair/cleanSpeciesTypeList`;

  private CLEAN_TASK_LIST = `${environment.apiHost}clean/task/list`;

  private CLEAN_TASK_UPDATE = `${environment.apiHost}clean/task/update`;

  private CLEAN_TASK_IMPORT = `${environment.apiHost}clean/task/import`;

  constructor(private requestService: RequestService,
              private roomService: RoomService,
              private authService: AuthService) {
  }

  getBase(): Promise<any> {
    return this.roomService.getProjectUnits({project_id: this.authService.getProjectId()}).then(data => {
      return Promise.resolve({
        'unit': data.project_units,
        'repairType': REPAIR_TYPE
      });
    });

  }

  /**
   * @Description: 添加回访记录
   * @Author: 王肖
   * @Date: 2019/6/24 上午10：36
   */
  public insertReturnRemark(params: RepairReturnRequest): Promise<any> {
    return this.requestService.authPost(this.REPAIR_RETURN_REMARK, params);
  }


  getListByPage(repairListReqParam: RepairListReqParam): Promise<RepairInfoList> {
    return this.requestService.authGet(this.REPAIR_LIST_URL, repairListReqParam);
  }

  create(repairAddReqParam: RepairAddReqParam): Promise<any> {
    return this.requestService.authPost(this.REPAIR_CREATE_URL, repairAddReqParam);
  }

  handle(repairHandleReqParam: RepairHandleReqParam): Promise<boolean> {
    return this.requestService.authPost(this.REPAIR_HANDLE_URL, repairHandleReqParam);
  }

  remark(repairHandleReqParam: RepairRemarkReqParam): Promise<boolean> {
    return this.requestService.authPost(this.REPAIR_REMARK_URL, repairHandleReqParam);
  }

  // getOne(repairInfoReqParam: RepairInfoReqParam): Promise<RepairInfo> {
  //
  //   let repairInfo: RepairInfo = new RepairInfo();
  //
  //   repairInfo.repair_id = repairInfoReqParam.repair_id;
  //   repairInfo.room_num = '40' + repairInfoReqParam.repair_id;
  //   repairInfo.create_time = new Date().getTime();
  //
  //   return Promise.resolve(repairInfo);
  // }

  getListByType(repairListReqParam: RepairListRequest): Promise<RepairOverHoursDTOList> {
    return this.requestService.authGet(this.REPAIR_LIST_TYPE_URL, repairListReqParam);
  }

  getListByTypeWithLoading(repairListReqParam: RepairListRequest): Promise<RepairOverHoursDTOList> {
    return this.requestService.authGet(this.REPAIR_LIST_TYPE_URL, repairListReqParam, true);
  }

  getCleanerList(request: CleanerListRequest): Promise<any> {
    return this.requestService.authGet(this.CLEANER_LIST, request);
  }

  getCleanServiceTypeList(): Promise<CleanServiceTypeListResponse> {
    return this.requestService.authGet(this.CLEAN_SERVICE_TYPE_LIST);
  }

  getCleanTaskList(params: any): Promise<any> {
    return this.requestService.authPost(this.CLEAN_TASK_LIST, params);
  }

  downloadRepairOrCleanInfo(repairListReqParam: RepairListRequest): string {
    let url = this.DOWN_LOAD_URL + '?type=' + repairListReqParam.type
      + '&repair_param=' + repairListReqParam.repair_param
      + '&urgency_degree=' + repairListReqParam.urgency_degree
      + '&cleaner=' + repairListReqParam.cleaner;
    if (repairListReqParam.reservation_start_time != null) {
      url = url + '&reservation_start_time=' + repairListReqParam.reservation_start_time;
    }
    if (repairListReqParam.reservation_end_time != null) {
      url = url + '&reservation_end_time=' + repairListReqParam.reservation_end_time;
    }
    if (repairListReqParam.clean_service_type != null) {
      url = url + '&clean_service_type=' + repairListReqParam.clean_service_type;
    }
    repairListReqParam.repair_status_list.forEach(item => {
      url = url + '&repair_status_list=' + item;
    });
    if (repairListReqParam.time_type != null) {
      url = url + '&time_type=' + repairListReqParam.time_type;
    }
    return url;
  }

  updateCleanTask(params: CleanTaskEditRequest): Promise<any> {
    return this.requestService.authPost(this.CLEAN_TASK_UPDATE, params);
  }

  importCleanTask(req: RoomBatchImportPreviewRequest): Promise<any> {
    return this.requestService.authPost(this.CLEAN_TASK_IMPORT, req);
  }
}
