import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {BillService} from "../../../service/finance/bill.service";
import {BillPayLogDTO, LeaseRoomBillDetailParam, LeaseRoomBillListDTO} from "../../../model/finance/bill.model";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogComponent} from "../../shared/dialog/dialog.component";
import {AuthService} from "../../../service/common/auth.service";
import {environment} from "../../../../environments/environment";
import {LocalStorage} from "../../../common/localstorage";
import {Constants} from "../../../common/constants";
import {WorkModel} from "../../../model/common/common.model";
import {WorkService} from "../../../service/common/work.service";
import {ModalDirective} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-lease-room-bill-detail',
  templateUrl: './lease-room-bill-detail.component.html'
})
export class LeaseRoomBillDetailComponent implements OnInit {

  //账单ID
  billId: number;
  //账单对象
  leaseRoomBillDetail: LeaseRoomBillListDTO;
  //账单财务明细
  billPayLogList: Array<BillPayLogDTO>;
  @Output() billDetailChange = new EventEmitter();
  @Output() couponDetailChange = new EventEmitter();
  //打印相关
  isPhone: boolean = true;
  @ViewChild(DialogComponent, { static: true }) dialogComponent: DialogComponent;
  @ViewChild('billDetailModal', { static: true }) billDetailModal: ModalDirective;
  // @ViewChild("printprew") printprew: PrintPreviewComponent;
  contractPath: string;
  //收款权限
  LEASE_BILL: boolean;
  isNoContract: boolean = false;
  chamberlain: string = '';
  contractPayTime: any;

  //v1.2.7账单财务明细
  billPayLogLists: Array<BillPayLogDTO>;
  received: string;
  constructor(
    private billService: BillService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private workService: WorkService
  ) {
  }

  ngOnInit() {
    this.getOperatePrivilege();
    let result = localStorage.getItem('CURRENT_USER_SOURCE');
    if (!result) {
      this.isPhone = false;
    }
  }

  setBillDetailModal(modal: any) {
    this.billDetailModal = modal;
  }

  showModel(billId: number, type?: string, chamberlain?: string, payTime?: any) {
    if (type && 'noContract' === type) {
      this.isNoContract = true;
    }
    if (chamberlain) {
      this.chamberlain = chamberlain;
    }
    if (payTime) {
      this.contractPayTime = payTime;
    }
    this.billId = billId;
    this.getleaseRoomBillDetail();
    //this.getBillPayLogList();

    //v2.0.7 获取账单交易明细
    this.getBillPayLogLists();
  }

  colseModel() {
    this.billDetailModal.hide();
  }

  //账单详情
  getleaseRoomBillDetail(): void {
    let param = new LeaseRoomBillDetailParam(this.billId, null);
    this.billService.getleaseRoomBillDetail(param)
      .then(data => {
        if (data) {
          this.leaseRoomBillDetail = data;
        } else {
          this.leaseRoomBillDetail = null;
        }
        this.billDetailModal.show();
      });
  }

  //账单财务明细
  getBillPayLogList(): void {
    let param = new LeaseRoomBillDetailParam(this.billId, null);
    this.billService.getBillPayLogList(param)
      .then(data => {
        if (data && data.bill_pay_log_list) {
          this.billPayLogList = data.bill_pay_log_list;
        } else {
          this.billPayLogList = null;
        }
      });
  }

  showDetail(id: number, type: string) {
    if (type === 'PAY_LOG') {
      this.colseModel();
      this.billDetailChange.emit(id);
    } else if (type === 'BOOKING') {
      this.colseModel();
      this.router.navigate(['/system_manage/booking-management/detail/' + id])
    } else if(type === 'COUPON'){
      this.couponDetailChange.emit(id);
    }
  }

  showDialog(leaseId?: any, billId?: any) {
    this.dialogComponent.openGatherDialog(leaseId, billId);
  }

  printshow(leaseId: number, billId: number) {
    this.contractPath = `${environment.apiHost}` + 'file/preview?bizcode=USERBILL&leaseId='
      + leaseId + '&billId=' + billId + '&' + Math.ceil(Math.random() * 100);

    this.workService.event_bus.emit(new WorkModel(this.workService.type.PDF_PRINT, this.contractPath));
    // this.printprew.show();
  }

  getOperatePrivilege() {
    let data = LocalStorage.getItem(Constants.USER_OPERATE_PRIVILEGES + this.authService.getUserInfo().id);
    if (data) {
      let privileges = JSON.parse(data);
      this.LEASE_BILL = privileges.find(value => value === 'LEASE_BILL');
    }
  }

  //v1.2.7账单财务明细
  getBillPayLogLists(): void {
    let param = new LeaseRoomBillDetailParam(this.billId, null);
    this.billService.getBillPayLogLists(param)
      .then(data => {
        if (data && data.bill_pay_log_list) {
          this.billPayLogLists = data.bill_pay_log_list;
          this.received = data.received;
        } else {
          this.billPayLogLists = null;
          this.received = '0';
        }
      });
  }
}
