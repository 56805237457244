<div class="modal fade" bsModal #renterBillDetailModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>费用分摊详情</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="renterBillDetailModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 col-md-6 mb10" *ngIf="renterBillDetail?.average_fee_bill">
            <p class="w140px text-left">应收金额：</p>
            <p class="condivred">{{renterBillDetail?.bill_amount}}元</p>
          </div>
          <div class="col-12 col-md-6 mb10" *ngIf="!renterBillDetail?.average_fee_bill">
            <p class="w140px text-left">账单金额：</p>
            <p class="condivred">{{renterBillDetail?.bill_amount}}元</p>
          </div>
          <div class="col-12 col-md-6 mb10">
            <p class="w140px text-left">应收/付时间：</p>
            <p class="condivred">
              {{renterBillDetail?.plan_pay_time | date:'yyyy-MM-dd'}}
            </p>
          </div>

          <div class="col-12 col-md-6 mb10">
            <p class="w140px text-left">账单周期：</p>
            <p class="condivred">
              <ng-template [ngIf]="renterBillDetail?.bill_start_time && renterBillDetail?.bill_end_time">
                {{renterBillDetail.bill_start_time | date:'yyyy.MM.dd'}} - {{renterBillDetail.bill_end_time |
                date:'yyyy.MM.dd'}}
              </ng-template>
            </p>
          </div>
          <div class="col-12 col-md-6 mb10">
            <p class="w140px text-left">账单类型：</p>
            <p class="condivred">{{renterBillDetail?.bill_type_name}}</p>
          </div>
          <div class="col-12 col-md-6 mb10">
            <p class="w140px text-left">所属合同：</p>
            <p class="condivred">{{renterBillDetail?.contract_num}}</p>
          </div>
          <div class="col-12 col-md-6 mb10" *ngIf="!isNoContract">
            <p class="w140px text-left">所属房间：</p>
            <p class="condivred">{{renterBillDetail?.room_name}}</p>
          </div>
          <div class="col-12 col-md-6 mb10" *ngIf="renterBillDetail?.average_fee_bill">
            <p class="w140px text-left">账单金额：</p>
            <p class="condivred">{{renterBillDetail?.bill_amount}}元</p>
          </div>
          <div class="col-12 col-md-6 mb10" *ngIf="renterBillDetail?.renter_free_start_time && renterBillDetail?.renter_free_end_time">
            <p class="w140px text-left">免租期：</p>
            <p class="condivred">
              {{renterBillDetail.renter_free_start_time | date:'yyyy.MM.dd'}} - {{renterBillDetail.renter_free_end_time |
              date:'yyyy.MM.dd'}}
            </p>
          </div>
          <div class="col-12 mb20">
            <p class="w140px text-left">备注：</p>
            <p class="condiv">{{renterBillDetail?.remark}}</p>
          </div>
        </div>

        <ng-container *ngIf="renterBillDetail?.average_fee_bill && renterBillDetail?.bill_biz_type != 'REFUND'">
          <br/>
          <div class="black mb10">
            此住客账单关联【{{renterBillDetail?.bill_type_name}}】账单,
            关联
            【{{renterBillDetail?.bill_type_name}}】
            账单编号：
            <button class="btn btn-outline-primary" (click)="showBillDetail(renterBillDetail?.bill_id)">{{renterBillDetail?.bill_no}}</button>
          </div>
          <br/>
        </ng-container>
        <ng-container *ngIf="renterBillDetail?.renter_deposit && renterBillDetail?.bill_biz_type != 'REFUND'">
          <br/>
          <div class="black mb10" *ngIf="renterBillDetail?.deposit_source_type === 'TRANSFER_IN_LEASE'">
            【转出】该住客押金因合同续约而转出,
             转入合同：
            <button type="button" class="btn btn-outline-primary" [routerLink]="'/system_manage/signed/pact/'+renterBillDetail?.deposit_source_lease_id">
              {{renterBillDetail?.deposit_source_contract_num}}
            </button>
          </div>
          <div class="black mb10" *ngIf="renterBillDetail?.deposit_source_type === 'TRANSFER_OUT_LEASE'">
            【转入】该住客押金因合同续约而转入,
            转出合同：
            <button type="button" class="btn btn-outline-primary" [routerLink]="'/system_manage/signed/pact/'+renterBillDetail?.deposit_source_lease_id">
              {{renterBillDetail?.deposit_source_contract_num}}
            </button>
          </div>
          <div class="black mb10" *ngIf="renterBillDetail?.deposit_source_type === 'TRANSFER_IN_ROOM'">
            【转出】该住客押金因换房而转出,
            转入房间号：
            <button type="button" class="btn btn-outline-primary">{{renterBillDetail?.deposit_source_room}}</button>
          </div>
          <div class="black mb10" *ngIf="renterBillDetail?.deposit_source_type === 'TRANSFER_OUT_ROOM'">
            【转入】该住客押金因换房而转入,
            转出房间号：
            <button type="button" class="btn btn-outline-primary">{{renterBillDetail?.deposit_source_room}}</button>
          </div>
          <br/>
        </ng-container>
        <!-- 如果是退款单，需要显示退款金额的来源账单-->
        <ng-container  *ngIf="renterBillDetail?.renter_deposit && renterBillDetail?.bill_biz_type == 'REFUND'">
          <br/>
          <div class="black mb10">
            <ng-container *ngIf="renterBillDetail?.parent_bill_id">
              此退款【{{renterBillDetail?.bill_type_name}}】,
              关联
              【{{renterBillDetail?.bill_type_name}}】
              收款账单号：
              <button class="btn btn-outline-primary" (click)="showModel(renterBillDetail?.parent_bill_id)">{{renterBillDetail?.parent_bill_no}}</button>
            </ng-container>
          </div>
          <br/>
        </ng-container>

        <!--psmV1.2.7交易明细-->
        <div class="row">
          <p class="black mb10 col-6" *ngIf="!billPayLogLists || billPayLogLists.length===0">此费用分摊共有0条 交易明细：</p>
          <p class="black mb10 col-6" *ngIf="billPayLogLists && billPayLogLists.length>0">此费用分摊共有{{billPayLogLists.length}}条 交易明细：</p>
          <p class="black mb10 text-right col-6">实收金额：{{received}}元</p>
        </div>
        <div class="table-box">
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th>交易时间</th>
              <th>交易类型</th>
              <th>交易方式</th>
              <th>交易金额</th>
              <th>相关支付流水或凭证</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let billPayLogs of billPayLogLists">
              <td>{{billPayLogs.pay_time | date:'yyyy-MM-dd HH:mm:ss'}}</td>
              <td>{{billPayLogs.bill_type}}</td>
              <td>{{billPayLogs.pay_type}}</td>
              <td>{{billPayLogs.pay_amount}}</td>
              <td>
                【{{billPayLogs.source_type_name}}】
                <ng-template [ngIf]="billPayLogs.source_type==='PAY_LOG' || billPayLogs.source_type==='BOOKING' || billPayLogs.source_type==='COUPON'">
                  <button type="button" class="btn btn-outline-primary"
                          (click)="showDetail(billPayLogs.source_id,billPayLogs.source_type)">{{billPayLogs.source_no}}
                  </button>
                </ng-template>
                <ng-template [ngIf]="billPayLogs.source_type!=='PAY_LOG' && billPayLogs.source_type!=='BOOKING' && billPayLogs.source_type!=='COUPON'">
                  <ng-container *ngIf="billPayLogs.relate_bill">
                    <button class="btn btn-outline-primary" (click)="showModel(billPayLogs.source_bill_id)">{{billPayLogs.source_no}}</button>
                  </ng-container>
                  <ng-container *ngIf="!billPayLogs.relate_bill">
                    <span class="text-blue">{{billPayLogs.source_no}}</span>
                  </ng-container>
                </ng-template>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="text-center" style="margin-top:20px;">
          <button type="button" class="btn btn-warning mr20"
                  (click)="showDialog(renterBillDetail?.lease_id,renterBillDetail?.bill_id)"
                  *ngIf="(renterBillDetail?.bill_status==='NO_PAYMENT' || renterBillDetail?.bill_status==='PARTIAL_PAYMENT')">
            <i class="fa fa-cny"></i>收款
          </button>
          <button type="button" class="btn btn-primary mr20" *ngIf="!renterBillDetail?.renter_deposit"
                  (click)="printshow(renterBillDetail.lease_id,renterBillDetail.bill_id)">
            <i class="fa fa-print"></i>打印
          </button>
          <button type="button" class="btn btn-primary mr20" *ngIf="renterBillDetail?.renter_deposit"
                  (click)="printRenterDepositShow(renterBillDetail.bill_id)">
            <i class="fa fa-print"></i>打印收据
          </button>
          <button type="button" class="btn btn-secondary" (click)="renterBillDetailModal.hide()">关闭</button>
        </div>
      </div>

    </div>
  </div>

</div>

<app-dialog></app-dialog>
<app-deposit-receipt #depositReceipt></app-deposit-receipt>
<app-pay-log-detail #payLogDetail></app-pay-log-detail>
<app-lease-room-bill-detail #billDetail></app-lease-room-bill-detail>



