import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/**
 * Allows the sidebar to be toggled via click.
 */
@Directive({
  selector: '[appNumberHandler]'
})
export class NumberHandlerDirective {

  @Input() currentValue: string;

  constructor(private el: ElementRef) {
  }

  @HostListener('change', ['$event'])
  onChange(event) {
    if (event.target.value) {
      event.target.value = parseFloat(event.target.value).toString();
    }
  }

}

export const NUMBER_HANDLER_DIRECTIVE = [
  NumberHandlerDirective,
];
