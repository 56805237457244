import {Injectable} from '@angular/core';
import {
  CheckoutFinanceUncheckListRequest,
  CheckoutFinanceUncheckListResponse,
  CheckoutFinanceUnconfirmListResponse
} from "../../model/lease/lease.model";
import {PageReq} from "../../model/page/page";
import {RequestService} from '../request.service';
import {environment} from '../../../environments/environment';
import {AuthService} from "../common/auth.service";

/**
 * @description: 退房相关
 * @Author: mazy
 * @Date: 2018/6/12 16:00
 * @Last Modified by: mazy
 * @Last Modified time: 2018/6/12 16:00
 */
@Injectable()
export class CheckoutService {
  // // 获取退房结算单信息
  // private CHECKOUT_ACCOUNTSINFO = `${environment.apiHost}checkout/accountsInfo`;
  // // 退房
  // private CHECKOUT_CLOSE = `${environment.apiHost}checkout/close`;

  private GET_CHECKOUT_NOT_FINISH = `${environment.apiHost}lease/booking/workbench/finance/uncheck`;
  private GET_CHECKOUT_FINANCE_NOT_FINISH = `${environment.apiHost}lease/booking/workbench/finance/unconfirm`;
  private LIST_DOWNLOAD = `${environment.apiHost}lease/booking/workbench/finance/uncheck/download`;

  constructor(private requestService: RequestService,
              private authService: AuthService) {
  }

  // public checkoutAccountsInfo(requestData: any): Promise<any> {
  //   return this.requestService.authPost(this.CHECKOUT_ACCOUNTSINFO, requestData);
  // }
  //
  // public checkoutClose(requestData: any): Promise<any> {
  //   return this.requestService.authPost(this.CHECKOUT_CLOSE, requestData);
  // }


  public checkoutNotFinishList(requestData: PageReq): Promise<CheckoutFinanceUncheckListResponse> {
    return this.requestService.authGet(this.GET_CHECKOUT_NOT_FINISH, requestData);
  }

  public checkoutFinanceNotFinishList(requestData: PageReq): Promise<CheckoutFinanceUnconfirmListResponse> {
    return this.requestService.authGet(this.GET_CHECKOUT_FINANCE_NOT_FINISH, requestData);
  }

  checkoutNotFinishListDownload(request: CheckoutFinanceUncheckListRequest) {
    let param = "";
    for (let key in request) {
      if (request[key] && request.hasOwnProperty(key)) {
        param += (key + '=' + request[key] + '&');
      }
    }

    if (param) {
      window.open(this.LIST_DOWNLOAD + "?" + param + 'Authorization=' + this.authService.getAuthToken(), "_blank");
    } else {
      window.open(this.LIST_DOWNLOAD + '?Authorization=' + this.authService.getAuthToken(), "_blank");
    }
  }
}
