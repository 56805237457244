import { C } from "@angular/cdk/keycodes";
import { CLASS_NAME } from "ngx-bootstrap/modal/modal-options.class";
import {PageReq, PageResp} from "../page/page";

export class ServiceMonitorResponse {
  service_monitor_dtolist?: Array<ServiceMonitorDTO>;
}

export class AllServiceMonitorResponse {
  all_service_monitor_dtolist?: Array<AllServiceMonitorDTOList>;
}

export class AllServiceMonitorDTOList {
  project_name?:string;
  service_monitor_dtolist?: Array<ServiceMonitorDTO>;
}

export class ServiceMonitorDTO {

  type?: string;

  in_handle?: number;

  un_handle?: number;

  handle_minute?: number;

  last_week_handle_count?: number;

  this_week_handle_count?: number;

  handle_rate?: number;
}

export enum ServiceMonitorTypeEnum {
  COMPLAINT = 'COMPLAINT',
  CLEAN = 'CLEAN',
  REPAIR = 'REPAIR',
}

export class EnergyConsumeMonitorDTO {

  project_name?: string;

  // 近1小时整个项目所有房间的电力总能耗
  ele_consume_amount_per_hour?: number;
  // @Remark("近一周平均每小时的能耗值")
  ele_avg_consume_amount_last_week?: number;
  // @Remark("近24小时每小时的能耗值数组")
  ele_consume_amount24_hours?: Array<number>;

  // 近1小时整个项目所有房间的冷水总能耗
  cold_water_consume_amount_per_hour?: number;
  // @Remark("近一周平均每小时的冷水能耗值")
  cold_water_avg_consume_amount_last_week?: number;
  // @Remark("近24小时每小时的冷水能耗值数组")
  cold_water_consume_amount24_hours?: Array<number>;

  // 近1小时整个项目所有房间的热水总能耗
  hot_water_consume_amount_per_hour?: number;
  // @Remark("近一周平均每小时的热水能耗值")
  hot_water_avg_consume_amount_last_week?: number;
  // @Remark("近24小时每小时的热水能耗值数组")
  hot_water_consume_amount24_hours?: Array<number>;
}

export class EnergyConsumptionResponse extends EnergyConsumeMonitorDTO{
}

export class EnergyConsumptionAllResponse {
  energy_consume_monitor_dtolist?: Array<EnergyConsumeMonitorDTO>;
}

export class ErrorMonitorResponse {

  elemeter_errors?: Array<ErrorMonitorDTO>;

  water_errors?: Array<ErrorMonitorDTO>;

  door_errors?: Array<ErrorMonitorDTO>;

  gate_way_errors?:Array<ErrorGateDTO>;
}
export class ErrorGateDTO{
  gate_way_name?: string;
  project_name?: string;
  room_num?:number;
}
export class ErrorMonitorDTO {
  project_name?: string;
  room_name?: string;
  minutes?: number;
  error_remark?:string;
}


export class ProjectDataDTO {
  project_id?: number;
  project_name?: string;
}

export class BusinessIncomeDataDTO extends ProjectDataDTO {
  // @Remark("房费应收")
  rent_income?: string;
  // @Remark("除房费账单以外的其他全部应收金额")
  other_income?: string;
}

export class PrepayConsumeDataDTO extends ProjectDataDTO {
  // @Remark("企业预付费账户消费扣款总额")
  company_prepay_consume?: string;
  // @Remark("房间个人预付费账户消费扣款总额")
  person_prepay_consume?: string;
  // @Remark("总消费")
  total_prepay_consume?: string;
}

export class SalesContractDataDTO extends ProjectDataDTO {
  // @Remark("合同类型为“新签约”")
  new_lease?: string;
  // @Remark("合同类型为“续签”")
  relet_lease?: string;
  total?: string;
}

export class DailyRentDataDTO extends ProjectDataDTO {
  // @Remark("日均价")
  price?: string;
}

export class OverdueGatheringDataDTO extends ProjectDataDTO {
  // @Remark("未支付金额")
  unpaid_amount?: string;
  // @Remark("已支付金额")
  paid_amount?: string;
  // @Remark("无需付金额")
  needless_amount?: string;
}

export class RefundBillDataDTO extends ProjectDataDTO {
  // @Remark("已核销退款的总金额")
  refunded_amount?: string;
  // @Remark("未被核销退款的总金额")
  unrefund_amount?: string;
  total_amount?: string;
}

export class RentPercentDataDTO extends ProjectDataDTO {
  // @Remark("今日出租率")
  today_rent_percent?: string;
  // @Remark("本月出租率")
  month_rent_percent?: string;
}

export class CheckinRenterDataDTO extends ProjectDataDTO {
  // @Remark("住房客人员数量")
  renter_num?: string;
}

export class ServiceDataDTO extends ProjectDataDTO {
  // @Remark("处理完成数量")
  complete_num?: string;
  // @Remark("处理中数量")
  processing_num?: string;
  // @Remark("未处理数量")
  unprocessed_num?: string;
  // @Remark("合计")
  total?: string;

  // @Remark("处理时效：小时")
  deal_hour?: string;
  // @Remark("处理时效：分钟")
  deal_minute?: string;
  // @Remark("本月响应超时单数")
  timeout_num?: string;
}

export class VisualDataThisMonthResponse {
  // @Remark("营业收入数据")
  business_income_data_dtos?: Array<BusinessIncomeDataDTO>;

  // @Remark("预付费消费数据")
  prepay_consume_data_dtos?: Array<PrepayConsumeDataDTO>;

  // @Remark("销售合同数据")
  sales_contract_data_dtos?: Array<SalesContractDataDTO>;

  // @Remark("签约日均价数据")
  daily_rent_data_dtos?: Array<DailyRentDataDTO>;

  // @Remark("逾期金收款数据")
  overdue_gathering_data_dtos?: Array<OverdueGatheringDataDTO>;

  // @Remark("合同/账单退款数据")
  refund_bill_data_dtos?: Array<RefundBillDataDTO>;

  // @Remark("出租率统计")
  rent_percent_data_dtos?: Array<RentPercentDataDTO>;

  // @Remark("在住人数统计")
  checkin_renter_data_dtos?: Array<CheckinRenterDataDTO>;

  // @Remark("投诉建议事件数据")
  complaint_data_dtos?: Array<ServiceDataDTO>;

  // @Remark("保洁事件数据")
  clean_data_dtos?: Array<ServiceDataDTO>;

  // @Remark("维修事件数据")
  repair_data_dtos?: Array<ServiceDataDTO>;
  // @Remark("总计-今日出租率")
  today_rent_total_percent?: string;
  // @Remark("总计-本月出租率")
  month_rent_total_percent?: string;
}



export class VisualDataTrendRequest {
  num?: number;
}

export class DataTrendDTO {
  // @Remark("数值")
  number?: string;
  // @Remark("比率")
  rate?: string;
}

export class ProjectDataTrendDTO {
  project_id?: number;
  project_name?: string;
  // @Remark("环比数据")
  data_trend_dtos?: Array<DataTrendDTO>;
}

export class VisualDataTrendResponse {
  // @Remark("营业总收入走势图")
  business_income_trend_dtos?: Array<ProjectDataTrendDTO>;
  // @Remark("房费收入走势图")
  rent_income_trend_dtos?: Array<ProjectDataTrendDTO>;
  // @Remark("其他收入走势图")
  other_income_trend_dtos?: Array<ProjectDataTrendDTO>;
  // @Remark("预付费消费总额走势图")
  prepay_consume_trend_dtos?: Array<ProjectDataTrendDTO>;
  // @Remark("个人预付费消费走势图")
  person_prepay_consume_trend_dtos?: Array<ProjectDataTrendDTO>;
  // @Remark("企业预付费消费走势图")
  company_prepay_consume_trend_dtos?: Array<ProjectDataTrendDTO>;
  // @Remark("逾期金总额走势图")
  overdue_trend_dtos?: Array<ProjectDataTrendDTO>;
  // @Remark("设为无需付逾期金走势图")
  needless_trend_dtos?: Array<ProjectDataTrendDTO>;
  // @Remark("合同/账单退款总额走势图")
  refund_bill_trend_dtos?: Array<ProjectDataTrendDTO>;
  // @Remark("签约日均价走势图")
  daily_rent_trend_dtos?: Array<ProjectDataTrendDTO>;
  // @Remark("出租率走势图")
  rent_percent_trend_dtos?: Array<ProjectDataTrendDTO>;
  // @Remark("销售合同走势图")
  sales_contract_trend_dtos?: Array<ProjectDataTrendDTO>;
  // @Remark("投诉事件走势图")
  complaint_trend_dtos?: Array<ProjectDataTrendDTO>;
  // @Remark("保洁服务事件走势图")
  clean_trend_dtos?: Array<ProjectDataTrendDTO>;
  // @Remark("维修服务事件走势图")
  repair_trend_dtos?: Array<ProjectDataTrendDTO>;
}

/**
* @Description: 运营数据监控数据
* @Author: yangwen
* @Date: 2019/11/28 11:21
*/
export class BusinessDataResponse {
  business_datas?: Array<BusinessStatisticsListDTO>;
}

export class BusinessStatisticsListDTO {
  project_id?: number;
  project_name?: string;

  //@Remark(value = "近30天出租率数据，size=30")
  rent_percent_list?: Array<string>;

  //@Remark(value = "近30天营业额，size=30")
  business_amount_list?: Array<string>;

  //@Remark(value = "近30天交易额，size=30")
  transaction_amount_list?: Array<string>;

  //@Remark(value = "近四周保洁订单数量，size=4")
  clean_order_list?: Array<number>;

  //@Remark(value = "近四周保洁订单处理时效，size=4")
  clean_deal_list?: Array<number>;

  //@Remark(value = "近四周维修订单数量，size=4")
  repair_order_list?: Array<number>;

  //@Remark(value = "近四周维修订单处理时效，size=4")
  repair_deal_list?: Array<number>;

  //@Remark(value = "近四周电力安全事件监测，size=4")
  ele_security_list?: Array<number>;

  //@Remark(value = "近四周水力安全事件监测，size=4")
  water_security_list?: Array<number>;

  //@Remark(value = "近4周门锁故障监测，size=4")
  door_error_list?: Array<number>;

  //@Remark(value = "近4周电表故障监测，size=4")
  ele_error_list?: Array<number>;

  //@Remark(value = "近4周水表故障监测，size=4")
  water_error_list?: Array<number>;
}

export class PersonSafeAlarmListReq extends PageReq{
  // true: (已关闭状态报警记录) false: (未处理/处理中状态报警记录)
  is_closed:boolean = true;
  // THID_NOT_IN 3日未归 THID_NOT_OUT 三日未出 UN_REGISTERED 未登记在住人员 UNKNOW_SUSPICIOUS 陌生可疑
  safe_type:string;
}

export class PersonSafeAlarmListRes extends PageResp{
  person_safe_alarm_dtos:PersonSafeAlarmDto[];
}

export class PersonSafeAlarmDto{
  // 安全报警id
  alarm_id:number;
  // 报警时间
  alarm_time:Date;
  // 人员抓拍头像
  head_image:string;
  // 状态值： UNPROCESSED 未处理 PROCESSED 处理中
  status:string;
  // 安全预警类型： THID_NOT_IN 3日未归 THID_NOT_OUT 三日未出 UN_REGISTERED 未登记在住人员 UNKNOW_SUSPICIOUS 陌生可疑
  safe_type:string;
  // 该对象为-（未登记在住人员）独有
  not_registered_chickin_dto:NotRegisteredChickinDto;
  // 该对象为-（陌生可疑人员独有）
  strange_suspect_dtos:StrangeSuspectDto[];
  // 房间名（三日未归/宵禁未归/三日未出）
  room_name:string;
  // 人员名称（三日未归/宵禁未归/三日未出）
  person_name:string;
  // 人员备注（三日未归/宵禁未归/三日未出）
  person_remark:string;
  // 最后一次离宿时间（三日未归/宵禁未归）
  get_out_time:Date;
  // 最后一次进入时间（三日未出）
  come_in_time:Date;
  // 最后一次抓拍图片
  last_image:string;
  // 关闭-操作人
  operator_name:string;
  // 关闭时间
  operator_time:Date;
}

export class NotRegisteredChickinDto{
  // 进出总次数
  total_num:number;
  // 进出楼栋名
  unit_name:number;
  // 出去时间段
  come_in_time:string;
  // 出去次数
  come_in_num:number;
  // 进入时间点
  get_out_time:string;
  // 进入次数
  get_out_num:number;
  // 首次进入时间
  first_come_in_time:Date;
  // 楼栋房间名
  unit_room_name:string;
  strange_suspect_dtolist:StrangeSuspectDto[];
}

export class StrangeSuspectDto{
  // 进出位置
  unit_detail:string;
  // 进出记录
  access_dtos:AccessDto[];
}

export class AccessDto{
  // 出入类型
  type:string;
  // 时间
  access_time:Date;
  // 进出位置
  position_detail:string;
  // 图片
  image:string;
}
