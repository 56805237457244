import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {
  CarportBillPayLogDTO,
  CarportLeaseBillDetailParam,
  CarportLeaseBillDTO
} from "../../../model/carport/carport.modal";
import {CarportService} from "../../../service/carport/carport.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../service/common/auth.service";
import {LocalStorage} from "../../../common/localstorage";
import {Constants} from "../../../common/constants";
import {environment} from "../../../../environments/environment";
import {BillGatheringComponent} from "../bill-gathering/bill-gathering.component";
import {WorkModel} from "../../../model/common/common.model";
import {WorkService} from "../../../service/common/work.service";

@Component({
  selector: 'app-carport-lease-bill-detail',
  templateUrl: './lease-bill-detail.component.html',
  styleUrls: ['./lease-bill-detail.component.css']
})
export class LeaseBillDetailComponent implements OnInit {
//账单ID
  billId: number;
  //账单对象
  leaseRoomBillDetail: CarportLeaseBillDTO;
  //账单财务明细
  billPayLogList: Array<CarportBillPayLogDTO>;
  billDetailModal: any;
  @Output() billDetailChange = new EventEmitter();
  //打印相关
  isPhone: boolean = true;
  @ViewChild(BillGatheringComponent, { static: true }) billGatheringComponent: BillGatheringComponent;
  // @ViewChild("printprew") printprew: PrintPreviewComponent;
  contractPath: string;
  //收款权限
  LEASE_BILL: boolean;

  constructor(
    private carportService: CarportService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private workService: WorkService
    ) {
  }

  ngOnInit() {
    this.getOperatePrivilege();
    let result = localStorage.getItem('CURRENT_USER_SOURCE');
    if (!result) {
      this.isPhone = false;
    }
  }

  getOperatePrivilege() {
    let data = LocalStorage.getItem(Constants.USER_OPERATE_PRIVILEGES + this.authService.getUserInfo().id);
    if (data) {
      let privileges = JSON.parse(data);
      this.LEASE_BILL = privileges.find(value => value === 'LEASE_BILL');
    }
  }

  setBillDetailModal(modal: any) {
    this.billDetailModal = modal;
  }

  showModel(billId: number) {
    this.billId = billId;
    this.getleaseRoomBillDetail();
    this.getBillPayLogList();
    this.billDetailModal.show();
  }

  colseModel() {
    this.billDetailModal.hide();
  }

  //账单详情
  getleaseRoomBillDetail(): void {
    let param = new CarportLeaseBillDetailParam(this.billId, null);
    this.carportService.getleaseBillDetail(param)
      .then(data => {
        if (data) {
          this.leaseRoomBillDetail = data;
        } else {
          this.leaseRoomBillDetail = null;
        }
      });
  }

  //账单财务明细
  getBillPayLogList(): void {
    let param = new CarportLeaseBillDetailParam(this.billId, null);
    this.carportService.getBillPayLogList(param)
      .then(data => {
        if (data && data.bill_pay_log_list) {
          this.billPayLogList = data.bill_pay_log_list;
        } else {
          this.billPayLogList = null;
        }
      });
  }

  /**
   * 打开支付流水详情弹框
   * @param id
   * @param type
   */
  showDetail(id: number, type: string) {
    if (type === 'PAY_LOG') {
      this.colseModel();
      this.billDetailChange.emit(id);
    } else if (type === 'BOOKING') {
      this.colseModel();

      this.router.navigate(['/system_manage/booking-management/detail', id])
    }
  }

  /**
   * 打开收款页面
   * @param leaseId
   * @param billId
   */
  showDialog(leaseId?: any, billId?: any) {
    this.billGatheringComponent.openGatherDialog(leaseId, billId);
  }

  /**
   * 打印账单
   * @param leaseId
   * @param billId
   */
  printshow(leaseId: number, billId: number) {

    this.contractPath = `${environment.apiHost}` + 'file/preview?bizcode=CARPORTUSERBILL&leaseId='
      + leaseId + '&billId=' + billId + '&' + Math.ceil(Math.random() * 100);

    this.workService.event_bus.emit(new WorkModel(this.workService.type.PDF_PRINT, this.contractPath));

    // this.printprew.show();
  }

}
