import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
  CompanyPrepayCreateReq,
  CompanyPrepayDetailRequest,
  CompanyPrepayDetailResponse,
  CompanyPrepayInfoReq,
  CompanyPrepayInfoRes,
  CompanyPrepayRechargeRecordRequest,
  CompanyPrepayRecordDTO
} from "../../../model/lease/lease.model";
import {BaseInfoService} from "../../../service/common/base-info.service";
import {Router} from "@angular/router";
import {WorkService} from "../../../service/common/work.service";
import {CompanyService} from "../../../service/company/company.service";
import {MessageModalInfo} from "../../../model/modal/message-model";
import {WorkModel} from "../../../model/common/common.model";
import {CompanyPrepayRechargeComponent} from "../../companyprepay/company-prepay-recharge/company-prepay-recharge.component";
import {AuthService} from "../../../service/common/auth.service";
import {ModalDirective} from "ngx-bootstrap/modal";
import {generateDatePluginData} from "../../../common/common";
import {getDateFromPlugin} from "../../../common/date.util";

@Component({
  selector: 'app-company-prepay',
  templateUrl: './company-prepay.component.html',
  styleUrls: ['./company-prepay.component.css']
})
export class CompanyPrepayComponent implements OnInit, OnChanges {

  @ViewChild(CompanyPrepayRechargeComponent, { static: true }) companyPrepayRechargeComponent: CompanyPrepayRechargeComponent;
  @ViewChild("createModal", { static: true }) createDialog: ModalDirective;
  @ViewChild("detailModal", { static: true }) detailDialog: ModalDirective;
  @Output() prepayChange = new EventEmitter();
  @Input() leaseId: number = 0;
  @Input() leaseNum: string;
  @Input() contactName: string;
  @Input() leaseStatus: string;
  @Input() leaseStatusDesc: string;
  @Input() auditStatus: string;

  //按钮权限对象
  btn_privileges: any = {
    'OPEN_COMPANY_PREPAY_ACCOUNT': false,
    'COMPANY_PREPAY_FIVE': false,
    'COMPANY_PREPAY_ARREARS': false,
    'COMPANY_PREPAY_SETTING': false,
    'COMPANY_PREPAY_RECHARGE': false,
    'COMPANY_PREPAY_RECORD': false,
    'COMPANY_PREPAY_RECHARGE_RECORD':false,
  };

  companyPrepayInfoReq: CompanyPrepayInfoReq = {
    lease_id: this.leaseId
  };
  companyPrepayCreateReq: CompanyPrepayCreateReq = {
    lease_id: this.leaseId,
    limit: 100
  };

  companyPrepayDetailReq: CompanyPrepayDetailRequest = {
    lease_id: this.leaseId,
    page_size: 15,
    page_num: 1
  };
  companyPrepayDetailRes: CompanyPrepayDetailResponse = {
    company_prepay_detail_dtos: []
  };
  loading = false;
  flag = 0;

  //1：不展示；2.展示开通按钮； 3：展示预付账户明细
  showData: number;
  companyPrepayInfoRes: CompanyPrepayInfoRes;

  startTime:any;
  endTime:any;

  company_prepay_record_dtos : Array<CompanyPrepayRecordDTO> = [];

  constructor(private router: Router,
              private baseInfoService: BaseInfoService,
              private authService: AuthService,
              private workService: WorkService,
              private companyService: CompanyService) {

  }

  ngOnInit() {

    this.authService.getOperatePrivilege(this.btn_privileges);

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.workService.event_bus.subscribe((data: WorkModel) => {
      if (data && data.type === this.workService.type.PANEL_NEED_REFRESH) {
        this.getInfo();
      }
    });

    this.getInfo();

    this.companyPrepayDetailRes = new CompanyPrepayDetailResponse();
    this.companyPrepayDetailRes.company_prepay_detail_dtos = [];
    this.companyPrepayDetailReq.page_num = 1;
  }

  getInfo(): Promise<any> {
    this.companyPrepayInfoReq.lease_id = this.leaseId;
    return this.companyService.getCompanyPrepayInfo(this.companyPrepayInfoReq).then(data => {
      this.companyPrepayInfoRes = data;
      if (this.companyPrepayInfoRes.lease_type === 'COMPANY') {
        if (this.companyPrepayInfoRes.total) {
          this.showData = 3;
        } else {
          this.showData = 2;
        }
      } else {
        this.showData = 1;
      }
    });
  }

  create() {
    this.createDialog.show();
  }

  confirmCreate() {
    this.companyPrepayCreateReq.lease_id = this.leaseId;

    if (this.showData === 3) {
      this.companyService.changeCompanyPrepay(this.companyPrepayCreateReq).then(data => {
        this.createDialog.hide();
        this.getInfo();
      });
    } else {
      this.companyService.createCompanyPrepay(this.companyPrepayCreateReq).then(data => {
        this.createDialog.hide();
        let messageModalInfo = new MessageModalInfo();
        messageModalInfo.title = "开通企业预付费账户";
        messageModalInfo.operationMessage = '已开通成功！';
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
        this.getInfo();

        //改变租约详情-预付费账户文案
        this.prepayChange.emit();
      });
    }
  }

  showDetail() {
    this.detailDialog.show();
    this.companyPrepayDetailReq.lease_id = this.leaseId;
    this.getDetail(this.flag);
  }

  initDate() {
    let number = new Date().getTime() - 31 * 24 * 60 * 60 * 1000;
    this.startTime = generateDatePluginData(new Date(number));
    this.endTime = generateDatePluginData(new Date());
  }

  validateDates(startTime: any, endTime: any): boolean {
    if (startTime && endTime) {
      if (startTime > endTime) {
        this.workService.showErrorModal('开始时间不能大于结束时间');
        return false;
      }

      if ((endTime - startTime) > 31 * 24 * 60 * 60 * 1000) {
        this.workService.showErrorModal('开始时间、结束时间之间不能超过31天');
        return false;
      }
    }
    return true;
  }

  getDetail(flag: number) {
    if(!this.startTime && !this.endTime){
      this.initDate();
    }
    if(!this.validateDates(getDateFromPlugin(this.startTime.formatted), getDateFromPlugin(this.endTime.formatted))){
      return;
    }

    this.companyPrepayDetailReq.start_time = getDateFromPlugin(this.startTime.formatted).getTime();
    this.companyPrepayDetailReq.end_time = getDateFromPlugin(this.endTime.formatted).getTime();

    if(flag === -1){
      this.companyPrepayDetailReq.page_num = 1;
      this.companyPrepayDetailRes.company_prepay_detail_dtos = [];
    }else if(this.flag === 1){
      return;
    }
    this.companyService.getCompanyPrepayDetail(this.companyPrepayDetailReq).then(data => {
      flag = 0;
      this.loading = false;
      this.companyPrepayDetailRes.total = data.total;
      this.companyPrepayDetailRes.company_prepay_detail_dtos =
        this.companyPrepayDetailRes.company_prepay_detail_dtos.concat(data.company_prepay_detail_dtos);
      if (data.company_prepay_detail_dtos != null && data.company_prepay_detail_dtos.length === 15) {
        this.companyPrepayDetailReq.page_num += 1;
        this.getDetail(flag);
      }
      // this.companyPrepayDetailRes.total = this.companyPrepayDetailRes.company_prepay_detail_dtos.length;
    });
  }

  /**
   * 关闭弹窗时，停止加载水电表数据
   */
  stopGetList() {
    this.flag = 1;
  }

  download() {
    let start_time = getDateFromPlugin(this.startTime.formatted).getTime();
    let end_time = getDateFromPlugin(this.endTime.formatted).getTime();
    let downloadUrl = this.companyService.downloadConsumeDetail(this.leaseId)
      + '&start_time='+ start_time + '&end_time='+ end_time + '&page_size=0&page_num=1&Authorization=' + this.authService.getAuthToken();
    window.open(downloadUrl, '_blank');
  }

  initDialog(createDialog?: any, detailDialog?: any) {
    this.createDialog = createDialog;
    this.detailDialog = detailDialog;
  }

  rechargeShow() {
    this.companyPrepayRechargeComponent.rechargeShow();
  }

  privilegeAlert() {
    if (!this.btn_privileges.COMPANY_PREPAY_ARREARS) {
      this.companyPrepayCreateReq.arrears_percent = null;
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "您无此权限，请联系店长/经理操作"));
    }
  }

  changeAlarm() {

    this.getInfo().then(data => {

      this.companyPrepayCreateReq.limit = +this.companyPrepayInfoRes.limit;
      this.companyPrepayCreateReq.arrears_percent = this.companyPrepayInfoRes.arrears_percent;

      this.createDialog.show();
    });
  }

  showPrePayRechargeRecord(prepayRechargeRecordModal: any){

    let req = new CompanyPrepayRechargeRecordRequest();
    req.lease_id = this.leaseId;
    this.companyService.getCompanyPrepayRchargRecordList(req).then(data => {
      this.company_prepay_record_dtos = data ? data.company_prepay_record_dtos : null;
      prepayRechargeRecordModal.show();
    });
  }

  downloadCompanyPrepayRchargRecord() {
    let req = new CompanyPrepayRechargeRecordRequest();
    req.lease_id = this.leaseId;
    this.companyService.downloadCompanyPrepayRchargRecord(req);
  }
}
