import { Injectable } from '@angular/core';

import { RequestService } from '../request.service';
import { environment } from '../../../environments/environment';

/**
 * @Description:  岗位service
 * @Author: zhoujiahao
 * @Date: 2018-03-20  15:44
 */
@Injectable()
export class PositionService {

  // 岗位列表查询
  private POSITION_LIST = `${environment.apiHost}position/list`;
  //岗位类型
  private POSITION_TYPE_LIST = `${environment.apiHost}position/type/list`;
  //部岗位管理
  private POSITION_MANAGE = `${environment.apiHost}position/manage`;
  //岗位信息查询
  private POSITION_INFO_QUERY = `${environment.apiHost}position/info`;

  /***************UPDATE**************************/
    //岗位管理 index
  private POSITION_INDEX = `${environment.apiHost}position/queryPositionByCoId`;
  private USE_INFO_BY_POSITION = `${environment.apiHost}position/queryUseInfoByPositionId`;
  //岗位管理：新增、修改、删除
  private POST_MANAGE = `${environment.apiHost}position/positionManage`;
  //判断是否能删除
  private CAN_DELETE = `${environment.apiHost}position/canDelete`;
  //岗位权限管理
  private POSITION_AUTH = `${environment.apiHost}position/positionAuthManage`;
  //获取公司岗位分组
  private COMPANY_POSITION = `${environment.apiHost}position/queryPositionList`;
  //获取公司岗位列表
  private COMPANY_POSITION_LIST = `${environment.apiHost}position/queryPositionListByCoId`;

  constructor(private requestService: RequestService) {
  }

  /**
   *获取公司岗位列表
   */
  queryPositionListByCoId(reqData?: any): Promise<any> {
    return this.requestService.authGet(this.COMPANY_POSITION_LIST, reqData);
  }

  /**
   *获取公司岗位分组
   */
  queryPositionList(reqData?: any): Promise<any> {
    return this.requestService.authGet(this.COMPANY_POSITION, reqData);
  }

  /**
   * 岗位权限管理
   */
  positionAuthManage(reqData?: any): Promise<any> {
    return this.requestService.authPost(this.POSITION_AUTH, reqData);
  }

  /**
   *岗位设置 判断是否能删除
   */
  canDelete(reqData?: any): Promise<any> {
    return this.requestService.authGet(this.CAN_DELETE, reqData);
  }

  /**
   * 岗位管理：新增、修改、删除
   */
  postManage(reqData?: any): Promise<any> {
    return this.requestService.authPost(this.POST_MANAGE, reqData);
  }

  /**
   *岗位设置 首页：通过公司ID获取岗位列表和登录用户的公司信息
   */
  queryPositionByCoId(reqData?: any): Promise<any> {
    return this.requestService.authGet(this.POSITION_INDEX, reqData);
  }

  queryUseInfoByPositionId(reqData?: any): Promise<any> {
    return this.requestService.authGet(this.USE_INFO_BY_POSITION, reqData);
  }

  /**
   * @Description:   根据部门ID获取部门岗位信息
   * @Author: zhoujiahao
   * @Date: 2018-03-16  17:45
   */
  public getPositionList(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.POSITION_LIST, requestData);
  }

  /**
   * @Description:   获得岗位类型
   * @Author: zhoujiahao
   * @Date: 2018-03-30  18:01
   */
  public getPositionTypeList(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.POSITION_TYPE_LIST, requestData);
  }

  public positionManage(requestData?: any): Promise<any> {
    return this.requestService.authPost(this.POSITION_MANAGE, requestData);
  }

  public queryPositionInso(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.POSITION_INFO_QUERY, requestData);
  }
}
