import {Component, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {ModalDirective} from "ngx-bootstrap/modal";
import {TabDirective, TabsetComponent} from "ngx-bootstrap/tabs";
import {
  ApproBatchPwdResponse,
  ApprovalLockPasswordResponse, ApprovalRenterCheckoutDepositResponse,
  ApprovalResultResponse,
  ApprovalTaskDTO
} from "../../../model/approv/approv.model";
import {
  ChangeRoomAvailableDaysFlowAuditResponse, FrozenPasswordFlowAuditResponse,
  LeaseBillFlowAuditResponse,
  LeaseSubjectFlowAuditResponse
} from "../../../model/lease/lease.model";
import {ApprovService} from "../../../service/approv/approv.service";
import {LeaseService} from "../../../service/lease/lease.service";
import { filterFormData } from "../../../common/common";
import { AssTransAuditDetailRequest, AssTransAuditDetailResponse } from "../../../model/asset/asset.model";
import { AssetService } from "../../../service/asset/asset.service";
import { WorkModel } from "../../../model/common/common.model";
import { WorkService } from "../../../service/common/work.service";
import {ApprovRecordImgComponent} from "../approv-record-img/approv-record-img.component";

@Component({
  selector: 'app-approv-record-detail-modal',
  templateUrl: './approv-record-detail-modal.component.html',
  styleUrls: ['./approv-record-detail-modal.component.css']
})
export class ApprovRecordDetailModalComponent implements OnInit {

  @ViewChild('staticTabs', {static: true}) staticTabs: TabsetComponent;
  @ViewChild(ApprovRecordImgComponent) approvRecordImgComponent: ApprovRecordImgComponent;
  @ViewChild('showLeaseChangeAuditModal', {static: true}) showLeaseChangeAuditModal: ModalDirective;


  leaseSubjectAuditDetail: LeaseSubjectFlowAuditResponse;
  leaseBillFlowAuditResponse: LeaseBillFlowAuditResponse;
  approvalResultResponse: ApprovalResultResponse;

  process_instance_id;
  img;
  approv_num;

  /** 资产转移审批模块参数   开始 */
  assTransAuditDetailResponse?: AssTransAuditDetailResponse;
  assTransAuditDetailRequest: AssTransAuditDetailRequest = {
    page_num: 1,
    page_size: 20,
    approRecordInfoId: 0
  };
  assetEditRecordCount: number = 0;
  loading = false;
  /** 资产转移审批模块参数   结束 */
  changeRoomAvailableDaysFlowAuditResponse: ChangeRoomAvailableDaysFlowAuditResponse;

  frozenPasswordFlowAuditResponse: FrozenPasswordFlowAuditResponse;
  approLockPasswordRes:ApprovalLockPasswordResponse;
  approRenterCheckoutDeposit:ApprovalRenterCheckoutDepositResponse;
  is_lock_password;
  sendLockPasswordLoading = false;
  approBatchPwdResponse:ApproBatchPwdResponse;
  approProjectBasePrice:any;
  approLeaseCommit:any;

  constructor(
    private router: Router,
    private approvService: ApprovService,
    private leaseService: LeaseService,
    private assetService: AssetService,
    private workService: WorkService,
  ) { }

  ngOnInit(): void {
  }


  showDetail(dto: ApprovalTaskDTO) {

    this.showLeaseChangeAuditModal.hide();

    this.approvService.approvDoneDetail(dto.instance_id).then(data => {
      this.approvalResultResponse = data;
    });

    // this.approvService.approvDetailImg(dto.instance_id).then(data => {
    //   this.img = 'data:image/jpg;base64,' + data.base64img;
    // });

    this.staticTabs.tabs[0].active = true;

    this.leaseSubjectAuditDetail = null;
    this.leaseBillFlowAuditResponse = null;
    this.assTransAuditDetailResponse = null;
    this.changeRoomAvailableDaysFlowAuditResponse = null;
    this.frozenPasswordFlowAuditResponse = null;

    switch (dto.type) {
      case 'LEASE_CHANGE':
        this.leaseService.getLeaseSubjectAuditDetail(dto.id).then(data => {
          this.leaseSubjectAuditDetail = data;
          this.approv_num = data.approv_num;
          this.process_instance_id = data.process_instance_id;
          this.showLeaseChangeAuditModal.show();
        });
        break;
      case 'LEASE_BILL_CHANGE':
        this.leaseService.getLeaseBillChangeDetail(dto.id).then(data => {
          this.leaseBillFlowAuditResponse = data;
          this.approv_num = data.approv_num;
          this.process_instance_id = data.process_instance_id;
          this.showLeaseChangeAuditModal.show();
        });
        break;
      case 'ASSET_TRANSFER':// 资产转移
        this.showAssetTransModal(dto.id);
        break;
      case 'ROOM_AVAILABLE_TIME_CHANGE':
        this.leaseService.getRoomAvailableTimeChangeAuditDetail(dto.id).then(data => {
          this.changeRoomAvailableDaysFlowAuditResponse = data;
          this.approv_num = data.approv_num;
          this.process_instance_id = data.process_instance_id;
          this.showLeaseChangeAuditModal.show();
        });
        break;
      case 'FROZEN_PASSWORD':
        this.leaseService.getFrozenPasswordAuditDetail(dto.id).then(data => {
          this.frozenPasswordFlowAuditResponse = data;
          this.approv_num = data.approv_num;
          this.process_instance_id = data.process_instance_id;
          this.showLeaseChangeAuditModal.show();
        });
        break;
      case 'LOCK_PASSWORD':
        this.is_lock_password = dto.is_lock_password;
        this.approvService.getLockPasswordDetail(dto.id).then(data => {
          this.approLockPasswordRes = data;
          this.approv_num = data.approv_num;
          this.process_instance_id = data.process_instance_id;
          this.showLeaseChangeAuditModal.show();
        });
        break;
      case 'RENTER_CHECKOUT_DEPOSIT':
        this.approvService.renterCheckoutDepositInfo(dto.id).then(data => {
          this.approRenterCheckoutDeposit = data;
          this.approv_num = data.approv_num;
          this.process_instance_id = data.process_instance_id;
          this.showLeaseChangeAuditModal.show();
        });
        break;
      case 'PROJECT_BASE_PRICE':
        this.approvService.projectBasePriceInfo(dto.id).then(data =>{
          this.approProjectBasePrice = data;
          this.approv_num = data.approv_num;
          this.process_instance_id = data.process_instance_id;
          this.showLeaseChangeAuditModal.show();
        });
        break;
      case 'LEASE_COMMIT':
        this.approvService.leaseCommitFlow(dto.id).then(data =>{
          this.approLeaseCommit = data;
          this.approv_num = data.approv_num;
          this.process_instance_id = data.process_instance_id;
          this.showLeaseChangeAuditModal.show();
        });
        break;
    }

  }

  to_lease(lease_id: number) {
    this.showLeaseChangeAuditModal.hide();
    this.router.navigate(["/system_manage/signed/pact/", lease_id]);
  }
  to_booking(lease_id: number) {
    this.showLeaseChangeAuditModal.hide();
    this.router.navigate(["/system_manage/booking-management/detail/", lease_id]);
  }

  isChangeEmergencyContacts(leaseSubjectAuditDetail: LeaseSubjectFlowAuditResponse): boolean {

    let tag = false;
    if (leaseSubjectAuditDetail) {
      let changeBeforeDtoE = leaseSubjectAuditDetail.change_before_dto.emergency_contacts;
      let changeAfterDtoE = leaseSubjectAuditDetail.change_after_dto.emergency_contacts;

      let a = [], b = [];

      if (changeBeforeDtoE) {
        changeBeforeDtoE.forEach((item) => {
          a.push({
            contact_name: item.contact_name,
            contact_phone: item.contact_phone,
          });
        });
      }
      if (changeAfterDtoE) {
        changeAfterDtoE.forEach((item) => {
          b.push({
            contact_name: item.contact_name,
            contact_phone: item.contact_phone,
          });
        });
      }

      tag = JSON.stringify(a) !== JSON.stringify(b);
    }

    return tag;
  }

  /** 资产转移审批模块方法   开始 */
  showAssetTransModal(approRecordInfoId: number) {
    this.assTransAuditDetailRequest.page_num = 1;
    this.assTransAuditDetailRequest.approRecordInfoId = approRecordInfoId;
    this.getAssetEditRecord();
  }

  // 获取资产详情
  getAssetEditRecord() {
    filterFormData(this.assTransAuditDetailRequest);
    this.assetService.assetEditRecordDetail(this.assTransAuditDetailRequest).then(data => {
      // 审批单号
      this.approv_num = data.approv_num;
      this.process_instance_id = data.process_instance_id;
      if (data.asset_list && data.asset_list.length > 0) {
        this.assTransAuditDetailResponse = data;

        this.assetEditRecordCount = this.assTransAuditDetailRequest.page_size*(this.assTransAuditDetailRequest.page_num - 1);
        this.assTransAuditDetailResponse.asset_list.forEach((dl2, index2) => {
          this.assetEditRecordCount++;
          dl2.asset_index = this.assetEditRecordCount;
        });
      } else {
        if (this.assTransAuditDetailRequest.page_num === 1) {
          this.assTransAuditDetailResponse.asset_edit_record_id = 0;
          this.assTransAuditDetailResponse.asset_list = [];
        }
      }
      if (this.assTransAuditDetailRequest.page_num === 1) {
        this.showLeaseChangeAuditModal.show();
      }
      this.loading = false;
    }).catch(error => {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, error.message));
    });
  }

  // 滚动分页
  transEditScroll() {
    let transEditTr = $("#transEditTr").height();
    let transEditTby = $("#transEditTby").height();
    let transEditTbySt = $("#transEditTby").scrollTop();
    let index = transEditTr * this.assetEditRecordCount;

    if (transEditTby + transEditTbySt >= index && this.loading === false) {
      this.loading = true;
      this.assTransAuditDetailRequest.page_num += 1;
      this.getAssetEditRecord();
    }
  }

  searchList(pageNum: number){
    this.assTransAuditDetailRequest.page_num = pageNum;
    this.getAssetEditRecord();
  }
  /** 资产转移审批模块方法   结束 */

  flowImgTabOnSelect(instanceId) {
    this.approvRecordImgComponent.show(instanceId);
  }

  sendLockPassword(id:number){
    this.sendLockPasswordLoading = true;
    this.approvService.sendLockPassword(id).then(data =>{
      this.sendLockPasswordLoading = false;
      this.approBatchPwdResponse = data;
      this.workService.event_bus.emit(new WorkModel("sendOver",{id:id}));
      this.showLeaseChangeAuditModal.hide();
    });
  }
  goRenterCheckout(id){
    this.router.navigate(['/system_manage/signed/renter/checkout/record/detail/'+id]);
  }
}
