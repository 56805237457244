import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {RequestService} from "../request.service";
import {ShopLeaseInfoEditRequest} from "../../model/shop/shop-edit.model";
import {ShopListRequest} from "../../model/shop/shop-list.model";
import {LeaseQueryRequest, ShopLeaseDetailResponse} from "../../model/shop/shop-detail.model";

@Injectable()
export class ShopService {

  private SHOP_LEASE_INFO_SAVE = `${environment.apiHost}shop/lease/info/save`;

  private SHOP_LEASE_INFO_LIST = `${environment.apiHost}shop/lease/info/list`;

  private SHOP_LEASE_INFO_DETAIL = `${environment.apiHost}shop/lease/info/detail`;

  private SHOP_RELATION_LIST = `${environment.apiHost}shop/lease/shopRelationList`;

  constructor(private requestService: RequestService) {
  }

  // 保存商铺合同
  public saveShopLeaseInfo(shopEditRequest: ShopLeaseInfoEditRequest): Promise<any> {
    return this.requestService.authPost(this.SHOP_LEASE_INFO_SAVE, shopEditRequest);
  }

  // 商铺合同列表
  public listShopLeaseInfo(request: ShopListRequest): Promise<any> {
    return this.requestService.authPost(this.SHOP_LEASE_INFO_LIST, request);
  }

  public listShopLeaseInfoWithLoading(request: ShopListRequest): Promise<any> {
    return this.requestService.authPost(this.SHOP_LEASE_INFO_LIST, request, true);
  }

  // 商铺合同详情
  public getLeaseInfoDetail(leaseEditParam: any): Promise<any> {
    return this.requestService.authGet(this.SHOP_LEASE_INFO_DETAIL, leaseEditParam);
  }

  public listShopRelation(leaseQueryRequest: LeaseQueryRequest): Promise<any> {
    return this.requestService.authGet(this.SHOP_RELATION_LIST, leaseQueryRequest);
  }
}
