import {Pipe, PipeTransform} from '@angular/core';

/**
 * @Description: 证件类型翻译管道
 * @Author: 陆炳彬
 * @Date: 2018/3/29 上午10:24
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/3/29 上午10:24
 */
@Pipe({
  name: 'subPipe'
})
export class SubPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (value && typeof value === 'string' && args && args.length > 1) {
      return value.substring(args[0], args[1]);
    } else {
      return value;
    }
  }
}
