import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import {WorkModel} from "../../../model/common/common.model";
import {WorkService} from "../../../service/common/work.service";

@Component({
  selector: 'app-tablet',
  templateUrl: './tablet.component.html',
  styleUrls: ['./tablet.component.css']
})
export class TabletComponent implements OnInit, AfterViewInit {

  @ViewChild('modal_n1', {static: true}) tabletModule: any;

  //签字板标题
  @Input() title: string;
  //发送签名字符串
  @Output() commitSign = new EventEmitter<string>();

  imgContent: string;

  submit;

  canvas;

  setLineWidth;
  writing;
  writeContextStyle;
  writeBegin;
  writeEnd;
  canvasClear;
  saveAsImg;
  canvasInit;
  option;
  writeCanvas;
  emptyCanvas;

  constructor(private workService: WorkService,) {
  }

  ngOnInit() {

  }

  isPad(): boolean {
    let ua = navigator.userAgent;
    let isAndroid = /(?:Android)/.test(ua);
    let isFireFox = /(?:Firefox)/.test(ua);
    let isTablet = /(?:Macintosh)/.test(ua) || /(?:iPad|PlayBook)/.test(ua) ||
      (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua));
    return isTablet;
  }


  setModule(module: any) {
    if (!this.tabletModule) {
      this.tabletModule = module;
    }
  }

  tabletShow() {
    if (this.isPad()) {
      this.initPad();
      this.tabletModule.show();
    } else {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请使用平板设备进行签署！'));
    }
  }

  setTitel(titel: string) {
    this.title = titel;
  }

  getImgContent() {
    return this.imgContent;
  }

  setSubmit(submit) {
    this.submit = submit;
  }

  WriteFont(id: string, options: any) {
    let self = this;
    this.canvas = document.getElementById(id);
    if (!this.canvas) {
      return;
    }
    let obj = {
      canvas: this.canvas,
      context: this.canvas.getContext("2d"),
      isWrite: false, //是否开始
      lastWriteTime: -1,
      lastWriteSpeed: 0,
      lastWriteWidth: 0,
      canvasWidth: 980, //canvas宽高
      canvasHeight: 300,
      isShowBorder: true, //是否显示网格
      bgColor: '#fff', //背景色
      borderWidth: 2, // 网格线宽度
      borderColor: "#fff", //网格颜色
      lastPoint: {
        x: null,
        y: null,
      }, //
      writeWidth: 2, //基础轨迹宽度
      maxWriteWidth: 30, // 写字模式最大线宽
      minWriteWidth: 1, // 写字模式最小线宽
      writeColor: '#000', // 轨迹颜色
      isWriteName: false, //签名模式
      control: null,
    };
    for (let name in options) {
      obj[name] = options[name];
    }

    /**
     * 轨迹宽度
     */
    this.setLineWidth = function () {
      let nowTime = new Date().getTime();
      let diffTime = nowTime - obj.lastWriteTime;
      obj.lastWriteTime = nowTime;
      let returnNum = obj.minWriteWidth + (obj.maxWriteWidth - obj.minWriteWidth) * diffTime / 30;
      if (returnNum < obj.minWriteWidth) {
        returnNum = obj.minWriteWidth;
      } else if (returnNum > obj.maxWriteWidth) {
        returnNum = obj.maxWriteWidth;
      }
      returnNum = Number.parseFloat(returnNum.toFixed(2));
      //写字模式和签名模式
      if (obj.isWriteName) {
        // obj.context.lineWidth = obj.writeWidth;
        obj.context.lineWidth = 12;
      } else {
        // obj.context.lineWidth = obj.lastWriteWidth = obj.lastWriteWidth / 4 * 3 + returnNum / 4;
        obj.context.lineWidth = 12;
      }
    };

    /**
     * 绘制轨迹
     */
    this.writing = function (point) {
      obj.context.beginPath();
      obj.context.moveTo(obj.lastPoint.x, obj.lastPoint.y);
      obj.context.lineTo(point.x, point.y);
      self.setLineWidth();
      obj.context.stroke();
      obj.lastPoint = point;
      obj.context.closePath();
    };

    /**
     * 轨迹样式
     */
    this.writeContextStyle = function () {
      obj.context.beginPath();
      obj.context.strokeStyle = obj.writeColor;
      obj.context.lineCap = 'round';
      obj.context.lineJoin = "round";
    };

    /**
     * 写开始
     */
    this.writeBegin = function (point) {
      obj.isWrite = true;
      obj.lastWriteTime = new Date().getTime();
      obj.lastPoint = point;
      self.writeContextStyle();
    };

    /**
     * 写结束
     */
    this.writeEnd = function () {
      obj.isWrite = false;
    };

    /**
     * 清空画板
     */
    this.canvasClear = function () {
      obj.context.save();
      obj.context.strokeStyle = '#fff';
      obj.context.clearRect(0, 0, obj.canvasWidth, obj.canvasHeight);
      if (obj.isShowBorder && !obj.isWriteName) {
        obj.context.beginPath();
        let size = obj.borderWidth / 2;
        //画外面的框
        obj.context.moveTo(size, size);
        obj.context.lineTo(obj.canvasWidth - size, size);
        obj.context.lineTo(obj.canvasWidth - size, obj.canvasHeight - size);
        obj.context.lineTo(size, obj.canvasHeight - size);
        obj.context.closePath();
        obj.context.lineWidth = obj.borderWidth;
        obj.context.strokeStyle = obj.borderColor;
        obj.context.stroke();
        //画里面的框
        obj.context.moveTo(0, 0);
        obj.context.lineTo(obj.canvasWidth, obj.canvasHeight);
        obj.context.lineTo(obj.canvasWidth, obj.canvasHeight / 2);
        obj.context.lineTo(obj.canvasWidth, obj.canvasHeight / 2);
        obj.context.lineTo(0, obj.canvasHeight / 2);
        obj.context.lineTo(0, obj.canvasHeight);
        obj.context.lineTo(obj.canvasWidth, 0);
        obj.context.lineTo(obj.canvasWidth / 2, 0);
        obj.context.lineTo(obj.canvasWidth / 2, obj.canvasHeight);
        obj.context.stroke();

      }
      obj.context.restore();
    };

    /**
     * 保存图片 格式base64
     */
    this.saveAsImg = function () {
      let image = new Image();
      image.src = this.canvas.toDataURL("image/png");
      if (image.src === this.emptyCanvas) {

      } else {
        this.imgContent = image.src;
      }
    };

    /**
     * 初始化画板
     */
    this.canvasInit = function () {
      this.canvas.width = obj.canvasWidth;
      this.canvas.height = obj.canvasHeight;
      this.emptyCanvas = this.canvas.toDataURL("image/png");
    };

    // 若绑定事件  则不再绑定
    if (this.canvas.eventListeners().length > 0) {
      return;
    }
    /**======================事件绑定===========================**/

    this.canvas.addEventListener('mousedown', function (e) {
      let point = {
        x: e.offsetX || e.clientX,
        y: e.offsetY || e.clientY
      };
      self.writeBegin(point);
    });

    this.canvas.addEventListener('mouseup', function (e) {
      let point = {
        x: e.offsetX,
        y: e.offsetY
      };
      self.writeEnd(point);
    });

    this.canvas.addEventListener('mouseleave', function (e) {
      let point = {
        x: e.offsetX,
        y: e.offsetY
      };
      self.writeEnd(point);
    });

    this.canvas.addEventListener('mousemove', function (e) {
      if (obj.isWrite) {
        let point = {
          x: e.offsetX,
          y: e.offsetY
        };

        self.writing(point);
      }
    });

    //移动端
    this.canvas.addEventListener('touchstart', function (e) {
      let touch = e.targetTouches[0];
      let point = {
        x: touch.pageX || touch.clientX,
        y: touch.pageY - 110 || touch.clientY - 110
      };
      self.writeBegin(point);
    });
    this.canvas.addEventListener('touchend', function (e) {
      let touch = e.changedTouches[0];
      let point = {
        x: touch.pageX,
        y: touch.pageY - 110
      };
      self.writeEnd(point);
    });
    this.canvas.addEventListener('touchmove', function (e) {
      let touch = e.targetTouches[0];
      let point = {
        x: touch.pageX,
        y: touch.pageY - 110
      };
      self.writeEnd(point);
    });
    this.canvas.addEventListener('touchmove', function (e) {
      let touch = e.targetTouches[0];
      let point = {
        x: touch.pageX,
        y: touch.pageY - 110
      };
      self.writing(point);
    });

    this.canvasInit();
    this.canvasClear();

    this.option = obj;
    obj.control = {
      clearCanvas: self.canvasClear
    };
  }

  clearClick() {
    // this.writeCanvas.option.control.clearCanvas();
    this.canvasClear();
  }

  saveClick() {
    this.tabletModule.hide();
    this.saveAsImg();
    this.commitSign.emit(this.imgContent);
    this.imgContent = null;
    // if(this.submit){
    //   this.submit();
    // }
  }

  cclean(event: any) {
    event.stopPropagation();//阻止向上冒泡
  }

  ngAfterViewInit(): void {

    this.initPad();

  }

  initPad() {

    $("#canvas_div").on("touchmove", function (event) {
      event.preventDefault();
      event.stopPropagation();
    });

    /**
     * 初始化调用
     * 设置参数
     */
    this.writeCanvas = this.WriteFont('canvas', {
      borderWidth: 10,
      writeWidth: 3,
      borderColor: '#ff6666',
      isWriteName: true //签名模式
    });
  }
}
