<div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name"(click)="cclean($event)">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>打印预览</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="staticModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body" [ngStyle]="{height:canShow?'70vh':'100%'}">

        <div class="print-preview">
          <div class="frame-title">
          </div>
          <div class="frame-content ios-scroll">

            <app-pdf-view [src]="src" [temp]="temp" [height]="'60vh'"></app-pdf-view>

          </div>
          <div class="frame-footer text-center">
            <button *ngIf="canShow" class="btn btn-info" (click)="printFrame()">打印</button>
            <button class="btn btn-secondary ml-1" (click)="staticModal.hide()">取消</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>




