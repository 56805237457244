import { RoomInfoDTO } from "../room/room.model";
import {BatchRechargeRoomDTO} from "../booking/booking-detail.model";

/**
 * 预付账户消费清单
 */
export class RoomPrepayDTO {
  leas_id?: number;
  room_id?: number;
  prepay_type?: string;
  service_type?: string;
  total?: number;
  valid_total?: string;
  create_time?: number;
  remark?: string;
  pay_type_name?: string;
  amount_fee_dto?: AmountFeeDTO;
  room_info_dto?: RoomInfoDTO;
}

/**
 * 水电量及单价
 */
export class AmountFeeDTO {
  consume_amount?: string;
  fee?: string;
}

/**
 * 预付账户基本信息
 */
export class PrepayDetailDTO {
  lease_room_id?: number;
  prepay_total?: string;
  water_rate?: string;
  ele_rate?: string;
  lease_room_status?: string;
  room_info_dto?: RoomInfoDTO;
}

/**
 * 充值、提现 请求封装
 */
export class PrepayRechargeWithdrawReqeust {
  lease_id?: number;
  room_id?: number;
  amount?: string;
  pay_time?: number;
  credential_num?: string;
  pay_type_code?: string;
  remark?: string;
  cret_images?: string[];
  pay_log_no?: string;
  random_time?:number;
  random_token?:string;
  account_money_info_id?: number;
}

/**
 * 批量充值房间预付费 请求封装
 */
export class BacthRechargePrepayReqeust {
  lease_id?: number;
  //用于获取code:这里用每个房间的id用逗号拼接起来
  room_id?: string;
  //每个房间需要充值的金额
  batchRechargeRoomDTO?: Array<BatchRechargeRoomDTO>;
  //优惠券券号
  couponNumber?: string;
  //实际支付金额
  amount?: string;
  pay_time?: number;
  credential_num?: string;
  pay_type_code?: string;
  remark?: string;
  cret_images?: string[];
  pay_log_no?: string;
  random_time?:number;
  random_token?:string;
  account_money_info_id?: number;
}
