import { Pipe, PipeTransform } from '@angular/core';

/**
 * @Description: 证件类型翻译管道
 * @Author: 陆炳彬
 * @Date: 2018/3/29 上午10:24
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/3/29 上午10:24
 */
@Pipe({
  name: 'certificatePipe'
})
export class CertificatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const status = {
      ID_CD: '居民身份证',
      OFFICAL_CF: '军官证',
      POLICE_OFFICAL_CF: '武警军官证',
      RETIREMENT_CF: '退休证',
      CIVILIAN_CADRE_CF: '入住文职干部证审核',
      SOLDIER_CF: '士兵证',
      STUDENT_ID_CD: '学生证',
      HKMO_PASS_CF: '港澳居民往来内地通行证',
      TW_PASS_CF: '台湾居民往来大陆通行证',
      CHINA_GUIDE_CD: '中华人民共和国旅行证',
      OTHER: '其他',
    };
    return status[value];
  }
}
