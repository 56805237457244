import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {RequestService} from "../request.service";

@Injectable()
export class ImageQrcodeService{
  private IMAGE_QRCODE_PATH = `${environment.apiHost}image/qrcode/path`;
  private IMAGE_LEASE_IDENTIFICATION = `${environment.apiHost}image/lease/identification`;
  private IMAGE_QRCODE_INCOME_PATH = `${environment.apiHost}image/qrcode/income/path`;
  constructor(private requestService: RequestService) {
  }

  public qrcodePath(data:any): Promise<any> {
      return this.requestService.authGet(this.IMAGE_QRCODE_PATH,data);
  }

  public leaseIdentification(data:any):Promise<any>{
    return this.requestService.authGet(this.IMAGE_LEASE_IDENTIFICATION,data);
  }

  public qrcodeIncomePath(): Promise<any> {
    return this.requestService.authGet(this.IMAGE_QRCODE_INCOME_PATH,null);
  }

}
