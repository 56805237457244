import {CheckoutViewDTO} from "./checkout.model";

export class SignModel {

}

export class SignOnIpadRequest {
  sign_router_link_type?: string;
  router_link?: string;
  computer_no?: string;

  // 页面原始的填充数据
  response_data?: any;

  // 页面操作的数据
  operate_data?: any;

  sign_data_request?: SignDataRequest;

  status?: string;

}

// [退房] 操作
export class CheckOutListOperate {
  // 不收款原因
  noBill?: boolean;
  not_receivable_remark?: string;
  checkout_bill_pay_type_enum?: string;
  refund_name?: string;
  open_bank?: string;
  bank_no?; string;
  checkout_finance_type_enum?: string;
  refund_id?: number;
}

export class SignDataRequest {
  lease_id?: number;
  room_id?: number;
  lease_room_id?: number;
  record_id?: number;
  checkout_view_dtolist?: Array<CheckoutViewDTO>;
  info_id?: number;
  img_list?: string[];
}
