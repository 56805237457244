<div class="over15">
  <div>退房信息确认：</div>
  <div class="text-danger">退房须知：</div>
  <div class="text-danger">1、退房物品交接单签署提交即视为退房生效，房间门锁密码失效、水电停止继续扣费、房间租金停止计算。</div>
  <div class="text-danger mb-3">2、请仔细确认房间信息，避免发生误退、错退房间操作。</div>
  <!-- tbody超出15个tr就加  overflow-y:auto; -->
  <ng-container>
    <table class="table table-bordered">
      <thead class="thead-light">
      <tr>
        <th>房间号</th>
        <th>房型</th>
        <th>入住人</th>
        <th>合同结束日期</th>
        <th>退房日期</th>
        <th>退房方式</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let info of listData;let j = index">
        <td class="text-danger font-weight-bold fa-2x">{{info.room_name}}</td>
        <td>{{info.room_type_name}}</td>
        <ng-container *ngIf="info.renter_list">
          <td>
            <div *ngFor="let item of info.renter_list; let i=index">
              <span>{{item.name }}</span>
              <span>({{ item.sex == 'MALE' ? '男' : '女' }})</span>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="!info.renter_list">
          <td></td>
        </ng-container>
        <td>{{info.end_time| date:'yyyy.MM.dd'}}</td>
        <td class="text-danger font-weight-bold fa-2x">{{info.checkout_time| date:'yyyy.MM.dd'}}</td>
        <td>
          <select class=" w-100" (change)="canNoMoreFeeAudit($event,info.lease_room_id)">
            <option value="NORMAL">直接退房</option>
            <option value="SUBLEASE" *ngIf="info.end_time > info.checkout_time && leaseDetailResponse?.lease_step!='INTERNAL_LEASE'">转租退房</option>
          </select>
        </td>
      </tr>
      <tr *ngIf="listData.length == 0">
        <td colspan="6">暂无数据</td>
      </tr>
      </tbody>
    </table>
  </ng-container>
  <div>
    <div *ngIf="isShow">
      <div class="text-danger">此合同房间选择转租退房，完成退房后可至合同详情页头部按钮区域点击【转租签约】按钮继续完成转租。</div>
<!--      <div>-->
<!--        <div class="d-inline-block mr-3">-->
<!--          <span class="text-danger">*</span>-->
<!--          <span class="font-weight-bold">转租类型</span>-->
<!--        </div>-->
        <!--          <label class="mr-3" >-->
        <!--            <input type="radio" value="oneself" name="sex" (click)="radioChage(1)" >住客自行转租（将收取200元/间换房费用）-->
        <!--          </label>-->

        <!--          <label >-->
        <!--            <input type="radio" value="agency" name="sex" checked >管家代理转租（将收取300元/间换房费用）-->
        <!--          </label>-->
        <!--          <div *ngIf="isRadio" class="text-danger">请选择转租类型</div>-->
<!--      </div>-->
    </div>
  </div>

  <div style="clear:both;margin-top: 10px;" *ngIf="leaseDetailResponse?.lease_step!='INTERNAL_LEASE'">
    <div class="w200px ">
      <span class="font-weight-bold">退房原因:</span>
      <select class="w-100" [(ngModel)]="checkoutReson" (change)="checkOutRoom()">
        <option value="合同到期正常退房">合同到期正常退房</option>
        <option value="企业用工减少退房">企业用工减少退房</option>
        <option value="空房闲置退房">空房闲置退房</option>
        <option value="企业迁移退房">企业迁移退房</option>
        <option value="其他">其他</option>
      </select>
      <div *ngIf="isCheckout" class="text-danger">请选择原因</div>
    </div>
    <div class="w300px ml-1">
      <span>&nbsp;</span>
      <input class="w-100" [(ngModel)]="checkoutResonExt" type="text" placeholder="请备注具体原因(选择其他时为必填项)" maxlength="100"
             (change)="checkOutRoomInput()">
      <div *ngIf="isRemark " class="text-danger">具体原因不能为空</div>
    </div>
  </div>

</div>
