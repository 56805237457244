import {CretImageDTO} from "../common/common.model";
import {LeaseRoomInfoDTO} from "../lease/lease.model";
import {FreeTimeDTO} from "./shop-edit.model";


export class ShopPersonInfoDTO {
  name?: string;
  tel?: string;
  tel_conceal?:string;
  cret_type?: string;
  cret_num?: string;
  cret_num_conceal?:string;
  zip?: string;
  address?: string;
  trade?: string;
  brand?: string;
  emergency_contact_name?: string;
  emergency_contact_phone?: string;
}

export class ShopCompanyInfoDTO {
  name?: string;
  company_id?: number;
  company_name?: string;
  contacts?: string;
  tel?: string;
  zip?: string;
  address?: string;
  invoice_type?: string;
  invoice_title?: string;
  taxpayer_num?: string;
  bank_name?: string;
  card_number?: string;
  company_address?: string;
  trade?: string;
  brand?: string;
  emergency_contact_name?: string;
  emergency_contact_phone?: string;
}

/**
 * @Description: 转租租约信息
 * @Author: yangwen
 * @Date: 2019/6/24 14:23
 */
export class SubleaseInfoDTO {
  children_lease_id?: number;//下级租约(转租使用)
  children_contract_num?: string;//下级租约编号(转租使用)
  children_lease_status?: string;//转租租约状态

  subject_type?: string;
  person_info?: ShopPersonInfoDTO;
  company_info?: ShopCompanyInfoDTO;
  image_url_list?: Array<string>;
}

export class ShopLeaseInfoDTO {
  lease_id?: number;//租约/预定单ID
  children_lease_id?: number;//下级租约(续租使用)
  children_contract_num?: string;//下级租约编号(续租使用)
  children_lease_status?: string;//续租租约状态
  sublease_info_dtos?: SubleaseInfoDTO[];//转租租约列表信息
  parent_lease_id?: number;//被续租的租约ID
  parent_contract_num?: string;//被续租约的编号
  contact_lease_id?: number;//预定转合同后的合同ID
  reserve_lease_id?: number;//预约ID
  booking_lease_id?: number;//预定ID
  lease_type?: string;//当前租约是什么租约,ORIGIN(原生)、RELTE(续租)、EXCHANGE(换房)
  contract_num?: string;//合同号
  renter_type?: string;//租客类型 SINGLE:个人  COMPANY:企业
  source_id?: number;//渠道ID
  source_name?: string;//渠道名称
  sale_name?: string;//销售名称
  lease_status?: string;//预定单/合同状态
  audit_status?: string;//审核状态 WAITING_AUDIT, AUDIT_SUCCESS, AUDIT_FAIL
  cancel_booking?: boolean;//是否可以取消预定单
  check_out?: boolean;//是否可以退房
  check_in?: boolean;//是否可以办理入住
  change_room?: boolean;//是否可以换房
  relte?: boolean;//是否可以续租
  change_to_lease?: boolean;//是否可以转化成租约
  need_down_payment?: boolean;//是否主要预定金
  remark?: string;//备注
  room_num?: number;//房间总数
  waiting_check_num?: number;//待入住房间数量
  check_in_num?: number;//已入住房间数量
  refund_num?: number;//退房数量
  remind_value?: number;//醒管家催租天数
  msg_send_limit?: number;//短信发送余额限制
  electric_stop_limit?: number;//停电余额数
  attached_list?: CretImageDTO[];//凭证信息
  lease_subject_info_dto?: LeaseSubjectInfoDTO;//预定人信息
  reserve_contact_info_dto?: ReserveContactInfoDTO;//合同/预定单信息
  lease_room_info_dtos?: LeaseRoomInfoDTO[];//租约房间信息
  contact_same?: boolean;//合同联系人是否和签约人相同
  is_free?: boolean; //是否免租优惠
  free_start_time?: Date;//免租优惠开始日期
  free_end_time?: Date;//免租优惠结束日期
  grace_period_days?: number;
  outstanding_amount_percent?: number;//逾期金比率
  audit_position_name?: string; //审核的岗位
  business_status?: string; // 租约的业务状态
  business_status_desc?: string; // 租约的业务状态
  can_sublease?: boolean; // 能否转租
  can_refund_booking?: boolean; //能否退预定金
  booking_bill_amount?: string; // 已付的预定金金额
  create_time?: Date;

  subject_info_dto?: SubleaseInfoDTO;
  start_time?: number;
  end_time?: number;
  pay_cycle?: number;
  deposit?: string;
  rent?: string;
  manage_fee?: string;
  build_area?: number;
  use_area?: number;
  area_type?: string;
  shop_name?: string;
  adviser_id?: string;
  free_time_list?: Array<FreeTimeDTO>;
  sign_checkout?: boolean;
  rent_bill_exist?: boolean;
  shop_lease_relation_list?: Array<ShopLeaseRelationDTO>;
  shop_lease_relation_size?: number;
  lease_log_num?: number;
}

/**
 * @Description:  账单详情响应model
 * @Author: zhoujiahao
 * @Date: 2018-07-10  17:01
 */
export class ShopLeaseDetailResponse {

  shop_lease_info_dto?: ShopLeaseInfoDTO;
}

/**
 * @Description:   预定人信息
 * @Author: zhoujiahao
 * @Date: 2018-07-10  18:01
 */
export class LeaseSubjectInfoDTO {
  subject_type?: string;//主题类型
  name?: string;//联系人名称
  tel?: string;//联系人手机号
  cret_type?: string;//证件类型
  cret_num?: string;//证件号
  zip?: string;//邮编
  address?: string;//联系人地址
  company_name?: string;//公司名称
  invoice_type?: string;//发票类型（NO_NEED 不需要发票，NORAML_INVOICE 普通发票，VAT_INVOICE 增值税发票)
  invoice_title?: string;//发票抬头
  taxpayer_num?: string;//纳税人编号
  card_number?: string;//银行账号
  bank_name?: string;//开户银行
  company_address?: string;//企业地址
  sex?: any;//性别
}

/**
 * @Description:  合同/预定单信息
 * @Author: zhoujiahao
 * @Date: 2018-07-10  18:02
 */
export class ReserveContactInfoDTO {
  start_time?: Date;//租约开始时间
  end_time?: Date;//租约结束时间
  pay_cycle?: number;//支付周期(月为单位 季度支付为4 年为12 )
  effect_time?: Date;//预定单转租约时间
  contact_name?: string;//联系人名称(同预定人/同签约人)
  contact_phone?: string;//联系人手机号（同预定则不显示此项）
  urgent_name?: string;//紧急联系人
  booking_amount?: string;//预定金
  to_lease_date?: number;//转租约时间
  emergency_contacts?: EmergencyContactInfoDTO[];//紧急联系人
}

/**
 * 紧急联系人
 */
export class EmergencyContactInfoDTO {
  contact_name?: string;//紧急联系人名称
  contact_phone?: string;//紧急联系人手机号
}

export class ShopLeaseRelationDTO {
  id?:number;
  shop_id?: number;
  shop_name?: string;
  build_area?: number;
  use_area?: number;
  deposit?: string;
  rent?: string;
  manage_fee?: string;
  rent_price_list?: Array<ShopLeasePriceResponseDTO>;
  manage_fee_list?: Array<ShopLeasePriceResponseDTO>;
  checkout_shop_room_num?: string;
  checkout_date_time?: Date;
  rent_type?: string;
  manage_fee_type?: string;
}

//商铺缓存
export class ShopRelationListCache {
  id:number;
  shop_id: number;
  shop_name: string;
  build_area: number;
  use_area: number;
  deposit: string;
  rent: string;
  manage_fee: string;
  rent_price_list: ShopLeasePriceResponseDTO[] = [];
  manage_fee_list: ShopLeasePriceResponseDTO[] = [];
  custom_rent_list_filled: boolean = false;
  manage_rent_list_filled: boolean = false;
  rent_type?: string;
  manage_fee_type?: string;
  constructor() {
  }
}

export class ShopLeasePriceResponseDTO {
  start_month?: number;
  end_month?: number;
  fee?: string;

  constructor(month?: number, end_month?: number, custom_rent?: string) {
    this.start_month = month;
    this.end_month = end_month;
    this.fee = custom_rent;
  }
}

export class ShopRentInfoWithIndex {
  index: number;
  rent_list: ShopLeasePriceResponseDTO[];

  constructor(index: number, rent_list: ShopLeasePriceResponseDTO[]) {
    this.index = index;
    this.rent_list = rent_list;
  }
}

export class ShopLeaseRelationResponse{
  shop_lease_relation_dtolist?:Array<ShopLeaseRelationDTO>;
}

export class LeaseQueryRequest{
  lease_id?:number;
}
