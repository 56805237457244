import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RequestService } from '../request.service';
import { ComplaintsDealRequest, ComplaintsDetailRequest, ComplaintsInfoDTO, ComplaintsListRequest, ComplaintsListResponse, ComplaintsLogListResponse } from '../../model/complaints/complaints.model';

@Injectable()
export class ComplaintsService {

  //获取投诉列表
  private COMPLAINTS_LIST = `${environment.apiHost}complaints/list`;
  //获取投诉详情
  private COMPLAINTS_DETAIL = `${environment.apiHost}complaints/detail`;
  //投诉处理
  private COMPLAINTS_DEAL = `${environment.apiHost}complaints/deal`;
  //投诉日志信息
  private COMPLAINTS_LOG_LIST = `${environment.apiHost}complaints/log/list`;

  constructor(private requestService: RequestService) {
  }

  /**
   * @description: 投诉列表
   * @Author: kaungye
   * @Date: 2018-08-28
   * @Last Modified by: kuangye
   * @Last Modified time: 2018-08-28
   */
  list(req: ComplaintsListRequest): Promise<ComplaintsListResponse> {
    return this.requestService.authGet(this.COMPLAINTS_LIST, req);
  }
  listWithLoading(req: ComplaintsListRequest): Promise<ComplaintsListResponse> {
    return this.requestService.authGet(this.COMPLAINTS_LIST, req, true);
  }

  /**
   * @description: 投诉详情
   * @Author: kaungye
   * @Date: 2018-08-28
   * @Last Modified by: kuangye
   * @Last Modified time: 2018-08-28
   */
  detail(req: ComplaintsDetailRequest): Promise<ComplaintsInfoDTO> {
    return this.requestService.authGet(this.COMPLAINTS_DETAIL, req);
  }

  /**
   * @description: 投诉处理
   * @Author: kaungye
   * @Date: 2018-08-28
   * @Last Modified by: kuangye
   * @Last Modified time: 2018-08-28
   */
  handle(req: ComplaintsDealRequest): Promise<any> {
    return this.requestService.authPost(this.COMPLAINTS_DEAL, req);
  }

  /**
   * @description: 投诉日志
   * @Author: kaungye
   * @Date: 2018-08-28
   * @Last Modified by: kuangye
   * @Last Modified time: 2018-08-28
   */
  log(req: ComplaintsDetailRequest): Promise<ComplaintsLogListResponse> {
    return this.requestService.authGet(this.COMPLAINTS_LOG_LIST, req);
  }

}
