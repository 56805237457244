import { PageReq, PageResp } from "../page/page";

export class CouponBatchInfoDTO {
  // 批次ID
  coupon_batch_info_id: number;
  // 类型
  coupon_batch_type: string;
  coupon_batch_name: string;
  // 业务范围
  coupon_batch_service: string;
  //可领用户
  target_user_type?: string;
  // 面额
  total_value: string;
  // 最小使用金额
  least_use_total: string;
  //已领数量
  voucher_sum:number;
  // 总张数
  coupon_sum: number;
  // 已核销张数
  verification_sum: number;
  // 状态
  status: string;
  type:string;
  effect_time:number;
  // 有效开始时间
  start_time: Date;
  // 有效结束时间
  end_time: Date;
  // 创建时间
  create_time: Date;
  // 备注
  remark: string;
  // 项目ID
  project_id: number;

  last_modify_id: number;

  create_user_Id: number;

  has_share: number;
  use_descs: string;
  max_own_sum: number;
  max_get_sum: number;
}

export class CouponInfoDTO {
  // 优惠券id
  coupon_info_id: number;

  // 业务状态
  service_status: number;

  // 领取时间
  binding_time: Date;

  // 使用时间
  use_time: Date;

  // 绑定用户名称
  binding_client_username;
  // 绑定用户手机号
  binding_client_user_tel;

  // 券号
  coupon_number: string;

  // 券回收金额
  recycle_value: number;

  // 租约号
  related_lease_contract_num: string;

  // 租约id
  related_lease_info_id: number;

  // 房间名
  room_name: string;
}

export class CouponManageListResponse extends PageResp {
  coupon_batch_info_list: CouponBatchInfoDTO[];
}

export class CouponManageListRequest extends PageReq {
  coupon_batch_type: string;
  coupon_batch_service: string;
  coupon_status: string;
}

// 优惠券批次详情req
export class CouponBatchInfoReq {
  coupon_batch_id: number;

  constructor(coupon_batch_id: number) {
    this.coupon_batch_id = coupon_batch_id;
  }
}

// 优惠券详情resp
export class CouponBatchInfoResp {
  coupon_batch_info: CouponBatchInfoDTO;
  binding_sum: number;
  use_amount: number;
  recycle_amount: number;
  verification_sum: number;
  recycle_sum: number;
}

// 优惠券列表请求
export class CouponInfoListReq extends PageReq {
  coupon_batch_id: number;
  coupon_number: string;
}

// 优惠券列表返回
export class CouponInfoListResp extends PageResp {
  coupon_info_list: CouponInfoDTO[];
}

// 优惠券日志
export class CouponBatchLogDTO {
  coupon_batch_log_id: number;
  content: string;
  coupon_batch_info_id: number;
  operation_user_id: number;
  operation_username: string;
  create_time: Date;
}

// 优惠券日志
export class CouponShareInfoResp {

  user_name: string;

  coupon_name: string;

  coupon_share_link: string;

  qr_code_linke: string;

}

// 优惠券日志列表请求
export class CouponBatchLogListReq extends PageReq {
  coupon_batch_id: number;

  constructor(coupon_batch_id: number) {
    super();
    this.coupon_batch_id = coupon_batch_id;
  }
}

// 优惠券日志列表返回
export class CouponBatchLogListResp extends PageResp {
  coupon_batch_log_list: CouponBatchLogDTO[];
}

export class CouponInfoBizRelationDTO {
  room_name: string;
  recharge_amount: number;
  used_amount: number;
  transfer_amount: number;
  bill_start_time: Date;
  bill_end_time: Date;
  order_master_id: string;
  products: string;
}

// 优惠券业务关联列表请求
export class CouponInfoBizRelationListReq {
  coupon_info_id: number;

  constructor(coupon_info_id: number) {
    this.coupon_info_id = coupon_info_id;
  }
}

// 优惠券业务关联列表返回
export class CouponInfoBizRelationListResp {
  coupon_info_biz_relation_list: CouponInfoBizRelationDTO[];
  biz_type: string;
}

// 优惠券详情请求
export class CouponInfoDetailByCouponNumberReq {
  coupon_number: string;
  receivable_total: string;
  coupon_batch_service: string;
  constructor(coupon_number: string, receivable_total: string, coupon_batch_service: string) {
    this.coupon_number = coupon_number;
    this.receivable_total = receivable_total;
    this.coupon_batch_service = coupon_batch_service;
  }
}

// 优惠券详情返回
export class CouponInfoDetailByCouponNumberResp {
  coupon_info: CouponInfoDetailDTO;
  least_use_total: string;
  error_msg: string;
}

export class CouponInfoDetailDTO {

  coupon_info_id: number;

  service_status: string;

  coupon_batch_type: string;

  discount_ratio: string;

  binding_time: Date;

  binding_client_user_id: number;

  create_coupon_user_id: number;

  coupon_number: string;

  total_value: number;

  recycle_value: number;

  coupon_batch_info_id: number;
}

export class CouponInfoModel {
  coupon_info_id: string;
}

export class CouponRoomByBatchResponse {

}

export class CouponRoomByBatchRequest {
  coupon_batch_type: string;
  coupon_batch_service: Array<string>;
  coupon_status: string;
  coupon_batch_name: string;
  has_threshold: boolean;
  rule_json: CouponRuleModel;
  has_share: number;
  max_own_sum: number;
  max_get_sum: number;
  use_projects: Array<number>;
  use_desc: string;
  total_value: number;
  least_use_total: number;
  start_time: number;
  end_time: number;
  coupon_sum: number;
  app_type: string;
  img_url: string;
  effect_time: number;
  type: string;
  target_user_type: string;
  idempotent_key?:string;
}

export class CouponRuleModel {
  is_use_agian: boolean;
  is_expired_agian: boolean;
}

export class CouponInfoRechargeReq {
  lease_id: number;

  room_id: number;

  coupon_number: string;
}

// 获取领券人用户信息请求参数
export class QueryGetCouponUserInfoReq {
  coupon_batch_id: number;
  user_phone: string;
}

// 获取领券人用户信息返回参数
export class QueryGetCouponUserInfoResp {
  user_id: number;
  user_name: string;
  room_name: string;
  project_name: string;
  has_distribution: boolean;
  reason: string;
}

export class CouponInfoRechargeResp {
  error_msg: string;
}
