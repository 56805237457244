import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NzUploadBtnComponent } from './nz-upload-btn.component';
import { NzUploadListComponent } from './nz-upload-list.component';
import { NzUploadComponent } from './nz-upload.component';
import {PlatformModule} from "@angular/cdk/platform";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {NzProgressModule} from "ng-zorro-antd/progress";
import {NzI18nModule} from "ng-zorro-antd/i18n";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzButtonModule} from "ng-zorro-antd/button";

@NgModule({
  imports: [CommonModule, FormsModule, PlatformModule, NzToolTipModule, NzProgressModule, NzI18nModule, NzIconModule, NzButtonModule],
  declarations: [NzUploadComponent, NzUploadBtnComponent, NzUploadListComponent],
  exports: [NzUploadComponent]
})
export class CustomNzUploadModule {
}
