import {RoomInfoDTO} from "../room/room.model";
import {RenterCreateBillDTO} from "./finance.model";
import {PageReq, PageResp} from "../page/page";

//房间下拉查询参数
export class RoomListParam {
  lease_id?: number;
  lease_room_status?: string[];
}

//租约账单/支付流水  查询参数
export class LeaseRoomBillListParam {
  lease_id?: number;
  bill_no?: string;
  room_id?: number;
  bill_status?: string;
  page_size?: number;
  page_num?: number;
  bill_type_id?: number;

  constructor(lease_id: number, bill_no: string, room_id: number, bill_status: string, page_size: number, page_num: number) {
    this.lease_id = lease_id;
    this.bill_no = bill_no;
    this.room_id = room_id;
    this.bill_status = bill_status;
    this.page_size = page_size;
    this.page_num = page_num;
  }
}

/**
 * @Description:  收款详情请求参数
 *  收款类型
 * * 1 otherExpense 其它费用
 *
 * 2 renterExpense   房费、押金、水电等
 *
 * 3 firstRenterDepositExpense  首期房费+押金+
 *
 * 4  firstRenterExpense 首期房费
 *
 * 5  depositExpense 押金
 *
 * 6   tips  提示文案
 * @Author: zhoujiahao
 * @Date: 2018-07-13  16:55
 */
export class GatherAmountDetailRequestParam {
  lease_id?: number;//租约ID
  room_ids?: Array<number>;//房间ID
  gather_type?: string;//收款类型
  bill_type_ids?: Array<number>;
  refundable_bill?: string;
  bill_charge_off_status?: string;  // 账单状态 已出;待出
  payer_type?: string; // 付款人类型
}

/**
 * @Description:  确认付款 参数
 * @Author: zhoujiahao
 * @Date: 2018-07-13  16:55
 */
export class ConfirmPaymentRequest {
  lease_id?: number;//租约ID
  room_ids?: Array<number>;//房间ID
  lease_room_ids?: Array<number>;
  payment_total_amount?: string;//应付总金额 （如果选择了预定金的话则应该减去预定金的钱）
  deductible?: boolean;//预定金是否抵扣
  bill_ids?: Array<number>;//账单ID （未支付和部分支付）
  type_id?: number;//付款类型
  actual_payment_amount?: string;//实际支付金额
  attached_list?: Array<string>;//附件
  remark?: string;//备注： 其它收款时必输
  book_amount?: string;//预定金金额
  gather_type?: string;//收款类型
  pay_log_no?: string;//支付流水号
  bill_type_code?: string;//当收款类型为 其它类型时此字段不能为空： 水费，电费，其它（备注不能为空）
  bussiness_type?: string;//退房收款时增加的参数：用户辨别当前收款时退房收款目前值为 CHECKOUT
  checked_overdue_amount?: any;
  coupon_info_id?: number;
  recycle_coupon_amount?;// 房费优惠券金额
  idempotent_key?:string;
  account_money_info_id?: number;
  checkout_id;
  renter_info_id?: number;
  // 分摊费用账单id
  renter_bill_ids?: Array<number>;
}

/**
 * @Description:  创建账单 参数
 * @Author: 赵路
 * @Date: 2021-02-02  16:55
 */
export class CreateBillRequest {
  leaseId?:number; //租约Id
  room_ids?:Array<number>; //房间号
  start_time?:number;
  end_time?:number;
  actual_payment_amount ?: string; //账单金额
  remark?:string; //备注
  billTypeCode ?: string; //账单类型
  parking_type?:string; //停车类型
  plate_number?:string; //车牌号
  idempotent_key?:string;
  payer_type?: string;
  renter_create_bills?: Array<RenterCreateBillDTO>;
}

/**
 * 已支付、部分支付账单退款申请
 */
export class RefundApplyRequest extends ConfirmPaymentRequest {
  gather_value?: string;
  bank_name?: string;
  card_number?: string;
  audit_phone?: string;
  audit_code?: string;
  mode_of_payment?: string;
  carport_ids?: Array<number>;
  apply_type?: string;
}

//账单详情参数
export class LeaseRoomBillDetailParam {
  bill_id?: number;//账单ID
  lease_id?: number;//合同ID
  constructor(bill_id: number, lease_id: number) {
    this.bill_id = bill_id;
    this.lease_id = lease_id;
  }
}

export class ShowParam {
  type?: string;
  id?: number;

  constructor(id: number, type: string) {
    this.id = id;
    this.type = type;
  }
}

export class NoContractBillListRequest {
  search_content?: string;
  start_time?: any;
  end_time?: any;
  page_size?: number;
  page_num?: number;
}

//支付流水列表 - 核销
export class PayLogListRequest {
  create_user?: string;//提交管家姓名
  audit_status?: string;//核销状态
  audit_total?:number;
  room_name?: string;
  lease_type?: string;
  page_size?: number;
  page_num?: number;
  contract_num?: string;
  subject_name?: string;
  commit_start_time?:string;
  commit_end_time?:string;
}

//房间下拉列表返回对象
export class RoomListResponse {
  room_info_list ?: RoomInfoDTO[];
}

//租约账单对象
export class LeaseRoomBillListDTO {
  bill_id?: number;
  bill_type_id?: number;
  bill_type_name?: string;
  room_id?: number;
  room_name?: string;
  bill_amount?: string;
  bill_start_time?: Date;
  bill_end_time?: Date;
  plan_pay_time?: Date;
  received?: string;
  // 可退金额
  can_refund?: string;
  waiting_pay_amount?: string;
  remark?: string;
  lease_id?: number;
  bill_no?: string;
  //账单支付状态
  bill_status?: string;
  //账单支付状态明文
  bill_status_name?: string;
  contract_num?: string;
  //账单状态 出账/待出
  bill_charge_off_status;
  bill_charge_off_status_name;
  //是否免租优惠
  is_free?: boolean;
  free_day?: number;
  bill_biz_type?: string;
  parent_bill_id?: number;
  parent_bill_no?: string;
  parent_bill_type_name?: string;
  overdue_amount?: any;
  in_audit?: boolean;
  selected?: boolean = false;
  change_amount?: string;
  lease_room_id?: number;
  // @Remark("创建类型，AUTO:自动生成 ADD:人工手动添加")
  create_type:string;
  // @Remark("手动创建的账单显示谁在什么时间创建")
  create_explain:string;
  // @Remark("是否为后付费账单,目前只有水电费支持")
  later_bill:boolean;
  // 付款方
  pay_user: string;
  // 分摊费用账单id
  renter_went_dutch_bill_id?: number;
  // 费用分摊总金额
  share_amount?: string;
  // 签约人应付总金额
  subject_pay_amount?: string;
  // 组合id
  combine_id?: string;
  // 是否分摊费用
  went_dutch_bill?: boolean;
}

//账单预览对象
export class LeaseRoomPreviewBillListDTO {
  bill_type_name?: string;
  room_id?: number;
  room_name?: string;
  bill_amount?: string;
  startTime_obj?: any;
  endTime_obj?: any;
}

export class RenterPreviewBillListDTO extends LeaseRoomPreviewBillListDTO {
  bill_type_code?: string;
  renter_name?: string;
  remark?: string;
  renter_id?: number;
}

//创建账单选择房间对象
export class RoomInfoModel {
  roomId:number;
  roomName:String;
}

//支付流水对象
export class PayLogListDTO {
  pay_id?: number;
  lease_type?: string;
  pay_no?: string;
  pay_time?: Date;
  pay_type_name?: string;
  pay_amount?: string;
  pay_user?: string;
  create_time?: Date;
  create_user?: string;
  audit_status?: string;
  audit_status_name?: string;

  room_name?: string;
  renter_name?: string;
  range_time?: string;
  data_type?:string;
  certificate_url?:string;
  bill_count?:String;
  bill_total?:String;
  credential_num?:string;
}

//租约账单返回对象
export class LeaseRoomBillListResponse {
  lease_room_bill_list?: LeaseRoomBillListDTO[];
  pay_log_list?: PayLogListDTO[];
  arrearages?: string;
  total?: number;
}

/**
 * @Description: 首期房费+押金， 押金，首期房费 收款也数据展示
 * @Author: zhoujiahao
 * @Date: 2018-07-13  14:56
 */
export class PaymentInfoDTO {
  payment_total_amount?: string;//应付总金额
  book_amount?: string;//预定金金额
  deposit?: string;//押金
  first_renter_expense?: string;//首期房费
  paymented_amount?: string;//已收取金额
  bill_ids?: Array<number>;//账单ID （未支付和部分支付）
  lease_room_bill_list?: LeaseRoomBillListDTO[];
}

//账单财务明细对象
export class BillPayLogDTO {
  //支付时间
  pay_time?: Date;
  //账单类型
  bill_type?: string;
  //支付类型
  pay_type?: string;
  //支付金额
  pay_amount?: string;
  //支付来源类型  支付流水PAY_LOG/预定金抵扣BOOKING
  source_type?: string;
  //支付来源类型  支付流水/预定金抵扣
  source_type_name?: string;
  //支付来源类型 ID
  source_id?: number;
  //流水号
  source_no?: string;
  //操作人
  create_name?: string;
  //是否关联账单
  relate_bill?: boolean;
  //来源账单ID
  source_bill_id?: number;
  // 付款方
  pay_user?: string;
}

//账单财务明细 返回对象
export class BillPayLogListResponse {
  bill_pay_log_list?: BillPayLogDTO[];
  received?: string;
}

//支付流水详情
export class PayLogDTO {
  //支付流水ID
  pay_id?: number;
  //租约类型
  contract_lease_type?: string;
  //合同ID
  lease_id?: number;
  //交易金额
  pay_amount?: string;
  //交易时间
  pay_time?: Date;
  //付款方式
  pay_type?: string;
  //收款人 = 创建人
  create_user?: string;
  //所属合同
  contract_num?: string;
  //凭证号
  credential_num?: string;
  //相关附件
  files?: string[];
  //核销状态
  audit_status?: string;
  //核销人
  audit_user?: string;
  //核销时间
  audit_time?: Date;

  audit_status_name?: string;

  lease_type?: string;
  // 支票是否已经收到
  check?: string;
  // 是否是支票付款
  is_check_pay?: boolean;
  // 房间号列表
  room_num_list?: string[];
  // 到账时间
  manual_account_time?: Date;
}

//支付流水详情账单明细
export class PayBillDetailDTO {
  //账单ID
  bill_id?: number;
  //账单流水号
  bill_no?: string;
  //账单类型
  bill_type?: string;
  //支付金额
  pay_amount?: string;
  //账单开始时间
  bill_start_time?: Date;
  //账单结束时间
  bill_end_time?: Date;
  //备注
  remark?: string;
}

//支付流水详情账单明细返回
export class PayBillListResponse {
  pay_bill_list?: PayBillDetailDTO[];
}

//支付流水列表 核销 返回对象
export class PayLogListResponse {
  pay_log_list?: Array<PayLogListDTO>;
  total?: number;
}

/**
 * @Description: 核销请求
 * @Author: 陆炳彬
 * @Date: 2018/9/25 下午4:25
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/9/25 下午4:25
 */
export class AuditPayLogRequest {
  // 收款记录id
  pay_id: number;
  // 支票是否已经收到
  is_checked: boolean;
  // 是否已经核销
  is_pay: boolean;
  // 是否未到账
  is_not_posted: boolean;
  // 流水号
  pay_log_no: string;
  password?: string;
  manual_account_time: number;
  pay_list?:AuditPayBatch[];
}

export class AuditPayBatch{
  pay_id :number;
  credential_num;
}

export class NoContractBillDTO {
  bill_id?: number;
  bill_no?: string;
  bill_type_name?: string;
  bill_amount?: string;
  remark?: string;
  create_name?: string;
  pay_time?: number;
  amount_check_time?: number;
}


export class LeaseIsRenewalRequest {
  lease_id?: number;
}
export class LeaseIsRenewalResponse {
  renewal?: boolean;
  message?: string;
}

export class LeaseBillChangeDTO {
  bill_id?: number;
  bill_amount?: string;
  change_amount?: string;
}

export class LeaseBillChangeRequest {
  lease_bill_change_dtolist?: Array<LeaseBillChangeDTO> ;
  lease_id?: number;
  // @Remark("变更原因")
  change_reason?: string;

  imgs?: Array<string>;
}

export class LeaseRenterBillListRequest extends PageReq{
  bill_type_code?: string;

  pay_status?: string;

  lease_id?: number;

  room_id?: number;
}

export class LeaseRenterBillListResponse extends PageResp{
  lease_renter_bill_list_dtos?: Array<LeaseRenterBillListDTO>;

  arrears?: string;
}

export class LeaseRenterBillListDTO{
  bill_id?: number;

  project_name?: string;

  room_name?: string;

  bill_type_name?: string;

  bill_type_code?: string;

  bill_start_time?: number;

  bill_end_time?: number;

  bill_status?: string;

  bill_status_name?: string;

  bill_biz_type?: string;

  bill_amount?: string;

  actual_bill_amount?: string;

  //@Remark("合同编号")
  contract_num?: string;

  //@Remark("账单编号")
  bill_no?: string;

  //@Remark("楼栋号")
  unit_name?: string;

  //@Remark("房间号")
  room_num?: string;

  //@Remark("已付/退金额")
  received?: string;

  //@Remark("出账日期")
  pay_out_time?: number;

  //@Remark("应收/付日期")
  plan_pay_time?: number;

  //@Remark("付/退款时间")
  pay_time?: number;

  renter_went_dutch_bill_id?: number;

  renter_name?: string;

  cert_code?: string;
}

export class RenterBillDetailRequest{
  bill_id?: number;
  renter_went_dutch_bill_id?: number;
}

export class RenterBillDetailResponse{
  contract_num?: string;

  lease_id?: number;

  room_name?: string;

  remark?: string;

  bill_type_name?: string;

  bill_type_id?: number;

  bill_no?: string;

  bill_id?: number;

  bill_amount?: string;

  plan_pay_time?: number;

  bill_start_time?: number;

  bill_end_time?: number;

  renter_free_start_time?: number;

  renter_free_end_time?: number;

  //@Remark("是否是分摊费用")
  average_fee_bill?: boolean;

  //@Remark("是否是住客押金")
  renter_deposit?: boolean;

  //@Remark("住客押金转入或转出")
  deposit_source_type?: string;

  //@Remark("住客押金转入或转出房间")
  deposit_source_room?: string;

  //@Remark("住客押金转入或转出租约id")
  deposit_source_lease_id?: number;

  //@Remark("住客押金转入或转出合同号")
  deposit_source_contract_num?: string;

  //@Remark("退款住客押金关联收款账单")
  parent_bill_no?: string;

  //@Remark("退款住客押金关联收款账单id")
  parent_bill_id?: number;

  bill_status?: string;
  bill_biz_type?: string
}

export class DepositReceiptImgRequest{
  bill_id?: number;
}

export class DepositReceiptImgResponse{
  deposit_receipt_img_url?: string;
}

export class RenterBillDetailListRequest extends RenterBillDetailRequest{

}
