import {Component, EventEmitter, Input, OnInit, Optional, Output, SkipSelf, ViewChild} from '@angular/core';
import {LeaseCustomRentDTO, LeaseExpenseInfoDTO, LeaseRoomInfoDTO, RenterInfo} from "../../../model/lease/lease.model";
import {LeaseService} from "../../../service/lease/lease.service";
import {LogService} from "../../../service/log/log.service";
import {RegularExp} from "../../../common/util.regexp";
import {RoomInfoDTO} from "../../../model/room/room.model";
import {
  ConfirmPaymentRequest,
  CreateBillRequest,
  GatherAmountDetailRequestParam,
  LeaseRoomBillListDTO,
  LeaseRoomPreviewBillListDTO,
  RoomInfoModel,
  PaymentInfoDTO,
  RefundApplyRequest, RenterPreviewBillListDTO
} from "../../../model/finance/bill.model";
import {WorkModel} from "../../../model/common/common.model";
import {BookingService} from "../../../service/booking/booking.service";
import {WorkService} from "../../../service/common/work.service";
import {BillService} from "../../../service/finance/bill.service";
import {BaseEnum} from "../../../model/modal/base-model";
import {MessageModalInfo} from "../../../model/modal/message-model";
import {removeFromArray, removeFromStrArray} from "../../../common/array.util";
import {FinanceService} from "../../../service/finance/finance.service";
import {OrderService} from "../../../service/order/order.service";
import {OrderInfoDTO} from "../../../model/order/order.model";
import {PayLogDetailComponent} from "../../finance/pay-log/pay-log-detail.component";
import {Decimal} from "decimal.js";
import {SysUser} from "../../../model/user/user.model";
import {SignedService} from "../../../service/signed/signed.service";
import {SMSAuditCodeRequest} from "../../../model/signed/checkout.model";
import {BaseInfoService} from "../../../service/common/base-info.service";
import {BillTypeInfo, RenterCreateBillDTO} from "../../../model/finance/finance.model";
import {commonGroupBy} from "../../../common/common";
import {RefundListService} from "../../../service/refund/refund.service";
import {CouponInfoDetailByCouponNumberReq, CouponInfoDetailDTO} from "../../../model/coupon/coupon.model";
import {CouponService} from "../../../service/coupon/coupon.service";
import {BatchRechargeRoomDTO, LeaseDetailResponse} from "../../../model/booking/booking-detail.model";
import {BacthRechargePrepayReqeust} from "../../../model/prepay/preapy.model";
import {PrepayService} from "../../../service/prepay/prepay.service";
import {IdempotentService} from "../../../service/idempotentService/IdempotentService";
import {addDate, formatYYYYmmdd, getDateFromPlugin, getTimeFromPlugin} from "../../../common/date.util";
import {IMyDpOptions} from "../../third-party/mydatepicker/src/lib/interfaces";
import {ModalDirective} from "ngx-bootstrap/modal";
import {ClientInfoService} from "../../../service/clientinfo/ClientInfoService";
import {AccountMoneyListDTO, QueryAccountLeaseRequest, QueryAccountLeaseResponse} from "../../../model/clientinfo/client-info.model";
import {QueryAllRenterListReq, RenterListDTO} from "../../../model/renter/renter.model";
import {RenterService} from "../../../service/renter/renter.service";

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
  startTime_obj: any;
  endTime_obj: any;
  isShow: boolean = false;
  now: Date = new Date();
  tomorrow = addDate(this.now,1);
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: "yyyy-mm-dd",
    editableDateField: true,
    showClearDateBtn: true
  };
  public startDatePickerOptions: IMyDpOptions = {
    dateFormat: "yyyy-mm-dd",
    editableDateField: true,
    showClearDateBtn: true,
  };
  public endDatePickerOptions: IMyDpOptions = {
    dateFormat: "yyyy-mm-dd",
    editableDateField: true,
    showClearDateBtn: true,
  };

  @ViewChild(PayLogDetailComponent, {static: true}) payLogDetailComponent: PayLogDetailComponent;
  //租约房间信息payment_total_amount
  @Input() leaseRoomInfoDTO: LeaseRoomInfoDTO;
  //租约ID
  @Input() leaseId: number;
  //房间ID
  @Input() roomId: number;
  //房间租约ID
  @Input() leaseRoomId: number;

  @Input() recordId: number;

  @Input() type: number;

  @Output() callback: EventEmitter<any> = new EventEmitter<any>();

  // 账单关联操作
  orderRelevance = false;

  // 1 每月租金
  @ViewChild('monthRent', {static: true}) detailDialog: ModalDirective;
  // 2 其他费用
  // @ViewChild('otherExpense', {static: true}) expenseDialog: ModalDirective;

  // 有合同收款
  @ViewChild('gatherBill', {static: true}) gatherBillDialog: ModalDirective;
  // 无合同收款
  @ViewChild('noContractGatherBill', {static: true}) noContractGatherBillDialog: ModalDirective;

  // 账单和订单关联数据
  @ViewChild('bindOrderBill', {static: true}) bindOrderBillDialog: ModalDirective;
  // 预定金收款dialog
  @ViewChild('bookAmount', {static: true}) bookAmountDialog: ModalDirective;
  // 付款成功页面
  @ViewChild('gatherSuccess', {static: true}) gatherSuccessDialog: ModalDirective;
  // 退款收取弹框
  @ViewChild('checkOutModel', {static: true}) checkOutModel: ModalDirective;

  // 账单退款选择页面
  @ViewChild('refundBillModel', {static: true}) refundBillDialog: ModalDirective;
  // 租约退款申请：审批确认
  @ViewChild('refundBillConfirmModel', {static: true}) refundBillConfirmDialog: ModalDirective;
  // 退款申请成功提示框
  @ViewChild('refundBillConfirmSuccessModel', {static: true}) refundBillConfirmSuccessDialog: ModalDirective;

  // 预定金退款页面
  @ViewChild('refundBookingModel', {static: true}) refundBookingDialog: ModalDirective;
  // 预定金退款申请：审批确认
  @ViewChild('refundBookingConfirmModel', {static: true}) refundBookingConfirmDialog: ModalDirective;
  // 预定金退款申请成功提示框
  @ViewChild('refundBookingConfirmSuccessModel', {static: true}) refundBookingConfirmSuccessDialog: ModalDirective;


  leaseCustomRentList: LeaseCustomRentDTO[];
  leaseExpenseList: LeaseExpenseInfoDTO[];
  /*  付款 参数定义 开始 */
  //收款页面租约ID
  paymentleaseId: any;
  //合同号
  contactNum: any;
  //错误提示
  errorContent: string;
  // 房源下拉列表
  roomInfoDtos: Array<RoomInfoDTO>;
  renterRoomInfos: Array<RoomInfoDTO>;
  //收款确认请求参数
  confirmPaymentRequest = new ConfirmPaymentRequest();
  //退款申请请求参数
  refundApplyRequest = new RefundApplyRequest();
  //点击收款时 下面文案更改
  //是否展示 提示文案
  showTips = true;
  expenseType: string;

  //存放所有账单ID
  allBillIds = [];
  // 存放所有费用分摊账单id
  allRenterBillIds = [];
  // 存放所有组合id
  allCombineIds = [];
  //存放所有账单类型
  allBillType = [];
  //存放所有合同ID
  allBillLeaseId = [];
  //存放所有房间ID
  allBillRoomId = [];

  //存放选中的账单Id
  checkedBillIds = [];
  // 存放所有费用分摊账单id
  checkedRenterBillIds = [];
  // 存放选中的combineID
  checkedCombineIds = [];
  //存放选中的账单类型
  checkedBillType = [];
  //存放选中的合同ID
  checkedBillLeaseId = [];
  //存放选中房间ID
  checkedBillRoomId = [];

  //被选中的账单数量
  ckeckedNum: number = 0;
  otherRemark: string = "";
  renterBillRemark: string;
  // 选中的逾期金总数
  ckeckedOverdueNum: number = 0;
  // 类型：逾期费  账单总数
  totalOverdueNum: number = 0;
  //首期房费+押金， 押金，首期房费 收款也数据展示
  paymentInfoDTO = new PaymentInfoDTO();
  //应付总金额
  paymengTotalAmount = 0;
  // 已付总金额
  paymentedTotalAmount = 0;
  //付款详情请求参数
  gatherAmountDetailRequestParam = new GatherAmountDetailRequestParam();
  //收款页面房间ID
  roomIds: Array<number>;
  //账单列表
  billList: Array<LeaseRoomBillListDTO>;
  newBillList: Array<LeaseRoomBillListDTO> = [];

  //费用类型枚举
  expenseTypeEnum: BaseEnum[];
  //付款类型
  paymentType: string;
  //付款类型名称
  paymentTypeName: string;
  //倒计时
  clock: number;
  smsSendClock: number;

  showSmsSendButton: boolean;
  //展示蓝字提示
  showContent: boolean;
  //关闭成功页面
  close: number;


  orderNum = 0;
  orderDtos: Array<OrderInfoDTO>;
  aliOrWeixinDialog: any;

  //商户订单号
  orderId: string;
  //订单查询不到错误提示
  orderRemind: string;

  //预定金收款
  gatherBookingAmount;

  string;
  //支付成功后额账单流水ID
  payId: number;
  @Output() paymentCallback = new EventEmitter();
  //通过账单id收款
  gatherByBillId: any;
  //判断是否是因为 预定金金额大于应付金额出的错： 如果是则不需要减总价
  bookAmountFlag: boolean = false;
  actualAmountValidErrorContent: string;
  /*  付款 参数定义 结束 */

  /*  1.0.5 版本更改 开始 */
  //是否有合同收款
  hasContact = true;
  /*  1.0.5 版本更改 结束 */

  //无合同收款是否禁用
  noContract = false;
  //是否自动勾选预定金抵扣
  selectBookingAmount = false;
  selectCouponInput = false;
  //创建账单收款输入的金额
  actualAmount: string;
  otherPayAmount: string;
  // 设为无需付
  noPaymentSet: boolean;
  // 短信验证码
  shortMessageVerificationCode: string = '';
  selectedUserPhone: string = 'ALL';
  // 选择的用户
  selectedUserId: number = 0;
  inter;
  needAudit: boolean = false;
  auditList: Array<SysUser> = [];
  doNotNeedToPay: boolean;
  time = 60;
  codeBtnStr = '点击获取';
  SMSAuditCodeRequest = new SMSAuditCodeRequest();
  billTypeList: Array<BillTypeInfo> = [];
  renterBillTypeList: Array<BillTypeInfo> = [];
  refundBillTypeList: Array<BillTypeInfo> = [];

  billTypeNameList: Array<string> = [];
  canBookAmountUseFlag: boolean = false;
  modeOfPayment: string = '';
  canRefundNextError: boolean = true;
  refundSelectValue: number = 0;
  resultRefundNo: string = '';
  auditCodeError: boolean = false;

  refundAuditUserList: Array<SysUser> = [];

  // 不收逾期金选择的原因
  overdueType = "";
  overdueContent: string;

  booking_bill_amount: string;
  bill_charge_off_status: string;

  contractNum: string;

  //多选房间列表div
  showRoomList = false;
  //单选房间列表div
  showRoomList2 = false;
  //选择的房间列表名
  selectRoomNames = [];
  selectRoomName = "请选择房间";

  check_order_no: string;

  // 代金券号
  coupon_number: string;
  coupon_info: CouponInfoDetailDTO;
  coupon_msg: string;
  coupon_flag = false;
  coupon_amount = 0;
  coupon_least_use_total = 0;

  final_amount = "0";

  // 收款弹窗-选择的账单类型
  selectBillType = 0;
  // 收款弹窗-选择的账单状态
  selectBillStatus = "";
  //预付费充值收银台
  @ViewChild('rechargePrepayModel', {static: true}) rechargePrepayModel: ModalDirective;
  @ViewChild('prepayaliOrWeixin', {static: true}) prepayaliOrWeixin: ModalDirective;
  @ViewChild('prepayposPaymnet', {static: true}) prepayposPaymnet: ModalDirective;
  @ViewChild('prepaycashPayment', {static: true}) prepaycashPayment: ModalDirective;
  @ViewChild('prepaygatherSuccess', {static: true}) prepaygatherSuccess: ModalDirective;
  @ViewChild('prepaybindOrderBill', {static: true}) prepaybindOrderBill: ModalDirective;
  @ViewChild('prepayorderQuery', {static: true}) prepayorderQuery: ModalDirective;
  @ViewChild('prepayAccountPayment', {static: true}) prepayAccountPayment: ModalDirective;
  //租约详情
  leaseDetailResponse: LeaseDetailResponse;
  //勾选的房间列表
  rechargeRooms: Array<BatchRechargeRoomDTO>;
  //充值总金额
  batchRechargeTotal = 0;
  //抵扣之后的充值总金额
  paymentAmount = 0;
  //批量充值请求参数
  prepayBatchRechargeReq: BacthRechargePrepayReqeust = {};
  //点击充值之后的回调
  @Output() closeCallback = new EventEmitter();
  //充值成功之后的回调
  @Output() refushLogsCallback = new EventEmitter();

  queryAccountLeaseReq: QueryAccountLeaseRequest = {};

  queryAccountLeaseResponse: QueryAccountLeaseResponse;

  idempotent_key: string;

  account_money_info_id: number = 0;

  payerType: string = 'SUBJECT';
  renterInfoList: Array<RenterListDTO>;
  selectRenterId: number = 0;
  selectRenter: RenterListDTO;
  selectRenterName: string;
  selectRenterRoom: number = 0;
  selectRenterRoomName: string;
  renterBillType: string = '';
  renterBillPreviewList: Array<RenterPreviewBillListDTO>;
  selectBillTypeName: string;
  constructor(private leaseService: LeaseService, private logService: LogService, private bookingService: BookingService,
              private workService: WorkService, private billService: BillService, private financeService: FinanceService,
              private signedService: SignedService, private baseInfoService: BaseInfoService,
              private refundListService: RefundListService, private couponService: CouponService, private prepayService: PrepayService,
              private idempotentService: IdempotentService,
              private clientInfoService: ClientInfoService,
              private orderService: OrderService, @Optional() @SkipSelf() dialogComponent: DialogComponent,
              private renterService: RenterService) {
  }

  ngOnInit() {
    if (!this.type) {
      this.getBillTypeList('ROOM_LEASE');
      this.getAuditList();
      this.getRefundAuditUserList();
      this.getRenterBillTypeList();
    }

    this.expenseTypeEnum = [
      new BaseEnum('otherExpense', '其它费用'),
      new BaseEnum('renterExpense', '房费、押金、水电等'),
      new BaseEnum('firstRenterDepositExpense', '首期房费+押金'),
      new BaseEnum('firstRenterExpense', '首期房费'),
      new BaseEnum('depositExpense', '押金'),
    ];
  }

  // 1.每月租金 start
  /**
   * @Description:   打开房间租金信息
   * @Author: zhoujiahao
   * @Date: 2018-07-22  12:05
   */
  openRoomDetetail() {
    this.confirmPaymentRequest.bussiness_type = null;
    let req: any = {new_lease_room_id: this.leaseRoomId, record_id: this.recordId};
    if (!this.recordId) {
      req = {new_lease_room_id: this.leaseRoomId};
    }
    if (!this.leaseRoomId) {
      req = {record_id: this.recordId};
    }
    this.leaseService.getRentList(req).then(res => {
      this.leaseCustomRentList = res.dynamic_rent_price_list;
    }).catch(error => {
      this.logService.error('DialogComponent:获得房间租金明细' + JSON.stringify(error));
    });
    this.detailDialog.show();
  }
  // 1.每月租金 end


  //
  // /**
  //  * @Description:   打开房间房间其它费用信息
  //  * @Author: zhoujiahao
  //  * @Date: 2018-07-22  12:05
  //  */
  // openExpenseDialog() {
  //   const req = {lease_room_id: this.leaseRoomId};
  //   this.leaseService.getExpenseList(req).then(res => {
  //     this.leaseExpenseList = res.lease_expense_info_dtos;
  //   }).catch(error => {
  //     this.logService.error('DialogComponent:获得房间其它费用失败' + JSON.stringify(error));
  //   });
  //   this.expenseDialog.show();
  // }


  /*        收款弹框页面    开始    */
  /**
   * @Description:   重新选择房间后出发查询付款详情
   * @Author: zhoujiahao
   * @Date: 2018-07-30  14:28
   */
  roomSelect(type?: any) {
    this.actualAmountValidErrorContent = null;
    //如果合同号正确才会去调付款详情
    if (!this.errorContent) {
      if (type && type === 'REFUND') {
        this.canRefundBillDetail(this.expenseType);
      } else {
        this.paymentDetail(this.expenseType, this.selectBillType);
      }
    }
  }


  //contractNum 合同号 打开收款页面
  openGatherDialog(leaseId?: any, billId?: any) {
    this.payerType = 'SUBJECT';
    this.billList = [];
    this.gatherAmountDetailRequestParam.refundable_bill = '';
    this.selectBillType = 0;
    this.selectBillStatus = "";
    this.bill_charge_off_status = "";
    this.changeValueToNull();
    //默认展示文案
    this.showTips = true;
    if (billId) {
      //默认选中所选的账单和房间 如果账单不为空，则默认选择付款类型：renterExpense 并且选中账单ID，房间ID
      //选中
      this.paymentleaseId = leaseId;
      this.gatherByBillId = billId;
      //不展示提示文案
      this.showTips = false;
    }
    if (leaseId) {
      this.hasContact = true;
      this.expenseType = 'renterExpense';
      this.noContract = true;
      this.enterLeaseId('LEASE_ID', leaseId);
      this.getAccountLease(leaseId);
    }
    //无合同收款
    if (!leaseId) {
      //不展示提示文案
      this.showTips = false;
      this.expenseType = 'NOLEASE';
      this.hasContact = false;
      this.gatherBillDialog.show();
    }
  }


  //contractNum 合同号 打开退款页面
  openRefundDialog(leaseId: any) {

    this.changeValueToNull();
    this.showTips = true;
    if (leaseId) {
      this.hasContact = true;
      this.expenseType = 'renterExpense';
      this.noContract = true;
      this.enterRefundLeaseId('LEASE_ID', leaseId);
      this.getAccountLease(leaseId);
    }
  }

  openRefundBookingDialog(leaseId: any, booking_bill_amount: string) {
    this.leaseId = leaseId;
    this.booking_bill_amount = booking_bill_amount;
    this.getAccountLease(leaseId);
    this.refundBookingDialog.show();
  }

  /**
   * @Description:  输入合同号或者点击收款获取租约ID
   * leaseType  LEASE_ID（租约ID）或者 CONTACT_NUM（合同号）
   * @Author: zhoujiahao
   * @Date: 2018-07-13  10:40
   */
  enterLeaseId(leaseType?: any, leaseValue?: any) {

    this.actualAmountValidErrorContent = null;
    //add by yangwen增加“全部”选项,默认选中“全部”
    let allRoomInfoDtoItem = new RoomInfoDTO();
    allRoomInfoDtoItem.room_id = 0;
    allRoomInfoDtoItem.room_name = '全部房间';
    this.roomInfoDtos = [];
    this.roomInfoDtos.push(allRoomInfoDtoItem);
    this.renterRoomInfos = [];

    const reqDate = {lease_room_query_type: leaseType, lease_value: leaseValue};
    if ((this.contactNum && this.contactNum.length >= 14) || "LEASE_ID" === leaseType) {
      this.bookingService.getLeaseRoomList(reqDate).then(res => {
        if (!res) {
          this.errorContent = "无效的合同号，请重新输入";
          this.roomInfoDtos = [];
          this.showTips = true;
          return;
        }
        this.roomIds = [];
        this.paymentleaseId = res.lease_id;
        this.leaseId = res.lease_id;
        this.contactNum = res.contact_num;
        this.confirmPaymentRequest.lease_id = this.paymentleaseId;
        this.errorContent = null;
        res.room_info_list.forEach(e => {
          this.roomInfoDtos.push(e);
        });
        res.room_info_list.forEach(e => {
          this.renterRoomInfos.push(e);
        });

        this.selectRoomNames = ['全部房间'];
        this.roomIds = [];
        for (let i = 0; i < this.roomInfoDtos.length; i++) {
          this.roomIds.push(this.roomInfoDtos[i].room_id);
        }

        if (this.expenseType) {
          //输入合同的同时也刷新下面的数据
          this.paymentDetail(this.expenseType);
        } else {
          this.gatherBillDialog.show();
        }
      }).catch(e => {
        this.errorContent = "无效的合同号，请重新输入";
        this.logService.error('PactComponent:获取租约下的房间失败' + JSON.stringify(e));
        this.showTips = true;
        return;
      });
    } else {
      this.billList = null;
      this.paymentleaseId = null;
      this.errorContent = "无效的合同号，请重新输入";
      this.roomInfoDtos = [];
      this.showTips = true;
    }
  }

  //获取付款信息
  paymentDetail(expenseType?: string, billTypeId?: number): string {
    //将参数置空
    this.changeValueToNull();
    if (!this.paymentleaseId && this.expenseType !== 'otherExpense') {
      //合同ID为空
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '合同号不能为空'));
      return;
    }
    this.confirmPaymentRequest.lease_id = this.paymentleaseId;
    this.confirmPaymentRequest.payment_total_amount = '0';
    this.expenseType = expenseType;
    this.gatherAmountDetailRequestParam.gather_type = this.expenseType;
    this.gatherAmountDetailRequestParam.lease_id = this.paymentleaseId;
    if (billTypeId) {
      this.gatherAmountDetailRequestParam.bill_type_ids = [billTypeId];
    } else {
      this.gatherAmountDetailRequestParam.bill_type_ids = [];
    }
    if (this.roomIds.indexOf(0) < 0) {
      this.gatherAmountDetailRequestParam.room_ids = this.roomIds;
    } else {
      this.gatherAmountDetailRequestParam.room_ids = [];
    }
    this.gatherAmountDetailRequestParam.bill_charge_off_status = this.bill_charge_off_status;
    // if (expenseType === 'otherExpense' && (!this.roomIds || this.roomIds.length !== 1 || this.roomIds.includes(0)) && this.paymentleaseId) {
    //   this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '选择其他费用收款时，只能选单个房间，且必须选一个房间'));
    //   return;
    // }
    this.confirmPaymentRequest.room_ids = this.roomIds;
    this.gatherAmountDetailRequestParam.payer_type = this.payerType;
    if (expenseType === 'renterExpense') {
      //房费、押金、水电等
      this.billService.gatherAmountDtail(this.gatherAmountDetailRequestParam).then(res => {
        this.allBillIds = [];
        if (res) {
          this.canBookAmountUseFlag = res.can_book_amount_use && res.can_book_amount_use === 1;
          this.allBillIds = res.lease_room_bill_list
            .filter(r => !r.in_audit)
            .map(r => r.bill_id);
          this.allBillType = res.lease_room_bill_list
            .filter(r => !r.in_audit)
            .map(r => r.bill_type_name);
          this.allBillLeaseId = res.lease_room_bill_list
            .filter(r => !r.in_audit)
            .map(r => r.lease_id);
          this.allBillRoomId = res.lease_room_bill_list
            .filter(r => !r.in_audit)
            .map(r => r.lease_room_id);
          this.allRenterBillIds = res.lease_room_bill_list
            .filter(r => !r.in_audit)
            .map(r => r.renter_went_dutch_bill_id);
          this.allCombineIds = res.lease_room_bill_list
            .filter(r => !r.in_audit)
            .map(r => r.combine_id);
          this.billList = res.lease_room_bill_list;
          this.paymentInfoDTO.book_amount = res.book_amount;
          this.confirmPaymentRequest.book_amount = this.paymentInfoDTO.book_amount;
          this.confirmPaymentRequest.gather_type = this.expenseType;
          this.paymengTotalAmount = res.payment_total_amount;
          if (!this.gatherByBillId) {
            this.checkedBillIds = [];
          } else {
            if (this.allBillIds.lastIndexOf(this.gatherByBillId) < 0) {
              //如果当前账单不含有用户勾选的账单id
              this.checkedBillIds = [];
            } else {
              this.billList.forEach(e => {
                if (e.bill_id === this.gatherByBillId) {
                  this.confirmPaymentRequest.payment_total_amount = e.waiting_pay_amount;
                  this.checkedBillIds.push(this.gatherByBillId);
                  this.confirmPaymentRequest.bill_ids = this.checkedBillIds;
                  this.confirmPaymentRequest.actual_payment_amount = e.waiting_pay_amount;
                  this.ckeckedNum += 1;

                  // 逾期账单
                  if (e.overdue_amount) {
                    if (this.confirmPaymentRequest.checked_overdue_amount) {
                      this.confirmPaymentRequest.checked_overdue_amount =
                        this.getTwoPoint(this.changToDecimal(this.confirmPaymentRequest.checked_overdue_amount).add(e.overdue_amount));
                    } else {
                      this.confirmPaymentRequest.checked_overdue_amount = this.getTwoPoint(e.overdue_amount);
                    }
                    this.ckeckedOverdueNum += 1;
                  }
                  if (e.bill_type_name === '滞纳金') {
                    this.totalOverdueNum += 1;
                  }
                }
              });
              this.setChecked();
              this.cal();
            }
            this.gatherByBillId = null;
          }
          this.gatherBillDialog.show();
        }
      }).catch(error => {
        this.logService.error('PactComponent:获取账单列表信息失败');
        return;
      });

    } else {
      if (this.paymentleaseId) {
        this.billService.gatherAmountDtail(this.gatherAmountDetailRequestParam).then(res => {
          if (res) {
            this.canBookAmountUseFlag = res.can_book_amount_use && res.can_book_amount_use === 1;
            this.paymentInfoDTO = res;
            //如果费用类型是 其他类型
            if (this.expenseType === 'otherExpense') {
              this.confirmPaymentRequest.payment_total_amount = '0';
            } else {
              this.confirmPaymentRequest.payment_total_amount = this.paymentInfoDTO.payment_total_amount;
            }
            this.confirmPaymentRequest.book_amount = this.paymentInfoDTO.book_amount;
            this.confirmPaymentRequest.gather_type = this.expenseType;
            this.cal();
          } else {
            this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '无待收款账单'));
            return;
          }
        }).catch(error => {
          this.logService.error('PactComponent:获取收款详情失败');
          return;
        });
      }
    }
    this.showTips = false;
  }

  /**
   * @Description:  切换收款类型时将参数置为空
   * @Author: zhoujiahao
   * @Date: 2018-07-14  21:20
   */
  changeValueToNull() {
    this.checkedBillIds = [];
    this.checkedRenterBillIds = [];
    this.checkedCombineIds = [];
    this.allBillIds = [];
    this.ckeckedNum = 0;
    this.ckeckedOverdueNum = 0;
    this.totalOverdueNum = 0;
    this.shortMessageVerificationCode = '';
    this.doNotNeedToPay = false;
    // this.auditList = [];
    if (this.time === 60 || this.time === 0) {
      this.selectedUserPhone = 'ALL';
    }
    this.confirmPaymentRequest.checked_overdue_amount = 0;
    this.confirmPaymentRequest.payment_total_amount = null;
    this.confirmPaymentRequest.remark = null;
    this.confirmPaymentRequest.bill_ids = null;
    this.confirmPaymentRequest.book_amount = null;
    this.confirmPaymentRequest.actual_payment_amount = null;
    this.confirmPaymentRequest.deductible = false;
    this.confirmPaymentRequest.attached_list = null;
    this.confirmPaymentRequest.lease_id = null;
    this.confirmPaymentRequest.room_ids = null;
    this.confirmPaymentRequest.lease_room_ids = null;
    this.confirmPaymentRequest.bussiness_type = null;
    this.confirmPaymentRequest.type_id = null;
    this.paymentInfoDTO.book_amount = null;
    this.paymentInfoDTO.payment_total_amount = null;
    this.paymentInfoDTO.first_renter_expense = null;
    this.paymentInfoDTO.paymented_amount = null;
    this.confirmPaymentRequest.bill_type_code = '';
    this.paymentInfoDTO.deposit = null;
    this.paymentInfoDTO.bill_ids = null;
    this.paymengTotalAmount = null;
    this.gatherBookingAmount = null;
    this.actualAmountValidErrorContent = null;
    this.selectBookingAmount = false;
    this.selectRoomName = "请选择房间";
    this.showRoomList2 = false;
    this.selectCouponInput = false;
    this.coupon_number = null;
    this.coupon_msg = null;
    this.coupon_flag = false;
    this.coupon_amount = 0;
    this.final_amount = "0";
    this.actualAmount = null;
  }

  /**
   * @Description: 选中当前账单
   * @Author: zhoujiahao
   * @Date: 2018-07-14  19:02
   */
  checkCurrentBill(event?: any, billId?: any, waitingAmount?: any, billTypeName?: string, renter_went_dutch_bill_id?: number, combine_id?: string) {
    //当前账单DTO
    let currentBill;
    if (this.payerType !== 'RENTER') {
      currentBill = this.billList.find(bill => bill.bill_id === billId);
    } else {
      if (renter_went_dutch_bill_id) {
        currentBill = this.billList.find(bill => bill.renter_went_dutch_bill_id === renter_went_dutch_bill_id);
      } else {
        currentBill = this.billList.find(bill => bill.bill_id === billId);
      }
    }
    //用户输入金额
    let currentActualAmount = this.changToDecimal("0");
    if (this.confirmPaymentRequest.actual_payment_amount) {
      currentActualAmount = this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount);
    }
    // 待收款金额
    let waitingAmountCheck = this.changToDecimal(waitingAmount);
    // 如果是勾选了账单
    if (event.target.checked) {
      if (this.confirmPaymentRequest.payment_total_amount) {
        this.confirmPaymentRequest.payment_total_amount =
          //保留两位小数点
          this.getTwoPoint(this.changToDecimal(this.confirmPaymentRequest.payment_total_amount).add(waitingAmountCheck));
      } else {
        this.confirmPaymentRequest.payment_total_amount = this.getTwoPoint(waitingAmountCheck);
      }
      // 逾期账单
      if (currentBill.overdue_amount) {
        if (this.confirmPaymentRequest.checked_overdue_amount) {
          this.confirmPaymentRequest.checked_overdue_amount =
            this.getTwoPoint(this.changToDecimal(this.confirmPaymentRequest.checked_overdue_amount).add(currentBill.overdue_amount));
        } else {
          this.confirmPaymentRequest.checked_overdue_amount = this.getTwoPoint(currentBill.overdue_amount);
        }
        this.ckeckedOverdueNum += 1;
      }
      if (currentBill.bill_type_name === '滞纳金') {
        this.totalOverdueNum += 1;
      }

      this.confirmPaymentRequest.actual_payment_amount =
        this.getTwoPoint(currentActualAmount.add(waitingAmountCheck));
      this.ckeckedNum += 1;

      this.checkedBillIds.push(billId);
      if (this.payerType === 'RENTER') {
        if (renter_went_dutch_bill_id) {
          this.checkedRenterBillIds.push(renter_went_dutch_bill_id);
        }
        this.checkedCombineIds.push(combine_id);
      }
      //add by yangwen start 2019-01-21 判断是否勾选预定金
      //如果存在预定金
      if (this.confirmPaymentRequest.book_amount && this.canBookAmountUseFlag) {
        if (!this.selectBookingAmount && this.changToDecimal(this.confirmPaymentRequest.book_amount).lessThanOrEqualTo(
          this.changToDecimal(this.confirmPaymentRequest.payment_total_amount))) {
          this.selectBookingAmount = true;
          $("#bookCheckBox").trigger("click");
        }
      }
      //add by yangwen end 2019-01-21 判断是否勾选预定金
    } else {
      //取消选中

      //add by yangwen 2019-01-21 判断是否勾选预定金
      //如果存在预定金
      if (this.confirmPaymentRequest.book_amount) {
        if (this.selectBookingAmount && this.changToDecimal(this.confirmPaymentRequest.book_amount).greaterThan(
          this.changToDecimal(this.confirmPaymentRequest.payment_total_amount).sub(this.changToDecimal(waitingAmount.toString())))) {
          this.selectBookingAmount = false;
          currentActualAmount = this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount).add(
            this.changToDecimal(this.confirmPaymentRequest.book_amount));
          this.confirmPaymentRequest.actual_payment_amount =
            (this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount).add(
              this.changToDecimal(this.confirmPaymentRequest.book_amount)).sub(this.changToDecimal(waitingAmount.toString()))).toString();
        }
      }

      //用户输入了金额
      // if (this.confirmPaymentRequest.actual_payment_amount
      //   && this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount).greaterThan("0")) {
      //   //取消后的账单金额>实际支付金额 此处的实际支付金额是用户付的
      //   if (waitingAmountCheck.greaterThan(currentActualAmount)) {
      //     this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "收款金额不能大于应付金额"));
      //     event.target.checked = true;
      //     return;
      //   }
      // }
      // else {
      //   if (currentActualAmount.lessThan(waitingAmount)) {
      //     this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "应付金额不能小于0"));
      //     event.target.checked = true;
      //     return;
      //   }
      // }
      this.confirmPaymentRequest.payment_total_amount =
        this.getTwoPoint(this.changToDecimal(this.confirmPaymentRequest.payment_total_amount).sub(waitingAmountCheck));
      this.confirmPaymentRequest.actual_payment_amount =
        this.getTwoPoint(currentActualAmount.sub(waitingAmountCheck));
      this.ckeckedNum -= 1;
      // 逾期金处理
      if (currentBill.overdue_amount) {
        this.ckeckedOverdueNum -= 1;
        this.confirmPaymentRequest.checked_overdue_amount -= currentBill.overdue_amount;
      }
      if (currentBill.bill_type_name === '滞纳金') {
        this.totalOverdueNum -= 1;
      }
      this.checkedBillIds = removeFromArray(this.checkedBillIds, billId);
      if (this.payerType === 'RENTER') {
        if (renter_went_dutch_bill_id) {
          this.checkedRenterBillIds = removeFromArray(this.checkedRenterBillIds ,renter_went_dutch_bill_id);
        }
        this.checkedCombineIds = removeFromStrArray(this.checkedCombineIds, combine_id);
      }
    }
    //如果为选中账单
    if (this.payerType === 'RENTER') {
      if (this.checkedCombineIds.length <= 0) {
        this.confirmPaymentRequest.actual_payment_amount = '0';
      }
    } else {
      if (this.checkedBillIds.length <= 0) {
        this.confirmPaymentRequest.actual_payment_amount = '0';
      }
    }

    this.confirmPaymentRequest.bill_ids = this.checkedBillIds;
    this.confirmPaymentRequest.renter_bill_ids = this.checkedRenterBillIds;

    //取消房费优惠券勾选
    this.selectCouponInput = false;
    this.coupon_amount = 0;
    this.cal();

    this.actualAmountValidErrorContent = null;
    this.setChecked();
  }

  /**
   * @Description:  是否勾选预定金抵扣
   /**
   *  输入验证：收取费用，不能超过待收费用
   *• 定金抵扣选项，当此合同的关联预定单有定金，且定金还可使用时，出现此选项，勾选时做以下判断：
   *如果定金的金额大于当前的费用，弹出提示“只有当收取金额大于等于定金时，才能抵扣”, 取消选中状态；
   *如果定金的金额小于当前的费用，上面的2000元，变成减去定金后的金额
   *如果定金的金额等于当前的费用，提示“抵扣金额相等，无需再收款，是否全部抵扣？确认后不能取消”，确认后直接跳到收款成功界面
   * @Author: zhoujiahao
   * @Date: 2018-07-13  16:09
   */
  checkBooking(event?: any, gatherSuccess?: any) {
    this.selectBookingAmount = event.target.checked;
    let deductible = event.target.checked;
    this.confirmPaymentRequest.deductible = event.target.checked;
    if (deductible) {
      let messageModalInfo = new MessageModalInfo();
      let bookAmount = new Decimal("0");
      if (this.paymentInfoDTO.book_amount) {
        bookAmount = this.changToDecimal(this.paymentInfoDTO.book_amount);
      }
      let totalPaymentAmount = new Decimal("0");

      if (this.expenseType === 'renterExpense') {
        if (!this.confirmPaymentRequest.payment_total_amount) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请选择需要付款的账单'));
          this.selectBookingAmount = event.target.checked = false;
          return;
        } else {
          this.paymentInfoDTO.payment_total_amount = this.confirmPaymentRequest.payment_total_amount;
        }
      }
      totalPaymentAmount = this.changToDecimal(this.confirmPaymentRequest.payment_total_amount);

      //预定金不能大于应付金额
      if (this.expenseType !== 'otherExpense') {
        if (bookAmount.greaterThan(totalPaymentAmount)) {
          messageModalInfo.operationMessage = "只有当收取金额大于等于定金时，才能抵扣！";
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
          this.confirmPaymentRequest.deductible = false;
          this.selectBookingAmount = event.target.checked = false;
          return;
        }
      }

      // 如果用户输入了金额
      if (this.confirmPaymentRequest.actual_payment_amount && parseFloat(this.confirmPaymentRequest.actual_payment_amount) > 0) {
        let actualAmount = this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount);
        if (this.expenseType === 'otherExpense') {
          if (bookAmount.greaterThan(actualAmount)) {
            messageModalInfo.operationMessage = "只有当收取金额大于等于定金时，才能抵扣！";
            this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
            this.confirmPaymentRequest.deductible = false;
            this.selectBookingAmount = event.target.checked = false;
            return;
          } else if (bookAmount.eq(actualAmount)) {
            //校验必传参数
            if (this.gatherOtherExpwnseChekck(true)) {
              //预定金等于应付金额
              messageModalInfo.operationMessage = "抵扣金额相等，无需再收款，是否全部抵扣？确认后不能取消";
              messageModalInfo.cancel = true;
              this.idempotentService.getIdempotentKey().then(data => {
                this.idempotent_key = data.idempotent_key;
                messageModalInfo.callback.subscribe(() => {
                  this.confirmPaymentRequest.room_ids = this.roomIds;
                  //直接跳到支付成功  定金直接抵扣
                  this.confirm(null, this.gatherBillDialog, this.gatherSuccessDialog, 'bookAmountPayment');
                });
                this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
              });
            }

          }
        } else {
          //其它费用以外的费用
          if ((bookAmount.greaterThan(totalPaymentAmount))) {
            messageModalInfo.operationMessage = "只有当收取金额大于等于定金时，才能抵扣！";
            this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
            this.confirmPaymentRequest.deductible = false;
            this.selectBookingAmount = event.target.checked = false;
            return;
          }
          if (bookAmount.eq(totalPaymentAmount)) {
            this.confirmPaymentRequest.actual_payment_amount = '0';
            //预定金等于应付金额
            messageModalInfo.operationMessage = "抵扣金额相等，无需再收款，是否全部抵扣？确认后不能取消";
            messageModalInfo.cancel = true;
            this.idempotentService.getIdempotentKey().then(data => {
              this.idempotent_key = data.idempotent_key;
              messageModalInfo.callback.subscribe(() => {
                //直接跳到支付成功  定金直接抵扣
                this.confirm(null, this.gatherBillDialog, this.gatherSuccessDialog, 'bookAmountPayment');
              });
              this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
            });
          }
        }
      } else {
        //用户没输入金额
        if (this.expenseType !== 'otherExpense') {
          //用户没有输入金额
          if (bookAmount.eq(totalPaymentAmount)) {
            this.confirmPaymentRequest.actual_payment_amount = '0';
            //预定金等于应付金额
            messageModalInfo.operationMessage = "抵扣金额相等，无需再收款，是否全部抵扣？确认后不能取消";
            messageModalInfo.cancel = true;
            this.idempotentService.getIdempotentKey().then(data => {
              this.idempotent_key = data.idempotent_key;
              messageModalInfo.callback.subscribe(() => {
                //直接跳到支付成功  定金直接抵扣
                this.confirm(null, this.gatherBillDialog, this.gatherSuccessDialog, 'bookAmountPayment');
              });
              this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
            });
          }
        } else {
          //其它费用
          messageModalInfo.operationMessage = "请输入应收金额!";
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
          this.confirmPaymentRequest.deductible = false;
          this.selectBookingAmount = event.target.checked = false;
          return;
        }
      }
      //金额计算 规则
      if (this.expenseType === 'otherExpense') {
        if (!this.confirmPaymentRequest.actual_payment_amount) {
          //其它费用
          messageModalInfo.operationMessage = "请输入应收金额!";
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
          this.confirmPaymentRequest.deductible = false;
          this.selectBookingAmount = event.target.checked = false;
          return;
        }
      }
      if (this.confirmPaymentRequest.actual_payment_amount
        && !this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount).lessThan(bookAmount)) {
        this.confirmPaymentRequest.actual_payment_amount =
          this.getTwoPoint((this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount)
            .sub(this.confirmPaymentRequest.book_amount)));
      }
      this.bookAmountFlag = true;

    } else if (!this.confirmPaymentRequest.deductible && this.bookAmountFlag) {
      //取消预定金
      if (this.confirmPaymentRequest.actual_payment_amount && this.confirmPaymentRequest.book_amount) {
        this.confirmPaymentRequest.actual_payment_amount =
          this.getTwoPoint(this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount)
            .add(this.confirmPaymentRequest.book_amount));
      }
    }
    this.cal();
  }

  format_input_num(event) {
    let obj = event.currentTarget;
    // 清除"数字"和"."以外的字符
    obj.value = obj.value.replace(/[^\d.]/g, "");
    // 验证第一个字符是数字
    obj.value = obj.value.replace(/^\./g, "");
    // 只保留第一个, 清除多余的
    obj.value = obj.value.replace(/\.{2,}/g, ".");
    obj.value = obj.value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    // 只能输入两个小数
    obj.value = obj.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
  }

  /**
   * @Description:  用户输入金额判断
   * @Param:  renterExpense,
   * @return:
   * @Author: zhoujiahao
   * @Date: 2018-07-13  17:21
   */
  renterExpense() {
    if (this.confirmPaymentRequest.actual_payment_amount == "") {
      this.confirmPaymentRequest.actual_payment_amount = "0";
    }
    if (this.confirmPaymentRequest.actual_payment_amount) {
      this.confirmPaymentRequest.actual_payment_amount = this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount + "").toString();
    }

    let messageModalInfo = new MessageModalInfo();

    if (this.actualAmount != null) {
      if (!this.validTwoPoint(this.actualAmount)) {
        messageModalInfo.operationMessage = "请输入一个正确的金额";
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
        this.actualAmount = null;
        this.confirmPaymentRequest.payment_total_amount = null;
        this.confirmPaymentRequest.actual_payment_amount = this.final_amount = "0";
        return;
      } else {
        this.actualAmount = this.changToDecimal(this.actualAmount + "").toString();
      }
      // if (this.changToDecimal(this.actualAmount).greaterThan(0)) {
      this.confirmPaymentRequest.actual_payment_amount = this.actualAmount;
      // }
    }

    if (!this.validTwoPoint(this.confirmPaymentRequest.actual_payment_amount)) {
      this.actualAmountValidErrorContent = '请输入一个正确的金额';
      if (this.expenseType === 'otherExpense') {
        //重新赋值
        this.confirmPaymentRequest.payment_total_amount = null;
        this.cal();
      }
      return;
    }
    this.actualAmountValidErrorContent = null;
    if (this.expenseType === 'otherExpense') {
      //其他类型
      if (this.confirmPaymentRequest.deductible && this.selectBookingAmount) {
        if ((parseFloat(this.confirmPaymentRequest.book_amount) >
          parseFloat(this.confirmPaymentRequest.actual_payment_amount))) {
          messageModalInfo.operationMessage = "只有当收取金额大于等于定金时，才能抵扣！";
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
          this.selectBookingAmount = false;
          return;
        } else {
          //应付总经额=用户输入金额 此时不弹出预定金是否全部抵扣
          //实际支付金额=输入金额-定金金额
          this.confirmPaymentRequest.payment_total_amount = this.confirmPaymentRequest.actual_payment_amount;
          this.confirmPaymentRequest.actual_payment_amount =
            this.getTwoPoint(this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount)
              .sub(this.confirmPaymentRequest.book_amount));
        }
      } else {
        this.confirmPaymentRequest.payment_total_amount = this.confirmPaymentRequest.actual_payment_amount;
      }

      //重新赋值
      this.cal();
      this.createPreviewList();
    } else if (this.expenseType === 'renterExpense') {
      //如果勾选了使用优惠券，则判断输入金额是否>=(优惠券使用门槛-优惠券金额)
      if (this.coupon_amount > 0) {
        if (this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount)
          .lessThan(this.changToDecimal(this.coupon_least_use_total + "").sub(this.changToDecimal(this.coupon_amount + "")))) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "本次收取金额应不少于" +
            this.changToDecimal(this.coupon_least_use_total + "").sub(this.changToDecimal(this.coupon_amount + "")).toString()));
          return;
        }
      }
      if (!this.confirmPaymentRequest.payment_total_amount) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "应收金额不能为空"));
        return;
      }
      if (this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount)
        .greaterThan(this.changToDecimal(this.confirmPaymentRequest.payment_total_amount))) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "收取费用不能超过待收费用"));
        return;
      }
      let amount = this.confirmPaymentRequest.payment_total_amount;
      if (this.confirmPaymentRequest.deductible) {
        amount = new Decimal(this.confirmPaymentRequest.payment_total_amount)
          .sub(new Decimal(this.confirmPaymentRequest.book_amount)).toString();
      }
      if (this.selectCouponInput) {
        amount = new Decimal(amount)
          .sub(new Decimal(this.coupon_amount)).toString();
      }
      if (this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount)
        .greaterThan(this.changToDecimal(amount))) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "收取费用不能超过待收费用"));
        return;
      }
    }
  }

  /**
   * @Description:  用户输入金额判断
   * @Param:  renterExpense,
   * @return:
   * @Author: zhoujiahao
   * @Date: 2018-07-13  17:21
   */
  renterOrderAmountExpense() {
    if (!this.validAmount(this.otherPayAmount)) {
      return false;
    }
    this.createPreviewList();
  }

  calLeastTotal(num1: any, num2: any) {
    return this.changToDecimal(num1 + "").sub(this.changToDecimal(num2 + "")).toString();
  }

  compareLeaseAccount(num1: any, accountDto: AccountMoneyListDTO): boolean {
    let leaseTotal = Number.parseFloat(num1);
    let account = Number.parseFloat(accountDto.balance);
    let _return = true;

    if (account < leaseTotal) {
      _return = false;
    }

    // 判断房费逻辑
    if (accountDto.special_type === "SPECIAL_ACCOUNT_RENT_DIC") {
      let relative_lease_ids = accountDto.relative_lease_ids;
      let relative_lease_room_ids = accountDto.relative_lease_room_ids;
      if (this.checkedBillIds.length > 0 && relative_lease_ids.length > 0 && relative_lease_room_ids.length > 0) {
        this.checkedBillIds.forEach((value, index) => {
          if (this.checkedBillType[index] !== "房费") {
            _return = false;
          }

          let tmpLease = relative_lease_ids.filter(a => a === this.checkedBillLeaseId[index]);
          let tmpRoom = relative_lease_room_ids.filter(a => a === this.checkedBillRoomId[index]);
          if (tmpLease.length === 0 || tmpRoom.length === 0 || !tmpLease || !tmpRoom) {
            _return = false;
          }
        });
      }
    }
    return _return;
  }

  // compareLeaseAccount2(num1: any, accountDto: AccountMoneyListDTO): boolean {
  //   let leaseTotal = Number.parseFloat(num1);
  //   let _return = true;
  //
  //   // 判断房费逻辑
  //   if (accountDto.special_type !== "SPECIAL_ACCOUNT_RENT_DIC") {
  //     let relative_lease_ids = accountDto.relative_lease_ids;
  //     let relative_lease_room_ids = accountDto.relative_lease_room_ids;
  //     if (this.checkedBillIds.length > 0 && relative_lease_ids.length > 0 && relative_lease_room_ids.length > 0) {
  //       console.log(relative_lease_ids);
  //       console.log(relative_lease_room_ids);
  //       this.checkedBillIds.forEach((value, index) => {
  //         if (this.checkedBillType[index] !== "房费") {
  //           _return = false;
  //         }
  //
  //         //console.log(this.checkedBillType[index]);
  //         //console.log(this.checkedBillLeaseId[index]);
  //         //console.log(this.checkedBillRoomId[index]);
  //
  //         let tmpLease = relative_lease_ids.filter(a => a === this.checkedBillLeaseId[index]);
  //         let tmpRoom = relative_lease_room_ids.filter(a => a === this.checkedBillRoomId[index]);
  //         //console.log(tmpLease);
  //         //console.log(tmpRoom);
  //         if (tmpLease.length === 0 || tmpRoom.length === 0 || !tmpLease || !tmpRoom) {
  //           _return = false;
  //         }
  //       });
  //     }
  //   }
  //   return _return;
  // }

  /**
   * @Description:
   * @Param:
   * @return:
   * @Author: zhoujiahao
   * @Date: 2018-07-14  19:01
   */
  checkAll(event?: any) {
    if (event.target.checked) {
      // 全选
      this.checkedBillIds = this.allBillIds;
      this.ckeckedNum = this.allBillIds.length;
      this.checkedBillType = this.allBillType;
      this.checkedBillLeaseId = this.allBillLeaseId;
      this.checkedBillRoomId = this.allBillRoomId;
      this.checkedRenterBillIds = this.allRenterBillIds;
      this.checkedCombineIds = this.allCombineIds;

      if (this.paymengTotalAmount) {
        this.confirmPaymentRequest.payment_total_amount = this.paymengTotalAmount.toString();
        this.confirmPaymentRequest.actual_payment_amount = this.confirmPaymentRequest.payment_total_amount;
      }
      //add by yangwen 2019-01-21 判断是否勾选预定金
      if (this.confirmPaymentRequest.book_amount && this.canBookAmountUseFlag) {
        if (!this.selectBookingAmount &&
          this.changToDecimal(this.confirmPaymentRequest.book_amount).lessThanOrEqualTo(
            this.changToDecimal(this.confirmPaymentRequest.payment_total_amount))) {
          this.selectBookingAmount = true;
          $("#bookCheckBox").trigger("click");
        }
      }

      this.ckeckedOverdueNum = 0;
      this.totalOverdueNum = 0;
      for (let i = 0; i < this.billList.length; i++) {
        let currentBill = this.billList[i];
        // 逾期账单
        if (currentBill.overdue_amount) {
          if (this.confirmPaymentRequest.checked_overdue_amount) {
            this.confirmPaymentRequest.checked_overdue_amount =
              this.getTwoPoint(this.changToDecimal(this.confirmPaymentRequest.checked_overdue_amount).add(currentBill.overdue_amount));
          } else {
            this.confirmPaymentRequest.checked_overdue_amount = this.getTwoPoint(currentBill.overdue_amount);
          }
          this.ckeckedOverdueNum += 1;
        }
        if (currentBill.bill_type_name === '滞纳金') {
          this.totalOverdueNum += 1;
        }
      }
    } else {
      //add by yangwen 2019-01-21 判断是否勾选预定金
      if (this.confirmPaymentRequest.book_amount && this.canBookAmountUseFlag) {
        if (this.selectBookingAmount) {
          this.selectBookingAmount = false;
          $("#bookCheckBox").trigger("click");
        }
      }

      // //实际支付金额减去总金额<0
      // if (this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount)
      //   .lessThan(this.changToDecimal(this.final_amount))) {
      //   this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "收取费用不能超过待收费用"));
      //   event.target.checked = true;
      //   return;
      // }
      this.checkedBillIds = [];
      this.checkedBillType = [];
      this.checkedBillLeaseId = [];
      this.checkedBillRoomId = [];
      this.checkedRenterBillIds = [];
      this.checkedCombineIds = [];
      this.ckeckedNum = 0;
      this.confirmPaymentRequest.payment_total_amount = null;
      this.confirmPaymentRequest.actual_payment_amount = '0';

      // 逾期金处理
      this.ckeckedOverdueNum = 0;
      this.confirmPaymentRequest.checked_overdue_amount = '0';
      this.totalOverdueNum = 0;

    }
    if (this.payerType !== 'RENTER') {
      this.confirmPaymentRequest.bill_ids = this.checkedBillIds;
    } else {
      this.confirmPaymentRequest.bill_ids = this.checkedBillIds;
      this.confirmPaymentRequest.renter_bill_ids = this.checkedRenterBillIds;
    }

    //取消房费优惠券勾选
    this.selectCouponInput = false;
    this.coupon_amount = 0;
    this.cal();

    this.actualAmountValidErrorContent = null;
  }

  /**
   * @Description:  收款  aliOrWeixin 不为空表示是微信或者支付宝支付
   * @Author: zhoujiahao
   * @Date: 2018-07-14  23:59
   */
  payment(paymentType?: any, dialog?: any, aliOrWeixin?: any, nowType?: string, accountMoneyInfoId?: number) {
    // dialog为空时表示0元收款，在上一步已经为idempotent_key赋值，这里如查赋值将会造成幂等失效
    if (dialog) {
      this.idempotentService.getIdempotentKey().then(data => {
        this.idempotent_key = data.idempotent_key;
      });
    }
    // modify by xulihua pms1.18 添加 同账单类型，不支持跨周期支付需求  start
    if (!nowType || "LEAVE" !== nowType) {
      // 当前选中的账单
      let nowCheckedBillIds = this.confirmPaymentRequest.bill_ids;
      let currentBillList = null;
      if (nowCheckedBillIds != null && nowCheckedBillIds.length > 0) {
        currentBillList = this.billList.filter(bill => bill.bill_id === nowCheckedBillIds.find(e => e === bill.bill_id));
      }
      let actualBillList = commonGroupBy(this.billList, item => item.bill_type_name);
      if (currentBillList != null && currentBillList.length > 0) {
        let groupBillList = commonGroupBy(currentBillList, item => item.bill_type_name);
        // 选中的 根据费用分组
        for (let i = 0; i < groupBillList.length; i++) {
          let handlerBillList = [];
          // 当前类型选中账单
          handlerBillList = groupBillList[i];
          // 当前类型选中账单总数
          let totalNum = handlerBillList.length;

          // 选中账单必须从第一个开始且连续（同一房间）
          // 根据房间分组
          let groupRoomBillList = commonGroupBy(handlerBillList, item => item.room_name);
          for (let j = 0; j < groupRoomBillList.length; j++) {
            // 该类型该房间的选中账单
            let roomBill = groupRoomBillList[j];
            let roomBillSize = roomBill.length;
            if (roomBillSize > 1) {
              // 该类型该房间 所有账单
              let typeRoomBillTotal = this.billList.filter(b => b.room_name === roomBill[0].room_name && b.bill_type_name === roomBill[0].bill_type_name);

              let sum = 0;
              typeRoomBillTotal.forEach((item, index) => {
                if (roomBill.find(b => b.bill_id === item.bill_id)) {
                  sum += (index + 1);
                }
              });
              // 顺序之和应该一致
              if (sum != (roomBillSize + 1) * roomBillSize / 2) {
                this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '同类型账单需按支付周期顺序支付，不支持跨周期支付！'));
                return;
              }
            } else {
              // 是不是第一个
              // 该类型该房间 所有账单
              let typeRoomBillFirst = this.billList.find(b => b.room_name === roomBill[0].room_name && b.bill_type_name === roomBill[0].bill_type_name);

              if (roomBill[0].bill_id !== typeRoomBillFirst.bill_id) {
                this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '同类型账单需按支付周期顺序支付！'));
                return;
              }

            }
          }


        }
      }
    }
    // modify by xulihua pms1.18 添加 同账单类型，不支持跨周期支付需求  end

    const req = {bill_type: 'SINGLE'};
    //实际付款金额
    if (!this.confirmPaymentRequest.deductible && !this.validTwoPoint(this.confirmPaymentRequest.actual_payment_amount)) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请输入正确的金额'));
      return;
    }
    this.confirmPaymentRequest.gather_type = this.expenseType;

    if (this.confirmPaymentRequest.bussiness_type !== 'CHECKOUT') {
      this.confirmPaymentRequest.room_ids = this.roomIds;
    }

    //将商户流水号置空
    this.confirmPaymentRequest.pay_log_no = null;
    let messageModalInfo = new MessageModalInfo();
    //处理其他费用 的收费类型

    if (this.expenseType !== 'NOLEASE' && (!this.confirmPaymentRequest.payment_total_amount
      || parseFloat(this.confirmPaymentRequest.payment_total_amount) < 0)) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '应付金额不能为空'));
      this.logService.error('payment方法：选中付款方式为' + this.expenseType + " 应付总金额confirmPaymentRequest.payment_total_amount="
        + this.confirmPaymentRequest.payment_total_amount);
      return;
    }
    //预定单 页面收取定金
    if (this.expenseType === 'NOLEASE') {
      if (!this.gatherOtherExpwnseChekck(true)) {
        return;
      }
      this.confirmPaymentRequest.room_ids = null;
    } else {
      if (+this.final_amount < 0) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '应付总计小于0，请重新勾选账单或者抵扣选项'));
        return;
      }
      //如果应付金额为空
      if (!this.confirmPaymentRequest.actual_payment_amount || parseFloat(this.confirmPaymentRequest.actual_payment_amount) <= 0) {
        //如果没有勾选预定金&&没有勾选优惠券
        if (!this.confirmPaymentRequest.deductible && !this.selectCouponInput) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请输入本次收取的金额'));
          return;
        } else {
          //如果付费类型为 其它
          if (this.expenseType === 'otherExpense') {
            if (!this.gatherOtherExpwnseChekck(true)) {
              return;
            }
          }
          if (this.checkedBillIds.length == 0 && this.expenseType == 'renterExpense') {
            this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请先勾选需要支付的账单'));
            return;
          }
          //如果勾选了使用优惠券，则判断输入金额是否>=(优惠券使用门槛-优惠券金额)
          if (this.selectCouponInput) {
            if (this.coupon_amount > 0) {
              if (this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount)
                .lessThan(this.changToDecimal(this.coupon_least_use_total + "").sub(this.changToDecimal(this.coupon_amount + "")))) {
                this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "本次收取金额应不少于" +
                  this.changToDecimal(this.coupon_least_use_total + "").sub(this.changToDecimal(this.coupon_amount + "")).toString()));
                return;
              }
            } else {
              this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请输入优惠券券号'));
              return;
            }
          }
          //收取预定金
          if (this.selectBookingAmount) {
            //如果预定金=收款金额
            if (this.changToDecimal(this.confirmPaymentRequest.payment_total_amount)
              .eq(this.changToDecimal(this.confirmPaymentRequest.book_amount))) {
              this.confirmPaymentRequest.actual_payment_amount = '0';
              messageModalInfo.operationMessage = "抵扣金额相等，无需再收款，是否全部抵扣？确认后不能取消";
              messageModalInfo.cancel = true;
              this.idempotentService.getIdempotentKey().then(data => {
                this.idempotent_key = data.idempotent_key;
                messageModalInfo.callback.subscribe(() => {
                  //直接跳到支付成功  定金直接抵扣
                  this.confirm(null, null, this.gatherSuccessDialog, 'bookAmountPayment');
                });
                this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
              });
              return;
            } else if (this.changToDecimal(this.confirmPaymentRequest.payment_total_amount)
              .lessThan(this.changToDecimal(this.confirmPaymentRequest.book_amount))) {
              //预定金金额大于应付总金额
              //预定金大于应支付金额
              messageModalInfo.operationMessage = "只有当收取金额大于等于定金时，才能抵扣！";
              this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
              // this.confirmPaymentRequest.deductible = false;
              return;
            }
          }
        }
      } else {
        //如果用户输入了金额
        //如果付费类型为 其它
        if (this.expenseType === 'otherExpense') {
          if (!this.gatherOtherExpwnseChekck(true)) {
            return;
          }
        } else {
          if (this.checkedBillIds.length == 0 && this.expenseType == 'renterExpense') {
            this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请先勾选需要支付的账单'));
            return;
          }
          //用户输入的金额不为空
          if (this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount)
            .add(this.changToDecimal(this.coupon_amount + ""))
            .greaterThan(this.changToDecimal(this.confirmPaymentRequest.payment_total_amount))) {
            //收取金额不能大于总金额
            messageModalInfo.operationMessage = "本次收取金额不能大于应付总金额";
            this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
            return;
          }
          //如果勾选了使用优惠券，则判断输入金额是否>=(优惠券使用门槛-优惠券金额)
          if (this.coupon_amount > 0) {
            if (this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount)
              .lessThan(this.changToDecimal(this.coupon_least_use_total + "").sub(this.changToDecimal(this.coupon_amount + "")))) {
              this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "本次收取金额应不少于" +
                this.changToDecimal(this.coupon_least_use_total + "").sub(this.changToDecimal(this.coupon_amount + "")).toString()));
              return;
            }
          }
        }
        //预定金抵扣
        if (this.confirmPaymentRequest.deductible) {
          if (this.changToDecimal(this.confirmPaymentRequest.actual_payment_amount)
            .add(this.confirmPaymentRequest.book_amount)
            .greaterThan(this.confirmPaymentRequest.payment_total_amount)) {
            //预定金大于应支付金额
            messageModalInfo.operationMessage = "只有当收取金额大于等于定金时，才能抵扣！";
            this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
            return;
          }
        }
      }
    }

    if (paymentType) {
      if (paymentType === "ACCOUNT_MONEY") {
        this.account_money_info_id = accountMoneyInfoId;
      }

      //获得付款类型以及 打开支付确认页面
      this.paymentType = paymentType;
      this.financeService.getPaymentType(req).then(res => {
        res.pay_types.forEach(data => {
          if (data.pay_type_code === paymentType) {
            this.confirmPaymentRequest.type_id = data.pay_type_id;
            this.paymentTypeName = data.pay_type_name;
          }
        });
      }).then(res => {
        this.aliOrWeixinDialog = dialog;
        if (aliOrWeixin) {
          this.showContent = false;
          this.confirmPaymentRequest.attached_list = [];
          this.clock = 120;
          this.timeOut();
        }
        this.bookAmountDialog.hide();
        this.gatherBillDialog.hide();
        this.noContractGatherBillDialog.hide();
        this.checkOutModel.hide();
        dialog.show();
      });
    } else {
      this.confirm(null, this.gatherBillDialog, this.gatherSuccessDialog, '0');
    }
  }

  /**
   * @Description:   收费类型为其它： 校验房间ID不能为空并且只能收取一个房间的费用
   * remind 是否提示用户
   * @Author: zhoujiahao
   * @Date: 2018-07-28  14:36
   */
  gatherOtherExpwnseChekck(remind: boolean): boolean {
    let remindMessage;
    if (!this.confirmPaymentRequest.bill_type_code) {
      remindMessage = '款项类型不能为空';
    }
    if (!this.confirmPaymentRequest.remark && this.confirmPaymentRequest.bill_type_code === 'OTHER') {
      remindMessage = '请输入备注信息';
    }
    //备注长度不能长于200
    if (this.confirmPaymentRequest.bill_type_code === 'OTHER'
      && this.confirmPaymentRequest.remark && this.confirmPaymentRequest.remark.length > 200) {
      remindMessage = '备注信息不能超过200字';
    }
    if (remindMessage && this.paymentleaseId) {
      if (remind) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, remindMessage));
      }
      return false;
    }
    if (this.expenseType !== 'NOLEASE') {
      //其它类型只能展示收取一个房间的费用
      if (!this.confirmPaymentRequest.room_ids || !this.roomIds.length || this.roomIds.length > 1 ||
        this.roomIds.includes(0)) {
        remindMessage = '选择其他费用收款时，只能选单个房间，且必须选一个房间';
      }
    }
    if (remindMessage) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, remindMessage));
      return false;
    }
    return true;
  }

  /**
   * @Description:  倒计时120秒
   * @Author: zhoujiahao
   * @Date: 2018-07-15  0:32
   */
  timeOut() {
    this.clock = this.clock - 1;
    if (this.clock === 0 || this.clock === -1) {
      this.clock = 0;
      this.showContent = true;

    } else if (this.clock > 0) {
      if (new RegExp(RegularExp.signlessInteger).test((this.clock / 5).toString())) {
        //五秒中查询一次账单对应的订单信息
        this.getBillOrderList();
      }
      setTimeout(() => this.timeOut(), 1000);
    }
  }

  /**
   * @Description:  确认收款
   * paymentType：支付类型
   * confirmDialog：支付确认弹框
   * paymentType：gatherSuccess 支付成功弹框
   * paymentType：预定金金额完全抵扣应付金额： 付款成功之后直接弹出收款成功页面      confirmDialog 此参数为空
   * @Author: zhoujiahao
   * @Date: 2018-07-15  1:06
   */
  confirm(paymentType?: any, confirmDialog?: any, gatherSuccess?: any, bookAmountPayment?: any): Promise<boolean> {
    this.confirmPaymentRequest.idempotent_key = this.idempotent_key;
    //如果是有账单收款，且勾选了优惠券,优惠券金额>0
    if (this.confirmPaymentRequest.gather_type === 'renterExpense' && this.selectCouponInput && this.coupon_flag && this.coupon_amount > 0) {
      this.confirmPaymentRequest.coupon_info_id = this.coupon_info.coupon_info_id;
    }
    //1 otherExpense 其它费用  不需要账单ID    2 renterExpense   房费、押金、水电等  账单ID在勾选账单ID是已经选好
    if (this.confirmPaymentRequest.gather_type !== 'otherExpense' &&
      this.confirmPaymentRequest.gather_type !== 'renterExpense') {
      this.confirmPaymentRequest.bill_ids = this.paymentInfoDTO.bill_ids;
    }

    if (paymentType === 'ACCOUNT_MONEY') {
      this.confirmPaymentRequest.account_money_info_id = this.account_money_info_id;
    } else {
      this.confirmPaymentRequest.account_money_info_id = null;
    }

    //预定金金额刚好抵扣应付金额
    if (bookAmountPayment) {
      let tempActualAmount = this.confirmPaymentRequest.actual_payment_amount;
      this.confirmPaymentRequest.actual_payment_amount = '0';
      //如果收款类型不是 otherExpense 正常收款
      if (this.confirmPaymentRequest.gather_type !== 'otherExpense') {
        this.confirmPaymentRequest.gather_type = 'NORMAL';
      }

      //调用收款接口
      this.billService.confirmGather(this.confirmPaymentRequest).then(res => {
        if (res.bill_id) {
          this.payId = res.bill_id;
        }
        this.close = 5;
        this.workService.event_bus.emit(new WorkModel(this.workService.type.SCHEDULE_TASK, this.confirmPaymentRequest.lease_id));
        gatherSuccess.show();
        this.gatherBillDialog.hide();
        this.setCloseTime(gatherSuccess);
        //支付成功后刷新 租约或者预定单页面
        //this.paymentCallback.emit();
      }).catch(error => {
        this.confirmPaymentRequest.actual_payment_amount = tempActualAmount;
        this.logService.error('PactComponent:确认付款' + JSON.stringify(error));
      });
    } else {
      if (paymentType === 'POS' || paymentType === 'TRANSFER' || paymentType === 'CHECK' || paymentType === 'CASH') {
        if (!this.confirmPaymentRequest.attached_list || this.confirmPaymentRequest.attached_list.length <= 0) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请上传付款凭证'));
          return;
        }
      }
      //实际付款金额
      if (!this.validTwoPoint(this.confirmPaymentRequest.actual_payment_amount)) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请输入正确的金额'));
        return;
      }
      //账单收款类型只有两种
      //账单收款其它费用传这个/其它费用
      // OTHEREXPENSE("otherExpense", "其它费用-无账单收款"),
      //账单收款确认时传这个
      // NORMAL("NORMAL", "正常收款"),;
      //NOLEASE("NOLEASE", "无合同收款"),
      if (this.confirmPaymentRequest.gather_type === 'renterExpense' && this.paymentleaseId) {
        if (parseFloat(this.confirmPaymentRequest.payment_total_amount) < 0) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '应收总金额不能小于0'));
          return;
        }
        this.confirmPaymentRequest.gather_type = 'NORMAL';
      } else if (this.confirmPaymentRequest.gather_type === 'NOLEASE') {
        this.confirmPaymentRequest.lease_id = null;
        if (!this.gatherOtherExpwnseChekck(true)) {
          return;
        }
      }
      //调用收款接口
      this.billService.confirmGather(this.confirmPaymentRequest).then(res => {
        if (res.bill_id) {
          this.payId = res.bill_id;
        }
        this.close = 5;
        confirmDialog.hide();
        gatherSuccess.show();
        this.setCloseTime(gatherSuccess);
        // if(this.gatherBookingAmount==='checkOut'){
        // 支付成功回调
        //退房结账
        this.callback.emit();
        // }
        //支付成功后刷新 租约或者预定单页面
        this.workService.event_bus.emit(new WorkModel(this.workService.type.SCHEDULE_TASK, this.confirmPaymentRequest.lease_id));
      }).catch(error => {
        this.logService.error('PactComponent:确认付款' + JSON.stringify(error));
      });
    }

  }

  setCloseTime(successDialog?: any) {
    this.close = this.close - 1;
    if (this.close === 0 || this.close === -1) {
      this.close = 0;
      successDialog.hide();
    } else if (this.close > 0) {
      setTimeout(() => this.setCloseTime(successDialog), 1000);
    }
  }

  /**
   * @Description:  附件
   * @Author: zhoujiahao
   * @Date: 2018-07-15  1:11
   */
  paymnetFill(imageList?: any) {
    this.confirmPaymentRequest.attached_list = imageList;
  }

  /**
   * @Description:   判断金额是否大于0
   * @Author: zhoujiahao
   * @Date: 2018-07-22  14:19
   */
  vaildAmount(amount?: any): boolean {
    if (!amount || parseFloat(amount) <= 0) {
      return false;
    }
    return true;
  }

  /**
   * @Description: 根据支付订单金额获取订单信息
   * @Author: zhoujiahao
   * @Date: 2018-07-22  16:46
   */
  getBillOrderList() {
    const reqParam = {pay_channel: this.paymentType, payment_amount: this.confirmPaymentRequest.actual_payment_amount};
    this.orderDtos = [];
    this.orderNum = 0;
    let type = this.paymentType;
    this.orderService.getBillOrderList(reqParam).then(res => {
      if (res && type === this.paymentType) {
        this.orderDtos = res.order_info_dtos;
        this.orderNum = this.orderDtos.length;
        if (this.orderNum > 0) {
          this.clock = 0;
          this.bindOrderBillDialog.show();
          this.aliOrWeixinDialog.hide();
        }
      }
    }).catch(error => {
      this.logService.error('PactComponent:根据付款金额获取订单信息失败' + JSON.stringify(error));
    });
  }

  /**
   * @Description:   确认订单和账单信息关联
   * @Author: zhoujiahao
   * @Date: 2018-07-22  17:06
   */
  reletedBillAndOrder(orderNo?: any, bindOrderBillDialog?: any, gatherSuccess?: any) {
    this.orderRelevance = true;
    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.operationMessage = "请确认此款项正确，确认后不能更改且立即入账";
    messageModalInfo.cancel = true;
    messageModalInfo.callback.subscribe(() => {
      this.confirmPaymentRequest.pay_log_no = orderNo;
      //付款类型为空
      this.confirm(this.paymentType, bindOrderBillDialog, gatherSuccess);
      this.orderRelevance = false;
    });
    messageModalInfo.cancelCallback.subscribe(() => {
      this.orderRelevance = true;
    });
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
  }

  /**
   * @Description:  显示通过商户订单号查询订单信息dialog
   * @Author: zhoujiahao
   * @Date: 2018-07-23  10:35
   */
  showQueryByOrderId(queryDialog?: any, needCloseDialog?: any) {
    this.orderId = null;
    this.orderRemind = null;
    if (needCloseDialog) {
      needCloseDialog.hide();
    }
    queryDialog.show();
  }

  /**
   * @Description:  通过订单号查询订单是否存在
   * @Author: zhoujiahao
   * @Date: 2018-07-23  10:39
   */
  queryByOrderId(queryShowDialog?: any, gatherSuccessDialog?: any) {
    this.orderRemind = null;
    if (!this.orderId) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请输入商户订单号'));
      return;
    }
    const reqParam = {
      pay_channel: this.paymentType,
      payment_amount: this.confirmPaymentRequest.actual_payment_amount,
      order_id: this.orderId
    };
    this.orderService.getBillOrderList(reqParam).then(res => {
      if (res) {
        this.orderDtos = res.order_info_dtos;
        if (res.order_info_dtos.length === 1) {
          this.confirmPaymentRequest.pay_log_no = this.orderDtos[0].order_no;
          this.confirm(this.paymentType, queryShowDialog, gatherSuccessDialog);
        }
      } else {
        this.orderRemind = '未查到该订单号，请确认订单号是否正确';
      }
    }).catch(error => {
      this.logService.error('PactComponent:根据订单和金额查询订单时失败' + JSON.stringify(error));
    });
  }

  /**
   * @Description:  收取预定金
   * @Author: zhoujiahao
   * @Date: 2018-07-23  11:37
   */
  gatherBookAmount(leaseId?: any, contractNum?: string) {
    this.changeValueToNull();
    this.gatherBookingAmount = 'book';
    this.contractNum = contractNum;
    const reqParam = {lease_id: leaseId, gather_type: 'NORMAL'};
    this.confirmPaymentRequest.lease_id = leaseId;
    this.billService.gatherAmountDtail(reqParam).then(res => {
      if (res) {
        this.canBookAmountUseFlag = res.can_book_amount_use && res.can_book_amount_use === 1;
        this.paymentInfoDTO = res;
        this.confirmPaymentRequest.payment_total_amount = this.paymentInfoDTO.payment_total_amount;
        this.confirmPaymentRequest.actual_payment_amount = this.confirmPaymentRequest.payment_total_amount;
        this.expenseType = 'NORMAL';
        this.confirmPaymentRequest.gather_type = this.expenseType;
        this.bookAmountDialog.show();
      } else {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '无待收款账单'));
        return;
      }
    }).catch(error => {
      this.logService.error('PactComponent:预定单获取定金账单详情');
    });
  }

  /**
   * @Description:  退款结账收款
   * @Author: zhoujiahao
   * @Date: 2018-07-23  11:37
   */
  gatherCheckOutAmount(leaseId: any, billIds?: any, amount?: any, type?: string, contractNum?: string, couponRecycleAmount?) {
    if (!amount || this.changToDecimal(amount).lessThan(new Decimal('0'))) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '收款金额不能为0'));
      return;
    }
    if (!billIds || billIds.length <= 0) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '账单不能为空'));
      return;
    }
    if (!leaseId) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '租约ID不能为空'));
      return;
    }
    this.changeValueToNull();
    this.gatherBookingAmount = 'checkOut';
    this.confirmPaymentRequest.lease_id = leaseId;
    this.confirmPaymentRequest.payment_total_amount = amount;
    this.confirmPaymentRequest.actual_payment_amount = amount;
    this.expenseType = 'NORMAL';
    this.confirmPaymentRequest.gather_type = this.expenseType;
    this.confirmPaymentRequest.bussiness_type = type;
    this.paymentInfoDTO.bill_ids = billIds;
    this.confirmPaymentRequest.bill_ids = billIds;
    this.contractNum = contractNum;

    this.confirmPaymentRequest.recycle_coupon_amount = couponRecycleAmount;

    this.checkOutModel.show();
  }

  /**
   * @Description:  退款结账收款
   * @Author: zhoujiahao
   * @Date: 2018-07-23  11:37
   */
  gatherCheckOutAmountWithPrepay(leaseId: any, roomIds?: any, leaseRoomIds?: any, billIds?: any, amount?: any, type?: string, contractNum?: string, checkoutId?: any) {
    if (!amount || this.changToDecimal(amount).lessThan(new Decimal('0'))) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '收款金额不能为0'));
      return;
    }
    if (!leaseId) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '租约ID不能为空'));
      return;
    }
    this.changeValueToNull();
    this.gatherBookingAmount = 'checkOut';
    this.confirmPaymentRequest.lease_id = leaseId;
    this.confirmPaymentRequest.payment_total_amount = amount;
    this.confirmPaymentRequest.actual_payment_amount = amount;
    this.expenseType = 'NORMAL';
    this.confirmPaymentRequest.gather_type = this.expenseType;
    this.confirmPaymentRequest.bussiness_type = type;
    this.paymentInfoDTO.bill_ids = billIds;
    this.confirmPaymentRequest.bill_ids = billIds;
    this.confirmPaymentRequest.room_ids = roomIds;
    this.confirmPaymentRequest.lease_room_ids = leaseRoomIds;
    this.confirmPaymentRequest.checkout_id = checkoutId;
    this.contractNum = contractNum;
    this.getAccountLease();
    this.checkOutModel.show();
  }


  /**
   * @Description:  支付宝、微信支付倒计时页面关闭
   * @Author: zhoujiahao
   * @Date: 2018-07-26  15:14
   */
  closeAliOrWei(closeAliOrWei?: any) {
    this.clock = 0;
    closeAliOrWei.hide();
  }

  /**
   * @Description:   校验只能含有两个小数点
   * @Author: zhoujiahao
   * @Date: 2018-07-26  15:33
   */
  validTwoPoint(amount?: any): boolean {
    if (amount) {
      if (this.confirmPaymentRequest.deductible && this.confirmPaymentRequest.payment_total_amount ===
        this.confirmPaymentRequest.book_amount
        && this.confirmPaymentRequest.actual_payment_amount === '0') {
        return true;
      }
      //如果勾选了优惠券，抵扣之后应付为0，也能通过校验
      if (this.selectCouponInput && this.confirmPaymentRequest.actual_payment_amount === '0') {
        return true;
      }
      if (/^\d+(\.\d{0,2})?$/.test(amount)) {
        let s = parseFloat(amount);
        if (s < 0.01 || s > 10000000) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * @Description:  关闭通过商户订单号查询订单的页面
   * @Author: zhoujiahao
   * @Date: 2018-07-26  18:05
   */
  closeOrderQueryDialog(orderQueryDialog?: any) {
    this.orderId = null;
    this.orderRemind = null;
    orderQueryDialog.hide();
  }


  //打印支付凭证
  printPaymentCret() {
    if (this.confirmPaymentRequest.deductible) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '预定金抵扣无法打印凭证'));
      return;
    }
    this.payLogDetailComponent.printShow(this.confirmPaymentRequest.lease_id, this.payId);
  }

  /**
   * @Description:  关闭收款页面： 需要将所有参数清空
   * @Author: zhoujiahao
   * @Date: 2018-07-28  14:54
   */
  closeGatherDialog(gatherBill?: any) {
    this.changeValueToNull();
    this.contactNum = null;
    this.billList = [];
    gatherBill.hide();
  }

  /**
   * 关闭退款申请页面。
   * @param refundBillModel
   */
  closeRefundDialog(refundBillModel?: any) {
    this.changeValueToNull();
    this.contactNum = null;
    this.billList = [];
    this.refundApplyRequest = new RefundApplyRequest();
    this.refundApplyRequest.audit_phone = 'ALL';
    this.refundApplyRequest.apply_type = 'LEASE';
    refundBillModel.hide();
  }

  /**
   * 关闭预定金退款申请页面
   * @param refundBillModel
   */
  closeRefundBookingDialog(refundBookingModel?: any) {
    refundBookingModel.hide();
  }

  closeRefundConfirmDialog(refundBillModel?: any) {
    this.refundApplyRequest.audit_phone = "ALL";
    this.refundApplyRequest.audit_code = null;
    refundBillModel.hide();
  }

  /**
   * @Description:   传进一个字符串金额：转换成两个小数点的Decimal类型
   * @Author: zhoujiahao
   * @Date: 2018-07-30  16:20
   */
  changToDecimal(amount?: string): Decimal {
    return new Decimal(this.getTwoPoint(new Decimal(amount)));
  }

  /**
   * @Description:  保留两位小数点
   * @Author: zhoujiahao
   * @Date: 2018-07-30  16:31
   */
  getTwoPoint(data?: Decimal): string {
    return (Math.ceil(new Decimal(data).mul("100").toNumber()) / 100).toString();
  }

  clearRemark() {
    if (this.confirmPaymentRequest.bill_type_code !== 'OTHER') {
      this.confirmPaymentRequest.remark = null;
      this.otherRemark = null;
    }
    if (this.parkingType == "0") {
      this.isPakringTypeError = true;
    } else {
      this.isPakringTypeError = false;
    }
  }

  checkPlateNumber() {
    if (this.parkingType == "MONTHLY_CARD" && (this.plateNumber == null || this.plateNumber == "")) {
      this.isPlateNumberError = true;
      return;
    } else {
      this.isPlateNumberError = false;
    }
    if (this.plateNumber.trim().length > 8) {
      this.isPlateLengthError = true;
    } else {
      this.isPlateLengthError = false;
    }
  }

  parkingType: string = "0";
  plateNumber: string;

  changeBillType() {
    if (this.otherBillType == 'PARKING') {
      this.isPakringTypeError = false;
      this.isPlateNumberError = false;
      this.isShow = true;
    } else {
      this.isPakringTypeError = false;
      this.isPlateNumberError = false;
      this.isShow = false;
    }
    this.createPreviewList();
  }

  /**
   * 创建账单
   */
  createBill() {
    if (this.payerType !== 'RENTER') {
      if (!this.checkParam()) {
        return;
      }
      let createBillRequest = new CreateBillRequest();
      createBillRequest.payer_type = 'SUBJECT';
      createBillRequest.leaseId = this.paymentleaseId;
      createBillRequest.actual_payment_amount = this.otherPayAmount;
      createBillRequest.billTypeCode = this.otherBillType;
      createBillRequest.start_time = getTimeFromPlugin(this.startTime_obj.formatted);
      createBillRequest.end_time = getTimeFromPlugin(this.endTime_obj.formatted);
      if (this.otherBillType == "PARKING") {
        createBillRequest.parking_type = this.parkingType;
        createBillRequest.plate_number = this.plateNumber;
      }
      createBillRequest.remark = this.otherRemark;
      createBillRequest.room_ids = new Array<number>();
      for (let i = 0; i < this.roomIds.length; i++) {
        if (this.roomIds[i] != 0) {
          createBillRequest.room_ids.push(this.roomIds[i]);
        }
      }
      // this.idempotent_key = data.idempotent_key;
      createBillRequest.idempotent_key = this.idempotent_key;
      this.billService.createBill(createBillRequest).then(res => {
        if (res != null && res.status_code) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, res.message));
        } else {
          //创建成功
          this.changeGatherType('renterExpense');
        }
      });
    } else {
      let createBillRequest = new CreateBillRequest();
      createBillRequest.payer_type = 'RENTER';
      createBillRequest.leaseId = this.paymentleaseId;
      createBillRequest.idempotent_key = this.idempotent_key;
      let renterCreateBillDTOS = new Array<RenterCreateBillDTO>();
      this.renterBillPreviewList.forEach(e => {
        let renterCreateBill = new RenterCreateBillDTO();
        renterCreateBill.bill_type_code = e.bill_type_code;
        renterCreateBill.bill_amount = e.bill_amount;
        renterCreateBill.renter_id = e.renter_id;
        renterCreateBill.room_id = e.room_id;
        renterCreateBill.remark = e.remark;
        renterCreateBill.start_time = e.startTime_obj.getTime();
        renterCreateBill.end_time = e.endTime_obj.getTime();
        renterCreateBillDTOS.push(renterCreateBill);
      });
      createBillRequest.renter_create_bills = renterCreateBillDTOS;
      this.billService.createBill(createBillRequest).then(res => {
        if (res != null && res.status_code) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, res.message));
        } else {
          //创建成功
          this.changeGatherType('renterExpense');
        }
      });
    }
  }

  checkParam() {
    if (this.roomIds == null || this.roomIds.length <= 0) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请选择房间'));
      return false;
    }

    if (this.otherBillType == "0") {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请选择账单类型'));
      return false;
    }

    if (this.otherPayAmount == null || this.otherPayAmount == "") {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请输入收款金额'));
      return false;
    }

    if (!this.validAmount(this.otherPayAmount)) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请输入0~99999元的收款金额'));
      return false;
    }

    if (this.startTime_obj == null || this.endTime_obj == null) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请选择费用所属周期'));
      return false;
    }

    if (this.endTime_obj && this.startTime_obj
      && getTimeFromPlugin(this.endTime_obj.formatted + " 23:59:59")
      < getTimeFromPlugin(this.startTime_obj.formatted + " 00:00:00")) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '开始日期不能大于结束日期'));
      return false;
    }

    if (this.startTime_obj == null || this.endTime_obj == null) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请选择费用所属周期'));
      return false;
    }

    if (this.otherBillType == "OTHER" && (this.otherRemark == null || this.otherRemark == "")) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '其他费用需要填写备注'));
      return false;
    }

    if (this.otherBillType == "PARKING" && this.parkingType == "0") {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请选择停车类型'));
      return false;
    }

    if (this.otherBillType == "PARKING" && this.parkingType == "MONTHLY_CARD" && (this.plateNumber == null || this.plateNumber == "")) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请填写车牌号'));
      return false;
    }

    if (this.otherBillType == "PARKING" && this.plateNumber.trim().length > 8) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '车牌号最多可输入8位字符'));
      return false;
    }

    return true;
  }

  validAmount(amount: any) {
    if (/^\d+(\.\d{0,2})?$/.test(amount)) {
      let s = parseFloat(amount);
      if (s < 0.01 || s > 99999) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  /**
   * 选择账单类型
   */
  selectBillingType(event: any, type?: any) {
    let value = event.target.value;
    if (value && value > 0) {
      this.selectBillType = value;
      this.expenseType = 'renterExpense';
      if (type && type === 'REFUND') {

        this.canRefundBillDetail(this.expenseType, value);
      } else {
        this.paymentDetail(this.expenseType, value);
      }
    } else {
      this.selectBillType = 0;
      this.expenseType = 'renterExpense';
      if (type && type === 'REFUND') {
        this.canRefundBillDetail(this.expenseType);
      } else {
        this.paymentDetail(this.expenseType);
      }
    }
  }

  selectChargeOffStatus(event: any, type?: any) {
    let value = event.target.value;
    this.bill_charge_off_status = value;
    this.selectBillStatus = value;
    if (type && type === 'REFUND') {
      this.canRefundBillDetail(this.expenseType);
    } else {
      if (this.selectBillType && this.selectBillType > 0) {
        this.paymentDetail(this.expenseType, this.selectBillType);
      } else {
        this.paymentDetail(this.expenseType);
      }
    }
  }


  /**
   * @Description:  有合同和无合同收款切换
   * changeType  true表示有合同收款，false表示无合同收款
   * @Author: zhoujiahao
   * @Date: 2018-09-17  15:59
   */
  changeContactGather(changeType?: boolean) {
    this.showTips = false;
    this.actualAmountValidErrorContent = null;
    this.confirmPaymentRequest.actual_payment_amount = null;
    this.actualAmount = null;
    this.paymentleaseId = null;
    this.contactNum = null;
    if (changeType) {
      //如果是有合同收款 非其它费用
      this.expenseType = 'renterExpense';
      this.paymentDetail(this.expenseType);
    } else {
      this.expenseType = 'NOLEASE';
    }
    this.hasContact = changeType;

  }

  /*
  * 有合同，其它费用收款或者有账单收款
  */
  billPreviewList: Array<LeaseRoomPreviewBillListDTO>;
  otherBillType: string = "0";
  isPakringTypeError: boolean = false;
  isPlateNumberError: boolean = false;
  isPlateLengthError: boolean = false;

  createPreviewList() {
    this.billPreviewList = [];
    if (!this.endTime_obj || !this.startTime_obj) {
      return;
    }

    if (this.endTime_obj && this.startTime_obj
      && getTimeFromPlugin(this.endTime_obj.formatted + " 23:59:59")
      < getTimeFromPlugin(this.startTime_obj.formatted + " 00:00:00")) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '开始日期不能大于结束日期'));
      return;
    }

    if (!this.otherBillType) {
      return;
    }
    if (!this.otherPayAmount) {
      return;
    }
    if (!this.validAmount(this.otherPayAmount)) {
      return;
    }
    for (let i = 0; i < this.roomIds.length; i++) {
      if (this.roomIds[i] == 0) {
        continue;
      }
      let leaseRoomPreviewBillListDTO = new LeaseRoomPreviewBillListDTO();
      leaseRoomPreviewBillListDTO.room_id = this.roomIds[i];
      for (let j = 0; j < this.roomInfoDtos.length; j++) {
        if (this.roomIds[i] == this.roomInfoDtos[j].room_id) {
          leaseRoomPreviewBillListDTO.room_name = this.roomInfoDtos[j].room_name;
          break;
        }
      }
      leaseRoomPreviewBillListDTO.startTime_obj = getDateFromPlugin(this.startTime_obj.formatted + " 00:00:00");
      leaseRoomPreviewBillListDTO.endTime_obj = getDateFromPlugin(this.endTime_obj.formatted + " 23:59:59");
      leaseRoomPreviewBillListDTO.bill_amount = this.otherPayAmount;
      for (let k = 0; k < this.billTypeList.length; k++) {
        if (this.billTypeList[k].bill_type_code == this.otherBillType) {
          leaseRoomPreviewBillListDTO.bill_type_name = this.billTypeList[k].bill_type_name;
          break;
        }
      }
      this.billPreviewList.push(leaseRoomPreviewBillListDTO);
    }
  }

  timeChange(event: any) {
    if (this.payerType === 'RENTER') {
      if (this.startTime_obj) {
        let date = getDateFromPlugin(this.startTime_obj.formatted);
        if(this.endTime_obj){
          let edate = getDateFromPlugin(this.endTime_obj.formatted);
          if(edate.getTime() < date.getTime()){
            this.endTime_obj = {
              date: {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate()
              },
              formatted: formatYYYYmmdd(date)
            };
          }
        }
        date = addDate(date,-1);
        let checkoutdate = new Date(32535100800000);
        let future = new Date(32535100800000);
        if (this.selectRenter.checkout_time) {
          checkoutdate = addDate(new Date(this.selectRenter.checkout_time), 1);
        }
        this.endDatePickerOptions = {
          dateFormat: "yyyy-mm-dd",
          editableDateField: true,
          showClearDateBtn: true,
          disableDateRanges: [{
            begin: {year: 1970, month: this.tomorrow.getMonth() + 1, day: this.tomorrow.getDate()},
            end: {year: date.getFullYear(), month: (date.getMonth() + 1), day: date.getDate()}
          }, {
            begin: {year: checkoutdate.getFullYear(), month: checkoutdate.getMonth() + 1, day: checkoutdate.getDate()},
            end: {year: future.getFullYear(), month: (future.getMonth() + 1), day: future.getDate()}
          }]
        };

      }
    }
  }

  otherClearInput() {
    this.isShow = false;
    this.selectRoomNames = ['请选择房间'];
    this.otherRemark = "";
    this.roomIds = [];
    this.otherBillType = "0";
    this.otherPayAmount = "";
    this.actualAmount = "";
    this.parkingType = "0";
    this.plateNumber = "";
    this.isPakringTypeError = false;
    this.isPlateNumberError = false;
    this.startTime_obj = null;
    this.endTime_obj = null;
    this.billPreviewList = [];
    this.renterBillPreviewList = [];
    this.renterBillRemark = null;
    this.selectRenterId = 0;
    this.selectRenterName = null;
    this.selectRenter = null;
    this.selectRenterRoom = 0;
    this.selectRenterRoomName = null;
    this.renterBillType = '';
    this.selectBillTypeName = null;
    this.renterInfoList = [];
    this.startDatePickerOptions = {
      dateFormat: "yyyy-mm-dd",
      editableDateField: true,
      showClearDateBtn: true,
    };
    this.endDatePickerOptions = {
      dateFormat: "yyyy-mm-dd",
      editableDateField: true,
      showClearDateBtn: true,
    };
  }

  changeGatherType(expenseType?: string) {
    if (expenseType === 'otherExpense') {
      this.otherClearInput();
    }

    if (expenseType === 'renterExpense') {
      this.selectRoomNames = ['全部房间'];
      this.selectBillType = 0;
      for (let i = 0; i < this.roomInfoDtos.length; i++) {
        this.roomIds.push(this.roomInfoDtos[i].room_id);
      }
    }

    this.actualAmountValidErrorContent = null;
    this.confirmPaymentRequest.actual_payment_amount = null;
    this.actualAmount = null;
    this.expenseType = expenseType;
    this.selectCouponInput = false;
    this.coupon_amount = 0;
    this.coupon_number = null;
    this.idempotentService.getIdempotentKey().then(data => {
      this.idempotent_key = data.idempotent_key;
    });
    this.paymentDetail(this.expenseType);
  }

  /*        收款弹框页面  结束      */

  setChange(value?: any) {
    if (value) {
      this.noPaymentSet = true;
    } else {
      this.noPaymentSet = false;
    }
  }

  /**
   * 点击获取验证码
   */
  clickToGet() {
    this.billService.overdueBillAuditSmsSend({
      user_id: this.selectedUserPhone,
      lease_id: this.leaseId
    }).then(res => {
      this.smsSendClock = 60;
      this.smsSendTimeOut();
    }).catch(error => {

    });
  }

  /**
   * 逾期金账单设置为“无需付”
   */
  confirmThatNoPaymentIsRequired() {
    let billSize = this.checkedBillIds.length;
    if (this.overdueType && this.overdueContent) {
      this.billService.overdueBillNoNeedPay({
        bill_id_list: this.checkedBillIds,
        user_phone: this.selectedUserPhone,
        identifying_code: this.shortMessageVerificationCode,
        overdue_type: this.overdueType,
        overdue_content: this.overdueContent,
      }).then(response => {
        let data = response.result;
        if (data && data === '执行成功') {
          let messageModalInfo = new MessageModalInfo();
          messageModalInfo.operationMessage = billSize + '笔滞纳金账单已成功设置为无需付';
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
          this.closeGatherDialog(this.gatherBillDialog);
          this.changeValueToNull();
          this.overdueType = null;
          this.overdueContent = null;
        } else {
          let messageModalInfo = new MessageModalInfo();
          messageModalInfo.operationMessage = data;
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
        }
        this.paymentCallback.emit();
      }).catch(eror => {

      });
    }
  }

  smsSendTimeOut() {
    this.smsSendClock -= 1;
    if (this.smsSendClock === 0 || this.smsSendClock === -1) {
      this.smsSendClock = 0;
      this.showSmsSendButton = true;

    } else if (this.smsSendClock > 0) {
      setTimeout(() => this.smsSendTimeOut(), 1000);
    }
  }

  getAuditList() {
    this.signedService.leaseAuditCheckoutList().then(data => {
      this.auditList = data.audit_user;
    });
  }

  getAccountLease(leaseId?: number) {
    if (leaseId) {
      this.queryAccountLeaseReq.lease_id = leaseId;
    } else {
      this.queryAccountLeaseReq.lease_id = this.leaseId;
    }
    this.clientInfoService.queryAccountLease(this.queryAccountLeaseReq).then(data => {
      this.queryAccountLeaseResponse = data;
    });
  }

  getRefundAuditUserList() {
    this.signedService.billRefundAuditUserList().then(data => {
      this.refundAuditUserList = data.audit_user;
    });
  }

  getCode() {
    if (this.time === 60) {
      this.time = 0;
      this.SMSAuditCodeRequest.code_type = 18;
      this.SMSAuditCodeRequest.lease_id = this.leaseId;
      this.SMSAuditCodeRequest.mobile = this.selectedUserPhone;
      this.signedService.leaseAuditCheckoutAuditSMS(this.SMSAuditCodeRequest).then(data => {
        this.time = 60;
        this.inter = setInterval(() => {
          if (--this.time > 0) {
            this.codeBtnStr = this.time + 's后重新获取';
          } else {
            this.time = 60;
            this.codeBtnStr = '点击获取';
            clearInterval(this.inter);
          }
        }, 1000);
      }).catch(() => {
        this.time = 60;
      });
    }
  }

  getBillTypeList(type?: string) {

    this.baseInfoService.getBillTypeListl({type: type}).then(res => {
      this.billTypeList = res.bill_types;
      if (this.billTypeList) {
        this.billTypeList = this.billTypeList.filter(billType => billType.bill_type_name !== '水电费' &&
          billType.bill_type_code !== 'RENTER_DEPOSIT' && billType.bill_type_code !== 'RENTER_COMPENSATION');
        this.refundBillTypeList =
          this.billTypeList.filter(billType => (billType.bill_type_name === '房费'
            || billType.bill_type_name === '押金' || billType.bill_type_name === '预定金' || billType.bill_type_code === 'BROADBANDFEE'));
      }

    });
  }

  getRenterBillTypeList(){
    this.baseInfoService.getRenterBillTypeList().then(data => {
      this.renterBillTypeList = data.bill_type_list;
    });
  }

  /**
   * 确认请求申请退款，弹窗确认验证码
   */
  confirmRefundRequest(model: any) {
    if (this.canRefundNextError && this.modeOfPayment === 'TRANSFER') {
      return;
    } else {
      this.refundApplyRequest.audit_phone = 'ALL';
      this.refundApplyRequest.apply_type = 'LEASE';
      model.show();
    }
  }

  /**
   * 当前房间已收/部分收款
   * @param leaseType
   * @param leaseValue
   */
  enterRefundLeaseId(leaseType?: any, leaseValue?: any) {
    this.actualAmountValidErrorContent = null;
    let allRoomInfoDtoItem = new RoomInfoDTO();
    allRoomInfoDtoItem.room_id = 0;
    allRoomInfoDtoItem.room_name = '全部房间';
    this.roomInfoDtos = [];
    this.roomInfoDtos.push(allRoomInfoDtoItem);
    const reqDate = {lease_room_query_type: leaseType, lease_value: leaseValue};
    this.bookingService.getLeaseRoomList(reqDate).then(res => {
      // 拉取房间
      if (!res) {
        this.errorContent = "无效的合同号，请重新输入";
        this.roomInfoDtos = [];
        this.showTips = true;
        return;
      }
      this.roomIds = [];
      this.paymentleaseId = res.lease_id;
      this.leaseId = res.lease_id;
      this.contactNum = res.contact_num;
      // 收款确认请求参数
      this.confirmPaymentRequest.lease_id = this.paymentleaseId;
      // 错误提示置空
      this.errorContent = null;
      // 房源下拉列表
      res.room_info_list.forEach(e => {
        this.roomInfoDtos.push(e);
      });
      this.roomIds = [0];
      if (this.expenseType) {
        //输入合同的同时也刷新下面的数据
        this.canRefundBillDetail(this.expenseType);
      } else {
        this.refundApplyRequest = new RefundApplyRequest();
        this.refundApplyRequest.audit_phone = 'ALL';
        this.refundApplyRequest.apply_type = 'LEASE';
        this.refundBillDialog.show();
      }
    }).catch(e => {
      this.errorContent = "无效的合同号，请重新输入";
      this.logService.error('PactComponent:获取租约下的房间失败' + JSON.stringify(e));
      this.showTips = true;
      return;
    });
  }

  /**
   * 可退款账单列表
   * @param expenseType
   * @param billTypeId
   */
  canRefundBillDetail(expenseType?: string, billTypeId?: number): string {
    //将参数置空
    this.changeValueToNull();
    this.refundApplyRequest.lease_id = this.paymentleaseId;
    this.refundApplyRequest.payment_total_amount = '0';
    this.expenseType = expenseType;
    this.gatherAmountDetailRequestParam.refundable_bill = "REFUND_BILL";
    this.gatherAmountDetailRequestParam.gather_type = this.expenseType;
    this.gatherAmountDetailRequestParam.lease_id = this.paymentleaseId;
    if (billTypeId) {
      this.gatherAmountDetailRequestParam.bill_type_ids = [billTypeId];
    } else {
      this.gatherAmountDetailRequestParam.bill_type_ids = this.billTypeList.filter(billType => (billType.bill_type_name === '房费'
        || billType.bill_type_name === '押金' || billType.bill_type_name === '预定金'
        || billType.bill_type_code === 'BROADBANDFEE')).map(billType => billType.bill_type_id);
    }
    if (!this.roomIds.includes(0)) {
      this.gatherAmountDetailRequestParam.room_ids = this.roomIds;
    } else {
      this.gatherAmountDetailRequestParam.room_ids = [];
    }
    if (expenseType === 'otherExpense' && (!this.roomIds || this.roomIds.length !== 1 || this.roomIds.includes(0)) && this.paymentleaseId) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '选择其他费用收款时，只能选单个房间，且必须选一个房间'));
      return;
    }
    this.refundApplyRequest.room_ids = this.roomIds;
    //房费、押金、水电等
    this.billService.gatherAmountDtail(this.gatherAmountDetailRequestParam).then(res => {
      this.refundApplyRequest = new RefundApplyRequest();
      this.refundApplyRequest.audit_phone = 'ALL';
      this.refundApplyRequest.apply_type = 'LEASE';
      this.allBillIds = [];
      if (res) {
        this.allBillIds = res.lease_room_bill_list.map(r => r.bill_id);
        this.billList = res.lease_room_bill_list;
        this.refundApplyRequest.gather_type = this.expenseType;
        this.paymentedTotalAmount = res.paymented_amount;
        if (!this.gatherByBillId) {
          this.checkedBillIds = [];
        } else {
          if (this.allBillIds.lastIndexOf(this.gatherByBillId) < 0) {
            //如果当前账单不含有用户勾选的账单id
            this.checkedBillIds = [];
          } else {
            this.billList.forEach(e => {
              if (e.bill_id === this.gatherByBillId) {
                this.refundApplyRequest.payment_total_amount = e.waiting_pay_amount;
                this.checkedBillIds.push(this.gatherByBillId);
                this.refundApplyRequest.bill_ids = this.checkedBillIds;
                this.refundApplyRequest.actual_payment_amount = e.waiting_pay_amount;
                this.ckeckedNum += 1;
              }
            });
          }
          this.setChecked();
          this.gatherByBillId = null;
        }
        this.refundBillDialog.show();
      }
    }).catch(error => {
      this.logService.error('PactComponent:获取账单列表信息失败');
      return;
    });
    this.showTips = false;
  }

  /**
   * 账单申请退款，选中/取消选中处理
   * @param event
   * @param billId
   * @param receiveAmount
   * @param billTypeName
   */
  checkRefundCurrentBill(event?: any, billId?: any, receiveAmount?: any, billTypeName?: string) {
    //当前账单DTO
    let currentBill = this.billList.find(bill => bill.bill_id === billId);
    //用户输入金额
    let currentActualAmount = this.changToDecimal("0");
    if (this.refundApplyRequest.actual_payment_amount) {
      currentActualAmount = this.changToDecimal(this.refundApplyRequest.actual_payment_amount);
    }
    // 待退款金额
    let waitingAmountCheck = this.changToDecimal(receiveAmount);
    // 如果是勾选了账单
    if (event.target.checked) {
      if (this.refundApplyRequest.payment_total_amount) {
        this.refundApplyRequest.payment_total_amount =
          //保留两位小数点
          this.getTwoPoint(this.changToDecimal(this.refundApplyRequest.payment_total_amount).add(waitingAmountCheck));
      } else {
        this.refundApplyRequest.payment_total_amount = this.getTwoPoint(waitingAmountCheck);
      }

      this.refundApplyRequest.actual_payment_amount =
        this.getTwoPoint(currentActualAmount.add(waitingAmountCheck));
      this.ckeckedNum += 1;
      this.checkedBillIds.push(billId);
    } else {
      //取消选中
      //用户输入了金额
      if (this.refundApplyRequest.actual_payment_amount
        && this.changToDecimal(this.refundApplyRequest.actual_payment_amount).greaterThan("0")) {
        //取消后的账单金额>实际支付金额 此处的实际支付金额是用户付的
        if (waitingAmountCheck.greaterThan(currentActualAmount)) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "收款金额不能大于应付金额"));
          event.target.checked = true;
          return;
        }
      } else {
        if (currentActualAmount.lessThan(receiveAmount)) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "应付金额不能小于0"));
          event.target.checked = true;
          return;
        }
      }
      this.refundApplyRequest.payment_total_amount =
        this.getTwoPoint(this.changToDecimal(this.refundApplyRequest.payment_total_amount).sub(waitingAmountCheck));
      this.refundApplyRequest.actual_payment_amount =
        this.getTwoPoint(currentActualAmount.sub(waitingAmountCheck));
      this.ckeckedNum -= 1;
      this.checkedBillIds = removeFromArray(this.checkedBillIds, billId);
    }
    //如果为选中账单
    if (this.checkedBillIds.length <= 0) {
      this.refundApplyRequest.actual_payment_amount = '0';
    }
    this.refundApplyRequest.bill_ids = this.checkedBillIds;
    this.setChecked();
  }

  /**
   * 申请退款账单全选功能
   * @param event
   */
  checkRefundBillAll(event?: any) {
    if (event.target.checked) {
      // 全选
      this.checkedBillIds = this.allBillIds;
      this.ckeckedNum = this.allBillIds.length;
      if (this.paymentedTotalAmount) {
        let total = new Decimal(0);
        this.billList.forEach(bill => {
          total = total.add(bill.can_refund);
        });
        this.refundApplyRequest.payment_total_amount = total.toString();
        this.refundApplyRequest.actual_payment_amount = this.refundApplyRequest.payment_total_amount;
      }
    } else {
      this.checkedBillIds = [];
      this.ckeckedNum = 0;
      this.refundApplyRequest.payment_total_amount = null;
      this.refundApplyRequest.actual_payment_amount = '0';
    }
    this.refundApplyRequest.bill_ids = this.checkedBillIds;
  }

  /**
   * 选择转账类型
   */
  modeOfPaymentSet(evetn: any, type: string) {
    if (evetn.target.checked) {
      this.modeOfPayment = type;
      if (type && type === 'CASE') {
        this.refundApplyRequest.gather_value = null;
        this.refundApplyRequest.bank_name = null;
        this.refundApplyRequest.card_number = null;
      }
    } else {
      this.modeOfPayment = '';
    }
  }

  /**
   * 提交 退款申请
   */
  confirmRefundSubmit() {
    if (this.refundApplyRequest.audit_phone !== 'ALL ' && this.refundApplyRequest.audit_code) {
      this.refundApplyRequest.mode_of_payment = this.modeOfPayment;
      this.refundApplyRequest.lease_id = this.leaseId;
      this.refundListService.renfundGatherHandler(this.refundApplyRequest).then(response => {
        if (response && response.result === '短信验证码验证错误') {
          this.auditCodeError = true;
        } else if (response && response.result === '执行成功') {
          this.resultRefundNo = response.refund_no;
          this.refundBillConfirmDialog.hide();
          this.refundBillConfirmSuccessDialog.show();
          this.paymentCallback.emit();
        }
      });
    }
  }

  /**
   * 提交 预定金退款申请
   */
  confirmRefundBookingSubmit() {
    if (this.refundApplyRequest.audit_phone !== 'ALL ' && this.refundApplyRequest.audit_code) {
      this.refundApplyRequest.mode_of_payment = this.modeOfPayment;
      this.refundApplyRequest.lease_id = this.leaseId;
      this.refundListService.refundBoookingHandler(this.refundApplyRequest).then(response => {
        if (response && response.result === '短信验证码验证错误') {
          this.auditCodeError = true;
        } else if (response && response.result === '执行成功') {
          this.resultRefundNo = response.refund_no;
          this.refundBookingConfirmDialog.hide();
          this.refundBookingConfirmSuccessDialog.show();
          this.paymentCallback.emit();
        }
      }).catch(error => {

      });
    }
  }

  /**
   * 选中转账收款时，输入收款账户相关信息
   * @param type
   * @param inputValue
   */
  entryTransferInfo(type: any, inputValue: any) {
    if (type && type === 'GATHER_VALUE') {
      // 收款人姓名
      if (inputValue && this.refundApplyRequest.bank_name && this.refundApplyRequest.card_number) {
        this.canRefundNextError = false;
      } else {
        this.canRefundNextError = true;
      }
    } else if (type && type === 'BANK_NAME') {
      // 开户行
      if (inputValue && this.refundApplyRequest.gather_value && this.refundApplyRequest.card_number) {
        this.canRefundNextError = false;
      } else {
        this.canRefundNextError = true;
      }
    } else if (type && type === 'CARD_NUMBER') {
      // 银行账号
      if (inputValue && this.refundApplyRequest.gather_value && this.refundApplyRequest.bank_name) {
        this.canRefundNextError = false;
      } else {
        this.canRefundNextError = true;
      }
    } else {
      return;
    }
  }

  /**
   * 获取审核短信验证码
   * @param modal
   */
  getAuditCodeWithUser() {
    if (this.refundApplyRequest.audit_phone !== 'ALL') {
      let requestRoomIds = [];
      if (this.roomIds.length === 1 && this.roomIds[0] === 0) {
        requestRoomIds = this.roomInfoDtos.map(roomInfo => roomInfo.room_id);
      } else {
        requestRoomIds = this.roomIds;
      }
      let request = {
        lease_id: this.leaseId,
        room_ids: requestRoomIds,
        bill_ids: this.checkedBillIds,
        phone: this.refundApplyRequest.audit_phone,
        code: '0000',
        lease_type: 'LEASE'
      };
      this.refundListService.refundBillAuditSmsSend(request).then(response => {
        this.time = 60;
        this.inter = setInterval(() => {
          if (--this.time > 0) {
            this.codeBtnStr = this.time + 's后重新获取';
          } else {
            this.time = 60;
            this.codeBtnStr = '点击获取';
            clearInterval(this.inter);
          }
        }, 1000);
      });
    }
  }

  /**
   * 获取预定金退款审核短信验证码
   * @param modal
   */
  refundBookingAuditCodeWithUser() {
    if (this.refundApplyRequest.audit_phone !== 'ALL') {
      let request = {
        lease_id: this.leaseId,
        phone: this.refundApplyRequest.audit_phone,
        code: '0000'
      };
      this.refundListService.refundBookingAuditSmsSend(request).then(response => {
        this.time = 60;
        this.inter = setInterval(() => {
          if (--this.time > 0) {
            this.codeBtnStr = this.time + 's后重新获取';
          } else {
            this.time = 60;
            this.codeBtnStr = '点击获取';
            clearInterval(this.inter);
          }
        }, 1000);
      });
    }
  }

  /**
   * 验证码输入
   * @param codeValue
   */
  auditCodeInput(codeValue: string) {
    this.auditCodeError = false;
  }

  /**
   * 打开无合同收款弹窗
   */
  openNoContractGatherDialog() {
    this.getBillTypeList('NO_LEASE');
    this.getAuditList();
    this.getRefundAuditUserList();
    this.showTips = false;
    this.expenseType = 'NOLEASE';
    this.hasContact = false;
    this.idempotentService.getIdempotentKey().then(data => {
      this.idempotent_key = data.idempotent_key;
    });
    this.noContractGatherBillDialog.show();
  }

  showRoomListDiv() {
    this.showRoomList = !this.showRoomList;
  }

  hideRoomListDiv() {
    this.showRoomList = false;
  }

  showRoomListDiv2() {
    this.showRoomList2 = !this.showRoomList2;
  }

  hideRoomListDiv2() {
    this.showRoomList2 = false;
  }

  chooseRoom(event: any) {
    let split = event.currentTarget.defaultValue.split(";");
    //勾选
    if (event.currentTarget.checked) {
      if (+split[0] === 0) {
        this.selectRoomNames = ['全部房间'];
        this.roomIds = [];
        for (let i = 0; i < this.roomInfoDtos.length; i++) {
          this.roomIds.push(this.roomInfoDtos[i].room_id);
        }
      } else {
        this.roomIds.push(+split[0]);
        if (this.roomIds.length === this.roomInfoDtos.length) {
          this.selectRoomNames = ['全部房间'];
        } else if ((this.roomIds.length + 1) === this.roomInfoDtos.length) {
          this.selectRoomNames.push(split[1]);
          if (this.roomIds.indexOf(0) !== 0) {
            this.roomIds.push(0);
            this.selectRoomNames = ['全部房间'];
          }
        } else {
          if (this.selectRoomNames.indexOf('请选择房间') >= 0) {
            this.selectRoomNames = [];
          }
          this.selectRoomNames.push(split[1]);
        }
      }
    } else {
      //取消全选状态的勾选
      if (+split[0] === 0) {
        this.selectRoomNames = [];
        // if (this.expenseType == 'otherExpense') {
        this.selectRoomNames = ['请选择房间'];
        // }
        this.roomIds = [];
      } else {
        for (let i = 0; i < this.roomIds.length; i++) {
          if (this.roomIds[i] === +split[0]) {
            this.roomIds.splice(i, 1);
          }
        }

        for (let i = 0; i < this.selectRoomNames.length; i++) {
          if (this.selectRoomNames[i] === split[1]) {
            this.selectRoomNames.splice(i, 1);
          }
        }

        //处理全选状态时，取消单个房间的勾选
        if (this.selectRoomNames.indexOf('全部房间') >= 0 || this.selectRoomNames.indexOf('请选择房间') >= 0) {
          this.selectRoomNames = [];
          for (let j = 0; j < this.roomInfoDtos.length; j++) {
            if (this.roomIds.indexOf(this.roomInfoDtos[j].room_id) >= 0 && this.roomInfoDtos[j].room_id > 0) {
              this.selectRoomNames.push(this.roomInfoDtos[j].room_name);
            }
          }
        }
        if (this.roomIds.indexOf(0) >= 0) {
          this.roomIds = this.roomIds.filter(e => e > 0);
        }

      }
    }
    if (this.expenseType == 'otherExpense') {
      if (this.roomIds.length <= 0) {
        this.selectRoomNames = ["请选择房间"];
      }
      this.createPreviewList();
    }
    this.roomSelect();
  }

  chooseOneRoom(event: any) {
    let split = event.currentTarget.defaultValue.split(";");
    this.selectRoomName = split[1];
    this.roomIds = [];
    this.roomIds.push(+split[0]);
  }


  checkOrderNo(order_no?: any) {
    this.check_order_no = order_no;
  }

  confirmCouponNumber() {
    if (this.coupon_number == null || this.coupon_number.length === 0) {
      this.coupon_msg = '请输入优惠券号';
      this.coupon_amount = 0;
      this.coupon_least_use_total = 0;
      this.cal();
      this.idempotentService.getIdempotentKey().then(data => {
        this.idempotent_key = data.idempotent_key;
      });
      return;
    }

    // 房费总金额
    let totalLeftAmount = "0";
    if (this.billList && this.billList.length > 0) {
      // 是否选择房费账单
      let hasCheckRent = false;
      //计算勾选的所有房费类型账单的剩余未付总金额
      for (let i = 0; i < this.billList.length; i++) {
        if (this.billList[i].bill_type_name === '房费') {
          if (this.checkedBillIds.length > 0 && this.checkedBillIds.indexOf(this.billList[i].bill_id) >= 0) {
            totalLeftAmount = new Decimal(totalLeftAmount).add(new Decimal(this.billList[i].waiting_pay_amount)).toString();
            hasCheckRent = true;
          }
        }
      }
      if (!hasCheckRent) {
        this.coupon_flag = false;
        this.coupon_msg = '未选择房费账单，无法使用优惠券';
        return;
      }
    }

    this.couponService.getCouponInfoDetailByCouponNumber(new CouponInfoDetailByCouponNumberReq(this.coupon_number.trim(), totalLeftAmount, "RENT")).then(data => {
      if (data.error_msg) {
        this.coupon_msg = data.error_msg;
        this.coupon_flag = false;
        this.coupon_amount = 0;
        this.coupon_least_use_total = 0;
      } else {
        this.coupon_amount = 0;
        this.coupon_least_use_total = 0;
        //和优惠券门槛作比较，大于门槛才可以使用
        if (this.changToDecimal(totalLeftAmount).greaterThanOrEqualTo(this.changToDecimal(data.least_use_total))) {
          this.coupon_info = data.coupon_info;
          if (data.coupon_info.coupon_batch_type === 'DISCOUNT') {
            this.coupon_msg = '房费-代金券：' + data.coupon_info.discount_ratio + '折';
          } else {
            this.coupon_msg = '房费-代金券：' + data.coupon_info.total_value + '元';
          }
          this.coupon_amount = data.coupon_info.total_value;
          this.coupon_flag = true;
          this.coupon_least_use_total = +data.least_use_total;
        } else {
          this.coupon_flag = false;
          this.coupon_msg = '未达到该房费代金券使用门槛：' + data.least_use_total + '元，不能使用该优惠券';
          return;
        }
      }
      this.cal();
    });
  }

  changeSelectCouponInput(event: any) {
    this.selectCouponInput = event.currentTarget.checked;
    if (!this.selectCouponInput) {
      // this.coupon_number = null;
      // this.coupon_msg = null;
      this.coupon_flag = false;
      this.coupon_amount = 0;
      this.coupon_least_use_total = 0;
      this.cal();
    } else {
      this.confirmCouponNumber();
    }
  }

  cal() {
    let amount = this.confirmPaymentRequest.payment_total_amount;
    //
    if (this.selectBookingAmount) {
      amount = new Decimal(amount).sub(new Decimal(this.confirmPaymentRequest.book_amount)).toString();
    }
    if (this.selectCouponInput) {
      amount = new Decimal(amount).sub(new Decimal(this.coupon_amount)).toString();
    }
    this.confirmPaymentRequest.actual_payment_amount = amount;
    if (!amount) {
      this.final_amount = '0';
    } else {
      this.final_amount = amount;
    }
    if (+this.final_amount < 0) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "应付总计小于0，请重新勾选账单或者抵扣选项"));
    }
  }

  checkIsNull() {
    if (!this.coupon_number || this.coupon_number == '') {
      this.selectCouponInput = false;
      this.coupon_amount = 0;
      this.coupon_least_use_total = 0;
      this.cal();
    }
  }

  /************************************************房间批量充值预付费 开始**********************************************/

  /**
   * 打开预付费批量充值收银台
   * @param leaseDetailResponse
   * @param rechargeRooms
   */
  openRechargePrepayModel(leaseDetailResponse: LeaseDetailResponse, rechargeRooms: Array<BatchRechargeRoomDTO>, batchRechargeTotal: number) {
    this.getAccountLease(leaseDetailResponse.lease_id)
    this.confirmPaymentRequest.bussiness_type = null;
    this.leaseDetailResponse = leaseDetailResponse;
    this.rechargeRooms = rechargeRooms;
    this.batchRechargeTotal = batchRechargeTotal;
    this.selectCouponInput = false;
    this.coupon_number = null;
    this.rechargePrepayModel.show();
  }

  /**
   * 关闭预付费批量充值收银台
   * @param leaseDetailResponse
   * @param rechargeRooms
   */
  closeRechargePrepayDialog() {
    this.selectCouponInput = false;
    this.coupon_number = null;
    this.coupon_msg = null;
    this.coupon_amount = 0;
    this.rechargePrepayModel.hide();
  }

  /**
   * 预付费批量充值收银台-勾选优惠券
   * @param leaseDetailResponse
   * @param rechargeRooms
   */
  changeRechargeSelectCouponInput(event: any) {
    this.selectCouponInput = event.currentTarget.checked;
    if (!this.selectCouponInput) {
      // this.coupon_number = null;
      // this.coupon_msg = null;
      this.coupon_flag = false;
      this.coupon_amount = 0;
    } else {
      this.confirmRechargeCouponNumber();
    }
  }

  /**
   * 预付费批量充值收银台-识别优惠券
   * @param leaseDetailResponse
   * @param rechargeRooms
   */
  confirmRechargeCouponNumber() {
    if (this.coupon_number == null || this.coupon_number.length === 0) {
      this.coupon_msg = '请输入优惠券号';
      this.coupon_amount = 0;
      return;
    }

    this.couponService.getCouponInfoDetailByCouponNumber(
      new CouponInfoDetailByCouponNumberReq(this.coupon_number.trim(), null, "PREPAY")).then(data => {
      if (data.error_msg) {
        this.coupon_msg = data.error_msg;
        this.coupon_flag = false;
        this.coupon_amount = 0;
      } else {
        this.coupon_amount = 0;
        // //判断优惠券面额是否大于总充值金额
        // if(this.changToDecimal(data.coupon_info.total_value+"").greaterThan(this.changToDecimal(this.batchRechargeTotal+""))){
        //   this.coupon_flag = false;
        //   this.coupon_msg = '优惠券面额：'+ data.coupon_info.total_value + '元，大于充值总额，不能使用';
        //   return;
        // }
        //判断充值总金额是否大于代金券使用门槛
        //和优惠券门槛作比较，大于门槛才可以使用
        if (this.changToDecimal(this.batchRechargeTotal + "").greaterThanOrEqualTo(this.changToDecimal(data.least_use_total))) {
          if (data.coupon_info.total_value < 1) {
            this.coupon_flag = false;
            this.coupon_msg = '优惠券面额：' + data.coupon_info.total_value + '元，低于最低1元的抵扣门槛，不能使用';
          } else {
            this.coupon_info = data.coupon_info;
            this.coupon_msg = '可用预付费-代金券：' + data.coupon_info.total_value + '元';
            this.coupon_amount = data.coupon_info.total_value;
            this.coupon_flag = true;
          }
        } else {
          this.coupon_flag = false;
          this.coupon_msg = '充值金额未满：' + data.least_use_total + '元，不能使用该优惠券';
        }
      }
    });
  }

  /**
   * 预付费充值点击收款
   * @param paymentType
   * @param dialog
   * @param aliOrWeixin
   */
  prepayPayment(paymentType?: any, dialog?: any, aliOrWeixin?: any, accountMoneyInfoId?: number) {
    const req = {bill_type: 'SINGLE'};
    this.paymentAmount = +(new Decimal(this.batchRechargeTotal + "").sub(new Decimal(this.coupon_amount + "")).toString());

    if (paymentType) {
      this.paymentType = paymentType;
      if (paymentType === "ACCOUNT_MONEY") {
        this.account_money_info_id = accountMoneyInfoId;
      }
      this.financeService.getPaymentType(req).then(res => {
        res.pay_types.forEach(data => {
          if (data.pay_type_code === paymentType) {
            this.paymentTypeName = data.pay_type_name;
          }
        });
      }).then(response => {
        this.aliOrWeixinDialog = dialog;
        if (aliOrWeixin) {
          this.showContent = false;
          this.prepayBatchRechargeReq.cret_images = [];
          this.clock = 120;
          this.prepayTimeOut();
        }
        this.prepayposPaymnet.hide();
        this.prepaycashPayment.hide();
        this.rechargePrepayModel.hide();
        this.prepayAccountPayment.hide();
        this.closeCallback.emit();
        this.prepayBatchRechargeReq.cret_images = [];
        if (paymentType === 'CASH' || paymentType === 'TRANSFER') {
          let nowTime = new Date().getTime();
          this.prepayBatchRechargeReq.amount = this.paymentAmount.toString();
          this.prepayBatchRechargeReq.lease_id = this.leaseDetailResponse.lease_id;
          this.prepayBatchRechargeReq.batchRechargeRoomDTO = this.rechargeRooms;
          this.prepayBatchRechargeReq.random_time = nowTime;
          this.prepayBatchRechargeReq.room_id = this.rechargeRooms.map(e => e.room_id).join(",");
          this.prepayService.accountRechargeCode(this.prepayBatchRechargeReq).then(res => {
            this.prepayBatchRechargeReq.random_token = res.random_token;
            dialog.show();
          });
        } else {
          this.prepayBatchRechargeReq.pay_type_code = paymentType;
          this.prepayBatchRechargeReq.random_token = '';
          dialog.show();
        }
      });
    } else {
      this.rechargePrepayModel.hide();
      this.closeCallback.emit();
      this.rechargePrepayAccount(null);
    }
  }

  prepayTimeOut() {
    this.clock = this.clock - 1;
    if (this.clock === 0 || this.clock === -1) {
      this.clock = 0;
      this.showContent = true;

    } else if (this.clock > 0) {
      if (new RegExp(RegularExp.signlessInteger).test((this.clock / 5).toString())) {
        //五秒中查询一次账单对应的订单信息
        this.getPrepayOrderList();
      }
      setTimeout(() => this.prepayTimeOut(), 1000);
    }
  }

  getPrepayOrderList() {
    this.paymentAmount = +(new Decimal(this.batchRechargeTotal + "").sub(new Decimal(this.coupon_amount + "")).toString());
    const reqParam = {pay_channel: this.paymentType, payment_amount: this.paymentAmount};
    this.orderService.getBillOrderList(reqParam).then(response => {
      if (response) {
        this.orderDtos = response.order_info_dtos;
        this.orderNum = this.orderDtos.length;
        if (this.orderNum > 0) {
          this.clock = 0;
          this.prepaybindOrderBill.show();
          this.aliOrWeixinDialog.hide();
        }
      }
    }).catch(error => {
      this.logService.error('PactComponent:根据付款金额获取订单信息失败2222' + JSON.stringify(error));
    });
  }

  prepayshowQueryByOrderId(queryDialog?: any, needCloseDialog?: any) {
    this.orderId = null;
    this.orderRemind = null;
    if (needCloseDialog) {
      needCloseDialog.hide();
    }
    queryDialog.show();
  }

  // 输入商户订单号：查询订单
  prepayQueryByOrderId(queryShowDialog?: any, gatherSuccessDialog?: any) {
    this.paymentAmount = +(new Decimal(this.batchRechargeTotal + "").sub(new Decimal(this.coupon_amount + "")).toString());
    this.orderRemind = null;
    if (!this.orderId) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请输入商户订单号'));
      return;
    }
    const reqParam = {
      pay_channel: this.paymentType,
      payment_amount: this.paymentAmount,
      order_id: this.orderId
    };
    this.orderService.getBillOrderList(reqParam).then(res => {
      if (res) {
        this.orderDtos = res.order_info_dtos;
        if (res.order_info_dtos.length === 1) {
          this.prepayBatchRechargeReq.pay_log_no = this.orderDtos[0].order_no;
          this.confirmPrepayRecharge(this.paymentType, queryShowDialog, gatherSuccessDialog);
        }
      } else {
        this.orderRemind = '未查到该订单号，请确认订单号是否正确';
      }
    }).catch(error => {
      this.logService.error('PactComponent:根据订单和金额查询订单时失败222' + JSON.stringify(error));
    });
  }

  confirmPrepayRecharge(paymentType?: any, confirmDialog?: any, gatherSuccessModal?: any): Promise<boolean> {
    this.rechargePrepayAccount(paymentType);
    return;
  }

  rechargePrepayAccount(payTypeCode: string) {
    if (this.coupon_amount && this.coupon_amount > 0) {
      this.prepayBatchRechargeReq.couponNumber = this.coupon_number;
    }
    this.prepayBatchRechargeReq.amount = this.paymentAmount.toString();
    this.prepayBatchRechargeReq.lease_id = this.leaseDetailResponse.lease_id;
    this.prepayBatchRechargeReq.batchRechargeRoomDTO = this.rechargeRooms;
    this.prepayBatchRechargeReq.pay_time = new Date().getTime();
    this.prepayBatchRechargeReq.pay_type_code = payTypeCode;
    if (payTypeCode === 'ACCOUNT_MONEY') {
      this.prepayBatchRechargeReq.account_money_info_id = this.account_money_info_id;
    } else {
      this.prepayBatchRechargeReq.account_money_info_id = null;
    }
    // 如果表单验证通过
    this.prepayService.accountBatchRecharge(this.prepayBatchRechargeReq).then(response => {
      this.prepayposPaymnet.hide();
      this.prepaycashPayment.hide();
      this.prepaybindOrderBill.hide();
      this.prepayorderQuery.hide();
      this.prepayAccountPayment.hide();
      this.paymentAmount = 0;
      this.orderId = null;
      this.prepayBatchRechargeReq = new BacthRechargePrepayReqeust();
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, "充值成功"));
      // 刷新租约日志
      this.refushLogsCallback.emit();
    }).catch(error => {
      // this.paymentAmount = 0;
      this.orderId = null;
    });
  }

  reletedPrepayAndOrder(orderNo?: any, bindOrderBillDialog?: any, gatherSuccess?: any) {
    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.operationMessage = "请确认此款项正确，确认后不能更改且立即入账";
    messageModalInfo.cancel = true;
    messageModalInfo.callback.subscribe(() => {
      this.prepayBatchRechargeReq.pay_log_no = orderNo;
      //付款类型为空
      this.confirmPrepayRecharge(this.paymentType, bindOrderBillDialog, gatherSuccess);
    });
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
  }

  prepayPaymnetFill(imageList?: any) {
    this.prepayBatchRechargeReq.cret_images = imageList;
  }

  calRechargeAmount(num1: number, num2: number): string {
    return new Decimal(num1 + "").sub(new Decimal(num2 + "")).toString();
  }

  turnToNumber(str1: string) {
    return +(new Decimal(str1).toString());
  }

  setChecked() {
    this.checkedBillType = [];
    this.checkedBillLeaseId = [];
    this.checkedBillRoomId = [];
    if (this.checkedBillIds.length > 0) {
      this.checkedBillIds.forEach(value => {
        let tmpBill = this.billList.find(bill => bill.bill_id === value);
        this.checkedBillType.push(tmpBill.bill_type_name);
        this.checkedBillLeaseId.push(tmpBill.lease_id);
        this.checkedBillRoomId.push(tmpBill.lease_room_id);
      });
    }
  }

  /************************************************房间批量充值预付费 结束**********************************************/
  changePayerType(payerType: string) {
    this.payerType = payerType;
    this.expenseType = 'renterExpense';
    this.selectBillType = 0;
    this.paymentDetail(this.expenseType);
  }

  selectRenterRoomInfo(event: any) {
    this.selectRenterRoomName = this.renterRoomInfos.find(e => e.room_id == event.target.value).room_name;
    let req = new QueryAllRenterListReq();
    req.room_ids = [event.target.value];
    req.lease_id = this.paymentleaseId;
    this.selectRenterId = 0;
    this.selectRenter = null;
    this.selectRenterName = null;
    this.renterService.queryAllRenterInfoList(req).then(data => {
      this.renterInfoList = data.renter_info_list;
    });
  }

  selectRenterInfo(event: any) {
    this.selectRenterName = this.renterInfoList.find(e => e.info_id == event.target.value).name;
    this.selectRenter = this.renterInfoList.find(e => e.info_id == event.target.value);
    let checkIndate = addDate(new Date(this.selectRenter.checkin_time), -1);
    let checkoutdate = new Date(32535100800000);
    let future = new Date(32535100800000);
    if (this.selectRenter.checkout_time) {
      checkoutdate = addDate(new Date(this.selectRenter.checkout_time), 1);
    }
    this.startDatePickerOptions = {
      dateFormat: "yyyy-mm-dd",
      editableDateField: true,
      showClearDateBtn: true,
      disableDateRanges: [{
        begin: {year: 1970, month: this.tomorrow.getMonth() + 1, day: this.tomorrow.getDate()},
        end: {year: checkIndate.getFullYear(), month: (checkIndate.getMonth() + 1), day: checkIndate.getDate()}
      }, {
        begin: {year: checkoutdate.getFullYear(), month: checkoutdate.getMonth() + 1, day: checkoutdate.getDate()},
        end: {year: future.getFullYear(), month: (future.getMonth() + 1), day: future.getDate()}
      }]
    };
    this.endDatePickerOptions = {
      dateFormat: "yyyy-mm-dd",
      editableDateField: true,
      showClearDateBtn: true,
      disableDateRanges: [{
        begin: {year: 1970, month: this.tomorrow.getMonth() + 1, day: this.tomorrow.getDate()},
        end: {year: checkIndate.getFullYear(), month: (checkIndate.getMonth() + 1), day: checkIndate.getDate()}
      }, {
        begin: {year: checkoutdate.getFullYear(), month: checkoutdate.getMonth() + 1, day: checkoutdate.getDate()},
        end: {year: future.getFullYear(), month: (future.getMonth() + 1), day: future.getDate()}
      }]
    };
  }

  changeRenterBillType(event: any) {
    if (event.target.value == 'RENTER_COMPENSATION') {
      this.selectBillTypeName = '住客赔偿金';
    } else if (event.target.value == 'RENTER_DEPOSIT') {
      this.selectBillTypeName = '住客押金';
    }
  }

  createRenterPreviewBill() {
    if (!this.validRenterBillParam()) {
      return;
    }
    let renterPreviewBill = new  RenterPreviewBillListDTO();
    renterPreviewBill.room_id = this.selectRenterRoom;
    renterPreviewBill.room_name = this.selectRenterRoomName;
    renterPreviewBill.bill_amount = this.otherPayAmount;
    renterPreviewBill.bill_type_name = this.selectBillTypeName;
    renterPreviewBill.bill_type_code = this.renterBillType;
    renterPreviewBill.renter_name = this.selectRenterName;
    renterPreviewBill.renter_id = this.selectRenterId;
    renterPreviewBill.remark = this.renterBillRemark;
    renterPreviewBill.startTime_obj = getDateFromPlugin(this.startTime_obj.formatted + " 00:00:00");
    renterPreviewBill.endTime_obj = getDateFromPlugin(this.endTime_obj.formatted + " 00:00:00");
    this.renterBillPreviewList.push(renterPreviewBill);
  }

  validRenterBillParam() {
    if (this.selectRenterRoom == null || this.selectRenterRoom == 0) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请选择房间'));
      return false;
    }

    if (this.selectRenterId == null || this.selectRenterId == 0) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请选择住客'));
      return false;
    }

    if (this.renterBillType == null ||this.renterBillType == "") {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请选择账单类型'));
      return false;
    }

    if (this.otherPayAmount == null || this.otherPayAmount == "") {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请输入收款金额'));
      return false;
    }

    if (!this.validAmount(this.otherPayAmount)) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请输入0~99999元的收款金额'));
      return false;
    }

    if (this.startTime_obj == null || this.endTime_obj == null) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请选择费用所属周期'));
      return false;
    }

    if (this.endTime_obj && this.startTime_obj
      && getTimeFromPlugin(this.endTime_obj.formatted + " 23:59:59")
      < getTimeFromPlugin(this.startTime_obj.formatted + " 00:00:00")) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '开始日期不能大于结束日期'));
      return false;
    }

    if (!this.renterBillRemark) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '账单备注不能为空'));
      return false;
    }
    return true;
  }

  deleteRenterBillPreview(item: RenterPreviewBillListDTO, i: number) {
    this.renterBillPreviewList.splice(i,1);
  }

  comparePrepayAccount(num1: any, accountDto: AccountMoneyListDTO): boolean {
    let leaseTotal = Number.parseFloat(num1);
    let account = Number.parseFloat(accountDto.balance);
    let _return = true;

    if (account < leaseTotal) {
      _return = false;
    }
    if (accountDto.special_type === "SPECIAL_ACCOUNT_RENT_DIC") {
      _return = false;
    }
    return _return;
  }
}
