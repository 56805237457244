import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {SharedRootModule} from '../shared/module/share-common.module';

export const routes: Routes = [

];

@NgModule({
  imports: [
    SharedRootModule,
    RouterModule.forChild(routes)

  ],
  // 导出去 便于使用
  exports: [RouterModule]
})

export class UserRoutingModule {
}
