import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'assetGodownStatusPipe'
})
export class AssetGodownStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const status = {
      AUDITING: '待审核',
      STORED_IN: '已入库',
      CANCELLED: '已取消',
      REJECTED: '已拒绝',
    };
    return status[value];
  }
}
