import {Component, OnInit, Input, ViewChild, Output, EventEmitter} from '@angular/core';
import {CheckoutRoomViewNewDTO, CheckoutRoomViewNewResponse, CheckoutRoomViewRequest} from '../../../../model/signed/checkout.model';
import {SignedService} from '../../../../service/signed/signed.service';
import {CheckOutPopupComponent} from '../../checkout/check-out-popup/check-out-popup.component';

@Component({
  selector: 'app-check-out-information',
  templateUrl: './check-out-information.component.html',
  styleUrls: ['./check-out-information.component.css']
})
export class CheckOutInformationComponent implements OnInit {
  @Input() paymentleaseId: number;
  @Input() roomIdList: Array<any> = [];
  @Input() isLease_room_id: Array<any> = [];
  checkoutRoomViewRequest: CheckoutRoomViewRequest = new CheckoutRoomViewRequest();
  sublet: boolean = true;
  isShow: boolean = false;
  listData: Array<CheckoutRoomViewNewDTO> = [];
  @Output() checkedBack: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkBack: EventEmitter<CheckoutRoomViewNewResponse> = new EventEmitter<CheckoutRoomViewNewResponse>();
  // checkinInventoryResponse:any;
  //退房原因
  checkoutReson: string;
  checkoutResonExt: string;//提交时 checkoutReson == ’其他‘  不能为空
  @ViewChild(CheckOutPopupComponent, {static: true}) CheckOutPopupComponent: CheckOutPopupComponent;
  datatList: CheckoutRoomViewNewResponse;
  // electricityReading:string;
  // waterReading:string;
  // hotWaterReading:string;
  constructor(
    private signedService: SignedService,
  ) {
  }

  ngOnInit() {
    this.isRoom();
  }


  isRoom() {
    let checkoutRoomViewRequest = {
      lease_id: this.paymentleaseId,
      lease_room_ids: this.roomIdList
    };
    this.signedService.inventoryCheckoutViewNew(checkoutRoomViewRequest).then(data => {
      this.checkBack.emit(data);
      this.datatList = data;
      data.checkout_inventory_list_dtos.forEach(item => {
        item.out_room_details_type = false;
        item.details = [];

        item.water_reading_temp = item.water_reading;
        item.hot_water_reading_temp = item.hot_water_reading;
        item.electricity_reading_temp = item.electricity_reading;
      });
      this.listData = data.checkout_inventory_list_dtos;

      // 没有最新水表读数 那就30S刷新一次 获取最新读数
      if (this.datatList.need_refresh_reading) {
        setTimeout(() => {
          this.isRoom();
        }, 30 * 1000);
      }
    });


  }

  callback(el) {
    this.listData.forEach(item => {
      el.forEach(e => {
        if (item.lease_room_info_dto.lease_room_id === e.id) {
          item.details = el;
          item.out_room_details_type = true;
        }
      });
    });
    this.checkedBack.emit(this.listData);

  }


  addBad(data, details) {
    this.CheckOutPopupComponent.showModal(data, details);
  }


  delete(info, e) {

    let aa = info.details.filter(item => {
      return item.rowId !== e.rowId;
    });
    info.details = aa;
  }

  infoClick() {
    // this.datatList.checkout_inventory_list_dtos.forEach(item => {
    //     if (item.lease_room_info_dto.room_id === roomId) {
    //       item.hot_water_reading = this.waterReading;
    //       item.water_reading = this.hotWaterReading;
    //       item.electricity_reading=this.electricityReading;
    //     }
    // });
    this.checkBack.emit(this.datatList);
  }
}
