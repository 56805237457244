import { Component, OnInit, ViewChild } from '@angular/core';
import { CheckoutRoomViewResponse } from '../../../../model/signed/checkout.model';
import { CheoutListComponent } from '../cheout-list/cheout-list.component';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  showCheckout = false;

  @ViewChild("appcheoutlist", { static: true }) cheoutListComponent: CheoutListComponent;

  constructor() {
  }

  ngOnInit() {
  }

  goNext(data: CheckoutRoomViewResponse) {
    this.showCheckout = true;
    this.cheoutListComponent.showDiv(data);
  }

}
