<div *ngIf="showData !== 1" class="card" style="margin-bottom: 0;">
  <div class="card-header" *ngIf="showData == 3" style="background: #FFFFE6;line-height: 33px;">
    提醒充值：低于<span class="red">{{companyPrepayInfoRes?.limit}}</span>元时&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    欠费额度：总押金的<span class="red">{{companyPrepayInfoRes.arrears_percent}}</span>%
    <button class="btn btn-warning" (click)="changeAlarm()" style="float: right;background: rgb(250,187,60);" *ngIf="btn_privileges.COMPANY_PREPAY_SETTING">修改设置</button>
  </div>
  <div class="padd15 yellow" style="padding-top:0px">
    <div class="card-body">
      <table *ngIf="showData == 3" class="table table-bordered border-striped">
        <thead style="background: rgba(243, 243, 249, 1)">
        <tr>
          <th>充值总额</th>
          <th>已使用</th>
          <th>余额</th>
          <th>操作</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{companyPrepayInfoRes?.total}}</td>
          <td>{{companyPrepayInfoRes?.used}}</td>
          <td>{{companyPrepayInfoRes?.left}}</td>
          <td>
            <button [attr.disabled]="leaseStatusDesc == '已退租'? true: null" class="btn border-btn"
                    (click)="rechargeShow()" *ngIf="btn_privileges.COMPANY_PREPAY_RECHARGE">充值
            </button>
            <button class="btn border-btn" (click)="showDetail()" *ngIf="btn_privileges.COMPANY_PREPAY_RECORD">查看使用记录</button>
            <button class="btn border-btn" (click)="showPrePayRechargeRecord(prepayRechargeRecordModal)" *ngIf="btn_privileges.COMPANY_PREPAY_RECHARGE_RECORD">查看充值记录</button>
          </td>
        </tr>
        </tbody>
      </table>
      <div *ngIf="showData == 2" class="col-12 mt10">
        <p class="text-dark">
          <b class="mr10">未开通企业预付费账户</b>
          <button type="button" class="btn btn-outline-primary"
                  *ngIf="btn_privileges.OPEN_COMPANY_PREPAY_ACCOUNT &&
                  ((leaseStatus === 'EFFECTIVE' || leaseStatus === 'EXPIRED')
                && (auditStatus === 'AUDIT_SUCCESS' || auditStatus === 'NO_AUDIT'))"
                  (click)="create()">开通账户
          </button>
        </p>
      </div>
    </div>
  </div>
</div>

<!-- 开通弹框 -->
<div class="modal fade" bsModal #createModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
           aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left" *ngIf="showData !== 3" ><i class="fa fa-info-circle" aria-hidden="true"></i>开通企业预付费账户</h4>
        <h4 class="modal-title pull-left" *ngIf="showData === 3" ><i class="fa fa-info-circle" aria-hidden="true"></i>企业预付费账户阈值修改</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="createModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-3" *ngIf="showData !== 3" >开通企业预付费账户后，当房间预付费余额欠费时，自动从企业预付账户余额里划扣。</p>
        <div class="border px-2 mb-3">
          <div class="my-2 mb-1">
            <div class="mb-2">充值提醒设置：</div>
            当企业账户余额少于
            <input style="width:50px;" type="text" [(ngModel)]="companyPrepayCreateReq.limit"/>
            元时，开始短信提醒用户
          </div>
          <div class="my-2 mb-3">
            <div class="mb-2">欠费额度（超过额度断电）设置：</div>
            <div class="py-1">
              允许欠费额度：
              <input type="radio" name="arrearsPercent" [(ngModel)]="companyPrepayCreateReq.arrears_percent" [value]="0" >0元
              <input class="ml-3" type="radio" name="arrearsPercent" [(ngModel)]="companyPrepayCreateReq.arrears_percent" [value]="5" *ngIf="btn_privileges.COMPANY_PREPAY_FIVE">总押金的5%
              <input class="ml-3" type="radio" name="arrearsPercent" [(ngModel)]="companyPrepayCreateReq.arrears_percent" [value]="20" *ngIf="btn_privileges.COMPANY_PREPAY_ARREARS" (click)="privilegeAlert()">总押金的20%
            </div>
          </div>
        </div>
        <p class="mb-3" *ngIf="showData !== 3">确认并开通企业预付费账户！</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="confirmCreate()" *ngIf="showData !== 3" >确认开通</button>
        <button class="btn btn-primary" (click)="confirmCreate()" *ngIf="showData === 3" >确认修改</button>
        <button class="btn" (click)="createModal.hide()">否</button>
      </div>
    </div>
  </div>
</div>


<!-- 企业预付账户充值 -->
<app-company-prepay-recharge [leaseId]="leaseId" [contactNum]="leaseNum" [contactName]="contactName"></app-company-prepay-recharge>

<!-- 扣费明细弹框-->
<div class="modal fade" bsModal #detailModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>企业预付费扣费明细</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="stopGetList();detailModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div style="padding-left: 10px;padding-top: 6px;font-size: 14px;" class="font" >
            扣费周期
          </div>

          <div class="col-12 col-md-4 mb10" style="">
            <my-date-picker [options]="" [(ngModel)]="startTime"></my-date-picker>
          </div>

          <div class="col-12 col-md-4 mb10" style="">
            <my-date-picker [options]="" [(ngModel)]="endTime"></my-date-picker>
          </div>

          <div class="col-6 col-md-2 mb10" style="margin-left: 10px;">
            <button class="btn btn-primary mg21 " type="button" (click)="getDetail(-1)">
              <i class="fa fa-search"></i>搜索
            </button>
          </div>
        </div>
        <p class="font12 mb-2" style="color:#9EA1A9;">提示：最大查询周期为31天</p>
        <table class="table border-striped v2 table-fix-scroll lg-scroll" >
          <thead>
          <tr>
            <th>扣费时间</th>
            <th>类型</th>
            <th>金额</th>
            <th>房间号</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of companyPrepayDetailRes?.company_prepay_detail_dtos">
            <td>{{item?.create_time | date:'yyyy-MM-dd HH:mm:ss'}}</td>
            <td>{{item?.service_type}}</td>
            <td>{{item?.total}}</td>
            <td>{{item?.room_name}}</td>
          </tr>
          <tr *ngIf="companyPrepayDetailRes?.company_prepay_detail_dtos.length == 0 && !loading">
            <td colspan="5">本次未查询到相关数据</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="download()">下载到excel</button>
        <button class="btn" (click)="stopGetList();detailModal.hide()">关闭</button>
      </div>
    </div>
  </div>
</div>

<!-- 预付费充值记录 -->
<div class="modal fade" bsModal #prepayRechargeRecordModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>充值记录</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="prepayRechargeRecordModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body" style="max-height: 378px;overflow-y: auto;">
        <table class="table table-bordered table-striped" >
          <thead>
          <tr>
            <th>操作人</th>
            <th>充值金额</th>
            <th>支付方式</th>
            <th>充值日期</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngIf="company_prepay_record_dtos && company_prepay_record_dtos.length > 0">
            <tr *ngFor="let record of company_prepay_record_dtos">
              <td>{{record?.operator}}</td>
              <td>{{record?.amount}}</td>
              <td>{{record?.pay_type}}</td>
              <td>{{record?.pay_time | date:'yyyy-MM-dd HH:mm:ss'}}</td>
            </tr>
          </ng-container>
          <tr *ngIf="company_prepay_record_dtos.length == 0">
            <td colspan="4">本次未查询到相关数据</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="downloadCompanyPrepayRchargRecord()">下载到excel</button>
        <button class="btn" (click)="prepayRechargeRecordModal.hide()">关闭</button>
      </div>
    </div>
  </div>
</div>
