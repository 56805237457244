import { Pipe, PipeTransform } from '@angular/core';

/**
 * @Description: 费用收取类型翻译管道
 * @Author: 陆炳彬
 * @Date: 2018/3/29 上午10:25
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/3/29 上午10:25
 */
@Pipe({
  name: 'gatherTypePipe'
})
export class GatherTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const status = {
        EVERY_MONTH_CHARGE: '每月一次',
        ONE_TIME_CHARGE: '一次性收取',
        EVERY_YEAR_CHARGE: '每年一次'
      }
    ;
    return status[value];
  }
}
