import {EventEmitter, Injectable} from "@angular/core";
import {RequestService} from "../request.service";
import {environment} from "../../../environments/environment";
import {
  AppHelloDetailRequest, AppHelloDetailResponse,
  AppHelloInsertRequest,
  AppHelloUpdateRequest,
  AppHelloUpdateResponse
} from "../../model/apphello/apphello.model";
import {BannerDetailRequest, BannerDetailResponse} from "../../model/banner/banner.model";

@Injectable()
export class ApphelloService {

  constructor(private requestService: RequestService) {
  }

  private APP_HELLO_INSERT = `${environment.apiHost}app/hello/insert`;
  private APP_HELLO_LIST = `${environment.apiHost}app/hello/list`;
  //活动编辑
  private APP_HELLO_EDIT = `${environment.apiHost}app/hello/edit`;

  private APP_HELLO_DETAIL = `${environment.apiHost}app/hello/detail`;


  public insertAppHello(params: AppHelloInsertRequest): Promise<any> {
    return this.requestService.authPost(this.APP_HELLO_INSERT, params);
  }

  getList(params: any) {
    return this.requestService.authPost(this.APP_HELLO_LIST, params);
  }
  getListWithLoading(params: any) {
    return this.requestService.authPost(this.APP_HELLO_LIST, params, true);
  }

  edit(req: AppHelloUpdateRequest): Promise<AppHelloUpdateResponse> {
    return this.requestService.authPost(this.APP_HELLO_EDIT, req);
  }

  detail(req: AppHelloDetailRequest): Promise<AppHelloDetailResponse> {
    return this.requestService.authGet(this.APP_HELLO_DETAIL, req);
  }

}
