import { Component, Input, OnInit,ViewChild } from '@angular/core';
import { LeaseLog, LogListReq } from '../../../model/lease/lease.model';
import { LeaseService } from '../../../service/lease/lease.service';
import { ModalDirective } from "ngx-bootstrap/modal";
@Component({
  selector: 'app-lease-log',
  templateUrl: './lease-log.component.html',
  styleUrls: ['./lease-log.component.css']
})
export class LeaseLogComponent implements OnInit {

  @Input()
  lease_id: number;

  @Input()
  lease_type: string;

  @Input()
  relet_id: number;
  @ViewChild('logModal') log_modal: ModalDirective;
  // 日志请求
  log_req: LogListReq = new LogListReq();

  // 日志弹窗
  // log_modal: any;

  // 日志列表
  log_list: LeaseLog[];

  constructor(private leaseService: LeaseService) {
  }

  ngOnInit() {
  }

  // 初始化
  // initSomething(modal: any) {
  //   this.log_modal = modal;
  // }

  // 获取日志列表
  queryLogList() {
    this.leaseService.getLeaseAccountLogList(this.log_req).then(data => {
      this.log_list = data.lease_log_dtolists;
    });
  }

  show() {
    this.log_req.lease_type = this.lease_type;
    this.log_req.lease_id = this.lease_id;
    this.log_req.relet_id = this.relet_id;
    this.log_req.page_num = 1;
    this.log_req.page_size = 10000;

    this.queryLogList();

    this.log_modal.show();
  }

  hide() {
    this.log_modal.hide();
  }
}
