<!-- {{initSomething(custom_rent_modal)}} -->

<!--灵活租金-->
<form [formGroup]="custom_rent_form_group">
  <div class="modal fade" bsModal #custom_rent_modal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
       aria-labelledby="dialog-static-name"  (click)="stopP($event)">
    <div class="modal-dialog modal-primary modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>设置每月租金</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
            <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
          </button>
        </div>
        <div class="modal-body" formArrayName="custom_rent_array">
          <div class="row">
            <div class="col-12 col-md-6 mb10" *ngFor="let custom_rent of customRentArray.controls; let h=index;"
                 [formGroupName]="h">
              <p class="w90px">第{{custom_rent.get('month').value + 1}}个月：</p>
              <input type="text" class="condiv" [placeholder]="basePrice!=null?(basePrice+'元/月'):'月租金'" formControlName="custom_rent" autocomplete="false" [attr.disabled]="!canEdit ? 'disabled' : null">
              <span class="text-danger w-100" *ngIf="isError(is_submit, custom_rent, 'custom_rent', 'required')">
                不能为空
              </span>
              <span class="text-danger w-100" *ngIf="isError(is_submit, custom_rent, 'custom_rent', 'formatError')">
                格式不正确(0-200000的数字，支持小数点后两位)
              </span>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-primary" (click)="confirmCustomRent()">确定</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hide()">
            取消
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
