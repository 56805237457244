import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {LogService} from '../../service/log/log.service';
import {Injectable} from '@angular/core';
import {LocalStorage} from "../../common/localstorage";

/**
 * 获取当前route的title
 * TODO (由于tabComponent中无法获取titleBUG)
 *
 * @Author: ky
 * @Date: 2019年10月11日20:16:32
 */
@Injectable()
export class TabTitleResolve implements Resolve<string> {


  constructor(private logService: LogService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string {

    let title = route.data ? route.data.title : "";
    LocalStorage.setItem("title", title);

    return title;
  }


}
