
<div class="modal fade" bsModal #payLogDetailModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-static-name" class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>支付流水详情</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModel()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-12 col-md-6 mb-3">
            <div class="row">
              <div class="col-3">
                <label class="bold">交易金额</label>
                <p class="red">{{payLogDetail?.pay_amount}}元</p>
              </div>
              <div class="col-3">
                <label class="bold">所属车库</label>
                <p >
                  <span *ngFor="let room_num of payLogDetail?.room_num_list" style="margin-right:3px;">
                    {{room_num}}
                  </span>
                </p>
              </div>
              <div class="col-6">
                <label class="bold">所属合同</label>
                <p class="text-primary" (click)="toLease(payLogDetail?.lease_id)" style="cursor: pointer;">
                  {{payLogDetail?.contract_num}}</p>
              </div>

            </div>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <div class="row">
              <div class="col-3">
                <label class="bold">收款人</label>
                <p>{{payLogDetail?.create_user}}</p>
              </div>
              <div class="col-3">
                <label class="bold">付款方式</label>
                <p>{{payLogDetail?.pay_type}}</p>
              </div>
              <div class="col-6">
                <label class="bold">交易时间</label>
                <p>{{payLogDetail?.pay_time|date :'yyyy-MM-dd HH:mm:ss'}}</p>
              </div>
            </div>
          </div>
          <div class="col-3">
            <label class="bold">核销状态</label>
            <p>
              {{payLogDetail?.audit_status === 'PAY' ? '已核销':
              (payLogDetail?.audit_status === 'REFUSED'? '已拒绝' : '未核销')}}
            </p>

            <div *ngIf="payLogDetail?.is_check_pay">
              <label class="orange">支票收取状态</label>
              <p>
                {{payLogDetail?.check === 'CHECKED' ? '已收支票': '未收到支票'}}
              </p>
            </div>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <div class="row">
              <div class="col-8">
            <label class="bold">三方凭证号</label>
            <p>
              <ng-template [ngIf]="payLogDetail && payLogDetail?.credential_num">
                {{payLogDetail?.credential_num}}
              </ng-template>
              <ng-template [ngIf]="!payLogDetail || !payLogDetail?.credential_num">
                没有相关凭证号
              </ng-template>
            </p>
              </div>
              <div class="col-4">
                <label class="bold">到账日期</label>
                <p *ngIf="payLogDetail && payLogDetail?.manual_account_time">
                  {{payLogDetail.manual_account_time | date: 'yyyy-MM-dd'}}
                </p>
                <p *ngIf="!payLogDetail || !payLogDetail?.manual_account_time">
                  --
                </p>
              </div>
          </div>
        </div>
        </div>

        <div class="row">
          <div class="col-12 mb10">
            <p class="w90px hei32">相关附件：</p>
            <div class="condiv ">
              <ul class="img-box mb10" *ngIf="payLogDetail?.files && payLogDetail?.files.length>0">
                <li *ngFor="let file of payLogDetail.files;let i=index" (click)="openGallerys(payLogDetail.files, i)"
                    style="cursor: pointer;">
                  <img src="{{file}}">
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="hei32 border-top"></div>
        <p>支付账单明细：</p>
        <div class="table-box-min">
          <table class="table table-bordered table-striped" style="max-height: 209px;overflow-y: auto;">
            <thead>
            <tr>
              <th>账单号</th>
              <th>账单类型</th>
              <th>支付金额</th>
              <th>账单周期</th>
              <th>备注</th>
            </tr>
            </thead>
            <tbody>
            <ng-template [ngIf]="payBillDetailList && payBillDetailList.length>0">
              <tr *ngFor="let payBill of payBillDetailList">
                <td>
                  <button type="button" class="btn btn-outline-primary"
                          (click)="showRelatedPayLogDetail(payBill.bill_id)">
                    {{payBill.bill_no}}
                  </button>
                </td>
                <td>{{payBill.bill_type}}</td>
                <td>{{payBill.pay_amount}}</td>
                <td>
                  <ng-template [ngIf]="payBill.bill_start_time && payBill.bill_end_time">
                    {{payBill.bill_start_time | date:'yyyy-MM-dd'}} - {{payBill.bill_end_time | date:'yyyy-MM-dd'}}
                  </ng-template>
                </td>
                <td class="new-lines">{{payBill.remark}}</td>
              </tr>
            </ng-template>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-12">
            <ng-container *ngIf="btn_privileges.ACCOUNTTING_CHECK && payLogDetail?.audit_status==='NO_PAY'">
              <label class="bold">更改状态</label>
              <div>
                <div class="form-check form-check-inline" *ngIf="payLogDetail?.is_check_pay">
                  <input class="form-check-input" type="checkbox"
                         [(ngModel)]="auditPayLogReq.is_checked"
                         [attr.disabled]="payLogDetail.check === 'CHECKED' ? true: null"
                         (change)="checkChange($event.target.checked)">
                  <label class="form-check-label">支票已收</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="auditPayLogReq.is_pay"
                         (change)="payChange($event.target.checked)">
                  <label class="form-check-label">款已到账</label>
                </div>
                <input type="text" class="form-control font-s inline col-7"
                       [(ngModel)]="createNumValue"
                       placeholder="请输入交易流水号（需填写转账/POS/支票的交易流水号）"/>
                &nbsp;&nbsp;&nbsp;
                <div class="form-check form-check-inline">
                  <div class="form-check-label">到账日期</div>
                  <div class="w-p-1 inline">
                    <my-date-picker [options]="accountTimeOptions"
                                    [(ngModel)]="manual_account_time"></my-date-picker>
                  </div>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox"
                         [(ngModel)]="auditPayLogReq.is_not_posted"
                         (change)="thePaymentIsNotPosted($event.target.checked,confirmModal)">
                  <label class="form-check-label">款未到账</label>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="col-2 text-right">
            <span class="text-primary pointer" (click)="logModalShow()">查看操作日志</span>
          </div>
        </div>

        <div class="text-center mt-4">
          <button type="button" class="btn btn-primary mr20 " (click)="doSubmit()"
                  *ngIf="btn_privileges.ACCOUNTTING_CHECK && payLogDetail?.audit_status==='NO_PAY'">
            保存
          </button>
          <button type="button" class="btn btn-primary mr20"
                  (click)="printShow(payLogDetail.lease_id, payLogDetail.pay_id)">
            <i class="fa fa-print"></i>打印
          </button>

          <button type="button" class="btn btn-secondary" (click)="closeModel()">关闭</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 图片查看 -->
<!-- <ngx-image-gallery #imageGallery [images]="images" [conf]="galleryConf"></ngx-image-gallery> -->
<!-- 打印预览 -->
<!--<app-print-preview #printPreview [src]="contractPath"></app-print-preview>-->

<app-lease-log #logModal></app-lease-log>

<div bsModal #confirmModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-="false">
  <div class="modal-dialog modal-primary modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header border-bottom-0">
        <h4 class="modal-title"><i class="fa fa-info-circle" aria-hidden="true"></i>操作确认</h4>
        <button type="button" class="close" (click)="cancelAuditingReject(confirmModal)" >
          <span aria-="true"><i class="icon icon-close" aria-="true"></i></span>
        </button>
      </div>
      <div class="modal-body mx-2 font-lg">
        <div class="row">
          <div class="col-12 col-lg-auto text-center">
            <p>拒绝该笔交易，确认资金未到账？</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-2">
            <p>登录密码：</p>
          </div>
          <div class="col-12 col-lg-auto px-0 mx-0">
            <input type="text" class="form-control"
                   (input)="passwordChange($event)"
                   [(ngModel)]="currentPassword"
                   placeholder="请输入登录密码"/>
          </div>
        </div>
        <p class="text-danger" *ngIf="passwordError">登录密码不正确</p>
      </div>
      <div class="modal-footer mx-2 mb-2 border-top-0">
        <button type="button" class="btn btn-primary" (click)="confirmRefuse(confirmModal)">确定</button>
        <button type="button" class="btn btn-secondary ml-5" (click)="confirmModal.hide()">取消</button>
      </div>
    </div>
  </div>
</div>
