import { NgModule } from '@angular/core';
import { SharedRootModule } from '../shared/module/share-common.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LogComponent } from './log/log.component';
import { LoginComponent } from './login/login.component';
import {UserRoutingModule} from "./user-routing.module";
import {MobileSafeWarnComponent} from "./mobile-safe-warn/mobile-safe-warn.component";
import {NgxTreeSelectModule} from "../third-party/tree-select";
import {WorkspaceModule} from "../workspace/workspace.module";
import { RegComponent } from './reg/reg.component';

@NgModule({
  imports: [
    NgxTreeSelectModule.forRoot({
      allowFilter: true,
      idField: 'id',
      textField: 'name',
      childrenField: 'children',
      allowParentSelection: true,
      expandMode: "None"
    }),
    UserRoutingModule,
    SharedRootModule,
    WorkspaceModule,
  ],
  exports: [
    LoginComponent,
    ResetPasswordComponent,
    RegComponent
  ],
  declarations: [
    LoginComponent,
    ResetPasswordComponent,
    LogComponent,
    MobileSafeWarnComponent,
    RegComponent
  ],
})
export class UserModule {
}
