import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import Decimal from "decimal.js";
import {IMyDpOptions} from "../../../third-party/mydatepicker/src/lib/interfaces";
import {ModalDirective} from "ngx-bootstrap/modal";
import {getTimeFromPlugin} from "../../../../common/date.util";
import {RegularExp} from "../../../../common/util.regexp";
import {WorkModel} from "../../../../model/common/common.model";
import {MessageModalInfo} from "../../../../model/modal/message-model";
import {
  CheckoutDateRoomDTO,
  CheckoutFinanceRoomListResponse,
  CheckoutFinanceRoomListViewDTO,
  CheckoutFinanceRoomListViewRequest,
  CheckoutFinanceRoomListViewResponse,
  CheckoutNoOtherFeeRequest, CheckoutRefundDTO,
  CheckoutRoomAffirmRequest,
  CheckoutRoomDTO,
  CheckoutViewNewDTO, ObjectDTO, RenterBillDTO, RenterViewList, SMSAuditCodeRequest
} from "../../../../model/signed/checkout.model";
import {WorkService} from "../../../../service/common/work.service";
import {SignedService} from "../../../../service/signed/signed.service";
import {
  QueryAccountLeaseRequest,
  QueryAccountLeaseResponse,
  UploadPaymentCertificateResponse
} from "../../../../model/clientinfo/client-info.model";
import {QueryAccountNameResponse} from "../../../../model/workbench/workbench.model";
import {ClientInfoService} from "../../../../service/clientinfo/ClientInfoService";

@Component({
  selector: 'app-checkout-finance-room-select',
  templateUrl: './checkout-finance-room-select.component.html',
  styleUrls: ['./checkout-finance-room-select.component.css']
})
export class CheckoutFinanceRoomSelectComponent implements OnInit {


  leaseId;
  leaseRoomIds;

  // step1
  myDatePickerOptions: IMyDpOptions = {
    dateFormat: "yyyy-mm-dd",
    editableDateField: false
  };
  endTime;

  resp: CheckoutFinanceRoomListResponse;

  step = 1;
  selectAll: boolean = false;
  rcSelectAll:boolean = false;
  selectCount = 0;

  // step2
  resp2: CheckoutFinanceRoomListViewResponse;
  respNoRefresh: CheckoutFinanceRoomListViewResponse;
  damageTotal = '0';

  noOverdue = false;
  noBreaking = false;
  auditList = [];
  inter;
  time = 60;
  codeBtnStr = '点击获取';
  gray_btn: boolean = false;
  cnof: CheckoutNoOtherFeeRequest = new CheckoutNoOtherFeeRequest();
  overdueType;
  overdueContent;
  validCode = false;
  isShowNumber = false;//是否显示
  @ViewChild('modal1') modal1: ModalDirective;

  showNoOverdue = true;
  showNoBreaking = true;

  // step3

  confirmReq: CheckoutFinanceRoomListViewRequest = new CheckoutFinanceRoomListViewRequest();

  clientUserNameMoneyId: number;
  uploadPaymentCertificateRsp: UploadPaymentCertificateResponse = new UploadPaymentCertificateResponse();
  queryAccountNameRsp: QueryAccountNameResponse = new QueryAccountNameResponse();
  queryAccountLeaseReq: QueryAccountLeaseRequest = new QueryAccountLeaseRequest();
  queryAccountLeaseResponse: QueryAccountLeaseResponse = new QueryAccountLeaseResponse();
  renterIds=[];
  roomCheckout=false;
  renterCheckout=false;
  constructor(
    private signedService: SignedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private workService: WorkService,
    private clientInfoService: ClientInfoService,
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      this.leaseId = param['leaseId'];
      this.search();
      this.confirmReq.checkout_refund_dto = new CheckoutRefundDTO();
      this.confirmReq.checkout_refund_dto.checkout_bill_pay_type_enum = 'TRANSFER';
      this.cnof.phone = '';
    });
    this.queryAccountLeaseReq.lease_id = this.leaseId;
    this.clientInfoService.queryAccountLease(this.queryAccountLeaseReq).then(data => {
      this.queryAccountLeaseResponse = data;
    });
  }


  // step 1
  search(notJump = false) {
    let time: number = null;
    if (this.endTime && this.endTime.formatted) {
      time = getTimeFromPlugin(this.endTime.formatted);
    }
    this.selectCount = 0;
    this.selectAll = false;
    this.signedService.checkoutFinanceRoomList(this.leaseId, time).then(data => {
      this.resp = data;
      if (this.resp.checkout_finance_room_list_view_dtolist && !notJump) {
        this.roomCheckout= this.resp.checkout_finance_room_list_view_dtolist.filter(e => !e.renter_view_list).length > 0;
        this.renterCheckout = this.resp.checkout_finance_room_list_view_dtolist.filter(e => e.renter_view_list.filter( r => !r.renter_checkout_number).length > 0).length > 0;
        if (this.resp.checkout_finance_room_list_view_dtolist.filter(e => !e.checkout_id).length === 1 && this.resp.checkout_finance_room_list_view_dtolist.filter(e => e.renter_view_list).length === 0) {
          this.step = 2;
          this.getStep2View([this.resp.checkout_finance_room_list_view_dtolist.filter(e => !e.checkout_id)[0].lease_room_id]);
        }
      }
    });
  }

  selectAllClick() {
    this.resp.checkout_finance_room_list_view_dtolist.forEach(e => {
      if (!(e.checkout_id || e.renter_view_list)) {
        e.checked = this.selectAll;
      }
    });

    this.selectCount = this.resp.checkout_finance_room_list_view_dtolist.filter(e => !e.checkout_id && e.checked).length;
    if(this.selectCount > 0){
      this.renterIds = [];
    }
  }
  selectRenterAllClick(event){
    this.renterIds = [];
    if(event.target.checked){
      this.resp.checkout_finance_room_list_view_dtolist.forEach(e => {
        if (e.renter_view_list) {
          e.renter_view_list.forEach(r =>{
            if(!r.renter_checkout_number){
              this.renterIds.push(r.renter_info_id);
            }
          });
        }
        e.checked = false;
      });
    }
  }

  itemClick(item: CheckoutFinanceRoomListViewDTO) {
    this.renterIds = [];
    this.selectCount = this.resp.checkout_finance_room_list_view_dtolist.filter(e => !e.checkout_id && e.checked).length;
    if(item.checked){
      this.roomCheckout = true;
      this.renterCheckout = false;
    }
    if (this.resp.checkout_finance_room_list_view_dtolist.filter(e => !e.checkout_id && e.checked).length
      === this.resp.checkout_finance_room_list_view_dtolist.filter(e => !e.checkout_id).length) {
      this.selectAll = true;
    }

    if (this.resp.checkout_finance_room_list_view_dtolist.filter(e => !e.checkout_id && e.checked).length
      !== this.resp.checkout_finance_room_list_view_dtolist.filter(e => !e.checkout_id).length
      ||
      this.resp.checkout_finance_room_list_view_dtolist.filter(e => !e.checkout_id && e.checked).length === 0
    ) {
      this.selectAll = false;
    }
  }

  goStep2() {
    if(this.renterIds.length === 0){
      this.step = 2;
      let leaseRoomIds = this.resp.checkout_finance_room_list_view_dtolist.filter(e => !e.checkout_id && e.checked).map(e => e.lease_room_id);
      this.leaseRoomIds = leaseRoomIds;
      this.getStep2View(leaseRoomIds);
    }else{
      this.router.navigate(['/system_manage/checkoutrenter/checkout/view'], {
        queryParams: {info_ids:this.renterIds,renter_id:this.renterIds[0]}
      });
    }
  }


  // step 2
  getStep2View(leaseRoomIds: Array<number>) {

    let req = new CheckoutFinanceRoomListViewRequest();
    req.lease_id = this.leaseId;
    req.checkout_view_dtolist = [];
    leaseRoomIds.forEach(leaseRoomId => {
      let dto = new CheckoutViewNewDTO();
      dto.lease_room_id = leaseRoomId;
      req.checkout_view_dtolist.push(dto);
    });
    this.signedService.checkoutFinanceRoomView(req).then(data => {
      this.respNoRefresh = JSON.parse(JSON.stringify(data));
      this.resp2 = data;
      this.refreshCheckbox();
    });

  }


  calculTotal(item: CheckoutDateRoomDTO): number {
    let total = 1;
    if (item.checkout_type === 'NORMAL') {
      total = (+item.overdue_total);
    } else if (item.checkout_type === 'OVERDUE') {
      total = (+item.overdue_total);
    }

    return total;
  }

  getAuditList() {
    if (this.noBreaking || this.noOverdue) {
      this.auditList = [];
      this.signedService.checkoutFinanceNomorefeeAuditList(this.noOverdue, this.noBreaking).then(data => {
        this.auditList = data.audit_user;
      });
    }
  }

  getCode() {
    if (this.cnof.phone) {
      if (this.time === 60) {
        this.time = 0;
        let req = new SMSAuditCodeRequest();
        req.code_type = 17;
        req.lease_id = this.leaseId;
        req.mobile = this.cnof.phone;
        req.no_overdue = this.noOverdue;
        req.no_breaking = this.noBreaking;
        this.signedService.leaseAuditCheckoutAuditSMS(req).then(data => {

          this.time = 60;
          this.gray_btn = true;
          this.inter = setInterval(() => {

            if (--this.time > 0) {
              this.codeBtnStr = this.time + 's后重新获取';
            } else {
              this.gray_btn = false;
              this.time = 60;
              this.codeBtnStr = '点击获取';
              clearInterval(this.inter);
            }

          }, 1000);
        }).catch(() => {
          this.time = 60;
        });
      }
    }
  }

  checkCode() {

    let req = new CheckoutNoOtherFeeRequest();
    req.lease_id = this.leaseId;
    req.phone = this.cnof.phone;
    req.code = this.cnof.code;
    req.no_overdue = this.noOverdue;
    req.no_breaking = this.noBreaking;
    this.signedService.checkoutFinanceNomorefeeAuditCheck(req).then(data => {
      this.validCode = true;
    });
  }

  refreshFee(): Promise<any> {
    let req = new CheckoutFinanceRoomListViewRequest();
    req.lease_id = this.leaseId;
    req.checkout_view_dtolist = [];

    req.no_overdue = this.noOverdue;
    req.no_breaking = this.noBreaking;

    req.damage_goods_list = this.respNoRefresh.damage_goods_list;

    this.resp2.checkout_room_dtolist.forEach(room => {
      let dto = new CheckoutViewNewDTO();
      dto.lease_room_id = room.lease_room_id;
      let checkoutDateRoomDTO = this.resp2.checkout_date_room_dtos.find(e => e.lease_room_ids.includes(dto.lease_room_id));
      dto.checkout_type = checkoutDateRoomDTO.checkout_type;
      req.checkout_view_dtolist.push(dto);
    });

    this.workService.event_bus.emit(new WorkModel(this.workService.type.SHOW_LOADING));

    return this.signedService.checkoutFinanceRoomView(req).then(data => {
      this.resp2 = data;
      this.refreshCheckbox();

      this.workService.event_bus.emit(new WorkModel(this.workService.type.HIDE_LOADING));
    });
  }


  goStep3() {

    this.isShowNumber = false;

    if (this.noOverdue || this.noBreaking) {
      if (!(this.cnof.phone && this.cnof.code && this.overdueType && this.overdueContent && this.validCode)) {
        return false;
      }
    }
    if (this.respNoRefresh.damage_goods_list) {
      // let tag = false;
      this.respNoRefresh.damage_goods_list.forEach(good => {
        if (!RegularExp.validAmount2.test(good.compensate_price)) {
          // tag = true;
          this.isShowNumber = true;
        }
      });
      if (this.isShowNumber) {
        return;
      }
    }

    this.refreshFee().then(() => {
      this.step = 3;
    });
  }

  // step 3
  sumCouponTotal(list: Array<CheckoutRoomDTO>, type?) {
    let total = new Decimal("0");
    if (list != null && list.length > 0) {
      for (let info of list) {
        if (info.check_out_room_info_dto != null && info.check_out_room_info_dto.recycle_items != null
          && info.check_out_room_info_dto.recycle_items.length > 0) {
          for (let coupon of info.check_out_room_info_dto.recycle_items) {
            if (!type || (type && type == coupon.object_name)) {
              total = total.add(new Decimal(coupon.object_value + ""));
            }
          }
        }
      }
    }
    return +(total.toString());
  }

  calculDamage() {
    if (this.respNoRefresh.damage_goods_list) {

      let total = new Decimal(0);
      this.respNoRefresh.damage_goods_list.forEach(good => {
        if (+good.compensate_price) {
          total = total.add(new Decimal(good.compensate_price));
        }
      });

      this.damageTotal = total.toString();
    }
  }

  validPrice(compensate_price: any): boolean {
    return RegularExp.validAmount2.test(compensate_price);
  }

  signCheck(): boolean {

    if (this.resp2.show_refund) {
      if (this.confirmReq.checkout_refund_dto.checkout_finance_type_enum) {
        if (this.confirmReq.checkout_refund_dto.checkout_finance_type_enum === 'REFUND') {
          if (this.confirmReq.checkout_refund_dto.checkout_bill_pay_type_enum) {
            if (this.confirmReq.checkout_refund_dto.checkout_bill_pay_type_enum === 'TRANSFER') {
              if (!this.confirmReq.checkout_refund_dto.refund_name) {
                return false;
              }
              if (!this.confirmReq.checkout_refund_dto.bank_no) {
                return false;
              }
              if (!this.confirmReq.checkout_refund_dto.open_bank) {
                return false;
              }
            }
          } else {
            return false;
          }
        }
      } else {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请选择退款方式'));
        return false;
      }
    }

    if (this.noOverdue || this.noBreaking) {
      if (!(this.cnof.phone && this.cnof.code && this.overdueType && this.overdueContent)) {
        return false;
      }
    }

    return true;
  }

  goConfirm() {

    if (!this.signCheck()) {
      return;
    }

    this.confirmReq.total = this.resp2.total;

    this.confirmReq.lease_id = this.leaseId;

    this.confirmReq.no_overdue = this.noOverdue;
    this.confirmReq.no_breaking = this.noBreaking;
    this.confirmReq.phone = this.cnof.phone;
    this.confirmReq.code = this.cnof.code;
    this.confirmReq.overdue_type = this.overdueType;
    this.confirmReq.overdue_content = this.overdueContent;

    this.confirmReq.damage_goods_list = this.respNoRefresh.damage_goods_list;

    this.confirmReq.checkout_view_dtolist = this.resp2.checkout_room_dtolist;
    this.confirmReq.checkout_refund_dto.account_money_info_id = this.clientUserNameMoneyId;

    this.signedService.checkoutFinanceRoomConfirm(this.confirmReq).then(data => {

      let messageModalInfo = new MessageModalInfo();
      messageModalInfo.operationMessage = "请联系客户进入乐享住APP“我的”页面进行退房单签字确认";
      messageModalInfo.title = "线上APP签字确认操作提示";
      messageModalInfo.okBtn = "好的";
      messageModalInfo.callback.subscribe(() => {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.TAB_CLOSE_SELF));
        this.router.navigate(['./../../../../pact', this.leaseId], {relativeTo: this.activatedRoute});
      });
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));

    });
  }

  refreshCheckbox() {
    this.showNoBreaking = false;
    this.showNoOverdue = false;
    if (this.resp2.checkout_room_dtolist) {
      if (this.resp2.has_overdue_fee) {
        this.showNoOverdue = true;
      }
      this.resp2.checkout_room_dtolist.forEach(data => {
        if (data.checkout_type === 'BREAK') {
          this.showNoBreaking = true;
        }
      });
    }
    if (!this.showNoBreaking) {
      this.noBreaking = false;
    }
    if (!this.showNoOverdue) {
      this.noOverdue = false;
    }
  }
  clientShiftFocus(e) {
    this.checkRequiredClick();
  }
  checkRequiredClick(){
    if(this.clientUserNameMoneyId && this.uploadPaymentCertificateRsp.payment_amount ){
      //this.isBtn = true;
    }else{
      //this.isBtn = false;
    }
  }

  rcClick(rc:RenterViewList,event,renterList){
    if(event.target.checked){
      this.roomCheckout = false;
      this.renterCheckout = true;
      this.renterIds.push(rc.renter_info_id);
      this.resp.checkout_finance_room_list_view_dtolist.forEach(c =>{
        c.checked = false;
      });
    }else{
      this.renterIds = this.renterIds.filter(r => r !== rc.renter_info_id);
    }
    if(renterList.length && this.renterIds.length === renterList.length && this.renterIds.length > 0){
      this.rcSelectAll = true;
    }else{
      this.rcSelectAll = false;
    }
  }

  goRenterCheckout(id){
    this.router.navigate(['/system_manage/signed/renter/checkout/record/detail/'+id]);
  }
}
