import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-text-collapse',
  templateUrl: './text-collapse.component.html',
  styleUrls: ['./text-collapse.component.css']
})
export class TextCollapseComponent implements AfterViewInit {


  @Input() height = 22;
  showMore = false;


  @ViewChild('contentDiv') contentDiv: ElementRef;

  ngAfterViewInit(): void {
    this.showMore = this.contentDiv.nativeElement.offsetHeight <= this.height * 3;
  }

  loadMore() {
    this.showMore = true;
  }

}
