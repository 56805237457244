import {Component, OnInit, ViewChild} from '@angular/core';
import {AppointListRequest, AppointListResponse} from "../../../model/appoint/appoint.model";
import {AppointService} from "../../../service/appoint/appoint.service";
import {AuthService} from "../../../service/common/auth.service";
import {SeehouseDetailComponent} from "../seehouse-detail/seehouse-detail.component";

declare var baidutongji:any;

@Component({
  selector: 'app-seehouse-list',
  templateUrl: './seehouse-list.component.html',
  styleUrls: ['./seehouse-list.component.css']
})
export class SeehouseListComponent implements OnInit {
  btn_privileges: any = {
    'SEE_HOUSE_LIST': false,
    'SEE_HOUSE_OPERATE': false,
    'APPOINT_ROOM_WAIT_VIEW': false,
    'WSEE_HOUSE_OPERATE': false,
  };

  appointListRequest: AppointListRequest = {
    status: '',
    page_num: 1,
    page_size: 20
  };
  appointListResponse: AppointListResponse = {appoint_info_dtos: [], total: 0 };

  @ViewChild('detailComponent', { static: true }) detailComponent: SeehouseDetailComponent;

  constructor(private authService: AuthService,
              private appointService: AppointService) { }

  ngOnInit() {
    this.authService.getOperatePrivilege(this.btn_privileges);
    this.searchList();
    baidutongji("预约看房管理");
  }


  searchList(){
    this.appointService.listWithLoading(this.appointListRequest).then(data => {
      if (data && data.total > 0) {
        this.appointListResponse = data;
      } else {
        this.appointListResponse.appoint_info_dtos = [];
        this.appointListResponse.total = 0;
      }
    });
  }

  statusSet(event) {
    this.appointListRequest.page_num = 1;
    this.appointListRequest.status = event.target.value;
  }

  pageChange(page) {
    this.appointListRequest.page_num = page;
    this.searchList();
  }

  clear() {
    this.appointListRequest = new AppointListRequest();
    this.appointListRequest.status = '';
    this.appointListRequest.page_num = 1;
    this.appointListRequest.page_size = 20;
    this.searchList();
  }

  openOperateModal(id: number){
    baidutongji("预约看房处理");
    this.detailComponent.showModel(id);
  }

  auditRefresh() {
    this.searchList();
  }
}
