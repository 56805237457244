import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {RequestService} from '../request.service';
import {
  BindingIpadPcRequest,
  BindingIpadPcResponse,
  RedisIpadPcResponse,
  QuerIpadPcResponse,
  SignIpadRequest,
  SignIpadResponse,
  LeaseCheckoutRequest,LeaseCheckoutResponse
} from '../../model/devicebinding/devicebinding.model';

@Injectable()
export class DevicebindingService {

  //绑定设备
  private BINDING_IPADPC_URL = `${environment.apiHost}signBoard/bindingIpadPc`;
  //获取待连接设备列表
  private REDIS_IPADPC_LIST_URL = `${environment.apiHost}signBoard/getRedisIpadPc`;
  //获取已连接设备
  private QUERY_IPADPC_LIST_URL = `${environment.apiHost}signBoard/queryIpadPc`;
  //查询pad是否有有效连接
  private QUERY_PAD_CONNECT = `${environment.apiHost}signBoard/pad/connect`;
  //查询pad task
  private QUERY_PAD_TASK = `${environment.apiHost}signBoard/pad/task`;
  //更新pad task
  private UPDATE_PAD_TASK = `${environment.apiHost}signBoard/task/update`;
  //查询pad 签字状态
  private QUERY_PAD_SIGN_STATUS = `${environment.apiHost}signBoard/signStatus`;

  private QUERY_CHECKOUT_RECORD_NUMBER = `${environment.apiHost}signBoard/checkRecordNumber`;

  constructor(private requestService: RequestService) {
  }

  /**
   * 设备绑定与解绑
   * @param bindingIpadPcRequest
   */
  bindingIpadPc(bindingIpadPcRequest: BindingIpadPcRequest): Promise<BindingIpadPcResponse> {
    return this.requestService.authGet(this.BINDING_IPADPC_URL, bindingIpadPcRequest);
  }

  /**
   * 获取待连接设备
   */
  getRedisIpadPc(): Promise<RedisIpadPcResponse> {
    return this.requestService.authGet(this.REDIS_IPADPC_LIST_URL, null);
  }

  /**
   * 获取已连接设备
   * @param bindingIpadPcRequest
   */
  queryIpadPc(bindingIpadPcRequest: BindingIpadPcRequest): Promise<QuerIpadPcResponse> {
    return this.requestService.authGet(this.QUERY_IPADPC_LIST_URL, bindingIpadPcRequest);
  }

  /**
   * 查询pad是否有有效连接
   * @param request
   * @returns {Promise<any>}
   */
  queryPadConnect(request: any): Promise<any> {
    return this.requestService.authPost(this.QUERY_PAD_CONNECT, request);
  }

  /**
   * 查询pad task
   * @param request
   * @returns {Promise<any>}
   */
  queryPadTask(request: any): Promise<any> {
    return this.requestService.authPost(this.QUERY_PAD_TASK, request);
  }

  /**
   * 更新pad task的状态
   * @param request
   * @returns {Promise<any>}
   */
  updatePadTask(request: any): Promise<any> {
    return this.requestService.authPost(this.UPDATE_PAD_TASK, request);
  }

  /**
   * 获取业务签字状态
   * @param signOnIpadRequest
   */
  querySignStatus(signIpadRequest: SignIpadRequest): Promise<SignIpadResponse> {
    return this.requestService.authGet(this.QUERY_PAD_SIGN_STATUS, signIpadRequest);
  }

  queryCheckoutNumber(leaseCheckoutRequest: LeaseCheckoutRequest): Promise<LeaseCheckoutResponse> {
    return this.requestService.authGet(this.QUERY_CHECKOUT_RECORD_NUMBER, leaseCheckoutRequest);
  }
}
