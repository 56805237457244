<div class="over15">
  <div class="font-weight-bold">物品交接单信息确认：</div>
  <div class=" ">请仔细确核对并检查以下房间物品是否损坏认，确认无误后进行签字确认。</div>

  <div class="text-red text-center" *ngIf="datatList && datatList.need_refresh_reading">
    最新水表读数获取中，30s后自动刷新。1分钟后请手动刷新页面。如10分钟后仍未获取请手动抄表
  </div>
  <!-- tbody超出15个tr就加  overflow-y:auto; -->
  <ng-container>
    <table class="table table-bordered">
      <thead class="thead-light">
      <tr>
        <th>房间号</th>

        <th>水表读数</th>
        <th>热水表读数</th>
        <th>电表读数</th>
        <th>物品损坏</th>

      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let info of listData;let j = index">
        <td class="text-danger font-weight-bold fa-2x">{{info.room_info_dto.room_name}}</td>

        <td>
          <ng-container *ngIf="info.water_dev&&info.water_reading_temp">
            {{info.water_reading}}
            <!-- <span class="gray">{{info.water_reading_time|date:'yyyy-MM-dd HH:mm'}}</span> -->
          </ng-container>
          <ng-container *ngIf="info.water_dev&&!info.water_reading_temp">
            <input type="text" [(ngModel)]="info.water_reading" placeholder="请输入设备读数" (change)="infoClick()">
          </ng-container>
          <ng-container *ngIf="!info.water_dev&&info.need_water=='YES'">
            <input type="text" [(ngModel)]="info.water_reading" placeholder="请输入设备读数" (change)="infoClick()">
          </ng-container>
          <ng-container *ngIf="!info.water_dev&&info.need_water=='NO'">
            <span class="org">此房型设置了不需要抄表收费</span>
          </ng-container>
        </td>
        <td>
          <ng-container *ngIf="info.hot_water_dev&&info.hot_water_reading_temp">
            {{info.hot_water_reading}}
            <!-- <span class="gray">（{{info.hot_water_reading_time|date:'yyyy-MM-dd HH:mm'}}）</span> -->
          </ng-container>
          <ng-container *ngIf="info.hot_water_dev&&!info.hot_water_reading_temp">
            <input type="text" [(ngModel)]="info.hot_water_reading" placeholder="请输入设备读数" (change)="infoClick()">
          </ng-container>
          <ng-container *ngIf="!info.hot_water_dev&&info.need_hot_water=='YES'">
            <input type="text" [(ngModel)]="info.hot_water_reading" placeholder="请输入设备读数" (change)="infoClick()">
          </ng-container>
          <ng-container *ngIf="!info.water_dev&&info.need_hot_water=='NO'">
            <span class="org">此房型设置了不需要抄表收费</span>
          </ng-container>
        </td>
        <td>
          <ng-container *ngIf="info.electricity_dev&&info.electricity_reading_temp">
            {{info.electricity_reading}}
            <!-- <span class="gray">（{{info.electricity_reading_time|date:'yyyy-MM-dd HH:mm'}}）</span> -->
          </ng-container>
          <ng-container *ngIf="info.electricity_dev&&!info.electricity_reading_temp">
            <input type="text" [(ngModel)]="info.electricity_reading" placeholder="请输入设备读数" (change)="infoClick()">
          </ng-container>
          <ng-container *ngIf="!info.electricity_dev&&info.need_electricity=='YES'">
            <input type="text" [(ngModel)]="info.electricity_reading" placeholder="请输入设备读数" (change)="infoClick()">
          </ng-container>
          <ng-container *ngIf="!info.electricity_dev&&info.need_electricity=='NO'">
            <span class="org">此房型设置了不需要抄表收费</span>
          </ng-container>
        </td>

        <td>
          <div *ngIf="info.out_room_details_type">

            <div *ngFor="let item of info.details">
              <div>
                <span>{{item.goodName}}</span>
                <span>【部分损坏{{item.fewDamage || 0}}件;</span>
                <span>全部损坏{{item.allDamage || 0}}件;</span>
                <span>丢失{{item.loseDamage || 0}}件】</span>
                <i class="fa fa-close text-danger" (click)="delete(info,item)"></i>
              </div>

            </div>

          </div>

          <button class="btn btn-outline-primary" (click)="addBad(info,info.details)">添加损坏物品</button>
        </td>
      </tr>
      <tr *ngIf="listData.length == 0">
        <td colspan="9">暂无数据</td>
      </tr>
      </tbody>
    </table>


  </ng-container>
  <div>
    <app-check-out-popup (callback)="callback($event)"></app-check-out-popup>
  </div>
</div>
