import {Component, OnInit} from '@angular/core';
import {NzUploadFile} from "ng-zorro-antd/upload";
import {environment} from "../../../../environments/environment";
import {WorkModel} from "../../../model/common/common.model";
import {MessageModalInfo} from "../../../model/modal/message-model";
import {CleanTaskImportDTO, CleanTaskImportPreviewResponse, RoomBatchImportPreviewRequest} from "../../../model/room/repair.model";
import {AuthService} from "../../../service/common/auth.service";
import {WorkService} from "../../../service/common/work.service";
import {ImageService} from "../../../service/image-upload/image.service";
import {RepairService} from "../../../service/room/repair.service";

@Component({
  selector: 'app-clean-task-import-batch',
  templateUrl: './clean-task-import-batch.component.html',
  styleUrls: ['./clean-task-import-batch.component.css']
})
export class CleanTaskImportBatchComponent implements OnInit {


  preview_url = `${environment.apiHost}clean/task/import/preview`;

  header = {
    Authorization: this.authService.getAuthToken()
  };

  fileList = [];
  list: Array<CleanTaskImportDTO>;
  content: string;
  errorList: Array<string> = [];
  resp: CleanTaskImportPreviewResponse;

  constructor(
    private authService: AuthService,
    private workService: WorkService,
    private repairService: RepairService
  ) {
  }

  ngOnInit(): void {
  }

  // 上传预览
  preview($event, tipsModal: any) {
    this.fileList = [];
    this.fileList.push($event.file);
    if ($event.type === "success") {
      let response = $event.file.response;
      if (response.status_code === "SUCCESS") {
        this.resp = response.biz_response;
        if (response.biz_response.clean_task_import_dtolist) {
          this.list = response.biz_response.clean_task_import_dtolist;
          this.workService.showSuccessModal("成功导入【" + response.biz_response.success_count + "】间保洁任务，【" + response.biz_response.err_count + "】条失败，请确认信息！");
        }
      } else {
        this.content = response.message;
        tipsModal.show();
      }
    }
  }

  // 确认上传
  ensureUpload() {
    if (this.resp && this.list && this.list.length > 0 && this.resp.err_count === 0) {
      let req = new RoomBatchImportPreviewRequest();
      req.clean_task_import_dtolist = this.resp.clean_task_import_dtolist;
      this.repairService.importCleanTask(req).then(() => {
        let messageModalInfo = new MessageModalInfo();
        messageModalInfo.operationMessage = '添加成功！';
        messageModalInfo.returnUrl = '/system_manage/workspace/cleantask';
        messageModalInfo.callback.subscribe(() => {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.TAB_CLOSE_SELF));
        });
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
      });
    }
  }

  // 移除文件
  removeFile = (file: NzUploadFile): boolean => {
    this.fileList = [];
    this.list = [];

    return false;
  }

}
