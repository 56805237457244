import {Component, Input, OnInit} from '@angular/core';
import {ApprovalResultResponse} from "../../../model/approv/approv.model";

@Component({
  selector: 'app-approv-record-log-list',
  templateUrl: './approv-record-log-list.component.html',
  styleUrls: ['./approv-record-log-list.component.css']
})
export class ApprovRecordLogListComponent implements OnInit {

  @Input()
  approvalResultResponse: ApprovalResultResponse;

  constructor() { }

  ngOnInit(): void {
  }

  calTime(take_up_time: number): string {

    let day = parseInt(take_up_time / 1000 / 3600 / 24 + "", 10);
    let hour = parseInt(take_up_time / 1000 / 3600 + "", 10) % 24;
    let min = parseInt(take_up_time / 1000 / 60 + "", 10) % 60;

    if (day > 0) {
      return `${day}天${hour}小时${min}分钟`;
    } else if (hour > 0) {
      return `0天${hour}小时${min}分钟`;
    } else {
      return `0天0小时${min}分钟`;
    }
  }

}
