export class BindingIpadPcRequest {
  pc_uuid: string;
  ipad_uuid: string;
  type: string;
}
export class BindingIpadPcResponse{
  result: string;
}
export class RedisIpadPcResponse{
  ipad_uuids: Array<string>;
}

export class QuerIpadPcResponse{
  pc_uuid: string;
  ipad_uuid: string;
}

export class SignIpadRequest{
  pad_uuid: string;
}

export class SignIpadResponse{
  pad_no: string;
  router_link: string;
  sign_router_link_rype: string;
  computer_no: string;
  status: string;
}
export class LeaseCheckoutRequest{
  lease_id: number;
}
export class LeaseCheckoutResponse{
  lease_checkout_dtos: Array<LeaseCheckoutDTO>;
}
export class LeaseCheckoutDTO{
  record_no: string;
  room_id: number;
}
