<!-- {{initSomething(confirm_lease_modal)}} -->

<div class="setbox-top">
  <div class="bg-primary ">
    <div class="row">
      <div class="col-12 col-md-6 col-xl-7 hei32">
        <i class="fa fa-info-circle">
          合同号：{{leaseDetailResponse?.contract_num}}
        </i>
      </div>
      <div class="col-12 col-md-6 col-xl-5">
        <p class="text-yellow1 text-right hei32">
          <span class="mr20">
            <ng-container *ngIf="!leaseDetailResponse?.audit_position_name">
                合同状态：{{leaseDetailResponse?.business_status_desc}}
            </ng-container>
            <ng-container *ngIf="leaseDetailResponse?.audit_position_name">
                合同状态：{{leaseDetailResponse?.business_status_desc + '【' + leaseDetailResponse?.audit_position_name + '】'}}
            </ng-container>
          </span>
        </p>
      </div>
    </div>
  </div>

  <div class="setbox">
    <!--合同取消-->
    <div class="p-3 mb-2 bg-warning text-orange mb10" *ngIf="leaseDetailResponse?.lease_status === 'CANCEL'">
      此合同已取消，无法进行任何操作
    </div>

    <!--合同已提交，审核中-->
    <div
      *ngIf="leaseDetailResponse?.lease_status === 'COMMITTED' && leaseDetailResponse?.audit_status === 'WAITING_AUDIT'">
      <div class="p-3 mb-2 bg-warning text-orange mb10">此合同待审核中，请等待审核完成后操作</div>
      <div>
        <div class="row mb10">
          <div class="col-12">
            <button type="button" class="btn btn-secondary" *ngIf="btn_privileges.CANCLE_LEASE_BTN"
                    (click)="cancleLease(leaseDetailResponse?.lease_id)">
              <i class="fa fa-trash-o"></i>取消合同
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--合同已提交，审核通过-->
    <div *ngIf="leaseDetailResponse?.lease_status === 'COMMITTED'
        && (leaseDetailResponse?.audit_status==='AUDIT_SUCCESS' || leaseDetailResponse?.audit_status==='NO_AUDIT')">
      <div class="p-3 mb-2 bg-warning text-orange mb10">签署合同后，必须上传合同或者合同签字后，才能进行更多操作；签署后不能取消合同；</div>
      <div>
        <div class="row">
          <div class="col-12 mb10">
            <button type="button" class="btn btn-secondary mr10" (click)="cancleLease(leaseDetailResponse?.lease_id)"
                    *ngIf="btn_privileges.CANCLE_LEASE_BTN">
              <i class="fa fa-trash-o"></i>取消合同
            </button>

            <!---->
            <button type="button" class="btn btn-sign mr10"
                    (click)="confirmRemind('SIGN')"
                    *ngIf="btn_privileges.LEASE_CONTRACT_SIGN && leaseDetailResponse.renter_type == 'PERSON'
                     && projectConfig && projectConfig.need_use_electronic_contract == 1 && leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'">
              <i class="fa fa-lock"></i>合同签字
            </button>

            <!-- 隐藏按钮 实际功能 -->
            <button type="button" class="btn btn-sign mr10"
                    [routerLink]="['/system_manage/signed/contract', leaseDetailResponse?.lease_id]"
                    *ngIf="btn_privileges.LEASE_CONTRACT_SIGN && leaseDetailResponse.renter_type == 'PERSON'
                    && projectConfig && projectConfig.need_use_electronic_contract == 1 && leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'"
                    id="sign_lease_btn"
                    hidden>
              <i class="fa fa-lock"></i>合同签字
            </button>

            <!--            <button type="button" class="btn btn-primary mr10" (click)="contractPrint()"-->
            <!--                    *ngIf="btn_privileges.LEASE_CONTRACT_PRINT">-->
            <!--              <i class="fa fa-print"></i>合同打印-->
            <!--            </button>-->

            <button type="button" class="btn btn-primary mr10" (click)="confirmRemind('UPLOAD')"
                    *ngIf="btn_privileges.LEASE_CONTRACT_UPLOAD && leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'">
              <i class="fa fa-upload"></i>合同上传
            </button>

            <!-- 隐藏按钮 实际功能 -->
            <app-new-file-upload
              *ngIf="btn_privileges.LEASE_CONTRACT_UPLOAD && leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'"
              #contract_file_upload
              name="合同上传"
              [url]="contract_upload_url"
              [accept]="'PDF'"
              (fileChange)="contractUpload($event)"
              hidden>
            </app-new-file-upload>
          </div>
        </div>
      </div>
    </div>

    <!--合同已生效，审核通过-->
    <div *ngIf="(leaseDetailResponse?.lease_status === 'EFFECTIVE' || leaseDetailResponse?.lease_status === 'EXPIRED')
                && (leaseDetailResponse?.audit_status === 'AUDIT_SUCCESS' || leaseDetailResponse?.audit_status === 'NO_AUDIT')">
      <div>
        <!-- 合同有效，且存在有效的合同文件-->
        <div class="row" [style.display]="(leaseDetailResponse && (leaseDetailResponse.business_status != 'EFFECTIVE'
  || (leaseDetailResponse.business_status == 'EFFECTIVE' && leaseDetailResponse.have_effective_contract) ? 'block' : 'none'))">
          <div class="col-12">
            <button type="button" *ngIf="btn_privileges.LEASE_GATHERING_BTN" class="btn btn-warning  mb10 mr10"
                    (click)="showDialog()">
              <i class="fa fa-cny"></i>收款
            </button>

            <button *ngIf="btn_privileges.BATCH_RECHARGE_PREPAY &&
            leaseDetailResponse.lease_subject_info_dto.subject_type == 'COMPANY' &&
            !leaseDetailResponse.open_company_prepay_account &&
           leaseDetailResponse?.business_status === 'EFFECTIVE'"
                    type="button"
                    class="btn btn-primary mb10 mr10"
                    (click)="openRechargeModel()">
              <img class="recharge-img" src="/assets/img/recharge-img.png"/>批量充值水电
            </button>
            <!-- yundun 3.0 移除
            <button *ngIf="btn_privileges.LEASE_ROOM_CHECKIN && leaseDetailResponse.check_in" type="button"
                    class="btn btn-warning mb10 mr10"
                    (click)="openRenters()">
              <i class="fa fa-vcard"></i>办理入住
            </button>-->
            <app-lessee #lesseeComponent [lease_id]="leaseDetailResponse.lease_id"
                        (renterChange)="refush($event)"></app-lessee>
            <button
              *ngIf="btn_privileges.LEASE_RELET_BTN && leaseDetailResponse?.relte && leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'"
              type="button"
              class="btn btn-primary mb10 mr10"
              (click)="goRelet()">
              <i class="fa fa-vcard"></i>续约
            </button>

            <button type="button" class="btn btn-primary mb10 mr10" (click)="changeRoom()"
                    *ngIf="btn_privileges.LEASE_CHANGE_BTN && leaseDetailResponse?.change_room">
              <i class="fa fa-print"></i>换房
            </button>

            <button
              *ngIf="btn_privileges.LEASE_ROOM_CHECKOUT && leaseDetailResponse.check_out  && leaseDetailResponse.show_old_checkout"
              type="button"
              class="btn btn-out mb10 mr10"
              (click)="checkOut()">
              <i class="fa fa-unlink"></i>退房(旧版)
            </button>

            <button type="button"
                    *ngIf="btn_privileges.LEASE_ROOM_CHECKOUT && leaseDetailResponse.check_out"
                    class="btn btn-out mb10 mr10" (click)="checkOutRoom()">
              <i class="fa fa-unlink "></i>退房
            </button>

            <button type="button"
                    class="btn btn-warning mb10 mr10"
                    *ngIf="btn_privileges.LEASE_ROOM_CHECKOUT && (leaseDetailResponse.check_out_finance || leaseDetailResponse.renter_checkout_num > 0)"
                    (click)="checkoutFinanceRoomList()">
              <i class="fa fa-money "></i>费用结算
            </button>

            <button *ngIf="btn_privileges.LEASE_FILE_VIEW && leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'"
                    type="button" class="btn btn-primary mb10 mr10"
                    (click)="contractPreview()">
              <i class="fa fa-print"></i>合同预览
            </button>

            <button type="button"
                    *ngIf="(btn_privileges.LEASE_REFUND_BTN &&  (leaseDetailResponse.check_in || leaseDetailResponse.check_out)) && leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'"
                    class="btn btn-primary mb10 mr10" (click)="showRefundDialog()">
              <i class="fa fa-undo "></i>退款
            </button>

            <button
              *ngIf="btn_privileges.SUBLEASE_SIGN_BTN && leaseDetailResponse?.lease_status === 'EFFECTIVE' && leaseDetailResponse?.can_sublease && leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'"
              type="button"
              class="btn btn-out mb10 mr10"
              (click)="subleaseModal()">
              <i class="fa fa-retweet"></i>转租
            </button>

            <button type="button"
                    *ngIf="btn_privileges.ELE_DIFF_BTN && isRefundDiffShowBtn"
                    class="btn btn-primary mb10 mr10" (click)="showElectricFeeRefund()">
              <i class="fa fa-undo "></i>电费补差退款
            </button>


            <button type="button"
                    class="btn btn-outline-warning mb10 mr10" (click)="showLeaseChangeModal()">
              <i class="fa fa-edit "></i>信息变更
            </button>

            <button type="button"
                    class="btn btn-primary mb10 mr10" (click)="showRenterCheckoutRecord()">
              <i class="fa fa-clock-o "></i>退宿记录
            </button>


          </div>
        </div>

        <!-- 合同有效，且不存在有效的合同文件-->
        <div class="row" *ngIf="leaseDetailResponse && leaseDetailResponse.business_status == 'EFFECTIVE'
  && !leaseDetailResponse.have_effective_contract && leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'">
          <div class="col-12 mb10">
            <button type="button" class="btn btn-primary mr10" (click)="clickReUploadBtn()"
                    *ngIf="btn_privileges.LEASE_CONTRACT_UPLOAD">
              <i class="fa fa-upload"></i>合同上传
            </button>
            <span class="text-red">请先上传合同，再操作合同功能</span>
            <!-- 隐藏按钮 实际功能 -->
            <app-new-file-upload *ngIf="btn_privileges.LEASE_CONTRACT_UPLOAD"
                                 #contract_file_upload2
                                 name="合同上传"
                                 [url]="contract_upload_url"
                                 [accept]="'PDF'"
                                 (fileChange)="contractUpload($event)"
                                 hidden>
            </app-new-file-upload>
          </div>
        </div>
      </div>
    </div>

    <!-- 签字提示 -->
    <div *ngIf="signatureList && signatureList.length>0">
      <div class="signatureBox">
        <div *ngFor="let item of signatureList">
          <span class="pointSize">·</span>
          <span>费用结算单号</span>
          <span class="numberColorBule"
                (click)="checkoutRecordPreview(item.checkout_record_id)">{{item.checkout_record_num}}</span>
          <div *ngIf="item.status == '1' " class="isInline">
            <span class="numberColorRed"> 客户签字确认中</span>
            <span class="numberColorGrid">（剩余{{item.checkout_confirm_end_time_new}}
              ，若过期仍未签字系统将视为自动同意）</span>
            <span class="numberColorBule" (click)="signatureCancel(item.checkout_record_id)">【取消】</span>
          </div>
          <div *ngIf="item.status == '2' " class="isInline">
            <span class="numberColorGreen"> 客户已完成签字，请跟进财务退款 </span>
          </div>
          <div *ngIf="item.status == '3' " class="isInline">
            <span class="numberColorGreen"> 客户已完成签字，请操作收款</span>
            <span class="numberColorBule" (click)="getCheckoutBill(item)">【收款】</span>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-12">
        <div class="yellow">
          <h5>合同基本信息</h5>
          <div class="row padd-l-15">
            <div class="col-12 col-md-2 mb10" style="cursor: pointer;"
                 *ngIf="leaseDetailResponse?.lease_type==='ORIGIN' && leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'">
              类型：新合同
            </div>
            <div class="col-12 col-md-2 mb10"
                 *ngIf="leaseDetailResponse?.lease_type==='RELET' && leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'">
              类型：续约合同
            </div>
            <div class="col-12 col-md-2 mb10"
                 *ngIf="leaseDetailResponse?.lease_type==='SUBLEASE' && leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'">
              类型：转租合同
            </div>
            <div class="col-12 col-md-2 mb10" style="cursor: pointer;"
                 *ngIf="leaseDetailResponse?.lease_type==='EXCHANGE' && leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'">
              类型：新合同
            </div>
            <div class="col-12 col-md-2 mb10" style="cursor: pointer;"
                 *ngIf="leaseDetailResponse?.lease_step==='INTERNAL_LEASE'">
              类型：内部免租合同
            </div>

            <div class="col-12 col-md-4 mb10">
              签约人：{{leaseDetailResponse?.lease_subject_info_dto?.subject_type === 'PERSON' ?
              leaseDetailResponse?.lease_subject_info_dto?.name :
              leaseDetailResponse?.lease_subject_info_dto?.company_name}}
            </div>

            <div class="col-12 col-md-4 mb10">
              合同租期：{{leaseDetailResponse?.reserve_contact_info_dto?.start_time | date:'yyyy.MM.dd'}}
              -
              {{leaseDetailResponse?.reserve_contact_info_dto?.end_time | date:'yyyy.MM.dd'}}
            </div>

            <div class="col-12 col-md-2 mb10" *ngIf="leaseDetailResponse?.lease_step !=='INTERNAL_LEASE'">
              付款周期：{{leaseDetailResponse?.reserve_contact_info_dto?.pay_cycle | enumTransfer:pay_cycle_list}}
              <span *ngIf="leaseDetailResponse?.generate_bill_type ==='CONTRACT_MONTH'">（按合同周期生成账单）</span>
              <span *ngIf="leaseDetailResponse?.generate_bill_type ==='NATURAL_MONTH'">（按自然周期生成账单）</span>
            </div>
          </div>
          <div class="row padd-l-15" *ngIf="leaseDetailResponse?.lease_step !=='INTERNAL_LEASE'">
            <!--<div class="col-12 col-md-6 col-lg-1 col-xl-1">-->
            <!--预约单：-->
            <!--<span class="text-primary" style="cursor: pointer;">-->
            <!--{{leaseDetailResponse?.reserve_lease_id != null ? leaseDetailResponse?.reserve_lease_id : '无'}}</span>-->
            <!--</div>-->
            <div class="col-12 col-md-3 mb10" *ngIf="leaseDetailResponse?.booking_lease_id">
              关联预定号：
              <span class="text-danger" style="cursor: pointer;"
                    [routerLink]="['../../../booking-management/detail/',leaseDetailResponse?.booking_lease_id]">
                  {{leaseDetailResponse?.booking_lease_num}}
                </span>
            </div>
            <div class="col-12 col-md-2 mb10" *ngIf="!leaseDetailResponse?.booking_lease_id">关联预定号：
              <span class="text-primary">无</span>
            </div>

            <div class="col-12 col-md-4 mb10"
                 *ngIf="leaseDetailResponse?.lease_type==='RELET' || leaseDetailResponse?.lease_type==='SUBLEASE'">
              原合同号：
              <span class="text-primary" style="cursor: pointer;"
                    [routerLink]="['/system_manage/signed/pact/',leaseDetailResponse?.parent_lease_id]">{{leaseDetailResponse?.parent_contract_num}}
                </span>
            </div>
            <div class="col-12 col-md-4 mb10">
              关联续约合同：
              <span class="text-primary" *ngIf="!leaseDetailResponse?.children_lease_id">无</span>
              <span class="text-primary" style="cursor: pointer;"
                    *ngIf="leaseDetailResponse?.children_lease_id && leaseDetailResponse?.children_lease_status !== 'UNCOMMITTED'"
                    [routerLink]="['/system_manage/signed/pact/',leaseDetailResponse?.children_lease_id]">
                   {{leaseDetailResponse?.children_lease_id != null ? leaseDetailResponse?.children_contract_num : '无'}}
                </span>
              <span class="text-primary" style="cursor: pointer;"
                    *ngIf="leaseDetailResponse?.children_lease_id&&leaseDetailResponse?.children_lease_status==='UNCOMMITTED'"
                    [routerLink]="['../../../lease/relet/',leaseDetailResponse?.children_lease_id]">
                  {{leaseDetailResponse?.children_lease_id != null ? leaseDetailResponse?.children_contract_num : '无'}}
                </span>
            </div>
            <div class="col-12 col-md-4 mb10">
              关联转租合同：
              <span class="text-primary"
                    *ngIf="!leaseDetailResponse?.sublease_info_dtos || leaseDetailResponse?.sublease_info_dtos?.length == 0">无</span>
              <ng-container *ngIf="leaseDetailResponse?.sublease_info_dtos">
                  <span *ngFor="let item of leaseDetailResponse?.sublease_info_dtos">
                    <ng-container *ngIf="item?.children_lease_status !== 'UNCOMMITTED'">
                      <button class="text-primary" style="background: transparent;"
                              [routerLink]="['/system_manage/signed/pact/',item?.children_lease_id]">
                        {{item?.children_lease_id != null ? item?.children_contract_num : '无'}}
                      </button>
                    </ng-container>
                    <ng-container *ngIf="item?.children_lease_id&&item?.children_lease_status==='UNCOMMITTED'">
                      <button class="text-primary" style="background: transparent;"
                              [routerLink]="['../../../lease/tolease/tolease']"
                              [queryParams]="{'lease_id':item?.children_lease_id}">
                        {{item?.children_lease_id != null ? item?.children_contract_num : '无'}}
                      </button>
                    </ng-container>
                  </span>
              </ng-container>
            </div>
            <div class="col-12 col-md-2 mb10" *ngIf="leaseDetailResponse?.lease_step !=='INTERNAL_LEASE'">
              住客费用分摊：
              <span *ngIf="!leaseDetailResponse?.went_dutch"> 不需要 </span>
              <span *ngIf="leaseDetailResponse?.went_dutch">需要</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content">

      <!--Tab 切换 -->
      <div class="col-12">
        <div class="row">
          <div (click)="chooseTab(1)" class="col-12 col-md-2 split1">
            <span class="normal" [ngClass]="{'active': tabIndex == 1}">房间明细（{{leaseDetailResponse?.room_num}}）</span>
          </div>
          <div (click)="chooseTab(2)" class="col-12 col-md-2 split1">
            <span class="normal" [ngClass]="{'active': tabIndex == 2}">账单及流水</span>
          </div>
          <div *ngIf="leaseDetailResponse?.lease_subject_info_dto?.subject_type == 'COMPANY'"
               (click)="chooseTab(3)"
               class="col-12 col-md-2 split1">
            <span class="normal" [ngClass]="{'active': tabIndex == 3}"
            >企业预付费账户（{{leaseDetailResponse?.open_company_prepay_account ? '已开通' : '未开通'}}）</span>
          </div>
          <div (click)="chooseTab(4)" class="col-12 col-md-2 split1">
            <span class="normal" [ngClass]="{'active': tabIndex == 4}">换房记录（{{changeLogsNum}}）</span>
          </div>
          <div (click)="chooseTab(6)" class="col-12 col-md-2 split1">
            <span class="normal" [ngClass]="{'active': tabIndex == 6}">审批记录（{{leaseDetailResponse?.flow_submit_num}}
              ）</span>
          </div>
          <div (click)="chooseTab(5)" class="col-12 col-md-2 split1">
            <span class="normal" [ngClass]="{'active': tabIndex == 5}">操作日志（{{leaseDetailResponse?.lease_logs_num}}
              ）</span>
          </div>

          <div class="col-12 col-md-12 px-0">
            <!-- 房间明细-->
            <div *ngIf="tabIndex == 1" class="modal-body yellow">
              <div class="row">
                <div class="col-12 col-md-3  ">
                  <div class="form-group row">
                    <label class="col-12 col-md-6 col-lg-4 col-xl-3 form-check-label hei32"
                           style="line-height: 32px;">筛选：</label>
                    <div class="col-12 col-md-6 col-lg-8 col-xl-9">
                      <select class="w-100 form-control" [(ngModel)]="room_status">
                        <option *ngFor="let item of  roomStatus; let i=first " [ngValue]="item.id">
                          {{item.value}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-7  hei32" style="line-height: 32px;display: inline-table">
                  <span class="text-dark mr-2">待入住：{{leaseDetailResponse?.waiting_check_num}} 间</span>
                  <span class="text-success mr-2"
                        style="color: #339900 !important;">已入住：{{leaseDetailResponse?.check_in_num}} 间</span>
                  <span class="text-danger">已退房：{{leaseDetailResponse?.refund_num}} 间</span>
                </div>
                <!--                无实用 还是待入住-->
                <!--                <div class="col-12 col-md-2   "-->
                <!--                     *ngIf="btn_privileges.BATCH_IMPORT_RENTER && leaseDetailResponse?.business_status_desc == '生效中'">-->
                <!--                  <button class="btn btn-warning"-->
                <!--                          (click)="residentTemplateShow(leaseDetailResponse?.lease_id,residentTemplate)">批量导入入住人-->
                <!--                  </button>-->
                <!--                </div>-->
              </div>

              <div class="w-100 scrollx-head">
                <table
                  class="table table-bordered table-striped table-fix-scroll v2 table-bg-gray scrollx-body scrollxy-width80">
                  <thead class="table-bg-gray">
                  <tr>
                    <th>房型</th>
                    <th>房间号</th>
                    <th>状态</th>
                    <th *ngIf="leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'">平均租金</th>
                    <th>其他费用</th>
                    <th *ngIf="leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'">押金</th>
                    <th *ngIf="leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'">住客押金</th>
                    <ng-container *ngIf="need_room_goods_list === 1">
                      <th>入住物品单</th>
                      <th>退房物品单</th>
                    </ng-container>
                    <ng-container *ngIf="need_room_goods_list === 0">
                      <th>设备初始读数</th>
                      <th>设备最后读数</th>
                    </ng-container>
                    <th *ngIf="leaseDetailResponse?.lease_subject_info_dto?.subject_type === 'COMPANY'">不同性别混住</th>
                    <th>入住人</th>
                    <th>退/换房单号</th>
                    <th>退/换房日期</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let item of leaseDetailResponse?.lease_room_info_dtos; let h=index" class="bg-white"
                      [style.display]="(room_status == '全部状态' || changeStatus(item) == room_status) ? '' : 'none'">
                    <td>{{item.room_type_name}}</td>
                    <td>{{item.room_num}}</td>
                    <td>
                      {{changeStatus(item)}}
                      <!--<ng-container *ngIf="item?.is_change_room && item.lease_room_status === 'CHECKOUT'">-->
                      <!--换房-->
                      <!--</ng-container>-->
                      <!--<ng-container *ngIf="!item?.is_change_room">-->
                      <!--{{item.lease_room_status | enumTransfer:lease_room_status_list}}-->
                      <!--</ng-container>-->
                    </td>
                    <td *ngIf="leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'">{{item.month_rent}}
                      <a *ngIf="item.rent_type === 'MULTI'" (click)="openRoomDetail(item.lease_room_id)"
                         class="text-primary pointer">明细</a>
                    </td>
                    <td>
                      {{getExpenseInfo(item.expense_list)}}
                    </td>
                    <td *ngIf="leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'">{{item.room_deposit}}</td>
                    <td *ngIf="leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'">{{item.renter_deposit}}</td>
                    <td *ngIf="need_room_goods_list === 1">
                      <ng-container
                        *ngIf="leaseDetailResponse?.lease_status === 'EFFECTIVE' || leaseDetailResponse?.lease_status === 'EXPIRED'">
                        <ng-container *ngIf="btn_privileges.CHECKIN_GOODS_SIGN && item.asset_confirm === 'UNCONFIRMED'">
                          <ng-container *ngIf="leaseDetailResponse?.lease_type==='RELET' && !checkReletTime()">
                            <span class="text-primary pointer" style="color:#BCBCBC !important;">签署</span>
                          </ng-container>
                          <ng-container
                            *ngIf="leaseDetailResponse?.lease_type!=='RELET' || (leaseDetailResponse?.lease_type==='RELET' && checkReletTime())">
                            <span class="text-primary pointer"
                                  [routerLink]="['/system_manage/signed/goods', leaseDetailResponse?.lease_id, item.room_id, item.lease_room_id]">签署</span>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="item.asset_confirm !== 'UNCONFIRMED'">
                          <span class="text-primary pointer"
                                [routerLink]="['/system_manage/signed/goods', leaseDetailResponse?.lease_id, item.room_id, item.lease_room_id]">查看</span>
                        </ng-container>
                      </ng-container>
                    </td>
                    <ng-container *ngIf="need_room_goods_list === 0">
                      <td *ngIf="item.need_room_goods_list === 1">
                        <ng-container
                          *ngIf="leaseDetailResponse?.lease_status === 'EFFECTIVE' || leaseDetailResponse?.lease_status === 'EXPIRED'">
                          <ng-container
                            *ngIf="btn_privileges.CHECKIN_GOODS_SIGN && item.asset_confirm === 'UNCONFIRMED'">
                            <ng-container *ngIf="leaseDetailResponse?.lease_type==='RELET' && !checkReletTime()">
                              <span class="text-primary pointer" style="color:#BCBCBC !important;">签署</span>
                            </ng-container>
                            <ng-container
                              *ngIf="leaseDetailResponse?.lease_type!=='RELET' || (leaseDetailResponse?.lease_type==='RELET' && checkReletTime())">
                            <span class="text-primary pointer"
                                  [routerLink]="['/system_manage/signed/goods', leaseDetailResponse?.lease_id, item.room_id, item.lease_room_id]">签署</span>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="item.asset_confirm !== 'UNCONFIRMED'">
                          <span class="text-primary pointer"
                                [routerLink]="['/system_manage/signed/goods', leaseDetailResponse?.lease_id, item.room_id, item.lease_room_id]">查看</span>
                          </ng-container>
                        </ng-container>
                      </td>
                      <td *ngIf="item.need_room_goods_list === 0">
                        <ng-container
                          *ngIf="(item.asset_confirm === 'CONFIRMED' || item.asset_confirm === 'CONFIRMEDOUT')  && (item.cold_check_in_number || item.hot_check_in_number || item.ele_check_in_number)">
                          <p *ngIf="item.ele_check_in_number">电表：{{item.ele_check_in_number}}</p>
                          <p *ngIf="item.cold_check_in_number">水表：{{item.cold_check_in_number}}</p>
                          <p *ngIf="item.hot_check_in_number">热水表：{{item.hot_check_in_number}}</p>
                        </ng-container>
                        <ng-container
                          *ngIf="!item.cold_check_in_number && !item.hot_check_in_number && !item.ele_check_in_number">
                          --
                        </ng-container>
                      </td>
                    </ng-container>

                    <td *ngIf="need_room_goods_list === 1">
                      <ng-container
                        *ngIf="leaseDetailResponse?.lease_status === 'EFFECTIVE' || leaseDetailResponse?.lease_status === 'EXPIRED' || leaseDetailResponse?.lease_status === 'CHECKOUT'">
                        <ng-container
                          *ngIf="btn_privileges.CHECKOUT_GOODS_SIGN && item.asset_confirm === 'UNCONFIRMED'">
                          <span class="text-primary pointer" style="color:#BCBCBC !important;">未签署</span>
                        </ng-container>
                        <ng-container *ngIf="btn_privileges.CHECKOUT_GOODS_SIGN && item.asset_confirm == 'CONFIRMED'">
                          <ng-container *ngIf="leaseDetailResponse?.children_lease_status !== 'EFFECTIVE'">
                            <span class="text-primary pointer" style="color:#BCBCBC !important;">未签署</span>
                            <!--                            (click)="turnToCheckoutPdf(leaseDetailResponse?.lease_id, item.room_id, item.lease_room_id)"-->
                          </ng-container>
                          <ng-container
                            *ngIf="btn_privileges.CHECKOUT_GOODS_SIGN && leaseDetailResponse?.children_lease_status === 'EFFECTIVE'">
                            <span class="text-secondary pointer" style="color:#BCBCBC !important;">未签署</span>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="item.asset_confirm == 'CONFIRMEDOUT'">
                          <span class="text-primary pointer"
                                (click)="turnToViewCheckoutPdf(leaseDetailResponse?.lease_id, item.room_id, item.lease_room_id)">查看</span>
                        </ng-container>
                      </ng-container>
                    </td>
                    <ng-container *ngIf="need_room_goods_list === 0">
                      <td *ngIf="item.need_room_goods_list === 1">
                        <ng-container
                          *ngIf="leaseDetailResponse?.lease_status === 'EFFECTIVE' || leaseDetailResponse?.lease_status === 'EXPIRED' || leaseDetailResponse?.lease_status === 'CHECKOUT'">
                          <ng-container
                            *ngIf="btn_privileges.CHECKOUT_GOODS_SIGN && item.asset_confirm === 'UNCONFIRMED'">
                            <span class="text-primary pointer" style="color:#BCBCBC !important;">签署</span>
                          </ng-container>
                          <ng-container *ngIf="btn_privileges.CHECKOUT_GOODS_SIGN && item.asset_confirm == 'CONFIRMED'">
                            <ng-container *ngIf="leaseDetailResponse?.children_lease_status !== 'EFFECTIVE'">
                              <span class="text-primary pointer"
                                    (click)="turnToCheckoutPdf(leaseDetailResponse?.lease_id, item.room_id, item.lease_room_id)">签署</span>
                            </ng-container>
                            <ng-container
                              *ngIf="btn_privileges.CHECKOUT_GOODS_SIGN && leaseDetailResponse?.children_lease_status === 'EFFECTIVE'">
                              <span class="text-secondary pointer" style="color:#BCBCBC !important;">签署</span>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="item.asset_confirm == 'CONFIRMEDOUT'">
                            <span class="text-primary pointer"
                                  (click)="turnToViewCheckoutPdf(leaseDetailResponse?.lease_id, item.room_id, item.lease_room_id)">查看</span>
                          </ng-container>
                        </ng-container>
                      </td>
                      <td *ngIf="item.need_room_goods_list ===0 ">
                        <ng-container
                          *ngIf="item.asset_confirm === 'CONFIRMEDOUT'  && (item.cold_checkout_number || item.hot_checkout_number || item.ele_checkout_number)">
                          <p *ngIf="item.ele_checkout_number">电表：{{item.ele_checkout_number}}</p>
                          <p *ngIf="item.cold_checkout_number">水表：{{item.cold_checkout_number}}</p>
                          <p *ngIf="item.hot_checkout_number">热水表：{{item.hot_checkout_number}}</p>
                        </ng-container>
                        <ng-container
                          *ngIf="!item.cold_checkout_number && !item.hot_checkout_number && !item.ele_checkout_number">
                          --
                        </ng-container>
                      </td>
                    </ng-container>


                    <td
                      *ngIf="leaseDetailResponse?.lease_subject_info_dto?.subject_type === 'COMPANY'">{{item.check_sex === true ? '是' : '否'}}</td>
                    <td>
                      <ng-container
                        *ngIf="leaseDetailResponse?.lease_status === 'EFFECTIVE' || leaseDetailResponse?.lease_status === 'EXPIRED'">
                        <ng-container *ngFor="let renterInfo of item.renter_list; let i=index">
                          <ng-container
                            *ngIf="renterInfo.renter_status === 'CHECKIN' || renterInfo.renter_status === 'WAITING_CHECKIN'">
                          <span
                            [ngClass]="{'checkin': renterInfo.renter_status === 'CHECKIN', 'wait_checkin': renterInfo.renter_status !== 'CHECKIN'}">
                            {{renterInfo.name}}({{renterInfo.sex === 'MALE' ? '男' : '女'}}
                            , {{renterInfo.renter_status | enumTransfer: renter_status_list}})
                            {{i !== (item.renter_list.length - 1) ? ',' : ''}}
                          </span>
                          </ng-container>
                        </ng-container>
                        <span *ngIf="btn_privileges.RENTER_SETTING_BTN && item?.lease_room_status === 'EFFECTIVE'"
                              class="text-primary pointer"
                              (click)="renterModalShow(h)">补充</span>
                      </ng-container>
                    </td>
                    <td>
                      <ng-container
                        *ngIf="item?.lease_room_status === 'CHECKOUT' && item?.checkout_record_number != null">
                        <ng-container *ngIf="item?.is_change_room && item?.record_number_ext">
                     <span style="color: #36a9e1;cursor: pointer;"
                           [routerLink]="['/system_manage/signed/change/room/details/' + item?.room_change_record_id]">
                                        {{item?.checkout_record_number}}
                     </span>
                          <span *ngIf="!item?.record_number_ext"
                                style="color: rgba(0, 0, 0, 0.65);">{{item?.checkout_record_number}}</span>
                        </ng-container>

                        <ng-container *ngIf="!item?.is_change_room">
                      <span *ngIf="item?.record_number_ext"
                            [routerLink]="['/system_manage/signed/checkoutrecord']"
                            [queryParams]="{id:item?.checkout_record_number}"
                            style="color: #36a9e1;cursor: pointer;">
                      {{item?.checkout_record_number}}
                      </span>
                          <span *ngIf="!item?.record_number_ext"
                                style="color: rgba(0, 0, 0, 0.65);">{{item?.checkout_record_number}}</span>
                        </ng-container>
                      </ng-container>
                    </td>
                    <td>
                      {{item?.checkout_time | date:'yyyy.MM.dd'}}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- 账单及流水-->
            <app-lease-room-bill *ngIf="tabIndex == 2" [leaseId]="leaseDetailResponse?.lease_id"
                                 [wentDutch]="leaseDetailResponse?.went_dutch"></app-lease-room-bill>

            <!-- 企业预付账户-->
            <app-company-prepay (prepayChange)="prepayChange()" *ngIf="tabIndex == 3" [leaseId]="paymentleaseId"
                                [leaseNum]="leaseDetailResponse?.contract_num"
                                [leaseStatus]="leaseDetailResponse?.business_status"
                                [leaseStatusDesc]="leaseDetailResponse?.business_status_desc"
                                [auditStatus]="leaseDetailResponse?.audit_status"
                                [contactName]="leaseDetailResponse?.lease_subject_info_dto.name+'/'+leaseDetailResponse?.lease_subject_info_dto.company_name"
            ></app-company-prepay>

            <!-- 换房记录-->
            <app-cr-change-room-record [style.display]="tabIndex == 4 ? 'block' : 'none'"
                                       [lease_id]="leaseDetailResponse?.lease_id"
                                       [lease_step]="leaseDetailResponse?.lease_step"
                                       (logsNum)="setChangLogsNum($event)"
                                       (isChangeing)="setIsChangeing($event)"
                                       (reload_record)="roomChangeRecordAndLogReload()"></app-cr-change-room-record>

            <!-- 操作日志-->
            <app-handlelog [style.display]="tabIndex == 5 ? 'block' : 'none'"></app-handlelog>

            <!-- 流程提交记录 -->
            <app-lease-flow-submit-log [style.display]="tabIndex == 6 ? 'block' : 'none'"
                                       [lease_id]="leaseDetailResponse?.lease_id"></app-lease-flow-submit-log>
          </div>
        </div>
      </div>
      <div class="row"
           *ngIf="leaseDetailResponse?.lease_step!=='INTERNAL_LEASE' && (leaseDetailResponse?.lease_status == 'EFFECTIVE' || leaseDetailResponse?.lease_status == 'EXPIRED')">
        <div class="col-12 col-lg-12 mb-2">
          <div class="padd15 font-lg-1 border-warning table-bordered table-striped" *ngIf="remainingDays > 0">
            <div class="inline font-lg-1 text-warning">房间使用限制:</div>
            首期房费在应付时间未付，仍可在未来
            <div class="inline font-lg-1 text-danger">{{remainingDays}}天</div>
            使用水电和门锁密码；
            <div class="inline font-lg-1 text-danger">{{payEndDate | date:"yyyy-MM-dd HH:mm"}}</div>
            过后仍未付清，系统将会对所有房间断水断电，作废门锁密码
          </div>
          <div class="padd15 font-lg-1 border-warning table-bordered table-striped" *ngIf="remainingDays <= 0">
            <div class="inline font-lg-1 text-warning">房间使用限制:</div>
            首期房费在应付时间未付，系统将会对所有房间断水断电，作废门锁密码
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-12">
          <div class="part1">
            <h5 class="bg-gray">签约主体</h5>
            <div class="padd15">
              <ng-container *ngIf="leaseDetailResponse?.lease_subject_info_dto?.subject_type === 'PERSON'">
                <div class="row">
                  <div class="col-12 col-md-3 mb10">
                    <p class="font-weight-bold">签约人</p>
                    <div>{{leaseDetailResponse?.lease_subject_info_dto?.name}}
                    </div>
                  </div>
                  <div class="col-12 col-md-3 mb10 ">
                    <p class="font-weight-bold">证件类型</p>
                    <div class=" mb10">{{leaseDetailResponse?.lease_subject_info_dto?.cret_type | cretTypePipe}}</div>
                  </div>
                  <div class="col-12 col-md-3 mb10 ">
                    <p class="font-weight-bold">证件号
                      <ng-container
                        *ngIf="!btn_privileges?.QUERY_USER_PRIVAYE && btn_privileges?.APPLY_QUERY_USER_PRIVAYE">
                        <a style="color:#42a5f5; margin-left: 5px;" class="fa fa-eye"
                           (click)="clickHasShowConceal('3')"></a>
                      </ng-container>
                    </p>
                    <div class=" mb10" id="show-html-3" data-hasShowConceal="false">
                      <input id="hide-html-3" type="hidden"
                             value="{{leaseDetailResponse?.lease_subject_info_dto?.cret_num}}">
                      <ng-container *ngIf="btn_privileges?.QUERY_USER_PRIVAYE">
                        {{leaseDetailResponse?.lease_subject_info_dto?.cret_num}}
                      </ng-container>
                      <ng-container *ngIf="!btn_privileges?.QUERY_USER_PRIVAYE">
                        {{leaseDetailResponse?.lease_subject_info_dto?.cret_num_conceal}}
                      </ng-container>
                    </div>
                  </div>
                  <div class="col-12 col-md-3 mb10 ">
                    <p class="font-weight-bold">联系方式
                      <ng-container
                        *ngIf="!btn_privileges?.QUERY_USER_PRIVAYE && btn_privileges?.APPLY_QUERY_USER_PRIVAYE">
                        <a style="color:#42a5f5; margin-left: 5px;" class="fa fa-eye"
                           (click)="clickHasShowConceal('4')"></a>
                      </ng-container>
                    </p>
                    <div class=" mb10" id="show-html-4" data-hasShowConceal="false">
                      <input id="hide-html-4" type="hidden"
                             value="{{leaseDetailResponse?.lease_subject_info_dto?.tel}}">
                      <ng-container *ngIf="btn_privileges?.QUERY_USER_PRIVAYE">
                        {{leaseDetailResponse?.lease_subject_info_dto?.tel}}
                      </ng-container>
                      <ng-container *ngIf="!btn_privileges?.QUERY_USER_PRIVAYE">
                        {{leaseDetailResponse?.lease_subject_info_dto?.tel_conceal}}
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="leaseDetailResponse?.lease_step !=='INTERNAL_LEASE'">
                  <div class="col-12 col-md-3">
                    <p class="font-weight-bold">联系地址</p>
                    <div
                      class=" mb10 new-lines">{{leaseDetailResponse?.lease_subject_info_dto?.address ? leaseDetailResponse?.lease_subject_info_dto?.address : '--'}}</div>
                  </div>
                  <div class="col-12 col-md-3">
                    <p class="font-weight-bold">邮箱地址</p>
                    <div
                      class=" mb10">{{leaseDetailResponse?.lease_subject_info_dto?.email ? leaseDetailResponse?.lease_subject_info_dto?.email : '--'}}</div>
                  </div>
                  <div class="col-12 col-md-3">
                    <p class="font-weight-bold">邮编</p>
                    <div
                      class=" mb10">{{leaseDetailResponse?.lease_subject_info_dto?.zip ? leaseDetailResponse?.lease_subject_info_dto?.zip : '--'}}</div>
                  </div>
                </div>
                <hr *ngIf="leaseDetailResponse?.lease_step !=='INTERNAL_LEASE'">
                <div class="row" *ngIf="leaseDetailResponse?.lease_step !=='INTERNAL_LEASE'">
                  <div class="col-12 col-md-12 ">
                    <p class="font-weight-bold">紧急联系人
                      <ng-container
                        *ngIf="!btn_privileges?.QUERY_USER_PRIVAYE && btn_privileges?.APPLY_QUERY_USER_PRIVAYE">
                        <a style="color:#42a5f5; margin-left: 5px;" class="fa fa-eye"
                           (click)="clickHasShowConceal('5')"></a>
                      </ng-container>
                    </p>
                  </div>
                  <div class="col-12 col-md-4 ">
                    <ng-template [ngIf]="leaseDetailResponse
                        && leaseDetailResponse.reserve_contact_info_dto
                        && leaseDetailResponse.reserve_contact_info_dto.emergency_contacts
                        && leaseDetailResponse.reserve_contact_info_dto.emergency_contacts.length > 0">
                      <div *ngFor="let emergency of leaseDetailResponse.reserve_contact_info_dto.emergency_contacts">
                        {{emergency.contact_name}}
                        <label id="show-html-5" data-hasShowConceal="false">
                          <ng-container *ngIf="btn_privileges?.QUERY_USER_PRIVAYE || applyQueryed">
                            {{emergency.contact_phone}}
                          </ng-container>
                          <ng-container *ngIf="!btn_privileges?.QUERY_USER_PRIVAYE && !applyQueryed">
                            {{emergency.tel_conceal}}
                          </ng-container>
                        </label>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="leaseDetailResponse?.lease_subject_info_dto?.subject_type === 'COMPANY'">
                <div class="row">
                  <div class="col-12 col-md-3 mb10">
                    <p class="font-weight-bold">公司名称</p>
                    <div>{{leaseDetailResponse?.lease_subject_info_dto?.company_name}}
                    </div>
                  </div>
                  <div class="col-12 col-md-3 mb10 ">
                    <p class="font-weight-bold">联系人</p>
                    <div class=" mb10">
                      {{leaseDetailResponse?.lease_subject_info_dto?.name}} &nbsp;&nbsp;&nbsp;
                      <button class="btn btn-outline-primary"
                              *ngIf="btn_privileges.CLIENT_COMPANY_USER_CHANGE && leaseDetailResponse?.business_status_desc == '生效中' || leaseDetailResponse?.business_status_desc == '待签署'
                               || leaseDetailResponse?.business_status_desc == '未提交'"
                              (click)="changeContactsModelShow(leaseDetailResponse?.lease_id,leaseDetailResponse?.lease_subject_info_dto?.name,leaseDetailResponse?.lease_subject_info_dto?.tel,changeContacts)">
                        变更联系人
                      </button>
                    </div>
                  </div>
                  <div class="col-12 col-md-3 mb10 ">
                    <p class="font-weight-bold">联系电话
                      <ng-container
                        *ngIf="!btn_privileges?.QUERY_USER_PRIVAYE && btn_privileges?.APPLY_QUERY_USER_PRIVAYE">
                        <a style="color:#42a5f5; margin-left: 5px;" class="fa fa-eye"
                           (click)="clickHasShowConceal('1')"></a>
                      </ng-container>
                    </p>
                    <div class=" mb10" id="show-html-1" data-hasShowConceal="false">
                      <input id="hide-html-1" type="hidden"
                             value="{{leaseDetailResponse?.lease_subject_info_dto?.tel}}">
                      <ng-container *ngIf="btn_privileges?.QUERY_USER_PRIVAYE">
                        {{leaseDetailResponse?.lease_subject_info_dto?.tel}}
                      </ng-container>
                      <ng-container *ngIf="!btn_privileges?.QUERY_USER_PRIVAYE">
                        {{leaseDetailResponse?.lease_subject_info_dto?.tel_conceal}}
                      </ng-container>
                    </div>
                  </div>
                  <div class="col-12 col-md-3 mb10 ">
                    <p class="font-weight-bold">统一社会信用代码</p>
                    <div
                      class=" mb10">{{leaseDetailResponse?.lease_subject_info_dto?.taxpayer_num ? leaseDetailResponse?.lease_subject_info_dto?.taxpayer_num : '--'}}</div>
                  </div>
                  <div class="col-12 col-md-3 mb10 " *ngIf="leaseDetailResponse?.lease_step !=='INTERNAL_LEASE'">
                    <p class="font-weight-bold">邮箱地址</p>
                    <div
                      class=" mb10">{{leaseDetailResponse?.lease_subject_info_dto?.email ? leaseDetailResponse?.lease_subject_info_dto?.email : '--'}}</div>
                  </div>
                  <div class="col-12 col-md-3 mb10 " *ngIf="leaseDetailResponse?.lease_step !=='INTERNAL_LEASE'">
                    <p class="font-weight-bold">邮编</p>
                    <div
                      class=" mb10">{{leaseDetailResponse?.lease_subject_info_dto?.zip ? leaseDetailResponse?.lease_subject_info_dto?.zip : '--'}}</div>
                  </div>
                </div>
                <div class="row" *ngIf="leaseDetailResponse?.lease_step !=='INTERNAL_LEASE'">
                  <div class="col-12 col-md-3 new-lines">
                    <p class="font-weight-bold">联系地址</p>
                    <div
                      class=" mb10">{{leaseDetailResponse?.lease_subject_info_dto?.address ? leaseDetailResponse?.lease_subject_info_dto?.address : '--'}}</div>
                  </div>
                </div>
                <hr *ngIf="leaseDetailResponse?.lease_step !=='INTERNAL_LEASE'">
                <div class="row" *ngIf="leaseDetailResponse?.lease_step !=='INTERNAL_LEASE'">
                  <div class="col-12 col-md-12 ">
                    <p class="font-weight-bold">紧急联系人
                      <ng-container
                        *ngIf="!btn_privileges?.QUERY_USER_PRIVAYE && btn_privileges?.APPLY_QUERY_USER_PRIVAYE">
                        <a style="color:#42a5f5; margin-left: 5px;" class="fa fa-eye"
                           (click)="clickHasShowConceal('2')"></a>
                      </ng-container>
                    </p>
                  </div>
                  <div class="col-12 col-md-4 ">
                    <ng-template [ngIf]="leaseDetailResponse
                        && leaseDetailResponse.reserve_contact_info_dto
                        && leaseDetailResponse.reserve_contact_info_dto.emergency_contacts
                        && leaseDetailResponse.reserve_contact_info_dto.emergency_contacts.length > 0">
                      <div *ngFor="let emergency of leaseDetailResponse.reserve_contact_info_dto.emergency_contacts">
                        {{emergency.contact_name}}
                        <label id="show-html-2" data-hasShowConceal="false">
                          <input id="hide-html-2" type="hidden" value="{{emergency.contact_phone}}">
                          <ng-container *ngIf="btn_privileges?.QUERY_USER_PRIVAYE || applyQueryed">
                            {{emergency.contact_phone}}
                          </ng-container>
                          <ng-container *ngIf="!btn_privileges?.QUERY_USER_PRIVAYE && !applyQueryed">
                            {{emergency.tel_conceal}}
                          </ng-container>
                        </label>
                      </div>
                    </ng-template>
                  </div>
                </div>
                <div class="row mt20" *ngIf="leaseDetailResponse?.lease_step !=='INTERNAL_LEASE'">
                  <div class="col-12 mb10">
                    <p class="font-weight-bold mb10">发票信息</p>
                  </div>
                  <div class="col-12 mb10">
                    <div class="row new-lines">
                      <ng-container *ngIf="leaseDetailResponse?.lease_subject_info_dto?.invoice_type == 'NO_NEED'">
                        <div class="col-12 col-md-3 mb10 ">
                          <p class="font-weight-bold">开票类型</p>
                          <div class=" mb10">无需开票</div>
                        </div>
                      </ng-container>
                      <ng-container
                        *ngIf="leaseDetailResponse?.lease_subject_info_dto?.invoice_type == 'NORMAL_INVOICE'">
                        <div class="col-12 col-md-3 mb10 ">
                          <p class="font-weight-bold">开票类型</p>
                          <div class=" mb10">普通发票</div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="leaseDetailResponse?.lease_subject_info_dto?.invoice_type == 'VAT_INVOICE'">
                        <div class="col-12 col-md-3 mb10 ">
                          <p class="font-weight-bold">开票类型</p>
                          <div class=" mb10">增值税发票</div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="leaseDetailResponse?.lease_subject_info_dto?.invoice_type!=='NO_NEED'">
                        <div class="col-12 col-md-3 mb10 ">
                          <p class="font-weight-bold">发票抬头</p>
                          <div class=" mb10">{{leaseDetailResponse?.lease_subject_info_dto?.invoice_title}}</div>
                        </div>
                        <div class="col-12 col-md-3 mb10 ">
                          <p class="font-weight-bold">纳税识别号</p>
                          <div class=" mb10 ">{{leaseDetailResponse?.lease_subject_info_dto?.taxpayer_num}}</div>
                        </div>
                        <div class="col-12 col-md-3 mb10 ">
                          <p class="font-weight-bold">银行账号</p>
                          <div class=" mb10 ">{{leaseDetailResponse?.lease_subject_info_dto?.card_number}}</div>
                        </div>
                        <div class="col-12 col-md-3 mb10 ">
                          <p class="font-weight-bold">开户银行</p>
                          <div class=" mb10 ">{{leaseDetailResponse?.lease_subject_info_dto?.bank_name}}</div>
                        </div>
                        <div class="col-12 col-md-3 mb10 ">
                          <p class="font-weight-bold">企业地址</p>
                          <div class=" mb10 ">{{leaseDetailResponse?.lease_subject_info_dto?.company_address}}</div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div *ngIf="leaseDetailResponse?.ihome_apply_id" class="padd15" >人才房渠道申请ID：{{leaseDetailResponse?.ihome_apply_id}}</div>
            <h5 class="bg-gray " *ngIf="leaseDetailResponse?.exists_extra_elect">物业费信息</h5>
            <div class="padd15" *ngIf="leaseDetailResponse?.exists_extra_elect">
              <div class="row">
                <div class="col-12 col-md-3 mb10 ">
                  <div *ngIf="leaseDetailResponse.lease_property_dto?.type ==='DON_NOT_CHARGE'">不收取物业费</div>
                  <div *ngIf="leaseDetailResponse.lease_property_dto?.type ==='DEGREE'">
                    按电表读数扣费，物业费单价：{{leaseDetailResponse.lease_property_dto?.fee}}元 / 度
                  </div>
                  <div *ngIf="leaseDetailResponse.lease_property_dto?.type ==='DAY'">
                    按天扣费，物业费单价：{{leaseDetailResponse.lease_property_dto?.fee}}元 / 天
                  </div>
                  <div *ngIf="leaseDetailResponse.lease_property_dto?.type ==='WUYE_PAY'">
                    按周期收取固定金额物业费：{{management_fee_gather_desc}}{{leaseDetailResponse.lease_property_dto?.fee}}元 / 间
                  </div>
                  <div *ngIf="leaseDetailResponse.lease_property_dto?.type ==='CYCLE_PAY'">
                    按周期收取固定金额物业费请从“房间信息>其他费用”中添加物业费账单
                  </div>
                </div>
              </div>
            </div>
            <h5 class="bg-gray " *ngIf="leaseDetailResponse?.public_energy_dto">其他费用</h5>
            <div class="padd15" *ngIf="leaseDetailResponse?.public_energy_dto">
              <div class="row">
                <div class="col-12 col-md-3 mb10 ">
                  <div *ngIf="leaseDetailResponse.public_energy_dto?.type ==='DAY'">
                    按天扣费，公共能耗分摊费单价：{{leaseDetailResponse.public_energy_dto?.fee}}元 / 天
                  </div>
                </div>
              </div>
            </div>

            <h5 class="bg-gray " *ngIf="leaseDetailResponse?.lease_step !=='INTERNAL_LEASE'">优惠及逾期信息</h5>
            <div class="padd15" *ngIf="leaseDetailResponse?.lease_step !=='INTERNAL_LEASE'">
              <div class="row">
                <div class="col-12 col-md-3 mb10 ">
                  <p class="font-weight-bold">房间免租周期</p>
                  <div class=" mb10">
                    <ng-container *ngIf="!leaseDetailResponse?.is_free">
                      无
                    </ng-container>
                    <ng-container *ngIf="leaseDetailResponse?.is_free">
                      {{leaseDetailResponse?.free_start_time|date:"yyyy.MM.dd"}}
                      —
                      {{leaseDetailResponse?.free_end_time|date:"yyyy.MM.dd"}}
                    </ng-container>
                  </div>
                </div>
                <div class="col-12 col-md-3 mb10 ">
                  <p class="font-weight-bold">滞纳金设置</p>
                  <div class=" mb10" style="width: max-content">
                    <ng-container *ngIf="!leaseDetailResponse?.outstanding_amount_percent">
                      无
                    </ng-container>
                    <ng-container *ngIf="leaseDetailResponse?.outstanding_amount_percent">
                      <p>
                        账期设置：允许账单延期 {{leaseDetailResponse?.grace_period_days}}天收款
                      </p>
                      <p>
                        滞纳金比例：每逾期一日，滞纳金比例为当月房间租金的{{leaseDetailResponse?.outstanding_amount_percent}} %
                      </p>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-3 mb10 " *ngIf="leaseDetailResponse?.went_dutch">
                  <p class="font-weight-bold">住客宽限期设置</p>
                  <div class=" mb10" style="width: max-content">
                    <ng-container *ngIf="leaseDetailResponse?.renter_grace_period_days == null">
                      无
                    </ng-container>
                    <ng-container *ngIf="leaseDetailResponse?.renter_grace_period_days || leaseDetailResponse?.renter_grace_period_days == 0">
                      允许住客账单延期 {{leaseDetailResponse?.renter_grace_period_days}}
                      天收款，延期日当天中午12:00，住客房间会自动断电，断门锁。
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <h5 class="bg-gray font-weight-bold">提醒和关停设置</h5>
            <div class="padd15">
              <div class="row">
                <div class="col-12 col-md-4 mb10" *ngIf="leaseDetailResponse?.lease_step !=='INTERNAL_LEASE'">
                  已设置提前
                  <i class="red">{{leaseDetailResponse?.remind_value}}天</i> 提醒管家催租
                </div>
                <div class="col-12 col-md-4 mb10">
                  已设置预付费余额低于
                  <i class="red">{{leaseDetailResponse?.msg_send_limit}}元</i> 提醒充值
                </div>
                <div class="col-12 col-md-4 mb10">
                  已设置预付费余额欠费
                  <i class="red">{{leaseDetailResponse?.electric_stop_limit}}元</i> 自动停电
                </div>
              </div>
            </div>


            <h5 class="bg-gray ">其他信息</h5>
            <div class="padd15">
              <div class="row" *ngIf="leaseDetailResponse?.lease_step !=='INTERNAL_LEASE'">
                <div class="col-12 col-md-6 mb10">
                  <p class="font-weight-bold mb10">客户来源</p>
                  <div class="mb10">
                    {{leaseDetailResponse?.source_name}}
                  </div>
                </div>
                <div class="col-12 col-md-6 ">
                  <p class="font-weight-bold mb10">销售</p>
                  <div class="mb10">
                    {{leaseDetailResponse?.sale_name}}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-12 mb10">
                  <p class="font-weight-bold mb10">备注</p>
                  {{leaseDetailResponse?.remark}}
                </div>
              </div>
            </div>

            <h5 class="bg-gray ">合同附件</h5>
            <div class="padd15">
              <ul class="img-box">
                <!--                <app-new-file-upload #img_file_upload-->
                <!--                                     [showViewBtn]="btn_privileges.LEASE_FILE_VIEW"-->
                <!--                                     [type]="2"-->
                <!--                                     [imgList]="attachedList"-->
                <!--                                     [showDelBtn]="btn_privileges.LEASE_FILE_DELETE"-->
                <!--                                     (fileChange)="addFileChange($event,1)"-->
                <!--                                     [showUploadBtn]="false" style="float: left;">-->
                <!--                </app-new-file-upload>-->
                <app-new-file-upload
                  [showViewBtn]="btn_privileges.LEASE_FILE_VIEW"
                  [type]="2"
                  [imgList]="attachedList"
                  [showDelBtn]="btn_privileges.LEASE_FILE_DELETE"
                  [showUploadBtn]="true"
                  [maxLength]='5'
                  [name]='uploadName'
                  [accept]="'IMG|PDF'"
                  (fileChange)="addFileChange($event,2)">
                </app-new-file-upload>
              </ul>
            </div>
          </div>
        </div>


      </div>
    </div>
    <!-- end -->
  </div>
</div>


<!-- 每月租金 -->
<div class="modal fade" bsModal #monthRent="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>每月租金</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="monthRent.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <table class="table w-100 ">
          <thead>
          <tr>
            <th>第几个月</th>
            <th>时间段</th>
            <th>租金</th>
          </tr>
          </thead>
          <tbody class="over12">
          <tr *ngFor="let item of leaseRoomInfoDTO?.custom_rent_list; let i = index">
            <td>{{i + 1}}</td>
            <td>{{item?.start_time |date: 'yyyy.MM.dd'}} —— {{item?.end_time |date:'yyyy.MM.dd'}}</td>
            <td>{{item?.custom_rent}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #confirm_lease_modal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-lg ">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>信息确认</h4>
      </div>
      <div class="modal-body">
        <div class=" row">
          <div class="col-12 mb10">
            <h4 class="red">签署合同前，请务必确认以下信息无误：</h4>
          </div>
          <div class="col-12 col-md-8 col-xl-8 mb10">
            <p class="inline">合同周期:</p>
            <h2
              class="red inline ml-2">{{leaseDetailResponse?.reserve_contact_info_dto?.start_time | date:'yyyy.MM.dd'}}-
              {{leaseDetailResponse?.reserve_contact_info_dto?.end_time | date:'yyyy.MM.dd'}}</h2>
          </div>
          <div class="col-12 col-md-4 col-xl-4 mb10">
            <p class="inline">付款周期:</p>
            <h2
              class="red inline ml-2">{{leaseDetailResponse?.reserve_contact_info_dto?.pay_cycle | enumTransfer:pay_cycle_list}}</h2>
          </div>
          <div class="col-12 col-md-8 col-xl-8 mb10" *ngIf="leaseDetailResponse?.is_free">
            <p class="inline">免租周期:</p>
            <p class="red inline ml-2">{{leaseDetailResponse?.free_start_time|date:"yyyy.MM.dd"}}
              —{{leaseDetailResponse?.free_end_time|date:"yyyy.MM.dd"}}</p>
          </div>
          <div class="col-12 col-md-4 col-xl-4 mb10" *ngIf="leaseDetailResponse?.outstanding_amount_percent">
            <p class="inline">滞纳金比率:</p>
            <p class="red inline ml-2">{{leaseDetailResponse?.outstanding_amount_percent}} %</p>
          </div>
          <div class="col-12 mb10">
            <table class="table table-bordered table-striped table-fix-scroll v2">
              <thead>
              <tr>
                <td>房间号</td>
                <td>押金</td>
                <td>租金</td>
                <td>其他费用</td>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of leaseDetailResponse?.lease_room_info_dtos; let i = index" class="bg-white">
                <td>{{item?.room_num}}</td>
                <td><h3 class="red">{{item?.room_deposit}}</h3></td>
                <td>
                  <h3 class="red">
                    <ng-container *ngIf="item?.rent_type == ''">
                      固定:
                    </ng-container>
                    <ng-container *ngIf="item?.rent_type == ''">
                      灵活:
                    </ng-container>
                    {{item.month_rent}}
                  </h3>
                </td>
                <td>
                  {{getExpenseInfo(item.expense_list)}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 mb10">
            <p>如信息有误，请取消合同，重新创建新合同。</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" id="ensure_btn" (click)="ensureAfter()">确定
          <ng-container *ngIf="timer_second>0">({{timer_second}}s)</ng-container>
        </button>
        <button type="button" class="btn btn-default " (click)="closeModal()">取消</button>
      </div>
    </div>
  </div>
</div>

<app-dialog [leaseRoomInfoDTO]="leaseRoomInfoDTO"
            [leaseId]="leaseDetailResponse?.lease_id"
            (paymentCallback)="refushBill()"
            (closeCallback)="cancelRecharge()"
            (refushLogsCallback)="refushLogs()">
</app-dialog>
<!--   退房  -->
<app-checkout-room-select [lease_id]="leaseDetailResponse?.lease_id"></app-checkout-room-select>
<!-- 打印合同-->
<!--<app-print-preview #contract_print></app-print-preview>-->
<app-inventory #meterInventory></app-inventory>
<app-checkininventory></app-checkininventory>
<!-- 入住人 -->
<app-renter-modal #renter_modal (out_renter_with_index)="confirmRenter($event)"></app-renter-modal>

<app-cr-room-choose (room_choose_emitter)="gotoChooseChangeType($event)"></app-cr-room-choose>
<app-cr-renter-choose [lease_id]="leaseDetailResponse?.lease_id"
                      [room_id]="change_room_id"
                      (renter_choose_emitter)="gotoChooseNewRoom($event)"></app-cr-renter-choose>
<app-choose-room-modal [is_sign]="false"
                       [lease_step]="leaseDetailResponse?.lease_step"
                       (time_with_choosed_room_list)="gotoRoomChangeSet($event)"
                       (refresh)="refush(leaseDetailResponse?.lease_id)"></app-choose-room-modal>

<app-cr-room-choose-set (go_step)="gotoBackOrConfirm($event)"></app-cr-room-choose-set>
<!-- 转租 -->
<app-sublease-room-select [lease_id]="leaseDetailResponse?.lease_id"></app-sublease-room-select>


<!--联系人变更 -->
<div class="modal fade" bsModal #changeContacts="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg" style="color:black;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>联系人变更</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="changeContactsHide(changeContacts)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-12 row">
          <div class="col-12 col-md-4 mb-2">
            <p class="hei32 w90px floatRight">
              联系人
            </p>
          </div>
          <div class="col-12 col-md-8 mb-2">
            <div class="condiv">
              <input class="w-100" type="text" [(ngModel)]="contact_name" (change)="name_format()">
              <span class="text-danger" *ngIf="!contact_name">请输入联系人！</span>
              <span class="text-danger" *ngIf="contact_name && !name_formatError">格式错误(2-20字符内)！</span>
            </div>
          </div>
          <div class="col-12 col-md-4 mb-2">
            <p class="hei32 w90px floatRight">
              联系电话
            </p>
          </div>
          <div class="col-12 col-md-8 mb-2">
            <div class="condiv">
              <input class="w-100" type="text" [(ngModel)]="contact_phone" (change)="phone_format()">
              <span class="text-danger" *ngIf="!contact_phone">请输入联系电话为空！</span>
              <span class="text-danger" *ngIf="contact_phone && !phone_formatError">格式错误(8-20位数字，允许*- 空格三种字符)！</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="auditPersonShow(auditPerson,changeContacts)">下一步</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" bsModal #auditPerson="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg" style="color:black;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>联系人变更</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="auditPersonHide(auditPerson)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body" style="padding-top:50px;padding-bottom:50px;">
        <div class="col-12 row">
          <div class="col-12 col-md-4 mb-2">
            <p class="hei32 w90px floatRight">
              审核人
            </p>
          </div>
          <div class="col-12 col-md-6 mb-2">
            <div class="condiv">
              <select class="w-100" [(ngModel)]="contact_audit_person_id">
                <option *ngFor="let person of audit_person_list"
                        [value]="person.id+','+person.user_name+','+person.user_phone">
                  {{person.user_name}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-md-2 mb-2">
            <div class="condiv">
            </div>
          </div>
          <div class="col-12 col-md-12 mb-2" style="margin-top: 30px;">
          </div>
          <div class="col-12 col-md-4 mb-2">
            <p class="hei32 w90px floatRight">
              验证码
            </p>
          </div>
          <div class="col-12 col-md-6 mb-2">
            <div class="condiv">
              <input class="w-100" type="text" [(ngModel)]="contact_check_code" (change)="code_format()">
              <span class="text-danger" *ngIf="!contact_check_code">请输入验证码！</span>
              <span class="text-danger" *ngIf="!code_formatError">验证码格式为6位数字组成！</span>
            </div>
          </div>
          <div class="col-12 col-md-2 mb-2" style="margin-top: -2px;margin-left: -110px;">
            <button type="button" class="btn btn-primary" id="check_code_btn" (click)="getCheckCode()">
              <span id="check_code_vlaue">点击获取</span>
              <ng-container *ngIf="checkcod_timer_second>0">({{checkcod_timer_second}}s)</ng-container>
            </button>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="changeLeaseContacts(auditPerson,contactOperationalTips)">确定</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #contactOperationalTips="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg" style="color:black;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="contactOperationalTipsHide(contactOperationalTips)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body" style="text-align:center;padding-top:100px;padding-bottom:100px;">
        联系人信息已变更，乐享住APP企业登录账号信息已更新
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="contactOperationalTipsHide(contactOperationalTips)">关闭</button>
      </div>
    </div>
  </div>
</div>

<!--&lt;!&ndash;批量导入入住人&ndash;&gt;-->
<!--<div class="modal fade" bsModal #residentTemplate="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"-->
<!--     aria-labelledby="dialog-static-name">-->
<!--  <div class="modal-dialog modal-primary">-->
<!--    <div class="modal-content">-->
<!--      <div class="modal-header">-->
<!--        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>批量导入入住人</h4>-->
<!--        <button type="button" class="close pull-right" aria-label="Close" (click)="residentTemplate.hide()">-->
<!--          <span><i class="icon icon-close" aria-hidden="true"></i></span>-->
<!--        </button>-->
<!--      </div>-->
<!--      <div class="modal-body">-->
<!--        <div class="row" style="color:#333333;">-->
<!--          <div class="col-12 mb20">-->
<!--            同时设置多个房间的入住人时，请根据附件模板填入入住人信息后导入系统-->
<!--          </div>-->
<!--          <div class="col-12 mb20">-->
<!--            <button class="btn btn-primary" style="color: #36a9e1; background: #fff; border: 1px solid #36a9e1;"-->
<!--                    (click)="downResidentTemplate()">下载模板-->
<!--            </button>-->
<!--          </div>-->
<!--          <div class="col-12">-->
<!--            文件上传：-->
<!--            <ng-container #uploadRoomResidents>-->
<!--              <nz-upload [nzAction]="importRoomResidentsUrl" [nzHeaders]="importRoomResidentsHeader"-->
<!--                         [nzData]="importRoomResidentsData"-->
<!--                         (nzChange)="importRoomResidents($event,residentTemplate)"-->
<!--                         [nzFileList]="residentsFileList">-->
<!--                <button nz-button>-->
<!--                  <i class="anticon anticon-upload"></i><span>上传附件</span>-->
<!--                </button>-->
<!--              </nz-upload>-->
<!--            </ng-container>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div class="modal fade" bsModal #changeTypeModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>换房选择</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="changeTypeModel.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <p class="mb-2">请选择需要换房房间类型：</p>
        <div class="over10 mt-2 mb-2 text-center">
          <label class="checkbox-inline mr-1">
            <input type="radio" name="type" value="IN" [(ngModel)]="changeRoomType" [checked]="changeRoomType=='IN'"/>我要换到<span
            class="red">合同内</span>的其他房间
          </label>
        </div>
        <div class="over10 text-center mb-4">
          <label class="checkbox-inline">
            <input type="radio" name="type" value="OUT" [(ngModel)]="changeRoomType" [checked]="changeRoomType=='OUT'"/>我要换到<span
            class="red">合同外</span>的其他房间
          </label>
        </div>

        <div class="text-center mb20">
          <button type="button" class="btn btn-primary mr20" [disabled]="canNext2()" (click)="next2()">下一步</button>
          <button type="button" (click)="changeTypeModel.hide()" class="btn btn-secondary">取消</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #rechargeRoomsModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name" style="z-index: 1049">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>设置批量充值金额</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancelRecharge()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mb-2 batch-div1">
          按每间
          <input class="form-control batch-input1" type="number" min="0" [(ngModel)]="batchRechargeAmount"
                 placeholder="请输入金额" (keyup)="format_input_num($event)"/>
          元充值
          <button type="button" class="btn btn-primary mr20 batch-btn1" (click)="resetAmount()">确认</button>
        </div>
        <table class="table w-100 ">
          <thead>
          <tr>
            <th><input type="checkbox"
                       [checked]="checkedLeaseRoomIds.length == rechargeRooms.length && checkedLeaseRoomIds.length > 0"
                       (click)="checkAll($event)">全选
            </th>
            <th>序号</th>
            <th>房间号</th>
            <th>预付费余额</th>
            <th>充值金额</th>
          </tr>
          </thead>
          <tbody class="over12">
          <tr *ngFor="let item of rechargeRooms; let i = index">
            <td>
              <input type="checkbox" [checked]="(checkedLeaseRoomIds.indexOf(item.lease_room_id)>=0)"
                     (click)="checkCurrentRoom($event,item.lease_room_id)">
            </td>
            <td>{{i + 1}}</td>
            <td>{{item?.room_name}}</td>
            <td>{{item?.prepay_total}}</td>
            <td><input id="{{item.lease_room_id}}" type="number" min="0" (keyup)="format_input_num($event)"
                       (change)="format_input_num($event)"/></td>
          </tr>
          </tbody>
        </table>
        <div class="row mb-2 batch-div1">
          已选择<span class="batch-span1">{{checkedLeaseRoomIds.length}}</span>间，充值总金额：<span
          class="batch-span2">{{batchRechargeTotal}}</span>元
        </div>
        <hr>
        <div class="text-center mb20">
          <button type="button" class="btn btn-primary mr20" [disabled]="batchRechargeTotal == 0"
                  (click)="turnToRechargeModle()">确认并收款
          </button>
          <button type="button" (click)="cancelRecharge()" class="btn btn-secondary">取消</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  电费补差退款  -->
<div class="modal fade showRefundModal" bsModal #showRefundModal="bs-modal"
     [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>电费补差退款</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="showRefundModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="col-12 mt20 mb10">
          <p style="margin-left:7px"><span class="color_red"
                                           *ngFor="let item of refundInfoDetalis.period_list;last as last">【{{item}}】<i
            *ngIf="!last">、</i></span>电费结转补差需退款：</p>
          <p class="ml30 mt10">电费结算补差有<span class="color_red">{{refundInfoDetalis.period_list.length}}</span>间房间电费需退款，电费补差退款总金额：<span
            class="_price">{{refundInfoDetalis?.total_refund_amount}}</span> 元</p>
        </div>
        <div class="col-12  mb10 refund_line_b">
          <div class="flex mb10">
            <span style="margin-left:13px">退款方式:</span>
            <div class="custom-control custom-radio ml10">
              <input type="radio" id="customRadio1" name="customRadio" [(ngModel)]="refundType" value="TRANSFER"
                     class="custom-control-input">
              <label class="custom-control-label" for="customRadio1">转账</label>
            </div>
            <div class="custom-control custom-radio ml10">
              <input type="radio" id="customRadio2" name="customRadio" [(ngModel)]="refundType" value="CASH"
                     class="custom-control-input">
              <label class="custom-control-label" for="customRadio2">现金</label>
            </div>
          </div>
          <div class="mt10" *ngIf="refundType === 'TRANSFER'">
            <div class="col-md-12 flex">
              <div class="col-md-6 mb-3 " style="padding: 0 10px 0 0;">
                <label for="validationDefault01">收款人姓名:</label>
                <input type="text" class="form-control" [(ngModel)]="refundNoInfo.refund_name">
              </div>
              <div class="col-md-6 mb-3 " style="padding: 0 0 0 10px;">
                <label for="validationDefault01">开户行:</label>
                <input type="text" class="form-control" [(ngModel)]="refundNoInfo.open_bank">
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <label for="validationDefault01">银行账号:</label>
              <input type="text" class="form-control" [(ngModel)]="refundNoInfo.bank_no">
            </div>
          </div>
        </div>
        <div class="col-12  mb10 room_table">
          <div class="_head">
            <span>房间补差退款明细：</span>
            <span class="down_t" (click)="refundDownload()">下载明细</span>
          </div>
          <div>
            <table
              [ngClass]="{'table1': true, 'mt10': true, 'is_scroll_w': refundInfoDetalis.ele_refund_dtos.length > 5}">
              <tr>
                <th class="w15">序号</th>
                <th class="w30">房间号</th>
                <th class="w25">结算周期</th>
                <th class="w25">电费补差金额</th>
              </tr>
            </table>
            <div [ngClass]="{'table_scroll': true, 'noscroll': refundInfoDetalis.ele_refund_dtos.length <= 5}">
              <table class="table2">
                <tr>
                  <th class="w15">序号</th>
                  <th class="w30">房间号</th>
                  <th class="w25">结算周期</th>
                  <th class="w25">电费补差金额</th>
                </tr>
                <tr *ngFor="let item of refundInfoDetalis.ele_refund_dtos;index as i">
                  <td>{{i + 1}}</td>
                  <td>{{item.room_name}}</td>
                  <td>{{item.year}}年{{item.month}}月</td>
                  <td>{{item.difference}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button"
                    (click)="submitRefund()"
                    class="btn btn-primary">
              <img src="/assets/img/loading.gif" *ngIf="saveLoading" class="saveLoading" alt="">
              {{saveLoading ? '提交中...' : '提交退款'}}
            </button>
            <button type="button" class="btn btn-secondary ml-4" data-dismiss="modal"
                    (click)="showRefundModal.hide()">取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade showMsgModal" bsModal #showMsgModal="bs-modal"
     [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>提示框</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="showMsgModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <p class="showMsgModal_p">电费补差退款提交成功，等待财务人员处理退款!</p>
      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button"
                    (click)="showMsgModal.hide()"
                    class="btn btn-primary">关闭
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- lease change -->
<div class="modal fade " bsModal #modalChange="bs-modal"
     [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>信息变更</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalChange.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">

        <div class="col mt-2">
          <div class="row">
            <div class="col-4 text-center">我要变更：</div>
            <div class="col-8">
              <div class="">

                <div class="form-check">
                  <input class="form-check-input" type="radio" value="leaseSubject" [(ngModel)]="changeType">
                  <label class="form-check-label">
                    合同主体信息
                  </label>
                </div>

                <div class="form-check" *ngIf="deposit_need_pay_show">
                  <input class="form-check-input" type="radio" value="roomAvailableTime" [(ngModel)]="changeType">
                  <label class="form-check-label">
                    房间使用期限调整
                  </label>
                </div>

                <div class="form-check">
                  <input class="form-check-input" type="radio" value="" [(ngModel)]="changeType" disabled>
                  <label class="form-check-label">
                    合同发票信息
                  </label>
                </div>

                <div class="form-check">
                  <input class="form-check-input" type="radio" value="" [(ngModel)]="changeType" disabled>
                  <label class="form-check-label">
                    付款周期及价格
                  </label>
                </div>

                <div class="form-check">
                  <input class="form-check-input" type="radio" value="" [(ngModel)]="changeType" disabled>
                  <label class="form-check-label">
                    免租期及滞纳金
                  </label>
                </div>

                <div class="form-check">
                  <input class="form-check-input" type="radio" value="" [(ngModel)]="changeType" disabled>
                  <label class="form-check-label">
                    提醒关停阈值
                  </label>
                </div>


              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button"
                    (click)="leaseChange()"
                    class="btn btn-primary">下一步
            </button>
            <button type="button"
                    (click)="modalChange.hide()"
                    class="btn btn-secondary">关闭
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade " bsModal #modalChangeLeaseSubject="bs-modal"
     [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary ">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>信息变更-合同主体信息</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalChangeLeaseSubject.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">

        <div class="col mt-2" [formGroup]="fgLeaseSubjectChange">

          <ng-container *ngIf="leaseSubjectResponse?.subject_type==='PERSON'">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>签约人</label>
                  <input type="text" class="form-control" formControlName="name" placeholder="签约人" maxlength="30"
                         readonly>
                  <p class="text-danger" *ngIf="lscSubmit && this.fgLeaseSubjectChange.hasError('required',['name'])">
                    签约人不能为空</p>
                  <p class="text-danger" *ngIf="lscSubmit && this.fgLeaseSubjectChange.hasError('minlength',['name'])">
                    不能小于两个字</p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>联系电话</label>
                  <input type="text" class="form-control" formControlName="tel" placeholder="联系电话" maxlength="20">
                  <p class="text-danger" *ngIf="lscSubmit && this.fgLeaseSubjectChange.hasError('required',['tel'])">
                    电话不能为空</p>
                  <p class="text-danger" *ngIf="lscSubmit && this.fgLeaseSubjectChange.hasError('pattern',['tel'])">
                    联系电话8-20位</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>证件类型</label>
                  <select class="form-control" formControlName="cret_type" (change)="changeLSCCheck()" readonly
                          disabled>
                    <option value="ID_CD" selected>身份证</option>
                    <option value="PASSPORT">护照</option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>证件号</label>
                  <input type="text" class="form-control" formControlName="cret_num" placeholder="证件号" maxlength="20"
                         readonly>
                  <p class="text-danger"
                     *ngIf="lscSubmit && this.fgLeaseSubjectChange.hasError('required',['cret_num'])">证件号不能为空</p>
                  <p class="text-danger"
                     *ngIf="lscSubmit && this.fgLeaseSubjectChange.hasError('minlength',['cret_num'])">证件号6-20位</p>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="leaseSubjectResponse?.subject_type==='COMPANY'">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>公司名称</label>
                  <input type="text" class="form-control" formControlName="company_name" placeholder="公司名称"
                         maxlength="50" readonly>
                  <p class="text-danger"
                     *ngIf="lscSubmit && this.fgLeaseSubjectChange.hasError('required',['company_name'])">公司名称不能为空</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>联系人</label>
                  <input type="text" class="form-control" formControlName="name" placeholder="联系人" maxlength="10">
                  <p class="text-danger" *ngIf="lscSubmit && this.fgLeaseSubjectChange.hasError('required',['name'])">
                    联系人不能为空</p>
                  <p class="text-danger" *ngIf="lscSubmit && this.fgLeaseSubjectChange.hasError('minlength',['name'])">
                    不能小于两个字</p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>联系电话</label>
                  <input type="text" class="form-control" formControlName="tel" placeholder="联系电话" maxlength="20">
                  <p class="text-danger" *ngIf="lscSubmit && this.fgLeaseSubjectChange.hasError('required',['tel'])">
                    电话不能为空</p>
                  <p class="text-danger" *ngIf="lscSubmit && this.fgLeaseSubjectChange.hasError('pattern',['tel'])">
                    联系电话8-20位</p>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="row" *ngIf="leaseDetailResponse?.lease_step!=='INTERNAL_LEASE'">
            <div class="col-6">
              <div class="form-group">
                <div>
                  <label>紧急联系人</label>
                  <button class="btn btn-link text-right" (click)="addLSEmergency()">+新增</button>
                </div>
                <div class="col" formArrayName="emergency_contacts" *ngIf="emergency_contacts">
                  <div class="row mt-1" *ngFor="let item of emergency_contacts.controls;let i=index"
                       [formGroupName]="i">
                    <div class="col-md-5 px-0">
                      <input type="text" class="form-control" formControlName="contact_name" placeholder="姓名"
                             maxlength="10" (change)="changeEmergency()">
                      <p class="text-danger"
                         *ngIf="lscSubmit && this.emergency_contacts.at(i).hasError('required',['contact_name'])">
                        联系人不能为空</p>
                      <p class="text-danger"
                         *ngIf="lscSubmit && this.emergency_contacts.at(i).hasError('minlength',['contact_name'])">
                        不能小于两个字</p>
                    </div>
                    <div class="col-md-6 px-0">
                      <input type="text" class="form-control" formControlName="contact_phone" placeholder="电话"
                             maxlength="20" (change)="changeEmergency()">
                      <p class="text-danger"
                         *ngIf="lscSubmit && this.emergency_contacts.at(i).hasError('required',['contact_phone'])">
                        电话不能为空</p>
                      <p class="text-danger"
                         *ngIf="lscSubmit && this.emergency_contacts.at(i).hasError('pattern',['contact_phone'])">
                        联系电话8-20位</p>
                    </div>
                    <div class="col-md-1 px-0 text-center" *ngIf="i!==0">
                      <button class="btn btn-link" (click)="delLSEmergency(i)"><i class="fa fa-times-circle-o"></i>
                      </button>
                    </div>
                  </div>
                  <div class="col-12 text-danger" *ngIf="lscErr">紧急联系人姓名、联系电话不可以和合同签约人/联系人、联系电话相同</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>变更原因</label>
                <textarea class="form-control" formControlName="change_reason" placeholder="限100字，必填"
                          maxlength="100"></textarea>
                <p class="text-danger"
                   *ngIf="lscSubmit && this.fgLeaseSubjectChange.hasError('required',['change_reason'])">变更原因不能为空</p>
              </div>
            </div>
          </div>
          <div class="row">
<!--            <div class="col-12">-->
<!--              <label>附件证明：-->
<!--                &lt;!&ndash;                <span class="text-gray-300">(&ndash;&gt;-->
<!--                &lt;!&ndash;                <ng-container *ngIf="leaseSubjectResponse?.subject_type==='PERSON'">&ndash;&gt;-->
<!--                &lt;!&ndash;                您有变更签约人姓名或证件号码时必传证明文件&ndash;&gt;-->
<!--                &lt;!&ndash;                </ng-container>&ndash;&gt;-->
<!--                &lt;!&ndash;                <ng-container *ngIf="leaseSubjectResponse?.subject_type==='COMPANY'">&ndash;&gt;-->
<!--                &lt;!&ndash;                您有变更公司名称时必传证明文件&ndash;&gt;-->
<!--                &lt;!&ndash;                </ng-container>&ndash;&gt;-->
<!--                &lt;!&ndash;                )</span>&ndash;&gt;-->
<!--              </label>-->

<!--              <div class="">-->
<!--                <app-new-file-upload [type]="2"-->
<!--                                     [accept]="'IMG'"-->
<!--                                     [maxLength]="5"-->
<!--                                     [isMaxFileSize]="true"-->
<!--                                     (fileChange)="leaseSubjectFileChange($event)">-->
<!--                </app-new-file-upload>-->

<!--                <p class="text-danger" *ngIf="lscSubmit && lscImgRequired">证明文件不能为空</p>-->
<!--              </div>-->
<!--            </div>-->
            <div class="col-12 mb10">
              <label>附件：</label>
              <div>
                <app-new-file-upload [type]="2" accept="IMG|PDF|WORD|EXCEL|TXT|PPT" maxLength="10"
                                     (fileChange)="setFiles1($event)" [imgList]="initImg"></app-new-file-upload>

<!--                <p class="text-danger" *ngIf="lscSubmit && lscImgRequired">证明文件不能为空</p>-->
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button"
                    (click)="leaseChangeSubmit()"
                    class="btn btn-primary">下一步
            </button>
            <button type="button"
                    (click)="modalChangeLeaseSubject.hide()"
                    class="btn btn-secondary">关闭
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #modalChangeRoomAvailableTime="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary ">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>信息变更-房间使用期限调整</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalChangeRoomAvailableTime.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="col mt-2" [formGroup]="roomAvailableChangeGroup">
          <div class="row">
            <div class="col-12">
              <div class="form-group text-center">
                <div>首期房费在应付时间未付，仍可在未来
                  <input type="text" style="width: 50px;" autocomplete="false" formControlName="room_available_days"
                         (change)="changeTempPayEndDate()">
                  天使用水电和门锁密码；
                </div>
                <div class="text-danger text-md-center ml-9 "
                     *ngIf="this.roomAvailableChangeGroup.hasError('formatError',['room_available_days'])">
                  只能输入数字,最多可设置30天
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group text-center">
                <label>{{tempPayEndDate | date:"yyyy-MM-dd"}}期限过后仍未付清，系统将会对所有房间断水断电，作废门锁密码</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>变更原因</label>
                <textarea class="form-control" formControlName="change_reason" placeholder="限100字，必填"
                          maxlength="100"></textarea>
                <p class="text-danger" *ngIf="this.roomAvailableChangeGroup.hasError('required',['change_reason'])">
                  变更原因不能为空</p>
              </div>
            </div>
            <div class="col-12 mb10">
              <label>附件：</label>
              <div>
                <app-new-file-upload [type]="2" accept="IMG|PDF|WORD|EXCEL|TXT|PPT" maxLength="10"
                                     (fileChange)="setFiles2($event)" [imgList]="initImg"></app-new-file-upload>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button"
                    (click)="changeRoomAvailableTimeSubmit()"
                    class="btn btn-primary">提交
            </button>
            <button type="button"
                    (click)="modalChangeRoomAvailableTime.hide()"
                    class="btn btn-secondary">关闭
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade " bsModal #approvModal="bs-modal"
     [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary ">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="approvModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">

        <div class="col mt-2">
          <div class="row mx-5">
            <div class="text-center mt-2">
              合同变更申请已提交成功,审批单号：<span class="text-danger">{{approvNum}}</span>
            </div>
            <div class="text-center my-2">
              合同变更需走审批流程，您可在【合同详情-审批记录】模块查看审批进度。
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button"
                    (click)="approvModal.hide()"
                    class="btn btn-primary">关闭
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #contractConfirm="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="contractConfirm.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-12 col-md-12 mt-4 mb-5 font-3xl text-md-center">
          合同上传成功，租约已生效
        </div>
        <div *ngIf="deposit_need_pay_show">
          <div class="col-12 col-md-12 mt-2">
            <hr style="border-style: solid">
          </div>
          <div class="col-12 col-md-12 mt-2 text-red">
            注意事项：
          </div>
          <div class="col-12 col-md-12 text-red">
            <p class="font-weight-bold font-2xl inline">·</p>&nbsp;该合同下所有房间的押金账单未全部支付时，不可操作办理入住；
          </div>
          <div class="col-12 col-md-12 text-red">
            <p class="font-weight-bold font-2xl inline">·</p>&nbsp;该合同下所有房间的首期房费在应付时间未全部支付时，系统将会对所有房间断水断电，作废门锁密码；
          </div>
          <div class="col-12 col-md-12 ml-a text-gray-300">
            您可在此通过调整“房间使用期限”来延长房间的可用期
            <span style="color: #36a9e1;cursor: pointer;"
                  (click)="showLeaseChangeModalAndHide()">
                去设置
          </span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary ml-2" (click)="contractConfirm.hide()">关闭</button>
      </div>
    </div>
  </div>
</div>
