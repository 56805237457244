import { Component, EventEmitter, OnInit, Output ,ViewChild} from '@angular/core';
import { CompanyDetail, CompanyInfo, CompanyListReqParam } from "../../../model/company/company.model";
import { CompanyService } from "../../../service/company/company.service";
import {ModalDirective} from "ngx-bootstrap/modal";
@Component({
  selector: 'app-company-modal',
  templateUrl: './company-modal.component.html',
  styleUrls: ['./company-modal.component.css']
})
export class CompanyModalComponent implements OnInit {

  @Output()
  choosed_company_info: EventEmitter<CompanyDetail> = new EventEmitter();
  @ViewChild('company_modal') company_modal: ModalDirective;
  //企业列表
  company_list: CompanyInfo[] = [];

  //企业名(用于查询)
  search_company_name: string = '';

  //企业模态框
  // company_modal: any;

  constructor(private companyService: CompanyService) {
  }

  ngOnInit() {
  }

  //初始化组件
  // initSomething(company_modal: any) {
  //   this.company_modal = company_modal;
  // }

  //查询企业列表
  searchCompanyList() {
    this.company_list = [];

    let param: CompanyListReqParam = new CompanyListReqParam();
    param.page_num = 1;
    param.page_size = 11;
    param.company_name = this.search_company_name;
    param.need_balance = param.need_delete = false;
    this.companyService.queryCompanyList(param).then(data => {
      if (data && data.company_list) {
        this.company_list = data.company_list;
      }
    });
  }

  //选择企业
  chooseCompany(company_id: number) {
    this.companyService.getCompanyInfo(company_id).then(data => {
      this.choosed_company_info.emit(data);
      this.hide();
    });
  }

  //企业搜索弹窗显示
  show() {
    this.search_company_name = '';
    this.company_list = [];

    this.searchCompanyList();
    this.company_modal.show();
  }

  //企业搜索弹窗关闭
  hide() {
    this.company_modal.hide();
  }
}
