import {LocalStorage} from './localstorage';
import {formatYYYYmmdd} from "./date.util";

import * as _ from "underscore";


export function getUserAgent() {

  let userAgent = window.navigator.userAgent; //取得浏览器的userAgent字符串

  let isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
  let isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
  if (!isIE) {
    isIE = userAgent.indexOf("Windows NT") > -1 && userAgent.indexOf("BOIE") > -1 && !isOpera; //判断是否IE浏览器
  }
  let is360 = isIE && ((window.navigator['userProfile'] + '') === 'null');//判断是否360浏览器
  let isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
  let isChrome = userAgent.indexOf("Chrome") > -1; //判断是否Safari浏览器
  let isSafari = userAgent.indexOf("Safari") > -1 && !isChrome; //判断是否Safari浏览器
  let isAppleWebKit = userAgent.indexOf("AppleWebKit") > -1; //判断是否AppleWebKit浏览器


  if (isIE && !is360) {
    let IE5 = false, IE55 = false, IE6 = false, IE7 = false, IE8 = false;
    let reIE = new RegExp("MSIE (\\d+\\.\\d+);");
    reIE.test(userAgent);
    let fIEVersion = parseFloat(RegExp["$1"]);
    IE55 = fIEVersion === 5.5;
    IE6 = fIEVersion === 6.0;
    IE7 = fIEVersion === 7.0;
    IE8 = fIEVersion === 8.0;
    if (IE55) {
      return "IE55|false";
    } else if (IE6) {
      return "IE6|false";
    } else if (IE7) {
      return "IE7|false";
    } else if (IE8) {
      return "IE8|false";
    } else {
      return "IE9|false";
    }
  } //isIE end
  if (is360) {
    return "360|true";
  }
  if (isFF) {
    return "FF|true";
  }
  if (isChrome) {
    return "Chrome|true";
  }
  if (isOpera) {
    return "Opera|true";
  }
  if (isSafari) {
    return "Safari|true";
  }
  if (isAppleWebKit) {
    return "AppleWebKit|true";
  }
}

/**
 * @Description: 去除两边空格
 * @Author: 陆炳彬
 * @Date: 2018/8/8 下午5:29
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/8/8 下午5:29
 */
export function trim(str: string): string {
  if (str && str.length > 0) {
    return str.trim();
  }

  return str;
}

// 是否是在APP上打开
export function isInApp() {
  let canShow = false, result = LocalStorage.getItem('CURRENT_USER_SOURCE');
  if (result && result === "CAN_VIEW") {
    canShow = true;
  }
  return canShow;
}

// 唤起APP原生操作
export function toCallPhoneView(type: string) {
  if (isInApp()) {
    callPhoneView(type);
  }
}

export function callPhoneView(type: string) {
  let Test2 = window['Test2'];
  let test = new Test2();
  // 由于对象映射,所以调用test对象等于调用Android映射的对象
  test.reqToOpenViewAndGetToken(type);
}

/**
 * 与APP原生交互
 * [实质上是监听alert内容]
 *
 * @param Constants.APP.CALL_TYPE type
 */
export function callApp(type: string, data?) {
  if (isInApp()) {
    if (data) {
      alert(type + ":" + JSON.stringify(data));
    } else {
      alert(type);
    }
  }
}


export function generateDatePluginData(time: Date): any {
  let value = {
    date: {
      year: time.getFullYear(),
      month: time.getMonth() + 1,
      day: time.getDate()
    },
    formatted: formatYYYYmmdd(time)
  };
  return value;
}

export function commonGroupBy(array: any, target: any): any {
  let groups = {};
  array.forEach(o => {
    let group = JSON.stringify(target(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(group => groups[group]);
}


export function filterFormData(formDataObj: any) {

  if (formDataObj && typeof formDataObj === 'object') {
    // 过滤无用参数
    for (const item in formDataObj) {
      if (formDataObj[item] == null) {
        delete formDataObj[item];
      } else if (typeof formDataObj[item] === 'object') {
        filterFormData(formDataObj[item]);
      }
    }
  }

  return formDataObj;
}


export function getDefault(str: any, def = "") {
  return str ? str : def;
}


export function is_inApp_Android() {
  let u: string = navigator.userAgent.toLowerCase();
  let regExpMatchArray = u.match(/app\/android/i);
  if (regExpMatchArray && regExpMatchArray.toString() === "app/android") {
    return true;
  } else {
    return false;
  }
}

export function is_inApp_IOS() {
  let u: string = navigator.userAgent.toLowerCase();
  let regExpMatchArray = u.match(/app\/ios/i);
  if (regExpMatchArray && regExpMatchArray.toString() === "app/ios") {
    return true;
  } else {
    return false;
  }
}


export function cartesianProductOf(arg: Array<any>) {
  let reduce = _.reduce(arg, function (a, b) {
    return _.flatten(_.map(a, function (x) {
      return _.map(b, function (y) {
        return x.concat([y]);
      });
    }), true);
  }, [[]]);
  return reduce;
}

//倒计时
export function countDownTime(time: number) {
  //计算出相差天数
  let days = Math.floor(time / (24 * 3600 * 1000));
  //计算出小时数
  let num = time % (24 * 3600 * 1000);//计算天数后剩余的毫秒数
  let hours = Math.floor(num / (3600 * 1000));
  //计算相差分钟数
  let leave = num % (3600 * 1000);//计算小时数后剩余的毫秒数
  let minutes = Math.floor(leave / (60 * 1000));
  //计算相差秒数
  let s = leave % (60 * 1000);//计算分钟数后剩余的毫秒数
  let seconds = Math.round(s / 1000);

  if (time < 0) {
    //计算出相差天数
    days = Math.ceil(time / (24 * 3600 * 1000));
    //计算出小时数
    num = time % (24 * 3600 * 1000);//计算天数后剩余的毫秒数
    hours = Math.ceil(num / (3600 * 1000));
    //计算相差分钟数
    leave = num % (3600 * 1000);//计算小时数后剩余的毫秒数
    minutes = Math.ceil(leave / (60 * 1000));
    //计算相差秒数
    s = leave % (60 * 1000);//计算分钟数后剩余的毫秒数
    seconds = Math.ceil(s / 1000);
  }

  let times = days + "天" + hours + "时" + minutes + "分" + seconds + "秒";
  return times;
}


export function copyText(content) {
  // 如果当前浏览器版本不兼容navigator.clipboard
  if (!navigator.clipboard) {
    let ele = document.createElement("input");
    ele.value = content;
    document.body.appendChild(ele);
    ele.select();
    document.execCommand("copy");
    document.body.removeChild(ele);
    if (document.execCommand("copy")) {
      console.log("复制成功！");
    } else {
      console.log("复制失败！");
    }
  } else {
    navigator.clipboard.writeText(content).then(function () {
      console.log("复制成功！");
    }).catch(function () {
      console.log("复制失败！");
    });
  }
}
