export const BuildingMap = {


  // start 汽车城
  // A 5
  29: {
    x: 7000,
    y: 0,
    z: 500,

    // 房间盒子长宽高
    buildingX: 330,
    buildingY: 200,
    buildingZ: 550,
    // 楼梯大小
    buildingStairX: 700,
    // 空白大小
    buildingSpaceX: 500,
  },
  // B 4
  31: {
    x: -5000,
    y: 0,
    z: 500,

    // 房间盒子长宽高
    buildingX: 330,
    buildingY: 200,
    buildingZ: 550,
    // 楼梯大小
    buildingStairX: 700,
    // 空白大小
    buildingSpaceX: 500,
  },
  // C 2
  33: {
    x: 2000,
    y: 0,
    z: -1500,

    // 房间盒子长宽高
    buildingX: 330,
    buildingY: 200,
    buildingZ: 550,
    // 楼梯大小
    buildingStairX: 700,
    // 空白大小
    buildingSpaceX: 500,
  },
  // end 汽车城

  // start 协通
  23: {
    x: 0,
    y: 0,
    z: 0,

    // 房间盒子长宽高
    buildingX: 270,
    buildingY: 200,
    buildingZ: 1080,
    // 楼梯大小
    buildingStairX: 700,
    // 空白大小
    buildingSpaceX: 100,

  },
  // end 协通


  // start 天津天海
  // B 1号楼
  101: {
    x: 0,
    y: 0,
    z: 0,

    // 房间盒子长宽高
    buildingX: 300,
    buildingY: 200,
    buildingZ: 550,
    // 楼梯大小
    buildingStairX: 300,
    // 空白大小
    buildingSpaceX: 100,
  },
  // A 2号楼
  99: {
    x: 0,
    y: 0,
    z: 0,

    // 房间盒子长宽高
    buildingX: 300,
    buildingY: 200,
    buildingZ: 550,
    // 楼梯大小
    buildingStairX: 300,
    // 空白大小
    buildingSpaceX: 100,
  },
  // D 5号楼
  105: {
    x: 0,
    y: 0,
    z: 0,

    // 房间盒子长宽高
    buildingX: 300,
    buildingY: 200,
    buildingZ: 550,
    // 楼梯大小
    buildingStairX: 300,
    // 空白大小
    buildingSpaceX: 300,
  },
  // F 综合楼
  109: {
    x: 0,
    y: 0,
    z: 0,

    // 房间盒子长宽高
    buildingX: 300,
    buildingY: 200,
    buildingZ: 550,
    // 楼梯大小
    buildingStairX: 300,
    // 空白大小
    buildingSpaceX: 100,
  },
  // E 4号楼
  107: {
    x: 0,
    y: 0,
    z: 0,

    // 房间盒子长宽高
    buildingX: 300,
    buildingY: 200,
    buildingZ: 550,
    // 楼梯大小
    buildingStairX: 300,
    // 空白大小
    buildingSpaceX: 300,
  },
  // end 天津天海

  // start 天津明湾
  // A 7号楼
  57: {
    x: 0,
    y: 0,
    z: 0,

    // 房间盒子长宽高
    buildingX: 300,
    buildingY: 200,
    buildingZ: 550,
    // 楼梯大小
    buildingStairX: 300,
    // 空白大小
    buildingSpaceX: 100,
  },
  // B 3号楼
  63: {
    x: 0,
    y: 0,
    z: 0,

    // 房间盒子长宽高
    buildingX: 300,
    buildingY: 200,
    buildingZ: 550,
    // 楼梯大小
    buildingStairX: 300,
    // 空白大小
    buildingSpaceX: 100,
  },
  // end 天津明湾

  // start 天津微电子
  // A
  20: {
    x: 0,
    y: 0,
    z: 0,

    // 房间盒子长宽高
    buildingX: 300,
    buildingY: 200,
    buildingZ: 550,
    // 楼梯大小
    buildingStairX: 300,
    // 空白大小
    buildingSpaceX: 300,
  },
  // B
  21: {
    x: 0,
    y: 0,
    z: 0,

    // 房间盒子长宽高
    buildingX: 300,
    buildingY: 200,
    buildingZ: 550,
    // 楼梯大小
    buildingStairX: 300,
    // 空白大小
    buildingSpaceX: 300,
  },
  // 12号楼
  22: {
    x: 0,
    y: 0,
    z: 0,

    // 房间盒子长宽高
    buildingX: 300,
    buildingY: 200,
    buildingZ: 550,
    // 楼梯大小
    buildingStairX: 300,
    // 空白大小
    buildingSpaceX: 300,
  },
  // end 天津微电子


  // TEST
  // start 汽车城
  // A 5
  1384: {
    x: 7000,
    y: 0,
    z: 500,

    // 房间盒子长宽高
    buildingX: 330,
    buildingY: 200,
    buildingZ: 550,
    // 楼梯大小
    buildingStairX: 700,
    // 空白大小
    buildingSpaceX: 500,
  },
  // B 4
  1386: {
    x: -5000,
    y: 0,
    z: 500,

    // 房间盒子长宽高
    buildingX: 330,
    buildingY: 200,
    buildingZ: 550,
    // 楼梯大小
    buildingStairX: 700,
    // 空白大小
    buildingSpaceX: 500,
  },
  // C 2
  1388: {
    x: 2000,
    y: 0,
    z: -1500,

    // 房间盒子长宽高
    buildingX: 330,
    buildingY: 200,
    buildingZ: 550,
    // 楼梯大小
    buildingStairX: 700,
    // 空白大小
    buildingSpaceX: 500,
  }
  // end 汽车城
};

export class BuildingFloorMapModel {
  matrix: [][];
  publicArea: Array<number>;
  floorWidthNum: number;
  floorWidthZ: number;
}

// 1 房间 2 楼梯 3 公区 0 空白 4 旋转房间不加距离 5 旋转房间 6 7 旋转楼梯
export const BuildingFloorMap = {


  // start 汽车城
  // A 5
  29: {
    1: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 3],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 3]
      ],
      publicArea: [
        700, 1200
      ],
      floorWidthNum: 21
    },
    2: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1],
      ],
      publicArea: [],
      floorWidthNum: 21
    },
    3: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1],
      ],
      publicArea: [],
      floorWidthNum: 21
    },
    4: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1],
      ],
      publicArea: [],
      floorWidthNum: 21
    },
    5: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1],
      ],
      publicArea: [],
      floorWidthNum: 21
    },
    6: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1],
      ],
      publicArea: [],
      floorWidthNum: 21
    },
    7: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1],
      ],
      publicArea: [],
      floorWidthNum: 21
    },
    8: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1],
      ],
      publicArea: [],
      floorWidthNum: 21
    },
  },
  // B 4
  31: {
    1: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [
        700, 1200
      ],
      floorWidthNum: 28
    },
    2: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 28
    },
    3: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 28
    },
    4: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 28
    },
    5: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 28
    },
    6: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 28
    },
    7: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 28
    },
    8: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 28
    },
  },
  // C 2
  33: {
    1: {
      // matrix: [],
      publicArea: [],
      floorWidthNum: 13
    },
    2: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 13
    },
    3: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 13
    },
    4: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 13
    },
    5: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 13
    },
    6: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 13
    },
    7: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 13
    },
    8: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 13
    },
  },
  // end 汽车城

  // start 协通
  23: {
    1: {
      matrix: [
        [5, 0, 0, 1, 1, 1, 3, 1, 1, 1, 1, 0],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5],
        [5],
        [5],
        [5],
        [5, 1, 2, 3, 2, 1],
      ],
      publicArea: [500, 270, 270, 500],
      floorWidthZ: 200,
      floorWidthNum: 20
    },
    2: {
      matrix: [
        [5, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 1, 2, 5, 2, 5],
      ],
      publicArea: [270],
      floorWidthZ: 200,
      floorWidthNum: 20
    },
    3: {
      matrix: [
        [5, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 1, 2, 3, 2, 5],
      ],
      publicArea: [270],
      floorWidthZ: 200,
      floorWidthNum: 20
    },
    4: {
      matrix: [
        [5, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 1, 2, 5, 2, 5],
      ],
      publicArea: [270],
      floorWidthZ: 200,
      floorWidthNum: 20
    },
    5: {
      matrix: [
        [5, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 1, 2, 3, 2, 5],
      ],
      publicArea: [270],
      floorWidthZ: 200,
      floorWidthNum: 20
    },
    6: {
      matrix: [
        [5, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 1, 2, 5, 2, 5],
      ],
      publicArea: [270],
      floorWidthZ: 200,
      floorWidthNum: 20
    },
    7: {
      matrix: [
        [5, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 1, 2, 3, 2, 5],
      ],
      publicArea: [270],
      floorWidthZ: 200,
      floorWidthNum: 20
    },
    8: {
      matrix: [
        [5, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 1, 2, 5, 2, 5],
      ],
      publicArea: [270],
      floorWidthZ: 200,
      floorWidthNum: 20
    },
    9: {
      matrix: [
        [5, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 1, 2, 3, 2, 5],
      ],
      publicArea: [270],
      floorWidthZ: 200,
      floorWidthNum: 20
    },
    10: {
      matrix: [
        [5, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
        [5, 1, 2, 5, 2, 5],
      ],
      publicArea: [270],
      floorWidthZ: 200,
      floorWidthNum: 20
    },

  },
  // end 协通


  // start 天津天海
  // 1 B
  101: {
    1: {
      matrix: [
        [3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        [5, 0, 3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2],
        [6, 0],
        [6, 0],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 7],
        [6, 0, 7],
      ],
      publicArea: [300, 300],
      floorWidthZ: 100,
      floorWidthNum: 18
    },
    2: {
      matrix: [
        [3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        [5, 0, 3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1],
        [6, 0],
        [6, 0],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 7],
        [6, 0, 6],
      ],
      publicArea: [300, 300],
      floorWidthZ: 100,
      floorWidthNum: 18
    },
    3: {
      matrix: [
        [3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        [5, 0, 3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1],
        [6, 0],
        [6, 0],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 7],
        [6, 0, 6],
      ],
      publicArea: [300, 300],
      floorWidthZ: 100,
      floorWidthNum: 18
    },
    5: {
      matrix: [
        [3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        [5, 0, 3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1],
        [6, 0],
        [6, 0],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 7],
        [6, 0, 6],
      ],
      publicArea: [300, 300],
      floorWidthZ: 100,
      floorWidthNum: 18
    },
    6: {
      matrix: [
        [3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        [5, 0, 3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1],
        [6, 0],
        [6, 0],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 7],
        [6, 0, 6],
      ],
      publicArea: [300, 300],
      floorWidthZ: 100,
      floorWidthNum: 18
    },
  },
  // 2 A
  99: {
    1: {
      matrix: [
        [7, 0, 7],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 2, 1, 1, 1, 1, 1, 1, 1, 1, 2],
        [6, 0],
        [8, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 3],
      ],
      publicArea: [300, 300, 300],
      floorWidthZ: 100,
      floorWidthNum: 13
    },
    2: {
      matrix: [
        [7, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 2, 1, 1, 1, 1, 1, 1, 1, 1, 2],
        [6, 0],
        [8, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [300, 300, 300],
      floorWidthZ: 100,
      floorWidthNum: 13
    },
    3: {
      matrix: [
        [7, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 2, 1, 1, 1, 1, 1, 1, 1, 1, 2],
        [6, 0],
        [8, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [300, 300, 300],
      floorWidthZ: 100,
      floorWidthNum: 13
    },
    5: {
      matrix: [
        [7, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 2, 1, 1, 1, 1, 1, 1, 1, 1, 2],
        [6, 0],
        [8, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [300, 300, 300],
      floorWidthZ: 100,
      floorWidthNum: 13
    },
    6: {
      matrix: [
        [7, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 6],
        [6, 0, 2, 1, 1, 1, 1, 1, 1, 1, 1, 2],
        [6, 0],
        [8, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [300, 300, 300],
      floorWidthZ: 100,
      floorWidthNum: 13
    },
  },
  // 5 D
  105: {
    2: {
      matrix: [
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
        [5, 0, 1, 1, 1, 0, 2, 3],
        [6],
        [6],
        [6],
      ],
      publicArea: [300, 300, 300],
      floorWidthZ: 120,
      floorWidthNum: 12
    },
    3: {
      matrix: [
        [0, 0, 0, 0, 1, 1, 1, 1, 1, 1],
        [0, 0, 0, 1, 2, 1, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 6],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 6],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 6],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 6],
        [0],
        [6, 0, 6],
        [6, 0, 7],
        [6, 0, 6],
        [6],
        [6],
        [6, 0, 1, 1, 1, 1],
      ],
      publicArea: [300, 300, 300],
      floorWidthZ: 120,
      floorWidthNum: 12
    },
    4: {
      matrix: [
        [0, 0, 0, 0, 1, 1, 1, 1, 1, 1],
        [0, 0, 0, 1, 2, 1, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 6],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 6],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 6],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 6],
        [0],
        [6, 0, 6],
        [6, 0, 7],
        [6, 0, 6],
        [6],
        [6],
        [6, 0, 1, 1, 1, 1],
      ],
      publicArea: [300, 300, 300],
      floorWidthZ: 120,
      floorWidthNum: 12
    },
    5: {
      matrix: [
        [0, 0, 0, 0, 1, 1, 1, 1, 1, 1],
        [0, 0, 0, 1, 2, 1, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 6],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 6],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 6],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 6],
        [0],
        [6, 0, 6],
        [6, 0, 7],
        [6, 0, 6],
        [6],
        [6],
        [6, 0, 1, 1, 1, 1],
      ],
      publicArea: [300, 300, 300],
      floorWidthZ: 120,
      floorWidthNum: 12
    },
    6: {
      matrix: [
        [0, 0, 0, 0, 1, 1, 1, 1, 1, 1],
        [0, 0, 0, 1, 2, 1, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 6],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 6],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 6],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 6],
        [0],
        [6, 0, 6],
        [6, 0, 7],
        [6, 0, 6],
        [6],
        [6],
        [6, 0, 1, 1, 1, 1],
      ],
      publicArea: [300, 300, 300],
      floorWidthZ: 120,
      floorWidthNum: 12
    },
  },
  // F 综合楼
  109: {
    1: {
      matrix: [
        [1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [300, 300, 300],
      floorWidthZ: 350,
      floorWidthNum: 14
    },
    2: {
      matrix: [
        [1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [300, 300, 300],
      floorWidthZ: 350,
      floorWidthNum: 14
    },
    3: {
      matrix: [
        [1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [300, 300, 300],
      floorWidthZ: 350,
      floorWidthNum: 14
    },
  },
  // E 4号楼
  107: {
    3: {
      matrix: [
        [3, 1, 1, 1, 1, 1, 1, 2],
        [6],
        [6, 0, 6, 0, 0, 0, 6],
        [6, 0, 6, 6, 0, 6],
        [6, 0, 6, 6, 0, 6],
        [6, 0, 0, 0, 0, 0, 0, 6],
        [6, 0, 6, 0, 0, 0, 6],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 120,
      floorWidthNum: 11
    },
    4: {
      matrix: [
        [1, 1, 1, 1, 1, 1, 1, 2],
        [6],
        [6, 0, 6, 0, 0, 0, 6],
        [6, 0, 6, 6, 0, 6],
        [6, 0, 6, 6],
        [6],
        [6],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 120,
      floorWidthNum: 11
    },
  },
  // end 天津天海

  // start 天津明湾
  // A 7号楼
  57: {
    1: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 0, 0],
        [1, 1, 1, 1, 1, 0, 0, 0, 0],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    2: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    3: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    4: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    5: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    6: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    7: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    8: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    9: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    10: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    11: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
  },
  // B 3号楼
  63: {
    1: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 3, 1, 1],
      ],
      publicArea: [300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    2: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    3: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    4: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    5: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    6: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    7: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    8: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    9: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    10: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
    11: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [600, 300, 300],
      floorWidthZ: 420,
      floorWidthNum: 10
    },
  },
  // end 天津明湾

  // start 天津微电子
  // A
  20: {
    1: {
      matrix: [
        [0],
        [0],
        [0],
        [0],
        [2, 0, 3, 1, 1, 3, 3, 3, 2, -1],
        [0],
        [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, -1],
      ],
      publicArea: [300, 300, 300, 900],
      floorWidthZ: 600,
      floorWidthNum: 12
    },
    2: {
      matrix: [
        [0],
        [0, 0, 6, 0, 6],
        [0, 0, 6, 0, 6],
        [0, 0, 0, 0, 0, 6],
        [2, 0, 3, 1, 1, 1, 1, 1, 1, 1, 2, -1],
        [0],
        [0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [300, 300, 300, 900],
      floorWidthZ: 600,
      floorWidthNum: 12
    },
    3: {
      matrix: [
        [0],
        [0, 0, 6, 0, 6],
        [0, 0, 6, 0, 6],
        [0, 0, 0, 0, 0, 6],
        [2, 0, 3, 1, 1, 1, 1, 1, 1, 1, 2, -1],
        [0],
        [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [300, 300, 300, 900],
      floorWidthZ: 600,
      floorWidthNum: 12
    },
    4: {
      matrix: [
        [0],
        [0],
        [0],
        [0],
        [2, 1, 3, 1, 1, 1, 1, 1, 1, 1, 2, -1],
        [0],
        [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [300, 300, 300, 900],
      floorWidthZ: 600,
      floorWidthNum: 12
    },
    5: {
      matrix: [
        [0],
        [0],
        [0],
        [0],
        [2, 1, 3, 1, 1, 1, 1, 1, 1, 1, 2, -1],
        [0],
        [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [300, 300, 300, 900],
      floorWidthZ: 600,
      floorWidthNum: 12
    },
    6: {
      matrix: [
        [0],
        [0],
        [0],
        [0],
        [2, 1, 3, 1, 1, 1, 1, 1, 1, 1, 2, -1],
        [0],
        [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [300, 300, 300, 900],
      floorWidthZ: 600,
      floorWidthNum: 12
    },
  },
  // B
  21: {
    1: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2],
        [1, 1, 1, 1, 3, 0, 3, 1, 1, 1, 1, 1, 1, 1],
        [0],
        [0],
        [0, 0, 0, 0, 0, 6],
        [0, 0, 0, 0, 0, 6],
        [0, 0, 0, 0, 0, 6],
      ],
      publicArea: [300, 300, 300, 300],
      floorWidthZ: 180,
      floorWidthNum: 17
    },
    2: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2],
        [1, 1, 1, 1, 1, 0, 3, 1, 1, 1, 1, 1, 1, 1],
        [0],
        [0],
        [0, 0, 0, 0, 0, 0, 6],
        [0, 0, 0, 6, 0, 6],
        [0, 0, 0, 6, 0, 6],
      ],
      publicArea: [300, 300, 300, 300],
      floorWidthZ: 180,
      floorWidthNum: 17
    },
    3: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2],
        [1, 1, 1, 1, 1, 0, 3, 1, 1, 1, 1, 1, 1, 1],
        [0],
        [0],
        [0, 0, 0, 0, 0, 0, 6],
        [0, 0, 0, 6, 0, 6],
        [0, 0, 0, 6, 0, 6],
      ],
      publicArea: [300, 300, 300, 300],
      floorWidthZ: 180,
      floorWidthNum: 17
    },
    4: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2],
        [1, 1, 1, 1, 1, 0, 3, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [300, 300, 300, 300],
      floorWidthZ: 450,
      floorWidthNum: 17
    },
    5: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2],
        [1, 1, 1, 1, 1, 0, 3, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [300, 300, 300, 300],
      floorWidthZ: 450,
      floorWidthNum: 17
    },
    6: {
      matrix: [
        [2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2],
        [1, 1, 1, 1, 1, 0, 3, 1, 1, 1, 1, 1, 1, 1],
      ],
      publicArea: [300, 300, 300, 300],
      floorWidthZ: 450,
      floorWidthNum: 17
    },
  },
  // 12号楼
  22: {
    x: 0,
    y: 0,
    z: 0,

    // 房间盒子长宽高
    buildingX: 300,
    buildingY: 200,
    buildingZ: 550,
    // 楼梯大小
    buildingStairX: 300,
    // 空白大小
    buildingSpaceX: 100,
  },
  // end 天津微电子

  // TEST

  // start 汽车城
  // A 5
  1384: {
    1: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 3],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 3]
      ],
      publicArea: [
        700, 1200
      ],
      floorWidthNum: 21
    },
    2: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1],
      ],
      publicArea: [],
      floorWidthNum: 21
    },
    3: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1],
      ],
      publicArea: [],
      floorWidthNum: 21
    },
    4: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1],
      ],
      publicArea: [],
      floorWidthNum: 21
    },
    5: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1],
      ],
      publicArea: [],
      floorWidthNum: 21
    },
    6: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1],
      ],
      publicArea: [],
      floorWidthNum: 21
    },
    7: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1],
      ],
      publicArea: [],
      floorWidthNum: 21
    },
    8: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1],
      ],
      publicArea: [],
      floorWidthNum: 21
    },
  },
  // B 4
  1386: {
    1: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [
        700, 1200
      ],
      floorWidthNum: 28
    },
    2: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 28
    },
    3: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 28
    },
    4: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 28
    },
    5: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 28
    },
    6: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 28
    },
    7: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 28
    },
    8: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 28
    },
  },
  // C 2
  1388: {
    1: {
      // matrix: [],
      publicArea: [],
      floorWidthNum: 13
    },
    2: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 13
    },
    3: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 13
    },
    4: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 13
    },
    5: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 13
    },
    6: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 13
    },
    7: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 13
    },
    8: {
      matrix: [
        [1, 1, 2, 1, 1, 1, 1, 2, 1, 1],
        [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1]
      ],
      publicArea: [],
      floorWidthNum: 13
    },
  }
  // end 汽车城
};


export class BuildProject {
  id: number;
  buildings: Array<BuildModel>;
}

export class BuildModel {
  id: number;
  name: string;

  floor: Array<BuildFloorModel>;

  // 代表坐标系展示位置
  x = 0;
  y = 0;
  z = 0;

  // 房间盒子长宽高
  buildingX;
  buildingY = 200;
  buildingZ;
  // 楼梯大小
  buildingStairX = 700;
  // 空白大小
  buildingSpaceX = 500;
}

export class BuildFloorModel {
  floor;
  room: Array<BuildRoomModel>;
  // 房间排列矩阵
  roomMatrix: BuildingFloorMapModel;
}

export class BuildRoomModel {
  roomId: number;
  roomName: string;

  type: number;

  face: number;


  constructor(roomId: number, roomName: string) {
    this.roomId = roomId;
    this.roomName = roomName;
  }
}

export class BuildRoomDeviceModel {
  deviceId: number;
  deviceName: string;
  deviceDesc: string;
  devicePos: [];
}
