import {PageReq} from "../page/page";
import {RepairRemarkDO, RepairSchemeDO,RepairSchemeDTO} from "../workbench/workbench.model";
import {EbusinessPageResponse} from "../ebusiness/ebusiness.model";
/**
 * @description: 报修
 * @Author: kaungye
 * @Date: 2018/3/2
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-09-23 11:17:35
 */
export class RepairInfo {
  repair_id?: number; // ID
  unit_id?: any; // 单元ID
  unit_name?: string;
  room_num?: string; // 房间号
  urgency_degree?: string; //紧急程度
  repair_content?: string; // 报修内容
  contact_name?: string; // 联系人
  contact_phone?: string; // 联系电话
  remark?: string; // 备注
  repair_status?: string; // 保修状态（WAITING:待确认 EFFECTIVE:处理中 FIXED:已处理 INVALID:已拒绝 ）
  plan_fix_time?: number; // 预计处理完成时间
  reject_reason?: number; // 拒绝原因
  fix_time?: number; // 完成时间
  create_time?: number;
  last_modify_time?: number;
  ext_info?: string;
  repair_type?: string;//报修类型（工单类型：REPAIR:维修，CLEAN:保洁,MALFUNCTION:故障）
  repair_image?: RepairImage[];
  repair_images?: RepairImage[];
  reservation_start_time?: number;//预约维修开始时间
  reservation_end_time?: number;//预约维修结束时间
  creator_type?: string;//创建人类型
  create_id?: number;//创建人ID
  create_name?: string;//创建人名称
  repair_species_type?: string; //报修部位
  repair_fee?:number; // 支付金额

  constructor() {
  }

}

// 报修图片
export class RepairImage {
  repair_image_id: number; // ID',
  repair_id: number; // 报修ID
  image_url: string; // 图片地址
  create_time: number;
  last_modify_time: number;
  ext_info: string;

  constructor() {
  }
}

// 报修状态
export const REPAIR_TYPE = [
  { id: 'WAITING', name: '待确认' },
  { id: 'EFFECTIVE', name: '处理中' },
  { id: 'FIXED', name: '已处理' },
  { id: 'INVALID', name: '已拒绝' },
];

//
export class RepairInfoList {
  repair_infos: Array<RepairInfo>;
  total: number;

  constructor() {
  }
}

export class RepairOverHoursDTOList {
  repair_over_hours:Array<RepairOverHoursDTO>;
  total:number;

  constructor(){

  }

}

export class RepairCommentDO {
 score_attitude?:string;

 score_quality?:string;


}

export class RepairReturnModel {
  repair_return_memark?:string;
  repair_duty?:string;
}
export class RepairOverHoursDTO {
  repair_scheme_dto?:RepairSchemeDTO;
  repair_result_dto?: RepairSchemeDTO;
  urgency_degree?:string;
  repair_return_model?:RepairReturnModel;
  repair_comment_do?:RepairCommentDO;
  repair_scheme_do?:RepairSchemeDO;
  remark?:string;//备注
  over_name?:string;//受理人姓名
  receipt_time?:string;//受理时间
  repair_fee?:number;//金额
  repair_id?: number; // ID
  unit_id?: any; // 单元ID
  unit_name?: string;
  room_num?: string; // 房间号
  repair_content?: string; // 报修内容
  contact_name?: string; // 联系人
  contact_phone?: string; // 联系电话
  repair_remark_dolist?: Array<RepairRemarkDO>;// 备注
  repair_status?: string; // 保修状态（WAITING:待确认 EFFECTIVE:处理中 FIXED:已处理 INVALID:已拒绝 ）
  plan_fix_time?: number; // 预计处理完成时间
  reject_reason?: number; // 拒绝原因
  fix_time?: number; // 完成时间
  create_time?: number;
  last_modify_time?: number;
  ext_info?: string;
  repair_type?: string;//报修类型（工单类型：REPAIR:维修，CLEAN:保洁, MALFUNCTION:故障）
  repair_images?: Array<string>;
  reservation_start_time?: number;//预约维修开始时间
  reservation_end_time?: number;//预约维修结束时间
  creator_type?: string;//创建人类型
  create_id?: number;//创建人ID
  create_name?: string;//创建人名称
  repair_species_type?:string;
  //逾期时间
  over_hours?:number;
  //逾期类型
  over_type?:string;

  type_name?: string;

  coming_time_str?: string;

  repair_place?: string;
  worker?: any;

  clean_type_id?: number;

  before_repair_images?;
  after_repair_images?;
}

export class RepairReturnRequest {

  repair_id?:number;
   repair_duty?:string;
  repair_return_memark?:string;


}

export class RepairReturnResponse {

  repair_id?:number;
  repair_duty?:string;
  repair_return_memark?:string;
}

// 报修列表
export class RepairListReqParam {

  contact_name?: string; // 联系人
  contact_phone?: string; // 联系电话
  unit_id?: string; // 单元ID
  room_num?: string; // 单元ID
  repair_status?: string; // 保修状态（WAITING:待确认 EFFECTIVE:处理中 FIXED:已处理 INVALID:已拒绝 ）
  repair_type?: string;//报修类型（工单类型：REPAIR:维修，CLEAN:保洁,MALFUNCTION:故障）
  page_num?: number;
  page_size?: number;

  constructor() {
  }
}

export class RepairInfoReqParam {

  repair_id?: number; // 联系人

  constructor() {
  }
}

// 报修处理
export class RepairHandleReqParam {

  repair_id?: number;
  repair_change_type?: string;
  plan_fix_time?: number;
  reject_reason?: string;

  constructor() {
  }
}

// 报修处理类型
export const REPAIR_HANDLE_TYPE = {
  CONFIRM: 'CONFIRM',
  REJECT: 'REJECT',
  FINISH: 'FINISH',
};

// 报修备注
export class RepairRemarkReqParam {
  repair_id?: number;
  remark?: string;

  constructor() {
  }
}

// 报修添加
export class RepairAddReqParam {
  contact_name?: string;
  contact_phone?: string;
  unit_id?: number;
  room_num?: string;
  repair_content?: string;
  repair_images?: Array<string>;
  repair_type?: string;

  constructor() {
  }
}

export class RepairListRequest extends PageReq {
  type?: string;
  repair_param?: string;
  // @Remark("处理状态")
  repair_status_list?: Array<string>;
  cleaner?: any;
  reservation_start_time?: number;
  reservation_end_time?: number;
  urgency_degree?: string;
  clean_service_type?: string;

  time_type?: number;
}

export class CleanTaskListRequest extends PageReq {

  project_id_list?: Array<number>;

  unit_id?: number;

  room_name?: string;

  clean_status?: string;

}

export class CleanTaskListResponse extends EbusinessPageResponse {
  clean_task_list?: Array<CleanTaskDTO>;
}

export class CleanTaskDTO {

  index?: number;

  clean_id?: number;

  project_name?: string;

  contract_start?: Date;

  contract_end?: Date;

  check_in_date?: Date;

  last_clean_date?: Date;

  need_clean_date?: Date;

  unit_name?: string;

  room_name?: string;

  clean_status?: string;

  finish_clean_date?: Date;

  late_clean_date?: Date;

  remark?: string;
}

export class CleanerListRequest {
  privilegeCode?: string;
}

export class CleanServiceTypeDTO {
  id?: number;
  name?: string;
}

export class CleanServiceTypeListResponse {
  clean_service_type_dtolist?: Array<CleanServiceTypeDTO>;
}


export class CleanTaskEditRequest {
  task_id?: number;
  status?: string;
  // @Remark("完成时间")
  finish_date?: number;
  // @Remark("延期时间")
  delay_date?: number;
  // @Remark("备注")
  remark?: string;
}

export class CleanTaskImportDTO {
  id?: number;

  project_name?: string;
  project_duration?: string;
  checkin_time?: string;
  last_clean_time?: string;
  deal_time?: string;
  unit_name?: string;
  room_name?: string;

  status_str?: string;
  status?: string;

  finish_time_str?: string;
  finish_time?: number;

  delay_time_str?: string;
  delay_time?: number;

  remark?: string;

  err?: string;
}

export class CleanTaskImportPreviewResponse {
  clean_task_import_dtolist?: Array<CleanTaskImportDTO>;

  err_count?: number;
  success_count?: number;
}

export class RoomBatchImportPreviewRequest {
  clean_task_import_dtolist?: Array<CleanTaskImportDTO>;
}
