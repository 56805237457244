import { Injectable } from "@angular/core";
import { RequestService } from "../../request.service";
import { AuthService } from "../../common/auth.service";
import { environment } from "../../../../environments/environment";
import {
  WelfareManagerSaveRequest,
  WelfareManagerRequest,
  WelfareManagerRespone,
  WelfareOrderManagerRequest,
  WelfareOrderManagerRespone,
  WelfareStatusRequest
} from "../../../model/ebusiness/ebusiness.model";


@Injectable()
export class EWelfareService {

  // 获得福利列表
  private GET_WELFARE_LIST = `${environment.apiHost}eb/welfareManager/list`;

  // 获得福利订单列表
  private GET_WELFARE_ORDER_LIST = `${environment.apiHost}eb/welfareManager/order/list`;

  // 更新福利状态
  private SET_WELFARE_STATUS = `${environment.apiHost}eb/welfareManager/setStatus`;

  private SAVE_WELFARE_LIST = `${environment.apiHost}eb/welfareManager/save`;

  private DETAIL_WELFARE_LIST = `${environment.apiHost}eb/welfareManager/detail`;

  constructor(
    private auth: AuthService,
    private requestService: RequestService
  ) {

  }

  /**
   * 福利列表
   */
  getWelfareList(req?: WelfareManagerRequest): Promise<WelfareManagerRespone> {
    return this.requestService.authPost(this.GET_WELFARE_LIST, req);
  }

  /**
   * 福利订单列表
   */
  getWelfareOrderList(req?: WelfareOrderManagerRequest): Promise<WelfareOrderManagerRespone> {
    return this.requestService.authPost(this.GET_WELFARE_ORDER_LIST, req);
  }

  /*
     * 福利保存
     */
  save(req?: WelfareManagerSaveRequest): Promise<any> {
    return this.requestService.authPost(this.SAVE_WELFARE_LIST, req);
  }

  /**
   * 更新福利状态
   */
  postUpdateStatus(req: WelfareStatusRequest): Promise<any> {
    return this.requestService.authPost(this.SET_WELFARE_STATUS, req);
  }

  /**
   * 福利详情
   */
  detail(req: any): Promise<any> {
    return this.requestService.authGet(this.DETAIL_WELFARE_LIST, req);
  }
}
