import { Component, ViewChild } from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html'
})
export class LoadingModalComponent {

  @ViewChild('loadingModal', { static: true }) public loadingModal: ModalDirective;

  constructor() {
  }

  showModal(): void {
    this.loadingModal.show();
  }

  hide(): void {
    this.loadingModal.hide();
  }
}
