import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';
import {ComponentRef, Injectable} from '@angular/core';

interface IRouteConfigData {
  reuse: boolean;
}

interface ICachedRoute {
  handle: DetachedRouteHandle;
  data: IRouteConfigData;
}


/**
 * 路由重用策略
 *
 * 替换一旦发生, 就会有某些组件要被丢弃 destroy, 这时 shouldDetech, store 就会被调用, 用于缓存这些已经渲染完成即将被丢弃的组件.
 * 有组件被丢弃自然有组件需要进来替补, 而这时 shouldAttach,retrieve 就会被调用, 用来调出缓存的组件.
 * 1. 是否替换-> 2. 替换发生, 有组件离去, 有组件加入 -> 3. 离去的组件, 我们可以缓存 -> 4. 加入的组件, 使用缓存好的组件. -> 替换->缓存->重用
 */
@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {

  private static routeCache = new Map<string, ICachedRoute>();

  private static waitDelete: string; // 当前页未进行存储时需要删除
  private static currentDelete: string;  // 当前页存储过时需要删除



  /**
   * (1) 进入路由触发，判断是否是同一路由 (不是同一路由需要获取缓存)
   * future上一节点  curr 当前节点
   */
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // return future.routeConfig === curr.routeConfig;
    // 根据路由和参数判断是否不同
    let newRoute = future.routeConfig === curr.routeConfig
      && JSON.stringify(future.params) == JSON.stringify(curr.params);

    if (newRoute) {
      // queryParams 有无 会进入两次 当做一次
      if (JSON.stringify(future.queryParams) != JSON.stringify(curr.queryParams)) {
        if (Object.keys(future.queryParams).length != 0 && Object.keys(curr.queryParams).length != 0) {
          newRoute = false;
        }
      }
    }

    return newRoute;
  }

  /**
   *  (2) 从缓存中获取快照，若无则返回null (是否有缓存)
   *  如果返回的是null，那么当前路由对应的组件会实例化
   */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    // url 完整路径作为key (加上参数)
    const key = this.getRouteKey(route);
    const data = this.getRouteData(route);
    let handle = data && data.reuse && CustomReuseStrategy.routeCache.has(key)
      ? CustomReuseStrategy.routeCache.get(key).handle
      : null;
    return handle;
  }

  /**
   * (3) 表示进入时是否对路由恢复(无需判断)
   * true 复用 false 不复用 | shouldReuseRoute() = false 时执行
   * 如果你有路由不想利用可以在这加一些业务逻辑判断，这里判断是否有data数据判断是否复用
   * shouldDetach是对上一路由的数据是否实现拆离，
   * 其调用开始是当前层级路由不需要复用的时候，即shouldReuseRoute()返回false的时候，
   * 如果这时候反回false，将继续到上一路由的下一层级调用shouldDetach,直到返回true或者是最末级路由后才结束对shouldDetach的调用，
   * 当返回true时就调用一次store 方法
   */
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // 获取路由是否有data属性
    const data = this.getRouteData(route);
    // 是否复用 reuse
    if (data && data.reuse) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * (4) 当shouldDetach()=true时触发（有缓存路由时）
   * 按path作为key存储路由快照&组件当前实例对象
   */
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const key = this.getRouteKey(route);
    const data = this.getRouteData(route);
    // CustomReuseStrategy.routeCache.set(key, {handle, data});
    if (CustomReuseStrategy.waitDelete && CustomReuseStrategy.waitDelete === key) {
      // 如果待删除是当前路由，且未存储过则不存储快照
      CustomReuseStrategy.waitDelete = null;
      if (handle) {
        let thisComponent = handle as { componentRef: ComponentRef<any> };
        thisComponent.componentRef.destroy()
      }
      return null;
    } else {
      // 如果待删除是当前路由，且存储过则不存储快照
      if (CustomReuseStrategy.currentDelete && CustomReuseStrategy.currentDelete === key) {
        CustomReuseStrategy.currentDelete = null;
        if (handle) {
          let thisComponent = handle as { componentRef: ComponentRef<any> };
          thisComponent.componentRef.destroy()
        }
        return null;
      } else {

        $("body").trigger("hide_company")

        // 缓存
        CustomReuseStrategy.routeCache.set(key, {handle, data});

        this.addRedirectsRecursively(route);
      }
    }
  }


  /**
   * (5) shouldReuseRoute()=false (同一个路由)
   * 若 path 在缓存中有的都认为允许还原路由
   */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const key = this.getRouteKey(route);
    const data = this.getRouteData(route);
    return data && data.reuse && CustomReuseStrategy.routeCache.has(key);
  }


  private addRedirectsRecursively(route: ActivatedRouteSnapshot): void {
    const config = route.routeConfig;
    if (config) {
      if (!config.loadChildren) {
        const routeFirstChild = route.firstChild;
        const routeFirstChildUrl = routeFirstChild ? this.getRouteUrlPaths(routeFirstChild).join('/') : '';
        const childConfigs = config.children;
        if (childConfigs) {
          const childConfigWithRedirect = childConfigs.find(c => c.path === '' && !!c.redirectTo);
          if (childConfigWithRedirect) {
            childConfigWithRedirect.redirectTo = routeFirstChildUrl;
          }
        }
      }
      route.children.forEach(childRoute => this.addRedirectsRecursively(childRoute));
    }
  }

  // 获取路由完整路径
  private getRouteKey(route: ActivatedRouteSnapshot): string {
    // return this.getFullRouteUrlPaths(route).filter(Boolean).join('/').replace('/', '_');

    // // 用于区分延迟加载时的模块路径，以及默认子组件与父组件

    let url, queryParams;
    url = queryParams = "";
    if (route.queryParams && Object.keys(route.queryParams).length > 0) {
      queryParams = JSON.stringify(route.queryParams)
    }

    url = route['_routerState'].url;
    // 去除？(参数)
    if (url.indexOf("?") >= 0) {
      url = url.substring(0, url.indexOf("?"));
    }

    url = url + "|" + queryParams;
    return url;
  }

  // private getFullRouteUrlPaths(route: ActivatedRouteSnapshot): string[] {
  //   const paths = this.getRouteUrlPaths(route);
  //   return route.parent ? [...this.getFullRouteUrlPaths(route.parent), ...paths] : paths;
  // }

  // 获取路由url
  private getRouteUrlPaths(route: ActivatedRouteSnapshot): string[] {
    return route.url.map(urlSegment => urlSegment.path);
  }

  // 获取路由data属性配置
  private getRouteData(route: ActivatedRouteSnapshot): IRouteConfigData {
    return route.routeConfig && route.routeConfig.data as IRouteConfigData;
  }

  /** 用于删除路由快照*/
  public static deleteRouteSnapshot(tabKey: string): void {
    if (CustomReuseStrategy.routeCache.has(tabKey)) {

      //this is important to work around memory leaks, as Angular will never destroy the Component
      let oldCache = CustomReuseStrategy.routeCache.get(tabKey);
      if (oldCache) {
        let oldHandle = oldCache.handle as { componentRef: ComponentRef<any> };
        if (oldHandle) {
          oldHandle.componentRef.destroy();
        }
      }
      CustomReuseStrategy.routeCache.delete(tabKey);
      CustomReuseStrategy.currentDelete = tabKey;
    } else {
      CustomReuseStrategy.waitDelete = tabKey;
    }
  }


}
