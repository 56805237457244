import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RequestService } from '../request.service';
import { AvaliableCarportListReq, AvaliableCarportListResp, CarportBillPayLogListResponse, CarportLeaseBillDetailParam, CarportLeaseBillDTO, CarportLeaseBillListParam, CarportLeaseBillListResponse } from '../../model/carport/carport.modal';
import { PayBillListResponse, PayLogDTO } from "../../model/finance/bill.model";

@Injectable()
export class CarportService {

  //可用车库列表
  private AVALIABLE_CARPORT_LIST = `${environment.apiHost}carport/manage/avaliable/list`;
  //车库租约账单列表
  private LEASE_BILL_LIST_URL = `${environment.apiHost}carportbill/bill/list`;
  //车库支付流水列表
  private PAY_LOG_LIST_URL = `${environment.apiHost}carportbill/paylog/list`;
  //账单详情
  private LEASE_BILL_DETAIL_URL = `${environment.apiHost}carportbill/bill/detail`;
  //账单财务明细
  private LEASE_BILL_PAY_LOG_LIST_URL = `${environment.apiHost}carportbill/bill/detaillist`;
  //支付流水详情
  private PAY_LOG_DETAIL_URL = `${environment.apiHost}carportbill/paylog/detail`;
  //支付账单明细 列表
  private PAY_LOG_DETAIL_LIST_URL = `${environment.apiHost}carportbill/paylog/detaillist`;
  //根据租约ID或者合同编号获取车库列表
  private LEASE_CARPORT_LIST = `${environment.apiHost}carport/lease/carport/list`;
  //获取收款窗口的账单列表
  private GATHERING_BILL_DETAIL = `${environment.apiHost}carportbill/gatheringBill/detail`;
  //确认收款
  private CONFIRM_GATHERING_BILL = `${environment.apiHost}carportbill/gatheringBill/confirm`;
  // 车库新增
  private CARPORT_MANAGE_INCREASED = `${environment.apiHost}carport/manage/increased`;
  // 车库租约列表
  private CARPORT_LEASE_LIST = `${environment.apiHost}carport/lease/list`;
  // 车库搜索列表
  private CARPORT_MANAGE_LIST = `${environment.apiHost}carport/manage/list`;
  // 车库上下架
  private CARPORT_PUTAWAY_SOLDOUT = `${environment.apiHost}carport/manage/putaway/soldout`;


  constructor(private requestService: RequestService) {
  }

  /**
   * @Description: 获取可用的车位列表
   * @Author: 陆炳彬
   * @Date: 2018/12/6 下午2:15
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/12/6 下午2:15
   */
  getAvaliableCarportList(req: AvaliableCarportListReq): Promise<AvaliableCarportListResp> {
    return this.requestService.authPost(this.AVALIABLE_CARPORT_LIST, req);
  }

  //租约账单列表
  getLeaseBillList(carportLeaseBillListParam: CarportLeaseBillListParam): Promise<CarportLeaseBillListResponse> {
    return this.requestService.authGet(this.LEASE_BILL_LIST_URL, carportLeaseBillListParam);
  }

  //支付流水列表
  getPayLogList(carportLeaseBillListParam: CarportLeaseBillListParam): Promise<CarportLeaseBillListResponse> {
    return this.requestService.authGet(this.PAY_LOG_LIST_URL, carportLeaseBillListParam);
  }

  //账单详情
  getleaseBillDetail(carportLeaseBillDetailParam: CarportLeaseBillDetailParam): Promise<CarportLeaseBillDTO> {
    return this.requestService.authGet(this.LEASE_BILL_DETAIL_URL, carportLeaseBillDetailParam);
  }

  //账单财务明细
  getBillPayLogList(carportLeaseBillDetailParam: CarportLeaseBillDetailParam): Promise<CarportBillPayLogListResponse> {
    return this.requestService.authGet(this.LEASE_BILL_PAY_LOG_LIST_URL, carportLeaseBillDetailParam);
  }

  //支付流水详情
  getPaylogDetail(carportLeaseBillDetailParam: CarportLeaseBillDetailParam): Promise<PayLogDTO> {
    return this.requestService.authGet(this.PAY_LOG_DETAIL_URL, carportLeaseBillDetailParam);
  }

  //支付账单明细 列表
  getPaylogDetailList(carportLeaseBillDetailParam: CarportLeaseBillDetailParam): Promise<PayBillListResponse> {
    return this.requestService.authGet(this.PAY_LOG_DETAIL_LIST_URL, carportLeaseBillDetailParam);
  }

  // 新增车库
  addCarportManageIncreased(requestData: any): Promise<any> {
    return this.requestService.authPost(this.CARPORT_MANAGE_INCREASED, requestData);
  }

  // 车库租约列表
  findCarportLeaseList(requestData: any): Promise<any> {
    return this.requestService.authGet(this.CARPORT_LEASE_LIST, requestData);
  }
  findCarportLeaseListWithLoading(requestData: any): Promise<any> {
    return this.requestService.authGet(this.CARPORT_LEASE_LIST, requestData, true);
  }

  //根据租约ID或者合同编号获取车库列表
  getLeaseCarports(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.LEASE_CARPORT_LIST, requestData);
  }

  //获取收款窗口的账单列表
  gatheringBillDetail(confirmPaymentRequest: any): any {
    return this.requestService.authGet(this.GATHERING_BILL_DETAIL, confirmPaymentRequest);
  }

  //确认收款
  gatheringBillConfirm(confirmPaymentRequest: any): Promise<any> {
    return this.requestService.authPost(this.CONFIRM_GATHERING_BILL, confirmPaymentRequest);
  }

  // 车库搜索列表
  findCarportManageList(requestData: any): Promise<any> {
    return this.requestService.authGet(this.CARPORT_MANAGE_LIST, requestData);
  }
  findCarportManageListWithLoading(requestData: any): Promise<any> {
    return this.requestService.authGet(this.CARPORT_MANAGE_LIST, requestData, true);
  }

  // 车库搜索列表
  updateCarportPutawaySoldout(requestData: any): Promise<any> {
    return this.requestService.authPost(this.CARPORT_PUTAWAY_SOLDOUT, requestData);
  }


}
