/**
 * @description:功能描述
 *
 * @Author: yinqiang
 * @Date: 2018-03-31 13:37:48
 * @Last Modified by: yinqiang
 * @Last Modified time: 2018-05-21 15:56:38
 */

export const environment = {
    production: true,
    apiHost: 'http://192.168.1.16/api/',
    // 日志级别 1：debug 2：info 3：war 10:error 11:exception
    logLevel: 1
  };

