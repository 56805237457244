<div class="mt-2 mx-md-2">
  <div class="col-12 scroll">
    <div class="row" *ngIf="approvalResultResponse">
      <ng-container *ngFor="let item of approvalResultResponse.approv_content_dtolist;let i=index">
        <div class="col-12 mt-2" *ngIf="i!==0">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2"><span class="font-weight-bold">审批人</span>：
              <ng-container *ngIf="item.approver_name && item.approv_result!=='cancel'">
                {{item.approver_name}}
              </ng-container>
              <ng-container *ngIf="!item.approver_name || item.approv_result==='cancel'">
                --
              </ng-container>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2"><span
              class="font-weight-bold">审批人岗位</span>：{{item.approver_position}}</div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2"><span
              class="font-weight-bold">审批时间</span>：{{item.approv_time|date:'yyyy-MM-dd HH:mm:ss'}}</div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2"><span
              class="font-weight-bold">审批耗时</span>：{{calTime(item.take_up_time)}}
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2"><span class="font-weight-bold">审批意见</span>：
              <button class="btn btn-info mx-2" *ngIf="item.approv_result==='true'"><i
                class="fa fa-check-circle-o"></i> 同意
              </button>
              <button class="btn btn-danger mx-2" *ngIf="item.approv_result==='false'"><i
                class="fa fa-times-circle-o"></i> 拒绝
              </button>
              <button class="btn btn-danger mx-2" *ngIf="item.approv_result==='cancel'">已取消</button>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2" *ngIf="item.approv_result==='cancel'">
              <span class="font-weight-bold">取消人</span>：
              {{item.approver_name}}
            </div>
            <div class="col-12 mt-2" *ngIf="item.approv_result==='false'">
              <span class="font-weight-bold">拒绝原因</span>：
              {{item.reject_reason}}
            </div>

          </div>
          <hr>
        </div>
      </ng-container>
    </div>
  </div>
</div>
