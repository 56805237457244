import { Injectable, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";
import { RequestService } from "../request.service";
import { AccountingCheckAddRequest, AccountingCheckDetailResponse, AccountingCheckListRequest, AccountingCheckListResponse } from '../../model/audit/accounting.model';

/**
 * @Description: 核销service
 * @Author: ky
 * @Date: 2018/6/7 下午5:23
 * @Last Modified by: ky
 * @Last Modified time: 2018/6/7 下午5:23
 */
@Injectable()
export class AccountingService implements OnInit {

  // 核销列表
  private ACCOUNTING_LIST = `${environment.apiHost}audit/accounting/list`;
  // 核销入账
  private ACCOUNTING_ADD = `${environment.apiHost}audit/accounting/add`;
  // 核销详情
  private ACCOUNTING_DETAIL = `${environment.apiHost}audit/accounting/detail`;
  // 核销详情
  private ACCOUNTING_CHARGEOF = `${environment.apiHost}audit/accounting/chargeof`;
  // 核销详情
  private ACCOUNTING_BALANCE = `${environment.apiHost}audit/accounting/balance`;

  constructor(private requestService: RequestService) {
  }

  ngOnInit(): void {

  }

  /**
   * @Description: 核销
   * @Author: ky
   * @Date: 2018/6/7 下午5:25
   */
  getListByPage(params: AccountingCheckListRequest): Promise<AccountingCheckListResponse> {
    return this.requestService.authGet(this.ACCOUNTING_LIST, params);
  }

  /**
   * @Description: 核销
   * @Author: ky
   * @Date: 2018/6/7 下午5:25
   */
  add(params: AccountingCheckAddRequest): Promise<any> {
    return this.requestService.authPost(this.ACCOUNTING_ADD, params);
  }

  getDetail(id: number): Promise<AccountingCheckDetailResponse> {
    return this.requestService.authGet(this.ACCOUNTING_DETAIL, { check_id: id });
  }

  chargeOf(ids: Array<number>): Promise<AccountingCheckDetailResponse> {
    return this.requestService.authPost(this.ACCOUNTING_CHARGEOF, { check_id_list: ids });
  }

  balance(ids: Array<number>): Promise<AccountingCheckDetailResponse> {
    return this.requestService.authPost(this.ACCOUNTING_BALANCE, { check_id_list: ids });
  }

}
