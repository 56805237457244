import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {RequestService} from "../request.service";
import {
  ProjectAreaEditRequest, ShopEditInfoRequest, ShopListPageRequest,
  ShopTreeQueryRequest
} from "../../model/shop/shop-info-list.model";
import {AuthService} from "../common/auth.service";

@Injectable()
export class ShopManagerService {

  private SHOP_INFO_PAGE_LIST = `${environment.apiHost}shopInfo/pageList`;

  private PROJECT_SHOP_AREA_SET = `${environment.apiHost}shopInfo/project/setArea`;

  private SHOP_SAVE = `${environment.apiHost}shopInfo/saveShop`;

  private SHOP_UPDATE = `${environment.apiHost}shopInfo/updateShop`;

  private PROJECT_SURPLUS_AREA_GET = `${environment.apiHost}shopInfo/project/getSurplusArea`;

  private SHOP_TREE_LIST = `${environment.apiHost}shopInfo/shopTreeList`;

  private LIST_DOWNLOAD = `${environment.apiHost}shopInfo/list/download`;

  constructor(private requestService: RequestService,
              private authService: AuthService) {
  }

  //商铺管理列表查询
  public listShopInfoWithLoading(shopInfoListRequest: ShopListPageRequest): Promise<any> {
    return this.requestService.authGet(this.SHOP_INFO_PAGE_LIST, shopInfoListRequest);
  }

  //项目社区建筑面积设置
  public setProjectAreaInfo(projectAreaEditRequest: ProjectAreaEditRequest): Promise<any> {
    return this.requestService.authPost(this.PROJECT_SHOP_AREA_SET, projectAreaEditRequest);
  }

  //新增商铺
  public saveShopInfo(shopEditInfoRequest: ShopEditInfoRequest): Promise<any> {
    return this.requestService.authPost(this.SHOP_SAVE, shopEditInfoRequest);
  }

  //修改商铺
  public updateShopInfo(shopEditInfoRequest: ShopEditInfoRequest): Promise<any> {
    return this.requestService.authPost(this.SHOP_UPDATE, shopEditInfoRequest);
  }

  //获取项目剩余可用建筑面积
  public getProjectSurplusArea(): Promise<any> {
    return this.requestService.authGet(this.PROJECT_SURPLUS_AREA_GET);
  }

  public getShopTreeList(shopTreeQueryRequest: ShopTreeQueryRequest): Promise<any> {
    return this.requestService.authGet(this.SHOP_TREE_LIST,shopTreeQueryRequest);
  }

  // 下载商铺列表
  public downloadShopList(request: ShopListPageRequest){
    let param = "";
    for (let key in request) {
      if (request[key] && request.hasOwnProperty(key)) {
        param += (key + '=' + request[key] + '&');
      }
    }

    if (param) {
      window.open(this.LIST_DOWNLOAD + "?" + param + 'Authorization=' + this.authService.getAuthToken(), "_blank");
    } else {
      window.open(this.LIST_DOWNLOAD + '?Authorization=' + this.authService.getAuthToken(), "_blank");
    }
  }
}
