import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RoomService } from '../../../../service/room/room.service';
import { WorkService } from '../../../../service/common/work.service';
import { LeaseService } from '../../../../service/lease/lease.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../../service/common/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkModel } from '../../../../model/common/common.model';
import { PdfViewComponent } from '../../../shared/pdf-view/pdf-view.component';

@Component({
  selector: 'app-cr-change-room-checkout-inventory-view',
  templateUrl: './cr-change-room-checkout-inventory-view.component.html',
  styleUrls: ['./cr-change-room-checkout-inventory-view.component.css']
})
export class CrChangeRoomCheckoutInventoryViewComponent implements OnInit {

  @ViewChild(PdfViewComponent, { static: true }) pdfView: PdfViewComponent;
  path;

  lease_id;
  leaseroomid;
  record_id: number;

  type: string;

  constructor(private roomService: RoomService,
              private workService: WorkService,
              private leaseService: LeaseService,
              private http: HttpClient,
              private authService: AuthService,
              private sanitizer: DomSanitizer,
              private router: Router,
              private activatedRoute: ActivatedRoute,) {
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(param => {
      this.lease_id = param['lease_id'];
      this.leaseroomid = param['lease_room_id'];
      this.record_id = param['record_id'];

      this.leaseService.getContractUrl({
        lease_id: this.lease_id,
        service_type: 'CHECKOUT_CONNECT',
        object_id: this.leaseroomid
      })
        .then(data => {
          if (data.image_url) {
            this.path = data.image_url;
          } else {
            this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '获取pdf异常'));
          }
        });
    });

  }

  /**
   * 打印
   */
  printFrame() {
    this.pdfView.print();
  }

  goBack() {
    this.router.navigateByUrl('/system_manage/signed/change/room/confirm/' + this.record_id);
  }
}
