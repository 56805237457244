import {Pipe, PipeTransform} from "@angular/core";

/**
 * @Description: 递增类型
 * @Author: 周嘉豪
 * @Date: 2018/05/07 上午10:26
 * @Last Modified by: 周嘉豪
 * @Last Modified time: 2018/3/29 上午10:26
 */
@Pipe({
  name: 'roomIncreaseTypePipe'
})
export class RoomIncreaseTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const status = {
      PER_YEAR: '每年',
      PER_HALF_YEAR: '每半年',
      PER_SEASON: '每季度',
      PER_MONTH: '每月',
    };
    return status[value];
  }
}
