import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DoorLockReqParam } from '../../view/lock/smart/smart.component';
import { RequestService } from '../request.service';

/**
 * 门锁服务
 */
@Injectable()
export class LockService {

  // 获取门锁列表
  private ROOM_DOOR_LOCK_LIST = `${environment.apiHost}manage/doorLock/list`;

  //获取开始记录
  private UNLOCK_RECORD = `${environment.apiHost}manage/doorLock/record`;

  constructor(private requestService: RequestService) {
  }

  public getRoomDoorLockList(doorLockReqParam: DoorLockReqParam): Promise<any> {
    return this.requestService.authPost(this.ROOM_DOOR_LOCK_LIST, doorLockReqParam);
  }

  public getUnlockRecord(doorLockReqParam: DoorLockReqParam): Promise<any> {
    return this.requestService.authPost(this.UNLOCK_RECORD, doorLockReqParam);
  }
}
