import {Component, OnInit, ViewChild} from '@angular/core';
import {SignedService} from '../../../../service/signed/signed.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CheckoutRoomAffirmRequest, CheckoutRoomDTO, CheckoutViewResponse} from '../../../../model/signed/checkout.model';

import {WorkModel} from '../../../../model/common/common.model';
import {DialogComponent} from '../../../shared/dialog/dialog.component';
import {WorkService} from '../../../../service/common/work.service';
import {ModalDirective} from "ngx-bootstrap/modal";
import {TabletComponent} from "../../tablet/tablet.component";
import {CheckOutListOperate, SignDataRequest, SignOnIpadRequest} from "../../../../model/signed/sign.model";
import {SignDeviceBindingComponent} from "../../../sign-device-binding/sign-device-binding.component";
import Decimal from "decimal.js";

@Component({
  selector: 'app-delay-refund-view',
  templateUrl: './delay-refund-view.component.html',
  styleUrls: ['./delay-refund-view.component.css']
})

export class DelayRefundView implements OnInit {

  now: Date = new Date();

  show = true;

  req: any = {};
  checkoutViewResponse: CheckoutViewResponse;

  noBill = false;

  // 收款
  @ViewChild(DialogComponent, { static: true }) dialogComponent: DialogComponent;

  confirmReq: CheckoutRoomAffirmRequest = new CheckoutRoomAffirmRequest();

  @ViewChild("staticModal", { static: true }) staticModal: ModalDirective;
  @ViewChild(SignDeviceBindingComponent, { static: true }) signDeviceBindingComponent: SignDeviceBindingComponent;

  total;

  totalTag = 0;

  refundType: any;
  status: boolean = true;

  // 签字版
  @ViewChild(TabletComponent, { static: true }) tabletComponent: TabletComponent;

  signImg;

  constructor(private signedService: SignedService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private workService: WorkService) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.req.refund_id = queryParams.id;
      this.req.checkout_finance_type_enum = queryParams.type;
      // this.refundType = queryParams.type;
      // if(this.refundType === 'REFUND') {
      //   this.confirmReq.checkout_finance_type_enum = 'REFUND'
      // } else {
      //   this.confirmReq.checkout_finance_type_enum = 'TRANSFER_IN'
      // }
      this.confirmReq.checkout_finance_type_enum = queryParams.type
      this.refresh()
    });
  }

  getAmount() {
    this.dialogComponent.gatherCheckOutAmountWithPrepay(
      this.confirmReq.lease_id,
      this.checkoutViewResponse.checkout_room_dtolist.map(e => e.room_id),
      null,
      this.checkoutViewResponse.check_out_room_info_dto.bill_ids,
      this.checkoutViewResponse.check_out_room_info_dto.amount_total,
      'CHECKOUT',
      this.checkoutViewResponse.lease_info.contract_num
    );

  }

  refresh() {
    this.signedService.getRefundDelayView(this.req).then(data => {
      this.checkoutViewResponse = data;

      this.total = new Decimal(this.checkoutViewResponse.check_out_room_info_dto.amount_total);
      this.totalTag = this.total.comparedTo(0);

    });
  }

  confirmIncome() {
    let params = new CheckoutRoomAffirmRequest();
    params.checkout_finance_type_enum = this.confirmReq.checkout_finance_type_enum;
    params.refund_id = this.req.refund_id;
    params.lease_id = this.checkoutViewResponse.lease_info.lease_id;
    params.sign_img = this.signImg;
    this.signedService.refundDelayConfirm(params).then(res => {
      this.staticModal.show();
    })
  }

  confirmOut() {
    if (!this.status) {
      let params = new CheckoutRoomAffirmRequest();

      params.checkout_finance_type_enum = this.confirmReq.checkout_finance_type_enum;
      params.checkout_bill_pay_type_enum = this.confirmReq.checkout_bill_pay_type_enum;

      params.refund_id = this.req.refund_id;
      params.lease_id = this.checkoutViewResponse.lease_info.lease_id;
      params.sign_img = this.signImg;
      if (this.confirmReq.refund_name && this.confirmReq.open_bank && this.confirmReq.bank_no) {
        params.refund_name = this.confirmReq.refund_name;
        params.open_bank = this.confirmReq.open_bank;
        params.bank_no = this.confirmReq.bank_no;
      }
      this.signedService.refundDelayConfirm(params).then(res => {
        this.staticModal.show();
      })
    }
  }

  billOwn(): number {
    if (this.totalTag > 0 && this.checkoutViewResponse && this.checkoutViewResponse.check_out_room_info_dto && this.checkoutViewResponse.check_out_room_info_dto.bill_ids) {
      return this.checkoutViewResponse.check_out_room_info_dto.bill_ids.length;
    } else {
      return 0;
    }
  }

  goLease() {

    this.workService.event_bus.emit(new WorkModel(this.workService.type.TAB_CLOSE_SELF));

    this.router.navigateByUrl(`system_manage/workspace/workbench`);
  }

  sumCouponTotal(list: Array<CheckoutRoomDTO>, type?) {
    let total = new Decimal("0");
    if (list != null && list.length > 0) {
      for (let info of list) {
        if (info.check_out_room_info_dto != null && info.check_out_room_info_dto.recycle_items != null
          && info.check_out_room_info_dto.recycle_items.length > 0) {
          for (let coupon of info.check_out_room_info_dto.recycle_items) {
            if (!type || (type && type == coupon.object_name)) {
              total = total.add(new Decimal(coupon.object_value + ""));
            }
          }
        }
      }
    }
    return +(total.toString());
  }


  sign() {
    //  签名
    if (this.isPad()) {
      this.tabletComponent.tabletShow();
    }
  }

  commitSign(imgStr: string) {
    this.signImg = imgStr;
  }

  // 在ipad上签名
  signOnIpad() {
    let request = new SignOnIpadRequest();
    request.sign_router_link_type = 'DELAY_CHECKOUT_LIST';
    request.router_link = this.router.url;
    request.response_data = this.checkoutViewResponse;
    request.computer_no = localStorage.getItem('PC_UUID');
    let signDataRequest = new SignDataRequest();
    signDataRequest.lease_id = this.checkoutViewResponse.lease_info.lease_id;
    signDataRequest.checkout_view_dtolist = this.checkoutViewResponse.checkout_room_dtolist;
    request.sign_data_request = signDataRequest;
    let operate = new CheckOutListOperate();
    operate.noBill = this.noBill;
    operate.not_receivable_remark = this.confirmReq.remark;
    operate.checkout_bill_pay_type_enum = this.confirmReq.checkout_bill_pay_type_enum;
    operate.refund_name = this.confirmReq.refund_name;
    operate.open_bank = this.confirmReq.open_bank;
    operate.bank_no = this.confirmReq.bank_no;
    // 添加延期退房
    operate.checkout_finance_type_enum = this.confirmReq.checkout_finance_type_enum;
    operate.refund_id = this.req.refund_id;
    request.operate_data = operate;
    // 打开连接弹窗
    this.signDeviceBindingComponent.openModel(request, 1);
  }

  // 判断当前设备是否是ipad
  isPad(): boolean {
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone",
      "SymbianOS", "Windows Phone",
      "iPad", "iPod"];
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        return true;
      }
    }
    return false;
  }

  changeInput() {
    if (this.confirmReq.refund_name && this.confirmReq.open_bank && this.confirmReq.bank_no) {
      this.status = false
    } else {
      this.status = true
    }
  }

  radioType() {
    if (this.confirmReq.checkout_bill_pay_type_enum == 'CASH') {
      this.status = false
    } else {
      if (this.confirmReq.refund_name && this.confirmReq.open_bank && this.confirmReq.bank_no) {
        this.status = false
      } else {
        this.status = true
      }
    }
  }
}
