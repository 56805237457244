<!-- 物品交接单打印预览 -->
<!-- {{setMada(inventoryModal)}} -->
<div class="modal fade" #inventoryModal="bs-modal" bsModal tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>物品交接单</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="inventoryModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-left mb10">房间号：{{checkinRoomInventoryResponse?.room_info?.room_name}}</p>
        <div class="row">
          <div class="col-12 col-md-6 mb10">
            <p class="w90px hei32">水表读数：</p>
            <ng-container
              *ngIf="checkinRoomInventoryResponse.water_dev || checkinRoomInventoryResponse.need_water == 'YES'">
              <input readonly="readonly" [(ngModel)]="checkinRoomInventoryResponse.water_reading" type="text"
                     class="w100px mr10">
            </ng-container>
            <span style="color: #e63e00 !important;"
                  *ngIf="!checkinRoomInventoryResponse.water_dev && checkinRoomInventoryResponse?.need_water != 'YES'"
                  class="font12 hei32 text-muted d-inline-block">此房型设置了不需要抄表收水费</span>
          </div>
          <div class="col-12 col-md-6 mb10">
            <p class="w90px hei32">电表读数：</p>
            <ng-container
              *ngIf="checkinRoomInventoryResponse.electricity_dev || checkinRoomInventoryResponse.need_electricity == 'YES'">
              <input readonly="readonly" type="text" [(ngModel)]="checkinRoomInventoryResponse.electricity_reading"
                     class="w100px mr10">
            </ng-container>
            <span style="color: #e63e00 !important;"
                  *ngIf="!checkinRoomInventoryResponse.electricity_dev && checkinRoomInventoryResponse?.need_electricity != 'YES'"
                  class="font12 hei32 text-muted d-inline-block">此房型设置了不需要抄表收电费</span>
          </div>
        </div>
        <p class="mb10">房间物品清单</p>
        <div class="table-box mb40">
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th>名称</th>
              <th>品牌</th>
              <th>型号</th>
              <th>价格</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let info of checkinRoomInventoryResponse.inventory_list">
              <td>{{info?.inventory_name}}</td>
              <td>{{info?.inventory_brand}}</td>
              <td>{{info?.inventory_type}}</td>
              <td>￥{{info?.price}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
