<!-- {{initSomething(logModal)}} -->

<!-- 操作日志 -->
<div class="modal fade" bsModal #logModal="bs-modal" tabindex="-1" role="dialog"
     [config]="{backdrop: 'static'}"
     aria-labelledby="dialog-static-name"
     style="z-index: 999999;background: rgba(0,0,0,0.4);">

  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作日志</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-box">
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th>操作人</th>
              <th>时间</th>
              <th>备注</th>
            </tr>
            </thead>
            <tbody>

            <ng-container *ngIf="!log_list || log_list.length === 0">
              <tr>
                <td colspan="3" class="text-center">暂无数据</td>
              </tr>
            </ng-container>

            <ng-container *ngIf="log_list && log_list.length > 0">
              <tr *ngFor="let log of log_list">
                <td>{{log.operation_peoson}}</td>
                <td>{{log.create_time | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
                <td class="new-lines">
                  {{log.operation_content}}
                </td>
              </tr>

            </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
