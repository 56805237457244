import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {RequestService} from "../request.service";
import {AuthService} from "../common/auth.service";
import {
  AccountCostTypeResponse,
  AccountInfoDetailResponse,
  AccountInfoIdRequest,
  AccountRelativeRoomRequest,
  AccountRelativeRoomResponse,
  ChangeLogListRequest,
  ChangeLogListResponse,
  ClientInfoDetailRequest,
  ClientInfoDetailResponse,
  ClientInfoEditPhoneRequest,
  ClientInfoFaceListResponse,
  ClientInfoFaceRequest,
  ClientInfoListRequest,
  ClientInfoListResponse,
  ClientInfoRenterExtInfoDTO,
  ClientInfoRenterExtRequest,
  DeclarationHealthyListRequest,
  DeclarationHealthyListResponse,
  MoneyPaymentConfirmRequest,
  MoneyRefundConfirmRequest,
  MoneyTransferConfirmRequest,
  QueryAccountLeaseRequest,
  QueryAccountLeaseResponse,
  QueryAvailableBalanceRequest,
  QueryAvailableBalanceResponse,
  QueryClientInfoListResp,
  QueryContractRoomTypeRequest,
  QueryContractRoomTypeResponse,
  QueryRelativeLeaseResponse,
  QueryRelativeRequest,
  QueryRelativeRoomsResponse,
  SpecialAccountRequest,
  TransferAccountBalanceResponse,
  TransferSmsCodeRequest,
  UploadPaymentCertificateRequest,
  UploadPaymentCertificateResponse,
} from "../../model/clientinfo/client-info.model";
import {QueryClientNameRequest} from "../../model/workbench/workbench.model";

@Injectable()
export class ClientInfoService {
  // 根据姓名和证件号获取入住人信息
  private CLIENT_INFO_LIST = `${environment.apiHost}manage/clientInfo/list`;
  private CLIENT_INFO_DETAIL = `${environment.apiHost}manage/clientInfo/detail`;
  private CLIENT_INFO_EDIT_PHONE = `${environment.apiHost}manage/clientInfo/edit/phone`;
  private CLIENT_INFO_RENTER_EXTS = `${environment.apiHost}manage/clientInfo/renter/exts`;
  private CLIENT_INFO_RENTER_EXTS_EDIT = `${environment.apiHost}manage/clientInfo/edit/renter/ext`;
  private CLIENT_INFO_LIST_DOWNLOAD = `${environment.apiHost}manage/clientInfo/list/download`;
  private CLIENT_INFO_FACE_LIST = `${environment.apiHost}manage/clientInfo/face/list`;
  // 客商2期 start
  private ACCOUNT_INFO_DETAIL = `${environment.apiHost}account/money/account/info/detail`;
  private SPECIAL_ACCOUNT_INFO = `${environment.apiHost}account/money/createOrUpdate/special/account/info`;
  private QUERY_RELATIVE_LEASE = `${environment.apiHost}account/money/query/relative/lease`;
  private QUERY_ACCOUNT_COST_TYPE = `${environment.apiHost}account/money/account/costType/detail`;
  private QUERY_RELATIVE_ROOMS = `${environment.apiHost}account/money/query/relative/rooms`;
  private QUERY_CONTRACT_ROOMTYPE = `${environment.apiHost}account/money/query/contract/roomType`;
  private ACCOUNT_RELATIVE_ROOM = `${environment.apiHost}account/money/query/account/relative/room`;
  private UPLOAD_PAYMENT_CERTIFICATE = `${environment.apiHost}account/money/upload/payment/certificate`;
  private MONEY_PAYMENT_CONFIRM = `${environment.apiHost}account/money/payment/confirm`;
  private QUERY_AVAILABLE_BALANCE = `${environment.apiHost}account/money/query/available/balance`;
  private MONEY_REFUND_CONFIRM = `${environment.apiHost}account/money/refund/confirm`;
  private CHANGE_LOG_LIST = `${environment.apiHost}account/money/change/log/list`;
  private LOG_LIST_DOWNLOAD = `${environment.apiHost}account/money/change/log/list/download`;
  private TRANSFER_ACCOUNT_BALANCE = `${environment.apiHost}account/money/query/transfer/account/balance`;
  private AUDIT_USER_LIST = `${environment.apiHost}account/money/transfer/audit/user/list`;
  private TRANSFER_SMS_CODE = `${environment.apiHost}account/money/transfer/sms/code`;
  private MONEY_TRANSFER_CONFIRM = `${environment.apiHost}account/money/transfer/confirm`;
  private QUERY_ACCOUNT_LEASE = `${environment.apiHost}account/money/query/account/lease`;
  private QUERY_SUBJECT_CLIENT_NAME = `${environment.apiHost}manage/clientInfo/query/subject/client/name`;
  private DECLARATION_HEALTHY_LIST = `${environment.apiHost}manage/clientInfo/declaration/healthy/list`;


  // 客商2期 end
  constructor(private requestService: RequestService,
              private authService: AuthService) {
  }

  public clientInfoList(request: ClientInfoListRequest): Promise<ClientInfoListResponse> {
    return this.requestService.authGet(this.CLIENT_INFO_LIST, request);
  }

  public clientInfoDetail(request: ClientInfoDetailRequest): Promise<ClientInfoDetailResponse> {
    return this.requestService.authGet(this.CLIENT_INFO_DETAIL, request);
  }

  public accountInfoDetail(request: AccountInfoIdRequest): Promise<AccountInfoDetailResponse> {
    return this.requestService.authGet(this.ACCOUNT_INFO_DETAIL, request);
  }

  public clientInfoEditPhone(request: ClientInfoEditPhoneRequest): Promise<any> {
    return this.requestService.authPost(this.CLIENT_INFO_EDIT_PHONE, request);
  }

  public clientInfoRenterExts(request: ClientInfoRenterExtRequest): Promise<ClientInfoRenterExtInfoDTO> {
    return this.requestService.authGet(this.CLIENT_INFO_RENTER_EXTS, request);
  }

  public clientInfoRenterExtsEdit(request: ClientInfoRenterExtRequest): Promise<ClientInfoRenterExtInfoDTO> {
    return this.requestService.authPost(this.CLIENT_INFO_RENTER_EXTS_EDIT, request);
  }

  public clientInfoFaceList(request: ClientInfoFaceRequest): Promise<ClientInfoFaceListResponse> {
    return this.requestService.authGet(this.CLIENT_INFO_FACE_LIST, request);
  }

  public downloadClientInfoList(request: ClientInfoListRequest) {
    let param = "";
    for (let key in request) {
      if (request[key] && request.hasOwnProperty(key)) {
        param += (key + '=' + request[key] + '&');
      }
    }

    if (param) {
      window.open(this.CLIENT_INFO_LIST_DOWNLOAD + "?" + param + 'Authorization=' + this.authService.getAuthToken(), "_blank");
    } else {
      window.open(this.CLIENT_INFO_LIST_DOWNLOAD + '?Authorization=' + this.authService.getAuthToken(), "_blank");
    }
  }

  public specialAccountInfo(request: SpecialAccountRequest): Promise<any> {
    return this.requestService.authPost(this.SPECIAL_ACCOUNT_INFO, request);
  }

  public queryRelativeLease(request: AccountInfoIdRequest): Promise<QueryRelativeLeaseResponse> {
    return this.requestService.authGet(this.QUERY_RELATIVE_LEASE, request);
  }

  public queryAccountCostType(): Promise<AccountCostTypeResponse> {
    return this.requestService.authGet(this.QUERY_ACCOUNT_COST_TYPE, null);
  }

  //QUERY_ACCOUNT_COST_TYPE
  public queryRelativeRooms(request: QueryRelativeRequest): Promise<QueryRelativeRoomsResponse> {
    return this.requestService.authGet(this.QUERY_RELATIVE_ROOMS, request);
  }

  public queryContractRoomType(request: QueryContractRoomTypeRequest): Promise<QueryContractRoomTypeResponse> {
    return this.requestService.authGet(this.QUERY_CONTRACT_ROOMTYPE, request);
  }

  public accountRelativeRoom(request: AccountRelativeRoomRequest): Promise<AccountRelativeRoomResponse> {
    return this.requestService.authGet(this.ACCOUNT_RELATIVE_ROOM, request);
  }

  public uploadPaymentCertificate(request: UploadPaymentCertificateRequest): Promise<UploadPaymentCertificateResponse> {
    return this.requestService.authPost(this.UPLOAD_PAYMENT_CERTIFICATE, request);
  }

  public moneyPaymentConfirm(request: MoneyPaymentConfirmRequest): Promise<any> {
    return this.requestService.authPost(this.MONEY_PAYMENT_CONFIRM, request);
  }

  public queryAvailableBalance(request: QueryAvailableBalanceRequest): Promise<QueryAvailableBalanceResponse> {
    return this.requestService.authGet(this.QUERY_AVAILABLE_BALANCE, request);
  }

  public moneyRefundConfirm(request: MoneyRefundConfirmRequest): Promise<any> {
    return this.requestService.authPost(this.MONEY_REFUND_CONFIRM, request);
  }

  public changeLogList(request: ChangeLogListRequest): Promise<ChangeLogListResponse> {
    return this.requestService.authGet(this.CHANGE_LOG_LIST, request);
  }

  public logListDownload(request: ChangeLogListRequest): Promise<any> {
    return this.requestService.authGet(this.LOG_LIST_DOWNLOAD, request);
  }

  public transferAccountBalance(request: AccountRelativeRoomRequest): Promise<TransferAccountBalanceResponse> {
    return this.requestService.authGet(this.TRANSFER_ACCOUNT_BALANCE, request);
  }

  public auditUserList(): any {
    return this.requestService.authGet(this.AUDIT_USER_LIST, null);
  }

  public transferSmsCode(request: TransferSmsCodeRequest): Promise<any> {
    return this.requestService.authGet(this.TRANSFER_SMS_CODE, request);
  }

  public moneyTransferConfirm(request: MoneyTransferConfirmRequest): Promise<any> {
    return this.requestService.authPost(this.MONEY_TRANSFER_CONFIRM, request);
  }

  public queryAccountLease(request: QueryAccountLeaseRequest): Promise<QueryAccountLeaseResponse> {//收银台的
    return this.requestService.authGet(this.QUERY_ACCOUNT_LEASE, request);
  }

  public querySubjectClientName(request: QueryClientNameRequest): Promise<QueryClientInfoListResp> {
    return this.requestService.authGet(this.QUERY_SUBJECT_CLIENT_NAME, request);
  }

  public getDeclarationHealthyDetail(request: DeclarationHealthyListRequest): Promise<DeclarationHealthyListResponse> {
    return this.requestService.authPost(this.DECLARATION_HEALTHY_LIST, request);
  }


}
