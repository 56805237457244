import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {RequestService} from "../../request.service";
import {AuthService} from "../../common/auth.service";
import {environment} from "../../../../environments/environment";
import {
  ProductDetailResponse, ProductManagerDetailRequest,
  ProductManagerRequest,
  ProductManagerResponse, ProductOrderListRequest, ProductOrderListResponse, ProductSortRequest,
  ProductStatusRequest, ProductToTopRequest
} from "../../../model/ebusiness/ebusiness.model";

@Injectable()
export class EProductService {

  // 获得商品列表
  private GET_PRODUCT_LIST = `${environment.apiHost}eb/productManager/list`;

  // 获得商品详情
  private GET_PRODUCT_DETAIL = `${environment.apiHost}eb/productManager/productDetail`;

  // 修改商品状态
  private GET_PRODUCT_ONOFF = `${environment.apiHost}eb/productManager/onOffSale`;

  // 新增商品
  private POST_INSERT_PRODUCT = `${environment.apiHost}eb/productManager/insertProduct`;

  // 编辑商品
  private POST_UPDATE_PRODUCT = `${environment.apiHost}eb/productManager/updateProduct`;

  // 商品上下移排序
  private POST_MOVE_SORT_PRODUCT = `${environment.apiHost}eb/productManager/moveUpOrDown`;

  // 商品置顶排序
  private POST_TOTOP_PRODUCT = `${environment.apiHost}eb/productManager/toTop`;

  constructor(
    private auth: AuthService,
    private requestService: RequestService
  ) {

  }

  /**
   * 商品列表
   */
  getProductList(req?: ProductManagerRequest): Promise<ProductManagerResponse> {
    return this.requestService.authGet(this.GET_PRODUCT_LIST, req);
  }

  /**
   * 商品上下移排序
   */
  moveUpAndDown(req?: ProductSortRequest): Promise<any> {
    return this.requestService.authPost(this.POST_MOVE_SORT_PRODUCT, req);
  }

  /**
   * 商品置顶排序
   */
  toTopSort(req?: ProductToTopRequest): Promise<any> {
    return this.requestService.authPost(this.POST_TOTOP_PRODUCT, req);
  }

  /**
   * 商品详情
   */
  getProductDetail(productId?: string): Promise<ProductDetailResponse> {
    return this.requestService.authGet(this.GET_PRODUCT_DETAIL, {product_id: productId});
  }

  postUpdateStatus(req: ProductStatusRequest): Promise<any> {
    return this.requestService.authGet(this.GET_PRODUCT_ONOFF, req);
  }

  postUpdateProduct(req: ProductManagerDetailRequest): Promise<any> {
    if(req.id == null){
      return this.requestService.authPost(this.POST_INSERT_PRODUCT, req);
    }
    return this.requestService.authPost(this.POST_UPDATE_PRODUCT, req);
  }


}
