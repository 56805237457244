<header class="app-header navbar ">

  <a class="navbar-brand">
    <div class="logo"></div>
    <span><img src="./././assets/img/v3/logo-symbol3.png"></span>
  </a>
  <li>
    <span class="show4">{{projectName}}</span><span class="show3">{{sub(projectName)}}...</span>
  </li>
  <ul class="nav navbar-nav ml-auto">
    <li>
      <span class="d-md-down-none" style="position: relative;letter-spacing: 0px !important;">
        <p>
          <i class="fa fa-user-circle-o show4" aria-hidden="true"></i>
            {{sysUserDTO?.user_name}},您好！
          <i class="fa fa-sort-desc show4" style="position: absolute;" aria-hidden="true"></i>
        </p>
      </span>
      <a class="fa fa-sign-out" (click)="logout()">退出</a>
    </li>
  </ul>
</header>
<div class="setbox securityCall mt36">
  <div class="card card-inverse card-warning">
    <div class="card-block" style="padding-top: 10px;">
      <div class="row">
        <div class="col-12 col-xl-51 mt-2" *ngFor="let item of person_safe_alarm_dtos">

          <div class="border rounded border-green p-2 mx-1 mh-22">

            <div class="clearfix">
              <span class="warn-tip bg-success" *ngIf="item.status=='UNPROCESSED'">
                未处理
              </span>
              <span class="warn-tip bg-warning" *ngIf="item.status=='PROCESSED'">
                处理中
              </span>
              <span class="warn-tip safe_end" *ngIf="item.status=='FIXED'" >
                已关闭
              </span>
            </div>

            <div class="col-12 mt-4">
              <div class="col-12 text-center">
                <img class="figure-img img-fluid " style="width: 6em;height: 8em"
                     src="{{item.head_image}}" alt="">
              </div>

              <div class="col-12" *ngIf="item.safe_type=='THID_NOT_IN'">

                <div class="text-center font-weight-bold">3日未归人员</div>

                <div class=" mt-2">{{item.room_name}} {{item.person_name}}</div>
                <div class="">{{item.person_remark}}</div>
                <div class="">离宿时间：{{item.get_out_time|date:'MM-dd HH:mm:ss'}}</div>

              </div>
              <div class="col-12" *ngIf="item.safe_type=='THID_NOT_OUT'">

                <div class="text-center font-weight-bold">3日未出人员</div>

                <div class=" mt-2">{{item.room_name}} {{item.person_name}}</div>
                <div class="">{{item.person_remark}}</div>
                <div class="">进入时间：{{item.come_in_time|date:'MM-dd HH:mm:ss'}}</div>

              </div>
              <div class="col-12" *ngIf="item.safe_type=='UN_REGISTERED'">

                <div class="text-center font-weight-bold">未登记在住人员</div>

                <div *ngIf="item.not_registered_chickin_dto">
                  <div class="font-weight-bold mt-2">{{item.not_registered_chickin_dto.total_num}}
                    次进出{{item.not_registered_chickin_dto.unit_name}}</div>
                  <div class="">进出时段：</div>
                  <div class="" *ngIf="item.not_registered_chickin_dto.come_in_time">
                    进：{{item.not_registered_chickin_dto.come_in_time}} {{item.not_registered_chickin_dto.come_in_num}}次</div>
                  <div class="" *ngIf="!item.not_registered_chickin_dto.come_in_time">
                    进：未能获取</div>
                  <div class="" *ngIf="item.not_registered_chickin_dto.get_out_time">
                    出：{{item.not_registered_chickin_dto.get_out_time}} {{item.not_registered_chickin_dto.get_out_num}}次</div>
                  <div class="" *ngIf="!item.not_registered_chickin_dto.get_out_time">
                    出：未能获取</div>
                  <div class="">
                    首次进入日期：{{item.not_registered_chickin_dto.first_come_in_time|date:'yyyy.MM.dd'}}</div>
                </div>

              </div>
              <div class="col-12" *ngIf="item.safe_type=='UNKNOW_SUSPICIOUS'">

                <div class="text-center font-weight-bold">陌生可疑人员</div>

                <div class=" font-weight-bold mt-2">频繁多次进出</div>

                <div class="scroll v2 scroll-min">
                  <ng-container *ngFor="let item1 of item.strange_suspect_dtos">
                    <ng-container *ngFor="let item2 of item1.access_dtos">
                      <div class="">
                        {{item2.access_time|date:'MM-dd HH:mm:ss'}}
                        ：{{item2.position_detail}}{{item2.type}}
                      </div>
                    </ng-container>
                  </ng-container>
                </div>

              </div>

            </div>
          </div>

          <div class="row mt-1 mx-1">
            <div class="col-6">
              <div class="btn btn-outline-primary text-center w-100" *ngIf="btn_privileges.VIEW_SECURITY_ALARM" (click)="safeWarnDetail(item)">
                详情
              </div>
            </div>
            <div class="col-6 text-center">
              <div class="btn btn-outline-primary text-center w-100" *ngIf="btn_privileges.PROCESS_SECURITY_ALARM" (click)="safeWarnHandle(item)">
                处理
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<app-safe-warn-detail (handled)="safeListHandle()"></app-safe-warn-detail>
