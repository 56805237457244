import { Component, OnInit } from '@angular/core';
import {WorkbenchService} from "../../../service/workbench/workbench.service";
import {WorkService} from "../../../service/common/work.service";
import { Params, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-data-deatil',
  templateUrl: './data-deatil.component.html',
  styleUrls: ['./data-deatil.component.css']
})
export class DataDeatilComponent implements OnInit {

  constructor(private workbenchService: WorkbenchService,
              private workService: WorkService,
              private activatedRoute: ActivatedRoute,) { }


  data: any;

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.getDataDetail(params['type'] , params['gatheringType']);
    });
  }

  // 获取表格数据
  getDataDetail(type: string, gatheringType: string) {
    this.workbenchService.getDataDetail({type: type, bill_type : gatheringType}).then(data => {
      this.data = data;
    });
  }

  // 下载
  downLoadDataDetail(type: string, bill_type: any) {
    this.workbenchService.downLoadDataDetail(type, bill_type);
  }
}
