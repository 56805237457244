/**
 * @description:自定义业务异常模型
 *
 * @Author: yinqiang
 * @Date: 2018-02-24 18:21:16
 * @Last Modified by: yinqiang
 * @Last Modified time: 2018-02-24 18:41:53
 */
export class BusinessError extends Error {
  name: string;
  message: string;
  stack?: string;

  public errorCode: string;

  constructor(private errorName: string, private errorMessage?: string, private code?: string) {

    super(errorMessage);
    this.name = errorName;
    this.message = errorMessage;
    this.errorCode = code;
  }

  private init(errorName: string, errorMessage: string, code: string) {
    this.name = errorName;
    this.message = errorMessage;
    this.errorCode = code;
  }
}
