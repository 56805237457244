import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'assetStatusPipe'
})
export class AssetStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const status = {
      EFFECTIVE: '正常',
      AUDITING: '待审核',
      INVALID: '已报废',
      REMOVING: '正在转移中',
      REMOVE_DONE: '已转出',
      CANCELLED: '已取消',
      REJECTED: '已拒绝',
      DELETE: '已删除',
    };
    return status[value];
  }
}
