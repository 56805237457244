import {PageReq, PageResp} from "../page/page";


export class ApprovalTaskDTO {
  id?: number;
  record_no?: string;

  type?: string;

  create_time?: number;
  approv_time?: number;

  create_user_id?: number;
  create_user_name?: string;

  approv_status?: string;

  task_id?: string;
  instance_id?: string;
  is_lock_password:boolean;
}

export class ApprovalTaskListResponse extends PageResp {
  approval_task_dtolist?: Array<ApprovalTaskDTO> ;
}

export class ApprovContentDTO {
  // @Remark("审批人")
  approver_name?: string;
  // @Remark("审批人岗位")
  approver_position?: string;
  // @Remark("审批时间")
  approv_time?: number;
  // @Remark("审批耗时")
  take_up_time?: number;
  // @Remark("审批结果")
  approv_result?: string;
  // @Remark("拒绝原因")
  reject_reason?: string;
}

export class ApprovalResultResponse {
  approv_content_dtolist?: Array<ApprovContentDTO>;
}

export class ProcessDetailImgResponse {
  base64img?: string;
}

export class UserTaskFlowChartNodeDTO {
  node?: string;
  users?: Array<string>;
  groups?: Array<string>;

  user_ids?: Array<number>;
  role_ids?: Array<number>;
}

export class UserTaskFlowChartDTO {
  nodes?: Array<UserTaskFlowChartNodeDTO>;
  links?: Array<String>;
}

export class ProcessDetailFlowChartResponse {
  user_task_flow_chart_dto?: UserTaskFlowChartDTO;
}

export class ApprovalUnitRoomDTO{
  unit_name:string;
  room_names:string[];
}

export class ApprovalLockPasswordResponse{
  password_type:string;
  submit_time:Date;
  create_user_name:string;
  room_name:string;
  room_count:number;
  remark:string;
  unit_room_list:ApprovalUnitRoomDTO[];
  process_instance_id:string;
  time_text:string;
  appro_record_info_id:number;
  approv_num:string;
  send:boolean;
  password:string;
  validity:string;
  status:string;
  refusal_remark:string;
}
export class ApprovalListSearchReq extends PageReq{
  start_time:string;
  end_time:string;
  user_name:string;
  appro_type:string;
}
export class ApprovLockPasswordListReq extends PageReq{
  start_time:string;
  end_time:string;
}
export class ApprovLockPasswordListDTO{
  id:number;

  process_instance_id:string;

  approv_num:string;

  password_type:string;

  submit_time:Date;

  status:string;

  send:boolean;
}

export class ApprovLockPasswordListRes extends PageResp{
  lock_password_list:ApprovLockPasswordListDTO[];
}

export class ApproBatchPwdResponse{
  success_count:number;
  error_count:number;
  code:string;
  validity:string;
  remark:string;
}

export class ApprovalRenterCheckoutDepositResponse{
  //@Remark("待退款金额")
  total:string;
  //@Remark("提交用户")
  commit_user:string;
  //@Remark("提交时间")
  commit_time:Date;
  //@Remark("住客姓名")
  renter_name:string;
  //@Remark("房间名")
  room_name:string;
  //@Remark("退宿日期")
  checkout_time:Date;
  //@Remark("退宿单号")
  renter_checkout_number:string;
  //@Remark("退款类型")
  checkout_bill_pay_type_enum:string;
  //@Remark("收款人姓名")
  refund_name:string;
  //@Remark("开户行")
  open_bank:string;
  //@Remark("银行账号")
  bank_no:string;
  process_instance_id:string;
  approv_num:string;
  appro_record_info_id:number;
  renter_checkout_id:number;
}

export class ApprovalTaskRequest{
  appro_record_info_id: number;

  service_status: string;
}

export class ApprovalTaskResponse{
  task_id: number;

  id: number;
}
