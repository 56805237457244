import {ErrorHandler, NgModule} from "@angular/core";
import {AppRoutingModule} from "./app-routing.module";
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from "@angular/common";
import {AppComponent} from "./app.component";
import {PathChildGuard} from "./core/guard/path.child.guard";
import {PathGuard} from "./core/guard/path.guard";
import {PrivilegeResolve} from "./core/resolve/privilege.resolve";
import {CustomErrorHandler} from "./core/custom.errorhandler";
import {WorkRemindService} from "./service/workremind/workremind.service";
import {SharedRootModule} from "./view/shared/module/share-common.module";
import {BrowserModule} from "@angular/platform-browser";
import {UserModule} from "./view/user/user.module";
import {RequestService} from "./service/request.service";
import {LogService} from "./service/log/log.service";
import {AuthService} from "./service/common/auth.service";
import {RoomService} from "./service/room/room.service";
import {ProjectService} from "./service/project/project.service";
import {PrivilegeService} from "./service/privilege/privilege.service";
import {WorkService} from "./service/common/work.service";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {LoadingInterceptor} from "./interceptor/loading.interceptor";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RepairService} from "./service/room/repair.service";
import {DeviceService} from './service/device/device.service';
import {RefundListService} from "./service/refund/refund.service";
import {TradingSceneService} from './service/finance/trading-scene.service';
import {PrepayService} from "./service/prepay/prepay.service";
import {BillService} from "./service/finance/bill.service";
import {RiskService} from "./service/workbench/risk.service";
import {WorkbenchService} from "./service/workbench/workbench.service";
import zh from '@angular/common/locales/zh';
import {AssetService} from './service/asset/asset.service';
import {SignedService} from './service/signed/signed.service';
import {BookingService} from './service/booking/booking.service';
import {FinanceService} from './service/finance/finance.service';
import {BannerService} from './service/banner/banner.service';
import {ComplaintsService} from './service/complaints/complaints.service';
import {RenterService} from './service/renter/renter.service';
import {ImageHandleService} from "./service/image/image.handle.service";
import {InvoiceService} from "./service/invoice/InvoiceService";
import {OrderService} from './service/order/order.service';
import {RoomGroupService} from './service/roomgroup/roomgroup.service';
import {CarportService} from "./service/carport/carport.service";
import {RefundService} from './service/audit/refund.service';
import {AccountingService} from './service/audit/accounting.service';
import {CarportLeaseService} from './service/carport/carport.lease.service';
import {BaseInfoService} from './service/common/base-info.service';
import {ImageQrcodeService} from './service/image/image_qrcode.service';
import {CompanyService} from './service/company/company.service';
import {ModuleService} from './service/module/module.service';
import {ScheduleServer} from './service/schedule/schedule.server';
import {WaterElectricService} from './service/waterElectric/waterElectric.service';
import {LeaseService} from './service/lease/lease.service';
import {IdReaderService} from './service/renter/id_reader.service';
import {CheckoutService} from './service/checkout/checkout.service';
import {LockService} from './service/lock/lock.service';
import {RptService} from './service/rpt/rpt.service';
import {UserService} from './service/user/user.service';
import {DepartmentService} from './service/department/department.service';
import {PositionService} from './service/position/position.service';
import {DiscountService} from './service/discount/DiscountService';
import {SystemLogService} from './service/systemLog/systemLog.service';
import {RoomChangeService} from './service/lease/roomchange.service';
import {ClientuserService} from "./service/user/clientuser.service";
import {AppointService} from './service/appoint/appoint.service';
import {CouponService} from "./service/coupon/coupon.service";
import {MonitorService} from "./service/monitor/monitor.service";
import {ApphelloService} from "./service/apphello/apphello.service";
import {CompanyPrepayService} from "./service/prepay/company-prepay.service";
import {NoticeService} from "./service/notice/notice.service";
import {ShopLeaseService} from "./service/shop/shop-lease.service";
import {ShopService} from "./service/shop/shop.service";
import {ShopBillService} from "./service/shop/shop-bill.service";
import {EquipmentService} from './service/equipment/equipment.service';
import {WorkScheduleService} from "./service/schedule/work-schedule.service";
import {GuardTourService} from "./service/guard-tour/guard-tour.service";
import {RoominspentService} from "./service/roominspent/roominspent.service";
import {NoticeOfAppService} from "./service/notice/notice-of-app.service";
import {ActivityService} from "./service/activity/activity.service";
import {RouteReuseStrategy} from "@angular/router";
import {CustomReuseStrategy} from "./core/strategy/custom-reuse.strategy";
import {TabTitleResolve} from "./core/resolve/tab-title.resolve";
import {CommonService} from "./service/common/common.service";
import {WalletService} from "./service/wallet/wallet.service";
import {DevicebindingService} from "./service/devicebinding/devicebinding.service";
import {WarningService} from "./service/warning/warning.service";
import {IdempotentService} from "./service/idempotentService/IdempotentService";
import {ElemonthService} from "./service/elediff/elemonth.service";
import {ImageService} from "./service/image-upload/image.service";
import {EProductService} from "./service/ebusiness/eproduct/EProductService";
import {EOrderService} from "./service/ebusiness/eorder/EOrderService";
import {ECommonService} from "./service/ebusiness/common/ECommonService";
import {EVenueService} from "./service/ebusiness/evenue/EVenueService";
import {EWelfareService} from "./service/ebusiness/ewelfare/EWelfareService";
import {ShopManagerService} from "./service/shop/shop-manager-list.service";
import {BsModalService} from "ngx-bootstrap/modal";
import { ApprovService } from './service/approv/approv.service';
import {ClientInfoService} from "./service/clientinfo/ClientInfoService";
import {EWithdrawService} from "./service/ebusiness/ewithdraw/EWithdrawService";
import {CustomProjectManageService} from "./service/weixinminiprogram/CustomProjectManageService";
import {Page404Component} from './view/page404/page404.component';

registerLocaleData(zh);

@NgModule({
  declarations: [AppComponent, Page404Component],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedRootModule,
    AppRoutingModule,
    UserModule
  ],
  exports: [],
  providers: [

    PrivilegeResolve,
    TabTitleResolve,
    PathChildGuard,
    PathGuard,

    BsModalService,

    LogService,
    WorkService,
    AuthService,
    RequestService,

    // file upload
    ImageService,

    RoomService,
    ProjectService,
    PrivilegeService,
    DeviceService,
    RepairService,
    RefundListService,
    PrepayService,
    TradingSceneService,
    BillService,
    WorkbenchService,
    RiskService,
    AssetService,
    BookingService,
    FinanceService,
    SignedService,
    ComplaintsService,
    ActivityService,
    BannerService,
    RenterService,
    ImageHandleService,
    InvoiceService,
    OrderService,
    RoomGroupService,
    RefundService,
    AccountingService,
    CarportService,
    CarportLeaseService,
    BaseInfoService,
    ImageQrcodeService,
    CompanyService,
    ModuleService,
    ScheduleServer,
    WaterElectricService,
    LeaseService,
    IdReaderService,
    CheckoutService,
    LockService,
    RptService,
    UserService,
    DepartmentService,
    PositionService,
    DiscountService,
    SystemLogService,
    RoomChangeService,
    ClientuserService,
    AppointService,
    CouponService,
    MonitorService,
    ApphelloService,
    CompanyPrepayService,
    NoticeService,
    ShopBillService,
    ShopLeaseService,
    ShopService,
    WorkScheduleService,
    EquipmentService,
    ElemonthService,
    GuardTourService,
    RoominspentService,
    NoticeOfAppService,
    CommonService,
    WalletService,
    DevicebindingService,
    WarningService,
    IdempotentService,
    ShopManagerService,
    ClientInfoService,
    ApprovService,
    CustomProjectManageService,
    WorkRemindService,


    // ebusiness
    ECommonService,
    EProductService,
    EOrderService,
    EVenueService,
    EWelfareService,
    EWithdrawService,


    {provide: ErrorHandler, useClass: CustomErrorHandler},
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},

    // 注册路由重用服务提供商
    {provide: RouteReuseStrategy, useClass: CustomReuseStrategy},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
