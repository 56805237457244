import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import Decimal from "decimal.js";
import {RegularExp} from "../../../common/util.regexp";
import {BillService} from "../../../service/finance/bill.service";
import {RoomInfoDTO} from "../../../model/room/room.model";
import {
  LeaseBillChangeDTO,
  LeaseBillChangeRequest, LeaseRenterBillListDTO, LeaseRenterBillListRequest, LeaseRenterBillListResponse,
  LeaseRoomBillListDTO,
  LeaseRoomBillListParam,
  PayLogListDTO,
  RoomListParam
} from "../../../model/finance/bill.model";
import {ActivatedRoute} from "@angular/router";
import {LeaseRoomBillDetailComponent} from "./lease-room-bill-detail.component";
import {PayLogDetailComponent} from "../pay-log/pay-log-detail.component";
import {ModalDirective} from "ngx-bootstrap/modal";
import {removeFromArray} from "../../../common/array.util";
import {SysUser} from "../../../model/user/user.model";
import {SignedService} from "../../../service/signed/signed.service";
import {SMSAuditCodeRequest} from "../../../model/signed/checkout.model";
import {MessageModalInfo} from "../../../model/modal/message-model";
import {WorkService} from "../../../service/common/work.service";
import {WorkModel} from "../../../model/common/common.model";
import {CouponInfoBizRelationDTO, CouponInfoBizRelationListReq} from "../../../model/coupon/coupon.model";
import {CouponService} from "../../../service/coupon/coupon.service";
import {BillReconciliationRequest, BillTypeInfo} from "../../../model/finance/finance.model";
import {generateDatePluginData} from "../../../common/common";
import {AuthService} from '../../../service/common/auth.service';
import {BaseInfoService} from "../../../service/common/base-info.service";
import {RenterWentDutchBillDetailComponent} from "../renter-went-dutch-bill/renter-went-dutch-bill-detail.component";

@Component({
  selector: 'app-lease-room-bill',
  templateUrl: './lease-room-bill.component.html',
  styleUrls: ['./lease-room-bill.component.css']
})
export class LeaseRoomBillComponent implements OnInit, OnChanges {
  btn_privileges: any = {
    'LEASE_BILL_SETTING': false,
    'BILL_RECONCILIATION': false,
    'BILL_ADVANCE': false
  };

  //PAYLOG  支付流水
  showView: string;

  //显示视图
  //BILL  账单列表
  //租约ID
  @Input() leaseId: number;
  @Input() wentDutch: boolean;
  // 房间下拉列表
  roomInfoList: Array<RoomInfoDTO>;
  //租约账单/支付流水 参数
  leaseRoomBillListParam: LeaseRoomBillListParam = {
    lease_id: null,
    bill_no: null,
    room_id: null,
    bill_status: 'ALL',
    page_num: 1,
    page_size: 10,
    bill_type_id: null,
  };
  leaseRoomBillListParam1: LeaseRoomBillListParam = {
    lease_id: null,
    bill_no: null,
    room_id: null,
    bill_status: '',
    page_num: 1,
    page_size: 10
  };
  //租约账单 返回对象
  leaseRoomBillList: Array<LeaseRoomBillListDTO>;
  //支付流水 返回对象
  payLogList: Array<PayLogListDTO>;
  //合同欠费
  arrearages: string;
  //总记录数
  total: number;
  total1: number;
  advanceBillSelect: any = 96;
  isAdvanceCheckAll: boolean = false;
  checkboxBill: any = [];
  advanceBuillLoading: boolean = false;
  advanceBillList: any = [];
  advanceBillOption: any = [];
  leaseRenterBillListRequest: LeaseRenterBillListRequest = new LeaseRenterBillListRequest();
  leaseRenterBillListResponse: LeaseRenterBillListResponse = new LeaseRenterBillListResponse();
  leaseRenterBillListDTOS?: Array<LeaseRenterBillListDTO> = new Array<LeaseRenterBillListDTO>();
  renterBillTypeList: Array<BillTypeInfo> = [];
  arrears: string;
  queryRenterBillTypeName: string;
  queryBillTypeName: string;

  @ViewChild('billDetail', {static: true}) billDetail: LeaseRoomBillDetailComponent;
  @ViewChild('payLogDetail', {static: true}) payLogDetail: PayLogDetailComponent;
  @ViewChild('staticModal', {static: true}) staticModal: ModalDirective;
  @ViewChild('staticModal2', {static: true}) staticModal2: ModalDirective;
  @ViewChild('couponDetail', {static: true}) couponDetail: ModalDirective;
  @ViewChild('advanceBillModal', {static: true}) advanceBillModal: ModalDirective;
  @ViewChild('showMsgModal', {static: true}) showMsgModal: ModalDirective;

  @ViewChild('reconciliationModal', {static: true}) reconciliationModal: ModalDirective;

  @ViewChild('modalChangeLeaseBill', {static: true}) modalChangeLeaseBill: ModalDirective;
  @ViewChild('approvModal', {static: true}) approvModal: ModalDirective;
  @ViewChild('renterBillDetail', {static: true}) renterBillDetail: RenterWentDutchBillDetailComponent;


  coupon_info_detail_list: CouponInfoBizRelationDTO[] = [];

  billStatus = [
    {id: 'ALL', value: '全部支付状态'},
    {id: 'NO_PAYMENT', value: '待支付'},
    {id: 'PAYMENT', value: '已支付'},
    {id: 'PARTIAL_PAYMENT', value: '部分支付'},
    {id: 'NEEDLESS', value: '无需付'}
  ];
  renterBillStatus = [
    {id: 'ALL', value: '全部支付状态'},
    {id: 'NO_PAYMENT', value: '待支付'},
    {id: 'PARTIAL_PAYMENT', value: '部分支付'},
    {id: 'PAYMENT', value: '已支付'},
    {id: 'NEEDLESS', value: '无需付'}
  ];

  room_id = '';

  //账单列表index
  index = 0;
  //支付流水index
  index1 = 0;
  //逾期金设置选择的房间
  roomIdOfBillSetting = '';
  //逾期金账单列表
  overdueBillList = [];
  //选组的逾期金账单IDs
  checkedBillIds = [];
  //设为无需付
  radio = 'true';
  auditList: Array<SysUser> = [];
  // 短信验证码
  shortMessageVerificationCode: string = '';
  selectedUserPhone: string = 'ALL';
  auditCodeError = false;
  time = 60;
  codeBtnStr = '点击获取';
  SMSAuditCodeRequest = new SMSAuditCodeRequest();
  inter;
  // 不收逾期金选择的原因
  overdueType = "";
  overdueContent: string;

  // 第一个租约开始时间
  initial_date: Date;
  // 从什么时候开始夜审
  settle_start_time: any;
  // 项目名
  project_name: string;
  // 重新夜审弹窗
  settle_modal: any;
  isPasswordNullError: boolean = true;

  isHasNoBill: boolean = true;
  isPasswordError: boolean = false;
  isPasswordTimeError: boolean = false;
  isShowRecBody: boolean = false;
  billAmountError: boolean = false;
  billReconciliationRequest: BillReconciliationRequest = new BillReconciliationRequest();
  //账单对象
  leaseRoomBillDetail: LeaseRoomBillListDTO;
  billTypeList: any[];


  //
  bc_room_id = '';
  bc_bill_type_id;
  changeLeaseRoomBaseBillList: Array<LeaseRoomBillListDTO> = [];
  changeLeaseRoomBillList: Array<LeaseRoomBillListDTO>;
  bcBillCount = 0;
  bcBillChangeTotal;
  reason: any;
  submit = false;
  approvNum;
  selectAllTag = false;
  bc_show = false;


  auditImgs;
  initImg = [];

  constructor(private billService: BillService, private activatedRoute: ActivatedRoute,
              private signedService: SignedService, private workService: WorkService, private couponService: CouponService,
              private authService: AuthService, private baseInfoService: BaseInfoService) {
  }

  ngOnInit() {
    this.authService.getOperatePrivilege(this.btn_privileges);
    this.getBillTypeList();
    this.queryRenterBillTypeList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showView = "BILL";
    this.getRoomList();
    this.serach_bill();
  }

  //租约账单查询
  serach_bill(): void {
    this.leaseRoomBillList = [];
    // this.arrearages = null;
    this.leaseRoomBillListParam.page_num = 1;
    this.total = 1;
    this.index++;
    if (this.leaseRoomBillListParam.bill_type_id) {
      this.queryBillTypeName = this.billTypeList.find(e => e.bill_type_id == this.leaseRoomBillListParam.bill_type_id).bill_type_name;
    } else {
      this.queryBillTypeName = null;
    }
    this.getLeaseRoomBillList(this.index);

  }

  //支付流水查询
  serach_pay(): void {
    this.payLogList = [];
    this.leaseRoomBillListParam1.page_num = 1;
    this.total1 = 1;
    this.index1++;
    this.getPayLogList(this.index1);
  }

  //获取租约账单集合
  getLeaseRoomBillList(index: number): void {
    // for (const item in this.leaseRoomBillListParam) {
    //   if (!this.leaseRoomBillListParam[item]) {
    //     delete this.leaseRoomBillListParam[item];
    //   }
    // }
    if (!this.leaseId) {
      this.leaseRoomBillList = [];
      this.arrearages = null;
      this.total = 1;
    } else {
      this.leaseRoomBillListParam.lease_id = this.leaseId;
      if (this.room_id !== '') {
        this.leaseRoomBillListParam.room_id = +this.room_id;
      } else {
        this.leaseRoomBillListParam.room_id = null;
      }
      this.billService.getLeaseRoomBillList(this.leaseRoomBillListParam)
        .then(data => {
          if (data && data.lease_room_bill_list) {
            if (this.index === index) {
              if (this.leaseRoomBillList != null) {
                this.leaseRoomBillList = this.leaseRoomBillList.concat(data.lease_room_bill_list);
              } else {
                this.leaseRoomBillList = data.lease_room_bill_list;
              }
              // 判断是否全部拉完
              if (this.leaseRoomBillList && data.total > this.leaseRoomBillList.length) {
                this.leaseRoomBillListParam.page_num++;
                this.getLeaseRoomBillList(index);
              }
            }
            // this.leaseRoomBillList = data.lease_room_bill_list;
            this.arrearages = data.arrearages;
            // this.total = data.total === 0 ? 1 : data.total;
          } else {
            this.arrearages = null;
            this.leaseRoomBillList = [];
          }
        });
    }
    // this.leaseRoomBillListParam.bill_type_id = null;
  }

  //获取支付流水集合
  getPayLogList(index: number): void {
    for (const item in this.leaseRoomBillListParam1) {
      if (!this.leaseRoomBillListParam1[item]) {
        delete this.leaseRoomBillListParam1[item];
      }
    }
    if (!this.leaseId) {
      this.payLogList = [];
      this.arrearages = null;
      this.total1 = 1;
    } else {
      this.leaseRoomBillListParam1.lease_id = this.leaseId;

      this.billService.getPayLogList(this.leaseRoomBillListParam1)
        .then(data => {
          // this.payLogList = [];
          // this.arrearages = null;
          // this.total1 = 1;
          if (data) {
            if (data.pay_log_list) {
              if (this.index1 === index) {
                if (this.payLogList != null) {
                  this.payLogList = this.payLogList.concat(data.pay_log_list);
                } else {
                  this.payLogList = data.pay_log_list;
                }
                // 判断是否全部拉完
                if (this.payLogList && data.total > this.payLogList.length) {
                  this.leaseRoomBillListParam1.page_num++;
                  this.getPayLogList(index);
                }
              }
            }
            // if (data.total) {
            //   this.total1 = data.total === 0 ? 1 : data.total;
            // }
          } else {
            this.payLogList = [];
          }
        });
    }
  }

  //房源下拉列表
  getRoomList(): void {
    let roomListParam = new RoomListParam();
    roomListParam.lease_id = this.leaseId;

    this.billService.getRoomList(roomListParam)
      .then(data => {
        if (data && data.room_info_list) {
          this.roomInfoList = data.room_info_list;
        } else {
          this.roomInfoList = [];
        }
      });
  }

  switch(showViewType: string) {
    this.room_id = '';
    if (showViewType === "BILL") {
      this.leaseRoomBillListParam = new LeaseRoomBillListParam(this.leaseId, null, null, 'ALL', 10, 1);
      this.showView = "BILL";
      this.getRoomList();
      this.serach_bill();
    }
    if (showViewType === "PAYLOG") {
      this.leaseRoomBillListParam1 = new LeaseRoomBillListParam(this.leaseId, null, null, 'ALL', 10, 1);
      this.showView = "PAYLOG";
      this.getRoomList();
      this.serach_pay();
    }
    if (showViewType === 'RENTER_BILL') {
      this.leaseRenterBillListRequest.lease_id = this.leaseId;
      this.leaseRenterBillListRequest.room_id = null;
      this.leaseRenterBillListRequest.pay_status = 'ALL';
      this.leaseRenterBillListRequest.bill_type_code = null;
      this.leaseRenterBillListRequest.page_num = 1;
      this.leaseRenterBillListRequest.page_size = 10;
      this.showView = "RENTER_BILL";
      this.getRoomList();
      this.queryRenterBill();
    }
  }

  showDetail(id: number) {
    if (this.showView === "BILL") {
      this.billDetail.showModel(id);
    }
    if (this.showView === "PAYLOG") {
      this.payLogDetail.showModel(id);
    }
  }

  showRenterBillDetail (billId : number, renterWentDutchBillId : number){
    this.renterBillDetail.showModel(billId, renterWentDutchBillId);
  }

  billToPay(id: number) {
    this.payLogDetail.showModel(id);
  }

  payToBill(id: number) {
    this.billDetail.showModel(id);
  }

  billToCoupon(id: number) {
    this.coupon_info_detail_list = [];
    this.couponService.getCouponInfoBizRelationList(new CouponInfoBizRelationListReq(id)).then(data => {
      this.coupon_info_detail_list = data.coupon_info_biz_relation_list;
      this.couponDetail.show();
    });
  }

  filterOverdueBill() {
    if (this.leaseRoomBillList) {
      this.overdueBillList = this.leaseRoomBillList.filter(e =>
        e.bill_type_name === '滞纳金' && (e.bill_status_name === '未支付' || e.bill_status_name === '部分支付'));
    }
  }

  filterBillByRoomId() {
    this.checkedBillIds = [];
    if (this.roomIdOfBillSetting !== '') {
      if (this.leaseRoomBillList) {
        let filter = this.leaseRoomBillList.filter(e =>
          e.bill_type_name === '滞纳金' && (e.bill_status_name === '未支付' || e.bill_status_name === '部分支付'));
        this.overdueBillList = filter.filter(e => e.room_id === +this.roomIdOfBillSetting);
      }
    } else {
      this.overdueBillList = this.leaseRoomBillList.filter(e =>
        e.bill_type_name === '滞纳金' && (e.bill_status_name === '未支付' || e.bill_status_name === '部分支付'));
    }
  }

  checkAll(event?: any) {
    if (event.target.checked) {
      // 全选
      if (this.overdueBillList && this.overdueBillList.length > 0) {
        this.checkedBillIds = this.overdueBillList.map(e => e.bill_id);
      }
    } else {
      //取消全选
      this.checkedBillIds = [];
    }
  }

  checkCurrentBill(event?: any, billId?: any) {
    // 勾选账单
    if (event.target.checked) {
      this.checkedBillIds.push(billId);
    } else {
      // 取消勾选
      this.checkedBillIds = removeFromArray(this.checkedBillIds, billId);
    }
  }

  getAuditList() {
    this.signedService.leaseAuditCheckoutList().then(data => {
      this.auditList = data.audit_user;
    });
  }

  nextStep() {
    //获取审核人列表
    this.getAuditList();
    this.staticModal.hide();
    this.staticModal2.show();
  }

  getCode() {
    if (this.time === 60) {
      this.time = 0;
      this.SMSAuditCodeRequest.code_type = 18;
      this.SMSAuditCodeRequest.lease_id = this.leaseId;
      this.SMSAuditCodeRequest.mobile = this.selectedUserPhone;
      this.signedService.leaseAuditCheckoutAuditSMS(this.SMSAuditCodeRequest).then(data => {
        this.time = 60;
        this.inter = setInterval(() => {
          if (--this.time > 0) {
            this.codeBtnStr = this.time + 's后重新获取';
          } else {
            this.time = 60;
            this.codeBtnStr = '点击获取';
            clearInterval(this.inter);
          }
        }, 1000);
      }).catch(() => {
        this.time = 60;
      });
    }
  }

  confirmThatNoPaymentIsRequired() {
    let billSize = this.checkedBillIds.length;
    if (this.overdueType && this.overdueContent) {
      this.billService.overdueBillNoNeedPay({
        bill_id_list: this.checkedBillIds,
        user_phone: this.selectedUserPhone,
        identifying_code: this.shortMessageVerificationCode,
        overdue_type: this.overdueType,
        overdue_content: this.overdueContent,
      }).then(response => {
        let data = response.result;
        if (data && data === '执行成功') {
          let messageModalInfo = new MessageModalInfo();
          messageModalInfo.operationMessage = billSize + '笔滞纳金账单已成功设置为无需付';
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
          this.staticModal2.hide();
          this.changeValueToNull();
        } else {
          let messageModalInfo = new MessageModalInfo();
          messageModalInfo.operationMessage = data;
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
        }
        this.serach_bill();
      }).catch(eror => {

      });
    }
  }

  changeValueToNull() {
    this.checkedBillIds = [];
    this.shortMessageVerificationCode = '';
    if (this.time === 60 || this.time === 0) {
      this.selectedUserPhone = 'ALL';
    }
    this.overdueType = null;
    this.overdueContent = null;
    this.selectedUserPhone = 'ALL';
  }

  // 重新夜审弹窗显示
  settleModalShow(is_show: boolean) {
    if (is_show) {
      this.settle_start_time = '';
      this.cancelBillReconciliationRequest();
      this.reconciliationModal.show();
      this.isPasswordNullError = true;
    } else {
      this.reconciliationModal.hide();
    }
  }

  /**
   * 取消系统调账
   * @param model
   */
  cancelBillReconciliationRequest() {
    this.isHasNoBill = true;
    this.isPasswordError = false;
    this.isPasswordTimeError = false;
    this.isShowRecBody = false;
    this.billAmountError = false;
    this.billReconciliationRequest = new BillReconciliationRequest();

    if (this.reconciliationModal) {
      this.reconciliationModal.hide();
    }
  }

  searchBillDetail(billNo: string) {
    this.billService.getleaseRoomBillDetail({bill_no: billNo})
      .then(data => {
        if (data && data.bill_id > 0) {
          this.isHasNoBill = false;
          this.leaseRoomBillDetail = data;
          this.billReconciliationRequest.bill_id = data.bill_id;
          if (data.bill_start_time) {
            this.billReconciliationRequest.bill_start_time = generateDatePluginData(new Date(data.bill_start_time));
          }
          if (data.bill_end_time) {
            this.billReconciliationRequest.bill_end_time = generateDatePluginData(new Date(data.bill_end_time));
          }
          if (data.plan_pay_time) {
            this.billReconciliationRequest.plan_pay_time = generateDatePluginData(new Date(data.plan_pay_time));
          }
          this.billReconciliationRequest.bill_amount = data.bill_amount;
          this.isShowRecBody = true;
        } else {
          this.leaseRoomBillDetail = new LeaseRoomBillListDTO;
          this.isHasNoBill = true;
          this.isShowRecBody = false;
        }
      });
  }

  updateConfirmPwd(event: any) {
    if (event.target.value) {
      this.isPasswordError = false;
      this.isPasswordNullError = false;
      this.billAmountError = false;
      this.isPasswordTimeError = false;
    } else {
      this.isPasswordNullError = true;
    }
  }

  billAmount(event: any) {
    if (event.target.value) {
      let amount = event.target.value;
      if (/^\d+(\.\d{0,2})?$/.test(amount)) {
        let s = parseFloat(amount);
        if (s < 0 || s > 10000000) {
          this.billAmountError = true;
        } else {
          this.billAmountError = false;
        }
      } else {
        this.billAmountError = true;
      }
    }
  }

  confirmBillReconciliationRequest() {

    this.isHasNoBill = false;

    if (this.billReconciliationRequest) {

      this.billReconciliationRequest.bill_start_time = new Date(this.billReconciliationRequest.bill_start_time.formatted);
      this.billReconciliationRequest.bill_end_time = new Date(this.billReconciliationRequest.bill_end_time.formatted);
      this.billReconciliationRequest.plan_pay_time = new Date(this.billReconciliationRequest.plan_pay_time.formatted);

      this.billService.billReconciliation(this.billReconciliationRequest).then(response => {
        if (response && response.information === '密码错误') {
          this.isPasswordError = true;
        } else if (response && response.information === '密码验证次数过多') {
          this.isPasswordTimeError = true;
        } else {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, '此账单系统调账成功！'));
          this.cancelBillReconciliationRequest();
        }
      });
    }
  }

  // 获取账单类型
  getBillTypeList() {
    this.baseInfoService.getBillTypeListl().then(data => {
      if (data.bill_types) {
        this.billTypeList = data.bill_types;
        this.billTypeList.unshift({'bill_type_name': "全部账单",bill_type_id:null});
        // 用于提前出账
        this.advanceBillOption = data.bill_types.filter((item) => {
          if (item.type_flag && item.type_flag === 'ADVANCE') {
            return item;
          }
        });
      }
    });
  }

  openAdvanceBillModal() {
    this.searchAdvanceBill();
    this.advanceBillModal.show();
  }

  searchAdvanceBill() {
    let req = {
      lease_id: this.leaseId,
      bill_type_id: this.advanceBillSelect,
      page_size: 99,
      page_num: 1
    };
    this.isAdvanceCheckAll = false;
    this.advanceBuillLoading = true;
    this.signedService.getAdvanceBill(req).then(res => {
      this.advanceBuillLoading = false;
      if (res.advance_lease_room_bill_list && res.advance_lease_room_bill_list.length > 0) {
        this.advanceBillList = res.advance_lease_room_bill_list;
        this.checkboxBill = new Array(this.advanceBillList.length).fill(false);
      } else {
        this.advanceBillList = [];
        this.checkboxBill = [];
      }
    });
  }

  advanceBillSelectFun() {
    let flag = this.checkboxBill.every((item) => {
      return item;
    });
    if (flag) {
      this.isAdvanceCheckAll = true;
    } else {
      this.isAdvanceCheckAll = false;
    }
  }

  advanceCheckAll() {
    if (this.isAdvanceCheckAll) {
      this.checkboxBill.fill(true);
    } else {
      this.checkboxBill.fill(false);
    }
  }

  changeAdvanceBill() {
    this.searchAdvanceBill();
  }

  submitAdvanceBill() {
    if (this.checkboxBill.length === 0) {
      return;
    }
    let index = this.checkboxBill.lastIndexOf(true);
    if (index === -1) {
      this.workService.showErrorModal('请选择账期!');
      return;
    }
    let newArr = this.checkboxBill.slice(0, index + 1);

    let flag = newArr.every((item) => {
      return item;
    });
    if (flag) {
      let len = newArr.length;
      let arr = [];
      for (let i = 0; i < len; i++) {
        arr.push({
          lease_id: this.leaseId,
          bill_type_id: this.advanceBillSelect,
          bill_start_time: this.advanceBillList[i].bill_start_time,
          bill_end_time: this.advanceBillList[i].bill_end_time
        });
      }
      this.signedService.confirmAdvanceBill({lease_advances: arr}).then(res => {
        this.advanceBillModal.hide();
        this.showMsgModal.show();

        this.workService.event_bus.emit(new WorkModel(this.workService.type.SCHEDULE_TASK));
      });
    } else {
      this.workService.showErrorModal('请按账期顺序选择出账，不支持跨账期出账！');
    }
  }


  billChangeShow() {

    if (!this.bc_show) {

      // clear
      this.selectAllTag = false;
      this.bc_room_id = '';
      this.bc_bill_type_id = null;
      this.bcBillCount = 0;
      this.reason = null;
      this.bcBillChangeTotal = '--';

      let param = {
        lease_id: null,
        bill_no: null,
        room_id: null,
        bill_status: 'ALL',
        page_num: 1,
        page_size: 10,
        bill_type_id: null,
      };
      this.bc_show = true;
      this.changeLeaseRoomBaseBillList = [];
      this.searchChangeBill(1, param).then(() => {
        // 只能变更未付部分账单
        this.changeLeaseRoomBillList = this.changeLeaseRoomBaseBillList.filter(e => e.bill_status !== 'PAYMENT' && e.bill_biz_type === 'GATHERING' && !e.went_dutch_bill);
        this.changeLeaseRoomBillList.forEach(e => {
          e.selected = false;
          e.change_amount = null;
        });

        this.bc_show = false;
        this.initImg = [];
        this.modalChangeLeaseBill.show();
      });
    }
  }

  billChangeSubmit() {

    this.submit = true;

    let tag = true;
    let selected = this.changeLeaseRoomBillList
      .filter(e => e.selected);

    let req = new LeaseBillChangeRequest();
    let list = [];

    if (selected && selected.length > 0) {
      selected.forEach(e => {
        if (!e.change_amount) {
          tag = false;
        } else if (+e.change_amount < +e.received) {
          tag = false;
        } else if (+e.change_amount === +e.bill_amount) {
          tag = false;
        }
        let item = new LeaseBillChangeDTO();
        item.bill_amount = e.bill_amount;
        item.change_amount = e.change_amount;
        item.bill_id = e.bill_id;
        list.push(item);
      });

      if (tag && this.reason) {
        req.change_reason = this.reason;
        req.lease_id = this.leaseId;
        req.lease_bill_change_dtolist = list;
        req.imgs = this.auditImgs;
        this.billService.leaseBillChange(req).then(data => {
          this.approvNum = data.approv_num;
          this.modalChangeLeaseBill.hide();
          this.approvModal.show();
        });
      }
    }
  }

  isNum(change_amount: string): boolean {
    return RegularExp.validAmount2.test(change_amount);
  }

  calSelected() {
    let selectedBill = this.changeLeaseRoomBillList.filter(e => e.selected);
    if (selectedBill.length === this.changeLeaseRoomBillList.length) {
      this.selectAllTag = true;
    } else {
      this.selectAllTag = false;
    }


    this.bcBillCount = selectedBill.length;
    let total = new Decimal("0");

    this.bcBillChangeTotal = '--';
    let tag = true;
    selectedBill.forEach(bill => {
      if (this.isNum(bill.change_amount)) {
        total = total.add(new Decimal(bill.change_amount + "").sub(new Decimal(bill.bill_amount + "")));
      } else {
        tag = false;
      }
    });
    if (tag) {
      this.bcBillChangeTotal = total;
    }
  }

  searchBcBill() {
    this.changeLeaseRoomBillList = this.changeLeaseRoomBaseBillList.filter(e => e.bill_status !== 'PAYMENT' && e.bill_biz_type === 'GATHERING' && !e.went_dutch_bill);

    // clear
    this.changeLeaseRoomBillList.forEach(item => {
      item.selected = false;
    });

    this.selectAllTag = false;
    this.bcBillCount = 0;
    this.bcBillChangeTotal = '--';

    if (+this.bc_room_id) {
      this.changeLeaseRoomBillList = this.changeLeaseRoomBillList
        .filter(e => e.room_id === +this.bc_room_id);
    }
    if (+this.bc_bill_type_id) {
      this.changeLeaseRoomBillList = this.changeLeaseRoomBillList
        .filter(e => e.bill_type_id === +this.bc_bill_type_id);
    }
  }

  selectAll() {
    if (this.selectAllTag) {
      this.changeLeaseRoomBillList.forEach(item => {
        item.selected = true;
      });
      this.bcBillCount = this.changeLeaseRoomBillList.length;
    } else {
      this.changeLeaseRoomBillList.forEach(item => {
        item.selected = false;
      });
      this.bcBillCount = 0;
    }
  }

  searchChangeBill(number: number, param): Promise<any> {
    let p = Promise.resolve();
    for (const item in param) {
      if (!param[item]) {
        delete param[item];
      }
    }
    if (!this.leaseId) {
      this.leaseRoomBillList = [];
    } else {
      param.lease_id = this.leaseId;
      return this.billService.getLeaseRoomBillList(param)
        .then(data => {
          if (data && data.lease_room_bill_list) {
            if (this.changeLeaseRoomBaseBillList != null) {
              this.changeLeaseRoomBaseBillList = this.changeLeaseRoomBaseBillList.concat(data.lease_room_bill_list);
            } else {
              this.changeLeaseRoomBaseBillList = data.lease_room_bill_list;
            }
            // 判断是否全部拉完
            if (this.changeLeaseRoomBaseBillList && data.total > this.changeLeaseRoomBaseBillList.length) {
              param.page_num++;
              return this.searchChangeBill(++number, param);
            }
          } else {
            this.leaseRoomBillList = [];
          }
        });
    }
    return p;
  }

  calSub(bill_amount: string, received: string): string {
    return new Decimal(bill_amount + "").sub(new Decimal(received + "")).toString();
  }


  setFiles($event: Array<string>) {
    this.auditImgs = $event;
  }

  queryRenterBill() {
    this.leaseRenterBillListDTOS = [];
    this.leaseRenterBillListRequest.page_num = 1;
    this.total = 1;
    this.index++;
    if (this.leaseRenterBillListRequest.bill_type_code) {
      this.queryRenterBillTypeName = this.renterBillTypeList.find(e => e.bill_type_code == this.leaseRenterBillListRequest.bill_type_code).bill_type_name;
    } else {
      this.queryRenterBillTypeName = null;
    }
    this.getLeaseRenterBillList(this.index);
  }

  queryRenterBillTypeList() {
    this.baseInfoService.getRenterBillTypeList().then(data => {
      this.renterBillTypeList = data.bill_type_list;
      this.renterBillTypeList.unshift({'bill_type_name': "全部账单",bill_type_code:null});
    });
  }

  getLeaseRenterBillList(index: number) {
    if (!this.leaseId) {
      this.leaseRenterBillListDTOS = [];
      this.arrears = null;
      this.total = 1;
    } else {
      this.leaseRenterBillListRequest.lease_id = this.leaseId;
      if (this.room_id !== '') {
        this.leaseRenterBillListRequest.room_id = +this.room_id;
      } else {
        this.leaseRenterBillListRequest.room_id = null;
      }
      this.billService.getLeaseRenterBillList(this.leaseRenterBillListRequest)
        .then(data => {
          if (data && data.lease_renter_bill_list_dtos) {
            if (this.index === index) {
              if (this.leaseRenterBillListDTOS != null) {
                this.leaseRenterBillListDTOS = this.leaseRenterBillListDTOS.concat(data.lease_renter_bill_list_dtos);
              } else {
                this.leaseRenterBillListDTOS = data.lease_renter_bill_list_dtos;
              }
              // 判断是否全部拉完
              if (this.leaseRenterBillListDTOS && data.total > this.leaseRenterBillListDTOS.length) {
                this.leaseRenterBillListRequest.page_num++;
                this.getLeaseRenterBillList(index);
              }
            }
            this.arrears = data.arrears;
          } else {
            this.arrears = null;
            this.leaseRenterBillListDTOS = [];
          }
        });
    }
  }

  delBill(bill_id: number) {

    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.cancel = true;
    messageModalInfo.operationMessage = '是否确认删除此账单？';
    messageModalInfo.callback.subscribe(()=>{
      this.billService.delBill(bill_id).then(()=>{
        this.serach_bill();
        this.workService.event_bus.emit(new WorkModel(this.workService.type.PANEL_NEED_REFRESH));
      });
    });
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
  }
}
