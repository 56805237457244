import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {
  ApprovalListSearchReq,
  ApprovalTaskDTO,
  ApprovalTaskListResponse, ApprovLockPasswordListDTO,
  ApprovLockPasswordListReq, ApprovLockPasswordListRes
} from "../../../model/approv/approv.model";
import {PageReq} from "../../../model/page/page";
import {ApprovService} from "../../../service/approv/approv.service";
import {LeaseService} from "../../../service/lease/lease.service";
import {ApprovRecordDetailModalComponent} from "../approv-record-detail-modal/approv-record-detail-modal.component";
import {getNextNMonth,addDate,generatePluginDate,getDateFromPlugin} from "../../../common/date.util";
import {IMyDpOptions} from "../../third-party/mydatepicker/src/lib/interfaces";
import {WorkModel} from "../../../model/common/common.model";
import {WorkService} from "../../../service/common/work.service";

@Component({
  selector: 'app-approv-lock-password-list',
  templateUrl: './approv-lock-password-list.component.html',
  styleUrls: ['./approv-lock-password-list.component.css']
})
export class ApprovLockPasswordListComponent implements OnInit {

  @ViewChild(ApprovRecordDetailModalComponent) modal:ApprovRecordDetailModalComponent;

  now = new Date();
  tomorrow = addDate(this.now,1);
  begin_time = getNextNMonth(this.now,-12,true);
  start_time:any;
  end_time:any;
  user_name:string;
  approvTypeStr:string="ALL";
  public myPastDatePickerOptions: IMyDpOptions = {
    dateFormat: "yyyy-mm-dd",
    editableDateField: false,
    showClearDateBtn: false,
    disableDateRanges: [{
      begin: {year: 1970, month: this.now.getMonth() + 1, day: this.now.getDate()},
      end: {year: this.begin_time.getFullYear(), month: (this.begin_time.getMonth() + 1), day: this.begin_time.getDate()}
    },{
      begin: {year: this.tomorrow.getFullYear(), month: this.tomorrow.getMonth() + 1, day: this.tomorrow.getDate()},
      end: {year: 3000, month: (this.tomorrow.getMonth() + 1), day: this.tomorrow.getDate()}
    }]
  };
  endDatePickerOptions: IMyDpOptions = {
    dateFormat: "yyyy-mm-dd",
    editableDateField: false,
    showClearDateBtn: false,
    disableDateRanges: [{
      begin: {year: 1970, month: this.now.getMonth() + 1, day: this.now.getDate()},
      end: {year: this.begin_time.getFullYear(), month: (this.begin_time.getMonth() + 1), day: this.begin_time.getDate()}
    },{
      begin: {year: this.tomorrow.getFullYear(), month: this.tomorrow.getMonth() + 1, day: this.tomorrow.getDate()},
      end: {year: 3000, month: (this.tomorrow.getMonth() + 1), day: this.tomorrow.getDate()}
    }]
  };
  approvType=[{type:"LOCK_PASSWORD",desc:"密码申请审批"},
    {type:"LEASE_CHANGE",desc:"合同信息变更申请"},
    {type:"LEASE_BILL_CHANGE",desc:"费用调整申请"},
    {type:"ASSET_TRANSFER",desc:"资产转移审批"},
    {type:"ROOM_AVAILABLE_TIME_CHANGE",desc:"房间使用限制天数修改申请"},
    {type:"FROZEN_PASSWORD",desc:"冻结密码审批"},
  ];
  req:ApprovLockPasswordListReq = new ApprovLockPasswordListReq();
  resp: ApprovLockPasswordListRes;


  constructor(
    private router: Router,
    private approvService: ApprovService,
    private leaseService: LeaseService,
    private workService:WorkService,
  ) {
  }

  ngOnInit(): void {
    this.req.page_num = 1;
    this.req.page_size = 20;
    this.list();
    this.workService.event_bus.subscribe((data:WorkModel) =>{
      if(data.type === "sendOver" && this.resp.lock_password_list){
        for (let r of this.resp.lock_password_list) {
          if(data.data.id === r.id){
            r.send = true;
          }
        }
      }
    });
  }


  list() {

    if(this.start_time){
      this.req.start_time = this.start_time.formatted;
    }else{
      delete this.req.start_time;
    }

    if(this.end_time){
      this.req.end_time = this.end_time.formatted;
    }else{
      delete this.req.end_time;
    }
    this.approvService.getLockPasswordList(this.req).then(data => {
      this.resp = data;
    });
  }

  goPage($event: number) {

    this.req.page_num = $event;
    this.list();
  }


  showDetail(item: ApprovLockPasswordListDTO) {
    let approvTask = new ApprovalTaskDTO();
    approvTask.instance_id = item.process_instance_id;
    approvTask.type = "LOCK_PASSWORD";
    approvTask.id = item.id;
    approvTask.is_lock_password = true;
    this.modal.showDetail(approvTask);
  }

  endTimeChange(){
    if(this.start_time && this.end_time){
      let s = getDateFromPlugin(this.start_time.formatted);
      let e = getDateFromPlugin(this.end_time.formatted);
      let st = addDate(s,-1);
      this.endDatePickerOptions = {
        dateFormat: "yyyy-mm-dd",
        editableDateField: false,
        showClearDateBtn: false,
        disableDateRanges: [{
          begin: {year: 1970, month: this.now.getMonth() + 1, day: this.now.getDate()},
          end: {year: st.getFullYear(), month: (st.getMonth() + 1), day: st.getDate()}
        },{
          begin: {year: this.tomorrow.getFullYear(), month: this.tomorrow.getMonth() + 1, day: this.tomorrow.getDate()},
          end: {year: 3000, month: (this.tomorrow.getMonth() + 1), day: this.tomorrow.getDate()}
        }]
      };
      if(s.getTime() > e.getTime()){
        this.end_time = generatePluginDate(getDateFromPlugin(this.start_time.formatted));
      }
    }
  }
}
