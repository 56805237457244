import {ViewChild, Component, OnInit} from "@angular/core";
import {Router, ActivatedRoute} from '@angular/router';
import {MonitorService} from "../../../service/monitor/monitor.service";
import {SafeWarnDetailComponent} from "../../workspace/safe-warn-detail/safe-warn-detail.component";
import {AuthService} from "../../../service/common/auth.service";
import {Constants} from "../../../common/constants";
import {CommonService} from "../../../service/common/common.service";
import {UserService} from "../../../service/user/user.service";

@Component({
  selector: "app-mobile-safe-warn",
  templateUrl: "./mobile-safe-warn.component.html",
  styleUrls: ["./mobile-safe-warn.component.css"]
})
export class MobileSafeWarnComponent implements OnInit {

  @ViewChild(SafeWarnDetailComponent, { static: true }) safeWarnDetailComponent: SafeWarnDetailComponent;
  btn_privileges: any = {
    'VIEW_SECURITY_ALARM': true,
    'PROCESS_SECURITY_ALARM': true
  };
  person_safe_alarm_dtos: any = [];
  id: any = '';
  projectName: string;
  sysUserDTO: any = {};
  wechatflag: string;
  projectId: string;
  companyId: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private monitorService:MonitorService,
    private authService: AuthService,
    private commonService: CommonService,
    private router: Router,
    private userService: UserService,
  ) {
  }

  ngOnInit(){

    let localitem = this.authService.getUserInfo();
    if (localitem) {
      this.sysUserDTO = localitem;
    }
    const authToken = this.authService.getAuthToken();
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (authToken == null || authToken.length <= 0) {
        this.router.navigate(['/login'], { queryParams: { wechatflag: '1', projectId: queryParams.projectId, companyId: queryParams.companyId, id: queryParams.id}});
        return false;
      }
      this.id = queryParams.id;
      this.wechatflag = queryParams.wechatflag;
      this.companyId = queryParams.companyId;
      this.projectId = queryParams.projectId;
      if (queryParams.wechatflag == 1) {
        this.setWechatConfig();
      } else {
        this.initData();
      }
    });
  }

  setWechatConfig(){
    this.authService.setCompanyId(this.companyId);
    this.userService.saveCurrentCompany({companyId: this.companyId}).then(data => {
      if(data.add_dept){
        localStorage.setItem("add_dept", data.add_dept);
      }
      if(data.add_project){
        localStorage.setItem("add_project", data.add_project);
      }
      if(data.add_user){
        localStorage.setItem("add_user", data.add_user);
      }
      if(data.add_room){
        localStorage.setItem("add_room", data.add_room);
      }
      this.userService.userProjectChange(this.projectId).then(data1 => {
        // 存放项目信息
        this.authService.setProjectInfo(data1.project);
        this.initData();
      });
    });
  }

  initData() {
    this.monitorService.getAlarmDetail({id: this.id}).then(res => {
      if(res !== true){
        this.projectName = this.authService.getProjectName();
      }
      if(res.person_safe_alarm_dtos){
        this.person_safe_alarm_dtos = res.person_safe_alarm_dtos;
      } else {
        this.person_safe_alarm_dtos = [];
      }
    });
  }

  safeWarnDetail(item) {
    this.safeWarnDetailComponent.detailShow(item);
  }

  safeWarnHandle(item) {
    this.safeWarnDetailComponent.handleShow(item, item.status === 'FIXED');
  }

  safeListHandle() {
    this.initData();
  }

  sub(name: string) {
    if (name && name.length > 6) {
      name = name.substring(0, 6);
    }
    return name;
  }

  logout() {
    this.commonService.logout();
  }
}
