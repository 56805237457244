<div class="setbox padd15" *ngIf="btn_privileges.WORKSPACE_CLEANTASK_LIST_VIEW">
  <div class="row">

    <div class="col-12 col-md-6 col-lg-2 item mb10">
      <div class="form-group">
        <label>项目名称</label>
        <div>
          <ng-select class="custom" [(ngModel)]="req.project_id_list"
                     [items]="selectGroupList" bindValue="id" bindLabel="name"
                     groupBy="childs" [selectableGroupAsModel]="false"
                     [selectableGroup]="true"
                     [multiple]="true"
                     placeholder="当前项目"
                     (change)="changeProject($event)"
          >
            <ng-template ng-optgroup-tmp let-item="item">
              {{item.name}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              {{item.name}}
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-3 col-xl-3">
      <div class="form-group">
        <label>房间号</label>
        <div>
          <div class="d-inline-block w-45">
            <select class="w-100" id="unit_list" [(ngModel)]="req.unit_id">
              <option value="0">全部</option>
              <option *ngFor="let item of filterProjectUnits;" [ngValue]="item.id">
                {{item.project_name}} - {{item.unit_name}}
              </option>
            </select>
          </div>
          <div class="ml-2 mr-2 d-inline-block align-top">-</div>
          <div class="d-inline-block w-45">
            <input [(ngModel)]="req.room_name" class="form-control w-100" placeholder="请输入房间号">
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 col-lg-2 item mb10">
      <label>保洁状态</label>
      <select class="w-100" [(ngModel)]="req.clean_status">
        <option value="ALL">全部</option>
        <option value="WAITING">待保洁</option>
        <option value="FINISH">已保洁</option>
        <option value="DELAY">延期保洁</option>
        <option value="CANCEL">放弃保洁</option>
      </select>
    </div>

    <div class="col-12 col-md-6 col-lg-3 item mb10">
      <label class="btn-label"></label>
      <div class="">
        <button (click)="search()" class="btn btn-info" type="button">
          <i class="fa fa-search"></i>搜索
        </button>

        <button (click)="download()" class="btn btn-warning bg-yellow" type="button">
          <i class="fa fa-download"></i>下载
        </button>

        <button (click)="batchUpdate()" class="btn btn-warning mr-2" type="button">
          <i class="fa fa-edit"></i>批量更新任务
        </button>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="row">
      <nz-table #headerTable nzTemplateMode
                [nzScroll]="{x : '1500px'}"
                [nzShowPagination]='false'
                [nzPageSize]="req.page_size"
                [nzData]="response?.clean_task_list" class="table table-bordered table-striped">
        <thead>
        <tr>
          <th>序列</th>
          <th>项目名称</th>
          <th>合同周期</th>
          <th>入住时间</th>
          <th>上次保洁日期</th>
          <th>应保洁日期</th>
          <th>楼栋</th>
          <th>房间号</th>
          <th>保洁状态</th>
          <th>保洁完成日期</th>
          <th>延期至（日期）</th>
          <th>备注信息</th>
          <th>操作</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngIf="response?.clean_task_list">
          <ng-container *ngFor="let info of headerTable.data | paginate: {itemsPerPage: req.page_size,
          currentPage: req.page_num,totalItems: response.total }; let i = index">
            <tr>
              <td>{{info?.index}}</td>
              <td>{{info?.project_name}}</td>
              <td>{{info?.contract_start | date:'yyyy.MM.dd'}}-{{info?.contract_end | date:'yyyy.MM.dd'}}</td>
              <td>
                <span *ngIf="null == info?.check_in_date">--</span>
                <span *ngIf="null != info?.check_in_date">{{info?.check_in_date | date:'yyyy.MM.dd'}}</span>
              </td>
              <td>
                <span *ngIf="null == info?.last_clean_date">--</span>
                <span *ngIf="null != info?.last_clean_date">{{info?.last_clean_date | date:'yyyy.MM.dd'}}</span>
              </td>
              <td>{{info?.need_clean_date | date:'yyyy.MM.dd'}}</td>
              <td>{{info?.unit_name}}</td>
              <td>{{info?.room_name}}</td>
              <td>
                <span *ngIf="'WAITING' == info.clean_status" class="badge badge-pill badge-yellow">待保洁</span>
                <span *ngIf="'FINISH' == info.clean_status" class="badge badge-pill badge-green">已保洁</span>
                <span *ngIf="'DELAY' == info.clean_status" class="badge badge-pill badge-red">延期保洁</span>
                <span *ngIf="'CANCEL' == info.clean_status" class="badge badge-pill badge-gray-100">放弃保洁</span>
              </td>
              <td>
                <span *ngIf="null == info?.finish_clean_date">--</span>
                <span *ngIf="null != info?.finish_clean_date">{{info?.finish_clean_date | date:'yyyy.MM.dd'}}</span>
              </td>
              <td>
                <span *ngIf="null == info?.late_clean_date">--</span>
                <span *ngIf="null != info?.late_clean_date">{{info?.late_clean_date | date:'yyyy.MM.dd'}}</span>
              </td>
              <td>
                <span *ngIf="null == info?.remark">--</span>
                <span *ngIf="null != info?.remark">{{info?.remark}}</span>
              </td>
              <td>
                <button type="button" class="btn btn-info mr10" (click)="update(info)">
                  <i class="fa fa-cog" aria-hidden="true">&nbsp;更新任务 </i>
                </button>
              </td>
            </tr>
          </ng-container>
        </ng-container>
        <tr *ngIf="!response?.clean_task_list || response?.clean_task_list.length === 0" class="text-center">
          <td colspan="13" class="show1">暂无数据信息</td>
          <td colspan="13" class="show2">暂无数据信息</td>
        </tr>
        </tbody>
      </nz-table>
    </div>
    <div *ngIf="response?.total != null && response?.total"
         class="text-right pages">
      <app-page (pageChangeEvent)="pageChange($event)"></app-page>
      <div class="text-right">共有{{response.total}}条保洁任务</div>
    </div>
  </div>
</div>


<div class="modal fade" bsModal #modal1="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">更新保洁任务</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modal1.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body" *ngIf="updateModel">
        <div class="mt-2 mx-4 yellow px-4 py-2">
          <div class="font-2xl font-weight-bold text-left text-success">
            您正在为 <span class="font-2xl font-weight-bold text-danger">{{updateModel.room_name}}</span> 房间更新保洁任务信息：
          </div>
        </div>
        <div class="row justify-content-md-center">
          <div class="mx-5 col-12 col-md-6">
            <div class="mt-2">
              <div class="text-left">
                保洁状态 <span class="text-danger">*</span>
              </div>
              <div class="text-center">
                <select class="w-100" [(ngModel)]="updateModel.clean_status">
                  <option value="WAITING">待保洁</option>
                  <option value="FINISH">已保洁</option>
                  <option value="DELAY">延期保洁</option>
                  <option value="CANCEL">放弃保洁</option>
                </select>
                <div class="text-danger text-left" *ngIf="!updateModel.clean_status">请选择保洁结果</div>
              </div>
            </div>
            <div class="mt-2" *ngIf="updateModel.clean_status==='FINISH'">
              <div class="">
                保洁完成日期 <span class="text-danger">*</span>
              </div>
              <div class="text-center">
                <my-date-picker [(ngModel)]="updateModel.finish_clean_date"
                                [options]="myDatePickerOptions"
                                placeholder="年/月/日">
                </my-date-picker>
                <div class="text-danger text-left" *ngIf="!updateModel.finish_clean_date">请选择保洁完成日期</div>
              </div>
            </div>
            <div class="mt-2" *ngIf="updateModel.clean_status==='DELAY'">
              <div class="">
                延期至 <span class="text-danger">*</span>
              </div>
              <div class="text-center">
                <my-date-picker [(ngModel)]="updateModel.late_clean_date"
                                [options]="myDatePickerOptions"
                                placeholder="年/月/日">
                </my-date-picker>
                <div class="text-danger text-left" *ngIf="!updateModel.late_clean_date">请选择延期日期</div>
              </div>
            </div>
            <div class="mt-2 mb-5">
              <div class="">
                备注信息
              </div>
              <div class="text-center">
                <input class="form-control" [(ngModel)]="updateModel.remark" maxlength="128" placeholder="请输入备注信息"/>
              </div>
            </div>

          </div>
        </div>


      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
                (click)="modal1.hide()">取消
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal"
                (click)="updateDo()">保存
        </button>
      </div>

    </div>
  </div>
</div>
