import {RoomTypeBaseDTO} from "./lease.model";
import {RoomLogDTO} from "../room/room.model";

/**
 * @Description: 签约页面中的选择房间请求
 * @Author: 陆炳彬
 * @Date: 2018/9/12 上午10:16
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/9/12 上午10:16
 */
export class AvaliableRoomListReq {
  // 楼栋id
  unit_id: number = -1;
  // 开始时间(控件)
  start_time_obj: any;
  // 结束时间(控件)
  end_time_obj: any;
  // 开始时间
  start_time: number;
  // 结束时间
  end_time: number;
  // 需要排除的租约
  exclude_lease_id: number;

  check_room_num: number;

  //  房型ID
  room_type_id: number = -1;

  //包含的租约ID
  include_lease_id: number;
  //需要排除的租约房间ID
  exclude_lease_room_id: number;
  //换离的人员数
  renter_num: number;

  in_all_contract:boolean = false;

  check_room_audit:boolean = false;

  data_type: string;
}

export class AvaliableRoomListResp {
  unit_list: AvaliableUnit[];
  room_ids: Array<string>;
}

export class AvaliableUnit {
  unit_id: number;
  unit_name: string;
  floor_list: AvaliableFloor[];

}

export class AvaliableFloor {
  floor: string;
  room_info_list: AvaliableRoomInfo[];
}

/**
 * @Description: 可用房间对象
 * @Author: 陆炳彬
 * @Date: 2018/9/12 下午1:54
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/9/12 下午1:54
 */
export class AvaliableRoomInfo {
  //租约房间Id
  lease_room_id:number;
  // 楼栋id
  unit_id: number;
  // 楼层id
  floor: string;
  // 租约id
  lease_id: number;
  // 房间id
  room_id: number;
  // 房间号
  room_num: string;
  // 房间名称
  room_name: string;
  // 床位数
  bed_num: number;
  // 基础价格
  base_price: number;
  // 房型名称
  room_type_name: string;
  // 房型ID
  room_type_id: number;
  // 面积
  room_area: string;
  //排序字段
  vacant_index: string;

  checked: boolean = false;

  date_diff: number;

  avaliable_checkin_num: number;

  checkin_renter_num: number;

  data_type: string;

  bottom_price:number;

  public_area:string;

  covered_area:string;
}

/**
 * @Description: 开始、结束时间携带选中的房间列表(用于组件传递信息)
 * @Author: 陆炳彬
 * @Date: 2018/9/13 上午10:32
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/9/13 上午10:32
 */
export class TimeWithChoosedRoomList {
  // 开始时间
  start_time: Date;
  // 结束时间
  end_time: Date;
  // 选中的房间列表
  choosed_room_list: AvaliableRoomInfo[];
  // 房型列表
  room_type_list: Array<RoomTypeBaseDTO>;

  constructor(start_time: Date, end_time: Date, choosed_room_list: AvaliableRoomInfo[], room_type_list: Array<RoomTypeBaseDTO>) {
    this.start_time = start_time;
    this.end_time = end_time;
    this.choosed_room_list = choosed_room_list;
    this.room_type_list = room_type_list;
  }
}
