import { Pipe, PipeTransform } from '@angular/core';

/**
 * @Description: 报修工单类型管道
 * @Author: 周嘉豪
 * @Date: 2018/05/07 上午10:26
 * @Last Modified by: 周嘉豪
 * @Last Modified time: 2018/3/29 上午10:26
 */
@Pipe({
  name: 'repairTypePipe'
})
export class RepairTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const status = {
      REPAIR: '报修单',
      CLEAN: '保洁单',
      MALFUNCTION: '故障',
    };
    return status[value];
  }
}
