import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LeaseService} from "../../../service/lease/lease.service";
import {RoomService} from "../../../service/room/room.service";
import {RoomGoodsRemark, RoomTypeGoodsDTO} from "../../../model/room/room.model";
import {CheckinRoomInventoryRequest, CheckinRoomInventoryResponse, RoomGoodsTempletRequest} from "../../../model/signed/inventory.model";
import {TabletComponent} from "../tablet/tablet.component";
import {ModalDirective} from "ngx-bootstrap/modal";
import {WorkModel} from "../../../model/common/common.model";
import {WorkService} from "../../../service/common/work.service";
import {environment} from "../../../../environments/environment";
import {MessageModalInfo} from "../../../model/modal/message-model";
import {DomSanitizer} from "@angular/platform-browser";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../../../service/common/auth.service";
import {RegularExp} from "../../../common/util.regexp";
import {PdfViewComponent} from '../../shared/pdf-view/pdf-view.component';
import {BaseInfoService} from "../../../service/common/base-info.service";
import {UserService} from "../../../service/user/user.service";
import {SignedService} from "../../../service/signed/signed.service";
import {SignDataRequest, SignOnIpadRequest} from "../../../model/signed/sign.model";
import {SignDeviceBindingComponent} from "../../sign-device-binding/sign-device-binding.component";

@Component({
  selector: 'app-goods-list',
  templateUrl: './goods-list.component.html',
  styleUrls: ['./goods-list.component.css']
})
export class GoodsListComponent implements OnInit {

  @ViewChild(PdfViewComponent) pdfView: PdfViewComponent;
  @ViewChild(TabletComponent, {static: true}) tabletComponent: TabletComponent;
  @ViewChild('staticModal') staticModal: ModalDirective;
  @ViewChild('remarksModal', {static: true}) remarksModal: ModalDirective;
  @ViewChild(SignDeviceBindingComponent, {static: true}) signDeviceBindingComponent: SignDeviceBindingComponent;

  title = "请入住人在下方写字板内签字";
  lease_id: number;
  room_id: number;
  lease_room_id: number;
  type: string;

  goodsList: Array<RoomTypeGoodsDTO> = [];
  goodsMap = new Map<string, RoomTypeGoodsDTO[]>();
  goodsMapKey = [];
  path: string;
  remark: string;
  remarkList: Array<RoomGoodsRemark> = [];

  checkinRoomInventoryRequest = new CheckinRoomInventoryRequest();
  checkinRoomInventoryResponse: CheckinRoomInventoryResponse = new CheckinRoomInventoryResponse();

  roomGoodsTempletRequest: RoomGoodsTempletRequest = new RoomGoodsTempletRequest();

  can_show: string;
  load_error: boolean = false;

  iframe;
  //该房型是否有配置物品清单
  roomTypeGoodsFlag: boolean = true;

  frame_name = "frame_" + Math.random();

  offset_x: Number = 0;
  offset_y: Number = 0;
  show: string = 'none';
  timer: any;
  isNeedFilter: boolean = false;

  constructor(private roomService: RoomService,
              private activatedRoute: ActivatedRoute,
              private workService: WorkService,
              private leaseService: LeaseService,
              private http: HttpClient,
              private authService: AuthService,
              private sanitizer: DomSanitizer,
              private router: Router,
              private baseInfoService: BaseInfoService,
              private userService: UserService,
              private signedService: SignedService) {
    if (this.activatedRoute.params['value']['lease_id']) {
      this.lease_id = this.activatedRoute.params['value']['lease_id'];
      this.room_id = this.activatedRoute.params['value']['room_id'];
      this.lease_room_id = this.activatedRoute.params['value']['lease_room_id'];
      this.checkinRoomInventoryRequest.lease_id = this.lease_id;
      this.checkinRoomInventoryRequest.room_id = this.room_id;
    }
    this.userService.isHotWater({project_id: this.authService.getProjectId()}).then(data => {
      this.isNeedFilter = data.hot_water;
    });
  }

  ngOnInit() {
    // let agent = getUserAgent();
    // this.can_show = agent.split("|")[1];

    this.roomService.queryRoomAssetStatus({leaseId: this.lease_id, roomId: this.room_id, leaseRoomId: this.lease_room_id}).then(data => {

      if (data && data.lease_room_info_dto) {
        if (data.lease_room_info_dto.asset_confirm === 'UNCONFIRMED') {//入住时确认
          this.type = 'sign';
          this.getGoodList();
        } else {//确认完毕后查看
          this.type = 'view';
          this.showPDF();
          this.getRemarks();
        }
      }
    });
  }

  /**
   * 根据床型获取物品清单
   */
  getGoodList() {
    this.roomService.getRoomTypeGoodsList(this.checkinRoomInventoryRequest).then(res => {
      this.checkinRoomInventoryResponse = res;
      if (res && res.room_type_goods_dtolist) {
        this.goodsList = res.room_type_goods_dtolist;
        this.goodsList.forEach(e => {
          e.initHave = true;
          e.initBad = 0;
          e.isHave = true;
          e.isBad = 0;
          e.focus = false;
          if (this.goodsMap.has(e.position)) {
            this.goodsMap.get(e.position).push(e);
          } else {
            this.goodsMapKey.push(e.position);
            let tempArray: Array<RoomTypeGoodsDTO> = [];
            tempArray.push(e);
            this.goodsMap.set(e.position, tempArray);
          }
        });
      }

      // 没有最新水表读数 那就30S刷新一次 获取最新读数
      if (this.checkinRoomInventoryResponse.need_refresh_reading) {
        setTimeout(() => {
          this.getGoodList();
        }, 30 * 1000);
      }

    }).catch(error => {
      let messageModalInfo = new MessageModalInfo();
      messageModalInfo.operationMessage = error.message;
      messageModalInfo.returnUrl = '/system_manage/signed/pact/' + this.lease_id;
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
    });
  }

  checkGoods(key: string, index: number, flag: string, $event) {
    clearTimeout(this.timer);
    if (flag === 'have') {
      this.goodsMap.get(key)[index].isHave = !this.goodsMap.get(key)[index].isHave;
    } else {
      this.goodsMap.get(key)[index].isBad = this.goodsMap.get(key)[index].isBad === 1 ? 0 : 1;
    }
    this.show = 'block';
    this.offset_y = $($event.target).parent().position().top + $($event.target).parent().height() + 10;
    this.offset_x = $($event.target).parent().position().left + (($($event.target).parent().width() - 100) / 2);
    this.timer = setTimeout(() => {
      this.show = 'none';
    }, 1000);
  }

  /**
   * 提交确认清单和签名信息
   * @param imgStr
   */
  commitSign(imgStr: string) {
    if (imgStr == null) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请先填写签名后再点击提交！'));
      return false;
    }
    if (this.checkinRoomInventoryResponse.water_dev || this.checkinRoomInventoryResponse.need_water === 'YES') {
      if (!this.roomGoodsTempletRequest.water_reading && !this.checkinRoomInventoryResponse.water_reading) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请填写水表读数'));
        return false;
      }
      // if (!new RegExp(RegularExp.number).test(this.roomGoodsTempletRequest.water_reading)) {
      //   this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '水表读数请填写数字'));
      //   return false;
      // }
      if (!RegularExp.numberPointTwo.test(this.roomGoodsTempletRequest.water_reading) && !this.checkinRoomInventoryResponse.water_reading) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '水表读数最多保留两位小数'));
        return false;
      }
    }
    if (this.checkinRoomInventoryResponse.electricity_dev || this.checkinRoomInventoryResponse.need_electricity === 'YES') {
      if (!this.roomGoodsTempletRequest.electricity_reading && !this.checkinRoomInventoryResponse.electricity_reading) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请填写电表读数'));
        return false;
      }
      // if (!new RegExp(RegularExp.number).test(this.roomGoodsTempletRequest.electricity_reading)) {
      //   this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '电表读数请填写数字'));
      //   return false;
      // }
      if (!RegularExp.numberPointTwo.test(this.roomGoodsTempletRequest.electricity_reading)
        && !this.checkinRoomInventoryResponse.electricity_reading) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '电表读数最多保留两位小数'));
        return false;
      }
    }
    // if(!this.roomGoodsTempletRequest.electricity_reading || !this.roomGoodsTempletRequest.water_reading){
    //   this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请填写水电表读数'));
    //   return false;
    // }
    // if(!new RegExp(RegularExp.number).test(this.roomGoodsTempletRequest.electricity_reading)
    //   || !new RegExp(RegularExp.number).test(this.roomGoodsTempletRequest.water_reading)){
    //   this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '水电表读数请填写数字'));
    //   return false;
    // }
    // if(this.roomGoodsTempletRequest.electricity_reading.toString().split(".")[1].length > 2
    //   || this.roomGoodsTempletRequest.water_reading.toString().split(".")[1].length > 2){
    //   this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '水电表读数最多保留两位小数'));
    //   return false;
    // }

    //发送签名字符串，上传
    this.roomGoodsTempletRequest.room_id = this.room_id;
    this.roomGoodsTempletRequest.lease_id = this.lease_id;
    this.roomGoodsTempletRequest.img_str = imgStr;
    let array: Array<RoomTypeGoodsDTO> = [];
    for (let key of this.goodsMapKey) {
      let tempArray: Array<RoomTypeGoodsDTO> = [];
      tempArray = this.goodsMap.get(key);
      tempArray.forEach(e => {
        if (e.isHave) {
          array.push(e);
        }
      });
    }
    this.roomGoodsTempletRequest.room_goods_info_dto_list = array;
    this.roomService.saveCheckINGoods(this.roomGoodsTempletRequest).then(res => {
      let messageModalInfo = new MessageModalInfo();
      messageModalInfo.type = 2;
      messageModalInfo.title = "交接确认";
      messageModalInfo.html = `
      <div class='modal-body text-center'>
        <p class='text-dark mt-3 mb-3 font16'>确认成功！</p>
        <p class='text-dark mt-3 mb-3 font16'>关闭弹窗后可打印交接单，也可在合同中“查看房间明细”中找到此单据再次打印</p>
      </div>
    `;
      messageModalInfo.callback.subscribe(() => {
        this.type = "view";
        this.ngOnInit();
      });
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));

    });
  }

  /**
   * 展示pdf
   */
  showView() {
    if (this.path) {
      this.iframe = this.path;
    } else {
      this.roomTypeGoodsFlag = false;
    }
  }

  /**
   * 展示入住物品交接单的pdf
   */
  showPDF() {
    //判断是否存在快照
    this.roomService.isExistSnap({
      lease_id: this.lease_id,
      service_type: 'CHECKIN_CONNECT',
      object_id: this.lease_room_id
    }).then(result => {
      if (result.exist_snap) {//存在快照:从阿里云服务器获取快照
        this.leaseService.getContractUrl({
          lease_id: this.lease_id,
          service_type: 'CHECKIN_CONNECT',
          object_id: this.lease_room_id
        })
          .then(data => {
            if (data.image_url) {
              this.path = data.image_url;
              this.showView();
            } else {
              this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '不存在此物品交接单的PDF记录'));
            }
          });
      } else {//不存在快照：通过freemaker渲染
        this.path = `${environment.apiHost}` + 'file/preview?bizcode=CHECKININVENTORY&leaseId='
          + this.lease_id + '&roomId=' + this.room_id + '&type=dynamic&imgStr=' + '&' + Math.ceil(Math.random() * 100);
        this.showView();
      }
    }).catch(error => {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, error.message));
    });
  }

  /**
   * 打印
   */
  printFrame() {
    this.pdfView.print();
  }

  /**
   * 线下确认
   */
  alertTips() {
    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.type = 2;
    messageModalInfo.title = "交接确认";
    messageModalInfo.cancel = true;
    messageModalInfo.html = `
      <div>确认已打印并线下已签署纸质物品交接单？</div>
    `;
    messageModalInfo.callback.subscribe(() => {
      this.commitSign("");
    });
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
  }

  /**
   * 提交备注
   */
  commitRemark() {
    if (!this.remark) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "请填写内容"));
      return;
    }
    if (this.remark.length > 60) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, "备注内容不能超过60个字符，请修改后重新提交！"));
      return;
    }
    this.roomService.insertRemark({
      lease_id: this.lease_id,
      room_id: this.room_id,
      remark: this.remark
    }).then(data => {
      this.remark = '';
      this.remarksModal.hide();
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, "备注提交成功"));
      this.getRemarks();
    });
  }

  /**
   * 获取备注列表
   */
  getRemarks() {
    this.roomService.queryRemarkList({
      lease_id: this.lease_id,
      room_id: this.room_id
    }).then(data => {
      if (data && data.room_goods_remark_dtolist) {
        this.remarkList = data.room_goods_remark_dtolist;
      }
    });
  }

  errorHandle() {
    this.can_show = "false";
    this.load_error = true;
    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.operationMessage = "获取PDF报错";
    // messageModalInfo.returnUrl = '/system_manage/signed/pact/' + this.lease_id;
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
  }

  // 在ipad上签名
  signOnIpad() {
    // 页面操作的数据
    if (this.checkinRoomInventoryResponse.water_dev || this.checkinRoomInventoryResponse.need_water === 'YES') {
      if (!this.roomGoodsTempletRequest.water_reading && !this.checkinRoomInventoryResponse.water_reading) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请填写水表读数'));
        return false;
      }
      if (!RegularExp.numberPointTwo.test(this.roomGoodsTempletRequest.water_reading) && !this.checkinRoomInventoryResponse.water_reading) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '水表读数最多保留两位小数'));
        return false;
      }
    }
    if (this.checkinRoomInventoryResponse.electricity_dev || this.checkinRoomInventoryResponse.need_electricity === 'YES') {
      if (!this.roomGoodsTempletRequest.electricity_reading && !this.checkinRoomInventoryResponse.electricity_reading) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请填写电表读数'));
        return false;
      }
      if (!RegularExp.numberPointTwo.test(this.roomGoodsTempletRequest.electricity_reading)
        && !this.checkinRoomInventoryResponse.electricity_reading) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '电表读数最多保留两位小数'));
        return false;
      }
    }
    // 操作的数据
    let request_data = Object.assign({}, this.roomGoodsTempletRequest);
    //发送签名字符串，上传
    request_data.room_id = this.room_id;
    request_data.lease_id = this.lease_id;
    request_data.img_str = "";
    let array: Array<RoomTypeGoodsDTO> = [];
    for (let key of this.goodsMapKey) {
      let tempArray: Array<RoomTypeGoodsDTO> = [];
      tempArray = this.goodsMap.get(key);
      tempArray.forEach(e => {
        if (e.isHave) {
          array.push(e);
        }
      });
    }
    request_data.room_goods_info_dto_list = array;

    let request = new SignOnIpadRequest();
    request.sign_router_link_type = 'GOODS_LIST';
    request.router_link = this.router.url;
    request.response_data = this.checkinRoomInventoryResponse;
    request.operate_data = request_data;
    let signDataRequest = new SignDataRequest();
    signDataRequest.lease_id = this.lease_id;
    signDataRequest.room_id = this.room_id;
    signDataRequest.lease_room_id = this.lease_room_id;
    request.sign_data_request = signDataRequest;
    request.computer_no = localStorage.getItem('PC_UUID');
    /*this.signedService.signOnIpad(request).then(data => {
      console.info(data);
    });*/

    // 打开连接弹窗
    this.signDeviceBindingComponent.openModel(request, 1);
  }

  // 判断当前设备是否是ipad
  isPad(): boolean {
    const userAgentInfo = navigator.userAgent;
    const Agents = ["Android", "iPhone",
      "SymbianOS", "Windows Phone",
      "iPad", "iPod"];
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        return true;
      }
    }
    return false;
  }

  changeFocus(e) {
    if (!new RegExp(RegularExp.signlessInteger).test(e.quantity + "") || e.quantity < 0) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '数量必须大于等于0'));
      e.quantity = 0;
    }
    e.focus = !e.focus;
  }

}
