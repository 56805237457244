<div class="setbox">
  <div class="row">
    <div class="col-12 mb10">
      <button class="btn btn-warning" type="button" (click)="switch()">
        <i class="fa fa-refresh" aria-hidden="true"></i>切换至
        <ng-container *ngIf="type === 'CLEAN'">维修</ng-container>
        <ng-container *ngIf="type === 'REPAIR'">保洁</ng-container>
      </button>
      <button class="btn btn-info" type="button" (click)="downloadRepair()">
        <i class="fa fa-download"></i> 下载
      </button>
    </div>
  </div>


  <ng-container *ngIf="type === 'CLEAN'">
    <!--<div class="setbox">-->
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-xl-2">
        <div class="form-group">
          <label>查询条件</label>
          <input type="text" class="form-control" placeholder="请输入楼栋号、房间号、手机号码" [(ngModel)]="repairReq.repair_param">
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-2 col-xl-1">
        <div class="form-group">
          <label>保洁类型</label>
          <div>
            <select class="w-100" [(ngModel)]="cleanServiceType">
              <option value="">全部</option>
              <option *ngFor="let item of cleanServiceTypeList" [value]="item.id">{{item.name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-2 col-xl-1">
        <div class="form-group">
          <label>状态</label>
          <div>
            <select (change)="statusSet($event)" class="w-100" [(ngModel)]="checkStatus">
              <option value="ALL">全部</option>
              <option value="WAITING">未受理</option>
              <option value="EFFECTIVE">已受理</option>
              <option value="FIXED">已完成</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-xl-4">
        <div class="form-group">
          <label>
            <input type="radio" [(ngModel)]="repairReq.time_type" [checked]="repairReq.time_type==1" [value]="1"> 预约日期
            <input type="radio" [(ngModel)]="repairReq.time_type" [checked]="repairReq.time_type==2" [value]="2"> 申请日期
          </label>
          <div>
            <div class="d-inline-block w-45">
              <my-date-picker placeholder="开始日期" [(ngModel)]="reservation_start_time"
                              [options]="myDatePickerOptions">
              </my-date-picker>
            </div>
            <div class="ml-2 mr-2 d-inline-block align-top">-</div>
            <div class="d-inline-block w-45">
              <my-date-picker placeholder="结束日期" [(ngModel)]="reservation_end_time"
                              [options]="myDatePickerOptions">
              </my-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-xl-2">
        <div class="form-group">
          <label>保洁员</label>
          <div>
            <select class="w-100" [(ngModel)]="repairReq.cleaner">
              <option [value]="item?.id" *ngFor="let item of workers">{{item?.user_name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3 col-xl-2">
        <div class="form-group">
          <label class="w-100 d-web">&nbsp;</label>
          <button class="btn btn-info  " type="button" (click)="search1()">
            <i class="fa fa-search"></i>搜索
          </button>
          <button class="btn btn-secondary " type="button" (click)="clear1()">
            <i class="fa fa-times"></i>清空条件
          </button>
        </div>
      </div>
    </div>
    <!-- table -->
    <table class="table table-bordered table-striped">
      <thead>
      <tr>
        <th>编号</th>
        <th class="show2">楼栋房间号</th>
        <th class="show2">保洁类型</th>
        <th class="show2">预约时间</th>
        <th>提交时间</th>
        <th>保洁员</th>
        <th>用户评价</th>
        <th class="show2">状态</th>
        <th class="show2">操作</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="show1" colspan="5" *ngIf="!btn_privileges.REPAIR_QUERY || total1 == 0">本次未查询到相关数据</td>
        <td class="show2" colspan="9" *ngIf="!btn_privileges.REPAIR_QUERY || total1 == 0">本次未查询到相关数据</td>
      </tr>
      <ng-container *ngIf="btn_privileges.REPAIR_QUERY && repairOverHoursDto">
        <tr *ngFor="let item of  repairOverHoursDto | paginate: {itemsPerPage: repairReq?.page_size,
        currentPage: repairReq?.page_num, totalItems:total1, id : 'clean' }">
          <td>{{item?.repair_id}}</td>
          <td class="show2">{{item?.unit_name}}>{{item?.room_num}}</td>
          <td class="show2">
            <ng-container
              *ngIf="item?.repair_species_type">{{item?.repair_species_type == 'DAILY_CLEAN' ? '日常保洁' : '深度保洁'}}</ng-container>
            <ng-container *ngIf="!item?.repair_species_type">{{item?.type_name}}</ng-container>
          </td>
          <td class="show2">{{item?.reservation_start_time | date: 'yyyy-MM-dd HH:mm'}}
            ~{{item?.reservation_end_time | date: 'HH:mm'}}</td>
          <td>{{item?.create_time | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
          <td>
            <span *ngIf="item?.over_name != null">{{item?.over_name}}</span>
            <span *ngIf="item?.over_name == null">--</span>
          </td>
          <td>
            <ng-container *ngIf="item.clean_type_id && item.clean_type_id==-1">
              /
            </ng-container>
            <ng-container *ngIf="!item.clean_type_id || item.clean_type_id!=-1">
              <span
                *ngIf="item?.repair_comment_do?.score_attitude != null && item?.repair_comment_do?.score_quality!= null">
                态度{{item?.repair_comment_do?.score_attitude}}星;质量{{item?.repair_comment_do?.score_quality}}星
              </span>
              <span
                *ngIf="item?.repair_comment_do?.score_attitude == null && item?.repair_comment_do?.score_quality== null">--</span>
            </ng-container>
          </td>
          <td class="show2">
            <span class="badge badge-danger badge-pill" *ngIf="item?.repair_status == 'WAITING'">未受理</span>
            <span class="badge badge-success badge-pill" *ngIf="item?.repair_status == 'EFFECTIVE'">已受理</span>
            <span class="badge badge-secondary badge-pill" *ngIf="item?.repair_status == 'FIXED'">已完成</span>
            <span class="badge badge-secondary badge-pill" *ngIf="item?.repair_status == 'INVALID'">已拒绝</span>
          </td>
          <td class="show2">
            <button *ngIf="btn_privileges.CLEAN_DETAIL" class="btn btn-info" type="button"
                    (click)="showRepairModal(item)">
              <i class="fa fa-eye"></i>查看
            </button>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
    <div class="text-right pages" *ngIf="btn_privileges.REPAIR_QUERY && repairOverHoursDto">
      <pagination-controls id="clean" *ngIf="total1 !== 0" (pageChange)="getRepairPage1($event)"
                           directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                           screenReaderPaginationLabel="Pagination"
                           screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"></pagination-controls>
      <div class="text-right">共有{{total1}}条记录</div>
    </div>
    <!--</div>-->
  </ng-container>

  <ng-container *ngIf="type === 'REPAIR'">
    <!--<div class="setbox">-->
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-xl-2">
        <div class="form-group">
          <label>查询条件</label>
          <input type="text" class="form-control" placeholder="请输入楼栋号、房间号、手机号码" [(ngModel)]="repairReq.repair_param">
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-2 col-xl-1">
        <div class="form-group">
          <label>状态</label>
          <div>
            <select (change)="statusSet($event)" class="w-100" [(ngModel)]="checkStatus">
              <option value="ALL">全部</option>
              <option value="WAITING">未处理</option>
              <option value="EFFECTIVE">处理中</option>
              <option value="FIXED">已处理</option>
              <option value="MANUAL">手动分配</option>
              <option value="UNDELIVER">未分配</option>
              <option value="IGNORE">无需处理</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-2 col-xl-1">
        <div class="form-group">
          <label>紧急程度</label>
          <div>
            <select class="w-100" [(ngModel)]="repairReq.urgency_degree">
              <option value="">全部</option>
              <option value="COMMON">一般</option>
              <option value="URGENCY">紧急</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-xl-4">
        <div class="form-group">
          <label>
            <input type="radio" [(ngModel)]="repairReq.time_type" [checked]="repairReq.time_type==1" [value]="1"> 预约日期
            <input type="radio" [(ngModel)]="repairReq.time_type" [checked]="repairReq.time_type==2" [value]="2"> 申请日期
          </label>
          <div class="">
            <div class="d-inline-block w-45">
              <my-date-picker placeholder="开始日期" [(ngModel)]="reservation_start_time"
                              [options]="myDatePickerOptions">
              </my-date-picker>
            </div>
            <div class="ml-2 mr-2 d-inline-block align-top">-</div>
            <div class="d-inline-block w-45">
              <my-date-picker placeholder="结束日期" [(ngModel)]="reservation_end_time"
                              [options]="myDatePickerOptions">
              </my-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-xl-2">
        <div class="form-group">
          <label>维修员</label>
          <div>
            <select class="w-100" [(ngModel)]="repairReq.cleaner">
              <option [value]="item?.id" *ngFor="let item of workers">{{item?.user_name}}</option>
            </select>
          </div>
        </div>
      </div>


      <div class="col-12 col-sm-6 col-md-4 col-xl-2 ">
        <div class="form-group">
          <label class="w-100 d-web">&nbsp;</label>
          <button class="btn btn-info " type="button" (click)="search1()">
            <i class="fa fa-search"></i>搜索
          </button>
          <button class="btn btn-secondary " type="button" (click)="clear1()">
            <i class="fa fa-times"></i>清空条件
          </button>
        </div>
      </div>
    </div>
    <!-- table -->
    <table class="table table-bordered table-striped">
      <thead>
      <tr>
        <th>编号</th>
        <th class="show2">楼栋房间号</th>
        <th class="show2">紧急程度</th>
        <th class="show2">预约时间</th>
        <th>提交时间</th>
        <th>维修员</th>
        <th>维修结果</th>
        <th>用户评价</th>
        <th class="show2">状态</th>
        <th class="show2">操作</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="show1" colspan="5" *ngIf="!btn_privileges.REPAIR_QUERY || total1 == 0">本次未查询到相关数据</td>
        <td class="show2" colspan="10" *ngIf="!btn_privileges.REPAIR_QUERY || total1 == 0">本次未查询到相关数据</td>
      </tr>
      <ng-container *ngIf="btn_privileges.REPAIR_QUERY && repairOverHoursDto">
        <tr *ngFor="let item of repairOverHoursDto  | paginate: {itemsPerPage: repairReq?.page_size,
        currentPage: repairReq?.page_num, totalItems:total1, id : 'repair' }">
          <td>{{item?.repair_id}}</td>
          <td class="show2">{{item?.unit_name}}>{{item?.room_num}}</td>
          <td class="show2">{{item?.urgency_degree === 'COMMON' ? '一般' : '紧急'}}</td>
          <td class="show2">
            {{item?.reservation_start_time | date: 'yyyy-MM-dd HH:mm'}}~{{item?.reservation_end_time | date: 'HH:mm'}}
          </td>
          <td>{{item?.create_time | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
          <td>
            <span *ngIf="item?.over_name != null">{{item?.over_name}}</span>
            <span *ngIf="item?.over_name == null">--</span>
          </td>
          <th>
            <span *ngIf="item?.repair_status == 'FIXED'">维修完成</span>
            <span *ngIf="item?.repair_status == 'IGNORE'">无需维修</span>
            <span *ngIf="item?.repair_status == 'INVALID'" style="color:red">放弃维修</span>
          </th>
          <td>
          <span
            *ngIf="item?.repair_comment_do?.score_attitude != null && item?.repair_comment_do?.score_quality!= null">
            态度{{item?.repair_comment_do?.score_attitude}}星;质量{{item?.repair_comment_do?.score_quality}}星
          </span>
            <span
              *ngIf="item?.repair_comment_do?.score_attitude == null && item?.repair_comment_do?.score_quality== null">--</span>
          </td>
          <td class="show2">
            <span class="badge badge-danger badge-pill" *ngIf="item?.repair_status == 'MANUAL'">手动分配</span>
            <span class="badge badge-danger badge-pill" *ngIf="item?.repair_status == 'UNDELIVER'">未分配</span>
            <span class="badge badge-danger badge-pill" *ngIf="item?.repair_status == 'WAITING'">未处理</span>
            <span class="badge badge-success badge-pill" *ngIf="item?.repair_status == 'EFFECTIVE'">处理中</span>
            <span class="badge badge-secondary badge-pill" *ngIf="item?.repair_status == 'FIXED'">处理完成</span>
            <span class="badge badge-secondary badge-pill"
                  *ngIf="item?.repair_status == 'IGNORE' || item?.repair_status == 'INVALID'">无需处理</span>
          </td>
          <td class="show2" *ngIf="btn_privileges.REPAIR_DETAIL">
            <button class="btn btn-info" type="button" (click)="showRepairModal(item)">
              <i class="fa fa-eye"></i><span
              *ngIf="item.repair_status == 'FIXED' || item.repair_status == 'IGNORE' || item.repair_status == 'INVALID'">查看</span><span
              *ngIf="item.repair_status != 'FIXED' && item.repair_status != 'IGNORE' && item.repair_status != 'INVALID'">处理</span>
            </button>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
    <div class="text-right pages" *ngIf="btn_privileges.REPAIR_QUERY && repairOverHoursDto">
      <pagination-controls id="repair" *ngIf="total1 !== 0" (pageChange)="getRepairPage1($event)"
                           directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                           screenReaderPaginationLabel="Pagination"
                           screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"></pagination-controls>
      <div class="text-right">共有{{total1}}条记录</div>
    </div>
    <!--</div>-->
  </ng-container>
</div>

<!--  处理维修/保洁信息  -->
<div class="modal fade" bsModal #template5="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{repair_type}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="template5.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="row">
          <div class="col-12 mb10">房间：{{repairOverHours?.unit_name}}>{{repairOverHours?.room_num}}</div>
          <div *ngIf="repairOverHours?.coming_time_str && repair_type_type === 'REPAIR'"
               class="col-12 col-md-4 col-lg-4 mb10">上门时间：{{repairOverHours?.coming_time_str}}</div>
          <div *ngIf="repairOverHours?.repair_place && repair_type_type === 'REPAIR'"
               class="col-12 col-md-4 col-lg-4 mb10">报修性质：
            {{repairOverHours?.repair_place === "ROOM" ? "房间报修" : "公区报修"}}
          </div>
          <div class="col-12 col-md-4 mb10">提交人：{{repairOverHours?.create_name}}</div>
          <div class="col-12 col-md-4 mb10">申请时间：{{repairOverHours?.create_time | date:'yyyy-MM-dd HH:mm:ss'}}</div>
          <div class="col-12 col-md-4 mb10">联系方式：{{repairOverHours?.contact_phone}}</div>

          <!--维修-->
          <div *ngIf="repair_type_type === 'REPAIR'" class="col-12 col-md-4 col-lg-4 mb10">
            紧急程度：
            <span *ngIf="repairOverHours.urgency_degree === 'COMMON'">一般</span>
            <span *ngIf="repairOverHours.urgency_degree === 'URGENCY'">紧急</span>
          </div>
          <div *ngIf="repair_type_type === 'REPAIR'" class="col-12 col-md-4 col-lg-4 mb10">保修类型：
            <ng-container *ngIf="repairOverHours?.type_name">
              {{repairOverHours?.type_name}}
            </ng-container>
            <ng-container *ngIf="!repairOverHours?.type_name">
              <span *ngIf="repairOverHours?.repair_species_type === 'WATER'">水</span>
              <span *ngIf="repairOverHours?.repair_species_type === 'ELECTRIC'">电</span>
              <span *ngIf="repairOverHours?.repair_species_type === 'OTHER'">其他</span>
              <span *ngIf="repairOverHours?.repair_species_type === 'FURNITURE'">家具</span>
              <span *ngIf="repairOverHours?.repair_species_type === 'BATHITEMS'">浴室物品</span>
              <span *ngIf="repairOverHours?.repair_species_type === 'SUNDRIES'">墙体/地面/天花板</span>
              <span *ngIf="repairOverHours?.repair_species_type === 'TUBEDEMAGE'">灯管损坏</span>
            </ng-container>
          </div>

          <!--保洁-->
          <div *ngIf="repair_type_type === 'CLEAN'" class="col-12 col-md-4 col-lg-4 mb10">
            预约项目：
            <ng-container *ngIf="repairOverHours?.repair_species_type">
              {{repairOverHours?.repair_species_type == 'DAILY_CLEAN' ? '日常保洁' : '深度保洁'}}
            </ng-container>
            <ng-container *ngIf="!repairOverHours?.repair_species_type">
              {{repairOverHours?.type_name}}
            </ng-container>
          </div>

          <ng-container *ngIf="repairOverHours?.clean_type_id&&repairOverHours?.clean_type_id==-1">
            <div class="col-12 col-md-4 mb10">
              支付金额：无需付
            </div>
            <div class="col-12 mb10">
              预约时间：{{repairOverHours?.reservation_start_time | date: 'yyyy-MM-dd HH:mm'}}
              ~{{repairOverHours?.reservation_end_time | date: 'HH:mm'}}
            </div>
          </ng-container>
          <ng-container *ngIf="!repairOverHours?.clean_type_id||repairOverHours?.clean_type_id!=-1">
            <div class="col-12 col-md-6 mb10">
              预约时间：{{repairOverHours?.reservation_start_time | date: 'yyyy-MM-dd HH:mm'}}
              ~{{repairOverHours?.reservation_end_time | date: 'HH:mm'}}
            </div>
          </ng-container>


          <div class="col-12 mb10">
            备注信息：{{repairOverHours?.remark}}
          </div>

          <div class="col-12 mb10"
               *ngIf="repairOverHours?.repair_images && repairOverHours?.repair_images.length > 0 && repair_type_type === 'REPAIR' ">
            <div class="col-12 mb10 px-0">照片附件：</div>
            <ng-container *ngFor="let item of repairOverHours?.repair_images;let i=index">
              <img src="{{item}}" (click)="openGallery(repairOverHours?.repair_images, i)"
                   style="height: 80px;width: 80px">
            </ng-container>
          </div>


        </div>
        <div class="con">
          <div class="row">

            <div *ngIf="repair_type_type === 'CLEAN'" class="col-12 col-md-12 col-lg-12 mb10 is">保洁员信息</div>
            <div *ngIf="repair_type_type === 'CLEAN'" class="col-12 col-md-6 col-lg-6 mb10">
              保洁员 ：{{repairOverHours?.over_name}}
            </div>
            <div *ngIf="repair_type_type === 'REPAIR'" class="col-12 col-md-12 col-lg-12 mb10 is">维修员信息</div>
            <div *ngIf="repair_type_type === 'REPAIR'" class="col-12 col-md-6 col-lg-6 mb10">
              维修员 ：{{repairOverHours?.over_name}}
            </div>
            <div class="col-12 col-md-6 col-lg-6 mb10">
              受理时间 ：{{repairOverHours?.receipt_time |date:'yyyy-MM-dd HH:mm:ss'}}
            </div>
          </div>
        </div>
        <div class="con" *ngIf="repair_type_type === 'REPAIR' && repairOverHours?.repair_scheme_dto">
          <div class="row">
            <div class="col-12 mb10 is">维修方案</div>
            <div class="col-12 col-md-6 mb10">
              费用承担方：<span *ngIf="repairOverHours?.repair_scheme_dto?.payer === 'LESSEE'">租户</span><span
              *ngIf="repairOverHours?.repair_scheme_dto?.payer === 'FLAT'">公寓</span>
            </div>
            <div class="col-12 col-md-6 mb10">
              预计维修完成时间：{{repairOverHours?.plan_fix_time|date:'yyyy-MM-dd HH:mm:ss'}}
            </div>
            <div class="col-12 col-md-6 mb10" *ngIf="repairOverHours?.repair_scheme_dto?.fee_material">
              材料费：{{repairOverHours?.repair_scheme_dto?.fee_material}}
            </div>
            <div class="col-12 col-md-6 mb10" *ngIf="repairOverHours?.repair_scheme_dto?.fee_labor">
              人工费：{{repairOverHours?.repair_scheme_dto?.fee_labor}}
            </div>
          </div>
        </div>
        <div class="con">
          <div class="row">
            <div *ngIf="repair_type_type === 'CLEAN'" class="col-12 mb10 is">保洁员备注</div>
            <div *ngIf="repair_type_type === 'REPAIR'" class="col-12 mb10 is">维修员备注</div>
            <div class="col-12 mb10 i" *ngFor="let info of repairOverHours?.repair_remark_dolist">
              <div class="col-12 mb10 my"> {{info?.create_time|date:'yyyy-MM-dd HH:mm:ss'}}</div>
              <div class="col-12 mb10 ">   {{info?.remark}}</div>
              <ng-container *ngFor="let item of info?.remark_imgs;let i=index">
                <img src="{{item}}" (click)="openRemarkImage(info?.remark_imgs, i)"
                     style="height: 100px;width: 100px">
              </ng-container>
              <hr size="1" style="border:1px #cccccc dashed;">
            </div>

          </div>
        </div>
        <div class="con">
          <div class="row">
            <div *ngIf="repair_type_type === 'CLEAN'" class="col-12 mb10 is">保洁结果</div>
            <div *ngIf="repair_type_type === 'CLEAN'" class="col-12 mb10">
              完成时间：{{repairOverHours?.fix_time |date:'yyyy-MM-dd HH:mm:ss'}}
            </div>
            <div *ngIf="repair_type_type === 'REPAIR'" class="col-12 mb10 is">维修结果</div>
            <div *ngIf="repair_type_type === 'REPAIR'" class="col-12 col-md-6  mb10">
              处理结果：
              <span *ngIf="repairOverHours?.repair_status === 'FIXED'">维修完成</span>
            </div>
            <div *ngIf="repair_type_type === 'REPAIR'" class="col-12 col-md-6 mb10">
              完成时间：{{repairOverHours?.fix_time |date:'yyyy-MM-dd HH:mm:ss'}}
            </div>
            <div *ngIf="repair_type_type === 'REPAIR'" class="col-12  mb10">
              费用承担方：<span *ngIf="repairOverHours?.repair_result_dto?.payer === 'LESSEE'">租户</span><span
              *ngIf="repairOverHours?.repair_result_dto?.payer === 'FLAT'">公寓</span>
            </div>
            <div *ngIf="repair_type_type === 'REPAIR'&&repairOverHours?.repair_result_dto?.fee_material"
                 class="col-12 col-md-6 col-lg-6 mb10">
              材料费：{{repairOverHours?.repair_result_dto?.fee_material }}
            </div>
            <div *ngIf="repair_type_type === 'REPAIR'&&repairOverHours?.repair_result_dto?.fee_labor"
                 class="col-12 col-md-6 col-lg-6 mb10">
              人工费：{{repairOverHours?.repair_result_dto?.fee_labor}}
            </div>
          </div>
        </div>

        <div class="con" *ngIf="repair_type_type === 'CLEAN' ">
          <div class="row">
            <div class="col-12 mb10 is">
              保洁前照片：

              <span *ngIf="repairOverHours?.before_repair_images && repairOverHours?.before_repair_images.length > 0">
                 <ng-container *ngFor="let item of repairOverHours?.before_repair_images;let i=index">
                    <img src="{{item}}" (click)="openGallery(repairOverHours?.before_repair_images, i)"
                         style="height: 80px;width: 80px">
                 </ng-container>
              </span>

              <span class="col-12 mb10 is" *ngIf="!repairOverHours?.before_repair_images
                || repairOverHours?.before_repair_images.length <=  0">
                无
              </span>
            </div>

            <div class="col-12 mb10 is">
              保洁后照片：
              <span *ngIf="repairOverHours?.after_repair_images && repairOverHours?.after_repair_images.length > 0">
                  <ng-container *ngFor="let item of repairOverHours?.after_repair_images;let i=index">
                     <img src="{{item}}" (click)="openGallery(repairOverHours?.after_repair_images, i)"
                          style="height: 80px;width: 80px">
                  </ng-container>
              </span>

              <span class="col-12 mb10 is" *ngIf="!repairOverHours?.after_repair_images
                || repairOverHours?.after_repair_images.length <= 0">
                无
              </span>
            </div>
          </div>
        </div>

        <div class="con">
          <div class="row">
            <div class="col-12 mb10 is">用户评价</div>

            <ng-container *ngIf="repairOverHours?.clean_type_id&&repairOverHours?.clean_type_id==-1">
              <div class="col-12 col-md-6 mb10">
                态度：/
              </div>
              <div class="col-12 col-md-6 mb10">
                质量：/
              </div>
            </ng-container>
            <ng-container *ngIf="!repairOverHours?.clean_type_id||repairOverHours?.clean_type_id!=-1">
              <div class="col-12 col-md-6 mb10" *ngIf="repairOverHours?.repair_comment_do?.score_attitude != null">
                态度：{{repairOverHours?.repair_comment_do?.score_attitude}}星
              </div>
              <div class="col-12 col-md-6 mb10" *ngIf="repairOverHours?.repair_comment_do?.score_attitude == null">
                态度：---
              </div>
              <div class="col-12 col-md-6 mb10" *ngIf="repairOverHours?.repair_comment_do?.score_quality != null">
                质量：{{repairOverHours?.repair_comment_do?.score_quality}}星
              </div>
              <div class="col-12 col-md-6 mb10" *ngIf="repairOverHours?.repair_comment_do?.score_quality == null">
                质量：---
              </div>
            </ng-container>

          </div>
        </div>
        <ng-container *ngIf="!repairOverHours?.clean_type_id||repairOverHours?.clean_type_id!=-1">
          <div class="con">
            <div class="row">
              <div class="col-12 mb10 is">回访结果</div>
              <div class="col-12 mb10">
                责任判断方：
                <span *ngIf="repairOverHours?.repair_return_model?.repair_duty === 'STAFF'">工作人员</span>
                <span *ngIf="repairOverHours?.repair_return_model?.repair_duty === 'LESSEE'">客户</span>
              </div>
              <div class="col-12 mb10">
                回访备注：{{repairOverHours?.repair_return_model?.repair_return_memark}}
              </div>
            </div>
          </div>
        </ng-container>

      </div>
      <div class="modal-footer">
        <button
          *ngIf="type === 'REPAIR' && repairOverHours?.repair_status !== 'FIXED' && repairOverHours?.repair_status !== 'IGNORE' && repairOverHours?.repair_status !== 'INVALID'"
          type="button" class="btn btn-primary" (click)="distribution(repairOverHours?.repair_id)">转分配
        </button>
        <ng-container *ngIf="!repairOverHours?.clean_type_id||repairOverHours?.clean_type_id!=-1">
          <button *ngIf="btn_privileges.REPAIR_UPDATE || btn_privileges.CLEAN_UPDATE"
                  type="button" class="btn btn-primary"
                  (click)="showReturn(repairOverHours)"
          >回访录入
          </button>
        </ng-container>
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
                (click)="template5.hide();repair_audit = ''">关闭
        </button>
      </div>

    </div>
  </div>
</div>


<!-- <ngx-image-gallery [images]="images" [conf]="conf"></ngx-image-gallery> -->


<!-- 回访录入-->

<div class="modal fade" bsModal #return="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>回访录入</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="return.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="row">
          <div class="col-12 mb10">
            用户评价低于3课星时，可选择回访，核定真实责任方：
          </div>
          <div class="col-12 mb10">
            责任判断方：
            <select class="w-100" [(ngModel)]="repairAudit" type="checkbox">
              <option ng-selected="checkbox">请选择</option>
              <option value="STAFF">工作人员</option>
              <option value="LESSEE">客户</option>
            </select>
          </div>
          <div class="col-12 mb10">回访备注：</div>
          <div class="col-12">
            <textarea class="w-100" rows="3" [(ngModel)]="returnRemark" minlength="3" maxlength="300"></textarea>
            <span class="text-danger" *ngIf="(!returnRemark)">进度不能为空！</span>
            <span class="text-danger" *ngIf="returnRemark && returnRemark.length >= 100">已超出最大可填写空间！</span>
            <span class="text-danger" *ngIf="returnRemark && returnRemark.length <= 3">不能少于3个字符！</span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="insertReturnRemark(repairOverHours?.repair_id)">提交
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="return.hide()">取消</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade showDistrModal" bsModal #showDistrModal="bs-modal"
     [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>工单转分配</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="showDistrModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="col-12">
          <div>当前维修员：{{repairOverHours?.over_name}}</div>
          <div class="form-group col-12 col-md-12 col-lg-9 repairuser">
            <label for="repairuser" class="w70">分配给:</label>
            <select id="repairuser" class="form-control" [(ngModel)]="repairActiveUser">
              <option value="">请选择</option>
              <option *ngFor="let item of repairUserList" [value]="item.id">{{item.user_name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button"
                    [ngClass]="{'btn': true, 'btn-secondary': repairActiveUser == repairOverHours?.worker, 'btn-primary': repairActiveUser != repairOverHours?.worker}"
                    (click)="confirmDistr()">确 认
            </button>
            <button type="button"
                    (click)="showDistrModal.hide()"
                    class="btn btn-secondary">关 闭
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
