import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";
import {AppointDetailRequest, AppointInfoDTO} from "../../../model/appoint/appoint.model";
import {AppointService} from "../../../service/appoint/appoint.service";
import {IMyDpOptions} from "../../third-party/mydatepicker/src/lib/interfaces";
import {getDateFromPlugin} from "../../../common/date.util";
import {AuthService} from "../../../service/common/auth.service";

@Component({
  selector: 'app-seehouse-detail',
  templateUrl: './seehouse-detail.component.html',
  styleUrls: ['./seehouse-detail.component.css']
})
export class SeehouseDetailComponent implements OnInit {
  @ViewChild('detailModal', { static: true }) detailModal: ModalDirective;
  @Output() auditingChange = new EventEmitter();

  btn_privileges: any = {
    'APPOINT_ROOM_WAIT_VIEW': false,
    'WSEE_HOUSE_OPERATE': false,
  };

  appointDetailRequest: AppointDetailRequest;
  appointDetailResponse: AppointInfoDTO;

  status: string;
  day:any;
  time:Date = new Date();
  isMeridian = false;
  errorMsg:any;

  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: "yyyy-mm-dd",
    editableDateField: false
  };

  constructor(private appointService: AppointService,
              private authService: AuthService) { }

  ngOnInit() {
    this.authService.getOperatePrivilege(this.btn_privileges);
  }

  // 显示当前弹窗
  showModel(id: number) {
    this.appointDetailRequest = new AppointDetailRequest();
    this.appointDetailRequest.id = id;
    this.appointService.detail(this.appointDetailRequest).then(data=>{
      if(data && data.appoint_info_dto){
        this.appointDetailResponse = data.appoint_info_dto;
        if(this.appointDetailResponse.status === 'UNHANDLE'){
          this.status = 'WAITSEEHOUSE';
        }
      }
    });
    this.detailModal.show();
  }

  closeModel() {
    this.detailModal.hide();
  }

  doSubmit(){
    if(this.status === 'WAITSEEHOUSE' && (!this.day || !this.time)){
      this.errorMsg = "请填写看房时间";
      return;
    }
    this.appointDetailRequest.status = this.status;
    if(this.status === 'WAITSEEHOUSE'){
      this.appointDetailRequest.see_house_time = getDateFromPlugin(this.day.formatted);
      this.appointDetailRequest.see_house_time.setHours(this.time.getHours());
      this.appointDetailRequest.see_house_time.setMinutes(this.time.getMinutes());
    }
    this.appointService.operate(this.appointDetailRequest).then(data=>{
      this.detailModal.hide();
      this.auditingChange.emit();
    });
  }

  statusSet(event){
    this.appointDetailRequest.status = event.target.value;
  }

  doSubmitAgain(){
    this.appointService.operate(this.appointDetailRequest).then(data=>{
      this.detailModal.hide();
      this.auditingChange.emit();
    });
  }
}
