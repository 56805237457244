import {environment} from "../../../environments/environment";
import {RequestService} from "../request.service";
import { Injectable } from '@angular/core';
import {NoticeDetailRequest, NoticeEditRequest} from "../../model/notice/notice.model";

@Injectable()
export class NoticeService {
  constructor(private requestService: RequestService) {
  }

  private NOTICE_LIST = `${environment.apiHost}notice/list`;
  private NOTICE_DETAIL = `${environment.apiHost}notice/detail`;
  private NOTICE_SAVE = `${environment.apiHost}notice/save`;


  // 查询列表
  public queryNoticeList(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.NOTICE_LIST, requestData);
  }
  public queryNoticeListWithLoading(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.NOTICE_LIST, requestData, true);
  }

  // 根据id查询公告
  public detail(noticeDetailRequest: NoticeDetailRequest): Promise<any> {
    return this.requestService.authGet(this.NOTICE_DETAIL, noticeDetailRequest);
  }

  // 保存公告
  public save(editRequest: NoticeEditRequest): Promise<any>{
    return this.requestService.authPost(this.NOTICE_SAVE, editRequest);
  }
}
