<div class="setbox-top p-4">
  <div class="row">
    <div class="col-12 col-md-3 bold">{{data?.title}}</div>
    <div class="col-12 col-md-5"></div>
    <div class="col-12 col-md-4">
      <div class="d-inline-block mr-3">总计: {{data?.total}}条</div>
      <div class="d-inline-block mr-3">未付总金额: {{data?.un_paid_amount}}元</div>
      <div class="d-inline-block mr-3"><button class="btn btn-warning" (click)="downLoadDataDetail(data?.type, data?.bill_type)"><i class="fa fa-download"></i>下载</button></div>
    </div>
  </div>
  <div class="mt-3">
    <ng-container *ngIf="data && data?.body">
      <div class="scrollx-head">
        <table class="table table-bordered table-striped scrollx-body">
          <thead>
          <tr>
            <th *ngFor="let item of data?.header">{{item}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of data?.body">
            <td *ngFor="let cell of item">{{cell}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
</div>
