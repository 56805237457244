import { Pipe, PipeTransform } from '@angular/core';
import { BaseEnum } from '../../model/modal/base-model';

/**
 * @Description: 基础枚举类翻译管道
 * @Author: 陆炳彬
 * @Date: 2018/3/29 上午10:25
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/3/29 上午10:25
 */
@Pipe({
  name: 'enumTransfer'
})
export class EnumTransferPipe implements PipeTransform {

  transform(value: string, list: BaseEnum[]): any {
    if (!value) {
      return '';
    }

    if (list) {
      let baseEnums = list.filter(function (item) {
        return item.code === value;
      });

      if (baseEnums) {
        return baseEnums[0].desc;
      }
    }
  }
}
