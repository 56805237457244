
<div class="padd15 yellow" style="background-color: #FFFFE6;">
  <div class="table-box" >
    <table class="table table-bordered border-striped table-fix-scroll v2 " >
      <thead>
      <tr style="background-color: rgba(243, 243, 249, 1);">
        <th>审批单号</th>
        <th>类型</th>
        <th>提交时间</th>
        <th>提交人</th>
<!--        <th>当前审批节点</th>-->
        <th>状态</th>
        <th>操作</th>
      </tr>
      </thead>
      <tbody style="background-color: #fff;">
      <ng-container *ngIf="list&&list.length>0" >
        <tr *ngFor="let item of list">
          <td>{{item.record_no}}</td>
          <td>
            <ng-container *ngIf="item?.type == 'LEASE_CHANGE'">合同信息变更申请</ng-container>
            <ng-container *ngIf="item?.type == 'LEASE_BILL_CHANGE'">费用调整申请</ng-container>
            <ng-container *ngIf="item?.type == 'ROOM_AVAILABLE_TIME_CHANGE'">房间使用期限调整审批</ng-container>
            <ng-container *ngIf="item?.type == 'FROZEN_PASSWORD'">冻结门锁密码审批</ng-container>
            <ng-container *ngIf="item?.type == 'RENTER_CHECKOUT_DEPOSIT'">住客退押审批</ng-container>
            <ng-container *ngIf="item?.type == 'LEASE_COMMIT'">合同审批</ng-container>
          </td>
          <td>{{item.create_time|date:'yyyy-MM-dd HH:mm:ss'}}</td>
          <td>{{item.create_user_name}}</td>
<!--          <td>-->
<!--            <ng-container *ngIf="item?.approv_status == 'WAITING_AUDIT'">-->
<!--              {{item.runtime_position}}-->
<!--            </ng-container>-->
<!--          </td>-->
          <td>{{item.approv_status_name}}</td>
          <td>
            <button class="btn btn-outline-primary" (click)="showDetail(item)">查看</button>

            <button class="btn btn-outline-danger ml-2" *ngIf="item?.approv_status == 'WAITING_AUDIT' && item?.type !=='RENTER_CHECKOUT_DEPOSIT'"
                    (click)="flowCancel(item)">取消</button>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="!list||list.length==0">
        <tr><td colspan="6">暂无记录</td></tr>
      </ng-container>

      </tbody>
    </table>
  </div>
</div>


<app-approv-record-detail-modal></app-approv-record-detail-modal>
