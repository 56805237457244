import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../service/common/auth.service';
import { WorkService } from '../../../service/common/work.service';
import { WorkModel } from '../../../model/common/common.model';
import { RoomGroupService } from '../../../service/roomgroup/roomgroup.service';
import { RoomGroupRelationConfirmRequest, RoomGroupRelationResponse } from '../../../model/roomgroup/room-group.model';

@Component({
  selector: 'app-roomgroup-room-relation-dialog',
  templateUrl: './roomgroup-room-relation-dialog.component.html',
  styleUrls: ['./roomgroup-room-relation-dialog.component.css']
})
export class RoomgroupRoomRelationDialogComponent implements OnInit {

  @Input() groupId;
  @Output() successEvent: EventEmitter<RoomGroupRelationResponse> = new EventEmitter<RoomGroupRelationResponse>();

  @ViewChild("addhouseModal", { static: true }) addhouseModal: ModalDirective;
  @ViewChild("infoModal", { static: true }) infoModal: ModalDirective;

  url = `${environment.apiHost}room/group/importroom`;
  header = {
    Authorization: this.authService.getAuthToken()
  };

  fileList;

  success = false;

  res: RoomGroupRelationResponse;

  confirm = false;
  errorInfo;

  constructor(
    private authService: AuthService,
    private workService: WorkService,
    private roomGroupService: RoomGroupService,
  ) {
  }

  ngOnInit() {
  }

  showAddDialog() {
    this.addhouseModal.show();
  }

  downloadDemo() {
    window.open("/assets/file/房源新增房间模板.xlsx");
  }

  uploadChange($event) {
    if ($event.type === "success") {
      let response = $event.file.response;
      if (response.status_code === "SUCCESS") {

        this.res = response.biz_response;
        this.success = true;

      } else {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, response.message));
      }
      this.fileList = [];
    }

  }

  confirmDo() {
    let req = new RoomGroupRelationConfirmRequest();
    req.group_id = this.groupId;
    this.roomGroupService.roomGroupRelationConfirm(req).then(data => {
      if (data.error_info) {
        this.errorInfo = data.error_info;
        this.infoModal.show();
      } else {

        this.addhouseModal.hide();

        this.success = false;

        this.successEvent.emit(data);

        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, '添加成功'));
      }
    });
  }

  returnUpload() {

    this.roomGroupService.roomGroupRelationCancel();

    this.success = false;
  }

  reConfirmDo() {
    let req = new RoomGroupRelationConfirmRequest();
    req.group_id = this.groupId;
    req.confirm = true;
    this.roomGroupService.roomGroupRelationConfirm(req).then((data) => {

      this.addhouseModal.hide();
      this.infoModal.hide();

      this.success = false;

      this.successEvent.emit(data);

      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, '添加成功'));
    });
  }
}
