import { Component, EventEmitter, Input, OnInit, Output ,ViewChild} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { limitPriceValidator, monthRentNotRequiredValidator } from '../../../core/validator/validator.sign';
import { setPure } from '../../../common/form.util';
import { BatchPriceSetResult } from '../../../model/lease/lease.model';
import { BaseInfoService } from '../../../service/common/base-info.service';
import { ModalDirective } from "ngx-bootstrap/modal";
@Component({
  selector: 'app-batch-price-set-modal',
  templateUrl: './batch-price-set-modal.component.html',
  styleUrls: ['./batch-price-set-modal.component.css']
})
export class BatchPriceSetModalComponent implements OnInit {

  // 弹窗对象
  // private batch_price_set_modal: any;
  @ViewChild('batchPriceSetModal') batch_price_set_modal: ModalDirective;
  // 月份数
  @Input()
  month_interval: number = 0;

  @Output()
  batch_set_result: EventEmitter<BatchPriceSetResult> = new EventEmitter<BatchPriceSetResult>();

  // 设置表单
  batch_set_form: UntypedFormGroup;

  wentDutch = false;

  // 表单是否提交
  is_submit: boolean = false;

  constructor(private fb: UntypedFormBuilder,
              private baseInfoService: BaseInfoService) {
  }

  ngOnInit() {
    this.batch_set_form = this.fb.group({
      deposit: [, limitPriceValidator(0, 200000, true)],
      renter_deposit: [, limitPriceValidator(0, 200000, true)],
      rent_type: ['NORMAL'],
      month_rent: [, monthRentNotRequiredValidator],
      custom_rent_array: this.fb.array([])
    });
  }

  // initSomething(batchPriceSetModal: any) {
  //   this.batch_price_set_modal = batchPriceSetModal;
  // }

  // 创建月租金
  createCustomRent(month: number) {
    return this.fb.group({
      month: [month],
      custom_rent: [, [Validators.required, limitPriceValidator(0, 200000, true)]]
    });
  }

  // 获取灵活租约列表
  get customRentArray(): UntypedFormArray {
    return this.batch_set_form.get('custom_rent_array') as UntypedFormArray;
  }

  // 清空灵活租金
  clearCustomRentArray() {
    if (this.customRentArray) {
      let length = this.customRentArray.length;
      for (let i = length - 1; i >= 0; i--) {
        this.customRentArray.removeAt(i);
      }
    }
  }

  onSubmit() {
    this.is_submit = true;

    if (this.batch_set_form.get('rent_type').value === 'NORMAL') {
      this.customRentArray.controls.forEach(custom_rent_item => {
        setPure(custom_rent_item.get('custom_rent'));
      });
    } else {
      setPure(this.batch_set_form.get('month_rent'));
    }

    if (!this.batch_set_form.valid) {
      return false;
    }

    const result = new BatchPriceSetResult();
    result.deposit = this.batch_set_form.value.deposit;
    result.rent_type = this.batch_set_form.value.rent_type;
    if (result.rent_type === 'NORMAL') {
      result.month_rent = this.batch_set_form.value.month_rent;
    } else {
      result.custom_rent_list = this.batch_set_form.value.custom_rent_array;
    }
    if(this.batch_set_form.value.renter_deposit){
      result.renter_deposit = this.batch_set_form.value.renter_deposit;
    }
    this.batch_set_result.emit(result);
    this.hide();
  }

  show() {
    this.is_submit = false;
    this.batch_price_set_modal.show();
    this.clearCustomRentArray();
    this.batch_set_form.get('rent_type').setValue('NORMAL');
    setPure(this.batch_set_form.get('deposit'), this.batch_set_form.get('month_rent'),this.batch_set_form.get('renter_deposit'));
    for (let i = 0; i < this.month_interval; i++) {
      this.customRentArray.push(this.createCustomRent(i));
    }
  }

  hide() {
    this.batch_price_set_modal.hide();
  }

  // 是否显示错误信息
  isError(is_submit: boolean, container: any, control_name: string, error_code: string): boolean {
    return this.baseInfoService.isError(is_submit, container, control_name, error_code);
  }
}
