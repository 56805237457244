import { Injectable } from '@angular/core';

import { RequestService } from '../request.service';
import { environment } from '../../../environments/environment';

/**
 * @Description:  岗位service
 * @Author: zhoujiahao
 * @Date: 2018-03-20  15:44
 */
@Injectable()
export class OrderService {

  // 获得账单订单信息
  private ORDER_LIST = `${environment.apiHost}pms/order/paymnet/list`;


  constructor(private requestService: RequestService) {
  }

  /**
   *获得账单订单信息
   */
  getBillOrderList(reqData?: any): Promise<any> {
    return this.requestService.authGet(this.ORDER_LIST, reqData);
  }



}
