import {CompanyDTO} from "../department/department.model";
import {TreeItem} from "ngx-treeview";
import {HotelProjectBaseDTO, ProjectUnitDTO, RoomTypeBaseDTO} from "../lease/lease.model";
import {HotelProject} from "../room/project.model";

export class ProjectImage {
  id?: number;
  image_type?: string;
  title?: string;
  image_url?: string;
  project_id?: number;
}

export class ProjectEditRequest {
  id?: number;
  project_type?: string;
  project_name?: string;
  project_full_name?: string;
  project_address?: string;
  keyword?: string;
  description?: string;
  image_list?: ProjectImage[] = [];
  component_list?:ProjectImage[] = [];

  // req
  vr_url?: string;
  vr_urls?: Array<string>;
  video_urls?: Array<string>;

  // resp
  vr_list?: Array<string>;
  video_list?: Array<string>;

  edit_image = false;
  edit_component = false;
}

export class ProjectDetailResponse {
  id?: number;
  project_type?: string;
  project_name?: string;
  project_full_name?: string;
  project_address?: string;
  keyword?: string;
  description?: string;
  image_list?: ProjectImage[] = [];
  component_list?:ProjectImage[] = [];

  vr_list?: Array<string>;
  video_list?: Array<string>;
}


export class ProjectManageListResponse {
  company_dto?: CompanyDTO;
}

export class ProjectManageDTO {
  // @Remark("项目ID")
  id?: number;
  // @Remark("项目名称")
  project_name?: string;
  // @Remark("项目全称")
  project_full_name?: string;

  project_status?: string;
  // @Remark("项目配置文件状态")
  project_config_status_enum?: string;
}


export class ProjectTree implements TreeItem {
  text: string;
  value: any;
  // COMPANY PROJECT
  type?: string;
  disabled?: boolean;
  checked?: boolean;
  collapsed?: boolean;
  children?: TreeItem[];
}

export class ProjectExtInfo {

  ext_name: string;
  ext_value: string;
  ext_type: string = 'INPUT';
  required: boolean = false;
  effective: boolean = false;
  select_value_isnull: boolean = false;

}


export class ProjectManageEditRequest {
  id?: number;
  company_id?: number;
  // @Remark("项目名")
  project_name?: string;
  // @Remark("项目全称")
  project_full_name?: string;
  // @Remark("地址")
  project_address?: string;
  // @Remark("城市ID")
  city_id?: number;
  // @Remark("省份ID")
  region_id?: number;

  // @Remark("项目费用配置")
  project_config_dto?: ProjectConfigDTO;

  // @Remark("合同模板链接")
  contract_url?: string;
  // @Remark("电子签章链接")
  signature_url?: string;
  // @Remark("换房协议链接")
  change_room_url?: string;
  // @Remark("住户公约链接")
  house_hold_url?: string;

  // @Remark("物品交接单")
  list?: Array<RoomTypeFileDTO>;
  room?: Array<number>;

  // @Remark("状态 有效 EFFECTIVE 草稿 DRAFT")
  status?: string;
  // 是否需要审核
  need_check?: number;

  is_file_module?: number;

  idempotent_key?: string;

  project_room_type?: string;

  change_type?: string;
}

export class ProjectConfigDTO {
  // @Remark("电表类型 NONE 无需抄表 SMART 智能电表 MANUAL 手动抄表")
  ele_type?: string;
  // @Remark("付费方式 PREPAY 预付费 BILL 后付费/账单")
  ele_fee_type?: string;
  // @Remark("单价 分/度")
  ele_base_fee?: number;
  // @Remark("扣费精度 N精度 每增加(至少 1 扣一次费")
  ele_base_precision?: number;
  // @Remark("后付费出账时间(每月今日生成 上月账单")
  ele_fee_generate_day?: number;

  // @Remark("冷水表类型 NONE 无需抄表 SMART 智能电表 MANUAL 手动抄表")
  cold_water_type?: string;
  // @Remark("付费方式 PREPAY 预付费 BILL 后付费/账单")
  cold_water_fee_type?: string;
  // @Remark("单价 分/度")
  cold_water_base_fee?: number;
  // @Remark("扣费精度 N精度 每增加(至少 1 扣一次费")
  // coldWaterBasePrecision?: number;
  // @Remark("后付费出账时间(每月今日生成 上月账单")
  cold_water_fee_generate_day?: number;

  // @Remark("热水表类型 NONE 无需抄表 SMART 智能电表 MANUAL 手动抄表")
  hot_water_type?: string;
  // @Remark("付费方式 PREPAY 预付费 BILL 后付费/账单")
  hot_water_fee_type?: string;
  // @Remark("单价 分/度")
  hot_water_base_fee?: number;
  // @Remark("扣费精度 N精度 每增加(至少 1 扣一次费")
  // hotWaterBasePrecision?: number;
  // @Remark("后付费出账时间(每月今日生成 上月账单")
  hot_water_fee_generate_day?: number;

  // @Remark("暖气类型 NONE 无供暖 ROOM 仅房间 PUBLIC 房间+公区 暖气费表heating_base_fee")
  heating_type?: string;
  // @Remark("房间暖气费单价 分/平/月")
  heating_room_base_fee?: number;
  // @Remark("公区暖气费单价 分/平/月")
  heating_public_base_fee?: number;

  // @Remark("换房费")
  change_room_fee?: number;
  sublease_fee?: number;

  // 是否需要签署租赁合同
  need_lease_contract: number;
  // 是否需要上传入住人员证件照片
  need_renter_pic: number;
  // 是否需要入住人员在线签署入住公约
  need_online_sign_convention: number;
  // 是否需要启用电子合同
  need_use_electronic_contract: number;
  // 是否需要签署并确认房间内的物品清单
  need_room_goods_list: number;
  // 是否需要清洁
  need_clean: number;
  // 是否需要日常清理
  need_daily_clean: number;
  // 是否需要深度清理
  need_deep_clean: number;
  // 日常保洁费
  daily_clean_fee: number;
  // 深度保洁费
  deep_clean_fee?: number;
  industrial_model: any;
  business_nature: any;

  // 是否需要脏房房态
  need_room_dirty: number;

  project_ext_info_dtos ?: Array<ProjectExtInfo>;

  room_type?: number;

  // 押金账单未全部支付时是否允许办理入住
  need_deposit_pay?: number;
  // 峰谷平电费改造新增字段
  // 定价类型
  ele_price_type?: string;
  feng_price?: string;
  feng_time_list?: Array<FengGuPingTime>;
  gu_price?: string;
  gu_time_list?: Array<FengGuPingTime>;
  ping_price?: string;
  ping_time_list?: Array<FengGuPingTime>;
  other_fee_list?: Array<OtherFeeModel>;
  effect_time?: number;

  checkin_guide?: string;
  guide_url?: string;
}

export class RoomTypeFileDTO {
  id?: number;
  url?: string;

  type?: string;
  status?: string;
  room_base_name?: string;
  people_num?: number;
  room_name?: string;
}

export class ProjectBaseRoomListResponse {
  room_base_type_dtolist?: Array<RoomBaseTypeDTO>;
}

export class RoomBaseTypeDTO {
  id?: number;
  room_name?: string;
  bed_num?: number;
  people_num?: number;
}

export class RoomBaseTypeDTOInPA extends RoomBaseTypeDTO {
  checked = false;
  url;

  list = [];
}

export class ProjectManageQueryResponse {

  hotel_project_dto?: HotelProjectBaseDTO;

  project_config_dto?: ProjectConfigDTO;

  list?: Array<RoomTypeFileDTO>;
  room?: Array<number>;

  unit?: Array<string>;

  service_phone_list?: Array<string>;

  status?: string;
  fail_content?: string;
  // 是否可修改项目费用信息
  edit_project_base_fee_config?: boolean;
  // 如果有待审批项目费用修改信息，显示时间
  project_base_fee_effective_time?: number;
  // 是否存在未来生效的项目费用配置
  future_effective_project_base_fee?: boolean;

}

export class ProjectDangerTypeRequest {
  danger_types: Array<string>;
}

export class ProjectTemplateResponse {
  template_content: string;
}

export class ProjectTemplateRequest {
  file_type: string;
  param_map: any;
}

export class ProjectSmartDevsResponse {
  ele_type?: string;
  cold_water_type?: string;
  hot_water_type?: string;
  smart_door?: boolean;
}

export class LocalProjectConfig {
  business_type?: string;
  city_id?: number;
  cold_water_fee_type?: string;
  cold_water_type?: string;
  ele_fee_type?: string;
  ele_type?: string;
  heating_type?: string;
  hot_water_fee_type?: string;
  hot_water_type?: string;
  id?: number;
  latitude?: number;
  longitude?: number;
  need_lease_contract?: number;
  need_online_sign_convention?: number;
  need_renter_pic?: number;
  need_room_goods_list?: number;
  need_use_electronic_contract?: number;
  project_address?: string;
  project_name?: string;
  project_type?: string;

}

export class ProjectDangerDto {
  name: string;
  status: number;
}

export class HotelProjectDTO {

  id?: number;

  business_type?: string;

  project_type?: string;

  project_name?: string;

  project_full_name?: string;

  project_address?: string;

  latitude?: number;

  longitude?: number;

  city_id?: number;

  // @Remark("主图")
  main_img?: string;
  // @Remark("是否需要签署租赁合同(1: 需要, 0: 不需要)")
  need_lease_contract?: number;
  // @Remark("是否需要上传入住人员证件照片(1: 需要, 0: 不需要)")
  need_renter_pic?: number;
  // @Remark("是否需要入住人员在线签署入住公约(1: 需要, 0: 不需要)")
  need_online_sign_convention?: number;
  // @Remark("是否需要启用电子合同(1: 需要, 0: 不需要)")
  need_use_electronic_contract?: number;
  // @Remark("是否需要签署并确认房间内的物品清单")
  need_room_goods_list?: number;
  // @Remark("是否需要脏房")
  need_room_dirty?: number;

  // @Remark("智能电表 NONE 无需抄表 SMART 智能电表 MANUAL 手动抄表")
  ele_type?: string;
  // @Remark("付费方式 PREPAY 预付费 BILL 后付费")
  ele_fee_type?: string;
  // @Remark("冷水表 NONE 无需抄表 SMART 智能电表 MANUAL 手动抄表")
  cold_water_type?: string;
  // @Remark("付费方式 PREPAY 预付费 BILL 后付费")
  cold_water_fee_type?: string;
  // @Remark("热水表 NONE 无需抄表 SMART 智能电表 MANUAL 手动抄表")
  hot_water_type?: string;
  // @Remark("付费方式 PREPAY 预付费 BILL 后付费")
  hot_water_fee_type?: string;
  // @Remark("NONE 无供暖 ROOM 仅房间 PUBLIC 房间+公区")
  heating_type?: string;

  // @Remark("智能门锁: 支持智能门锁 SMART; 不支持: NONE")
  door_lock_type?: string;

  room_types?: Array<RoomTypeBaseDTO>;

  project_id?: number;
  business_nature?: string;

  room_type?: number;

  project_unit_dtolist?: Array<ProjectUnitDTO>;
}

export class ProjectChangeResponse {
  project: HotelProject;
}

export class FengGuPingTime{
  start_time: string;
  end_time: string;
}

export class OtherFeeModel {
  fee_type: string; // 费用名称
  charging_unit: string; // 计费单位
  amount: string; // 费用金额
  charging_start_time_month: string; // 计费开始时间月
  charging_start_time_day: string; // 计费开始时间日
  charging_end_time_month: string; // 计费结束时间月
  charging_end_time_day: string; // 计费结束时间日
}

export class WaitingEffectiveProjectBaseConfigRequest {
  project_id: number;
}

export class WaitingEffectiveProjectBaseConfigResponse{
  project_config_dto?: ProjectConfigDTO;
}

export class UpdateCheckinGuideRequest {
  project_id?: number;
  content?: string;
}

export class ProjectAppointmentTimeDTO {
  // @Remark("开始时间HH:mm:ss")
  start?: string;
  // @Remark("结束时间HH:mm:ss")
  end?: string;
  // @Remark("人数")
  people_num?: number;

  startTime;
  endTime;


  constructor(start: string, end: string, people_num: number, startTime, endTime) {
    this.start = start;
    this.end = end;
    this.people_num = people_num;
    this.startTime = startTime;
    this.endTime = endTime;
  }
}

export class ProjectAppointmentTimeResponse {
  times?: Array<ProjectAppointmentTimeDTO>;
}

export class ProjectAppointmentTimeRequest {
  times?: Array<ProjectAppointmentTimeDTO>;
  project_id?: number;
}


