<!-- {{initSomething(company_modal)}} -->
<!-- 选择企业 -->
<div class="modal fade" bsModal #company_modal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-static-name" class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>选择企业</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mb10">
          <div class="col-8 col-md-6">
            <input type="text" class="w-100" placeholder="请输入企业名称" [(ngModel)]="search_company_name" autocomplete="false">
          </div>
          <div class="col-4  col-md-6">
            <button class="btn btn-info" type="button" (click)="searchCompanyList()">
              <i class="fa fa-search" aria-hidden="true"></i>搜索
            </button>
          </div>
        </div>
        <table class="w-100 table table-bordered table-striped">
          <thead>
          <tr>
            <th>企业名称</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let company of company_list; let h=index">
            <tr *ngIf="h <= 10">
              <td>{{company.company_name}}</td>
              <td>
                <button class="btn btn-outline-primary" type="button" (click)="chooseCompany(company.company_id)">选择</button>
              </td>
            </tr>
          </ng-container>
          <tr *ngIf="!company_list || company_list.length == 0">
            <td colspan="2" class="text-center">暂未找到企业</td>
          </tr>
          </tbody>
        </table>
        <p class="text-warning" *ngIf="company_list && company_list.length > 10">您搜索的条件过于宽泛，只显示前10条结果，请输入更多条件</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="hide()">关闭</button>
      </div>
    </div>
  </div>
</div>
