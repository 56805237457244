<div class="setbox">
  <div class="row m-0">
    <div class="col-12 row m-0 change_bg p-0 mb-3">
      <div class="col-6 text-center p-2 {{type === 'list'?'active':''}}" (click)="type='list';active_workOrderId= -1;request.page_num = 1;search(); ">工单记录</div>
      <div class="col-6 text-center p-2 {{type === 'submit'?'active':''}}" (click)="type='submit';submitFormInit()">提工单</div>
    </div>
  </div>
  <div *ngIf="type === 'list' && btn_privileges.WORK_ORDER_PAGE_QUERY" >
    <div class="row" >
      <div class="col-12 col-md-3 mb10">
        <label>关键词搜索</label>
        <div class="wit200">
          <input [(ngModel)]="searchForm.searchKey" class="form-control" placeholder="请输入问题概要的关键词或报告人姓名">
        </div>
      </div>
      <div class="col-12 col-md-2 mb10">
        <label>问题类型</label>
        <div>
          <select [(ngModel)]="searchForm.problem_type_name" class="w-100">
            <option [value]="''">全部</option>
            <option *ngFor="let item of problemTypeAllList" [value]="item.problem_type_name">{{item.problem_type_name}}</option>
          </select>
        </div>
      </div>

      <div class="col-12 col-md-2 mb10">
        <label>紧急程度</label>
        <div>
          <select [(ngModel)]="searchForm.workOrderLevel" class="w-100">
            <option [value]="-1">全部</option>
            <option value="VERYHOT">非常紧急</option>
            <option value="HOT">紧急</option>
            <option value="COMMON">一般</option>
          </select>
        </div>
      </div>

      <div class="col-12 col-md-2 mb10">
        <label>状态</label>
        <div>
          <select [(ngModel)]="searchForm.workOrderStatus" class="w-100">
            <option [value]="-1">全部</option>
            <option value="WAITDEAL">待处理</option>
            <option value="DEALING">处理中</option>
            <option value="WAITCONFIRM">待确认</option>
            <option value="CLOSED">已关闭</option>
            <option value="CANCELED">已取消</option>
          </select>
        </div>
      </div>

      <div class="col-12 col-md-2 mb10">
        <label>&nbsp;&nbsp;</label>
        <div>
          <button (click)="request.page_num = 1;search()" class="btn btn-info" type="button">
            <i class="fa fa-search"></i>搜索
          </button>
        </div>
      </div>

      <div class="col-12 mb10">
        <div class="checkbox">
          <label>
            <input type="checkbox" [(ngModel)]="searchForm.isSelf" class="mr5">只看我的工单
          </label>
        </div>
      </div>
    </div>

    <div class="scrollx-head">
      <table class="table table-bordered table-striped scrollx-body">
        <thead>
        <tr>
          <th class="">工单号</th>
          <th>问题类型</th>
          <th class="show2">紧急程度</th>
          <th class="show2">问题概要</th>
          <th>报告人</th>
          <th class="show2">创建时间</th>
          <th>状态</th>
          <th>操作</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngIf="res?.order_page_dtolist.length > 0 && !loading" >
          <tr *ngFor="let item of res?.order_page_dtolist | paginate: {itemsPerPage: request.page_size,
              currentPage: request.page_num,totalItems: res.total }">
            <td>{{item?.work_order_no}}</td>
            <td>{{item?.problem_type_name}}</td>
            <td class="show2">{{levelName[item?.work_order_level]}}</td>
            <td class="show2">{{item?.summary}}</td>
            <td>{{item?.report_user_name}}</td>
            <td class="show2">{{item?.create_date_time}}</td>
            <td>
                <span class="badge" [ngClass]="{'badge-danger': item.work_order_status == 'WAITDEAL',
                  'badge-warning': item.work_order_status == 'DEALING',
                  'badge-success': item.work_order_status == 'WAITCONFIRM',
                  'badge-light': item.work_order_status == 'CLOSED'
                }" >{{item?.work_order_status_desc}}</span>
            </td>
            <td>
              <div>
                <button type="button" *ngIf="btn_privileges.WORK_ORDER_INFO"  class="btn btn-info" (click)="openDetalis(item.work_order_id)">
                  <i class="fa fa-eye"></i>查 看
                </button>
                <button type="button" class="btn btn-info" (click)="openConfirmModal(item.work_order_id)" *ngIf="item.work_order_status == 'WAITCONFIRM' && btn_privileges.WORK_ORDER_CONFIRM">
                  <i class="fa fa-check-circle-o"></i>确 认
                </button>
                <button type="button" class="btn btn-info"
                        (click)="workOrderEdit(item.work_order_id)"
                        *ngIf="(item.work_order_status == 'WAITDEAL' || item.work_order_status == 'DEALING') && btn_privileges.WORK_ORDER_UPDATE">
                  <i class="fa fa-edit"></i>修 改
                </button>
                <button type="button" class="btn btn-info"
                        (click)="active_id = item.work_order_id;delModal.show();"
                        *ngIf="item.work_order_status == 'WAITDEAL' && btn_privileges.WORK_ORDER_CANCEL">
                  <div class="icon-cancel"><span></span><span>取 消</span></div>
                </button>
              </div>
            </td>
          </tr>
        </ng-container>
        <tr *ngIf="loading">
          <td colspan="8" class="text-center show2">数据查询中...</td>
          <td colspan="5" class="text-center show1">数据查询中...</td>
        </tr>
        <tr *ngIf="res?.order_page_dtolist.length === 0 && !loading">
          <td colspan="8" class=" text-center show2">本次未查询到相关数据</td>
          <td colspan="5" class="text-center show1">本次未查询到相关数据</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="res?.order_page_dtolist.length > 0"
      class="text-right pages">
      <pagination-controls (pageChange)="pageChange($event)" directionLinks="true" previousLabel="上一页" nextLabel="下一页"
        screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"></pagination-controls>
      <div class="text-right">共有{{res?.total}}条记录</div>
    </div>
  </div>
  <div *ngIf="type === 'submit' && btn_privileges.WORK_ORDER_SUBMIT">
    <div class="row">
      <div class="col-12 col-md-6 li">报告人ㅤ：{{sysUserDTO?.user_name}}</div>
      <div class="col-12 col-md-6 li">所属项目：{{projectName}}</div>
      <div class="col-12 col-md-6 li">
        <span>问题类型：</span>
        <select [(ngModel)]="submitForm.problem_type_id" class="w-100" [disabled]="active_workOrderId !== -1">
          <option [value]="-1">请选择</option>
          <option *ngFor="let item of problemTypeList" [value]="item.problem_type_id">{{item.problem_type_name}}</option>
        </select>
      </div>
      <div class="col-12 col-md-6 li">
        <span>紧急程度：</span>
        <select [(ngModel)]="submitForm.work_order_level" class="w-100" [disabled]="active_workOrderId !== -1">
          <option [value]="-1">请选择</option>
          <option value="VERYHOT">非常紧急</option>
          <option value="HOT">紧急</option>
          <option value="COMMON">一般</option>
        </select>
      </div>
      <div class="col-12 li">
        <span>问题概要：</span>
        <input [(ngModel)]="submitForm.summary" maxlength="30" class="form-control" placeholder="请一句话突出重点简要描述您的问题，限30个字">
      </div>
      <div class="col-12 li">
        <span>问题描述：</span>
        <textarea [(ngModel)]="submitForm.description" maxlength="1000" class="form-control" rows="6"
        placeholder="请详细描述您的问题，限1000字。&#10;1、如涉及具体合同数据的，请注明合同号、房间号（如有）、账单编号（如有）；&#10;2、如涉及具体功能的，请注明功能菜单入口，操作动作，系统结果；&#10;3、如系统报错，可将弹窗中的行为ID复制粘贴进来；"></textarea>
      </div>
      <div class="col-12 li">
        <span>附件ㅤㅤ：</span>
        <div class="w-100">
          <p>说明：（支持图片、word、excel、txt、ppt、pdf格式文件上传）</p>
          <div class="position-relative">
            <app-new-file-upload
            name="添加附件"
            [baseUrl]="'workOrder/upload'"
            [type]="2"
            [maxLength]="10"
            [isMaxFileSize]="true"
            [maxFileSize]="10"
            [imgList]="attachmentUrlList"
            [accept]="'IMG|PDF|WORD|EXCEL|TXT|PPT'"
            (fileChangeMore)="fileChange($event, 'attachmentUrlList', 'attachmentUrlKeyList');">
            </app-new-file-upload>
            <span class="exp2">最多可上传10张，单张大小不超过10M</span>
          </div>
        </div>
      </div>
      <div class="col-12 li justify-content-center">
        <button type="button" class="btn bg-secondary mr-3" (click)="submitFormInit()">
          清 空
        </button>
        <button type="button" class="btn btn-info" (click)="submit()">
          <img src="/assets/img/loading.gif" alt="" class="loading_img" alt="" *ngIf="editLoading" >{{loading?'提交中...':'提 交'}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal showMsgModal" bsModal #showMsgModal="bs-modal"
     [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     style="z-index: 10000;"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="showMsgModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="col-12 msg" >
          {{msgtext}}
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button"
              (click)="showMsgModal.hide()"
              class="btn btn-primary">关 闭
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal " bsModal #showConfirmModal="bs-modal"
     [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     style="z-index: 9999;"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm" >
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>工单确认</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="showConfirmModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="col-12 showConfirmModal" >
          <p>本条功能宿管已处理完成，请确认是否解决了您的问题？</p>
          <div class="select work-order">
            <button (click)="checkStatus(1)" class="btn mr-5"
            [ngClass]="{'_bg-green': activeStatus === 1}"
            style="min-width:80px;" type="button">
              <img class="tipsIcon_img" src="/assets/img/u686.png">
              <span class="ml-1">已解决</span>
            </button>
            <button (click)="checkStatus(0)" class="btn ml-5"
            [ngClass]="{'bg-danger': activeStatus === 0}"
                    style="min-width:80px;" type="button">
              <img class="tipsIcon_img" src="/assets/img/u26309.png">
              <span class="ml-1">未解决</span>
            </button>
          </div>
          <div *ngIf="activeStatus === 0">
            <textarea [(ngModel)]="refuseText" maxlength="100" class="form-control" rows="3"
            placeholder="请描述问题未解决的具体说明，限100字"></textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button"
              (click)="submitConfirm()"
              class="btn btn-primary">确 认
            </button>
            <button type="button"
              (click)="showConfirmModal.hide()"
              class="btn btn-secondary text-white">关 闭
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" bsModal #detalisModal="bs-modal"
     [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>工单详情</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="detalisModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body ">
        <div class="col-12 px-md-4 mt-4">
          <p class="d-flex align-items-center"><span class="diamond"></span><span>工单内容【<span class="text-success">工单号：{{detalisData.work_order_no}}</span>】</span></p>
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-md-6 li">报告人ㅤ：{{detalisData.report_user_name}}</div>
              <div class="col-12 col-md-6 li">创建时间：{{detalisData.create_date_time}}</div>
              <div class="col-12 col-md-6 li">问题类型：{{detalisData.problem_type_name}}</div>
              <div class="col-12 col-md-6 li">紧急程度：{{levelName[detalisData.work_order_level]}}</div>
              <div class="col-12 li">所属项目：{{detalisData.project_name}}</div>
              <div class="col-12 li">问题概要：{{detalisData.summary}}</div>
              <div class="col-12 li">
                <span>问题描述：</span>
                <textarea rows="6" disabled class="only">{{detalisData.description}}</textarea>
              </div>
              <div class="col-12 li mt20">
                <span>附件ㅤㅤ：</span>
                <div class="position-relative">
                  <app-new-file-upload
                    [type]="2"
                    [maxLength]="5"
                    [isMaxFileSize]="true"
                    [maxFileSize]="10"
                    [imgList]="detalisData.img_url_list"
                    [showUploadBtn]="false"
                    [showDelBtn]="false">
                  </app-new-file-upload>
                </div>
              </div>
            </div>
          </div>
          <hr *ngIf="deal_record_list.length" />
          <div *ngIf="deal_record_list.length" >
            <p class="flex align-items-center"><span class="diamond"></span><span>工单处理记录</span></p>
            <div class="" *ngFor="let item of deal_record_list">
              <p class="date mt-3 mb-2"><span>{{item.deal_date_time}}</span></p>
              <div class="col-12" *ngIf="item.record_type === 'DEALING' || item.record_type === 'DEALED' || item.record_type === 'RESOLVED' ">
                <div class="row">
                  <ng-container *ngIf="item.record_type === 'DEALING'">
                    <div class="col-12 col-md-6 li" >宿管客服处理回复：</div>
                    <div class="col-12 col-md-6 li linh27 justify-content-end">
                      <span class="mr-3">预计完成时间</span>
                      <span class="text-success mr-3">{{item.expect_complete_date_time}}</span>
                      <span class="badge badge-warning mr-3">{{item.record_type_desc}}</span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="item.record_type === 'DEALED'" >
                    <div class="col-12 col-md-6 li" >宿管客服处理回复：</div>
                    <div class="col-12 col-md-6 li linh27 justify-content-end">
                      <span class="text-success mr-3">&nbsp;</span>
                      <span class="badge badge-success mr-3">{{item.record_type_desc}}</span>
                    </div>
                  </ng-container>
                  <div class="tablebg">
                    <p *ngIf="item.record_type !== 'RESOLVED'" >{{item.deal_result}}</p>
                    <div *ngIf="item.record_type === 'RESOLVED'">
                      <p>【{{item.deal_user_name}}】确认工单处理结果：{{item.record_type_desc}}</p>
                      <p >{{item.deal_result}}</p>
                    </div>
                    <div class="position-relative mt-2">
                      <app-new-file-upload
                        [type]="2"
                        [maxLength]="5"
                        [isMaxFileSize]="true"
                        [maxFileSize]="10"
                        [imgList]="item?.img_url_list"
                        [showUploadBtn]="false"
                        [showDelBtn]="false">
                      </app-new-file-upload>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12" *ngIf="item.record_type === 'TURNAPPORTION'">
                <div class="tablebg ">
                  <p >【宿管客服】：工单转分配</p>
                  <div class="scrollx-head">
                    <table class="table table-bordered scrollx-body">
                      <tr>
                        <th>原处理人</th>
                        <th>新处理人</th>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let val of item.change_record" >
                          <td>{{val.before_value}}</td>
                          <td>{{val.after_value}}</td>
                        </ng-container>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="" *ngIf="item.record_type === 'UNRESOLVED' || item.record_type === 'CANCELED'">
                <div class="tablebg " *ngIf="item.record_type === 'CANCELED'">
                  <p>【{{item.deal_user_name}}】操作了取消工单</p>
                </div>
                <div class="tablebg " *ngIf="item.record_type === 'UNRESOLVED'">
                  <p>【{{item.deal_user_name}}】确认工单处理结果：{{item.record_type_desc}}</p>
                  <!-- <textarea rows="6" disabled class="only">123</textarea> -->
                  <p class="padd10">说明：{{item.deal_result}}</p>
                </div>
              </div>
              <div class="" *ngIf="item.record_type === 'CHANGED' || item.record_type ==='CHANGE_PROBLEM_TYPE'">
                <div class="tablebg ">
                  <p *ngIf="item.deal_source === 0">【{{item.deal_user_name}}】变更了工单内容：</p>
                  <p *ngIf="item.deal_source === 1">【宿管客服】：变更了工单内容</p>
                  <div class="scrollx-head ">
                    <table class="table table-bordered mt-2 scrollx-body">
                      <tr>
                        <th>字段</th>
                        <th>原值</th>
                        <th>新值</th>
                      </tr>
                      <tr *ngFor="let val of item.change_record" >
                        <ng-container *ngIf="val.filed_type !== 'attachmentUrlList'" >
                          <td style="word-break: break-word">{{val.filed_desc}}</td>
                          <td style="word-break: break-word">{{val.before_value}}</td>
                          <td style="word-break: break-word">{{val.after_value}}</td>
                        </ng-container>
                        <ng-container *ngIf="val.filed_type === 'attachmentUrlList'">
                          <td>附件</td>
                          <td style="word-break: break-word">
                            <ng-container *ngFor="let v of val.before_attachment_list;last as last" >
                              <a [href]="v.img_url" [download]="v.img_url" target="_blank">{{v.file_name}}</a><span *ngIf="!last" >、</span>
                            </ng-container>
                          </td>
                          <td style="word-break: break-word">
                            <ng-container *ngFor="let v of val.after_attachment_list;last as last" >
                              <a [href]="v.img_url" [download]="v.img_url" target="_blank">{{v.file_name}}</a><span *ngIf="!last" >、</span>
                            </ng-container>
                          </td>
                        </ng-container>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button"
              *ngIf="detalisData.work_order_status == 'DEALING'"
              (click)="detalisModal.hide();workOrderEdit(detalisData?.work_order_id)"
              class="btn btn-primary">修 改
            </button>
            <button type="button"
              *ngIf="detalisData.work_order_status == 'WAITCONFIRM'"
              (click)="activeStatus = -1;showConfirmModal.show();"
              class="btn btn-primary">确 认
            </button>
            <button type="button"
              (click)="detalisModal.hide()"
              class="btn btn-secondary">关 闭
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal " bsModal #delModal="bs-modal"
     [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="delModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div class="col-12 msg" >
          确认取消本条工单吗？
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button"
              (click)="cancel()"
              class="btn btn-primary">确 认
            </button>
            <button type="button"
              (click)="delModal.hide()"
              class="btn btn-secondary text-white">取 消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
