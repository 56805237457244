<div class="setbox goodslist">
  <div class="goodslist-header">
    <div class="btn-list">
      <!--<button class="btn btn-info" (click)="goBack()">返回退房</button>-->
      <button class="btn btn-info" (click)="saveOneOutLine()" [disabled]="btnDisable?true:''">线下确认</button>
      <button class="btn btn-warning ml-5" (click)="saveOneInLine()" [disabled]="btnDisable?true:''" *ngIf="isPad()">线上签字</button>
      <button class="btn btn-warning ml-5" (click)="saveOneInLine()" [disabled]="btnDisable?true:''" *ngIf="!isPad()">同步pad签字</button>
    </div>
    <div class="test-describe">
      操作提示 : 点击<span class="no">【是】</span>/<span class="yes">【否】</span>可切换物品状态
    </div>
  </div>
  <div class="goodslist-body">
    <table border="1px soild #f2f2f2" width="100%">
      <tbody>
      <tr>
        <td colspan="10">
          <div class="table-title">
<!--            <img src='../../../../assets/img/logo2.png'/>-->
            公寓物品交接清单<span>【退房】</span>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="2">公寓门店</td>
        <td colspan="3">{{response?.project_name}}</td>
        <td colspan="2">房号</td>
        <td colspan="3">{{response?.room_info_dto.room_name}}</td>
      </tr>
      <tr>
        <td [attr.colspan]="isNeedFilter ? 1:2">水表读数</td>
        <td [attr.colspan]="isNeedFilter ? 2:3">
          <ng-container *ngIf="response?.water_dev&&response?.water_reading">
            {{response?.water_reading}}
            <span class="gray">（{{response?.water_reading_time|date:'yyyy-MM-dd HH:mm'}}）</span>
          </ng-container>
          <ng-container *ngIf="response?.water_dev&&!response?.water_reading">
            <input type="text" [(ngModel)]="req.checkout_room_inventorydto.water_reading">
          </ng-container>
          <ng-container *ngIf="!response?.water_dev&&response?.need_water=='YES'">
            <input type="text" [(ngModel)]="req.checkout_room_inventorydto.water_reading">
          </ng-container>
          <ng-container *ngIf="!response?.water_dev&&response?.need_water=='NO'">
            <span class="org">此房型设置了不需要抄表收费</span>
          </ng-container>
        </td>

        <td [attr.colspan]="isNeedFilter ? 1:2" *ngIf="isNeedFilter">热水表读数</td>
        <td [attr.colspan]="isNeedFilter ? 2:3" *ngIf="isNeedFilter">
          <ng-container *ngIf="response?.hot_water_dev&&response?.hot_water_reading">
            {{response?.hot_water_reading}}
            <span class="gray">（{{response?.hot_water_reading_time|date:'yyyy-MM-dd HH:mm'}}）</span>
          </ng-container>
          <ng-container *ngIf="response?.hot_water_dev&&!response?.hot_water_reading">
            <input type="text" [(ngModel)]="req.checkout_room_inventorydto.hot_water_reading">
          </ng-container>
          <ng-container *ngIf="!response?.hot_water_dev&&response?.need_hot_water=='YES'">
            <input type="text" [(ngModel)]="req.checkout_room_inventorydto.hot_water_reading">
          </ng-container>
          <ng-container *ngIf="!response?.hot_water_dev&&response?.need_hot_water=='NO'">
            <span class="org">此房型设置了不需要抄表收费</span>
          </ng-container>
        </td>

        <td [attr.colspan]="isNeedFilter ? 1:2">电表读数</td>
        <td [attr.colspan]="isNeedFilter ? 3:3">
          <ng-container *ngIf="response?.electricity_dev&&response?.electricity_reading">
            {{response?.electricity_reading}}
            <span class="gray">（{{response?.electricity_reading_time|date:'yyyy-MM-dd HH:mm'}}）</span>
          </ng-container>
          <ng-container *ngIf="response?.electricity_dev&&!response?.electricity_reading">
            <input type="text" [(ngModel)]="req.checkout_room_inventorydto.electricity_reading">
          </ng-container>
          <ng-container *ngIf="!response?.electricity_dev&&response?.need_electricity=='YES'">
            <input type="text" [(ngModel)]="req.checkout_room_inventorydto.electricity_reading">
          </ng-container>
          <ng-container *ngIf="!response?.electricity_dev&&response?.need_electricity=='NO'">
            <span class="org">此房型设置了不需要抄表收费</span>
          </ng-container>
        </td>
      </tr>
      <tr>
        <th colspan="10">
          <div class="table-title-second">
            房屋标准配置清单及价格
          </div>
        </th>
      </tr>
      <tr>
        <td colspan="2">部位</td>
        <td colspan="1">物品名称</td>
        <td colspan="1">单价</td>
        <!--<td colspan="1">是否有</td>-->
        <td colspan="1">数量</td>
        <td colspan="1">是否损坏</td>
        <td colspan="1">物品名称</td>
        <td colspan="1">单价</td>
        <!--<td colspan="1">是否有</td>-->
        <td colspan="1">数量</td>
        <td colspan="1">是否损坏</td>
      </tr>

      <ng-container *ngIf="response && response?.room_goods_info_category_dtolist">
        <ng-container *ngFor="let category of response.room_goods_info_category_dtolist">
          <ng-container *ngFor="let item of category.room_goods_info_dtolist;let i=index">

            <tr *ngIf="i < category.room_goods_info_dtolist.length/2">

              <ng-container *ngIf="i==0">

                <td colspan="2"
                    [attr.rowspan]="category.room_goods_info_dtolist.length%2==0?category.room_goods_info_dtolist.length/2:(category.room_goods_info_dtolist.length-1)/2+1">
                  <span class="black">{{category.category_name}}</span>
                </td>
              </ng-container>


              <td>{{category.room_goods_info_dtolist[2*i].good_name}}</td>
              <td>
                <p style="text-align: right">
                  {{category.room_goods_info_dtolist[2*i].price | number}}
                  {{category.room_goods_info_dtolist[2*i].unit}}
                </p>
              </td>
              <!--<td>-->
                <!--<a>-->
                  <!--【是】-->
                <!--</a>-->
              <!--</td>-->
              <td>{{category.room_goods_info_dtolist[2*i].quantity}}</td>
              <td>
                <a
                  [ngClass]="{
                  'yes':category.room_goods_info_dtolist[2*i].is_bad=='0',
                  'no':category.room_goods_info_dtolist[2*i].is_bad=='1',
                  'change':category.room_goods_info_dtolist[2*i].is_bad!=category.room_goods_info_dtolist[2*i].init_bad
                  }"
                  (click)="changeGoodsStatus(category.room_goods_info_dtolist[2*i],$event)">
                  {{category.room_goods_info_dtolist[2*i].is_bad=='1'?'【是】':'【否】'}}
                </a>
              </td>

              <ng-container *ngIf="2*i+1 < category.room_goods_info_dtolist.length">
                <td>{{category.room_goods_info_dtolist[2*i+1].good_name}}</td>
                <td>
                  <p style="text-align: right">
                    {{category.room_goods_info_dtolist[2*i+1].price | number}}
                    {{category.room_goods_info_dtolist[2*i+1].unit}}
                  </p>
                </td>
                <!--<td>-->
                  <!--<a>-->
                    <!--【是】-->
                  <!--</a>-->
                <!--</td>-->
                <td>{{category.room_goods_info_dtolist[2*i+1].quantity}}</td>
                <td>
                  <a
                    [ngClass]="{
                  'yes':category.room_goods_info_dtolist[2*i+1].is_bad=='0',
                  'no':category.room_goods_info_dtolist[2*i+1].is_bad=='1',
                  'change':category.room_goods_info_dtolist[2*i+1].is_bad!=category.room_goods_info_dtolist[2*i+1].init_bad
                  }"
                    (click)="changeGoodsStatus(category.room_goods_info_dtolist[2*i+1],$event)">
                    {{category.room_goods_info_dtolist[2*i+1].is_bad=='1'?'【是】':'【否】'}}
                  </a>
                </td>
              </ng-container>
              <ng-container *ngIf="2*i+1 == category.room_goods_info_dtolist.length">
                <td colspan="4"></td>
              </ng-container>
            </tr>

          </ng-container>
        </ng-container>

      </ng-container>

      <ng-container
        *ngIf="!response || !response?.room_goods_info_category_dtolist || response?.room_goods_info_category_dtolist.length==0">
        <tr>
          <td colspan="10">无</td>
        </tr>
      </ng-container>

      <tr>
        <td colspan="1"><p style="height: 50px;line-height: 50px;">房客签字</p></td>
        <td colspan="3"><p style="height: 50px;line-height: 50px;"></p></td>
        <td colspan="1"><p style="height: 50px;line-height: 50px;">接待人</p></td>
        <td colspan="3"><p style="height: 50px;line-height: 50px;">{{response?.manage_man}}</p></td>
        <td colspan="1"><p style="height: 50px;line-height: 50px;">确认日期</p></td>
        <td colspan="1"><p style="height: 50px;line-height: 50px;">{{today|date:'yyyy-MM-dd'}}</p></td>
      </tr>
      </tbody>
    </table>
    <div class="tips" [ngStyle]="{'left':offset_x+'px','top':offset_y+'px','display':show}"  >
      状态已切换
      <div class="sang">

      </div>
    </div>
  </div>
</div>


<!--物品损坏确认-->
<div class="modal fade" bsModal #damageModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>赔损确认</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="damageModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h5>以下物品破损需赔偿：</h5>
        <table class="table table-bordered" style="margin-bottom: 20px;">
          <thead>
          <tr>
            <th>物品名称</th>
            <th>单价</th>
            <th>是否损坏</th>
            <th>损坏状况</th>
            <th>赔偿金额</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let good of badGoods;let i=index">
            <td>{{good?.good_name}}</td>
            <td>{{good.price}}{{good.unit}}</td>
            <td>
                <span class="change">
                  【是】
                </span>
            </td>
            <td>
              <select [(ngModel)]="good.bad_degree">
                <option value="0">部分损坏</option>
                <option value="1">全部损坏</option>
                <option value="2">丢失</option>
              </select>
            </td>
            <td>
              <input type="text" [(ngModel)]="good.compensate_price" (input)="calc()"/>
              <span *ngIf="hasError(good.compensate_price)">请输入正确金额</span>
            </td>
          </tr>
          </tbody>
        </table>
        <h5>赔偿金额总计（元）:<span class="change" style="font-size: 20px;">{{totalDamageFee}}</span></h5>
      </div>
      <div class="modal-footer">
        <button class="btn btn-info" (click)="confirmDamage()">下一步</button>
      </div>
    </div>
  </div>
</div>

<!-- 写字板 -->
<app-tablet [title]="'请入住房客在下方写字板内签署姓名'" (commitSign)="commitSign($event)"></app-tablet>
<app-sign-device-binding #signDeviceBindingComponent (signInventoryChange)="ngOnInit()"></app-sign-device-binding>
