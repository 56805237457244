import { Component, OnInit,ViewChild } from "@angular/core";
import { CheckinRoomInventoryRequest, CheckinRoomInventoryResponse } from "../../../model/signed/inventory.model";
import { SignedService } from "../../../service/signed/signed.service";
import { ModalDirective } from "ngx-bootstrap/modal";
@Component({
  selector: 'app-checkininventory',
  templateUrl: './CheckinInventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class CheckinInventoryComponent implements OnInit {

  checkinRoomInventoryRequest: CheckinRoomInventoryRequest = new CheckinRoomInventoryRequest();
  checkinRoomInventoryResponse: CheckinRoomInventoryResponse = new CheckinRoomInventoryResponse();
  // inventory: any;
  @ViewChild('inventoryModal') inventory: ModalDirective;
  constructor(private signdeService: SignedService) {
  }

  ngOnInit() {
  }

  getData() {
    this.signdeService.inventoryCheckin(this.checkinRoomInventoryRequest)
      .then(data => {
        this.checkinRoomInventoryResponse = data;
      });
  }

  openModal(lease_id: number, room_id: number) {
    this.checkinRoomInventoryRequest.lease_id = lease_id;
    this.checkinRoomInventoryRequest.room_id = room_id;
    this.getData();
    this.inventory.show();
  }

  // setMada(inventoryModal: any) {
  //   this.inventory = inventoryModal;
  // }
}

