import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";
import {RenterService} from "../../../../service/renter/renter.service";
import {LeaseRenterInfoDTO, LeaseRoomRentRequestParam} from "../../../../model/renter/renter.model";
import {removeFromArray} from "../../../../common/array.util";
import {AvaliableRoomInfo} from "../../../../model/lease/room_choose.model";
import {Router} from "@angular/router";
import {WorkService} from "../../../../service/common/work.service";
import {WorkModel} from "../../../../model/common/common.model";

@Component({
  selector: 'app-cr-renter-choose',
  templateUrl: './cr-renter-choose.component.html',
  styleUrls: ['./cr-renter-choose.component.css']
})
export class CrRenterChooseComponent implements OnInit {

  @ViewChild('modal', { static: true }) modal: ModalDirective;

  @Input()
  lease_id: number;
  @Input()
  room_id: number;

  @Output()
  renter_choose_emitter: EventEmitter<Array<number>> = new EventEmitter();

  renterList: Array<LeaseRenterInfoDTO>;

  //存放选中的入住人Id
  checkedInfoIds = [];

  startTime: Date;
  endTime: Date;
  choosed_room_id_list: Array<number>;
  exclude_lease_id: number;
  // 房间租约id
  change_lease_room_id: number;
  // 换房日期
  change_time: Date;
  // 换取房间信息
  new_room_info: AvaliableRoomInfo;

  constructor(  private router: Router,
                  private renterService: RenterService,
                  private workService: WorkService,
  ) { }

  ngOnInit() {
  }

  chooseRenters() {
    let param = new LeaseRoomRentRequestParam(this.lease_id, this.room_id);

    this.renterService.queryCheckinRenterList(param).then(data => {
      if(data && data.lease_renter_dtolist){
        this.renterList = data.lease_renter_dtolist;
        this.modal.show();
      }else{
        //没有入住人
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, "没有入住人，不能进行换房操作"));
      }
    });
  }

  hide() {
    this.modal.hide();
  }

  next() {
    this.hide();
    // 跳转到房间选择
    this.renter_choose_emitter.emit(this.checkedInfoIds);
  }

  canNext(): boolean {
    return !this.checkedInfoIds || this.checkedInfoIds.length === 0;
  }

  checkAll(event?: any) {
    if (event.target.checked) {
      // 全选
      this.checkedInfoIds = this.renterList.map(r => r.info_id);
    } else {
      // 取消全选
      this.checkedInfoIds = [];
    }
  }

  checkCurrentRenter(event?: any, infoId?: any) {
    //当前入住人
    let currentRenter = this.renterList.find(renter => renter.info_id === infoId);
    // 如果是勾选了账单
    if (event.target.checked) {
      this.checkedInfoIds.push(currentRenter.info_id);
    } else {
      //取消选中
      this.checkedInfoIds = removeFromArray(this.checkedInfoIds, currentRenter.info_id);
    }
  }

  /**
   * @Description:   阻止向上冒泡
   * @Author: zhoujiahao
   * @Date: 2018-11-02  9:55
   */
  stopP($event) {
    $event.stopPropagation();
  }

}
