import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";
import {BillService} from "../../../service/finance/bill.service";
import {DepositReceiptImgRequest} from "../../../model/finance/bill.model";

@Component({
  selector: 'app-deposit-receipt',
  templateUrl: './deposit-receipt.component.html',
  styleUrls: ['./deposit-receipt.component.css']
})
export class DepositReceiptComponent implements OnInit {

  @ViewChild('depositReceiptModal', {static: true}) depositReceiptModal: ModalDirective;

  imgUrl?: string;
  constructor(private billService: BillService) { }

  ngOnInit(): void {
  }

  showModal(billId: number){
    let request = new DepositReceiptImgRequest();
    request.bill_id = billId;
    this.billService.getDepositReceiptImg(request).then(data => {
      this.imgUrl = data.deposit_receipt_img_url;
      this.depositReceiptModal.show();
    });
  }
  closeModal(){
    this.depositReceiptModal.hide();
    this.imgUrl= null;
  }
}
