export class BillType {
  bill_type_id?: number;
  bill_type_name?: string;
  bill_type_code?: string;
}

export class WorkModel {
  type: string;
  data?: any;

  constructor(type: string, data?: any) {
    this.type = type;
    this.data = data;
  }

}

/**
 * @Description:   凭证model
 * @Param:
 * @return:
 * @Author: zhoujiahao
 * @Date: 2018-07-10  17:52
 */
export class CretImageDTO {
  cret_image_id?: number;
  image_url?: string;
}

// 附件
export class AnnexDTO {
  // @Remark("附件地址")
  annex_url?: string;
  // @Remark("附件类型")
  annex_type?: string;
}



export class TwoTuple {
  a?: any;
  b?: any;

  constructor(p1, p2) {
    this.a = p1;
    this.b = p2;
  }
}

export class ProjectConfigParamValueDTO {

  item_name?: string;

  item_code?: string;

  sort?: number;
}


export class ProjectConfigParamModel {

  id:number;

  project_id:number;

  name:string;

  code:string;

  value:string;

  status:string;

  type:string;

  is_required:number;

  group_code:string;

  sort:number;
}


export class ApplyQueryDesensitizationLogReq {
  action_page: string;
  target_id: string;
  data_type: string;

  constructor(action_page?, target_id?, data_type?) {
    this.action_page = action_page;
    this.target_id = target_id;
    this.data_type = data_type;
  }
}

export class GroupModel {
  id?: number;
  name?: string;
  childs: Array<GroupModel>;


  constructor(id: number, name: string, childs?: Array<GroupModel>) {
    this.id = id;
    this.name = name;
    this.childs = childs;
  }
}

export class SelectDTO{
  key?: string;
  value?: string;
}
