import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {
  RenterCheckoutFinanceNotFinishRequest, RenterCheckoutFinanceNotFinishResponse,
  RenterCheckoutNotFinishRequest,
  RenterCheckoutNotFinishResponse, RenterCheckoutRemindListRequest, RenterCheckoutRemindListResponse, RenterCheckoutTransferErrorDealRequest
} from "../../model/lease/lease.model";
import {
  CalFreeTimeReq,
  CalFreeTimeResp,
  CheckInParams,
  LeaseRoomRentRequestParam,
  MatchLesseeParams,
  ProjectExtInfoResponse,
  QueryAllRenterListReq,
  QueryAllRenterListResp,
  RenterCheckOutRequest,
  RenterCheckOutResponse,
  RenterListRequest,
  RenterListResponse,
  RenterPersonTypeResponse,
  RenterRepeatRequest,
  RenterRepeatResponse,
  RoomListParams,
  RoomRenterBatchImportPreviewRequest
} from '../../model/renter/renter.model';
import {AuthService} from "../common/auth.service";
import {RequestService} from '../request.service';

/**
 * @Description: 入住人服务
 * @Author: 陆炳彬
 * @Date: 2018/3/29 上午10:23
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/3/29 上午10:23
 */
@Injectable()
export class RenterService {

  // 根据姓名和证件号获取入住人信息
  private RENTER_LIST = `${environment.apiHost}manage/renter/renterlist`;
  // 给已存在的入住人办理入住
  private CHECK_IN = `${environment.apiHost}manage/renter/checkIn`;
  // 查询该租约下可办理入住的房间列表
  private ROOM_LIST = `${environment.apiHost}manage/renter/roomList`;
  // 创建入住人
  private RENTER_CREATE = `${environment.apiHost}manage/renter/createRenter`;
  // 批量操作入住人
  private BATCH_OPERATE = `${environment.apiHost}manage/renter/batch/operate`;
  // 查询某租约房间的有效入住人
  private LEASE_ROOM_RENTER = `${environment.apiHost}manage/renter/leaseRoomRenter`;
  //上传已签署的入住公约
  private CONVENTION_UPLOAD = `${environment.apiHost}manage/renter/convention/upload`;
  //检查房间是否有未退房合同
  private CHECK_ROOM_OUT = `${environment.apiHost}manage/renter/checkRoomOut`;
  //房间已入住人员退宿
  private ROOM_RENTER_CHECK_OUT = `${environment.apiHost}manage/renter/renterCheckOut`;

  // 查询某租约房间的在住入住人
  private CHECKIN_RENTER = `${environment.apiHost}manage/renter/checkin/list`;
  // 查询换房确认的信息
  private CONFIRM_CR_INFO = `${environment.apiHost}manage/lease/room/change/renter/change/info`;

  //是否重复入住
  private CHECK_IN_REPEAT = `${environment.apiHost}manage/renter/checkInRepeat`;

  // 住客管理列表
  private LIST = `${environment.apiHost}manage/renter/list`;

  // 入住人详情
  private RENTER_DETAIL = `${environment.apiHost}manage/renter/detail`;

  // 入住人的历史入住记录
  private RENTER_RENTED_LIST = `${environment.apiHost}manage/renter/rented/list`;

  // 住客管理列表-下载
  private LIST_DOWNLOAD = `${environment.apiHost}manage/renter/list/download`;

  //批量导入入住人
  private BATCH_IMPORT_RENTER = `${environment.apiHost}manage/room/importRenter`;

  // 修改入住人手机号
  private MODIFY_RENTER_MOBILE = `${environment.apiHost}sms/code/renter/modify/mobile`;

  // 获取入住人添加配置
  private RENTER_EXT_INFO = `${environment.apiHost}manage/renter/getRenterAddExt`;

  private RENTER_EXT_INFOS = `${environment.apiHost}manage/renter/getRenterExtInfo`;

  // 获取入住人导入模板
  private RENTER_BATCH_IMPORT = `${environment.apiHost}manage/renter/importBatchMore`;

  private RENTER_BATCH_TEMPLATE = `${environment.apiHost}manage/renter/downLoad/renterTemplate`;

  //修改租客基本信息
  private MODIFIY_RENTER_INFO = `${environment.apiHost}manage/renter/modifiyRenterInfo`;

  // 查询该项目是否有入住公约，有则返回入住公约连接
  private HOUSE_HOLD_CONVENTION = `${environment.apiHost}manage/renter/houseHoldConvention`;

  private CAL_FREE_TIME = `${environment.apiHost}manage/renter/cal/free/time`;

  // 查询所有房间下所有入住人
  private ALL_RENTER_LIST = `${environment.apiHost}manage/renter/all/renter/list`;


  // 退宿未结算列表
  private CHECKOUT_NOT_FINISH_LIST = `${environment.apiHost}manage/renter/checkout/waitfinanceconfirm/list`;
  // 退宿未结算列表
  private CHECKOUT_FINANCE_NOT_FINISH_LIST = `${environment.apiHost}manage/renter/checkout/waitfinancefinish/list`;
  // 退宿未结算取消
  private CHECKOUT_FINANCE_NOT_FINISH_CANCEL = `${environment.apiHost}manage/renter/checkout/waitfinancefinish/cancel`;
  // 退宿提醒列表
  private CHECKOUT_REMIND_LIST = `${environment.apiHost}manage/renter/checkout/remind/list`;
  // 退宿申请取消/同意
  private CHECKOUT_REMIND_OPERATE = `${environment.apiHost}manage/renter/checkout/request`;
  // 退宿转账失败提醒列表
  private CHECKOUT_TRANSFER_ERROR_REMIND_LIST = `${environment.apiHost}manage/renter/checkout/transfererror/remind/list`;
  // 退宿转账失败提醒处理
  private CHECKOUT_TRANSFER_ERROR_REMIND_DEAL = `${environment.apiHost}manage/renter/checkout/transfererror/deal`;



  constructor(private requestService: RequestService,
              private authService: AuthService) {
  }


  /**
   * @Description: 根据姓名和证件号匹配入住人
   * @Author: yw
   */
  public matchLesseeList(lesseeListReqData: MatchLesseeParams): Promise<any> {
    return this.requestService.authGet(this.RENTER_LIST, lesseeListReqData);
  }

  /**
   * @Description: 给已存在的入住人办理入住
   * @Author: yw
   */
  public checkIn(checkInParams: CheckInParams): Promise<any> {
    return this.requestService.authPost(this.CHECK_IN, checkInParams);
  }

  /**
   * @Description: 查询该租约下可办理入住的房间列表
   * @Author: yw
   */
  public queryRoomList(roomListReqData: RoomListParams): Promise<any> {
    return this.requestService.authGet(this.ROOM_LIST, roomListReqData);
  }

  /**
   * @Description: 创建入住人
   * @Author: yw
   */
  public createRenter(createRenterParams: any): Promise<any> {
    return this.requestService.authPost(this.RENTER_CREATE, createRenterParams);
  }

  /**
   * @Description: 批量添加入住人
   * @Author: 陆炳彬
   * @Date: 2018/9/27 下午4:13
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/9/27 下午4:13
   */
  public batchOperate(params: any): Promise<any> {
    return this.requestService.authPost(this.BATCH_OPERATE, params);
  }

  /**
   * 查询某租约房间的有效入住人
   * @param {LeaseRoomRentRequestParam} param
   * @returns {Promise<any>}
   */
  public queryLeaseRoomRenterList(param: LeaseRoomRentRequestParam): Promise<any> {
    return this.requestService.authGet(this.LEASE_ROOM_RENTER, param);
  }

  /**
   * @Description: 上传已签署的入住公约
   * @Author: yw
   */
  public uploadConvention(req: any): Promise<any> {
    return this.requestService.authPost(this.CONVENTION_UPLOAD, req);
  }

  /**
   * @Description: 检查房间是否有未退房合同
   * @Author: zsy
   */
  public checkRoomOut(param: LeaseRoomRentRequestParam): Promise<any> {
    return this.requestService.authGet(this.CHECK_ROOM_OUT, param);
  }

  /**
   * 房间已入住人员退宿
   * @param param
   */
  public renterCheckOut(param: RenterCheckOutRequest): Promise<RenterCheckOutResponse> {
    return this.requestService.authGet(this.ROOM_RENTER_CHECK_OUT, param);
  }

  /**
   * 查询某租约房间的有效入住人
   * @param {LeaseRoomRentRequestParam} param
   * @returns {Promise<any>}
   */
  public queryCheckinRenterList(param: LeaseRoomRentRequestParam): Promise<any> {
    return this.requestService.authGet(this.CHECKIN_RENTER, param);
  }

  /**
   * 查询换房确认的信息
   * @returns {Promise<any>}
   */
  public queryCrConfirmInfo(param: any): Promise<any> {
    return this.requestService.authGet(this.CONFIRM_CR_INFO, param);
  }

  /**
   * @Description: 是否重复入住同一房间
   * @Author: zh
   */
  public checkInRepeat(request: RenterRepeatRequest): Promise<RenterRepeatResponse> {
    return this.requestService.authGet(this.CHECK_IN_REPEAT, request);
  }

  /**
   * 住客管理 列表
   * @param request
   */
  public list(request: RenterListRequest): Promise<RenterListResponse> {
    return this.requestService.authGet(this.LIST, request);
  }

  /**
   * 住客管理 详情
   * @param request
   */
  public detail(request: any): Promise<any> {
    return this.requestService.authGet(this.RENTER_DETAIL, request);
  }

  /**
   * 入住人的入住历史记录
   * @param request
   */
  getRentedInfoList(request: any): Promise<any> {
    return this.requestService.authGet(this.RENTER_RENTED_LIST, request);
  }

  downloadRenterList(request: RenterListRequest) {
    let param = "";
    for (let key in request) {
      if (request[key] && request.hasOwnProperty(key)) {
        param += (key + '=' + request[key] + '&');
      }
    }

    if (param) {
      window.open(this.LIST_DOWNLOAD + "?" + param + 'Authorization=' + this.authService.getAuthToken(), "_blank");
    } else {
      window.open(this.LIST_DOWNLOAD + '?Authorization=' + this.authService.getAuthToken(), "_blank");
    }
  }

  /**
   * 批量导入住客
   * @param request
   */
  public batchImportRenter(request: RoomRenterBatchImportPreviewRequest): Promise<any> {
    return this.requestService.authPost(this.BATCH_IMPORT_RENTER, request);
  }

  /**
   * 修改入住人手机号
   * @param request
   */
  public modifyRenterMobile(request: any): Promise<any> {
    return this.requestService.authPost(this.MODIFY_RENTER_MOBILE, request);
  }

  public getPersonType(): Promise<RenterPersonTypeResponse> {
    return this.requestService.authGet(this.RENTER_EXT_INFO);
  }

  public getRenterExtInfo(): Promise<ProjectExtInfoResponse> {
    return this.requestService.authGet(this.RENTER_EXT_INFOS);
  }

  public getCustomBatchImport(): Promise<Boolean> {
    return this.requestService.authGet(this.RENTER_BATCH_IMPORT);
  }

  public modifyRenterInfo(modifiyRenterInfo: any): Promise<any> {
    return this.requestService.authPost(this.MODIFIY_RENTER_INFO, modifiyRenterInfo);
  }

  public getHouseHoldConvention(req: any): Promise<any> {
    return this.requestService.authGet(this.HOUSE_HOLD_CONVENTION, req);
  }

  public calFreeTime(req: CalFreeTimeReq): Promise<CalFreeTimeResp> {
    return this.requestService.authGet(this.CAL_FREE_TIME, req);
  }

  public queryAllRenterInfoList(req: QueryAllRenterListReq): Promise<QueryAllRenterListResp>{
    return this.requestService.authGet(this.ALL_RENTER_LIST, req);
  }


  public getRenterCheckoutNotFinishList(req: RenterCheckoutNotFinishRequest): Promise<RenterCheckoutNotFinishResponse>{
    return this.requestService.authGet(this.CHECKOUT_NOT_FINISH_LIST, req);
  }

  public getRenterCheckoutFinanceNotFinishList(req: RenterCheckoutFinanceNotFinishRequest): Promise<RenterCheckoutFinanceNotFinishResponse>{
    return this.requestService.authGet(this.CHECKOUT_FINANCE_NOT_FINISH_LIST, req);
  }

  public postRenterCheckoutFinanceNotFinishCancel(recordId): Promise<any>{
    return this.requestService.authPost(this.CHECKOUT_FINANCE_NOT_FINISH_CANCEL, {record_id: recordId});
  }

  public getRenterCheckoutRemindList(req: RenterCheckoutRemindListRequest): Promise<RenterCheckoutRemindListResponse>{
    return this.requestService.authGet(this.CHECKOUT_REMIND_LIST, req);
  }

  public postRenterCheckoutRequest(recordId, agree): Promise<any>{
    return this.requestService.authPost(this.CHECKOUT_REMIND_OPERATE, {record_id: recordId, agree: agree});
  }

  public getRenterCheckoutTransferErrorRemindList(req: RenterCheckoutRemindListRequest): Promise<RenterCheckoutRemindListResponse>{
    return this.requestService.authGet(this.CHECKOUT_TRANSFER_ERROR_REMIND_LIST, req);
  }

  public postRenterCheckoutTransferErrorDeal(req: RenterCheckoutTransferErrorDealRequest): Promise<any>{
    return this.requestService.authPost(this.CHECKOUT_TRANSFER_ERROR_REMIND_DEAL, req);
  }
}
