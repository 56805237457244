import * as Highcharts from 'highcharts';
import {Chart} from "angular-highcharts";

/**
 * @Description: 黑色主题
 * @Author: 陆炳彬
 * @Date: 2018/10/10 下午2:17
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/10/10 下午2:17
 */
export function setDarkTheme() {
  // @ts-ignore
  Highcharts.theme = {
    colors: ['#2b908f', '#90ee7e', '#f45b5b', '#7798BF', '#aaeeee', '#ff0066',
      '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
    chart: {
      backgroundColor: 'rgba(0,0,0,30%)',
      style: {
        fontFamily: '\'Unica One\', sans-serif',
        opacity: '90%',
      },
      spacingLeft: 5,
      spacingRight: 20,
      plotBorderColor: '#6E6E6E',
      borderRadius: '10px',
      borderWidth: 2,
      borderColor: '#344992'
    },
    title: {
      style: {
        color: '#E0E0E3',
        textTransform: 'uppercase',
        fontSize: '20px'
      }
    },
    subtitle: {
      style: {
        color: '#E0E0E3',
        textTransform: 'uppercase'
      }
    },
    xAxis: {
      gridLineColor: '#6E6E6E',
      labels: {
        style: {
          color: '#E0E0E3'
        }
      },
      lineColor: '#6E6E6E',
      minorGridLineColor: '#6E6E6E',
      tickColor: '#6E6E6E',
      title: {
        style: {
          color: '#A0A0A3'

        }
      }
    },
    yAxis: {
      gridLineColor: '#6E6E6E',
      labels: {
        style: {
          color: '#E0E0E3'
        }
      },
      lineColor: '#6E6E6E',
      minorGridLineColor: '#6E6E6E',
      tickColor: '#6e6e6e',
      tickWidth: 1,
      title: {
        style: {
          color: '#A0A0A3'
        }
      }
    },
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
      style: {
        color: '#F0F0F0'
      }
    },
    plotOptions: {
      // series: {
      //   dataLabels: {
      //     color: '#B0B0B3'
      //   },
      //   marker: {
      //     lineColor: '#333'
      //   }
      // },
      boxplot: {
        fillColor: '#505053'
      },
      candlestick: {
        lineColor: 'white'
      },
      errorbar: {
        color: 'white'
      }
    },
    legend: {
      itemStyle: {
        color: '#E0E0E3'
      },
      itemHoverStyle: {
        color: '#FFF'
      },
      itemHiddenStyle: {
        color: '#606063'
      }
    },
    credits: {
      style: {
        color: '#666'
      }
    },
    labels: {
      style: {
        color: '#707073'
      }
    },

    drilldown: {
      activeAxisLabelStyle: {
        color: '#F0F0F3'
      },
      activeDataLabelStyle: {
        color: '#F0F0F3'
      }
    },

    navigation: {
      buttonOptions: {
        symbolStroke: '#DDDDDD',
        theme: {
          fill: '#505053'
        }
      }
    },

    // scroll charts
    rangeSelector: {
      buttonTheme: {
        fill: '#505053',
        stroke: '#000000',
        style: {
          color: '#CCC'
        },
        states: {
          hover: {
            fill: '#707073',
            stroke: '#000000',
            style: {
              color: 'white'
            }
          },
          select: {
            fill: '#000003',
            stroke: '#000000',
            style: {
              color: 'white'
            }
          }
        }
      },
      inputBoxBorderColor: '#E0E0E3',
      inputStyle: {
        backgroundColor: '#333',
        color: 'silver'
      },
      labelStyle: {
        color: 'silver'
      }
    },

    navigator: {
      handles: {
        backgroundColor: '#666',
        borderColor: '#AAA'
      },
      outlineColor: '#CCC',
      maskFill: 'rgba(255,255,255,0.1)',
      series: {
        color: '#7798BF',
        lineColor: '#A6C7ED'
      },
      xAxis: {
        gridLineColor: '#505053'
      }
    },

    scrollbar: {
      barBackgroundColor: '#808083',
      barBorderColor: '#808083',
      buttonArrowColor: '#CCC',
      buttonBackgroundColor: '#606063',
      buttonBorderColor: '#606063',
      rifleColor: '#FFF',
      trackBackgroundColor: '#404043',
      trackBorderColor: '#404043'
    },

    // special colors for some of the
    legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
    background2: '#505053',
    dataLabelsColor: '#B0B0B3',
    textColor: '#C0C0C0',
    contrastTextColor: '#F0F0F3',
    maskColor: 'rgba(255,255,255,0.3)'
  };

  // Apply the theme
  // @ts-ignore
  Highcharts.setOptions(Highcharts.theme);
}

/**
 * @Description: 获取随机数组
 * @Author: 陆炳彬
 * @Date: 2018/10/10 下午2:16
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/10/10 下午2:16
 */
export function generateRandomArr(repeat_num: number, start: number, seed: number, change_range: number,
                                  next_num_relation: boolean = true): Array<number> {
  let arr = [];
  for (let i = 0; i < repeat_num; i++) {

    let num = 0;
    do {
      num = parseInt((start + (Math.random() * change_range) * (Math.random() > seed ? 1 : -1)).toString(), 0);
    } while (num < 0) ;

    arr.push(num);

    if (next_num_relation) {
      start = num;
    }
  }
  return arr;
}

/**
 * @Description: 获取预测随机数组
 * @Author: 陆炳彬
 * @Date: 2018/10/10 下午2:17
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/10/10 下午2:17
 */
export function generateForecastRadomArr(arr: Array<number>, seed: number, change_range: number): Array<number> {
  let nearly_arr: number[] = [];
  arr.forEach(item => {
    let num = item + (Math.random() * change_range * (Math.random() > seed ? 1 : -1));
    nearly_arr.push(parseInt(num.toString(), 0));
  });

  return nearly_arr;
}

/**
 * @Description: 生成repeat_num个0-mul_num范围内的数值
 * @Author: 陆炳彬
 * @Date: 2018/10/15 下午5:29
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/10/15 下午5:29
 */
export function generateMathRandomArr(repeat_num: number, mul_num: number = 1): Array<number> {
  let arr: number[] = [];
  for (let i = 0; i < repeat_num; i++) {
    let num = parseInt((Math.random() * mul_num).toString(), 0);
    arr.push(num);
  }
  return arr;
}

/**
 * @Description: 重新设置图表大小
 * @Author: 陆炳彬
 * @Date: 2018/10/15 下午3:33
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/10/15 下午3:33
 */
export function resizeCharts(charts: Chart[]) {
  if (charts && charts.length > 0) {
    setTimeout(function () {
      charts.forEach(item =>{
        if(item.ref) {
          item.ref.reflow();
        }
      });
    }, 200);
  }
}
