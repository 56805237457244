<!--                   收款dialog      开始       -->
<!-- 收款 -->
<div class="modal fade" bsModal #prepaygatherBill="bs-modal" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-lg">
    <div class="modal-content">
      <div class="modal-header money-div">
        <img class="money-img" src="../../../../assets/img/money/u7947.png">
        <h4 class="modal-title pull-left money-title">收银台-企业预付费充值</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeGatherDialog(prepaygatherBill)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body money-body">

        <div class="ml-5 my-3 mt-2">
            <div class="mx-5">
              <span class="hei32">您正在为合同号：</span>
              <span class="font-weight-bold hei32">{{contactNum}} 【{{contactName}}】</span>
              <span class="hei32">操作企业预付费账户充值：</span>
            </div>
            <div class=" row">
              <div class="col-4 text-right hei32">
                充值金额：
              </div>
              <div class="col-8 hei32">
                <input type="text" placeholder="请输入充值金额" [(ngModel)]="inputAmount" (keyup)="confirmCouponNumberEvent($event)" />元
              </div>
            </div>
            <div class=" row mt-2">
              <div class="col-4 text-right hei32">
                <input type="checkbox" style="vertical-align:middle" (change)="changeSelectCouponInput($event)" [checked]="selectCouponInput">
                使用代金券：
              </div>
              <div class="col-8 hei32 mt-2">
                <ng-container *ngIf="selectCouponInput">
                  <input class="mr10" type="text" [(ngModel)]="coupon_number"/>
                  <div class="b-blues pointer ml-2" placeholder="请输入券号" (click)="confirmCouponNumber(this.inputAmount)" style="display: inline-block;">确定</div>
                  <ng-container *ngIf="coupon_msg">
                    (<span style="color: red;">{{coupon_msg}}</span>)
                  </ng-container>
                </ng-container>
              </div>
          </div>
        </div>

        <div class="money-total">
          应收总计：
          <span class="money-amount">{{paymentAmount}}元</span>
        </div>

        <div class="money-pay-1" *ngIf="paymentAmount!=0">
          <div class="">
            <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length > 1">
              <div class="money-span pointer">
                <img class="money-img2" src="../../../../assets/img/money/account.png">
                账户
              </div>
              <span class="ml-2 hint-1 text-sm text-danger valign-bottom">
                 请先选择付款账户
                </span>
            </ng-container>

            <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length == 1">
              <ng-container
                *ngIf="!compareLeaseAccount(paymentAmount, queryAccountLeaseResponse.account_money_list[0]);">
                <div class="money-span pointer">
                  <img class="money-img2" src="../../../../assets/img/money/account.png">
                  账户
                </div>
              </ng-container>

              <ng-container
                *ngIf="compareLeaseAccount(paymentAmount, queryAccountLeaseResponse.account_money_list[0]);">
                <div class="money-span pointer" (click)="prepayPayment('ACCOUNT_MONEY',accountPayment,null, queryAccountLeaseResponse.account_money_list[0].account_money_info_id)">
                  <img class="money-img2" src="../../../../assets/img/money/account.png">
                  账户
                </div>
              </ng-container>

              <span class="ml-2 hint-1 text-sm text-secondary valign-bottom">
                账户余额{{queryAccountLeaseResponse.account_money_list[0].balance}}元
                 <ng-container
                   *ngIf="!compareLeaseAccount(paymentAmount, queryAccountLeaseResponse.account_money_list[0]);">
               （余额不足）
                 </ng-container>
              </span>
            </ng-container>
          </div>

          <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length > 1">
            <div class="">
              <div class="text-danger text-sm">该客户存在多个账户，请先选择该次付款的账户</div>
              <ng-container *ngFor="let info of queryAccountLeaseResponse.account_money_list">
                <ng-container
                  *ngIf="compareLeaseAccount(paymentAmount, info);">
                  <div class="money-span money-span3 active pointer border border-green" (click)="prepayPayment('ACCOUNT_MONEY',accountPayment,null, info.account_money_info_id)">
                    <div class="text-green1 text-truncate" [title]="info.account_money_name">{{info.account_money_name}}</div>
                    <div class="text-gray1 text-truncate" [title]="info.balance">余额{{info.balance}}元</div>

                    <ng-container *ngIf="account_money_info_id === info.account_money_info_id">
                      <div class="r-flag position-absolute d-inline-block">
                        <i class="fa fa-check"></i>
                        <div class="position-absolute">
                          <div class="position-relative r-flag-angle">
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="!compareLeaseAccount(paymentAmount, info);">
                  <div class="money-span money-span3 pointer border bg-gray1">
                    <div class="text-gray1 text-truncate">{{info.account_money_name}}</div>
                    <div class="text-gray1 text-truncate">余额{{info.balance}}元</div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>

        <div class="money-pay" *ngIf="paymentAmount!=0">
          <span class="money-span2">确认金额后，选择收款：</span>
          <span class="money-span pointer" (click)="prepayPayment('WEIXIN',prepayaliOrWeixin,'aliOrWeixin')"><img class="money-img2" src="../../../../assets/img/money/wx.png">微信</span>
          <span class="money-span pointer" (click)="prepayPayment('ALIPAY',prepayaliOrWeixin,'aliOrWeixin')"><img class="money-img2" src="../../../../assets/img/money/ali.png">支付宝</span>
          <span class="money-span pointer" (click)="prepayPayment('TRANSFER',prepayposPaymnet,null)"><img class="money-img2" src="../../../../assets/img/money/zz.png">转账</span>
          <span class="money-span pointer" (click)="prepayPayment('CASH',prepayposPaymnet,null)"><img class="money-img2" src="../../../../assets/img/money/cash.png">现金</span>
          <!--<span class="money-span pointer" (click)="prepayPayment('CCBPAY',prepayaliOrWeixin,'aliOrWeixin')"><img class="money-img2" src="../../../../assets/img/money/jh.png">龙支付</span>-->
          <!--<span class="money-span pointer" (click)="payment('POS',posPaymnet,null)"><img class="money-img2" src="../../../../assets/img/money/pos.png">POS</span>-->
          <!--<span class="money-span pointer" (click)="payment('CHECK',posPaymnet,null)"><img class="money-img2" src="../../../../assets/img/money/check.png">支票</span>-->
        </div>

        <div class="money-pay" *ngIf="paymentAmount==0&&selectCouponInput&&coupon_number">
          <span class="money-span2">确认金额后，选择收款：</span>
          <button type="button" class="btn zeroBtn" (click)="prepayPayment(null,null,null)">确认收款</button>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- 支付宝/微信收款 -->
<div class="modal fade" bsModal #prepayaliOrWeixin="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作收款</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeAliOrWei()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">

          <span class="payIcon black" *ngIf="paymentType === 'WEIXIN'">
             <img class="payImg" src="../../../../assets/img/money/wx.png">
              微信
          </span>
          <span class="payIcon black" *ngIf="paymentType === 'ALIPAY'">
             <img class="payImg" src="../../../../assets/img/money/ali.png">
              支付宝
          </span>
          <span class="payIcon black" *ngIf="paymentType === 'CCBPAY'">
             <img class="payImg" src="../../../../assets/img/money/jh.png">
              龙支付
          </span>
          <small class="text-orange">收款金额：<span class="money-color">{{paymentAmount}}</span>元</small>

          <!--<small class="text-orange">已选择{{paymentTypeName}}收款</small>-->
          <!--{{paymentAmount}}元-->
          <!--<small class="text-orange">正在等待支付中({{clock}})……</small>-->
        </h2>

        <h2 class="red text-center mb20"><small class="text-orange">等待支付中…&nbsp;({{clock}}s)</small></h2>

        <p class="font12 text-center black mb20">
          支付成功后，{{paymentAmount}}元预付账户充值才会生效，否则不生效</p>
        <p class="b-blues font12 text-center " *ngIf="showContent"
           (click)="prepayshowQueryByOrderId(prepayorderQuery,prepayaliOrWeixin)">
          如果实际已支付，但还没有见到支付成功的提示，请点击此处；</p>
      </div>
    </div>
  </div>
</div>

<!-- 现金收款 -->
<div class="modal fade" bsModal #prepaycashPayment="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作收款</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="prepaycashPayment.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">

          <span class="payIcon black">
             <img class="payImg" src="../../../../assets/img/money/cash.png">
              现金
          </span>
          <small class="text-orange">收款金额：<span class="money-color">{{paymentAmount}}</span>元</small>

          <!--<small class="text-orange">已选择{{paymentTypeName}}收款</small>-->
          <!--{{paymentAmount}}元-->
          <!--<small class="text-orange">请确认</small>-->
        </h2>
        <p class="font12 text-center black mb20">确认后立即入账且不可更改，是否确认已收？</p>
        <!--<p class="font12 text-center black mb40">-->
          <!--支付成功后，{{paymentAmount}}元预付账户充值才会生效，否则不生效</p>-->
        <!--<p class="b-muted font12 text-center mb20">确认后不得现更改，将立即入账</p>-->
        <div class="text-center mb20">
          <button type="button" class="btn btn-warning mr20"
                  (click)="confirm(paymentType)">
            确认已收
          </button>
          <button type="button" class="btn btn-secondary" (click)="prepaycashPayment.hide()">取消</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- POS/转账收款 -->
<div class="modal fade" bsModal #prepayposPaymnet="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left">
          <!--{{paymentType === 'CHECK' ? '支票' : (paymentType === 'POS' ? 'POS' : '转账')}}-->
          <!--收款-->
          <i class="fa fa-info-circle" aria-hidden="true"></i>操作收款
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="prepayposPaymnet.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">

           <span class="payIcon black" *ngIf="paymentType === 'POS'">
             <img class="payImg" src="../../../../assets/img/money/pos.png">
              POS
          </span>
          <span class="payIcon black" *ngIf="paymentType === 'TRANSFER'">
             <img class="payImg" src="../../../../assets/img/money/zz.png">
              转账
          </span>
          <span class="payIcon black" *ngIf="paymentType === 'CHECK'">
             <img class="payImg" src="../../../../assets/img/money/check.png">
              支票
          </span>
          <span class="payIcon black" *ngIf="paymentType === 'CASH'">
             <img class="payImg" src="../../../../assets/img/money/cash.png">
              现金
          </span>
          <small class="text-orange">收款金额：<span class="money-color">{{paymentAmount}}</span>元</small>




          <!--<small class="text-orange">已选择{{paymentTypeName}}付款</small>-->
          <!--{{paymentAmount}}元-->
          <!--<small class="text-orange">请上传凭证附件后保存</small>-->
        </h2>
        <p class="b-muted font12 text-left black" style="padding-left: 15px;margin-top: 40px;margin-bottom: -5px;" *ngIf="paymentType === 'POS'">请上传pos收银小票：（每张限15M以内，最多5张）</p>
        <p class="b-muted font12 text-left black" style="padding-left: 15px;margin-top: 40px;margin-bottom: -5px;" *ngIf="paymentType === 'TRANSFER'">请上传转账凭证：（每张限15M以内，最多5张）</p>
        <p class="b-muted font12 text-left black" style="padding-left: 15px;margin-top: 40px;margin-bottom: -5px;" *ngIf="paymentType === 'CHECK'">请上传支票照片：（每张限15M以内，最多5张）</p>
        <p class="b-muted font12 text-left black" style="padding-left: 15px;margin-top: 40px;margin-bottom: -5px;" *ngIf="paymentType === 'CASH'">请上传现金收据照片：（每张限15M以内，最多5张）</p>

        <!--<p class="b-muted font12 text-center mb20" *ngIf="paymentType === 'POS'">POS收款请拍照收银小票</p>-->
        <!--<p class="b-muted font12 text-center mb20" *ngIf="paymentType === 'TRANSFER'">转账请上传转账凭证</p>-->
        <!--<p class="b-muted font12 text-center mb20" *ngIf="paymentType === 'CHECK'">支票请拍照</p>-->
        <div class="text-center mb20">
          <div class="padd15">
            <ul class="img-box">
              <app-new-file-upload [type]="2" [maxSize]="15 * 1024 * 1024" [accept]="'IMG'" [maxLength]="5"
                                   [imgList]="prepayRechargeReq?.cret_images"
                                   (fileChange)="paymnetFill($event)"></app-new-file-upload>
            </ul>
          </div>
        </div>
        <p class="b-muted font12 text-center mb20"
           *ngIf="paymentType==='CHECK' || paymentType === 'TRANSFER' || paymentType === 'CHECK'">
          最多可上传5个附件，每个15M之内
        </p>

        <p class="font12 text-center black mb40">
          支付成功后，{{paymentAmount}}元预付账户充值才会生效，否则不生效</p>

        <div class="text-center mb20">
          <button type="button" class="btn btn-warning mr20"
                  (click)="confirm(paymentType)">
            确认已收
          </button>
          <button type="button" class="btn btn-secondary" (click)="prepayposPaymnet.hide()">取消</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 账户收款 -->
<div class="modal fade" bsModal #accountPayment="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作收款</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="accountPayment.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">
          <span class="payIcon black">
             <img class="payImg" src="../../../../assets/img/money/cash.png">
              客商账户
          </span>
          <small class="text-orange">收款金额：<span
            class="money-color">{{paymentAmount}}</span>元</small>
        </h2>
        <p class="font12 text-center black mb20">
          支付成功后，{{paymentAmount}}元预付账户充值才会生效，否则不生效</p>
        <p class="b-muted font12 text-center mb20">确认后立即入账且不可更改，是否确认已收？</p>
      </div>
      <div class="modal-footer">
        <div class="text-center mb20">
          <button type="button" class="btn btn-warning mr20" style="background-color: rgba(242, 90, 99, 1);"
                  (click)="confirm(paymentType)">
            确认已收
          </button>
          <button type="button" class="btn btn-secondary"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;"
                  (click)="accountPayment.hide()">取消
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 收款成功 -->
<div class="modal fade" bsModal #prepaygatherSuccess="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>收款成功</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="prepaygatherSuccess.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">
          <small class="text-orange">已成功收款</small>
          {{paymentAmount}}元
          <small class="text-orange">此窗口将在{{close}}秒后关闭</small>
        </h2>
        <p class="font12 text-center text-orange mb40">
          另已成功将{{paymentAmount}}元定金抵扣账单</p>
        <p class="b-muted font12 text-center mb20">当您看见此界面，代表此款已经入账</p>
        <div class="text-center mb20">
          <button type="button" class="btn btn-primary mr20" (click)="printPaymentCret()">
            <i class="fa fa-print"></i>打印凭证
          </button>
          <button type="button" class="btn btn-secondary" (click)="prepaygatherSuccess.hide()">关闭</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 账单订单号绑定页面 -->
<div class="modal fade" bsModal #prepaybindOrderBill="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>请选择收款项</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="prepaybindOrderBill.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <p class="red mb10">已读取到{{orderDtos.length}}笔相同金额的收款，请选择确认：</p>
        <div class="table-box">
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th>商户账单号</th>
              <th>支付金额</th>
              <th>支付时间</th>
              <th>操作</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of orderDtos">
              <td>{{item.order_id}}</td>
              <td>{{item.order_amount}}</td>
              <td>{{item.pay_time |date:'yyyy-MM-dd HH:mm:ss'}}</td>
              <td>
                <button type="button" class="btn btn-info">
                  <i class="fa fa-eye"
                     (click)="reletedBillAndOrder(item.order_no,prepaybindOrderBill,prepaygatherSuccess)">选择确认</i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <p class="b-blues font12" (click)="showQueryByOrderId(prepayorderQuery,prepaybindOrderBill)">
          上面没有找到相应的数据，但用户实际已经完成付款，请点此处</p>
      </div>
    </div>
  </div>
</div>

<!-- 商户订单号查询页面 -->
<div class="modal fade" bsModal #prepayorderQuery="bs-modal" style="z-index: 999999;background: rgba(0,0,0,0.4);"
     [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>输入商户订单号：</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="closeOrderQueryDialog()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-center mb20 black">请输入用户手机支付结果中的商户订单号：</p>
        <div class="row mb10">
          <div class="col-md-6 offset-md-3">
            <input type="text" class="w-100 " [(ngModel)]="orderId">
          </div>
        </div>
        <p class="text-orange font12 text-center mb20" *ngIf="orderRemind">查不到相应的支付记录 ，请确认订单号是否正确</p>
        <div class="text-center mb20">
          <button type="button" class="btn btn-primary mr20"
                  (click)="prepayQueryByOrderId(prepayorderQuery,prepaygatherSuccess)">
            确认
          </button>
          <button type="button" class="btn btn-secondary" (click)="closeOrderQueryDialog()"> 关闭</button>
        </div>
      </div>
    </div>
  </div>
</div>
