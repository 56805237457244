import {NgModule} from '@angular/core';

import {SignedRoutingModule} from './signed-routing.module';
import {ListComponent} from './list/list.component';
import {PactComponent} from './pact/pact.component';
import {SharedRootModule} from '../shared/module/share-common.module';
import {SignChangeComponent} from './sign-change/sign-change.component';
import {GoodsListComponent} from './goods-list/goods-list.component';
import {ContractComponent} from './contract/contract.component';
import {CheckoutRoomSelectComponent} from './checkout/checkout-room-select/checkout-room-select.component';
import {CheoutAffirmComponent} from './checkout/cheout-affirm/cheout-affirm.component';
import {CheoutListComponent} from './checkout/cheout-list/cheout-list.component';
import {CheckoutInventoryComponent} from './checkout/checkout-inventory/checkout-inventory.component';
import {ContractPreviewComponent} from './contract-preview/contract-preview.component';
import {CheckoutInventoryViewComponent} from './checkout/checkout-inventory-view/checkout-inventory-view.component';
import {CheckoutComponent} from './checkout/checkout/checkout.component';
import {GarageListComponent} from "./garage-list/garage-list.component";
import {GarageAddComponent} from "./garage-add/garage-add.component";
import {CheckoutRecordComponent} from "./checkout/checkout-record/checkout-record.component";
import {CarportSearchListComponent} from "./carport-list/carport-search-list.component";
import {CrRoomChooseComponent} from './change-room/cr-room-choose/cr-room-choose.component';
import {CrChangeRoomRecordComponent} from './change-room/cr-change-room-record/cr-change-room-record.component';
import {CrChangeRoomCheckoutInventoryComponent} from './change-room/cr-change-room-checkout-inventory/cr-change-room-checkout-inventory.component';
import {CrChangeRoomCheckoutInventoryViewComponent} from './change-room/cr-change-room-checkout-inventory-view/cr-change-room-checkout-inventory-view.component';
import {CrChangeRoomCheckinInventoryComponent} from './change-room/cr-change-room-checkin-inventory/cr-change-room-checkin-inventory.component';
import {CrChangeRoomConfirmComponent} from './change-room/cr-change-room-confirm/cr-change-room-confirm.component';
import {CrChangeRoomContractComponent} from './change-room/cr-change-room-contract/cr-change-room-contract.component';
import {CrChangeRoomDetailsComponent} from './change-room/cr-change-room-details/cr-change-room-details.component';
import {CrChangeRoomBillDetailComponent} from './change-room/cr-change-room-bill-detail/cr-change-room-bill-detail.component';
import {SubleaseRoomSelectComponent} from './pact/sublease-room-select/sublease-room-select.component';
import {CompanyPrepayComponent} from './company-prepay/company-prepay.component';
import {CompanyPrepayRechargeComponent} from "../companyprepay/company-prepay-recharge/company-prepay-recharge.component";
import {DelayRefundView} from './checkout/delay-refund-view/delay-refund-view.component';
import {LeaseFlowSubmitLogComponent} from './pact/lease-flow-submit-log/lease-flow-submit-log.component';
import {CheckoutRoomContractComponent} from './checkout/checkout-room-contract/checkout-room-contract.component';
import {CheckOutStepsComponent} from './checkout/check-out-steps/check-out-steps.component';
import {CheckOutConfirmComponent} from './checkout/check-out-confirm/check-out-confirm.component';
import {CheckOutInformationComponent} from './checkout/check-out-information/check-out-information.component';
import {CheckOutPopupComponent} from './checkout/check-out-popup/check-out-popup.component';
import {CheckoutFinanceRoomSelectComponent} from './checkout/checkout-finance-room-select/checkout-finance-room-select.component';
import { CheckoutRecordPreviewComponent } from './checkout/checkout-record-preview/checkout-record-preview.component';
import { RenterCheckoutListComponent } from './renter-checkout/renter-checkout-list/renter-checkout-list.component';
import { RenterCheckoutDetailComponent } from './renter-checkout/renter-checkout-detail/renter-checkout-detail.component';


@NgModule({
    imports: [
        SignedRoutingModule,
        SharedRootModule,
    ],
    exports: [
        LeaseFlowSubmitLogComponent
    ],
    declarations: [
        ListComponent,
        PactComponent,
        SignChangeComponent,
        GoodsListComponent,
        ContractComponent,
        CheckoutRoomSelectComponent,
        CheoutAffirmComponent,
        CheoutListComponent,
        CheckoutInventoryComponent,
        ContractPreviewComponent,
        CheckoutInventoryViewComponent,
        CheckoutComponent,
        GarageListComponent,
        GarageAddComponent,
        CheckoutRecordComponent,
        CarportSearchListComponent,
        CrRoomChooseComponent,
        DelayRefundView,

        CrChangeRoomConfirmComponent,
        CrChangeRoomBillDetailComponent,
        CrChangeRoomRecordComponent,
        CrChangeRoomCheckoutInventoryComponent,
        CrChangeRoomCheckoutInventoryViewComponent,
        CrChangeRoomCheckinInventoryComponent,
        CrChangeRoomContractComponent,
        CrChangeRoomDetailsComponent,
        SubleaseRoomSelectComponent,
        CompanyPrepayComponent,

        CompanyPrepayRechargeComponent,
        LeaseFlowSubmitLogComponent,
        CheckoutRoomContractComponent,
        CheckOutStepsComponent,
        CheckOutConfirmComponent,
        CheckOutInformationComponent,
        CheckOutPopupComponent,
        CheckoutFinanceRoomSelectComponent,
        CheckoutRecordPreviewComponent,
        RenterCheckoutListComponent,
        RenterCheckoutDetailComponent,
    ]
})
export class SignedModule {
}
