import { RequestService } from '../request.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class SystemLogService {
  private SYSTEM_LOG_LIST = `${environment.apiHost}system/log/list`;

  constructor(private requestService: RequestService) {

  }

  /**
   * @Description:   获得操作日志信息
   * @Author: zhoujiahao
   * @Date: 2018-04-04  15:09
   */
  public getSystemLogList(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.SYSTEM_LOG_LIST, requestData);
  }

}
