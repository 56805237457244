<!--  合同信息变更审批  -->
<div class="modal fade " bsModal #showLeaseChangeAuditModal="bs-modal"
     [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          <i class="fa fa-info-circle" aria-hidden="true"></i>
          <ng-container *ngIf="leaseSubjectAuditDetail">合同信息变更申请</ng-container>
          <ng-container *ngIf="leaseBillFlowAuditResponse">费用调整申请</ng-container>
          <ng-container *ngIf="assTransAuditDetailResponse">资产转移详情</ng-container>
          <ng-container *ngIf="changeRoomAvailableDaysFlowAuditResponse">房间使用期限调整审批</ng-container>
          <ng-container *ngIf="frozenPasswordFlowAuditResponse">冻结门锁密码申请</ng-container>
          <ng-container *ngIf="approLockPasswordRes">密码申请审批</ng-container>
          <ng-container *ngIf="approRenterCheckoutDeposit">住客退押申请审批</ng-container>
          <ng-container *ngIf="approProjectBasePrice">项目基础价格变更</ng-container>
          <ng-container *ngIf="approLeaseCommit">合同审批</ng-container>
          （审批单号：<span class="text-yellow1"
                      style="font-size: inherit">{{approv_num}}</span>）
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="showLeaseChangeAuditModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <div>
          <tabset #staticTabs [justified]="true">
            <tab heading="申请信息">
              <div class="my-2 mx-md-5">
                <div class="col-12">
                  <div class="row " *ngIf="leaseSubjectAuditDetail">
                    <div class="col-4"><span class="font-weight-bold">合同号</span>：
                      <button class="btn btn-link"
                              (click)="to_lease(leaseSubjectAuditDetail?.lease_id)">{{leaseSubjectAuditDetail?.contract_num}}</button>
                    </div>
                    <div class="col-4"><span class="font-weight-bold">签约人</span>：{{leaseSubjectAuditDetail?.name}}</div>
                    <div class="col-4"><span
                      class="font-weight-bold">合同周期</span>：{{leaseSubjectAuditDetail?.start_time|date:'yyyy.MM.dd'}}
                      -{{leaseSubjectAuditDetail?.end_time|date:'yyyy.MM.dd'}}</div>

                    <div class="col-4"><span class="font-weight-bold">变更内容</span>：<span
                      class="text-danger">合同主体信息</span></div>
                    <div class="col-4"><span
                      class="font-weight-bold">提交人</span>：{{leaseSubjectAuditDetail?.submit_name}}</div>
                    <div class="col-4"><span
                      class="font-weight-bold">提交时间</span>：{{leaseSubjectAuditDetail?.submit_time|date:'yyyy.MM.dd HH:mm:ss'}}
                    </div>

                    <div class="col-12 mt-2">
                      <table class="table table-bordered">
                        <thead>
                        <tr>
                          <td class="bg-gray-100">变更信息</td>
                          <td class="bg-gray-100">变更前</td>
                          <td class="bg-gray-100">变更后</td>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngIf="leaseSubjectAuditDetail?.change_before_dto.subject_type==='PERSON'">
                          <tr
                            *ngIf="leaseSubjectAuditDetail?.change_before_dto.name!=leaseSubjectAuditDetail?.change_after_dto.name">
                            <td class="bg-gray-100">签约</td>
                            <td>{{leaseSubjectAuditDetail?.change_before_dto.name}}</td>
                            <td>{{leaseSubjectAuditDetail?.change_after_dto.name}}</td>
                          </tr>
                          <tr
                            *ngIf="leaseSubjectAuditDetail?.change_before_dto.cret_num!=leaseSubjectAuditDetail?.change_after_dto.cret_num">
                            <td class="bg-gray-100">证件号</td>
                            <td>{{leaseSubjectAuditDetail?.change_before_dto.cret_num}}</td>
                            <td>{{leaseSubjectAuditDetail?.change_after_dto.cret_num}}</td>
                          </tr>
                          <tr
                            *ngIf="leaseSubjectAuditDetail?.change_before_dto.tel!=leaseSubjectAuditDetail?.change_after_dto.tel">
                            <td class="bg-gray-100">联系电话</td>
                            <td>{{leaseSubjectAuditDetail?.change_before_dto.tel}}</td>
                            <td>{{leaseSubjectAuditDetail?.change_after_dto.tel}}</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="leaseSubjectAuditDetail?.change_before_dto.subject_type==='COMPANY'">
                          <tr
                            *ngIf="leaseSubjectAuditDetail?.change_before_dto.company_name!=leaseSubjectAuditDetail?.change_after_dto.company_name">
                            <td class="bg-gray-100">企业名称</td>
                            <td>{{leaseSubjectAuditDetail?.change_before_dto.company_name}}</td>
                            <td>{{leaseSubjectAuditDetail?.change_after_dto.company_name}}</td>
                          </tr>
                          <tr
                            *ngIf="leaseSubjectAuditDetail?.change_before_dto.name!=leaseSubjectAuditDetail?.change_after_dto.name">
                            <td class="bg-gray-100">联系人</td>
                            <td>{{leaseSubjectAuditDetail?.change_before_dto.name}}</td>
                            <td>{{leaseSubjectAuditDetail?.change_after_dto.name}}</td>
                          </tr>
                          <tr
                            *ngIf="leaseSubjectAuditDetail?.change_before_dto.tel!=leaseSubjectAuditDetail?.change_after_dto.tel">
                            <td class="bg-gray-100">联系电话</td>
                            <td>{{leaseSubjectAuditDetail?.change_before_dto.tel}}</td>
                            <td>{{leaseSubjectAuditDetail?.change_after_dto.tel}}</td>
                          </tr>
                        </ng-container>
                        <tr *ngIf="isChangeEmergencyContacts(leaseSubjectAuditDetail)">
                          <td class="bg-gray-100">紧急联系人</td>
                          <td>
                            <p *ngFor="let item of leaseSubjectAuditDetail?.change_before_dto.emergency_contacts">
                              {{item.contact_name}} {{item.contact_phone}}
                            </p>
                          </td>
                          <td>
                            <p *ngFor="let item of leaseSubjectAuditDetail?.change_after_dto.emergency_contacts">
                              {{item.contact_name}} {{item.contact_phone}}
                            </p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12 mt-2">
                      <label>变更原因</label>
                      <div>
                        {{leaseSubjectAuditDetail?.change_reason}}
                      </div>
                    </div>
                    <div class="col-12 mt-2" *ngIf="leaseSubjectAuditDetail?.image_url_list">
                      <label>附件证明：</label>
                      <div>
                        <app-new-file-upload [type]="2"
                                             [accept]="'IMG'"
                                             [imgList]="leaseSubjectAuditDetail?.image_url_list"
                                             [showDelBtn]="false"
                                             [showUploadBtn]="false"
                        >
                        </app-new-file-upload>
                      </div>
                    </div>
                    <div class="col-12 mt-2" *ngIf="leaseSubjectAuditDetail?.attachment_list">
                      <label>附件：</label>
                      <div>
                        <app-new-file-upload [type]="2"
                                             [accept]="'IMG'"
                                             [imgList]="leaseSubjectAuditDetail?.attachment_list"
                                             [showDelBtn]="false"
                                             [showUploadBtn]="false"
                        >
                        </app-new-file-upload>
                      </div>
                    </div>

                  </div>

                  <div class="row " *ngIf="approLeaseCommit">
                    <div class="col-4"><span class="font-weight-bold">合同号</span>：
                      <button *ngIf="!approLeaseCommit.booking" class="btn btn-link"
                              (click)="to_lease(approLeaseCommit?.lease_id)">{{approLeaseCommit?.contract_num}}</button>
                      <button *ngIf="approLeaseCommit.booking" class="btn btn-link"
                              (click)="to_booking(approLeaseCommit?.lease_id)">{{approLeaseCommit?.contract_num}}</button>
                    </div>
                    <div class="col-4"><span class="font-weight-bold">签约人</span>：{{approLeaseCommit.lease_subject_info.company_name != null ?approLeaseCommit.lease_subject_info.company_name:approLeaseCommit.lease_subject_info.name}}
                    </div>
                    <div class="col-4"><span
                      class="font-weight-bold">合同周期</span>：{{approLeaseCommit.lease_info.start_time|date:'yyyy.MM.dd'}}
                      -{{approLeaseCommit.lease_info.end_time|date:'yyyy.MM.dd'}}</div>

                    <div class="col-4"><span class="font-weight-bold">变更内容</span>：<span
                      class="text-danger">合同审批</span></div>
                    <div class="col-4"><span
                      class="font-weight-bold">提交人</span>：{{approLeaseCommit.create_name}}</div>
                    <div class="col-4"><span
                      class="font-weight-bold">提交时间</span>：{{approLeaseCommit.commit_time |date:'yyyy.MM.dd'}}
                    </div>
                    <div class="col-4"><span
                      class="font-weight-bold">房间数</span>：{{approLeaseCommit.lease_info.room_info_list.length}}
                    </div>
                    <div class="col-12 mt-2">
                      <p>有以下事项需要审批：</p>
                      <ul style="margin-left: 20px;">
                        <li *ngIf="approLeaseCommit.booking" style="list-style-type:disc">未收取预定金</li>
                        <li *ngIf="!approLeaseCommit.booking && approLeaseCommit.lease_info.outstanding_amount_percent === 0" style="list-style-type:disc">滞纳金比例为：{{approLeaseCommit.lease_info.outstanding_amount_percent}}</li>
                        <li *ngIf="!approLeaseCommit.booking && approLeaseCommit.lease_info.grace_period_days > 0" style="list-style-type:disc">账期为{{approLeaseCommit.lease_info.grace_period_days}}天（允许账单延期收款）</li>
                        <li *ngIf="approLeaseCommit.lease_info.renter_grace_period_days > 0" style="list-style-type:disc">住客账期为{{approLeaseCommit.lease_info.renter_grace_period_days}}天（允许账单延期收款）</li>
                        <li *ngIf="approLeaseCommit.lease_info.free_start_time" style="list-style-type:disc">设置了免租期，免租期为：{{approLeaseCommit.lease_info.free_start_time |date:'yyyy.MM.dd'}}-{{approLeaseCommit.lease_info.free_end_time |date:'yyyy.MM.dd'}}</li>
                        <li *ngIf="approLeaseCommit.min_discount <= 95" style="list-style-type:disc">房费设置了折扣，明细如下
                        </li>
                      </ul>
                      <table *ngIf="approLeaseCommit.min_discount <= 95" class="table table-bordered table-fix-scroll v2">
                        <thead>
                        <tr>
                          <td class="bg-gray-100">房间号</td>
                          <td class="bg-gray-100">标准房费</td>
                          <td class="bg-gray-100">签约价（均价）</td>
                          <td class="bg-gray-100">折扣率</td>
                        </tr>
                        </thead>
                        <tbody >
                        <ng-container *ngFor="let room of approLeaseCommit.lease_info.room_info_list">
                          <tr *ngIf="room.discount <= 95">
                            <td>{{room.room_name}}</td>
                            <td>{{room.base_price}}</td>
                            <td>{{room.month_rent}}</td>
                            <td>{{room.discount}}%</td>
                          </tr>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>


                  </div>

                  <div class="row " *ngIf="leaseBillFlowAuditResponse">
                    <div class="col-4"><span class="font-weight-bold">合同号</span>：
                      <button class="btn btn-link"
                              (click)="to_lease(leaseBillFlowAuditResponse?.lease_id)">{{leaseBillFlowAuditResponse?.contract_num}}</button>
                    </div>
                    <div class="col-4"><span class="font-weight-bold">签约人</span>：{{leaseBillFlowAuditResponse?.name}}
                    </div>
                    <div class="col-4"><span
                      class="font-weight-bold">合同周期</span>：{{leaseBillFlowAuditResponse?.start_time|date:'yyyy.MM.dd'}}
                      -{{leaseBillFlowAuditResponse?.end_time|date:'yyyy.MM.dd'}}</div>

                    <div class="col-4"><span class="font-weight-bold">变更内容</span>：<span
                      class="text-danger">费用调整申请</span></div>
                    <div class="col-4"><span
                      class="font-weight-bold">提交人</span>：{{leaseBillFlowAuditResponse?.submit_name}}</div>
                    <div class="col-4"><span
                      class="font-weight-bold">提交时间</span>：{{leaseBillFlowAuditResponse?.submit_time|date:'yyyy.MM.dd HH:mm:ss'}}
                    </div>

                    <div class="col-12 mt-2">
                      <table class="table table-bordered table-fix-scroll v2">
                        <thead>
                        <tr>
                          <td class="bg-gray-100">房间号</td>
                          <td class="bg-gray-100">账单类型</td>
                          <td class="bg-gray-100">账单周期</td>
                          <td class="bg-gray-100">应收时间</td>
                          <td class="bg-gray-100">应收金额</td>
                          <td class="bg-gray-100">应收金额调整为</td>
                          <td class="bg-gray-100">调整幅度</td>
                        </tr>
                        </thead>
                        <tbody *ngIf="leaseBillFlowAuditResponse.lease_bill_flow_audit_dtolist">
                        <tr *ngFor="let bill of leaseBillFlowAuditResponse.lease_bill_flow_audit_dtolist">
                          <td>{{bill.room_name}}</td>
                          <td>{{bill.bill_type_name}}</td>
                          <td>{{bill.bill_start_time|date:'yyyy-MM-dd'}}-{{bill.bill_end_time|date:'yyyy-MM-dd'}}</td>
                          <td>{{bill.plan_pay_time|date:'yyyy-MM-dd'}}</td>
                          <td>{{bill.bill_amount}}</td>
                          <td class="text-danger">{{bill.after_amount}}</td>
                          <td class="text-danger">{{bill.percent}}%</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12">
                      共计 <span
                      class="text-danger">{{leaseBillFlowAuditResponse.lease_bill_flow_audit_dtolist.length}}</span>
                      个账单，
                      调整差额总计 <span class="text-danger">{{leaseBillFlowAuditResponse.balance}}</span> 元，调整总幅度： <span
                      class="text-danger">{{leaseBillFlowAuditResponse.balance_percent}}%</span>
                    </div>
                    <div class="col-12 mt-2">
                      <label>变更原因</label>
                      <div>
                        {{leaseBillFlowAuditResponse?.change_reason}}
                      </div>
                    </div>
                    <div class="col-12 mt-2" *ngIf="leaseBillFlowAuditResponse?.attachment_list">
                      <label>附件：</label>
                      <div>
                        <app-new-file-upload [type]="2"
                                             [accept]="'IMG'"
                                             [imgList]="leaseBillFlowAuditResponse?.attachment_list"
                                             [showDelBtn]="false"
                                             [showUploadBtn]="false"
                        >
                        </app-new-file-upload>
                      </div>
                    </div>

                  </div>

                  <div class="row" *ngIf="assTransAuditDetailResponse?.asset_edit_record_id > 0">
                    <div class="col-12 row mb10" style="margin-left: 0px;">
                      <div class="col-10 mt-2 font-xl font-weight-bold">
                        <label
                          style="color: red;font-size: 1.25rem !important;">【{{assTransAuditDetailResponse.old_project_name}}
                          】</label>
                        申请转移如下资产至
                        <label
                          style="color: red;font-size: 1.25rem !important;">【{{assTransAuditDetailResponse.new_project_name}}
                          】</label>
                      </div>
                      <div class="col-10 mt-2">
                        共计{{assTransAuditDetailResponse.asset_edit_num}}
                        项，总金额{{assTransAuditDetailResponse.asset_edit_total}}元
                      </div>
                      <div class="col-12">
                        <div class="mt-1">
                          <table class="table table-bordered table-striped table-fix-scroll">
                            <thead>
                            <tr>
                              <th class="border">序列</th>
                              <th class="border">资产类别</th>
                              <th class="border">资产编号</th>
                              <th class="border">资产名称</th>
                              <th class="border">资产型号</th>
                              <th class="border">单价</th>
                            </tr>
                            </thead>
                            <tbody>
                            <ng-container *ngIf="assTransAuditDetailResponse?.asset_list">
                              <ng-container *ngFor="let info of assTransAuditDetailResponse?.asset_list | paginate: {itemsPerPage: assTransAuditDetailRequest?.page_size,
          currentPage: assTransAuditDetailRequest?.page_num,totalItems: assTransAuditDetailResponse?.total,id:'assTransAuditDetailPage'};let i = index">
                                <tr>
                                  <td>{{info?.asset_index}}</td>
                                  <td>{{info?.batch_category}}</td>
                                  <td>{{info?.asset_serial}}</td>
                                  <td>{{info?.batch_model}}</td>
                                  <td>{{info?.batch_type}}</td>
                                  <td>{{info?.asset_price}}</td>
                                </tr>
                              </ng-container>
                            </ng-container>
                            </tbody>
                          </table>
                        </div>
                        <div class="text-right mt-3 pages" *ngIf="assTransAuditDetailResponse?.total > 0">
                          <pagination-controls id="assTransAuditDetailPage" (pageChange)="searchList($event)" directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                                               screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                                               screenReaderCurrentLabel="You're on page"></pagination-controls>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-2" *ngIf="assTransAuditDetailResponse?.attachment_list">
                      <label>附件：</label>
                      <div>
                        <app-new-file-upload [type]="2"
                                             [accept]="'IMG'"
                                             [imgList]="assTransAuditDetailResponse?.attachment_list"
                                             [showDelBtn]="false"
                                             [showUploadBtn]="false"
                        >
                        </app-new-file-upload>
                      </div>
                    </div>
                  </div>

                  <div class="row " *ngIf="changeRoomAvailableDaysFlowAuditResponse">
                    <div class="col-4"><span class="font-weight-bold">合同号</span>：
                      <button class="btn btn-link"
                              (click)="to_lease(changeRoomAvailableDaysFlowAuditResponse?.lease_id)">{{changeRoomAvailableDaysFlowAuditResponse?.contract_num}}</button>
                    </div>
                    <div class="col-4"><span
                      class="font-weight-bold">签约人</span>：{{changeRoomAvailableDaysFlowAuditResponse?.name}}</div>
                    <div class="col-4"><span
                      class="font-weight-bold">合同周期</span>：{{changeRoomAvailableDaysFlowAuditResponse?.start_time|date:'yyyy.MM.dd'}}
                      -{{changeRoomAvailableDaysFlowAuditResponse?.end_time|date:'yyyy.MM.dd'}}</div>

                    <div class="col-4"><span class="font-weight-bold">变更内容</span>：<span
                      class="text-danger">房间使用期限调整审批</span></div>
                    <div class="col-4"><span
                      class="font-weight-bold">提交人</span>：{{changeRoomAvailableDaysFlowAuditResponse?.submit_name}}
                    </div>
                    <div class="col-4"><span
                      class="font-weight-bold">提交时间</span>：{{changeRoomAvailableDaysFlowAuditResponse?.submit_time|date:'yyyy.MM.dd HH:mm:ss'}}
                    </div>

                    <div class="col-12 mt-2">
                      <table class="table table-bordered">
                        <thead>
                        <tr>
                          <td class="bg-gray-100" colspan="2">变更信息</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="bg-gray-100" width="200px">变更前</td>
                          <td>首期房费在应付时间未付，仍可在未来<span
                            class="text-danger">{{changeRoomAvailableDaysFlowAuditResponse.change_before_dto.room_available_days}}</span>天使用水电和门锁密码；{{changeRoomAvailableDaysFlowAuditResponse.change_before_dto.pay_end_date | date:'yyyy.MM.dd'}}
                            过后仍未付清，系统将会对所有房间断水断电，作废门锁密码
                          </td>
                        </tr>
                        <tr>
                          <td class="bg-gray-100" width="200px">变更后</td>
                          <td>首期房费在应付时间未付，仍可在未来<span
                            class="text-danger">{{changeRoomAvailableDaysFlowAuditResponse.change_after_dto.room_available_days}}</span>天使用水电和门锁密码；{{changeRoomAvailableDaysFlowAuditResponse.change_after_dto.pay_end_date | date:'yyyy.MM.dd'}}
                            期限过后仍未付清，系统将会对所有房间断水断电，作废门锁密码
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12 mt-2">
                      <label>申请原因：</label>
                      <div>
                        {{changeRoomAvailableDaysFlowAuditResponse?.change_reason}}
                      </div>
                    </div>
                    <div class="col-12 mt-2" *ngIf="changeRoomAvailableDaysFlowAuditResponse?.attachment_list">
                      <label>附件：</label>
                      <div>
                        <app-new-file-upload [type]="2"
                                             [accept]="'IMG'"
                                             [imgList]="changeRoomAvailableDaysFlowAuditResponse?.attachment_list"
                                             [showDelBtn]="false"
                                             [showUploadBtn]="false"
                        >
                        </app-new-file-upload>
                      </div>
                    </div>

                  </div>

                  <div class="row " *ngIf="frozenPasswordFlowAuditResponse">
                    <div class="col-4"><span class="font-weight-bold">申请内容</span>：
                      <span class="text-danger">冻结门锁密码</span>
                     </div>
                    <div class="col-4"><span
                      class="font-weight-bold">提交人</span>：{{frozenPasswordFlowAuditResponse?.submit_name}}
                    </div>
                    <div class="col-4"><span
                      class="font-weight-bold">提交时间</span>：{{frozenPasswordFlowAuditResponse?.submit_time|date:'yyyy.MM.dd HH:mm:ss'}}
                    </div>

                    <div class="col-12 mt-5">
                      <label class="font-weight-bold">申请原因：</label>
                      <div *ngIf="frozenPasswordFlowAuditResponse.frozen_type=='RENT_OVERDUE'">
                        由于【{{frozenPasswordFlowAuditResponse.sign_name}}】的
                        <button class="btn-link pl-0 pr-0"
                                (click)="to_lease(frozenPasswordFlowAuditResponse?.lease_id)">
                          【{{frozenPasswordFlowAuditResponse?.contract_num}}】
                        </button>合同
                        <span class="text-danger">逾期未缴纳房费账单</span>， 申请将该合同下未付房费账单的房间进行冻结门锁密码
                      </div>
                      <div *ngIf="frozenPasswordFlowAuditResponse.frozen_type=='LEASE_OVERDUE'">
                        由于【{{frozenPasswordFlowAuditResponse.sign_name}}】的
                        <button class="btn-link  pl-0 pr-0"
                                (click)="to_lease(frozenPasswordFlowAuditResponse?.lease_id)">
                          【{{frozenPasswordFlowAuditResponse?.contract_num}}】
                        </button>合同
                        <span class="text-danger">逾期未退</span>， 申请将该合同下未付房费账单的房间进行冻结门锁密码
                      </div>
                      <div *ngIf="frozenPasswordFlowAuditResponse.frozen_type=='SINGLE_FROZEN'">
                        申请将【{{frozenPasswordFlowAuditResponse.sign_name}}】的
                        <button class="btn-link pl-0 pr-0"
                                (click)="to_lease(frozenPasswordFlowAuditResponse?.lease_id)">
                          【{{frozenPasswordFlowAuditResponse?.contract_num}}】
                        </button>合同下
                        <span class="text-danger">【{{frozenPasswordFlowAuditResponse.room_name}}】</span>房间进行冻结门锁密码
                      </div>
                    </div>
                    <div class="col-12 mt-2" *ngIf="frozenPasswordFlowAuditResponse?.attachment_list">
                      <label>附件：</label>
                      <div>
                        <app-new-file-upload [type]="2"
                                             [accept]="'IMG'"
                                             [imgList]="frozenPasswordFlowAuditResponse?.attachment_list"
                                             [showDelBtn]="false"
                                             [showUploadBtn]="false"
                        >
                        </app-new-file-upload>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="approLockPasswordRes" class="row" style="padding-left: 5px;">
                    <div class="col-12 col-md-4 col-lg-4 mb10">审批类型：密码申请审批</div>
                    <div class="col-12 col-md-4 col-lg-4 mb10">申请内容：{{approLockPasswordRes?.password_type}}</div>
                    <div class="col-12 col-md-4 col-lg-4 mb10">提交时间：{{approLockPasswordRes?.submit_time | date:"yyyy-MM-dd HH:mm:ss"}}</div>
                    <div class="col-12 col-md-4 col-lg-4 mb10">提交人：{{approLockPasswordRes?.create_user_name}}</div>
                    <div *ngIf="approLockPasswordRes?.room_count === 1" class="col-12 col-md-4 col-lg-4 mb10">申请房间：{{approLockPasswordRes?.room_name}}</div>
                    <div *ngIf="approLockPasswordRes?.room_count > 1" class="col-12 col-md-4 col-lg-4 mb10">申请房间数：{{approLockPasswordRes?.room_count}}间</div>
                    <div class="col-12 col-md-4 col-lg-4 mb10">密码有效时间：{{approLockPasswordRes?.time_text}}</div>
                  </div>

                  <div *ngIf="approRenterCheckoutDeposit" class="row" style="padding-left: 5px;">
                    <div class="col-12 col-md-4 col-lg-4 mb10">审批类型：住客退押审批</div>
                    <div class="col-12 col-md-4 col-lg-4 mb10">提交人：{{approRenterCheckoutDeposit?.commit_user}}</div>
                    <div class="col-12 col-md-4 col-lg-4 mb10">提交时间：{{approRenterCheckoutDeposit?.commit_time | date:"yyyy-MM-dd HH:mm:ss"}}</div>
                    <div class="col-12 col-md-4 col-lg-4 mb10">住客姓名：{{approRenterCheckoutDeposit?.renter_name}}</div>
                    <div class="col-12 col-md-4 col-lg-4 mb10">房间号：{{approRenterCheckoutDeposit?.room_name}}</div>
                    <div class="col-12 col-md-4 col-lg-4 mb10">退宿日期：{{approRenterCheckoutDeposit?.checkout_time | date:"yyyy-MM-dd"}}</div>
                    <div style="cursor:pointer"  (click)="goRenterCheckout(approRenterCheckoutDeposit?.renter_checkout_id)" class="col-12 col-md-4 col-lg-4 mb10">退宿单号：<span style="color: #1a7fb0">{{approRenterCheckoutDeposit?.renter_checkout_number}}</span></div>
                    <div class="col-12 col-md-4 col-lg-4 mb10">退款方式：{{approRenterCheckoutDeposit?.checkout_bill_pay_type_enum}}</div>
                    <div class="col-12 col-md-4 col-lg-4 mb10">退款金额：{{approRenterCheckoutDeposit?.total}}</div>
                  </div>

                  <div *ngIf="approProjectBasePrice"  style="padding-left: 5px;">
                    <div>
                          <div>{{approProjectBasePrice?.project_name}}</div>
                          <table class="table table-bordered">
                            <thead class="thead-light">
                            <tr>
                              <td >调整项</td>
                              <td >调整前</td>
                              <td>调整后</td>
                            </tr>
                            </thead>
                            <tbody>

                            <tr *ngIf="approProjectBasePrice?.electric_price || approProjectBasePrice?.old_electric_price">
                              <td>电费定价</td>
                              <td *ngIf="approProjectBasePrice?.old_electric_price">
                                <p *ngIf="approProjectBasePrice?.old_electric_price?.unitive">统一定价：{{approProjectBasePrice?.old_electric_price?.price}}元/度</p>
                                <p *ngIf="!approProjectBasePrice?.old_electric_price?.unitive">
                                  峰谷平定价：
                                </p>
                                <p *ngIf="approProjectBasePrice?.old_electric_price?.feng_price">峰{{approProjectBasePrice?.old_electric_price?.feng_price.price}}元/度</p>
                                <p *ngIf="approProjectBasePrice?.old_electric_price?.ping_price">平{{approProjectBasePrice?.old_electric_price?.ping_price.price}}元/度</p>
                                <p *ngIf="approProjectBasePrice?.old_electric_price?.gu_price">谷{{approProjectBasePrice?.old_electric_price?.gu_price.price}}元/度</p>
                                <p *ngIf="approProjectBasePrice?.old_electric_price?.feng_price">峰时段 <span *ngFor="let time of approProjectBasePrice?.old_electric_price?.feng_price.time_list">{{time?.start_time}} - {{time?.end_time}}&nbsp;</span></p>
                                <p *ngIf="approProjectBasePrice?.old_electric_price?.ping_price">平时段 <span *ngFor="let time of approProjectBasePrice?.old_electric_price?.ping_price.time_list">{{time?.start_time}} - {{time?.end_time}}&nbsp;</span></p>
                                <p *ngIf="approProjectBasePrice?.old_electric_price?.gu_price">谷时段 <span *ngFor="let time of approProjectBasePrice?.old_electric_price?.gu_price.time_list">{{time?.start_time}} - {{time?.end_time}}&nbsp;</span></p>
                              </td>
                              <td *ngIf="!approProjectBasePrice?.old_electric_price">未定价</td>
                              <td *ngIf="approProjectBasePrice?.electric_price">
                                <p *ngIf="approProjectBasePrice?.electric_price?.unitive">统一定价： {{approProjectBasePrice?.electric_price?.price}}元/度</p>
                                <p *ngIf="!approProjectBasePrice?.electric_price?.unitive">
                                  峰谷平定价
                                </p>
                                <p *ngIf="approProjectBasePrice?.electric_price?.feng_price">峰{{approProjectBasePrice?.electric_price?.feng_price.price}}元/度</p>
                                <p *ngIf="approProjectBasePrice?.electric_price?.ping_price">平{{approProjectBasePrice?.electric_price?.ping_price.price}}元/度</p>
                                <p *ngIf="approProjectBasePrice?.electric_price?.gu_price">谷{{approProjectBasePrice?.electric_price?.gu_price.price}}元/度</p>
                                <p *ngIf="approProjectBasePrice?.electric_price?.feng_price">峰时段 <span *ngFor="let time of approProjectBasePrice?.electric_price?.feng_price?.time_list">{{time?.start_time}} - {{time?.end_time}}&nbsp;</span></p>
                                <p *ngIf="approProjectBasePrice?.electric_price?.ping_price">平时段 <span *ngFor="let time of approProjectBasePrice?.electric_price?.ping_price?.time_list">{{time?.start_time}} - {{time?.end_time}}&nbsp;</span></p>
                                <p *ngIf="approProjectBasePrice?.electric_price?.gu_price">谷时段 <span *ngFor="let time of approProjectBasePrice?.electric_price?.gu_price?.time_list">{{time?.start_time}} - {{time?.end_time}}&nbsp;</span></p>
                              </td>
                              <td *ngIf="approProjectBasePrice?.old_electric_price && !approProjectBasePrice?.electric_price">
                                删除该费用项（审批通过后立即生效）
                              </td>
                            </tr>
                            <tr *ngIf="approProjectBasePrice?.water_price || approProjectBasePrice?.old_water_price">
                              <td>水费定价</td>
                              <td *ngIf="approProjectBasePrice?.old_water_price"><p>{{approProjectBasePrice?.old_water_price?.price}}元/吨</p></td>
                              <td *ngIf="!approProjectBasePrice?.old_water_price">未定价</td>
                              <td *ngIf="approProjectBasePrice?.water_price"><p>{{approProjectBasePrice?.water_price?.price}}元/吨</p></td>
                              <td *ngIf="approProjectBasePrice?.old_water_price && !approProjectBasePrice?.water_price">
                                删除该费用项（审批通过后立即生效）
                              </td>
                            </tr>
                            <tr *ngIf="approProjectBasePrice?.hot_water_price || approProjectBasePrice?.old_hot_water_price">
                              <td>热水费定价</td>
                              <td *ngIf="approProjectBasePrice?.old_hot_water_price"><p>{{approProjectBasePrice?.old_hot_water_price?.price}}元/吨</p></td>
                              <td *ngIf="!approProjectBasePrice?.old_hot_water_price">未定价</td>
                              <td *ngIf="approProjectBasePrice?.hot_water_price"><p>{{approProjectBasePrice?.hot_water_price?.price}}元/吨</p></td>
                              <td *ngIf="approProjectBasePrice?.old_hot_water_price && !approProjectBasePrice?.hot_water_price">
                                删除该费用项（审批通过后立即生效）
                              </td>
                            </tr>
                            <tr *ngIf="approProjectBasePrice?.room_heating_price || approProjectBasePrice?.old_room_heating_price">
                              <td>房间暖气费定价</td>
                              <td *ngIf="approProjectBasePrice?.old_room_heating_price">
                                <p>{{approProjectBasePrice?.old_room_heating_price?.price}}元/平米/月</p>
                                <p>收费期间：{{approProjectBasePrice?.old_room_heating_price?.start_time}}至{{approProjectBasePrice?.old_room_heating_price?.end_time}}</p>
                              </td>
                              <td *ngIf="!approProjectBasePrice?.old_room_heating_price">未定价</td>
                              <td *ngIf="approProjectBasePrice?.room_heating_price">
                                <p>{{approProjectBasePrice?.room_heating_price?.price}}元/平米/月</p>
                                <p>收费期间：{{approProjectBasePrice?.room_heating_price?.start_time}}至{{approProjectBasePrice?.room_heating_price?.end_time}}</p>
                              </td>
                              <td *ngIf="approProjectBasePrice?.old_room_heating_price && !approProjectBasePrice?.room_heating_price">
                                删除该费用项（审批通过后立即生效）
                              </td>
                            </tr>
                            <tr *ngIf="approProjectBasePrice?.public_heating_price || approProjectBasePrice?.old_public_heating_price">
                              <td>公区暖气费定价</td>
                              <td *ngIf="approProjectBasePrice?.old_public_heating_price">
                                <p>{{approProjectBasePrice?.old_public_heating_price?.price}}元/平米/月</p>
                                <p>收费期间：{{approProjectBasePrice?.old_public_heating_price?.start_time}}至{{approProjectBasePrice?.old_public_heating_price?.end_time}}</p>
                              </td>
                              <td *ngIf="!approProjectBasePrice?.old_public_heating_price">未定价</td>
                              <td *ngIf="approProjectBasePrice?.public_heating_price">
                                <p>{{approProjectBasePrice?.public_heating_price?.price}}元/平米/月</p>
                                <p>收费期间：{{approProjectBasePrice?.public_heating_price?.start_time}}至{{approProjectBasePrice?.public_heating_price?.end_time}}</p>
                              </td>
                              <td *ngIf="approProjectBasePrice?.old_public_heating_price && !approProjectBasePrice?.public_heating_price">
                                删除该费用项（审批通过后立即生效）
                              </td>
                            </tr>
                            <tr *ngIf="approProjectBasePrice?.change_room_price || approProjectBasePrice?.old_change_room_price">
                              <td>换房费定价</td>
                              <td *ngIf="approProjectBasePrice?.old_change_room_price"><p>{{approProjectBasePrice?.old_change_room_price?.price}}元</p></td>
                              <td *ngIf="!approProjectBasePrice?.old_change_room_price">未定价</td>
                              <td *ngIf="approProjectBasePrice?.change_room_price"><p>{{approProjectBasePrice?.change_room_price?.price}}元</p></td>
                              <td *ngIf="approProjectBasePrice?.old_change_room_price && !approProjectBasePrice?.change_room_price">
                                删除该费用项（审批通过后立即生效）
                              </td>
                            </tr>
                            <tr *ngIf="approProjectBasePrice?.sublet_price || approProjectBasePrice?.old_sublet_price">
                              <td>转租费定价</td>
                              <td *ngIf="approProjectBasePrice?.old_sublet_price"><p>{{approProjectBasePrice?.old_sublet_price?.price}}元</p></td>
                              <td *ngIf="!approProjectBasePrice?.old_sublet_price">未定价</td>
                              <td *ngIf="approProjectBasePrice?.sublet_price"><p>{{approProjectBasePrice?.sublet_price?.price}}元</p></td>
                              <td *ngIf="approProjectBasePrice?.old_sublet_price && !approProjectBasePrice?.sublet_price">
                                删除该费用项（审批通过后立即生效）
                              </td>
                            </tr>
                            <tr *ngIf="approProjectBasePrice?.public_energy_price || approProjectBasePrice?.old_public_energy_price">
                              <td>公共能耗费定价</td>
                              <td *ngIf="approProjectBasePrice?.old_public_energy_price"><p>{{approProjectBasePrice?.old_public_energy_price?.price}}元/天</p></td>
                              <td *ngIf="!approProjectBasePrice?.old_public_energy_price">未定价</td>
                              <td *ngIf="approProjectBasePrice?.public_energy_price"><p>{{approProjectBasePrice?.public_energy_price?.price}}元/天</p></td>
                              <td *ngIf="approProjectBasePrice?.old_public_energy_price && !approProjectBasePrice?.public_energy_price">
                                删除该费用项（审批通过后立即生效）
                              </td>
                            </tr>
                            </tbody>
                          </table>
                          <div class="red2">以上费用调整的生效时间为：{{approProjectBasePrice?.effective_time | date:"yyyy-MM-dd HH:mm"}}</div>
                    </div>
                  </div>

                  <div *ngIf="approLockPasswordRes?.unit_room_list">
                    <div *ngIf="approLockPasswordRes?.remark" style="padding-left: 5px;">
                      <p style="word-wrap: break-word">备注信息：{{approLockPasswordRes?.remark}}</p>
                    </div>
                    <table class="table table-bordered">
                      <tbody>
                      <tr>
                        <td>房间楼栋</td>
                        <td>房间号</td>
                      </tr>
                      <tr *ngFor="let info of approLockPasswordRes?.unit_room_list">
                        <td>{{info?.unit_name}}</td>
                        <td>
                      <span *ngFor="let rn of info?.room_names;let i=index">
                        {{i !== 0?"、":""}}
                        {{rn}}
                      </span>
                        </td>
                      </tr>

                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="is_lock_password" style="padding-left: 5px;">
                    <p style="word-wrap: break-word">审核结果：{{approLockPasswordRes?.status}}</p>
                    <p *ngIf="approLockPasswordRes?.refusal_remark" style="word-wrap: break-word">拒绝原因：{{approLockPasswordRes?.refusal_remark}}</p>
                    <button (click)="sendPassword.show();sendLockPassword(approLockPasswordRes?.appro_record_info_id)" *ngIf="!approLockPasswordRes?.send && approLockPasswordRes?.status==='已同意'"  class="btn btn-info" type="button" >
                      下发密码
                    </button>
                    <p *ngIf="approLockPasswordRes?.password">房间密码：{{approLockPasswordRes?.password}}</p>
                    <p *ngIf="approLockPasswordRes?.validity && approLockPasswordRes?.validity !== '密码已过期'">剩余有效时间：{{approLockPasswordRes?.validity}}</p>
                    <p *ngIf="approLockPasswordRes?.validity === '密码已过期'">（密码已过期）</p>
                  </div>
                </div>
              </div>
            </tab>
            <tab heading="审批记录信息">
              <app-approv-record-log-list [approvalResultResponse]="approvalResultResponse"></app-approv-record-log-list>
            </tab>
            <tab heading="审批流程图" (selectTab)="flowImgTabOnSelect(process_instance_id)">
              <app-approv-record-img></app-approv-record-img>
            </tab>
          </tabset>
        </div>


      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button" class="btn btn-primary " data-dismiss="modal"
                    (click)="showLeaseChangeAuditModal.hide()">关闭
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" bsModal #sendPassword="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>下发密码</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="sendPassword.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">

        <div class="row" style="height: 300px;">
          <div class="col-12 col-md-12">
            <p *ngIf="approLockPasswordRes?.room_count === 1">您正在为房间：<span style="color:#FF6600">{{approLockPasswordRes?.room_name}}</span>下发密码</p>
            <p *ngIf="approLockPasswordRes?.room_count > 1">您正在为 <span style="color:#FF6600">{{approLockPasswordRes?.room_count}}</span> <span *ngIf="!sendLockPasswordLoading">间房批量下发密码，{{approBatchPwdResponse?.success_count}} 间成功，
              {{approLockPasswordRes?.room_count - approBatchPwdResponse?.success_count}} 间失败。</span></p>
          </div>
          <div *ngIf="sendLockPasswordLoading" class="text-center col-12 col-md-12" ><san style="color:#FF6600;font-size: 20px;">生成中请稍后...</san></div>
          <div *ngIf="!sendLockPasswordLoading" class="col-12 col-md-12" style="margin-left: 200px;">
            <p>房间密码：<san style="color:#FF6600;font-size: 20px;margin-left: 30px;">{{approBatchPwdResponse?.code}}</san></p>
            <p>剩余有效时间：<san style="color:#FF6600;font-size: 20px;margin-left: 30px;">{{approBatchPwdResponse?.validity}}</san></p>
            <p>备注信息：<san style="margin-left: 30px;">{{approBatchPwdResponse?.remark}}</san></p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary " data-dismiss="modal"
                (click)="sendPassword.hide()">关闭
        </button>
      </div>

    </div>
  </div>
</div>
