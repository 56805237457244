import {Injectable} from '@angular/core';

import {RequestService} from '../request.service';
import {environment} from '../../../environments/environment';
import {
  AllServiceMonitorResponse, BusinessDataResponse, EnergyConsumptionAllResponse,
  EnergyConsumptionResponse,
  ErrorMonitorResponse,
  ServiceMonitorResponse, VisualDataThisMonthResponse, VisualDataTrendRequest, VisualDataTrendResponse
} from "../../model/monitor/monitor.model";

/**
 * @Description:  监控service
 * @Author: ky
 * @Date: 2019年5月21日19:59:44
 */
@Injectable()
export class MonitorService {

  // 服务监控
  private SERVICE_MONITOR = `${environment.apiHost}monitor/service`;
  // 服务监控(全部)
  private SERVICE_MONITOR_ALL = `${environment.apiHost}monitor/service/all`;
  // 能耗监控
  private ENERGY_MONITOR = `${environment.apiHost}monitor/energy/consumption`;
  // 能耗监控（全部）
  private ENERGY_MONITOR_ALL = `${environment.apiHost}monitor/energy/consumption/all`;
  // 故障监控
  private ERROR_MONITOR = `${environment.apiHost}monitor/errmonitor`;
  // 安全监控
  private SAFE_MONITOR = `${environment.apiHost}monitor/security/monitor`;
  // 运营数据监控
  private BUSINESS_MONITOR = `${environment.apiHost}monitor/business/data`;


  // 可视化数据仓 - 当月数据
  private GET_BUSINESS_BRAIN = `${environment.apiHost}businessbrain/current/month/data`;
  // 可视化数据仓 - 环比数据
  private GET_BUSINESS_BRAIN_DATA_TREND = `${environment.apiHost}businessbrain/data/trend`;

  // 人员安全数据监控
  private GET_WATCH_DATA = `${environment.apiHost}monitor/renter/data`;

  // 人员安全监控中心-通过项目ID查询视频流地址列表
  private GET_WATCH_CORE_FACEURL = `${environment.apiHost}faceCamera/findCameraInfoByProject`;

  // 人员安全监控中心-陌生人报警列表,未出/未归人员
  private GET_WATCH_CORE_WARNING = `${environment.apiHost}cameraWarning/findCameraWaringByStatus`;

  // 人员安全监控中心-进出实时统计
  private GET_WATCH_CORE_REALTIME = `${environment.apiHost}cameraPropleFlow/groupPeopleFlowoByday`;

  // 人员安全监控中心-陌生人确认离开
  private GET_WATCH_CORE_CONFIRMLEAVE = `${environment.apiHost}cameraWarning/closeUnkonwWarn`;

  // 人员安全监控中心-通过项目ID查询视频流地址列表
  private GET_WATCH_CORE_DORMITORY = `${environment.apiHost}workbench/data/countInhome`;

  // 未登记入住/陌生可疑人员列表
  private GET_WAINGTYPE_PEOPLE = `${environment.apiHost}cameraWarning/findUnkownWaringsByType`;

  // 工作台_人员安全报警记录列表
  private GET_PERSON_SAFE_ALARMLIST = `${environment.apiHost}person/safe/alarmList`;

  // H5_人员安全报警详情
  private GET_ALARM_DETAIL = `${environment.apiHost}person/safe/alarmDetail`;
  // 智能预测
  private GET_FORECAST_DATA = `${environment.apiHost}monitor/business/intelligentData`;

  constructor(private requestService: RequestService) {
  }

  /**
   * 运营数据监控
   */
  getBusinessMonitorData(reqData?: any): Promise<BusinessDataResponse> {
    return this.requestService.authLongGet(this.BUSINESS_MONITOR, reqData, 5 * 60 * 1000);
  }

  /**
   * 服务监控
   */
  getServiceMonitorData(reqData?: any): Promise<ServiceMonitorResponse> {
    return this.requestService.authGet(this.SERVICE_MONITOR, reqData);
  }

  getAllServiceMonitorData(reqData?: any): Promise<AllServiceMonitorResponse> {
    return this.requestService.authGet(this.SERVICE_MONITOR_ALL, reqData);
  }

  /**
   * 能耗监控
   */
  getEnergyMonitorData(reqData?: any): Promise<EnergyConsumptionResponse> {
    return this.requestService.authGet(this.ENERGY_MONITOR, reqData);
  }

  getAllEnergyMonitorData(reqData?: any): Promise<EnergyConsumptionAllResponse> {
    return this.requestService.authLongGet(this.ENERGY_MONITOR_ALL, reqData, 10 * 60 * 1000);
  }

  /**
   * 故障监控
   */
  getErrorMonitorData(reqData?: any): Promise<ErrorMonitorResponse> {
    return this.requestService.authGet(this.ERROR_MONITOR, reqData);
  }

  /**
   * 安全监控
   */
  getSafeMonitorData(reqData?: any): Promise<ErrorMonitorResponse> {
    return this.requestService.authGet(this.SAFE_MONITOR, reqData);
  }



  /**
   * 可视化数据仓 - 当月数据
   */
  getVDData(): Promise<VisualDataThisMonthResponse> {
    return this.requestService.authGet(this.GET_BUSINESS_BRAIN);
  }

  /**
   * 可视化数据仓 - 查询环比趋势
   */
  getVDDataTrend(req: VisualDataTrendRequest): Promise<VisualDataTrendResponse> {
    return this.requestService.authGet(this.GET_BUSINESS_BRAIN_DATA_TREND, req);
  }

  /**
   * 人员安全监控数据 - 查询
   */
  getWatchData() {
    return this.requestService.authGet(this.GET_WATCH_DATA);
  }


  /**
   * 人员安全监控数据 - 通过项目projectId查询视频流地址列表
   */
  getWatchCoreFaceUrl(req) {
    return this.requestService.authGet(this.GET_WATCH_CORE_FACEURL, req);
  }

  /**
   * 人员安全监控数据 - 陌生人报警列表,未出/未归人员
   */
  getWatchCoreWarning(req) {
    return this.requestService.authLongGet(this.GET_WATCH_CORE_WARNING, req, 10 * 60 * 1000);
  }

  /**
   * 人员安全监控数据 - 进出实时统计
   */
  getWatchCoreRealTime(req) {
    return this.requestService.authGet(this.GET_WATCH_CORE_REALTIME, req);
  }

  /**
   * 人员安全监控数据 - 陌生人确认离开
   */
  getWatchCoreconfirmLeave(req) {
    return this.requestService.authGet(this.GET_WATCH_CORE_CONFIRMLEAVE, req);
  }

  /**
   * 人员安全监控数据 - 在宿统计
   */
  getWatchCoreFaceDormitory(req) {
    return this.requestService.authGet(this.GET_WATCH_CORE_DORMITORY, req);
  }

  /**
   * 未登记入住/陌生可疑人员列表
   */
  getWatchWaingPeople(req) {
    return this.requestService.authGet(this.GET_WAINGTYPE_PEOPLE, req);
  }

  getPersonSafeAlarmList(req:any){
    return this.requestService.authGet(this.GET_PERSON_SAFE_ALARMLIST,req);
  }

  getAlarmDetail(req:any){
    return this.requestService.authGet(this.GET_ALARM_DETAIL,req);
  }

  getForecastData(){
    return this.requestService.authGet(this.GET_FORECAST_DATA);
  }
}
