import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RequestService } from '../request.service';
import { CarportCheckoutContractExtResp, CarportLeaseCheckoutConfirmRequest, CarportLeaseCheckoutConfirmResponse, CarportLeaseCheckoutListRequest, CarportLeaseCheckoutListResponse, CarportLeaseCheckoutViewRequest, CarportLeaseCheckoutViewResponse, CarportLeaseCommonReq, CarportLeaseCommonResp, CarportLeaseDetailReq, CarportLeaseDetailResp, CarportLeaseLogListReq, CarportLeaseLogListResp, CreateCarportResp, GetContractUrlResp, IsCarportAvaliableReq, IsCarportAvaliableResp } from '../../model/carport/carport.modal';
import { getTimeFromPlugin } from '../../common/date.util';

@Injectable()
export class CarportLeaseService {

  // 创建/修改租约
  private CARPORT_CREATE_LEASE = `${environment.apiHost}carport/lease/create`;
  // 获取租约详情
  private CARPORT_LEASE_DETAIL = `${environment.apiHost}carport/lease/detail`;
  // 取消租约
  private CARPORT_LEASE_CANCEL = `${environment.apiHost}carport/lease/cancel`;
  // 合同生效
  private CARPORT_LEASE_CONTRACT_CONFIRM = `${environment.apiHost}carport/lease/contract/confirm`;
  // 获取合同日志列表
  private CARPORT_LEASE_LOG_LIST = `${environment.apiHost}carport/lease/log/list`;
  // 获取合同url
  private GET_CONTRACT_URL = `${environment.apiHost}carport/lease/contract/url`;
  // 退租弹框
  private CARPORT_LEASE_CHECKOUT_SELECT = `${environment.apiHost}carport/checkout/carport`;
  // 退租弹框
  private CARPORT_LEASE_CHECKOUT_VIEW = `${environment.apiHost}carport/checkout/view`;
  // 退租弹框
  private CARPORT_LEASE_CHECKOUT_CONFIRM = `${environment.apiHost}carport/checkout/confirm`;
  // 车库是否可用
  private IS_CARPORT_AVALIABLE = `${environment.apiHost}carport/manage/isavaliable`;
  // 生成pdf
  private GENERATE_CHECKOUT_PDF = `${environment.apiHost}carport/checkout/generate/pdf`;
  // 合同额外信息
  private CONTRACT_EXT = `${environment.apiHost}carport/checkout/contract/ext`;

  constructor(private requestService: RequestService) {
  }

  /**
   * @Description: 创建租约
   * @Author: 陆炳彬
   * @Date: 2018/12/6 下午5:55
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/12/6 下午5:55
   */
  createCarportLease(result: any, type: string): Promise<CreateCarportResp> {
    result.lease_info.start_time = getTimeFromPlugin(result.lease_info.start_time_obj.formatted);
    result.lease_info.end_time = getTimeFromPlugin(result.lease_info.end_time_obj.formatted);
    if (result.lease_info.is_free) {
      result.lease_info.free_start_time = getTimeFromPlugin(result.lease_info.free_start_time_obj.formatted);
      result.lease_info.free_end_time = getTimeFromPlugin(result.lease_info.free_end_time_obj.formatted);
    }

    result.operate_type = type.toUpperCase();

    result.lease_subject_info.name = result.lease_subject_info.person_info.name;
    result.lease_subject_info.tel = result.lease_subject_info.person_info.tel;
    result.lease_subject_info.cret_type = result.lease_subject_info.person_info.cret_type;
    result.lease_subject_info.cret_num = result.lease_subject_info.person_info.cret_num;
    result.lease_subject_info.zip = result.lease_subject_info.person_info.zip;
    result.lease_subject_info.address = result.lease_subject_info.person_info.address;

    return this.requestService.authPost(this.CARPORT_CREATE_LEASE, result);
  }

  /**
   * @Description: 获取租约详情
   * @Author: 陆炳彬
   * @Date: 2018/12/7 下午3:57
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/12/7 下午3:57
   */
  getCarportLeaseDetail(params: CarportLeaseDetailReq): Promise<CarportLeaseDetailResp> {
    return this.requestService.authGet(this.CARPORT_LEASE_DETAIL, params);
  }

  /**
   * @Description: 取消租约
   * @Author: 陆炳彬
   * @Date: 2018/12/8 下午2:23
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/12/8 下午2:23
   */
  cancelLease(params: CarportLeaseCommonReq): Promise<CarportLeaseCommonResp> {
    return this.requestService.authGet(this.CARPORT_LEASE_CANCEL, params);
  }

  /**
   * @Description: 租约生效
   * @Author: 陆炳彬
   * @Date: 2018/12/8 下午3:35
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/12/8 下午3:35
   */
  contractConfirm(params: CarportLeaseCommonReq): Promise<CarportLeaseCommonResp> {
    return this.requestService.authPost(this.CARPORT_LEASE_CONTRACT_CONFIRM, params);
  }

  /**
   * @Description: 获取日志列表
   * @Author: 陆炳彬
   * @Date: 2018/12/9 下午1:35
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/12/9 下午1:35
   */
  getCarportLeaseLogList(params: CarportLeaseLogListReq): Promise<CarportLeaseLogListResp> {
    return this.requestService.authGet(this.CARPORT_LEASE_LOG_LIST, params);
  }

  /**
   * @Description: 获取合同入了地址
   * @Author: 陆炳彬
   * @Date: 2018/12/10 下午1:47
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/12/10 下午1:47
   */
  getContractUrl(params: any): Promise<GetContractUrlResp> {
    return this.requestService.authGet(this.GET_CONTRACT_URL, params);
  }

  /**
   * @description: 退房车位视图
   * @Author: kaungye
   * @Date: 2018-12-10
   * @Last Modified by: kuangye
   * @Last Modified time: 2018-12-10
   */
  checkoutCarport(params: CarportLeaseCheckoutListRequest): Promise<CarportLeaseCheckoutListResponse> {
    return this.requestService.authPost(this.CARPORT_LEASE_CHECKOUT_SELECT, params);
  }

  /**
   * @description: 退房视图
   * @Author: kaungye
   * @Date: 2018-12-10
   * @Last Modified by: kuangye
   * @Last Modified time: 2018-12-10
   */
  checkoutView(params: CarportLeaseCheckoutViewRequest): Promise<CarportLeaseCheckoutViewResponse> {
    return this.requestService.authPost(this.CARPORT_LEASE_CHECKOUT_VIEW, params);
  }

  /**
   * @description: 退房确认
   * @Author: kaungye
   * @Date: 2018-12-10
   * @Last Modified by: kuangye
   * @Last Modified time: 2018-12-10
   */
  checkoutConfirm(params: CarportLeaseCheckoutConfirmRequest): Promise<CarportLeaseCheckoutConfirmResponse> {
    return this.requestService.authPost(this.CARPORT_LEASE_CHECKOUT_CONFIRM, params);
  }

  /**
   * @Description: 查看车库是否可用
   * @Author: 陆炳彬
   * @Date: 2018/12/12 上午11:15
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/12/12 上午11:15
   */
  isCarportAvaliable(params: IsCarportAvaliableReq): Promise<IsCarportAvaliableResp> {
    return this.requestService.authGet(this.IS_CARPORT_AVALIABLE, params);
  }

  /**
   * @Description:
   * @Author: 陆炳彬
   * @Date: 2019/1/21 下午5:51
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2019/1/21 下午5:51
   */
  generateCheckoutPDF(params: CarportLeaseCheckoutConfirmRequest): Promise<any> {
    return this.requestService.authPost(this.GENERATE_CHECKOUT_PDF, params);
  }

  /**
   * @Description: 获取合同额外信息
   * @Author: 陆炳彬
   * @Date: 2019/1/22 下午1:33
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2019/1/22 下午1:33
   */
  getContractExtInfo(params: CarportLeaseCommonReq): Promise<CarportCheckoutContractExtResp> {
    return this.requestService.authGet(this.CONTRACT_EXT, params);
  }
}
