<!-- {{setInventoryModal(inventoryModal)}} -->
<!-- 签署物品交接单 -->
<div class="modal fade" #inventoryModal="bs-modal" bsModal tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>签署物品交接单</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-left mb10">房间号：{{inventorViewResponse?.room_info?.room_name}}</p>
        <div class="row">
          <div class="col-12 col-md-6 mb10">
            <p class="w90px hei32">水表读数：</p>
            <ng-container *ngIf="inventorViewResponse?.water_dev || inventorViewResponse?.need_water == 'YES'">
              <input [(ngModel)]="inventorViewResponse.water_reading" type="text" class="w100px mr10">
            </ng-container>
            <span *ngIf="inventorViewResponse.water_dev && inventorViewResponse.water_reading_time != null"
                  class="font12 hei32 text-muted d-inline-block">{{inventorViewResponse.water_reading_time | date:'yyyy-MM-dd HH:mm:ss'}} 自动读取</span>
            <span *ngIf="inventorViewResponse.water_dev && inventorViewResponse.water_reading_time == null"
                  class="font12 hei32 text-muted d-inline-block">未抄表</span>
            <span *ngIf="!inventorViewResponse.water_dev && inventorViewResponse?.need_water == 'YES'"
                  class="font12 hei32 text-muted d-inline-block">尚未安装设备</span>
            <span style="color: #e63e00 !important;"
                  *ngIf="!inventorViewResponse.water_dev && inventorViewResponse?.need_water != 'YES'"
                  class="font12 hei32 text-muted d-inline-block">此房型设置了不需要抄表收水费</span>
          </div>
          <div class="col-12 col-md-6 mb10">
            <p class="w90px hei32">电表读数：</p>
            <ng-container
              *ngIf="inventorViewResponse?.electricity_dev || inventorViewResponse?.need_electricity == 'YES'">
              <input type="text" [(ngModel)]="inventorViewResponse.electricity_reading" class="w100px mr10">
            </ng-container>
            <span *ngIf="inventorViewResponse.electricity_dev && inventorViewResponse.electricity_reading_time != null"
                  class="font12 hei32 text-muted d-inline-block">{{inventorViewResponse.electricity_reading_time | date:'yyyy-MM-dd HH:mm:ss'}} 自动读取</span>
            <span *ngIf="inventorViewResponse.electricity_dev && inventorViewResponse.electricity_reading_time == null"
                  class="font12 hei32 text-muted d-inline-block">未抄表</span>
            <span *ngIf="!inventorViewResponse.electricity_dev && inventorViewResponse?.need_electricity == 'YES'"
                  class="font12 hei32 text-muted d-inline-block">尚未安装设备</span>
            <span style="color: #e63e00 !important;"
                  *ngIf="!inventorViewResponse.electricity_dev && inventorViewResponse?.need_electricity != 'YES'"
                  class="font12 hei32 text-muted d-inline-block">此房型设置了不需要抄表收电费</span>
          </div>
        </div>
        <p class="mb10">房间物品清单</p>
        <div class="table-box mb40">
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th>名称</th>
              <th>品牌</th>
              <th>型号</th>
              <th>价格</th>
              <th>确认包含</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let info of inventorViewResponse.asset_list">
              <td>{{info?.batch_model}}</td>
              <td>{{info?.batch_brand}}</td>
              <td>{{info?.batch_type}}</td>
              <td>￥{{info?.asset_price}}</td>
              <td><input (click)="checkboxIn(info.id,$event)" type="checkbox"></td>
            </tr>
            </tbody>
          </table>
          <p style="color: #ff3b3b">{{errorText}}</p>
        </div>
        <div class="text-center mb20">
          <button type="button" (click)="inventorAffirm(success)" class="btn btn-primary mr20">确认
          </button>
          <button type="button" class="btn btn-secondary" (click)="hideModal()">取消</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 保存成功 -->
<div class="modal fade" bsModal #success="bs-modal" tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-static-name" class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>保存成功</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="success.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h3 class="text-center black mb20">物品交接单已保存！您可以进行打印或其他操作</h3>
        <p class="text-center mb40 text-muted font12">今后您也可以在合同中“查看房间详细”中找到此单据再次打印</p>
        <div class="text-center mb20">
          <button type="button" (click)="checkin();success.hide();" class="btn btn-primary mr10">办理入住</button>
          <button (click)="printPage()" type="button" class="btn btn-primary mr10">
            <i class="fa fa-print" aria-hidden="true"></i>打印
          </button>
          <button type="button" (click)="success.hide()" class="btn btn-secondary">关闭</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<app-print-preview [src]="pintUrl"></app-print-preview>-->
