import {Injectable} from "@angular/core";
import {RequestService} from "../../request.service";
import {AuthService} from "../../common/auth.service";
import {environment} from "../../../../environments/environment";
import {
  CatPurchaseInfoResponse,
  CourierCompanyInfoListResponse,
  OrderDetailResponse,
  OrderManagerListResponse,
  OrderManagerRequest, ProductOrderListRequest, ProductOrderListResponse
} from "../../../model/ebusiness/ebusiness.model";

@Injectable()
export class EOrderService {

  // 获取优惠类型
  private GET_ORDER_LIST = `${environment.apiHost}eb/orderManager/list`;

  // 获取优惠类型
  private GET_ORDER_DETAIL = `${environment.apiHost}eb/orderManager/orderDetail`;

  // 获取采购信息
  private GET_ORDER_PURCHASE = `${environment.apiHost}eb/orderManager/catPurchaseInfo`;

  // 去采购
  private POST_CONFIRM_PURCHASE = `${environment.apiHost}eb/orderManager/confirmPurchase`;

  // 下载订单列表
  private DOWN_ORDER_LIST = `${environment.apiHost}eb/orderManager/exportList`;

  // 下载预定订单列表
  private DOWN_RESERVE_ORDER_LIST = `${environment.apiHost}eb/orderManager/exportReserveList`;

  // 编辑售后
  private POST_EDITOR_AFTER_SALES = `${environment.apiHost}eb/orderManager/editorAfterSales`;

  // 获取快递公司列表
  private COURIRE_LIST = `${environment.apiHost}eb/common/courier/list`;

  // 修改物流/去发货
  private MODIFY_LOGISTICS = `${environment.apiHost}eb/orderManager/modifyLogistics`;

  // 获得商品详情
  private GET_PRODUCT_ORDER_LIST = `${environment.apiHost}eb/orderManager/productOrders`;

  constructor(
    private auth: AuthService,
    private requestService: RequestService
  ) {

  }

  /**
   * 订单列表
   */
  getOrderList(req?: OrderManagerRequest): Promise<OrderManagerListResponse> {
    return this.requestService.authGet(this.GET_ORDER_LIST, req);
  }


  /**
   * 订单详情
   */
  getOrderDetail(orderId?: string): Promise<OrderDetailResponse> {
    return this.requestService.authGet(this.GET_ORDER_DETAIL, {masterOrderId: orderId});
  }

  /**
   * 采购信息
   */
  getCatPurchaseInfo(orderId?: string): Promise<CatPurchaseInfoResponse> {
    return this.requestService.authGet(this.GET_ORDER_PURCHASE, {masterOrderId: orderId});
  }

  /**
   * 去采购
   */
  postConfirmPurchase(requestData?: any): Promise<any> {
    return this.requestService.commonHttp2(this.POST_CONFIRM_PURCHASE, 'post', requestData, true, null, false);
  }

  /**
   * 编辑售后
   */
  postEditorAfterSales(requestData?: any): Promise<any> {
    return this.requestService.commonHttp2(this.POST_EDITOR_AFTER_SALES, 'post', requestData, true, null, false);
  }

  /**
   * 获取快递公司列表
   */
  getCurierList(): Promise<CourierCompanyInfoListResponse> {
    return this.requestService.authGet(this.COURIRE_LIST);
  }

  /**
   * 下载订单列表
   */
  downOrderList(req?: OrderManagerRequest, orderType?: string): string {
    let downloadurl = this.DOWN_ORDER_LIST + "?Authorization=" + this.auth.getAuthToken();
    if (orderType == 'RESERVE') {
      downloadurl = this.DOWN_RESERVE_ORDER_LIST + "?Authorization=" + this.auth.getAuthToken();
    }

    if (req.keyword){
      downloadurl += "&keyword=" + req.keyword;
    }

    if (req.startDate){
      downloadurl += "&startDate=" + req.startDate;
    }
    if (req.endDate){
      downloadurl += "&endDate=" + req.endDate;
    }
    if (req.orderType){
      downloadurl += "&orderType=" + req.orderType;
    }
    if (req.startPickUpTime){
      downloadurl += "&startPickUpTime=" + req.startPickUpTime;
    }
    if (req.endPickUpTime){
      downloadurl += "&endPickUpTime=" + req.endPickUpTime;
    }
    if (req.orderStatus){
      downloadurl += "&orderStatus=" + req.orderStatus;
    }
    if (req.orderExchangeStatus){
      downloadurl += "&orderExchangeStatus=" + req.orderExchangeStatus;
    }
    if (req.sortType){
      downloadurl += "&sortType=" + req.sortType + "&sortMethod=" +req.sortMethod;
    }else{
      downloadurl += "&sortType=0";
    }
    return downloadurl;
  }

  //修改物流/去发货
  updateLogisitcs(param ?: any) {
    return this.requestService.commonHttp2(this.MODIFY_LOGISTICS, 'post', param, true, null, false);
  }

  getProductOrdersList(req:ProductOrderListRequest): Promise<ProductOrderListResponse>{
    return this.requestService.authGet(this.GET_PRODUCT_ORDER_LIST, req);
  }
}
