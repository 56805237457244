<div class="setbox-top" *ngIf="resp">
  <div class="bg-primary bg-green"><i class="fa fa-info-circle"></i>退宿单号:{{resp?.renter_checkout_number}}</div>
  <div class="setbox">

    <div class="col-12">
      <h2 class="text-center">退宿费用结算单</h2>
    </div>
    <hr>

    <div class="col-12">
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">退宿单号：{{resp?.renter_checkout_number}}</div>
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">姓名：{{renter?.name}}</div>
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">证件号码：{{renter?.cret_num_conceal}}</div>
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">手机号码：{{renter?.tel_conceal}}</div>
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">房间号：{{renter?.room_name}}</div>
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">入住日期：{{renter?.checkin_time|date:'yyyy.MM.dd'}}</div>
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2" *ngIf="renter?.free_start_time">免租期:
          {{renter?.free_start_time|date:'yyyy.MM.dd'}} ~
          <ng-container *ngIf="renter?.free_end_time">
            {{renter?.free_end_time|date:'yyyy.MM.dd'}}
          </ng-container>
          <ng-container *ngIf="!renter?.free_end_time">
            --
          </ng-container>
        </div>
        <div class="col-12 col-md-4 col-lg-3 col-2xl-2 mb-2">
          退宿日期：{{renter.checkout_time|date:'yyyy.MM.dd'}}
        </div>
      </div>
    </div>


    <div class="box-table">
      <h5 class="mt-1 font-weight-bold">费用结算汇总：</h5>
      <table class="table table-bordered">

        <ng-container *ngIf="resp?.check_out_room_info_dto?.receivable_total">
          <thead class="thead-light">
          <tr>
            <th class="w-25" scope="col">应收费用</th>
            <th class="w-50" scope="col">费用周期</th>
            <th class="w-25" scope="col">应收金额（元）</th>
          </tr>
          </thead>
          <tbody *ngFor="let item of resp?.check_out_room_info_dto?.receivable_items;let j = index">
          <tr>
            <td>{{item.object_code}}</td>
            <td>{{item.object_name}}</td>
            <td>{{item.object_value}}</td>
          </tr>
          </tbody>
          <tbody>
          <td class="bg-gray-200 font-weight-bold" colspan="2">应收总计（元）</td>
          <td class="text-right text-danger font-weight-bold" >{{resp?.check_out_room_info_dto?.receivable_total}}</td>
          </tbody>
        </ng-container>

        <ng-container *ngIf="resp?.check_out_room_info_dto?.retreated_total">
          <thead class="thead-light">
          <tr>
            <th class="w-25" scope="col">应退费用</th>
            <th class="w-25" scope="col">费用周期</th>
            <th class="w-25" scope="col">应退金额（元）</th>
          </tr>
          </thead>
          <tbody *ngFor="let item of resp?.check_out_room_info_dto?.retreated_items;let j = index">
          <tr>
            <td>{{item.object_code}}</td>
            <td>{{item.object_name}}</td>
            <td>{{item.object_value}}</td>
          </tr>
          </tbody>
          <tbody>
          <td class="bg-gray-200 font-weight-bold" colspan="2">应退总计（元）</td>
          <td class="text-right text-danger font-weight-bold" >{{resp?.check_out_room_info_dto?.retreated_total}}</td>
          </tbody>
        </ng-container>
      </table>
    </div>
    <div class="box-table">
      <h5 class="mt-1 font-weight-bold">费用处理：</h5>
      <table class="table table-bordered mb-0">
        <thead class="thead-light">
        <tr>
          <th class="w-25 text-left" scope="col">是否收支抵扣：是</th>
        </tr>
        </thead>
      </table>
      <table class="table table-bordered mb-0">
        <ng-container *ngIf="resp?.check_out_room_info_dto?.st_after_list">
          <thead class="thead-light">
          <tr>
            <th class="w-25" scope="col">收支抵扣后剩余费用</th>
            <th class="w-50" scope="col">收支抵扣后剩余应收/应退金额（元）</th>
          </tr>
          </thead>
          <tbody *ngFor="let item of resp?.check_out_room_info_dto?.st_after_list;let j = index">
          <tr>
            <td>{{item.object_code}}</td>
            <td>{{item.object_value}}</td>
          </tr>
          </tbody>
          <tbody>
          <tr>
            <td class="bg-gray-200 font-weight-bold" >应收/退总计（元）</td>
            <td class="text-right text-danger font-weight-bold" >{{-resp?.check_out_room_info_dto?.amount_total}}</td>
          </tr>
          </tbody>
        </ng-container>
      </table>
      <ng-container *ngIf="checkoutRefundDTO?.checkout_finance_type_enum === 'REFUND'">
        <ng-container *ngIf="checkoutRefundDTO?.checkout_bill_pay_type_enum === 'ALIPAY'">
          <table class="table table-bordered ">
            <thead class="thead-light">
            <tr>
              <th class="w-25" scope="col">退款方式：转账</th>
              <th class="w-50" colspan="2" scope="col">转账方式：支付宝</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>收款人姓名：{{checkoutRefundDTO.refund_name}}</td>
              <td>支付宝账号：{{checkoutRefundDTO.bank_no}}</td>
              <td>退款金额（元）：<span class="text-danger font-weight-bold">{{resp.check_out_room_info_dto.amount_total}}</span></td>
            </tr>
            </tbody>
          </table>
        </ng-container>
        <ng-container *ngIf="checkoutRefundDTO?.checkout_bill_pay_type_enum === 'TRANSFER'">
          <table class="table table-bordered ">
            <thead class="thead-light">
            <tr>
              <th class="w-25" scope="col">退款方式：转账</th>
              <th class="w-50" colspan="3" scope="col">转账方式：银行卡</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>收款人姓名：{{checkoutRefundDTO.refund_name}}</td>
              <td>开户行：{{checkoutRefundDTO.open_bank}}</td>
              <td>银行账号：{{checkoutRefundDTO.bank_no}}</td>
              <td>退款金额（元）：<span class="text-danger font-weight-bold">{{resp.check_out_room_info_dto.amount_total}}</span></td>
            </tr>
            </tbody>
          </table>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="checkoutRefundDTO?.checkout_finance_type_enum === 'ACCOUNT_MONEY'">
        <table class="table table-bordered ">
          <thead class="thead-light">
          <tr>
            <th colspan="2" scope="col" class="text-left">退款方式：客商账户</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>客商账户：{{checkoutRefundDTO.account_money_info_name}}</td>
            <td>转移金额（元）：<span class="text-danger font-weight-bold">{{resp.check_out_room_info_dto.amount_total}}</span></td>
          </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-container *ngIf="checkoutRefundDTO?.checkout_finance_type_enum === 'TRANSFER_IN'">
        <table class="table table-bordered ">
          <thead class="thead-light">
          <tr>
            <th colspan="3" scope="col">退款方式：退款转收入</th>
          </tr>
          </thead>
          <tbody>
          <ng-container
            *ngIf="resp?.check_out_room_info_dto?.st_after_list">
            <tr *ngFor="let item of resp?.check_out_room_info_dto?.st_after_list">
              <ng-template [ngIf]="+item.object_value < 0">
                <td>可转收入费用：{{item.object_code}}</td>
                <td>可转收入金额（元）：{{transferInTotalAbs(item.object_value)}}</td>
                <td>转收入总金额（元）：<span class="text-danger font-weight-bold">{{resp.check_out_room_info_dto.amount_total}}</span></td>
              </ng-template>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </ng-container>
    </div>
    <div class="box-table">
      <table class="table table-bordered">
        <tr>
          <td class="w-50">接待管家：{{resp?.operate_name}}</td>
          <td class="w-50" *ngIf="resp?.affirm_time">客户签字：{{renter?.name}}  {{resp?.affirm_time|date:'yyyy.MM.dd HH:mm:ss'}}</td>
        </tr>
      </table>
    </div>



    <div class="text-center">
      <button printSectionId="print_div_main" ngxPrint class="btn btn-primary mr-3">打印退宿汇总单</button>
      <button type="button" class="btn btn-secondary" (click)="closeTab()">关闭</button>
    </div>
  </div>
</div>




<div style="display: none">
  <div #print_div_main id="print_div_main">
    <table width="100%" border="1" cellpadding="0" cellspacing="0">
      <thead bgcolor="#e4e4e4">
      <tr>
        <th colspan="4" align="center"><b>退宿费用结算单</b></th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>退宿单号：{{resp?.renter_checkout_number}}</td>
        <td>姓名：{{renter?.name}}</td>
        <td>证件号码：{{renter?.cret_num_conceal}}</td>
        <td>手机号码：{{renter?.tel_conceal}}</td>
      </tr>
      <tr>
        <td>房间号：{{renter?.room_name}}</td>
        <td>入住日期：{{renter?.checkin_time|date:'yyyy.MM.dd'}}</td>
        <td>免租期:
          <ng-container *ngIf="renter?.free_start_time&&renter?.free_end_time">{{renter?.free_start_time|date:'yyyy.MM.dd'}}-{{renter?.free_end_time|date:'yyyy.MM.dd'}}</ng-container>
        </td>
        <td>退宿日期：{{renter.checkout_time|date:'yyyy.MM.dd'}}</td>
      </tr>
      <tr>
        <th colspan="4">费用结算汇总：</th>
      </tr>
      <tr>
        <th>应收费用</th>
        <th colspan="2">费用周期</th>
        <th>应收金额（元）</th>
      </tr>
      <ng-container *ngFor="let item of resp?.check_out_room_info_dto?.receivable_items;let i=index">
        <tr>
          <td>{{item.object_code}}</td>
          <td colspan="2">{{item.object_name}}</td>
          <td>{{item.object_value}}</td>
        </tr>
      </ng-container>
      <tr>
        <td colspan="4"><b>应收总计：{{resp?.check_out_room_info_dto?.receivable_total}}元</b></td>
      </tr>

      <tr>
        <th>应退费用</th>
        <th colspan="2">费用周期</th>
        <th>应退金额（元）</th>
      </tr>
      <ng-container *ngFor="let item of resp?.check_out_room_info_dto?.retreated_items;let i=index">
        <tr>
          <td>{{item.object_code}}</td>
          <td colspan="2">{{item.object_name}}</td>
          <td>{{item.object_value}}</td>
        </tr>
      </ng-container>
      <tr>
        <td colspan="4"><b>应退总计：{{resp?.check_out_room_info_dto?.retreated_total}}元</b></td>
      </tr>

      </tbody>
    </table>
    <br>
    <table width="100%" border="1" cellpadding="0" cellspacing="0" *ngIf="resp?.check_out_room_info_dto.deduction" class="table table-bordered">

      <ng-container *ngIf="resp?.check_out_room_info_dto?.st_after_list">
        <thead class="#e4e4e4">
        <tr>
          <th style="text-align: left" colspan="2" scope="col">是否收支抵扣：是</th>
        </tr>
        </thead>
        <thead class="#e4e4e4">
        <tr>
          <th class="w-25" scope="col">收支抵扣后剩余费用</th>
          <th class="w-50" scope="col">收支抵扣后剩余应收/应退金额（元）</th>
        </tr>
        </thead>
        <tbody *ngFor="let item of resp?.check_out_room_info_dto?.st_after_list;let j = index">
        <tr>
          <td>{{item.object_code}}</td>
          <td>{{item.object_value}}</td>
        </tr>
        </tbody>
        <tbody>
        <tr>
          <td class="bg-gray-200 font-weight-bold" >应收/退总计（元）</td>
          <td class="text-right text-danger font-weight-bold" >{{-resp?.check_out_room_info_dto?.amount_total}}</td>
        </tr>
        </tbody>
      </ng-container>
    </table>
    <br>
    <ng-container *ngIf="checkoutRefundDTO?.checkout_finance_type_enum === 'REFUND'">
      <ng-container *ngIf="checkoutRefundDTO?.checkout_bill_pay_type_enum === 'ALIPAY'">
        <table width="100%" border="1" cellpadding="0" cellspacing="0">
          <thead bgcolor="#e4e4e4">
          <tr>
            <th class="w-25" scope="col" style="text-align: left">退款方式：转账</th>
            <th class="w-50" colspan="2" scope="col">转账方式：支付宝</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>收款人姓名：{{checkoutRefundDTO.refund_name}}</td>
            <td>支付宝账号：{{checkoutRefundDTO.bank_no}}</td>
            <td>退款金额（元）：<span style="font-weight: 600">{{resp.check_out_room_info_dto.amount_total}}</span></td>
          </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-container *ngIf="checkoutRefundDTO?.checkout_bill_pay_type_enum === 'TRANSFER'">
        <table width="100%" border="1" cellpadding="0" cellspacing="0">
          <thead bgcolor="#e4e4e4">
          <tr>
            <th class="w-25" scope="col" style="text-align: left">退款方式：转账</th>
            <th class="w-50" colspan="3" scope="col">转账方式：银行卡</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>收款人姓名：{{checkoutRefundDTO.refund_name}}</td>
            <td>开户行：{{checkoutRefundDTO.open_bank}}</td>
            <td>银行账号：{{checkoutRefundDTO.bank_no}}</td>
            <td>退款金额（元）：<span style="font-weight: 600">{{resp.check_out_room_info_dto.amount_total}}</span></td>
          </tr>
          </tbody>
        </table>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="checkoutRefundDTO?.checkout_finance_type_enum === 'ACCOUNT_MONEY'">
      <table width="100%" border="1" cellpadding="0" cellspacing="0">
        <thead bgcolor="#e4e4e4">
        <tr>
          <th colspan="2" scope="col" style="text-align: left">退款方式：客商账户</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>客商账户：{{checkoutRefundDTO.account_money_info_name}}</td>
          <td>转移金额（元）：<span class="font-weight-bold">{{resp.check_out_room_info_dto.amount_total}}</span></td>
        </tr>
        </tbody>
      </table>
    </ng-container>
    <ng-container *ngIf="checkoutRefundDTO?.checkout_finance_type_enum === 'TRANSFER_IN'">
      <table width="100%" border="1" cellpadding="0" cellspacing="0">
        <thead bgcolor="#e4e4e4">
        <tr>
          <th colspan="3" scope="col" style="text-align: left">退款方式：退款转收入</th>
        </tr>
        </thead>
        <tbody>
        <ng-container
          *ngIf="resp?.check_out_room_info_dto?.st_after_list">
          <tr *ngFor="let item of resp?.check_out_room_info_dto?.st_after_list">
            <ng-template [ngIf]="+item.object_value < 0">
              <td>可转收入费用：{{item.object_code}}</td>
              <td>可转收入金额（元）：{{transferInTotalAbs(item.object_value)}}</td>
              <td>转收入总金额（元）：<span class="font-weight-bold">{{resp.check_out_room_info_dto.amount_total}}</span></td>
            </ng-template>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </ng-container>
    <br>
    <table width="100%" border="1" cellpadding="0" cellspacing="0">
      <tr>
        <td style="text-align: center">接待管家：{{resp?.operate_name}}</td>
        <td style="text-align: center">客户签字：{{renter?.name}} {{renter?.checkout_time|date:'yyyy.MM.dd HH:mm:ss'}}</td>
      </tr>
    </table>
  </div>
</div>
