<div class="setbox padd15">
  <div class="row">
    <div class="col-12 col-sm-6 col-md-3 col-xl-4">
      <div class="form-group">
        <label>查询条件</label>
        <input [(ngModel)]="appointListRequest.search_content" class="form-control" placeholder="请输入姓名、手机号码、微信号">
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-5 col-xl-2 col-2xl-3">
      <div class="form-group">
        <label>状态</label>

        <div class="border-0">
          <select (change)="statusSet($event)" class="w-100" [(ngModel)]="appointListRequest.status">
            <option value="">全部</option>
            <option value="UNHANDLE">未处理</option>
            <option value="WAITSEEHOUSE">待看房</option>
            <option value="SIGNED">已签约</option>
            <option value="UNSIGN">未签约</option>
            <option value="UNSEEHOUSE">未看房</option>
            <option value="CANCLE">已取消</option>
          </select>

        </div>
      </div>

    </div>
    <div class="col-12 col-sm-6 col-md-3 col-xl-3 ">
      <div class="form-group">
        <label class="w-100 d-web">&nbsp;</label>
        <button class="btn btn-info  " type="button" (click)="appointListRequest.page_num = 1;searchList()">
          <i class="fa fa-search"></i>搜索
        </button>
        <button class="btn btn-secondary " type="button" (click)="clear()">
          <i class="fa fa-times"></i>清空条件
        </button>
      </div>
    </div>
  </div>

  <table class="table table-bordered table-striped">
    <thead>
    <tr>
      <th class="show2">提交时间</th>
      <th>预约人姓名</th>
      <th>手机号码</th>
      <th class="show2">微信号</th>
      <th class="show2">预约社区</th>
      <th class="show2">预约房型</th>
      <th>预计入住日期</th>
      <th class="show2">看房时间</th>
      <th>状态</th>
      <th>操作</th>
    </tr>
    </thead>
    <tbody>

    <tr *ngIf="!btn_privileges.SEE_HOUSE_LIST || appointListResponse?.appoint_info_dtos == null || appointListResponse?.appoint_info_dtos.length == 0">
      <td class="show1" colspan="5">本次未查询到相关数据</td>
      <td class="show2" colspan="10">本次未查询到相关数据</td>
    </tr>

    <ng-container *ngIf="btn_privileges.SEE_HOUSE_LIST">
      <ng-container *ngFor="let info of appointListResponse?.appoint_info_dtos | paginate: {itemsPerPage: appointListRequest.page_size,
          currentPage: appointListRequest.page_num,totalItems: appointListResponse.total }">
      <tr>
        <td class="show2">{{info?.create_time | date:'yyyy-MM-dd HH:mm:ss'}}</td>
        <td>{{info?.check_in_name}}</td>
        <td>{{info?.check_in_phone}}</td>
        <td class="show2">{{info?.check_in_weixin}}</td>
        <td class="show2">{{info?.project_name}}</td>
        <td class="show2">{{info?.room_type_name}}</td>
        <td>{{info?.check_in_time | date:'yyyy-MM-dd'}}</td>
        <td class="show2">{{info?.see_house_time ? (info?.see_house_time | date:'yyyy-MM-dd HH:mm:ss') : '-'}}</td>
        <td>
          <span *ngIf="'UNHANDLE' == info.status" class="badge badge-pill" style="background-color:#ff404b;color:#fff">未处理</span>
          <span *ngIf="'WAITSEEHOUSE' == info.status" class="badge badge-pill" style="background-color:#00abe7;color:#fff">待看房</span>
          <span *ngIf="'CANCLE' == info.status" class="badge badge-pill" style="background-color:#c8ccd7">已取消</span>
          <span *ngIf="'UNSIGN' == info.status" class="badge badge-pill" style="background-color:#c8ccd7">未签约</span>
          <span *ngIf="'SIGNED' == info.status" class="badge badge-pill" style="background-color:#afef60;color:#fff">已签约</span>
          <span *ngIf="'UNSEEHOUSE' == info.status" class="badge badge-pill" style="background-color:#c8ccd7">未看房</span>
        </td>

        <td>
          <div>
            <button *ngIf="btn_privileges.SEE_HOUSE_OPERATE && info.status !== 'SIGNED' && info.status !== 'CANCLE'" (click)="openOperateModal(info.id)"
                    type="button" class="btn btn-info mr10">
              <i class="fa fa-cogs"></i>处理
            </button>
            <button *ngIf="btn_privileges.SEE_HOUSE_OPERATE && info.status === 'CANCLE'" (click)="openOperateModal(info.id)"
                    type="button" class="btn btn-info mr10">
              <i class="fa fa-eye"></i>查看
            </button>
            <button *ngIf="btn_privileges.SEE_HOUSE_OPERATE && info.status === 'SIGNED'"
                    type="button" class="btn mr10"
                     disabled>
              <i class="fa fa-cogs"></i>处理
            </button>
          </div>
        </td>
      </tr>
    </ng-container>
    </ng-container>
    </tbody>
  </table>
  <div *ngIf="btn_privileges.SEE_HOUSE_LIST && appointListResponse?.appoint_info_dtos != null && appointListResponse?.appoint_info_dtos.length > 0"
       class="text-right pages">
    <pagination-controls (pageChange)="pageChange($event)" directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                         screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                         screenReaderCurrentLabel="You're on page"></pagination-controls>
    <div class="text-right">共有{{appointListResponse.total}}条预定信息</div>
  </div>
</div>
<app-seehouse-detail #detailComponent (auditingChange)="auditRefresh()"></app-seehouse-detail>
