import {Injectable} from '@angular/core';

import {RequestService} from '../request.service';
import {environment} from '../../../environments/environment';
import {HotelProject} from '../../model/room/project.model';
import {
  ProjectAppointmentTimeRequest,
  ProjectAppointmentTimeResponse,
  ProjectBaseRoomListResponse, ProjectDangerTypeRequest,
  ProjectEditRequest, ProjectManageEditRequest,
  ProjectManageListResponse, ProjectManageQueryResponse, ProjectSmartDevsResponse, ProjectTemplateRequest,
  ProjectTemplateResponse, UpdateCheckinGuideRequest, WaitingEffectiveProjectBaseConfigRequest, WaitingEffectiveProjectBaseConfigResponse
} from "../../model/project/project.model";

/**
 * @description:项目相关服务
 *
 * @Author: xulihua
 * @Date: 2018-02-27 15:31:31
 * @Last Modified by: xulihua
 * @Last Modified time: 2018-02-27 15:31:31
 */
@Injectable()
export class ProjectService {

  // 房源创建api
  private projectDetailUrl = `${environment.apiHost}room/project/detail`;
  // 单元列表
  private PROJECT_LIST_URL = `${environment.apiHost}room/project/list`;
  //获得所有的项目列表
  private ALL_PROJECT_LIST = `${environment.apiHost}room/all/project`;
  // 编辑项目信息
  private PROJECT_EDIT = `${environment.apiHost}room/project/edit`;

  // 获取基础房型
  private GET_BASE_ROOM_LIST = `${environment.apiHost}project/manage/baseRoom/list`;

  // 单元列表
  private GET_PROJECT_MANAGE_LIST = `${environment.apiHost}project/manage/list`;
  // 提交项目
  private POST_PROJECT_EDIT = `${environment.apiHost}project/manage/edit`;
  // 保存项目
  private POST_PROJECT_SAVE = `${environment.apiHost}project/manage/save`;
  // 查询项目
  private POST_PROJECT_QUERY = `${environment.apiHost}project/manage/query`;

  //查询项目隐患类型
  private GET_PROJECT_DANGER_TYPES = `${environment.apiHost}project/manage/find/dangerTypes`;
  //保存项目隐患类型
  private POST_PROJECT_DANGER_TYPES = `${environment.apiHost}project/manage/save/dangerTypes`;

  //项目配置文件转html
  private GET_PROJECT_TEMPLATE_CONFIGURE = `${environment.apiHost}project/manage/template`;

  // 查询项目是否有智能设备
  private PROJECT_SMART_DEVS = `${environment.apiHost}project/manage/smart/devs`;

  // 查询未来生效项目费用配置
  private PROJECT_FUTURE_EFFECTIVE_PROJECT_BASE_CONFIG = `${environment.apiHost}project/manage/query/waiting/effective/project/base/config`;

  private POST_UPDATE_PROJECT_CHECKIN_GUIDE = `${environment.apiHost}project/manage/edit/checkin/guide`;
  // 项目管理-预约时间段配置
  private GET_PROJECT_APPOINTMENT_TIME = `${environment.apiHost}project/manage/appointment/time/detail`;
  private POST_PROJECT_APPOINTMENT_TIME_SAVE = `${environment.apiHost}project/manage/edit/appointment/time`;

  constructor(private requestService: RequestService) {
  }

  /**
   *  获取项目详情
   */
  public getProjectDetail(requestData: any): Promise<any> {
    return this.requestService.authGet(this.projectDetailUrl, requestData);
  }

  /**
   * @Description:  获得房源项目列表信息
   * @Author: zhoujiahao
   * @Date: 2018-03-23  11:59
   */
  getProjects(requestData?: any): Promise<Array<HotelProject>> {
    return this.requestService.authGet(this.ALL_PROJECT_LIST, requestData)
      .then(data => {
        return data.user_projects;
      });
  }

  /**
   * @description: 获取当前用户项目列表
   * @Author: kaungye
   * @Date: 2018/4/12
   * @Last Modified by: kuangye
   * @Last Modified time: 2018/4/12
   */
  getUserProjects(requestData?: any): Promise<Array<HotelProject>> {
    return this.requestService.authGet(this.PROJECT_LIST_URL, requestData)
      .then(data => {
        return data.user_projects;
      });
  }

  editProject(request?: ProjectEditRequest): Promise<any> {
    return this.requestService.authPost(this.PROJECT_EDIT, request);
  }

  queryCompanyProject(): Promise<ProjectManageListResponse> {
    return this.requestService.authGet(this.GET_PROJECT_MANAGE_LIST);
  }

  getRoomBaseTemp(): Promise<ProjectBaseRoomListResponse> {
    return this.requestService.authGet(this.GET_BASE_ROOM_LIST);
  }

  saveProject(req: ProjectManageEditRequest) {
    return this.requestService.authPost(this.POST_PROJECT_SAVE, req);
  }

  addProject(req: ProjectManageEditRequest): Promise<any> {
    return this.requestService.authPost(this.POST_PROJECT_EDIT, req);
  }

  getManageProjectDetail(id): Promise<ProjectManageQueryResponse> {
    return this.requestService.authGet(this.POST_PROJECT_QUERY, {id: id});
  }

  getProjectDangerTypes(): Promise<any> {
    return this.requestService.authGet(this.GET_PROJECT_DANGER_TYPES, null);
  }

  saveProjectDangerTypes(req: ProjectDangerTypeRequest): Promise<any> {
    return this.requestService.authPost(this.POST_PROJECT_DANGER_TYPES, req);
  }

  getProjectTemplateConfigure(req: ProjectTemplateRequest): Promise<ProjectTemplateResponse> {
    return this.requestService.authPost(this.GET_PROJECT_TEMPLATE_CONFIGURE, req);
  }

  getProjectSmarDevs(id): Promise<ProjectSmartDevsResponse> {
    return this.requestService.authGet(this.PROJECT_SMART_DEVS, {id: id});
  }

  getWaitingEffectiveProjectBaseConfig(req: WaitingEffectiveProjectBaseConfigRequest): Promise<WaitingEffectiveProjectBaseConfigResponse> {
    return this.requestService.authGet(this.PROJECT_FUTURE_EFFECTIVE_PROJECT_BASE_CONFIG, req);
  }

  updateCheckinGuide(req: UpdateCheckinGuideRequest): Promise<any> {
    return this.requestService.authPost(this.POST_UPDATE_PROJECT_CHECKIN_GUIDE, req);
  }

  getProjectAppointmentTime(projectId: number): Promise<ProjectAppointmentTimeResponse> {
    return this.requestService.authGet(this.GET_PROJECT_APPOINTMENT_TIME, {project_id: projectId});
  }

  saveProjectAppointmentTime(req: ProjectAppointmentTimeRequest): Promise<any> {
    return this.requestService.authPost(this.POST_PROJECT_APPOINTMENT_TIME_SAVE, req);
  }
}
