import {LogService} from "../../../service/log/log.service";
import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {
  LeQiDaLoginSmsCodeRequest,
  Login,
  LoginCheckCode,
  LoginSecondCheckRequest,
  SendCode,
  SmsCodeCheckRequest,
  SysUser,
  SysUserDTO, UserWechatRelateFlagRequest
} from "../../../model/user/user.model";
import {UserService} from "../../../service/user/user.service";
import {AuthService} from "../../../service/common/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {LocalStorage} from '../../../common/localstorage';
import {trim} from '../../../common/common';
import {WorkService} from "../../../service/common/work.service";
import {MessageModalInfo} from "../../../model/modal/message-model";
import {WorkModel} from "../../../model/common/common.model";
import {loginPassWordValidator, timeMinValidator} from "../../../core/validator/validator.common";
import {Constants} from "../../../common/constants";
import {SafetyComponent} from "../../workspace/safety/safety.component";
import {RegularExp} from "../../../common/util.regexp";
import {is_inApp_IOS} from "../../../common/common";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit, OnDestroy {

  /**
   *  登录流程-PMS
   *  1.账号密码/ 短信[userLogin][userSystemLogin]
   *  2.是否需要附带手机验证码
   *  3.手机短信二次校验（仅密码登录）（第三方无）
   *  4.是否绑定微信[inspectWechatFlag]
   *  5.登录城市安全监测（第三方无）[showSatetyDialog][findBase]
   *  6.跳转[_loginOp]
   *  第三方
   */

  clearTime: any;
  imgCodeUrl: string = environment.apiHost + "image/code/";
  imgUrl: string;
  errorContent: string;
  login: Login = new Login();
  smsCodeCheckRequest: SmsCodeCheckRequest = new SmsCodeCheckRequest();
  buttonShow: boolean = true;
  clock: number = 60;
  charcter: string;
  codeShow = false;
  is_login: boolean;
  showCode: boolean = false;
  loginCode: string;
  wechatflag: string;
  projectId: string;
  companyId: number;
  id: string;
  loginLoading: boolean = false;
  platformCode: string = 'CSDM';
  redirect_url: any;
  temporary_sign: any;
  back_url: string;
  is_need_sms_check: boolean = false;
  sendCode: SendCode = new SendCode();
  loginCheckCode: LoginCheckCode = new LoginCheckCode();
  loginSmsCodeRequest: LeQiDaLoginSmsCodeRequest = new LeQiDaLoginSmsCodeRequest();
  has_login: boolean = false;
  loginSecondCheckRequest: LoginSecondCheckRequest = new LoginSecondCheckRequest();
  user_phone: string;
  is_select_need_check: string;
  errorCodeContent: string;
  sysUserDTO: SysUser = new SysUser();
  temp_token: string;
  temp_redirect_url: any;
  temp_temporary_sign: any;
  loginId: string;
  callback: any;

  @ViewChild(SafetyComponent, {static: true}) safetyComponent: SafetyComponent;

  constructor(private logService: LogService,
              private userService: UserService,
              private auth: AuthService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private workService: WorkService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.system_type) {
        this.platformCode = queryParams.system_type;
        this.back_url = queryParams.backurl;
      }
      if (queryParams.wechatflag) {
        this.wechatflag = queryParams.wechatflag;
        this.companyId = queryParams.companyId;
        this.projectId = queryParams.projectId;
        this.id = queryParams.id;
      }

      if (!queryParams.system_type) {
        let item = sessionStorage.getItem("state");
        if (item) {
          this.platformCode = item;
        }
      }

    });
    this.is_login = false;
    // loadScript(
    //   "https://seal.godaddy.com/getSeal?sealID=z5pqOmGebDjEWJm5KjpZT11XRAZ9by12DDoiftZaTeni2lI3Uhk3ahilA3HD"
    // );
    this.loginCodeShow();
    this.callback = () => {
      this.getListener();
    };
  }

  imgCodeClick() {
    let uniqueTag = this.createCharacter();
    let date = new Date();
    this.imgUrl = this.imgCodeUrl + uniqueTag + "?num=" + date.getTime();
    this.charcter = uniqueTag;
  }

  loginCodeShow() {
    this.userService.userCodeShow().then(data => {
      if (data.code_show === "SHOW") {
        this.imgCodeClick();
        this.codeShow = true;
      }
    });
  }

  check(isBounced: boolean) {
    this.login.userAccount = trim(this.login.userAccount);
    this.login.imgCode = trim(this.login.imgCode);
    // if (!new RegExp(RegularExp.phone.mobile).test(this.login.userAccount)) {
    //   this.errorContent = '请输入合法手机号';
    //   return false;
    // }
    if (this.login.userPassword === "") {
      this.login.userPassword = null;
    }
    if (this.login.loginType === 'PASSWORD') {
      if ((this.login.imgCode == null || this.login.imgCode === "") && this.codeShow) {
        this.errorContent = "图片验证码不能为空";
        return false;
      } else if (this.login.userPassword && !new RegExp(/^[a-zA-Z0-9\W_]{6,18}$/).test(this.login.userPassword)) {
        this.errorContent = "密码由6-18位字符组成(数字,字母,符号)";
        return false;
      } else {
        this.errorContent = "";
        return true;
      }
    } else {
      if (this.login.loginCheckCode == null || this.login.loginCheckCode === "") {
        this.errorContent = "验证码不能为空";
        return false;
      } else {
        this.errorContent = "";
        return true;
      }
    }
  }




  timeOut() {
    this.clock = this.clock - 1;
    if (this.clock === 0) {
      this.buttonShow = true;
      return true;
    } else {
      setTimeout(() => this.timeOut(), 1000);
    }
  }

  inspectWechat() {
    if (this.clearTime) {
      window.clearInterval(this.clearTime);
    }
    sessionStorage.removeItem('code');
    localStorage.setItem('platformCode', this.platformCode);

    if (this.platformCode === 'WOS' || this.platformCode === 'LEQIDA') {
      if (this.back_url) {
        location.href = `${this.back_url}?temporarySign=${this.temporary_sign}`;
      } else {
        location.href = `${this.redirect_url}?temporarySign=${this.temporary_sign}`;
      }
    } else {
      this.router.navigateByUrl("/system_manage");
    }
  }

  inspectWechatFlag() {
    let request = new UserWechatRelateFlagRequest();
    request.system_type = this.platformCode;
    request.temporary_code = trim(this.temporary_sign);
    this.userService.inspectWechatFlag(request).then(data => {
      this.loginLoading = false;
      if (data.flag === 'false') {
        if (data.redirect_url) {

          let messageModalInfo = new MessageModalInfo();
          messageModalInfo.operationMessage = "未绑定微信,待页面刷新后重新登录绑定。";
          messageModalInfo.callback.subscribe(() => {
            location.href = data.redirect_url;
          });
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));

        } else {
          this.showCode = true;
          this.is_need_sms_check = false;
          this.clearTime = setTimeout(() => {
            this.inspectWechatFlag();
          }, 3000);
        }
      } else {
        this.showSatetyDialog();
      }
    });
  }

  passWordEdit() {
    // 密码不合规，弹框提示
    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.operationMessage = '您的账号密码存在安全风险，请前往修改密码！!';
    messageModalInfo.returnUrl = "/reset_password?system_type=" + this.platformCode;
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));
  }

  userLogin() {
    if (this.loginLoading) {
      return;
    }
    this.loginCode = sessionStorage.getItem("code") || '';
    if (is_inApp_IOS()) {
      window.document.addEventListener('apptransfer', this.callback);
      window['webkit'].messageHandlers.getLocalStorage.postMessage(null);
    } else {
      let uniqueTag = this.createCharacter();
      let loginUniqueTag = this.auth.getLoginUniqueTag();
      if (this.check(true)) {
        // 登录
        if (this.is_login) {
          return;
        }
        this.userSystemLogin(uniqueTag, loginUniqueTag);
      }
    }


  }

  getListener() {
    let uniqueTag = this.createCharacter();
    let loginUniqueTag = this.auth.getLoginUniqueTag();
    //let uniqueTag = localStorage.getItem("uniqueTag");
    // 检查登录参数
    if (this.check(true)) {
      // 登录
      if (this.is_login) {
        return;
      }
      this.userSystemLogin(uniqueTag, loginUniqueTag);
    }
  }

  userSystemLogin(uniqueTag: string, loginUniqueTag) {
    if (this.loginCode) {
      this.login.code = this.loginCode;
    }
    this.loginLoading = true;
    this.login.uniqueTag = uniqueTag;
    this.login.loginUniqueId = loginUniqueTag;
    this.login.systemType = this.platformCode;
    this.userService
      .userLogin(this.login)
      .then(biz_response => {
        this.is_login = false;
        let need_sms_check = biz_response.is_need_login_code_check;
        if (need_sms_check && this.login.loginType === 'PASSWORD') {
          delete this.is_select_need_check;
          delete this.loginSecondCheckRequest.sms_code;
          delete this.loginSecondCheckRequest.mobile;
          this.user_phone = this.login.userAccount;
          this.user_phone = this.user_phone.replace(/(\d{3})\d*(\d{4})/, '$1****$2');
          this.has_login = true;
          this.is_need_sms_check = true;
          this.sysUserDTO = biz_response.sys_user_dto;
          this.temp_token = biz_response.token;
          this.temp_redirect_url = biz_response.redirect_url;
          this.temp_temporary_sign = biz_response.temporary_sign;
        } else {
          if (!loginPassWordValidator(this.login.userPassword) && this.login.loginType === 'PASSWORD') {
            this.loginLoading = false;
            this.passWordEdit();
          } else {

            this.auth.afterFinishLogin(biz_response);

            if (this.platformCode === 'WOS' || this.platformCode === 'LEQIDA') {
              this.redirect_url = biz_response.redirect_url;
              this.temporary_sign = biz_response.temporary_sign;
            }
            this.inspectWechatFlag();
          }
        }

      })
      .catch(e => {
        this.loginLoading = false;
        this.errorContent = e.message;
        // 查询是否需要展示图片验证码
        this.loginCodeShow();
        // 登录失败后刷新图片验证码
        this.imgCodeClick();
        this.is_login = false;
      });
  }



  backLogin() {

    this.loginCode = sessionStorage.getItem("code") || '';

    let uniqueTag = this.createCharacter();
    let loginUniqueTag = this.auth.getLoginUniqueTag();
    // 检查登录参数
    if (this.check(true)) {
      // 登录
      if (this.is_login) {
        return;
      }
      this.login.uniqueTag = uniqueTag;
      this.login.loginUniqueId = loginUniqueTag;
      this.userService
        .userLogin(this.login)
        .then(biz_response => {
          let need_sms_check = biz_response.is_need_login_code_check;
          if (need_sms_check && this.login.loginType === 'PASSWORD') {
            delete this.is_select_need_check;
            delete this.loginSecondCheckRequest.sms_code;
            delete this.loginSecondCheckRequest.mobile;
            this.user_phone = this.login.userAccount;
            this.user_phone = this.user_phone.replace(/(\d{3})\d*(\d{4})/, '$1****$2');
            this.has_login = true;
            this.is_need_sms_check = true;
            this.sysUserDTO = biz_response.sys_user_dto;
            this.temp_token = biz_response.token;
            this.temp_redirect_url = biz_response.redirect_url;
            this.temp_temporary_sign = biz_response.temporary_sign;
          } else {
            if (!loginPassWordValidator(this.login.userPassword) && this.login.loginType === 'PASSWORD') {
              this.loginLoading = false;
              this.passWordEdit();
            } else {
              this.is_login = false;
              //登录成功 之后清空之前用户的信息
              LocalStorage.customClear(this.auth.getUserInfo());
              sessionStorage.clear();
              this.auth.setUserInfo(biz_response.sys_user_dto);

              // 存放token
              this.auth.setToken(biz_response.token);

              this.setWechatConfig();

              this.showSatetyDialog();
              // 跳转
              // this.router.navigateByUrl("/system_manage/workspace/alarm/2898");
            }
          }
        })
        .catch(e => {

          this.errorContent = e.message;
          // 查询是否需要展示图片验证码
          this.loginCodeShow();
          // 登录失败后刷新图片验证码
          this.imgCodeClick();
          this.is_login = false;
        });
    }
  }

  // 判断是否需要短信二次验证
  // isNeedSmsCheck() {
  //   delete this.loginSecondCheckRequest.img_code;
  //   delete this.loginSecondCheckRequest.sms_code;
  //   delete this.loginSecondCheckRequest.mobile;
  //   this.user_phone = this.login.userAccount;
  //   this.user_phone = this.user_phone.replace(/(\d{3})\d*(\d{4})/, '$1****$2');
  //   let smsCheckTag = this.createCharacter();
  //   this.smsCodeCheckRequest.sms_check_tag = smsCheckTag;
  //   this.smsCodeCheckRequest.user_account = this.login.userAccount;
  //   this.userService.needSmsCheck(this.smsCodeCheckRequest).then(data => {
  //     this.is_need_sms_check = data.need_sms_check_flag;
  //     if (this.is_need_sms_check) {
  //       this.imgCodeClick();
  //       this.codeShow = true;
  //       this.has_login = true;
  //     } else {
  //       this.userLogin();
  //     }
  //
  //   });
  // }

  sendCodeRequest(codeType?: string) {
    if (codeType === 'loginCheckCode') {
      if (this.checkMobile()) {
        let uniqueTag = localStorage.getItem("uniqueTag");
        this.loginCheckCode.uniqueTag = uniqueTag;
        this.loginCheckCode.codeType = 43;
        this.loginCheckCode.mobile = this.login.userAccount;
        this.userService.sendLoginCheckCode(this.loginCheckCode).then(data => {
          this.clock = 60;
          // 发送成功之后进行倒记时
          this.buttonShow = false;
          setTimeout(() => this.timeOut(), 1000);
        }).catch(error => {
          this.clock = 60;
          this.buttonShow = true;
        });
      }
    } else {
      if (this.checkMobile()) {
        this.loginSmsCodeRequest.code_type = 49;
        this.loginSmsCodeRequest.send_phone = this.login.userAccount;
        this.userService.leQiDaLoginSmsCode(this.loginSmsCodeRequest).then(data => {
          this.clock = 60;
          // 发送成功之后进行倒记时
          this.buttonShow = false;
          setTimeout(() => this.timeOut(), 1000);
        }).catch(error => {
          this.clock = 60;
          this.buttonShow = true;
        });
      }
    }
  }

  checkOther(bl?: boolean) {
    this.sendCode.mobile =
      Number((this.login.userAccount + "").trim()) === 0
        ? null
        : Number((this.login.userAccount + "").trim());
    this.sendCode.imgCode = this.loginSecondCheckRequest.img_code;
    this.sendCode.imgCode = this.sendCode.imgCode ? (this.sendCode.imgCode).trim() : null;

    if (!new RegExp(RegularExp.phone.mobile).test(this.sendCode.mobile + "")) {
      this.errorContent = "请输入合法手机号";
      return false;
    } else if (this.sendCode.imgCode == null || this.sendCode.imgCode === "") {
      this.errorContent = "图片验证码不能为空";
      return false;
    } else {
      this.errorContent = "";
    }
    return true;
  }

  checkSmsCode() {
    this.loginSecondCheckRequest.unique_id = this.temp_token;
    this.loginSecondCheckRequest.mobile = this.login.userAccount;
    this.loginSecondCheckRequest.user_id = this.sysUserDTO.id;
    let is_select_need_check_flag;
    if (this.is_select_need_check) {
      is_select_need_check_flag = "NO_NEED";
      let loginUniqueTag = this.auth.getLoginUniqueTag();
      this.loginSecondCheckRequest.login_unique_id = loginUniqueTag;
    } else {
      is_select_need_check_flag = "NEED";
    }
    this.loginSecondCheckRequest.is_select_need_check = is_select_need_check_flag;
    this.userService.loginSecondCheck(this.loginSecondCheckRequest).then(data => {
      if (data.sms_code_check) {
        this.afterSecondCheckLogin();
      }
    }).catch(e => {
      this.errorCodeContent = e.message;
    });
  }

  setWechatConfig() {
    this.auth.setCompanyId(this.companyId);
    this.userService.saveCurrentCompany({companyId: this.companyId}).then(data => {
      if (data.add_dept) {
        localStorage.setItem("add_dept", data.add_dept);
      }
      if (data.add_project) {
        localStorage.setItem("add_project", data.add_project);
      }
      if (data.add_user) {
        localStorage.setItem("add_user", data.add_user);
      }
      if (data.add_room) {
        localStorage.setItem("add_room", data.add_room);
      }
      // this.userService.userProjectChange(this.projectId).then(x => {
      //   //获得项目名称
      //   // this.projectName = this.authService.getProjectName();
      //   this.router.navigateByUrl(`/alarm?id=${this.id}`);
      // });
      this.userService.userProjectChange(this.projectId).then(data2 => {
        // 存放项目信息
        this.auth.setProjectInfo(data2.project);
      });
    });
  }

  createCharacter() {
    let charcter: string = localStorage.getItem("uniqueTag");
    charcter = (charcter == null || charcter === "") && this.charcter != null ? this.charcter : charcter;
    if (charcter == null || charcter === "" || charcter.length !== 32) {
      let date = new Date();
      charcter = date.getTime() + "imgcodesms" + Math.random() * 100000000000;
      if (charcter.length !== 32) {
        charcter = charcter.substring(0, 32);
      }
    }
    localStorage.setItem("uniqueTag", charcter);
    return charcter;
  }

  ngOnDestroy() {
    if (this.clearTime) {
      window.clearInterval(this.clearTime);
    }
    window.document.removeEventListener('apptransfer', this.callback);
  }

  // 安全预警
  showSatetyDialog() {
    this.safetyComponent.openDialog(this.platformCode);
  }

  _loginOp() {
    if (!this.wechatflag) {
      this.inspectWechat();
    } else {
      this.router.navigateByUrl(`/alarm?id=${this.id}&wechatflag=2&projectId=${this.projectId}&companyId=${this.companyId}`);
    }
  }

  checkMobile() {
    if (!new RegExp(RegularExp.phone.mobile).test(this.login.userAccount + "")) {
      this.errorCodeContent = "请输入合法手机号";
      return false;
    } else {
      return true;
    }
  }

  afterSecondCheckLogin() {
    if (!loginPassWordValidator(this.login.userPassword)) {
      this.loginLoading = false;
      this.passWordEdit();
    } else {
      LocalStorage.customClear(this.auth.getUserInfo());
      sessionStorage.clear();
      this.auth.setUserInfo(this.sysUserDTO);
      this.auth.setToken(this.temp_token);
      if (this.platformCode === 'WOS' || this.platformCode === 'LEQIDA') {
        this.redirect_url = this.temp_redirect_url;
        this.temporary_sign = this.temp_temporary_sign;
      }
      this.inspectWechatFlag();
    }
  }

  goReg() {
    this.router.navigate(['/reg'], {queryParams: {system_type: 'LEQIDA'}});
  }

  onSelectTab(e: string) {
    this.login.loginType = e;
    this.errorContent = null;
  }
}
