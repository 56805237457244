import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {RegularExp} from "../../../../common/util.regexp";
import {WorkModel} from "../../../../model/common/common.model";
import {LeaseBaseInfoDTO} from "../../../../model/lease/lease.model";
import {MessageModalInfo} from "../../../../model/modal/message-model";
import {CheckoutRoomViewDTO, CheckoutRoomViewNewResponse} from "../../../../model/signed/checkout.model";
import {CheckoutInventoryConfirmRequestDTO, CheckoutRoomInvenDTO, DamageGoodsLilsDTO} from '../../../../model/signed/inventory.model';
import {SignDataRequest, SignOnIpadRequest} from "../../../../model/signed/sign.model";
import {WorkService} from "../../../../service/common/work.service";
import {SignedService} from "../../../../service/signed/signed.service";
import {PdfViewComponent} from '../../../shared/pdf-view/pdf-view.component';
import {SignDeviceBindingComponent} from "../../../sign-device-binding/sign-device-binding.component";
import {TabletComponent} from "../../tablet/tablet.component";
import {CheckOutConfirmComponent} from "../check-out-confirm/check-out-confirm.component";

@Component({
  selector: 'app-checkout-room-contract',
  templateUrl: './checkout-room-contract.component.html',
  styleUrls: ['./checkout-room-contract.component.css']
})
export class CheckoutRoomContractComponent implements OnInit {
  biz_request: CheckoutInventoryConfirmRequestDTO = new CheckoutInventoryConfirmRequestDTO();
  @ViewChild(CheckOutConfirmComponent) checkOutConfirm: CheckOutConfirmComponent;
  @ViewChild(PdfViewComponent) pdfView: PdfViewComponent;
  @ViewChild(TabletComponent, {static: true}) tabletComponent: TabletComponent;
  @ViewChild(SignDeviceBindingComponent, {static: true}) signDeviceBindingComponent: SignDeviceBindingComponent;

  constructor(
    private router: Router,
    private signedService: SignedService,
    private activatedRoute: ActivatedRoute,
    private workService: WorkService,
  ) {
  }

  roomIdList: Array<any> = [];

  paymentleaseId: number;
  leaseDetailResponse: LeaseBaseInfoDTO;//合同主体
  roomList: Array<CheckoutRoomViewDTO>;//列表数据
  leaseRoomId: number;
  // isPad :boolean=true;
  isNeedFilter: boolean = true;
  checkOutInformationNewdata: CheckoutRoomViewNewResponse;
  goodsData: Array<any> = [];
  roomName: any;
  manageName: any;
  isRoomList: Array<any> = [];
  checkOutConfirmList: any;
  CheckoutRoomInven: any;
  isBtn: boolean = true;
  isLease_room_id: Array<any> = [];
  roomNumber: Array<any> = [];//已退房间号
  //stepsData:步骤条参数
  //active：设置当前激活步骤，默认为2
  active: number;
  newDate: any;
  isModel: boolean = false;
  signName: string;
  public stepsData: any = [
    {
      title: '选择房间',
    },
    {
      title: '退房确认',
    },
    {
      title: '物品交接',
    },
    {
      title: '完成退房',
    }
  ];

  selectAll = false;

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.paymentleaseId = params['leaseId'];
      this.active = params['active'];
      this.leaseRoomId = params['leaseRoomId'];
      if (this.active !== 5) {
        this.initialize();
      }
    });
  }

  initialize() {

    this.signedService.checkoutRoomChooseViewNew({lease_id: this.paymentleaseId}).then(data => {
      if (data) {
        if (this.leaseRoomId) {
          let item = data.checkout_room_list.find(e => +e.lease_room_id === +this.leaseRoomId);
          if (!item) {
            this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, '未签署入住单或已退房'));
            this.workService.event_bus.emit(new WorkModel(this.workService.type.TAB_CLOSE_SELF));
          } else {
            data.checkout_room_list = data.checkout_room_list.filter(e => +e.lease_room_id === +this.leaseRoomId);
          }
        }
        if (data.checkout_room_list.length === 1) {
          this.active = 3;
          this.roomIdList.push(data.checkout_room_list[0].lease_room_id);
          this.isLease_room_id.push(data.checkout_room_list[0].lease_room_id);
        } else if (data.checkout_room_list.length > 1) {
          this.active = 2;
        } else {
          this.isModel = true;
          this.active = 2;
        }
      } else {
        this.isModel = true;
        this.active = 2;
      }


      this.leaseDetailResponse = data.lease_info;
      if (data.checkout_room_list) {
        data.checkout_room_list.forEach(item => {
          item['check'] = false;
        });
        this.roomList = data.checkout_room_list;
      }

    }).catch(() => {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.TAB_CLOSE_SELF));
    });
    //退房的时间
    this.newDate = new Date();
  }

  onSave(room: CheckoutRoomViewDTO) {
    room.check = !room.check;
    this.updateSelectAll();
  }

  updateSelectAll() {

    let allNum = this.roomList.length;
    let checkedNum = this.roomList.filter(e => e.check).length;

    if (allNum === checkedNum) {
      this.selectAll = true;
    } else if (allNum !== checkedNum) {
      this.selectAll = false;
    }

    this.roomIdList = this.roomList.filter(e => e.check).map(e => e.lease_room_id);

    if (this.roomIdList.length > 0) {
      this.isBtn = false;

    } else {
      this.isBtn = true;
    }
  }

  btnNext() {

    if (+this.active === 2) {
      if (this.roomIdList.length > 0) {
        this.active = 3;
      }

    } else if (+this.active === 3) {
      this.checkOutConfirmList = this.checkOutConfirm;
      if (this.checkOutConfirm.isShow) {//选择了转租
        if (!this.checkOutConfirm.setRadio) {
          this.checkOutConfirm.isRadio = true;
        } else {
          this.checkOutConfirm.isRadio = false;
        }
      }

      if (this.leaseDetailResponse.lease_step !== 'INTERNAL_LEASE') {
        if (this.checkOutConfirm.checkoutReson) {
          if (this.checkOutConfirm.checkoutReson !== "其他") {
            this.active = 4;
          } else {
            if (this.checkOutConfirm.checkoutResonExt) {
              this.active = 4;
            }
          }
        } else {
          this.checkOutConfirm.isCheckout = true;
        }
      } else {
        this.active = 4;
      }
    } else if (+this.active === 4) {
      this.active = 5;
    }

    if (this.active > 5) {
      this.active = 2;
    }
  }

  checkedBack(event) {
    this.isRoomList = event;
    if (event) {
      event.forEach(e => {
        // this.isNeedFilter = e.water_dev;
        e.details.forEach(ele => {
          this.checkOutInformationNewdata.checkout_inventory_list_dtos.forEach(elem => {
            elem.room_good_relation_dto.room_goods_info_category_dtolist.forEach(element => {
              element.room_goods_info_dtolist.forEach(eleme => {
                if (+ele.rowId === +eleme.id) {
                  eleme.partical_damage = ele.fewDamage;
                  eleme.full_damage = ele.allDamage;
                  eleme.lose = ele.loseDamage;
                  eleme.is_bad = '1';
                }
              });
            });
          });
        });
      });
    }
  }

  checkBack(event) {
    this.checkOutInformationNewdata = event;
    this.roomName = event.project_name;
    this.manageName = event.manage_man;

  }

  goSettingAsset() {
    this.pdfView.print();
  }

  /**
   * 线下确认
   */
  alertTips() {
    let messageModalInfo = new MessageModalInfo();
    messageModalInfo.type = 2;
    messageModalInfo.title = "交接确认";
    messageModalInfo.cancel = true;
    messageModalInfo.html = `
      <div>确认已打印并线下已签署纸质物品交接单？</div>
    `;
    messageModalInfo.callback.subscribe(() => {
      this.isConfirm();
    });
    this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_SUCCESS, messageModalInfo));

  }

  isConfirm() {//调用接口
    // let biz_request = new CheckoutInventoryConfirmRequestDTO();

    this.packageReq();
    this.biz_request.offline = true;

    this.signedService.checkoutInventoryListConfirmNew(this.biz_request).then(data => {
      this.roomNumber = data;
      this.active = 5;
    });
  }

  packageReq() {
    // let biz_request = new CheckoutInventoryConfirmRequestDTO();
    if (this.signName) {
      this.biz_request.sign = this.signName;
    }
    this.biz_request.lease_id = this.paymentleaseId;
    this.biz_request.offline = false;
    this.biz_request.checkout_room_inventory_dtos = [];
    if (this.checkOutConfirmList) {
      this.biz_request.checkout_reason = this.checkOutConfirmList.checkoutReson;
      this.checkOutConfirmList.listData.forEach(e => {
        let checkoutRoomInven = new CheckoutRoomInvenDTO();
        checkoutRoomInven.room_id = e.room_id;
        checkoutRoomInven.lease_room_id = e.lease_room_id;
        if (e.checkoutRoomType) {
          checkoutRoomInven.checkout_room_type = e.checkoutRoomType;
        } else {
          checkoutRoomInven.checkout_room_type = 'NORMAL';
        }
        this.checkOutInformationNewdata.checkout_inventory_list_dtos.forEach(ele => {
          if (ele.lease_room_info_dto.lease_room_id === e.lease_room_id) {
            if (ele.water_reading) {
              checkoutRoomInven.water_reading = ele.water_reading;
            }
            if (ele.hot_water_reading) {
              checkoutRoomInven.hot_water_reading = ele.hot_water_reading;
            }
            if (ele.electricity_reading) {
              checkoutRoomInven.electricity_reading = ele.electricity_reading;
            }
          }
        });
        checkoutRoomInven.damage_goods_list = [];
        this.isRoomList.forEach(item => {
          item.details.forEach(data => {

            if (e.lease_room_id === data.id) {
              let obj = new DamageGoodsLilsDTO();
              obj.good_name = data.goodName;
              obj.id = data.rowId;
              obj.is_bad = "1";
              obj.partical_damage = data.fewDamage;
              obj.lose = data.loseDamage;
              obj.full_damage = data.allDamage;
              checkoutRoomInven.damage_goods_list.push(obj);
            }
          });
        });
        this.biz_request.checkout_room_inventory_dtos.push(checkoutRoomInven);
      });

    }
  }

  // 判断当前设备是否是ipad
  isPad(): boolean {
    let userAgentInfo = navigator.userAgent;
    let Agents = ["Android", "iPhone",
      "SymbianOS", "Windows Phone",
      "iPad", "iPod"];
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        return true;
      }
    }
    return false;
  }

  // 在ipad上签名
  signOnIpad() {
    let isPopup = true;
    // 页面操作的数据
    let now = +new Date();
    this.checkOutInformationNewdata.checkout_inventory_list_dtos.forEach(ele => {
      if (ele.water_dev || ele.need_water === 'YES') {
        if (!ele.water_reading) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请填写水表读数'));
          isPopup = false;
        }
        if (!RegularExp.numberPointTwo.test(ele.water_reading) && !ele.water_reading) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '水表读数最多保留两位小数'));
          isPopup = false;
        }
        if (!ele.water_reading_time) {
          ele.water_reading_time = now;
        }
      }
      if (ele.hot_water_dev || ele.need_hot_water === 'YES') {
        if (!ele.hot_water_dev && !ele.hot_water_reading) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请填写热水表读数'));
          isPopup = false;
        }
        if (!RegularExp.numberPointTwo.test(ele.hot_water_reading) && !ele.hot_water_reading) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '热水表读数最多保留两位小数'));
          isPopup = false;
        }
        if (!ele.hot_water_reading_time) {
          ele.hot_water_reading_time = now;
        }
      }
      if (ele.electricity_dev || ele.need_electricity === 'YES') {
        if (!ele.electricity_reading && !ele.electricity_reading) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请填写电表读数'));
          isPopup = false;
        }
        if (!RegularExp.numberPointTwo.test(ele.electricity_reading) && !ele.electricity_reading) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '电表读数最多保留两位小数'));
          isPopup = false;
        }
        if (!ele.electricity_reading_time) {
          ele.electricity_reading_time = now;
        }
      }
    });
    // 页面操作数据
    if (isPopup) {
      this.packageReq();
      this.biz_request.offline = false;
      // 操作的数据
      let request_data = Object.assign({}, this.checkOutInformationNewdata);

      let signDataRequest = new SignDataRequest();
      signDataRequest.lease_id = this.paymentleaseId;
      // signDataRequest.room_id = this.room_id;
      // signDataRequest.lease_room_id = this.lease_room_id;

      let request = new SignOnIpadRequest();
      request.sign_router_link_type = 'INVENTORY_LIST';
      request.router_link = this.router.url;
      request.response_data = request_data;
      request.operate_data = this.biz_request;
      request.sign_data_request = signDataRequest;
      request.computer_no = localStorage.getItem('PC_UUID');

      // 打开连接弹窗
      this.signDeviceBindingComponent.openModel(request, 1);
    }
  }

  saveOneInLine() {
    //  签名
    if (this.isPad()) {
      this.tabletComponent.tabletShow();
    } else {
      this.signOnIpad();
    }
  }

  continueRoom(e) {
    if (e === 1) {
      this.active = 2;
    } else if (e === 2) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.TAB_CLOSE_SELF));
      this.router.navigate(['/system_manage/signed/pact', this.paymentleaseId]);
    }

  }

  commitSign(imgStr: string) {
    //发送签名字符串，上传
    this.signName = imgStr;
    this.packageReq();

    this.signedService.checkoutInventoryListConfirmNew(this.biz_request).then(data => {
      this.roomNumber = data;
      this.active = 5;

    });
  }

  goFinanceCheckout() {
    this.workService.event_bus.emit(new WorkModel(this.workService.type.TAB_CLOSE_SELF));
    this.router.navigate(['./../checkout/finance/roomselect', this.leaseDetailResponse.lease_id], {
      relativeTo: this.activatedRoute
    });
  }

  goStep5() {
    this.roomNumber = this.roomList.map(e => e.room_name);
    this.active = 5;
  }

  selectAllChange() {
    if (this.selectAll) {
      this.roomList.forEach(room => {
        room.check = true;
      });
    } else {
      this.roomList.forEach(room => {
        room.check = false;
      });
    }

    this.updateSelectAll();
  }
}
