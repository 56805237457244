import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import Decimal from "decimal.js";
import {CheckoutFinanceRoomListViewResponse, CheckoutRoomDTO} from "../../../../model/signed/checkout.model";
import {SignedService} from "../../../../service/signed/signed.service";

@Component({
  selector: 'app-checkout-record-preview',
  templateUrl: './checkout-record-preview.component.html',
  styleUrls: ['./checkout-record-preview.component.css']
})
export class CheckoutRecordPreviewComponent implements OnInit {

  id;
  checkoutViewResponse: CheckoutFinanceRoomListViewResponse;

  financeType: string;
  type: string;

  constructor(
    private signedService: SignedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(queryParams => {
      this.id = queryParams['record'];

      this.signedService.checkoutRecordPreview(this.id).then(data => {
        this.checkoutViewResponse = data;
        if(this.checkoutViewResponse.refund_info_dto){
          this.type = this.checkoutViewResponse.refund_info_dto.refund_type;
          this.financeType = this.checkoutViewResponse.refund_info_dto.refund_finance_type;
        }
      });
    });
  }

  goLease() {
    this.router.navigate(['../../../pact', this.checkoutViewResponse.lease_info.lease_id], {
      relativeTo: this.activatedRoute
    });
  }

  sumCouponTotal(list: Array<CheckoutRoomDTO>, type?) {
    let total = new Decimal("0");
    if (list != null && list.length > 0) {
      for (let info of list) {
        if (info.check_out_room_info_dto != null && info.check_out_room_info_dto.recycle_items != null
          && info.check_out_room_info_dto.recycle_items.length > 0) {
          for (let coupon of info.check_out_room_info_dto.recycle_items) {
            if (!type || (type && type === coupon.object_name)) {
              total = total.add(new Decimal(coupon.object_value+""));
            }
          }
        }
      }
    }
    return +(total.toString());
  }

}
