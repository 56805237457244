import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {RequestService} from "../request.service";
import {RoomListParam, RoomListResponse} from "../../model/finance/bill.model";

@Injectable()
export class IdempotentService{
  //房间下拉列表
  private SYSTEM_IDEMPOTENT_KEY = `${environment.apiHost}system/idempotent/key`;
  constructor(//引用服务
    private requestService: RequestService) {
  }

  getIdempotentKey(): Promise<any> {
    return this.requestService.authGet(this.SYSTEM_IDEMPOTENT_KEY);
  }
}
