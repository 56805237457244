import { Pipe, PipeTransform } from '@angular/core';
import { BillTypeDTO } from '../../model/finance/finance.model';

/**
 * @Description: 账单类型
 * @Author: 田广野
 * @Date: 2018/4/02 下午11:53
 * @Last Modified by: 田广野
 * @Last Modified time: 2018/4/02 下午11:53
 */
@Pipe({
  name: 'billTypePipe'
})
export class BillTypePipe implements PipeTransform {

  transform(value: any, billTypeGroups: Array<BillTypeDTO>): string {
    let name: string;
    billTypeGroups.forEach(billTypeGroup => {
      let bill = billTypeGroup.bill_types;
      bill.forEach(item => {
        if (item.bill_type_id === value) {
          name = item.bill_type_name;
        }
      });
    });
    return name;
  }
}
