import { EvolveInfo } from "./workbench.model";

/**
 * Created by cyl on 2018/7/11.
 */
export class RiskReqParam {
  pri_type?: string[];
  alert_type?: string;
  alert_status?: string[];
  page_num?: number;
  page_size?: number;

  constructor() {
  }
}

export class RiskInfo {
  alert_id?: number;
  lease_id?:number;
  alert_type?: string;
  title?: string;
  create_time?: number;
  alert_status?: string;
  room_name?: string;
  plan_time?: number;
  first_alert_time?: number;
  last_handle_time?: number;
  last_handler_name?: string;
  contract_num?: string;
  bill_type?: string;
  bill_amount?: string
  contact_name?: string;
  contact_phone?: string;
  evolve_dtos?: EvolveInfo[];

  constructor() {
  }
}

export class RiskInfoList {
  risk_info_dtos?: Array<RiskInfo>;
  total?: number;
}

export class EvolveReqParam {
  alert_id?: number;
  evolve_remark?: string;
  evolve_status?: string;
  alert_type?: string;

  constructor() {
  }
}
