import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {NgSelectModule} from "@ng-select/ng-select";
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {EditorModule} from '@tinymce/tinymce-angular';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {ChartModule} from "angular-highcharts";
import {DragDropModule} from '@angular/cdk/drag-drop';

// echart
import * as echarts from 'echarts';
// echarts baidumap extension
import 'echarts/dist/extension/bmap.min.js';
import {QrCodeModule} from "ng-qrcode";

import {NzIconModule} from "ng-zorro-antd/icon";
import {NzSwitchModule} from "ng-zorro-antd/switch";
import {NzTableModule} from "ng-zorro-antd/table";
import {NzTimePickerModule} from 'ng-zorro-antd/time-picker';
import {NzTreeModule} from "ng-zorro-antd/tree";
import {NzUploadModule} from "ng-zorro-antd/upload";
import {NzNotificationModule} from 'ng-zorro-antd/notification';
import {AccordionModule} from "ngx-bootstrap/accordion";
import {CollapseModule} from "ngx-bootstrap/collapse";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {ModalModule} from "ngx-bootstrap/modal";
import {PopoverModule} from "ngx-bootstrap/popover";
import {ProgressbarModule} from "ngx-bootstrap/progressbar";
import {TabsModule} from "ngx-bootstrap/tabs";
import {TimepickerModule} from "ngx-bootstrap/timepicker";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {NgxCopyPasteModule} from "ngx-copypaste";
import {NgxEchartsModule} from 'ngx-echarts';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgxPrintModule} from "ngx-print";
import {QuillModule} from "ngx-quill";
import {NgScrollbarModule} from "ngx-scrollbar";
import {INgxSelectOptions, NgxSelectModule} from 'ngx-select-ex';
import {AssetGodownStatusPipe} from '../../../core/pipe/AssetGodownStatusPipe';
import {AssetStatusPipe} from '../../../core/pipe/AssetStatusPipe';
import {BillTypePipe} from '../../../core/pipe/bill-type-pipe';
import {BookingStatusPipe} from "../../../core/pipe/booking-status-pipe";
import {CertificatePipe} from '../../../core/pipe/certificate-pipe';
import {CommonPipe} from '../../../core/pipe/common-pipe';
import {CretTypePipe} from "../../../core/pipe/cret-type-pipe";
import {EnumTransferPipe} from '../../../core/pipe/enum-transfer.pipe';
import {FilterListPipe} from "../../../core/pipe/filter-list-pipe";
import {GatherTypePipe} from '../../../core/pipe/gather-type-piper';
import {HtmlPipe} from "../../../core/pipe/html.pipe";
import {InvoiceStatusPipe} from "../../../core/pipe/invoice-status.pipe";
import {LeaseAuditPipe} from "../../../core/pipe/lease-audit-pipe";
import {LeaseLogContent} from '../../../core/pipe/lease-log-content';
import {LeasePipe} from '../../../core/pipe/lease-pipe';
import {MaxLengthPipe} from "../../../core/pipe/max-length.pipe";
import {PrepayItemPipe} from "../../../core/pipe/prepay-item-pipe";
import {RepairStatusPipe} from "../../../core/pipe/repair-status.pipe";
import {RepairTypePipe} from "../../../core/pipe/repair-type-pipe";
import {RoomGroupStatusPipe} from "../../../core/pipe/room-group-status-pipe";
import {RoomIncreaseTypePipe} from "../../../core/pipe/room-increase-ype-pipe";
import {NAV_DROPDOWN_DIRECTIVES} from '../../../directives/nav-dropdown';
import {NUMBER_HANDLER_DIRECTIVE} from "../../../directives/number";
import {SIDEBAR_TOGGLE_DIRECTIVES} from '../../../directives/sidebar';
import {BillGatheringComponent} from "../../carport/bill-gathering/bill-gathering.component";
import {LeaseBillDetailComponent} from "../../carport/lease-bill-detail/lease-bill-detail.component";
import {CarportPayLogDetailComponent} from "../../carport/pay-log-detail/pay-log-detail.component";
import {LeaseRoomBillDetailComponent} from "../../finance/lease-room-bill/lease-room-bill-detail.component";
import {LeaseRoomBillComponent} from "../../finance/lease-room-bill/lease-room-bill.component";
import {PayLogDetailComponent} from "../../finance/pay-log/pay-log-detail.component";
import {BatchPriceSetModalComponent} from '../../lease/batch-price-set-modal/batch-price-set-modal.component';
import {ChooseRoomModalComponent} from '../../lease/choose-room-modal/choose-room-modal.component';
import {CompanyModalComponent} from '../../lease/company-modal/company-modal.component';
import {CustomRentModalComponent} from '../../lease/custom-rent-modal/custom-rent-modal.component';
import {ExpenseModalComponent} from '../../lease/expense-modal/expense-modal.component';
import {LesseeComponent} from "../../lease/lessee/lessee.component";
import {RenterModalComponent} from '../../lease/renter-modal/renter-modal.component';
import {
  RoomgroupRoomRelationDialogComponent
} from '../../newhousesupply/roomgroup-room-relation-dialog/roomgroup-room-relation-dialog.component';
import {SignDeviceBindingComponent} from "../../sign-device-binding/sign-device-binding.component";
import {CrRenterChooseComponent} from "../../signed/change-room/cr-renter-choose/cr-renter-choose.component";
import {CrRoomChooseSetComponent} from "../../signed/change-room/cr-room-choose-set/cr-room-choose-set.component";
import {HandleLogComponent} from "../../signed/handlelog/handlelog.component";
import {TabletComponent} from "../../signed/tablet/tablet.component";
import {NgxImageGalleryModule} from "../../third-party/image-gallery";
import {MyDatePickerModule} from "../../third-party/mydatepicker/src/lib/mydatepicker.module";
import {CustomNzUploadModule} from '../../third-party/upload';
import {ApprovRecordDetailModalComponent} from "../../workspace/approv-record-detail-modal/approv-record-detail-modal.component";
import {ApprovRecordImgComponent} from "../../workspace/approv-record-img/approv-record-img.component";
import {ApprovRecordLogListComponent} from "../../workspace/approv-record-log-list/approv-record-log-list.component";
import {SafeWarnDetailComponent} from '../../workspace/safe-warn-detail/safe-warn-detail.component';
import {DialogComponent} from "../dialog/dialog.component";
import {CheckinInventoryComponent} from "../inventory/CheckinInventory.component";
import {CheckoutInventoryComponent} from "../inventory/checkoutInventory.component";
import {InventoryComponent} from "../inventory/inventory.component";
import {LeaseLogComponent} from '../log/lease-log.component';
import {ComplainModalComponent} from '../modal/complain-modal.component';
import {LoadingModalComponent} from '../modal/loading-modal.component';
import {MessageModalComponent} from '../modal/message-modal.component';
import {NewFileUploadComponent} from '../new-file-upload/new-file-upload.component';
import {PageComponent} from '../page/page.component';
import {PanoramaUploadComponent} from '../panorama/panorama-upload.component';
import {PdfViewComponent} from '../pdf-view/pdf-view.component';
import {PrintPreviewComponent} from '../print-preview/print-preview.component';
import {NzStepsModule} from 'ng-zorro-antd/steps';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzCollapseModule} from "ng-zorro-antd/collapse";
import {NzTagModule} from "ng-zorro-antd/tag";
import {RenterWentDutchBillDetailComponent} from "../../finance/renter-went-dutch-bill/renter-went-dutch-bill-detail.component";
import {DepositReceiptComponent} from "../../finance/renter-went-dutch-bill/deposit-receipt.component";
import {FireVFloorComponent} from "../../monitor/fire-visualization/fire-v-floor/fire-v-floor.component";
import {TextCollapseComponent} from "../text-collapse/text-collapse.component";
import {Options} from "ngx-quill-upload";
import {environment} from "../../../../environments/environment";
import {Constants} from "../../../common/constants";
import {SubPipe} from "../../../core/pipe/sub-pipe";
import {FilterCatePipe} from "../../../core/pipe/filter-cate-pipe";

const APP_DIRECTIVES = [
  NAV_DROPDOWN_DIRECTIVES,
  SIDEBAR_TOGGLE_DIRECTIVES,
  NUMBER_HANDLER_DIRECTIVE,
];

const CustomSelectOptions: INgxSelectOptions = { // Check the interface for more options
  placeholder: '全部',
  noResultsFound: '无此选项',
  allowClear: true,
  keepSelectedItems: false
};


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    // popover
    PopoverModule.forRoot(),

    // loading bar
    LoadingBarModule.forRoot(),
    // tooltip
    TooltipModule.forRoot(),
    // tab
    TabsModule.forRoot(),
    // // Ng-Zorro
    NzTableModule,
    NzUploadModule,
    NzTimePickerModule,
    NzSwitchModule,
    NzTreeModule,
    NzIconModule,
    NzDropDownModule,
    NzSelectModule,
    NzCollapseModule,
    NzTagModule,
    NzNotificationModule,
    // ngx-select
    NgxSelectModule.forRoot(CustomSelectOptions),

    // PAGE
    NgxPaginationModule,
    // DATE PICKER
    MyDatePickerModule,
    NgSelectModule,
    NgxImageGalleryModule,
    // editor
    EditorModule,
    // dropdown
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    AccordionModule.forRoot(),

    // custom
    CustomNzUploadModule,
    NgxExtendedPdfViewerModule,

    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),

    QrCodeModule,
    NgxPrintModule,

    // progress
    ProgressbarModule.forRoot(),

    // copy
    NgxCopyPasteModule,

    // material.angular
    MatInputModule,
    MatButtonModule,
    DragDropModule,

    // scrollbar
    NgScrollbarModule,
    NzStepsModule,
    NgxEchartsModule.forRoot({
      echarts
    }),

    // quill editor
    QuillModule.forRoot({
      placeholder: '请键入文字...',
      modules: {
        syntax: false,
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          // ['blockquote', 'code-block'],

          [{'header': 1}, {'header': 2}],               // custom button values
          [{'list': 'ordered'}, {'list': 'bullet'}],
          [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
          [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
          [{'direction': 'rtl'}],                         // text direction

          [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
          [{'header': [1, 2, 3, 4, 5, 6, false]}],

          [{'color': []}, {'background': []}],          // dropdown with defaults from theme
          [{'font': []}],
          [{'align': []}],
          ['link', 'image'],
          ['clean']                                         // remove formatting button
        ],
        imageHandler: {
          upload: (file) => {
            return new Promise((resolve, reject) => {
              if (file.type === 'image/jpeg'
                || file.type === 'image/png'
                || file.type === 'image/jpg'
                || file.type === 'image/gif') { // File types supported for image
                if (file.size < 5 * 1024 * 1024) { // Customize file size as per requirement

                  const formData = new FormData();
                  formData.append("image", file);
                  formData.append('requestId', new Date().getTime() + '_' + Math.random());
                  formData.append("isNeedWaterMark", 'NOT_NEED_WATER_MARK');
                  formData.append("logoNeedWaterMark", 'NOT_NEED_LEJING_LOGO');

                  return fetch(
                    environment.apiHost + "image/upload/file",
                    {
                      method: "POST",
                      headers: {'Authorization': sessionStorage.getItem(Constants.TOKEN_KEY)},
                      body: formData
                    }
                  )
                    .then(response => response.json())
                    .then(result => {
                      resolve(result.biz_response.image_url);
                    })
                    .catch(error => {
                      reject("上传失败");
                      console.error("Error:", error);
                    });
                } else {
                  reject('图片过大');
                }
              } else {
                reject('不支持的类型');
              }
            });
          },
          accepts: ['png', 'jpg', 'jpeg', 'jfif', 'gif'] // Extensions to allow for images (Optional) | Default - ['jpg', 'jpeg', 'png']
        } as Options,
        // ImageResize: {},
        // blotFormatter: {
        //   // empty object for default behaviour.
        // }
      }
    }),

    // time pciker
    TimepickerModule.forRoot(),

    // ngx-highchart
    ChartModule

  ],
  declarations: [
    // component 需要declare+export
    LoadingModalComponent,
    MessageModalComponent,
    PageComponent,
    NewFileUploadComponent,
    InventoryComponent,
    LeaseRoomBillComponent,
    LeaseRoomBillDetailComponent,
    RenterWentDutchBillDetailComponent,
    DepositReceiptComponent,
    BillGatheringComponent,
    LeaseBillDetailComponent,
    CarportPayLogDetailComponent,
    PayLogDetailComponent,
    CheckinInventoryComponent,
    LesseeComponent,
    DialogComponent,
    CheckoutInventoryComponent,
    CompanyModalComponent,
    ChooseRoomModalComponent,
    CustomRentModalComponent,
    ExpenseModalComponent,
    RenterModalComponent,
    HandleLogComponent,
    PrintPreviewComponent,
    LeaseLogComponent,
    BatchPriceSetModalComponent,
    RoomgroupRoomRelationDialogComponent,
    PdfViewComponent,
    SignDeviceBindingComponent,
    ComplainModalComponent,
    CrRenterChooseComponent,
    CrRoomChooseSetComponent,
    SafeWarnDetailComponent,
    ApprovRecordDetailModalComponent,
    ApprovRecordLogListComponent,
    ApprovRecordImgComponent,
    FireVFloorComponent,
    TextCollapseComponent,

    // PIPE
    EnumTransferPipe,
    CertificatePipe,
    CommonPipe,
    LeasePipe,
    GatherTypePipe,
    LeaseLogContent,
    BillTypePipe,
    RepairStatusPipe,
    RepairTypePipe,
    MaxLengthPipe,
    PrepayItemPipe,
    BookingStatusPipe,
    LeaseAuditPipe,
    AssetStatusPipe,
    AssetGodownStatusPipe,
    CretTypePipe,
    FilterListPipe,
    FilterCatePipe,
    SubPipe,

    // DIRECTIVES
    APP_DIRECTIVES,
    PanoramaUploadComponent,
    InvoiceStatusPipe,
    TabletComponent,
    RoomGroupStatusPipe,
    RoomIncreaseTypePipe,
    HtmlPipe,

  ],
  // 记得导出
  exports: [
    TabletComponent,
    // component
    LoadingModalComponent,
    MessageModalComponent,
    PageComponent,
    NewFileUploadComponent,
    InventoryComponent,
    LeaseRoomBillComponent,
    LeaseRoomBillDetailComponent,
    RenterWentDutchBillDetailComponent,
    DepositReceiptComponent,
    BillGatheringComponent,
    LeaseBillDetailComponent,
    CarportPayLogDetailComponent,
    PayLogDetailComponent,
    CheckinInventoryComponent,
    LesseeComponent,
    DialogComponent,
    CheckoutInventoryComponent,
    CompanyModalComponent,
    ChooseRoomModalComponent,
    CustomRentModalComponent,
    ExpenseModalComponent,
    RenterModalComponent,
    HandleLogComponent,
    PrintPreviewComponent,
    LeaseLogComponent,
    BatchPriceSetModalComponent,
    RoomgroupRoomRelationDialogComponent,
    PdfViewComponent,
    SignDeviceBindingComponent,
    ComplainModalComponent,
    CrRenterChooseComponent,
    CrRoomChooseSetComponent,
    SafeWarnDetailComponent,
    PanoramaUploadComponent,
    ApprovRecordDetailModalComponent,
    ApprovRecordLogListComponent,
    ApprovRecordImgComponent,
    FireVFloorComponent,
    TextCollapseComponent,

    // module
    CommonModule,
    // HTTP
    HttpClientModule,
    // FORM
    FormsModule,
    ReactiveFormsModule,
    // PAGE
    NgxPaginationModule,
    // MODAL
    ModalModule,
    // DATE PICKER
    MyDatePickerModule,
    // popover
    PopoverModule,
    // image preview
    NgxImageGalleryModule,
    // loading bar
    LoadingBarModule,
    // tooltip
    TooltipModule,
    // tab
    TabsModule,
    // dropdown
    BsDropdownModule,
    // // Ng-Zorro
    NzTableModule,
    NzUploadModule,
    NzTimePickerModule,
    NzSwitchModule,
    NzTreeModule,
    NzIconModule,
    NzDropDownModule,
    NzSelectModule,
    NzCollapseModule,
    NzTagModule,
    NzNotificationModule,
    // ngx-select
    NgxSelectModule,
    NgSelectModule,
    // editor
    EditorModule,
    CollapseModule,
    AccordionModule,

    // custom
    CustomNzUploadModule,
    NgxExtendedPdfViewerModule,

    // progress
    ProgressbarModule,

    CalendarModule,
    QrCodeModule,
    // copy
    NgxCopyPasteModule,

    // material.angular
    MatInputModule,
    MatButtonModule,
    DragDropModule,

    // scrollbar
    NgScrollbarModule,

    NgxEchartsModule,
    NzStepsModule,

    // quill editor
    QuillModule,

    // time picker
    TimepickerModule,

    // ngx-highchart
    ChartModule,

    // print
    NgxPrintModule,

    // pipes

    EnumTransferPipe,
    CertificatePipe,
    CommonPipe,
    LeasePipe,
    GatherTypePipe,
    LeaseLogContent,
    BillTypePipe,
    RepairStatusPipe,
    RepairTypePipe,
    MaxLengthPipe,
    PrepayItemPipe,
    BookingStatusPipe,
    LeaseAuditPipe,
    FilterListPipe,
    FilterCatePipe,
    SubPipe,

    // DIRECTIVES
    APP_DIRECTIVES,
    AssetStatusPipe,
    AssetGodownStatusPipe,
    InvoiceStatusPipe,
    CretTypePipe,
    RoomGroupStatusPipe,
    RoomIncreaseTypePipe,
    HtmlPipe,

  ],
  providers: []
})
export class SharedRootModule {
}

