<!-- {{initSomething(batchPriceSetModal)}} -->

<div class="modal fade" bsModal #batchPriceSetModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-static-name" class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>批量更改金额</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-body-style">

        <form [formGroup]="batch_set_form">
          <div class="row">
            <div class="col-12 mb-3">
              <p class="w70px">押金：</p>
              <div class="w150px">
                <input type="text" class="w-100" formControlName="deposit" autocomplete="false">
                <p class="text-danger" *ngIf="isError(is_submit, batch_set_form, 'deposit', 'formatError')">
                  格式不正确(0-200000之间的数字，支持小数点后两位)
                </p>
              </div>
            </div>
            <div *ngIf="wentDutch" class="col-12 mb-3">
              <p class="w70px">住客押金：</p>
              <div class="w150px">
                <input type="text" class="w-100" formControlName="renter_deposit" autocomplete="false">
                <p class="text-danger" *ngIf="isError(is_submit, batch_set_form, 'renter_deposit', 'formatError')">
                  格式不正确(0-200000之间的数字，支持小数点后两位)
                </p>
              </div>
            </div>
            <div class="col-12">
              <p class="w70px">租金：</p>
              <div class="w150px mr-3 mb-3">
                <select class="w-100" formControlName="rent_type" #rentType>
                  <option value="NORMAL">每月固定</option>
                  <option value="MULTI">灵活租金</option>
                </select>
              </div>

              <ng-container *ngIf="rentType.value === 'NORMAL'">
                <div class="w150px mb-3">
                  <input type="text" class="w-100" placeholder="月租金" formControlName="month_rent" autocomplete="false">
                  <p class="text-danger" *ngIf="isError(is_submit, batch_set_form, 'month_rent', 'required')">
                    固定租金不能为空
                  </p>
                  <p class="text-danger" *ngIf="isError(is_submit, batch_set_form, 'month_rent', 'formatError')">
                    格式不正确(0-200000之间的数字)
                  </p>
                </div>
              </ng-container>
            </div>

            <ng-container *ngIf="rentType.value === 'MULTI'">
              <ng-container formArrayName="custom_rent_array">
                <div class="col-12 col-md-6 mb-3" *ngFor="let custom_rent of customRentArray.controls; let h=index;" [formGroupName]="h">
                  <p class="w70px">第{{custom_rent.get('month').value + 1}}月：</p>
                  <div class="w150px">
                    <input type="text" class="w-100" placeholder="月租金" formControlName="custom_rent" autocomplete="false">

                    <p class="text-danger" *ngIf="isError(is_submit, custom_rent, 'custom_rent', 'required')">
                      月租金不能为空
                    </p>
                    <p class="text-danger" *ngIf="isError(is_submit, custom_rent, 'custom_rent', 'formatError')">
                      格式不正确(0-200000之间的数字)
                    </p>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </form>
      </div>
      <div class="modal-footer text-center">
        <button type="button" class="btn btn-primary mr-3" (click)="onSubmit()">确 定</button>
        <button type="button" class="btn btn-secondary" (click)="hide()">取 消</button>
      </div>
    </div>
  </div>
</div>
