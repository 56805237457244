import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {
  CleanerListRequest,
  CleanServiceTypeDTO,
  RepairInfo,
  RepairListRequest,
  RepairOverHoursDTO,
  RepairReturnRequest,
  RepairReturnResponse
} from "../../../../model/room/repair.model";
import {RepairService} from "../../../../service/room/repair.service";
import {IMyDpOptions} from "../../../third-party/mydatepicker/src/lib/interfaces";
import {REPAIR_HANDLE_TYPE, RepairHandleReqParam,} from "../../../../model/workbench/workbench.model";
import {WorkModel} from "../../../../model/common/common.model";
import {WorkbenchService} from "../../../../service/workbench/workbench.service";
import {WorkService} from "../../../../service/common/work.service";
import {AuthService} from "../../../../service/common/auth.service";
import {getTimeFromPlugin} from "../../../../common/date.util";
import {UserService} from "../../../../service/user/user.service";
import {BsModalRef, BsModalService, ModalDirective} from "ngx-bootstrap/modal";
import {ImagePreviewModel} from "../../../../model/image/image-file";

declare var baidutongji: any;


@Component({
  selector: 'app-repair',
  templateUrl: './repair.component.html',
  styleUrls: ['./repair.component.css']
})
export class RepairComponent implements OnInit {
  btn_privileges: any = {
    'REPAIR_QUERY': false,
    'REPAIR_DETAIL': false,
    'CLEAN_DETAIL': false,
    'REPAIR_UPDATE': false,
    'CLEAN_UPDATE': false,
  };

  @ViewChild('template5', {static: true}) template5: ModalDirective;
  @ViewChild('return', {static: true}) return: ModalDirective;
  @ViewChild('showDistrModal', {static: true}) showDistrModal: ModalDirective;

  repairReq: RepairListRequest = {
    page_num: 1,
    page_size: 20,
    repair_param: '',
    type: 'CLEAN',
    urgency_degree: '',
    cleaner: '',
    time_type: 1
  };

  repairUserList: any = [];
  repairActiveUser: any = '';

  isSubmit: boolean = false;
  compensationError: boolean = false;
  // 列表相关
  repairOverHoursDto: Array<RepairOverHoursDTO>;
  repairInfos: Array<RepairInfo>;
  total1: number;

  //列表类型
  type: string = 'CLEAN';

  processingResult: string = 'ALL';
  compensation: string;
  repair_id: number;
  repair_return_memark: string;
  repair_duty: string;
  repairInfo: RepairInfo;
  repairOverHours: RepairOverHoursDTO;
  repairReturnResponse: RepairReturnResponse;

  modalRef: BsModalRef;

  returnRemark: string;
  repairAudit: string;
  repair_type: string;
  repair_type_type: string;
  repair_audit: string;
  plan_fix_time: any = '';
  remark: string;
  evolve_remark: string;
  evolve_status: string = 'IN_HANDLE';
  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
   };
  checkStatus: string = 'ALL';

  status = [
    {name: '未处理', selected: false, id: 'WAITING'},
    {name: '处理中', selected: false, id: 'EFFECTIVE'},
    {name: '处理完成', selected: false, id: 'FIXED'},
    {name: '已拒绝', selected: false, id: 'INVALID'},
    {name: '手动分配', selected: false, id: 'MANUAL'},
    {name: '未分配', selected: false, id: 'UNDELIVER'},
    {name: '无需处理', selected: false, id: 'IGNORE'}
  ];


  reservation_start_time: any;
  reservation_end_time: any;
  workers: any;

  cleanServiceTypeList: Array<CleanServiceTypeDTO> = [];
  cleanServiceType = '';



  constructor(
    private modalService: BsModalService,
    private router: ActivatedRoute,
    private repairService: RepairService,
    private workbenchService: WorkbenchService,
    private workService: WorkService,
    private authService: AuthService,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.authService.getOperatePrivilege(this.btn_privileges);

    this.repairReq.page_num = 1;
    this.repairReq.page_size = 20;
    this.repairReq.repair_param = '';
    this.repairInfos = [];
    this.total1 = 0;

    this.clear1();

    baidutongji("保洁维修管理");
    this.getCleanerList();
    this.getRepairList();

    this.getCleanServiceTypeList();

  }


  // 去除搜索框空格
  trim(str: string): any {
    if (str && str.length > 0) {
      return str.trim();
    }
    return str;
  }

  getRepairList() {
    if (this.reservation_start_time != null && this.reservation_end_time == null) {
      this.workService.showErrorModal("结束时间为空");
      return;
    }
    if (this.reservation_start_time == null && this.reservation_end_time != null) {
      this.workService.showErrorModal("开始时间为空");
      return;
    }
    if (this.reservation_start_time != null && this.reservation_end_time != null) {
      let start_time = getTimeFromPlugin(this.reservation_start_time.formatted);
      let end_time = getTimeFromPlugin(this.reservation_end_time.formatted);
      if (end_time < start_time) {
        this.workService.showErrorModal("结束时间应大于开始时间");
        return;
      }
      this.repairReq.reservation_start_time = start_time;
      this.repairReq.reservation_end_time = end_time;
    }
    // 清空请求的时间数据
    if (this.reservation_start_time == null && this.reservation_end_time == null) {
      delete this.repairReq.reservation_start_time;
      delete this.repairReq.reservation_end_time;
    }

    if (!this.cleanServiceType) {
      delete this.repairReq.clean_service_type;
    } else {
      this.repairReq.clean_service_type = this.cleanServiceType;
    }

    this.repairReq.type = this.type;
    this.repairReq.repair_status_list = [];
    this.status.forEach(item => {
      if (item.selected) {
        this.repairReq.repair_status_list.push(item.id);
      }
    });

    this.repairReq.repair_param = this.trim(
      this.repairReq.repair_param
    );
    this.repairReq.cleaner = this.repairReq.cleaner || '';
    this.repairOverHoursDto = null;
    this.total1 = 0;
    this.repairService
      .getListByTypeWithLoading(this.repairReq)
      .then(data => {
        if (data) {
          this.repairOverHoursDto = data.repair_over_hours;
          this.total1 = data.total;
        } else {
          this.repairOverHoursDto = [];
          this.total1 = 0;
        }
      });
  }

  showReturn(repairOverHours: RepairOverHoursDTO) {
    this.repair_id = repairOverHours.repair_id;
    this.repairOverHours = repairOverHours;

    this.return.show();
  }

  returnSave() {

  }

  insertReturnRemark(bill_id: number) {
    if (this.returnRemark && this.returnRemark.length >= 3 && this.returnRemark.length <= 100) {
      const req = new RepairReturnRequest();
      req.repair_id = bill_id;
      req.repair_duty = this.repairAudit;
      req.repair_return_memark = this.returnRemark;
      this.repairService.insertReturnRemark(req).then(data1 => {
        this.template5.hide();
        this.ngOnInit();
        this.returnRemark = null;
        this.return.hide();
      });

    }
  }

  showRepairModal(repairOverHours: RepairOverHoursDTO) {
    baidutongji("保洁维修查看处理");
    if (repairOverHours.repair_status === 'INVALID' || repairOverHours.repair_status === 'EFFECTIVE') {
      this.repair_type = '查看';
    } else {
      this.repair_type = '';
    }
    if (repairOverHours.repair_type === 'CLEAN') {
      this.repair_type_type = 'CLEAN';
      this.repair_type += '保洁详细信息';
    }
    if (repairOverHours.repair_type === 'REPAIR') {
      this.repair_type_type = 'REPAIR';
      this.repair_type = '维修详细信息';
    }
    this.plan_fix_time = null;
    this.remark = null;
    this.repair_id = repairOverHours.repair_id;
    this.repairOverHours = repairOverHours;
    this.remark = repairOverHours.remark;
    this.template5.show();
  }

  // 复选框点击
  statusClicked1(event: any, index: number) {
    this.status[index].selected = event.target.checked;
  }

  statusSet(event: any) {
    this.status = [
      {name: '未处理', selected: false, id: 'WAITING'},
      {name: '处理中', selected: false, id: 'EFFECTIVE'},
      {name: '处理完成', selected: false, id: 'FIXED'},
      {name: '已拒绝', selected: false, id: 'INVALID'},
      {name: '手动分配', selected: false, id: 'MANUAL'},
      {name: '未分配', selected: false, id: 'UNDELIVER'},
      {name: '无需处理', selected: false, id: 'IGNORE'}
    ];
    if (event.target.value === 'ALL') {
      this.status[0].selected = true;
      this.status[1].selected = true;
      this.status[2].selected = true;
      this.status[3].selected = true;
      this.status[4].selected = true;
      this.status[5].selected = true;
      this.status[6].selected = true;
    }
    if (event.target.value === 'WAITING') {
      this.status[0].selected = true;
    }
    if (event.target.value === 'EFFECTIVE') {
      this.status[1].selected = true;
    }
    if (event.target.value === 'FIXED') {
      this.status[2].selected = true;
    }
    if (event.target.value === 'MANUAL') {
      this.status[4].selected = true;
    }
    if (event.target.value === 'UNDELIVER') {
      this.status[5].selected = true;
    }
    if (event.target.value === 'IGNORE') {
      this.status[6].selected = true;
      this.status[3].selected = true;
    }
  }

  // 搜索
  search1() {
    this.repairReq.page_num = 1;
    this.repairReq.page_size = 20;
    this.getRepairList();
  }

  //下载维修及保洁信息
  downloadRepair(): void {
    if (this.reservation_start_time != null && this.reservation_end_time == null) {
      this.workService.showErrorModal("结束时间为空");
      return;
    }
    if (this.reservation_start_time == null && this.reservation_end_time != null) {
      this.workService.showErrorModal("开始时间为空");
      return;
    }
    if (this.reservation_start_time != null && this.reservation_end_time != null) {
      let start_time = getTimeFromPlugin(this.reservation_start_time.formatted);
      let end_time = getTimeFromPlugin(this.reservation_end_time.formatted);
      if (end_time < start_time) {
        this.workService.showErrorModal("结束时间应大于开始时间");
        return;
      }
      this.repairReq.reservation_start_time = start_time;
      this.repairReq.reservation_end_time = end_time;
    }
    this.repairReq.type = this.type;
    this.repairReq.repair_status_list = [];
    this.status.forEach(item => {
      if (item.selected) {
        this.repairReq.repair_status_list.push(item.id);
      }
    });

    if (!this.cleanServiceType) {
      delete this.repairReq.clean_service_type;
    } else {
      this.repairReq.clean_service_type = this.cleanServiceType;
    }

    let downloadUrl = this.repairService.downloadRepairOrCleanInfo(this.repairReq)
      + '&Authorization=' + this.authService.getAuthToken();
    window.open(downloadUrl, '_self');
  }

  // 清除筛选
  clear1() {
    this.repairReq.repair_param = '';
    this.status.forEach(element => {
      element.selected = true;
    });
    this.checkStatus = 'ALL';
    delete this.repairReq.cleaner;
    this.reservation_start_time = null;
    this.reservation_end_time = null;
    this.repairReq.urgency_degree = '';
    this.cleanServiceType = '';
    delete this.repairReq.clean_service_type;
    this.search1();
  }

  // 分页
  getRepairPage1(currentPage: number) {
    this.repairReq.page_num = currentPage;
    this.getRepairList();
  }

  yanzhengCompensation() {
    if ('ARTIFICIAL_DAMAGE' === this.processingResult) {
      if (!/^\d+(\.\d{0,2})?$/.test(this.compensation)) {
        this.compensationError = true;
        return;
      } else {
        this.compensationError = false;
      }
    }
  }

  repairOk(repairInfo: any, type?: string): void {
    let repairHandleReqParam: RepairHandleReqParam = new RepairHandleReqParam();
    repairHandleReqParam.repair_id = this.repair_id;
    if (type) {
      this.repair_audit = type;
    }
    this.yanzhengCompensation();
    switch (this.repair_audit) {
      case 'CONFIRM':
        repairHandleReqParam.repair_change_type = REPAIR_HANDLE_TYPE.CONFIRM;
        repairHandleReqParam.plan_fix_time = repairInfo.reservation_start_time;
        repairHandleReqParam.repair_id = this.repair_id;
        repairHandleReqParam.reject_reason = this.remark;
        this.workbenchService.handle(repairHandleReqParam).then(data => {
          this.ngOnInit();
        });
        this.template5.hide();
        break;

      case 'REJECT':
        if (this.remark !== null) {
          repairHandleReqParam.repair_change_type = REPAIR_HANDLE_TYPE.REJECT;
          repairHandleReqParam.repair_id = this.repair_id;
          repairHandleReqParam.reject_reason = this.remark;
          this.workbenchService
            .handle(repairHandleReqParam)
            .then(data => {
              this.ngOnInit();
            })
            .catch(err => {
              this.workService.event_bus.emit(
                new WorkModel(this.workService.type.DIALOG_ERROR, err.message)
              );
            });
          this.template5.hide();
        }
        break;

      case 'FINISH':
        repairHandleReqParam.repair_change_type = REPAIR_HANDLE_TYPE.FINISH;
        repairHandleReqParam.repair_id = this.repair_id;
        repairHandleReqParam.reject_reason = this.remark;
        repairHandleReqParam.processing_result = this.processingResult;
        repairHandleReqParam.compensation = this.compensation;
        this.workbenchService.handle(repairHandleReqParam)
          .then(data => {
            this.ngOnInit();
          })
          .catch(err => {
            this.workService.event_bus.emit(
              new WorkModel(this.workService.type.DIALOG_ERROR, err.message)
            );
          });
        this.template5.hide();
        break;
    }
  }

  switch() {
    this.type = this.type === 'CLEAN' ? 'REPAIR' : 'CLEAN';
    this.reservation_start_time = null;
    this.reservation_end_time = null;
    this.repairReq.time_type = 1;
    delete this.repairReq.cleaner;
    this.ngOnInit();
  }

  //照片凭证
  // 预览图片
  openGallery(imgArray: Array<string>, index) {
    this.workService.event_bus.emit(new WorkModel(this.workService.type.IMGFULL_SCREEN_PREVEIEW, new ImagePreviewModel(imgArray, index)));
  }

  // 获取维修工或清洁原
  getCleanerList() {
    let request = new CleanerListRequest();
    request.privilegeCode = this.type === 'CLEAN' ? 'WOS_MMH_000008' : 'WOS_MMH_000004';
    this.repairService.getCleanerList(request).then(data => {
      this.workers = data.sys_user_list;
      this.workers.unshift({user_name: '请选择', id: ''});
    });
  }

  getUserRepair() {
    this.userService.getUserRepair().then(res => {
      if (res.worker_list) {
        this.repairUserList = res.worker_list;
      }
    });
  }

  confirmDistr() {
    if (this.repairActiveUser == this.repairOverHours.worker) {
      return;
    }
    if (!this.repairActiveUser) {
      this.workService.showErrorModal('请选择维修账号!');
      return;
    }
    this.userService.distrUserRepair({repair_id: this.repair_id, worker_id: this.repairActiveUser}).then(res => {
      this.showDistrModal.hide();
      this.template5.hide();
      this.getRepairList();
    });
  }

  distribution(repairId: number) {
    this.repairActiveUser = '';
    this.userService.userSysWorker({privilegeCode: 'WOS_MMH_000004', rest_day: true, repairId: repairId}).then(res => {
      if (res.sys_user_list) {
        this.repairUserList = res.sys_user_list;
      }
      this.showDistrModal.show();
    });
  }

  //打开图片详情
  openRemarkImage(imageUrls: Array<string>, index: number) {
    this.workService.event_bus.emit(new WorkModel(this.workService.type.IMGFULL_SCREEN_PREVEIEW, new ImagePreviewModel(imageUrls, index)));
  }

  getCleanServiceTypeList() {
    this.repairService.getCleanServiceTypeList().then(data => {
      this.cleanServiceTypeList = data.clean_service_type_dtolist;
    });
  }
}
