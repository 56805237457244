import {SignatoryDTO} from "../workbench/workbench.model";

/**
 * @description: 基础枚举类
 * @Author: lubingbin
 * @Date: 2018/3/5
 * @Last Modified by: lubingbin
 * @Last Modified time: 2018/3/5
 */
export class BaseEnum {
  code: any;
  desc: string;

  constructor(code: any, desc: string) {
    this.code = code;
    this.desc = desc;
  }
}

/**
 * @Description: 字典类
 * @Author: 陆炳彬
 * @Date: 2018/7/17 下午2:06
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/17 下午2:06
 */
export class SysDic {
  item_id: number;
  item_code: string;
  item_name: string;
  item_type: string;

  constructor(item_id: number, item_name: string) {
    this.item_id = item_id;
    this.item_name = item_name;
  }
}

/**
 * @description: 省市区对象
 * @Author: lubingbin
 * @Date: 2018/3/14
 * @Last Modified by: lubingbin
 * @Last Modified time: 2018/3/14
 */
export class ProvinceCity {
  id: number;
  type: string;
  name: string;
  parent_id: number;
  open_status: string;
  isHot: boolean;
  sort: number;
}

/**
 * @Description: 省市区请求参数
 * @Author: 陆炳彬
 * @Date: 2018/6/13 下午5:47
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/6/13 下午5:47
 */
export class ProvinceCityReqParam {
  //查询省市父id
  id?: number;
  //查询省市类型
  type: string;

  is_open?: boolean;

  constructor(id: number, type: string, isOpen?: boolean) {
    this.id = id;
    this.type = type;
    this.is_open = isOpen;
  }
}

/**
 * @Description: 省市类型
 * @Author: 陆炳彬
 * @Date: 2018/6/13 下午6:00
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/6/13 下午6:00
 */
export enum ProvinceCityType {
  //省
  PROVINCE = 'PROVINCE',
  //市
  CITY = 'CITY',
  //区
  REGION = 'REGION'
}


export class ExpressDTO {
  id?: number;
  express_name?: string;
  express_no?: string;
}

export class LoginAreaResponse {
  province_dtos?: Array<ProvinceDTO>;
}

export class ProvinceDTO {
  id: number;
  type: string;
  name: string;
  parent_id: number;
  open_status: string;
  is_hot: boolean;
  sort: number;
  city_dtos?: Array<CityDTO>;
}

export class CityDTO {
  id: number;
  type: string;
  name: string;
  parent_id: number;
  open_status: string;
  is_hot: boolean;
  sort: number;
  region_dtos?: Array<CityDTO>;

  active = false;
}

export class RegionDTO {
  id: number;
  type: string;
  name: string;
  parent_id: number;
  open_status: string;
  is_hot: boolean;
  sort: number;
}

export class ProvinceCityDTO {
  id?: number;
  type?: string;
  name?: string;

  parent_id?: number;

  //省市区开通状态（未开通：UNOPEN，已开通：OPENING）
  open_status?: string;
  is_hot?: boolean;
  sort?: number;
}

export class ProvinceCityResponse {
  province_city_list?: Array<ProvinceCityDTO>;
}
