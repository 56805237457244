/**
 * Created by cyl on 2018/7/6.
 */

import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { RequestService } from "../request.service";
import {
  AcceptanceResParam,
  AlertListReqParam,
  AuditDetailReqParam,
  AuditRoomGroupReqParam,
  DataStatInfo,
  DataStatReqParm,
  DiscountInfoList,
  DiscountReqParam,
  DoorLockInfoList,
  DoorLockListReqParam,
  DoorRestInfo,
  DueAlertInfos,
  EvolveReqParam,
  FaultAlertInfos,
  LeaseAuditReqParam,
  LFCReviewResponse,
  NoCheckoutAlertInfos,
  OrderIfRelateResponse,
  RepairHandleReqParam,
  RepairInfoList,
  RepairListReqParam,
  RepairOverHoursDTOList,
  RestPswReqParam,
  RoomPriceList,
  RoomPriceReqParam,
  OrderIfRelateListReqParam,
  NoticeUserRequest,
  NoticeUserListResponse,
  NoticeUserResponse,
  NoticeUserCreateResponse,
  ProjectFileAuditRequest,
  SweepCodeNotPayRequest,
  OrderInfoLogResponse,
  WorkbenchDataResponse,
  WorkbenchWarningStatisticResponse,
  PersonSafeAlarmListResponse,
  PersonSafeAlarmListRequest,
  QueryClientNameRequest,
  QueryClientNameResponse,
  ClientNameCertcodeResponse,
  ClientNameCertcodeRequest,
  ThirdpayPaymentConfirmRequest,
  QueryAccountNameRequest,
  QueryAccountNameResponse,
  NoAuditListRequest,
  NoAuditListResponse,
  PersonSafeAlarmLogsResponse,
  PersonSafeAlarmLogRequest,
  WorkbenchMonthPriceRequest,
  WorkbenchMonthPriceResponse,
  RelevanceRoomParam,
  RelevanceRoomResponse,
  QueryEvolveRequest,
  QueryEvolveResponse,
  AddEvolveRemarkRequest,
  WorkbenchSendEmailRequest,
  WorkbenchSendEmailResponse,
  WorkbenchEmailHistoryRequest,
  WorkbenchEmailHistoryResponse, RoomPriceList1
} from "../../model/workbench/workbench.model";
import { AuditRoomChangeReq } from '../../model/lease/roomchange.model';
import {AuthService} from "../common/auth.service";
import {AuditRoomEditReq} from "../../model/room/room.model";

@Injectable()
export class WorkbenchService {

  private REPAIR_LIST_URL = `${environment.apiHost}backlog/list/repair`;

  private DOORLOCK_LIST_URL = `${environment.apiHost}backlog/list/doorlock`;

  private DISCOUNT_LIST = `${environment.apiHost}backlog/list/discount`;

  private REPAIR_HANDLE = `${environment.apiHost}backlog/handle/repair`;

  private DOOR_LOCK_REST = `${environment.apiHost}backlog/handle/resetpsw`;

  private DOOR_LOCK_ACCEPTANCE = `${environment.apiHost}backlog/handle/acceptance`;

  private LEASE_AUDIT = `${environment.apiHost}backlog/handle/discount`;

  private ROOM_PRICE = `${environment.apiHost}backlog/list/monthprice`;

  private ALERT_LIST = `${environment.apiHost}alert/list`;

  private RELEVANCE_ROOM = `${environment.apiHost}manage/room/relevance`;

  private EVOLVE_HANDLE = `${environment.apiHost}alert/handle`;

  private DATA_STAT = `${environment.apiHost}alert/dataStat`;

  private AUDIT_DETAIL = `${environment.apiHost}backlog/detail/audit`;

  private ROOM_EDIT_AUDIT_DETAIL = `${environment.apiHost}manage/room/edit/audit/detail`;

  private AUDIT_ROOM_GROUP = `${environment.apiHost}backlog/audit/roomgroup`;
  // 车库审批
  private AUDIT_CARPORT = `${environment.apiHost}backlog/handle/carport/discount`;
  // 换房审批
  private AUDIT_ROOM_CHANGE = `${environment.apiHost}manage/lease/room/change/new/discount`;
  // 房间信息修改审批
  private AUDIT_ROOM_EDIT = `${environment.apiHost}manage/room/batch/edit/audit`;

  // 获得未绑定的账单订单信息
  private ORDER_LIST_IF_RELATE = `${environment.apiHost}backlog/order/if/relate`;

  //公告
  private NOTICE_USER_LIST = `${environment.apiHost}noticeUser/find/noticUserList`;
  private NOTICE_USER_DETAIL = `${environment.apiHost}noticeUser/find/noticUserDetail`;
  private NOTICE_USER_INSERT = `${environment.apiHost}noticeUser/insert/insertNoticeUser`;

  //工作台数据
  private WORKBENCH_DATA = `${environment.apiHost}workbench/data`;

  private PROJECT_FILE_AUDIT = `${environment.apiHost}backlog/projectFile/audit`;

  //未支付关联手动关闭
  private CLOSE_ORDER_INFO = `${environment.apiHost}backlog/closeOrderInfo`;

  //未支付关联手动关闭
  private CLOSE_ORDER_INFO_LOGS = `${environment.apiHost}backlog/orderInfo/logList`;

  // 获取数据详情
  private GET_DATA_DETAIL = `${environment.apiHost}workbench/data/detail`;

  private DOWN_LOAD_DATA_DETAIL = `${environment.apiHost}workbench/data/detail/download`;

  // 工作台 安全统计图表
  private GET_WORKBENCH_WARNGING_STATIC = `${environment.apiHost}person/safe/alarmStatistics`;
  // 工作台 安全报警列表
  private GET_WORKBENCH_WARNGING_LIST = `${environment.apiHost}person/safe/alarmList`;
  // 工作台 安全报警 详情
  private GET_WORKBENCH_WARNGING_DETAIL = `${environment.apiHost}person/safe/alarmDetail`;
  // 工作台 安全报警 日志
  private GET_WORKBENCH_WARNGING_LOGS = `${environment.apiHost}person/safe/alarmLogs`;
  // 工作台 安全报警 处理
  private HANDLE_WORKBENCH_WARNGING = `${environment.apiHost}person/safe/processPersonSafeAlarm`;
  // 问题类型列表
  private WORKORDER_PROBLEM_TYPE_LIST = `${environment.apiHost}problemType/typeList`;
  // 所有问题类型列表
  private WORKORDER_PROBLEM_TYPE_ALL_LIST = `${environment.apiHost}problemType/typeAllList`;
  // 工单列表接口
  private WORKORDER_LIST = `${environment.apiHost}workOrder/pageList`;
  // 查看工单详情
  private WORKORDER_DETAILS = `${environment.apiHost}workOrder/details`;
  // 工单修改接口
  private WORKORDER_UPDATE = `${environment.apiHost}workOrder/update`;
  // 工单新增提交接口
  private WORKORDER_SUBMIT = `${environment.apiHost}workOrder/submit`;
  // 工单确认接口
  private WORKORDER_CONFIRM = `${environment.apiHost}workOrder/solve`;
  // 云控工单详情-编辑回显
  private WORKORDER_INFO = `${environment.apiHost}workOrder/info`;
  // 工单取消
  private WORKORDER_CANCEL = `${environment.apiHost}workOrder/cancel`;
  // 查询当前用户待确认工单条数
  private WORKORDER_AWAIT_NUM = `${environment.apiHost}workOrder/waiteConfirmNum`;

  //月均价
  private MONTH_PRICE_LIST = `${environment.apiHost}workbench/data/monthPriceAvg`;


  // 工作台-判断当前账号人脸是否采集
  private GET_SYS_FACE_FLAG = `${environment.apiHost}sysuser/upload/flag`;
  //通过客商名称查询客商信息
  private QUERY_CLIENT_NAME= `${environment.apiHost}manage/clientInfo/query/client/name`;
  //核对客商名称和证件号码是否一致
  private CLIENT_NAME_CERTCODE= `${environment.apiHost}manage/clientInfo/check/client/name/certCode`;
  //工作台线上收款确认收款
  private THIRDPAY_PAYMENT_CONFIRM= `${environment.apiHost}account/money/thirdPay/payment/confirm`;
  //通过客商名称查询客商账户
  private QUERY_ACCOUNT_NAME= `${environment.apiHost}account/money/query/account/name`;
  //线下收款未核销列表
  private NO_AUDIT_LIST= `${environment.apiHost}account/money/transfer/no/audit/list`;
  // 应退未退告警列表下载
  private NO_CHECKOUT_LIST_DOWNLOAD = `${environment.apiHost}alert/list/download/no/checkout`;
  // 合同收租提醒下载
  private DUE_LIST_DOWNLOAD = `${environment.apiHost}alert/list/download/due`;
  private QUERY_EVOLVE_DETAIL = `${environment.apiHost}alert/query/evolve/detail`;
  private ADD_EVOLVE_REMARK = `${environment.apiHost}alert/add/evolve/remark`;
  private WORKBENCH_SEND_EMAIL = `${environment.apiHost}alert/workbench/send/email`;
  private WORKBENCH_EMAIL_HISTORY = `${environment.apiHost}alert/workbench/email/history`;
  constructor(private requestService: RequestService,
              private authService: AuthService) {

  }

  /**
   *获得已绑定账单订单信息
   */
  getOrderIfRelateList(orderIfRelateListReqParam?: OrderIfRelateListReqParam): Promise<OrderIfRelateResponse> {
    return this.requestService.authGet(this.ORDER_LIST_IF_RELATE , orderIfRelateListReqParam);
  }

  getRepairLit(repairListReqParam: RepairListReqParam): Promise<RepairOverHoursDTOList> {
    return this.requestService.authGet(this.REPAIR_LIST_URL, repairListReqParam);
  }

  handle(repairHandleReqParam: RepairHandleReqParam): Promise<boolean> {
    return this.requestService.authPost(this.REPAIR_HANDLE, repairHandleReqParam);
  }

  getDoorLockList(doorLockListReq: DoorLockListReqParam): Promise<DoorLockInfoList> {
    return this.requestService.authGet(this.DOORLOCK_LIST_URL, doorLockListReq);
  }

  restDoorLockPsw(resetReq: RestPswReqParam): Promise<DoorRestInfo> {
    return this.requestService.authPost(this.DOOR_LOCK_REST, resetReq);
  }

  acceptance(acceptanceReq: AcceptanceResParam): Promise<any> {
    return this.requestService.authPost(this.DOOR_LOCK_ACCEPTANCE, acceptanceReq);
  }

  getDueAlertList(alertReq: AlertListReqParam): Promise<DueAlertInfos> {
    return this.requestService.authGet(this.ALERT_LIST, alertReq);
  }

  getFaultAlertList(alertReq: AlertListReqParam): Promise<FaultAlertInfos> {
    return this.requestService.authGet(this.ALERT_LIST, alertReq);
  }

  getRelevanceRoom(roomParam: RelevanceRoomParam): Promise<RelevanceRoomResponse> {
    return this.requestService.authPost(this.RELEVANCE_ROOM, roomParam);
  }

  getNoCheckoutAlertList(alertReq: AlertListReqParam): Promise<NoCheckoutAlertInfos> {
    return this.requestService.authGet(this.ALERT_LIST, alertReq);
  }

  getLFCReviewAlertList(alertReq: AlertListReqParam): Promise<LFCReviewResponse> {
    return this.requestService.authGet(this.ALERT_LIST, alertReq);
  }

  getdelayRefundList(alertReq: AlertListReqParam) {
    return this.requestService.authGet(this.ALERT_LIST, alertReq);
  }

  getFireAlertList(alertReq: AlertListReqParam) {
    return this.requestService.authGet(this.ALERT_LIST, alertReq);
  }

  handleEvolve(evolveReq: EvolveReqParam): Promise<any> {
    return this.requestService.authPost(this.EVOLVE_HANDLE, evolveReq);
  }

  getDiscountList(discountReq: DiscountReqParam): Promise<DiscountInfoList> {
    return this.requestService.authGet(this.DISCOUNT_LIST, discountReq);
  }

  getDataStat(dataReq: DataStatReqParm): Promise<DataStatInfo> {
    return this.requestService.authGet(this.DATA_STAT, dataReq);
  }

  handleLeaseAudit(leaseReq: LeaseAuditReqParam): Promise<any> {
    return this.requestService.authPost(this.LEASE_AUDIT, leaseReq);
  }

  getRoomPrice(priceReq: RoomPriceReqParam): Promise<RoomPriceList1> {
    return this.requestService.authGet(this.ROOM_PRICE, priceReq);
  }

  getAuditDetail(req:AuditDetailReqParam):Promise<any>{
    return this.requestService.authPost(this.AUDIT_DETAIL,req);
  }

  handleRoomGroupAudit(roomReq: AuditRoomGroupReqParam): Promise<any> {
    return this.requestService.authPost(this.AUDIT_ROOM_GROUP, roomReq);
  }
  handleCarportAudit(roomReq: AuditRoomGroupReqParam): Promise<any> {
    return this.requestService.authPost(this.AUDIT_CARPORT, roomReq);
  }
  handleRoomChangeAudit(req: AuditRoomChangeReq): Promise<any> {
    return this.requestService.authPost(this.AUDIT_ROOM_CHANGE, req);
  }

  getNoticeUserList(req: NoticeUserRequest) : Promise<NoticeUserListResponse> {
    return this.requestService.authGet(this.NOTICE_USER_LIST,req);
  }
  getNoticeUserDetail(req: NoticeUserRequest) : Promise<NoticeUserResponse> {
    return this.requestService.authGet(this.NOTICE_USER_DETAIL,req);
  }
  insertNoticeUserDetail(req: NoticeUserRequest) : Promise<NoticeUserCreateResponse> {
    return this.requestService.authPost(this.NOTICE_USER_INSERT,req);
  }

  /**
  * @Description: 获取工作台数据
  * @Author: yangwen
  * @Date: 2019/9/25 11:49
  */
  getWorkbenchData(): Promise<WorkbenchDataResponse> {
    return this.requestService.authGet(this.WORKBENCH_DATA);
  }

  projectFileAudit(req: ProjectFileAuditRequest) : Promise<any> {
    return this.requestService.authPost(this.PROJECT_FILE_AUDIT,req);
  }

  closeOrderInfo(req: SweepCodeNotPayRequest) : Promise<any> {
    return this.requestService.authPost(this.CLOSE_ORDER_INFO,req);
  }
  orderInfoLogList() : Promise<OrderInfoLogResponse> {
    return this.requestService.authGet(this.CLOSE_ORDER_INFO_LOGS,null);
  }

  getDataDetail(request: any) : Promise<any> {
    return this.requestService.authGet(this.GET_DATA_DETAIL, request);
  }

  downLoadDataDetail(type: string, bill_type: any) {
    window.open(this.DOWN_LOAD_DATA_DETAIL + '?Authorization=' + this.authService.getAuthToken() +"&type=" + type+"&bill_type=" + bill_type, "_blank");
  }

  getWorkbenchWarningData(): Promise<WorkbenchWarningStatisticResponse> {
    return this.requestService.authGet(this.GET_WORKBENCH_WARNGING_STATIC);
  }

  getWorkbenchWarningList(request: PersonSafeAlarmListRequest): Promise<PersonSafeAlarmListResponse> {
    return this.requestService.authGet(this.GET_WORKBENCH_WARNGING_LIST, request);
  }


  getSysFaceFlag(): Promise<any> {
    return this.requestService.authGet(this.GET_SYS_FACE_FLAG);
  }
  getWorkbenchWarningLogs(id: number): Promise<PersonSafeAlarmLogsResponse> {
    return this.requestService.authGet(this.GET_WORKBENCH_WARNGING_LOGS, {alarm_id: id});
  }

  handleWorkbenchWarning(request: PersonSafeAlarmLogRequest): Promise<PersonSafeAlarmListResponse> {
    return this.requestService.authPost(this.HANDLE_WORKBENCH_WARNGING, request);
  }

  /**
   * @Description: 审批房间信息修改
   */
  handleRoomEditAudit(req: AuditRoomEditReq): Promise<any> {
    return this.requestService.authPost(this.AUDIT_ROOM_EDIT, req);
  }

  getMonthPriceAvgList(req:WorkbenchMonthPriceRequest): Promise<any>{
    return this.requestService.authGet(this.MONTH_PRICE_LIST,req);
  }

  workOrderProblemtypeList(): Promise<any> {
    return this.requestService.authGet(this.WORKORDER_PROBLEM_TYPE_LIST);
  }
  workOrderProblemtypeAllList(): Promise<any> {
    return this.requestService.authGet(this.WORKORDER_PROBLEM_TYPE_ALL_LIST);
  }
  workOrderList(request): Promise<any> {
    return this.requestService.authGet(this.WORKORDER_LIST, request);
  }
  workOrderDetail(request): Promise<any> {
    return this.requestService.authGet(this.WORKORDER_DETAILS, request);
  }
  workOrderUpdate(request): Promise<any> {
    return this.requestService.authPost(this.WORKORDER_UPDATE, request);
  }
  workOrderSubmit(request): Promise<any> {
    return this.requestService.authPost(this.WORKORDER_SUBMIT, request);
  }
  workOrderConfirm(request): Promise<any> {
    return this.requestService.authPost(this.WORKORDER_CONFIRM, request);
  }
  workOrderInfo(request): Promise<any> {
    return this.requestService.authGet(this.WORKORDER_INFO, request);
  }
  workOrderCancel(request): Promise<any> {
    return this.requestService.authGet(this.WORKORDER_CANCEL, request);
  }
  workOrderAwaitNum(): Promise<any> {
    return this.requestService.authGet(this.WORKORDER_AWAIT_NUM);
  }
  queryClientName(request: QueryClientNameRequest): Promise<QueryClientNameResponse>{
    return this.requestService.authGet(this.QUERY_CLIENT_NAME,request);
  }
  clientNameCertcode(request:ClientNameCertcodeRequest): Promise<ClientNameCertcodeResponse>{
    return this.requestService.authGet(this.CLIENT_NAME_CERTCODE,request);
  }
  thirdpayPaymentConfirm(request:ThirdpayPaymentConfirmRequest): Promise<any>{
    return this.requestService.authPost(this.THIRDPAY_PAYMENT_CONFIRM,request);
  }
  queryAccountName(request:QueryAccountNameRequest): Promise<QueryAccountNameResponse>{
    return this.requestService.authGet(this.QUERY_ACCOUNT_NAME,request);
  }
  noAuditList(request:NoAuditListRequest): Promise<NoAuditListResponse>{
    return this.requestService.authGet(this.NO_AUDIT_LIST,request);
  }

  noCheckoutAlertListDownload(request: AlertListReqParam) {
    let param = "";
    for (let key in request) {
      if (request[key] && request.hasOwnProperty(key)) {
        param += (key + '=' + request[key] + '&');
      }
    }

    if (param) {
      window.open(this.NO_CHECKOUT_LIST_DOWNLOAD + "?" + param + 'Authorization=' + this.authService.getAuthToken(), "_blank");
    }
  }

  dueAlertListDownload(request: AlertListReqParam) {
    let param = "";
    for (let key in request) {
      if (request[key] && request.hasOwnProperty(key)) {
        param += (key + '=' + request[key] + '&');
      }
    }

    if (param) {
      window.open(this.DUE_LIST_DOWNLOAD + "?" + param + 'Authorization=' + this.authService.getAuthToken(), "_blank");
    }
  }

  queryEvolveDetail(request: QueryEvolveRequest): Promise<QueryEvolveResponse>{
    return this.requestService.authGet(this.QUERY_EVOLVE_DETAIL,request);
  }

  addEvolveRemark(request: AddEvolveRemarkRequest) {
    return this.requestService.authPost(this.ADD_EVOLVE_REMARK,request);
  }

  workbenchSendEmail(request: WorkbenchSendEmailRequest): Promise<WorkbenchSendEmailResponse> {
    return this.requestService.authPost(this.WORKBENCH_SEND_EMAIL,request);
  }

  workbenchEmailHistory(request: WorkbenchEmailHistoryRequest): Promise<WorkbenchEmailHistoryResponse> {
    return this.requestService.authGet(this.WORKBENCH_EMAIL_HISTORY,request);
  }
}
