import { Component, EventEmitter, Input, OnInit, Output ,ViewChild} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { limitPriceValidator } from '../../../core/validator/validator.sign';
import {CustomRentInfo, CustomRentInfoWithIndex, RoomListCache} from '../../../model/lease/lease.model';
import { BaseInfoService } from '../../../service/common/base-info.service';
import { ModalDirective } from "ngx-bootstrap/modal";
@Component({
  selector: 'app-custom-rent-modal',
  templateUrl: './custom-rent-modal.component.html',
  styleUrls: ['./custom-rent-modal.component.css']
})
export class CustomRentModalComponent implements OnInit {

  @Input()
  index: number;

  @Input()
  custom_rent_list: CustomRentInfo[];

  @Input()
  canEdit: boolean;

  @Output()
  out_custom_rent_with_index: EventEmitter<CustomRentInfoWithIndex> = new EventEmitter<CustomRentInfoWithIndex>();
  @ViewChild('custom_rent_modal') custom_rent_modal: ModalDirective;
  // 是否提交
  is_submit: boolean = false;

  basePrice: number;

  // 灵活租金弹窗
  // private custom_rent_modal: any;

  // 灵活租金表单
  custom_rent_form_group: UntypedFormGroup = this.fb.group({
    custom_rent_array: this.fb.array([])
  });

  constructor(private fb: UntypedFormBuilder,
              private baseInfoService: BaseInfoService) {
  }

  ngOnInit() {
  }

  resetCustomRentList() {
    this.is_submit = false;
    this.clearCustomRentArray();

    this.custom_rent_list.forEach(item => {
      let custom_rent_item = this.createCustomRent();
      custom_rent_item.patchValue({
        month: item.month,
        custom_rent: item.custom_rent
      });
      this.customRentArray.push(custom_rent_item);
    });
  }

  // 初始化
  // initSomething(modal: any) {
  //   this.custom_rent_modal = modal;
  // }

  // 创建月租金
  createCustomRent() {
    return this.fb.group({
      month: [],
      custom_rent: [, [Validators.required, limitPriceValidator(0, 200000, true)]]
    });
  }

  // 获取灵活租约列表
  get customRentArray(): UntypedFormArray {
    return this.custom_rent_form_group.get('custom_rent_array') as UntypedFormArray;
  }

  // 清空灵活租金
  clearCustomRentArray() {
    if (this.customRentArray) {
      let length = this.customRentArray.length;
      for (let i = length - 1; i >= 0; i--) {
        this.customRentArray.removeAt(i);
      }
    }
  }

  // 灵活租金确定
  confirmCustomRent() {
    this.is_submit = true;
    if (!this.custom_rent_form_group.valid) {
      return;
    }

    this.out_custom_rent_with_index.emit(new CustomRentInfoWithIndex(this.index, this.customRentArray.value));

    this.is_submit = false;
    this.hide();
  }

  show(basePrice?: number) {
    this.basePrice = basePrice;
    this.custom_rent_modal.show();
  }

  hide() {
    this.custom_rent_modal.hide();
  }

  // 是否显示错误信息
  isError(is_submit: boolean, container: any, control_name: string, error_code: string): boolean {
    return this.baseInfoService.isError(is_submit, container, control_name, error_code);
  }

  /**
   * @Description:   阻止向上冒泡
   * @Author: zhoujiahao
   * @Date: 2018-11-02  9:55
   */
  stopP($event) {
    $event.stopPropagation();
  }
}
