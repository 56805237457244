<form [formGroup]="carportInfoForm">

  <div class="setbox-top">
    <p class="bg-primary">
      <i class="fa fa-info-circle"></i>新增车库/位
    </p>
    <div class="setbox">
      <div class="row">
        <div class="col-12">
          添加
          <input class="addcardnumber"  (input)="newNumberInput($event.target.value)" type="number" placeholder="上限20"  maxlength="2" max="20" min="1"/>
          个车库/位
          <button class="btn btn-primary mr20" (click)="addNewRow()">新增</button>
          <button class="btn mr20" (click)="releaseNowRow()">重置</button>
          <!--<button class="btn btn-info mr20">-->
          <!--<i class="anticon anticon-upload"></i><span>上传附件</span>-->
          <!--</button>-->
          <!--<button class="btn p-0" style="color: #36a9e1; background: #fff; border: 1px solid #36a9e1; margin-left: 10px;">下载模板</button>-->
        </div>
        <div class="col-12 mt20" formArrayName="carportFormArrayList">
          <!-- table -->
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th>车库/位编号</th>
              <th>租金(元/月)</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="carportInfoList && carportInfoList.length === 0">
              <td colspan="2">未导入相关数据</td>
            </tr>
            <tr *ngFor="let item of carportInfoList;let i=index;" >
              <td><input placeholder="请输入编号" maxlength="20" (input)="inputCarportNumber($event,i)"/>
                <p style="color: #FF0000;" *ngIf="!item.carport_number">车库/位编号必填</p>
              </td>
              <td><input placeholder="请输入租金" maxlength="10"  (input)="inputMonthPriceStr($event,i)"/>
                <p style="color: #FF0000;" *ngIf="!item.month_price_str">租金必填</p>
                <p style="color: #FF0000;" *ngIf="item.month_price_str && item.month_price_str.match('/^\d+(\.\d{0,2})?$/')">租金格式不正确</p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-primary mr20" (click)="submitCarportAdd()" [disabled]="isSubmitConfirm || (carportInfoList && carportInfoList.length === 0)">提交</button>
          <button class="btn" [routerLink]="['/system_manage/signed/carport/list']">取消</button>
        </div>
      </div>
    </div>
  </div>
</form>



