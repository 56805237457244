<div class="modal fade" bsModal #checkoutRoomView="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>添加损坏物品</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="checkoutRoomView.hide();checkAll=false">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="over10">
          <table class="table table-bordered table-striped">
            <thead>
                <tr>
                    <th>物品名称</th>
                    <th>单价</th>
                    <th>数量</th>
                </tr>
            </thead>
            <tbody>
              <tr>
                <td >
                  <ng-select [(ngModel)]="isGood" [items]="listGoodsNew"(change)="ischange($event)"
                    bindLabel="good_name" bindValue="id"
                    [multiple]="false"
                    placeholder="选择物品"
                    [virtualScroll]="false"
                      >
                  </ng-select>
                </td>
                
                <td>{{price}}{{unit}}</td>
                <td>{{amount}}</td>
              </tr>
              
            </tbody>
            <tr  class="text-danger widthName" *ngIf="selectName" >*请选择物品名称</tr>
            
            <tr class="font-weight-bold loseBad">物品损坏/丢失情况：</tr>
            <thead>
                <tr>
                    <th>部分损坏</th>
                    <th>全部损坏</th>
                    <th>丢失</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                  <td>
                    <input type="text" [(ngModel)]="fewDamage" (change)="changeInput()" (input)="fewDamageInput($event)">件
                    <p class="text-danger" *ngIf="fewDamageNumber">*请正确填写部分损坏数字</p>
                  </td>
                  <td> 
                    <input type="text" [(ngModel)]="allDamage" (change)="changeInput()" (input)="allDamageInput($event)">件
                    <p class="text-danger" *ngIf="allDamageNumber">*请正确填写全部损坏数字</p>
                  </td>
                  <td>
                    <input type="text" [(ngModel)]="loseDamage" (change)="changeInput()" (input)="loseDamageInput($event)">件
                    <p class="text-danger" *ngIf="loseDamageNumber">*请正确填写丢失数字</p>
                  </td>
                </tr>
                <tr class="text-danger" *ngIf="writeLose">*请正确填写物品损坏/丢失情况</tr>
                </tbody>
          </table>
          
          <div class="text-danger" *ngIf="isNumber">*损坏数量不能大于总数量</div>
          <div class="text-danger" >说明：退房完成后可在费用结算时确认赔偿金额</div>
        </div>

        <div class="text-center mb20">

          <button type="button"  (click)="openCheckoutConfirm()" class="btn btn-primary mr20">确定
          </button>
          
          <button type="button" (click)="checkoutRoomView.hide();checkAll=false" class="btn btn-secondary">取消</button>
        </div>
      </div>
    </div>
  </div>
</div>
