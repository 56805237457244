<div class="animated fadeIn ">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class=" card-title">
          <p class="p-info">
            批量导入日常保洁
          </p>

        </div>
        <div class="card-block text-center">
          <div class="row justify-content-center  install-top">
            <div class="col"></div>
            <div class="col">
              <label>1</label>
              <p>上传附件</p>

              <p class="mt-2">
                将下载的保洁任务更新编辑后上传至系统
              </p>
              <div class="mt-2">
                <nz-upload [nzAction]="preview_url" [nzHeaders]="header"
                           (nzChange)="preview($event,tipsModal)"
                           [nzFileList]="fileList"
                           [nzRemove]="removeFile"
                >
                  <button nz-button>
                    <i class="anticon anticon-upload"></i><span>上传附件</span>
                  </button>
                </nz-upload>
              </div>

            </div>
            <div class="col">
              <hr>
            </div>
            <div class="col">
              <label>2</label>
              <p>确认数据录入完成</p>

              <p class="mt-2">
                确认保洁信息无误后点击下方按钮完成批量更新
              </p>
              <div class="mt-2" style="width: 200px">
                <button type="button" class="btn btn-default mr-3 d-inline-block" (click)="fileList=[];list=[]"
                        [disabled]="fileList && fileList.length < 1">
                  清空上传
                </button>
                <button type="button" class="btn btn-info mr-3 d-inline-block" (click)="ensureUpload()"
                        [disabled]="(fileList && fileList.length < 1) || (resp && resp.err_count>0)">
                  确认并添加
                </button>
              </div>
            </div>
            <div class="col"></div>
          </div>
          <div class="mt-4 table-overflow" style="height: 500px">

            <table class="table table-striped v2">
              <thead>
              <tr>
                <td>序号</td>
                <td>项目名称</td>
                <td>合同周期</td>
                <td>入住时间</td>
                <td>上次保洁日期</td>
                <td>楼栋</td>
                <td>房间号</td>
                <td>应保洁时间</td>
                <td><span class="text-danger">*</span>保洁状态</td>
                <td>保洁完成日期</td>
                <td>延期至(日期)</td>
                <td>备注信息</td>
                <td>错误信息</td>
              </tr>
              </thead>
              <tbody *ngIf="list && list.length>0">
              <tr *ngFor="let item of list; let i = index">
                <td>{{i + 1}}</td>
                <td>{{item?.project_name}}</td>
                <td>{{item?.project_duration}}</td>
                <td>{{item?.checkin_time}}</td>
                <td>{{item?.last_clean_time}}</td>
                <td>{{item?.unit_name}}</td>
                <td>{{item?.room_name}}</td>
                <td>{{item?.deal_time}}</td>
                <td>{{item?.status_str}}</td>
                <td>{{item?.finish_time_str}}</td>
                <td>{{item?.delay_time_str}}</td>
                <td>{{item?.remark}}</td>
                <td class="text-danger">{{item?.err}}</td>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" bsModal #tipsModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" keyboard="false"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="tipsModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body" style="padding: 20px;max-height: 300px;overflow-y: auto;">
        <div class="pl-3">
          {{content}}
        </div>
        <div class="pl-3" *ngFor="let error of errorList">{{error}}</div>
      </div>
      <div class="modal-footer">
        <div class="col-lg-12 mt-4 text-center">
          <button (click)="tipsModal.hide()" class="btn btn-primary btn-info">
            关闭
          </button>&nbsp;&nbsp;
        </div>
      </div>
    </div>
  </div>
</div>

