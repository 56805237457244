<div class="modal fade" bsModal #depositReceiptModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>打印预览</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 flex">
            <img [src]="imgUrl">
          </div>
        </div>
        <div class="text-center" style="margin-top:20px;">
          <button printSectionId="print_div" ngxPrint class="btn btn-primary mr20"><i class="fa fa-print"></i>打印</button>
          <button type="button" class="btn btn-secondary" (click)="closeModal()">关闭</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div style="display: none">
  <div #print_div id="print_div">
    <img [src]="imgUrl">
  </div>
</div>



