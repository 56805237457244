import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'filterlist'
})
export class FilterListPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(!args)
      return value;
    return value.filter(
      item => item.user_name.toLowerCase().indexOf(args.toLowerCase()) > -1
    );
  }
}
