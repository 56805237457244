import { Pipe, PipeTransform } from "@angular/core";

/**
 * @Description: 预付账户 项目
 * @Author: 徐礼华
 * @Date: 2018/3/29 上午10:26
 * @Last Modified by: 徐礼华
 * @Last Modified time: 2018/3/29 上午10:26
 */
@Pipe({
  name: 'prepayItemPipe'
})
export class PrepayItemPipe implements PipeTransform {

  transform(type: any, service: any): any {
    switch (type) {
      case 'CONSUME':
        if (service && service === 'ELECTRICITY') {
          return '电费账单';
        } else {
          return '水费账单';
        }
      case 'RECHARGE':
        return '充值';
      case 'WITHDRAW_DEPOSIT':
        return '提现';
      default:
        break;
    }
  }
}
