import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {RequestService} from "../request.service";
import {AuthService} from "../common/auth.service";
import {
  ChangeCommunitySortRequest,
  CityAndAreaResponse,
  CommunityAllResponse,
  CommunitySelectTabResponse,
  CommunityStatusManageRequest,
  CustomProjectDetailRequest,
  CustomProjectDetailResponse,
  CustomProjectEditRequest,
  CustomProjectListRequest,
  CustomProjectListResponse,
  CustomRoomEditRequest,
  CustomRoomListRequest,
  CustomRoomListResponse,
  CustomRoomTypeDetailRequest,
  CustomRoomTypeDetailResponse, CustomSubscribeChangeStatusRequest,
  CustomSubscribeDetailRequest,
  CustomSubscribeDetailResponse,
  CustomSubscribeListRequest,
  CustomSubscribeListResponse, ProjectAddressFeatureRequest, ProjectAddressFeatureResponse,
  ProjectAddressLocationRequest,
  ProjectAddressLocationResponse,
  PromoteFacilityInfoRequest,
  PromoteFacilityInfoResponse,
  RelatedRoomCommunityRequest,
  RoomStatusManageRequest,
  RoomTypeListRequest,
  RoomTypeListResponse
} from "../../model/weixinminiprogram/custom-project-manage.model";

@Injectable()
export class CustomProjectManageService{
  private CUSTOM_PROJECT_LIST = `${environment.apiHost}custom/project/manage/list`;
  private CUSTOM_PROJECT_LIST_DOWNLOAD = `${environment.apiHost}custom/project/manage/list/download`;
  private UP_DOWN_COMMUNITY = `${environment.apiHost}custom/project/manage/up/or/down/community`;
  private CHANGE_COMMUNITY_SORT = `${environment.apiHost}custom/project/manage/change/community/sort`;
  private QUERY_CITY_AND_AREA = `${environment.apiHost}custom/project/manage/city/area`;
  private QUERY_PROMOTE_FACILITY_INFOS = `${environment.apiHost}custom/project/manage/promotes/facility/info`;
  private QUERY_COMMUNITY_SELECT_TAB = `${environment.apiHost}custom/project/manage/community/select/tab`;
  private CREATE_OR_EDIT = `${environment.apiHost}custom/project/manage/create/edit/project`;
  private CUSTOM_PROJECT_DETAIL = `${environment.apiHost}custom/project/manage/detail`;
  private BAIDU_MAP_ADDRESS_LOCATION = `${environment.apiHost}custom/project/manage/address/location`;
  private BAIDU_MAP_ADDRESS_FEATURE = `${environment.apiHost}custom/project/manage/address/feature`;
  private CUSTOM_ROOM_LIST = `${environment.apiHost}custom/room/manage/list`;
  private CUSTOM_ROOM_LIST_DOWNLOAD = `${environment.apiHost}custom/room/manage/list/download`;
  private CREATE_OR_EDIT_ROOM = `${environment.apiHost}custom/room/manage/create/or/edit`;
  private CUSTOM_ROOM_DETAIL = `${environment.apiHost}custom/room/manage/detail`;
  private UP_DOWN_ROOM = `${environment.apiHost}custom/room/manage/up/or/down/room`;
  private QUERY_ROOM_TYPE_LIST = `${environment.apiHost}custom/room/manage/related/room/list`;
  private RELATED_ROOM_AND_COMMUNITY = `${environment.apiHost}custom/room/manage/related/room/community`;
  private QUERY_COMMUNITY_ALL = `${environment.apiHost}custom/project/manage/community/all`;
  private CUSTOM_SUBSCRIBE_LIST = `${environment.apiHost}custom/subscribe/manage/list`;
  private CUSTOM_SUBSCRIBE_LIST_DOWNLOAD = `${environment.apiHost}custom/subscribe/manage/list/download`;
  private CUSTOM_SUBSCRIBE_DETAIL = `${environment.apiHost}custom/subscribe/manage/detail`;
  private CUSTOM_SUBSCRIBE_CHANGE_STATUS = `${environment.apiHost}custom/subscribe/manage/change/status`;
  constructor(private requestService: RequestService,
              private authService: AuthService) {
  }

  public customProjectList(request: CustomProjectListRequest): Promise<CustomProjectListResponse>{
    return this.requestService.authGet(this.CUSTOM_PROJECT_LIST,request);
  }

  public downloadCustomProjectList(request: CustomProjectListRequest) {
    let param = "";
    for (let key in request) {
      if (request[key] && request.hasOwnProperty(key)) {
        param += (key + '=' + request[key] + '&');
      }
    }

    if (param) {
      window.open(this.CUSTOM_PROJECT_LIST_DOWNLOAD + "?" + param + 'Authorization=' + this.authService.getAuthToken(), "_blank");
    } else {
      window.open(this.CUSTOM_PROJECT_LIST_DOWNLOAD + '?Authorization=' + this.authService.getAuthToken(), "_blank");
    }
  }

  public upOrDownCommunity(request: CommunityStatusManageRequest) {
    return this.requestService.authPost(this.UP_DOWN_COMMUNITY,request);
  }

  public changeCommunitySort(request: ChangeCommunitySortRequest) {
    return this.requestService.authPost(this.CHANGE_COMMUNITY_SORT,request);
  }

  public queryCityAndArea(): Promise<CityAndAreaResponse> {
    return this.requestService.authGet(this.QUERY_CITY_AND_AREA);
  }

  public queryPromoteFacilityInfos(request: PromoteFacilityInfoRequest): Promise<PromoteFacilityInfoResponse> {
    return this.requestService.authGet(this.QUERY_PROMOTE_FACILITY_INFOS, request);
  }

  public queryCommunitySelectTab(): Promise<CommunitySelectTabResponse> {
    return this.requestService.authGet(this.QUERY_COMMUNITY_SELECT_TAB);
  }

  public queryBaiduMapAddressLocation(request: ProjectAddressLocationRequest): Promise<ProjectAddressLocationResponse> {
    return this.requestService.authGet(this.BAIDU_MAP_ADDRESS_LOCATION, request);
  }

  public createOrEdit(request: CustomProjectEditRequest) {
    return this.requestService.authPost(this.CREATE_OR_EDIT,request);
  }

  public customProjectDetail(request: CustomProjectDetailRequest): Promise<CustomProjectDetailResponse>{
    return this.requestService.authGet(this.CUSTOM_PROJECT_DETAIL, request);
  }

  public customRoomList(request: CustomRoomListRequest): Promise<CustomRoomListResponse>{
    return this.requestService.authGet(this.CUSTOM_ROOM_LIST,request);
  }

  public createOrEditRoom(request: CustomRoomEditRequest) {
    return this.requestService.authPost(this.CREATE_OR_EDIT_ROOM, request);
  }

  public customRoomTypeDetail(request: CustomRoomTypeDetailRequest): Promise<CustomRoomTypeDetailResponse> {
    return this.requestService.authGet(this.CUSTOM_ROOM_DETAIL, request);
  }

  public queryRoomTypeList(request: RoomTypeListRequest): Promise<RoomTypeListResponse> {
    return this.requestService.authGet(this.QUERY_ROOM_TYPE_LIST, request);
  }

  public relatedRoomAndCommunity(request: RelatedRoomCommunityRequest) {
    return this.requestService.authPost(this.RELATED_ROOM_AND_COMMUNITY, request);
  }

  public queryCommunityAll() : Promise<CommunityAllResponse>{
    return this.requestService.authGet(this.QUERY_COMMUNITY_ALL);
  }

  public upOrDownRoomType(request: RoomStatusManageRequest) {
    return this.requestService.authPost(this.UP_DOWN_ROOM, request);
  }

  customSubscribeList(request: CustomSubscribeListRequest): Promise<CustomSubscribeListResponse>{
    return this.requestService.authGet(this.CUSTOM_SUBSCRIBE_LIST,request);
  }

  customSubscribeDetail(request: CustomSubscribeDetailRequest): Promise<CustomSubscribeDetailResponse>{
    return this.requestService.authGet(this.CUSTOM_SUBSCRIBE_DETAIL, request);
  }

  customSubscribeChangeStatus(request: CustomSubscribeChangeStatusRequest) {
    return this.requestService.authPost(this.CUSTOM_SUBSCRIBE_CHANGE_STATUS, request);
  }

  queryBaiduMapAddressFeature(request: ProjectAddressFeatureRequest): Promise<ProjectAddressFeatureResponse>{
    return this.requestService.authGet(this.BAIDU_MAP_ADDRESS_FEATURE, request);
  }

  customSubscribeDownloadList(request: CustomSubscribeListRequest) {
    let param = "";
    for (let key in request) {
      if (request[key] && request.hasOwnProperty(key)) {
        param += (key + '=' + request[key] + '&');
      }
    }

    if (param) {
      window.open(this.CUSTOM_SUBSCRIBE_LIST_DOWNLOAD + "?" + param + 'Authorization=' + this.authService.getAuthToken(), "_blank");
    } else {
      window.open(this.CUSTOM_SUBSCRIBE_LIST_DOWNLOAD + '?Authorization=' + this.authService.getAuthToken(), "_blank");
    }
  }

  customRoomDownloadList(request: CustomRoomListRequest) {
    let param = "";
    for (let key in request) {
      if (request[key] && request.hasOwnProperty(key)) {
        param += (key + '=' + request[key] + '&');
      }
    }

    if (param) {
      window.open(this.CUSTOM_ROOM_LIST_DOWNLOAD + "?" + param + 'Authorization=' + this.authService.getAuthToken(), "_blank");
    } else {
      window.open(this.CUSTOM_ROOM_LIST_DOWNLOAD + '?Authorization=' + this.authService.getAuthToken(), "_blank");
    }
  }
}
