import {UntypedFormControl, ValidatorFn} from "@angular/forms";
import {getTimeFromPlugin} from "../../common/date.util";
import {trim} from "../../common/common";

/**
 * @Description: 新签约验证器
 * @Author: 陆炳彬
 * @Date: 2018/7/12 上午11:03
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/12 上午11:03
 */

/**
 * @Description: 价格限制
 * @Author: 陆炳彬
 * @Date: 2018/7/13 下午5:18
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/13 下午5:18
 */
export function limitPriceValidator(min: number, max: number, is_digtal: boolean): ValidatorFn {
  return (control: UntypedFormControl) => {

    let value = control.value ? control.value.toString() : control.value;

    if (is_digtal) {
      if (value != null) {
        value = value + '';
        if (value.split(".").length <= 2 && value.substring(value.length - 1, value.length) !== "."
          && Number.parseFloat(value) >= min && Number.parseFloat(value) <= max) {
          if (value.split(".").length === 2 && value.split(".")[1].length > 2
            || (value.substring(0, 1) === "0" && value.length > 1 && value.substring(1, 2) !== ".")) {
            return {formatError: true};
          }
          return null;
        } else {
          return {formatError: true};
        }
      } else {
        return {formatError: true};
      }
    } else {
      let reg = new RegExp(/^(-)?\d+$/);
      if (value != null) {
        if (!reg.test(value) || value < min || value > max) {
          return {formatError: true};
        }
      } else {
        return {formatError: true};
      }
    }
    return null;
  };

}

export function renterDepositCheck(min: number, max: number, is_digtal: boolean, is_check: boolean): ValidatorFn {
  return (control: UntypedFormControl) => {

    let value = control.value ? control.value.toString() : control.value;
    if (is_check) {
      if (is_digtal) {
        if (value != null && value.split(".").length <= 2 && value.substring(value.length - 1, value.length) !== "."
          && Number.parseFloat(value) >= min && Number.parseFloat(value) <= max) {
          if (value.split(".").length === 2 && value.split(".")[1].length > 2
            || (value.substring(0, 1) === "0" && value.length > 1 && value.substring(1, 2) !== ".")) {
            return {formatError: true};
          }
          return null;
        } else {
          return {formatError: true};
        }
      } else {
        let reg = new RegExp(/^(-)?\d+$/);
        if (value) {
          if (!reg.test(value) || value < min || value > max) {
            return {formatError: true};
          }
        }
      }
    }
    return null;
  };
}

export function renterDepositLimitCheck(is_check: boolean): ValidatorFn {
  return (control: UntypedFormControl) => {

    let value = control.value ? control.value.toString() : control.value;
    if (is_check) {

    }
    return null;
  };
}

/**
 * @Description: 名称验证器
 * @Author: 陆炳彬
 * @Date: 2018/7/13 下午5:19
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/13 下午5:19
 */
export function nameValidator(control: UntypedFormControl): any {
  let name = control.value;

  if (name) {
    if (name.length < 2 || name.length > 20) {
      return {formatError: true};
    }
  }
  return null;
}

/**
 * @Description: 签约主体为个人 名字校验器 (2-20字符)
 * @Author: 陆炳彬
 * @Date: 2018/7/12 上午11:03
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/12 上午11:03
 */
export function personInfoNameValidator(control: UntypedFormControl): any {
  let person_info_form_group = control.parent;

  if (person_info_form_group) {
    let subject_type = person_info_form_group.parent.get('subject_type').value;
    if (subject_type === 'PERSON') {
      let name = person_info_form_group.get('name').value;
      if (!name || name.length === 0) {
        return {required: true};
      }

      if (name.length < 2 || name.length > 20) {
        return {formatError: true};
      }
    }
  }
  return null;
}

/**
 * @Description: 证件类型校验
 * @Author: 陆炳彬
 * @Date: 2018/7/12 上午11:16
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/12 上午11:16
 */
export function personInfoCretValueValidator(isBooking: boolean): ValidatorFn {
  return (control: UntypedFormControl): any => {
    let ID_CARD_REG = /^[A-Za-z0-9]{18}$/;
    let OTHER_REG = /^[A-Za-z0-9]{6,20}$/;

    let person_info_form_group = control.parent;

    if (person_info_form_group) {
      let subject_type = person_info_form_group.parent.get('subject_type').value;
      if (subject_type === 'PERSON') {
        let cret_type = person_info_form_group.get('cret_type').value;
        let cret_num = person_info_form_group.get('cret_num').value;
        if (!isBooking && (!cret_num || cret_num.length === 0)) {
          return {required: true};
        }

        if (cret_num) {
          if (cret_type === 'ID_CD') {
            return ID_CARD_REG.test(cret_num) ? null : {formatError: true};
          } else {
            return OTHER_REG.test(cret_num) ? null : {formatError: true};
          }
        }
      }
    }
    return null;
  };
}

/**
 * @Description: 个人信息电话校验
 * @Author: 陆炳彬
 * @Date: 2018/7/12 上午11:27
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/12 上午11:27
 */
export function personInfoTelValidator(control: UntypedFormControl): any {
  let TEL_REG = /^[-|0-9|\s|*]{8,20}$/;
  let person_info_form_group = control.parent;

  if (person_info_form_group) {
    let subject_type = person_info_form_group.parent.get('subject_type').value;
    if (subject_type === 'PERSON') {
      let tel = person_info_form_group.get('tel').value;
      if (!tel || tel.length === 0) {
        return {required: true};
      }

      return TEL_REG.test(tel) ? null : {formatError: true};
    }
  }

  return null;
}

/**
 * @Description: 个人信息邮编校验
 * @Author: 陆炳彬
 * @Date: 2018/7/12 上午11:37
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/12 上午11:37
 */
export function personInfoZipValidator(control: UntypedFormControl): any {
  let ZIP_REG = /^[0-9]{6}$/;
  let person_info_form_group = control.parent;

  if (person_info_form_group) {
    let subject_type = person_info_form_group.parent.get('subject_type').value;
    if (subject_type === 'PERSON') {
      let zip = person_info_form_group.get('zip').value;
      if (zip && zip.length > 0) {
        return ZIP_REG.test(zip) ? null : {formatError: true};
      }
    }
  }
  return null;
}

/**
 * @Description: 地址校验
 * @Author: 陆炳彬
 * @Date: 2018/11/22 下午2:25
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/11/22 下午2:25
 */
export function addressValidator(control: UntypedFormControl): any {
  let form_group = control.parent;
  if (form_group) {
    let address = form_group.get('address').value;
    if (address && (address.length < 1 || address.length > 50)) {
      return {formatError: true};
    }
  }
  return null;
}

export function addressValidator2(control: UntypedFormControl): any {
  let form_group = control.parent;
  if (form_group) {
    let address = form_group.get('company_address').value;
    let invoice_type = form_group.get('invoice_type').value;
    if (invoice_type === 'VAT_INVOICE') {
      if (!address || address.length === 0) {
        return {required: true};
      }
    }
    if (address && (address.length < 1 || address.length > 50)) {
      return {formatError: true};
    }
  }
  return null;
}

export function addressValidator3(control: UntypedFormControl): any {
  let form_group = control.parent;
  if (form_group) {
    let address = form_group.get('company_address').value;
    let invoice_type = form_group.get('invoice_type').value;
    if (invoice_type === 'VAT_INVOICE') {
      if (!address || address.length === 0) {
        return {required: true};
      }
    }
    if (address && (address.length < 1 || address.length > 100)) {
      return {formatError: true};
    }
  }
  return null;
}

/**
 * 业态
 * @param control
 */
export function tradeValidator(control: UntypedFormControl): any {
  let form_group = control.parent;
  if (form_group) {
    let trade = form_group.get('trade').value;
    if (!trade || trade.length === 0) {
      return {required: true};
    }
    if (trade && (trade.length < 1 || trade.length > 50)) {
      return {formatError: true};
    }
  }
  return null;
}

/**
 * 品牌
 * @param control
 */
export function brandValidator(control: UntypedFormControl): any {
  let form_group = control.parent;
  if (form_group) {
    let brand = form_group.get('brand').value;
    if (!brand || brand.length === 0) {
      return {required: true};
    }
    if (brand && (brand.length < 1 || brand.length > 50)) {
      return {formatError: true};
    }
  }
  return null;
}

/**
 * 紧急联系人姓名
 * @param control
 */
export function emergentContractNameValidator(control: UntypedFormControl): any {
  let form_group = control.parent;
  if (form_group) {
    let emergentContactName = form_group.get('emergency_contact_name').value;
    if (!emergentContactName || emergentContactName.length === 0) {
      return {required: true};
    }
    if (emergentContactName && (emergentContactName.length < 2 || emergentContactName.length > 20)) {
      return {formatError: true};
    }
  }
  return null;
}

/**
 * 紧急联系人电话
 *
 * @param control
 */
export function emergentContractPhoneValidator(control: UntypedFormControl): any {
  let TEL_REG = /^[-|0-9|\s|*]{8,20}$/;
  let form_group = control.parent;
  if (form_group) {
    let emergentContactPhone = form_group.get('emergency_contact_phone').value;
    if (!emergentContactPhone || emergentContactPhone.length === 0) {
      return {required: true};
    }
    return TEL_REG.test(emergentContactPhone) ? null : {formatError: true};
  }
  return null;
}

/**
 * 业态
 * @param control
 */
export function companyInfoTradeValidator(control: UntypedFormControl): any {
  let form_group = control.parent;
  if (form_group) {
    let trade = form_group.get('trade').value;
    if (!trade || trade.length === 0) {
      return {required: true};
    }
    if (trade && (trade.length < 1 || trade.length > 50)) {
      return {formatError: true};
    }
  }
  return null;
}

/**
 * 品牌
 * @param control
 */
export function companyInfoBrandValidator(control: UntypedFormControl): any {
  let form_group = control.parent;
  if (form_group) {
    let brand = form_group.get('brand').value;
    if (!brand || brand.length === 0) {
      return {required: true};
    }
    if (brand && (brand.length < 1 || brand.length > 50)) {
      return {formatError: true};
    }
  }
  return null;
}

/**
 * 紧急联系人姓名
 * @param control
 */
export function CompanyEmergentContractNameValidator(control: UntypedFormControl): any {
  let form_group = control.parent;
  if (form_group) {
    let emergentContactName = form_group.get('emergency_contact_name').value;
    if (!emergentContactName || emergentContactName.length === 0) {
      return {required: true};
    }
    if (emergentContactName && (emergentContactName.length < 2 || emergentContactName.length > 20)) {
      return {formatError: true};
    }
  }
  return null;
}

/**
 * 紧急联系人电话
 *
 * @param control
 */
export function CompanyEemergentContractPhoneValidator(control: UntypedFormControl): any {
  let TEL_REG = /^[-|0-9|\s|*]{8,20}$/;
  let form_group = control.parent;
  if (form_group) {
    let emergentContactPhone = form_group.get('emergency_contact_phone').value;
    if (!emergentContactPhone || emergentContactPhone.length === 0) {
      return {required: true};
    }
    return TEL_REG.test(emergentContactPhone) ? null : {formatError: true};
  }
  return null;
}


/**
 * @Description: 企业信息企业名校验
 * @Author: 陆炳彬
 * @Date: 2018/7/12 上午11:57
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/12 上午11:57
 */
export function companyInfoCompanyNameValidator(control: UntypedFormControl): any {
  let company_info_form_group = control.parent;

  if (company_info_form_group) {
    let subject_type = company_info_form_group.parent.get('subject_type').value;
    if (subject_type === 'COMPANY') {
      let company_name = company_info_form_group.get('company_name').value;
      if (!company_name || company_name.length === 0) {
        return {required: true};
      }

      if (company_name.length < 1 || company_name.length > 50) {
        return {formatError: true};
      }
    }
  }
  return null;
}

export function companyInfoCompanyNameValidatorForShop(control: UntypedFormControl): any {
  let company_info_form_group = control.parent;

  if (company_info_form_group) {
    let subject_type = company_info_form_group.parent.get('subject_type').value;
    if (subject_type === 'COMPANY') {
      let company_name = company_info_form_group.get('company_name').value;
      if (!company_name || company_name.length === 0) {
        return {required: true};
      }

      if (company_name.length < 1 || company_name.length > 100) {
        return {formatError: true};
      }
    }
  }
  return null;
}

/**
 * @Description: 企业信息联系人校验
 * @Author: 陆炳彬
 * @Date: 2018/7/12 上午11:59
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/12 上午11:59
 */
export function companyInfoContactsValidator(control: UntypedFormControl): any {
  let company_info_form_group = control.parent;
  if (company_info_form_group) {
    let subject_type = company_info_form_group.parent.get('subject_type').value;
    if (subject_type === 'COMPANY') {
      let contacts = company_info_form_group.get('contacts').value;
      if (!contacts || contacts.length === 0) {
        return {required: true};
      }

      if (contacts.length < 2 || contacts.length > 20) {
        return {formatError: true};
      }
    }
  }
  return null;
}

/**
 * @Description: 企业信息电话校验
 * @Author: 陆炳彬
 * @Date: 2018/7/12 下午12:00
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/12 下午12:00
 */
export function companyInfoTelValidator(control: UntypedFormControl): any {
  let TEL_REG = /^[-|0-9|\s|*]{8,20}$/;
  let company_info_form_group = control.parent;

  if (company_info_form_group) {
    let subject_type = company_info_form_group.parent.get('subject_type').value;
    if (subject_type === 'COMPANY') {
      let tel = company_info_form_group.get('tel').value;
      if (!tel || tel.length === 0) {
        return {required: true};
      }

      return TEL_REG.test(tel) ? null : {formatError: true};
    }
  }
  return null;
}

/**
 * @Description: 企业信息邮编校验
 * @Author: 陆炳彬
 * @Date: 2018/7/12 下午12:02
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/12 下午12:02
 */
export function companyInfoZipValidator(control: UntypedFormControl): any {
  let ZIP_REG = /^[0-9]{6}$/;
  let company_info_form_group = control.parent;

  if (company_info_form_group) {
    let subject_type = company_info_form_group.parent.get('subject_type').value;
    if (subject_type === 'COMPANY') {
      let zip = company_info_form_group.get('zip').value;
      if (zip && zip.length > 0) {
        return ZIP_REG.test(zip) ? null : {formatError: true};
      }
    }
  }
  return null;
}

/**
 * @Description: 企业信息企业抬头校验
 * @Author: 陆炳彬
 * @Date: 2018/7/12 下午1:33
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/12 下午1:33
 */
export function companyInfoInvoiceTitleValidator(control: UntypedFormControl): any {
  let company_info_form_group = control.parent;
  if (company_info_form_group) {
    let subject_type = company_info_form_group.parent.get('subject_type').value;
    if (subject_type === 'COMPANY') {
      let invoice_type = company_info_form_group.get('invoice_type').value;

      if (invoice_type && invoice_type !== 'NO_NEED') {
        let invoice_title = company_info_form_group.get('invoice_title').value;
        if (invoice_title && (invoice_title.length < 1 || invoice_title.length > 50)) {
          return {formatError: true};
        }
      }
    }
  }
  return null;
}

export function companyInfoInvoiceTitleValidator2(control: UntypedFormControl): any {
  let company_info_form_group = control.parent;
  if (company_info_form_group) {
    let subject_type = company_info_form_group.parent.get('subject_type').value;
    if (subject_type === 'COMPANY') {
      let invoice_type = company_info_form_group.get('invoice_type').value;

      if (invoice_type && invoice_type !== 'NO_NEED') {
        let invoice_title = company_info_form_group.get('invoice_title').value;
        if (!invoice_title || invoice_title.length === 0) {
          return {required: true};
        }
        if (invoice_title && (invoice_title.length < 10 || invoice_title.length > 50)) {
          return {formatError: true};
        }
      }
    }
  }
  return null;
}

export function companyInfoInvoiceTitleValidator3(control: UntypedFormControl): any {
  let company_info_form_group = control.parent;
  if (company_info_form_group) {
    let subject_type = company_info_form_group.parent.get('subject_type').value;
    if (subject_type === 'COMPANY') {
      let invoice_type = company_info_form_group.get('invoice_type').value;

      if (invoice_type && invoice_type !== 'NO_NEED') {
        let invoice_title = company_info_form_group.get('invoice_title').value;
        if (!invoice_title || invoice_title.length === 0) {
          return {required: true};
        }
        if (invoice_title && (invoice_title.length < 1 || invoice_title.length > 100)) {
          return {formatError: true};
        }
      }
    }
  }
  return null;
}

/**
 * @Description: 企业纳税识别号校验
 * @Author: 陆炳彬
 * @Date: 2018/7/12 下午1:36
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/12 下午1:36
 */
export function companyInfoTaxpayerNumValidator(control: UntypedFormControl): any {
  let taxpayer_num = control.value;

  let company_info_form_group = control.parent;
  if (company_info_form_group) {
    let subject_type = company_info_form_group.parent.get('subject_type').value;
    if (subject_type === 'COMPANY') {
      let invoice_type = company_info_form_group.get('invoice_type').value;
      if (invoice_type && invoice_type !== 'NO_NEED') {
        if (taxpayer_num && taxpayer_num.length !== 15 && taxpayer_num.length !== 18 && taxpayer_num.length !== 20) {
          return {formatError: true};
        }
      }
    }
  }
  return null;
}

export function companyInfoTaxpayerNumValidatorWithoutInvoice(control: UntypedFormControl): any {
  let taxpayer_num = control.value;
  let CARD_NUM_REG = /^[0-9|A-Z|a-z]*$/;

  let company_info_form_group = control.parent;
  if (company_info_form_group) {
    let subject_type = company_info_form_group.parent.get('subject_type').value;
    if (subject_type === 'COMPANY') {
      if (!taxpayer_num || taxpayer_num.length == 0) {
        return {required: true};
      }
      if (taxpayer_num && taxpayer_num.length !== 15 && taxpayer_num.length !== 18 && taxpayer_num.length !== 20) {
        return {formatError: true};
      }
      return CARD_NUM_REG.test(taxpayer_num) ? null : {formatError: true};
    }
  }
  return null;
}

export function companyInfoTaxpayerNumValidator2(control: UntypedFormControl): any {
  let taxpayer_num = control.value;

  let company_info_form_group = control.parent;
  if (company_info_form_group) {
    let subject_type = company_info_form_group.parent.get('subject_type').value;
    if (subject_type === 'COMPANY') {
      let invoice_type = company_info_form_group.get('invoice_type').value;
      if (invoice_type && invoice_type !== 'NO_NEED') {
        if (!taxpayer_num || taxpayer_num.length === 0) {
          return {required: true};
        }
        if (taxpayer_num && taxpayer_num.length !== 15 && taxpayer_num.length !== 18 && taxpayer_num.length !== 20) {
          return {formatError: true};
        }
      }
    }
  }
  return null;
}

/**
 * @Description: 企业信息银行名校验
 * @Author: 陆炳彬
 * @Date: 2018/7/12 下午1:38
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/12 下午1:38
 */
export function companyInfoBankNameValidator(control: UntypedFormControl): any {
  let company_info_form_group = control.parent;

  if (company_info_form_group) {
    let subject_type = company_info_form_group.parent.get('subject_type').value;
    if (subject_type === 'COMPANY') {
      let invoice_type = company_info_form_group.get('invoice_type').value;

      if (invoice_type && invoice_type !== 'NO_NEED') {
        let bank_name = company_info_form_group.get('bank_name').value;

        if (bank_name && bank_name.length > 30) {
          return {formatError: true};
        }
      }
    }
  }
  return null;
}

export function companyInfoBankNameValidator2(control: UntypedFormControl): any {
  let company_info_form_group = control.parent;

  if (company_info_form_group) {
    let subject_type = company_info_form_group.parent.get('subject_type').value;
    if (subject_type === 'COMPANY') {
      let invoice_type = company_info_form_group.get('invoice_type').value;

      if (invoice_type && invoice_type !== 'NO_NEED') {
        let bank_name = company_info_form_group.get('bank_name').value;
        if (invoice_type === 'VAT_INVOICE') {
          if (!bank_name || bank_name.length === 0) {
            return {required: true};
          }
        }
        if (bank_name && bank_name.length > 30) {
          return {formatError: true};
        }
      }
    }
  }
  return null;
}

/**
 * @Description: 企业信息银行卡号校验
 * @Author: 陆炳彬
 * @Date: 2018/7/12 下午1:38
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/12 下午1:38
 */
export function companyInfoCardNumberValidator(control: UntypedFormControl): any {
  let CARD_NUM_REG = /^[0-9]{9,30}$/;
  let company_info_form_group = control.parent;

  if (company_info_form_group) {
    let subject_type = company_info_form_group.parent.get('subject_type').value;
    if (subject_type === 'COMPANY') {
      let invoice_type = company_info_form_group.get('invoice_type').value;

      if (invoice_type && invoice_type !== 'NO_NEED') {
        let card_number = company_info_form_group.get('card_number').value;

        if (card_number && card_number.length > 0) {
          return CARD_NUM_REG.test(card_number) ? null : {formatError: true};
        }
      }
    }
  }
  return null;
}

export function companyInfoCardNumberValidator2(control: UntypedFormControl): any {
  let CARD_NUM_REG = /^[0-9]{9,30}$/;
  let company_info_form_group = control.parent;

  if (company_info_form_group) {
    let subject_type = company_info_form_group.parent.get('subject_type').value;
    if (subject_type === 'COMPANY') {
      let invoice_type = company_info_form_group.get('invoice_type').value;

      if (invoice_type && invoice_type !== 'NO_NEED') {
        let card_number = company_info_form_group.get('card_number').value;
        if (invoice_type === 'VAT_INVOICE') {
          if (!card_number || card_number.length === 0) {
            return {required: true};
          }
        }
        if (card_number && card_number.length > 0) {
          return CARD_NUM_REG.test(card_number) ? null : {formatError: true};
        }
      }
    }
  }
  return null;
}

/**
 * @Description: 结束时间校验
 * @Author: 陆炳彬
 * @Date: 2018/7/12 下午1:58
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/12 下午1:58
 */
export function endTimeValidator(control: UntypedFormControl): any {
  let lease_info_form_group = control.parent;

  if (lease_info_form_group
    && lease_info_form_group.get('start_time_obj').value
    && lease_info_form_group.get('end_time_obj').value) {
    let start_time = lease_info_form_group.get('start_time_obj').value === '' ? null : lease_info_form_group.get('start_time_obj').value.epoc;
    let end_time = lease_info_form_group.get('end_time_obj').value === '' ? null : lease_info_form_group.get('end_time_obj').value.epoc;
    if (start_time && end_time) {
      if (start_time >= end_time) {
        return {startLargerThanEnd: true};
      }
    }
  }
  return null;
}

export function payCycleValidator(control: UntypedFormControl): any {
  let lease_info_form_group = control.parent;
  if (lease_info_form_group && (lease_info_form_group.get("pay_cycle").value <= 0
    || lease_info_form_group.get("pay_cycle").value > 36)) {
    return {formatError: true};
  }
  return null;
}

/**
 * @Description: 备注校验
 * @Author: 陆炳彬
 * @Date: 2018/7/12 下午2:06
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/12 下午2:06
 */
export function remarkValidator(control: UntypedFormControl): any {
  let remark = control.value;
  if (remark && remark.length > 200) {
    return {formatError: true};
  }
  return null;
}

/**
 * @Description: 合同联系人校验
 * @Author: 陆炳彬
 * @Date: 2018/7/12 下午2:10
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/12 下午2:10
 */
export function contractNameValidator(control: UntypedFormControl): any {
  let contract_name = control.value;

  let lease_info_form_group = control.parent;
  if (lease_info_form_group) {
    let contact_same = lease_info_form_group.get('contact_same').value;
    if (!contact_same) {
      if (!contract_name || contract_name.length === 0) {
        return {required: true};
      }

      if (contract_name.length < 2 || contract_name.length > 20) {
        return {formatError: true};
      }
    }
  }

  return null;
}

/**
 * @Description: 合同联系人电话校验
 * @Author: 陆炳彬
 * @Date: 2018/7/12 下午2:17
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/12 下午2:17
 */
export function contractPhoneValidator(control: UntypedFormControl): any {
  let TEL_REG = /^[-|0-9|\s|*]{8,20}$/;

  let contract_phone = control.value;
  let lease_info_form_group = control.parent;
  if (lease_info_form_group) {
    let contact_same = lease_info_form_group.get('contact_same').value;
    if (!contact_same) {
      if (!contract_phone || contract_phone.length === 0) {
        return {required: true};
      }

      return TEL_REG.test(contract_phone) ? null : {formatError: true};
    }
  }
  return null;
}

export function phoneValidator(control: UntypedFormControl): any {
  let TEL_REG = /^[-|0-9|\s|*]{8,20}$/;

  let contract_phone = control.value;
  if (!contract_phone || contract_phone.length === 0) {
    return {required: true};
  }
  return TEL_REG.test(contract_phone) ? null : {formatError: true};
}

/**
 * @Description: 父租约id校验
 * @Author: 陆炳彬
 * @Date: 2018/7/12 下午2:23
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/12 下午2:23
 */
export function parentLeaseIdValidator(control: UntypedFormControl): any {
  let LEASE_REG = /^[0-9]{0,10}$/;
  let parent_lease_id = control.value;

  let lease_info_form_group = control.parent;
  if (lease_info_form_group) {
    let lease_type = lease_info_form_group.get('lease_type').value;
    if (lease_type && lease_type === 'RELET') {
      if (!parent_lease_id || parent_lease_id.length === 0) {
        return {required: true};
      }

      return LEASE_REG.test(parent_lease_id) ? null : {formatError: true};
    }
  }
  return null;
}

/**
 * @Description: 房间每月租金
 * @Author: 陆炳彬
 * @Date: 2018/7/16 下午8:35
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/16 下午8:35
 */
export function monthRentValidator(control: UntypedFormControl): any {
  let month_rent = control.value ? control.value.toString() : control.value;
  let room_info_form_group = control.parent;

  if (room_info_form_group) {
    let rent_type = room_info_form_group.get('rent_type').value;
    if (rent_type === 'NORMAL') {
      if (!month_rent) {
        return {required: true};
      }
      if (month_rent != null && month_rent.split(".").length <= 2 && month_rent.substring(month_rent.length - 1, month_rent.length) !== "."
        && Number.parseFloat(month_rent) >= 0 && Number.parseFloat(month_rent) <= 200000) {
        if (month_rent.split(".").length === 2 && month_rent.split(".")[1].length > 2
          || (month_rent.substring(0, 1) === "0" && month_rent.length > 1 && month_rent.substring(1, 2) !== ".")) {
          return {formatError: true};
        }
        return null;
      } else {
        return {formatError: true};
      }
    }
  }
  return null;
}

export function monthRentNotRequiredValidator(control: UntypedFormControl): any {
  let month_rent = control.value;
  let room_info_form_group = control.parent;

  if (room_info_form_group) {
    let rent_type = room_info_form_group.get('rent_type').value;
    if (rent_type === 'NORMAL') {
      if (!/^\d+$/.test(month_rent) || month_rent < 0 || month_rent > 200000) {
        return {formatError: true};
      }
    }
  }
  return null;
}

/**
 * @Description: 提前提醒校验器
 * @Author: 陆炳彬
 * @Date: 2018/7/20 下午1:32
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/7/20 下午1:32
 */
export function remindValueValidator(control: UntypedFormControl): any {
  let value = control.value;

  if (value) {
    if (!/^\d+$/.test(value) || value < 0 || value > 30) {
      return {formatError: true};
    }
  }

  return null;
}

/**
 * @Description: 入住人弹窗电话验证
 * @Author: 陆炳彬
 * @Date: 2018/9/20 下午2:16
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/9/20 下午2:16
 */
export function renterModalPhoneValidator(control: UntypedFormControl): any {
  const value = control.value;

  if (value) {
    if (!/^1[3456789][0-9]{9}$/.test(control.value)) {
      return {formatError: true};
    }
  }

  const parent_form = control.parent;
  if (parent_form) {
    const register_vip = parent_form.get('register_vip').value;
    const name = parent_form.get('name').value;
    if (name && register_vip && !value) {
      return {registerVipPhoneRequired: true};
    }
  }
  return null;
}

/**
 * @Description: 入住人弹窗证件号验证
 * @Author: 陆炳彬
 * @Date: 2019/1/2 下午1:26
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/2 下午1:26
 */
export function renterModalCretValueValidator(control: UntypedFormControl): any {
  const ID_CARD_REG = /^[A-Za-z0-9]{18}$/;
  const OTHER_REG = /^[A-Za-z0-9]{6,20}$/;

  const cret_num = control.value;

  const form_group = control.parent;
  if (form_group) {
    const name = form_group.get('name').value;
    if (name && !cret_num) {
      return {required: true};
    }

    const cret_type = form_group.get('cret_type').value;
    if (cret_type) {
      if (cret_type === 'ID_CD') {
        return ID_CARD_REG.test(cret_num) ? null : {formatError: true};
      } else {
        return OTHER_REG.test(cret_num) ? null : {formatError: true};
      }
    }
  }
  return null;
}

/**
 * @Description: 入住人弹窗证件类型验证
 * @Author: 陆炳彬
 * @Date: 2019/1/2 下午1:26
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2019/1/2 下午1:26
 */
export function renterModalCretTypeValidator(control: UntypedFormControl) {
  const cret_type = control.value;
  const form_group = control.parent;
  if (form_group) {
    const name = form_group.get('name').value;
    if (name && !cret_type) {
      return {required: true};
    }
  }
  return null;
}

/**
 * @Description: 免租金结束时间校验
 * @Author: 陆炳彬
 * @Date: 2018/11/7 上午11:14
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/11/7 上午11:14
 */
export function freeStartTimeValidator(control: UntypedFormControl): any {
  let form_group = control.parent;
  if (form_group) {
    let is_free = form_group.get('is_free').value;

    if (!is_free) {
      return null;
    }

    // 如果免租期，值不能为空
    if (!control.value || !control.value.formatted) {
      return {required: true};
    }

    // 租约开始、结束时间不能为空
    if (!form_group.get('start_time_obj').value || !form_group.get('end_time_obj').value) {
      return {leaseTimeRequired: true};
    }

    let start_time_mill = getTimeFromPlugin(form_group.get('start_time_obj').value.formatted);
    let end_time_mill = getTimeFromPlugin(form_group.get('end_time_obj').value.formatted);
    let free_start_time_mill = getTimeFromPlugin(control.value.formatted);
    let free_end_time_mill = getTimeFromPlugin(form_group.get('free_end_time_obj').value.formatted);

    let time = free_end_time_mill - free_start_time_mill;
    //zh 2020-03-31 去除免租周期15天限制
    /*if (time / 1000 / 60 / 60 / 24 >= 15) {
      return {freeTimeLargerThan15Days: true};
    }*/

    // 不能大于租约结束时间
    if (free_start_time_mill > end_time_mill) {
      return {freeStartTimeAfterEndTime: true};
    }

    // 不能小于租约开始时间
    if (free_start_time_mill < start_time_mill) {
      return {freeStartTimeBeforeStartTime: true};
    }
  }

  return null;
}


/**
 * @Description: 逾期金校验
 * @Author: 陆炳彬
 * @Date: 2018/11/23 上午11:00
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/11/23 上午11:00
 */
export function outstandingAmountPercentValidator(control: UntypedFormControl): any {

  let form_group = control.parent;

  if (form_group) {
    let need_outstanding_amount = form_group.get('need_outstanding_amount').value;
    let outstanding_amount_percent = form_group.get('outstanding_amount_percent').value;

    if (need_outstanding_amount) {
      // if (!outstanding_amount_percent) {
      //   return { required: true };
      // }

      if (outstanding_amount_percent) {
        if (!/^[0-9]{1,2}$/.test(outstanding_amount_percent)
          || outstanding_amount_percent <= 0
          || outstanding_amount_percent > 10) {
          return {formatError: true};
        }
      } else {
        return {required: true};
      }
    } else {
      return null;
    }
  }
}

export function outstandingAmountPercentValidator2(control: UntypedFormControl): any {
  let form_group = control.parent;
  if (form_group) {
    let need_outstanding_amount = form_group.get('need_outstanding_amount').value;
    let outstanding_amount_percent = form_group.get('outstanding_amount_percent').value;
    if (need_outstanding_amount) {
      if (outstanding_amount_percent) {
        if (outstanding_amount_percent < 0
          || outstanding_amount_percent > 10) {
          return {formatError: true};
        }
      } else {
        return {required: true};
      }
    } else {
      return null;
    }
  }
}

export function outstandingAmountPercentValidator3(control: UntypedFormControl): any {
  let form_group = control.parent;
  if (form_group) {

    let need_outstanding_amount = form_group.get('need_outstanding_amount').value;
    let outstanding_amount_percent = form_group.get('outstanding_amount_percent').value;

    if (need_outstanding_amount) {
      if (outstanding_amount_percent) {

        outstanding_amount_percent = outstanding_amount_percent + "";

        /* if (outstanding_amount_percent < 0
           || outstanding_amount_percent > 10) {
           return {formatError: true};
         }*/
        if (outstanding_amount_percent.split(".").length <= 2
          && outstanding_amount_percent.substring(outstanding_amount_percent.length - 1, outstanding_amount_percent.length) !== "."
          && Number.parseFloat(outstanding_amount_percent) >= 0
          && Number.parseFloat(outstanding_amount_percent) <= 10) {
          if (outstanding_amount_percent.split(".").length === 2
            && outstanding_amount_percent.split(".")[1].length > 2
            || outstanding_amount_percent.split(".")[0] > 10) {
            return {formatError: true};
          }
          return null;
        } else {
          return {formatError: true};
        }

      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}

/**
 * @Description: 长度限制
 * @Author: 陆炳彬
 * @Date: 2018/12/9 下午10:41
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/12/9 下午10:41
 */
export function lengthValidator(min_length: number, max_length): ValidatorFn {
  return (control: UntypedFormControl) => {
    let value = control.value;
    if (value) {
      if (value.length < min_length || value.length > max_length) {
        return {formatError: true};
      }
    }
    return null;
  };
}

//发票地址
export function invoiceAddressValidator(control: UntypedFormControl): any {
  let company_info_form_group = control.parent;
  if (company_info_form_group) {
    let subject_type = company_info_form_group.parent.get('subject_type').value;
    if (subject_type === 'COMPANY') {
      let invoice_type = company_info_form_group.get('invoice_type').value;

      if (invoice_type && invoice_type !== 'NO_NEED') {
        let address = company_info_form_group.get('company_address').value;
        if (address && (address.length < 1 || address.length > 50)) {
          return {formatError: true};
        }
      }
    }
  }
  return null;
}

/**
 * @Description: 宽限期校验
 * @Author: luyang
 * @Date: 2020/12/15 19:32
 */
export function gracePeriodDaysValidator(control: UntypedFormControl): any {
  let form_group = control.parent;
  if (form_group) {
    let grace_period_days = form_group.get('grace_period_days').value;
    if (grace_period_days) {
      if (!/^[0-9]*$/.test(grace_period_days)
        || Number.parseInt(grace_period_days) < 0
        || Number.parseInt(grace_period_days) > 9999
        || (Number.parseInt(grace_period_days) === 0 && grace_period_days.length > 1)
      ) {
        return {formatError: true};
      }
    } else {
      return {required: true};
    }
  }
  return null;
}

export function gracePeriodDaysValidator100(control: UntypedFormControl): any {
  let form_group = control.parent;
  if (form_group) {
    let grace_period_days = form_group.get('renter_grace_period_days').value;
    if (form_group.get('went_dutch').value === true && form_group.get('renter_outstanding_amount').value === true) {
      if (!grace_period_days) {
        return {required: true};
      }
      if (!/^[0-9]*$/.test(grace_period_days)
        || Number.parseInt(grace_period_days) < 0
        || Number.parseInt(grace_period_days) > 100
      ) {
        return {formatError: true};
      }
    } else {
      return null;
    }
  }
  return null;
}

export function roomAvailableDaysValidator(control: UntypedFormControl): any {
  let form_group = control.parent;
  if (form_group) {
    let grace_period_days = form_group.get('room_available_days').value;
    if (grace_period_days) {
      if (!/^[0-9]*$/.test(grace_period_days)
        || Number.parseInt(grace_period_days) < 0
        || Number.parseInt(grace_period_days) > 30
        || (Number.parseInt(grace_period_days) === 0 && grace_period_days.length > 1)
      ) {
        return {formatError: true};
      }
    } else {
      return {required: true};
    }
  }
  return null;
}

export function changeReasonValidator(control: UntypedFormControl): any {
  let form_group = control.parent;
  if (form_group) {
    let change_reason = form_group.get('change_reason').value;
    if (change_reason) {
      if (!trim(change_reason)) {
        return {required: true};
      }
    } else {
      return {required: true};
    }
  }
  return null;
}
