import {PageReq, PageResp} from '../page/page';
import {RoomBedDTO} from "../room/room.model";
import {ProjectConfigParamModel, ProjectConfigParamValueDTO} from "../common/common.model";
import {RenterExtInfoValueDTO} from "../clientinfo/client-info.model";

/**
 * @description: 租客个人信息
 * @Author: lubingbin
 * @Date: 2018/3/2
 * @Last Modified by: lubingbin
 * @Last Modified time: 2018/3/2
 */
export class Renter {
  lease_id?: number;
  name?: string;
  sex?: string;
  phone?: string;
  cret_type?: string;
  cret_num?: string;
  country?: string;
  province_id?: number;
  city_id?: number;
  area_id?: number;
  nation?: string;
  birthday?: number;
  address?: string;
  room_bed_id?: number;
  renter_status?: string;
}

/**
 * @description: 用于租客列表的展现
 * @Author: lubingbin
 * @Date: 2018/3/9
 * @Last Modified by: lubingbin
 * @Last Modified time: 2018/3/9
 */
export class RenterListItem {
  info_id?: number;
  name?: string;
  sex?: string;
  phone?: string;
  cret_type?: string;
  cret_num?: string;
  room_name?: string;
  renter_status?: string;
  checkin_time?: Date;
  checkout_time?: Date;
  lease_id?: number;
}

/**
 * @description:租客列表查询条件
 * @Author: lubingbin
 * @Date: 2018/3/9
 * @Last Modified by: lubingbin
 * @Last Modified time: 2018/3/9
 */
export class RenterListReqParam extends PageReq {
  //楼栋id
  unit_id?: any;

  //房间号
  room_num?: string;

  //租客名称
  renter_name?: string;

  //租客电话
  renter_phone?: string;

  //租客状态
  renter_status?: string[];
}

export class LeaseRenterInfoDTO {
  info_id?: number;
  lease_id?: number;
  name?: string;
  sex?: string;
  phone?: string;
  tel_conceal?: string;
  cret_type?: string;
  cret_num?: string;
  cret_num_conceal?: string;
  country?: string;
  province_id?: number;
  city_id?: number;
  area_id?: number;
  nation?: number;
  birthday?: Date;
  address?: string;
  room_bed_id?: number;
  bed_name?: string;
  room_id?: number;
  renter_status?: string;
  room_name?: string;
  checkin_time?: Date;
  checkout_time?: Date;
  subsidiary?: string;
  emp_no?: string;
  check_in_time?: number;

  person_type?: string;
  bedding?: string;
  key_num?: string;
  renter_ext_info_values?:Array<RenterExtInfoValueDTO>;

  free_start_time?: number;
  free_end_time?: number;

  renter_check_view?:boolean;
  effective_password?: boolean;
}

/**
 * @Description: 搜索入住人参数
 * @Author: 陆炳彬
 * @Date: 2018/5/14 下午5:14
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/5/14 下午5:14
 */
export class SearchRenterParams {
  company_name: string;
  renter_name: string;
  renter_phone: string;
  lease_id: number;
}

/**
 * @Description: 床位携带租客对象
 * @Author: 陆炳彬
 * @Date: 2018/6/12 下午5:49
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/6/12 下午5:49
 */
export class RenterWithBed {
  //入住人
  lease_renter_info: LeaseRenterInfoDTO;
  //房间租约id
  lease_room_id: number;
  //床位
  room_bed: RoomBedDTO;
  //是否可以添加(退房审核不能够添加)
  can_bed_add: boolean;
  //是否可以换床
  can_bed_exchange: boolean;
  //是否可以退床
  can_bed_checkout: boolean;
  //是否可以取消
  can_bed_cancel: boolean;
  //是否可以编辑
  can_bed_edit: boolean;
}

/**
 * @Description: 设备详细返回信息
 * @Author: 陆炳彬
 * @Date: 2018/6/12 下午2:35
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/6/12 下午2:35
 */
export class EquipmentDetailResponse {
  //是否是首次入住
  is_first_checkin: boolean;

  //房间名
  room_name: string;

  //设备详细信息列表
  equipment_detail_list: EquipmentDetail[];
}

/**
 * @Description: 设备详细信息
 * @Author: 陆炳彬
 * @Date: 2018/6/12 下午2:20
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/6/12 下午2:20
 */
export class EquipmentDetail {
  //设备类型
  equipment_type: string;

  //当前设备读数
  now_value?: number;

  //上次设备读数
  last_value?: number;

  //上次设备读取时间
  last_time?: Date;

  //设备当前状态
  equipment_status?: string;

  //设备状态记录时间
  equipment_status_record_time?: Date;
}

/**
 * @Description: 获取床位携带租客列表请求
 * @Author: 陆炳彬
 * @Date: 2018/6/12 下午2:15
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/6/12 下午2:15
 */
export class LeaseInfoDetailReqParam {
  //租约id
  lease_id: number;

  constructor(lease_id: number) {
    this.lease_id = lease_id;
  }
}

/**
 * @Description: 获取床位携带租客列表请求
 * @Author: 陆炳彬
 * @Date: 2018/6/12 下午2:15
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/6/12 下午2:15
 */
export class BedWithRenterListReqParam {
  //租约id
  lease_id: number;

  constructor(lease_id: number) {
    this.lease_id = lease_id;
  }
}

/**
 * @description: 租客个人信息
 * @Author: yw
 * @Date: 2018/7/17
 */
export class Lessee {
  info_id?: number;
  lease_id?: number;
  room_id?: number;
  unit_id?: number;
  room_num?: string;
  room_name?: string;
  name?: string;
  sex?: string;
  phone?: string;
  cret_type?: string;
  cret_num?: string;
  country?: string;
  province_id?: number;
  city_id?: number;
  area_id?: number;
  nation?: string;
  birthday?: number;
  address?: string;
  room_bed_id?: number;
  renter_status?: string;
  asset_confirm?: string;
  lease_room_id?: number;
  subsidiary?: string;
}

/**
 * @Description: 匹配入住人参数
 * @Author: yw
 */
export class MatchLesseeParams {
  name: string;
  cret_num: string;
  lease_id: number;
  sex?: string;
  subsidiary?: string;
}

/**
 * @Description: 办理入住参数
 * @Author: yw
 */
export class CheckInParams {
  info_id: number;
  room_id: number;
  lease_id: number;
  img_list:Array<string>;
}

/**
 * @Description: 查询办理入住的房间列表参数
 * @Author: yw
 */
export class RoomListParams {
  lease_id?: number;
  room_id?: number;
}

/**
 * @Description: 创建入住人参数
 * @Author: yw
 */
export class CreateLesseeParams {
  room_id: number;
  lease_id: number;
  name: string;
  sex: string;
  phone?: string;
  cret_type?: string;
  cret_num?: string;
  register_vip?: boolean = true;
  contact_name1?: string;
  contact_phone1?: string;
  contact_name2?: string;
  contact_phone2?: string;
  img_url_list?: string[];
  subsidiary?: string;
  emp_no?:string;
  person_type?:string;
  bedding?: string;
  key_num?: string;
  project_ext_info_values?: Array<ProjectExtInfoValue>;
  free_start_time: number;
  free_end_time: number;
  is_add: number;
}

export class LeaseRoomRentRequestParam {
  lease_id: number;
  room_id: number;

  constructor(lease_id: number, room_id: number) {
    this.lease_id = lease_id;
    this.room_id = room_id;
  }
}

/**
 * @Description: 入住人修改基本信息
 * @Author: 陆炳彬
 * @Date: 2018/11/23 下午5:43
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/11/23 下午5:43
 */
export class RenterEditBaseInfo {
  is_empty: boolean;
  disable: boolean;
  status: string;
  phone_disable: boolean;
  is_remove: boolean;
  is_edit: boolean;
}

// 身份证读卡器参数
export class ReadIdCardInfo {
  sex?: string = 'MALE';
  name?: string;
  cret_type?: string = 'ID_CD';
  cret_num?: string;
  phone?: string;
}

export class RenterCheckOutRequest {
  info_ids: Array<number>;
  checkout_date: number;
  lease_id:number;
  renter_id?: number;
}

export class RenterCheckOutResponse {
  result: string;
}

export class RenterRepeatRequest {
  name: string;
  cret_num: string;
  lease_id: number;
  phone: string;
  emp_no?: any;
  person_type?: any;
}

export class RenterRepeatResponse {
  check_cert_num: boolean;
  check_phone: boolean;
  room_name: string;
  match_phone_cert_num:boolean;
}

export class RenterListRequest extends PageReq{
  // @Remark("搜索文本：姓名，手机号，身份证")
  search_content?:string;
  subject_name?: string;
  room_name?: string;
  // @Remark("搜索状态)
  status?:string;
  rented_start_date?: number;
  rented_end_date?: number;
  checkout_start_date?: number;
  checkout_end_date?: number;
  face?:string;
  face_status?:string;
}

export class RenterListDTO {
  // @Remark("住户id")
  info_id?: number;
  // @Remark("用户姓名")
  name?: string;
  // @Remark("用户手机号")
  phone?: string;
  // @Remark("脱敏手机号")
  tel_conceal?:string;
  // @Remark("证件类型")
  cret_type?: string;
  // @Remark("证件号码")
  cret_num?: string;
  // @Remark("脱敏证件号码")
  cret_num_conceal:string
  // @Remark("人员属性") 企业员工 EMPLOYEE/员工家属 FAMILIES
  person_type?: string;
  // @Remark("工号")
  emp_no?: string;
  // @Remark("备注信息")
  subsidiary?: string;
  // @Remark("最后一次入住时间")
  lastCheckinTime?: number;
  // 最后一次退宿时间
  last_checkout_time?: number;
  // @Remark("社区名称")
  project_name?: string;
  // @Remark("房间名称")
  room_name?: string;
  // @Remark("签约主体")
  subject_name?: string;
  // @Remark("入住状态")
  renter_status?: string;
  // @Remark("入住时间")
  checkin_time?: number;
  // @Remark("退租时间")
  checkout_time?: number;

  // @Remark("人脸图采集状态")
  face_lib_img_status?: string;

}

export class RenterListResponse extends PageResp {
  renter_list?: Array<RenterListDTO>;
}

export class RoomRenterBatchImportPreviewRequest {
  room_renter_dtos?: Array<LeaseRenterInfoDTO>;
}

export class RenterPersonTypeResponse {
  renter_person_type_list?: Array<ProjectConfigParamValueDTO>;

  need_bedding?: Boolean;
  need_key_num?: Boolean;
}

export class ProjectExtInfoResponse {
  project_ext_info_list?: Array<ProjectConfigParamModel>;

}

export class RenterAddExtInfo {

  id:number;

  project_id:number;

  name:string;

  code:string;

  value:string;

  select_value:Array<string>;

  type:string;

  is_required:number;

  user_input_value:string = "";
}

export class ProjectExtInfoValue {

  project_config_param_id:number;

  name:string;

  value:string;

}

export class CalFreeTimeReq {

  free_start_time: number;

  select_free: number;

  lease_id: number;

}

export class CalFreeTimeResp {

  free_start_time: number;

  free_end_time: number;

  end_time: number;

}

export class QueryAllRenterListReq {
  lease_id: number;
  room_ids: Array<number>;
}

export class QueryAllRenterListResp {
  renter_info_list: Array<RenterListDTO>;
}
