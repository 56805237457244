import { Component, OnInit, ViewChild } from '@angular/core';
import { RoomChangeCommonReq, RoomChangeContractExtResp } from '../../../../model/lease/roomchange.model';
import { RoomChangeService } from '../../../../service/lease/roomchange.service';
import { BaseEnum } from '../../../../model/modal/base-model';
import { BaseInfoService } from '../../../../service/common/base-info.service';
import { LeaseService } from '../../../../service/lease/lease.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { WorkService } from '../../../../service/common/work.service';
import { PdfViewComponent } from '../../../shared/pdf-view/pdf-view.component';

@Component({
  selector: 'app-cr-change-room-details',
  templateUrl: './cr-change-room-details.component.html',
  styleUrls: ['./cr-change-room-details.component.css']
})
export class CrChangeRoomDetailsComponent implements OnInit {

  lease_id: number;

  record_id: number;

  contract_ext: RoomChangeContractExtResp;

  // 租约状态列表
  status_list: BaseEnum[];

  // 换房单url
  room_change_bill_url: string;
  // 换房协议url
  room_change_contract_url: string;

  can_show: string;
  iframe: string;
  @ViewChild(PdfViewComponent, { static: true }) pdfView: PdfViewComponent;

  is_bill: boolean = true;

  constructor(private roomChangeService: RoomChangeService,
              private leaseService: LeaseService,
              private http: HttpClient,
              private sanitizer: DomSanitizer,
              private workService: WorkService,
              private activatedRoute: ActivatedRoute,
              private baseInfoService: BaseInfoService) {
    this.activatedRoute.params.subscribe(params => {
      this.record_id = params['record_id'];
    });
  }

  ngOnInit() {
    this.roomChangeService.getContractExtInfo(new RoomChangeCommonReq(this.record_id)).then(data => {
      this.contract_ext = data;
    });

    this.leaseService.getContractUrl({
      lease_id: -1,
      object_id: this.record_id,
      service_type: 'ROOM_CHANGE_BILL'
    }).then(data => {
      this.room_change_bill_url = data.image_url;
      this.iframe = this.room_change_bill_url;

    });

    this.leaseService.getContractUrl({
      lease_id: -1,
      object_id: this.record_id,
      service_type: 'ROOM_CHANGE_CONTRACT'
    }).then(data => {
      this.room_change_contract_url = data.image_url;
    });

    this.status_list = this.baseInfoService.getRoomChangeStatusList();
  }

  toggleUrl() {
    this.is_bill = !this.is_bill;

    if (this.is_bill) {
      this.leaseService.getContractUrl({
        lease_id: -1,
        object_id: this.record_id,
        service_type: 'ROOM_CHANGE_BILL'
      }).then(data => {
        this.room_change_bill_url = data.image_url;
        this.iframe = this.room_change_bill_url;
      });
    } else {
      this.leaseService.getContractUrl({
        lease_id: -1,
        object_id: this.record_id,
        service_type: 'ROOM_CHANGE_CONTRACT'
      }).then(data => {
        this.room_change_contract_url = data.image_url;
        this.iframe = this.room_change_contract_url;
      });
    }
  }

  printFrame() {
    this.pdfView.print();
  }
}
