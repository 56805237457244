import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {WorkService} from '../service/common/work.service';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {WorkModel} from "../model/common/common.model";
import {map} from "rxjs/operators";

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  static WITH_LOADING = 'WITH_LOADING';

  constructor(private workService: WorkService,
              private loadingBar: LoadingBarService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let loading = req.headers.has(LoadingInterceptor.WITH_LOADING);

    // this.loadingBar.start();
    if (loading) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.PANEL_LOADING_START));
    }

    return next.handle(req).pipe(map(event => {

      if (event.type) {
        // this.loadingBar.complete();
        if (loading) {
          this.workService.event_bus.emit(new WorkModel(this.workService.type.PANEL_LOADING_END));
        }
      }
      return event;
    }));
  }
}
