import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PrivilegeResolve} from '../../core/resolve/privilege.resolve';
import {TabTitleResolve} from "../../core/resolve/tab-title.resolve";
import {AppointmentListComponent} from "./appointment-list/appointment-list.component";
import {AppointmentDetailComponent} from "./appointment-detail/appointment-detail.component";

const routes: Routes = [
  {
    path: "",
    data: {
      title: '预约看房'
    },
    children: [
      {
        path: "list",
        component: AppointmentListComponent,
        data: {
          title: '预约列表',
          OPERATION_PRIVILEGES: [
            'SEE_HOUSE_LIST',
            'SEE_HOUSE_OPERATE',
            'APPOINT_ROOM_WAIT_VIEW',
            'WSEE_HOUSE_OPERATE',
          ],
          reuse: true
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      },
      {
        path: "detail/:id",
        component: AppointmentDetailComponent,
        data: {
          title: '预约详情',
          OPERATION_PRIVILEGES: [
            'SEE_HOUSE_OPERATE',
            'RESERVE_ROOM_VIEW',
            'RESERVE_ROOM_HANDLE'
          ]
        },
        resolve: {
          operation_priviliges: PrivilegeResolve,
          title: TabTitleResolve
        }
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SeeHouseRoutingModule {
}
