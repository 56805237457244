<div class="modal fade" bsModal #modal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>请选择要换的房间</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="over10">
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th></th>
              <th>房间号</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of avaliable_lease_room_list">
              <td>
                <input type="radio" [value]="item.lease_room_id" [(ngModel)]="change_lease_room_id">
              </td>
              <td>
                {{item.room_num}}
              </td>
            </tr>
            <tr *ngIf="avaliable_lease_room_list.length === 0">
              <td colspan="4">此合同下房间已退
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="text-center mb20">
          <button type="button" class="btn btn-primary mr20" [disabled]="canNext()" (click)="next()">下一步</button>
          <button type="button" (click)="hide()" class="btn btn-secondary">取消</button>
        </div>
      </div>
    </div>
  </div>
</div>
