import { Pipe, PipeTransform } from '@angular/core';

/**
 * @Description: 租约状态管道
 * @Author: 陆炳彬
 * @Date: 2018/3/29 上午10:26
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/3/29 上午10:26
 */
@Pipe({
  name: 'leasePipe'
})
export class LeasePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const status = {
      EFFECTIVE: '已生效',
      DEFUNCT: '已失效',
      AUDITING: '待审核',
      REJECT: '审核失败',
      CHECKOUTAUDIT: '退房审核',
      INEFFECTIVE: '未生效',
      CHECKOUT: "已退房"
    };
    return status[value];
  }
}
