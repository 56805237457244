<ngx-extended-pdf-viewer *ngIf="canShow && iframe"
                         [src]="iframe"

                         [height]="height"
                         [showUnverifiedSignatures]="true"

                         [delayFirstView]="1000"
                         [useBrowserLocale]="true"
                         [textLayer]="true"

                         [showFindButton]="false"
                         [showOpenFileButton]="false"
                         [showSecondaryToolbarButton]="false"
                         [showHandToolButton]="false"
                         [showScrollingButton]="false"
                         [showSpreadButton]="false"
                         [showPropertiesButton]="false"
                         [showPrintButton]="false"
                         [showSidebarButton]="false"
                         [showDownloadButton]="false"
                         [showTextEditor]="false"
                         [showDrawEditor]="false"

>
</ngx-extended-pdf-viewer>


<iframe frameborder="0" [name]="frameName" [src]="iframeSafeUrl" *ngIf="canShow && iframeSafeUrl"
        type='application/pdf'
        style="position: fixed;opacity: 0;z-index: -1;"
>
</iframe>

<div *ngIf="!canShow" class="text-center">该浏览器不支持预览PDF，请<a [href]="iframe" target="_blank">下载</a>后预览
</div>
