import { Injectable } from '@angular/core';
import { RequestService } from '../request.service';
import { environment } from '../../../environments/environment';
import {
  RoomGroupAddRequest,
  RoomGroupBaseDataResponse,
  RoomGroupEditDetailRequest,
  RoomGroupEditDetailResponse,
  RoomGroupFacListResponse,
  RoomGroupRelationConfirmRequest,
  RoomGroupRelationResponse,
  RoomGroupStyleAddRequest,
  RoomGroupStyleAddResponse,
  RoomGroupUpdateRequest
} from '../../model/roomgroup/room-group.model';

/**
 * @description:房源分组相关服务
 *
 * @Author: kuangye
 * @Date: 2018年11月26日11:46:22
 * @Last Modified by: kuangye
 * @Last Modified time: 2018年11月26日11:46:22
 */
@Injectable()
export class RoomGroupService {

  // 房源分组详情api
  private ROOM_GROUP_DETAIL_URL = `${environment.apiHost}room/group/detail`;
  // 房源添加
  private ROOM_GROUP_ADD_URL = `${environment.apiHost}room/group/add`;
  // 房源编辑
  private ROOM_GROUP_EDIT_URL = `${environment.apiHost}room/group/edit`;
  // 房源设备列表
  private ROOM_GROUP_FAC_LIST_URL = `${environment.apiHost}room/group/fac/list`;
  // 房源装修风格添加
  private ROOM_GROUP_STYLE_ADD_URL = `${environment.apiHost}room/group/style/add`;
  // 房源编辑获取详情
  private ROOM_GROUP_EDIT_DETAIL_URL = `${environment.apiHost}room/group/edit/detail`;
  // 房源列表api
  private ROOM_GROUP_LIST_URL = `${environment.apiHost}room/group/list`;
  // 房源房间下楼栋房间信息
  private ROOM_GROUP_UNIT_LIST_URL = `${environment.apiHost}room/group/room/list`;
  // 房源操作日志列表
  private ROOM_GROUP_LOG_LIST_URL = `${environment.apiHost}room/group/log/list`;
  // 房源房间号信息
  private ROOM_GROUP_ROOM_NUM_LIST_URL = `${environment.apiHost}room/group/room/num`;

  // 房源房间关系添加确认
  private ROOM_GROUP_RELATION_CONFIRM_URL = `${environment.apiHost}room/group/importroom/confirm`;
  // 房源房间关系添加取消
  private ROOM_GROUP_RELATION_CANCEL_URL = `${environment.apiHost}room/group/importroom/cancel`;

  // 下载房源房间号信息
  private ROOM_GROUP_DOWN_ROOM_NUM_LIST_URL = `${environment.apiHost}room/group/room/num/down`;

  // 房源管理：取消，下架
  private ROOM_GROUP_MANAGE_URL = `${environment.apiHost}room/group/manage`;

  //批量导入入住人-模板下载
  private DOWN_RESIDENT_TEMPLATE_URL = `${environment.apiHost}room/group/downResidentTemplate`;


  // 房源图片基础信息
  private GET_ROOM_GROUP_BASE_DATA = `${environment.apiHost}room/group/base/data`;

  constructor(private requestService: RequestService) {
  }

  /**
   *  房源详情
   */
  public roomGroupDetail(requestData: any): Promise<any> {
    return this.requestService.authGet(this.ROOM_GROUP_DETAIL_URL, requestData);
  }

  /**
   *  房源列表
   */
  public roomGroupList(requestData: any): Promise<any> {
    return this.requestService.authGet(this.ROOM_GROUP_LIST_URL, requestData);
  }
  public roomGroupListWithLoading(requestData: any): Promise<any> {
    return this.requestService.authGet(this.ROOM_GROUP_LIST_URL, requestData, true);
  }

  /**
   *  房源楼栋列表
   */
  public roomGroupUnitList(requestData: any): Promise<any> {
    return this.requestService.authGet(this.ROOM_GROUP_UNIT_LIST_URL, requestData);
  }

  /**
   *  创建房源
   */
  public roomGroupAdd(requestData: RoomGroupAddRequest): Promise<any> {
    return this.requestService.authPost(this.ROOM_GROUP_ADD_URL, requestData);
  }

  /**
   *  修改房源
   */
  public roomGroupUpdate(requestData: RoomGroupUpdateRequest): Promise<any> {
    return this.requestService.authPost(this.ROOM_GROUP_EDIT_URL, requestData);
  }

  /**
   *  设备列表
   */
  public roomGroupFacList(requestData?: any): Promise<RoomGroupFacListResponse> {
    return this.requestService.authGet(this.ROOM_GROUP_FAC_LIST_URL, requestData);
  }

  // 添加装修风格
  public addRoomStyle(name: any): Promise<RoomGroupStyleAddResponse> {
    let requestData = new RoomGroupStyleAddRequest();
    requestData.name = name;
    return this.requestService.authPost(this.ROOM_GROUP_STYLE_ADD_URL, requestData);
  }

  // 编辑用房源分组详情
  public editDetail(requestData: RoomGroupEditDetailRequest): Promise<RoomGroupEditDetailResponse> {
    return this.requestService.authPost(this.ROOM_GROUP_EDIT_DETAIL_URL, requestData);
  }

  /**
   *  房源日志
   */
  public roomGroupLogList(requestData: any): Promise<any> {
    return this.requestService.authGet(this.ROOM_GROUP_LOG_LIST_URL, requestData);
  }

  /**
   *  房源楼栋下个房间信息
   */
  public roomGroupRoomNumList(requestData: any): Promise<any> {
    return this.requestService.authGet(this.ROOM_GROUP_ROOM_NUM_LIST_URL, requestData);
  }

  /**
   *  房源楼栋下个房间信息
   */
  public roomGroupdOWNRoomNumList(): String{
    return this.ROOM_GROUP_DOWN_ROOM_NUM_LIST_URL;
  }

  /**
   *  房源管理
   */
  public roomGroupManage(requestData: any): Promise<any>{
    return this.requestService.authPost(this.ROOM_GROUP_MANAGE_URL, requestData);
  }

  /**
   *  房源房间关系确认
   */
  public roomGroupRelationConfirm(requestData: RoomGroupRelationConfirmRequest): Promise<RoomGroupRelationResponse> {
    return this.requestService.authPost(this.ROOM_GROUP_RELATION_CONFIRM_URL, requestData);
  }

  /**
   *  房源房间关系取消
   */
  public roomGroupRelationCancel(requestData?: any): Promise<any> {
    return this.requestService.authPost(this.ROOM_GROUP_RELATION_CANCEL_URL, requestData);
  }

  /**
   *  批量导入入住人-模板
   */
  public downResidentTemplate(): String{
    return this.DOWN_RESIDENT_TEMPLATE_URL;
  }

  public roomGroupBaseData(): Promise<RoomGroupBaseDataResponse> {
    return this.requestService.authGet(this.GET_ROOM_GROUP_BASE_DATA);
  }
}
