<!-- {{initSomething(roomChooseModal)}} -->

<!-- 选择房间 -->
<div class="modal fade" bsModal #roomChooseModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name" (click)="stopP($event)">
  <div class="modal-dialog modal-primary modal-2xl fullPage">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>请选择房间/床位</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hide();choose_room_req.check_room_num=null">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 col-sm-3 col-md-2 mb-2">
            <select class="w-100" [(ngModel)]="choose_room_req.unit_id">
              <option value=-1>
                请选择楼栋
              </option>
              <option *ngFor="let unit of unit_list" [value]="unit.id">
                {{unit.unit_name}}
              </option>
            </select>
          </div>
          <div class="col-12 col-sm-3 col-md-2 mb-2">
            <select class="w-100" [(ngModel)]="choose_room_req.room_type_id">
              <option value=-1>
                请选择房型
              </option>
              <option *ngFor="let rt of roomType" [value]="rt.id">
                {{rt.room_type_name}}
              </option>
            </select>
          </div>
          <div *ngIf="is_changRenter && is_company" class="col-12 col-sm-3 col-md-2 mb-2">
            <select class="w-100" [(ngModel)]="choose_room_req.in_all_contract">
              <option value=false>
                本企业当前合同
              </option>
              <option value=true>
                本企业全部合同
              </option>
            </select>
          </div>
          <div class="col-12 col-md-7 mb-2" *ngIf="!is_changRenter">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                <my-date-picker placeholder="入住日期"
                                [(ngModel)]="choose_room_req.start_time_obj"
                                [options]="myDatePickerOptions">
                </my-date-picker>
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2" *ngIf="is_sign">
                <select class="w-100" (change)="quickSetEndTime($event.target.value)">
                  <option disabled selected >快速选择</option>
                  <option *ngFor="let month of getArr(1, 24)" [value]="month">
                    {{month + "个月"}}
                  </option>
                </select>
              </div>
              <div class="col-12 col-sm-6 col-md-4 mb-2">
                <my-date-picker placeholder="结束日期"
                                [disabled]="!is_sign"
                                [(ngModel)]="choose_room_req.end_time_obj"
                                [options]="myDatePickerOptions">
                </my-date-picker>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 mb-2" *ngIf="is_changRenter">
            <div class="w90px hei32">图例说明：</div>
            <div class="color-spans">
              <span class="mr-3 hei32 "><i></i> 空房间</span>
              <span class="mr-3 hei32 "><i></i> 未满员</span>
            </div>
          </div>
          <div class="col-12 col-md-1 mb-2">
            <button *ngIf="!is_changRenter" type="button" class="btn btn-info" (click)="searchRoom()">搜索</button>
            <button *ngIf="is_changRenter" type="button" class="btn btn-info" (click)="searchAvaliableRooms()">搜索</button>
          </div>
        </div>
        <div class="row" *ngIf="!is_changRenter">
          <div class="col-12 col-sm-12 col-md-12 mb-2">
            房间数：<input type="text" [(ngModel)]="choose_room_req.check_room_num" [disabled]="!is_sign">
            <button type="button" class="btn btn-info" style="margin-left: 20px;" (click)="intelligenceCheckRoom()">智能选房</button>
          </div>
        </div>

        <div class="p-2 mb-2 yellow" *ngIf="is_sign">共选择了 {{getCheckedRoom().length}} 间房
          <span class="text-primary ml-2" style="cursor: pointer" (click)="cancelChoose()">点此取消选中</span>
        </div>

        <div class="" *ngIf="canChange">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn" [ngClass]="{'btn-primary':choose_room_req.data_type=='ROOM','btn-secondary':choose_room_req.data_type!='ROOM'}" (click)="chooseRoomBed('ROOM')">房间</button>
            <button type="button" class="btn" [ngClass]="{'btn-primary':choose_room_req.data_type=='BED','btn-secondary':choose_room_req.data_type!='BED'}" (click)="chooseRoomBed('BED')">床位</button>
            <button type="button" class="btn" [ngClass]="{'btn-primary':choose_room_req.data_type=='PUBLIC','btn-secondary':choose_room_req.data_type!='PUBLIC'}" (click)="chooseRoomBed('PUBLIC')">公区</button>
            <button type="button" class="btn" [ngClass]="{'btn-primary':choose_room_req.data_type=='SHOP','btn-secondary':choose_room_req.data_type!='SHOP'}" (click)="chooseRoomBed('SHOP')">商铺</button>
          </div>
        </div>

        <!-- 幢与层 -->
        <ng-container *ngIf="!unit_with_room || unit_with_room.length == 0">
          <h2>未找到满足条件的房间</h2>
        </ng-container>
        <div class="room-box">
          <ng-container *ngFor="let unit of unit_with_room">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" *ngIf="is_sign"
                     [(ngModel)]="unit_floor_checked['unit_' + unit.unit_id]"
                     (click)="checkAll('unit', $event.target.checked, unit.unit_id)">
              <label class="form-check-label">
                <b>{{unit.unit_name}}</b>
              </label>
            </div>
            <!-- 全部楼层 -->
            <div class="room-all" *ngFor="let floor of unit?.floor_list">

              <!-- 楼层 -->
              <div class="room-content">
                <div class="floor form-check">
                  <input type="checkbox" class="form-check-input" *ngIf="is_sign"
                         [(ngModel)]="unit_floor_checked['unit_' + unit.unit_id + '_floor_' + floor.floor]"
                         (click)="checkAll('floor', $event.target.checked, unit.unit_id, floor.floor)">
                  <label class="form-check-label">{{getFloor(floor.floor)}}</label>
                </div>
                <div class="room">
                  <ul>
                    <ng-container *ngFor="let room of floor?.room_info_list">
                      <li class="pointer" (click)="chooseRoom(room)" [ngClass]="{'li2': is_changRenter && room.checkin_renter_num > 0}">
                        <div class="d-flex justify-content-between">
                          <span class="text-left">{{room.room_num}}</span>
                          <span [style.display]="!is_changeRoom ? 'block' : 'none'" class="text-right">{{room.room_type_name}}</span>
                        </div>
                        <div class="d-flex justify-content-between">
                          <span class="text-left" *ngIf="!is_changRenter">0/{{room.bed_num}}</span>
                          <span class="text-right" *ngIf="!is_changRenter">{{room.base_price}}</span>
                        </div>
                        <div class="d-flex">
                          <span class="text-left" *ngIf="is_changRenter">{{room.checkin_renter_num}}/{{room.avaliable_checkin_num}}</span>
                        </div>

                        <div class="checkBox" *ngIf="room.checked">
                          <!--<span class="text-center"><b>✔︎</b></span>-->
                          <img style="width: 30px;height: 30px;" src="../../../../assets/img/u3877.png">
                        </div>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="modal-footer text-center" *ngIf="unit_with_room && unit_with_room.length > 0">
        <ng-container *ngIf="!is_changRenter">
          <button type="button" class="btn btn-primary" (click)="doConfirm()" *ngIf="is_sign">
            确认选择
          </button>
          <button type="button" class="btn btn-primary" (click)="doConfirm()" *ngIf="!is_sign"
                  [disabled]="!choose_room_req.end_time_obj || getCheckedRoom().length === 0">
            下一步
          </button>
        </ng-container>
        <ng-container *ngIf="is_changRenter">
          <button type="button" class="btn btn-primary" (click)="openConfirmRenterInfo()" [disabled]="getCheckedRoom().length === 0">
            下一步
          </button>
        </ng-container>
        <button type="button" class="btn btn-secondary" (click)="hide();choose_room_req.check_room_num=null">取消</button>
      </div>
    </div>
  </div>
</div>

<!--办理换房-->
<div class="modal fade" bsModal #modal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name" (click)="stopP($event)">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>办理换房</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="setbox" style="background: rgba(255, 255, 204, 1);">
          <div class="row mt10 px-2">
            <div class="wp33 text-left">
              <div class="old-room">原房间：</div>
              <div class="text-room" >{{oldRoomInfo?.room_name}}</div>
            </div>
            <div class="wp33 text-center">
              <div>换至</div>
              <div><i class="fa fa-long-arrow-right arrow" aria-hidden="true"></i></div>
            </div>
            <div class="wp33 text-left" >
              <div class="new-room">新房间：</div>
              <div class="text-room">{{selectChangeRoom?.room_name}}</div>
            </div>
          </div>
        </div>
        <div class="setbox" style="padding-bottom: 0px;">换入人员信息</div>
        <hr>
        <div class="setbox" style="padding-top: 0px;">
          <div class="person" *ngFor="let item of renterList">
            {{item?.name}} | {{item?.sex=='MALE'?'男':'女'}} | {{item?.cret_num}} | {{item?.phone}}
          </div>
        </div>
        <div class="text-center mb20">
          <button type="button" class="btn btn-primary mr20" (click)="confirmChange()">确认</button>
          <button type="button" (click)="modal.hide();choose_room_modal.show();" class="btn btn-secondary">返回</button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- 操作提示 -->
<div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static', keyboard: false}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name" (click)="stopP($event)">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-static-name" class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideSuccess()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body text-center">
        <p class="text-dark mt-3 mb-3 font16">换房成功！</p>
        <p class="text-dark mt-3 mb-3 font16" *ngIf="doubleDoorLock">新房间为一房两锁房型，请至房态图中为入住人补发门锁密码。</p>
        <button class="btn btn-info" (click)="hideSuccess()">关闭</button>
      </div>
    </div>
  </div>
</div>
