/**
 * @description:常量定义
 *
 * @Author: yinqiang
 * @Date: 2018-03-07 21:05:12
 * @Last Modified by: yinqiang
 * @Last Modified time: 2018-03-28 15:32:10
 */
export class Constants {

  public static CURRENT_COMPANY_ID: string = "CURRENT_COMPANY_ID";

  /**
   *  获取token的KEY
   */
  public static TOKEN_KEY: string = "TOKEN";
  /**
   *  用户信息KEY
   */
  public static USER_INFO: string = "PMS_USER_INFO";
  /**
   *    过期时间KEY
   **/
  public static EXPIRE_TIME: string = "EXPIRE_TIME";

  /**
   * 项目ID
   * @type {string}
   */
  public static PROJECT_ID: string = "PROJECT_ID";

  /**
   *  获取当前模块子模块信息KEY
   */
  public static MODULE_ID = "MODULE_KEY";

  /**
   *  获取权限码key
   */
  public static PRIVILEGE_CODES = "PRIVILEGE_CODES";

  /**
   * 操作类权限字段key
   */
  public static OPERATION_PRIVILEGES: string = "OPERATION_PRIVILEGES";

  /**
   * resulve 操作权限码
   */
  public static OPERATION_PRIVILEGES_RESOLVE: string = "operation_priviliges";

  /**
   * 用户拥有的权限ID 集合
   */
  public static USER_PRIVILEGE_IDS: string = "USER_PRIVILEGE_IDS";

  /**
   *  当前用户拥有的操作类型key
   */
  public static USER_OPERATE_PRIVILEGES: string = "USER_OPERATE_PRIVILEGES";

  /**
   *  存放当前用户的部门ID
   */
  public static CURRENT_USER_DEPART_ID: string = "CURRENT_USER_DEPART_ID";

  /**
   * 存放当前用户的来源，默认为 web
   * @type {string}
   */
  public static CURRENT_USER_SOURCE: string = 'CURRENT_USER_SOURCE';

  public static HAS_CHOOSE_PROJECT_ID: string = 'HAS_CHOOSE_PROJECT_ID';

  //巡房记录跳过不提示
  public static NON_PRESENTMENT: string = 'NON_PRESENTMENT';

  // 公司id
  public static COMPANY_LIST: string = 'COMPANY_LIST';

  /**
   * 登录设备唯一id
   */
  public static LOGIN_UNIQUE_TAG: string = 'loginUniqueTag';

  /**
   * 登录设备唯一id过期key
   */
  public static LOGIN_UNIQUE_TAG_EXPIRE_TIME: string = "LOGIN_UNIQUE_TAG_EXPIRE_TIME";

  /**
   * LocalStorageclear时的白名单
   */
  public static LOCAL_STORAGE_WHITE_LIST: Array<string> = [Constants.LOGIN_UNIQUE_TAG, Constants.LOGIN_UNIQUE_TAG_EXPIRE_TIME];

  /**
   * 微信绑定code
   */
  public static WECHAT_CODE: string = "code";
  public static WECHAT_STATE: string = "state";

  public static LOGIN_BACKURL='login_backurl';
  public static LOGIN_COMPANYID='login_companyid';
  public static LOGIN_PROJECTID='login_projectid';

  public static LOGIN_SYSTEMTYPE='login_systemtype';



  /**
   * SessionStorageclear时的白名单
   */
  public static SESSION_STORAGE_WHITE_LIST: Array<string> = [
    Constants.WECHAT_CODE,
    Constants.WECHAT_STATE,

    Constants.LOGIN_BACKURL,
    Constants.LOGIN_COMPANYID,
    Constants.LOGIN_PROJECTID,
  ];


  // app 交互
  public static APP = {
    // call app
    CALL_TYPE : {
      SINGLE:'single',
      MULTI:'multi',
      // 扫码
      SCAN_LINK: 'scanLink',
    },

    // trigger js
    TRIGGER_TYPE: {
      CUSTOM_TYPE: 'CUSTOM_TYPE',
      QRCODE_WORK_REMIND: 'QRCODE_WORK_REMIND',
    },

    KEY: {
      QRCODE_WORK_REMIND: 'QRCODE_WORK_REMIND'
    }
  };
}

/**
 * @Description:   操作类型：增删改查
 * @Param:
 * @return:
 * @Author: zhoujiahao
 * @Date: 2018-03-22  17:00
 */
export const OperationType = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  QUERY: 'QUERY',
  TOGGLE: 'TOGGLE'
};
