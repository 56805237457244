<div class="setbox goodslist">
  <div class="goodslist-header">
    <div class="btn-list">
      <!--<button class="btn btn-info" (click)="goBack()">返回退房</button>-->
      <!--</button>-->
      <button class="btn btn-info" (click)="printFrame()">打印交接单</button>
    </div>
  </div>
  <div class="setbox">
    <div class="frame-content">

      <app-pdf-view [src]="iframe"></app-pdf-view>

    </div>
  </div>
</div>


