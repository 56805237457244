<div class="setbox-top flex padd15">
  <div class="flex-content">
    <div class="row">
      <div class="col-12 col-md-3 col-lg-2 col-2xl-2">
        <div class="form-group">
          <label>选择房间：</label>
          <select class="w-100" [(ngModel)]="roomId">
            <option value=0>全部房间</option>
            <option *ngFor="let item of renterRoomInfos" [value]="item.room_id">{{item.room_name}}</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-3 col-lg-3 col-2xl-2">
        <div class="form-group">
          <label>住客姓名</label>
          <input type="text" [(ngModel)]="searchContext"  class="form-control w-100" placeholder="请输入住客姓名">
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-4 col-2xl-4">
        <div class="form-group">
          <label>&nbsp;</label>
          <div>
            <button (click)="searchData(1)" class="btn btn-info mr-2" type="button"><i class="fa fa-search"></i>搜索</button>
          </div>
        </div>
      </div>
    </div>

    <div class="scrollx-head">
      <table class="table table-bordered table-striped scrollx-body">
        <thead class="thead">
        <tr>
          <th>房间号</th>
          <th>住客姓名</th>
          <th>入住日期</th>
          <th>退宿日期</th>
          <th>退宿结算单</th>
          <th>是否欠费</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngIf="renterCheckoutRecordListResponse?.renter_checkout_record_list">
          <tr *ngFor="let item of renterCheckoutRecordListResponse?.renter_checkout_record_list | paginate: {itemsPerPage: renterCheckoutRecordListRequest?.page_size,
          currentPage: renterCheckoutRecordListRequest?.page_num,totalItems: renterCheckoutRecordListResponse?.total,id:'renterCheckoutRecordListPage'};let i = index">
            <td>{{item?.room_name}}</td>
            <td>{{item?.renter_name}}</td>
            <td>{{item?.check_in_time|date:"yyyy-MM-dd"}}</td>
            <td>{{item?.checkout_time|date:"yyyy-MM-dd"}}</td>
            <td>
              <button *ngIf="item.renter_checkout_id"
                      [routerLink]="['/system_manage/signed/renter/checkout/record/detail',item?.renter_checkout_id]"
                      type="button" class="numberColorBlue">{{item?.renter_checkout_number}}</button>
            </td>
            <td>
              <span *ngIf="item.in_arrears">是</span>
              <span *ngIf="!item.in_arrears">否</span>
            </td>
          </tr>
        </ng-container>

        <tr *ngIf="!renterCheckoutRecordListResponse?.total || renterCheckoutRecordListResponse?.total == 0">
          <!-- pc用 -->
          <td colspan="6" class="show2">未查询到相关数据</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="text-right mt-3 pages" *ngIf="renterCheckoutRecordListResponse?.total > 0">
      <span class=" mr-4">共{{renterCheckoutRecordListResponse?.total}}条记录</span>
      <pagination-controls id="renterCheckoutRecordListPage" (pageChange)="searchData($event)" directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                           screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                           screenReaderCurrentLabel="You're on page"></pagination-controls>
    </div>
  </div>
</div>
