import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import 'rxjs/add/operator/toPromise';
import {AuthService} from './common/auth.service';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {CustomResponse} from '../model/CustomResponse';
import {WorkService} from "./common/work.service";
import {WorkModel} from "../model/common/common.model";
import {environment} from '../../environments/environment';
import {MessageModalInfo} from '../model/modal/message-model';
import {LoadingInterceptor} from "../interceptor/loading.interceptor";
import {LocalStorage} from "../common/localstorage";
import {Constants} from "../common/constants";
import {Observable} from "rxjs";
import {timeout} from 'rxjs/operators';

@Injectable()
export class RequestService {

  private static RESPONSE_SUCCESS = 'SUCCESS';
  // TOKEN 过期
  private static RESPONSE_TOKEN_EXPIRED = '50010016';

  private static TIMEOUT_TIME = 60 * 1000;

  private static NO_PROJECT = 'NO_PROJECT';

  private static NO_COMPANY = 'NO_COMPANY';

  constructor(private router: Router,
              private http: HttpClient,
              private injector: Injector,
              private workService: WorkService) {
  }

  authGet<T extends CustomResponse>(url: string, queryData?: any, needLoading?: boolean): Promise<any> {
    return this.commonHttp(url, 'get', queryData, true, null, needLoading);
  }

  authLongGet<T extends CustomResponse>(url: string, queryData?: any, timeouts?: number, needLoading?: boolean): Promise<any> {
    return this.commonHttp(url, 'get', queryData, true, timeouts, needLoading);
  }

  authPost<T extends CustomResponse>(url: string, data: any, needLoading?: boolean): Promise<any> {
    return this.commonHttp(url, 'post', data, true, null, needLoading);
  }

  authLongPost<T extends CustomResponse>(url: string, queryData?: any, timeouts?: number, needLoading?: boolean): Promise<any> {
    return this.commonHttp(url, 'post', queryData, true, timeouts, needLoading);
  }

  commonGet<T extends CustomResponse>(url: string, queryData?: any, needLoading?: boolean): Promise<any> {
    return this.commonHttp(url, 'get', queryData, false, null, needLoading);
  }

  commonPost<T extends CustomResponse>(url: string, data: any, needLoading?: boolean): Promise<any> {
    return this.commonHttp(url, 'post', data, false, null, needLoading);
  }

  commonHttp<T extends CustomResponse>(url: string, method: string, queryData?: any, needToken?: boolean,
                                       timeouts?: number, needLoading?: boolean): Promise<any> {
    return this.commonHttp2(url, method, queryData, needToken, timeouts, true, needLoading);
  }

  commonHttp2<T extends CustomResponse>(url: string, method: string, queryData?: any,
                                        needToken?: boolean, timeouts?: number,
                                        iscatch?: boolean,
                                        needLoading?: boolean
  ): Promise<any> {
    // header
    // header 中不能传空值 不然会报错
    let header = new HttpHeaders().append('Content-Type', 'application/json; charset=utf-8');

    let authService = this.injector.get(AuthService);

    if (!authService.isExpired()) {

      let token = authService.getToken();
      header = header.set('Authorization', token);
    }

    if (needToken) {
      let token = authService.getAuthToken();
      if (!token) {
        return this.router.navigate(['/login']);
      }
      header = header.set('Authorization', token);
    }
    if (needLoading) {
      header = header.append(LoadingInterceptor.WITH_LOADING, "");
    }

    let resource;
    if (method === 'get') {
      // convert
      const reqData = this.getSourceConvert(queryData);
      for (const item in reqData) {
        if (!reqData[item] && reqData[item] !== false) {
          delete reqData[item];
        }
      }
      resource = this.http.get<T>(url, {
        params: reqData,
        headers: header,
        observe: 'response'
      });
    } else {
      // convert
      const reqData = this.postSourceConvert(queryData);
      resource = this.http.post<T>(url, reqData, {
        headers: header,
        observe: 'response'
      });
    }

    //请求成功之后重新设置token过期时间
    return this.commonHandler(resource, timeouts)
      .toPromise()
      .then((response: HttpResponse<CustomResponse>) => {

        // project valid

        if (response.headers) {
          // 先判断公司
          if (response.headers.has(RequestService.NO_COMPANY) && response.headers.get(RequestService.NO_COMPANY)) {
            this.workService.event_bus.emit(new WorkModel(this.workService.type.NO_COMPANY));
          } else if (response.headers.has(RequestService.NO_PROJECT) && response.headers.get(RequestService.NO_PROJECT)) {
            // 再判断项目
            this.workService.event_bus.emit(new WorkModel(this.workService.type.NO_PROJECT));
          }
        }

        return response.body;
      })
      .then((response: CustomResponse) => {
        if (response && response.status_code === RequestService.RESPONSE_SUCCESS) {
          if (needToken) {
            //请求成功之后重新设置token过期时间
            // tslint:disable-next-line:no-shadowed-variable
            let authService = this.injector.get(AuthService);
            authService.setToken(authService.getAuthToken());
          }

          return response.biz_response;
        } else {
          if (iscatch) {
            return this.handleError(response, url);
          } else {
            return response;
          }
        }
      })
      .catch(err => {
        return this.handleError(err, url, iscatch);
      });
  }

  getSourceConvert(queryData?: any, sourceType?: any): any {
    sourceType = {source: 'WEB'};
    // let resource;
    let reqData;
    if (queryData) {
      reqData = Object.assign(sourceType, queryData);
    } else {
      reqData = sourceType;
    }
    return reqData;
  }

  postSourceConvert(data: any, sourceType?: any): any {
    // if (data) {
    // Object.assign(reqData, sourceType);
    // sourceType = this.sourceService.getSourceType();
    const reqData = {
      device_id: '',
      dt_monitor: '',
      language: 'ZH_CN',
      source: 'WEB',
      version: '1.0',
      token: '',
      biz_request: data
    };
    return reqData;
    // } else {
    //   return;
    // }
  }

  commonHandler(ob: Observable<Object>, timeouts?: number): Observable<Object> {
    // 设置超时时长
    return ob.pipe(timeout(timeouts ? timeouts : RequestService.TIMEOUT_TIME));
  }

  private handleError(error: any, url: string, iscatch = true): any {

    // 不一定是转换过的返回
    let response: any = error.error || error;

    // 添加报错间隔判断 (interval request 才需要加次判断)
    let base64UrlStr = window.btoa(url);

    let goout = false;

    this.getIntervarRequestList().forEach(irl => {
      let alertErr = false;
      if (environment.apiHost + irl.url === url) {
        goout = true;
      }
    });

    if (!goout && iscatch) {
      // camel case
      if ('TimeoutError' === error.name) {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '系統繁忙请稍后再试'));
      } else if ((response.status_code || response.statusCode) === RequestService.RESPONSE_TOKEN_EXPIRED) {
        let authService = this.injector.get(AuthService);
        authService.tokenRemove();
      } else {
        if (!response.message || response.message === 'Cannot read property \'status_code\' of null') {
          let messageModalInfo = new MessageModalInfo();
          messageModalInfo.type = 2;
          messageModalInfo.title = "操作提示";
          messageModalInfo.okBtn = "我知道了";
          messageModalInfo.html = `<h4 class="text-center">网速报警：您的网络速度为50kb/s，网速过低将导致您的系统响应速度减慢。</h4><hr>
        <p class="tips-p">解决方法：</p>
        <p class="tips-p">1、如连接WIFI，可尝试连接有线网络；</p>
        <p class="tips-p">2、如为低速网络套餐，可尝试更换高速网络套餐；</p>
        <p class="tips-p">3、继续耐心等待系统慢速响应。</p>`;
          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));

          setTimeout(() => {
            // 2分钟后自动关闭
            this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_CLOSE));
          }, 2 * 60 * 1000);

        } else {

          let mm = new MessageModalInfo();
          mm.operationMessage = response.message;
          mm.trace_id = response.trace_id;

          this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, mm));
        }
      }
    }

    this.workService.event_bus.emit(new WorkModel(this.workService.type.PANEL_LOADING_END, event));

    return Promise.reject(response);
  }

  getIntervarRequestList = (): Array<TempDTO> => {
    return [
      // // 待办事项数量统计
      // new TempDTO("待办事项数量统计", "L2FsZXJ0L2RhdGFTdGF0"),
      // // 工作台告警列表
      // new TempDTO("工作台告警列表", "L2FsZXJ0L2xpc3Q="),
      // // 优惠审批列表
      // new TempDTO("优惠审批列表", "L2JhY2tsb2cvbGlzdC9kaXNjb3VudA=="),
      // // 待验收门锁列表
      // new TempDTO("待验收门锁列表", "L2JhY2tsb2cvbGlzdC9kb29ybG9jaw=="),
      // // 维修保洁列表
      // new TempDTO("维修保洁列表", "L2JhY2tsb2cvbGlzdC9yZXBhaXI="),
      // // 数量统计
      // new TempDTO("投诉列表", "L2NvbXBsYWludHMvbGlzdA=="),
      // 待办事项列表
      new TempDTO("待办事项数量统计", "schedule/list"),
    ];
  };

}

class TempDTO {
  private _name?: string;
  private _url?: string;
  private _burl?: string;

  constructor(name: string, url: string) {
    this._name = name;
    this._url = url;
    this._burl = window.btoa(url);
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get url(): string {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }

  get burl(): string {
    return this._burl;
  }

  set burl(value: string) {
    this._burl = value;
  }
}
