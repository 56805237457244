import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageModalInfo } from '../../../model/modal/message-model';
import { WorkService } from '../../../service/common/work.service';
import { WorkModel } from '../../../model/common/common.model';
import {ModalDirective, ModalOptions} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html'
})
export class MessageModalComponent {

  @ViewChild(ModalDirective, { static: true }) public childModal: ModalDirective;

  @Input() messageModalInfo: MessageModalInfo;

  constructor(private router: Router,
              private workService: WorkService) {
  }

  show(): void {

    if (!this.childModal.config) {
      this.childModal.config = new ModalOptions();
    }
    this.childModal.config.backdrop = 'static';
    this.childModal.config.keyboard = false;
    this.childModal.show();

    if (this.messageModalInfo && this.messageModalInfo.timeout) {
      setTimeout(() => {
        this.childModal.hide();
        if (this.messageModalInfo && this.messageModalInfo.returnUrl) {
          this.router.navigateByUrl(this.messageModalInfo.returnUrl);
        }
      }, this.messageModalInfo.timeout);
    }
  }

  hide(): void {
    this.childModal.hide();
  }

  stayHere(): void {
    this.childModal.hide();

    if (this.messageModalInfo && this.messageModalInfo.cancelCallback) {
      this.messageModalInfo.cancelCallback.emit();
    }
  }

  returnTo(): void {
    this.childModal.hide();

    this.workService.event_bus.emit(new WorkModel(this.workService.type.MODAL_CLASS_REMOVE));

    if (this.messageModalInfo && this.messageModalInfo.callback) {
      this.messageModalInfo.callback.emit();
    }
    if (this.messageModalInfo && this.messageModalInfo.returnUrl) {
      this.router.navigateByUrl(this.messageModalInfo.returnUrl);
    }

  }

}
