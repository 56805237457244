import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";
import { CheckoutRoomViewDTO, CheckoutRoomViewRequest, CheckoutRoomViewResponse } from '../../../../model/signed/checkout.model';
import { SignedService } from '../../../../service/signed/signed.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-checkout-room-select',
  templateUrl: './checkout-room-select.component.html',
  styleUrls: ['./checkout-room-select.component.css']
})
export class CheckoutRoomSelectComponent implements OnInit {

  @Input() lease_id: number;
  // 退房房间选择model
  @ViewChild('checkoutRoomView', { static: true }) checkoutRoomViewModal: ModalDirective;
  // 回调
  callback: EventEmitter<any> = new EventEmitter<any>();

  checkoutRoomViewRequest: CheckoutRoomViewRequest = new CheckoutRoomViewRequest();
  checkoutRoomViewResponse: CheckoutRoomViewResponse;

  checkAll = false;
  ids: Array<number> = [];

  constructor(private signedService: SignedService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
  }

  showModal() {

    this.checkoutRoomViewRequest.lease_id = this.lease_id;
    this.signedService.checkoutRoomChooseView(this.checkoutRoomViewRequest).then(data => {
      this.checkoutRoomViewResponse = data;
      if (this.checkoutRoomViewResponse && this.checkoutRoomViewResponse.checkout_room_list && this.checkoutRoomViewResponse.checkout_room_list.length == 1) {
        // 一间房跳过选择
        this.ids = [this.checkoutRoomViewResponse.checkout_room_list[0].room_id];
        this.openCheckoutConfirm();

      } else {
        this.checkoutRoomViewModal.show();
      }

    });
  }

  roomCheckAll() {
    if (this.checkAll) {
      if (this.checkoutRoomViewResponse.checkout_room_list != null) {
        this.ids = this.checkoutRoomViewResponse.checkout_room_list.map(e => e.room_id);
        this.checkoutRoomViewResponse.checkout_room_list.forEach(e => e.roomCheck = true);
      }
    } else {
      if (this.checkoutRoomViewResponse.checkout_room_list != null) {
        this.ids = [];
        this.checkoutRoomViewResponse.checkout_room_list.forEach(e => e.roomCheck = false);
      }
    }
  }

  selectOne(info: CheckoutRoomViewDTO) {
    if (info.roomCheck) {
      this.ids.push(info.room_id);
    } else {
      this.ids = this.ids.filter(e => e !== info.room_id);
    }

    //是否全选
    let all = true;
    this.checkoutRoomViewResponse.checkout_room_list.forEach(e =>{
      if(!e.roomCheck){
        all = false;
      }
    });
    this.checkAll = all;
  }

  openCheckoutConfirm() {
    this.router
      .navigate(["../../checkout"],
        {
          queryParams: {
            lease_id: this.checkoutRoomViewResponse.lease_info.lease_id,
            ids: this.ids
          },
          relativeTo: this.activatedRoute
        });
  }

}
