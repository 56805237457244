<div class="card" style="margin-bottom: 0;" *ngIf="showView=='BILL'">
  <!--<div class="card-header yellow1">-->
  <!--账单及费用-->
  <!--</div>-->
  <div class="padd15 yellow">
    <div class="card-body">
      <div class="row">
        <div class="col12 col-md-5 mb10">
          <label class="form-check-label hei32">筛选：</label>
          <select style="width: 25%;margin-right: 3%" [(ngModel)]="room_id" (change)="serach_bill()">
            <option value="">全部房间</option>
            <option *ngFor="let item of  roomInfoList; let i=first " [ngValue]="item.room_id">
              {{item.room_name}}
            </option>
          </select>
          <select style="width: 25%;margin-right: 3%" [(ngModel)]="leaseRoomBillListParam.bill_type_id"
                  (change)="serach_bill()">
            <option *ngFor="let item of billTypeList; let i=first " [ngValue]="item.bill_type_id">
              {{item.bill_type_name}}
            </option>
          </select>
          <select style="width: 25%" [(ngModel)]="leaseRoomBillListParam.bill_status" (change)="serach_bill()">
            <option *ngFor="let item of  billStatus; let i=first " [ngValue]="item.id">
              {{item.value}}
            </option>
          </select>
        </div>
        <div class="col12 col-md-3 mb10 text-danger hei32" style="line-height: 32px;">
          {{queryBillTypeName}}
          <ng-template [ngIf]="arrearages">
            欠费合计：{{arrearages}}
          </ng-template>
          <ng-template [ngIf]="!arrearages">
            欠费合计：此合同无欠费
          </ng-template>
        </div>
        <div class="col12 col-md-4 mb10">
          <button class="btn btn-warning float-right mb10" (click)="switch('RENTER_BILL')">
            <i class="fa fa-refresh" aria-hidden="true"></i>切换至住客账单
          </button>
          <button class="btn btn-warning2 float-right mb10" (click)="switch('PAYLOG')">
            <i class="fa fa-refresh" aria-hidden="true"></i>切换至流水视图
          </button>
          <button class="btn btn-warning2 float-right mb10" (click)="staticModal.show();filterOverdueBill();"
                  *ngIf="btn_privileges.LEASE_BILL_SETTING">
            <i class="fa fa-refresh" aria-hidden="true"></i>账单设置
          </button>
          <button *ngIf="btn_privileges.BILL_ADVANCE" class="btn btn-warning2 float-right mb10"
                  (click)="openAdvanceBillModal()" >
            提前出账
          </button>
          <button class="btn btn-warning2 float-right mb10" type="button"
                  *ngIf="btn_privileges.BILL_RECONCILIATION"
                  (click)="settleModalShow(true)">系统调账
          </button>

          <button class="btn btn-warning float-right mb10" type="button"
                  (click)="billChangeShow()">费用调整
          </button>

        </div>
      </div>

      <div class="scrollx-head">
        <table class="table table-bordered table-striped table-fix-scroll v2 table-bg-gray scrollx-body scrollxy-width80">
          <thead class="table-bg-gray">
          <tr>
            <th>类型</th>
            <th>账单编号</th>
            <th>房号</th>
            <th>金额</th>
            <th>账单周期</th>
            <th *ngIf="wentDutch">费用分摊总额</th>
            <th>签约人应收/付金额</th>
            <th>账单状态</th>
            <th>付款方/收款方</th>
            <th>付款状态</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let leaseRoomBill of leaseRoomBillList | paginate: {itemsPerPage: leaseRoomBillListParam.page_size,
                          currentPage: leaseRoomBillListParam.page_num,totalItems: total,id:'bill' } ; let i=index;"
              [ngClass]="{'text-secondary':leaseRoomBill.bill_charge_off_status=='WAIT'}"
              class="bg-white">
            <td>
              <span style="width: 70%">{{leaseRoomBill.bill_type_name}}</span>
              <span style="width: 10%;height: 100%" title="{{leaseRoomBill.create_explain}}" style="color: #61808f;cursor: pointer">{{leaseRoomBill.create_type === "ADD"?"手":"&nbsp;&nbsp;&nbsp;"}}</span>
              <span style="width: 10%;height: 100%" style="color: #61808f;cursor: pointer">&nbsp;&nbsp;&nbsp;</span>
              <span style="width: 10%;height: 100%" title="后付费账单" style="color: #61808f;cursor: pointer">{{leaseRoomBill.later_bill?"后":"&nbsp;&nbsp;&nbsp;"}}</span>
            </td>
            <td>{{leaseRoomBill.bill_no}}</td>
            <td>{{leaseRoomBill.room_name}}</td>
            <td>{{leaseRoomBill.bill_amount}}</td>
            <td>{{leaseRoomBill.bill_start_time | date:'yyyy.MM.dd'}}
              - {{leaseRoomBill.bill_end_time | date:'yyyy.MM.dd'}}</td>
            <td *ngIf="wentDutch">{{leaseRoomBill.share_amount}}</td>
            <td>{{leaseRoomBill.subject_pay_amount}}</td>
            <td>{{leaseRoomBill.bill_charge_off_status_name}}</td>
            <td>{{leaseRoomBill.pay_user}}</td>
            <td>{{leaseRoomBill.bill_status_name}}</td>
            <td>
              <button type="button" class="btn btn-outline-primary" (click)="showDetail(leaseRoomBill.bill_id)">
                查看详细
              </button>

              <button *ngIf="leaseRoomBill.create_type==='ADD' && +leaseRoomBill.received===0"
                class="btn btn-outline-danger" (click)="delBill(leaseRoomBill.bill_id)">删除</button>
            </td>
          </tr>
          <tr *ngIf="!leaseRoomBillList || leaseRoomBillList.length===0" class="text-right">
            <td colspan="5" class="show1 bg-white">本次未查询到相关数据</td>
            <td colspan="8" class="show2 bg-white">本次未查询到相关数据</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="card" style="margin-bottom: 0;" *ngIf="showView=='PAYLOG'">
  <!--<div class="card-header yellow1">-->
  <!--收退款流水明细-->
  <!--</div>-->
  <div class="padd15 yellow">
    <div class="card-body">
      <div class="row">
        <div class="col12 col-md-5 mb10">
          <label class="form-check-label hei32" style="width: 10%">筛选：</label>
          <select style="width: 40%;margin-right:3%;" [(ngModel)]="room_id" (change)="serach_bill()">
            <option value="">全部房间</option>
            <option *ngFor="let item of  roomInfoList; let i=first " [ngValue]="item.room_id">
              {{item.room_name}}
            </option>
          </select>
          <input style="width: 40%" type="text" placeholder="流水号" [(ngModel)]="leaseRoomBillListParam1.bill_no">
        </div>
        <div class="col12 col-md-5 mb10 text-danger">
          <button class="btn btn-info" type="button" (click)="serach_pay()" style="background: rgb(72,171,247);">
            <i class="fa fa-search"></i>搜索
          </button>
        </div>
        <div class="col12 col-md-2 mb10">
          <button class="btn btn-warning mb10" (click)="switch('BILL')"
                  style="background: rgb(250,187,60);float:right;">
            <i class="fa fa-refresh" aria-hidden="true"></i>切换成账单视图
          </button>
        </div>
      </div>

      <div class="table-box pre-scrollable" style="max-height: 434px;">
        <table class="table table-bordered border-striped">
          <thead>
          <tr>
            <th>流水号</th>
            <th>付款时间</th>
            <th>付款方式</th>
            <th>金额</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let payLog of payLogList | paginate: {itemsPerPage: leaseRoomBillListParam1.page_size,
                          currentPage: leaseRoomBillListParam1.page_num,totalItems: total1,id:'pay' } ; let i=index;">
            <td>{{payLog.pay_no}}</td>
            <td>{{payLog.pay_time | date:'yyyy/MM/dd HH:mm:ss'}}</td>
            <td>{{payLog.pay_type_name}}</td>
            <td>{{payLog.pay_amount}}</td>
            <td>
              <button type="button" class="btn btn-outline-primary" (click)="showDetail(payLog.pay_id)">查看详细
              </button>
            </td>
          </tr>
          <tr *ngIf="!payLogList || payLogList.length===0" class="text-right">
            <td colspan="5" class="show1">本次未查询到相关数据</td>
            <td colspan="8" class="show2">本次未查询到相关数据</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="card" style="margin-bottom: 0;" *ngIf="showView=='RENTER_BILL'">
  <!--<div class="card-header yellow1">-->
  <!--账单及费用-->
  <!--</div>-->
  <div class="padd15 yellow">
    <div class="card-body">
      <div class="row">
        <div class="col12 col-md-5 mb10">
          <label class="form-check-label hei32">筛选：</label>
          <select style="width: 25%;margin-right: 3%" [(ngModel)]="room_id" (change)="queryRenterBill()">
            <option value=0>全部房间</option>
            <option *ngFor="let item of  roomInfoList; let i=first " [ngValue]="item.room_id">
              {{item.room_name}}
            </option>
          </select>
          <select style="width: 25%;margin-right: 3%" [(ngModel)]="leaseRenterBillListRequest.bill_type_code"
                  (change)="queryRenterBill()">
            <option *ngFor="let item of renterBillTypeList; let i=first " [ngValue]="item.bill_type_code">
              {{item.bill_type_name}}
            </option>
          </select>
          <select style="width: 25%" [(ngModel)]="leaseRenterBillListRequest.pay_status" (change)="queryRenterBill()">
            <option *ngFor="let item of  renterBillStatus; let i=first " [ngValue]="item.id">
              {{item.value}}
            </option>
          </select>
        </div>
        <div class="col12 col-md-3 mb10 text-danger hei32" style="line-height: 32px;">
          {{queryRenterBillTypeName}}
          <ng-template [ngIf]="arrears">
            欠费合计：{{arrears}}
          </ng-template>
          <ng-template [ngIf]="!arrears">
            欠费合计：暂无住客欠费
          </ng-template>
        </div>
        <div class="col12 col-md-4 mb10">
          <button class="btn btn-warning2 float-right mb10" (click)="switch('BILL')">
            <i class="fa fa-refresh" aria-hidden="true"></i>切换至合同账单
          </button>

        </div>
      </div>

      <div class="scrollx-head">
        <table class="table table-bordered table-striped table-fix-scroll v2 table-bg-gray scrollx-body scrollxy-width80">
          <thead class="table-bg-gray">
          <tr>
            <th>账单类型</th>
            <th>住客账单编号</th>
            <th>房间号</th>
            <th>住客姓名</th>
            <th>证件号</th>
            <th>账单周期</th>
            <th>出账日期</th>
            <th>账单金额</th>
            <th>应收/付金额</th>
            <th>应收/付日期</th>
            <th>支付状态</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let renterBill of leaseRenterBillListDTOS | paginate: {itemsPerPage: leaseRenterBillListRequest.page_size,
                          currentPage: leaseRenterBillListRequest.page_num, totalItems: total, id:'renter_bill' } ; let i=index;"
              class="bg-white">
            <td>
              <span style="width: 70%">{{renterBill.bill_type_name}}</span>
            </td>
            <td>{{renterBill.bill_no}}</td>
            <td>{{renterBill.room_name}}</td>
            <td>{{renterBill.renter_name}}</td>
            <td>{{renterBill.cert_code}}</td>
            <td>{{renterBill.bill_start_time | date:'yyyy.MM.dd'}}
              - {{renterBill.bill_end_time | date:'yyyy.MM.dd'}}</td>
            <td>{{renterBill.pay_out_time | date:'yyyy.MM.dd'}}</td>
            <td>{{renterBill.bill_amount}}</td>
            <td>{{renterBill.actual_bill_amount}}</td>
            <td>{{renterBill.plan_pay_time | date:'yyyy.MM.dd'}}</td>
            <td>{{renterBill.bill_status_name}}</td>
            <td>
              <button type="button" class="btn btn-outline-primary" (click)="showRenterBillDetail(renterBill.bill_id, renterBill.renter_went_dutch_bill_id)">
                查看详细
              </button>
            </td>
          </tr>
          <tr *ngIf="!leaseRenterBillListDTOS || leaseRenterBillListDTOS.length===0" class="text-right">
            <td colspan="5" class="show1 bg-white">本次未查询到相关数据</td>
            <td colspan="8" class="show2 bg-white">本次未查询到相关数据</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- 勾选逾期金账单-->
<div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>账单设置</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="staticModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col12 col-md-4 mb10">
            <p>选择房间</p>
            <select class="w-100" [(ngModel)]="roomIdOfBillSetting" (change)="filterBillByRoomId()">
              <option value="">全部房间</option>
              <option *ngFor="let item of  roomInfoList; let i=first " [ngValue]="item.room_id">
                {{item.room_name}}
              </option>
            </select>
          </div>
          <div class="col12 col-md-4 mb10">
            <p>账单类型</p>
            <select class="w-100">
              <option value="" selected>滞纳金</option>
            </select>
          </div>
        </div>
        <label>未支付账单如下：</label>
        <table class="table table-bordered border-striped">
          <thead>
          <tr>
            <th>
              <input type="checkbox"
                     [checked]="checkedBillIds.length == overdueBillList.length && checkedBillIds.length > 0"
                     (click)="checkAll($event)">
            </th>
            <th>类型</th>
            <th>房号</th>
            <th>金额</th>
            <th>账单周期</th>
            <th>账单状态</th>
            <th>付款状态</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let leaseRoomBill of overdueBillList; let i=index;">
            <td>
              <input type="checkbox" [checked]="(checkedBillIds.indexOf(leaseRoomBill.bill_id)>=0)"
                     (click)="checkCurrentBill($event,leaseRoomBill.bill_id)">
            </td>
            <td>{{leaseRoomBill.bill_type_name}}</td>
            <td>{{leaseRoomBill.room_name}}</td>
            <td>{{leaseRoomBill.bill_amount}}</td>
            <td>{{leaseRoomBill.bill_start_time | date:'yyyy.MM.dd'}}
              - {{leaseRoomBill.bill_end_time | date:'yyyy.MM.dd'}}</td>
            <td>{{leaseRoomBill.bill_charge_off_status_name}}</td>
            <td>{{leaseRoomBill.bill_status_name}}</td>
          </tr>
          <tr *ngIf="!leaseRoomBillList || leaseRoomBillList.length===0" class="text-right">
            <td colspan="5" class="show1">无滞纳金账单</td>
            <td colspan="8" class="show2">无滞纳金账单</td>
          </tr>
          </tbody>
        </table>
        <label>已选{{checkedBillIds.length}}个账单</label>
        <div class="row col-12" style="padding-left:0px;">
          <div class="col-md-6 form-check-inline">
            <div class="col-md-5">
              <input type="radio" name="radio1" value="true" [(ngModel)]="radio">设为无需付
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="nextStep()" [attr.disabled]="checkedBillIds.length > 0 ? null: true">
          下一步
        </button>
        <button class="btn btn-default ml-2" (click)="staticModal.hide()">取消</button>
      </div>
    </div>
  </div>
</div>

<!-- 确认无需付 -->
<div class="modal fade" bsModal #staticModal2="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" keyboard="true"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-orange modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left text-orange">账单设置</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="staticModal2.hide();">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mb-2">
            已选{{checkedBillIds.length}}个账单
          </div>
        </div>
        <div class="row paddingl0">
          <div class="col col-lg-4 col-md-4 mt-1">
            <select class="w-100" [(ngModel)]="selectedUserPhone">
              <option value="ALL" disabled>审核人</option>
              <option *ngFor="let man of auditList" [value]="man.user_phone">{{man.user_name}}({{man.user_phone}})
              </option>
            </select>
            <div *ngIf="!selectedUserPhone" class="text-danger">审核用户不能为空</div>
          </div>
          <div class="col col-lg-4 col-md-4 mt-1">
            <input class="w-100" type="text" placeholder="请输入短信验证码" maxlength="4"
                   [(ngModel)]="shortMessageVerificationCode">
            <div *ngIf="!shortMessageVerificationCode" class="text-danger">验证码不能为空</div>
            <div *ngIf="auditCodeError " class="text-danger">短信验证码验证错误</div>
          </div>
          <div class="col col-lg-4 col-md-4 mt-1 pl-0 ml-0">
            <button class="btn btn-primary" style="margin-right: 5px;" (click)="getCode()"
                    [attr.disabled]="(selectedUserPhone === 'ALL' || time>0 && time< 60) ? true : null ">
              {{codeBtnStr}}
            </button>
          </div>
        </div>
        <div class="row paddingl0">
          <div class="col col-lg-4 col-md-4 mt-1">
            <select class="w-100" [(ngModel)]="overdueType">
              <option value="" disabled>请选择</option>
              <option value="客户协商">客户协商</option>
              <option value="无法收取">无法收取</option>
              <option value="公寓原因">公寓原因</option>
            </select>
            <div *ngIf="!overdueType" class="text-danger">请选择原因</div>
          </div>
          <div class="col col-lg-8 col-md-8 mt-1">
            <input class="w-100" [(ngModel)]="overdueContent" type="text" placeholder="请描述具体原因，限30字" maxlength="30">
            <div *ngIf="!overdueContent" class="text-danger">请填写具体原因</div>
          </div>
        </div>
        <div class="money-list row ">
          <div class="col-lg-12 mt-4 text-center">
            <button class="btn btn-primary"
                    [attr.disabled]="(selectedUserPhone === 'ALL' || shortMessageVerificationCode === ''
                    || !overdueType || overdueContent == null || overdueContent == '')
                 ? true: null" (click)="confirmThatNoPaymentIsRequired();staticModal2.hide();">提交
            </button>
            <button class="btn btn-default ml-2" (click)="staticModal2.hide();">取消</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 房费代金券详情-->
<div class="modal fade" bsModal #couponDetail="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name" style="z-index: 9999;">
  <div class="modal-dialog modal-primary modal-mm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="couponDetail.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th>房间号</th>
              <th>房费账单周期</th>
              <th>支付金额</th>
              <th>已抵扣金额</th>
              <th>回收金额</th>
            </tr>
            </thead>

            <tbody>
            <tr *ngIf="coupon_info_detail_list == null || coupon_info_detail_list?.length == 0">
              <td colspan="5">本次未查询到相关数据</td>
            </tr>
            <ng-container *ngIf="coupon_info_detail_list?.length != 0">
              <tr *ngFor="let coupon_info_detail of coupon_info_detail_list">
                <td>{{coupon_info_detail.room_name}}</td>
                <td>{{coupon_info_detail.bill_start_time | date:'yyyy.MM.dd'}}
                  - {{coupon_info_detail.bill_end_time | date:'yyyy.MM.dd'}}</td>
                <td>{{coupon_info_detail.recharge_amount}}</td>
                <td>{{coupon_info_detail.used_amount}}</td>
                <td>{{coupon_info_detail.transfer_amount}}</td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
        <div class="text-center mb20">
          <button type="button" class="btn btn-secondary" (click)="couponDetail.hide()"> 关闭</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 系统调账 -->
<div class="modal fade" bsModal #reconciliationModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header bg-warning">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>系统调账</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="reconciliationModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="">
          <p class="text-danger mb-3">*注意* 执行调账操作后，需执行夜审数据方可重新统计。</p>
          <div class="row">
            <div class="col-12 col-lg-8">
              <input type="text" class="form-control" placeholder="请输入账单编号"
                     [(ngModel)]="billReconciliationRequest.bill_no"/>
            </div>
            <div class="col-12 col-lg-4">
              <button class="btn btn-primary mr-2" type="button"
                      (click)="searchBillDetail(billReconciliationRequest.bill_no)">
                搜索
              </button>
            </div>
          </div>
          <p class="text-danger mb-2" *ngIf="isHasNoBill">无此账单，请确认账单编号是否有误</p>
        </div>

        <div class="row mt-2" *ngIf="isShowRecBody">
          <div class="col-12 col-md-6 text-left">
            账单编号：
            <span class="text-primary">{{leaseRoomBillDetail.bill_no}}</span>
          </div>
          <div class="col-12 col-md-6">
            账单类型：
            <span class="text-primary">{{leaseRoomBillDetail.bill_type_name}}</span>
          </div>
        </div>

        <div class="row mt-2" *ngIf="isShowRecBody">
          <div class="col-12 col-md-6">
            所属合同：
            <span class="text-primary">{{leaseRoomBillDetail.contract_num}}</span>
          </div>
          <div class="col-12 col-md-6">
            所属房间：
            <span class="text-primary">{{leaseRoomBillDetail.room_name}}</span>
          </div>
        </div>

        <div class="mt-2" *ngIf="isShowRecBody">
          <table class="table table-bordered">
            <thead class="thead-light">
            <tr>
              <th>类别</th>
              <th>原值</th>
              <th>新值</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>账单周期</td>
              <td>{{leaseRoomBillDetail.bill_start_time | date:'yyyy.MM.dd'}}
                -{{leaseRoomBillDetail.bill_end_time | date:'yyyy.MM.dd'}}</td>
              <td>
                <div class="row">
                  <div class="col-12 col-lg-5 px-0 mx-0">
                    <my-date-picker placeholder="年/月/日" [(ngModel)]="billReconciliationRequest.bill_start_time"
                    ></my-date-picker>
                  </div>
                  <div class="col-12 col-lg-2 px-0 mx-0">-</div>
                  <div class="col-12 col-lg-5 px-0 mx-0">
                    <my-date-picker placeholder="年/月/日" [(ngModel)]="billReconciliationRequest.bill_end_time"
                    ></my-date-picker>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>应付时间</td>
              <td>{{leaseRoomBillDetail.plan_pay_time | date:'yyyy.MM.dd HH:mm'}}</td>
              <td>
                <div class="row">
                  <div class="col-12 col-lg-6 px-0 mx-0">
                    <my-date-picker placeholder="年/月/日" [(ngModel)]="billReconciliationRequest.plan_pay_time"
                    ></my-date-picker>
                  </div>
                  <div class="col-12  col-lg-6 mx-0 text-left"> 00:00
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>账单金额</td>
              <td>{{leaseRoomBillDetail.bill_amount}}</td>
              <td>
                <input type="text" placeholder="请输入金额"
                       (input)="billAmount($event);"
                       [(ngModel)]="billReconciliationRequest.bill_amount">
                <p class="text-danger" *ngIf="billAmountError">账单金额不支持</p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="mt-2" *ngIf="isShowRecBody">
          <p class="mb-3">管理员密码确认：</p>
          <input type="text" class="form-control" placeholder="请输入管理员密码"
                 [(ngModel)]="billReconciliationRequest.confirm_password" (input)="updateConfirmPwd($event)"/>
          <p class="text-danger mb-2" *ngIf="isPasswordNullError">密码不能为空</p>
          <p class="text-danger mb-2" *ngIf="isPasswordError">密码验证不正确，请重新验证</p>
          <p class="text-danger mb-2" *ngIf="isPasswordTimeError">验证次数过多，请稍后重试</p>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary " type="button"
                (click)="confirmBillReconciliationRequest()"
                [attr.disabled]="isHasNoBill || billAmountError || isPasswordError || isPasswordNullError || isPasswordTimeError ? true : null">
          确认
        </button>
        <button class="btn btn-secondary " type="button" (click)="cancelBillReconciliationRequest()">
          取消
        </button>
      </div>
    </div>
  </div>
</div>

<!-- 合同提前出账-->
<div class="modal fade" bsModal #advanceBillModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>合同提前出账</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="advanceBillModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="">
          <p>该合同还有以下账期账单未出，请选择需要提前出账的账期</p>
          <div class="col12 col-md-4 mb10 dip_flex align-items-center">
            <span style="width: 100px;">费用类型:</span>
            <select class="w-100" [(ngModel)]="advanceBillSelect" (change)="changeAdvanceBill()">
              <option *ngFor="let item of  advanceBillOption;" [ngValue]="item?.bill_type_id">
                {{item?.bill_type_name}}
              </option>
            </select>
          </div>
        </div>
        <table class="table table-bordered border-striped mt10">
          <thead>
          <tr>
            <th class="_w15">
              <input type="checkbox" [disabled]="checkboxBill.length === 0" [(ngModel)]="isAdvanceCheckAll"
                     (change)="advanceCheckAll()">全选
            </th>
            <th class="_w50">账期</th>
            <th>系统出账日期</th>
          </tr>
          </thead>
          <tbody *ngIf="!advanceBuillLoading && advanceBillList.length > 0">
          <tr *ngFor="let item of advanceBillList; let i=index;">
            <td>
              <input type="checkbox" [(ngModel)]="checkboxBill[i]" (change)="advanceBillSelectFun()">
            </td>
            <td>{{item?.bill_start_time | date :"yyyy-MM-dd"}} - {{item?.bill_end_time | date :"yyyy-MM-dd"}}</td>
            <td>{{item?.bill_charge_off_time | date :"yyyy-MM-dd"}}</td>
          </tr>
          </tbody>
          <tbody *ngIf="!advanceBuillLoading && advanceBillList.length===0">
          <tr class="text-center">
            <td colspan="3">暂无账期</td>
          </tr>
          </tbody>
          <tbody *ngIf="advanceBuillLoading">
          <tr class="text-center">
            <td colspan="3">数据查询中...</td>
          </tr>
          </tbody>
        </table>
        <p style="color: #FF3300">请按账期顺序选择出账，不支持跨账期出账！</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="submitAdvanceBill()"
                [ngStyle]="{'background': checkboxBill.length > 0?'':'#EBEBEB'}">确定
        </button>
        <button class="btn btn-default ml-2" (click)="advanceBillModal.hide()">取消</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade showMsgModal" bsModal #showMsgModal="bs-modal"
     [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>提示框</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="showMsgModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">
        <p style="padding: 60px 40px;" class="text-center">账单提前出账成功！</p>
      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button"
                    (click)="showMsgModal.hide()"
                    class="btn btn-primary">关闭
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-lease-room-bill-detail #billDetail (billDetailChange)="billToPay($event)"
                            (couponDetailChange)="billToCoupon($event)"></app-lease-room-bill-detail>
<app-pay-log-detail #payLogDetail (payLogDetailChange)="payToBill($event)"></app-pay-log-detail>
<app-renter-went-dutch-bill-detail #renterBillDetail></app-renter-went-dutch-bill-detail>


<div class="modal fade " bsModal #modalChangeLeaseBill="bs-modal"
     [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>费用调整</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalChangeLeaseBill.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">

        <div class="col-12 mt-4">
          <div class="row">
            <div class="col-12 col-md-3">
              <label class="mx-2">请选择房间</label>
              <select [(ngModel)]="bc_room_id" >
                <option value="">全部房间</option>
                <option *ngFor="let item of  roomInfoList" [value]="item.room_id">
                  {{item.room_name}}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-4">
              <label class="mx-2">请选择账单类型</label>
              <select [(ngModel)]="bc_bill_type_id" >
                <option *ngFor="let item of billTypeList" [value]="item.bill_type_id">
                  {{item.bill_type_name}}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-2">
              <button class="btn btn-info" (click)="searchBcBill()" >查询</button>
            </div>
          </div>
        </div>
        <hr>
        <div class="col mt-2" >

          <div class="">
            请选择需要调整的账单：
          </div>
          <div class="mt-2">
            <table class="table table-striped table-bordered v2 table-fix-scroll">
              <thead>
              <tr>
                <th style="width: 2em;"><input type="checkbox" [(ngModel)]="selectAllTag" (change)="selectAll()"></th>
                <th>房间号</th>
                <th>账单类型</th>
                <th>账单周期</th>
                <th>应收时间</th>
                <th>应收金额</th>
                <th>实收金额</th>
                <th>剩余未收金额</th>
                <th>应收金额调整为</th>
              </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="changeLeaseRoomBillList&&changeLeaseRoomBillList.length>0">
                  <tr *ngFor="let bill of changeLeaseRoomBillList">
                    <td style="width: 2em;">
                      <input [name]="'b_'+bill.bill_id" type="checkbox"
                             [(ngModel)]="bill.selected"
                             (change)="calSelected()"
                      >
                    </td>
                    <td>{{bill.room_name}}</td>
                    <td>{{bill.bill_type_name}}</td>
                    <td>{{bill.bill_start_time | date:'yyyy.MM.dd'}}
                      - {{bill.bill_end_time | date:'yyyy.MM.dd'}}</td>
                    <td>{{bill.plan_pay_time | date:'yyyy.MM.dd'}}</td>
                    <td>{{bill.bill_amount}}</td>
                    <td>{{bill.received}}</td>
                    <td>{{calSub(bill.bill_amount,bill.received)}}</td>
                    <td class="text-left">
                      <input class="w-75" type="text" [name]="'cm_'+bill.bill_id" [(ngModel)]="bill.change_amount" (change)="calSelected()" >
                      <div class="text-danger" *ngIf="bill.selected&&!isNum(bill.change_amount)">
                        请输入最多两位小数数字
                      </div>
                      <div class="text-danger" *ngIf="bill.selected&&isNum(bill.change_amount)&&+bill.received>+bill.change_amount">
                        变更金额不能小于已收
                      </div>
                      <div class="text-danger" *ngIf="bill.selected&&isNum(bill.change_amount)&&+bill.bill_amount===+bill.change_amount">
                        变更金额不能等于原金额
                      </div>
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="!changeLeaseRoomBillList||changeLeaseRoomBillList.length===0">
                  <tr>
                    <td colspan="9">无满足条件账单</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="mt-2" *ngIf="bcBillCount>0">
            共选择了<span class="text-danger">{{bcBillCount}}</span>个账单，费用调整差额统计<span class="text-danger">{{bcBillChangeTotal}}</span>元
          </div>
          <div class="mt-2">
            <div class="row">
              <div class="col-2">
                <div class="">调整原因：</div>
                <div class="text-danger mt-2" *ngIf="submit&&!reason">
                  请填写原因
                </div>
              </div>
              <div class="col-10">
                <textarea [(ngModel)]="reason" class="w-100"></textarea>
              </div>

              <div class="col-2">
                <div class="">附件：</div>
              </div>
              <div class="col-10">
                <app-new-file-upload [type]="2" accept="IMG|PDF|WORD|EXCEL|TXT|PPT" maxLength="10" (fileChange)="setFiles($event)" [imgList]="initImg"></app-new-file-upload>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button"
                    (click)="billChangeSubmit()"
                    class="btn btn-primary">提交
            </button>
            <button type="button"
                    (click)="modalChangeLeaseBill.hide()"
                    class="btn btn-secondary">关闭
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade " bsModal #approvModal="bs-modal"
     [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary ">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="approvModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="model-body">

        <div class="col mt-2">
          <div class="row mx-5">
            <div class="text-center mt-2">
              合同变更申请已提交成功,审批单号：<span class="text-danger">{{approvNum}}</span>
            </div>
            <div class="text-center my-2">
              合同变更需走审批流程，您可在【合同详情-审批记录】模块查看审批进度。
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <div class="col-12">
          <div class="col-12 text-center">
            <button type="button"
                    (click)="approvModal.hide()"
                    class="btn btn-primary">关闭
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
