import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {RequestService} from "../../request.service";
import {AuthService} from "../../common/auth.service";
import {environment} from "../../../../environments/environment";
import {
  CommonCategoryManagerResponse,
  CommonLabelManagerResponse,
  CommonSpecAddRequest,
  CommonSpecManagerResponse, CommonSpecValueAddRequest,
  CourierCompanyInfoListResponse,
  ProductDetailResponse,
  ProductManagerRequest,
  ProductManagerResponse
} from "../../../model/ebusiness/ebusiness.model";

@Injectable()
export class ECommonService {

  // 获得类目
  private GET_CATEGORY_LIST = `${environment.apiHost}eb/common/category/categoryList`;


  // 获得类目
  private GET_LABEL_LIST = `${environment.apiHost}eb/common/label/labelList`;
  // 获得类目
  private POST_LABEL_ADD = `${environment.apiHost}eb/common/label/addLabel`;
  // 获得类目
  private POST_LABEL_DEL = `${environment.apiHost}eb/common/label/delLabel`;

  //
  private GET_COURIER_LIST = `${environment.apiHost}eb/common/courier/list`;

  //
  private GET_SPEC_LIST = `${environment.apiHost}eb/common/spec/specList`;

  private POST_SPEC_ADD = `${environment.apiHost}eb/common/spec/addSpec`;

  private POST_SPEC_VALUE_ADD = `${environment.apiHost}eb/common/spec/addSpecValue`;

  constructor(
    private auth: AuthService,
    private requestService: RequestService
  ) {

  }

  /**
   * 商品类目列表
   */
  getCategoryList(req?: ProductManagerRequest): Promise<CommonCategoryManagerResponse> {
    return this.requestService.authGet(this.GET_CATEGORY_LIST, req);
  }


  /**
   * 商品类目列表
   */
  getLabelList(): Promise<CommonLabelManagerResponse> {
    return this.requestService.authGet(this.GET_LABEL_LIST);
  }

  addLabel(text: string): Promise<any> {
    return this.requestService.authPost(this.POST_LABEL_ADD, {label_name: text});
  }

  delLabel(id: number): Promise<any> {
    return this.requestService.authPost(this.POST_LABEL_DEL, {label_id: id});
  }


  getCourierList(): Promise<CourierCompanyInfoListResponse> {
    return this.requestService.authGet(this.GET_COURIER_LIST);
  }

  //
  getSpecList(): Promise<CommonSpecManagerResponse> {
    return this.requestService.authGet(this.GET_SPEC_LIST);
  }

  addSpec(req: CommonSpecAddRequest): Promise<any> {
    return this.requestService.authPost(this.POST_SPEC_ADD, req);
  }

  addSpecValue(req: CommonSpecValueAddRequest): Promise<any> {
    return this.requestService.authPost(this.POST_SPEC_VALUE_ADD, req);
  }
}
