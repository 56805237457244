<div class="steps">
    <ul class="text">
        <li *ngFor="let item of stepsData; let i=index">
          <div class="title" *ngIf="item.title">{{item.title}}</div>
          <!-- <div class="description" *ngIf="item.description">{{item.description}}</div> -->
        </li>
       </ul>
    <ul class="lineUl">
     <li *ngFor="let item of stepsData; let i=index" [ngClass]="{'liWidth': i+1 == stepsData.length}">
        <div class="stepsIcon">
            <div class="radioSelect" [ngClass]="{'isRadioSelect': i+2>active}">
                <span class="icomoon icomoon-zhuye" *ngIf="i==0"></span>
                <span class="icomoon icomoon-queren" *ngIf="i==1"></span>
                <span class="icomoon icomoon-iconku" *ngIf="i==2"></span>
                <span class="icomoon icomoon-success" *ngIf="i==3"></span>
            </div>
            <div *ngIf="i+1 != stepsData.length" class="line" [ngClass]="{'lineDefaule': i+2>active}">
                <!-- <span *ngIf="item.tips">{{item.tips}}</span> -->
            </div>
        </div>
     </li>
    </ul>
   
    
</div>