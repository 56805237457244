import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {RequestService} from '../request.service';
import {
  InspectRoomListResponse,
  InspectRoomListRequest,
  InsertRoomInspectRecordRequest,
  InsertRoomInspectRecordResponse,
  PwdDetailResponse,
  RoomInspectPwdResponse,
  RoomInspectPwdRequest,
  RoomInspectRecordListResponse,
  RoomInspectListRecordRequest,
  RoomInspectRecordDetailResponse,
  RoomInspectRecordDetailRequest,
  BatchOperationRecordRequest, LastDangerTypesRequest
} from '../../model/roominspect/roominspect.model';
import {AuthService} from "../common/auth.service";

@Injectable()
export class RoominspentService {

  //巡房管理-房间列表
  private GET_ROOM_LIST_URL = `${environment.apiHost}roomInspect/roomList`;

  //新增巡房记录
  private INSERT_ROOM_INSPECT_RECORD_URL = `${environment.apiHost}roomInspect/insert`;

  //设置巡查密码
  private SETTING_LOCK_PWD_URL = `${environment.apiHost}roomInspect/settingLockPwd`;

  //获取巡房密码
  private GET_LOCK_PWD_URL = `${environment.apiHost}roomInspect/getLockPwd`;

  //获取巡房记录
  private GET_ROOM_INPECT_RECORD_LIST_URL = `${environment.apiHost}roomInspect/getRoomInpectRecordList`;

  //获取巡房记录excel
  private GET_ROOM_INPECT_RECORD_DOWNLOAD = `${environment.apiHost}roomInspect/downloadRoomInspectRecord`;

  //获取巡房记录详情
  private GET_ROOM_INPECT_RECORD_DETAIL_URL = `${environment.apiHost}roomInspect/getRoomInpectRecordDetail`;

  private GET_ROOM_INPECT_RECORD_BY_STATUS_URL = `${environment.apiHost}roomInspect/getRoomInpectRecordStatus`;

  private BATCH_OPERATION_RECORD_URL = `${environment.apiHost}roomInspect/batchOperationRecord`;

  private GET_ROOM_DOOR_LOCK_LIST = `${environment.apiHost}roomInspect/getRoomDoorLockList`;

  //获取上一次巡查隐患项
  private GET_LAST_DANGER_TYPES = `${environment.apiHost}roomInspect/getLastDangerTypes`;

 // 获取动态密码
  private GET_DYNAMIC_PASSWORD_PLAINTEXT = `${environment.apiHost}roomInspect/getDynamicPasswordPlaintext`;

  private GET_ROOMINSPECT_NOTIFICATIONLIST = `${environment.apiHost}roomInspect/notificationList`;

  // 巡房通知书_整改企业列表查询
  private GET_ROOM_INSPECT_COMAPNYS = `${environment.apiHost}roomInspect/roomInspectComapnys`;

  // 巡房通知书_新增/编辑通知书
  private SAVE_ROOM_NOTICE = `${environment.apiHost}roomInspect/saveRoomInspectNotification`;

  // 巡房通知书_企业隐患明细下载exce
  private DOWNLOAD_EXCEL_COMAPNYS = `${environment.apiHost}roomInspect/downloadInspectNotificationDetail`;

  // 巡防通知书详情
  private GET_ROOM_NOTICE_DETALS = `${environment.apiHost}roomInspect/getRoomInspectNotificationById`;

  // 重发巡房通知书
  private RESET_ROOM_NOTICE = `${environment.apiHost}roomInspect/resendNotification`;

  //身份证正面照识别身份证上信息
  private CAPTURE_IDENTIFIY_INFO = `${environment.apiHost}capture/identifiy/info`;

  constructor(
    private requestService: RequestService,
    private authService: AuthService
  ) {
  }

  /**
   * 获取巡房管理-房间列表
   * @param request
   */
  getRoomList(request: InspectRoomListRequest): Promise<InspectRoomListResponse> {
    return this.requestService.authGet(this.GET_ROOM_LIST_URL, request);
  }

  /**
   * 新增巡房记录
   * @param request
   */
  insertRoomInspectRecord(request: InsertRoomInspectRecordRequest): Promise<InsertRoomInspectRecordResponse> {
    return this.requestService.authPost(this.INSERT_ROOM_INSPECT_RECORD_URL, request);
  }

  settingLockPwd(request: RoomInspectPwdRequest): Promise<RoomInspectPwdResponse> {
    return this.requestService.authPost(this.SETTING_LOCK_PWD_URL, request);
  }

  getLockPwd(request: RoomInspectPwdRequest): Promise<PwdDetailResponse> {
    return this.requestService.authGet(this.GET_LOCK_PWD_URL, request);
  }

  getRoomInpectRecordList(request: RoomInspectListRecordRequest): Promise<RoomInspectRecordListResponse> {
    return this.requestService.authGet(this.GET_ROOM_INPECT_RECORD_LIST_URL, request);
  }

  getRoomInpectRecordListWithLoading(request: RoomInspectListRecordRequest): Promise<RoomInspectRecordListResponse> {
    return this.requestService.authPost(this.GET_ROOM_INPECT_RECORD_LIST_URL, request, true);
  }

  downloadRoomInpectRecord(request: RoomInspectListRecordRequest) {

    let param = "";
    for (let key in request) {
      if (request[key] && request.hasOwnProperty(key)) {
        param += (key + '=' + request[key] + '&');
      }
    }

    if (param) {
      window.open(this.GET_ROOM_INPECT_RECORD_DOWNLOAD + "?" + param + 'Authorization=' + this.authService.getAuthToken(), "_blank");
    } else {
      window.open(this.GET_ROOM_INPECT_RECORD_DOWNLOAD + '?Authorization=' + this.authService.getAuthToken(), "_blank");
    }
  }

  getRoomInpectRecordDetail(request: RoomInspectRecordDetailRequest): Promise<RoomInspectRecordDetailResponse> {
    return this.requestService.authGet(this.GET_ROOM_INPECT_RECORD_DETAIL_URL, request);
  }

  getRoomInpectRecordStatus(request: RoomInspectListRecordRequest): Promise<RoomInspectRecordListResponse> {
    return this.requestService.authGet(this.GET_ROOM_INPECT_RECORD_BY_STATUS_URL, request);
  }

  batchOperationRecord(request: BatchOperationRecordRequest): Promise<InsertRoomInspectRecordResponse> {
    return this.requestService.authPost(this.BATCH_OPERATION_RECORD_URL, request);
  }

  getRoomDoorLockList(request: any): Promise<any> {
    return this.requestService.authGet(this.GET_ROOM_DOOR_LOCK_LIST, request);
  }

  getLastDangerTypes(request: LastDangerTypesRequest): Promise<any> {
    return this.requestService.authGet(this.GET_LAST_DANGER_TYPES, request);
  }

  getDynamicPasswordPlaintext(request: RoomInspectPwdRequest): Promise<any> {
    return this.requestService.authGet(this.GET_DYNAMIC_PASSWORD_PLAINTEXT, request);
  }

  getNotificationList(request: any): Promise<any> {
    return this.requestService.authGet(this.GET_ROOMINSPECT_NOTIFICATIONLIST, request);
  }

  getRoomInspectComapnys(request: any): Promise<any> {
    return this.requestService.authGet(this.GET_ROOM_INSPECT_COMAPNYS, request);
  }

  saveRoomNotice(request: any): Promise<any> {
    return this.requestService.authPost(this.SAVE_ROOM_NOTICE, request);
  }

  downloadExcelComapnys(request: any): Promise<any> {
    return this.requestService.authPost(this.DOWNLOAD_EXCEL_COMAPNYS, request);
  }

  getRoomNoticeDetals(request: any): Promise<any> {
    return this.requestService.authGet(this.GET_ROOM_NOTICE_DETALS, request);
  }

  resetRoomNotice(request: any): Promise<any> {
    return this.requestService.authPost(this.RESET_ROOM_NOTICE, request);
  }

  captureIdentifiyInfo(request: any): Promise<any> {
    return this.requestService.authPost(this.CAPTURE_IDENTIFIY_INFO,request);
  }
}
