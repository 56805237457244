import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RequestService } from '../request.service';
import { BannerAddRequest, BannerDetailRequest, BannerDetailResponse,
  BannerEditRequest, BannerListRequest, BannerListResponse } from '../../model/banner/banner.model';

@Injectable()
export class BannerService {

  //活动列表
  private BANNER_LIST = `${environment.apiHost}banner/list`;
  //活动添加
  private BANNER_ADD = `${environment.apiHost}banner/add`;
  //活动编辑
  private BANNER_EDIT = `${environment.apiHost}banner/edit`;
  //活动详情
  private BANNER_DETAIL = `${environment.apiHost}banner/detail`;
  //运营_获取品牌介绍
  private BANNER_GET_INFO = `${environment.apiHost}brand/manage/getBrandInfo`;
  //运营_新增/编辑品牌
  private BANNER_GET_SAVE = `${environment.apiHost}brand/manage/saveBrandInfo`;
  //运营_获取项目保洁/维修服务配置
  private SERVICE_GET_CONFIG = `${environment.apiHost}project/manage/getCleanOrRepairService`;
  //运营_项目保洁/维修服务配置新增/编辑
  private SERVICE_EDIT_CONFIG = `${environment.apiHost}project/manage/saveCleanOrRepairService`;
  //运营_禁启用服务配置
  private SERVICE_OPEN_CONFIG = `${environment.apiHost}project/manage/changeServiceStatus`;

  constructor(private requestService: RequestService) {
  }

  /**
   * @description: 活动列表
   * @Author: kaungye
   * @Date: 2018-08-28
   * @Last Modified by: kuangye
   * @Last Modified time: 2018-08-28
   */
  list(req: BannerListRequest): Promise<BannerListResponse> {
    return this.requestService.authGet(this.BANNER_LIST, req);
  }
  listWithLoading(req: BannerListRequest): Promise<BannerListResponse> {
    return this.requestService.authGet(this.BANNER_LIST, req, true);
  }

  /**
   * @description: 活动添加
   * @Author: kaungye
   * @Date: 2018-08-28
   * @Last Modified by: kuangye
   * @Last Modified time: 2018-08-28
   */
  add(req: BannerAddRequest): Promise<any> {
    return this.requestService.authPost(this.BANNER_ADD, req);
  }

  /**
   * @description: 活动编辑
   * @Author: kaungye
   * @Date: 2018-08-28
   * @Last Modified by: kuangye
   * @Last Modified time: 2018-08-28
   */
  edit(req: BannerEditRequest): Promise<BannerListResponse> {
    return this.requestService.authPost(this.BANNER_EDIT, req);
  }

  /**
   * @description: 活动详情
   * @Author: kaungye
   * @Date: 2018-08-28
   * @Last Modified by: kuangye
   * @Last Modified time: 2018-08-28
   */
  detail(req: BannerDetailRequest): Promise<BannerDetailResponse> {
    return this.requestService.authGet(this.BANNER_DETAIL, req);
  }
  getBrandInfo(): Promise<any> {
    return this.requestService.authGet(this.BANNER_GET_INFO);
  }
  getBrandSave(req: any): Promise<any> {
    return this.requestService.authPost(this.BANNER_GET_SAVE, req);
  }
  getServiceConfig(): Promise<any> {
    return this.requestService.authGet(this.SERVICE_GET_CONFIG);
  }
  editServiceConfig(req: any): Promise<any> {
    return this.requestService.authPost(this.SERVICE_EDIT_CONFIG, req);
  }
  openServiceConfig(req: any): Promise<any> {
    return this.requestService.authPost(this.SERVICE_OPEN_CONFIG, req);
  }

}
