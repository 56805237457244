import { Component, EventEmitter, Input, OnInit, Output,ViewChild } from '@angular/core';
import { ExpenseInfo, ExpenseInfoWithIndex } from '../../../model/lease/lease.model';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { limitPriceValidator } from '../../../core/validator/validator.sign';
import { arrContains } from '../../../common/array.util';
import { BaseInfoService } from '../../../service/common/base-info.service';
import { BaseEnum } from '../../../model/modal/base-model';
import { LeaseService } from '../../../service/lease/lease.service';
import { ModalDirective } from "ngx-bootstrap/modal";
@Component({
  selector: 'app-expense-modal',
  templateUrl: './expense-modal.component.html',
  styleUrls: ['./expense-modal.component.css']
})
export class ExpenseModalComponent implements OnInit {

  @Input()
  index: number;

  @Input()
  expense_list: ExpenseInfo[];

  @Output()
  out_expense_with_index: EventEmitter<ExpenseInfoWithIndex> = new EventEmitter<ExpenseInfoWithIndex>();
  @ViewChild('expense_modal') expense_modal: ModalDirective;
  // 收取类型列表
  gather_type_list: BaseEnum[] = [];

  // 附加费用类型列表
  bill_type_other_list: string[] = [];

  // 是否提交
  is_submit: boolean = false;

  // 灵活租金弹窗
  // private expense_modal: any;

  expense_form_group: UntypedFormGroup = this.fb.group({
    expense_array: this.fb.array([])
  });

  constructor(private fb: UntypedFormBuilder,
              private leaseService: LeaseService,
              private baseInfoService: BaseInfoService) {
  }

  ngOnInit() {
    this.leaseService.getBillTypeWithGather().then(data => {
      // this.gather_type_list = data.base_enum_dto_list;
      data.base_enum_dto_list.forEach(item => {
        if(item.code === 'EVERY_MONTH_CHARGE'){
          this.gather_type_list.push(item);
        }
      });
    });
    this.baseInfoService.getBillTypeOtherList().then(data => {
      if (data.sys_dic_list) {
        data.sys_dic_list.forEach(item => {
          this.bill_type_other_list.push(item.item_name);
        });
      }
    });
  }

  // 重置额外费用列表
  resetExpenseList() {
    this.is_submit = false;
    this.clearExpenseArray();

    this.expense_list.forEach(item => {
      let expense_item = this.createExpense();
      expense_item.patchValue({
        gather_type: item.gather_type,
        expense_name: item.expense_name,
        expense_value: item.expense_value
      });
      this.expenseArray.push(expense_item);
    });
  }

  // 初始化
  // initSomething(modal: any) {
  //   this.expense_modal = modal;
  // }

  // 附加费用确定
  confirmExpenseModal() {
    this.is_submit = true;

    if (!this.expense_form_group.valid) {
      return;
    }
    this.out_expense_with_index.emit(new ExpenseInfoWithIndex(this.index, this.expenseArray.value));

    this.is_submit = false;
    this.hide();
  }

  // 清空附加费用
  clearExpenseArray() {
    if (this.expenseArray) {
      let length = this.expenseArray.length;
      for (let i = length - 1; i >= 0; i--) {
        this.expenseArray.removeAt(i);
      }
    }
  }

  // 创建额外费用
  createExpense(): UntypedFormGroup {
    return this.fb.group({
      gather_type: ['EVERY_MONTH_CHARGE', Validators.required],
      expense_name: [, Validators.required],
      expense_value: [, [Validators.required, limitPriceValidator(1, 10000, true)]]
    });
  }

  // 添加新额外费用
  addNewExpense(): void {
    this.expenseArray.push(this.createExpense());
  }

  //移除附加费用
  removeExpense(index: number) {
    this.expenseArray.removeAt(index);
  }

  // 获取额外费用列表
  get expenseArray(): UntypedFormArray {
    return this.expense_form_group.get('expense_array') as UntypedFormArray;
  }

  // 添加附加费用自定义
  addItem = (term) => (this.addToBillTypeOtherList(term));

  addToBillTypeOtherList(term): any {
    if (!arrContains(this.bill_type_other_list, term)) {
      this.bill_type_other_list.push(term);
      return term;
    }
    return undefined;
  }

  show() {
    this.expense_modal.show();
  }

  hide() {
    this.expense_modal.hide();
  }

  // 是否显示错误信息
  isError(is_submit: boolean, container: any, control_name: string, error_code: string): boolean {
    return this.baseInfoService.isError(is_submit, container, control_name, error_code);
  }
}
