import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {WorkModel} from './model/common/common.model';
import {WorkService} from './service/common/work.service';
import {MessageModalInfo, MessageType} from "./model/modal/message-model";
import {MessageModalComponent} from "./view/shared/modal/message-modal.component";

import * as $ from "jquery";
import Quill from 'quill';
import {ImageHandler} from 'ngx-quill-upload';

import {loadingAnimate} from "./core/animates/common-animate";
import {PrintPreviewComponent} from "./view/shared/print-preview/print-preview.component";
// import BlotFormatter from "quill-blot-formatter";
import {GALLERY_CONF, GALLERY_IMAGE, NgxImageGalleryComponent} from "./view/third-party/image-gallery";
import {LocaleService} from "./view/third-party/mydatepicker/src/lib/services/mydatepicker.locale.service";
import {Constants} from "./common/constants";
import {WorkRemindService} from "./service/workremind/workremind.service";
import {LocalStorage} from "./common/localstorage";
import {AuthService} from "./service/common/auth.service";


declare var initBaiduTongJi: any;


// use css
let Align = Quill.import('attributors/style/align');
Quill.register(Align, true);
let BackgroundStyle = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle, true);
let ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle, true);
let DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);
let FontStyle = Quill.import('attributors/style/font');
Quill.register(FontStyle, true);
let SizeStyle = Quill.import('attributors/style/size');
Quill.register(SizeStyle, true);


Quill.register('modules/imageHandler', ImageHandler);
// Quill.register('modules/videoHandler', VideoHandler);
// Quill.register('modules/blotFormatter', BlotFormatter);

// Quill.register('modules/imageResize', ImageResize);

// link
const Link = Quill.import('formats/link');

// Override the existing property on the Quill global object and add custom protocols
Link.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'radar', 'rdar', 'smb', 'sms'];

class CustomLinkSanitizer extends Link {
  static sanitize(url: string) {
    // Run default sanitize method from Quill
    const sanitizedUrl = super.sanitize(url);

    // Not whitelisted URL based on protocol so, let's return `blank`
    if (!sanitizedUrl || sanitizedUrl === 'about:blank') {
      return sanitizedUrl;
    }

    // Verify if the URL already have a whitelisted protocol
    const hasWhitelistedProtocol = Link.PROTOCOL_WHITELIST.some(function (protocol: string) {
      return sanitizedUrl.startsWith(protocol);
    });

    if (hasWhitelistedProtocol) {
      return sanitizedUrl;
    }

    // if not, then append only 'https' to not to be a relative URL
    return `https://${sanitizedUrl}`;
  }
}

Quill.register(CustomLinkSanitizer, true);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [
    loadingAnimate
  ]
})
export class AppComponent implements OnInit {

  // loading
  loading = false;
  fullLoadingMask = false;

  loadingTimer = 0;

  // 提示框
  @ViewChild('messageModal', {static: true}) messageModal: MessageModalComponent;
  @ViewChild(PrintPreviewComponent, {static: true}) ppc: PrintPreviewComponent;
  // 图片全屏预览
  @ViewChild('imageGallery', {static: true}) imageGallery: NgxImageGalleryComponent;
  galleryConf: GALLERY_CONF = {
    imageOffset: '0px',
    showDeleteControl: false,
    showImageTitle: false,
  };

  resetMessage: MessageModalInfo;

  autoCloseTime = 5 * 60;
  autoCloseTimer = this.autoCloseTime * 1000;
  autoCloseCountDownTimer = 30 * 1000;
  autoCloseCountDown = this.autoCloseTime * 1000;
  autoCloseInterval;

  constructor(private el: ElementRef,
              private workService: WorkService,
              private workRemindService: WorkRemindService,
              private auth: AuthService,
  ) {
  }

  ngOnInit(): void {
    // get wechat code
    let query = window.location.search.substring(1);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      if (pair[0] === "code") {
        sessionStorage.setItem("code", pair[1]);
      }
      if (pair[0] === "state") {
        if (pair.length > 1 && pair[1]) {
          sessionStorage.setItem("state", pair[1]);
        }
      }
      if (pair[0] === "backurl") {
        if (pair.length > 1 && pair[1]) {
          sessionStorage.setItem(Constants.LOGIN_BACKURL, pair[1]);
        }
      }
      if (pair[0] === "companyid") {
        if (pair.length > 1 && pair[1]) {
          sessionStorage.setItem(Constants.LOGIN_COMPANYID, pair[1]);
        }
      }
      if (pair[0] === "projectid") {
        if (pair.length > 1 && pair[1]) {
          sessionStorage.setItem(Constants.LOGIN_PROJECTID, pair[1]);
        }
      }
      if (pair[0] === "systemtype") {
        if (pair.length > 1 && pair[1]) {
          sessionStorage.setItem(Constants.LOGIN_SYSTEMTYPE, pair[1]);
        }
      }
    }

    // 百度统计
    initBaiduTongJi();

    window.addEventListener("scroll", (event) => {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.PANEL_SCROLL, event));
    });

    // 监听APP 调用事件
    // demo
    window.addEventListener(Constants.APP.TRIGGER_TYPE.CUSTOM_TYPE, (event: CustomEvent) => {
      // custom todo
    });
    // 待办扫码
    window.addEventListener(Constants.APP.TRIGGER_TYPE.QRCODE_WORK_REMIND, (event: CustomEvent) => {
      let id = LocalStorage.getItem(Constants.APP.KEY.QRCODE_WORK_REMIND);
      this.workRemindService.workRemindCustomRuleQrcode(
        id,
        event['data']
      ).then(data => {
        this.workService.event_bus.emit(new WorkModel(this.workService.type.PANEL_NEED_REFRESH));
        this.workService.showSuccessModal('扫码成功');
      });
    });

    setInterval(() => {
      if (this.loadingTimer > 0) {
        this.loadingTimer--;
      }
    }, 100);

    // loading
    this.workService.event_bus.subscribe((data: WorkModel) => {
      if (data.type === this.workService.type.PANEL_LOADING_START) {
        this.loading = true;
        this.addTimer();
      }
      if (data.type === this.workService.type.PANEL_LOADING_END) {
        this.loading = false;
      }
      // 成功提示
      if (data.type === this.workService.type.DIALOG_SUCCESS) {
        this.showDialog(data, MessageType.SUCCESS);
      }
      // 监听错误信息
      if (data.type === this.workService.type.DIALOG_ERROR && this.workService.show) {
        this.showDialog(data, MessageType.ERROR);
      }

      // remove class
      if (data.type === this.workService.type.MODAL_CLASS_REMOVE) {
        $("body").removeClass("modal-open");
      }

      // 自动关闭错误弹框
      if (data.type === this.workService.type.DIALOG_CLOSE) {
        this.hideDialog();
      }


      if (data.type === this.workService.type.PDF_PRINT) {
        this.ppc.src = data.data;
        this.ppc.show();
      }
      //图片全屏预览
      if (data.type === this.workService.type.IMGFULL_SCREEN_PREVEIEW) {
        let images: GALLERY_IMAGE[] = [];
        if (typeof data.data === 'string') {
          images.push({
            url: data.data,
            altText: '',
            title: '',
            thumbnailUrl: data.data + ""
          });
        } else {
          data.data.imgArray.forEach(img => {
            images.push({
              url: img,
              altText: '',
              title: '',
              thumbnailUrl: img + ''
            });
          });
        }
        this.imageGallery.images = images;
        this.imageGallery.open(data.data.index);
      }


      if (data.type === this.workService.type.SHOW_LOADING) {
        this.fullLoadingMask = true;
      }
      if (data.type === this.workService.type.HIDE_LOADING) {
        this.fullLoadingMask = false;
      }
    });


    // 统一处理 重写LocaleService的getLocaleOptions函数，修改默认语言为zh-cn
    LocaleService.prototype.getLocaleOptions = function (locale) {
      if (locale && this.locales.hasOwnProperty(locale)) {
        return this.locales[locale];
      }
      return this.locales["zh-cn"];
    };

  }

  addTimer() {
    if (this.loadingTimer < 5) {
      this.loadingTimer = 5;
    }
  }

  showDialog(data, modalClass) {

    this.autoCloseCountDown = this.autoCloseTimer;
    this.autoCloseInterval = setInterval(() => {
      this.autoCloseCountDown -= this.autoCloseCountDownTimer;
      if (this.autoCloseCountDown <= 0) {
        this.hideDialog();
        if (this.autoCloseInterval) {
          window.clearInterval(this.autoCloseInterval);
        }
      }
    }, this.autoCloseCountDownTimer);

    this.messageModal.hide();

    this.resetMessage = {
      title: '提示框',
      type: 1,
      operationMessage: null,
      modal_class: modalClass ? modalClass : MessageType.ERROR,
      returnUrl: null,
      cancel: false,
      timeout: null
    } as MessageModalInfo;

    if (data) {
      if (typeof data.data === 'string') {
        this.resetMessage.operationMessage = data.data;
      } else {
        let message: MessageModalInfo = data.data;
        for (let key in message) {
          if (message[key]) {
            this.resetMessage[key] = message[key];
          }
        }
      }

      this.messageModal.messageModalInfo = this.resetMessage;

      this.messageModal.show();
      this.loading = false;

    }

  }

  hideDialog() {
    this.messageModal.hide();
  }

  galleryError(event) {
    delete this.imageGallery.images;
  }
}
