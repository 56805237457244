import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {ApprovalResponse} from "../../model/lease/lease.model";
import {RequestService} from '../request.service';
import {
  AuditPayLogRequest,
  BillPayLogListResponse,
  ConfirmPaymentRequest,
  CreateBillRequest, DepositReceiptImgRequest, DepositReceiptImgResponse,
  GatherAmountDetailRequestParam,
  LeaseBillChangeRequest,
  LeaseIsRenewalRequest,
  LeaseIsRenewalResponse,
  LeaseRenterBillListRequest, LeaseRenterBillListResponse,
  LeaseRoomBillDetailParam,
  LeaseRoomBillListDTO,
  LeaseRoomBillListParam,
  LeaseRoomBillListResponse,
  PayBillListResponse,
  PayLogDTO,
  PayLogListRequest,
  PayLogListResponse, RenterBillDetailListRequest, RenterBillDetailRequest, RenterBillDetailResponse,
  RoomListParam,
  RoomListResponse
} from "../../model/finance/bill.model";

@Injectable()
export class BillService {

  //房间下拉列表
  private ROOM_LIST_URL = `${environment.apiHost}room/box/list`;
  //租约账单列表
  private LEASE_ROOM_BILL_LIST_URL = `${environment.apiHost}finance/lease/list`;
  //支付流水列表
  private PAY_LOG_LIST_URL = `${environment.apiHost}finance/lease/paylist`;
  //收款页面，应收金额
  private GATHER_AMOUNT_DETAIL = `${environment.apiHost}finance/gather/amount/detail`;
  //待收款详情账单列表
  private GATHER_BILL_LIST = `${environment.apiHost}finance/gather/amount/list`;
  //账单详情
  private LEASEROOMBILL_DETAIL_URL = `${environment.apiHost}finance/bill/detail`;
  //账单财务明细
  private LEASEROOMBILL_BILLPAYLOG_LIST_URL = `${environment.apiHost}finance/bill/detailList`;
  //确认收款
  private CONFIRM_GATHER_URL = `${environment.apiHost}finance/gather/gatheringBill`;
  //创建账单
  private CREATE_BILL_URL = `${environment.apiHost}finance/gather/createBill`;
  //支付流水详情
  private PAY_LOG_DETAIL_URL = `${environment.apiHost}finance/paylog/detail`;
  //支付账单明细 列表
  private PAY_LOG_DETAIL_LIST_URL = `${environment.apiHost}finance/paylog/detailList`;
  //支付流水列表-核销
  private PAY_LOG_LIST_AUDIT_URL = `${environment.apiHost}finance/audit/payloglist`;
  //支付流水-核销
  private PAY_LOG_AUDIT_URL = `${environment.apiHost}finance/audit/auditing`;
  // 无合同账单列表
  private NO_CONTRACT_BILL_LIST_URL = `${environment.apiHost}finance/no_contract/bill/list`;
  // 逾期账单审核发送短信
  private OVERDUE_BILL_AUDIT_SMS_SEND_URL = `${environment.apiHost}finance/overdue/bill/audit/sms/send`;
  // 账单无需付
  private OVERDUE_BILL_NO_NEED_PAY_URL = `${environment.apiHost}finance/overdue/bill/no_need_pay`;
  // 系统调账
  private BILL_RECONCILIATION_URL =  `${environment.apiHost}finance/bill/reconciliation`;
  //v1.2.7账单财务明细
  private LEASEROOMBILL_BILLPAYLOG_LISTS_URL = `${environment.apiHost}finance/bill/detailLists`;

  //租约_是否可以续租
  private LEASE_IS_RENEWAL = `${environment.apiHost}finance/lease/isRenewal`;

  // 合同账单变更
  private POST_LEASE_BILL_CHANGE = `${environment.apiHost}finance/billChange`;

  // 住客账单列表
  private RENTER_BILL_LIST = `${environment.apiHost}finance/renter/bill/list`;
  private RENTER_BILL_DETAIL = `${environment.apiHost}finance/renter/bill/detail`;
  private RENTER_BILL_DETAIL_LIST = `${environment.apiHost}finance/renter/bill/detail/list`;
  private FINANCE_DEPOSIT_RECEIPT = `${environment.apiHost}finance/deposit/receipt`;

  private POST_BILL_DEL = `${environment.apiHost}finance/bill/del`;

  constructor(//引用服务
    private requestService: RequestService) {
  }

  //房间下拉列表
  getRoomList(roomListParam: RoomListParam): Promise<RoomListResponse> {
    return this.requestService.authGet(this.ROOM_LIST_URL, roomListParam);
  }

  //租约账单列表
  getLeaseRoomBillList(leaseRoomBillListParam: LeaseRoomBillListParam): Promise<LeaseRoomBillListResponse> {
    return this.requestService.authGet(this.LEASE_ROOM_BILL_LIST_URL, leaseRoomBillListParam);
  }

  //支付流水列表
  getPayLogList(leaseRoomBillListParam: LeaseRoomBillListParam): Promise<LeaseRoomBillListResponse> {
    return this.requestService.authGet(this.PAY_LOG_LIST_URL, leaseRoomBillListParam);
  }

  //收款详情
  gatherAmountDtail(confirmPaymentRequest: GatherAmountDetailRequestParam): any {
    return this.requestService.authLongGet(this.GATHER_AMOUNT_DETAIL, confirmPaymentRequest, 120 * 1000);
  }

  //获取但收款详情列表
  getWaitingPayBillList(confirmPaymentRequest: GatherAmountDetailRequestParam): any {
    return this.requestService.authGet(this.GATHER_BILL_LIST, confirmPaymentRequest);
  }

  //账单详情
  getleaseRoomBillDetail(leaseRoomBillDetailParam: any): Promise<LeaseRoomBillListDTO> {
    return this.requestService.authGet(this.LEASEROOMBILL_DETAIL_URL, leaseRoomBillDetailParam);
  }

  //账单财务明细
  getBillPayLogList(leaseRoomBillDetailParam: LeaseRoomBillDetailParam): Promise<BillPayLogListResponse> {
    return this.requestService.authGet(this.LEASEROOMBILL_BILLPAYLOG_LIST_URL, leaseRoomBillDetailParam);
  }

  //确认收款
  confirmGather(confirmPaymentRequest: ConfirmPaymentRequest): Promise<any> {
    return this.requestService.authPost(this.CONFIRM_GATHER_URL, confirmPaymentRequest);
  }

  //创建账单
  createBill(createBillRequest: CreateBillRequest): Promise<any> {
    return this.requestService.authPost(this.CREATE_BILL_URL, createBillRequest);
  }

  //支付流水详情
  getPaylogDetailt(leaseRoomBillDetailParam: LeaseRoomBillDetailParam): Promise<PayLogDTO> {
    return this.requestService.authGet(this.PAY_LOG_DETAIL_URL, leaseRoomBillDetailParam);
  }

  //支付账单明细 列表
  getPaylogDetailtList(leaseRoomBillDetailParam: LeaseRoomBillDetailParam): Promise<PayBillListResponse> {
    return this.requestService.authGet(this.PAY_LOG_DETAIL_LIST_URL, leaseRoomBillDetailParam);
  }

  //支付流水列表-核销
  getAuditPayLogList(param: PayLogListRequest): Promise<PayLogListResponse> {
    return this.requestService.authGet(this.PAY_LOG_LIST_AUDIT_URL, param);
  }
  getAuditPayLogListWithLoading(param: PayLogListRequest): Promise<PayLogListResponse> {
    return this.requestService.authGet(this.PAY_LOG_LIST_AUDIT_URL, param, true);
  }

  // 核销支付流水
  auditing(param: AuditPayLogRequest): Promise<any> {
    return this.requestService.authPost(this.PAY_LOG_AUDIT_URL, param);
  }

  // 核销支付流水
  noContractBillList(param: any): Promise<any> {
    return this.requestService.authGet(this.NO_CONTRACT_BILL_LIST_URL, param);
  }
  noContractBillListWithLoading(param: any): Promise<any> {
    return this.requestService.authGet(this.NO_CONTRACT_BILL_LIST_URL, param, true);
  }

  // 逾期账单审核发送短信
  overdueBillAuditSmsSend(param: any): Promise<boolean> {
    return this.requestService.authPost(this.OVERDUE_BILL_AUDIT_SMS_SEND_URL, param);
  }

  // 账单无需付
  overdueBillNoNeedPay(param: any): Promise<any> {
    return this.requestService.authPost(this.OVERDUE_BILL_NO_NEED_PAY_URL, param);
  }

  /**
   * 系统调账
   * @param param
   */
  billReconciliation(param: any): Promise<any> {
    return this.requestService.authPost(this.BILL_RECONCILIATION_URL, param);
  }

  //v1.2.7账单财务明细
  getBillPayLogLists(leaseRoomBillDetailParam: LeaseRoomBillDetailParam): Promise<BillPayLogListResponse> {
    return this.requestService.authGet(this.LEASEROOMBILL_BILLPAYLOG_LISTS_URL, leaseRoomBillDetailParam);
  }

  //租约_是否可以续租
  leaseIsRenewal(req: LeaseIsRenewalRequest): Promise<LeaseIsRenewalResponse> {
    return this.requestService.authGet(this.LEASE_IS_RENEWAL, req);
  }

  leaseBillChange(req: LeaseBillChangeRequest): Promise<ApprovalResponse> {
    return this.requestService.authPost(this.POST_LEASE_BILL_CHANGE, req);
  }

  // 查询住客账单列表
  getLeaseRenterBillList(leaseRenterBillListRequest: LeaseRenterBillListRequest): Promise<LeaseRenterBillListResponse> {
    return this.requestService.authGet(this.RENTER_BILL_LIST, leaseRenterBillListRequest);
  }

  //账单详情
  getRenterBillDetail(renterBillDetailRequest: RenterBillDetailRequest): Promise<RenterBillDetailResponse> {
    return this.requestService.authGet(this.RENTER_BILL_DETAIL, renterBillDetailRequest);
  }

  //住客押金收据
  getDepositReceiptImg(depositReceiptImgRequest: DepositReceiptImgRequest): Promise<DepositReceiptImgResponse> {
    return this.requestService.authGet(this.FINANCE_DEPOSIT_RECEIPT, depositReceiptImgRequest);
  }

  //分摊账单支付流水详情
  getRenterBillDetailList(request: RenterBillDetailListRequest): Promise<BillPayLogListResponse> {
    return this.requestService.authGet(this.RENTER_BILL_DETAIL_LIST, request);
  }

  delBill(bill_id: number) {
    return this.requestService.authPost(this.POST_BILL_DEL, {bill_id:bill_id});
  }
}
