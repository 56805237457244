import { Pipe, PipeTransform } from '@angular/core';

/**
 * @Description: 报修状态管道
 * @Author: 周嘉豪
 * @Date: 2018/3/29 上午10:26
 * @Last Modified by: 周嘉豪
 * @Last Modified time: 2018/3/29 上午10:26
 */
@Pipe({
  name: 'repairStatusPipe'
})
export class RepairStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const status = {
      WAITING: '待处理',
      INVALID: '已拒绝',
      FIXED: '已处理',
      EFFECTIVE: '处理中',
    };
    return status[value];
  }
}
