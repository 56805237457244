import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// jquery
import * as $ from 'jquery';

if (environment.production) {
  enableProdMode();
}

let normalRun = () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => {}
      // console.log(err)
    );
};

try {
  $.ajax('config/sysconfig.json', {
    cache: false,
    dataType: 'json',
    method: 'get',
    success: (data) => {
      if (data && data.apiHost) {
        environment.apiHost = data.apiHost;
      }
      normalRun();
    },
    error: (e) => {
      normalRun();
    }
  });
} catch (e) {
  normalRun();
}


