import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {RequestService} from "../request.service";
import {
  ShopCheckoutRecordResponse,
  ShopCheckoutViewResponse,
  ShopLeaseCheckoutConfirmRequest,
  ShopLeaseCheckoutlistResponse,
  ShopLeaseCheckoutRequest, ShopRecordResponse
} from "../../model/shop/shop-checkout.model";
import {
  ShopBillPayLogDetailResponse,
  ShopBillPayLogListResponse,
  ShopLeaseBillCreateRequest,
  ShopLeaseBillCreateResponse,
  ShopLeaseBillListRequest,
  ShopLeaseBillListResponse,
  ShopLeaseBillRequest, ShopLeaseBillResponse,ShopAuditPersonResponse
} from "../../model/shop/shop-bill.model";
import {CheckoutAuditListResponse} from "../../model/signed/checkout.model";

@Injectable()
export class ShopLeaseService {

  // 合同生效
  private POST_LEASE_CONFIRM = `${environment.apiHost}shop/lease/contract/confirm`;
  // 获取查看合同地址
  private GET_FILE_CONTRACT_LIST = `${environment.apiHost}image/getContractUrl`;
  // 附件上传
  private POST_LEASE_ATTACHMENT_ADD = `${environment.apiHost}shop/lease/attachment/add`;
  //创建商铺账单
  private CREATE_SHOP_BILL_URL = `${environment.apiHost}shopLeaseBill/create`;
  //商铺账单列表
  private SHOP_BILL_LIST_URL = `${environment.apiHost}shopLeaseBill/billList`;
  //商铺账单详情
  private SHOP_BILL_DETAIL_URL = `${environment.apiHost}shopLeaseBill/billDetail`;
  //商铺账单财务明细
  private SHOP_BILL_DETAIL_LIST_URL = `${environment.apiHost}shopLeaseBill/bill/detaillist`;
  // 获取退房信息
  private GET_LEASE_CHECKOUT_INFO = `${environment.apiHost}shop/lease/checkoutTime`;
  //支付流水列表
  private SHOP_PAY_LOG_LIST_URL = `${environment.apiHost}shopLeaseBill/paylogList`;
  //支付流水详情
  private SHOP_PAY_LOG_DETAIL_URL = `${environment.apiHost}shopLeaseBill/paylogDetail`;
  // 确认退房信息
  private POST_LEASE_CHECKOUT_CONFIRM = `${environment.apiHost}shop/lease/checkoutConfirm`;
  // 逾期退房审核人列表
  private GET_LEASE_CHECKOUT_ADUIT_LIST = `${environment.apiHost}shop/lease/checkout/audit/list`;
  // 违约审核人列表
  private GET_LEASE_CHECKOUT_OVERDUE_ADUIT_LIST = `${environment.apiHost}shop/lease/checkout/overdue/audit/list`;
  // 退房单页面
  private GET_LEASE_CHECKOUT_VIEW = `${environment.apiHost}shop/lease/checkoutlist`;
  // 退房
  private POST_LEASE_CHECKOUT = `${environment.apiHost}shop/lease/checkout`;
  //支付账单明细
  private SHOP_PAY_LOG_DETAIL_LIST_URL = `${environment.apiHost}shopLeaseBill/paylog/detaillist`;
  // 合同预览额外信息
  private GET_LEASE_PREVIEW_EXT = `${environment.apiHost}shop/lease/contract/preview/ext`;
  // 合同退房单
  private GET_LEASE_CHECKOUT_RECORD = `${environment.apiHost}shop/lease/checkout/record`;

  // 取消合同
  private SHOP_LEASE_CANCEL = `${environment.apiHost}shop/lease/cancel`;

  //退款
  private RENFUND_GATHER_HANDLER_URL = `${environment.apiHost}shopLeaseBill/refund/refundGatherHandler`;
  //退款审核人
  private BILL_REFUND_AUDIT_USER_LIST_URL = `${environment.apiHost}shopLeaseBill/refund/findAuditPersons`;
  //账单退款审核短信发送
  private REFUND_BILL_AUDIT_SMSSEND_URL = `${environment.apiHost}shopLeaseBill/refund/sendModelCode`;

  // 附件删除
  private DEL_POST_LEASE_ATTACHMENT_ADD = `${environment.apiHost}shop/lease/attachment/del`;

  //账单提前出账
  private ADVANCE_SHOP_BILL_URL = `${environment.apiHost}shopLeaseBill/advanceBillList`;

  //
  private SHOP_CHECKOUT_RECORD = `${environment.apiHost}shop/lease/checkoutRecord`;
  //退租时查看是否有未核销收款账单
  private GATHERING_BILL_CHECKOUT_AUDIT = `${environment.apiHost}shopLeaseBill/bill/gathering/checkoutAudit`;


  constructor(private requestService: RequestService) {
  }

  // 合同生效
  leaseContractConfirm(leaseId: number): Promise<any> {
    return this.requestService.authPost(this.POST_LEASE_CONFIRM, {lease_id: leaseId});
  }

  getContractUrl(lease_id: number) {
    return this.requestService.authGet(this.GET_FILE_CONTRACT_LIST, {
      lease_id: lease_id,
      service_type: 'SHOP_CONTRACT'
    });
  }

  leaseAttachment(param: any) {
    return this.requestService.authPost(this.POST_LEASE_ATTACHMENT_ADD, param);
  }

  //创建账单
  createShopBill(shopLeaseBillCreateRequest: ShopLeaseBillCreateRequest): Promise<ShopLeaseBillCreateResponse> {
    return this.requestService.authPost(this.CREATE_SHOP_BILL_URL, shopLeaseBillCreateRequest);
  }

  //商铺账单列表
  getShopBillList(shopLeaseBillListRequest: ShopLeaseBillListRequest): Promise<ShopLeaseBillListResponse> {
    return this.requestService.authGet(this.SHOP_BILL_LIST_URL, shopLeaseBillListRequest);
  }

  //商铺账单详情
  getShopBillDetail(shopLeaseBillRequest: ShopLeaseBillRequest): Promise<ShopLeaseBillResponse> {
    return this.requestService.authGet(this.SHOP_BILL_DETAIL_URL, shopLeaseBillRequest);
  }

  //商铺账单财务明细
  getShopBillDetailList(shopLeaseBillRequest: ShopLeaseBillRequest): Promise<ShopBillPayLogListResponse> {
    return this.requestService.authGet(this.SHOP_BILL_DETAIL_LIST_URL, shopLeaseBillRequest);
  }

  // 获取退房信息
  checkoutTimeConfirm(leaseId: number, time: number): Promise<ShopLeaseCheckoutlistResponse> {
    return this.requestService.authGet(this.GET_LEASE_CHECKOUT_INFO, {lease_id: leaseId, checkout_time: time});
  }

  //获取支付流水
  getShopPayLogList(shopLeaseBillListRequest: ShopLeaseBillListRequest): Promise<ShopLeaseBillListResponse> {
    return this.requestService.authGet(this.SHOP_PAY_LOG_LIST_URL, shopLeaseBillListRequest);
  }

  //支付流水详情
  getShopPaylogDetailt(shopLeaseBillRequest: ShopLeaseBillRequest): Promise<ShopBillPayLogDetailResponse> {
    return this.requestService.authGet(this.SHOP_PAY_LOG_DETAIL_URL, shopLeaseBillRequest);
  }

  //支付账单明细 列表
  getShopPaylogDetailtList(shopLeaseBillRequest: any): Promise<any> {
    return this.requestService.authGet(this.SHOP_PAY_LOG_DETAIL_LIST_URL, shopLeaseBillRequest);
  }

  // 退房确认
  leaseCheckoutConfirm(slccReq: ShopLeaseCheckoutConfirmRequest) {
    return this.requestService.authPost(this.POST_LEASE_CHECKOUT_CONFIRM, slccReq);
  }

  // 逾期退房审核人列表
  leaseCheckoutAuditList(): Promise<CheckoutAuditListResponse> {
    return this.requestService.authGet(this.GET_LEASE_CHECKOUT_ADUIT_LIST);
  }
  // 违约退房审核人列表
  leaseCheckoutOverdueAuditList(): Promise<CheckoutAuditListResponse> {
    return this.requestService.authGet(this.GET_LEASE_CHECKOUT_OVERDUE_ADUIT_LIST);
  }


  leaseCheckoutView(leaseId: number): Promise<ShopCheckoutViewResponse> {
    return this.requestService.authGet(this.GET_LEASE_CHECKOUT_VIEW, {lease_id: leaseId});
  }

  // 最终退房
  checkoutLeaseConfirm(req: ShopLeaseCheckoutRequest) {
    return this.requestService.authPost(this.POST_LEASE_CHECKOUT, req);
  }

  getContractPreviewExt(leaseId: number) {
    return this.requestService.authGet(this.GET_LEASE_PREVIEW_EXT, {lease_id: leaseId});
  }

  getCheckoutRecord(leaseId: any): Promise<ShopCheckoutRecordResponse> {
    return this.requestService.authGet(this.GET_LEASE_CHECKOUT_RECORD, {lease_id: leaseId});
  }

  shopLeaseCancel(req:any){
    return this.requestService.authGet(this.SHOP_LEASE_CANCEL,req);
  }

  //退款
  renfundGatherHandler(auditReqParam: any): any {
    return this.requestService.authGet(this.RENFUND_GATHER_HANDLER_URL, auditReqParam);
  }
  //退款审核人
  billRefundAuditUserList(): Promise<ShopAuditPersonResponse> {
    return this.requestService.authGet(this.BILL_REFUND_AUDIT_USER_LIST_URL, null);
  }

  //发送审核验证码
  refundBillAuditSmsSend(auditReqParam: any): any {
    return this.requestService.authGet(this.REFUND_BILL_AUDIT_SMSSEND_URL, auditReqParam);
  }

  delLeaseAttachment(param: any) {
    return this.requestService.authPost(this.DEL_POST_LEASE_ATTACHMENT_ADD, param);
  }

  getAdvanceBill(request: any): Promise<any> {
    return this.requestService.authGet(this.ADVANCE_SHOP_BILL_URL, request);
  }

  getShopCheckoutRecord(leaseId: any): Promise<ShopRecordResponse> {
    return this.requestService.authGet(this.SHOP_CHECKOUT_RECORD, {lease_id: leaseId});
  }

  gathingBillCheckoutAudit(request: any): Promise<any>{
    return this.requestService.authGet(this.GATHERING_BILL_CHECKOUT_AUDIT, request);
  }
}
