import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseEnum} from '../../../../model/modal/base-model';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseInfoService} from '../../../../service/common/base-info.service';
import {AuthService} from '../../../../service/common/auth.service';
import {HttpClient} from '@angular/common/http';
import {WorkService} from '../../../../service/common/work.service';
import {WorkModel} from '../../../../model/common/common.model';
import {MessageModalInfo} from '../../../../model/modal/message-model';
import {getUserAgent} from '../../../../common/common';
import {environment} from '../../../../../environments/environment';
import {RoomChangeService} from '../../../../service/lease/roomchange.service';
import {
  RoomChangeContractExtReq,
  RoomChangeContractExtResp,
  RoomChangeContractSignUploadReq
} from '../../../../model/lease/roomchange.model';
import {ModalDirective} from "ngx-bootstrap/modal";
import {TabletComponent} from '../../tablet/tablet.component';
import {SignDataRequest, SignOnIpadRequest} from "../../../../model/signed/sign.model";
import {SignedService} from "../../../../service/signed/signed.service";
import {SignDeviceBindingComponent} from "../../../sign-device-binding/sign-device-binding.component";
import {ProjectService} from "../../../../service/project/project.service";
import {ProjectTemplateRequest, ProjectTemplateResponse} from "../../../../model/project/project.model";

@Component({
  selector: 'app-cr-change-room-contract',
  templateUrl: './cr-change-room-contract.component.html',
  styleUrls: ['./cr-change-room-contract.component.css']
})
export class CrChangeRoomContractComponent implements OnInit {

  record_id: number;

  contract_ext: RoomChangeContractExtResp;

  template_content: string;

  // 租约状态列表
  status_list: BaseEnum[];

  pdf_url: string;

  can_show: string;
  load_error: boolean = false;

  iframe: SafeResourceUrl;

  frame_name = "frame_" + Math.random();

  // 合同上传地址
  contract_upload_url;

  @ViewChild(TabletComponent, { static: true }) tabletComponent: TabletComponent;
  @ViewChild('staticModal', { static: true }) staticModal: ModalDirective;
  @ViewChild(SignDeviceBindingComponent, { static: true }) signDeviceBindingComponent:SignDeviceBindingComponent;

  constructor(private activatedRoute: ActivatedRoute,
              private baseInfoService: BaseInfoService,
              private authService: AuthService,
              private sanitizer: DomSanitizer,
              private router: Router,
              private http: HttpClient,
              private roomChangeService: RoomChangeService,
              private workService: WorkService,
              private signedService: SignedService,
              private projectService: ProjectService) {
    this.activatedRoute.params.subscribe(params => {
      this.record_id = params['record_id'];
      this.contract_upload_url = `${environment.apiHost}manage/lease/room/change/data/upload?recordId=` + this.record_id;
    });
  }

  ngOnInit() {
    let agent = getUserAgent();
    this.can_show = agent.split("|")[1];
    const req  = new ProjectTemplateRequest();
    let recordIdArray: Array<string> = [this.record_id.toString()];
    req.file_type = 'ROOMCHANGECONTRACT';
    req.param_map = {"recordId": recordIdArray};
    this.projectService.getProjectTemplateConfigure(req).then(data => {
      this.template_content = data.template_content;
    });

    this.roomChangeService.getContractExtInfo(new RoomChangeContractExtReq(this.record_id, true)).then(data => {
      this.contract_ext = data;
    });

    this.status_list = this.baseInfoService.getRoomChangeStatusList();

    // 获取合同pdf的URL
    this.pdf_url = `${environment.apiHost}file/preview?bizcode=ROOMCHANGECONTRACT` + '&recordId=' + this.record_id;
    this.showPdf();
  }

  showPdf() {
    this.http.get(this.pdf_url, {
      headers: {
        'Authorization': this.authService.getAuthToken()
      },
      responseType: "blob"
    }).toPromise().then((data) => {
        let data_url = URL.createObjectURL(data);
        this.iframe = this.sanitizer.bypassSecurityTrustResourceUrl(data_url);
      }
    ).catch(error => {
      this.can_show = "false";
      this.load_error = true;
      let messageModalInfo = new MessageModalInfo();
      messageModalInfo.operationMessage = "展示合同PDF报错:" + error.message;
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, messageModalInfo));
    });
  }

  contractUpload() {
    this.staticModal.show();
  }

  commitSign(img_str: string) {
    if (!img_str) {
      this.workService.event_bus.emit(new WorkModel(this.workService.type.DIALOG_ERROR, '请先填写签名后再点击提交！'));
      return false;
    }

    //电子合同上传
    this.roomChangeService.uploadSignContract(new RoomChangeContractSignUploadReq(this.record_id, img_str)).then(data => {
      this.staticModal.show();
    });
  }

  close() {

    this.workService.event_bus.emit(new WorkModel(this.workService.type.TAB_CLOSE_SELF));

    this.router.navigate(['/system_manage/signed/change/room/details', this.record_id])
  }

  // 在ipad上签名
  signOnIpad(){
    let request = new SignOnIpadRequest();
    request.sign_router_link_type = 'CHANGE_ROOM';
    request.router_link = this.router.url;
    request.response_data = this.contract_ext;
    request.computer_no = localStorage.getItem('PC_UUID');
    let signDataRequest = new SignDataRequest();
    signDataRequest.record_id = this.record_id;
    request.sign_data_request = signDataRequest;
    /*this.signedService.signOnIpad(request).then(data => {
      console.info(data);
    });*/
    // 打开连接弹窗
    this.signDeviceBindingComponent.openModel(request,1);
  }

  // 判断当前设备是否是ipad
  isPad(): boolean{
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone",
      "SymbianOS", "Windows Phone",
      "iPad", "iPod"];
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        return true;
      }
    }
    return false;
  }
}
