<!-- 操作日志 -->
<!--<h5 class="bg-gray ">操作日志</h5>-->
<div class="padd15  yellow" style="background-color: #FFFFE6;">
  <div class="">
    <table class="table border-striped table-fix-scroll v2 " >
      <thead>
      <tr style="background-color: rgba(243, 243, 249, 1);">
        <th>操作时间</th>
        <th>操作人</th>
        <th class="new-lines w-50">日志描述</th>
      </tr>
      </thead>
      <tbody style="background-color: #fff;">
      <ng-container *ngIf="leaseLogs" >
      <tr *ngFor="let item of leaseLogs">
        <td>{{item?.create_time | date:'yyyy-MM-dd HH:mm:ss'}}</td>
        <td>{{item?.operation_peoson}}</td>
        <td class="new-lines w-50">{{item?.operation_content}}</td>
      </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</div>
