import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {
  ApprovalLockPasswordResponse, ApprovalRenterCheckoutDepositResponse,
  ApprovalResultResponse,
  ApprovalTaskListResponse, ApprovalTaskRequest, ApprovalTaskResponse, ApprovLockPasswordListRes,
  ProcessDetailFlowChartResponse,
  ProcessDetailImgResponse
} from "../../model/approv/approv.model";
import {RequestService} from "../request.service";

@Injectable()
export class ApprovService {


  private GET_FLOW_APPROV_DONE_LIST = `${environment.apiHost}flow/approvDoneList`;

  private GET_FLOW_APPROV_FLOW_DETAIL_LIST = `${environment.apiHost}flow/approvRecordList`;

  private GET_FLOW_APPROV_FLOW_DETAIL_IMG = `${environment.apiHost}flow/getProcessImg`;
  private GET_FLOW_APPROV_FLOW_DETAIL_IMGJS = `${environment.apiHost}flow/getProcessImgJS`;

  private POST_FLOW_CANCEL = `${environment.apiHost}flow/cancel`;

  private APPRO_LOCK_PASSWORD = `${environment.apiHost}appro/lock/password`;

  private APPRO_LOCK_PASSWORD_LIST = `${environment.apiHost}appro/lock/password/list`;

  private APPRO_SEND_LOCK_PASSWORD = `${environment.apiHost}appro/send/lock/password`;

  private APPRO_RENTER_CHECKOUT_DEPOSIT_INFO = `${environment.apiHost}appro/renter/checkout/deposit/info`;
  private APPRO_PROJECT_BASE_PRICE_INFO = `${environment.apiHost}appro/project/base/price/info`;
  private APPRO_PROJECT_BASE_PRICE_INFO_PROJECTID = `${environment.apiHost}appro/project/base/price/info/projectid`;
  private QUERY_TASK_ID = `${environment.apiHost}flow/taskId`;
  private APPRO_LEASE_COMMIT_FLOW = `${environment.apiHost}appro/lease/commit/flow`;

  // 资产入库
  constructor(private requestService: RequestService) {
  }

  approvDoneList(params?: any): Promise<ApprovalTaskListResponse> {
    return this.requestService.authGet(this.GET_FLOW_APPROV_DONE_LIST, params,true);
  }


  approvDoneDetail(id?: any): Promise<ApprovalResultResponse> {
    return this.requestService.authGet(this.GET_FLOW_APPROV_FLOW_DETAIL_LIST, {process_instance_id: id});
  }

  approvDetailImg(id?: any) :Promise<ProcessDetailImgResponse> {
    return this.requestService.authGet(this.GET_FLOW_APPROV_FLOW_DETAIL_IMG, {process_instance_id: id});
  }

  approvDetailImgJS(id?: any) :Promise<ProcessDetailFlowChartResponse> {
    return this.requestService.authGet(this.GET_FLOW_APPROV_FLOW_DETAIL_IMGJS, {process_instance_id: id});
  }

  cancelFlow(id?: any) {
    return this.requestService.authPost(this.POST_FLOW_CANCEL, {process_instance_id: id});
  }

  getLockPasswordDetail(id?: any):Promise<ApprovalLockPasswordResponse>{
    return this.requestService.authGet(this.APPRO_LOCK_PASSWORD, {appro_record_infoid: id});
  }

  getLockPasswordList(req: any):Promise<ApprovLockPasswordListRes>{
    return this.requestService.authGet(this.APPRO_LOCK_PASSWORD_LIST, req,true);
  }

  sendLockPassword(id?: number) {
    return this.requestService.authPost(this.APPRO_SEND_LOCK_PASSWORD, {appro_record_info_id: id});
  }

  renterCheckoutDepositInfo(id?: any):Promise<ApprovalRenterCheckoutDepositResponse>{
    return this.requestService.authGet(this.APPRO_RENTER_CHECKOUT_DEPOSIT_INFO, {appro_record_infoid: id});
  }

  projectBasePriceInfo(id?: any): Promise<any> {
    return this.requestService.authGet(this.APPRO_PROJECT_BASE_PRICE_INFO, {appro_record_infoid: id});
  }
  projectBasePriceInfoByProjectId(id?: any): Promise<any> {
    return this.requestService.authGet(this.APPRO_PROJECT_BASE_PRICE_INFO_PROJECTID, {project_id: id});
  }
  queryTaskId(req:ApprovalTaskRequest): Promise<ApprovalTaskResponse>{
    return this.requestService.authGet(this.QUERY_TASK_ID, req);
  }

  leaseCommitFlow(id:number): Promise<any>{
    return this.requestService.authGet(this.APPRO_LEASE_COMMIT_FLOW,{appro_record_infoid:id});
  }
}
