import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RequestService } from '../request.service';
import {
  ChangeRoomNowTermRentBillReq,
  ChangeRoomNowTermRentBillResp,
  RenterChangeConfirmReq, RoomChangeApplyReq, RoomChangeApplyResp, RoomChangeAvaliableReq, RoomChangeAvaliableResp,
  RoomChangeBillsReq,
  RoomChangeBillsResp, RoomChangeCancelReq, RoomChangeCommonReq, RoomChangeConfirmResp, RoomChangeContractExtResp,
  RoomChangeContractSignUploadReq, RoomChangeGotoStepReq, RoomChangeListReq, RoomChangeListResp,
  RoomChangeRecordConfirmReq, RoomChangeSettingReq, RoomChangeSettingResp
} from '../../model/lease/roomchange.model';

@Injectable()
export class RoomChangeService {

  // 获取换房记录列表
  private ROOM_CHANGE_LIST = `${environment.apiHost}manage/lease/room/change/record/list`;
  // 获取老房间租约信息
  private ROOM_CHANGE_ORIGIN_INFO = `${environment.apiHost}manage/lease/room/change/origin/info`;
  // 获取新老房间租约信息
  private ROOM_CHANGE_INFO = `${environment.apiHost}manage/lease/room/change/redis/info`;
  //无合同
  private ROOM_CHANGE_INTERNAL_DATA = `${environment.apiHost}manage/lease/room/change/internal/data`;
  // 换房设置
  private ROOM_CHANGE_APPLY = `${environment.apiHost}manage/lease/room/change/new/apply`;
  // 换房取消
  private ROOM_CHANGE_CANCEL = `${environment.apiHost}manage/lease/room/change/cancel`;
  // 可换房房间列表
  private ROOM_CHANGE_AVALIABLE_LEASE_ROOM_LIST = `${environment.apiHost}manage/lease/room/change/avaliable/lease/room/list`;
  // 切换步骤
  private GOTO_STEP = `${environment.apiHost}manage/lease/room/change/goto/step`;
  // 换房单详情
  private FINANCE_DETAILS = `${environment.apiHost}manage/lease/room/change/data/new/view`;
  // 换房单确认
  private ROOM_CHANGE_CONFIRM = `${environment.apiHost}manage/lease/room/change/record/redis/confirm`;
  // 换房单合同额外信息
  private CONTRACT_EXT = `${environment.apiHost}manage/lease/room/change/contract/ext`;
  // 上传签名合同
  private UPLOAD_SIGN_CONTRACT = `${environment.apiHost}manage/lease/room/change/sign/upload`;
  // 缓存收款前换房单
  private STORE_HISTORY = `${environment.apiHost}manage/lease/room/change/store/history`;

  private CHANGE_ROOM_NOW_TERM_RENT_BILL = `${environment.apiHost}manage/lease/room/change/now/term/rent/bill`;

  // v3.0.0
  // 换房确认提交（无合同换房、有合同合同内换房）
  private RENTER_CHANGE_CONFIRM = `${environment.apiHost}manage/lease/room/change/renter/change/confirm`;

  constructor(private requestService: RequestService) {
  }

  /**
   * @Description: 获取换房记录列表
   * @Author: 陆炳彬
   * @Date: 2019/1/4 下午2:06
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2019/1/4 下午2:06
   */
  public getRoomChangeList(params: RoomChangeListReq): Promise<RoomChangeListResp> {
    return this.requestService.authGet(this.ROOM_CHANGE_LIST, params);
  }

  /**
   * @Description: 获取老房间租约信息
   * @Author: 陆炳彬
   * @Date: 2019/1/7 上午10:03
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2019/1/7 上午10:03
   */
  public getRoomChangeOriginInfo(params: RoomChangeSettingReq): Promise<RoomChangeSettingResp> {
    return this.requestService.authGet(this.ROOM_CHANGE_ORIGIN_INFO, params);
  }

  /**
   * @Description: 换房设置
   * @Author: 陆炳彬
   * @Date: 2019/1/7 下午2:46
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2019/1/7 下午2:46
   */
  public roomChangeApply(params: RoomChangeApplyReq): Promise<RoomChangeApplyResp> {
    return this.requestService.authPost(this.ROOM_CHANGE_APPLY, params);
  }

  /**
   * @Description: 获取新老房间租约信息
   * @Author: 陆炳彬
   * @Date: 2019/1/8 上午10:25
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2019/1/8 上午10:25
   */
  public getRoomChangeInfo(params: RoomChangeCommonReq): Promise<RoomChangeConfirmResp> {
    return this.requestService.authGet(this.ROOM_CHANGE_INFO, params);
  }

  /**
   * @Description: 换房取消
   * @Author: 陆炳彬
   * @Date: 2019/1/8 下午5:14
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2019/1/8 下午5:14
   */
  public roomChangeCancel(params: RoomChangeCancelReq): Promise<any> {
    return this.requestService.authPost(this.ROOM_CHANGE_CANCEL, params);
  }

  /**
   * @Description: 获取可换房房间列表
   * @Author: 陆炳彬
   * @Date: 2019/1/8 下午7:44
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2019/1/8 下午7:44
   */
  public getAvaliableRoomChangeLeaseRoomList(params: RoomChangeAvaliableReq): Promise<RoomChangeAvaliableResp> {
    return this.requestService.authGet(this.ROOM_CHANGE_AVALIABLE_LEASE_ROOM_LIST, params);
  }

  /**
   * @Description: 切换步骤
   * @Author: 陆炳彬
   * @Date: 2019/1/9 上午10:47
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2019/1/9 上午10:47
   */
  public gotoStep(params: RoomChangeGotoStepReq): Promise<any> {
    return this.requestService.authGet(this.GOTO_STEP, params);
  }

  /**
   * @Description: 获取换房单详情
   * @Author: 陆炳彬
   * @Date: 2019/1/9 上午11:32
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2019/1/9 上午11:32
   */
  public getFinanceDetails(params: RoomChangeBillsReq): Promise<RoomChangeBillsResp> {
    return this.requestService.authGet(this.FINANCE_DETAILS, params);
  }

  /**
   * @Description: 换房单确认
   * @Author: 陆炳彬
   * @Date: 2019/1/9 下午4:25
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2019/1/9 下午4:25
   */
  public roomChangeConfirm(params: RoomChangeRecordConfirmReq): Promise<any> {
    return this.requestService.authPost(this.ROOM_CHANGE_CONFIRM, params);
  }

  /**
   * @Description: 获取换房协议额外信息
   * @Author: 陆炳彬
   * @Date: 2019/1/9 下午5:49
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2019/1/9 下午5:49
   */
  public getContractExtInfo(params: RoomChangeCommonReq): Promise<RoomChangeContractExtResp> {
    return this.requestService.authGet(this.CONTRACT_EXT, params);
  }

  /**
   * @Description: 上传签名合同
   * @Author: 陆炳彬
   * @Date: 2019/1/10 上午10:05
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2019/1/10 上午10:05
   */
  public uploadSignContract(params: RoomChangeContractSignUploadReq): Promise<any> {
    return this.requestService.authPost(this.UPLOAD_SIGN_CONTRACT, params);
  }

  /**
   * @Description: 记录收款之前的历史
   * @Author: 陆炳彬
   * @Date: 2019/1/11 下午1:54
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2019/1/11 下午1:54
   */
  public storeHistory(params: RoomChangeCommonReq): Promise<any> {
    return this.requestService.authGet(this.STORE_HISTORY, params);
  }

  /**
   * @Description: 换房确认提交（无合同换房、有合同合同内换房）
   * @Author: yangwen
   * @Date: 2020/3/4 15:17
   */
  public renterChangeConfirm(params: RenterChangeConfirmReq): Promise<any> {
    return this.requestService.authPost(this.RENTER_CHANGE_CONFIRM, params);
  }

  /**
   * @Description: 换房 免租约数据入库
   * @Author: 张卓月
   * @Date: 2021年5月8日13:38:49
   */
  public insertInternalLeaseData(params: RoomChangeCommonReq): Promise<any> {
    return this.requestService.authPost(this.ROOM_CHANGE_INTERNAL_DATA, params);
  }

  public queryChangeRoomNowTermRentBill(params: ChangeRoomNowTermRentBillReq): Promise<ChangeRoomNowTermRentBillResp> {
    return this.requestService.authGet(this.CHANGE_ROOM_NOW_TERM_RENT_BILL,params);
  }
}
