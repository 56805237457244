import { EventEmitter } from '@angular/core';

export class MessageModalInfo {
  title?: string;
  stayStatus?: boolean;
  cancel?: boolean;
  operationMessage: string;
  returnUrl?: string;
  modal_class?: string;
  timeout?: number;
  callback?: EventEmitter<any> = new EventEmitter<any>();
  cancelCallback?: EventEmitter<any> = new EventEmitter<any>();
  type?: number = 1;
  html?: string;
  okBtn?: string;

  trace_id?: string;
}

export class MessageType {
  public static SUCCESS = 'modal-primary';
  public static ERROR = 'modal-primary';
}

