<div class="card" style="margin-bottom: 0;">
  <!--<div class="card-header yellow1">-->
    <!--换房记录-->
  <!--</div>-->
  <div class="padd15 yellow" style="background-color: #FFFFE6;">
    <div class="card-body scrollx-head">
      <table class="table table-bordered border-striped scrollx-body">
        <thead style="background: rgba(243, 243, 249, 1)">
        <tr>
          <th>原房间号</th>
          <th *ngIf="lease_step !== 'INTERNAL_LEASE'" >原平均租金（元）</th>
          <th>新房间号</th>
          <th *ngIf="lease_step !== 'INTERNAL_LEASE'">新平均租金（元）</th>
          <th>状态</th>
          <th>操作</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let record of room_change_record_list">
          <td>{{record.origin_room_name}}</td>
          <td *ngIf="lease_step !== 'INTERNAL_LEASE'">
            {{record.origin_avg_month_rent}}
            <a *ngIf="record.origin_rent_type && record.origin_rent_type == 'MULTI'" (click)="openRoomDetail(record.origin_lease_room_id)"
                 class="text-primary pointer">明细</a>
          </td>
          <td>{{record.new_room_name}}</td>
          <td *ngIf="lease_step !== 'INTERNAL_LEASE'">
            {{record.new_avg_month_rent}}
            <a *ngIf="record.new_rent_type && record.new_rent_type == 'MULTI'" (click)="openRoomDetail(record.new_lease_room_id,record.record_id)"
               class="text-primary pointer">明细</a>
          </td>
          <td>{{record.status | enumTransfer: room_change_status_list}}</td>
          <td>
            <button *ngIf="record.status === 'AUDIT_FAIL'" class="btn btn-outline-primary" (click)="showFailReason(record.audit_fail_reason)">查看</button>
            <button *ngIf="record.status === 'UNCOMPLETED'" class="btn btn-outline-primary" (click)="goOn(record)">继续</button>
            <button *ngIf="record.status === 'COMPLETED'" class="btn btn-outline-primary" [routerLink]="['/system_manage/signed/change/room/details', record.record_id]">查看</button>
            <button *ngIf="record.status === 'UNCOMPLETED' || record.status === 'WAITING_AUDIT'" class="btn btn-outline-primary" (click)="cancelRoomChange(record.record_id)">取消</button>
          </td>
        </tr>

        <tr *ngIf="room_change_record_list.length === 0" class="text-right">
          <td colspan="6" class="show1">本次未查询到相关数据</td>
          <td colspan="8" class="show2">本次未查询到相关数据</td>
        </tr>
        </tbody>
      </table>

      <!--<div class="text-right pages">-->
        <!--<pagination-controls-->
                             <!--(pageChange)="pageChange($event)"-->
                             <!--directionLinks="true"-->
                             <!--previousLabel="上一页"-->
                             <!--nextLabel="下一页"-->
                             <!--screenReaderPaginationLabel="Pagination"-->
                             <!--screenReaderPageLabel="page"-->
                             <!--screenReaderCurrentLabel="You're on page">-->
        <!--</pagination-controls>-->
      <!--</div>-->
    </div>
  </div>
</div>

<app-dialog>
</app-dialog>
