import {Injectable} from '@angular/core';
import {
  Beds,
  FloorDetail,
  GetRoomListByIdsRequest,
  ManageRoomListRequest,
  ManageRoomListResponse,
  RoomCheckDetailResponse,
  ProjectUnitResponse,
  RoomDateStatusResponse,
  RoomDetailReq,
  RoomElemeterEnableLogRequest,
  RoomElemeterEnableLogResponse,
  RoomEquipmentIndicationRequest,
  RoomEquipmentIndicationResponse,
  RoomEquipmentOrIndicationRequest,
  RoomFuzzyRequest,
  RoomIntelligDeviceRequest,
  RoomIntelligDeviceResponse,
  RoomLeaseRequest,
  RoomLeaseResponse,
  RoomRealTimeInfoResponse,
  RoomSmartRechargeRequest,
  RoomSmartRechargeResponse,
  RoomStatusListResponse,
  RoomStatusRequest,
  UnitDetail,
  BatchEditInfoRequest,
  DownLoadEditInfoRequest,
  RoomEditInfoResponse,
  ManageRoomEditRecordListRequest,
  ManageRoomEditRecordListResponse,
  PrepayTransferAuditCodeRequest,
  TransferPrepayBalnaceResponse,
  TransferPrepayBalnaceRequest,
  PrepayTransferLogRequest,
  PrepayTransferLogResponse,
  PrepayTransferAuditListResponse,
  PrepayRoomListRequest,
  PrepayRoomListResponse,
  PropayConsumeTotalLogResponse,
  PropayConsumeTotalLogRequest,
  PrepayLogRequest,
  PrepayLogResponse,
  RoomDirtyStatusRequest,
  ProjectListUnitRequest,
  RoomRealTimeDetailInfoResponse, CancelRoomTimeStampRequest, SetingRoomTimeStampRequest, EffectiveLeaseNoticeResponse,
} from '../../model/room/room.model';
import {RequestService} from '../request.service';
import {environment} from '../../../environments/environment';
import {ReminderInfo} from '../../view/room-configuration/room-panel/room-panel.component';
import {CheckinRoomInventoryRequest} from "../../model/signed/inventory.model";
import {RoomInspectListRecordRequest} from "../../model/roominspect/roominspect.model";
import {AuthService} from "../common/auth.service";
import {OrderManagerRequest} from "../../model/ebusiness/ebusiness.model";

/**
 * @description:房源相关服务
 *
 * @Author: xulihua
 * @Date: 2018-02-27 15:31:31
 * @Last Modified by: xulihua
 * @Last Modified time: 2018-02-27 15:31:31
 */
@Injectable()
export class RoomService {

  // 房源创建api
  private roomCreateUrl = `${environment.apiHost}manage/room/create/update`;
  // 房间更新
  private roomUpdateUrl = `${environment.apiHost}manage/room/create/update`;
  // 房间删除
  private roomDeleteUrl = `${environment.apiHost}manage/room/delete/info`;
  // 房间批量更新
  private roomBatchUpdateUrl = `${environment.apiHost}manage/room/batch/update`;
  // 床型api
  private bedUrl = `${environment.apiHost}manage/room/bed/list`;
  // 楼栋（单元）api
  private projectUrl = `${environment.apiHost}room/unit/list`;
  // 项目下楼栋（单元）api
  private projectListUrl = `${environment.apiHost}room/unit/project/list`;
  // 房间详情
  private roomDetailUrl = `${environment.apiHost}manage/room/detail/info`;
  // 房间管理列表
  private manageRoomListUrl = `${environment.apiHost}manage/room/list`;
  // 房间信息变更审批记录
  private manageRoomEditRecordListUrl = `${environment.apiHost}manage/room/audit/record/list`;
  // 管理房间详情
  private manageRoomCheckDetail = `${environment.apiHost}manage/room/check/details`;
  // 设置房间状态
  private manageSetRoomStatus = `${environment.apiHost}manage/room/set/status`;
  // 批量修改房间信息
  private manageBatchEditInfo = `${environment.apiHost}manage/room/batch/edit/info`;
  // 导出批量修复房间信息
  private manageDownLoadEditInfo = `${environment.apiHost}manage/room/down/load/edit/info`;
  // 上传批量修改完的房间信息
  private manageImportEditInfo = `${environment.apiHost}manage/room/import/edit/info`;
  // 可入住房间模糊查询
  private roomFuzzyUrl = `${environment.apiHost}manage/room/fuzzy/list`;
  // 房态
  private ROOM_STATUS_URL = `${environment.apiHost}room/status/list`;
  // 房态详情
  // private ROOM_STATUS_DETAIL_URL = `${environment.apiHost}room/status/info`;
  private ROOM_STATUS_INFO_URL = `${environment.apiHost}room/search/room/lease`;
  //
  private ROOM_STATUS_DETAIL_INFO_URL = `${environment.apiHost}room/status/detail/info`;
  //催租内容
  private REMINDER_INFO = `${environment.apiHost}manage/room/collection/info`;
  //发送催租短信
  private SEND_REMINDER = `${environment.apiHost}sms/sendMSM`;
  // 模糊搜索
  private SEARCH_ROOM_BY_NAME = `${environment.apiHost}manage/room/fuzzy/list`;
  // 单元下房间列表
  private ROOM_IN_UNIT = `${environment.apiHost}manage/room/list/in/unit`;
  // 电表断闸日志
  private ROOM_ELEMETER_LOG = `${environment.apiHost}room/elemeter/log`;
  // 根据房间id列表获取房间列表
  private ROOM_LIST_BY_IDS = `${environment.apiHost}room/list/by/ids`;
  // 查询房间时段占用情况
  private ROOM_SEARCH_ROOM_STATUS = `${environment.apiHost}room/search/room/status`;
  //获取房间的物品交接清单
  private ROOM_TYPE_GOODS_LIST = `${environment.apiHost}goods/list`;
  //查看房间详情
  private ROOM_DETAIL = `${environment.apiHost}manage/room/detail`;
  //保存入住物品交接单
  private SAVE_CHECKIN_GOODS = `${environment.apiHost}goods/uploadSignGoodsList`;
  //缓存入住物品交接单
  private REDIS_CHECKIN_GOODS = `${environment.apiHost}goods/redis/sign/goods`;
  //判断该物品交接单是否存在快照
  private IS_EXSIT_SNAP = `${environment.apiHost}goods/isExsitSnap`;
  //新增备注
  private INSERT_REMARK = `${environment.apiHost}goods/insertRemark`;
  //查询备注
  private QUERY_REMARK_LIST = `${environment.apiHost}goods/getRemarks`;
  //查询房间的资产确认状态
  private ROOM_ASSET_STATUS = `${environment.apiHost}goods/asset/status`;
  //从Redis中查询房间的资产确认状态
  private ROOM_ASSET_REDIS_STATUS = `${environment.apiHost}goods/asset/redis/status`;
  // 置保留房
  private ROOM_RESERVED_URL = `${environment.apiHost}manage/room/reserved`;
  // 取消置保留房
  private CANCEL_ROOM_RESERVED_URL = `${environment.apiHost}manage/room/reserved/cancel`;
  // 获取项目所有楼层
  private ROOM_FLOOR_LIST = `${environment.apiHost}room/floor/list`;

  // 查询房间入离人员记录
  private ROOM_OLD_RENTER_LIST = `${environment.apiHost}manage/renter/list/roomHistoryPersonList`;

  // 房态充值列表
  private ROOM_SMART_RECHARGE_LIST_URL = `${environment.apiHost}room/search/room/smart/recharge`;

  private ROOM_TEST = `${environment.apiHost}room/test`;

  //房间硬件选型及智能设备情况
  private ROOM_INTELLIG_DEVICE = `${environment.apiHost}room/intelligDevice`;

  //获取房间当前生效租约id
  private GET_LEASE_ID_BY_ROOM_ID = `${environment.apiHost}room/getLeaseByRoom`;

  //获取房间当前生效租约id
  private PROJECT_ROOM_TYPE_LIST = `${environment.apiHost}manage/room/project/room/type/list`;

  //房间预付账户充值记录下载
  private GET_ROOM_DOWN_PAYMENT_RECORDS = `${environment.apiHost}room/downPaymentRecords`;

  private ROOM_GET_EQUIPMENT_OR_INDICATION = `${environment.apiHost}room/getRoomEquipmentOrIndication`;

  private ROOM_SET_EQUIPMENT_OR_INDICATION = `${environment.apiHost}room/setRoomEquipmentOrIndication`;
  //【余额转移】房间列表
  private ROOM_TRANSFER_BALANCE_ROOM_LIST = `${environment.apiHost}room/showTransferRoomList`;
  //根据房间名称搜索房间列表【余额转移】
  private ROOM_TRANSFER_LIST_SEARCH_BY_NAME = `${environment.apiHost}room/searchByTransferRoomName`;
  //【余额转移】审核 人员列表
  private TRANSFER_AUDIT_LIST = `${environment.apiHost}room/transferAuditList`;
//【余额转移】发送短信验证码 prepayTransferAuditCode
  private PREPAY_TRANSFER_AUDIT_CODE = `${environment.apiHost}room/prepayTransferAuditCode`;
  //【【余额转移】】 转移余额 + 短信验证
  private TRANSFER_PREPAY_BALANCE = `${environment.apiHost}room/transferPrepayBalnace`;

  //【转移余额】 转移记录
  private PREPAY_TRANSFER_LOG = `${environment.apiHost}room/transferLogs`;

  //【转移余额】 【报表】 转移日志
  private RPT_PREPAY_TRANSFER_LOGS = `${environment.apiHost}room/reportTransferLog`;
  //抵冲记录 【报表】PropayConsumeTotalLogs
  private PROPAY_CONSUME_TOTAL_LOGS = `${environment.apiHost}room/propayConsumeTotalLogs`;

  // 脏房/取消脏房
  private POST_TOGGLE_ROOM_DIRTY = `${environment.apiHost}room/toggleChangeRoomDirtyStatus`;

  private ROOM_PREPAY_INFO = `${environment.apiHost}room/prepay/info`;

  private ROOM_LIMITING = `${environment.apiHost}room/limiting`;

  private ROOM_MANAGE_SETING_STAMP = `${environment.apiHost}manage/room/seting/stamp`;

  private ROOM_MANAGE_CANCEL_STAMP = `${environment.apiHost}manage/room/cancel/stamp`;

  private GET_ROOM_MANAGE_STAMP = `${environment.apiHost}manage/room/get/stamp`;

  private GET_ROOM_BUILDING_INDEX = `${environment.apiHost}room/building/index`;

  private GET_ROOM_BUILDING_INDEX_ALL = `${environment.apiHost}room/building/index/all`;

  private GET_ROOM_BUILDING_FIRE_COMPONENT_LIST = `${environment.apiHost}room/building/component`;

  private POST_ROOM_BUILDING_INDEX_CHANGE = `${environment.apiHost}room/building/indexChange`;

  private POST_ROOM_BUILDING_ROOM_RELATION = `${environment.apiHost}room/building/component/related`;


  private GET_NOTICE_LEASE = `${environment.apiHost}manage/lease/effective/lease/notice`;




  constructor(private requestService: RequestService, private authService: AuthService) {
  }

  public roomTest(requestData: any) {
    return this.requestService.authGet(this.ROOM_TEST, requestData);
  }

  /**
   *  创建房源
   */
  public roomCreateFunc(requestData: any): Promise<any> {
    return this.requestService.authPost(this.roomCreateUrl, requestData);
  }

  /**
   *  创建房源
   */
  public roomUpdateFunc(requestData: any): Promise<any> {
    return this.requestService.authPost(this.roomUpdateUrl, requestData);
  }

  /**
   * 获取房源详情
   */
  public getCurrentRoomFunc(requestData: any): Promise<any> {
    return this.requestService.authGet(this.roomDetailUrl, requestData);
  }

  /**
   * 删除房间
   */
  public roomDeleteFunc(requestData: any): Promise<any> {
    return this.requestService.authPost(this.roomDeleteUrl, requestData);
  }

  /**
   * 批量更新房间
   */
  public roomBatchUpdateFunc(requestData: any): Promise<any> {
    return this.requestService.authPost(this.roomBatchUpdateUrl, requestData);
  }

  /**
   * 拉取现有床型
   */
  public getBasicRoomBeds(requestData?: any): Promise<Beds> {
    return this.requestService.authGet(this.bedUrl, requestData);
  }


  /**
   * 单元列表
   */
  public getProjectUnits(requestData?: any): Promise<ProjectUnitResponse> {
    return this.requestService.authGet(this.projectUrl, requestData);
  }

  /**
   * 项目下单元列表
   */
  public getProjectListUnits(requestData?: ProjectListUnitRequest): Promise<ProjectUnitResponse> {
    return this.requestService.authPost(this.projectListUrl, requestData);
  }

  /**
   * 房间管理列表
   */
  public getManageRoomList(requestData?: ManageRoomListRequest): Promise<ManageRoomListResponse> {
    return this.requestService.authPost(this.manageRoomListUrl, requestData);
  }

  /**
   * 管理房间详情
   */
  public getRoomCheckDetail(requestData: any): Promise<RoomCheckDetailResponse> {
    return this.requestService.authGet(this.manageRoomCheckDetail, requestData);
  }

  /**
   * 设置房间状态
   */
  public setRoomStatus(requestData?: any): Promise<any> {
    return this.requestService.authPost(this.manageSetRoomStatus, requestData);
  }

  /**
   * 批量修改房间信息
   */
  public batchEditInfo(requestData?: BatchEditInfoRequest): Promise<RoomEditInfoResponse> {
    return this.requestService.authPost(this.manageBatchEditInfo, requestData);
  }

  /**
   * 导出批量修改房间信息
   */
  public downLoadEditRoomInfo(req?: DownLoadEditInfoRequest): string {
    return this.manageDownLoadEditInfo + "?room_edit_record_id=" + req.room_edit_record_id + "&project_id=" + req.project_id;
  }

  /**
   * 上传批量修改完的房间信息
   */
  public importEditRoomInfo(requestData?: any): Promise<any> {
    return this.requestService.authPost(this.manageImportEditInfo, requestData);
  }

  /**
   * 根据名称模糊查询可入住房源
   * @param {{room_name: string; is_necessary: string}} param
   */
  public getRoomAndBedList(requestData: any): Promise<any> {
    return this.requestService.authGet(this.roomFuzzyUrl, requestData);
  }

  /**
   * @description: 模糊搜索房源，（根据房间名）
   * @Author: mazy
   * @Date: 2018/4/23 20:18
   * @Last Modified by: mazy
   * @Last Modified time: 2018/4/23 20:18
   */
  public likeRoomList(requestData: RoomFuzzyRequest): Promise<any> {
    return this.requestService.authGet(this.SEARCH_ROOM_BY_NAME, requestData);
  }

  /**
   * 房态图
   */
  getRoomStatusList(requestData?: any): Promise<RoomStatusListResponse> {
    return this.requestService.authGet(this.ROOM_STATUS_URL, requestData);
  }

  getRoomStatusListWithLoading(requestData?: any): Promise<RoomStatusListResponse> {
    return this.requestService.authGet(this.ROOM_STATUS_URL, requestData, true);
  }

  sortArrByFloor(a, b) {
    return a.floor - b.floor;
  }

  sortArrByUnit(a, b) {
    return a.unit_id - b.unit_id;
  }

  combineAllRoomStatusList(response: RoomStatusListResponse, base: Array<UnitDetail>) {
    let data = response;
    // 归类
    let unitDetailList = base ? base : [];
    if (data.room_status_list && data.room_status_list.length > 0) {
      let list = data.room_status_list;
      for (let i = 0; i < list.length; i++) {
        let room = list[i];
        // 是否有该单元
        let unitDetail: UnitDetail = unitDetailList.find(u => u.unit_id === room.unit_id);
        if (unitDetail) {
          // 是否有该楼层
          let floorDetail = unitDetail.floorList.find(f => f.floor === room.floor);
          if (floorDetail) {
            floorDetail.room.push(room);
          } else {
            let newFloorDetail = new FloorDetail();
            newFloorDetail.floor = room.floor;
            newFloorDetail.floor_room_count = room.floor_room_count;
            newFloorDetail.room = [];
            // 存房
            newFloorDetail.room.push(room);
            // 存楼层
            unitDetail.floorList.push(newFloorDetail);
            // sort
            unitDetail.floorList = unitDetail.floorList.sort(this.sortArrByFloor);
          }
        } else {
          let newUnitDetail = new UnitDetail();
          newUnitDetail.unit_id = room.unit_id;
          newUnitDetail.unit_name = room.unit_name;
          newUnitDetail.project_name = room.project_name;
          newUnitDetail.floorList = [];
          //
          let newFloorDetail = new FloorDetail();
          newFloorDetail.floor = room.floor;
          newFloorDetail.floor_room_count = room.floor_room_count;
          newFloorDetail.room = [];
          // 存房
          newFloorDetail.room.push(room);
          // 存单元
          newUnitDetail.floorList.push(newFloorDetail);
          // sort
          newUnitDetail.floorList = newUnitDetail.floorList.sort(this.sortArrByFloor);

          unitDetailList.push(newUnitDetail);

        }
      }

      // sort
      unitDetailList = unitDetailList.sort(this.sortArrByUnit);

    }

    return unitDetailList;
  }

  // 房态详情
  public getRoomStatusInfo(requestData: any): Promise<RoomRealTimeInfoResponse> {
    return this.requestService.authGet(this.ROOM_STATUS_INFO_URL, requestData);
  }

  public getReminderInfo(leaseId: number, roomId: number): Promise<any> {
    return this.requestService.authPost(this.REMINDER_INFO, {lease_id: leaseId, room_id: roomId});
  }

  public sendReminder(reminderInfo: ReminderInfo): Promise<any> {
    return this.requestService.authPost(this.SEND_REMINDER, reminderInfo);
  }

  public roomListInUnit(requestData: any): Promise<any> {
    return this.requestService.authGet(this.ROOM_IN_UNIT, requestData);
  }

  public roomElemeterLog(requestData: RoomElemeterEnableLogRequest): Promise<RoomElemeterEnableLogResponse> {
    return this.requestService.authGet(this.ROOM_ELEMETER_LOG, requestData);
  }

  /**
   * @Description: 根据房间id列表获取房间列表
   * @Author: 陆炳彬
   * @Date: 2018/9/13 下午3:23
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/9/13 下午3:23
   */
  public getRoomListByIds(params: GetRoomListByIdsRequest): Promise<any> {
    return this.requestService.authGet(this.ROOM_LIST_BY_IDS, params);
  }

  public roomSearchRoomStatus(requestData: RoomStatusRequest): Promise<RoomDateStatusResponse> {
    return this.requestService.authGet(this.ROOM_SEARCH_ROOM_STATUS, requestData);
  }

  public getRoomTypeGoodsList(requestData: CheckinRoomInventoryRequest): Promise<any> {
    return this.requestService.authGet(this.ROOM_TYPE_GOODS_LIST, requestData);
  }

  public getRoomDetail(req: RoomDetailReq): Promise<any> {
    return this.requestService.authGet(this.ROOM_DETAIL, req);
  }

  public getRoomDetailWithLoading(req: RoomDetailReq): Promise<any> {
    return this.requestService.authGet(this.ROOM_DETAIL, req, true);
  }

  public saveCheckINGoods(requestData: any): Promise<any> {
    return this.requestService.authPost(this.SAVE_CHECKIN_GOODS, requestData);
  }

  public redisCheckINGoods(requestData: any): Promise<any> {
    return this.requestService.authPost(this.REDIS_CHECKIN_GOODS, requestData);
  }

  public isExistSnap(requestData: any): Promise<any> {
    return this.requestService.authGet(this.IS_EXSIT_SNAP, requestData);
  }

  public insertRemark(requestData: any): Promise<any> {
    return this.requestService.authPost(this.INSERT_REMARK, requestData);
  }

  public queryRemarkList(requestData: any): Promise<any> {
    return this.requestService.authGet(this.QUERY_REMARK_LIST, requestData);
  }

  public queryRoomAssetStatus(requestData: any): Promise<any> {
    return this.requestService.authGet(this.ROOM_ASSET_STATUS, requestData);
  }

  public queryRoomAssetStatusInRedis(requestData: any): Promise<any> {
    return this.requestService.authGet(this.ROOM_ASSET_REDIS_STATUS, requestData);
  }

  public roomReserved(requestData: any): Promise<any> {
    return this.requestService.authPost(this.ROOM_RESERVED_URL, requestData);
  }

  public cancelRoomReserved(requestData: any): Promise<any> {
    return this.requestService.authPost(this.CANCEL_ROOM_RESERVED_URL, requestData);
  }

  public getFloorList(): Promise<any> {
    return this.requestService.authGet(this.ROOM_FLOOR_LIST, null);
  }


  /**
   *  充值记录
   */
  public roomRechargeList(requestData: RoomSmartRechargeRequest): Promise<RoomSmartRechargeResponse> {
    return this.requestService.authGet(this.ROOM_SMART_RECHARGE_LIST_URL, requestData);
  }

  /**
   *  房间硬件选型及智能设备情况
   */
  public roomIntelligDevce(requestData: RoomIntelligDeviceRequest): Promise<RoomIntelligDeviceResponse> {
    return this.requestService.authGet(this.ROOM_INTELLIG_DEVICE, requestData);
  }

  /**
   * 获取房间当前有效租约id
   * @param requestData
   */
  public getLeaseByRoom(requestData: RoomLeaseRequest): Promise<RoomLeaseResponse> {
    return this.requestService.authGet(this.GET_LEASE_ID_BY_ROOM_ID, requestData);
  }

  /**
   * 获取项目关联的房型
   * @param requestData
   */
  public getProjectRoomTypeList(requestData?: any): Promise<Beds> {
    return this.requestService.authGet(this.PROJECT_ROOM_TYPE_LIST, requestData);
  }


  downPaymentRecords(request: RoomSmartRechargeRequest) {

    let param = "";
    for (let key in request) {
      if (request[key] && request.hasOwnProperty(key)) {
        param += (key + '=' + request[key] + '&');
      }
    }
    if (param) {
      window.open(this.GET_ROOM_DOWN_PAYMENT_RECORDS + "?" + param + 'Authorization=' + this.authService.getAuthToken(), "_blank");
    } else {
      window.open(this.GET_ROOM_DOWN_PAYMENT_RECORDS + '?Authorization=' + this.authService.getAuthToken(), "_blank");
    }
  }


  public getRoomEquipmentOrIndication(req?: RoomEquipmentIndicationRequest): Promise<RoomEquipmentIndicationResponse> {
    return this.requestService.authGet(this.ROOM_GET_EQUIPMENT_OR_INDICATION, req);
  }

  public setRoomEquipmentOrIndication(req?: RoomEquipmentOrIndicationRequest): Promise<any> {
    return this.requestService.authPost(this.ROOM_SET_EQUIPMENT_OR_INDICATION, req);
  }

  public getRoomOldRenter(req): Promise<any> {
    return this.requestService.authGet(this.ROOM_OLD_RENTER_LIST, req);
  }

  public showTransferRoomList(req?: PrepayRoomListRequest): Promise<PrepayRoomListResponse> {
    return this.requestService.authGet(this.ROOM_TRANSFER_BALANCE_ROOM_LIST, req);
  }

  public searchByTransferRoomName(req?: PrepayRoomListRequest): Promise<PrepayRoomListResponse> {
    return this.requestService.authGet(this.ROOM_TRANSFER_LIST_SEARCH_BY_NAME, req);
  }


  public transferAuditList(): Promise<PrepayTransferAuditListResponse> {
    return this.requestService.authGet(this.TRANSFER_AUDIT_LIST);
  }

  public prepayTransferAuditCode(req?: PrepayTransferAuditCodeRequest): any {
    return this.requestService.authGet(this.PREPAY_TRANSFER_AUDIT_CODE, req);
  }


  public transferPrepayBalnace(req?: TransferPrepayBalnaceRequest): Promise<TransferPrepayBalnaceResponse> {
    return this.requestService.authGet(this.TRANSFER_PREPAY_BALANCE, req);
  }


  public transferLogs(req?: PrepayTransferLogRequest): Promise<PrepayTransferLogResponse> {
    return this.requestService.authGet(this.PREPAY_TRANSFER_LOG, req);
  }

  public reportTransferLogs(req?: PrepayLogRequest): Promise<PrepayLogResponse> {
    return this.requestService.authGet(this.RPT_PREPAY_TRANSFER_LOGS, req);
  }

  public propayConsumeTotalLogs(req?: PropayConsumeTotalLogRequest): Promise<PropayConsumeTotalLogResponse> {
    return this.requestService.authGet(this.PROPAY_CONSUME_TOTAL_LOGS, req);
  }

  /**
   * 房间信息变更审批记录
   */
  public getRoomEditRecordList(requestData?: ManageRoomEditRecordListRequest): Promise<ManageRoomEditRecordListResponse> {
    return this.requestService.authGet(this.manageRoomEditRecordListUrl, requestData);
  }

  toggleRoomDirty(req: RoomDirtyStatusRequest): Promise<any> {
    return this.requestService.authPost(this.POST_TOGGLE_ROOM_DIRTY, req);
  }

  roomPrepayInfo(req): Promise<any> {
    return this.requestService.authGet(this.ROOM_PREPAY_INFO, req);
  }

  roomLimiting(req): Promise<any> {
    return this.requestService.authGet(this.ROOM_LIMITING, req);
  }

  setingRoomTimeStamp(req: SetingRoomTimeStampRequest): Promise<any> {
    return this.requestService.authPost(this.ROOM_MANAGE_SETING_STAMP, req);
  }

  cancelRoomTimeStamp(req: CancelRoomTimeStampRequest): Promise<any> {
    return this.requestService.authPost(this.ROOM_MANAGE_CANCEL_STAMP, req);
  }

  public getRoomStatusDetailInfo(roomId: number): Promise<RoomRealTimeDetailInfoResponse> {
    return this.requestService.authGet(this.ROOM_STATUS_DETAIL_INFO_URL, {room_id: roomId});
  }

  getRoomTimeStamp(req): Promise<any> {
    return this.requestService.authGet(this.GET_ROOM_MANAGE_STAMP, req);
  }


  getRoomBuildingIndex(): Promise<any> {
    return this.requestService.authGet(this.GET_ROOM_BUILDING_INDEX);
  }

  getRoomBuildingIndexAll(): Promise<any> {
    return this.requestService.authLongGet(this.GET_ROOM_BUILDING_INDEX_ALL, null, 10000);
  }

  getRoomBuildingComponent(roomId, str): Promise<any> {
    return this.requestService.authGet(this.GET_ROOM_BUILDING_FIRE_COMPONENT_LIST, {room_id: roomId, content: str});
  }

  postBuildingComponentIndexChange(ids): Promise<any> {
    return this.requestService.authPost(this.POST_ROOM_BUILDING_INDEX_CHANGE, {room_ids: ids});
  }

  postBuildingComponentRoomRelation(roomId, ids): Promise<any> {
    return this.requestService.authPost(this.POST_ROOM_BUILDING_ROOM_RELATION, {room_id: roomId, component_ids: ids});
  }


  public getNoticeLeaseList(type): Promise<EffectiveLeaseNoticeResponse> {
    return this.requestService.authGet(this.GET_NOTICE_LEASE, {'subject_type': type});
  }

}
