{{initDialog(gatherBill,bindOrderBill,gatherSuccess,checkOutModel,refundBillModel, refundBillConfirmModel, refundBillConfirmSuccessModel)}}
<!--                   收款dialog      开始       -->
<!-- 收款 -->
<div class="modal fade" bsModal #gatherBill="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>收款</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeGatherDialog(gatherBill)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <button type="button" class="btn " [ngClass]="{'btn-link': !hasContact,'btn-info':hasContact }" (click)="changeContactGather(true)">有合同收款</button>
          <button type="button" class="btn " [disabled]="noContract" [ngClass]="{'btn-info': !hasContact,'btn-link':hasContact}" (click)="changeContactGather(false)">无合同收款</button>
        </div>
        <div class="row mb10">
          <div class="col-12 col-md-4 mb10" *ngIf="hasContact">
            <p>请输入合同号：</p>
            <div class="w-100">
              <input type="text" class="w-100" (input)="enterLeaseId('CONTACT_NUM',contactNum)" [(ngModel)]="contactNum">
              <p class="text-danger" *ngIf="errorContent">{{errorContent}}</p>
            </div>
          </div>

          <div class="col-12 col-md-4 mb10" *ngIf="hasContact">
            <p>请选择车库：</p>
            <div class="w-100">
              <ngx-select [items]="carportInfoList" optionValueField="carport_info_id" optionTextField="carport_number" [multiple]="false"
                          (select)="roomSelect()" (remove)="roomSelect()" [(ngModel)]="carportIds" placeholder="请选择"></ngx-select>
            </div>
          </div>

          <div class="col-12 col-md-4 mb10" *ngIf="hasContact">
            <p>请选择账单类型：</p>
            <select class="w-100" (change)="selectBillingType($event)">
              <option value=0 >全部</option>
              <option *ngFor="let item of billTypeList" [value]="item.bill_type_id">{{item.bill_type_name}}</option>
            </select>
          </div>

        </div>
        <div class="partbox1" *ngIf="hasContact">
          <!--       其它费用  -->
          <div class="row" *ngIf="expenseType==='otherExpense'&&!showTips">
            <div class="mb-2 col-12">
              <span class="text-danger ml-3">您的收款会自动创建账单，</span>
              <span class="b-blues ml-2 pointer" (click)="changeGatherType('renterExpense')">如想选择已有账单 请点击这里</span>
            </div>

            <div class="col-12 col-md-3 mb-2">
              <label>收款金额 <i class="red">*</i></label>
              <input type="text" class="w-100" placeholder="请输入收款金额" (input)="renterExpense()" [(ngModel)]="confirmPaymentRequest.actual_payment_amount">
            </div>
            <div class="col-12 col-md-3 mb-2">
              <label>款项类型</label>
              <select class="w-100" [(ngModel)]="confirmPaymentRequest.bill_type_code" (change)="clearRemark()">
                <option value="" disabled>款项类型</option>
                <option value="WATER">水费</option>
                <option value="ELECTRICITY">电费</option>
                <option value="PARKING">停车费</option>
                <option value="OTHER">其他费用</option>
              </select>
            </div>
            <div class="col-12 col-md-4 mb-2">
              <label> &nbsp; &nbsp; </label>
              <input type="text" class="w-100" placeholder="其他项请写具体备注" [disabled]="confirmPaymentRequest.bill_type_code!=='OTHER'"
                     placeholder="其他项请写具体备注" [(ngModel)]="confirmPaymentRequest.remark">
            </div>
          </div>

          <!--    房费     -->
          <div class="part4" *ngIf="expenseType ==='renterExpense'&&!showTips">
            <div class="mb-2">
              <span>选中的车库已出账单如下</span>
              <span class="text-danger ml-3">没找到账单？</span>
              <span class="b-blues ml-2 pointer" (click)="changeGatherType('otherExpense')">点击这里收款同时创建账单</span>
            </div>

            <div class="table-box">

              <table class="table table-bordered table-striped">
                <thead>
                <tr>
                  <th>
                    <input type="checkbox" [checked]="checkedBillIds.length == billList.length" (click)="checkAll($event)">
                  </th>
                  <th>车库号</th>
                  <th>账单类型</th>
                  <th>账单周期</th>
                  <th>应付时间</th>
                  <th>费用</th>
                  <th>未付费</th>
                  <th>状态</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of billList">
                  <td>
                    <input type="checkbox" [checked]="(checkedBillIds.indexOf(item.bill_id)>=0)" (click)="checkCurrentBill($event,item.bill_id,item.waiting_pay_amount)">
                  </td>
                  <td>{{item.carport_number}}</td>
                  <td>{{item.bill_type_name}}</td>
                  <td>{{item.bill_start_time | date:'yyyy-MM-dd'}}-{{item.bill_end_time | date:'yyyy-MM-dd'}}</td>
                  <td>{{item.plan_pay_time |date: 'yyyy-MM-dd HH:mm:ss'}}</td>
                  <td>{{item.bill_amount}}</td>
                  <td>{{item.waiting_pay_amount}}</td>
                  <td>{{item.bill_status_name}}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="mb-2">
              <span>共选择了{{ckeckedNum}}个账单，总计<span class="text-danger big">
                  {{this.confirmPaymentRequest?.payment_total_amount!=null?confirmPaymentRequest?.payment_total_amount:'0'}}</span>元</span>
            </div>

          </div>
        </div>

        <div class="partbox1" *ngIf="!hasContact&&expenseType==='NOLEASE'">
          <div class="row">
            <div class="col-12 col-md-3 mb-2">
              <label>收款金额 <i class="red">*</i></label>
              <input type="text" class="w-100" placeholder="请输入收款金额" (input)="renterExpense()" [(ngModel)]="confirmPaymentRequest.actual_payment_amount">
            </div>
            <div class="col-12 col-md-3 mb-2">
              <label>款项类型</label>
              <select class="w-100" [(ngModel)]="confirmPaymentRequest.bill_type_code" (change)="clearRemark()">
                <option value="" disabled>款项类型</option>
                <option value="PARKING">停车费</option>
                <option value="OTHER">其他费用</option>
              </select>
            </div>
            <div class="col-12 col-md-4 mb-2">
              <label> &nbsp; &nbsp; </label>
              <input type="text" class="w-100" placeholder="其他项请写具体备注" [disabled]="(confirmPaymentRequest.bill_type_code!=='OTHER' && confirmPaymentRequest.bill_type_code!=='PARKING')"
                     placeholder="其他项请写具体备注" [(ngModel)]="confirmPaymentRequest.remark">
            </div>
          </div>
        </div>
        <div class="form-check mb-5 hei32" *ngIf="expenseType!=='NOLEASE'">
          <label class="form-check-label" *ngIf="paymentInfoDTO && vaildAmount(paymentInfoDTO?.book_amount)">
            <small *ngIf="paymentInfoDTO && vaildAmount(paymentInfoDTO?.book_amount)">本合同有
              <span class="text-danger" *ngIf="paymentInfoDTO && vaildAmount(paymentInfoDTO?.book_amount)">预定金{{paymentInfoDTO?.book_amount!=null?
                paymentInfoDTO?.book_amount:'0'}}元</span>，勾选此处抵扣</small></label>
          <span *ngIf="expenseType !=='otherExpense'">
            <span class=" ml-2">本次收取</span>
            <input type="text" class=" ml-2" style="width: 140px;display: inline-block;" (input)="renterExpense()"
                   [(ngModel)]="confirmPaymentRequest.actual_payment_amount"> <i class="red ml-2">*</i>
            <span class="secondary  ml-2">可分批次使用不同方式收取</span>
          </span>
        </div>
        <p class="text-warning" *ngIf="actualAmountValidErrorContent">{{actualAmountValidErrorContent}}</p>

        <p class="orange mb-3 text-center" *ngIf="!showTips&&expenseType"><small>确认金额后，点击下方按钮收款</small></p>
        <div class="money-list text-center " *ngIf="!showTips&&expenseType">
          <span class="green-c pointer" (click)="payment('WEIXIN',aliOrWeixin,'aliOrWeixin')">微信</span>
          <span class="blues-c pointer" (click)="payment('ALIPAY',aliOrWeixin,'aliOrWeixin')">支付宝</span>
          <span class="blue-c pointer" (click)="payment('CASH',posPaymnet,null)">现金</span>
          <span class="blue-c pointer" (click)="payment('TRANSFER',posPaymnet,null)">转账</span>
          <span class="blue-c pointer" (click)="payment('POS',posPaymnet,null)">POS</span>
          <span class="blue-c pointer" (click)="payment('CHECK',posPaymnet,null)">支票</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 支付宝/微信收款 -->
<div class="modal fade" bsModal #aliOrWeixin="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{paymentType==='WEIXIN'?'微信':'支付宝'}}收款</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeAliOrWei(aliOrWeixin)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">
          <small class="text-orange">已选择{{paymentTypeName}}收款</small>
          {{confirmPaymentRequest.actual_payment_amount}}元
          <small class="text-orange">正在等待支付中({{clock}})……</small>
        </h2>
        <p class="b-blues font12 text-center " *ngIf="showContent" (click)="showQueryByOrderId(orderQuery,aliOrWeixin)">
          如果实际已支付，但还没有见到支付成功的提示，请点击此处；</p>
      </div>
    </div>
  </div>
</div>
<!-- 现金收款 -->
<div class="modal fade" bsModal #cashPayment="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>现金收款</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cashPayment.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">
          <small class="text-orange">已选择{{paymentTypeName}}收款</small>
          {{confirmPaymentRequest.actual_payment_amount}}元
          <small class="text-orange">请确认</small>
        </h2>
        <p class="b-muted font12 text-center mb20">确认后不得现更改，将立即入账</p>
        <div class="text-center mb20">
          <button type="button" class="btn btn-warning mr20" (click)="confirm(paymentType,cashPayment,gatherSuccess)" [attr.disabled]="!showConfirmBtn ? true : null">
            {{showConfirmBtn ? "确认已收" : "确认中..."}}
          </button>
          <button type="button" class="btn btn-secondary" (click)="cashPayment.hide()">取消</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- POS/转账收款 -->
<div class="modal fade" bsModal #posPaymnet="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left" *ngIf="paymentType==='CHECK'">支票收款</h4>
        <h4 class="modal-title pull-left" *ngIf="paymentType==='POS'">POS收款</h4>
        <h4 class="modal-title pull-left" *ngIf="paymentType==='TRANSFER'">转账收款</h4>
        <h4 class="modal-title pull-left" *ngIf="paymentType==='CASH'">现金收款</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="posPaymnet.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">
          <small class="text-orange">已选择{{paymentTypeName}}付款</small>
          {{confirmPaymentRequest.actual_payment_amount}}元
          <small class="text-orange">请上传凭证附件后保存</small>
        </h2>
        <p class="b-muted font12 text-center mb20" *ngIf="paymentType === 'POS'">POS收款请拍照收银小票</p>
        <p class="b-muted font12 text-center mb20" *ngIf="paymentType === 'TRANSFER'">转账请上传转账凭证</p>
        <p class="b-muted font12 text-center mb20" *ngIf="paymentType === 'CHECK'">支票请拍照</p>
        <p class="b-muted font12 text-center mb20" *ngIf="paymentType === 'CASH'">现金请拍照上传现金收据</p>
        <div class="text-center mb20">
          <div class="padd15">
            <ul class="img-box">
              <app-new-file-upload [type]="2" [maxSize]="15 * 1024 * 1024" [accept]="'IMG'" [maxLength]="5" [imgList]="confirmPaymentRequest?.attached_list"
                                   (fileChange)="paymnetFill($event)"></app-new-file-upload>
            </ul>
          </div>
        </div>
        <p class="b-muted font12 text-center mb20" *ngIf="paymentType==='CHECK' || paymentType === 'TRANSFER' || paymentType === 'CHECK' || paymentType === 'CASH'">
          最多可上传5个附件，每个15M之内
        </p>
        <div class="text-center mb20">
          <button type="button" class="btn btn-warning mr20" (click)="confirm(paymentType,posPaymnet,gatherSuccess)" [attr.disabled]="!showConfirmBtn ? true : null">
            {{showConfirmBtn ? "确认已收" : "确认中..."}}
          </button>
          <button type="button" class="btn btn-secondary" (click)="posPaymnet.hide()">取消</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 收款成功 -->
<div class="modal fade" bsModal #gatherSuccess="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>收款成功</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="gatherSuccess.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">
          <small class="text-orange">已成功收款</small>
          {{confirmPaymentRequest.actual_payment_amount}}元
          <small class="text-orange">此窗口将在{{close}}秒后关闭</small>
        </h2>
        <p class="b-muted font12 text-center mb20">当您看见此界面，代表此款已经入账</p>
        <div class="text-center mb20">
          <button type="button" class="btn btn-info mr20" (click)="printPaymentCret()">
            <i class="fa fa-print"></i>打印凭证
          </button>
          <button type="button" class="btn btn-secondary" (click)="gatherSuccess.hide()">关闭</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 输入商户订单号： -->
<div class="modal fade" bsModal #orderQuery="bs-modal" style="z-index: 999999;background: rgba(0,0,0,0.4);" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>输入商户订单号：</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeOrderQueryDialog(orderQuery)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-center mb20 black">请输入用户手机支付结果中的商户订单号：</p>
        <div class="row mb10">
          <div class="col-md-6 offset-md-3">
            <input type="text" class="w-100 " [(ngModel)]="orderId">
          </div>
        </div>
        <p class="text-orange font12 text-center mb20" *ngIf="orderRemind">查不到相应的支付记录 ，请确认订单号是否正确</p>
        <div class="text-center mb20">
          <button type="button" class="btn btn-primary mr20" (click)="queryByOrderId(orderQuery,gatherSuccess)">
            确认
          </button>
          <button type="button" class="btn btn-secondary" (click)="closeOrderQueryDialog(orderQuery)"> 关闭</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 账单订单号绑定页面 -->
<div class="modal fade" bsModal #bindOrderBill="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>请选择收款项</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bindOrderBill.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <p class="red mb10">已读取到{{orderNum}}笔相同金额的收款，请选择确认：</p>
        <div class="table-box">
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th>商户账单号</th>
              <th>支付金额</th>
              <th>支付时间</th>
              <th>操作</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of orderDtos">
              <td>{{item.order_id}}</td>
              <td>{{item.order_amount}}</td>
              <td>{{item.pay_time |date:'yyyy-MM-dd HH:mm:ss'}}</td>
              <td>
                <button type="button" class="btn btn-info">
                  <i class="fa fa-eye" (click)="reletedBillAndOrder(item.order_no,bindOrderBill,gatherSuccess)">选择确认</i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <p class="b-blues font12" (click)="showQueryByOrderId(orderQuery,bindOrderBill)">上面没有找到相应的数据，但用户实际已经完成付款，请点此处</p>
      </div>
    </div>
  </div>
</div>

<!-- 退房收款 -->
<div class="modal fade" bsModal #checkOutModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>退房收款</h4>
        <button type="button" class="close pull-right" aria-label="Close"  (click)="checkOutModel.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mt80-150 text-center">退房收款，应收总金额：
          <span class="text-orange display-inline text-money">
            {{confirmPaymentRequest?.payment_total_amount!=null?confirmPaymentRequest?.payment_total_amount:'0'}}<span>元</span>
          </span>
        </div>
        <p class="text-orange mb10 text-center">请点击下面的收款方式进行收款</p>
        <div class="money-list text-center ">
          <span class="green-c pointer" (click)="payment('WEIXIN',aliOrWeixin,'aliOrWeixin','LEAVE')">微信</span>
          <span class="blues-c pointer" (click)="payment('ALIPAY',aliOrWeixin,'aliOrWeixin','LEAVE')">支付宝</span>
          <span class="blue-c pointer" (click)="payment('CASH',posPaymnet,null,'LEAVE')">现金</span>
          <span class="blue-c pointer" (click)="payment('TRANSFER',posPaymnet,null,'LEAVE')">转账</span>
          <span class="blue-c pointer" (click)="payment('POS',posPaymnet,null,'LEAVE')">POS</span>
          <span class="blue-c pointer" (click)="payment('CHECK',posPaymnet,null,'LEAVE')">支票</span>
        </div>
      </div>
    </div>
  </div>
</div>
<app-carport-pay-log-detail></app-carport-pay-log-detail>
<!--                   收款dialog        结束     -->


<!-- 账单退款选择页面 -->
<div class="modal fade" bsModal #refundBillModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left text-orange">退款申请</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeRefundDialog(refundBillModel)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mb10">
          <div class="col-12 col-md-4 mb10">
            <p>请选择车库：</p>
            <div class="w-100">
              <ngx-select [items]="carportInfoList" optionValueField="carport_info_id" optionTextField="carport_number"
                          [multiple]="false"
                          (select)="roomSelect('REFUND')" (remove)="roomSelect('REFUND')"
                          [(ngModel)]="carportIds" placeholder="请选择"></ngx-select>
            </div>
          </div>

          <div class="col-12 col-md-4 mb10" *ngIf="hasContact">
            <p>请选择账单类型：</p>
            <select class="w-100" (change)="selectBillingType($event,'REFUND')">
              <option value=0>全部</option>
              <option *ngFor="let item of refundBillTypeList"
                      [value]="item.bill_type_id">{{item.bill_type_name}}</option>
            </select>
          </div>

          <div class="col-12 col-md-4 mb10" *ngIf="hasContact">
            <div class="w-100">
              <span class="text-danger" *ngIf="billList && billList.length === 0">无满足条件的账单</span>
            </div>
          </div>
        </div>
        <div class="partbox1" *ngIf="hasContact">
          <!--    房费     -->
          <div class="part4">
            <div class="mb-2">
              <span class="text-dark ml-3">请选择已支付账单：</span>
            </div>
            <div class="table-box">
              <table class="table table-bordered table-striped">
                <thead>
                <tr>
                  <th>
                    <input type="checkbox" [checked]="checkedBillIds.length>0" (click)="checkRefundBillAll($event)">
                  </th>
                  <th>车库号</th>
                  <th>账单类型</th>
                  <th>账单周期</th>
                  <th>应付时间</th>
                  <th>账单金额</th>
                  <th>已支付金额</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of billList">
                  <td>
                    <input type="checkbox" [checked]="(checkedBillIds.indexOf(item.bill_id)>=0)"
                           (click)="checkRefundCurrentBill($event,item.bill_id,item.received,item.bill_type_name)">
                  </td>
                  <td>{{item.carport_number}}</td>
                  <td>{{item.bill_type_name}}</td>
                  <td>{{item.bill_start_time |
                    date:'yyyy-MM-dd'}}-{{item.bill_end_time | date:'yyyy-MM-dd'}}
                  </td>
                  <td>{{item.plan_pay_time |date: 'yyyy-MM-dd HH:mm:ss'}}</td>
                  <td>{{item.bill_amount}}</td>
                  <td>{{item.received}}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="mb-2">
              <span>共选择了{{ckeckedNum}}个账单，退款金额总计<span class="text-danger big">
                  {{refundApplyRequest?.payment_total_amount != null ? refundApplyRequest?.payment_total_amount : '0'}}</span>元</span>
            </div>
          </div>
        </div>

        <div class="row paddingl0">
          <div class="col-12 col-md-12 mb10">
            <p class="hei32 w100px">退款方式：</p>
            <ng-container>
              <div class="form-check form-check-inline hei32 mr20">
                <input (click)="modeOfPaymentSet($event,'TRANSFER')"
                       [checked]="modeOfPayment&&modeOfPayment==='TRANSFER'"
                       class="form-check-input" type="checkbox">
                <label class="form-check-label">转账</label>
              </div>
              <div class="form-check form-check-inline hei32 mr20">
                <input (click)="modeOfPaymentSet($event,'CASE')"
                       [checked]="modeOfPayment&&modeOfPayment==='CASE'"
                       class="form-check-input" type="checkbox">
                <label class="form-check-label">现金</label>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="row paddingl0" *ngIf="modeOfPayment&&modeOfPayment === 'TRANSFER'">
          <div class="col col-lg-4 col-md-4 mt-1">
            <p>收款人姓名：</p>
            <div class="mt-2">
              <input type="text" placeholder="请输入收款人姓名" [(ngModel)]="refundApplyRequest.gather_value"
                     (input)="entryTransferInfo('GATHER_VALUE',$event.target.value)">
            </div>
            <p *ngIf="!refundApplyRequest.gather_value && modeOfPayment === 'TRANSFER'" class="text-danger">收款人姓名必填</p>
          </div>
          <div class="col col-lg-4 col-md-4 mt-1">
            <p>开户行：</p>
            <div class="mt-2">
              <input type="text" placeholder="请输入开户行" [(ngModel)]="refundApplyRequest.bank_name"
                     (input)="entryTransferInfo('BANK_NAME',$event.target.value)">
            </div>
            <p *ngIf="!refundApplyRequest.bank_name && modeOfPayment === 'TRANSFER'" class="text-danger">开户行必填</p>
          </div>
          <div class="col col-lg-4 col-md-4 mt-1">
            <p>银行账号：</p>
            <div class="mt-2">
              <input type="text" placeholder="请输入银行账号" [(ngModel)]="refundApplyRequest.card_number"
                     (input)="entryTransferInfo('CARD_NUMBER',$event.target.value)">
            </div>
            <p *ngIf="!refundApplyRequest.card_number && modeOfPayment === 'TRANSFER'" class="text-danger">银行账号必填</p>
          </div>
        </div>
        <div class="money-list row ">
          <div class="col-lg-12 mt-4 text-center">
            <button type="button" class="btn btn-primary"
                    [attr.disabled]="((modeOfPayment&&modeOfPayment === 'CASE')||
                    (modeOfPayment&&modeOfPayment === 'TRANSFER' && !canRefundNextError)) && ckeckedNum > 0
                     ? null : true"
                    (click)="confirmRefundRequest(refundBillConfirmModel)">
              下一步
            </button>
            <button class="btn btn-default" (click)="closeRefundDialog(refundBillModel)">取消</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 退款申请：审批确认 -->
<div class="modal fade" bsModal #refundBillConfirmModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" keyboard="true"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-orange modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left text-orange">退款申请</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="closeRefundDialog(refundBillConfirmModel)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mb-2">
              <span>共选择了{{ckeckedNum}}个账单，退款金额总计<span class="text-danger big">
                  {{refundApplyRequest?.payment_total_amount != null ? refundApplyRequest?.payment_total_amount : '0'}}</span>元</span>
          </div>
          <div class="col-lg-12 mb-1">
            <span>您正在操作退款申请，请进行管理人员短信验证：</span>
          </div>
        </div>
        <div class="row paddingl0">
          <div class="col col-lg-4 col-md-4 mt-1">
            <select class="mt-2 w-100" [(ngModel)]="refundApplyRequest.audit_phone">
              <option value="ALL"disabled>审核人</option>
              <option *ngFor="let man of refundAuditUserList" [value]="man.user_phone">{{man.user_name}}({{man.user_phone}})
              </option>
            </select>
            <div *ngIf="!refundApplyRequest.audit_phone" class="text-danger">审核用户不能为空</div>
          </div>
          <div class="col col-lg-4 col-md-4 mt-1">
            <div class="mt-2">
              <input type="text" placeholder="请输入短信验证码" [(ngModel)]="refundApplyRequest.audit_code"
                     (input)="auditCodeInput($event.target.value)">
            </div>
            <div *ngIf="!refundApplyRequest.audit_code" class="text-danger">验证码不能为空</div>
            <div *ngIf="refundApplyRequest.audit_code && auditCodeError " class="text-danger">短信验证码验证错误</div>
          </div>
          <div class="col col-lg-4 col-md-4 mt-1 pl-0 ml-0">
            <button type="button" class="btn btn-primary pull-left"
                    [attr.disabled]="(refundApplyRequest.audit_phone === 'ALL' || (time>0 && time< 60)) ? true : null "
                    (click)="getAuditCodeWithUser()">
              <span>{{codeBtnStr}}</span>
            </button>
          </div>
        </div>
        <div class="money-list row ">
          <div class="col-lg-12 mt-4 text-center">
            <button class="btn btn-primary"
                    [attr.disabled]="((refundApplyRequest.audit_phone === 'ALL' || !refundApplyRequest.audit_code ||
                 auditCodeError ) ? true : null)"
                    (click)="confirmRefundSubmit();refundBillModel.hide();">提交</button>
            <button class="btn btn-default"
                    (click)="closeRefundConfirmDialog(refundBillConfirmModel)">取消</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--退款申请成功提示框-->
<div class="modal fade" bsModal #refundBillConfirmSuccessModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" keyboard="false"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-success modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left text-orange">退款申请</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="closeRefundDialog(refundBillConfirmSuccessModel)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row text-center">
          <div class="col-lg-12 col-md-12 pt-10 ">
            退款单申请已提交成功，退款单号：
            <span class="text-danger">{{resultRefundNo}}</span>
          </div>
        </div>
        <div class="money-list row ">
          <div class="col-lg-12 mt-4 text-center">
            <span class="blue-c pointer"
                  (click)="closeRefundDialog(refundBillConfirmSuccessModel)">关闭</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
