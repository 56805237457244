import { Pipe, PipeTransform } from "@angular/core";

/**
 * @Description: 预定单状态管道
 * @Author: 周嘉豪
 * @Date: 2018/3/29 上午10:26
 * @Last Modified by: 周嘉豪
 * @Last Modified time: 2018/3/29 上午10:26
 */
@Pipe({
  name: 'bookingStatusPipe'
})
export class BookingStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const status = {
      BOOKING: '预定',
      CANCEL: '取消',
      TO_LEASE: '转租约',
      UNCOMMITTED: '未提交',
      COMMITTED: '已提交',
      EFFECTIVE: '已生效',
      CHECKOUT: '已退房',
      AUDIT_FAIL: '审核失败',
      EXPIRED: '已失效'
    };
    return status[value];
  }
}
