import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {isNumeric} from "rxjs/internal-compatibility";

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
})
export class PageComponent implements OnInit {

  @Input() id;
  @Input() p = false;
  @Output() pageChangeEvent = new EventEmitter<any>();


  constructor(private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(param => {
      let page = param['p'];
      if (this.p && page && isNumeric(page)) {
        this.pageChange(page);
      }
    });

  }

  pageChange($event) {
    if (this.p){
      this.router.navigate([], {queryParams: {'p': $event}});
    }
    this.pageChangeEvent.emit($event);
  }

}
