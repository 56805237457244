import {environment} from "../../../environments/environment";
import {RequestService} from "../request.service";
import {
  WorkScheduleCheckRequest,
  WorkScheduleDeleteRequest,
  WorkScheduleListRequest,
  WorkScheduleListResponse, WorkScheduleUpdateRequest
} from "../../model/schedule/work-schedule.model";
import {Injectable} from '@angular/core';

@Injectable()
export class WorkScheduleService {
  private WORK_SCHEDULE_LIST = `${environment.apiHost}work/schedule/list`;
  private WORK_SCHEDULE_MONTH_DELETE = `${environment.apiHost}work/schedule/month/delete`;
  private WORK_SCHEDULE_DAY_CHECK = `${environment.apiHost}work/schedule/day/check`;
  private WORK_SCHEDULE_BATCH_UPDATE = `${environment.apiHost}work/schedule/batch/update`;

  constructor(private requestService: RequestService) {

  }

  public workScheduleLit(request?: WorkScheduleListRequest): Promise<WorkScheduleListResponse> {
    return this.requestService.authGet(this.WORK_SCHEDULE_LIST, request);
  }
  public workScheduleLitWithLoading(request?: WorkScheduleListRequest): Promise<WorkScheduleListResponse> {
    return this.requestService.authGet(this.WORK_SCHEDULE_LIST, request, true);
  }

  monthDelete(request: WorkScheduleDeleteRequest): Promise<any>{
    return this.requestService.authPost(this.WORK_SCHEDULE_MONTH_DELETE, request);
  }

  checkWork(request: WorkScheduleCheckRequest) : Promise<any>{
    return this.requestService.authPost(this.WORK_SCHEDULE_DAY_CHECK, request);
  }

  batchUpdate(request: WorkScheduleUpdateRequest): Promise<any>{
    return this.requestService.authPost(this.WORK_SCHEDULE_BATCH_UPDATE, request);
  }
}
