import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {RoomChangeService} from '../../../../service/lease/roomchange.service';
import {
  ROOM_CHANGE_STEP,
  RoomChangeCommonReq,
  RoomChangeConfirmResp,
  RoomChangeGotoStepReq
} from '../../../../model/lease/roomchange.model';
import {WorkService} from '../../../../service/common/work.service';

@Component({
  selector: 'app-cr-change-room-view',
  templateUrl: './cr-change-room-confirm.component.html',
  styleUrls: ['./cr-change-room-confirm.component.css']
})
export class CrChangeRoomConfirmComponent implements OnInit {

  record_id: number;

  room_change_confirm_resp: RoomChangeConfirmResp;

  renter_name_list: string[] = [];

  type: string = 'NORMAL'; //INTERNAL_LEASE

  // 新房房间租约信息 （string 类型  不会丢失精度）
  newLeaseRoomId: string;

  //当前流程是否是签署退房单  true 则显示 旧房退房单  false 则显示新房入住单
  isCheckOut: Boolean;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private workService: WorkService,
              private roomChangeService: RoomChangeService) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.record_id = params['record_id'];

      this.getRecordDetail(this.record_id);
    });
  }

  // 获取记录详情
  getRecordDetail(record_id: number) {
    this.roomChangeService.getRoomChangeInfo(new RoomChangeCommonReq(record_id)).then(data => {
      this.room_change_confirm_resp = data;
      this.newLeaseRoomId = data['new_lease_room_id'];
      this.isCheckOut = this.room_change_confirm_resp.room_change_record.operate_step !== "CONTRACT";
      this.type = data.lease_step;
      // 入住人解析
      if (this.room_change_confirm_resp.origin_lease_room_info.renter_list
        && this.room_change_confirm_resp.origin_lease_room_info.renter_list.length > 0) {
        this.room_change_confirm_resp.origin_lease_room_info.renter_list.forEach(renter => {
          this.renter_name_list.push(renter.name + '(' + (renter.sex === 'MALE' ? '男' : '女') + ')');
        });
      }
    });
  }

  // 跳转到退房交接单页面
  goCheckoutInventory() {
    this.router.navigate(["../../checkout/inventory"],
      {
        queryParams: {
          lease_id: this.room_change_confirm_resp.room_change_record.lease_id,
          room_id: this.room_change_confirm_resp.origin_lease_room_info.room_id,
          ids: this.room_change_confirm_resp.origin_lease_room_info.room_id,
          record_id: [this.room_change_confirm_resp.room_change_record.record_id],
          redis: true
        },
        relativeTo: this.activatedRoute
      });
  }

  noConfirm() {
    this.workService.showErrorModal('请设置入住物品交接单');
  }

  goCheckinInventory() {
    this.router.navigate(["../../checkin/inventory"],
      {
        queryParams: {
          lease_id: this.room_change_confirm_resp.room_change_record.lease_id,
          room_id: this.room_change_confirm_resp.new_lease_room_info.room_id,
          lease_room_id: this.newLeaseRoomId,
          record_id: [this.room_change_confirm_resp.room_change_record.record_id]
        },
        relativeTo: this.activatedRoute
      });
  }

  // 返回租约详情
  back() {
    this.router.navigateByUrl('/system_manage/signed/pact/' + this.room_change_confirm_resp.room_change_record.lease_id);
  }

  // 跳转到换房单页面
  goNext() {
    if (this.isCheckOut) {
      this.roomChangeService.gotoStep(new RoomChangeGotoStepReq(this.room_change_confirm_resp.room_change_record.record_id, ROOM_CHANGE_STEP.RECORD)).then(data => {
        this.router.navigateByUrl('/system_manage/signed/change/room/bill/details/' + this.room_change_confirm_resp.room_change_record.record_id);
      });
    } else {
      //签署入住交接单后 免租约执行数据入库流程  正常租约走合同上传流程
      if (this.room_change_confirm_resp.lease_step === 'INTERNAL_LEASE') {
        // 免租约
        this.roomChangeService.insertInternalLeaseData(new RoomChangeCommonReq(this.record_id)).then(data => {
          this.router.navigateByUrl('/system_manage/signed/pact/' + this.room_change_confirm_resp.room_change_record.lease_id);
        });
      }else {
        //正常租约
        this.router.navigateByUrl('/system_manage/signed/change/room/contract/' + this.record_id);
      }
    }
  }
}
