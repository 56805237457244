import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-workbench-preload',
  templateUrl: './workbench-preload.component.html',
  styleUrls: ['./workbench-preload.component.css']
})
export class WorkbenchPreloadComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
