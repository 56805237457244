import {environment} from "../../../environments/environment";
import {RequestService} from "../request.service";
import { Injectable } from '@angular/core';
import {
  NoticeOfAppDeatilRequest,
  NoticeOfAppDetailRequest,
  NoticeOfAppEditRequest,
  NoticeOfAppListRequest
} from "../../model/notice/notice-of-app.model";

@Injectable()
export class NoticeOfAppService {
  constructor(private requestService: RequestService) {
  }

  private NOTICE_OF_APP_LIST = `${environment.apiHost}notice_of_app/list`;
  private NOTICE_OF_APP_EDIT = `${environment.apiHost}notice_of_app/add_or_edit`;
  private NOTICE_OF_APP_PUSH = `${environment.apiHost}notice_of_app/push`;
  private NOTICE_OF_APP_DETAIL = `${environment.apiHost}notice_of_app/detail`;
  // 查询列表
  public noticeList(request?: NoticeOfAppListRequest): Promise<any> {
    return this.requestService.authGet(this.NOTICE_OF_APP_LIST, request);
  }

  // 添加或编辑
  public addOrEdit(request?: NoticeOfAppEditRequest): Promise<any> {
    return this.requestService.authPost(this.NOTICE_OF_APP_EDIT, request);
  }

  public push(request?: NoticeOfAppDeatilRequest): Promise<any> {
    return this.requestService.authPost(this.NOTICE_OF_APP_PUSH, request);
  }

  // 查询详情
  public detail(request?: NoticeOfAppDetailRequest): Promise<any> {
    return this.requestService.authGet(this.NOTICE_OF_APP_DETAIL, request);
  }
}
