/**
 * @Description:  待办事件列表响应
 * @Author: zhoujiahao
 * @Date: 2018-05-08  10:54
 */
export class ScheduleListResponse {
  // @Remark("告警数量")
  due_alert_num: number = 0;
  // @Remark("应退未退告警数量")
  nocheckout_alert_num: number = 0;
  // @Remark("故障告警数量")
  fault_alert_num: number = 0;
  // @Remark("门锁待验收数量")
  doorlock_num: number = 0;
  // @Remark("报修数量")
  repair_num: number = 0;
  // @Remark("故障数量")
  malfunction_num: number = 0;
  // @Remark("保洁数量")
  clean_num: number = 0;
  // @Remark("待审批数量")
  audit_num: number = 0;
// @Remark("待处理投诉数量")
  complaint_num: number = 0;
  // 租约财务复核
  lfc_review_num: number = 0;
  // 十天内到期租约提醒
  within_ten_days_count = 0;
  // 资产变更提醒
  asset_change_remind_count = 0;
  // 转租换房提醒
  sublease_remind_count = 0;
}

export class ScheduleOperateModel {
  scheduleType?: string;
  operateType?: string;
}



