<pagination-controls *ngIf="id" [id]="id" (pageChange)="pageChange($event)"
                     directionLinks="true"
                     previousLabel="上一页"
                     nextLabel="下一页"
                     screenReaderPaginationLabel="Pagination"
                     screenReaderPageLabel="page"
                     screenReaderCurrentLabel="You're on page"></pagination-controls>
<pagination-controls *ngIf="!id" (pageChange)="pageChange($event)"
                     directionLinks="true"
                     previousLabel="上一页"
                     nextLabel="下一页"
                     screenReaderPaginationLabel="Pagination"
                     screenReaderPageLabel="page"
                     screenReaderCurrentLabel="You're on page"></pagination-controls>
