import {ViewChild, Component, OnInit} from "@angular/core";
import {MonitorService} from "../../../service/monitor/monitor.service";
import {PersonSafeAlarmListReq, PersonSafeAlarmListRes} from "../../../model/monitor/monitor.model";
import {SafeWarnDetailComponent} from "../safe-warn-detail/safe-warn-detail.component";
import {PersonSafeAlarmDTO} from '../../../model/workbench/workbench.model';

@Component({
  selector: "app-safety-dispose-list",
  templateUrl: "./safety-dispose-list.component.html",
  styleUrls: ["./safety-dispose-list.component.css"]
})
export class SafetyDisposeListComponent implements OnInit {

  @ViewChild(SafeWarnDetailComponent, { static: true }) safeWarnDetailComponent: SafeWarnDetailComponent;

  personSafeAlarmListReq:PersonSafeAlarmListReq = new PersonSafeAlarmListReq();
  personSafeAlarmListRes:PersonSafeAlarmListRes = new PersonSafeAlarmListRes();
  safe_type:string = "ALL";
  loading:boolean = false;
  constructor(
    private monitorService:MonitorService,
  ) {
  }




  ngOnInit() {
    this.personSafeAlarmListReq.page_size = 20;
    this.search(1);
  }

  search(page:number){
    this.personSafeAlarmListReq.page_num = page;
    delete this.personSafeAlarmListRes.person_safe_alarm_dtos;
    this.loading = true;
    if(this.safe_type !== "ALL"){
      this.personSafeAlarmListReq.safe_type = this.safe_type;
    }else{
      delete this.personSafeAlarmListReq.safe_type;
    }
    this.monitorService.getPersonSafeAlarmList(this.personSafeAlarmListReq).then(data =>{
      this.personSafeAlarmListRes = data;
      this.loading = false;
    }).catch(e =>{
      this.loading = false;
    });
  }

  safeWarnDetail(item: PersonSafeAlarmDTO) {

    this.safeWarnDetailComponent.detailShow(item);
  }

  safeWarnHandle(item: PersonSafeAlarmDTO) {
    this.safeWarnDetailComponent.handleShow(item, true);
  }

  safeListHandle() {
  }
}
