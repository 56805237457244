<!-- {{ initDialog(safetyTipsModal, historyModal, expectionModal,
  oftenCityModal)}} -->
<div class="modal fade" bsModal #oftenCityModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name" style="z-index: 99999; background: rgba(0, 0, 0, 0.4);">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i> 设置常用登录城市</h4>
       <button type="button" class="close pull-right" aria-label="Close"
                (click)="getLoginOp();">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 mb-3">
            常用登录城市：
            <select [(ngModel)]="province_id" (change)="checkProvince($event)" class="w-25">
              <option *ngFor="let province of province_dtos" [value]="province?.id">
                {{province?.name}}
              </option>
            </select>&nbsp;&nbsp;
            <select [(ngModel)]="city_id" class="w-25">
              <option *ngFor="let city of city_dtos" [value]="city?.id">
                {{city?.name}}
              </option>
            </select>&nbsp;&nbsp;
            <button class="btn btn-info" (click)="addCity()" type="button">添&nbsp;加
            </button>
          </div>
          <div class="col-12 mb-5">
            已选城市<span style="color: #A1A1A1">（最多可选5个）</span>：
            <ng-container *ngFor="let city of city_name; let i=index">
              <button type="button" style="cursor: none;background: white;border:1px solid #A1A1A1;width: 70px;line-height: 20px;margin-right: 10px;margin-bottom: 5px;">
                {{city.city}}<span (click)="delCity(city.id)" style="cursor:pointer;" class="float-right">×</span>
              </button>
            </ng-container>
          </div>
          <div class="col-12 mb-1" style="color: #A1A1A1">
            设置常用登录城市后，系统将启用账户登录安全预警，监控异常登录行为
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="submit()" class="btn btn-info" type="button">确&nbsp;定
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #expectionModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name" style="z-index: 99999; background: rgba(0, 0, 0, 0.4);">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i> 异常登录通知</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="expectionModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body text-left">
        <div style="color:#FF3300;padding-top: 30px;padding-bottom: 30px;">当前账户近期发生{{login_unusual_dtos_exception.length}}次异常登录行为，如确认非本人行为，你的账户可能已经被盗，请尽快通过“忘记密码”功能修改密码！</div>
        <div style="color: black;">
          <div class="text-left">异常登录状态：</div>
          <div style="max-height: 150px;overflow-y: auto;">

                <table class="table"style="margin-bottom: 10px;">
                  <ng-container *ngFor="let unusualException of login_unusual_dtos_exception;">
                  <tr style="border:none;">
                    <td style="border:none;">{{unusualException?.create_time | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                    <td style="border:none;">{{unusualException?.country}}</td>
                    <td style="border:none;">{{unusualException?.province}}</td>
                    <td style="border:none;">{{unusualException?.city}}</td>
                    <td style="border:none;">{{unusualException?.source}}登录</td>
                  </tr>
                  </ng-container>
                </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="oftenCityModal.show();expectionModal.hide();" class="btn btn-info" type="button">修改登录城市
        </button>&nbsp;&nbsp;
        <button (click)="historyModal.show();expectionModal.hide();" class="btn btn-info" type="button">查看登录历史
        </button>&nbsp;&nbsp;
        <button (click)="getLoginOp()" style="color:white;" class="btn btn-default" style="background-color: #c9c9c9;" type="button">我知道了
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #historyModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name" style="z-index: 99999; background: rgba(0, 0, 0, 0.4);">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i> 登录历史</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="historyModal.hide()">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body text-left" style="max-height: 365px;overflow-y: auto;">
         <div style="color: black;">
          <table class="table table-bordered table-striped" style="border: none;">
            <thead>
            <tr style="border: none;">
              <th style="border: none;">登录时间</th>
              <th style="border: none;" colspan="3">登录城市</th>
              <th style="border: none;">登录端口</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let unusual of login_unusual_dtos;">
              <ng-container *ngIf="unusual?.ext_info && unusual?.ext_info === 'error'">
                <tr style="border: none;color:#FF3300;">
                  <td style="border: none;">{{unusual?.create_time | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                  <td style="border: none;">{{unusual?.country}}</td>
                  <td style="border: none;">{{unusual?.province}}</td>
                  <td style="border: none;">{{unusual?.city}}</td>
                  <td style="border: none;">{{unusual?.source}}登录</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="!unusual?.ext_info">
                <tr style="border: none;">
                  <td style="border: none;">{{unusual?.create_time | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                  <td style="border: none;">{{unusual?.country}}</td>
                  <td style="border: none;">{{unusual?.province}}</td>
                  <td style="border: none;">{{unusual?.city}}</td>
                  <td style="border: none;">{{unusual?.source}}登录</td>
                </tr>
              </ng-container>
            </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="getLoginOp();" style="color:white;" class="btn btn-info" type="button">关&nbsp;闭
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #safetyTipsModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name" style="z-index: 99999; background: rgba(0, 0, 0, 0.4);">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i> 操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="safetyTipsModal.hide();">
          <span aria-hidden="true"><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body text-center" style="padding-top:50px;padding-bottom: 50px;">
        常用登录城市已设置成功！
      </div>
      <div class="modal-footer">
        <button (click)="getLoginOp();" style="color:white;" class="btn btn-info" type="button">关&nbsp;闭
        </button>
      </div>
    </div>
  </div>
</div>
