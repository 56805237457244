import {PageReq, PageResp} from "../page/page";

export class EbusinessPageRequest {
  page_num?: number;
  page_size?: number;
}

export class EbusinessPageResponse {
  total?: number;
}


// product
export class ProductManagerRequest extends EbusinessPageRequest {
  keyword?: string;
  status?: string;
  categoryIds?: string;
  productType?:string;
}

// welfare
export class WelfareManagerRequest extends EbusinessPageRequest {
  channel?: string;

  welfareName?: string;

  welfareStatus?: string;
}

// welfare order
export class WelfareOrderManagerRequest extends EbusinessPageRequest {

  channel?: string;

  key?: string;

  startDate?: number;

  endDate?: number;

  finishStartDate?: number;

  finishEndDate?: number;

  welfareOrderStatus?: string;

}

// withdraw record
export class WithdrawManagerRequest extends EbusinessPageRequest {

  startDate?: number;

  endDate?: number;

  searchKey?: string;

  type?: string;

}

// order
export class OrderManagerRequest extends EbusinessPageRequest {
  keyword?: string;

  orderStatus?: string;

  startDate?: number;

  endDate?: number;

  orderType?: string;

  startPickUpTime?: number;

  endPickUpTime?: number;

  sortType?:number;

  sortMethod?:string;

  orderExchangeStatus?: string;
}

export class ProductManagerModel {

  id?: string;

  product_name?: string;

  brand?: string;

  recommend_slogans?: string;

  status?: string;

  high_price?: string;

  lower_price?: string;

  category_str?: string;

  visits?: number;

  like_count?: number;

  order_count?: number;

  deal_order_count?: number;

  create_time?: number;

  list_image_url?: string;

  is_index_recommended?:number;

  product_type?: string;
}

export class WelfareManagerModel {
  welfare_id?: number;

  welfare_status?: string;

  channel?: string;

  channel_name?: string;

  welfare_name?: string;

  welfare_img_url?: string;

  welfare_price?: string;

  stock_quantity?: number;

  gain_quantity?: number;

  left_quantity?: number;

  start_date?: Date;

  end_date?: Date;
}

export class WelfareOrderManagerModel {

  channel?: string;

  channel_name?: string;

  welfare_order_status?: string;

  third_order_id?: string;

  third_product_name?: string;

  third_order_time?: Date;

  third_order_finish_time?: Date;

  third_order_amount?: string;

  third_refund_amount?: string;

  commission?: string;

  fee_amount?: string;

  actual_commission?: string;

  user_commission?: string;

  plat_commission?: string;

}

export class WithdrawManagerModel {

  id?: number;

  create_time?: Date;

  commit_user_name?: string;

  commit_user_phone?: string;

  gathering_type?: string;

  gathering_account?: string;

  account_name?: string;

  withdraw_amount?: string;

  withdraw_status?: string;

  withdraw_status_code?: string;

  audit_user_name?: string;

  audit_reason?: string;

  serial_number?: string;

  fail_msg?: string;

  audit_opinion?: string;

}

export class WelfareManagerSaveRequest {
  welfare_id: number;
  channel: string;
  welfare_name: string;
  welfare_img_url: string;
  welfare_price: string;
  start_date: number;
  end_date: number;
  stock_quantity: number;
  button_txt: string;
  welfare_link_url: string;
}

export class OrderManagerModel {

  orderMasterId?: string;

  userId?: number;

  userMobile?: string;

  userCommunity?: string;

  userLabel?: string;

  orderType?: string;

  orderStatus?: string;

  payType?: string;

  orderAmount?: number;

  orderActAmount?: number;

  logisticsTotal?: number;

  discountsTotal?: number;

  createTime?: Date;
}

export class ProductManagerResponse extends EbusinessPageResponse {
  //   ON("ON","上架"),OFF("OFF","下架"),DELETE("DELETE","删除");
  status_list?: Array<string>;
  product_manager_model_list?: Array<ProductManagerModel>;
}

export class WelfareManagerRespone extends EbusinessPageResponse {
  welfare_manager_model_list?: Array<WelfareManagerModel>;
}

export class WelfareOrderManagerRespone extends EbusinessPageResponse {
  welfare_order_manager_model_list?: Array<WelfareOrderManagerModel>;
}

export class WithdrawManagerRespone extends EbusinessPageResponse {
  withdraw_record_list?: Array<WithdrawManagerModel>;
}

export class CommonCategoryModel {
  // @Remark("类目编号")
  id?: number;
  // @Remark("类目父编号")
  parent_id?: number;
  // @Remark("类目排序")
  sort?: number;
  // @Remark("类目名称")
  categroy_name?: string;
  // @Remark("类目状态")
  status?: string;
  // @Remark("类目子类集合")
  child_category_models?: Array<CommonCategoryModel>;

  active = false;
}

export class CommonLabelModel {
  // @Remark("商品标签编号")
  id?: number;
  // @Remark("商品标签排序")
  sort?: number;
  // @Remark("商品标签名称")
  label_name?: string;
  // @Remark("商品标签状态")
  status?: string;
  // @Remark("是否默认标签")
  is_default?: string;

  checked: boolean = false;
}

export class SkuSpecModel {
  // @Remark("商品规格编号")
  spec_id?: number;
  // @Remark("商品规格值编号")
  spec_value_id?: number;
  // @Remark("商品规格名称")
  spec_name?: string;
  // @Remark("商品规格值名称")
  spec_value_name?: string;
  // @Remark("商品规格层级编号")
  index?: number;
}

export class SkuResponse {
  // @Remark("SKU规格集合")
  sku_spec_models?: Array<SkuSpecModel>;
  // @Remark("SKU编号")
  sku_id?: number;
  // @Remark("规格图片")
  sku_image?: string;
  // @Remark("市场价")
  cost_price?:string;
  market_price?: string;
  // @Remark("优惠价")
  current_price?: string;
  // @Remark("库存")
  stock?: number;
  // @Remark("库存")
  activity_stock?: number;
  // 帮砍所需刀数
  cut_predict_num?: number;
}

export class ProductIntroductionRequest {
  // @Remark("商品类型")
  desc_type?: string;
  // @Remark("商品介绍 图片/视频/文字")
  desc_content?: string;
  // @Remark("位置排序")
  index_number?: number;
}

export class ProductDetailResponse {
  // @Remark("商品编号")
  product_id?: string;
  // @Remark("商品名称")
  product_name?: string;
  // @Remark("品牌名称")
  brand?: string;
  // @Remark("推荐标语")
  recommend_slogans?: string;
  // @Remark("类目对象集合")
  common_category_models?: Array<CommonCategoryModel>;
  // @Remark("标签对象集合")
  common_label_models?: Array<CommonLabelModel>;
  // @Remark("主图列表")
  main_images?: Array<string>;
  // @Remark("列表图")
  list_image?: string;
  // @Remark("SKU信息(含规格信息)")
  sku_responses?: Array<SkuResponse>;
  // @Remark("商品介绍集合")
  introductions?: Array<ProductIntroductionRequest>;
  // @Remark("配送方式")
  delivery_mode?: string;

  delivery_mode_name?: string;
  // @Remark("运费")
  freight?: string;
  // @Remark("第三方商品链接")
  third_links?: string;
  // 商品状态
  status?: string;
  is_index_recommended?: number;
  // @Remark("商品类型")
  product_type?: string;
  product_book_info_response?:ProductBookInfoResponse;

}

export class ProductBookInfoResponse {
  project_id : string;

  project_name:string;

  scheduled_date_range:number;

  start_time:string;

  end_time :string;

  has_coupon : number;

  pick_up_code_rules : number;

  pick_up_location : string;

  pick_up_location_image : string;

  pick_up_method : string;

  refund_instructions:string;
}

export class ProductLabelRequest {
  // @Remark("商品标签编号")
  label_id?: number;
  // @Remark("商品标签排序编号")
  index_number?: number;
}

export class ProductSpecValueRequest {
  // @Remark("商品规格编号")
  spec_id?: number;
  // @Remark("商品规格值编号")
  spec_value_id?: number;
  // @Remark("商品规格层级序号")
  index_number?: number;
}

export class ProductSpecDetailRequest {
  // @Remark("规格IDS")
  spec_ids?: Array<ProductSpecValueRequest>;
  // @Remark("规格图片URL")
  spec_image_url?: string;
  // @Remark("建议零售价")
  market_price?: string;
  // @Remark("优惠销售价")
  current_price?: string;
  cost_price?:string;
  // @Remark("库存")
  stock?: number;
  // @Remark("库存")
  activity_stock?: number;
  // 帮砍所需刀数
  cut_predict_num?: number;
}

export class ProductManagerDetailRequest {
  // @Remark("商品编号")
  id?: string;
  // @Remark("商品名称")
  product_name?: string;
  // @Remark("商品品牌")
  brand?: string;
  // @Remark("推荐标语")
  recommend_slogans?: string;
  // @Remark("商品类目ID")
  category_id?: number;
  // @Remark("商品标签IDS")
  label_ids?: Array<ProductLabelRequest>;
  // @Remark("商品主图链接")
  main_image_url?: Array<string>;
  // @Remark("商品列表图链接")
  list_image_url?: string;
  // @Remark("配送方式")
  delivery_mode?: string;
  // @Remark("运费")
  freight?: string;
  // @Remark("第三方链接")
  tripartite_links?: string;
  // @Remark("商品规格信息")
  product_spec_details?: Array<ProductSpecDetailRequest>;
  // @Remark("商品介绍")
  product_descs?: Array<ProductIntroductionRequest>;
  is_index_recommended?: number;
  // @Remark("商品类型")
  product_type?: string;
  product_booking_info_request? :ProductBookingInfoRequest ;
}

export class ProductOrderListRequest extends EbusinessPageRequest {
  product_id?: string;
  order_status?: string;
}

export class ProductBookingInfoRequest {

  project_id : string;

  scheduled_date_range:number;

  start_time:string;

  end_time :string;

  has_coupon : number;

  pick_up_code_rules : number;

  pick_up_location : string;

  pick_up_location_image : string;

  pick_up_method : number;

  refund_instructions:string;

}

export class ProductSortRequest {
  idempotent_key:any;
  //@Remark("源商品编号")
  sourceProduct_id:string;
  //@Remark("目标商品编号")
  targetProduct_id:string;
}

export class ProductOrderListResponse extends EbusinessPageResponse {
  product_order_responses?: Array<ProductOrderResponse>;
  order_status_count_responses?: Array<OrderStatusCountResponse>;
}

export class ProductOrderResponse {
  // @Remark("母订单号")
  order_master_id?: string;
  // @Remark("创建时间")
  create_time?: number;
  // @Remark("订单类型")
  order_type?: string;
  // @Remark("商品数量")
  order_num?: number;
  // @Remark("订单金额")
  order_amount?: string;
  // @Remark("订单实付金额")
  order_act_amount?: string;
  // @Remark("支付方式")
  pay_type?: string;
  // @Remark("订单状态")
  order_status?: string;
  // @Remark("售后状态")
  exchange_status?: string;
  // @Remark("下单用户")
  user_mobile?: string;
  // @Remark("用户标签")
  user_label?: string;
  // @Remark("所属社区")
  user_community?: string;
}

export class OrderStatusCountResponse {
  // @Remark("订单状态")
  order_status?: string;
  order_status_str?: string;
  // @Remark("订单笔数")
  count?: number;
}

export class ProductStatusRequest {
  ids?: Array<String>;
  status?: string;
}

export class WelfareStatusRequest {
  welfareIds?: Array<Number>;
  welfareStatus?: string;
}

export class ProductToTopRequest {
  product_id?: string;
}

// 订单详情
export class OrderDetailResponse {

  order_master_id?: string;

  create_time?: Date;

  order_type?: string;

  pay_type?: string;

  third_out_trade_no?: string;

  user_mobile?: string;

  user_label?: string;

  delivery_name?: string;

  deliver_mobile?: string;

  delivery_address?: string;

  remark?: string;

  order_status?: string;

  order_amount?: string;

  order_act_amount?: string;

  coupon_price?:string;

  cut_id?:string;

  cut_price?:string;

  coupon_number?:string;

  order_exchange_status?: string;

  order_manager_seed_models?: Array<OrderManageSeedModel>;

  order_logistics_response?: OrderLogisticsResponse;

  order_exchange_log_responses?: Array<OrderExchangeLogResponse>;

}

export class OrderManageSeedModel {

  order_seed_id?: string;

  order_master_id?: string;

  product_id?: string;

  product_name?: string;

  brand_name?: string;

  product_num?: number;

  product_price?: number;

  logistics_amount?: number;

  discounts?: number;

  remark?: string;

  third_links?: string;

  product_type?: string;

  delivery_mode?: string;

  order_sku_spec_models?: Array<OrderSkuSpecModels>;

}

export class OrderSkuSpecModels {

  spec_name?: string;

  spec_value?: string;

}

export class OrderLogisticsResponse {

  logistics_id?: number;

  order_seed_id?: string;

  order_master_id?: string;

  express_id?: number;

  express_name?: string;

  express_order_id?: string;
}

export class OrderExchangeLogResponse {

  exchange_status?: string;

  op_id?: number;

  op_name?: string;

  content?: string;

  create_time?: Date;
}

export class OrderManagerListResponse extends EbusinessPageResponse {
  order_manager_response_list?: Array<OrderManagerResponse>;
}

export class CourierCompanyInfoListResponse {
  courier_company_info_models?: Array<CourierCompanyInfoModel>;
}

export class CourierCompanyInfoModel {

  id?: number;

  courier_name?: string;

  sort?: number;

  status?: string;

}


export class OrderManagerResponse {

  order_master_id?: string;

  create_time?: Date;

  product_name?: string;

  product_brand?: string;

  order_type?: string;

  order_sku_spec_models?: Array<OrderSkuSpecModels>;

  order_sku_spec_str?: string;

  pick_up_time?: string;

  order_status?: string;

  order_amount?: string;

  order_act_amount?: string;

  pay_type?: string;

  purchase_amount?: string;

  exchange_status?: string;
}

export class CatPurchaseInfoResponse {

  third_party_id?: number;

  third_party_url?: string;

  third_party_name?: string;

  third_party_order_id?: string;

  purchase_amount?: number;

}


// category

export class CommonCategoryManagerResponse {
  // @Remark("类目集合")
  category_models?: Array<CommonCategoryModel>;
}

// label

export class CommonLabelManagerResponse {
  label_models?: Array<CommonLabelModel>;
}

// spec
export class CommonSpecValueModel {
  id?: number;
  spec_id?: number;
  sort?: number;
  spec_value?: string;
  status?: string;
}

export class CommonSpecModel {
  id?: number;
  sort?: number;
  spec_name?: string;
  status?: string;
  common_spec_value_model_list?: Array<CommonSpecValueModel>;

  addSpec?: string;
  all_common_spec_value_model_list?: Array<CommonSpecValueModel>;
  list_common_spec_value_model_list?: Array<CommonSpecValueModel>;
}

export class CommonSpecManagerResponse {
  // @Remark("属性(规格)集合")
  spec_models?: Array<CommonSpecModel>;
}

export class CommonSpecAddRequest {
  // @Remark("规格名称集合")
  spec_names?: Array<string>;
}

export class CommonSpecValueAddRequest {
  // @Remark("规格值集合")
  spec_values?: Array<String>;
  // @Remark("规格id")
  spec_id?: number;
}


export class SpecModel {

  spec1Id?: number;
  spec1Name?: string;
  rowSpan1?: number;

  spec2Id?: number;
  spec2Name?: string;
  rowSpan2?: number;

  spec3Id?: number;
  spec3Name?: string;
  rowSpan3?: number;
}


//

export class EbSiteReserveListRequest extends PageReq {
  site_title?: string;
}

export class EbSiteReserveResponse {

  site_id?: number;

  /**
   * 场地编号
   */
  site_code?: string;

  /**
   * 场地名称
   */
  site_title?: string;


  /**
   * 场地图片(多个用","(逗号)分隔)
   */
  image_url?: string;

  /**
   * 场地图片(多个用","(逗号)分隔)
   */
  image_urls?: Array<string>;

  /**
   * 价格区间
   */
  price_range?: string;

  /**
   * 场地预约率
   */
  booking_rate?: string;

  about_full?: boolean;

  /**
   * 上架状态（0-待上架，1-已上架，2-已下架）
   */
  site_shelf_status?: string;
}

export class EbSiteReserveListResponse extends PageResp {

  eb_site_reserve_response_list?: Array<EbSiteReserveResponse>;

}

export class EbSiteReserveExtInfoDTO {
  site_detail_id?: number;

  /**
   * 场地其他信息
   */
  site_pub_topic?: string;

  /**
   * 发布的信息(关联sys_dict_data表的dict_code)
   */
  site_pub_dict_code?: string;

  site_pub_dict_code_tmp?: string;

  site_pub_dict_code_str?: number;
}


export class EbSiteReserveSaveRequest {
  site_title?: string;
  site_location_desc?: string;
  site_administrator_phone?: string;
  image_urls?: Array<string>;
  site_desc?: string;
  eb_site_reserve_detail_request_list?: Array<EbSiteReserveExtInfoDTO>;
}


export class EbSiteReserveDescResponse {

  site_id?: number;

  /**
   * 场地名称
   */
  site_title?: string;

  /**
   * 场地的位置描述信息
   */
  site_location_desc?: string;

  /**
   * 场地管理员电话
   */
  site_administrator_phone?: string;


  site_desc?: string;


  /**
   * 场地图片
   */
  image_urls?: Array<string>;

  /**
   * 场地介绍及须知
   */
  eb_site_reserve_detail_responses?: Array<EbSiteReserveExtInfoDTO>;

  // 场地设置状态 0 新建(无排期,无订单规则) 1 已排期(无订单规则) 2 已完成设置(已排期,已设置订单规则)
  site_set_status?: string;

  // 场地上下架状态: 0 待上架 1 已上架 2 已下架
  site_shelf_status?: string;
}

export class DictResponse {
  dict_code?: string;
  dict_type?: string;
  dict_value?: string;

}

export class DictListResponse {
  dict_response_list?: Array<DictResponse>;
}

export class EbSiteReserveUpdateRequest extends EbSiteReserveSaveRequest {
  site_id?: number;
}


export class VenueTimeRange {
  startTime?: Date;
  endTime?: Date;
}

export class VenueTimeRangeWeek extends VenueTimeRange {
  week?: number;
  price?: string;
  sessionStatus?: boolean;

  err: boolean;
}


// venue order

export class SiteOrderManagerListRequest extends PageReq {

  keyword?: string;

  orderCreateTimeStart?: number;

  orderCreateTimeEnd?: number;

  appointmentTimeStart?: number;

  appointmentTimeEnd?: number;

  orderStatus?: string;

}


export class AppointmentTimeResponse {
  // @Remark("生效日期")
  effect_date?: number;
  // @Remark("场次开始时间")
  session_start_time?: string;
  // @Remark("场次结束时间")
  session_finish_time?: string;
  // @Remark("当天属于周几")
  week_day?: number;
  // @Remark("当天属于周几")
  week_day_str?: string;
  // @Remark("场次价格")
  current_price?: string;
}

export class SiteOrderListResponse {
  // @Remark("订单号")
  order_master_id?: string;
  // @Remark("下单时间")
  create_time?: number;
  // @Remark("场地名称")
  site_title?: string;
  // @Remark("预约人")
  user_name?: string;
  // @Remark("预约人手机号")
  user_mobile?: string;
  // @Remark("预约场次信息")
  appointment_time_responses?: Array<AppointmentTimeResponse>;
  // @Remark("实付金额")
  order_act_amount?: string;
  // @Remark("订单状态")
  order_status?: string;
}

export class SiteOrderPageResponse extends PageResp {

  site_order_list_responses?: Array<SiteOrderListResponse>;
}


export class EbSiteReserveShelfRequest {
  site_id?: number;
  site_shelf_status?: number;
}

export class SiteSessionModel {
  /**
   * 场次id
   */
  site_session_id?: number;

  /**
   * 场地id
   */
  site_id?: number;

  /**
   * 场次开始时间点（规则: 24H制，时分中间用:号隔开；如: 08:00, 09:30，可精确到具体分钟）
   */
  session_start_time?: string;

  /**
   * 场次结束时间点（规则: 24H制，时分中间用:号隔开；如: 20:00, 21:30，可精确到具体分钟）
   */
  session_finish_time?: string;

  /**
   * 该场次对应价格
   */
  current_price?: string;

  /**
   * 该场次状态 1 可预订  2 不可预订
   */
  session_status?: number;

  /**
   * 星期  0 周日  1 周一 2 周二  3 周三 4 周四 5 周五  6 周六
   */
  week_day?: number;

  /**
   * 删除状态（0：未删，1：已删，默认：未删除）
   */
  deleted?: boolean;
}

export class AddSiteSessionRequest {
  /**
   * 场地编号(ID)
   */
  site_id?: number;

  /**
   * 场地开始营业时间
   */
  site_open_time?: string;

  /**
   * 场地开始营业时间
   */
  site_close_time?: string;

  /**
   * 场地默认预约价格(通用价格)
   */
  site_price?: string;

  /**
   * 场地场次集合
   */
  site_session_models?: Array<SiteSessionModel>;
}

export class SiteSessionTimeModel {
  // @Remark("场次开始时间")
  session_start_time?: string;
  // @Remark("场次结束时间")
  session_finish_time?: string;
}

export class SiteWeekSessionModel {
  // @Remark("日期")
  effect_date?: number;
  // @Remark("星期中文")
  week_day_str?: string;
  // @Remark("排期列表")
  site_session_models?: Array<SiteSessionModel>;
}

export class SiteSessionResponse {
  /**
   * 场地编号(ID)
   */
  site_id?: number;

  /**
   * 场地开始营业时间
   */
  site_open_time?: string;

  /**
   * 场地开始营业时间
   */
  site_close_time?: string;

  /**
   * 场地默认预约价格(通用价格)
   */
  site_price?: string;

  /**
   * 场地排期时间分割对象
   */
  site_session_time_models?: Array<SiteSessionTimeModel> ;

  /**
   * 场地场次集合
   */
  site_week_session_models?: Array<SiteWeekSessionModel> ;
}

export class SiteInvalidDateListResponse {
  // @Remark("是否有临时排期 true 有  false 没有")
  has_invalid_session?: boolean;
  business_dates?: Array<number>;
}
