<ng-container *ngIf="type===1">
  <app-nz-upload
    [nzAction]="url"
    [nzAccept]="acceptType"
    [nzBeforeUpload]="beforeUpload"
    [nzHeaders]="headers"
    [nzSize]="maxSize"
    [nzCustomRequest]="custom"
    [nzRemove]="remove"
    [nzShowUploadList]="showUploadList">
    <button nz-button class="btn btn-updata ng-star-inserted" [attr.disabled]="isDiabled ? true : null">
      <i nz-icon nzType="upload"></i><span style="">{{name}}</span>
    </button>
  </app-nz-upload>
</ng-container>

<ng-container *ngIf="type===2">
  <app-nz-upload
    [nzAction]="url"
    [nzAccept]="acceptType"
    [nzBeforeUpload]="beforeUpload"
    [nzHeaders]="headers"
    [nzSize]="maxSize"
    [nzCustomRequest]="custom"
    [nzRemove]="remove"
    nzListType="picture-card"
    [(nzFileList)]="fileList"
    [nzShowButton]="showUploadBtn && fileList.length < maxLength"
    [nzPreview]="handlePreview"
    [showIndexMark]="showIndexMark"
    [nzShowUploadList]="{showRemoveIcon: showDelBtn, showPreviewIcon: showViewBtn}"
    [showSortBtn]="showSortBtn"

    [nzMultiple]="true"
    (nzChange)="listChange($event)"
  >
    <div class="">
      <i nz-icon nzType="plus"></i>
      <div class="ant-upload-text">{{name}}</div>
    </div>
  </app-nz-upload>

  <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
            (nzOnCancel)="previewVisible=false">
    <ng-template #modalContent>
      <img [src]="previewImage" [ngStyle]="{ 'width': '100%' }"/>
    </ng-template>
  </nz-modal>
</ng-container>

<ng-container *ngIf="type===3">
  <app-nz-upload
    [nzAction]="url"
    nzListType="picture-card"
    [(nzFileList)]="fileList"
    [nzShowButton]="fileList.length < maxLength"
    [nzPreview]="handlePreview">
   <div class="">
     <i nz-icon nzType="plus"></i>
     <div class="ant-upload-text">{{name}}</div>
   </div>
  </app-nz-upload>
  <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
            (nzOnCancel)="previewVisible=false">
    <ng-template #modalContent>
      <img [src]="previewImage" [ngStyle]="{ 'width': '100%' }"/>
    </ng-template>
  </nz-modal>
</ng-container>


<ng-container *ngIf="type===4">
  <app-nz-upload
    [nzAction]="url"
    [nzAccept]="acceptType"
    [nzBeforeUpload]="beforeUpload"
    [nzHeaders]="headers"
    [nzSize]="maxSize"
    [nzCustomRequest]="custom"
    [nzRemove]="remove"
    nzListType="picture-card"
    [(nzFileList)]="fileList"
    [nzShowButton]="showUploadBtn && fileList.length < maxLength"
    [nzPreview]="handlePreview"
    [nzShowUploadList]="{showRemoveIcon: showDelBtn, showPreviewIcon: showViewBtn}"
    [showSortBtn]="showSortBtn"
    [nzMultiple]="true"
  >

    <div class="">
      <i nz-icon nzType="plus"></i>
      <div class="ant-upload-text">{{name}}</div>
    </div>
  </app-nz-upload>

  <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
            (nzOnCancel)="previewVisible=false">
    <ng-template #modalContent>
      <img [src]="previewImage" [ngStyle]="{ 'width': '100%' }"/>
    </ng-template>
  </nz-modal>
</ng-container>



<!-- 图片查看 -->
<!-- <ngx-image-gallery #imageGallery [images]="galleryImages" [conf]="galleryConf"></ngx-image-gallery> -->
