import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RequestService } from '../request.service';
import {BookingListRequest} from "../../model/booking/booking-list.model";
import {AuthService} from "../common/auth.service";

@Injectable()
export class BookingService {
  private LEASE_BOOKING_LIST = `${environment.apiHost}lease/booking/list`;

  //预定单合同详情
  private LEASE_DETAIL = `${environment.apiHost}manage/lease/detail`;

  //预定单，合同取消
  private BOOKING_CANCLE = `${environment.apiHost}manage/lease/cancel`;

  //附件上传/删除
  private ATTACHED_MANAGE = `${environment.apiHost}manage/lease/attached/manage`;

  //通过合同ID获取合同下的房间
  private LEASE_ROOM_LIST = `${environment.apiHost}manage/lease/room/list`;

  private BILL_TEST = `${environment.apiHost}manage/lease/lease/bill`;
  private LIST_DOWNLOAD = `${environment.apiHost}lease/booking/list/download`;

  constructor(private requestService: RequestService,
              private authService: AuthService) {
  }

  public leaseBookingList(requestData: any): Promise<any> {
    return this.requestService.authPost(this.LEASE_BOOKING_LIST, requestData);
  }
  public leaseBookingListWithLoading(requestData: any): Promise<any> {
    return this.requestService.authPost(this.LEASE_BOOKING_LIST, requestData, true);
  }

  public leaseDetail(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.LEASE_DETAIL, requestData);
  }

  public bookingCancle(requestData?: any): Promise<any> {
    return this.requestService.authPost(this.BOOKING_CANCLE, requestData);
  }

  public attachedManage(requestData?: any): Promise<any> {
    return this.requestService.authPost(this.ATTACHED_MANAGE, requestData);
  }

  public getLeaseRoomList(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.LEASE_ROOM_LIST, requestData);
  }

  public billTest(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.BILL_TEST, requestData);
  }

  leaseBookingListDownload(request: BookingListRequest) {
    let param = "";
    for (let key in request) {
      if (request[key] && request.hasOwnProperty(key)) {
        param += (key + '=' + request[key] + '&');
      }
    }

    if (param) {
      window.open(this.LIST_DOWNLOAD + "?" + param + 'Authorization=' + this.authService.getAuthToken(), "_blank");
    } else {
      window.open(this.LIST_DOWNLOAD + '?Authorization=' + this.authService.getAuthToken(), "_blank");
    }
  }
}
