<!-- {{setLesseeModal(renterModal,handInputModal,confirmModal,createRenterModal,conventionModal,successModal,successModal2,cameraModal)}} -->
<!-- 办理入住 -->
<div style="background: rgba(0,0,0,0.4);" class="modal fade" bsModal #renterModal="bs-modal" [config]="{backdrop: 'static'}"
  tabindex="-1" role="dialog" aria-labelledby="dialog-static-name" (click)="cclean($event)">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>办理入住-填写基本信息</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideRenterModal()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">

        <div class="part1">
          <div class="row mlmr">
            <div class="col-12 col-md-12 bg-green mb-4">
                <div class="pt-2" style="line-height: normal">入住房间：</div>
                <div class="roomname text-center">{{new_room_name}}</div>
            </div>

            <div class="col-12 col-md-12 mb-2"*ngIf="roomDoorLockList && roomDoorLockList.length > 1">
              <p>
                 选择门锁：
                <ng-container *ngFor="let item of roomDoorLockList;let i=index">
                  <ng-container *ngIf="i === 0">
                    <input style="vertical-align: middle;" type="radio" name="ensure_choosed_lock_radio" (click)="checkLock($event)" value="{{item?.room_door_lock_id}}" class="mr-1">
                    <span>{{item?.lock_name}}（已发{{item?.send_password_renter_count}}人）</span>
                  </ng-container>
                  <ng-container *ngIf="i === 1">
                  <input style="margin-left: 15%;vertical-align: middle;" type="radio" name="ensure_choosed_lock_radio" (click)="checkLock($event)" value="{{item?.room_door_lock_id}}" class="mr-1">
                  <span>{{item?.lock_name}}（已发{{item?.send_password_renter_count}}人）</span>
                  </ng-container>
                </ng-container>
              </p>
            </div>
            <div class="col-12 col-md-12 border-bottom-grey mb-4">
              入住信息
              <span class="readcard float-right" (click)="scanIdCardInfo()">从身份证读卡器读取</span><br>
              <span class="readcard float-right" (click)="getCardInfoByCamera()">拍照读取身份证信息</span>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <p class="w90px hei32 text-r"><i class="red">* </i>姓名：</p>
              <div class="condiv">
                <input type="text" class="w-100" [(ngModel)]="matchLesseeReqParam.name">
              </div>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <p class="w90px hei32 text-r"><i class="red">* </i>性别：</p>
              <div class="condiv" style="padding-top: 5px;padding-left: 15px;">
                <input class="form-check-input" type="radio" [checked]="matchLesseeReqParam.sex === '男'" (click)="matchLesseeReqParam.sex = '男'" name="csex">
                男&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" [checked]="matchLesseeReqParam.sex === '女'" (click)="matchLesseeReqParam.sex = '女'" name="csex">
                女
              </div>
            </div>
            <div class="col-12 col-md-6 mb-2 mt10">
              <p class="w90px hei32 text-r"><i class="red">* </i>证件类型：</p>
              <div class="condiv">
                <select class="w-100" [(ngModel)]="selectCretType" (change)="changeCretType()">
                  <option *ngFor="let cretType of cretTypeList" [value]="cretType.code">{{cretType.desc}}</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-2 mt10">
              <p class="w90px hei32 text-r"><i class="red">* </i>证件号：</p>
              <div class="condiv">
                <input type="text" class="w-100" (keyup)="changeCretnum()" [(ngModel)]="matchLesseeReqParam.cret_num">
              </div>
              <span *ngIf="error_cret_num !== 'SUCCESS'" class="text-danger ml90">
                {{error_cret_num}}
              </span>
            </div>
            <div class="col-12 col-md-6 mb-2 mt10">
              <p class="w90px hei32 text-r"><i class="red">* </i>手机号码：</p>
              <div class="condiv">
                <input type="text" class="w-100" [maxLength]="11" [(ngModel)]="createRenterReqParam.phone">
              </div>
            </div>
            <div class="col-12 col-md-6 mb-2 mt10">
              <p class="w90px hei32 text-r">附属信息：</p>
              <div class="condiv">
                <input type="text" class="w-100" [maxLength]="12" placeholder="人员部门/专业等信息，限12字" [(ngModel)]="createRenterReqParam.subsidiary">
              </div>
            </div>

            <div class="col-12 col-md-6 mb-2 mt10">
              <p class="w90px hei32 text-r">人员类型：</p>
              <div class="condiv">
                <select class="w-100" [(ngModel)]="createRenterReqParam.person_type">
                  <ng-container *ngFor="let item of renter_person_type_list">
                    <option [value]="item.item_code">{{item.item_name}}</option>
                  </ng-container>
                </select>
              </div>
            </div>

            <div class="col-12 col-md-6 mb-2 mt10" >
              <p class="w90px hei32 text-r">员工编号：</p>
              <div class="condiv">
                <input type="text" class="w-100" placeholder="" [(ngModel)]="createRenterReqParam.emp_no">
              </div>
            </div>
            <ng-container *ngFor="let ext of renterAddExt">
              <div class="col-12 col-md-6 mb-2 mt10">
                <p class="w90px hei32 text-r"><i class="red" *ngIf="ext.is_required">* </i>{{ext.name}}：</p>
                <div class="condiv">
                  <ng-container *ngIf="ext.type=='INPUT'">
                    <input type="text" class="w-100" placeholder="{{ext.value}}" [(ngModel)]="ext.user_input_value">
                  </ng-container>
                  <ng-container *ngIf="ext.type=='SELECT'">
                    <select class="w-100" [(ngModel)]="ext.user_input_value">
                      <option value="">请选择</option>
                      <option *ngFor="let v of ext.select_value">{{v}}</option>
                    </select>
                  </ng-container>
                </div>
              </div>
            </ng-container>

            <!-- 免租期设置 -->
            <ng-container *ngIf="this.went_dutch">
            <div style="width: 800px; padding-top: 40px; padding-left: 70px;">
              <input type="checkbox" (click)="setFreeTime($event)" class="form-check-input" id="rentFree" [(ngModel)]="rentFree">
              <label class="form-check-label" for="rentFree">有免租优惠，设置免租期(住客免租期内，房费由签约人支付）</label>
              <br/>
              <div class="form-group">
              <div class="w200px" style="padding-right: 15px;">
                <my-date-picker placeholder="年/月/日" [(ngModel)]="free_start_time"
                                [options]="myDatePickerOptions"
                                [disabled]="free_time_disabled">
                </my-date-picker>
              </div>
              <select class="w100px" [(ngModel)]="select_free" [attr.disabled]="select_free_disabled" (change)="selectFreeSet()">
                <option value="0">快速选择</option>
                <option value="5">同合同周期</option>
                <option value="1">1个月</option>
                <option value="2">2个月</option>
                <option value="3">3个月</option>
                <option value="4">24个月</option>
              </select>
              <div class="w200px" style="padding-left: 15px;">
                <my-date-picker placeholder="年/月/日" [(ngModel)]="free_end_time"
                                [options]="myDatePickerOptions"
                                [disabled]="free_time_disabled">
                </my-date-picker>
              </div>
              </div>
            </div>
            </ng-container>

          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-info" (click)="isIdentificationPhoto()">确&nbsp;&nbsp;定</button>&nbsp;&nbsp;
        <button type="button" class="btn btn-secondary" (click)="hideRenterModal()">取&nbsp;&nbsp;消</button>
      </div>
    </div>
  </div>
</div>

<!-- 办理入住人 -->
<div class="modal fade" bsModal #handInputModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
  aria-labelledby="dialog-static-name" (click)="cclean($event)">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>办理入住-上传证件照片</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="handInputModal.hide();clearTimer();">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body ml-5">
        <!-- part2 -->
        <div class="part2">
          <div class="row">
            <div class="col-12 col-md-12 mb-3 mt10">
              请上传入住人的证件照片
            </div>
            <div class="col-12 col-md-6 mb-3 pl-5 mt10">
              姓名：{{createRenterReqParam.name}}
            </div>
            <div class="col-12 col-md-6 mb-3 mt10">
              证件类型：{{selectCretDesc}}
            </div>
            <div class="col-12 col-md-12 mb-3 pl-5 mt10">
              证件号码：{{createRenterReqParam.cret_num}}
            </div>
            <div class="col-12 col-md-12 mb-1 mt10 mr-5 bb-grey">

            </div>
            <div class="col-12 col-md-12 mb-3 mt20">
              <p class="mb-3" *ngIf="selectCretType === 'ID_CD'">证件照片： <b class="text-danger">请扫码上传/重传证件照片</b>
                &nbsp;&nbsp;&nbsp;&nbsp;<a class="text-blue" (click)="captureBackID()">拍照获取背面身份证图片</a></p>
              <p class="mb-3" *ngIf="selectCretType === 'PASSPORT'">证件照片： <b class="text-danger">请上传证件照片</b></p>
              <p class="text-danger w90px">（照片大小限15M以内）</p>

<!--              <ngx-qrcode *ngIf="qrCodeIsShow" [qrc-value]="qrCodePath" [qrc-class]="'qrcode'" qrc-errorCorrectionLevel="L">-->
<!--              </ngx-qrcode>-->

              <qr-code *ngIf="qrCodeIsShow"
                       [value]="qrCodePath"
                       class="qrcode"
                       size="104"
              >
              </qr-code>

              <div style="float: left">
                <app-new-file-upload *ngIf="selectCretType === 'ID_CD'" #appFileUpload [name]="'身份证正面照'" [imgList]="[idCardFront]" [type]="2" [accept]="'IMG'" [maxLength]="1"
                                     (fileChange)="fileChange($event,'idCardFront')">
                </app-new-file-upload>
              </div>
              <div style="float: left">
                <app-new-file-upload *ngIf="selectCretType === 'ID_CD'" #appFileUpload [name]="'身份证反面照'" [imgList]="[idCardReverse]" [type]="2" [accept]="'IMG'" [maxLength]="1"
                                     (fileChange)="fileChange($event,'idCardReverse')">
                </app-new-file-upload>
              </div>
              <div style="float: left">
                <app-new-file-upload *ngIf="selectCretType !== 'ID_CD'" #appFileUpload [name]="'护照信息页照片'" [imgList]="[passport]" [type]="2" [accept]="'IMG'" [maxLength]="1"
                                     (fileChange)="fileChange($event,'passport')">
                </app-new-file-upload>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
          <button class="btn btn-info" (click)="submitIdentificationPhoto()">确&nbsp;&nbsp;定
          </button>&nbsp;&nbsp;&nbsp;&nbsp;
          <button class="btn btn-secondary" (click)="handInputModal.hide();clearTimer();renterModal.show()">返&nbsp;&nbsp;回
          </button>
      </div>
    </div>

  </div>
</div>

<!-- 确认入住人 -->
<div class="modal fade" bsModal #confirmModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
  aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>确认入住人</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="confirmModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="text-center text-orange mb10">
          <small class="black">{{lessee.name}}，身份证号：{{lessee.cret_num}}&nbsp;&nbsp;&nbsp;入住 </small>
          {{lessee.room_name}}
        </h2>
        <div *ngIf="is_scan" class="row mb10">
          <div class="col-12 mb-3 text-left" style="padding-left: 18%;">证件照片： <b class="text-danger">请扫码上传/重传证件照片</b></div>
          <div class="col-12 mb20" style="padding-left: 18%;">
            <div class="text-danger w90px float-left">（照片大小限15M以内）</div>
            <div style="float: left">
<!--              <ngx-qrcode *ngIf="qrCodeIsShow" [qrc-value]="qrCodePath" [qrc-class]="'qrcode'" qrc-errorCorrectionLevel="L">-->
<!--              </ngx-qrcode>-->

              <qr-code *ngIf="qrCodeIsShow"
                       [value]="qrCodePath"
                       class="qrcode"
                       size="104"
              >
              </qr-code>
            </div>
            <ng-container>
              <div style="float: left">
                <app-new-file-upload #appFileUpload11 [name]="'身份证正面照'" [imgList]="[idCardFront]" [type]="2" [accept]="'IMG'" [maxLength]="1"
                                     (fileChange)="fileChange($event,'idCardFront')">
                </app-new-file-upload>
              </div>
              <div style="float: left">
                <app-new-file-upload #appFileUpload12 [name]="'身份证正面照'" [imgList]="[idCardReverse]" [type]="2" [accept]="'IMG'" [maxLength]="1"
                                     (fileChange)="fileChange($event,'idCardReverse')">
                </app-new-file-upload>
              </div>
            </ng-container>
          </div>
        </div>
        <div *ngIf="!hasSent && roomDoorLockList && roomDoorLockList.length > 1" class="row mb20">
          <div style="margin-left: 27%">
            <span class="d-inline-block">选择门锁: </span>
            <ng-container *ngFor="let item of roomDoorLockList">
              <div class="d-inline-block ml-3">
                <input type="radio" name="ensure_choosed_lock_radio" value="{{item?.room_door_lock_id}}" class="mr-1">{{item?.lock_name}}(已发{{item?.send_password_renter_count}}人)
              </div>
            </ng-container>
          </div>
        </div>
        <p *ngIf="lessee.asset_confirm === 'CONFIRMED'" class="black text-center mb10">确认无误后请点击下方按钮，确认入住</p>
        <p *ngIf="lessee.asset_confirm === 'UNCONFIRMED'" class="text-center mb10">
          <span class="red">此房间尚未确认 房间物品交接单，</span>
          <span class="b-blue2" (click)="linkGoodsList(lessee.lease_id, lessee.room_id, lessee.lease_room_id)" style="cursor: pointer;">请先点击此处确认</span>
        </p>
        <div class="text-center mb20">
          <button *ngIf="lessee.asset_confirm === 'CONFIRMED'" class="btn btn-primary mr20" (click)="getHouseholdConvention();conventionModal.show();confirmModal.hide()">确认入住
          </button>
          <button class="btn btn-secondary" (click)="closeModal(confirmModal)">关闭</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 新增入住人 -->
<div class="modal fade" bsModal #createRenterModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
  aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>新增入住人<ng-container *ngIf="subjectType === 'COMPANY'">（此房间{{check_sex?'允许':'不允许'}}不同性别入住）</ng-container></h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal(createRenterModal)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="check-box ">
          <b class="d-block mb10">请选择入住房间：</b>
          <div class="condiv2">
            <select class="w-100" (change)="selectRoom()" [(ngModel)]="selectRoomInfo">
              <option value="" *ngFor="let room of roomList" [value]="room.room_id+','+room.asset_confirm+','+room.lease_room_id">
                {{room.room_name}}
              </option>
            </select>
            <p class="mb10">
              <ng-container *ngIf="selectRoomAssetConfirm === 'UNCONFIRMED'">
                <span class="red">此房间尚未确认 房间物品交接单，</span>
                <span class="b-blue2" (click)="linkGoodsList(lease_id, selectRoomId, selectLeaseRoomId)" style="cursor: pointer;">请先点击此处确认</span>
              </ng-container>
              <span *ngIf="selectRoomAssetConfirm === 'CONFIRMEDOUT'" class="text-danger">
                该房间已签署退房单，无法办理入住
              </span>
            </p>
          </div>
        </div>
        <div *ngIf="roomDoorLockList && roomDoorLockList.length > 1" class="mt-1 mb-1">
          <span class="d-inline-block">选择门锁: </span>
          <ng-container *ngFor="let item of roomDoorLockList">
            <div class="d-inline-block ml-3">
              <input type="radio" name="choosed_lock_radio" value="{{item?.room_door_lock_id}}" class="mr-1">{{item?.lock_name}}(已发{{item?.send_password_renter_count}}人)
            </div>
          </ng-container>
        </div>
        <div class="check-box mb10">
          <b class="d-block">入住人：</b>
          <div class="condiv-flex">
            <div class="divbox mb10 hei32 w90px">
              {{matchLesseeReqParam.name}}
            </div>
            <div class="divbox mb10">
              <select [(ngModel)]="createRenterReqParam.sex">
                <option value="MALE">男</option>
                <option value="FEMALE">女</option>
              </select>
            </div>
            <div class="divbox mb10">
              <input type="text" placeholder="手机" [(ngModel)]="createRenterReqParam.phone">
              <span class="b-blue2 pointer" (click)="fillLeaseTel()" >点击填入签约人手机号码</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb10">
            <b>身份信息</b>
          </div>
          <div class="col-12">
            <div class="mb10">证件类型：{{selectCretDesc}}</div>
            <div class="mb10">证件号：{{matchLesseeReqParam.cret_num}}</div>
          </div>
        </div>
        <ul *ngIf="!is_read_card" class="row img-box mb10">
          <app-new-file-upload [type]="2" [accept]="'IMG'" [imgList]="imgList" [showDelBtn]="false" [showUploadBtn]="false">
          </app-new-file-upload>
        </ul>
        <div *ngIf="is_read_card" class="row mb20">
          <div class="col-12 mb-3 text-left">证件照片： <b class="text-danger">请扫码上传/重传证件照片</b></div>
          <div class="col-12 mb20">
            <div class="text-danger w90px float-left">（照片大小限15M以内）</div>
            <div style="float: left">
<!--              <ngx-qrcode *ngIf="qrCodeIsShow" [qrc-value]="qrCodePath" [qrc-class]="'qrcode'" qrc-errorCorrectionLevel="L">-->
<!--              </ngx-qrcode>-->

              <qr-code *ngIf="qrCodeIsShow"
                       [value]="qrCodePath"
                       class="qrcode"
                       size="104"
              >
              </qr-code>
            </div>
            <ng-container>
            <div style="float: left">
              <app-new-file-upload #appFileUpload11 [name]="'身份证正面'" [imgList]="[idCardFront]" [type]="2" [accept]="'IMG'" [maxLength]="1"
                                   (fileChange)="fileChange($event,'idCardFront')">
              </app-new-file-upload>
            </div>
            <div style="float: left">
              <app-new-file-upload #appFileUpload12 [name]="'身份证反面'" [imgList]="[idCardReverse]" [type]="2" [accept]="'IMG'" [maxLength]="1"
                                   (fileChange)="fileChange($event,'idCardReverse')">
              </app-new-file-upload>
            </div>
            </ng-container>
          </div>
        </div>
        <div class="text-center mb20">
          <button class="btn btn-primary" *ngIf="selectRoomAssetConfirm === 'CONFIRMED'" (click)="createLeaseRenter()">确认入住
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 签署住户公约 -->
<div class="modal fade" bsModal #conventionModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
  aria-labelledby="dialog-static-name" (click)="cclean($event)">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>办理入住-签署公约</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="conventionModal.hide();">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body convention-body" style="color: #000;">
<!--        <img src='../../../../assets/img/logo2.png' style="width: 100px;height: 40px;" />-->

        <div id="printDiv" #printDiv *ngIf="template_content" [innerHTML]="template_content | html">

        </div>

      </div>
      <div class="modal-footer">
<!--        <e-ngx-print style="margin-right: 10px;" [btnText]="'打印'" [btnClass]="{'btn': true, 'btn-info': true}"-->
<!--          [printHTML]="printDiv" [printStyle]="'@page {size: auto;margin: 0mm;}'">-->
<!--        </e-ngx-print>-->
        <button printSectionId="printDiv" ngxPrint class="btn btn-info mr-2" >打印</button>

        <button class="btn btn-info" (click)="conventionModal.hide();successModal.show()">线下签字</button>
        <button class="btn btn-warning ml-5" (click)="tabletComponent.clearClick();tabletComponent.tabletShow()" *ngIf="isPad()">线上签字</button>
        <button class="btn btn-warning" (click)="signOnIpad()" *ngIf="!isPad()">同步pad签字</button>
      </div>
    </div>
  </div>
</div>
<!-- 操作确认 -->
<div class="modal fade" bsModal #successModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
  aria-labelledby="dialog-static-name" (click)="cclean($event)">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="successModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body success-body">
        <p>确认入住人已线下签署住户公约？</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-info" (click)="successModal.hide();checkIn();">确定</button>
        <button class="btn btn-secondary" (click)="successModal.hide();conventionModal.show()">返回</button>
      </div>
    </div>
  </div>
</div>

{{initDialog(prepaycashPayment, prepaygatherBill, prepayposPaymnet)}}


<div class="modal fade" bsModal #prepaygatherBill="bs-modal" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name" (click)="stopP($event)">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header money-div">
        <img class="money-img" src="../../../../assets/img/money/u7947.png">
        <h4 class="modal-title pull-left money-title">办理入住-收取押金</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeGatherDialog(prepaygatherBill)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body money-body">
        <div style="min-height: 100px;">
          <div class="mt-4">
            <div class="prepay-div1" style="text-align: center;">
              <b>正在为 【 {{renterName}} 】 办理入住，入住房间号： 【 {{roomName}} 】</b>
            </div>
          </div>

          <div class="money-total" style="text-align: center;">
            应收总计：
            <span class="money-amount">{{this.renterDeposit}}元</span>
          </div>

          <div class="money-pay">
            <span class="money-span2">确认金额后，选择收款：</span>
            <span class="money-span pointer" (click)="prepayPayment('WEIXIN',prepayaliOrWeixin,'aliOrWeixin')"><img
              class="money-img2" src="../../../../assets/img/money/wx.png">微信</span>
            <span class="money-span pointer" (click)="prepayPayment('ALIPAY',prepayaliOrWeixin,'aliOrWeixin')"><img
              class="money-img2" src="../../../../assets/img/money/ali.png">支付宝</span>
            <span class="money-span pointer" (click)="prepayPayment('CCBPAY',prepayaliOrWeixin,'aliOrWeixin')"><img
              class="money-img2" src="../../../../assets/img/money/jh.png">龙支付</span>
            <!--<span class="money-span pointer" (click)="prepayPayment('CASH',prepaycashPayment,null)"><img
              class="money-img2" src="../../../../assets/img/money/cash.png">现金</span>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- 支付宝/微信收款 -->
<div class="modal fade" bsModal #prepayaliOrWeixin="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name" (click)="stopP($event)">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left">操作收款</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeAliOrWei(prepayaliOrWeixin)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">
          <span class="payIcon black" *ngIf="paymentType === 'WEIXIN'">
             <img class="payImg" src="../../../../assets/img/money/wx.png">
              微信
          </span>
          <span class="payIcon black" *ngIf="paymentType === 'ALIPAY'">
             <img class="payImg" src="../../../../assets/img/money/ali.png">
              支付宝
          </span>
          <small class="text-orange">收款金额：<span class="money-color">{{renterDeposit}}</span>元</small>
        </h2>
        <h2 class="red text-center mb20">
          <small class="text-orange">等待支付中…&nbsp;({{clock}}s)</small>
        </h2>
        <p class="font12 text-center black mb20">
          支付成功后，{{renterDeposit}}元入住押金才会生效，否则不生效
        </p>
        <p class="font12 black text-center" *ngIf="showContent">
          倒计时结束仍未跳转且客户已支付成功？
          <button class="btn b-blues" style="background-color: white;border: 1px solid  #3366FF;"
                  (click)="prepayshowQueryByOrderId(prepayorderQuery,prepayaliOrWeixin)">点击进入
          </button>
        </p>
      </div>
    </div>
  </div>
</div>

<!-- 现金收款 -->
<div class="modal fade" bsModal #prepaycashPayment="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name" (click)="stopP($event)">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作收款</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="prepaycashPayment.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">
          <span class="payIcon black">
             <img class="payImg" src="../../../../assets/img/money/cash.png">
              现金
          </span>
          <small class="text-orange">收款金额：<span class="money-color">{{renterDeposit}}</span>元</small>
        </h2>
        <p class="font12 text-center black mb20">
          支付成功后，{{renterDeposit}}元入住押金才会生效，否则不生效</p>
        <p class="b-muted font12 text-center mb20">确认后立即入账且不可更改，是否确认已收？</p>
      </div>
      <div class="modal-footer">
        <div class="text-center mb20">
          <button type="button" class="btn btn-warning mr20" style="background-color: rgba(242, 90, 99, 1);"
                  (click)="confirm(paymentType,prepaycashPayment,prepaygatherSuccess)">
            确认已收
          </button>
          <button type="button" class="btn btn-secondary"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;"
                  (click)="prepaycashPayment.hide()">取消
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- POS/转账收款 -->
<div class="modal fade" bsModal #prepayposPaymnet="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name" (click)="stopP($event)">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left">操作收款</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="prepayposPaymnet.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">
          <span class="payIcon black" *ngIf="paymentType === 'POS'">
             <img class="payImg" src="../../../../assets/img/money/pos.png">
              POS
          </span>
          <span class="payIcon black" *ngIf="paymentType === 'TRANSFER'">
             <img class="payImg" src="../../../../assets/img/money/zz.png">
              转账
          </span>
          <span class="payIcon black" *ngIf="paymentType === 'CHECK'">
             <img class="payImg" src="../../../../assets/img/money/check.png">
              支票
          </span>
          <small class="text-orange">收款金额：<span class="money-color">{{renterDeposit}}</span>元</small>
        </h2>
        <p class="b-muted font12 text-left black" style="padding-left: 15px;margin-top: 40px;margin-bottom: -5px;"
           *ngIf="paymentType === 'POS'">请上传pos收银小票：（每张限15M以内，最多5张）</p>
        <p class="b-muted font12 text-left black" style="padding-left: 15px;margin-top: 40px;margin-bottom: -5px;"
           *ngIf="paymentType === 'TRANSFER'">请上传转账凭证：（每张限15M以内，最多5张）</p>
        <p class="b-muted font12 text-left black" style="padding-left: 15px;margin-top: 40px;margin-bottom: -5px;"
           *ngIf="paymentType === 'CHECK'">请上传支票照片：（每张限15M以内，最多5张）</p>
        <div class="text-center mb20">
          <div class="padd15">
            <ul class="img-box">
              <app-new-file-upload [type]="2" [maxSize]="15 * 1024 * 1024" [accept]="'IMG'" [maxLength]="5"
                                   [imgList]="prepayRechargeWithdrawReq?.cret_images"
                                   (fileChange)="paymnetFill($event)"></app-new-file-upload>
            </ul>
          </div>
        </div>
        <p class="font12 text-center black mb40">
          支付成功后，{{renterDeposit}}元入住押金才会生效，否则不生效
        </p>
      </div>
      <div class="modal-footer">
        <div class="text-center mb20">
          <button type="button" class="btn btn-warning mr20" style="background-color: rgba(242, 90, 99, 1);"
                  (click)="confirm(paymentType,prepayposPaymnet,prepaygatherSuccess)">
            确认已收
          </button>
          <button type="button" class="btn btn-secondary"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;"
                  (click)="prepayposPaymnet.hide()">取消
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- 3.3.1.2 输入商户订单号： -->
<div class="modal fade" bsModal #orderQuery="bs-modal" style="z-index: 999999;background: rgba(0,0,0,0.4);"
     [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name" (click)="stopP($event)">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>收款操作</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeOrderQueryDialog(orderQuery)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-left mt-4 mb20 black" style="padding-left: 10%;">请输入用户支付结果页面中显示的商户订单号：
        </p>
        <div class="row mb10">
          <div class="col-md-12" style="padding-left: 13%;">
            <input type="text" class="w-90" [(ngModel)]="orderId">
          </div>
        </div>
        <p class="text-orange font12 text-left mb20" *ngIf="orderRemind" style="padding-left: 10%;">
          未查到该订单号，请确认订单号是否正确</p>
      </div>
      <div class="modal-footer">
        <div class="text-center mb20">
          <button type="button" class="btn btn-primary mr20" style="background-color: rgba(242, 90, 99, 1);width: 70px;"
                  (click)="queryByOrderId(orderQuery,prepaygatherSuccess)">
            确认
          </button>
          <button type="button" class="btn btn-secondary"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;"
                  (click)="closeOrderQueryDialog(orderQuery)"> 取消
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 3.3.1.1 账单订单号绑定页面 -->
<div class="modal fade" bsModal #bindOrderBill="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name" (click)="stopP($event)">
  <div class="modal-dialog modal-warning modal-lg">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作收款</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="bindOrderBill.hide();checkOrderNo(null)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <p class="red mb10">已到账{{orderNum}}笔相同金额的收款，请选择并确认：</p>
        <div class="table-box">
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th>请选择</th>
              <th>商户账单号</th>
              <th>支付金额</th>
              <th>支付时间</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of orderDtos">
              <td><input type="radio" name="check_order_no" value="{{item.order_no}}"
                         (click)="checkOrderNo(item.order_no)"></td>
              <td>{{item.order_id}}</td>
              <td>{{item.order_amount}}</td>
              <td>{{item.pay_time |date:'yyyy-MM-dd HH:mm:ss'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <p class="text-orange font12">
          多笔相同金额请核对付款人和商户订单号信息，未找到客户提供的订单号？
          <button class="btn b-blues" style="background-color: white;border: 1px solid  #3366FF;"
                  (click)="showQueryByOrderId(orderQuery,bindOrderBill)">点击进入
          </button>
        </p>
      </div>
      <div class="modal-footer">
        <div class="text-center mb20">
          <button *ngIf="check_order_no" type="button" class="btn btn-primary mr20"
                  style="background-color: rgba(242, 90, 99, 1);width: 70px;"
                  (click)="reletedBillAndOrder(check_order_no,bindOrderBill,prepaygatherSuccess)">
            确认
          </button>
          <button *ngIf="!check_order_no" type="button" class="btn btn-primary mr20"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;">
            确认
          </button>
          <button type="button" class="btn btn-secondary"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;"
                  (click)="bindOrderBill.hide();checkOrderNo(null)"> 取消
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 收款成功 -->
<div class="modal fade" bsModal #prepaygatherSuccess="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name" (click)="stopP($event)">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="prepaygatherSuccess.hide();dealWith(0)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body success-body">
        <p>押金收款成功！收款金额：<span style="color: red;">{{renterDeposit}}元</span> </p>
        <p>{{renterName}}已成功入住 <span>{{roomName}}</span></p>
        <p>是否继续办理下一位？</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-info" (click)="prepaygatherSuccess.hide();dealWith(0);printShow()">打印入住单</button>
        <button class="btn btn-info" (click)="prepaygatherSuccess.hide();dealWith(1)">下一位</button>
        <button class="btn btn-info" (click)="prepaygatherSuccess.hide();dealWith(0)">结束办理</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #successModal2="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
  aria-labelledby="dialog-static-name" (click)="cclean($event)">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="successModal2.hide();dealWith(0)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body success-body">
        <p>{{renterName}}已成功入住 <span>{{roomName}}</span></p>
        <p>是否继续办理下一位？</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-info" (click)="successModal2.hide();dealWith(0);printShow()">打印入住单</button>
        <button class="btn btn-info" (click)="successModal2.hide();dealWith(1)">下一位</button>
        <button class="btn btn-info" (click)="successModal2.hide();dealWith(0)">结束办理</button>
      </div>
    </div>
  </div>
</div>

<div style="z-index: 999999;background: rgba(0,0,0,0.4);" class="modal fade" bsModal #checkInEqumentModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name" (click)="cclean($event)">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>确认房间设备读数</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="checkInEqumentModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <!-- part1 -->
        <!--<div class="part1">
          <h2 class="text-center mb20">
            <span class="text-orange font18">请读取证件信息，</span>
            <span class="text-primary font18" (click)="openModalStaticModal18()" style="cursor: pointer !important;">护照或手工录入请点这里</span>
          </h2>
        </div>-->
        <div class="part1">
          <div class="row" style="margin: 10px 15px;">
            <div class="col-12 col-md-12 bg-green mb-4">
<!--              设备所属房间号：<span class="roomname">{{new_room_name}}</span>-->
              <div class="pt-2" style="line-height: normal">设备所属房间号：</div>
              <div class="roomname text-center">{{new_room_name}}</div>
            </div>
            <p class="mt10 ml-4">房间首次入住,请确认该房间水电设备的初始读数：</p>
            <div *ngIf="elemeter_info_dto || cold_watermeter_info_dto || hot_watermeter_info_dto" class="col-12 col-md-12 mt10 pt10" style="padding-left: 20%">
              <ng-container *ngIf="ele_type === 'SMART' && elemeter_info_dto">
                <p *ngIf="elemeter_info_dto.indication !== -100" class="mb10">电&nbsp;&nbsp;&nbsp;&nbsp;表：<input class="w-50" [(ngModel)]="ele_number" type="text" value="{{elemeter_info_dto.indication}}"></p>
                <p *ngIf="elemeter_info_dto.indication === -100" class="mb10">电&nbsp;&nbsp;&nbsp;&nbsp;表：<input class="w-50" [(ngModel)]="ele_number" type="text" placeholder="设备异常,未获取到读数"></p>
              </ng-container>
              <ng-container *ngIf="ele_type === 'MANUAL'">
                <p class="mb10">电&nbsp;&nbsp;&nbsp;&nbsp;表：<input class="w-50" [(ngModel)]="ele_number" type="text"></p>
              </ng-container>
              <ng-container *ngIf="cold_water_type === 'SMART' && cold_watermeter_info_dto">
                <p *ngIf="cold_watermeter_info_dto.indication !== -100" class="mb10">水&nbsp;&nbsp;&nbsp;&nbsp;表：<input class="w-50" [(ngModel)]="cold_number" type="text" value="{{cold_watermeter_info_dto.indication}}"></p>
                <p *ngIf="cold_watermeter_info_dto.indication === -100" class="mb10">水&nbsp;&nbsp;&nbsp;&nbsp;表：<input class="w-50" [(ngModel)]="cold_number" type="text" placeholder="设备异常,未获取到读数"></p>
              </ng-container>
              <ng-container *ngIf="cold_water_type === 'MANUAL'">
                <p class="mb10">水&nbsp;&nbsp;&nbsp;&nbsp;表：<input type="text" [(ngModel)]="cold_number" class="w-50"></p>
              </ng-container>
              <ng-container *ngIf="hot_water_type === 'SMART' && hot_watermeter_info_dto">
                <p *ngIf="hot_watermeter_info_dto.indication !== -100" class="mb10">热水表：<input class="w-50" [(ngModel)]="hot_number" type="text" value="{{hot_watermeter_info_dto.indication}}"></p>
                <p *ngIf="hot_watermeter_info_dto.indication === -100" class="mb10">热水表：<input class="w-50" [(ngModel)]="hot_number" type="text" placeholder="设备异常,未获取到读数"></p>
              </ng-container>
              <ng-container *ngIf="hot_water_type === 'MANUAL'">
                <p class="mb10">热水表：<input type="text" [(ngModel)]="hot_number" class="w-50"></p>
              </ng-container>
            </div>
            <div *ngIf="!elemeter_info_dto && !cold_watermeter_info_dto && !hot_watermeter_info_dto" class="col-12 col-md-12 mt-4 mb-4 text-danger f16" style="padding-left: 20%">
              该房间尚未绑定水表/电表设备，请先绑定设备。
            </div>
            <p class="mt10 ml-4" style="font-size: 12px;color: #FF6600;">提醒：确认完成后，客户可在APP端房间信息内查看设备初始度数，管家可在“签约管理-合同详情-房间模块”查看</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">

        <button *ngIf="is_sumbit"
                type="button" class="btn btn-info" (click)="checkRoomIndication(checkInEqumentModal,successCheckinModal)">确&nbsp;&nbsp;定</button>&nbsp;&nbsp;
        <button *ngIf="!is_sumbit"
                type="button" class="btn btn-secondary">确&nbsp;&nbsp;定</button>&nbsp;&nbsp;
        <button type="button" class="btn btn-secondary" (click)="checkInEqumentModal.hide()">取&nbsp;&nbsp;消</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #successCheckinModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name" (click)="cclean($event)">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="successCheckinModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body success-body">
        <p class="mt-4">设备度数确认后不可更改，是否确认？</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-info" (click)="successCheckinModal.hide();confimDs()">确定</button>
        <button class="btn btn-secondary" (click)="successCheckinModal.hide();checkInEqumentModal.show()">返回</button>
      </div>
    </div>
  </div>
</div>

<!-- 打印-->
<!--<app-print-preview #print [src]="path"></app-print-preview>-->
<!-- 写字板 -->
<app-tablet [title]="title" (commitSign)="commitSign($event)"></app-tablet>
<app-inventory #meterInventory [lease_id]="lease_id" [room_id]="selectRoomId"></app-inventory>
<app-sign-device-binding #signDeviceBindingComponent (signChange)="singChane($event)"></app-sign-device-binding>


<!--<app-print-preview #printPreview [src]="contractPath"></app-print-preview>-->

<div class="loading" *ngIf="is_loading">
  <div class="loading-frame">
    <div class="loading-content px-4 py-3">
      <i class="fa fa-spinner fa-pulse"></i> 加载中，请稍后...
    </div>
  </div>
</div>
<div class="modal fade" bsModal #cameraModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>获取身份证信息</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cameraModal.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div id='ActiveXDivOne' style="display: inline-block">
        </div>
        <div style="width:200px; height: 550px; background:#C7EDCC; border: 1px solid black; float:right ;display: inline-block; ">
          <div style="width: 100%;height: 30%;">
            <img id="img1"  style="width: 99%;height: 100%"/>
          </div>
          <div style="width: 100%;height: 40%;">
							<textarea id="TextArea1" cols="20" rows="2" style="width: 100%;height:100%;">
							</textarea>
          </div>
          <select id="DeviceName" name="D1" hidden>
          </select>
          <select id="Resolution" name="D2" hidden>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
