import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {RequestService} from "../request.service";
import {GuardTourListRequest, GuardTourListResponse} from "../../model/guard-tour/guard-tour.model";

@Injectable({
  providedIn: 'root'
})
export class GuardTourService {

  // 巡更列表
  GET_LIST = `${environment.apiHost}guardtour/list`;
  // 删除一天记录
  POST_DELETE_ONE_DAY = `${environment.apiHost}guardtour/deleteOneDay`;

  // 获取巡更轨迹
  GET_GUARDTOUR_SITE_PATH = `${environment.apiHost}guardtour/site/path`;

  constructor(
    private requestService: RequestService
  ) { }


  getList(param: GuardTourListRequest): Promise<GuardTourListResponse> {
    return this.requestService.authGet(this.GET_LIST, param);
  }
  getListWithLoading(param: GuardTourListRequest): Promise<GuardTourListResponse> {
    return this.requestService.authGet(this.GET_LIST, param, true);
  }

  deleteOneDay(date: number) {
    return this.requestService.authPost(this.POST_DELETE_ONE_DAY, {date:date});
  }

  searchSitePath(req:any): Promise<any>{
    return this.requestService.authGet(this.GET_GUARDTOUR_SITE_PATH,req);
  }
}
