export class Page {

  NO_ROW_OFFSET = 0;

  NO_ROW_LIMIT = 10;

  pageIndex = 1;// 当前页

  pageSize = this.NO_ROW_LIMIT;// 页大小

  offset = 0;

  limit = this.NO_ROW_LIMIT;

  count;// 总数

  pageCount = 0;// 总数页数

  maxPageItems = 5;// 最多项

  startIndex = 0;// 开始

  endIndex = 0;// 结束

  constructor() {
  }

  getPageIndex(): number {
    return this.pageIndex;
  }

  setPageIndex(pageIndex: number): void {
    this.pageIndex = pageIndex;

    this.initPageItems();
  }

  getPageSize(): number {
    return this.pageSize;
  }

  setPageSize(pageSize: number): void {
    this.pageSize = pageSize;
    this.limit = pageSize;

    this.initPageItems();
  }

  getOffset(): number {
    return this.offset;
  }

  setOffset(offset: number): void {
    this.offset = offset;
    if (offset === 0) {
      this.pageIndex = 1;
    } else {
      this.pageIndex = (offset / this.limit) + 1;
    }

    this.initPageItems();
  }

  getLimit(): number {
    return this.limit;
  }

  setLimit(limit: number): void {
    this.setPageSize(limit);
  }

  getCount(): number {
    return this.count;
  }

  setCount(count: number): void {
    this.count = count;

    this.initPageItems();
  }

  getPageCount(): number {
    return this.pageCount;
  }

  getMaxPageItems(): number {
    return this.maxPageItems;
  }

  setMaxPageItems(maxPageItems: number): void {
    if (maxPageItems <= 0) {
      maxPageItems = 1;
    }
    this.maxPageItems = maxPageItems;
  }

  getStartIndex(): number {
    return this.startIndex;
  }

  getEndIndex(): number {
    return this.endIndex;
  }

  initPageItems(): void {

    if (this.pageSize === 0) {
      this.pageSize = 1;
    }

    this.offset = (this.pageIndex - 1) * this.pageSize;

    this.pageCount = Math.floor(this.count / this.pageSize);

    let plus = Math.ceil(this.maxPageItems / 2);
    let num = this.pageIndex - plus;
    if (num <= 0) {
      num = 1;
    }
    this.startIndex = num;
    plus = parseInt('' + (this.maxPageItems - 1) / 2, 0);
    num = this.pageIndex + plus;
    if (num > this.pageCount) {
      num = this.pageCount;
      let temp = num - this.startIndex;
      if (temp < this.maxPageItems - 1) {
        temp = this.maxPageItems - 1 - temp;
        this.startIndex = this.startIndex - temp;
        if (this.startIndex <= 0) {
          this.startIndex = 1;
        }
      }
    } else {
      let temp = num - this.startIndex;
      if (temp < this.maxPageItems - 1) {
        temp = this.maxPageItems - 1 - temp;
        num = num + temp;
        if (num > this.pageCount) {
          num = this.pageCount;
          temp = num - this.startIndex;
          this.startIndex = this.startIndex - temp;
          if (this.startIndex <= 0) {
            this.startIndex = 1;
          }
        }
      }
    }
    this.endIndex = num;
    if (this.endIndex <= 0) {
      this.endIndex = 1;
    }
  }

}

export class PageReq {
  page_num?: number;
  page_size?: number;
}

export class PageResp {
  total?: number;
}

