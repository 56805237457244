/**
 * Created by cyl on 2018/7/6.
 */
import {PageResp, PageReq} from '../page/page';
import {OrderInfoDTO} from "../order/order.model";
import {LoginUnusualDTO} from "../user/user.model";
import {SystemConfigDTO} from "../company/company.model";
import {LeaseInfoDTO, LeaseSubjectInfoDTO} from "../shop/shop-edit.model";

// 报修列表
export class RepairListReqParam {

  contact_name?: string; // 联系人
  contact_phone?: string; // 联系电话
  unit_id?: string; // 单元ID
  room_num?: string; // 单元ID
  repair_status?: string; // 保修状态（WAITING:待确认 EFFECTIVE:处理中 FIXED:已处理 INVALID:已拒绝 ）
  repair_type?: string;//报修类型（工单类型：REPAIR:维修，CLEAN:保洁,MALFUNCTION:故障）
  page_num?: number;
  page_size?: number;

  constructor() {
  }
}

export class OrderIfRelateListReqParam {
  page_num?: number;
  page_size?: number;

  constructor() {
  }
}

// 报修处理
export class RepairHandleReqParam {

  repair_id?: number;
  repair_change_type?: string;
  plan_fix_time?: number;
  reject_reason?: string;
  processing_result?: string;
  compensation?: string;

}

// 报修处理类型
export const REPAIR_HANDLE_TYPE = {
  CONFIRM: 'CONFIRM',
  REJECT: 'REJECT',
  FINISH: 'FINISH',
};

export class RepairInfo {
  repair_id?: number; // ID
  unit_id?: any; // 单元ID
  unit_name?: string;
  room_num?: string; // 房间号
  repair_content?: string; // 报修内容
  contact_name?: string; // 联系人
  contact_phone?: string; // 联系电话
  remark?: string; // 备注
  repair_status?: string; // 保修状态（WAITING:待确认 EFFECTIVE:处理中 FIXED:已处理 INVALID:已拒绝 ）
  plan_fix_time?: number; // 预计处理完成时间
  reject_reason?: number; // 拒绝原因
  fix_time?: number; // 完成时间
  create_time?: number;
  last_modify_time?: number;
  ext_info?: string;
  repair_type?: string;//报修类型（工单类型：REPAIR:维修，CLEAN:保洁, MALFUNCTION:故障）
  repair_images?: RepairImage[];
  reservation_start_time?: number;//预约维修开始时间
  reservation_end_time?: number;//预约维修结束时间
  creator_type?: string;//创建人类型
  create_id?: number;//创建人ID
  create_name?: string;//创建人名称
  repair_species_type?: string;

  constructor() {
  }
}

// 报修图片
export class RepairImage {
  repair_image_id: number; // ID',
  repair_id: number; // 报修ID
  image_url: string; // 图片地址
  create_time: number;
  last_modify_time: number;
  ext_info: string;

  constructor() {
  }
}

export class RepairInfoList {
  repair_infos: Array<RepairInfo>;
  total: number;

  constructor() {
  }
}

export class RepairOverHoursDTOList {
  repair_over_hours: Array<RepairOverHoursDTO>;
  total: number;

  constructor() {

  }

}


export class RepairOverHoursDTO {
  repair_scheme_dto?: RepairSchemeDTO;
  repair_scheme_do?: RepairSchemeDO;
  remark?: string;//备注
  over_name?: string;//受理人姓名
  receipt_time?: string;//受理时间
  repair_fee?: number;//金额
  repair_id?: number; // ID
  unit_id?: any; // 单元ID
  unit_name?: string;
  room_num?: string; // 房间号
  repair_content?: string; // 报修内容
  contact_name?: string; // 联系人
  contact_phone?: string; // 联系电话
  repair_remark_dolist?: Array<RepairRemarkDO>;// 备注
  repair_status?: string; // 保修状态（WAITING:待确认 EFFECTIVE:处理中 FIXED:已处理 INVALID:已拒绝 ）
  plan_fix_time?: number; // 预计处理完成时间
  reject_reason?: number; // 拒绝原因
  fix_time?: number; // 完成时间
  create_time?: number;
  last_modify_time?: number;
  ext_info?: string;
  repair_type?: string;//报修类型（工单类型：REPAIR:维修，CLEAN:保洁, MALFUNCTION:故障）
  repair_images?: RepairImage[];
  reservation_start_time?: number;//预约维修开始时间
  reservation_end_time?: number;//预约维修结束时间
  creator_type?: string;//创建人类型
  create_id?: number;//创建人ID
  create_name?: string;//创建人名称
  repair_species_type?: string;
  //逾期时间
  over_hours: number;
  //逾期类型
  over_type: string;

  type_name?: string;

  coming_time_str?: string;

  repair_place?: string;
  worker: any;

  before_repair_images:string[];
  after_repair_images:string[];

  clean_type_id?: number;
}

export class RepairRemarkDO {
  create_time?: number;
  remark?: string;
  remark_imgs ?: Array<string>;
}

export class RepairSchemeDO {
  payer?: string;//费用承担方
  fee_material?: number;//材料费用
  fee_labor?: number;//人工费用

}

export class RepairSchemeDTO {

  payer?: string;//费用承担方
  fee_material?: number;//材料费用
  fee_labor?: number;//人工费用

}

export class DiscountReqParam {
  service_status?: string;
  page_num?: number;
  page_size?: number;

  constructor() {
  }
}

export class RoomPriceReqParam {
  lease_id?: number;

  constructor() {
  }
}

export class RoomPriceList {
  lease_room_price_dtos?: RoomPrice;

  constructor() {
  }
}

export class RoomPriceList1 {
  lease_room_price_dtos?: Array<RoomPrice>;
}

export class RoomPrice {
  month_rent?: string;
  reall_rent?: string;
  room_name?: string;
  discount?: string;
  month_price_info_dtos?: MonthPriceInfo[];
  deposit?: string;

  constructor() {
  }
}

export class MonthPriceInfo {
  start_time?: number;
  end_time?: number;
  month_rent?: number;

  constructor() {
  }
}

export class LeaseAuditReqParam {
  lease_audi_type?: string;
  lease_audit_value?: string;
  lease_id?: number;
  remark?: string;
  lease_type?: string;

  constructor() {
  }
}

export class AuditRoomGroupReqParam {
  id?: number;
  status?: string;
  remark?: string;
}

export class AuditDetailReqParam {
  id?: number;
  service_type?: string;
  service_status?: string;
}

export class DiscountInfo {
  lease_booking_list_dto?: Lease;
  shop_lease_info_dto?: any;
}

export class Lease {
  serial_number?: number;
  create_time?: number;
  month_price?: string;
  start_time?: number;
  end_time?: number;
  contact?: string;
  lease_type?: string;
  service_type?: string;
  creater_name?: string;

  remark?: string;

  free_start_time?: Date;
  free_end_time?: Date;

  business_id?: number;
  contract_num?: string;

  constructor() {
  }
}

export class DiscountInfoList {
  // lease_booking_list?: Array<DiscountInfo>;
  audit_list_dtos?: Array<AuditDTO>;
  data_count?: number;

  constructor() {
  }
}

export class AuditDTO {
  service_type?: string;

  create_time?: number;

  id?: number;

  create_name?: string;

  project_id?: number;

  task_id?: number;
}

//门锁相关
export class DoorLockListReqParam {
  page_num?: number;
  page_size?: number;

  constructor() {
  }
}


export class DoorLockInfo {
  dev_type?: string;
  uuid?: string;
  room_id?: number;
  brand_id?: number;
  install_status?: string;
  room_name?: string;
  dev_password?: string;

  constructor() {
  }
}

export class DoorLockInfoList {
  door_lock_dtos: Array<DoorLockInfo>;
  total: number;

  constructor() {
  }
}

export class RestPswReqParam {
  uuid?: string;

  constructor() {
  }
}

export class AcceptanceResParam {
  room_id?: number;
  uuid?: string;
  install_status?: string;

  constructor() {
  }
}

export class DoorRestInfo {
  uuid?: string;
  status?: string;

  constructor() {
  }
}

//告警
export class AlertListReqParam {
  alert_type?: string;
  junior_alert_type?: string;
  page_num?: number;
  page_size?: number;
  room_name?: string;
  contract_num?: string;

  constructor() {
  }
}

export class RelevanceRoomParam {
  uuid?: string;
}

export class AlertInfo {
  alert_id?: number;
  alert_type?: string;
  title?: string;
  create_time?: number;
  alert_status?: string;
  room_name?: string;
  plan_time?: number;
  first_alert_time?: number;
  last_handle_time?: number;
  last_handler_name?: string;
  contract_num?: string;
  bill_type?: string;
  bill_amount?: string;
  waiting_pay_amount?: string;
  contact_name?: string;
  contact_phone?: string;
  evolve_dtos?: EvolveInfo[];
  lease_id?: number;
  overdue_day?: number;
  alert_type_name?: string;
  gate_way_name?: string;
  gate_way_uuid?: string;
  rent_overdue?: boolean;
  frozen_pwd_completed?: boolean;
  lease_room_ids?: number[];
  jump_remark?:string;
  sign_name?:string;
  lease_overdue?:boolean;
  frozen_pwd_completed_lease?:string;
  project_name?:string;
  room_count?:number;
  bill_time?: string;
  no_pay_amount?: string;
  contract_end?: number;
  constructor() {
  }
}

export class AlertInfos {
  alertInfos: Array<AlertInfo>;
  total?: number;

  constructor() {
  }
}

export class FaultAlertInfos {
  fault_alert_dtos?: Array<AlertInfo>;
  total?: number;

  constructor() {
  }
}

export class DelayRefundInfo {
  delay_refund_list?: Array<AlertInfo>;
  total?: number;

  constructor() {
  }
}

export class DueAlertInfos {
  due_alert_dtos?: Array<AlertInfo>;
  total?: number;
  signatory_dtolist?: Array<SignatoryDTO>;

  constructor() {
  }
}

export class NoCheckoutAlertInfos {
  no_checkout_alert_dto?: Array<AlertInfo>;
  total?: number;
  signatory_dtolist?: Array<SignatoryDTO>;

  constructor() {
  }
}

export class EvolveInfo {
  evolve_id?: number;
  alert_id?: number;
  alert_type?: string;
  status?: string;
  handler?: string;
  remark?: string;
  create_time?: number;

  constructor() {
  }
}

export class EvolveReqParam {
  alert_id?: number;
  evolve_remark?: string;
  evolve_status?: string;
  alert_type?: string;

  constructor() {
  }
}

export class DataStatReqParm {
  data_stat?: string;

  constructor() {
  }
}

export class DataStatInfo {
  check_in?: number;
  vacant?: number;
  check_info?: number;

  constructor() {
  }
}

export class RoomGroupInfo {
  audit_room_group_dto?: AuditRoomGroupDTO;
}

export class AuditRoomGroupDTO {
  //@Remark("房源ID")
  id?: number;
  //@Remark("房源编号")
  room_group_no?: string;

  //@Remark("月租金")
  month_price?: string;

  //@Remark("递增类型 PER_YEAR 每年 PER_HALF_YEAR 每半年 PER_SEASON 每季度 PER_MONTH 每月")
  increase_type?: string;

  //@Remark("递增金额")
  increase_amount?: string;

  //床数量
  bed_num?: number;
  //最多可入住人数
  livein_num?: number;
  //朝向
  orientation?: string;
  //房源风格
  group_style_name?: string;
  //面积
  area?: string;
  //粗略地址
  simple_location?: string;
  //详细地址
  location?: string;
  //交通信息
  traffic_desc?: string;
  //经度
  longtitude?: string;
  //纬度
  latitude?: string;
  //状态
  status?: string;
  //房间数量
  room_num?: number;
  //主图
  banner_pic?: string;
  image_list?: any[];

  //@Remark("物业费")
  manage_fee?: string;

  //@Remark("项目名称")
  project_name?: string;
  //房型  一床房 / 高级大床房
  roon_type_name?: string;

  //设施
  facility_info_dtos?: FacilityInfoDTO[];
}

export class FacilityInfoDTO {
  facility_id?: number;
  facility_name?: string;
  facility_code?: string;
  facility_url?: string;
}

// 报修处理类型
export const ALERT_TYPE = {
  DUE: 'DUE',
  DUE_PROPERTYMANAGEMENTFEE: 'DUE_PROPERTYMANAGEMENTFEE',
  DUE_BROADBANDFEE: 'DUE_BROADBANDFEE',
  DUE_ALL: 'DUE_ALL',
  NO_CHECKOUT: 'NO_CHECKOUT',
  FAULT: 'FAULT',
  DELAY_REFUND: 'DELAY_REFUND'
};

/**
 * @Description:  工作台车库租约审核详情
 * @Author: zhoujiahao
 * @Date: 2018-12-07  11:40
 */
export class CarportAuditDetailResponse {
  // @Remark("车库租约ID")
  carport_id: number;

  //@Remark("提交人")
  submit_user_name?: string;

  //@Remark("开始时间")
  start_time: Date;

  //@Remark("结束时间")
  end_time: Date;

  //@Remark("租约类型，原生 origin、续租 relet ")
  lease_type: string;

  //@Remark("提交时间")
  submit_time?: Date;

  //@Remark("合同号")
  contract_num?: string;

  //@Remark("车库信息")
  carport_lease_relation_dtos?: CarportLeaseRelationDTO[];

  remark?: string;

  free_start_time?: Date;

  free_end_time?: Date;
}

/**
 * @Description:  车库信息
 * @Author: zhoujiahao
 * @Date: 2018-12-07  11:44
 */
export class CarportLeaseRelationDTO {
  //@Remark("车库-租约关联ID")
  carport_lease_relation_id: number;

  //@Remark("车库ID")
  carport_info_id: number;

  //@Remark("车库编号")
  carport_number: number;

  //@Remark("基准价")
  base_price?: string;

  //@Remark("签约价")
  month_price: string;

  //@Remark("折扣幅度")
  discount?: string;
}

export class AlertDTO {

  alert_id?: number;

  title?: string;

  alert_type?: string;

  alert_status?: string;

  last_handler_name?: string;

  last_handle_time?: number;

  evolve_dtos?: Array<EvolveInfo>;

  room_name?: string;

  first_alert_time?: string;
}

export class LeaseFCReviewDTO extends AlertDTO {

  lease_id?: number;

  lease_num?: string;

  create_user_name?: string;

  first_alert_time?: string;
}

export class LFCReviewResponse extends PageResp {
  lease_fcreview_dtolist?: Array<LeaseFCReviewDTO>;
}

export class RelevanceRoomResponse {

  gate_way_name?: string;

  room_name_list?: Array<String>;

}

export class Aaa extends PageResp {
  ffdsafdsafasd?: Array<LeaseFCReviewDTO>;

}

export class OrderIfRelateResponse {
  order_info_dtolist: Array<OrderInfoDTO>;
  total: number;

  constructor() {

  }

}

/**
 * 公告相关model
 */
export class NoticeUserListResponse extends PageResp {
  notice_user_dtolist: Array<NoticeUserDTO>;
}

export class NoticeUserRequest extends PageReq {
  userId: number;
  noticeId: number;
}

export class NoticeUserResponse extends PageResp {
  notice_user_dto: NoticeUserDTO;
}

export class NoticeUserDTO {
  id: number;
  type: string;
  title: string;
  status: string;
  read_flag: number;
  content: string;
  create_time: string;
  version: string;
}

export class NoticeUserCreateResponse {
  result: string;
}

export enum NoticeType {
  SYSTEM_UPDATE = '系统升级',
  BUSINESS_REMIND = '业务通知'
}

export class SignatoryDTO {

  lease_id?: number;

  signatory?: string;

  room_name_or_numnber?: string;

  contact_name?: string;

  contact_phone?: string;

  contact_type?: string;
}

export class ProjectDto {
  project_id?: number;

  project_name?: string;

  project_file_dtos: Array<ProjectFileDTO>;
}

export class ProjectFileDTO {
  id?: number;

  file_type?: string;

  file_url?: string;

  room_type_name?: string;

  file_name?: string;

  audit_status?: string;

  base_room_id?: string;
}

export class ProjectFileAuditRequest {
  id?: number;
  project_id?: number;
  project_file_dtos: Array<ProjectFileDTO>;
  remark?: string;
}

export class SweepCodeNotPayRequest {
  create_time: Date;
  order_id: string;
  order_no: string;
  buiness_id: string;
  order_status: string;
  order_amount: string;
  pay_channel: string;
  remark: string;
  pay_type: string;
}

export class OrderInfoLogResponse {
  order_info_log_dtos: Array<OrderInfoLogDTO>;
}

export class OrderInfoLogDTO {
  pay_type: string;
  pay_amount: string;
  pay_time: Date;
  user_name: string;
  remark: string;
  create_time: Date;
}


// workbench data

export class RoomRentStatisticsDTO {
  month_rent_percent?: number;
  today_rent_percent?: number;

  room_num?: number;
  lease_room_num?: number;
  enclosed_lease_room_num?:number;

  unavailable_room_num?: number;
  internal_room_num?: number;

  actual_total_percent?: string;
  room_total_percent?: string;
  bed_total_percent?: string;
}

export class RenterStatisticsDTO {
  // 办入住人数
  checkin_num?: number;
  // 退房人数
  checkout_num?: number;
  // 在住人数
  effective_num?: number;
}

export class LeaseGatheringRentDTO {
  // 今日剩余应收
  left_gathering?: string;
  // 逾期未收
  overdue_gathering?: string;
  /**
   * 今日押金剩余应收
   */
  deposit_left_gathering?: string;

  /**
   * 逾期押金未收
   */
  deposit_overdue_gathering?: string;

  /**
   * 今日其他费用剩余应收
   */
  other_left_gathering?: string;

  /**
   * 其他费用逾期未收
   */
  other_overdue_gathering?: string;
  /**
   * 商铺今日房费剩余应收
   */
  shop_left_gathering?: string;

  /**
   * 商铺逾期房费未收
   */
  shop_overdue_gathering?: string;

  /**
   * 商铺今日押金剩余应收
   */
  shop_deposit_left_gathering?: string;

  /**
   * 商铺逾期押金未收
   */
  shop_deposit_overdue_gathering?: string;

  /**
   * 商铺今日其他费用剩余应收
   */
  shop_other_left_gathering?: string;

  /**
   * 商铺其他费用逾期未收
   */
  shop_other_overdue_gathering?: string;
}

export class LeaseOverdueCheckoutDTO {
  // 今日应退房间数
  planNum?: number;
  // 逾期未退房间数
  overdueNum?: number;

  plan_num?: number;

  overdue_num?: number;
}

export class ComplaintStatisticsDTO {
  // 今日新增
  today_num?: number;
  // 本周新增
  this_week_num?: number;
  // 本周处理时效(总分钟)
  deal_speed_mins?: number;
  // 本周处理时效：小时
  deal_hour?: String;
  // 本周处理时效：分钟
  dealMinute?: number;
}

export class DevErrorStatisticsDTO {
  // 门锁故障数量
  door_count?: number;
  // 电表故障数量
  ele_count?: number;
  // 水表故障数量
  water_count?: number;
}

export class RepairStatisticsDTO {
  // 今日新增
  today_num?: number;
  // 本周新增
  this_week_num?: number;
  // 本周处理时效
  deal_speed_mins?: number;
  // 处理时效：小时
  deal_hour?: string;
  // 处理时效：分钟
  deal_minute?: string;
  // 本周响应超时单数
  timeout_num?: number;
  // 平均超时（分钟）
  avg_timeout_mins?: number;
}

export class RoomInspectStatisticDTO {
  // 今日新增
  today_num?: number;
  // 本月新增
  this_month_num?: number;
  // 需复查数量
  need_check_num?: number;
}

export class BusinessBasisDataDTO {
  job_date?: number;
  // 今日总收入
  today_income?: string;
  // 今日新签合同
  today_new_lease?: number;
  // 今日续签合同
  today_relet_lease?: number;
}

export class PrepayConsumeDataDTO {

  jobDate?: number;
  // 今日个人账户消费
  today_person_consume?: string;
  // 今日企业账户消费
  today_company_consume?: string;
}

export class OverdueGatheringDataDTO {
  // 本月剩余应收
  left_gathering_amount?: string;
  // 本月实收
  recieved_amount?: string;
  // 本月设为无需付
  needless_amount?: string;
}

export class PrepayRechargeStatisticsDTO {
  // 个人充值
  person_amount?: string;
  // 企业充值
  company_amount?: string;
}

export class RefundStatisticsDTO {
  // 今日剩余应退
  today_not_refund?: string;
  // 本周剩余应退
  this_week_not_refund?: string;
  // 本月实际已退
  this_month_refunded?: string;
}

export class InvoiceStatisticsDTO {
  // 本月申请发票
  apply_num?: number;
  // 本月已开发票
  invoiced_num?: number;
  // 本月开票总额
  invoiced_amount?: string;
}

export class BusinessGatheringDataDTO {
  // 转账
  transfer_amount?: string;
  // 微信
  weixin_amount?: string;
  // 支付宝
  alipay_amount?: string;
  // 现金
  cash_amount?: string;
  // 龙支付
  long_amount?: string;
  // 其他
  other_amount?: string;
}

export class WorkScheduleSttisticsDTO {
  /**
   * 今日保洁工作计划进度
   */
  clean_percent?: number;
  /**
   * 今日保安工作计划进度
   */
  security_percent?: number;
  /**
   * 今日工程工作计划进度
   */
  engineering_percent?: number;
  /**
   * 今日计划完成率
   */
  today_finished_percent?: number;
  /**
   * 本月设备维保工作计划进度
   */
  equipment_percent?: number;
  /**
   * 本月计划完成率
   */
  this_month_finished_percent?: number;
  /**
   * 本月已完成数量
   */
  finished_num?: number;
  /**
   * 本月未完成数量
   */
  un_finished_num?: number;
  /**
   * 本月待检查数量
   */
  wait_check_num?: number;
}

export class PatrolRecordUploadReminderDTO {
  /**
   * 昨天是否上传
   */
  yesterday_upload_flag?: boolean;
  /**
   * 本周上传天数
   */
  this_week_un_upload_days?: number;
}

export class RoomFreeStatisticsModel {
  /**
   * 空房间数
   */
  vacant_room_num?: number;
  /**
   * 总房间数
   */
  room_num?: number;
  /**
   * 空房间数
   */
  vacant_bed_num?: number;
  /**
   * 总房间数
   */
  bed_num?: number;
  not_checkin_num?:number;
  bed_config?:boolean;
}


export class WorkbenchDataResponse {
  // @Remark("房间出租统计")
  room_rent_statistics_dto?: RoomRentStatisticsDTO;
  // @Remark("房间在住统计")
  room_free_statistics_model?: RoomFreeStatisticsModel;
  // @Remark("住户统计")
  renter_statistics_dto?: RenterStatisticsDTO;
  // @Remark("合同收租")
  lease_gathering_rent_dto?: LeaseGatheringRentDTO;
  // @Remark("逾期退房统计")
  lease_overdue_checkout_dto?: LeaseOverdueCheckoutDTO;
  // @Remark("投诉建议事件统计")
  complaint_statistics_dto?: ComplaintStatisticsDTO;
  // @Remark("设备故障事件统计")
  dev_error_statistics_dto?: DevErrorStatisticsDTO;
  // @Remark("保洁事件统计")
  clean_statistics_dto?: RepairStatisticsDTO;
  // @Remark("维修事件统计")
  repair_statistics_dto?: RepairStatisticsDTO;
  // @Remark("房间巡查统计")
  room_inspect_statistic_dto?: RoomInspectStatisticDTO;
  // @Remark("营业基础数据")
  business_basis_data_dtos?: Array<BusinessBasisDataDTO>;
  // @Remark("预付账户消费数据")
  prepay_consume_data_dtos?: Array<PrepayConsumeDataDTO>;
  // @Remark("逾期金收款数据")
  overdue_gathering_data_dto?: OverdueGatheringDataDTO;
  // @Remark("预付费充值统计")
  prepay_recharge_statistics_dto?: PrepayRechargeStatisticsDTO;
  // @Remark("退款数据统计")
  refund_statistics_dto?: RefundStatisticsDTO;
  // @Remark("发票数据统计")
  invoice_statistics_dto?: InvoiceStatisticsDTO;
  // @Remark("运营数据统计")
  business_gathering_data_dto?: BusinessGatheringDataDTO;
  // @Remark("工作计划统计")
  work_schedule_sttistics_dto?: WorkScheduleSttisticsDTO;
  // @Remark("巡更记录上传提醒")
  patrol_record_upload_reminder_dto?: PatrolRecordUploadReminderDTO;
  // @Remark("营业基础数据-本月总收入")
  this_month_income?: string;
  // @Remark("营业基础数据-本月新签合同数")
  this_month_new_lease?: number;
  // @Remark("营业基础数据-本月续签合同数")
  this_month_relet_lease?: number;
  // @Remark("预付账户消费数据-本月总收入")
  this_month_person_prepay_consume_total?: string;
  // @Remark("预付账户消费数据-本月总收入")
  this_month_company_prepay_consume_total?: string;
  // @Remark("预付账户充值统计-本月总充值")
  this_month_prepay_recharge_total?: string;
  // @Remark("运营收款数据统计-本月转账总收入")
  this_month_transfer_total?: string;
  // @Remark("运营收款数据统计-本月微信总收入")
  this_month_weixin_total?: string;
  // @Remark("运营收款数据统计-本月支付宝总收入")
  this_month_ali_total?: string;
  // @Remark("运营收款数据统计-本月现金总收入")
  this_month_cash_total?: string;
  // @Remark("运营收款数据统计-本月龙支付总收入")
  this_month_long_total?: string;
  // @Remark("运营收款数据统计-本月其他总收入")
  this_month_other_total?: string;

  system_config_dto?: SystemConfigDTO;

  smart_dev_flag?: boolean;
}


export class WorkbenchWarningStatisticDTO {
  month?: number;
  processed_num?: number;
  un_processed_num?: number;
}

export class WorkbenchWarningStatisticResponse {
  alarm_statistics_dtolist?: Array<WorkbenchWarningStatisticDTO>;
}


export class PersonSafeAlarmListRequest extends PageReq {
  // true: (已关闭状态报警记录) false: (未处理/处理中状态报警记录)
  is_closed?: boolean;
  // THID_NOT_IN 3日未归 THID_NOT_OUT 三日未出 UN_REGISTERED 未登记在住人员 UNKNOW_SUSPICIOUS 陌生可疑
  safe_type?: string;
}



export class AccessDTO {
  // @Remark("类型(进/出)")
  type?: string;
  // @Remark("进出时间")
  access_time?: number;
  // @Remark("进出位置详情")
  position_detail?: string;
  // @Remark("图片")
  image?: string;
}


export class StrangeSuspectDTO {
  unit_detail?: string;
  access_dtos?: Array<AccessDTO>;
}

export class NotRegisteredChickinDTO {
  // @Remark("进出总次数")
  total_num?: number;
  // @Remark("进出楼栋(多楼栋以'/'隔开：D栋/A1栋)")
  unit_name?: string;
  // @Remark("进出楼栋房间(多楼栋以'/'隔开：D>105/A1>108)")
  unit_room_name?: string;
  // @Remark("进入时间段")
  come_in_time?: string;
  // @Remark("进入次数")
  come_in_num?: number;
  // @Remark("出去时间段")
  get_out_time?: string;
  // @Remark("出去次数")
  get_out_num?: number;
  // @Remark("首次进入日期")
  first_come_in_time?: number;
  // @Remark("进出明细")
  strange_suspect_dtolist?: Array<StrangeSuspectDTO>;
}

export class PersonSafeAlarmDTO {
  alarm_id?: number;
  // @Remark("报警时间")
  alarm_time?: number;
  // @Remark("人员安全报警类型")
  safe_type?: string;
  // @Remark("人员头像")
  head_image?: string;
  // @Remark("入住房间号")
  room_name?: string;
  // @Remark("人员名称")
  person_name?: string;
  // @Remark("人员备注信息")
  person_remark?: string;
  // @Remark("离宿时间")
  get_out_time?: number;
  // @Remark("进入时间")
  come_in_time?: number;
  // @Remark("最后一次抓拍照片")
  last_image?: string;
  status?: string;
  // 操作人
  operator_name?: string;
  // 操作时间
  operator_time?: number;


  // @Remark("未登记入住人员")
  not_registered_chickin_dto?: NotRegisteredChickinDTO;
  // @Remark("陌生可疑人员")
  strange_suspect_dtos?: Array<StrangeSuspectDTO>;
}

export class PersonSafeAlarmListResponse extends PageResp {
  person_safe_alarm_dtos?: Array<PersonSafeAlarmDTO>;
}


export class PersonSafeAlarmResponse {
  person_safe_alarm_dto?: PersonSafeAlarmDTO;
}

export class PersonSafeAlarmLogDTO {
  id?: number;
  type?: string;
  content?: string;
  create_time?: number;
  operate_name?: string;
}

export class PersonSafeAlarmLogsResponse {
  person_safe_alarm_log_dtos?: Array<PersonSafeAlarmLogDTO>;
}

export class PersonSafeAlarmLogRequest {
  alarm_id?: number;
  type?: string;
  content?: string;
}

export class WorkbenchMonthPriceResponse {
  room_type_name?:string;
  month_price_avg?:number;
}

export class WorkbenchMonthPriceRequest {
  year?:string;
  month?:string;
}
export class QueryClientNameRequest{
  client_name?:string;
}
export class QueryClientNameResponse{
  exist?:boolean;
}
export class ClientNameCertcodeRequest{
  client_name?:string;
  cert_code?:string;

}
export class ClientNameCertcodeResponse{
  account_money_list:Array<AccountMoneyListDTO>;
  exist_multiple_accounts?:boolean;
  exist?:boolean;
  client_name?:string;
}
export class ThirdpayPaymentConfirmRequest{
  account_money_info_id?:number;
  order_no?:string;
  idempotent_key?:string;
  pay_name?:string;
}
export class QueryAccountNameRequest {
  client_name?:string;
}
export class QueryAccountNameResponse {
  account_money_list?: Array<AccountMoneyListDTO>;
  exist?:boolean;

}
export class AccountMoneyListDTO {

  account_money_name?:string;
  account_money_info_id?:number;
}
export class NoAuditListRequest {
  page_num?:number;
  page_size?:number;
}
export class NoAuditListResponse {
  no_audit_transfer_list?: Array<NoAuditTranstferListDTO>;
  total?:number;

}
export class NoAuditTranstferListDTO{
  create_time?:string;
  create_user?:string;
  pay_user?:string;
  pay_type?:string;
  pay_amount?:number;
  status?:string;
}
export class FrozenDoorPasswordAuditRequest{
  phone?:string;
  code?:string;
  frozen_lease_id?: number;
  frozen_lease_room_ids?: number[];
  frozen_type?:string;
  alert_id;
  imgs?: Array<string>;
}

export class FrozenDoorPasswordSmsCodeRequest{
  phone?:string;
  contract_num?:string;
  frozen_type?:string;
}
export class UnFrozenDoorPasswordRequest{
  frozen_lease_id?: number;
  frozen_lease_room_ids?: number[];
  frozen_type?:string;
  alert_id?: number;
}

export class QueryEvolveRequest{
  alert_id?: number;
}

export class QueryEvolveResponse{
  evolve_dtos?: Array<EvolveInfo>;
}

export class AddEvolveRemarkRequest{
  alert_id?: number;
  evolve_remark?: string;
}

export class WorkbenchSendEmailRequest{
  mail_address?: string;

  mail_subject?: string;

  mail_text?: string;

  alert_id?: number;

  service_type?: string;

  lease_id?: number;
}

export class WorkbenchSendEmailResponse{
  result?: string;
}

export class WorkbenchEmailHistoryRequest{
  service_type?: string;

  lease_id?: number;
}

export class WorkbenchEmailHistoryDTO{
  user_name?: string;

  create_time?: number;
}

export class WorkbenchEmailHistoryResponse{
  workbench_email_history_dtos?: Array<WorkbenchEmailHistoryDTO>;
}

export class FireFacilitySystemComponentStateAlertDTO extends AlertInfo {

  // @Remark("系统类型")
  type_num: number;
  system_type_enum?: string;
  system_type_name?: string;

  // @Remark("系统地址")
  addr_num?: number;
  sys_addr_str?: string;

  // @Remark("部件类型")
  component_type_num?: number;

  fire_engine_system_component_type_enum?: string;
  component_type_str?: string;

  // @Remark("部件地址")
  component_addr_num?: number;
  component_addr_str?: string;

  // @Remark("状态")

  // 0： 1正常运行 0测试
 b0?: number;
  // 1： 1火警 0无火警
 b1?: number;
  // 2： 1故障 0无故障
 b2?: number;
  // 3： 1屏蔽 0无屏蔽
 b3?: number;
  // 4： 1监管 0无监管
 b4?: number;
  // 5： 1启动 0停止
 b5?: number;
  // 6： 1反馈 0无反馈
 b6?: number;
  // 7： 1延时状态 0未延时
 b7?: number;
  // 8： 1电源故障 0正常
 b8?: number;
  // 9： 预留
 b9?: number;
  // 10： 预留
 b10?: number;
  // 11： 预留
 b11?: number;
  // 12： 预留
 b12?: number;
  // 13： 预留
 b13?: number;
  // 14： 预留
 b14?: number;
  // 15： 预留
   b15?: number;

  // @Remark("描述")
  desc_str?: string;

  // @Remark("原始数据")
  hex_log_data?: string;

  // @Remark("上报时间")
 time?: number;
}

export class FireAlertListResp extends PageResp {
  dto_list: Array<FireFacilitySystemComponentStateAlertDTO>;
}
