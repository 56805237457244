import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {getUserAgent} from '../../../common/common';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../../service/common/auth.service';
import {NgxExtendedPdfViewerComponent} from "ngx-extended-pdf-viewer";
import {WorkService} from "../../../service/common/work.service";

@Component({
  selector: 'app-pdf-view',
  templateUrl: './pdf-view.component.html',
  styleUrls: ['./pdf-view.component.css']
})
export class PdfViewComponent implements OnInit, OnChanges {


  @ViewChild(NgxExtendedPdfViewerComponent) viewer: NgxExtendedPdfViewerComponent;

  canShow;

  @Input() src: string;
  @Input() temp = true;
  @Input() height = '80vh';

  iframe: string;
  iframeSafeUrl: SafeResourceUrl;
  frameName = "frame_" + Math.random();

  constructor(
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private authService: AuthService,
    private workService: WorkService
  ) {
  }

  ngOnInit() {

    let agent = getUserAgent();
    this.canShow = agent.split("|")[1];

  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('src' in changes) {
      this.initUrl();
    }
  }

  initUrl() {

    if (!!this.src) {

      if (this.temp) {
        this.http.get(this.src, {
          headers: {
            'Authorization': this.authService.getAuthToken(),
          },
          responseType: "blob"
        }).toPromise().then((data) => {
            let data_url = URL.createObjectURL(data);
            this.iframe = data_url;
            this.iframeSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframe);
          }
        );
      } else {
        this.iframe = this.src;
        this.iframeSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframe);
      }
    }
  }

  /**
   * 打印
   */
  print() {

    try {
      let map: NodeListOf<HTMLElement> = document.getElementsByName(this.frameName) as NodeListOf<HTMLElement>;
      let frameDiv: HTMLIFrameElement = map.item(0) as HTMLIFrameElement;
      let iWindow = frameDiv.contentWindow;
      iWindow.focus();
      iWindow.print();
    } catch (e) {
      window.open(this.iframe);
    }
  }
}
