<div class="setbox-top" *ngIf="contract_ext">
  <div class="bg-primary ">
    <div class="row">
      <div class="col-12 col-md-6 col-xl-7 hei32">
        <i class="fa fa-info-circle">
          申请单号：{{contract_ext.record_number}}</i>
      </div>
      <div class="col-12 col-md-6 col-xl-5">
        <p class="text-yellow1 text-right hei32">
          <span class="mr20">合同状态: {{contract_ext.status | enumTransfer: status_list}}</span>
        </p>
      </div>
    </div>
  </div>
  <div class="setbox contract">
    <div class="frame-content" #print_div id="print_div">
      <div style="font-size: 10pt;line-height: 1.5;" *ngIf="contract_ext.contract_details_map">
<!--        <div style="padding:15pt;">-->
<!--          <img src='../../../../assets/img/logo2.png' />-->
<!--        </div>-->

        <div *ngIf="template_content" [innerHTML]="template_content | html"></div>

      </div>
    </div>
  </div>
  <div class="contract-fixed">
<!--    <e-ngx-print [btnText]="'打印协议书'" [btnClass]="{'btn btn-info mr-2': true}" [printHTML]="print_div"></e-ngx-print>-->
    <button printSectionId="print_div" ngxPrint class="btn btn-primary mr-2"  >打印协议书</button>

    <app-new-file-upload #contract_file_upload
                         name="上传已签协议书"
                         [url]="contract_upload_url"
                         [accept]="'PDF'"
                         (fileChange)="contractUpload($event)"
    >
    </app-new-file-upload>
    <button class="btn btn-warning ml-5" (click)="tabletComponent.tabletShow()" *ngIf="isPad()">线上签字</button>
    <button class="btn btn-warning ml-5" (click)="signOnIpad()" *ngIf="!isPad()">同步pad签字</button>
  </div>
</div>

<!-- 写字板 -->
<app-tablet [title]="'请签约人在下方写字板内签字'" (commitSign)="commitSign($event)"></app-tablet>

<!-- 操作提示 -->
<div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static', keyboard: false}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-static-name" class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提示</h4>
        <button type="button" class="close pull-right" aria-label="Close" [routerLink]="['/system_manage/signed/change/room/details', record_id]">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body text-center">
        <p class="text-dark mt-3 mb-3 font16">换房成功！</p>
        <p class="text-dark mt-3 mb-3 font16">您可在“已签署合同明细页面”-【换房记录】模块查看/打印换房协议书。</p>
        <button class="btn btn-info" (click)="close()">关闭</button>
      </div>
    </div>
  </div>
</div>
<app-sign-device-binding #signDeviceBindingComponent></app-sign-device-binding>
